import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.grey['600']}`,
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function PreguntasTextoUI(props) {
        const classes = useStyles();
        return (
            <ExpansionPanel
            onChange={props.onChange}
            defaultExpanded={props.defaultExpanded}
            expanded={props.expanded}
            disabled={props.disabled}
            >
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                  {
                    props.header ?
                    props.header :
                    <Typography className={classes.heading}>{props.title}</Typography>
                  }
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.root}>
                    {props.toolBar}
                    {props.description}
                </ExpansionPanelDetails>
            </ExpansionPanel>

        );
}
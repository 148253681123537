import React, { Component } from 'react';
import { Button, Header } from 'semantic-ui-react';
import formatter from '../../../../utils/formatter';
import _ from 'lodash';

class OptionsSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      institution: null,
      rol: null,
      agenda: null
    };
  }
  // ---------------------------------
  // ------ life cycle events --------
  // ---------------------------------

  componentDidMount() {
    this.fowardSelector(this.props.info, this.state);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !_.isEqual(nextState, this.state) || !_.isEqual(clean(nextProps), clean(this.props));
  }

  // ------------------------
  // ------  methods --------
  // ------------------------

  selectInstitution(institution) {
    const state = this.state;
    state.institution = institution;
    this.fowardSelector(this.props.info, state);
  }

  selectRol(rol) {
    window.amplify.store('rol', rol);
    const state = this.state;
    state.rol = rol;
    this.fowardSelector(this.props.info, state);
  }

  // selectAgenda(agenda) {
  //   const state = this.state;
  //   state.agenda = agenda;
  //   this.fowardSelector(this.props.info, state);
  // }

  // ------------------------------
  // ------ render methods --------
  // ------------------------------

  fowardSelector(info, stateEdit) {
    const { selected } = this.props;
		const state = stateEdit;
		if (info.instituciones.length > 0 && state.institution == null) {
			if (info.instituciones.length === 1) {
				state.institution = info.instituciones[0];
			}
		}
		if (state.institution != null) {
			if (state.institution.roles.length > 0 && state.rol == null) {
				if (state.institution.roles.length === 1) {
          state.rol = state.institution.roles[0];
				}
			}
			// if (state.institution.agendas.length > 0 && state.agenda == null) {
			// 	if (state.institution.agendas.length === 1) {
			// 		state.agenda = state.institution.agendas[0];
			// 	}
			// }
		}
		if (state.institution && state.rol) {
      this.setState({ info, state });
			selected(state);
		} else {
      this.setState({ info, state });
		}
	}

  renderRol() {
    if (this.state.institution) {
      return this.state.institution.roles.map((item, index) => {
        return (
          <Button style={styles.button}  key={index} onClick={() => { this.selectRol(item);}}>
            {item.vc_nombre}
          </Button>
        );
      });
    }
  }

  renderInstitutions() {
    const { info } = this.props;
    if (info) {
      return info.instituciones.map((item, index) => {
        return (
          <Button style={styles.button}  key={index} onClick={() => { this.selectInstitution(item);}}>
            {item.vc_nombre}
          </Button>
        );
      });
    }
  }

  // renderAgenda() {
  //   if (this.state.institution) {
  //     return this.state.institution.agendas.map((item, index) =>
  //       (
  //         <Button style={{ width: '100%', borderRadius: 0, backgroundColor: 'ligthGray', marginBottom: 1, minHeight: 50 }} key={index} onClick={() => { this.selectAgenda(item); }} >
  //           {item.vc_nombre}
  //         </Button>
  //       )
  //     );
  //   }
  // }

  renderHeader() {
    let title = '';
    if (this.state.institution == null) {
      title = 'Seleccione una institución';
    } else if (this.state.rol == null) {
      title = 'Seleccione un rol';
    }
    return title;
  }

  renderContent() {
    const { info } = this.props;
    if (info) {
      if (this.state.institution == null) {
          return this.renderInstitutions();
      } else if (this.state.rol == null) {
          return this.renderRol();
      }
    }
  }

  render() {
    return (
        <div style={{ float:'center', padding: 15, border:0 , width:'100%', padding: 2 }}>
            <Header size='tiny' style={{position:'sticky'}}> {this.renderHeader()}</Header>
            <div style={{float:'center',overflowY: 'auto', width: '100%', maxHeight: window.innerHeight * 0.6, overflowY: 'scroll', WebkitOverflowScrolling: 'touch', borderRadius:5, padding: 5}} >
                {this.renderContent()}
            </div>
        </div>
    );
  }
}
const styles = {
  button: {
    width: '100%', borderRadius: 5, backgroundImage:'linear-gradient(rgb(250,250,250), rgb(240,240,240))', backgroundColor:window.colors.white, color:window.colors.gray, marginBottom: 5, minHeight: 60  
  }
}

export default OptionsSelector;

import { LOGIN, LOG_OUT } from '../actions/types';


export default (state, INITIAL_STATE, action) => {
    switch (action.type) {
		case LOGIN:
			return { ...state, user: action.payload };
		case LOG_OUT:
			return { ...INITIAL_STATE };
		default:
			return null;
	}
};

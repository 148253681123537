import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  outlinedInput: {
    padding: '8px 15px',
  },
  inputLabel: {
    transform: 'translate(14px, 10px) scale(1)'
  }
}));
const NumberFormatComponent = props => {
  const {
    inputRef,
    onChange,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      prefix='$ '
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
    />
  );
};

export default props => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [prevValueProps, setPrevValueProps] = React.useState(null);
  useEffect(() => {
    if (props.value != value.value && props.value != prevValueProps) {
      if (props.value !== null) {
        setPrevValueProps(props.value)
        setValue(props.value)
      }
    }
  }, [props])
  const onChangeCapture = (e) => {
    if (!props.maxLength) {
      const value = e.target.value
      setValue(value)
      props.onChange && props.onChange(value)
    }
    else if (props.maxLength >= e.target.value.length) {
      const value = e.target.value
      setValue(value)
      props.onChange && props.onChange(value)
    }
  }
  return (
    <TextField
      variant={'outlined'}
      id={props.fieldid}
      onChangeCapture={onChangeCapture}
      value={value}
      InputProps={{
        inputComponent: NumberFormatComponent
      }}
      inputProps={{
        className: classes.outlinedInput,
        format: props.format || null,
        ...props.inputProps || {}
      }}
      label={props.label}
      // {...props}
      style={{ marginTop: 9.5, marginRight: 10 }}
    />
  );
};
export const fields = ["vcNombre", "vcDescripcion"];

export const fieldsRequired = ["vcNombre"];

export const fieldsForm = [
  {
    icon: "ShortText",
    name: "vcNombre",
    props: { placeholder: "Nombre*" },
  },
  {
    icon: "Subject",
    name: "vcDescripcion",
    props: { placeholder: "Descripción", multiline: true, rows: 3, rowsMax: 3 },
  },
];

export const columns = [
  // { name: "vcNombre", label: "Nombre" },
  { name: "vc_descripcion", label: "Descripcion" },
];

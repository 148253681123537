import React from "react";

import {
  Box,
  Divider,
  Popover,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";

import Input from "app/components/Form/Input";
import Search from "@material-ui/icons/Search";
import Check from "@material-ui/icons/Check";
import _ from "lodash";

const Index = ({
  onClick,
  data,
  value,
  hideSearch,
  maxRows,
  showAllRows,
  anchorOrigin,
  transformOrigin,
  ...props
}) => {
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState(
    data.find((v) => v.value === value)
  );
  React.useEffect(() => {
    setRows(getRows(data));
  }, []);

  React.useEffect(() => {
    setSelected(data.find((v) => v.value === value));
  }, [value]);

  React.useEffect(() => {
    if (search) {
      const filtered = data.filter(({ text }) =>
        text.toLowerCase().includes(search.toLowerCase())
      );
      setRows(getRows(filtered));
    } else {
      setRows(getRows(data));
    }
  }, [search]);

  const getRows = (array) =>
    showAllRows ? array : [...array].splice(0, maxRows);

  const select = (row) => {
    onClick(row);
    setAnchorEl(null);
    setSearch("");
    setRows(getRows(data));
    setSelected(row);
  };

  return [
    <Button
      {...props}
      size="small"
      color="inherit"
      onClick={(e) => setAnchorEl(e.currentTarget)}
    >
      {selected ? selected.text : "Seleccionar"}
    </Button>,

    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Box
        padding={0}
        style={{
          maxHeight: "300px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!hideSearch && [
          <Box padding={1} paddingBottom={0}>
            <Input
              inputProps={{}}
              autoFocus
              onClick={(e) => e.stopPropagation()}
              value={search}
              onChange={setSearch}
              startAdornment={<Search style={{ marginRight: 8 }} />}
              style={{ margin: 0 }}
              dense
              hasBackground={false}
            />
          </Box>,
          <Divider variant="fullWidth" />,
        ]}

        <Box
          padding={0}
          style={{
            flex: "1 1 auto",
            overflowY: "auto",
          }}
        >
          {rows.map((row, index) => (
            <ListItem key={index} button onClick={() => select(row)}>
              <ListItemText primary={row.text} />
              {selected && selected.value === row.value && (
                <ListItemIcon style={{ justifyContent: "flex-end" }}>
                  <Check color="primary" />
                </ListItemIcon>
              )}
            </ListItem>
          ))}
        </Box>
      </Box>
    </Popover>,
  ];
};

Index.defaultProps = {
  data: [],
  onClick: () => {},
  value: "",
  hideSearch: false,
  maxRows: 10,
  showAllRows: false,
  anchorOrigin: { vertical: "bottom", horizontal: "left" },
  transformOrigin: { vertical: "top", horizontal: "right" },
};

Index.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideSearch: PropTypes.bool,
  maxRows: PropTypes.number,
  showAllRows: PropTypes.bool,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
};

export default Index;

export const menuCatalogos = [
    {
        id: 'catalogos',
        vc_nombre: 'Catalogos',
        enable: true,
        opciones: [
            {
                id: 'catalogos',
                vc_nombre: 'Catalogos',
                acciones: [
                    {
                        id: 'nuevo',
                        vc_nombre: 'Nuevo',
                        icon: null
                    }
                ]
            },
            {
                id: 'buscador',
                vc_nombre: 'Buscador',
                acciones: [
                    {
                        id: 'nuevo',
                        vc_nombre: 'Nuevo',
                        icon: null
                    }
                ]
            },
            {
                id: 'editor',
                vc_nombre: 'Editor',
                acciones: [
                    {
                        id: 'guardar',
                        vc_nombre: 'Guardar',
                        icon: null
                    },
                    {
                        id: 'limpiar',
                        vc_nombre: 'Limpiar',
                        icon: null
                    }
                ]
            }
        ]
    }
];

export const menuAgendas = [
    {
        id: 'agendas',
        vc_nombre: 'Agendas',
        sidebar: {
          open: true,
          enable: false
        },
        enable: true
    },
    {
        id: 'configuracion',
        vc_nombre: 'Configuración',
        enable: true,
        sidebar: {
          open: true,
          enable: true
        },
        opciones: [
            {
                id: 'configuracion',
                vc_nombre: 'Configuracion',
                acciones: [
                    {
                        id: 'guardar',
                        vc_nombre: 'Guardar',
                        icon: null
                    },
                ]
            },
            {
                id: 'listado',
                vc_nombre: 'Listado',
                acciones: [
                    {
                        id: 'guardar',
                        vc_nombre: 'Guardar',
                        icon: null
                    },
                ]
            }
        ]
    },
  ];

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import MIcon from '@material-ui/core/Icon';

// Material UI
import { styled } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const EstudioItem = styled(Paper)({
  border: 0,
  borderRadius: 5,
  padding: '5px',
  marginRight: '10px',
  marginBottom: '10px',
  float:'left',
  border: '1px solid transparent',
  '& .icon': {
    marginLeft: 10,
    float: 'right',
    color: 'gray',
    
    '&.delete': {
        display: 'none',
        cursor: 'pointer',
        color :window.colors.red,
      },
  },

  '&:hover': {
    '& .icon': {
        display: 'none',
        '&.delete': {
            display: 'block',
        }
    },
  },
  '&.selected': {
    borderColor:window.colors.itemSelected,
  },
  '&.normal .icon': {
    color:window.colors.itemSelected,
  },
  '&.urgente .icon': {
    color:window.colors.yellow,
  },
  '&.inmediata .icon': {
    color:window.colors.red,
  },
});

class EstudiosItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hoverIndex: null,
            hoverDelete: false,
            indexSeleccionado: null,
            estudio: {
                id: null,
                id_prioridad: null,
                vc_observaciones: null,
                estudio: null,
                detalles: []
            }
        };
    }
    selectItem() {
        if ( this.state.hoverDelete == false ) {
            if ( this.props.index == this.props.indexSeleccionado && this.props.indexSeleccionado != null ) {
                this.props.callbackSelect({ opcionesModuloNext: 'agregar', sn_seleccionado: 0, indexSeleccionado: this.props.index});
            }else{
                this.props.callbackSelect({ opcionesModuloNext: 'prioridad', sn_seleccionado: 1, indexSeleccionado: this.props.index});
            }
        }
    }
    deleteItem(item) {
        this.props.callbackDelete({ opcionesModuloNext: 'agregar', sn_seleccionado: 0, indexSeleccionado: null, item });
    }
    timeSince(refDate, date) {

        var seconds = Math.floor((refDate - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
        return interval + " años";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
        return interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
        return interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
        return interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
        return interval + " minutos";
        }
        return "un instante";
    }
    statusDxToString(id_estatusDiagnostico){
        switch (id_estatusDiagnostico) {
            case 1:
            return "presuntivo";
            case 2:
            return "definitivo ó confirmado";
            case 3:
            return "descartado";
            default:
            return "presuntivo";
        }
    }
    
    isDxActive(dt_vigencia){
        if ( (new Date(dt_vigencia) < (new Date())) ) {
            return false
        }
        return true
    }
    stringDescriptionDx(item) {
        const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro}).reverse()[0];
        if ( this.isDxActive(lastDetalle.dt_vigencia) ) {
            return "Diagnostico con estatus " + `${this.statusDxToString(lastDetalle.id_estatusDiagnostico)}` + ' actualmente vigente, el cual inició hace ' + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}`
        }else{
            return "Diagnostico con estatus " + `${this.statusDxToString(lastDetalle.id_estatusDiagnostico)}` + ", el cual inició hace " + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` + ' con un periodo de convalecencia registrado de '+ `${this.timeSince(new Date(lastDetalle.dt_vigencia), new Date(lastDetalle.dt_inicio))}` + ', actualmente no se encuentra vigente ó la convalecencia ha terminado.'
        }
    }
    colorDx(lastDetalle) {
        if ( lastDetalle.id != null) {
            if ( this.isDxActive(lastDetalle.dt_vigencia) ) {
                return window.colors.green;
            }else{
                return window.colors.red;
            }
        }else{
            return window.colors.itemSelected;
        }
    }
    iconTipoEstudio(item) {
        if ( item.id_tipoEstudio ) {
            switch ( item.id_tipoEstudio ) {
                case 1:
                return 'lab'
                break;
                case 2:
                return 'id badge'
                break;
                case 3:
                return 'lab'
                break;
            }
        }else{
            return 'cubes'
        }
    }
    render() {
        const { date,sn_printVersion, item, index } = this.props;
        const shadow = this.state.hoverIndex == index ? '1px 1px 20px rgba(0, 0,0, 1)' : '1px 0px 10px rgba(0, 0,0, 0.7)';

        if ( sn_printVersion ) {

            return (
                <div style={{
                    color: 'black',
                    padding:5,
                    marginRight:8,
                    marginBottom:8,
                    border: '1px solid black',
                    borderRadius: 5,
                    fontSize: 10 ,
                    float:'left',
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'baseline',
                    whiteSpace: 'nowrap',
                }}>
                    
                    <MIcon style={{color:window.colors.yellow, marginRight: '10px', display: item.id_prioridad == 2 ? 'block' : 'none' }}>error</MIcon>
                    <MIcon style={{color:window.colors.red, marginRight: '10px', display: item.id_prioridad == 3 ? 'block' : 'none'}}>warning</MIcon>

                    {item.estudio ? (item.estudio.vc_nombre != null ? item.estudio.vc_nombre : item.estudio.vc_nombre) : ( item.grupo ? (item.grupo.vc_nombre != null ? item.grupo.vc_nombre : item.grupo.vc_nombre) : '')}
                </div>
            );
        }else if ( !sn_printVersion ){
            let classes = classNames({
                'selected': this.props.indexSeleccionado == index,
                'normal':item.id_prioridad == 1,
                'urgente':item.id_prioridad == 2,
                'inmediata':item.id_prioridad == 3,
            });

            return (
                <EstudioItem key={index} onClick={() => this.selectItem() } className={classes}>

                    {this.getTitulo(item)}
                    
                    <Icon name={this.iconTipoEstudio(item)}/>
                    <Icon name='delete' onClick={(e) => {
                        e.stopPropagation();
                        this.deleteItem(item)
                    }}/>
                </EstudioItem>
            );
        }
    }

    getTitulo(item){
        return item.estudio ? (item.estudio.vc_abreviatura != null ? item.estudio.vc_abreviatura : item.estudio.vc_nombre) : ( item.grupo ? (item.grupo.vc_abreviatura != null ? item.grupo.vc_abreviatura : item.grupo.vc_nombre) : '');
    }
}


const stylesEstudios = {
    container: {
        padding:2,
        float: 'left'

    },
    title:{
        backgroundColor: "white",
        color:window.colors.black,
        padding:5,
        borderRadius: 3,
    }
};


const mapStateToProps = (state) => {
  const { diagnosticos } = state.Think;
  return { diagnosticos };
};


export default connect(mapStateToProps)(EstudiosItem);

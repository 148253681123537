import { SELECT_MODULE } from '../actions/types';


export default (state, INITIAL_STATE, action) => {
    switch (action.type) {
		case SELECT_MODULE:
			return { ...state, currentModule: { ...state.currentModule, name: action.payload } };
		default:
			return null;
	}
};

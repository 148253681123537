import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

class FieldsetGroup extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <Grid container spacing={1}>
        {React.Children.map(children, (child, index) => {
          const xs = child.props.fullWidth || child.props.fullwidth == 'true' || this.props.fullWidth ? 12 : 6;
          return (
            <Grid key={index} item xs={xs}>
              {child}
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default FieldsetGroup;
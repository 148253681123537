import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IconButton, Input } from '@material-ui/core';
import ClearSharp from '@material-ui/icons/ClearSharp'
import DoneAllRounded from '@material-ui/icons/DoneAllRounded'
const useStyles = makeStyles(theme => ({
    outlinedInput: {
        padding: '8px 14px'
    },
    inputLabel: {
        transform: 'translate(14px, 10px) scale(1)'
    }
}));
export default props => {
    const [value, setValue] = React.useState(0);
    const [prevValueProps, setPrevValueProps] = React.useState(null);
    useEffect(() => {
        if (props.value != value.value && props.value != prevValueProps) {
            if (props.value !== null) {
                setPrevValueProps(props.value)
                setValue(props.value)
            }
        }
    }, [props])

    return (
        <IconButton key={props.fieldid} size='small' onClick={(e) => {
            setValue(value ? 0 : 1)
            props.delete && props.delete(e)
            e.stopPropagation();
        }} style={{marginLeft: 10}}>
            <Input hidden id={props.fieldid} value={value} style={{position: 'absolute', width:0, height: 0}}/>
            {
                value === 1 ?
                <DoneAllRounded color='primary'/>
                :
                <ClearSharp color='error'/>
            }
        </IconButton>
    )
};
import React from "react";
import ReactDOM from "react-dom";
import {
  Box,
  IconButton,
  Typography,
  Paper,
  Button,
  Divider,
  Badge,
  makeStyles,
} from "@material-ui/core";
import { Menu, Dropdown } from "semantic-ui-react";
import moment from "moment";
import { teal } from "@material-ui/core/colors";
import repository from "app/utils/repository";
import classNames from "classnames";
const sidebarWidth = window.sidebarWidth;
const daySize = (sidebarWidth - 4) / 7;

const useStyles = makeStyles((theme) => {
  return {
    button: {
      minWidth: daySize,
      minHeight: daySize,
      border: 0,
      borderRadius: 0,
      cursor: "pointer",
      display: "inline-flex",
      color: theme.palette.text.primary,
      padding: 8,
      justifyContent: "center",
      alignItems: "center",
    },
    dia: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      alignSelf: "stretch",
      justifySelf: "stretch",
      "& .daybackground": {
        position: "absolute",
        top: "-4px",
        left: "-4px",
        right: "-4px",
        bottom: "-4px",
        borderRadius: "50%",
      },
      "&:hover .daybackground": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.selected .daybackground": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  };
});

const colorCitas = (nu_disponibles) => {
  if (nu_disponibles >= 3) {
    return window.colors.green;
  } else if (nu_disponibles >= 1) {
    return window.colors.yellow;
  } else {
    return window.colors.red;
  }
};

const ButtonDay = (props) => {
  const classes = useStyles();
  const { style = {}, opacity = 1, ...rest } = props;
  return (
    <div
      className={classes.button}
      {...rest}
      style={{
        opacity,
        ...style,
      }}
    >
      {props.children}
    </div>
  );
};

const Day = (props) => {
  const classes = useStyles();
  const { day, selected = false } = props;

  const classesDay = classNames(classes.dia, { selected });

  return (
    <div className={classesDay}>
      <div className="daybackground" />
      <Typography
        style={{
          border: "1px solid transparent",
          position: "relative",
          // borderBottomColor: colorCitas(day.nu_disponibles),
          fontSize: 12,
        }}
      >
        {day.nu_citas > 0 && (
          <Badge
            badgeContent={day.nu_citas}
            variant="dot"
            style={{ left: "50%", top: "-4px", position: "absolute" }}
            color={"primary"}
          >
            {" "}
          </Badge>
        )}

        {moment(day.dt_inicio).format("D")}
      </Typography>
    </div>
  );
};

export default (props) => {
  const { date: dia = "", dates = [], data = {} } = props;
  const date = moment(dia);
  const anoActual = date.format("YYYY") * 1;
  const anos = [
    anoActual - 2,
    anoActual - 1,
    anoActual,
    anoActual + 1,
    anoActual + 2,
  ];
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const dias = ["D", "L", "M", "M", "J", "V", "S"];

  const days = repository.getCalendarLayout(date.format("YYYY-MM-DD")) || [];
  const { daysOfCalendar = days } = data;

  const changeMonthCalendar = (index) => {
    const month = index < 9 ? `0${index + 1}` : `${index + 1}`;

    if (date.format("MM") != month) {
      const dateString = `${date.format("YYYY")}-${month}-01`;
      props.onChangeDate(dateString);
    }
  };

  const changeYearCalendar = (item) => {
    if (date.format("YYYY") != item) {
      const dateString = `${item}-${date.format("MM")}-01`;
      props.onChangeDate(dateString);
    }
  };

  return (
    <Box>
      <Menu style={{ backgroundColor: "transparent", margin: 0 }}>
        <Dropdown
          item
          text={date.format("MMMM")}
          style={{
            textTransform: "capitalize",
            maxWidth: "40%",
            width: "40%",
            height: 40,
            textAlign: "center",
            lineHeight: 3,
            float: "right",
            color: "gray",
          }}
        >
          <Dropdown.Menu
            style={{
              width: "100%",
              overflowY: "scroll",
              maxHeight: 400,
              color: teal,
            }}
          >
            {meses.map((item, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => changeMonthCalendar(index)}
                >
                  {item}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item
          size="large"
          onClick={() => props.onChangeDate(moment().format('YYYY-MM-DD'))}
          style={{
            fontSize: 13,
            padding: 10,
            maxWidth: "20%",
            height: 40,
            width: "20%",
            color: "gray",
          }}
        >
          Hoy
        </Menu.Item>
        <Dropdown
          item
          text={date.format("YYYY")}
          style={{
            width: "40%",
            maxWidth: "40%",
            height: 40,
            textAlign: "center",
            lineHeight: 3,
            float: "right",
            color: "gray",
          }}
        >
          <Dropdown.Menu
            style={{ width: "100%", overflowY: "scroll", maxHeight: 400 }}
          >
            {anos.map((item, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => changeYearCalendar(item)}
                >
                  {item}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
      <Divider key={"divider1"} />
      <div style={{ width: sidebarWidth }}>
        {dias.map((d, i) => (
          <ButtonDay key={i} opacity={0.4}>
            <span style={{ alignSelf: "center", justifySelf: "center" }}>
              {d}
            </span>
          </ButtonDay>
        ))}
      </div>
      <Divider key={"divider2"} />
      <Paper key={"calendar"} style={{ padding: 2, width: sidebarWidth }}>
        {daysOfCalendar.map((day, index) => {
          // const isSelected = day.dt_inicio === date.format("YYYY-MM-DD");
          const isSelected = dates.includes(day.dt_inicio);
          return (
            <ButtonDay
              key={index}
              onClick={() => props.onChangeDate(day.dt_inicio)}
              opacity={day.sn_mesActual == 1 ? 1 : 0.4}
            >
              <Day key={index} day={day} selected={isSelected} />
            </ButtonDay>
          );
        })}
      </Paper>
    </Box>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  DialogContentText,
} from "@material-ui/core";

import repository from "app/utils/repository";
// import ConfirmAlert from "app/material-ui/ConfirmAlert";
import ConfirmAlert from "app/agenda/components/ConfirmDialog";
import Paciente from "app/components/Paciente/PacienteSidebar";
import api from "app/agenda/api";

import { setPacienteActual } from "app/containers/Think/actions";
import eventManager from "app/utils/eventManager";
import { START_VIEW_TRANSITION } from "app/constants";

import Input from "app/components/Form/Input";

const Index = (props) => {
  const { selected, onActionCallback } = props;
  const [confirmAlert, setConfirmAlert] = React.useState({
    title: "",
    open: false,
    callback: () => {},
  });
  const [motivo, setMotivo] = React.useState("");

  const cambiarEstatus = (accion, id_estatusCita) => {
    const nombrePaciente = `${selected.vc_nombre} ${selected.vc_apellidoPaterno} ${selected.vc_apellidoMaterno}`;
    setMotivo("");
    setConfirmAlert({
      open: true,
      title: `¿Desea ${accion} la cita del paciente: ${nombrePaciente}?`,
      callback: async (vc_observaciones) => {
        await api.changeStatus({
          ...selected,
          id_estatusCita,
          vc_observaciones,
        });
        setConfirmAlert((prev) => ({ ...prev, open: false }));
        onActionCallback();
      },
    });
  };

  const botones = [
    {
      text: "Consultar",
      validate: repository.validateOptionsRol("consultar"),
      onClick: async () => {
        const paciente = await api.expediente(selected);

        props.setPacienteActual(paciente);
        eventManager.emit(START_VIEW_TRANSITION, "#/admin/notas");
      },
      disabled: false,
    },
    {
      text: "Formularios",
      validate: repository.validateOptionsRol("formularios"),
      onClick: () => {},
      disabled: true,
    },
    {
      text: "Cancelar cita",
      validate: repository.validateOptionsRol("cancelar cita"),
      onClick: () => cambiarEstatus("cancelar", 7),
      disabled: false,
      validStatus: [1, 2, 8],
    },
  ]
    .filter((v) => v.validate)
    .filter(
      (v) => !v.validStatus || v.validStatus.includes(selected.id_estatusCita)
    );

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <AppBar
        position="relative"
        style={{
          height: 95,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Typography style={{ textAlign: "center", width: "100%" }}>
            Paciente
          </Typography>
        </Toolbar>
      </AppBar>
      <Box flex={1} display="flex" flexDirection="column">
        <Paciente
          paciente={selected}
          onChange={(url) => {
            console.log('LAUEREELE', url);
            onActionCallback();
          }}
        />
        <Box paddingX={1} height="0px" overflow="scroll" flex="1 1 auto">
          {botones.map((a, i) => (
            <Box mb={1} key={i}>
              <Button
                key={i}
                variant="outlined"
                color="primary"
                fullWidth
                disabled={a.disabled}
                onClick={a.onClick}
              >
                {a.text}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>

      <ConfirmAlert
        open={confirmAlert.open}
        title={confirmAlert.title}
        accept={() => confirmAlert.callback(motivo)}
        close={() => setConfirmAlert((prev) => ({ ...prev, open: false }))}
      >
        <DialogContentText style={{ margin: 0 }}>Motivo</DialogContentText>
        <Input
          multiline
          rows={2}
          rowsMax={2}
          value={motivo}
          autoFocus
          onChange={(value) => setMotivo(value)}
        />
      </ConfirmAlert>
    </Box>
  );
};

Index.defaultProps = {
  selected: {},
  onActionCallback: () => {},
};

Index.propTypes = {
  selected: PropTypes.object,
  setPacienteActual: PropTypes.func,
  onActionCallback: PropTypes.func,
};

const actions = { setPacienteActual };

const mapStateToProps = (state) => {
  const { especialidades } = state.Think;

  return {
    especialidades,
  };
};

export default connect(mapStateToProps, actions)(Index);

import React from "react";
import { connect } from "react-redux";
import QrReader from "react-qr-reader";

import internalAPI from "app/utils/internalAPI";
import repository from "app/utils/repository";
import notifications from "app/utils/notifications";

import ThinkForm from "app/material-ui/ThinkForm";
import CreateButton from "app/material-ui/ThinkForm/CreateButton";
import ConfirmAlert from "app/material-ui/ConfirmAlert";

import {
  ThemeProvider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  Button,
  Box,
  Divider,
  FormControlLabel,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  CircularProgress
} from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { teal } from "@material-ui/core/colors";
// import termsText from "./terms";
import moment from "moment";

const themeSelected = window.amplify.store("theme") || null;
const themeBase = createMuiTheme({
  overrides: {
    MuiCardHeader: {
      root: {
        position: "relative",
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: "4px",
        marginBottom: "8px",
      },
      root: {
        width: "100%",
        marginTop: "4px",
        marginBottom: "8px",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "8px 14px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 10px) scale(1)",
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: "4px 0px 0",
      },
    },
    MuiDrawer: {
      paper: {
        // backgroundColor: grey
      },
    },
    MuiFormHelperText: {
      root: {
        position: "absolute",
        bottom: "-4px",
        left: "-14px",
        color: "#f3564b",
        width: "100%",
      },
      contained: {
        margin: "0px 14px 0",
      },
    },
    MuiInputBase: {
      root: {
        marginBottom: "10px",
      },
    },
  },
  palette: {
    primary: themeSelected ? themeSelected.primary : teal,
    secondary: {
      main: "#fafafa",
    },
    type: "light",
  },
  MuiExpansionPanelSummary: {
    root: {
      "&$expanded": {
        boxShadow: "0 1px 12px 0 rgba(0,0,0,.11)",
      },
    },
  },
});
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const savePatient = (px, sn_enlace) => {
  const { id_institucion } = window.amplify.store("institution") || {};
  const id_usuario = window.amplify.store("user_id") || 0;

  const endPoint = px.detalle.id
    ? internalAPI.editPatients
    : internalAPI.savePatient;

  return new Promise((resolve, reject) => {
    const body = {
      ...px.detalle,
      id: px.detalle.id || null,
      id_institucion,
      id_usuario,
      sn_enlace,
    };

    endPoint(body, (error, response) => {
      if (error) reject(error?.texto || "Ocurrio un error al guardar paciente");
      else resolve(response);
    });
  });
};

const Index = ({
  open,
  close,
  catalogo,
  catalogos,
  init = "intro",
  finish,
  ...props
}) => {
  const [functions, setFunctions] = React.useState(null);
  const termsText = "";

  const [buttonHelper, setButtonHelper] = React.useState({
    text: "",
    action: () => {},
  });
  const [registro, setRegistro] = React.useState({ id: null, detalle: {} });
  const [type, setType] = React.useState(init);
  const [help, setHelp] = React.useState("");
  const [actions, setActions] = React.useState([]);
  const [content, setContent] = React.useState(null);
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modalNotificacion, setModalNotificacion] = React.useState({
    open: false,
    title: null,
    message: null,
  });

  const handleScan = (data) => {
    if (data) {
      const curpInfo = data.split("|");
      const [
        vc_curp,
        a,
        vc_apellidoPaterno,
        vc_apellidoMaterno,
        vc_nombre,
        vc_sexo,
        dt_nacimiento,
        endidad,
        id_entidad,
      ] = curpInfo;

      if (["HOMBRE", "MUJER"].includes(vc_sexo)) {
        const params = {
          id: null,
          detalle: {
            vc_curp,
            vc_nombre,
            vc_apellidoPaterno,
            vc_apellidoMaterno,
            id_sexo: vc_sexo === "HOMBRE" ? 1 : 2,
            dt_nacimiento: moment(dt_nacimiento, "DD/MM/YYYY").format(
              "YYYY/MM/DD"
            ),
            id_entidad: Number(id_entidad),
          },
        };

        if (functions) functions.setRegistro && functions.setRegistro(params);

        setRegistro(params);
        setType("form");
      }
    }
  };
  const handleError = (err) => {
    setType("form");
  };

  const guardar = (enlace = false) => {
    const px = functions.getRegistro();

    const errors = repository.validationBody(px, catalogo, catalogos);
    if (errors.noNulls.length || errors.noSuccess.length) {
      return showErrorsForm(errors);
    }

    setLoading(true);
    savePatient(px, enlace)
      .then((res) => {
        if (res.peticion) {
          notifications.success(
            px.detalle.id
              ? "El paciente se editó correctamente"
              : "El paciente se guardó correctamente"
          );
          finish && finish(res.paciente)
          close();
        } else {
          const { vc_nombre, vc_curp, vc_email } = res.paciente;
          setModalNotificacion({
            open: true,
            title: `Paciente ${vc_nombre} ¡Ya es parte de Thin-k!`,
            message: vc_email
              ? `¿Desea enlazar la cuenta con el correo: ${vc_email}?`
              : `Se enlazará la cuenta al CURP: ${vc_curp}`,
            onCancel: () =>
              setModalNotificacion((prev) => ({ ...prev, open: false })),
            onAccept: () => {
              setModalNotificacion((prev) => ({ ...prev, open: false }));
              guardar(true);
            },
          });
        }
      })
      .catch((message) =>
        setModalNotificacion({ open: true, title: "¡Ups!", message })
      )
      .finally(() => setLoading(false));
  };

  const showErrorsForm = (errors) => {
    setModalNotificacion({
      open: true,
      title: errors.title,
      message: (
        <Box>
          {!!errors.noNulls.length && (
            <Box>
              <Typography>No deben estar vacios</Typography>
              <Divider />
              <List dense>
                {errors.noNulls.map((v, i) => (
                  <ListItem key={i}>
                    <ListItemText primary={v} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {!!errors.noSuccess.length && (
            <Box>
              <Typography>Formato invalido</Typography>
              <Divider />
              <List dense>
                {errors.noSuccess.map((v, i) => (
                  <ListItem key={i}>
                    <ListItemText primary={v} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
      ),
    });
  };

  // OPEN
  React.useEffect(() => {
    setType(init);
    setRegistro({ id: null, detalle: {} });
    setAcceptTerms(true);
  }, [open]);

  // ACTIONS
  React.useEffect(() => {
    switch (type) {
      case "qr":
        setActions([
          <Button onClick={() => setType("form")} color="primary">
            {"Lo hare manual"}
          </Button>,
        ]);
        break;
      case "form":
        setActions([
          <Button onClick={() => setType("qr")} color="primary">
            Leer CURP
          </Button>,
          <Button
            onClick={() => guardar()}
            color="primary"
            endIcon={loading && <Box><CircularProgress color="inherit" size={15}/></Box>}
          >
            Guardar
          </Button>,
          // functions && functions.getRegistro && (
          //   <CreateButton
          //     disabled={!acceptTerms}
          //     color="primary"
          //     getRegistro={functions.getRegistro.bind(this)}
          //     catalogo={catalogo}
          //     saveComplete={() => {
          //       finish && finish(functions.getRegistro());
          //       close();
          //     }}
          //   />
          // ),
        ]);
        break;
      default:
        setActions([
          <Button key={"curp"} onClick={() => setType("qr")} color="primary">
            Tengo a la mano mi CURP
          </Button>,
          <Button
            key={"manual"}
            onClick={() => setType("form")}
            color="primary"
          >
            Lo hare manual
          </Button>,
        ]);
        break;
    }
  }, [type, functions, acceptTerms, catalogo, registro]);

  // CONTENT
  React.useEffect(() => {
    switch (type) {
      case "qr":
      case "terms":
        setButtonHelper({ text: "Atrás", action: () => setType("form") });
        break;
      default:
        setButtonHelper({ text: "Salir", action: close });
        break;
    }

    switch (type) {
      case "intro":
        setHelp(
          "Para iniciar con su consulta es necesario que cree una cuenta, puede hacerlo con su CURP de manera automatica, ó con el formulario."
        );
        setContent(null);
        break;
      case "qr":
        setHelp("Coloque el código QR frente a su cámara por favor.");
        setContent(
          <Box position="relative">
            <QrReader
              showViewFinder={false}
              delay={0}
              onError={handleError}
              onScan={handleScan}
              style={{
                width: "100%",
                margin: 0,
                padding: 0,
                borderRadius: 0,
                overflow: "hidden",
              }}
            />
            <Box position="absolute" p={4} top={0} width="100%" height="100%">
              <Box height="100%" border="2px solid rgba(20,20,20,0.2)" />
            </Box>
          </Box>
        );

        break;
      case "form":
        setHelp("Cápture sus datos por favor.");
        setContent(
          <Box>
            <Box height="calc(100vh - 300px)" bgcolor="rgba(200,200,200,0.1)">
              <Divider />

              <ThinkForm
                fullWidth
                type="form"
                setFunctions={(functions) => {
                  setFunctions(functions);
                }}
                withOutHeader
                tags={[]}
                registro={registro}
                catalogo={catalogo}
              />

              <Divider />
            </Box>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTerms}
                  onChange={() => {
                    setAcceptTerms(!acceptTerms);
                    if (type === "terms" && !acceptTerms) setType("form");
                  }}
                  name="acceptTerms"
                  color="primary"
                />
              }
              label={
                <div>
                  Acepto{" "}
                  <a
                    onClick={(e) => {
                      setType("terms");
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    terminos y condiciones
                  </a>
                </div>
              }
              labelPlacement="end"
              style={{ padding: 16 }}
            />
            <Divider /> */}
          </Box>
        );
        break;
      case "terms":
        setHelp("Términos y condiciones.");
        setContent(
          <Box>
            <Divider />
            <Box
              p={2}
              fontSize="1rem"
              textAlign="justify"
              overflow="scroll"
              whiteSpace="pre-wrap"
              height="calc(100vh - 300px)"
            >
              {termsText}
            </Box>
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTerms}
                  onChange={() => {
                    setAcceptTerms(!acceptTerms);
                    if (type === "terms" && !acceptTerms) setType("form");
                  }}
                  name="acceptTerms"
                  color="primary"
                />
              }
              label={
                <div>
                  Acepto{" "}
                  <a
                    onClick={(e) => {
                      setType("terms");
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    terminos y condiciones
                  </a>
                </div>
              }
              labelPlacement="end"
              style={{ padding: 16 }}
            />
            <Divider />
          </Box>
        );
        break;
    }
  }, [type, acceptTerms, catalogo, registro]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={close}
      keepMounted
    >
      <ThemeProvider theme={themeBase}>
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              Registro de Paciente Thin-k Health
            </Box>
            <Button onClick={buttonHelper.action} color="primary">
              {buttonHelper.text}
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent style={{ minWidth: "400px" }}>
          <DialogContentText>{help}</DialogContentText>
        </DialogContent>
        {content}
        {actions.length && <DialogActions>{actions}</DialogActions>}
        {modalNotificacion.open && (
          <ConfirmAlert
            title={modalNotificacion.title}
            message={modalNotificacion.message}
            onCancel={modalNotificacion.onCancel}
            onAccept={() =>
              modalNotificacion.onAccept
                ? modalNotificacion.onAccept()
                : setModalNotificacion((prev) => ({ ...prev, open: false }))
            }
          />
        )}
      </ThemeProvider>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { catalogos } = state.Think;

  return {
    catalogos,
    catalogo: catalogos.find((e) => e.vc_nameTable === "ins_pacientes"),
  };
};
export default connect(mapStateToProps, [])(Index);

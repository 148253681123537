import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { FadeInView } from './FadeInView';


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    marginTop: 20,
    borderBottom: '1px solid rgb(220,220,220)'
  },
  icon: {
    color: 'rgb(170,170,170)',
    cursor: 'pointer'
  }
};


class Acordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: null
    };
  }

  onItemPressed(item) {
    const { selectedItem } = this.state;

    if (item.id === selectedItem) {
      this.setState({ selectedItem: null });
    } else {
      this.setState({ selectedItem: item.id });
    }
  }

  renderItems() {
    const { items, renderItem } = this.props;

    return items.map((item) => {
      let content;
      const currentItemSelected = this.state.selectedItem === item.id;

      if (currentItemSelected) {
        content = (
          <FadeInView>
            { renderItem(item) }
          </FadeInView>
        );
      }

      return (
        <div key={item.id}>
          <div style={styles.container}>
            <p>{ item.vc_nombre }</p>

            <Icon
              name={currentItemSelected ? 'chevron up' : 'chevron down'}
              size='large'
              style={styles.icon}
              onClick={() => { this.onItemPressed(item); }}
            />
          </div>

          { content }
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        { this.renderItems() }
      </div>
    );
  }
}


Acordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func,
};


export { Acordion };

/* eslint-disable */
import React, { useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import Unity, { UnityContent } from 'react-unity-webgl';
import { Slider, makeStyles, IconButton, Box, SvgIcon, Divider, ListItem, List, Avatar } from '@material-ui/core';
import { FullscreenExitOutlined, VisibilityOffOutlined, FilterCenterFocusOutlined, ThreeDRotationOutlined, AddAPhotoOutlined } from '@material-ui/icons';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import styled from 'styled-components';
import { teal } from '@material-ui/core/colors';
const themeSelected = amplify.store('theme') || null;
const unityContent = new UnityContent(
  'unity/female/3D.json',
  // 'https://firebasestorage.googleapis.com/v0/b/thin-k-sockets.appspot.com/o/3D.data.unityweb?alt=media&token=ed42c67f-aaef-452e-8971-773fb82f58ab'
  'unity/female/UnityLoader.js'
);
const useStyles = makeStyles({
  root: {
    height: 300,
  },
});
const Mask = styled('div')`
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  padding: 0px;
  background-color: red;
  background-size: '30px 30px';
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + 'assets/images/cerebro.svg'});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: 'center';
`;
export default ({id_sexo = 1}) => {

  const classes = useStyles();
  const handle = useFullScreenHandle();
  const [mode, setMode] = useState({ id: 1, icon: ThreeDRotationOutlined, description: 'Rotacion y selección 3D' })
  const [loaded, setLoaded] = useState(false);
  const [systems, setSystems] = useState([
    { id: 1, src: 'atras.svg', description: 'Piel y tegumentos', alpha: 1 },
    { id: 2, src: 'cerebro.svg', description: 'Nervioso', alpha: 1 },
    { id: 3, src: 'globo-del-ojo.svg', description: 'Ocular', alpha: 1 },
    { id: 4, src: 'pulmon.svg', description: 'Respiratorio', alpha: 1 },
    { id: 5, src: 'estomago.svg', description: 'Digestivo', alpha: 1 },
    { id: 6, src: 'tiroides.svg', description: 'Endrocrino', alpha: 1 },
    { id: 7, src: 'rinones.svg', description: 'Urinario', alpha: 1 },
    { id: 8, src: 'cerebro.svg', description: 'Reproductor', alpha: 1 },
    { id: 9, src: 'corazon.svg', description: 'Vascular', alpha: 1 },
    { id: 10, src: 'ganglios-linfaticos.svg', description: 'Linfatico', alpha: 1 },
    { id: 11, src: 'pelvis.svg', description: 'Oseo y conectivo', alpha: 1 },
  ])
  const defaultColor = themeSelected ? themeSelected.primary[500] : teal[500];
  const [fs, sfs] = useState(false);
  const [errorWarn, setError] = useState(false);
  const [ref, { height, width }] = useMeasure();
  const modes = [
    { id: 1, icon: ThreeDRotationOutlined, description: 'Rotacion y selección 3D' },
    { id: 2, icon: FilterCenterFocusOutlined, description: 'Enfocar' },
    { id: 3, icon: VisibilityOffOutlined, description: 'Ocultar/Disecar' },
    { id: 4, icon: AddAPhotoOutlined, description: 'Image' }
  ]

  useEffect(() => {
    window.addEventListener('error', (e) => {
      setError(e);
    });
    // const sex = id_sexo === 1 ? 'male' : 'female';
    // unityContent.buildJsonPath = `unity/${sex}/render.json`;
    // unityContent.unityLoaderJsPath = `unity/${sex}/UnityLoader.js`;
  });
  useEffect(() => {
    unityContent.on("SelectCallback", (f) => {

      // Now we can use the score to for example
      // display it on our React app.

      console.log('point', f);
    });
    unityContent.on("GameOver", (f) => {

      // Now we can use the score to for example
      // display it on our React app.
      console.log('score', f);
    });
    unityContent.on("loaded", () => {

      // Now we can for example hide the loading overlay.
      console.log('Cargado');
      setLoaded(true);

    });
    unityContent.on('Say', (message) => {
      console.log({ message });
    });
  })

  useEffect(() => {
    unityContent.send('Camera', 'setMode', mode.id);
  }, [mode])
  return (
    <div ref={ref} style={{ position: 'relative', height: '100%', width: '100%' }}>
      <FullScreen handle={handle}>
        <div style={{ position: 'relative', height: handle.active ? '100%' : height, width: handle.active ? '100%' : width }}>
          {
            !errorWarn &&
            <Unity unityContent={unityContent} style={{ position: 'absolute', height: '100%', width: '100%' }} />
          }
          <div
            style={{ position: 'absolute', top: 8, right: 8, width: 40 }}
          >
            <List style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: 5, boxShadow: '0px 0px 5px black', padding: 0, marginBottom: 8 }}>
              <ListItem key={'fs'} button selected={handle.active} onClick={handle.active ? handle.exit : handle.enter} style={{ justifyContent: 'center', height: 40 }}>
                <FullscreenExitOutlined />
              </ListItem>
              <Divider />
              {
                modes.map((item, index) => {
                  const Icon = item.icon;
                  return (
                    <ListItem key={index} button selected={item.id === mode.id} onClick={() => setMode(item)} style={{ justifyContent: 'center', backgroundColor: item.id === mode.id ? defaultColor : 'transparent', }}>
                      <Icon />
                    </ListItem>
                  )
                })
              }
              <Divider />
            </List>
            <List style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: 5, boxShadow: '0px 0px 5px black', padding: 0, overflowX: 'scroll' }}>
              {
                systems.map((item, index) => {
                  const Mask = styled('div')`
                    box-sizing: border-box;
                    height: 30px;
                    width: 30px;
                    padding: 0px;
                    background-color: red;
                    background-size: '30px 30px';
                    background-position: center;
                    background-repeat: no-repeat;
                    mask-size: contain;
                    mask-image: url(${process.env.PUBLIC_URL + 'assets/images/' + item.src});
                    mask-repeat: no-repeat;
                    mask-position: center;
                    align-self: 'center';
                  `;
                  return (
                    <ListItem key={index} onClick={() => {
                      const systemsE = [...systems];
                      systemsE[index].selected = !item.selected;
                      setSystems(systemsE);
                    }} button style={{ justifyContent: 'center', height: 40, width: 40 }}>
                      <div style={{ height: 40, width: 40, padding: 5 }}>
                        <Mask style={{ backgroundColor: item.selected ? defaultColor : 'white' }} />
                        <Divider />
                      </div>
                    </ListItem>
                  )
                })
              }
              <ListItem key={'demo'} onClick={() => {
                unityContent.send('Camera', 'setPoint', ["point", "delete"]);
              }} button style={{ justifyContent: 'center', height: 40, width: 40 }}>
                <div style={{ height: 40, width: 40, padding: 5 }}>
                  <Mask style={{ backgroundColor: 'white' }} />
                  <Divider />
                </div>
              </ListItem>
            </List>
          </div>
        </div>
      </FullScreen>
    </div>
  );
};

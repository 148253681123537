import React, { Component } from 'react';
import transitions from '../utils/transitions';

const INITIAL_VALUE = 0;
const FINAL_VALUE = 1;


class SlideSideView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valueTransition: INITIAL_VALUE
        };
    }

    componentDidMount() {
        const delay = this.props.delay || 0;
        const params = {
            initialValue: INITIAL_VALUE,
            finalValue: FINAL_VALUE,
            duration: this.props.duration || 500,
            width: "0%"
        };

        setTimeout(() => {
            transitions.easePolyOut(params, (response) => {
                this.setState({ valueTransition: response.value });
            });
        }, delay);
    }

    render() {
        const { valueTransition } = this.state;
        const w = `${valueTransition * 100}` + "%"
        const styles = {
            width: w,
            height:"100%",
            float:"right",
            margin:0,
            padding:0
        }
        return (
            <div style={styles}>
                { this.props.children }
            </div>
        );
    }
}


export { SlideSideView };

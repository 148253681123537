import http from '../http';
import httpAdmin from '../httpAdmin';

export default {
    getInstitucionLicencias(callback) {
        const headers = { Authorization: window.amplify.store('token') };
        const institucionId = window.amplify.store('institution').id_institucion;
        httpAdmin.get(`/api/v1/renovaciones?institucionId=${institucionId}`, headers, callback);
    },
    getLicencias(usuarioId, callback) {
    	const headers = { Authorization: window.amplify.store('token') };
    	const institucionId = window.amplify.store('institution').id_institucion;
    	const url = `/api/v1/licencias?institucionId=${institucionId}&usuarioId=${usuarioId}`;

        httpAdmin.get(url, headers, callback);
    },
    getPaymentAccount(usuarioId, callback) {
    	const headers = { Authorization: window.amplify.store('token') };
    	const institucionId = window.amplify.store('institution').id_institucion;
    	const url = `/api/v1/formaPagos?id_institucion=${institucionId}`;
        httpAdmin.get(url, headers, callback);
    }
};

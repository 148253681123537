/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Mic from "@material-ui/icons/Mic";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import HearingOutlined from "@material-ui/icons/HearingOutlined";
import SportsEsportsOutlined from "@material-ui/icons/SportsEsportsOutlined";
import { IconButton, Box, Paper } from "@material-ui/core";
import ThinkResponse from "../../../../../material-ui/ThinkResponse";
import {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setDisplaySidebar,
  setPacienteActual,
} from "../../../actions";
import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";
import ModalUI from "@material-ui/core/Modal";
import GoogleMaps from "../../patients/GoogleMaps";
import Map from "@material-ui/icons/Map";
import moment from "moment";
import TextViewThink from "./TextViewThink";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 8,
    fontSize: theme.typography.pxToRem(20),
    position: "relative",
  },
  textArea: {
    color: theme.palette.type === "dark" ? "white" : "gray",
    border:
      theme.palette.type === "dark"
        ? "1px solid rgba(200,200,200,0.2)"
        : "1px solid rgba(100,100,100,0.2)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightMedium,
    width: "100%",
  },
  InputLabels: {
    fontSize: 30,
  },
}));

function PreguntasTextoUI(props) {
  const { item, index } = props;
  if (!item) return <div />;
  const steps = moment().diff(moment("2020-01-22"), "days").toFixed(0);
  const [prevValueProps, setPrevValueProps] = React.useState(null);
  const [value, setValue] = React.useState("");
  const [showMap, setShowMap] = React.useState(false);
  const [showCode, setShowCode] = React.useState(false);
  const [over, setOver] = React.useState(false);
  const [preview, setPreview] = React.useState("");
  const onChangeValue = (e) => {
    const value = e.target.value || "";
    props.callbackPreg({ value });
  };
  const { speak, cancel, speaking } = useSpeechSynthesis();

  const onchangeInner = (e) => {
    const value = e.target.value || "";
    setValue(value);
  };
  const preguntasEnlace = [];
  if (item.version && item.version.id_preguntaEnlace) {
    preguntasEnlace.push(item.version.id_preguntaEnlace);
  } else if (item.versiones[0].id_preguntaEnlace) {
    preguntasEnlace.push(item.versiones[0].id_preguntaEnlace);
  }
  const renderHelperSideBar = () => {
    return (
      <ThinkResponse
        preguntasEnlace={preguntasEnlace}
        id_preguntaPadre={item.id}
        getDefault={item.id === 364}
        callbackFinish={() => {
          props.callbackFinish && props.callbackFinish();
        }}
      />
    );
  };
  const [voice, setVoice] = React.useState("");
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setVoice(result + " ");
    },
    onEnd: () => {
      setPregunta(voice);
      setVoice("");
    },
  });
  const setPregunta = (textValue) => {
    const pacienteActual = props.pacienteActual;
    const preguntasTexto = (props.pacienteActual.preguntasTexto || []).map(
      (pregunta, index) => {
        if (item.id === pregunta.id) {
          let vc_respuesta = pregunta.respuesta
            ? pregunta.respuesta.vc_respuesta
            : "";
          vc_respuesta = vc_respuesta + textValue;
          const respuestasInternas = pregunta.respuestasInternas || [];
          respuestasInternas.push({ vc_sentencia: textValue });
          return {
            ...pregunta,
            respuesta: { ...(pregunta.respuesta || {}), vc_respuesta },
            respuestasInternas,
          };
        } else {
          return pregunta;
        }
      }
    );
    const newPacienteActual = { ...pacienteActual, preguntasTexto };
    props.setPacienteActual(newPacienteActual);
  };
  const updateHelperSideBar = (e) => {
    e.stopPropagation();
    props.setHelperSidebarRender(renderHelperSideBar);
    props.setDisplaySidebar(true);
  };
  const version = item.versiones[0] || {};

  useEffect(() => {
    const valueReceibed = props.item.respuesta
      ? props.item.respuesta.vc_respuesta
      : "";
    if (valueReceibed != value && valueReceibed != prevValueProps) {
      if (props.value !== null) {
        setPrevValueProps(valueReceibed);
        setValue(valueReceibed);
      }
    }
  }, [props]);
  const start = () => {
    try {
      listen();
    } catch {

    }
  }
  const points = (item.respuestasInternas || []).filter(
    (e) => e.nu_lat && e.nu_lon
  );
  let lastIndex = null;
  const respuestasInternas = item.respuestasInternas || [];
  respuestasInternas.map((input, index) => {
    if (!input.id_pregunta) {
      lastIndex = index;
    }
  });
  if (respuestasInternas[lastIndex]) {
    respuestasInternas[lastIndex] = {
      vc_sentencia: respuestasInternas[lastIndex].vc_sentencia || "" + value,
    };
  } else {
    respuestasInternas.push({ vc_sentencia: value });
  }
  item.respuestasInternas = respuestasInternas;
  return (
    <Box onMouseLeave={() => setOver(false)} onMouseEnter={() => setOver(true)}>
      <Box style={{ height: 40 }}>
        <Box
          style={{
            height: 40,
            flexDirection: "column",
            float: "left",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography
            align="center"
            style={{
              height: 40,
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              verticalAlign: "middle",
            }}
          >
            {item.versiones[0].vc_pregunta}
          </Typography>
        </Box>
        <Box
          style={{
            height: 40,
            flexDirection: "row",
            float: "right",
            alignItems: "center",
            display: "flex",
            verticalAlign: "middle",
          }}
        >
          {version.id_preguntaEnlace && (
            <IconButton
              disableFocusRipple
              size="small"
              onClick={updateHelperSideBar}
              style={{ marginRight: 5 }}
            >
              <SportsEsportsOutlined fontSize="small" />
            </IconButton>
          )}
          {
            points.length > 0 &&
            <IconButton disableFocusRipple size='small' onClick={() => setShowMap(true)} style={{ marginRight: 5 }}>
              <Map fontSize='small' />
            </IconButton>
          }

          <IconButton disableFocusRipple size='small' onClick={() => {
            if (speaking) {
              cancel()
            } else {
              const docu = (document.getElementById(`preguntaTexto:${item.id}`) || {}).innerText || 'No hay texto que decir'
              speak({ text: docu, voice: window.speechSynthesis.getVoices().filter(e => e.lang === 'es-MX')[0] || undefined })
            }
          }} style={{ marginRight: 5 }}>
            <HearingOutlined fontSize='small' />
          </IconButton>
          <IconButton onMouseDown={start} onMouseUp={stop} disableFocusRipple size='small' style={{ marginRight: 5 }}>
            <Mic fontSize='small' />
          </IconButton>
          <IconButton disableFocusRipple size='small' onClick={() => {
            if (showCode) {
              setShowCode(!showCode)
            } else {
              setShowCode(!showCode)
            }
          }} style={{ marginRight: 5 }}>
            {
              showCode ?
                <VisibilityOutlined fontSize='small' /> :
                <VisibilityOffOutlined fontSize='small' />
            }
          </IconButton>
        </Box>
      </Box>
      <Box id={`preguntaTexto:${item.id}`}>
        <TextViewThink
          showCode={showCode}
          voice={voice}
          item={{ ...item, respuestasInternas }}
        />
      </Box>
      {/* <TextField
        variant={'outlined'}
        margin="normal"
        autoComplete='off'
        autoCorrect='off'
        onBlur={onChangeValue}
        value={value}
        onChange={onchangeInner}
        key={`pregunta-${item.id}`}
        fieldid={`pregunta-${item.id}`}
        // label={item.versiones[0].vc_pregunta}
        fullWidth
        autoComplete="off"
        type={'text'}
        multiline
        InputProps={{
          endAdornment: null

        }}
        inputProps={{
          style: { textAlign: 'justify', paddingRight: 16, whiteSpace: 'pre-wrap', fontSize: 16, lineHeight: 1.3 }
        }}
        InputLabelProps={{

          style: { fontSize: 16 }
        }
        }
      /> */}
      {/* <TextView
        key={`pregunta-${item.id}`}
        fieldid={`pregunta-${item.id}`}
        field={`pregunta-${item.id}`}
        onChange={onChangeValue}
        name={`pregunta-${item.id}`}
        fullWidth
        value={item.respuesta ? item.respuesta.vc_respuesta : ''}
        label={item.versiones[0].vc_pregunta}
        type={'text'}
        endAdornment={
          version.id_preguntaEnlace &&
          <IconButton disableFocusRipple size='small' onClick={updateHelperSideBar} style={{margin:0}}>
            <SportsEsportsOutlined />
          </IconButton>
        }
      /> */}
      {/* <TextareaAutosize
        onScroll={(e) => e.stopPropagation()}
        autoFocus={index === 0}
        className={classes.textArea}
        placeholder="Escribe...."
        defaultValue={item.respuesta ? item.respuesta.vc_respuesta : ''}
        onBlur={onChangeValue}
        style={{ fontSize: 14, backgroundColor: 'transparent', width: '100%', minHeight: window.isMobile ? 200 : 60, maxHeight: 400, minWidth: '100%', maxWidth: '100%', borderRadius: 8, outline: 'none', padding: 8, overflow: 'auto' }}
      /> */}
      <ModalUI
        open={showMap}
        onClose={() => setShowMap(false)}
        style={{
          boxShadow: "0px 0px 0px transparent",
          backgroundColor: "transparent",
          margin: window.isMobile ? 50 : 100,
        }}
      >
        <Paper
          style={{
            padding: 0,
            borderRadius: 20,
            overflow: "hidden",
            height: "100%",
            width: "100%",
            outline: "none",
          }}
        >
          <GoogleMaps
            points={points}
            circles={(props.covid19 || {}).countries || []}
          />
          {/* <div style={{ width: '100%', position: 'absolute', padding: 40, paddingTop: 15, marginBottom: 10, marginTop: 10, bottom: 0 }}>
            <Slider
              defaultValue={step}
              getAriaValueText={(value) => {
                return `${moment('2020-01-22').add(value,'days').format('DD-MM')}`
              }}
              onChange={(value) => {
                setStep(value);
              }}
              aria-labelledby="discrete-slider"
              valueLabelDisplay={(value) => {
                return `${moment('2020-01-22').add(value,'days').format('DD-MM')}`
              }}
              step={1}
              marks
              min={0}
              max={steps}
            />
          </div> */}
        </Paper>
      </ModalUI>
    </Box>
  );
}

const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setDisplaySidebar,
  setPacienteActual,
};
const mapStateToProps = (state) => {
  const { preguntas, pacienteActual, diagnosticos, covid19 } = state.Think;
  return { preguntas, pacienteActual, diagnosticos, covid19 };
};
export default connect(mapStateToProps, actions)(PreguntasTextoUI);

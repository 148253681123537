import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from './TextField';

const SecureCard = props => {
  const {
    inputRef,
    onChange,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
    />
  );
};

export default props => {
  return (
    <TextField
      InputProps={{
        inputComponent: SecureCard,
        disableUnderline: true
      }}
      inputProps={{
        format: '####',
        'data-conekta': 'card[cvc]',
        style:{fontSize: 16}
      }}
      label="Código de seguridad"
      fullWidth
      {...props}
    />
  );
};
import http from '../http';

export default {
    getExpediente(body, callback) {
        const headers = { Authorization: window.amplify.store('token') };
        http.post('/expediente/usuario', headers, body, callback);
    },
    guardarNotas(body, callback) {
        const headers = { Authorization: window.amplify.store('token') };
        http.post('/app/api/notas', headers, body, callback);
    },
    registrarTratamiento(body, callback) {
        const headers = { Authorization: window.amplify.store('token') };
        http.post('/app/api/notas/tratamientos', headers, body, callback);
    },
    registrarSolicitudes(body, callback) {
        const headers = { Authorization: window.amplify.store('token') };
        http.post('/app/api/notas/solicitudes', headers, body, callback);
    }
};

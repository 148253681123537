import React, { Component } from "react";
import { Grid, Modal, Feed, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import formatter from "../../../../utils/formatter";
import internalAPI from "../../../../utils/internalAPI";
import notifications from "../../../../utils/notifications";
import moment from "moment";
import ChangeTime from "./ChangeTime";

import {
  Paper,
  ListItem,
  Input,
  Box,
  Button,
  MenuItem,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";

import Select from "../../../../material-ui/ThinkForm/SearchSelect";
import TextView from "../../../../material-ui/TextView";
import Search from "@material-ui/icons/Search";
import PersonAddOutlined from "@material-ui/icons/PersonAddOutlined";
import PatientsView from "../patients/PatientsView";
// import Select from '../../../../material-ui/Select'
const { Row, Column } = Grid;
const { Event, Content, Summary, User, Date, Meta, Like } = Feed;

class AddMeetingModal extends Component {
  constructor(props) {
    super(props);

    const { setInfo } = this.props;

    this.state = {
      patients: [],
      selectedPatient: null,
      error: "",
      changeTime: false,
      setInfo,
      waitList: false,
      searchText: "",
      addPatientModal: false,
      functions: {},
    };
  }

  componentDidUpdate() {
    if (
      document.activeElement != window.$("#searchBar") &&
      this.state.selectedPatient == null
    ) {
      window.$("#searchBar").focus();
    }
  }

  // ------------------------
  // ------  methods --------
  // ------------------------
  searchPatient(event) {
    const body = {
      id_institucion: window.amplify.store("institution").id_institucion,
      texto: event.target.value,
    };
    this.setState({ selectedPatient: null, searchText: event.target.value });
    if (event.target.value) {
      internalAPI.searchPatient(body, (error, response) => {
        if (error) {
          console.log("Error: ", error);
          notifications.error(error.error);
        } else {
          this.setState({ patients: response });
        }
      });
    }
  }

  save() {
    const { addMeeting, agendaActual } = this.props;
    const { date } = formatter;

    const info = this.state.setInfo === null ? [] : this.state.setInfo;
    if (this.state.selectedPatient) {
      const patient = this.state.selectedPatient;
      const meeting = {
        id_institucion: agendaActual.id_institucion,
        id_dependencia: agendaActual.id_dependencia,
        id_area: agendaActual.id_area,
        id_agenda: agendaActual.id,
        id_usuario: window.amplify.store("user_id"),
        id_paciente: patient.id,
        dt_cita: date(info.start, "YYYY-MM-DD"),
        tm_inicio: date(info.start, "HH:mm:ss"),
        tm_fin: date(info.end, "HH:mm:ss"),
        vc_observaciones: info.vc_observaciones || "",
      };
      internalAPI.saveMeeting(meeting, (error, response) => {
        if (error) {
          notifications.error(error.texto);
          this.setState({ error: error.error });
        } else {
          this.setState({ error: "", selectedPatient: null, patients: [] });
          response.dt_cita = date(info.start, "YYYY-MM-DD");
          addMeeting(response);
        }
      });
    } else {
      this.setState({ error: "Seleccione un paciente" });
    }
  }

  selectItem(selectedPatient) {
    this.setState({
      selectedPatient,
      addPatientModal: false,
      patients: [],
      searchText: "",
    });
  }

  changeTime() {
    this.setState({ changeTime: true });
  }
  valueChangeTime(setInfo) {
    this.setState({ setInfo, changeTime: false });
  }

  waitList(event, data) {
    const waitList = data.checked;
    this.setState({ waitList });
  }
  // ------------------------------
  // ------ render methods --------
  // ------------------------------
  onChangeForm(formData) {
    const { close } = this.props;
    const { date } = formatter;

    if (formData.datosCita) {
      const info = this.state.setInfo;
      const duracion = info.end - info.start;
      const newTurno = info.proximas[formData.datosCita];
      const buildDateStart = moment(`${newTurno.tm_inicio}`);
      const newDuracion = buildDateStart + duracion;
      const new_fin = date(newDuracion, "YYYY-MM-DD HH:mm:ss");
      const buildDateEnd = moment(`${new_fin}`);
      const newInfo = {
        start: buildDateStart,
        end: buildDateEnd,
        tm_inicio: newTurno.tm_inicio,
        tm_fin: `${new_fin}`,
        proximas: info.proximas,
      };
      this.setState({ setInfo: newInfo });
    } else if (formData.duracion) {
      const info = this.state.setInfo;
      const duracion = formData.duracion * 60000;
      const buildDateStart = moment(`${info.tm_inicio}`);
      const newDuracion = buildDateStart + duracion;
      const new_fin = date(newDuracion, "YYYY-MM-DD HH:mm:ss");
      const buildDateEnd = moment(`${new_fin}`);
      const newInfo = {
        start: info.start,
        end: buildDateEnd,
        tm_inicio: info.tm_inicio,
        tm_fin: `${new_fin}`,
        proximas: info.proximas,
      };
      this.setState({ setInfo: newInfo });
    }
  }
  rederChangeTime() {
    const { horary, events } = this.props;
    const { min, max } = horary;

    return (
      <Grid style={{ padding: 15 }}>
        <Row>
          <Column>
            <ChangeTime
              horary={horary}
              events={events}
              change={this.valueChangeTime.bind(this)}
            />
          </Column>
        </Row>
      </Grid>
    );
  }

  renderPatients() {
    return this.state.patients.map((patient, index) => {
      const fecha = moment(patient.dt_nacimiento);
      const edad = moment().diff(fecha, "years", false);
      return (
        <div key={index}>
          <MenuItem
            onClick={() => {
              this.selectItem(patient);
            }}
          >
            <div style={{ width: "100%" }}>
              <Typography
                style={{ width: "100%", textTransform: "capitalize" }}
              >
                {patient.vc_nombre.toLowerCase()}{" "}
                {patient.vc_apellidoPaterno.toLowerCase()}{" "}
                {(patient.vc_apellidoMaterno || "").toLowerCase()}
              </Typography>
              <Typography
                style={{
                  width: "100%",
                  textTransform: "capitalize",
                  color: "gray",
                }}
              >{`Sexo: ${
                patient.id_sexo === 1 ? "Masculino" : "Femenino"
              }  -   Edad: ${edad}`}</Typography>
              <Typography
                style={{
                  width: "100%",
                  textTransform: "capitalize",
                  color: "gray",
                  textAlign: "right",
                }}
              >{`CURP: ${patient.vc_curp || ""}`}</Typography>
            </div>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
        </div>
      );
    });
  }

  renderPatientName() {
    if (this.state.selectedPatient) {
      const fecha = moment(this.state.selectedPatient.dt_nacimiento);
      const edad = moment().diff(fecha, "years", false);
      const patient = this.state.selectedPatient;
      return (
        <div
          style={{
            marginTop: 0,
            marginBottom: window.screen.availWidth > 800 ? 0 : 15,
            height: window.screen.availWidth > 800 ? "50%" : "100%",
          }}
        >
          <MenuItem
          // onClick={() => { this.selectItem(patient); }}
          >
            <div style={{ width: "100%" }}>
              <Typography
                color="primary"
                style={{ width: "100%", textTransform: "capitalize" }}
              >
                {patient.vc_nombre.toLowerCase()}{" "}
                {patient.vc_apellidoPaterno.toLowerCase()}{" "}
                {(patient.vc_apellidoMaterno || "").toLowerCase()}
              </Typography>
              <Typography
                style={{
                  width: "100%",
                  textTransform: "capitalize",
                  color: "gray",
                }}
              >{`Sexo: ${
                patient.id_sexo === 1 ? "Masculino" : "Femenino"
              }  -   Edad: ${edad} - CURP: ${patient.vc_curp}`}</Typography>
            </div>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
        </div>
      );
    }
  }

  renderError() {
    if (this.state.error) {
      return (
        <Message negative>
          <Message.Header>Error!</Message.Header>
          <p>{this.state.error}</p>
        </Message>
      );
    }
  }

  renderAddMeeting() {
    const { close } = this.props;
    const { date } = formatter;
    const info = this.state.setInfo === null ? [] : this.state.setInfo;
    const duracion = (info.end - info.start) / 60000;
    let defaultDuracion = duracion;
    const duraciones = [
      { label: "5", value: 5 },
      { label: "10", value: 10 },
      { label: "15", value: 15 },
      { label: "20", value: 20 },
      { label: "25", value: 25 },
      { label: "30", value: 30 },
      { label: "45", value: 45 },
      { label: "60", value: 60 },
      { label: "90", value: 90 },
      { label: "2 hrs.", value: 120 },
      { label: "2 hrs. y media", value: 150 },
      { label: "3 hrs.", value: 180 },
      { label: "3 hrs. y media", value: 210 },
      { label: "4 hrs", value: 240 },
    ];

    let defaultTurno = null;
    const proximas = info.proximas.map((turno, index) => {
      if (`${turno.tm_inicio}` == `${info.tm_inicio}`) {
        defaultTurno = index;
      }
      return {
        label:
          `${date(turno.tm_inicio, "HH:mm")}` +
          " - " +
          `${date(turno.tm_inicio, "dddd D MMMM YYYY")}`,
        value: index,
      };
    });

    duraciones.map((mins, index) => {
      if (mins == duracion) {
        defaultDuracion = index;
      }
    });

    const opacityPacientesSearch = this.state.selectedPatient != null ? 0 : 1;
    const opacityTurno = this.state.selectedPatient != null ? 1 : 0;
    return (
      <Paper style={{ padding: 15 }}>
        <Row>
          <Column style={{ width: "100%", marginBottom: 10 }}>
            <h2>Agregar Cita</h2>
          </Column>
        </Row>
        <Row
          style={{
            marginTop: 0,
            marginBottom: 0,
            maxHeight: window.screen.availHeight * 0.6,
          }}
        >
          <Column>
            <ListItem style={{ padding: 0, height: 45, zIndex: 0 }}>
              <Search style={{ margin: 10 }} />
              <Input
                id="searchBar"
                color="primary"
                onChange={this.searchPatient.bind(this)}
                autoFocus
                autoComplete="off"
                disableUnderline
                value={this.state.searchText || ""}
                placeholder="Buscar paciente..."
                style={{ width: "100%" }}
              />
              <IconButton
                disabled={this.state.searchText.length < 3}
                onClick={() => {
                  this.setState({ addPatientModal: true });
                }}
                style={{ margin: 10, float: "right" }}
              >
                <PersonAddOutlined />
              </IconButton>
            </ListItem>
            {this.state.selectedPatient ? (
              <Column
                style={{
                  flexDirection: "column",
                  width: "100%",
                  margin: 0,
                  padding: 0,
                }}
              >
                {/* <h5 style={{ marginTop: 0, marginBottom: 0, height: 20, width: '100%' }}>Paciente: </h5> */}
                <Divider style={{ margin: 0 }} />
                {this.renderPatientName()}
                <div style={{ width: "calc(100%)", margin: 0, padding: 0 }}>
                  <Select
                    label={"Turno"}
                    key="turno"
                    fieldid="turno"
                    name="turno"
                    value={defaultTurno}
                    onChange={(opcion) => {
                      this.onChangeForm({
                        datosCita: opcion.value,
                      });
                    }}
                    options={proximas}
                  />

                  <Select
                    label={"Duración"}
                    key="duracion"
                    fieldid="duracion"
                    name="duracion"
                    value={duracion}
                    onChange={(opcion) => {
                      this.onChangeForm({
                        duracion: opcion.value,
                      });
                    }}
                    options={duraciones}
                  />

                  <TextView
                    label={"Motivo de Cita"}
                    value={""}
                    type={"text"}
                    multiline
                    fullWidth
                    required={false}
                    onChange={(v) => {
                      const oldinfo = this.state.setInfo;
                      this.setState({
                        setInfo: { ...oldinfo, vc_observaciones: v },
                      });
                    }}
                    {...{
                      key: "vc_observaciones",
                      fieldid: "vc_observaciones",
                      name: "vc_observaciones",
                    }}
                  />
                </div>
                <Divider variant="fullWidth" />
              </Column>
            ) : null}
            <Divider variant="fullWidth" />
            <Box
              style={{
                opacity: opacityPacientesSearch,
                height: 200,
                overflow: "auto",
                boxShadow: "0px 0px 3px rgba(100,100,100, 0.8)",
                borderRadius: 5,
                marginTop: 8,
              }}
            >
              {this.renderPatients()}
            </Box>
            {this.renderError()}
          </Column>

          {/* <Column width={0}>
						<List>
						<Item>
							<Checkbox label='Lista de espera' onChange={this.waitList.bind(this)} />
						</Item>
						</List>
					</Column> */}
        </Row>

        <Row>
          <Column textAlign="right">
            <Button
              onClick={() => {
                close();
                this.setState({
                  error: "",
                  selectedPatient: null,
                  patients: [],
                });
              }}
            >
              Cancelar
            </Button>
            <Button onClick={this.save.bind(this)}> Guardar</Button>
          </Column>
        </Row>
      </Paper>
    );
  }

  renderContent() {
    if (this.state.changeTime) {
      return this.rederChangeTime();
    }

    return this.renderAddMeeting();
  }
  setRegistro(registro) {
    const patientEdit = { ...this.state.patientEdit, ...registro };
    const moduloActual = "registrar";
    const opcionActualModulo = patientEdit.id ? "editar" : "nuevo";
    this.setState(
      { patientSelected: null, patientEdit, moduloActual, opcionActualModulo },
      () => {}
    );
  }
  renderAddPatientModal() {
    console.log("se renderiza desde patitnView");
    return (
      <Modal
        style={{ backgroundColor: "transparent" }}
        open={this.state.addPatientModal}
        onClose={() => {
          this.setState({ addPatientModal: false });
        }}
      >
        <Paper
          style={{
            height: 600,
            maxHeight: 1000,
            maxHeight: 800,
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          <PatientsView
            modalMode
            opcionActualModulo="nuevo"
            saveEditPatient={(patient) => {
              console.log("patientfdff", patient);
              this.selectItem(patient);
            }}
          />
        </Paper>
      </Modal>
    );
  }
  render() {
    const { visible, close } = this.props;

    return (
      <Modal
        open={visible}
        onClose={() => {
          close();
        }}
      >
        {this.renderContent()}
        {this.renderAddPatientModal()}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  return {
    catalogo: catalogos.find((e) => e.vc_nameTable === "ins_pacientesDetalle"),
  };
};
export default connect(mapStateToProps, [])(AddMeetingModal);

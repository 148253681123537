import * as types from "../actions/types";

const INITIAL_STATE = {
  uuidCaja: null,
  uuidEmpresa: null,
  isLoggedIn: false,
  catalogos: {},
  venta: {
    cliente: null,
    uuid_cuenta: null,
    productos: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_LOGGEDIN:
      return { ...state, isLoggedIn: action.payload };

    case types.SET_EMPRESA:
      return { ...state, uuidEmpresa: action.payload };

    case types.SET_CAJA:
      return { ...state, uuidCaja: action.payload };

    case types.RESET_VENTA:
      return {
        ...state,
        venta: INITIAL_STATE.venta,
      };

    case types.SET_CLIENTE:
      return {
        ...state,
        venta: {
          ...state.venta,
          cliente: action.payload,
        },
      };
    case types.SET_CUENTA:
      return {
        ...state,
        venta: {
          ...state.venta,
          uuid_cuenta: action.payload,
        },
      };

    case types.ADD_PRODUCTO:
      return {
        ...state,
        venta: {
          ...state.venta,
          productos: [...state.venta.productos, action.payload],
        },
      };

    case types.EDIT_PRODUCTO:
      return {
        ...state,
        venta: {
          ...state.venta,
          productos: state.venta.productos.map((producto) => {
            if (producto.uuid !== action.payload.uuid) return producto;

            return action.payload;
          }),
        },
      };

    case types.DELETE_PRODUCTO:
      return {
        ...state,
        venta: {
          ...state.venta,
          productos: state.venta.productos.filter(
            (producto) => producto.uuid !== action.payload
          ),
        },
      };

    case types.SET_CATALOGO:
      return {
        ...state,
        catalogos: {
          ...state.catalogos,
          [action.payload.catalogo]: action.payload.data,
        },
      };
    default:
      return state;
  }
};

import React from "react";

import { connect } from "react-redux";
import SubMenuModulos from "../../../material-ui/SubMenuModulos";
import { FadeInView } from "../../../components";
import moment from "moment";
import debounce from 'lodash/debounce';
import MUIDataTable from "mui-datatables";

import {
    setHelperSidebarRender,
    setDisplaySidebar,
} from "../../../containers/Think/actions";

import Sidebar from "./Sidebar";
import catalogs from '../fields/index';
import erpEndPoints from "app/utils/internalAPI/endPoints/erpEndPoints";
import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import FormAutocomplete from "app/material-ui/FormAutocomplete";
import ConfirmAlert from "app/material-ui/ConfirmAlert";
const Index = (props) => {
    const institucion = window.amplify.store("institution") || {};
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [catalog, setCatalog] = React.useState(catalogs[0] || null);
    const [results, setResults] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [modalForm, setModalForm] = React.useState(false);
    const [modalConfirm, setModalConfirm] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [rowSelected, setRowSelected] = React.useState({
        index: -1,
        row: null,
    });

    React.useEffect(() => {
        props.setDisplaySidebar(false);
        props.setHelperSidebarRender(null);
    }, []);

    const getData = () => {
        if (institucion.id_institucion) {
            const apiCall = debounce( () => {
                erpEndPoints.general(catalog.vc_path, 'GET', { page: 1, limit: 100,searchText, filters: catalog.fields.filter((field) => field.filter).map(e => e.key )}, (err, res) => {
                    if (err) {
                        if (err.text) {
    
                        }
                    } else {
                        setResults(res.items || []);
                    }
                })
            }, 500);
            apiCall()
        }
    }
    React.useEffect(() => {
        if (searchText.length > 2 || !results.length) 
            getData();
    }, [catalog, searchText]);

    React.useEffect(() => {
        setData(
            results.map((item) => {
                const row = {};
                catalog.fields.map((field) => {
                    row[field.key] = item[field.key]
                })
                return row;
            })
        );
    }, [results]);

    React.useEffect(() => {
        props.setHelperSidebarRender(() =>
            Boolean(rowSelected.row) ? <Sidebar data={rowSelected.row} /> : null
        );
        props.setDisplaySidebar(Boolean(rowSelected.row));
    }, [rowSelected]);

    const handleSelect = (row, index) => {
        const newRowSelected = { index: -1, row: null };

        if (rowSelected.index !== index) {
            newRowSelected.index = index;
            newRowSelected.row = row;
        }

        setRowSelected(newRowSelected);
    };

    return (
        <div
            style={{
                width: "100%",
                height: "calc(100vh - 48px)",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <SubMenuModulos
                title={
                    <Button
                        color="inherit"
                        onClick={() => {
                            handleSelect(null, -1);
                            setModalForm(true);
                        }}
                    >
                        {'Agregar'}
                    </Button>
                }
                type={"search"}
                searchText={searchText}
                onChange={(e) => {
                    setSearchText(e.target.value);
                }}
                rightComponent={
                    <div>
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => setAnchorEl(e.currentTarget)}>
                            {catalog.vc_name || 'Seleccione'}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={(e) => setAnchorEl(null)}
                        >
                            {
                                catalogs.map((cat, index) => {
                                    return (
                                        <MenuItem key={index} onClick={() => {
                                            setCatalog(cat)
                                            setAnchorEl(null)
                                        }}>{cat.vc_name}</MenuItem>
                                    )
                                })
                            }
                        </Menu>
                    </div>
                }
            />

            <div
                style={{
                    ...styles.principal,
                    flex: 1,
                    ...{ height: "calc(100% - 47px)" },
                    padding: "10px",
                }}
            >
                <Table catalog={catalog} data={data} onClick={handleSelect} />
            </div>
            {modalForm && (
                <FormAutocomplete
                    open
                    catalog={catalog}
                    close={() => setModalForm(false)}
                    // accept={guardarProducto}
                    data={rowSelected.row}
                />
            )}

            {modalConfirm && (
                <ConfirmAlert
                    title={`¿Desea eliminar el producto: ${rowSelected.row?.vc_description}?`}
                    // onAccept={eliminarProducto}
                    onCancel={() => setModalConfirm(false)}
                />
            )}
        </div>
    );
};

const Table = (props) => {
    const { data = [], onClick = () => { }, catalog } = props;
    const options = { filter: true, sort: true };
    const columns = (catalog ? catalog.fields:[]).map((field) => {
        return {
            name: field.key,
            label: field.vc_name,
            options
        }
    })
    const optionsTable = {
        elevation: 0,
        filter: true,
        search: false,
        fixedSelectColumn: false,
        print: false,
        selectableRows: "none",
        selectableRowsHeader: false,
        onRowClick(rowData, { dataIndex, rowIndex }) {
            onClick(data[dataIndex], dataIndex);
        },
        textLabels: {
            pagination: {
              next: "Siguiente",
              previous: "Anterior",
              rowsPerPage: "Registros por pagina:",
              displayRows: "de" // 1-10 of 30
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar Excel",
              print: "Imprimir",
              viewColumns: "Columnas",
              filterTable: "Filtros"
            },
            filter: {
              title: "Filtros",
              reset: "Eliminar",          
            },
            viewColumns: {
              title: "Mostrar Columnas"
            },
            selectedRows: {
              text: "registros(s) borrados",
              delete: "Eliminar"
            }
        }
    };
    return (
        <FadeInView>
            <MUIDataTable
            
                title={catalog ? catalog.vc_name : ''}
                data={data}
                columns={columns}
                options={optionsTable}
                pagination={false}
            />
        </FadeInView>
    );
};

const styles = {
    container: {
        overflowX: "hidden",
        overflowY: "hidden",
        height: "100vh",
        width: "100%",
        padding: 0,
        margin: 0,
    },
    principal: {
        overflowY: "scroll",
        WebkitOverflowScrolling: "touch",
        overflowX: "hidden",
        padding: 0,
        paddingBottom: 0,
        margin: 0,
        height: "calc(100vh - 95px)",
        width: "100%",
    },
    rightHeader: {
        color: "white",
        background: "transparent",
        textAlign: "center",
        margin: 0,
    },
    centralColumn: {
        overflowY: "scroll",
        WebkitOverflowScrolling: "touch",
        background: "rgba(50,50,50,0.4)",
        padding: 0,
        paddingTop: 0,
        margin: 0,
        width: "100%",
        height: window.innerHeight,
    },
    item: {
        padding: 0,
        paddingBottom: 3,
        margin: 0,
        width: "100%",
    },
};
const actions = { setHelperSidebarRender, setDisplaySidebar };
const mapStateToProps = (state) => {
    const { catalogos } = state.Think;
    return {
        catalogo: catalogos.find((e) => e.vc_nameTable === "ins_pacientes"),
    };
};

export default connect(mapStateToProps, actions)(Index);

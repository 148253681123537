/* eslint-disable */
import React from "react";
import { LocalizationProvider, DatePicker } from "@material-ui/pickers";

import "moment/locale/es";
import moment from "moment";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import { TextField } from "@material-ui/core";
import locale from "date-fns/locale/es";
import { string } from "prop-types";

moment.locale("es");

// const staticDateAdapter = new DateFnsAdapter({ locale: locale });

export default (props) => {
  const minWidth = props.label ? props.label.length * 6 : null;
  const useStyles = makeStyles((theme) => ({
    formControl: {
      marginTop: 4.5,
      marginRight: 10,
      marginBottom: 4,
    },
  }));
  const classes = useStyles();
  const { required, label, fullWidth, options = [], input, fieldid } = props;

  const [selectedDate, setDate] = React.useState(
    props.value ? moment(props.value) : null
  );
  const [prevValue, setPrevValue] = React.useState(null);

  React.useEffect(() => {
    // setLabelWidth(inputLabel.current.offsetWidth);
    if (props.value !== selectedDate && props.value)
      setDate(moment(props.value));
  }, [props.value]);
  const inputName = label.replace(" ", "-");

  const handleDateChange = (date) => {
    console.log("date", date);
    if (date === null) {
      setPrevValue(null);
    } else if (moment(date).isValid()) {
      setDate(date);
    } else {
      setDate(date);
    }
  };

  MomentAdapter.prototype.getMonth = function (date) {
    console.log("DATE???????", date);
    return date ? date.get("month") : moment().get("month");
  };

  MomentAdapter.prototype.getMonthArray = function (d) {
    var date = d || moment();
    var firstMonth = date.clone().startOf("year");
    var monthArray = [firstMonth];
    while (monthArray.length < 12) {
      var prevMonth = monthArray[monthArray.length - 1];
      monthArray.push(this.getNextMonth(prevMonth));
    }
    return monthArray;
  };

  MomentAdapter.prototype.setMonth = function (date, count) {
    if (!date) return;
    return date.clone().month(count);
  };

  return (
    <FormControl
      variant="outlined"
      required={required}
      fullWidth={fullWidth}
      className={classes.formControl}
    >
      {/* <LocalizationProvider dateAdapter={MomentAdapter}> */}
      <LocalizationProvider dateAdapter={MomentAdapter} locale={"es"}>
        <DatePicker
          autoOk
          clearable
          // VAMOS VIENDO
          allowKeyboardControl={false}
          // dateAdapter={staticDateAdapter}
          autoCorrect="on"
          placeholder="DD-MM-AAAA"
          name={inputName}
          variant="inline"
          inputVariant={props.variant || "outlined"}
          label={props.required ? `${props.label}*` : props.label}
          inputFormat="DD-MM-YYYY"
          mask="__-__-____"
          onError={(e) => {
            console.log(e);
          }}
          value={selectedDate}
          views={["date", "month", "year"]}
          InputAdornmentProps={{ position: "end" }}
          onChange={handleDateChange}
          error={false}
          InputLabelProps={props.InputLabelProps || {}}
          // rifmFormatter={ !prevValue ? (str) => {
          //   if ((str * 1) > -1 && (str * 1) <= 3) {
          //     setPrevValue(str);
          //     return str
          //   };
          //   return '';
          // }: null}
          InputProps={{ id: fieldid }}
          renderInput={(_) => {
            return (
              <TextField
                variant={"outlined"}
                {..._}
                error={false}
                helperText={
                  moment(selectedDate).isValid() &&
                  moment(selectedDate).format &&
                  moment(selectedDate).format("yyyy") * 1 > 1900
                    ? ""
                    : "*Fecha invalida"
                }
              />
            );
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

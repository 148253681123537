import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import _ from 'lodash';

// crear componente
class NumericInput extends Component {
    constructor(props) {
        super(props);

        let valid;
        if (props.defaultValue) {
            valid = true;
        } else {
            valid = !props.required;
        }

        this.state = {
            value: props.defaultValue ? props.defaultValue : '',
            valid,
            message: props.required ? `El campo ${props.label} es requerido` : '',
            dirty: false
        };
    }


    // -----------------------------
    // ------ life cycle events ----
    // -----------------------------
    componentDidMount() {
        this.setForm();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.dirty && nextProps.defaultValue) {
            let value = nextProps.defaultValue;

            if (nextProps.currency) {
                const parsedNumber = parseFloat(value);
                value = this.currencyFormat(`${parsedNumber}`);
            }

            this.setState({ value, valid: true });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextState, this.state) || !_.isEqual(nextProps, this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.eq(prevState, this.state)) {
            this.setForm();
        }
    }


    // -----------------------
    // ------ user events ----
    // -----------------------
    onChange(e) {
        let regex; // choose regex
        if (this.props.currency) {
            regex = /[^.,$\d]/g;
        } else {
            regex = /[^.\d]/g;
        }

        if (this.props.required && !e.target.value) {
            this.setState({
                valid: false,
                value: '',
                message: `El campo ${this.props.label} es requerido`
            });
        } else if (!regex.test(e.target.value)) {
            const number = this.getNumericValue(e.target.value);

            if (number.valid) {
                const newValue = {
                    value: number.value,
                    message: '',
                    valid: true,
                    dirty: true
                };

                if (this.props.max && number.length <= this.props.max) {
                    this.setState(newValue);
                } else if (!this.props.max) {
                    this.setState(newValue);
                }
            } else {
                this.setState({
                    value: number.value,
                    valid: false,
                    message: `El campo ${this.props.label} no tiene un formato valido`,
                    dirty: true
                });
            }
        }
    }

    onBlur(e) {
        const number = this.getNumericValue(e.target.value);

        if (this.props.required && !e.target.value) {
            this.setState({
                valid: false,
                value: '',
                message: `El campo ${this.props.label} es requerido`
            });
        } else if (number.numericValue && !parseFloat(number.numericValue)) {
            this.setState({
                valid: false,
                message: `El campo ${this.props.label} no tiene un formato valido`,
                dirty: true
            });
        } else if (parseFloat(number.numericValue)) {
            const parsedNumber = parseFloat(number.numericValue);

            if (this.props.min && number.length >= this.props.min) {
                this.setState({
                    value: this.props.currency ? this.currencyFormat(`${parsedNumber}`) : parsedNumber,
                    valid: true,
                    message: ''
                });
            } else if (this.props.min && number.length < this.props.min) {
                this.setState({
                    value: this.props.currency ? this.currencyFormat(`${parsedNumber}`) : parsedNumber,
                    valid: false,
                    message: `El campo ${this.props.label} debe contener al menos ${this.props.min} numeros`
                });
            } else if (!this.props.min) {
                this.setState({
                    value: this.props.currency ? this.currencyFormat(`${parsedNumber}`) : parsedNumber,
                    valid: true,
                    message: ''
                });
            }
        }
    }

    getNumericValue(rawValue) {
        let numericValue;
        let validNumber = true;

        // get numeric value
        if (this.props.currency) {
            numericValue = rawValue.replace(/[$]/g, '');
            numericValue = numericValue.replace(/[,]/g, '');
        } else {
            numericValue = rawValue;
        }

        // valid number
        if (numericValue && !parseFloat(numericValue)) {
            validNumber = false;
        }

        if (validNumber) {
            if (this.props.currency) {
                const formattedNumber = this.currencyFormat(numericValue);

                return {
                    numericValue,
                    value: formattedNumber,
                    length: numericValue.length,
                    valid: true,
                };
            }

            return {
                numericValue,
                value: numericValue,
                length: rawValue.length,
                valid: true,
            };
        }

        return {
            numericValue,
            value: rawValue,
            length: rawValue.length,
            valid: validNumber
        };
    }

    setForm() {
        this.props.setFormData(this.props.name, this.state);
    }

    getValue() {
        let numericValue;

        // get numeric value
        if (this.props.currency) {
            numericValue = this.state.value.replace(/[$]/g, '');
            numericValue = numericValue.replace(/[,]/g, '');
        } else {
            numericValue = this.state.value;
        }

        return numericValue ? parseFloat(numericValue) : '';
    }

    currencyFormat(value) {
        const arrayNumber = value.split('.');
        const formattedNumber = `$${arrayNumber[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;

        if (arrayNumber.length > 1) {
            return `${formattedNumber}.${arrayNumber[1]}`;
        }

        return formattedNumber;
    }

    dirtInput() {
        this.setState({ dirty: true });
    }

    resetInput() {
        let valid;
        if (this.props.defaultValue) {
            valid = true;
        } else {
            valid = !this.props.required;
        }

        this.setState({
            value: this.props.defaultValue ? this.props.defaultValue : '',
            valid,
            message: this.props.required ? `El campo ${this.props.label} es requerido` : '',
            dirty: false
        });
    }


    // --------------------------
    // ------ render methods ----
    // --------------------------
    render() {
        const invalidInput = this.state.dirty && !this.state.valid;
        let className = '';
        let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
        let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

        if (this.props.className) {
            className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
        } else {
            className = invalidInput ? 'invalid' : 'valid';
        }

        if (invalidInput) {
            finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
            finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
        }

        // render input
        if (this.props.inlineLabel) {
            return (
                <Form.Field onBlur={this.onBlur.bind(this)}>
                    <Input
                        label={this.props.label}
                        value={this.state.value}
                        name={this.props.name}
                        className={className}
                        onChange={this.onChange.bind(this)}
                        disabled={this.props.readOnly}
                        placeholder={this.props.placeholder}
                        style={finalInputStyle}
                        onClick={() => this.props.callbackSelectInput()}
                    />
                </Form.Field>
            );
        }

        return (
            <Form.Field onBlur={this.onBlur.bind(this)}>
                <label style={finalLabelStyle}>
                    { this.props.label }
                </label>
                <input
                    value={this.state.value}
                    name={this.props.name}
                    className={className}
                    onChange={this.onChange.bind(this)}
                    disabled={this.props.readOnly}
                    placeholder={this.props.placeholder}
                    style={finalInputStyle}
                    onClick={() => this.props.callbackSelectInput()}
                />
            </Form.Field>
        );
    }
}


// estilos
const styles = {
    label: {

    },
    errorLabel: {
        color: '#9F3A38'
    },
    input: {

    },
    errorInput: {
        background: 'rgba(224, 180, 180, 0.48)',
        border: '1px solid #9F3A38',
        color: '#9F3A38'
    }
};


// exportar componente
export default NumericInput;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import { Lens } from "@material-ui/icons";
import { Typography, Grid, Paper, makeStyles, Box } from "@material-ui/core";

import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "app/containers/Think/actions";

import Loading from "app/agenda/components/Loading";
import Sidebar from "./sidebar";
import api from "app/agenda/api";

import Table from "app/material-ui/Table";
import { getAge } from "app/utils/repository";

const useStyles = makeStyles((theme) => {
  return {
    cita: {
      borderRadius: 5,
      padding: "12px",
      marginBottom: theme.spacing(1),
      boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
      border: "1px solid transparent",
      "&.selected": {
        borderColor: theme.palette.primary.main,
        textTransform: 'uppercase'
      },
      "&.notSelected": {
        opacity: 0.8,
        textTransform: 'uppercase'
      },
      "& h5": {
        color: window.colors.itemSelected,
        marginBottom: "0",
        textTransform: "capitalize",
      },
    },
  };
});

const sexos = ["No Especificado", "Masculino", "Femenino", "Indistinto"];
const columns = [
  {
    name: "vc_nombreCompleto",
    label: "Nombre del Paciente",
    options: {
      typographyProps: {
        color: "primary",
        variant: "subtitle1",
      },
    },
  },
  { name: "vc_sexo", label: "Sexo" },
  { name: "nu_edad", label: "Edad" },
  { name: "dt_fecha", label: "Fecha" },
  {
    name: "estatus",
    label: "Estatus",
    options: {
      cellRender: (estatus) => {
        return (
          <Box display="flex" alignItems="center">
            <Lens
              fontSize="small"
              style={{
                marginRight: "4px",
                color: estatus.vc_color,
              }}
            />
            <Typography>{estatus.vc_nombre}</Typography>
          </Box>
        );
      },
    },
  },
];

function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Index = (props) => {
  const classes = useStyles();
  const { agendaId, dates, estatusCitas, estatus } = props;
  const [data, setData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState({
    index: -1,
    row: null,
  });
  const prevAgendaID = usePrevious(agendaId);
  const prevDates = usePrevious(dates);
  const prevEstatus = usePrevious(estatus);
  const prevData = usePrevious(data);

  const handleSelect = (row, index) => {
    const newSelected = { index: -1, row: null };

    if (selected.index !== index) {
      newSelected.index = index;
      newSelected.row = row;
    }

    setSelected(newSelected);
  };

  const fetchData = async () => {
    // setData([]);
    setLoading(true);
    const res = await api.days(agendaId, dates);
    const fetched = Object.values(res)
      .reduce((p, c) => [...p, ...c.citas], [])
      .sort((a, b) =>
        moment(`${a.dt_cita} ${a.tm_inicio}`).diff(
          moment(`${b.dt_cita} ${b.tm_inicio}`)
        )
      );

    if (selected.row) {
      const index = fetched.findIndex((v) => v.id == selected.row.id);
      setSelected({ index, row: fetched[index] });
    }
    setData(fetched);
    setLoading(false);
  };

  React.useEffect(() => {
    if (
      agendaId &&
      dates.length &&
      (prevAgendaID !== agendaId ||
        JSON.stringify(prevDates) !== JSON.stringify(dates))
    ) {
      fetchData();
    }
  }, [agendaId, dates]);

  React.useEffect(() => {
    if (selected.row)
      props.setHelperSidebarRender(() => (
        <Sidebar
          selected={selected.row}
          onActionCallback={async () => {
            await fetchData();
            handleSelect(null, -1);
          }}
        />
      ));
    else props.showCalendar();
  }, [selected.row]);

  React.useEffect(() => {
    const estatusId = estatus.filter((v) => v.checked).map((v) => v.id);
    // console.log(JSON.stringify(prevEstatus) !== JSON.stringify(estatus));
    // console.log(prevEstatus, estatus);
    if (
      JSON.stringify(prevData) !== JSON.stringify(data) ||
      JSON.stringify(prevEstatus) !== JSON.stringify(estatus)
    ) {
      const rowsAux = data
        .filter((v) => estatusId.includes(v.id_estatusCita))
        .map((v, i) => {
          const { id_sexo = 0, id_estatusCita = 1, dt_nacimiento } = v;
          const vc_nombreCompleto =
            `${v.vc_nombre} ${v.vc_apellidoPaterno} ${v.vc_apellidoMaterno}`.toLowerCase();
          // const nu_edad = dt_nacimiento
          //   ? moment().diff(moment(dt_nacimiento), "years")
          //   : "NA";
          const nu_edad = dt_nacimiento ? getAge(dt_nacimiento) : "NA";
          const vc_sexo = sexos[id_sexo];
          const estatus = estatusCitas.find((e) => e.id === id_estatusCita);
          const dt_fecha = moment(`${v.dt_cita} ${v.tm_inicio}`).format(
            "hh:mm a, DD-MM-YYYY"
          );

          return {
            ...v,
            vc_nombreCompleto,
            nu_edad,
            vc_sexo,
            estatus,
            dt_fecha,
          };
        });
      setRows(rowsAux);
    }
  }, [data, estatus]);

  return (
    <Box
      bgcolor="rgba(200, 200, 200, 0.2)"
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      flexGrow={1}
      position="relative"
    >
      <Loading show={loading} />
      <Box
        display="flex"
        flexGrow={1}
        flexShrink={1}
        flexBasis="auto"
        flexDirection="column"
        px={2}
        pb={2}
        height={"0px"}
        overflow="scroll"
      >
        <Table
          columns={columns}
          data={rows}
          onSelect={handleSelect}
          value={selected.row?.id}
        />
      </Box>
    </Box>
  );
};

Index.defaultProps = {
  dates: [],
  agendas: [],
  estatus: [],
  showCalendar: () => {},
};

Index.propTypes = {
  dates: PropTypes.array,
  estatusCitas: PropTypes.array,
  estatus: PropTypes.array,
  agendaId: PropTypes.number,
  showCalendar: PropTypes.func,

  setHelperSidebarRender: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
};

const actions = {
  setHelperSidebarRender,
  setDisplaySidebar,
};

const mapStateToProps = ({ Think: { estatusCitas } }) => ({ estatusCitas });

export default connect(mapStateToProps, actions)(Index);

import React, { Component } from 'react';
import { Grid, Form } from 'semantic-ui-react';
import _ from 'lodash';
import TextInput from './inputs/TextInput';
import TextAreaInput from './inputs/TextAreaInput';
import EmailInput from './inputs/EmailInput';
import NumericInput from './inputs/NumericInput';
import DropdownInput from './inputs/DropdownInput';
import ConfirmPasswordInput from './inputs/ConfirmPasswordInput';
import CheckboxInput from './inputs/CheckboxInput';
import RadioInput from './inputs/RadioInput';
import DateInput from './inputs/DateInput';
import FileInput from './inputs/FileInput';

const { Column } = Grid;


class AutoForm extends Component {
    constructor(props) {
        super(props);
        this.formInputs = {}; // form's registered inputs

        this.state = {
            formData: {}
        };
    }

    // -----------------------------
    // ------ life cycle events ----
    // -----------------------------
    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextState, this.state) || !_.isEqual(nextProps, this.props);
    }


    // --------------------
	// --- form methods ---
	// --------------------
    setFormData(inputName, data) {
        const formData = this.state.formData;
        formData[inputName] = data;
        this.setState({ formData });

        // notify changes if needed
        if (this.props.onChange) {
            this.props.onChange(this.formatFormData());
        }
    }

    getData() {
        this.dirtFormInputs();
        const validForm = this.validForm();

        if (validForm.valid) {
            return { IsValid: true, Data: this.formatFormData() };
        }

        return { IsValid: false, Message: validForm.message, Data: this.formatFormData() };
    }

    handleSubmit(e) {
        e.preventDefault();
        this.dirtFormInputs();

        if (this.validForm().valid) {
            this.props.onSubmit(this.formatFormData());
        }
    }

    validForm() {
        const result = { valid: true, message: '' };

        // iterate over inputs
        Object.keys(this.formInputs).forEach(input => {
            if (this.formInputs[input]) {
                const currentInput = this.formInputs[input].state;

                if (result.valid && !currentInput.valid) {
                    result.valid = false;
                    result.message = currentInput.message;

                    if (this.props.onError) {
                        this.props.onError(currentInput.message);
                    }
                }
            }
        });

        return result;
    }

    formatFormData() {
        const formattedData = {};

        // iterate over inputs
        Object.keys(this.formInputs).forEach(input => {
            const currentInput = this.formInputs[input];
            if (currentInput !== null) {
                formattedData[input] = currentInput.getValue();
            }
        });

        return formattedData;
    }

    dirtFormInputs() {
        Object.keys(this.formInputs).forEach(input => {
            if (this.formInputs[input]) {
                this.formInputs[input].dirtInput();
            }
        });
    }

    reset() {
        Object.keys(this.formInputs).forEach(input => {
            if (this.formInputs[input]) {
                this.formInputs[input].resetInput();
            }
        });
    }


    // ----------------------
	// --- render methods ---
	// ----------------------
    renderInputs() {
        return this.props.inputs.map((inputSettings, index) => {
            let inputComponent;

            if (inputSettings.active === false) {
                return null;
            }

            switch (inputSettings.type) {
                case 'text':
                    inputComponent = (
                        <TextInput
                            {...inputSettings}
                            setFormData={this.setFormData.bind(this)}
                            ref={refInput => { this.formInputs[inputSettings.name] = refInput; }}
                            callbackSelectInput={() => {
                                this.props.callbackSelectInput(inputSettings.name);
                            }}
                            callbackChange={(value) => {
                                const name = inputSettings.name;
                                this.props.callbackChange({ value, name });
                            }}
                        />
                    );
                    break;
                case 'textArea':
                    inputComponent = (
                        <TextAreaInput
                            {...inputSettings}
                            setFormData={this.setFormData.bind(this)}
                            ref={refInput => { this.formInputs[inputSettings.name] = refInput; }}
                            callbackSelectInput={() => {
                                this.props.callbackSelectInput(inputSettings.name);
                            }}
                            callbackChange={(value) => {
                                const name = inputSettings.name;
                                this.props.callbackChange({ value, name });
                            }}
                        />
                    );
                    break;
                case 'number':
                    inputComponent = (
                        <NumericInput
                            {...inputSettings}
                            setFormData={this.setFormData.bind(this)}
                            ref={refInput => { this.formInputs[inputSettings.name] = refInput; }}
                            callbackSelectInput={() => {
                                this.props.callbackSelectInput(inputSettings.name);
                            }}
                            callbackChange={(value) => {
                                const name = inputSettings.name;
                                this.props.callbackChange({ value, name });
                            }}
                        />
                    );
                    break;
                case 'email':
                    inputComponent = (
                        <EmailInput
                            {...inputSettings}
                            setFormData={this.setFormData.bind(this)}
                            ref={refInput => { this.formInputs[inputSettings.name] = refInput; }}
                            callbackSelectInput={() => {
                                this.props.callbackSelectInput(inputSettings.name);
                            }}
                            callbackChange={(value) => {
                                const name = inputSettings.name;
                                this.props.callbackChange({ value, name });
                            }}
                        />
                    );
                    break;
                case 'dropdown':
                    inputComponent = (
                        <DropdownInput
                            {...inputSettings}
                            setFormData={this.setFormData.bind(this)}
                            ref={refInput => { this.formInputs[inputSettings.name] = refInput; }}
                            callbackSelectInput={() => {
                                this.props.callbackSelectInput(inputSettings.name);
                            }}
                        />
                    );
                    break;
                case 'confirmPassword':
                    inputComponent = (
                        <ConfirmPasswordInput
                            {...inputSettings}
                            setFormData={this.setFormData.bind(this)}
                            ref={refInput => { this.formInputs[inputSettings.name] = refInput; }}
                            callbackSelectInput={() => {
                                this.props.callbackSelectInput(inputSettings.name);
                            }}
                            callbackChange={(value) => {
                                const name = inputSettings.name;
                                this.props.callbackChange({ value, name });
                            }}
                        />
                    );
                    break;
                case 'checkbox':
                    inputComponent = (
                        <CheckboxInput
                            {...inputSettings}
                            setFormData={this.setFormData.bind(this)}
                            ref={refInput => { this.formInputs[inputSettings.name] = refInput; }}
                            callbackSelectInput={() => {
                                this.props.callbackSelectInput(inputSettings.name);
                            }}
                            callbackChange={(value) => {
                                const name = inputSettings.name;
                                this.props.callbackChange({ value, name });
                            }}
                        />
                    );
                    break;
                case 'radio':
                    inputComponent = (
                        <RadioInput
                            {...inputSettings}
                            setFormData={this.setFormData.bind(this)}
                            ref={refInput => { this.formInputs[inputSettings.name] = refInput; }}
                            callbackSelectInput={(value) => {
                                const name = inputSettings.name;
                                this.props.callbackChange({ value, name });
                            }}
                        />
                    );
                    break;
                case 'date':
                    inputComponent = (
                        <DateInput
                            {...inputSettings}
                            setFormData={this.setFormData.bind(this)}
                            ref={refInput => { this.formInputs[inputSettings.name] = refInput; }}
                            callbackSelectInput={() => {
                                this.props.callbackSelectInput(inputSettings.name);
                            }}
                            callbackChange={(value) => {
                                const name = inputSettings.name;
                                this.props.callbackChange({ value, name });
                            }}
                        />
                    );
                    break;
                case 'file':
                    inputComponent = (
                        <FileInput
                            {...inputSettings}
                            setFormData={this.setFormData.bind(this)}
                            ref={refInput => { this.formInputs[inputSettings.name] = refInput; }}
                            callbackSelectInput={() => {
                                this.props.callbackSelectInput(inputSettings.name);
                            }}
                        />
                    );
                    break;
                case 'container':
                    inputComponent = inputSettings.content;
                    break;
                default:
                    throw new Error(`Tipo de input "${inputSettings.type}" no soportado por Autoform. Inputs disponibles: "text", "textArea", "email", "number", "dropdown", "confirmPassword", "checkbox", "radio", "date", "container", "file"`);
            }

            return (
                <Column
                    textAlign={inputSettings.textAlign ? inputSettings.textAlign : null}
                    key={inputSettings.name || index}
                    width={inputSettings.type !== 'confirmPassword' ? inputSettings.width : 16}
                    verticalAlign="middle"
                >
                    { inputComponent }
                </Column>
            );
        });
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit.bind(this)} loading={this.props.loading}>
                <Grid stackable>
                    { this.renderInputs() }
                </Grid>
                { this.props.children }
            </Form>
        );
    }
}


export { AutoForm };

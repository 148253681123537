import http from "../http";

export default {
  searchPatient(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.post("/app/api/pacientes/buscar", headers, body, callback);
  },
  savePatient(body, callback) {
    console.log("error");
    const headers = { Authorization: window.amplify.store("token") };
    http.post("/app/api/pacientes/guardar", headers, body, callback);
  },
  getEntities(callback) {
    http.get("/api/pacientes/cargarEntidades", {}, callback);
  },
  getAcademicTraining(callback) {
    http.get("/api/pacientes/cargarformacionAcademica", {}, callback);
  },
  getMunicipios(body, callback) {
    http.post("/api/pacientes/cargarMunicipios", {}, body, callback);
  },
  getLocalidades(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.post("/api/pacientes/cargarLocalidades", headers, body, callback);
  },
  getNationalities(callback) {
    http.get("/api/pacientes/cargarNacionalidades", {}, callback);
  },
  getIndigenousLanguages(callback) {
    http.get("/api/pacientes/cargarLenguasIndigenas", {}, callback);
  },
  getReligions(callback) {
    http.get("/api/pacientes/cargarReligiones", {}, callback);
  },
  getTypeAddress(callback) {
    http.get("/api/pacientes/cargarTiposDomicilios", {}, callback);
  },
  editPatients(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    console.log("body.id", body.id);
    http.put(`/app/api/pacientes/${body.id}/editar`, headers, body, callback);
  },
  editPatientPhoto(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.post(`/app/api/pacientes/${body.id}/foto`, headers, body, callback);
  },
  sendMessage(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.post(`/app/api/pacientes/correo/consulta`, headers, body, callback);
  },
  getMeetings({ id_paciente: id, ...body }, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.post(`/app/api/pacientes/${id}/citas`, headers, body, callback);
  },
};

import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setDisplaySidebar,
} from "app/containers/Think/actions";

import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";

import notifications from "app/utils/notifications";

import repository from "app/utils/repository";
import ThinkForm from "app/material-ui/ThinkForm";
import ConfirmAlert from "app/material-ui/ConfirmAlert";
import SubMenuModulos from "app/material-ui/SubMenuModulos";
import QRReader from "app/material-ui/QRReader";
import useMeasure from 'react-use-measure';

import api from "./api";

const Index = ({
  catalogo,
  catalogos,
  data,
  opciones,
  changeModulo,
  setLoading,
}) => {
  const [ref, { height, width }] = useMeasure();
  const [opcion, setOpcion] = React.useState(null);
  const [acciones, setAcciones] = React.useState([]);
  const [functions, setFunctions] = React.useState(null);
  const [registro, setRegistro] = React.useState({
    id: data?.id || null,
    detalle: {
      id_paciente: data?.id || null,
      ...(data || {}),
    },
  });

  console.log("=========>>", data, registro);

  const [modalNotificacion, setModalNotificacion] = React.useState({
    open: false,
    title: null,
    message: null,
  });

  const openModal = (modal, open = true) => {
    switch (modal) {
      case "notificacion":
        setModalNotificacion((prev) => ({ ...prev, open }));
        break;
    }
  };

  React.useEffect(() => {
    const opcion = opciones.find(
      (v) => v.id === (data?.id ? "editar" : "nuevo")
    );
    const acciones = (opcion?.acciones || [])
      .map((v) => ({
        ...v,
        action: () => handleAction(v),
      }))
      .filter((v) => v.id !== "limpiar");

    setOpcion(opcion);
    setAcciones(acciones);

    if (data) {
      setRegistro({
        id: data.id || null,
        detalle: {
          id_paciente: data.id || null,
          ...data,
        },
      });
    }
  }, [data, functions]);

  React.useEffect(() => {
    if (functions) {
      console.log(functions);
      console.log("GETREGISTRO", functions.getRegistro());
      console.log(registro);
    }

    if (functions) functions.setRegistro(registro);
  }, [registro, functions]);

  const addPatient = (data) => {
    console.log("ADDDATA", data);
    setRegistro({ ...data });
  };

  const handleAction = (action) => {
    switch (action.id) {
      case "limpiar":
        functions.limpiar();
        break;
      case "guardar":
        guardar();
        break;
    }
  };

  const guardar = (enlace = false) => {
    const px = functions.getRegistro();

    const errors = repository.validationBody(px, catalogo, catalogos);
    if (errors.noNulls.length || errors.noSuccess.length) {
      return showErrorsForm(errors);
    }

    setLoading("Guardando Paciente...");
    api
      .saveEditPatient(px, enlace)
      .then((res) => {
        if (res.peticion) {
          notifications.success(
            px.detalle.id
              ? "El paciente se editó correctamente"
              : "El paciente se guardó correctamente"
          );
          changeModulo();
        } else {
          const { vc_nombre, vc_curp, vc_email } = res.paciente;
          setModalNotificacion({
            open: true,
            title: `Paciente ${vc_nombre} ¡Ya es parte de Thin-k!`,
            message: vc_email
              ? `¿Desea enlazar la cuenta con el correo: ${vc_email}?`
              : `Se enlazará la cuenta al CURP: ${vc_curp}`,
            onCancel: () => openModal("notificacion", false),
            onAccept: () => {
              openModal("notificacion", false);
              guardar(true);
            },
          });
        }
      })
      .catch((message) =>
        setModalNotificacion({ open: true, title: "¡Ups!", message })
      )
      .finally(() => setLoading(null));
  };

  const showErrorsForm = (errors) => {
    console.log(errors);
    setModalNotificacion({
      open: true,
      title: errors.title,
      message: (
        <Box>
          {!!errors.noNulls.length && (
            <Box>
              <Typography>No deben estar vacios</Typography>
              <Divider />
              <List dense>
                {errors.noNulls.map((v, i) => (
                  <ListItem key={i}>
                    <ListItemText primary={v} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {!!errors.noSuccess.length && (
            <Box>
              <Typography>Formato invalido</Typography>
              <Divider />
              <List dense>
                {errors.noSuccess.map((v, i) => (
                  <ListItem key={i}>
                    <ListItemText primary={v} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
      ),
    });
  };

  return (
    <Box
      ref={ref}
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      flexGrow={1}
      bgcolor="rgba(200,200,200,0.2)"
    >
      <SubMenuModulos
        actions={acciones}
        // scanner={<QRReader setRegistro={addPatient} />}
      />
      {/* <Box flex="1 1 auto" height="0px" overflow="scroll"> */}
      <Box flexGrow={1} height={height - 97} overflowY="hidden" padding='15'>
        <ThinkForm
          type="form"
          setFunctions={setFunctions}
          withOutHeader
          registro={registro}
          catalogo={catalogo}
        />
      </Box>

      {modalNotificacion.open && (
        <ConfirmAlert
          title={modalNotificacion.title}
          message={modalNotificacion.message}
          onCancel={modalNotificacion.onCancel}
          onAccept={() =>
            modalNotificacion.onAccept
              ? modalNotificacion.onAccept()
              : openModal("notificacion", false)
          }
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  const catalogo = catalogos.find((e) => e.vc_nameTable === "ins_pacientes");

  return {
    catalogos,
    catalogo,
  };
};

const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setDisplaySidebar,
};

export default connect(mapStateToProps, actions)(Index);

import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";

export default React.forwardRef((props, ref) => {
  const { print = {} } = props;
  const institucion = window.amplify.store("institution") || {};
  const usuario = (window.amplify.store("user") || {}).usuario || {
    vc_nombre: "",
  };
  let cajero = `${usuario.vc_nombre} ${usuario.vc_apellidoPaterno || ""} ${
    usuario.vc_apellidoMaterno || ""
  }`;

  if (print.vc_nameUser) cajero = print.vc_nameUser;

  const { cashRegister, pays, monto, saldo } = print;

  console.log(print);

  const fecha = print.dt_end
    ? moment(print.dt_end).format("LL")
    : moment().format("LL");
  const { cut = {} } = cashRegister;
  const { nu_initReceived = 0 } = cut;
  const suma = pays.reduce((sum, v) => sum + v.nu_quantity, 0);
  const total = suma + nu_initReceived;
  const cobros = [
    {
      title: "Efectivo",
      total: pays
        .filter((v) => v.id_method == "efectivo")
        .reduce((sum, v) => sum + v.nu_quantity, 0),
    },
    {
      title: "Cheque",
      total: pays
        .filter((v) => v.id_method == "cheque")
        .reduce((sum, v) => sum + v.nu_quantity, 0),
    },
    {
      title: "Tarjeta",
      total: pays
        .filter((v) => v.id_method == "tarjeta")
        .reduce((sum, v) => sum + v.nu_quantity, 0),
    },
    {
      title: "Transferencia",
      total: pays
        .filter((v) => v.id_method == "transferencia")
        .reduce((sum, v) => sum + v.nu_quantity, 0),
    },
    {
      title: "Vales de Descuento",
      total: pays
        .filter((v) => v.id_method == "vale")
        .reduce((sum, v) => sum + v.nu_quantity, 0),
    },
  ];

  const el = document.createElement("div");
  el.setAttribute("id", "print");
  const formatter = (v) =>
    "$ " + (v * 1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

  const onPrintClose = (callback) => () => {
    window.onafterprint = () => null;
    el.remove();
    callback();
  };

  React.useImperativeHandle(ref, () => ({
    print() {
      return new Promise((resolve, reject) => {
        document.body.insertAdjacentElement("afterbegin", el);
        window.onafterprint = onPrintClose(resolve);
        window.print();
      });
    },
  }));

  const printComponent = (
    <div
      style={{
        width: "100%",
        maxWidth: "350px",
        color: "black",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: ".75rem",
      }}
    >
      <style>
        {" "}
        {` #print{ display: none; z-index: 999999; position: relative; background-color: white; } @media print { body > *:not(#print) { display: none; } #print { display: block; } } `}{" "}
      </style>

      <img
        style={{ maxWidth: "150px", marginBottom: "30px" }}
        src={institucion.vc_urlLogo}
      />
      <h5
        style={{ margin: 0, textTransform: "uppercase", textAlign: "center" }}
      >
        {institucion.vc_razonSocial}
      </h5>
      <h5
        style={{ margin: 0, textTransform: "uppercase", textAlign: "center" }}
      >
        calle {institucion.vc_calle} Col. {institucion.vc_colonia} CP{" "}
        {institucion.vc_codigoPostal}
      </h5>
      <p style={{ margin: "0" }}>{fecha}</p>
      <p style={{ margin: "0" }}>Cajero: {cajero}</p>

      {/* <h5 style={{ margin: 0, textTransform: 'uppercase', textAlign: 'center' }}>Folio</h5> */}

      <table style={{ margin: "20px 0", width: "100%" }}>
        <tbody>
          <tr>
            <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>
              Monto Inicial
            </td>
            <td style={{ whiteSpace: "nowrap", textAlign: "right" }}>
              {formatter(nu_initReceived)}
            </td>
          </tr>

          {cobros.map((v, i) => (
            <tr key={i}>
              <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>
                {v.title}
              </td>
              <td style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                {formatter(v.total)}
              </td>
            </tr>
          ))}

          <tr>
            <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>Total</td>
            <td style={{ whiteSpace: "nowrap", textAlign: "right" }}>
              {formatter(suma)}
            </td>
          </tr>
          <tr>
            <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>
              Monto a Retirar
            </td>
            <td style={{ whiteSpace: "nowrap", textAlign: "right" }}>
              {formatter(monto)}
            </td>
          </tr>
          <tr>
            <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>
              Saldo de Caja
            </td>
            <td style={{ whiteSpace: "nowrap", textAlign: "right" }}>
              {formatter(saldo)}
            </td>
          </tr>
        </tbody>
      </table>

      <div
        style={{
          width: "70%",
          borderTop: "1px solid black",
          marginTop: "70px",
          padding: 5,
          textAlign: "center",
        }}
      >
        Firma de cajero
      </div>
    </div>
  );

  return (
    <div>
      {props.children}
      {ReactDOM.createPortal(printComponent, el)}
    </div>
  );
});

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  updateCatalogs,
  setState,
} from "../../../containers/Think/actions";
import internalAPI from "../../../utils/internalAPI";
import ListadoCatalogos from "./ListadoCatalogos";
import ListadoRegistrosCatalogos from "./ListadoRegistrosCatalogos";
import ThinkForm from "../../../material-ui/ThinkForm";
import { Paper } from "@material-ui/core";
import moment from "moment";
const modulo = "backoffice";
class CatalogosView extends React.Component {
  constructor() {
    super();
    this.state = {
      searchText: "",
    };
  }
  componentDidMount() {
    if (!this.props.state.opcion) {
      this.props.setState({ modulo, state: { opcion: "listadoCatalogos" } });
    }
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    console.log("catalogos");
  }
  updateCatalogs() {
    const dt_actualizacion = moment().add(-1, "M").format("YYYY-MM-DD");
    internalAPI.updateCatalogs(
      {
        actualizacion: [
          {
            id_catalogo: 3,
            dt_actualizacion,
          },
          {
            id_catalogo: 5,
            dt_actualizacion,
          },
          {
            id_catalogo: 6,
            dt_actualizacion,
          },
          {
            id_catalogo: 7,
            dt_actualizacion,
          },
          {
            id_catalogo: 9,
            dt_actualizacion,
          },
          {
            id_catalogo: 10,
            dt_actualizacion,
          },
          {
            id_catalogo: 17,
            dt_actualizacion: "2017-01-01",
          },
          {
            id_catalogo: 24,
            dt_actualizacion: "2019-01-01",
          },
        ],
      },
      (err, response) => {
        if (err) {
          console.log("Error: ", err);
        } else {
          this.props.updateCatalogs(response);
        }
      }
    );

    internalAPI.updatePreguntas(
      {
        dt_actualizacion,
      },
      (err, response) => {
        if (err) {
          console.log("Error: ", err);
        } else {
          this.props.updateCatalogs([
            {
              vc_nombreTabla: "cat_preguntas",
              registros: response,
            },
          ]);
        }
      }
    );
  }
  renderContent() {
    switch (this.props.state.opcion) {
      case "listadoRegistrosCatalogos":
        return <ListadoRegistrosCatalogos />;
      case "editorRegistros":
        return (
          <Paper
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(200,200,200,0.2)",
            }}
          >
            <ThinkForm
              backButton={() => {
                console.log("listado");
                this.updateCatalogs();
                this.props.setState({
                  modulo,
                  state: { opcion: "listadoRegistrosCatalogos" },
                });
              }}
              tags={[]}
            />
          </Paper>
        );
      default:
        return <ListadoCatalogos />;
    }
  }

  render() {
    return this.renderContent();
  }
}

CatalogosView.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  updateCatalogs: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    state: state.Think[modulo] || null,
  };
};
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  updateCatalogs,
  setState,
};
export default connect(mapStateToProps, actions)(CatalogosView);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input, Button, Grid, GridColumn, Card, Icon, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setHelperSidebarRender, resetHelperSidebarRender, updateCatalogs } from '../../actions';
import { VideoCustom } from '../../../../components/VideoCustom';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import ReactAudioPlayer from 'react-audio-player';
import { NoColors } from 'three';
import moment from 'moment';
class NewPublications extends Component {
	constructor(props) {
        super(props);
        this.search = null;

        this.state = {
            
        };
	}

	componentDidMount() {

    }

	componentWillUnmount() {

    }


  // ----------------------
  // ------ methods -------
  // ----------------------
    renderPublicationsMedia(media) {
        switch (media.id_tipo) {
            case 1:
            return (
                <VideoCustom
                src={media.vc_url}
                />               
            );
            case 2:
            return (
                <Image src={media.vc_url} 
                style={{ margin: 0, padding: 0, borderRadius: 5,backgroundColor: 'transparent'}}
                />
            );
            case 3:
            return (
                <ReactAudioPlayer
                src={media.vc_url}
                />
            );
            default:{
                return null;
            }
        }
    }
    // renderModalMedia() {
    //     return (
    //         <AliceCarousel
    //         mouseDragEnabled
    //         responsive={{
    //             0: { items: 1 },
    //             1024: { items: 2 },
    //             }}
    //         style={{maxHeight: window.screen.availHeight - 300, width:'100%', backgrounColor: 'red'}}
    //         >
    //             {this.renderPublicationsMedia(publication)}
    //         </AliceCarousel>
    //     )
    // }
    renderMediaContent(publication, index) {
        const media = publication.media || [];
        return (
            <Grid
            style={{ backgrounColor:window.colors.black, height: '100%', padding: 0, overflow: 'hidden'}}
            >
                <Grid.Row
                style={{width: '100%', maxHeight: '100%'}}
                >
                {
                    media.length === 1 ?
                    <Grid.Column
                    key={0}
                    style={{width: '100%', margin: 0, padding: 0, height: '100%'}}
                    >
                        {this.renderPublicationsMedia(media[0])}
                    </Grid.Column>
                    : 
                    media.length > 1 ?

                    <Grid.Column
                    key={0}
                    style={{width: '50%', margin: 0, padding: 0, height: '100%'}}
                    >
                        {this.renderPublicationsMedia(media[0])}
                    </Grid.Column>
                    : <div/>
                }
                <Grid.Column
                key={1}
                style={{flex: 1, flexDirection: 'column', margin: 0, padding: 0, height: '100%'}}
                >
                    {
                        media.map((media, index) => {
                            if (index > 0 && index < 3)
                            return (
                                <Grid.Row
                                key={index}
                                style={{flex:1}}
                                >
                                    {this.renderPublicationsMedia(media)}
                                </Grid.Row>
                            );
                        })
                    }
                </Grid.Column>
                
                </Grid.Row>
                
                
            </Grid>
        )
    }

    renderContent() {
        return this.props.publications.map((publication, index) => {
            const usuario = {
                vc_nombre: 'Thin-k Soluciones Médicas',
                ...publication.usuario
            }
            const usuarioCompartir = {
                vc_nombre: 'Thin-k Soluciones Médicas',
                ...publication.usuarioCompartir
            }
            return (
                <Card
                key={index}
                style={{width: '100%', margin: 0, marginTop: 10, padding: 10, overflow: 'hidden'}}
                >
                    <Card.Content>
                        <Card.Header>{usuario.vc_nombre}</Card.Header>
                        <Card.Meta>
                            <span className='date'>{moment(publication.dt).format('D MMMM YYYY')}</span>
                        </Card.Meta>
                        <Card.Description>{usuarioCompartir.vc_descripcion}</Card.Description>
                    </Card.Content>
                    <Card.Content
                    style={{width: '100%', margin: 0, padding: 0, height: window.innerHeight - 400 }}
                    >
                        {this.renderMediaContent(publication, index)}
                        
                    </Card.Content>
                    <Card.Content extra>
                    <a>
                        <Icon name='comments' />
                        {publication.comentarios ? publication.comentarios.length : 0}
                    </a>
                    </Card.Content>
                </Card>
            )
        })
        
    }
	render() {
		return (
            <div style={{ width: '100%', height: window.innerHeight, overflowY: 'scroll', WebkitOverflowScrolling: 'touch', padding: 30}}>
                { this.renderContent() }
            </div>
		);
	}
}

export default (NewPublications);

import React from 'react';
import { connect } from 'react-redux';
import HeaderPrint from './Print/HeaderPrint';
import PrintForm from '../components/PrintForm';
import { Grid } from '@material-ui/core';
import { timeSince } from '../utils/repository'
import moment from 'moment';
class PrintFormInstitution extends React.Component {
    constructor() {
        super();
        this.state = {
            someKey: 'someValue'
        };
    }
    getDatos() {
        const { date } = this.props;
        const h = window.screen.height;
        let paciente = 'no especificado, la nota no podrá ser guardada.'
        let sexo = 'sexo: no especificado'
        let edad = 'edad: no especificada'
        const pacienteObj = this.props.pacienteActual || {};
        let sn_paciente = true
        if (pacienteObj.detalle) {
            edad = `edad: ${moment().diff(moment(pacienteObj.detalle.dt_nacimiento, 'YYYY-MM-DD'), 'years')}`
            sexo = pacienteObj.detalle.id_sexo == 1 ? 'sexo: masculino' : (pacienteObj.detalle.id_sexo == 2 ? 'sexo: femenino' : 'sexo: no especificado')
            if (pacienteObj.detalle.vc_apellidoMaterno != undefined) {
                paciente = `${pacienteObj.detalle.vc_nombre}` + ' ' + `${pacienteObj.detalle.vc_apellidoPaterno}` + ' ' + `${pacienteObj.detalle.vc_apellidoMaterno}`;
            } else {
                paciente = `${pacienteObj.detalle.vc_nombre}` + ' ' + `${pacienteObj.detalle.vc_apellidoPaterno}`
            }
        } else {
            sn_paciente = false
        }

        const usuario = window.amplify.store('user').usuario;
        const institucion = window.amplify.store('institution');
        const nombreUsuario = `${usuario.vc_nombre} ${usuario.vc_apellidoPaterno} ${usuario.vc_apellidoMaterno}`;

        const nombreInstitucion = `${institucion.vc_nombre}`;
        const prefijo = usuario.id_sexo === 1 ? 'Dr. ' : 'Dra.';
        const rol = (institucion.roles || []).filter(e => e.id_especialidad && e.vc_cedulaProfesional)[0];
        const cedulaTitulo = this.cedulaTitulo(rol);
        const especialidad = cedulaTitulo.especialidad;

        return {
            nombreInstitucion,
            especialidad,
            paciente,
            cedulaTitulo,
            rol,
            sexo,
            edad,
            sn_paciente
        }
    }
    cedulaTitulo(rol) {
        if (rol) {
            const especialidad = this.props.especialidades.find((e) => e.id === rol.id_especialidad);
            return {
                especialidad: especialidad ? especialidad.vc_alias : '',
                vc_cedulaProfesional: rol.vc_cedulaProfesional,
                vc_registroSSA: rol.vc_registroSSA
            };
        }
        return {
            especialidad: '',
            vc_cedulaProfesional: '',
            vc_registroSSA: ''
        }

    }
    render() {
        const { nombreInstitucion, especialidad, paciente, cedulaTitulo, rol, sexo, edad, sn_paciente } = this.getDatos()
        console.log('contente', this.props.content)
        return (
            <PrintForm
                header={
                    <HeaderPrint
                        module={this.props.module || 'Nota'}
                    />
                }
                content={
                    this.props.content
                }
                rol={rol}
                footer={
                    <Grid style={{ width: '100%', margin: 0, marginBottom: 0, padding: 0, color: 'black' }}>
                        <div style={{ display:'flex', alignContent: 'center', width: '100%', height: 50, margin: 0, marginBottom: 5, padding: 0, alignItems: 'center', justifyContent:'center' }}>
                            <div style={{ textAlign: 'center', width: '35%', height: 50, margin: 0, marginBottom: 5, padding: 0, alignItems: 'center' }}>
                                <div style={{ float: 'center', textAlign: 'center', width: '100%', height: 1, margin: 0, backgroundColor: 'black' }}></div>
                                <div style={{ float: 'center', textAlign: 'center', width: '100%', fontSize: 13, height: 20, margin: 0, marginTop: 5 }}>{cedulaTitulo.especialidad}</div>
                                <div style={{ float: 'center', textAlign: 'center', width: '100%', fontSize: 13, height: 20, margin: 0 }}>Ced. Prof.: {cedulaTitulo.vc_cedulaProfesional} - Reg. SSA: {cedulaTitulo.vc_registroSSA}</div>
                            </div>
                        </div>
                        <div key={'foot'} style={{ float: 'bottom', textAlign: 'center', fontSize: 12, width: '100%', height: 10, margin: 0, borderTop: '1px solid red' }}>
                            {!!rol && rol.vc_direccionReceta ? rol.vc_direccionReceta : 'Dirección no especificada'}
                        </div>
                    </Grid>
                }
                marginFooter={30}
            />
        );
    }

    componentDidMount() {
        this.setState({
            someKey: 'otherValue'
        });
    }
}
const mapStateToProps = (state) => {
    const { diagnosticos, especialidades, pacienteActual, niveles } = state.Think;
    return { diagnosticos, pacienteActual, especialidades, niveles };
};
export default connect(mapStateToProps)(PrintFormInstitution);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Modal } from 'semantic-ui-react';
import { START_VIEW_TRANSITION } from '../../../../constants';
import eventManager from '../../../../utils/eventManager';
import notifications from '../../../../utils/notifications';
import { AutoForm, YellowButton } from '../../../../components';
import * as firebase from 'firebase/app';
import { login, loginWithOutInstitution, updateCatalogs } from '../../actions';
import OptionsSelector from './optionsSelector';
import UsuariosHelpBar from './usuariosHelpBar';
import { ThemeProvider } from '@material-ui/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { teal } from '@material-ui/core/colors';
import './login.css';
import RegistroUsuario from './RegistroUsuario';
import { SwipeableDrawer, Box, Drawer, TextField, InputBase, Input, FormControl, Paper } from '@material-ui/core';
import ConfirmAlert from '../../../../material-ui/ConfirmAlert';
import RecuperacionUsuarioModal from './RecuperacionUsuarioModal';
const themeSelected = window.amplify.store('theme') || null;

const { Column, Row } = Grid;
let theme = createMuiTheme({
	overrides: {
		MuiDrawer: {
			paperAnchorBottom: {
				height: '100%'
			}
		}
	},
	palette: {
		primary: themeSelected ? themeSelected.primary : teal,
		secondary: {
			main: '#fafafa'
		},
		type: themeSelected ? themeSelected.typeMain : 'dark'
	}
});


class LoginView extends Component {
	constructor(props) {
		super(props);
		console.disableYellowBox = true;
		this.app = firebase;
		this.auth = this.app.auth();
		this.storage = this.app.storage().ref();

		this.state = {
			registroDisplay: false,
			loading: false,
			result: null,
			optionsModal: false,
			loginOk: false,
			notification: null
		};
	}

	render() {
		const { notification } = this.state;
		return (
			<ThemeProvider theme={theme}>
				<Grid style={{...styles.container}} >
				<div style={{height: '100%', width: '100%', position: 'absolute', backgroundColor: themeSelected ? themeSelected.typeMain === 'light' ? 'rgba(50,50,50,1)' : 'black' : 'black' }}/>
				<div style={{height: '100%', width: '100%', position: 'absolute', backgroundImage: 'linear-gradient(rgba(100,100,100,0.1),rgba(100,100,100,0.5))'}}/>
				<div style={{ opacity: 0.05, height: '100%', width: '100%', position: 'absolute',backgroundImage:'url(assets/images/background.png)', backgroundSize:'40vh 100vh', backgroundRepeat: 'repeat'}}/>
					<div className="background">
						<div className="background-container">
							<div className="triangle"></div>
						</div>
					</div>
					<div className="contenido">
						<div className="col-1">
							<div className="logo" style={{...styles.logo}} ></div>
						</div>
						<div className="col-2">
							<div className="formulario">
								{this.renderModule()}
							</div>
						</div>
					</div>
					{this.renderUsuarioRegistro()}
					{this.renderUsuarioRecuperacion()}
					{notification &&
						<ConfirmAlert
							title={notification.title || ''}
							message={notification.message || ''}
							onAccept={() => {
								this.setState({ notification: null })
							}}
						/>
					}

				</Grid>
			</ThemeProvider>

		);
	}

	// ---------------------------------
	// ------ life cycle events --------
	// ---------------------------------

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions.bind(this));
		document.onkeypress = this.modifyEnterKeyPressAsTab.bind(this);
		const myParams = window.location.href.split('?')[1] && window.location.href.split('?')[1].split('&');
		let vc_email = null;
		let vc_token = null;
		(myParams || []).map((param, index) => {
			const arrayObj = param.split('=')
			if (arrayObj.length === 2) {
				switch (arrayObj[0]) {
					case 'email': {
						vc_email = arrayObj[1]
					}
					case 'token': {
						vc_token = arrayObj[1]
					}
				}
			}
		})
		this.setState({ recoveryModal: true, vc_email, vc_token })
	}

	componentWillUnmount() {
		document.onkeypress = null;
		// window.removeEventListener('resize', this.updateDimensions.bind(this));
	}

	// ----------------------
	// --- render methods ---
	// ----------------------

	renderModule() {
		return this.renderContent()
	}
	renderUsuarioRegistro() {
		return (
			<Drawer
				PaperProps={{
					style: {
						backgroundColor: 'transparent',
						boxShadow: 'none',
						overflow: 'hidden',
						padding: window.isMobile ? 10 : 100
					}
				}}
				anchor="bottom"
				open={this.state.registroDisplay}
				onClose={() => {
					this.setState({ registroDisplay: false });
				}}
				onOpen={() => {
				}}
			>
				<Paper style={{ width: '100%', height: '100%', overflow: 'hidden', borderRadius: 10 }}>
					<Paper style={{ width: '100%', height: '100%', overflow: 'hidden', backgroundColor: 'rgba(200,200,200,0.2)' }}>
						<RegistroUsuario
							backButton={() => {
								this.setState({ registroDisplay: false });
							}}
						/>
					</Paper>
				</Paper>
			</Drawer>
		)
	}
	renderInputs() {
		const fields = this.getInputs()
		const regExp = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
		const valEmail = regExp.test(fields.vc_email)
		return (
			<Grid style={{ padding: window.isMobile ? 0 : 200 }}>
				<FormControl fullWidth>
					<Input
						key={fields.vc_email.fieldid}
						id={fields.vc_email.fieldid}
						field={fields.vc_email.key}
						name={fields.vc_email.fieldid}
						readOnly={fields.vc_email.readOnly || false}
						label={fields.vc_email.vc_name}
						type={fields.vc_email.type}
						disableUnderline
						fullWidth
						placeholder={fields.vc_email.name}
						inputProps={{
							placeholder: fields.vc_email.name,
							style: {
								padding: '9px 18px',
								borderRadius: 25,
								height: 25,
								border: '1px solid white',
								backgroundColor: 'rgba(255,255,255, 0.3)'
							}
						}}
						errormessage={!valEmail ? 'E-mail no valido' : ''}
					/>
				</FormControl>
				<FormControl fullWidth>
					<Input
						key={fields.vc_password.fieldid}
						id={fields.vc_password.fieldid}
						field={fields.vc_password.key}
						name={fields.vc_password.fieldid}
						readOnly={fields.vc_password.readOnly || false}
						label={fields.vc_password.vc_name}
						type={fields.vc_password.type}
						disableUnderline
						fullWidth
						margin='dense'
						placeholder={fields.vc_password.name}
						inputProps={{
							placeholder: fields.vc_password.name,
							style: {
								padding: '8px 18px',
								borderRadius: 25,
								height: 25,
								border: '1px solid white',
								backgroundColor: 'rgba(255,255,255, 0.3)'
							}
						}}
						style={{ marginTop: 16, minWidth: window.isMobile ? null : 300 }}
					/>
					<div className="acciones">
						<YellowButton
							label='Iniciar sesión'
							onClick={this.onButtonClick.bind(this)}
							loading={this.state.loading}
						/>
					</div>
				</FormControl>
				<div className="extra">
					<a style={{ color:window.colors.itemSelected }} onClick={() => {
						this.recuperarCuenta();
					}} >
						Recuperar Cuenta
					</a>
					<a style={{ color:window.colors.itemSelected }} onClick={() => { this.setState({ registroDisplay: true }); }} >
						Registrarse
					</a>
				</div>
			</Grid>
		)
	}
	renderContent() {
		return (
			<div className="login">
				{this.renderInputs()}
			</div>
		);
	}



	// ----------------------
	// ------  methods ------
	// ----------------------

	updateDimensions() {
		console.log('render')
		this.forceUpdate();
	}
	validateFieldsLogin() {

	}
	onButtonClick() {

		if (this.state.optionsModal) {
			this.setState({ result: null, optionsModal: false, loading: false });
		} else {
			let formData = {};
			Object.values(this.getInputs()).map((field, index) => {
				console.log('key', field.key)
				const renderField = document.getElementById(field.key)
				if (renderField.value) {
					formData[field.key.replace('Login', '')] = renderField.value
				}
			})
			console.log('form', formData)
			if (!formData.vc_email || !formData.vc_password) {
				this.setState({ result: null, optionsModal: false, loading: false, notification: { title: '¡Ups!', message: formData.Message || 'Verifica los campos.' } });
			} else if (!this.state.optionsModal) {
				const body = {
					...formData,
					vc_uuid: 'web',
					vc_descripcion: 'mac'
				};
				this.setState({ result: null, loading: true });
				window.$.ajax({
					url: `${window.backEndURL}/app/login`,
					data: body,
					method: 'POST'
				}).then((data, status, xhr, error) => {
					console.log('hello')
					this.loginFirebase(body);
					const result = {
						...data,
						token: xhr.getResponseHeader('Authorization'),
						institution: data.instituciones[0],
						roles: data.instituciones[0] ? data.instituciones[0].roles[0] || [] : []
					};
					this.selected(result);
					// this.fowardSelector(result, { institution: null, rol: null, agenda: null });
				}).fail((data, status, xhr, error) => {
					console.log('regreso', data)
					if (data.responseJSON) {
						this.setState({ result: null, optionsModal: false, loading: false, notification: { title: '¡Ups!', message: data.responseJSON.error || 'Verifica tu conexión a internet.' } });
					} else if (error) {
						console.log('error', error)
						this.setState({ result: null, optionsModal: false, loading: false, notification: { title: '¡Ups!', message: error || 'Verifica tu conexión a internet.' } });
					} else {
						this.setState({ result: null, optionsModal: false, loading: false, notification: { title: '¡Ups!', message: 'Verifica tu conexión a internet.' } });
					}
				});
			}
		}
	}

	getInputs() {
		const fields = {
			vc_email: {
				key: 'vc_emailLogin',
				fieldid: 'vc_emailLogin',
				required: true,
				name: 'E-mail',
				fullWidth: true,
				label: 'E-mail',
				type: 'email'
			},
			vc_password: {
				key: 'vc_passwordLogin',
				fieldid: 'vc_passwordLogin',
				required: true,
				name: 'Contraseña',
				fullWidth: true,
				label: 'Contraseña',
				type: 'password'
			}
		};
		return fields;
	}

	loginFirebase(user) {
		firebase.auth().signInWithEmailAndPassword(user.vc_email, user.vc_password).catch(function (error) {
			if (error) {
				console.log('login firebase', firebase.auth().currentUser)

				firebase.auth().createUserWithEmailAndPassword(user.vc_email, user.vc_password).catch(function (error) {
					// Handle Errors here.
					if (!error) {
						console.log('login firebase')
					} else {
						console.log('no login firebase')
						// firebase.auth().sendPasswordResetEmail(user.vc_email).catch(function (error) {
						// 	console.log('no login firebase', error)
						// })
					}
				});
			} else {
				console.log('login firebase', firebase.auth().currentUser)
			}
		});

	}

	fowardSelector(result, stateEdit) {
		const state = stateEdit;
		if (result.instituciones.length > 0 && state.institution == null) {
			if (result.instituciones.length === 1) {
				state.institution = result.instituciones[0];
			}
		}
		if (state.institution != null) {
			if (state.institution.roles.length > 0 && state.rol == null) {
				if (state.institution.roles.length === 1) {
					state.rol = state.institution.roles[0];
				}
			}
			if (state.institution.agendas.length > 0 && state.agenda == null) {
				if (state.institution.agendas.length === 1) {
					state.agenda = state.institution.agendas[0];
				}
			}
		}

		if (state.institution && state.rol && state.agenda) {
			const resultFinal = {
				...result,
				...state
			};
			this.setState({ optionsModal: false }, () => { this.props.login(resultFinal); });
		} else if (result.instituciones.length === 0) {
			const resultFinal = {
				...result,
				...state
			};
			this.setState({ optionsModal: false }, () => { this.props.loginWithOutInstitution(resultFinal); });
		} else {
			this.setState({ result, optionsModal: true, loading: false });
		}
	}

	modifyEnterKeyPressAsTab(e) {
		if (e && e.keyCode === 13) {
			this.onButtonClick();
		}
	}

	selected(optionsSelected) {
		const result = {
			...this.state.result,
			...optionsSelected
		};
		this.setState({ optionsModal: false }, () => { this.props.login(result); });
	}

	returnHome() {
		eventManager.emit(START_VIEW_TRANSITION, '#/admin');
	}

	recuperarCuenta() {
		let formData = {};
		Object.values(this.getInputs()).map((field, index) => {
			const renderField = document.getElementById(field.key)
			if (renderField.value) {
				formData[field.key] = renderField.value
			}
		})
		const regExp = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
		const valEmail = regExp.test((formData.vc_emailLogin || '').toLowerCase())

		if (formData.vc_emailLogin && valEmail) {
			const body = {
				vc_email: formData.vc_emailLogin,
				vc_uuid: 'web',
				vc_descripcion: 'mac'
			};
			this.setState({ result: null, loading: true });
			window.$.ajax({
				url: `${window.backEndURL}/app/resetPasswordMail/${body.vc_email}`,
				method: 'GET'
			}).then((data, status, xhr, error) => {
				if (data.message) {
					this.setState({ result: null, optionsModal: false, loading: false, notification: { title: data.message, message: '' } });
				}
			}).fail((data, status, xhr, error) => {
				this.setState({ result: null, optionsModal: false, loading: false, notification: { title: '!Ups¡', message: error || 'comprueba tu conexión a internet' } });
			});
		} else {
			this.setState({ result: null, optionsModal: false, loading: false, notification: { title: 'Ingresa un E-Mail valido.' } });
		}
	}
	renderUsuarioRecuperacion() {
		return (
			<Modal open={!!(this.state.vc_email && this.state.vc_token && this.state.recoveryModal)} size='mini' basic>
				<RecuperacionUsuarioModal
					vc_email={this.state.vc_email}
					vc_token={this.state.vc_token}
					backButton={() => {
						this.setState({ registroDisplay: false, recoveryModal: false });
					}}
				/>
			</Modal>
		)
	}

}


const styles = {
	container: {
		position: 'relative',
		height: '100%',
		marginTop: 0,
		// filter: 'blur(0px)',
		// background: 'rgba(0, 0, 0, 0.85)',
		backgroundColor: '#565656'
	},
	backLayer: {
		position: 'absolute',
		margin: 0,
		marginRight: 0,
		width: '100%',
		height: '100%',
		filter: 'blur(50px)',
		// backgroundImage: 'url(assets/images/quirofano.jpg)',
		backgroundImage: 'linear-gradient(white, gray)',
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	},
	logoSmall: {
		position: 'relative',
		marginTop: 25,
		height: 150,
		width: 150,
		backgroundImage: 'url(assets/images/iconThin-k.png)',
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	},
	logo: {
		width: 200,
		height: 200,
		backgroundImage: 'url(assets/images/iconThin-k.png)',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		filter: themeSelected ? 'grayscale(100%)' : null,
		zIndex: 1000
	},
	loginBox: {
		padding: 0,
		width: '100%',
	},
	button: {
		width: 150,
		margin: 0,
		marginTop: 30,
		marginBottom: 20,
		borderRadius: 10,
		color: 'white'
	}
};


export default connect(null, { login, loginWithOutInstitution, updateCatalogs })(LoginView);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import formatter from '../../utils/formatter';

class HoverableButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  onClick() {
    const { onClick, option } = this.props;

    if (onClick) {
      onClick(option);
    }
  }

  isCurrentOptionSelected() {
    const { selectedIndex, index } = this.props;
    return selectedIndex === index;
  }

  renderLabel() {
    const { option } = this.props;

    if (this.isCurrentOptionSelected()) {
      return <strong>{ option.label }</strong>;
    }

    return <span>{ option.label }</span>;
  }

  render() {
    const style = { ...buttonStyle };

    if (this.state.isHovering) {
      style.backgroundColor = 'rgb(210, 210, 210)';
    }

    if (this.isCurrentOptionSelected()) {
      style.backgroundColor = '#1584D3';
      style.color = 'white';
      style.boxShadow = '0px 0px 5px #ffffff66';
      style.border = '1px solid #c0c0c080';
    }

    return (
      <Button
        fluid
        style={style}
        onClick={this.onClick.bind(this)}
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        { this.renderLabel() }
      </Button>
    );
  }
}


const buttonStyle = {
  marginBottom: 5,
  backgroundColor: 'white'
};


HoverableButton.propTypes = {
  option: PropTypes.object,
  onClick: PropTypes.func,
  index: PropTypes.number,
  selectedIndex: PropTypes.number
};


export default HoverableButton;

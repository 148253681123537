import { LOGIN, LOG_OUT } from './types';

// crear y exportar las acciones
export const login = (user, sendToAdmin = true) => {
	return (dispatch) => {
		dispatch({ type: LOGIN, payload: user });
		console.log(user)
		const oneDay = 1000 * 60 * 60 * 24;
		window.amplify.store('user', user, { expires: oneDay });
		window.amplify.store('user_id',user.institution ? user.institution.id_usuario : null, { expires: oneDay });
		window.amplify.store('institution', user.institution || null, { expires: oneDay });
		window.amplify.store('roles', user.institution ? user.institution.roles || [] : [], { expires: oneDay });
		window.amplify.store('institutions', user.instituciones || [], { expires: oneDay });
		window.amplify.store('token', user.token, { expires: oneDay });
		window.amplify.store('nombre', user.usuario.vc_nombre, { expires: oneDay });
		window.amplify.store('vc_apellidoPaterno', user.usuario.vc_apellidoPaterno, { expires: oneDay });
		window.amplify.store('vc_apellidoMaterno', user.usuario.vc_apellidoMaterno, { expires: oneDay });
		window.amplify.store('vc_email', user.usuario.vc_email, { expires: oneDay });
		if( sendToAdmin ) window.location = '#/admin';
	};
};

export const loginWithOutInstitution = (user) => {
	return (dispatch) => {
		dispatch({ type: LOGIN, payload: user });
		const oneDay = 1000 * 60 * 60 * 24;
		window.amplify.store('user', user, { expires: oneDay });
		window.amplify.store('user_id', null, { expires: 1 });
		window.amplify.store('institution', null, { expires: 1 });
		window.amplify.store('roles', null, { expires: oneDay });
		window.amplify.store('rol', null, { expires: oneDay });
		window.amplify.store('token', user.token, { expires: oneDay });
		window.amplify.store('nombre', user.usuario.vc_nombre, { expires: oneDay });
		window.amplify.store('vc_apellidoPaterno', user.usuario.vc_apellidoPaterno, { expires: oneDay });
		window.amplify.store('vc_apellidoMaterno', user.usuario.vc_apellidoMaterno, { expires: oneDay });
		window.amplify.store('vc_email', user.usuario.vc_email, { expires: oneDay });
		window.location = '#/admin';
	};
};

export const logOut = () => {
	window.amplify.store('user', null, { expires: 1 });
	window.amplify.store('user_id', null, { expires: 1 });
	window.amplify.store('institution', null, { expires: 1 });
	window.amplify.store('institutions', null, { expires: 1 });
	window.amplify.store('roles', null, { expires: 1 });
	window.amplify.store('token', null, { expires: 1 });
	window.amplify.store('nombre', null, { expires: 1 });
	window.amplify.store('vc_apellidoPaterno', null, { expires: 1 });
	window.amplify.store('vc_apellidoMaterno', null, { expires: 1 });
	window.amplify.store('vc_email', null, { expires: 1 });
	window.amplify.store('rol', null, { expires: 1 });

	window.location = '#/login';

	return { type: LOG_OUT };
};

import React, { Component } from 'react';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';


class NotificationsModal extends Component {
    renderTitle() {
        if (this.props.title) {
            return (<h3>{this.props.title}</h3>);
        }
    }
    renderMessage() {
        if (this.props.message) {
            return (<h4 style={{ color: 'gray' }}>{this.props.message}</h4>);
        }
    }
    renderOptions() {
        if (this.props.options) {
            return (
                <div style={{ color: 'gray', padding: 2, height: window.innerHeight * 0.3, overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>
                    {
                    this.props.options.map((item, index) => {
                        return (
                            <div
                            key={index}
                            style={{ width: '100%', marginTop: index === 0 || index === (this.props.options.length - 1) ? 0 : 2 }}
                            onClick={() => {
                                    item.action && item.action();
                                }}
                            >
                                {item.vc_nombre}
                            </div>
                        );
                    })
                    }
                </div>
            );
        }
    }

    render() {
        const { visible } = this.props;
        return (
            <Modal
            open={visible}
            basic={this.props.basic}
            size={this.props.size}
            onClick={ () => {
                this.props.close && this.props.close();
            }}
            >
                <Header icon={this.props.icon ? this.props.icon : null} content={this.props.title ? this.props.title : null} />
                <Modal.Content>
                <p>{this.props.message ? this.props.message : ''}</p>
                </Modal.Content>
                {this.renderOptions()}
                <Modal.Actions>
                <Button basic color='red' inverted>
                    <Icon name='remove' /> {this.props.cancel ? this.props.cancel : 'Cancelar'}
                </Button>
                <Button
                color='twitter'
                inverted
                onClick={() => {
                    this.props.success();
                }}
                >
                    <Icon
                    name='checkmark'
                    /> {this.props.acept ? this.props.acept : 'Ok'}
                </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}


export default NotificationsModal;

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import Event from "./Event";
import moment from "moment";

const useStyles = makeStyles((theme) => {
  return {
    day: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      "& .title": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: `1px solid ${theme.palette.divider}`,
        "& span": { fontWeight: 600, lineHeight: 1 },
      },
      "& .content": {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        padding: "0px 0px",
        position: "relative",
      },
    },
  };
});

const Index = ({ dia, detalles, edit, events, hideTitleDay, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.day}>
      <Box className="title" height={hideTitleDay ? "1px" : 20}>
        {!hideTitleDay && (
          <Typography color="primary" variant="overline">
            {moment(dia.dt_dia).format("DD ddd")}
          </Typography>
        )}
      </Box>

      <Box className="content">
        {detalles.map((d, i) => (
          <Event
            key={i}
            data={d}
            {...props}
            onClick={() => edit(d, events.indexOf(d))}
          />
        ))}
      </Box>
    </Box>
  );
};

Index.defaultProps = {
  dia: {},
  events: [],
  detalles: [],
  hideTitleDay: false,
};

Index.propTypes = {
  dia: PropTypes.object,
  events: PropTypes.array,
  detalles: PropTypes.array,
  hideTitleDay: PropTypes.bool,
  edit: PropTypes.func,
  rowHeight: PropTypes.number,
};

export default Index;

import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@material-ui/core";

import HeaderPatient from "app/components/Paciente/Header";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "./TimeLineItem";
import Typography from "@material-ui/core/Typography";

import useStyles from "./useStyles";

const Index = (props) => {
  const { open, onClose, atencion, paciente } = props;
  const classes = useStyles();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const { detalles = [] } = atencion;

    const aux = detalles.map((item) => ({ ...item }));

    setData(aux);
  }, [atencion]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        style={{
          minWidth: "500px",
          height: "500px",
        }}
      >
        <Box flex={1} display="flex" flexDirection="column" height="100%">
          <HeaderPatient data={paciente} disableGutters large />
          <Box height="0px" overflow="scroll" flex="1 1 auto">
            <Timeline className={classes.timeline}>
              {data.map((item, i) => (
                <TimelineItem key={i} color="primary">
                  <Typography variant="h6" component="h1">
                    {item.vc_motivo}
                  </Typography>
                  <Typography>{item.vc_observaciones}</Typography>
                </TimelineItem>
              ))}
            </Timeline>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

Index.propTypes = {
  paciente: PropTypes.object,
  atencion: PropTypes.object,
};

Index.defaultProps = {
  paciente: {},
  atencion: {},
};

export default Index;

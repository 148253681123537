import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import formatter from "../../utils/formatter";
import {
  setHelperSidebarRender,
  resetHelperSidebarRender,
} from "../../containers/Think/actions";
import HoverableRow from "./HoverableRow";
import SidebarInput from "./SidebarInput";

class SidebarFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedField: props.fields[0],
      values: props.defaultValue || {},
      dirty: false,
    };
  }

  componentDidMount() {
    this.updateHelperSidebar();
    this.loadField(this.state.selectedField);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.dirty && nextProps.defaultValue) {
      this.setState({ values: nextProps.defaultValue });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return (
      !isEqual(nextState, this.state) ||
      !isEqual(clean(nextProps), clean(this.props))
    );
  }

  componentDidUpdate(prevProps) {
    this.checkLoadedFieldChanges();

    if (prevProps.loading !== this.props.loading) {
      this.updateHelperSidebar();
    }
  }

  componentWillUnmount() {
    this.props.resetHelperSidebarRender();
  }

  // ----------------------
  // ------ methods -------
  // ----------------------
  onRowClick(field) {
    this.loadField(field);
  }

  setFieldValue(fieldName, value) {
    const values = { ...this.state.values };
    values[fieldName] = value;

    this.setState({ values, dirty: true }, () => {
      const nextField = this.getNextField(fieldName);

      if (this.props.onChange) {
        this.props.onChange(values);
      }

      if (nextField) {
        this.loadField(nextField);
      } else if (this.props.onSubmit) {
        const formData = this.getData();

        if (formData.isValid) {
          this.props.onSubmit(values);
        }
      }
    });
  }

  getFieldIndex(fieldName) {
    let fieldIndex;

    this.props.fields.forEach((field, index) => {
      if (field.name === fieldName) {
        fieldIndex = index;
      }
    });

    return fieldIndex;
  }

  getData() {
    const { values } = this.state;
    const { fields } = this.props;
    const formData = { isValid: true, message: "" };

    fields.forEach((field) => {
      if (formData.isValid && field.required && !values[field.name]) {
        formData.isValid = false;
        formData.message = `El campo ${field.label} es requerido`;
      }
    });

    if (formData.isValid) {
      formData.data = values;
    }

    return formData;
  }

  getNextField(fieldName) {
    const { values } = this.state;
    const { fields } = this.props;
    const fieldIndex = this.getFieldIndex(fieldName);
    let nextField;

    if (fieldIndex + 1 < fields.length) {
      fields.forEach((currentField, index) => {
        if (!nextField && index > fieldIndex && !values[currentField.name]) {
          nextField = currentField;
        }
      });

      if (nextField) {
        return nextField;
      }

      return this.getFirstEmptyField();
    }

    return this.getFirstEmptyField();
  }

  getFirstEmptyField() {
    const { values } = this.state;
    const { fields } = this.props;
    let nextField;

    fields.forEach((field) => {
      if (!nextField && !values[field.name]) {
        nextField = field;
      }
    });

    return nextField;
  }

  loadField(field, reset = true) {
    this.setState({ selectedField: field }, () => {
      this.updateHelperSidebar();
      const defaultValue = this.state.values[field.name];

      setTimeout(() => {
        this.SidebarInput.setKeyboardListener();

        if (reset) {
          this.SidebarInput.resetInput();
        }

        if (defaultValue) {
          this.SidebarInput.setValue(defaultValue.label);
        }
      }, 200);
    });
  }

  updateHelperSidebar() {
    this.props.setHelperSidebarRender(this.renderHelperSidebar.bind(this));
  }

  refresh() {
    const { fields } = this.props;
    let fieldToRefresh;

    fields.forEach((field) => {
      if (field.name === this.state.selectedField.name) {
        fieldToRefresh = field;
      }
    });

    this.loadField(fieldToRefresh, false);
  }

  reset() {
    this.setState({
      selectedField: this.props.fields[0],
      values: {},
    });
  }

  checkLoadedFieldChanges() {
    const { selectedField } = this.state;
    const { fields } = this.props;
    const { clean } = formatter;
    let currentLoadedField;

    fields.forEach((field) => {
      if (field.name === selectedField.name) {
        currentLoadedField = field;
      }
    });

    if (!isEqual(clean(currentLoadedField), clean(selectedField))) {
      this.refresh();
    }
  }

  // -----------------------------
  // ------ render methods -------
  // -----------------------------
  renderHelperSidebar() {
    const { selectedField, values } = this.state;

    return (
      <SidebarInput
        ref={(ref) => {
          this.SidebarInput = ref;
        }}
        defaultValue={
          values[selectedField.name] ? values[selectedField.name].label : ""
        }
        label={selectedField.label}
        placeholder={selectedField.placeholder}
        options={selectedField.options || []}
        fieldName={selectedField.name}
        onChange={selectedField.onChange}
        onSelect={this.setFieldValue.bind(this)}
        loading={this.props.loading}
        type={selectedField.type}
        optimized={selectedField.optimized}
      />
    );
  }

  renderFields() {
    const { values, selectedField } = this.state;
    const { fields, hideFields } = this.props;

    if (hideFields) {
      return null;
    }

    return fields.map((field, index) => (
      <HoverableRow
        key={index}
        field={field}
        selectedField={selectedField}
        value={values[field.name] || {}}
        onClick={this.onRowClick.bind(this)}
      />
    ));
  }

  render() {
    return <div style={this.props.style}>{this.renderFields()}</div>;
  }
}

SidebarFormComponent.propTypes = {
  style: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  hideFields: PropTypes.bool,
  loading: PropTypes.bool,
  setHelperSidebarRender: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  defaultValue: PropTypes.object,
};

const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
};

export const SidebarForm = connect(null, actions, null, { forwardRef: true })(
  SidebarFormComponent
);

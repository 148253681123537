import React, { Component } from 'react';
import { Modal, Icon, Grid } from 'semantic-ui-react';
import { Paper, Button, Dialog, List, Divider, ListItem, ListItemText, DialogTitle, DialogContent } from '@material-ui/core';


class ActionSheet extends Component {
    componentDidMount() {
        console.log('did mount');
    }

    componentDidUpdate() {

    }

    renderActions() {

    }

    render() {
        console.log('render');
        const actions = this.props.content.actions ? this.props.content.actions : [] ;
        
        return <Dialog
            maxWidth={'sm'}
            onClose={()=>{}}
            aria-labelledby="simple-dialog-title"
            open={true}
        >
            <DialogTitle style={{textAlign: 'center'}}>
                {this.props.content.title}
                <br/>
                <small>{this.props.content.message}</small>
            </DialogTitle>
            <Divider></Divider>
            <div style={{width: '400px'}}>
                <List style={{height: '100%'}}>
                    { actions.map((action,index) => (
                        <ListItem button onClick={() => action.action() } key={index} >
                            <ListItemText style={{textAlign: 'center'}} primary={action.title} />
                        </ListItem>
                    ))}
                    <ListItem button onClick={() => this.props.content.cancel.action()} key={'cancelar'} >
                        <ListItemText style={{textAlign: 'center'}} primary={this.props.content.cancel.title} />
                    </ListItem>
                </List>
            </div>
        </Dialog>
        
        return (
            <Modal
            size='small'
            open={this.props.open}
            style={{ border: 0, maxHeight: '90%', padding: window.screen.availWidth < 500 ? '10%' : 200, paddingTop:0, backgroundColor: 'transparent', boxShadow: '0px 0px 10px rgba(0, 0,0, 0)' }}>
                <Paper style={{ backgroundColor: 'transparent', borderRadius: 10 }}>
                    <div style={{ borderRadius: actions.length == 0 ? 10 : 0, borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 20 }}>
                        <p style={{ padding: 0, textAlign: 'center', backgroundColor: 'transparent', color: 'gray', margin: 0, width: '100%' }}>
                            {this.props.content.title}
                        </p>
                        <p style={{ padding: 0, textAlign: 'center', backgroundColor: 'transparent', color: 'gray', margin: 0, width: '100%' }}>
                            {this.props.content.message}
                        </p>
                    </div>
                    {
                        this.props.content.frame ?
                            <Grid centered style={{width: '100%', padding: 0, margin: 0}} >
                                {this.props.content.frame}
                            </Grid>
                         :
                        <div/>
                    }
                    {
                        actions.map((action,index) => {
                            return (
                                <Button
                                key={index}
                                onClick={() => action.action() }
                                style={{marginTop: 1, width: '100%', minHeight: 50, borderRadius: 0, borderBottomLeftRadius: index == (actions.length - 1) ? 10 : 0, borderBottomRightRadius: index == (actions.length - 1) ? 10 : 0}}
                                >
                                {
                                    action.icon ?
                                    <Icon name={action.icon} style={{ border: 0, height: '100%'}}/>
                                    : <div/>
                                }
                                {action.title}
                                </Button>
                            )
                        })
                    }
                </Paper>

                <Paper>
                    <Button
                    onClick={() => this.props.content.cancel.action()}
                    style={{padding: 0, textAlign: 'center', borderRadius: 10, height: 50, marginTop: 5, width: '100%' }}>
                        {this.props.content.cancel.title}
                    </Button>
                </Paper>
            </Modal>
        );
    }
}


export { ActionSheet };

import React from "react";
import ModalForm from "app/sales/components/Productos/ModalForm";

export default function useConfirmationDialog() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [data, setData] = React.useState({});

  const onOpen = (edit = {}) => {
    setData(edit);
    setIsOpen(true);
  };

  const handleAccept = (form) => {
    console.log(form);
  };

  const Dialog = React.useCallback(
    () => (
      <ModalForm
        open={isOpen}
        close={() => setIsOpen(false)}
        accept={handleAccept}
        data={data}
      />
    ),
    [isOpen, data]
  );

  return {
    ModalForm: Dialog,
    onOpen,
  };
}

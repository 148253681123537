import React from 'react';
import HelpBarThink from '../../../../../material-ui/HelpBarThink';
import { connect } from 'react-redux';
import moment from 'moment';
import { Box, ListItem, Toolbar, Typography, AppBar, List, Input, ListItemText, Paper, Button, TextField, Tab, Menu, MenuItem, Modal, MenuList, Popover, withStyles } from '@material-ui/core';
import DateSelectPicker from '../../../../../material-ui/DateSelectPicker';
import TimeSelectPicker from '../../../../../material-ui/TimeSelectPicker';
import PhoneNumber from '../../../../../material-ui/PhoneNumber';
import FileUploader from '../../../../../material-ui/FileUploader';
import Repository from './repository';
import Search from "@material-ui/icons/Search";
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import { isString, isNumber } from 'util';
import PreguntasItem from './PreguntaItem';
import Charts from '../../../../../material-ui/Charts';
import { setPacienteActual } from '../../../actions';
import SearchSelect from '../../../../../material-ui/ThinkForm/SearchSelect';
const TooltipThink = withStyles(theme => ({
    tooltip: {
        backgroundColor: 'transparent !important',
        // border: '1px solid #dadde9',
    },
}))(Tooltip);
class PreguntasNumeticasUI extends React.Component {
    constructor(props) {
        super(props);
        this.state = { searchText: '' };
    }
    render() {
        return (
            <Paper style={{ height: '100%', overflow: 'hidden' }}>
                <Paper>
                    {this.renderHeader()}
                    {this.renderSearchBar()}
                </Paper>
                <List style={{ height: 'calc(100% - 124px)', overflowX: 'scroll', WebkitOverflowScrolling: 'touch' }}>
                    {this.renderOptions()}
                </List>
            </Paper>
        );
    }
    componentDidMount() {
        const preguntasNumericas = [];
        (this.props.pacienteActual.preguntasNumericas || []).filter((pregunta, index) => {
            pregunta.preguntaVersion = this.getPreguntaVersion(pregunta, this.props.pacienteActual.detalle);
            if (pregunta.preguntaVersion) {
                preguntasNumericas.push(pregunta);
            }
        })
        this.setState({ preguntasNumericas });
    }
    renderHeader(opacity) {
        let { header = {} } = this.props;
        if (isString(header)) {
            header = { title: header };
        }
        return (
            <AppBar position={'static'} color='primary' key={`header-${opacity}`} style={{ height: header.height || 95, opacity }}>
                <Toolbar color='primary' key={`header-${opacity}`} style={{ height: header.height || 95, opacity }}>
                    <Typography style={{ textAlign: 'center', width: '100%', flexDirection: 'column', verticalAlign: 'center', justifyItems: 'center' }}>
                        {header.title || 'Seleccione'}
                    </Typography>
                </Toolbar>
            </AppBar>

        )
    }
    renderSearchBar() {
        const searchBarHeight = 40;
        return (
            <ListItem style={{ padding: '5px 8px 3px 10px', height: searchBarHeight, zIndex: 0 }}>
                <Search style={{ margin: 10 }} />
                <Input
                    onChangeCapture={this.onChangeValue.bind(this)}
                    value={this.state.searchText || ''}
                    placeholder="Buscar..." disableUnderline style={{ width: '100%' }} />
            </ListItem>
        );
    }
    validate() {

    }
    handleChange(event) {
        const id_pregunta = event.target.id * 1;
        let finded = false;
        let valor = "";
        let decimal = "2";
        const preguntasNumericas = this.props.pacienteActual.preguntasNumericas.map((pregunta) => {
            if (pregunta.id === id_pregunta) {
                switch (pregunta.id_tipoPregunta) {
                    case 3: {
                        if (pregunta.id !== 243) {
                            valor = event.target.value;
                            if (valor == '.') { valor = '0.' }
                            if (valor * 1 && valor.length > 0 || valor == '0' || valor == '0.' || valor == '0.0') {
                                // console.log(valor.split('.')[1].length = 3);
                                pregunta.respuesta = {
                                    nu_respuesta: valor,
                                    dt_registro: moment().format('YYYY-MM-DD HH:mm:ss')
                                };
                                finded = true;
                            } else if (event.target.value === '') {
                                pregunta.respuesta = null;
                            }
                        }
                        break;
                    }
                    case 5: {
                        const value = moment(event.target.value);
                        if (value.isValid()) {
                            pregunta.respuesta = {
                                dt_respuesta: value.format('YYYY-MM-DD'),
                                dt_registro: moment().format('YYYY-MM-DD HH:mm:ss')
                            };
                            finded = true;
                        }
                        break;
                    }
                    case 6: {
                        const value = moment(event.target.value);
                        if (value.isValid()) {
                            pregunta.respuesta = {
                                tm_respuesta: value.format('HH:mm:ss'),
                                dt_registro: moment().format('YYYY-MM-DD HH:mm:ss')
                            };
                            finded = true;
                        }
                        break;
                    }
                }
            }
            if (pregunta.id == 243) {
                if (this.props.pacienteActual.preguntasNumericas.find((e) => e.id == 241) && this.props.pacienteActual.preguntasNumericas.find((e) => e.id == 242)) {
                    if (this.props.pacienteActual.preguntasNumericas.find((e) => e.id == 241).respuesta && this.props.pacienteActual.preguntasNumericas.find((e) => e.id == 242).respuesta) {
                        const tallaMts = (this.props.pacienteActual.preguntasNumericas.find((e) => e.id == 241).respuesta.nu_respuesta / 100);
                        const imc = ((this.props.pacienteActual.preguntasNumericas.find((e) => e.id == 242).respuesta.nu_respuesta) / (tallaMts * tallaMts));
                        pregunta.respuesta = {
                            dt_registro: moment().format('YYYY-MM-DD HH:mm:ss'),
                            nu_respuesta: imc.toFixed(1)
                        };
                    }
                }
            }
            return pregunta;
        });

        this.props.setPacienteActual({
            ...this.props.pacienteActual,
            preguntasNumericas
        });
    }
    renderOptions() {
        const preguntasNumericas = (this.props.pacienteActual || {}).preguntasNumericas || [];
        let preguntasFiltered;
        if (this.state.searchText !== '') {
            preguntasFiltered = this.state.preguntasFiltered.filter((e, index) => index < 60);
        } else {
            preguntasFiltered = preguntasNumericas;
        }
        const pacienteActual = this.props.pacienteActual;
        const respuestas = pacienteActual.pacienteRespuestas || [];
        return preguntasFiltered.map((pregunta, index) => {
            if (pregunta.preguntaVersion) {
                const open = this.state.focusRow === index;
                const data = []
                const respuestasValues = respuestas.filter((e) => e.id_pregunta === pregunta.preguntaVersion.id_pregunta);
                let lastData = respuestasValues[respuestasValues.length - 1];
                if (pregunta.respuesta) {
                    if (respuestasValues.length === 0) {
                        respuestasValues.push({ ...pregunta.respuesta, dt_registro: moment() - 60000 })
                    }
                    respuestasValues.push(pregunta.respuesta)
                }
                respuestasValues.map((respuesta, index) => {
                    data.push({ x: moment(respuesta.dt_registro) * 1, y: respuesta.nu_respuesta * 1 })
                })

                return (
                    <Box
                        key={index}
                    >

                        {
                            <TooltipThink
                                id={'Tooltip'}
                                placement="left-start"
                                title={
                                    pregunta.id_tipoPregunta === 3 &&
                                    <div onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}>
                                        {data.length > 0 ?
                                            <Charts
                                                name={pregunta.preguntaVersion.vc_pregunta}
                                                data={data}
                                                type={'datetime'}
                                            />
                                            :
                                            <Paper style={{minHeight: 100, minWidth: 300}}>
                                                <Typography style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', alignSelf: 'center' }}>
                                                    Aun no tienes registros para para graficar
                                                </Typography>
                                            </Paper>
                                        }
                                    </div>
                                }
                            >
                                <div
                                >
                                    <PreguntasItem
                                        key={index}
                                        open={pregunta.id_tipoPregunta === 3 ? open : false}
                                        item={this.renderFields({ ...pregunta, ...pregunta.preguntaVersion, index, lastData })}
                                        chart={<Charts
                                            name={pregunta.preguntaVersion.vc_pregunta}
                                            registers={data}
                                            typeRegisters
                                        />}
                                        reload={() => {
                                            this.forceUpdate()
                                        }}
                                    />
                                </div>
                            </TooltipThink>
                        }
                        {
                            this.state.id_preguntaActual && !!this.state.anchorEl && pregunta.id_tipoPregunta < 3 ?
                                <Menu
                                    anchorEl={this.state.anchorEl}
                                    open={!!(this.state.id_preguntaActual === pregunta.id)}
                                    onClose={(e) => {
                                        e.stopPropagation();
                                        this.setState({ focusRow: null, anchorEl: null, id_preguntaActual: null })
                                    }}
                                >
                                    {
                                        pregunta.preguntaVersion.respuestas.map((respuesta, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.setState({ focusRow: null, anchorEl: null, id_preguntaActual: null }, () => {
                                                            let finded = false;
                                                            let findedPreguntaEnlace = false;
                                                            let findedPreguntaEnlaceRespuesta = false;
                                                            let valor = "";
                                                            let decimal = "2";
                                                            const preguntas = this.props.preguntas || [];
                                                            let preguntaEnlace = preguntas.find(e => e.id === pregunta.preguntaVersion.id_preguntaEnlace) || null;
                                                            let preguntaEnlaceRespuesta = preguntas.find(e => e.id === respuesta.id_preguntaEnlace) || null;
                                                            let preguntasNumericas = this.props.pacienteActual.preguntasNumericas.map((preguntaNum, index) => {
                                                                if (preguntaNum.id === pregunta.id) {
                                                                    preguntaNum.respuesta = {
                                                                        id_respuesta: respuesta.id,
                                                                        sn_patologico: respuesta.sn_patologico,
                                                                        dt_registro: moment()
                                                                    }
                                                                    finded = true;
                                                                }
                                                                if (preguntaEnlace && preguntaNum.id === preguntaEnlace.id) {
                                                                    findedPreguntaEnlace = true
                                                                }
                                                                if (preguntaEnlaceRespuesta && preguntaNum.id === preguntaEnlaceRespuesta.id) {
                                                                    findedPreguntaEnlaceRespuesta = true
                                                                }
                                                                return preguntaNum;
                                                            })
                                                            if (!finded) {
                                                                const preguntaNumAdd = {
                                                                    ...pregunta, respuesta: {
                                                                        id_respuesta: respuesta.id,
                                                                        sn_patologico: respuesta.sn_patologico,
                                                                        dt_registro: moment()
                                                                    }
                                                                }
                                                                preguntasNumericas.splice(index, 0, preguntaNumAdd)
                                                            }
                                                            let addPreguntasPendientes = [];
                                                            if (!findedPreguntaEnlaceRespuesta && preguntaEnlaceRespuesta) {
                                                                preguntasNumericas.splice(index, 0, preguntaEnlaceRespuesta)
                                                                addPreguntasPendientes.push(preguntaEnlaceRespuesta.id)
                                                            }
                                                            if (!findedPreguntaEnlace && preguntaEnlace) {
                                                                preguntasNumericas.splice(index, 0, preguntaEnlace)
                                                                addPreguntasPendientes.push(preguntaEnlace.id)
                                                            }
                                                            this.props.setPacienteActual({
                                                                ...this.props.pacienteActual,
                                                                preguntasNumericas
                                                            });

                                                            let preguntasPendientes = this.state.reguntasPendientes || [];
                                                            preguntasPendientes = [...preguntasPendientes, ...addPreguntasPendientes];
                                                            this.setState({ searchText: '', preguntasPendientes }, () => {
                                                                let rest = [...this.state.preguntasPendientes]
                                                                const id_preguntaActual = rest.pop();
                                                                const anchorEl = document.getElementById(`id_pregunta:${id_preguntaActual}`);
                                                                if (anchorEl) {
                                                                    console.log(anchorEl);
                                                                    this.setState({ focusRow: index, anchorEl, id_preguntaActual, preguntasPendientes: rest })
                                                                }
                                                            })

                                                        })
                                                    }}
                                                >
                                                    <Typography>
                                                        {respuesta.vc_respuesta}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }

                                </Menu>
                                : null
                        }
                    </Box>

                )
            } else {
                return null;
            }
        })
    }
    getOptions(preguntaVersion) {
        let options = [];
        preguntaVersion.respuestas.map((item) => {
            options.push({
                value: item.id,
                label: item.vc_respuesta
            })
        });
        return options;
    }
    renderFields(pregunta) {
        let um;
        let respuesta = pregunta.respuesta || {};
        switch (pregunta.id_tipoPregunta) {
            case 1: {
                const options = this.getOptions(pregunta.preguntaVersion);
                let valueObject = {};
                if (!respuesta.id_respuesta) {
                    const preguntaOpcion = this.props.pacienteActual.preguntasNumericas.find(e => e.id === pregunta.id) || {};
                    respuesta = preguntaOpcion.respuesta || {};
                }
                if (respuesta.id_respuesta) {
                    valueObject = options.find(e => e.value === respuesta.id_respuesta) || {};
                }
                return (
                    <TextField
                        variant='filled'
                        key={pregunta.index}
                        fieldid={`id_pregunta:${pregunta.id_pregunta}`}
                        field={'id_respuesta'}
                        autoComplete='off'
                        autoCorrect='off'
                        readOnly
                        name={`${pregunta.id_pregunta}`}
                        fullWidth
                        readOnly={pregunta.readOnly || false}
                        placeholder={pregunta.lastData ? `${pregunta.lastData.nu_respuesta}` : ''}
                        value={valueObject.label || ''}
                        label={pregunta.vc_pregunta}
                        InputLabelProps={{
                            margin: 'dense',
                            shrink: true
                        }}
                        onClick={(e) => this.setState({ focusRow: pregunta.index, anchorEl: e.currentTarget, id_preguntaActual: pregunta.id })}
                        InputProps={{
                            autoComplete: 'off',
                            autoCorrect: 'off',
                            key: pregunta.index,
                            id: `${pregunta.id_pregunta}`,
                            // endAdornment: <InputAdornment position="end" color='gray'>{um.vc_abreviatura || ''}</InputAdornment>,
                        }}
                        style={{ margin: 0 }}
                    />
                )
            }
            case 2: {
                const options = this.getOptions(pregunta.preguntaVersion);
                let valueObject = {};
                if (!respuesta.id_respuesta) {
                    const preguntaOpcion = this.props.pacienteActual.preguntasNumericas.find(e => e.id === pregunta.id) || {};
                    respuesta = preguntaOpcion.respuesta || {};
                }
                if (respuesta.id_respuesta) {
                    valueObject = options.find(e => e.value === respuesta.id_respuesta) || {};
                }
                return (
                    <TextField
                        variant='filled'
                        key={pregunta.index}
                        fieldid={`id_pregunta:${pregunta.id_pregunta}`}
                        field={'id_respuesta'}
                        autoComplete='off'
                        autoCorrect='off'
                        readOnly
                        name={`${pregunta.id_pregunta}`}
                        fullWidth
                        readOnly={pregunta.readOnly || false}
                        placeholder={pregunta.lastData ? `${pregunta.lastData.nu_respuesta}` : ''}
                        value={valueObject.vc_respuesta || ''}
                        label={pregunta.vc_pregunta}
                        InputLabelProps={{
                            margin: 'dense',
                            shrink: true
                        }}
                        onFocus={(e) => this.setState({ focusRow: pregunta.index, anchorEl: e.currentTarget, id_preguntaActual: pregunta.id })}
                        InputProps={{
                            autoComplete: 'off',
                            autoCorrect: 'off',
                            key: pregunta.index,
                            id: `${pregunta.id_pregunta}`,
                            // endAdornment: <InputAdornment position="end" color='gray'>{um.vc_abreviatura || ''}</InputAdornment>,
                        }}
                        style={{ margin: 0 }}
                    />
                )
            }

            case 3:
                um = this.props.unidadesMedida.find(e => e.id === pregunta.id_um_numerico) || {};
                return (
                    <TextField
                        variant='filled'
                        key={pregunta.index}
                        fieldid={`id_pregunta:${pregunta.id_pregunta}`}
                        field={'nu_respuesta'}
                        autoComplete='off'
                        autoCorrect='off'
                        onFocus={(e) => this.setState({ focusRow: pregunta.index, anchorEl: e.currentTarget, id_preguntaActual: pregunta.id })}
                        onChange={this.handleChange.bind(this)}
                        name={`${pregunta.id_pregunta}`}
                        fullWidth
                        readOnly={pregunta.readOnly || false}
                        placeholder={pregunta.lastData ? `${pregunta.lastData.nu_respuesta}` : 'Captura...'}
                        value={respuesta.nu_respuesta || ''}
                        label={pregunta.vc_pregunta}
                        InputLabelProps={{
                            margin: 'dense',
                            shrink: true
                        }}
                        InputProps={{
                            key: pregunta.index,
                            id: `${pregunta.id_pregunta}`,
                            endAdornment: <InputAdornment position="end" color='gray'>{um.vc_abreviatura || ''}</InputAdornment>,
                        }}
                        style={{ margin: 0 }}
                    />
                )
            // case 2:
            //     const options = this.getOptions(field);
            //     return (
            //         <Select
            //         key={pregunta.index}
            //         fieldid={pregunta.id_pregunta}
            //         field={'nu_respuesta'}
            //         required={pregunta.required || false}
            //         // defaultValue={this.props.registro ? this.props.registro[pregunta.key] || null : pregunta.relation.defaultValue || null}
            //         fullWidth
            //         label={pregunta.vc_preguntaUsuario}
            //             options={options}
            //             autoFocus
            //         />
            //     )
            case 5:
                return (
                    <DateSelectPicker
                        fullWidth
                        key={pregunta.index}
                        variant='filled'
                        InputLabelProps={{
                            margin: 'dense',
                            shrink: true
                        }}
                        onFocus={(e) => this.setState({ focusRow: pregunta.index, anchorEl: e.currentTarget, id_preguntaActual: pregunta.id })}
                        fieldid={`id_pregunta:${pregunta.id_pregunta}`}
                        required={pregunta.required || false}
                        value={respuesta.dt_respuesta || ''}
                        onChange={() => {
                            console.log('change')
                        }}
                        // defaultValue={this.props.registro ? this.props.registro[pregunta.key] || null : pregunta.relation.defaultValue || null}
                        label={pregunta.vc_pregunta}
                    />
                )
            case 6:
                return (
                    <TimeSelectPicker
                        fullWidth
                        key={pregunta.index}
                        InputLabelProps={{
                            margin: 'dense',
                            shrink: true
                        }}
                        onFocus={(e) => this.setState({ focusRow: pregunta.index, anchorEl: e.currentTarget, id_preguntaActual: pregunta.id })}
                        value={respuesta.tm_respuesta || ''}
                        fieldid={`id_pregunta:${pregunta.id_pregunta}`}
                        required={pregunta.required || false}
                        // defaultValue={this.state.registro ? this.state.registro[pregunta.key] || null : pregunta.relation.defaultValue || null}
                        label={pregunta.vc_name}
                    />
                )
            case 12:
                return (
                    <FileUploader addFile={this.addFile} files={[]}
                        key={pregunta.index}
                    />
                )
        }
    }
    isValidSex = (id_sexoPaciente, id_sexoPregunta) => {
        if (id_sexoPaciente === id_sexoPregunta || id_sexoPregunta === 3) {
            return true;
        } else {
            return false;
        }
    }

    isValidAge = (nuAgeMinutesPaciente, item) => {
        const { unidadesMedida } = this.props;
        let limiteInferiorAge = null;
        let limiteSuperiorAge = null;
        if (item.nu_minEdad > 0 && item.id_um_minEdad != null) {
            const nuUmBase = this.finder(unidadesMedida, 'id', item.id_um_minEdad).nu_ratio;
            limiteInferiorAge = nuUmBase * item.nu_minEdad;

        }
        if (item.nu_maxEdad > 0 && item.id_um_maxEdad != null) {
            const nuUmBase = this.finder(unidadesMedida, 'id', item.id_um_maxEdad).nu_ratio;
            limiteSuperiorAge = nuUmBase * item.nu_maxEdad;
        }
        if (limiteInferiorAge != null && nuAgeMinutesPaciente < limiteInferiorAge) {
            return false;
        }
        if (limiteSuperiorAge != null && nuAgeMinutesPaciente > limiteSuperiorAge) {
            return false;
        }
        return true;
    }
    finder = (array, key, value) => {
        const object = array.find((e) => { return e[`${key}`] === value });
        if (object) {
            return object;
        }
        return null;
    }
    getPreguntaVersion = (preguntaActual, pacienteDetalle = {}) => {
        const id_sexo = pacienteDetalle.id_sexo || 3;
        const dt_nacimiento = new Date(pacienteDetalle.dt_nacimiento) || new Date()
        const nu_edadMinutos = (new Date() - new Date(pacienteDetalle.dt_nacimiento)) / 60000;
        let version = null;
        preguntaActual.versiones.map((preguntaVersion, index) => {
            if (this.isValidAge(nu_edadMinutos, preguntaVersion) && this.isValidSex(id_sexo, preguntaVersion.id_sexo)) {
                version = preguntaVersion;
            }
        });
        return version;
    }
    onChangeValue(event) {
        const preguntasFiltered = [];
        this.props.preguntas.filter((pregunta, index) => {
            pregunta.preguntaVersion = this.getPreguntaVersion(pregunta, this.props.pacienteActual.detalle);
            if (pregunta.preguntaVersion && pregunta.preguntaVersion.vc_pregunta.toLowerCase().includes(this.state.searchText.toLowerCase()) ) {
                preguntasFiltered.push(pregunta);
            }
        })

        this.setState({ searchText: event.target.value, preguntasFiltered });
    }
}
const mapStateToProps = (state) => {
    const { pacienteActual, unidadesMedida, preguntas, preguntasRespuestas, respuestas } = state.Think;
    return { pacienteActual, unidadesMedida, preguntas, preguntasRespuestas, respuestas };
};
export default connect(mapStateToProps, { setPacienteActual })(PreguntasNumeticasUI);


import moment from "moment";

const indiceCase = '2020-02-28 00:00:00';
// const indiceCase = '2020-03-02 00:00:00';
const remisionValues = (data, value) => {
    
    if (data.length < 15){
        return 0;
    }
    const dataValue = data.reverse()[14][1]
    if (dataValue > value ){
        return value;
    }
    return dataValue
}
export default {
    getCasesData: ({tasaAtaque,tasaDuplicacion, poblacion}) => {
        const dayStart = moment('2020-01-01 00:00:00') * 1;
        const data = [];
        let value = 0;
        let valueInit = 1;
        let dayInit = null;
        let i = 0;
        const limit = poblacion * tasaAtaque
        for (i = 0; i < 365; i++) {
            const ts = dayStart + (i * 86400000);
            if (moment(indiceCase) * 1 < ts && value === 0){
                value = valueInit;
                dayInit = i;
            }
            if (dayInit){
                value = value + (((value) / tasaDuplicacion))
                if (value > limit) {
                    value = limit
                }
            }            
            data.push([ts,value]);
        }
        return data;
    },
    getCasesPrevalencyData: ({tasaAtaque,tasaDuplicacion, poblacion}) => {
        const dayStart = moment('2020-01-01 00:00:00') * 1;
        const data = [];
        let value = 0;
        let valueInit = 1;
        let dayInit = null;
        let i = 0;
        const limit = poblacion * tasaAtaque
        for (i = 0; i < 365; i++) {
            const ts = dayStart + (i * 86400000);
            if (moment(indiceCase) * 1 < ts && value === 0){
                value = valueInit;
                dayInit = i;
            }
            if (dayInit){
                value = value + (((value) / tasaDuplicacion))
                if (value > limit) {
                    value = limit
                }
            }        
            const valueAdjust = value - remisionValues(data,value)    
            if (valueAdjust <= 0) {
                data.push([ts,0]);
            }else{
                data.push([ts,valueAdjust]);
            } 
        }
        return data;
    },
    getCasesHospitalizedData: ({tasaAtaque,tasaDuplicacion, poblacion}) => {
        const dayStart = moment('2020-01-01 00:00:00') * 1;
        const data = [];
        let value = 0;
        let valueInit = 1;
        let dayInit = null;
        let i = 0;
        const limit = poblacion * tasaAtaque
        for (i = 0; i < 365; i++) {
            const ts = dayStart + (i * 86400000);
            if (moment(indiceCase) * 1 < ts && value === 0){
                value = valueInit;
                dayInit = i;
            }
            if (dayInit){
                value = value + (((value) / tasaDuplicacion))
                if (value > limit) {
                    value = limit
                }
            }       
            const valueAdjust = value * 0.14     
            data.push([ts,valueAdjust]);
        }
        return data;
    },
    getCasesHospitalizedPrevalencyData: ({tasaAtaque,tasaDuplicacion, poblacion}) => {
        const dayStart = moment('2020-01-01 00:00:00') * 1;
        const data = [];
        let value = 0;
        let valueInit = 1;
        let dayInit = null;
        let i = 0;
        const limit = poblacion * tasaAtaque
        for (i = 0; i < 365; i++) {
            const ts = dayStart + (i * 86400000);
            if (moment(indiceCase) * 1 < ts && value === 0){
                value = valueInit;
                dayInit = i;
            }
            if (dayInit){
                value = value + (((value) / tasaDuplicacion))
                if (value > limit) {
                    value = limit
                }
            }       
            let valueAdjust = value * 0.14     
            valueAdjust = valueAdjust - remisionValues(data,valueAdjust)  
            if (valueAdjust <= 0) {
                data.push([ts,0]);
            }else{
                data.push([ts,valueAdjust]);
            }   
        }
        return data;
    },
    getCasesSeriusData: ({tasaAtaque,tasaDuplicacion, poblacion}) => {
        const dayStart = moment('2020-01-01 00:00:00') * 1;
        const data = [];
        let value = 0;
        let valueInit = 1;
        let dayInit = null;
        let i = 0;
        const limit = poblacion * tasaAtaque
        for (i = 0; i < 365; i++) {
            const ts = dayStart + (i * 86400000);
            if (moment(indiceCase) * 1 < ts && value === 0){
                value = valueInit;
                dayInit = i;
            }
            if (dayInit){
                value = value + (((value) / tasaDuplicacion))
                if (value > limit) {
                    value = limit
                }
            }            
            data.push([ts,value * 0.06]);
        }
        return data;
    },
    getCasesSeriusPrevalencyData: ({tasaAtaque,tasaDuplicacion, poblacion}) => {
        const dayStart = moment('2020-01-01 00:00:00') * 1;
        const data = [];
        let value = 0;
        let valueInit = 1;
        let dayInit = null;
        let i = 0;
        const limit = poblacion * tasaAtaque
        
        for (i = 0; i < 365; i++) {
            const ts = dayStart + (i * 86400000);
            if (moment(indiceCase) * 1 < ts && value === 0){
                value = valueInit;
                dayInit = i;
            }
            if (dayInit){
                value = value + (((value) / tasaDuplicacion))
                if (value > limit) {
                    value = limit
                }
            }    
            let valueAdjust = value * 0.06     
            valueAdjust = valueAdjust - remisionValues(data,valueAdjust)  
            if (valueAdjust <= 0) {
                data.push([ts,0]);
            }else{
                data.push([ts,valueAdjust]);
            }   
        }
        return data;
    },
    getCasesDeadData: ({tasaAtaque,tasaDuplicacion, poblacion}) => {
        const dayStart = moment('2020-01-01 00:00:00') * 1;
        const data = [];
        let value = 0;
        let valueInit = 1;
        let dayInit = null;
        let i = 0;
        const limit = poblacion * tasaAtaque
        for (i = 0; i < 365; i++) {
            const ts = dayStart + (i * 86400000);
            if (moment(indiceCase) * 1 < ts && value === 0){
                value = valueInit;
                dayInit = i;
            }
            if (dayInit){
                value = value + (((value) / tasaDuplicacion))
                if (value > limit) {
                    value = limit
                }
            }            
            data.push([ts,value * 0.034]);
        }
        return data;
    },
    getHospitalizedCapacityData: ({tasaAtaque,tasaDuplicacion, poblacion}) => {
        const dayStart = moment('2020-01-01 00:00:00') * 1;
        const data = [];
        let value = 0;
        let valueInit = 1;
        let dayInit = null;
        let i = 0;
        const limit = poblacion * tasaAtaque
        for (i = 0; i < 365; i++) {
            const ts = dayStart + (i * 86400000);
            if (moment(indiceCase) * 1 < ts && value === 0){
                value = valueInit;
                dayInit = i;
            }
            if (dayInit){
                value = value + (((value) / tasaDuplicacion))
                if (value > limit) {
                    value = limit
                }
            }            
            data.push([ts,1003]);
        }
        return data;
    },
    getTerapyCapacityData: ({tasaAtaque,tasaDuplicacion, poblacion}) => {
        const dayStart = moment('2020-01-01 00:00:00') * 1;
        const data = [];
        let value = 0;
        let valueInit = 1;
        let dayInit = null;
        let i = 0;
        const limit = poblacion * tasaAtaque
        for (i = 0; i < 365; i++) {
            const ts = dayStart + (i * 86400000);
            if (moment(indiceCase) * 1 < ts && value === 0){
                value = valueInit;
                dayInit = i;
            }
            if (dayInit){
                value = value + (((value) / tasaDuplicacion))
                if (value > limit) {
                    value = limit
                }
            }            
            data.push([ts,150]);
        }
        return data;
    },
    getBase: ({tasaAtaque,tasaDuplicacion, poblacion}) => {
        const dayStart = moment('2020-01-01 00:00:00') * 1;
        const data = [];
        let value = 0;
        let valueInit = 1;
        let dayInit = null;
        let i = 0;
        const limit = poblacion * tasaAtaque
        for (i = 0; i < 365; i++) {
            const ts = dayStart + (i * 86400000);
            if (moment(indiceCase) * 1 < ts && value === 0){
                value = valueInit;
                dayInit = i;
            }
            if (dayInit){
                value = value + (((value) / tasaDuplicacion))
                if (value > limit) {
                    value = limit
                }
            }            
            data.push([ts,0]);
        }
        return data;
    }
}
import React from "react";
import SubMenuModulos from "../../../material-ui/SubMenuModulos";
import { menuShop } from "../../../containers/Think/listados";
import { Button } from "@material-ui/core";
import ButtonDateRange from "../ButtonDateRange";
import moment from "moment";

const Title = (props) => {
  const {
    opcion = "",
    cashRegister,
    range = [moment().startOf("month"), moment().endOf("month")],
    onChange = () => {},
    onClick = () => {},
  } = props;

  switch (opcion) {
    case "accountsDay":
      return (
        <Button onClick={onClick} color="inherit">
          {cashRegister || "Selecciona Caja"}
        </Button>
      );
      break;

    case "accountsHistory":
      return <ButtonDateRange value={range} onChange={onChange} />;
      break;

    default:
      return null;
      break;
  }
};

export default (props) => {
  const {
    opcion = "",
    setOpcion = () => {},
    searchText = "",
    setSearchText = () => {},
    onClickAction = () => {},

    onClick = () => {},
    onChangeRange = () => {},
    cashRegister,
    range,
  } = props;

  const moduloActual = menuShop.find((v) => v.id === "shop");
  const opciones = moduloActual.opciones.map((v) => ({
    ...v,
    action: () => setOpcion(v.id),
  }));

  const opcionActual = moduloActual.opciones.find((e) => e.id === opcion) || {};
  const opcionesDisplay = ["Caja", opcionActual.vc_nombre || ""];

  let { acciones = [] } = opcionActual;
  acciones = acciones.map((v) => ({
    ...v,
    action: () => onClickAction(v.id),
  }));

  return (
    <SubMenuModulos
      title={
        <Title
          cashRegister={cashRegister}
          opcion={opcion}
          range={range}
          onClick={onClick}
          onChange={onChangeRange}
        />
      }
      type={"search"}
      searchText={searchText}
      onChange={(e) => {
        setSearchText(e.target.value);
      }}
      options={opciones}
      actions={acciones}
      optionDisplay={opcionesDisplay}
    />
  );
};

import http from '../http';

export default {
    getImages(callback) {
        const request = { server: 'https://api.imgur.com', url: '/3/gallery/hot/viral/0'}
        http.get(request, {}, callback);
    },
    getPdfs(callback) {
        const request = { server: 'http://www.cenetec-difusion.com', url: '/CMGPC/IMSS-335-09/ER.pdf'}
        http.get(request, {}, callback);
    }
};

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
} from "app/containers/Think/actions";

import { Modal, Paper } from "@material-ui/core";
import TriageModule from "app/containers/Think/views/notas/preguntas/TriageModule";

const Index = (props) => {
  const { open, paciente, onFinish, onClose } = props;

  const [preguntaCovid, setPreguntaCovid] = React.useState(
    paciente?.preguntasTexto[0]
  );

  React.useEffect(() => {
    setPreguntaCovid(paciente?.preguntasTexto[0]);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        boxShadow: "0px 0px 0px transparent",
        backgroundColor: "transparent",
        margin: window.isMobile ? 20 : "50px 30vh 50px 30vh",
      }}
    >
      <Paper
        style={{
          width: "100%",
          borderRadius: 10,
          overflow: "hidden",
          height: "100%",
          outline: "none",
        }}
      >
        {paciente && preguntaCovid && (
          <TriageModule callbackFinish={onFinish} item={preguntaCovid} />
        )}
      </Paper>
    </Modal>
  );
};

Index.propTypes = {
  setHelperSidebarRender: PropTypes.func,
  setPacienteActual: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
  logOut: PropTypes.func,
};

const actions = {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
};

const mapStateToProps = (state) => {
  const {
    preguntas,
    medicamentosDgis,
    diagnosticos,
    pacienteActual,
  } = state.Think;

  return {
    preguntas,
    medicamentosDgis,
    diagnosticos,
    paciente: pacienteActual,
  };
};

export default connect(mapStateToProps, actions)(Index);

import React from "react";
import PropTypes, { func } from 'prop-types';
import { connect } from 'react-redux';
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowUp from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { grey, teal } from "@material-ui/core/colors";
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { SwipeableDrawer, Paper, Drawer } from "@material-ui/core";
import { setDisplaySidebar, setDisplayMenu, setPacienteActual } from '../../../actions';
import { Button } from "semantic-ui-react";
import Paused from '../Paused';
import VideoCall from "../../../../../material-ui/VideoCall/VideoCall";
const themeSelected = window.amplify.store('theme') || null;


let menuTheme = createMuiTheme({
    overrides: {
        MuiDrawer: {
            paper: {
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px 14px'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 10px) scale(1)'
            }
        },
        MuiFormHelperText: {
            contained: {
                margin: '4px 0px 0'
            }
        },
    },
    palette: {
        primary: themeSelected ? themeSelected.primary : teal,
        secondary: grey,
        type: themeSelected ? themeSelected.typeMenu : 'dark',
    }
});
let darkTheme = createMuiTheme({
    overrides: {
        MuiDrawer: {
            paper: {
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px 14px'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 10px) scale(1)'
            }
        },
        MuiFormHelperText: {
            contained: {
                margin: '4px 0px 0'
            }
        },
    },
    palette: {
        primary: themeSelected ? themeSelected.primary : teal,
        secondary: grey,
        type: themeSelected ? themeSelected.typeSidebar : 'dark',
    }
});
let darkThemeTransparent = createMuiTheme({
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: 'transparent'
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px 14px'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 10px) scale(1)'
            }
        },
        MuiFormHelperText: {
            contained: {
                margin: '4px 0px 0'
            }
        },
    },
    palette: {
        primary: themeSelected ? themeSelected.primary : teal,
        secondary: grey,
        type: themeSelected ? themeSelected.typeSidebar : 'dark',
    }
});
var userAgent = navigator.userAgent.toLowerCase();
const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
const minWidthSideBar = window.isMobile ? 0 : 50;
const useStyles = makeStyles(theme => ({
    rootBase: {
        display: "flex",
        backgroundImage: 'linear-gradient(rgba(75,75,75, 0.95), rgba(100,100,100,0.9), rgba(75,75,75, 0.95))',
        height: '100vh'
    },
    contentBase: {
        width: '100%',
        height: 'calc(100vh)',
    },
    contentBaseOpen: {
        height: 'calc(100vh)',
        transition: theme.transitions.create("height", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    contentBaseClose: {
        height: 'calc(100vh - 300px)',
        transition: theme.transitions.create("height", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    barButton: {
        position: 'absolute',
        overflow: "hidden",
        width: '100%',
        height: 0,
        bottom: 0,
        backgroundImage: 'linear-gradient(rgba(0,0,0, 0.95), rgb(0,0,0), rgba(0,0,0,, 0.95))'
    },
    barButtonOpen: {
        position: 'absolute',
        height: 300,
        transition: theme.transitions.create("height", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        backgroundImage: 'linear-gradient(rgba(0,0,0, 0.95), rgb(0,0,0), rgba(0,0,0,, 0.95))'
    },
    barButtonClose: {
        position: 'absolute',
        height: 0,
        transition: theme.transitions.create("height", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        backgroundImage: 'linear-gradient(rgba(10,10,10,0.8), rgb(10,10,10), rgba(10,10,10,0.8))'
    }
}));

function ToolBottomBar(props) {
    const classes = useStyles();
    return (
        <div className={classes.rootBase} style={{ backgroundColor: 'rgba(200,200,200,0.2)' }}>
            <Paper
                className={clsx(classes.contentBase, {
                    [classes.contentBaseOpen]: !props.showVideoCall,
                    [classes.contentBaseClose]: props.showVideoCall,
                })}
            >
                {props.content}
            </Paper>
            <ThemeProvider theme={window.isMobile ? darkThemeTransparent : darkTheme}>
                <SwipeableDrawer
                    onOpen={() => { }}
                    onClose={() => { }}
                    variant={'permanent'}
                    className={clsx(classes.barButton, {
                        [classes.barButtonOpen]: props.showVideoCall,
                        [classes.barButtonClose]: !props.showVideoCall
                    })}
                    classes={{
                        paper: clsx({
                            [classes.barButtonOpen]: props.showVideoCall,
                            [classes.barButtonClose]: !props.showVideoCall
                        })
                    }}
                    anchor={'bottom'}
                    open={props.showVideoCall}
                >
                    <Paper id={'drawerHelpBar'}>
                        {
                            props.showVideoCall &&
                            <VideoCall 
                            setHiddeVideoCall={props.setHiddeVideoCall || null}
                            />
                        }
                    </Paper>
                </SwipeableDrawer>
            </ThemeProvider>
        </div>
    );
}

ToolBottomBar.propTypes = {

};

const mapStateToProps = (state) => {
    const { drawersLayout } = state.Think;
    return { drawersLayout };
};

const actions = {
    setDisplaySidebar, setDisplayMenu, setPacienteActual
};


export default connect(mapStateToProps, actions)(ToolBottomBar);

import React, { useState } from 'react'
import LoaderThink from '../LoaderThink';
import Jitsi from 'react-jitsi'
export default (props) => {
    const { setHiddeVideoCall, displayName, setApi, setOnCall, roomName, password } = props;
    return (
        <Jitsi
            roomName={roomName}
            displayName={displayName}
            loadingComponent={LoaderThink}
            onAPILoad={JitsiMeetAPI => {
                setApi(JitsiMeetAPI)
                console.log('apiiii', JitsiMeetAPI)
                JitsiMeetAPI.addEventListener('readyToClose', () => {
                    setOnCall(false)
                    setHiddeVideoCall && setHiddeVideoCall()
                });
                JitsiMeetAPI.addEventListener('readyToClose', () => {
                    setOnCall(false)
                    setHiddeVideoCall && setHiddeVideoCall()
                });
                JitsiMeetAPI.addEventListener('error', (e) => {
                    console.log('error------>', e)
                    setOnCall(false)
                    setOnCall(true)
                });
                console.log('Good Morning everyone!')
            }}
            containerStyle={{ height: '100%', width: '100%', outline: 'none' }}
            domain={'meet.thin-k.mx'}
            config={{
                startWithAudioMuted: true,
                startWithVideoMuted: false,  
                p2p: true,
                
            }}
            interfaceConfig={{
                DEFAULT_BACKGROUND: '#474747',
                DISABLE_VIDEO_BACKGROUND: true,
                INITIAL_TOOLBAR_TIMEOUT: 20000,
                TOOLBAR_TIMEOUT: 4000,
                TOOLBAR_ALWAYS_VISIBLE: false,
                DEFAULT_REMOTE_DISPLAY_NAME: 'Paciente',
                DEFAULT_LOCAL_DISPLAY_NAME: 'Yo',
                SHOW_JITSI_WATERMARK: false,
                JITSI_WATERMARK_LINK: 'https://www.thin-k.mx',
                SHOW_WATERMARK_FOR_GUESTS: false,
                SHOW_BRAND_WATERMARK: false,
                BRAND_WATERMARK_LINK: '',
                SHOW_POWERED_BY: false,
                SHOW_DEEP_LINKING_IMAGE: false,
                GENERATE_ROOMNAMES_ON_WELCOME_PAGE: true,
                DISPLAY_WELCOME_PAGE_CONTENT: true,
                DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
                APP_NAME: 'Jitsi Meet',
                NATIVE_APP_NAME: 'Jitsi Meet',
                PROVIDER_NAME: 'Jitsi',
                LANG_DETECTION: true, // Allow i18n to detect the system language
                INVITATION_POWERED_BY: false,
                AUTHENTICATION_ENABLE: true,
                TOOLBAR_BUTTONS: [
                    'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
                    'fodeviceselection', 'hangup', 'profile', 'info', 'chat', 'recording',
                    'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
                    'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
                    'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone'
                ],
                SETTINGS_SECTIONS: ['devices', 'language', 'moderator', 'profile', 'calendar'],
                VIDEO_LAYOUT_FIT: 'both',
                filmStripOnly: false,
                VERTICAL_FILMSTRIP: true,
                CLOSE_PAGE_GUEST_HINT: false,
                SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                RANDOM_AVATAR_URL_PREFIX: false,
                RANDOM_AVATAR_URL_SUFFIX: false,
                FILM_STRIP_MAX_HEIGHT: 120,
                ENABLE_FEEDBACK_ANIMATION: false,
                DISABLE_FOCUS_INDICATOR: false,
                DISABLE_DOMINANT_SPEAKER_INDICATOR: false,
                DISABLE_TRANSCRIPTION_SUBTITLES: false,
                DISABLE_RINGING: false,
                AUDIO_LEVEL_PRIMARY_COLOR: 'rgba(255,255,255,0.4)',
                AUDIO_LEVEL_SECONDARY_COLOR: 'rgba(255,255,255,0.2)',
                POLICY_LOGO: null,
                LOCAL_THUMBNAIL_RATIO: 16 / 9, // 16:9
                REMOTE_THUMBNAIL_RATIO: 1, // 1:1
                // LIVE_STREAMING_HELP_LINK: 'https://jitsi.org/live',
                MOBILE_APP_PROMO: true,
                MAXIMUM_ZOOMING_COEFFICIENT: 1.3,
                // SUPPORT_URL: 'https://community.jitsi.org/',
                CONNECTION_INDICATOR_AUTO_HIDE_ENABLED: true,
                CONNECTION_INDICATOR_AUTO_HIDE_TIMEOUT: 5000,
                CONNECTION_INDICATOR_DISABLED: false,
                VIDEO_QUALITY_LABEL_DISABLED: false,
                RECENT_LIST_ENABLED: true,
                OPTIMAL_BROWSERS: ['chrome', 'chromium', 'firefox', 'nwjs', 'electron'],
                UNSUPPORTED_BROWSERS: [],
                AUTO_PIN_LATEST_SCREEN_SHARE: 'remote-only',
                DISABLE_PRESENCE_STATUS: false,
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
                SHOW_CHROME_EXTENSION_BANNER: false

                /**
                 * When enabled, the kick participant button will not be presented for users without a JWT
                 */
                // HIDE_KICK_BUTTON_FOR_GUESTS: false

                /**
                 * How many columns the tile view can expand to. The respected range is
                 * between 1 and 5.
                 */
                // TILE_VIEW_MAX_COLUMNS: 5,

                /**
                 * Specify custom URL for downloading android mobile app.
                 */
                // MOBILE_DOWNLOAD_LINK_ANDROID: 'https://play.google.com/store/apps/details?id=org.jitsi.meet',

                /**
                 * Specify URL for downloading ios mobile app.
                 */
                // MOBILE_DOWNLOAD_LINK_IOS: 'https://itunes.apple.com/us/app/jitsi-meet/id1165103905',

                /**
                 * Specify mobile app scheme for opening the app from the mobile browser.
                 */
                // APP_SCHEME: 'org.jitsi.meet',

                /**
                 * Specify the Android app package name.
                 */
                // ANDROID_APP_PACKAGE: 'org.jitsi.meet',

                /**
                 * Override the behavior of some notifications to remain displayed until
                 * explicitly dismissed through a user action. The value is how long, in
                 * milliseconds, those notifications should remain displayed.
                 */
                // ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 15000,

                // List of undocumented settings
                /**
                 INDICATOR_FONT_SIZES
                 MOBILE_DYNAMIC_LINK
                 PHONE_NUMBER_REGEX
                */
            }}
        />
    )
}
import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Avatar,
  Typography,
  IconButton,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
} from "@material-ui/core";

import moment from "moment";
import { Delete } from "@material-ui/icons";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  item: {
    "& .small": {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    "& .large": {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginRight: theme.spacing(2),
    },
  },
}));

const Index = ({ data, onClick, onDelete, ...props }) => {
  const classes = useStyles();
  const row = {
    ...data,
    vc_nombreCompleto: `${data.vc_nombre} ${data.vc_apellidoPaterno} ${
      data.vc_apellidoMaterno || ""
    }`
      .trim()
      .toUpperCase(),
    edad: moment().diff(moment(data.dt_nacimiento), "years", false),
    nacimiento: moment(data.dt_nacimiento).format("LL"),
    sexo: data.id_sexo === 1 ? "Masculino" : "Femenino",
  };

  let avatar =
    data.id_sexo === 1 ? "assets/images/hombre.png" : "assets/images/mujer.png";

  if (data.vc_url) avatar = data.vc_url;

  const classesAvatar = classNames({ small: props.small, large: props.large });

  return (
    <ListItem {...props} onClick={onClick} className={classes.item}>
      <ListItemAvatar>
        <Avatar src={avatar} className={classesAvatar} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="subtitle1" component="h1">
            {row.vc_nombreCompleto}
          </Typography>
        }
        secondary={
          <Box>
            {row.dt_nacimiento && (
              <Typography component="div" variant="body1" color="textSecondary">
                {row.edad} años, {row.nacimiento}
              </Typography>
            )}
            <Typography component="div" variant="body1" color="textSecondary">
              {row.vc_curp}
            </Typography>
          </Box>
        }
      />
      {Boolean(onDelete) && (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="comments" onClick={onDelete}>
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

Index.defaultProps = {
  data: {},
  onClick: () => {},
};

Index.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};

export default Index;

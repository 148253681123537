import internalAPI from '../../utils/internalAPI';
import memoize from 'lodash/memoize';

const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const rfcRegexp = /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/i;

export const required = value => (Boolean(value) ? undefined : 'Requerido');
export const mustBeEmail = value => (!emailRegexp.test(value) ? 'Debe de ser un correo válido' : undefined);
export const mustBeRFC = value => (!rfcRegexp.test(value) ? 'Debe de ser un RFC válido' : undefined);
export const mustBeLengthString = size => value => String(value).length < size ? `Debe ser exactamente a ${size} caracteres` : undefined;
export const mustBeLengthNumber = size => value => String(value).length < size ? `Debe ser exactamente a ${size} digitos` : undefined;
export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const mustBeUnique = (item, field, id) => {
  return memoize((value, ...props) => {
    return new Promise((resolve, reject) => {
      internalAPI.unique({ item, field, value, id }, (err, response) => {
        if (err) {
          return reject();
        }

        resolve(Boolean(response.id) ? 'Ya se encuentra en uso' : undefined);
      });
    });
  });
};
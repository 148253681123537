import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Popup, Image } from "semantic-ui-react";
// import InputRange from 'react-input-range';
import moment from "moment";
import { VideoCustom } from "../../../../components";
import TratamientosItemTimeLine from "./tratamientosItemTimeLine";
import DiagnosticosItem from "./diagnosticosItem";
import ProcedimientosItem from "./procedimientosItem";
// import styleInput from '../../../../../../node_modules/react-input-range/lib/css/index.css';
import { Paper, Slider, Typography } from "@material-ui/core";
import Charts from "../../../../material-ui/Charts";
import { setDisplaySidebar } from "../../actions";

class TimeLineHealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scala: {
        min: this.props.pacienteActual.detalle
          ? moment(this.props.pacienteActual.detalle.dt_nacimiento) * 1
          : moment() * 1 - 1000,
        max: moment() * 1,
      },
      scalaMaterial: [
        this.props.pacienteActual.detalle
          ? moment(this.props.pacienteActual.detalle.dt_nacimiento) * 1
          : moment() * 1 - 1000,
        moment() * 1,
      ],
      selectors: [
        {
          vc_nombre: "Diagnósticos",
          colorSelected: "rgb(0, 102, 175)",
          colorUnSelected: "rgba(0, 102, 175, 0.3)",
          active: true,
        },
        {
          vc_nombre: "Diagnósticos",
          colorSelected: "rgb(1, 122, 96)",
          colorUnSelected: "rgba(1, 122, 96, 0.3)",
          active: true,
        },
        {
          vc_nombre: "Procedimientos",
          colorSelected: "rgb(92, 1, 135)",
          colorUnSelected: "rgba(92, 1, 135, 0.3)",
          active: true,
        },
        {
          vc_nombre: "Otros",
          colorSelected: "rgb(0, 134, 201)",
          colorUnSelected: "rgb(0, 134, 201, 0.3)",
          active: true,
        },
      ],
    };
  }

  startLandingTimeLine() {}

  componentDidMount() {
    this.overScreen();
    this.props.setDisplaySidebar(false);
  }
  componentWillUnmount() {
    this.props.setDisplaySidebar(true);
  }
  overScreen(event) {
    console.log(event);
  }
  nivelesPreguntas(preguntas) {
    const nivelesPreguntas = [];
    preguntas.map((pregunta) => {
      if (!nivelesPreguntas.includes(pregunta.id_nivel) && pregunta.respuesta) {
        nivelesPreguntas.push(pregunta.id_nivel);
      }
      return null;
    });
    this.props.pacienteRespuestas.map((pacienteRespuesta, index) => {
      const preguntas = this.props.preguntas.filter(
        (e) => e.id === pacienteRespuesta.id_pregunta
      );
      if (preguntas.length > 0) {
        if (!nivelesPreguntas.includes(preguntas[0].id_nivel)) {
          nivelesPreguntas.push(preguntas[0].id_nivel);
        }
      }
    });
    return nivelesPreguntas;
  }
  titleNumericas(vc_pregunta) {
    let textEdit = "";
    const text = vc_pregunta.split("(")[0];
    if (text.split(" ").length > 1 && text.split(" ")[1] !== "") {
      text.split(" ").map((word) => {
        if (textEdit === "") {
          textEdit = word.substr(0, 1) + ". ";
        } else {
          textEdit =
            textEdit +
            word.substr(0, 1).toUpperCase() +
            word
              .substr(1, word.length <= 4 ? word.length - 1 : 3)
              .toLowerCase() +
            ".";
        }
      });
      return textEdit;
    } else if (text.length > 4) {
      textEdit =
        text.substr(0, 1).toUpperCase() + text.substr(1, 3).toLowerCase() + ".";
      return textEdit;
    }
    return (
      text.substr(0, 1).toUpperCase() +
      text.substr(1, text.length - 1).toLowerCase()
    );
  }
  respuestasPreguntas(pregunta) {
    const { unidadesMedida } = this.props;
    switch (pregunta.id_tipoPregunta) {
      case 3:
        const um = unidadesMedida.find((e) => e.id === pregunta.id_um_numerico);
        const registros = this.props.pacienteRespuestas.filter(
          (e) => e.id_pregunta === pregunta.id
        );
        console.log("respuesta", pregunta.respuesta);
        if (um && (pregunta.respuesta || registros.length > 0)) {
          if (pregunta.respuesta) {
            registros.push(pregunta.respuesta);
          }
          console.log("entro a renderizar");
          console.log("registros", registros);

          return (
            <div>
              <Typography>
                {`${pregunta.respuesta.nu_respuesta} ${um.vc_abreviatura}`}
              </Typography>
              {/* <Charts
                                name={pregunta.preguntaVersion.vc_pregunta}
                                registers={registros}
                                typeRegisters
                            /> */}
            </div>
          );
          return (
            <Charts
              name={pregunta.preguntaVersion.vc_pregunta}
              registers={registros}
              typeRegisters
            />
          );
          return `${pregunta.respuesta.nu_respuesta} ${um.vc_abreviatura}`;
        }
        break;
      case 4:
        if (pregunta.respuesta) {
          return `${pregunta.respuesta.vc_respuesta}`;
        }
        break;
      default:
        return "";
    }
  }
  isValidSex(id_sexoPaciente, id_sexoPregunta) {
    if (id_sexoPaciente == id_sexoPregunta || id_sexoPregunta == 3) {
      return true;
    } else {
      return false;
    }
  }
  isValidAge(nuAgeMinutesPaciente, item) {
    const { unidadesMedida } = this.props;
    let limiteInferiorAge = null;
    let limiteSuperiorAge = null;
    if (item.nu_minEdad > 0 && item.id_um_minEdad != null) {
      const nuUmBase = this.finder(unidadesMedida, "id", item.id_um_minEdad)
        .nu_ratio;
      limiteInferiorAge = nuUmBase * item.nu_minEdad;
    }
    if (item.nu_maxEdad > 0 && item.id_um_maxEdad != null) {
      const nuUmBase = this.finder(unidadesMedida, "id", item.id_um_maxEdad)
        .nu_ratio;
      limiteSuperiorAge = nuUmBase * item.nu_maxEdad;
    }
    if (limiteInferiorAge != null && nuAgeMinutesPaciente < limiteInferiorAge) {
      return false;
    }
    if (limiteSuperiorAge != null && nuAgeMinutesPaciente > limiteSuperiorAge) {
      return false;
    }
    return true;
  }
  finder(array, key, value) {
    const object = array.find((e) => {
      return e[`${key}`] === value;
    });
    if (object) {
      return object;
    }
    return null;
  }
  preguntaVersion(preguntaActual) {
    let pacienteDetalle = null;
    const pacienteObj = this.props.pacienteActual;
    if (pacienteObj !== {} && pacienteObj != null) {
      pacienteDetalle = pacienteObj.detalle;
    } else {
      return preguntaActual.versiones[0] || null;
    }
    const idSexo = pacienteDetalle.id_sexo;
    const nuEdadMinutos =
      (new Date() - new Date(pacienteDetalle.dt_nacimiento)) / 60000;
    let version = null;
    preguntaActual.versiones.map((preguntaVersion) => {
      if (
        this.isValidAge(nuEdadMinutos, preguntaVersion) &&
        this.isValidSex(idSexo, preguntaVersion.id_sexo)
      ) {
        version = preguntaVersion;
      }
      return null;
    });
    return version;
  }
  widthTime(dt_inicio, dt_fin, scala) {
    return (dt_fin - dt_inicio) / scala;
  }
  renderPreguntas() {
    const { niveles, preguntas } = this.props;
    const scala = this.state.scala;
    return this.nivelesPreguntas(preguntas).map((nivel, index) => {
      const niv = niveles.filter((e) => e.id === nivel)[0];
      const nombreNivel = niv.vc_nombre || "";
      return (
        <div
          key={index}
          style={{
            float: "left",
            textAlign: "center",
            fontSize: 16,
            width: "100%",
          }}
        >
          <div
            style={{
              textAlign: "left",
              float: "left",
              width: "100%",
              padding: 0,
              height: 15,
              fontSize: 12,
              margin: 2,
            }}
          >
            {nombreNivel.toLowerCase() === "somatometria" ||
            nombreNivel.toLowerCase() === "pruebas rapidas" ||
            nombreNivel.toLowerCase() === "signos vitales"
              ? nombreNivel
              : nombreNivel}
          </div>
          {preguntas
            .filter((e) => e.id_nivel === nivel && e.respuesta)
            .map((pregunta, indexPregunta) => {
              const version = this.preguntaVersion(pregunta);
              return (
                <div
                  key={indexPregunta}
                  style={{
                    borderRadius: 7.5,
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                    margin: 0,
                    marginBottom: 1,
                    paddingLeft: 0,
                    float: "left",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      boxShadow: "1px 0px 1px rgba(0, 0, 0, 0)",
                      padding: 1,
                      height: 15,
                      minWidth: 15,
                      borderRadius: 7.5,
                      backgroundColor: "transparent",
                      float: "left",
                      width: this.renderMarginLeftRespuestas(
                        scala,
                        pregunta.respuesta
                      ),
                      maxWidth: "calc(100% - 16px)",
                    }}
                  />
                  <Popup
                    trigger={
                      <div
                        style={{
                          boxShadow: "1px 0px 1px rgba(0, 0, 0, 0.5)",
                          padding: 1,
                          height: 15,
                          minWidth: 15,
                          borderRadius: 7.5,
                          backgroundColor: this.colorRespuesta(
                            pregunta.respuesta
                          ),
                          float: "left",
                          width: this.renderWidthRespuestas(
                            scala,
                            pregunta.respuesta
                          ),
                          maxWidth: "100%",
                        }}
                      ></div>
                    }
                    content={
                      <div
                        style={{
                          margin: 0,
                          padding: 5,
                          float: "left",
                          width: "100%",
                        }}
                      >
                        <div style={{ float: "left" }}>
                          {version
                            ? version.vc_pregunta
                            : "Pregunta no encontrada"}
                          : {this.respuestasPreguntas(pregunta)}
                        </div>
                      </div>
                    }
                    basic
                    on="hover"
                    hoverable
                    hideOnScroll
                  />
                </div>
              );
            })}
        </div>
      );
    });
  }
  isActive(dt_vigencia) {
    if (new Date(dt_vigencia) < new Date()) {
      return false;
    }
    return true;
  }
  description(tipo) {
    switch (tipo) {
      case "respuestas":
    }
  }
  color(item) {
    return this.isActive(item.detalles[item.detalles.length - 1].dt_vigencia)
      ? window.colors.itemSelected
      : "rgba(100, 100, 100, 0.8)";
  }
  colorEvent(item) {
    return window.colors.itemSelected;
  }
  colorRespuesta(item) {
    return this.isActive(item.dt_vigencia)
      ? window.colors.itemSelected
      : "rgba(100, 100, 100, 0.8)";
  }
  renderWidthItem(scala, item) {
    const rangoTotal = scala.max - scala.min;
    const inicio = moment(item.detalles[item.detalles.length - 1].dt_inicio);
    const vigencia = moment(
      item.detalles[item.detalles.length - 1].dt_vigencia
    );
    const min = scala.min;
    const max = scala.max;
    if (vigencia <= scala.max && inicio >= min) {
      return `${((vigencia - inicio) / rangoTotal) * 100}%`;
    } else if (vigencia <= max && inicio <= min) {
      return `${((vigencia - min) / rangoTotal) * 100}%`;
    } else if (vigencia >= max && inicio >= min) {
      return `${((max - inicio) / rangoTotal) * 100}%`;
    } else {
      return "100%";
    }
  }
  renderWidthItemMedicamentos(scala, item) {
    const rangoTotal = scala.max - scala.min;
    let inicio = moment(item.detalles[item.detalles.length - 1].dt_inicio);
    item.detalles
      .filter((e) => this.isActive(e.dt_vigencia))
      .map((detalle) => {
        if (moment(detalle.dt_inicio) < inicio) {
          inicio = moment(detalle.dt_inicio);
        }
      });
    const vigencia = moment(
      item.detalles[item.detalles.length - 1].dt_vigencia
    );
    const min = scala.min;
    const max = scala.max;
    if (vigencia <= scala.max && inicio >= min) {
      return `${((vigencia - inicio) / rangoTotal) * 100}%`;
    } else if (vigencia <= max && inicio <= min) {
      return `${((vigencia - min) / rangoTotal) * 100}%`;
    } else if (vigencia >= max && inicio >= min) {
      return `${((max - inicio) / rangoTotal) * 100}%`;
    } else {
      return "100%";
    }
  }
  renderWidthRespuestas(scala, item) {
    const rangoTotal = scala.max - scala.min;
    const inicio = moment(item.dt_registro);
    const vigencia = moment(item.dt_vigencia);
    const min = scala.min;
    const max = scala.max;
    if (vigencia <= scala.max && inicio >= min) {
      return `${((vigencia - inicio) / rangoTotal) * 100}%`;
    } else if (vigencia <= max && inicio <= min) {
      return `${((vigencia - min) / rangoTotal) * 100}%`;
    } else if (vigencia >= max && inicio >= min) {
      return `${((max - inicio) / rangoTotal) * 100}%`;
    } else {
      return "100%";
    }
  }
  renderMarginLeftItemMedicamentos(scala, item) {
    const rangoTotal = scala.max - scala.min;
    let inicio = moment(item.detalles[item.detalles.length - 1].dt_inicio);
    item.detalles
      .filter((e) => this.isActive(e.dt_vigencia))
      .map((detalle) => {
        if (moment(detalle.dt_inicio) < inicio) {
          inicio = moment(detalle.dt_inicio);
        }
      });
    const min = scala.min;
    const max = scala.max;
    if (inicio > min) {
      return `${((inicio - min) / rangoTotal) * 100}%`;
    }
    return "0%";
  }
  renderMarginLeftItem(scala, item) {
    const rangoTotal = scala.max - scala.min;
    const inicio = moment(item.detalles[item.detalles.length - 1].dt_inicio);
    const min = scala.min;
    const max = scala.max;
    if (inicio > min) {
      return `${((inicio - min) / rangoTotal) * 100}%`;
    }
    return "0%";
  }
  renderMarginLeftEvents(scala, item) {
    const rangoTotal = scala.max - scala.min;
    const inicio = item.timeStamp;
    const min = scala.min;
    const max = scala.max;
    if (inicio > min) {
      return `${((inicio - min) / rangoTotal) * 100}%`;
    }
    return "0%";
  }
  renderMarginLeftRespuestas(scala, item) {
    const rangoTotal = scala.max - scala.min;
    const inicio = moment(item.dt_registro);
    const min = scala.min;
    const max = scala.max;
    if (inicio > min) {
      return `${((inicio - min) / rangoTotal) * 100}%`;
    }
    return "0%";
  }
  isShowTimeLapse(scala, item) {
    const min = scala.min;
    const max = scala.max;
    const inicio = moment(item.detalles[item.detalles.length - 1].dt_inicio);
    const vigencia = moment(
      item.detalles[item.detalles.length - 1].dt_vigencia
    );
    if (inicio >= min && inicio < max) {
      return true;
    } else if (vigencia >= min && vigencia <= max) {
      return true;
    } else if (inicio <= min && vigencia >= max) {
      return true;
    }
    return false;
  }
  isShowTimeLapseMedicamentos(scala, item) {
    const min = scala.min;
    const max = scala.max;
    let inicio = moment(item.detalles[item.detalles.length - 1].dt_inicio);
    item.detalles
      .filter((e) => this.isActive(e.dt_vigencia))
      .map((detalle) => {
        if (moment(detalle.dt_inicio) < inicio) {
          inicio = moment(detalle.dt_inicio);
        }
      });
    const vigencia = moment(
      item.detalles[item.detalles.length - 1].dt_vigencia
    );
    if (inicio >= min && inicio < max) {
      return true;
    } else if (vigencia >= min && vigencia <= max) {
      return true;
    } else if (inicio <= min && vigencia >= max) {
      return true;
    }
    return false;
  }
  isShowTimeLapseEvent(scala, item) {
    const min = scala.min;
    const max = scala.max;
    const inicio = item.timeStamp;
    if (inicio >= min && inicio < max) {
      return true;
    }
    return false;
  }
  renderDiagnosticos() {
    const scala = this.state.scala;
    const filter = this.props.diagnosticosPaciente.filter((item) =>
      this.isShowTimeLapse(scala, item)
    );
    return filter.map((item, index) => (
      <div
        key={index}
        style={{
          borderRadius: 7.5,
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          margin: 0,
          marginBottom: 1,
          paddingLeft: 0,
          float: "left",
          width: "100%",
        }}
      >
        <div
          style={{
            boxShadow: "1px 0px 1px rgba(0, 0, 0, 0)",
            padding: 1,
            height: 15,
            minWidth: 15,
            borderRadius: 7.5,
            backgroundColor: "transparent",
            float: "left",
            width: this.renderMarginLeftItem(scala, item),
            maxWidth: "calc(100% - 16px)",
          }}
        />
        <Popup
          trigger={
            <div
              style={{
                boxShadow: "1px 0px 1px rgba(0, 0, 0, 0.5)",
                padding: 1,
                height: 15,
                minWidth: 15,
                borderRadius: 7.5,
                backgroundColor: this.color(item),
                float: "left",
                width: this.renderWidthItem(scala, item),
                maxWidth: "100%",
              }}
            />
          }
          content={
            <DiagnosticosItem
              item={item}
              index={index}
              key={index}
              sn_printVersion={true}
            />
          }
          basic
          on="hover"
          hoverable
          hideOnScroll
        />
      </div>
    ));
  }
  renderProcedimientos() {
    const scala = this.state.scala;
    const filter = this.props.procedimientosPaciente
      .filter((item) => this.isShowTimeLapse(scala, item))
      .sort(function ({ detalles: a }, { detalles: b }) {
        return moment(a[0].dt_inicio) - moment(b[0].dt_inicio);
      });

    console.log("procedimientos", filter);
    return filter.map((item, index) => (
      <div
        key={index}
        style={{
          borderRadius: 7.5,
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          margin: 0,
          marginBottom: 1,
          paddingLeft: 0,
          float: "left",
          width: "100%",
        }}
      >
        <div
          style={{
            boxShadow: "1px 0px 1px rgba(0, 0, 0, 0)",
            padding: 1,
            height: 15,
            minWidth: 15,
            borderRadius: 7.5,
            backgroundColor: "transparent",
            float: "left",
            width: this.renderMarginLeftItem(scala, item),
            maxWidth: "calc(100% - 16px)",
          }}
        />
        <Popup
          trigger={
            <div
              style={{
                boxShadow: "1px 0px 1px rgba(0, 0, 0, 0.5)",
                padding: 1,
                height: 15,
                minWidth: 15,
                borderRadius: 7.5,
                backgroundColor: this.color(item),
                float: "left",
                width: this.renderWidthItem(scala, item),
                maxWidth: "100%",
              }}
            />
          }
          content={
            <ProcedimientosItem
              item={item}
              index={index}
              key={index}
              sn_printVersion={true}
            />
          }
          basic
          on="hover"
          hoverable
          hideOnScroll
        />
      </div>
    ));
  }
  renderImagenes() {
    const scala = this.state.scala;
    const filter = this.props.imagenes.filter((item) =>
      this.isShowTimeLapseEvent(scala, item)
    );
    return filter.map((item, index) => (
      <div
        key={index}
        style={{
          borderRadius: 7.5,
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          margin: 0,
          marginBottom: 1,
          paddingLeft: 0,
          float: "left",
          width: "100%",
        }}
      >
        <div
          style={{
            boxShadow: "1px 0px 1px rgba(0, 0, 0, 0)",
            padding: 1,
            height: 15,
            minWidth: 15,
            borderRadius: 7.5,
            backgroundColor: "transparent",
            float: "left",
            width: this.renderMarginLeftEvents(scala, item),
            maxWidth: "calc(100% - 16px)",
          }}
        />
        <Popup
          trigger={
            <div
              style={{
                boxShadow: "1px 0px 1px rgba(0, 0, 0, 0.5)",
                padding: 1,
                height: 15,
                minWidth: 15,
                borderRadius: 7.5,
                backgroundColor: this.colorEvent(item),
                float: "left",
                width: 15,
                maxWidth: "100%",
              }}
            />
          }
          content={
            <Image
              size="large"
              src={item.url}
              style={{ margin: 0, padding: 0, borderRadius: 5 }}
            />
          }
          basic
          on="hover"
          hoverable
          hideOnScroll
          style={{
            margin: 0,
            padding: 0,
            backgroundColor: "transparent",
            boxShadow: null,
            minHeight: window.screen.availHeight * 0.3,
          }}
        />
      </div>
    ));
  }
  renderVideos() {
    const scala = this.state.scala;
    const filter = this.props.videos.filter((item) =>
      this.isShowTimeLapseEvent(scala, item)
    );
    return filter.map((item, index) => (
      <div
        key={index}
        style={{
          borderRadius: 7.5,
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          margin: 0,
          marginBottom: 1,
          paddingLeft: 0,
          float: "left",
          width: "100%",
        }}
      >
        <div
          style={{
            boxShadow: "1px 0px 1px rgba(0, 0, 0, 0)",
            padding: 1,
            height: 15,
            minWidth: 15,
            borderRadius: 7.5,
            backgroundColor: "transparent",
            float: "left",
            width: this.renderMarginLeftEvents(scala, item),
            maxWidth: "calc(100% - 16px)",
          }}
        />
        <Popup
          trigger={
            <div
              style={{
                boxShadow: "1px 0px 1px rgba(0, 0, 0, 0.5)",
                padding: 1,
                height: 15,
                minWidth: 15,
                borderRadius: 7.5,
                backgroundColor: this.colorEvent(item),
                float: "left",
                width: 15,
                maxWidth: "100%",
              }}
            />
          }
          content={
            <Grid
              centered
              style={{
                margin: 0,
                padding: 0,
                borderRadius: 5,
                backgroundColor: "transparent",
              }}
            >
              <VideoCustom
                autoPlay
                src={item.url}
                style={{ margin: 0, padding: 0, borderRadius: 5 }}
              />
            </Grid>
          }
          basic
          on="hover"
          hoverable
          hideOnScroll
          style={{
            minWidth: "50%",
            margin: 0,
            padding: 0,
            backgroundColor: "transparent",
          }}
        />
      </div>
    ));
  }
  renderMedicamentos() {
    const scala = this.state.scala;
    const filter = this.props.medicamentos.filter((item) =>
      this.isShowTimeLapseMedicamentos(scala, item)
    );
    return filter.map((item, index) => (
      <div
        key={index}
        style={{
          borderRadius: 7.5,
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          margin: 0,
          marginBottom: 1,
          paddingLeft: 0,
          float: "left",
          width: "100%",
        }}
      >
        <div
          style={{
            boxShadow: "1px 0px 1px rgba(0, 0, 0, 0)",
            padding: 1,
            height: 15,
            minWidth: 15,
            borderRadius: 7.5,
            backgroundColor: "transparent",
            float: "left",
            width: this.renderMarginLeftItemMedicamentos(scala, item),
            maxWidth: "calc(100% - 16px)",
          }}
        />
        <Popup
          trigger={
            <div
              style={{
                boxShadow: "1px 0px 1px rgba(0, 0, 0, 0.5)",
                padding: 1,
                height: 15,
                minWidth: 15,
                borderRadius: 7.5,
                backgroundColor: this.color(item),
                float: "left",
                width: this.renderWidthItemMedicamentos(scala, item),
                maxWidth: "100%",
              }}
            />
          }
          content={
            <TratamientosItemTimeLine
              item={item}
              sn_printVersion={true}
              index={index}
              key={index}
            />
          }
          basic
          on="hover"
          hoverable
          hideOnScroll
        />
      </div>
    ));
  }
  timeSince(value) {
    var seconds = value / 1000;

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " a";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " m";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " d";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " h";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " m";
    }
    return seconds + "s";
  }
  changeOn(index) {
    const selectors = this.state.selectors;
    selectors[index].active = !selectors[index].active;
    // this.setState({selectors});
  }
  mousePosition(event) {
    // console.log(event);
  }
  render() {
    let dt_inicio = this.props.pacienteActual.detalle
      ? moment(this.props.pacienteActual.detalle.dt_nacimiento) * 1
      : moment() * 1;
    const scalaDefault = { min: dt_inicio, max: moment() * 1 };
    const scalaDefaultMaterial = [dt_inicio, moment() * 1];
    console.log("scala material", scalaDefaultMaterial);
    console.log("scala", this.state.scalaMaterial);
    const maxValue = moment() * 1 + 1;
    return (
      <Paper
        onClick={this.mousePosition(this)}
        style={{
          borderRadius: 10,
          width: "100%",
          height: "100%",
          padding: 5,
          boxShadow: "1px 0px 1px rgba(100, 100, 100, 0.5)",
        }}
      >
        <div
          style={{
            float: "left",
            width: "100%",
            padding: "0px 16px 0px 16px",
            marginTop: 10,
          }}
        >
          <Typography
            style={{
              textAlign: "left",
              float: "left",
              width: "30%",
              padding: 0,
              height: 30,
              fontSize: 12,
            }}
          >
            {`${moment(this.state.scala.min).format("DD-MM-YYYY")}`}
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              float: "left",
              width: "40%",
              padding: 0,
              height: 30,
              fontSize: 14,
            }}
          >
            {`Escala de ${this.timeSince(
              this.state.scala.max - this.state.scala.min
            )}`}
          </Typography>
          <Typography
            style={{
              textAlign: "right",
              float: "left",
              width: "30%",
              padding: 0,
              height: 30,
              fontSize: 12,
            }}
          >
            {`${moment(this.state.scala.max).format("DD-MM-YYYY")}`}
          </Typography>
        </div>
        <div
          style={{
            borderRadius: 10,
            padding: 20,
            width: "100%",
            height: "calc(100% - 100px)",
            overflow: "scroll",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <div style={{ float: "left", width: "100%", padding: 0 }}>
            <div
              style={{
                textAlign: "left",
                float: "left",
                width: "100%",
                padding: 0,
                height: 15,
                fontSize: 12,
              }}
            >
              Diagnosticos:
            </div>
            {this.renderDiagnosticos()}
            <div
              style={{
                textAlign: "left",
                float: "left",
                width: "100%",
                padding: 0,
                height: 15,
                fontSize: 12,
              }}
            >
              Medicamentos:
            </div>
            {this.renderMedicamentos()}
            <div
              style={{
                textAlign: "left",
                float: "left",
                width: "100%",
                padding: 0,
                height: 15,
                fontSize: 12,
              }}
            >
              Procedimientos:
            </div>
            {this.renderProcedimientos()}
            {this.renderPreguntas()}
            <div
              style={{
                textAlign: "left",
                float: "left",
                width: "100%",
                padding: 0,
                height: 15,
                fontSize: 12,
              }}
            >
              Videos:
            </div>
            {this.renderVideos()}
            <div
              style={{
                textAlign: "left",
                float: "left",
                width: "100%",
                padding: 0,
                height: 15,
                fontSize: 12,
              }}
            >
              Imagenes:
            </div>
            {this.renderImagenes()}
          </div>
        </div>

        <div
          style={{
            width: "100%",
            padding: 40,
            paddingTop: 15,
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          <Slider
            max={maxValue}
            min={dt_inicio}
            value={
              this.state.scala
                ? [this.state.scala.min, this.state.scala.max]
                : scalaDefaultMaterial
            }
            onChange={(e, scalaMaterial) => {
              console.log("change", scalaMaterial);
              this.setState({
                scala: { min: scalaMaterial[0], max: scalaMaterial[1] },
              });
            }}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            getAriaValueText={(value) =>
              value - dt_inicio < 84600
                ? "Nacimiento"
                : this.timeSince(value - dt_inicio)
            }
            valueLabelFormat={(value) =>
              value - dt_inicio < 84600
                ? "Nacimiento"
                : this.timeSince(value - dt_inicio)
            }
          />
          {/* <InputRange
                        draggableTrack
                        maxValue={maxValue}
                        minValue={dt_inicio}
                        formatLabel={value => (value - dt_inicio) < 84600 ? 'Nacimiento' : this.timeSince(value - dt_inicio)}
                        value={this.state.scala ? this.state.scala : scalaDefault}
                        onChange={scala => this.setState({ scala })}
                        onChangeComplete={scala => console.log(scala)}
                    /> */}
        </div>
      </Paper>
    );
  }
}

const actions = {
  setDisplaySidebar,
};

const mapStateToProps = (state) => {
  const { diagnosticos, pacienteActual, niveles, unidadesMedida } = state.Think;
  return { diagnosticos, pacienteActual, niveles, unidadesMedida };
};

export default connect(mapStateToProps, actions)(TimeLineHealth);

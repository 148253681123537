import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import SearchIcon from '@material-ui/icons/Search';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  }
}));

export default props => {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="relative" style={{ height: 47 }}>
        <Toolbar style={{ minHeight: '100%' }}>
          {props.back && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="Open drawer"
              onClick={props.back}
            >
              <ArrowBackIos/>
            </IconButton>
          )}

          {props.type==='search' && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Buscar..."
                onChange={props.onChange('searchText')}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          )}

          <div className={classes.grow} />

          {props.type==='search' && (
            <IconButton onClick={props.add}>
              <PlaylistAdd />
            </IconButton>
          )}

          {props.type==='capture' && (
            <IconButton onClick={props.save}>
              <SaveIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
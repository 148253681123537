import internalAPI from "../../../utils/internalAPI";
import notifications from "../../../utils/notifications";
import { logOut } from "../../../containers/Think/actions";
import moment from "moment";

export default {
  saveEditPatient(px, sn_enlace) {
    const { id_institucion } = window.amplify.store("institution") || {};
    const id_usuario = window.amplify.store("user_id") || 0;

    const endPoint = px.detalle.id
      ? internalAPI.editPatients
      : internalAPI.savePatient;

    return new Promise((resolve, reject) => {
      const body = {
        ...px.detalle,
        id: px.detalle.id || null,
        id_institucion,
        id_usuario,
        sn_enlace,
      };

      endPoint(body, (error, response) => {
        if (error)
          reject(error?.texto || "Ocurrio un error al guardar paciente");
        else resolve(response);
      });
    });
  },
};

import React from "react";

import { connect } from "react-redux";
import ConfirmAlert from "../../../material-ui/ConfirmAlert";
import { Box, Paper, Typography, Button, Dialog } from "@material-ui/core";

import * as firebase from "firebase/app";
import moment from "moment";
import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "../../../containers/Think/actions";
import Sidebar from "./Sidebar";
import AccountModal from "./AccountModal";
import SearchSelect from "../../../material-ui/ThinkForm/SearchSelect";
import NumberInput from "../../../material-ui/NumberInput";
import AddAccountModal from "./AddAccountModal";
import ModalCloseCut from "./ModalCloseCut";
import ModalOpenCut from "./ModalOpenCut";
import Table from "./AccountsTable";
import Header from "./Header";

import _ from "lodash";

const EliminarCuenta = ({ open, data, onAccept, onCancel }) => {
  const { client } = data || {};
  return open ? (
    <ConfirmAlert
      title={`¿Desea eliminar la cuenta del cliente: ${client.vc_nombre} ${client.vc_apellidoPaterno} ${client.vc_apellidoMaterno}?`}
      onAccept={onAccept}
      onCancel={onCancel}
    />
  ) : null;
};

const Index = (props) => {
  const institucion = window.amplify.store("institution") || {};
  const usuario = (window.amplify.store("user") || {}).usuario || {};
  const cashRegister = window.amplify.store("cashRegister") || null;

  const db = firebase.database().ref();

  const [cashRegisterSelected, setCashRegisterSelected] = React.useState(
    cashRegister
  );
  const [modal, setModal] = React.useState({
    opencut: false,
    closecut: false,
    addAccount: false,
    account: false,
    eliminarcuenta: false,
  });

  const [opcion, setOpcion] = React.useState("accountsDay");
  const [data, setData] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");

  const [refaccH, setRefaccH] = React.useState(null);

  const [accounts, setAccounts] = React.useState([]);
  const [accountsDay, setAccountsDay] = React.useState([]);
  const [accountsOpen, setAccountsOpen] = React.useState([]);
  const [accountsClosed, setAccountsClosed] = React.useState([]);
  const [accountsHistory, setAccountsHistory] = React.useState([]);

  const [products, setProducts] = React.useState([]);
  const [cashRegisters, setCashRegisters] = React.useState([]);

  const [range, setRange] = React.useState([
    moment().startOf("day"),
    moment().endOf("day"),
  ]);

  const [rowSelected, setRowSelected] = React.useState({
    index: -1,
    row: null,
  });

  React.useEffect(() => {
    const { id_institucion: id } = institucion;
    props.setDisplaySidebar(false);
    props.setHelperSidebarRender(null);

    // PRODUCTOS
    const refprod = db.child(`erp/branches_products/${id}`);

    refprod.on("value", (snapshot) => {
      const value = snapshot.val() ? Object.values(snapshot.val()) : [];
      setProducts(value);
    });

    // CAJAS
    const refcash = db.child(`erp/cashRegisters/${id}`);
    const refcuts = db
      .child(`erp/cashRegistersCuts/${id}`)
      .orderByChild("dt_start");

    refcash.on("value", (snapshot) => {
      const value = snapshot.val() ? Object.values(snapshot.val()) : [];

      refcuts.off();
      refcuts.on("value", (snapshotCuts) => {
        const values = Object.values(snapshotCuts.val() || {}) || [];
        const keys = Object.keys(snapshotCuts.val() || {}) || [];

        values.forEach((v, i) => (v.id = keys[i]));

        const crs = value.map((v) => {
          const cuts = values.filter((e) => e.id_cashRegister === v.id);
          const lastCutEnded = [...cuts.filter((e) => e.dt_end)].pop();
          const cut = [...cuts.filter((e) => !e.dt_end)].pop();

          return {
            ...v,
            lastCutEnded,
            cut,
          };
        });

        setCashRegisters(crs);
      });
    });

    return () => {
      if (refaccH) refaccH.off();

      refprod.off();
      refcash.off();
      refcuts.off();
    };
  }, []);

  // Sidebar
  React.useEffect(() => {
    props.setHelperSidebarRender(() =>
      rowSelected.row ? (
        <Sidebar
          opcion={opcion}
          selectedRow={rowSelected.row}
          revisar={() => {
            if (!(cashRegisterSelected || {}).cut) {
              handleModal("opencut");
            } else {
              handleModal("account");
              props.setDisplaySidebar(false);
            }
          }}
          eliminar={() => {
            handleModal("eliminarcuenta");
          }}
        />
      ) : null
    );
    props.setDisplaySidebar(!!rowSelected.row);
  }, [rowSelected, cashRegisterSelected, modal.account]);

  // Seleccionar caja globalmente
  React.useEffect(() => {
    const oneDay = 1000 * 60 * 60 * 24;
    window.amplify.store("cashRegister", cashRegisterSelected, {
      expires: oneDay,
    });
  }, [cashRegisterSelected]);

  React.useEffect(() => {
    if (cashRegisterSelected) {
      const newcashregister = cashRegisters.find(
        (v) => v.id == cashRegisterSelected.id
      );

      if (newcashregister) setCashRegisterSelected(newcashregister);
    }
  }, [cashRegisters]);

  // Filtro de cuentas
  React.useEffect(() => {
    const dataAux = accounts
      .map(({ client, ...v }) => ({
        ...v,
        client: {
          ...client,
          vc_nombreCompleto: `${client.vc_nombre} ${client.vc_apellidoPaterno} ${client.vc_apellidoMaterno}`,
        },
      }))
      .filter((v) =>
        v.client.vc_nombreCompleto
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );

    console.log("DATAAUX", dataAux);
    if (rowSelected.row) {
      const index = dataAux.findIndex((v) => v.id == rowSelected.row.id);
      setRowSelected({ index, row: dataAux[index] });
    }

    setData(dataAux);
  }, [accounts, searchText]);

  React.useEffect(() => {
    const { id_institucion: id } = institucion;
    let ref = null;
    switch (opcion) {
      case "accountsDay":
        ref = db
          .child(`erp/orders/${id}`)
          .orderByChild("dt_created")
          .startAt(moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"))
          .endAt(moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"));
        break;
      case "accountsOpen":
        ref = db
          .child(`erp/orders/${id}`)
          .orderByChild("dt_created")
          .endAt(moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"));
        break;
      case "accountsHistory":
        ref = db
          .child(`erp/orders/${id}`)
          .orderByChild("dt_created")
          .startAt(
            moment(range[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          )
          .endAt(moment(range[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss"));
        break;
    }

    ref.on("value", (snapshot) => {
      console.log("CAMBIO ALGO");
      const value = snapshot.val() ? Object.values(snapshot.val()) : [];
      if (opcion !== "accountsHistory") {
        setAccounts(value.filter((v) => !("saleCut" in v)));
      } else {
        setAccounts(value.filter((v) => "saleCut" in v));
      }
    });

    return () => ref.off();
  }, [opcion, range]);

  const selectCashRegister = (id_cashRegister, nu_initReceived) => {
    if (institucion.id_institucion && id_cashRegister) {
      const cashRegister = cashRegisters.find((e) => e.id === id_cashRegister);

      setCashRegisterSelected(cashRegister);

      if (nu_initReceived) {
        const {
          vc_nombre,
          vc_apellidoPaterno,
          vc_apellidoMaterno = "",
        } = usuario;

        db.child(`erp/cashRegistersCuts/${institucion.id_institucion}`).push({
          id_cashRegister,
          nu_initReceived,
          dt_start: moment().format("YYYY-MM-DD HH:mm:ss"),
          id_user: institucion.id_usuario,
          vc_nameUser: `${vc_nombre} ${vc_apellidoPaterno} ${vc_apellidoMaterno}`,
          vc_name: cashRegister.vc_name,
        });
      }
    }
  };

  const handleModal = (m, o = true) => setModal((p) => ({ ...p, [m]: o }));

  const handleSelect = (row, index) => {
    const newRowSelected = { index: -1, row: null };

    if (rowSelected.index !== index) {
      newRowSelected.index = index;
      newRowSelected.row = row;
    }

    setRowSelected(newRowSelected);
  };

  const onClickAction = (id) => {
    if (opcion == "accountsDay") {
      switch (id) {
        case "agregar":
          handleModal("addAccount");
          break;
        case "corte":
          if (!cashRegisterSelected || !cashRegisterSelected.cut) return;

          handleModal("closecut");
          break;
      }
    }
  };

  const addAccount = (patient) => {
    return new Promise(async (resolve, reject) => {
      const snapshot = await db
        .child(`erp/orders/${institucion.id_institucion}`)
        .orderByChild("dt_created")
        .endAt(moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"))
        .once("value");

      const cuentas = snapshot.val() ? Object.values(snapshot.val()) : [];
      const account = cuentas
        .filter((v) => !("saleCut" in v))
        .find((v) => v.client.id === patient.id);

      // NOTIFICAR QUE EXISTE LA CUENTA ABIERTA
      if (!!account) return reject();

      const accountId = moment().unix();

      const {
        vc_nombre,
        vc_apellidoPaterno,
        vc_apellidoMaterno = "",
      } = usuario;

      await db
        .child(`erp/orders/${institucion.id_institucion}/${accountId}`)
        .update({
          id: `${accountId}`,
          client: patient,
          datails: [],
          dt_created: moment().format("YYYY-MM-DD HH:mm:ss"),
          vc_nameUser: `${vc_nombre} ${vc_apellidoPaterno} ${vc_apellidoMaterno}`,
        });

      setModal((prev) => ({ ...prev, addAccount: false }));
      resolve();
    });
  };

  const payFunction = (account) => {
    db.child(`erp/orders/${institucion.id_institucion}/${account.id}`).set(
      account
    );
  };

  const closeCut = async ({ monto, saldo }) => {
    const snapshot = await db
      .child(`erp/orders/${institucion.id_institucion}`)
      .orderByChild("dt_created")
      .endAt(moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"))
      .once("value");

    const cuentas = snapshot.val() ? Object.values(snapshot.val()) : [];

    const pays = cuentas
      .reduce((arr, { pays = [] }) => [...arr, ...pays], [])
      .filter(
        (p) =>
          p.id_cashRegister == cashRegisterSelected.id &&
          p.id_cut == cashRegisterSelected.cut.id
      );

    const suma = pays.reduce((sum, v) => sum + v.nu_quantity, 0);
    const { vc_nombre, vc_apellidoPaterno, vc_apellidoMaterno = "" } = usuario;

    const params = {
      ...cashRegisterSelected,
      dt_end: moment().format("YYYY-MM-DD HH:mm:ss"),
      close: {
        pays,
        nu_quantity: monto,
        nu_balance: saldo,
        nu_paysTotal: suma,
        vc_nameUser: `${vc_nombre} ${vc_apellidoPaterno} ${vc_apellidoMaterno}`,
      },
      lastCutEnded: null,
      cut: null,
    };

    return new Promise((resolve) => {
      db.child(
        `erp/cashRegistersCuts/${institucion.id_institucion}/${cashRegisterSelected.cut.id}`
      )
        .update(params)
        .then(() => {
          resolve();
        });
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 48px)",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(200, 200, 200, 0.2)",
      }}
    >
      <Header
        opcion={opcion}
        setOpcion={setOpcion}
        searchText={searchText}
        setSearchText={setSearchText}
        onClickAction={onClickAction}
        range={range}
        cashRegister={cashRegisterSelected ? cashRegisterSelected.vc_name : ""}
        onClick={() => setModal((prev) => ({ ...prev, opencut: true }))}
        onChangeRange={setRange}
      />

      <div
        style={{
          ...styles.principal,
          flex: 1,
          ...{ height: "calc(100% - 47px)" },
          padding: "10px",
        }}
      >
        <Table data={data} onClick={handleSelect} selected={rowSelected.row} />
      </div>

      <ModalOpenCut
        open={modal.opencut}
        onClose={() => handleModal("opencut", false)}
        onAccept={selectCashRegister}
        value={cashRegisterSelected ? cashRegisterSelected.id : null}
        options={cashRegisters.map((e) => ({
          ...e,
          text: `${e.id} - ${e.vc_name}`,
          value: e.id,
        }))}
      />

      <AddAccountModal
        open={modal.addAccount}
        onClose={() => handleModal("addAccount", false)}
        onAccept={addAccount}
      />

      {modal.closecut && (
        <ModalCloseCut
          open={modal.closecut}
          cashRegister={cashRegisterSelected}
          onClose={() => handleModal("closecut", false)}
          onAccept={closeCut}
        />
      )}
      {rowSelected.row && modal.account && (
        <AccountModal
          products={products}
          accountSelected={rowSelected.row}
          onClose={() => {
            handleSelect(null, rowSelected.index);
            handleModal("account", false);
          }}
          cashRegisters={cashRegisters}
          cashRegisterSelected={cashRegisterSelected}
          openModalOpenCut={() => handleModal("opencut", false)}
          pagar={payFunction}
        />
      )}

      <EliminarCuenta
        open={modal.eliminarcuenta}
        data={rowSelected.row || {}}
        onAccept={() => {
          const { details = [] } = rowSelected.row;
          if (!details.length) {
            db.child(
              `erp/orders/${institucion.id_institucion}/${rowSelected.row.id}`
            ).set(null);

            handleModal("eliminarcuenta", false);
          }
        }}
        onCancel={() => {
          handleModal("eliminarcuenta", false);
        }}
      />
    </div>
  );
};

const styles = {
  container: {
    overflowX: "hidden",
    overflowY: "hidden",
    height: "100vh",
    width: "100%",
    padding: 0,
    margin: 0,
  },
  principal: {
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    overflowX: "hidden",
    padding: 0,
    paddingBottom: 0,
    margin: 0,
    height: "calc(100vh - 95px)",
    width: "100%",
  },
  rightHeader: {
    color: "white",
    background: "transparent",
    textAlign: "center",
    margin: 0,
  },
  centralColumn: {
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    background: "rgba(50,50,50,0.4)",
    padding: 0,
    paddingTop: 0,
    margin: 0,
    width: "100%",
    height: window.innerHeight,
  },
  item: {
    padding: 0,
    paddingBottom: 3,
    margin: 0,
    width: "100%",
  },
};
const actions = { setHelperSidebarRender, setDisplaySidebar };
const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  return {
    catalogo: catalogos.find((e) => e.vc_nameTable === "ins_pacientes"),
  };
};

export default connect(mapStateToProps, actions)(Index);

import React, { Component } from 'react';
import { Grid, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';

const { Row, Column } = Grid;

class HelperSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
			options: []
		};
  }

  clickCalendar() {
    console.log('se ha presionado el index');
  }

  render() {
    const { renderFromView } = this.props;

    if (renderFromView) {
      return renderFromView();
    }

    return (
      <div></div>
    );
  }
}

const styles = {
  content: {
    width: '100%',
    top: 0,
    bottom: 0,
    maxHeight: window.innerHeight,
    padding: 0,
    border: 0,
    backgroundColor:window.colors.black
  },
  helpsButton: {
    padding: 0,
    border: 0,
    width: '100%',
    height: 74,
    textAlign: 'center',
    color: 'white',
    backgroundColor:window.colors.menu
  },
  section: {
    width: '100%',
    padding: 3,
    height: 37,
    color: 'white',
  },
  optionsSection: {
    height: window.screen.availHeight - 150,
    color:window.colors.white,
    textAlign: 'center',
    padding: 10
  }
};


const mapStateToProps = (state) => {
  const { helper } = state.Think.sidebar;
  return { renderFromView: helper };
};


export default connect(mapStateToProps)(HelperSidebar);

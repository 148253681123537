import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import classNames from "classnames";

const useStyles = makeStyles((theme) => {
  return {
    space: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      "& .label": {
        position: "absolute",
        top: 0,
        left: 0,
        transform: "translateY(-50%)",
      },
      "& .row": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginLeft: "50px",
        borderBottom: "1px solid",
        borderBottomColor: theme.palette.divider,
        "& .dia:last-child": {
          borderRightColor: "transparent",
        },
        "& .dia": {
          borderRight: "1px solid",
          borderRightColor: theme.palette.divider,

          "& .turno": {
            cursor: "pointer",
            backgroundColor: theme.palette.primary.light,
            opacity: 0.2,
            "& .hora": {
              visibility: "hidden",
              color: theme.palette.primary.light,
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
            },
            "&:hover": {
              backgroundColor: 'rgba(200,200,200,0.3)',
              opacity: 0.5,
              "& .hora": { visibility: "visible" },
            },
            "&.disabled": {
              backgroundColor: 'rgba(200,200,200,0)',
              cursor: "not-allowed",
            },
          },
        },
      },
    },
  };
});

const timeDiff = (t1, t2) =>
  moment(t1, "HH:mm:ss").diff(moment(t2, "HH:mm:ss"), "minutes");

const gt = (t1, t2) => timeDiff(t1, t2) > 0;
const lt = (t1, t2) => timeDiff(t2, t1) > 0;
const gte = (t1, t2) => gt(t1, t2) || timeDiff(t1, t2) === 0;
const lte = (t1, t2) => lt(t1, t2) || timeDiff(t1, t2) === 0;

const Index = (props) => {
  const classes = useStyles();
  const refs = React.useRef([]);

  const { dias, add, inicio, rowHeight, rowTime, hoursDisabled } = props;
  const label = moment(inicio, "HH:mm:ss").format("HH:mm");

  const tm_from = moment(`1970-01-01 ${inicio}`, "YYYY-MM-DD HH:mm:ss");
  const tm_to = tm_from.clone().add(rowTime, "minutes");
  const diff = tm_to.diff(tm_from, "minutes");

  const lapsos = diff / 5;
  const turnoHeight = rowHeight / lapsos;

  const date = tm_from.clone().subtract(5, "minutes");
  const turnos = Array(lapsos)
    .fill(0)
    .map(() => {
      const dateRow = date.add(5, "minutes");

      const dateSpace = dateRow.clone();

      return {
        inicio: dateSpace.format("HH:mm:ss"),
        label: dateSpace.format("hh:mm a"),
        fin: dateSpace.add(5, "minutes").format("HH:mm:ss"),
      };
    });

  const isDisabled = (dia, turno) => {
    if (!hoursDisabled[dia]) return false;

    const horas = hoursDisabled[dia];
    let disabled = false;

    horas.forEach((d) => {
      if (gte(turno.inicio, d.tm_inicio) && lte(turno.fin, d.tm_fin))
        disabled = true;
    });

    return disabled;
  };

  // React.useEffect(() => {
  //   var coeff = 1000 * 60 * 5;
  //   var date = new Date(); //or use any other date
  //   var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
  //   var hora = moment(rounded).format("hh:mm");

  //   const el = document.querySelector(`[inicio="${hora}:00"]`);

  //   console.log(el);
  // }, []);

  return (
    <Box className={classes.space} height={rowHeight}>
      <Typography className="label" color="textSecondary" variant="subtitle2">
        {label}
      </Typography>
      <Box className="row" height={rowHeight}>
        {dias.map((d, di) => (
          <Box className="dia" key={di} flexGrow={1} zIndex={15}>
            {turnos.map((t, ti) => (
              <Box
                key={ti}
                ref={(el) => (refs.current[ti] = el)}
                inicio={t.inicio}
                className={classNames("turno", {
                  disabled: isDisabled(d.dt_dia, t),
                })}
                height={turnoHeight}
                onClick={() => {
                  const disabled = isDisabled(d.dt_dia, t);
                  // !disabled &&
                  //   add({
                  //     nu_dia: d.id,
                  //     dt_dia: d.dt_dia,
                  //     ...t,
                  //     disabled,
                  //   });
                  add({
                    nu_dia: d.id,
                    dt_dia: d.dt_dia,
                    ...t,
                    disabled,
                  });
                }}
              >
                <Typography className="hora" variant="caption" component="span">
                  {t.label}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
Index.defaultProps = {
  dias: [],
  hoursDisabled: {},
};

Index.propTypes = {
  dias: PropTypes.array,
  add: PropTypes.func,
  inicio: PropTypes.string,
  rowTime: PropTypes.number,
  rowHeight: PropTypes.number,
  hoursDisabled: PropTypes.object,
};

export default Index;

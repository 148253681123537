
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar } from '../../actions';
import { styled, withStyles } from '@material-ui/core/styles';
import { Paper, Divider, MenuItem, Typography, Box } from '@material-ui/core';
import { Grid } from 'semantic-ui-react';
import moment from 'moment';

class Dia extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
        }

        this.getAge = this.getAge.bind(this)
    }

    componentDidMount() {
        this.props.setDisplaySidebar(true)
    }

    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }


    render() {
        return (
            <Grid key="contener" style={{ borderRadius: 5, margin: 0, padding: 0, width: "100%", color: "white" }}>
                {
                    this.props.turns.map((item, index) => {
                        const tm_cut = `${item.tm_inicio}`.split(" ").reverse()[0];
                        const tm_inicio = `${tm_cut}`.split(":").reverse()[2] + ":" + `${tm_cut}`.split(":").reverse()[1];
                        const w = window.innerWidth;
                        const alto = ((w * 0.2) - 11) / 7;
                        const line = alto * 0.08;
                        const fSize = 14;
                        const opacity = this.state.hoverTurn == index ? 1 : 0.6;
                        const colorSelected = this.state.hoverTurn == index ? "#006ebc" :window.colors.itemSelected;
                        const high = this.state.hoverTurn == index ? 5 : 0;

                        if (item.id_cita != undefined) {
                            const sexo = item.id_sexo == 1 ? "Sexo: Masculino" : "Sexo: femenino";
                            const edad = `Edad: ${this.getAge(item.dt_nacimiento)} años`;
                            const dateStart = moment(`${item.dt_cita}` + ' ' + `${item.tm_inicio}`);
                            const dateEnd = moment(`${item.dt_cita}` + ' ' + `${item.tm_fin}`);
                            const alturaCita = ((dateEnd - dateStart) / 20000);
                            return (
                                <Grid key={index} style={{ opacity: 1, margin: 0, marginTop: 2, padding: 0, width: "100%", height: alturaCita, backgroundColor: "transparent", color: "white" }}>
                                    <Typography color='textSecondary' style={{ position: "absolute", textAlign: 'center', lineHeight: 0, opacity: 1, borderRadius: 5, marginLeft: 0, marginTop: 0, marginBottom: 0, padding: 0, width: 50, height: '100%', fontSize: fSize - 2 }}>{tm_inicio}</Typography>
                                    <Box style={{ position:'relative', marginLeft: 50, marginTop: 1, padding: 0, paddingBottom: 1, paddingTop: 1, width: "calc(100% - 60px)", float: 'right', height: '100%'}}>
                                        <Paper style={{width: "100%", height: '100%', boxShadow: '0px 1px 3px rgba(0,0,0,0.2)', overflow: 'hidden' }}>
                                            <MenuItem selected={(this.props.meetingSelected || {}).id_cita === item.id_cita} button onClick={() => { this.props.onMeetSelected(item); }} style={{ width: "100%", height: '100%' }}>
                                                <Typography variant={window.isMobile ? 'subtitle1' : 'inherit'} color='primary' style={{ textTransform: 'uppercase', marginRight: 16 }}>{`${item.vc_nombre || ''} ${item.vc_apellidoPaterno || ''} ${item.vc_apellidoMaterno || ''}`}</Typography>
                                                {
                                                    !window.isMobile &&
                                                    <Box>
                                                        <Typography variant={window.isMobile ? 'button' : 'inherit'} style={{ textTransform: 'uppercase', marginRight: 16 }}>{`Sexo: ${item.id_sexo === 1 ? window.isMobile ? 'M' : 'Masculino' : window.isMobile ? 'F' : 'Femenino'}`}</Typography>
                                                        <Typography variant={window.isMobile ? 'button' : 'inherit'} style={{ textTransform: 'uppercase', marginRight: 16 }}>{`Edad: ${this.getAge(item.dt_nacimiento) || ''} ${window.isMobile ? 'a' : 'años'}`}</Typography>
                                                    </Box>
                                                }
                                            </MenuItem>
                                        </Paper>
                                        <div style={{position: 'absolute',borderBottom: '1px solid gray', opacity: opacity, width: '100%'}}/>
                                    </Box>
                                </Grid>
                            )
                        } else {
                            const dateStart = moment(`${item.tm_inicio}`);
                            const dateEnd = moment(`${item.tm_fin}`);
                            const alturaCita = ((dateEnd - dateStart) / 20000);
                            return (
                                <Grid key={index} style={{ margin: 0, marginTop: 2, padding: 0, width: "100%", height: alturaCita, backgroundColor: "transparent", color: "white" }}>
                                    <Typography color='textSecondary' style={{ position: "absolute", textAlign: 'center', lineHeight: 0, opacity: 1, borderRadius: 5, marginLeft: 0, marginTop: 0, marginBottom: 0, padding: 0, width: 50, height: '100%', fontSize: fSize - 2 }}>{tm_inicio}</Typography>
                                    <MenuItem key={'selectionscell'} onClick={() => { this.props.getSlot(item); }} style={{ opacity: opacity, borderRadius: 5, marginLeft: 50, marginTop: 1, padding: 0, width: "calc(100% - 60px)", float: 'right', height: '100%' }} className="selection myhover" />
                                    <div key={'divider'} style={{ marginLeft: 50, width: "calc(100% - 60px)", float: 'right', padding: 0 }}>
                                        <Divider variant='fullWidth' />
                                    </div>
                                </Grid>
                            )
                        }
                    })
                }
                {
                    this.props.turns.length === 0 &&
                    <Box padding={2} style={{ minHeight: 90, width: '100%' }}>
                        <Typography align='center'>
                            No hay turnos ni citas disponibles para el dia de seleccionado.
                        </Typography>
                    </Box>
                }
            </Grid>
        );
    }
}

Dia.propTypes = {
    viewTitle: PropTypes.string,
    setViewTitle: PropTypes.func,
    setActiveView: PropTypes.func,
    setSubMenuOptions: PropTypes.func,
    resetHelperSidebarRender: PropTypes.func,
    setHelperSidebarRender: PropTypes.func,
};

const mapStateToProps = (state) => {
    const { } = state.Think;
    return {
    };
};
const actions = {
    setHelperSidebarRender,
    resetHelperSidebarRender,
    setDisplaySidebar
};
export default connect(mapStateToProps, actions)(Dia);

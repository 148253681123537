import React from "react";
import PropTypes from "prop-types";

import repository from "app/utils/repository";

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  IconButton,
} from "@material-ui/core";
import Paciente from "app/components/Paciente/PacienteSidebar";
import { Settings } from "@material-ui/icons";
import { database, auth } from "firebase";

const Index = (props) => {
  const { selected, actions } = props;
  const [app] = React.useState({
    auth: auth(),
    db: database().ref(),
  });

  const [user, setUser] = React.useState(null);
  const [formularios, setFormularios] = React.useState([]);

  React.useEffect(() => {
    app.auth.onAuthStateChanged(() => setUser(app.auth.currentUser));
  }, []);

  React.useEffect(() => {
    let ref = null;

    if (user && !user.isAnonymous) {
      ref = app.db
        .child(`settings/customNotes`)
        .orderByChild("uid")
        .equalTo(user.uid);

      ref.on("value", (snapshot) => {
        const val = Object.values(snapshot.val() || []);
        const aux = [];
        snapshot.forEach((v) => {
          aux.push({ key: v.key, ...v.val() });
        });
        setFormularios(aux.filter((v) => v.activo));
      });
    }

    return () => ref && ref.off();
  }, [user]);

  const botones = [
    {
      text: "Consultar",
      onClick: () => actions?.consultar(),
      disabled: false,
      validate: repository.validateOptionsRol,
    },
    {
      text: "Expediente",
      onClick: () => actions?.expediente(),
      disabled: false,
      validate: repository.validateOptionsRol,
    },
    {
      text: "Editar",
      onClick: () => actions?.editar(),
      disabled: false,
      validate: repository.validateOptionsRol("cancelar cita"),
    },
    // {
    //   text: "Formularios",
    //   onClick: () => {},
    //   disabled: true,
    //   validate: repository.validateOptionsRol("formularios"),
    // },
    // {
    //   text: "Notificacion",
    //   onClick: () => actions?.triage(364),
    //   disabled: false,
    //   validate: repository.validateOptionsRol("covid19"),
    // },
    // {
    //   text: "Evolucion",
    //   onClick: () => actions?.triage(402),
    //   disabled: false,
    //   validate: repository.validateOptionsRol("covid19"),
    // },
  ].filter((v) => v.validate);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <AppBar
        position="relative"
        style={{
          height: 95,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Typography style={{ textAlign: "center", width: "100%" }}>
            Paciente
          </Typography>
        </Toolbar>
      </AppBar>
      <Box flex={1} display="flex" flexDirection="column">
        <Paciente paciente={selected} />
        <Box height="0px" overflow="scroll" flex="1 1 auto">
          {botones.map((a, i) => (
            <Box mb={1} mx={1} key={i}>
              <Button
                key={i}
                variant="outlined"
                color="primary"
                fullWidth
                disabled={a.disabled}
                onClick={a.onClick}
              >
                {a.text}
              </Button>
            </Box>
          ))}

          <AppBar
            position="static"
            color="primary"
            style={{ height: 40, zIndex: 1, marginBottom: "8px" }}
          >
            <Toolbar color="primary" style={{ height: 40, minHeight: 40 }}>
              <Typography style={{ textAlign: "center", width: "100%" }}>
                Formularios
              </Typography>
              <IconButton
                style={{ position: "absolute", right: "8px" }}
                onClick={() => actions?.formularios()}
              >
                <Settings />
              </IconButton>
            </Toolbar>
          </AppBar>
          {formularios.map((a, i) => (
            <Box mb={1} mx={1} key={i}>
              <Button
                key={i}
                variant="outlined"
                color="primary"
                fullWidth
                disabled={!a.activo}
                onClick={() => actions.consultarFormulario(a)}
              >
                {a.nombre}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

Index.defaultProps = { selected: null, actions: {} };

Index.propTypes = {
  selected: PropTypes.object,
  actions: PropTypes.object,
};

export default Index;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setDisplaySidebar,
} from "../../../containers/Think/actions";
import Listado from "./listado";
import Formulario from "./formulario";
import SubMenuModulos from "../../../material-ui/SubMenuModulos";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import { IconButton, Box } from "@material-ui/core";

class ConfiguracionAgendas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opcion: "listado",
      data: {},
      searchText: "",
    };

    this.changeState = this.changeState.bind(this);
  }

  render() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        flexGrow={1}
        flexDirection="column"
        bgcolor="rgba(200, 200, 200, 0.2)"
      >
        {this.renderContent()}
      </Box>
    );
  }

  renderContent() {
    const { opcion } = this.state;

    switch (opcion) {
      case "listado":
        return (
          <Listado
            searchText={this.state.searchText}
            changeState={this.changeState}
          />
        );

      case "agregar":
        return <Formulario changeState={this.changeState} />;

      case "editar":
        return (
          <Formulario changeState={this.changeState} data={this.state.data} />
        );

      default:
        return null;
    }
  }

  componentDidMount() {
    this.props.setHelperSidebarRender(() => null);
    this.props.setDisplaySidebar(false);
  }

  changeState({ opcion, data = {} }, callback = () => {}) {
    this.setState({ opcion, data }, callback);
  }
}

ConfiguracionAgendas.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  setActiveView: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { catalogos } = state.Think;

  return {
    catalogos,
  };
};

const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setDisplaySidebar,
};

export default connect(mapStateToProps, actions)(ConfiguracionAgendas);

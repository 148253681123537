import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';

class TratamientosItemTimeLine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hoverIndex: null,
            hoverDelete: false,
            indexSelected: null,
            tratamiento: {
                id: null,
                id_estatus: 1,
                id_institucion: null,
                id_tratamiento: null,
                id_medicamento: null,
                id_usuario: null,
                id_paciente: null,
                sn_activo: 1,
                dt_registro: null,
                detalle: {
                        id: null,
                        id_institucion: null,
                        id_estatus: 1,
                        id_tratamientoOrigen: null,
                        id_tratamientoActual: null,
                        id_tratamientoMedicamento: null,
                        id_usuario: null,
                        id_um_dosis: null,
                        id_um_duracion: null,
                        id_um_frecuencia: null,
                        id_um_intervalo: null,
                        id_conciliacion: null,
                        vc_dosis: null,
                        nu_dosis: null,
                        id_vehiculo: null,
                        nu_vehiculos: null,
                        id_viaAdministracion: null,
                        nu_duracion: null,
                        nu_frecuencia: null,
                        nu_intervalo: null,
                        dt_vigencia: null,
                        dt_inicio: null,
                        tm_inicio: null,
                        vc_observaciones: '',
                        sn_activo: 1,
                        dt_registro: null
                    }
            }
        };
    }

    selectMedicamento() {
        if (!this.state.hoverDelete) {
            if ( this.props.index == this.props.indexSeleccionado && this.props.indexSeleccionado != null ) {
                this.props.callbackSelect({ opcionesModuloNext: 'agregar', sn_seleccionado: 0, indexSeleccionado: this.props.index});
            } else if ( this.props.item.id_vehiculo == null ){
                this.props.callbackSelect({ opcionesModuloNext: 'vehiculo', sn_seleccionado: 1 , indexSeleccionado: this.props.index});
            } else {
                this.props.callbackSelect({ opcionesModuloNext: 'dosis', sn_seleccionado: 1 , indexSeleccionado: this.props.index});
            }
        }
    }

    // onChangeValue(event) {
    //     this.props.callbackPreg({ value: event.target.value, index: this.props.index });
    //     this.setState({ value: event.target.value });
    // }

    respuestaValor(item) {
        switch (item.id_tipoPregunta) {
            case 1,2:
                if ( item.respuesta != undefined ) {
                    return `${item.versiones[0].respuestas.filter(r => r.id === item.respuesta.id_respuesta)[0].vc_respuesta}`;
                }
            case 3:
                if ( item.respuesta != undefined ) {
                    return `${item.respuesta.nu_respuesta}`;
                }
            case 4:
                if ( item.respuesta != undefined ) {
                    return `${item.respuesta.vc_respuesta}`;
                }
            case 5:
                if ( item.respuesta != undefined ) {
                    return `${item.respuesta.vc_respuesta}`;
                }
            case 6:
                if ( item.respuesta != undefined ) {
                    return `${item.respuesta.tm_respuesta}`;
                }
            default:
                return null;
        }
        return "";
    }

    timeSince(refDate, date) {

        var seconds = Math.floor((refDate - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
        return interval + " años";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
        return interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
        return interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
        return interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
        return interval + " minutos";
        }
        return "un instante";
    }
    statusDxToString(id_estatusDiagnostico){
        switch (id_estatusDiagnostico) {
            case 1:
            return "presuntivo";
            case 2:
            return "definitivo ó confirmado";
            case 3:
            return "descartado";
            default:
            return "presuntivo";
        }
    }
    
    isActive(dt_vigencia){
        if ( (new Date(dt_vigencia) < (new Date())) ) {
            return false
        }
        return true
    }
    dosisString(item) {
        const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro})[item.detalles.length - 1];
        if ( lastDetalle.nu_vehiculos != null && lastDetalle.id_vehiculo != null ) {
            if ( lastDetalle.nu_vehiculos == 1 ) {
                return `Dosis: ${lastDetalle.nu_vehiculos}` + ' ' + `${this.props.vehiculos.filter(r => r.id === lastDetalle.id_vehiculo)[0].vc_abreviaturaSingular}`;
            } else {
                return `Dosis: ${lastDetalle.nu_vehiculos}` + ' ' + `${this.props.vehiculos.filter(r => r.id === lastDetalle.id_vehiculo)[0].vc_abreviaturaPlural}`;
            }
        } else if ( lastDetalle.nu_dosis != null && lastDetalle.id_um_dosis != null ) {
            return `Dosis: ${lastDetalle.nu_dosis}` + ' ' + `${this.props.unidadesMedida.filter(r => r.id === lastDetalle.id_um_dosis)[0].vc_abreviatura}`;
        } else if ( lastDetalle.vc_dosis != null ) {
            return 'Dosis:' + `${lastDetalle.vc_dosis}`
        } else {
            return 'Dosis: no especificada'
        }
    }
    frecuenciastring(item) {
        const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro})[item.detalles.length - 1];
        if ( lastDetalle.nu_frecuencia != null && lastDetalle.id_um_frecuencia != null ) {
            if ( lastDetalle.nu_frecuencia == 1 ) {
                return ' cada ' + `${lastDetalle.nu_frecuencia}` + ' ' + `${this.props.unidadesMedida.filter(r => r.id === lastDetalle.id_um_frecuencia)[0].vc_nombre}`.toLowerCase();
            } else if ( lastDetalle.id_um_frecuencia == 5 ) {
                return ' cada ' + `${lastDetalle.nu_frecuencia}` + ' ' + `${this.props.unidadesMedida.filter(r => r.id === lastDetalle.id_um_frecuencia)[0].vc_nombre}`.toLowerCase() + 'es';
            } else {
                return ' cada ' + `${lastDetalle.nu_frecuencia}` + ' ' + `${this.props.unidadesMedida.filter(r => r.id === lastDetalle.id_um_frecuencia)[0].vc_nombre}`.toLowerCase() + 's';
            }
        } else {
            return ' frecuéncia: no especificada'
        }
    }
    viaAdministracionString(item) {
        const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro})[item.detalles.length - 1];
        if ( lastDetalle.id_viaAdministracion != null ) {
            const viaAdministracion = this.props.viasAdministracion.filter(r => r.id === lastDetalle.id_viaAdministracion)[0];
            if ( viaAdministracion ) {
                return ' via ' + `${viaAdministracion.vc_nombre.toLowerCase()}`
            } else {
                return ' via de administración no especificáda';
            }
        } else {
            return ' via de administración no especificáda';
        }
    }
    duracionString(item) {
        const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro})[item.detalles.length - 1];
        if ( lastDetalle.nu_duracion != null && lastDetalle.id_um_duracion != null ) {
            if ( lastDetalle.nu_frecuencia == 1 ) {
                return ' durante ' + `${lastDetalle.nu_duracion}` + ' ' + `${this.props.unidadesMedida.filter(r => r.id === lastDetalle.id_um_duracion)[0].vc_nombre}`.toLowerCase();
            } else if ( lastDetalle.id_um_frecuencia == 5 ) {
                return ' durante ' + `${lastDetalle.nu_duracion}` + ' ' + `${this.props.unidadesMedida.filter(r => r.id === lastDetalle.id_um_duracion)[0].vc_nombre}`.toLowerCase() + 'es';
            } else if ( lastDetalle.id_um_frecuencia == 34 ) {
                return ' sin suspender.';
            } else {
                return ' durante ' + `${lastDetalle.nu_duracion}` + ' ' + `${this.props.unidadesMedida.filter(r => r.id === lastDetalle.id_um_duracion)[0].vc_nombre}`.toLowerCase() + 's';
            }
        } else {
            return ' duracion: no especificada'
        }
    }
    stringDescriptionMedicamento(item) {
        const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro})[item.detalles.length - 1];
        if ( this.isActive(lastDetalle.dt_vigencia) && lastDetalle.id != null && lastDetalle.id_um_duracion != 34 ) {
            return `${this.dosisString(item)}` + `${this.viaAdministracionString(item)}` + `${this.frecuenciastring(item)}` + `${this.duracionString(item)}` + '    -   actualmente activo, el cual inició hace ' + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` + ' y termina en ' + `${this.timeSince(new Date(lastDetalle.dt_vigencia), new Date())}.`
        } else if ( this.isActive(lastDetalle.dt_vigencia) && lastDetalle.id != null ) {
            return `${this.dosisString(item)}` + `${this.viaAdministracionString(item)}` + `${this.frecuenciastring(item)}` + `${this.duracionString(item)}` + ' el cual inició hace ' + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}.`
        } else if (lastDetalle.id == null) {
            return `${this.dosisString(item)}` + `${this.viaAdministracionString(item)}` + `${this.frecuenciastring(item)}` + `${this.duracionString(item)}` + '    -   medicamento agregado recientemente (aun no registrado).';
        } else {
            return `${this.dosisString(item)}` + `${this.viaAdministracionString(item)}` + `${this.frecuenciastring(item)}` + `${this.duracionString(item)}` + '    -   actualmente inactivo, el cual inició hace ' + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` + 'y estuvo vigente durante ' + `${this.timeSince(new Date(lastDetalle.dt_vigencia), new Date(lastDetalle.dt_inicio))}.`
        }
    }
    stringDescriptionMedicamentoReceta(item) {
        const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro})[item.detalles.length - 1];
        if ( this.isActive(lastDetalle.dt_vigencia) && lastDetalle.id != null  && lastDetalle.id_um_duracion != 34 ) {
            return `${this.dosisString(item)}` + `${this.viaAdministracionString(item)}` + `${this.frecuenciastring(item)}` + `indicado ${this.duracionString(item)}` + ' y termina en ' + `${this.timeSince(new Date(lastDetalle.dt_vigencia), new Date())} mas.`
        } else if ( this.isActive(lastDetalle.dt_vigencia) && lastDetalle.id != null ) {
            return `${this.dosisString(item)}` + `${this.viaAdministracionString(item)}` + `${this.frecuenciastring(item)}` + `${this.duracionString(item)}` + ' el cual inició hace ' + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}.`
        } else if (lastDetalle.id == null && lastDetalle.id_um_duracion != 34 ) {
            return `${this.dosisString(item)}` + `${this.viaAdministracionString(item)}` + `${this.frecuenciastring(item)}` + `${this.duracionString(item)}` + ' inicia hoy y termina en ' + `${this.timeSince(new Date(lastDetalle.dt_vigencia), new Date())} mas.`
        } else if (lastDetalle.id == null ) {
            return `${this.dosisString(item)}` + `${this.viaAdministracionString(item)}` + `${this.frecuenciastring(item)}` + `${this.duracionString(item)}.`
        } else {
            return `${this.dosisString(item)}` + `${this.viaAdministracionString(item)}` + `${this.frecuenciastring(item)}` + `${this.duracionString(item)}` + '    -   actualmente inactivo, el cual inició hace ' + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` + 'y estuvo vigente durante ' + `${this.timeSince(new Date(lastDetalle.dt_vigencia), new Date(lastDetalle.dt_inicio))}.`
        }
    }
    colorDx(lastDetalle) {
        if ( this.isActive(lastDetalle.dt_vigencia) ) {
            if ( lastDetalle.id != null ) {
                return window.colors.green;
            } else {
                return window.colors.itemSelected;
            }
        } else {
            return window.colors.red;
        }
    }
    getInputs(item) {
        const respuesta = item.respuesta ? item.respuesta: "";
		return [
			{
				type: 'number',
				placeholder: respuesta,
                required: true,
                onChange: this.onChangeValue.bind(this),
                style: stylesTx.textField
			}
		];
    }
    deleteItem(item) {
        if (item.id == null) {
            this.props.callbackDelete({ opcionesModuloNext: 'agregar', sn_seleccionado: 0, indexSeleccionado: null, item });
        } else {
            this.props.callbackDelete({ opcionesModuloNext: 'agregar', sn_seleccionado: 0, indexSeleccionado: null, item });
        }
    }

    render() {
        const { date, medicamentosDgis, item, index, sn_printVersion } = this.props;
        const shadow = this.state.indexSelected == index ? '1px 1px 20px rgba(0, 0,0, 1)' : '1px 0px 10px rgba(0, 0,0, 0.7)';
        if ( sn_printVersion && this.isActive(item)) {
            const description = this.stringDescriptionMedicamentoReceta(item);
            const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro})[item.detalles.length - 1];
            const title = item.id_medicamento == 0 ? item.vc_medicamento : `${medicamentosDgis.filter(r => r.id == item.id_medicamento)[0].vc_nombre}  ${medicamentosDgis.filter(r => r.id == item.id_medicamento)[0].vc_concentracion.toLowerCase()}`
            const formaFarmaceutica = item.id_medicamento == 0 ? '' : `${medicamentosDgis.filter(r => r.id == item.id_medicamento)[0].vc_farmaceutica}`
            return (
                <Container key={index} style={{
                    width: "100%",
                    minHeight: 60,
                    margin:0,
                    padding:5,
                }}>
                    <div style={{
                        width: "100%",
                        float: "left",
                        borderTopLeftRadius: 4,
                        color:this.colorDx(lastDetalle),
                    }}>
                        {title}
                    </div>
                    <div style={{
                            color:this.colorDx(lastDetalle),
                            width: "100%",
                            textBorderLeft: 5,
                            float: "left",
                            textAlign: "left",
                            textTransform: "capitalize",
                        }}>
                            {formaFarmaceutica}
                    </div>
                    <div readOnly style={stylesTx.subTitlePrint}>
                        {description}
                    </div>
                </Container>
            );
        } else {
            return null;
        }
    }
}


const stylesTx = {
    container: {
        width: "100%",
        minHeight: 100,
        margin:0,
        padding:0,
    },
    subContainer: {
        width: "100%",
        margin:0,
        marginBottom: 5,
        padding:0,
        minHeight: 90,
        borderRadius: 4,
        backgroundColor:window.colors.itemSelected,
        boxShadow: '1px 0px 10px rgba(0, 0,0, 0.7)'
    },
    CIE10:{
        width: "6%",
        textBorderLeft: 5,
        height: 30,
        borderTopLeftRadius: 4,
        float: "left",
        textAlign: "center",
        backgroundColor: "rgba(50,50,50,1)",
        margin: 0,
        marginTop: 1,
        lineHeight: 2
    },
    title:{
        width: "84%",
        float: "left",
        overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
        borderTopLeftRadius: 4,
        marginTop: 1,
        marginRight: 0,
        paddingLeft: 8,
        paddingRight: 8,
        height: 30,
        backgroundColor: "rgba(50,50,50,1)",
        color:window.colors.white,
        lineHeight: 2
    },
    subTitlePrint:{
        width: "100%",
        float: "left",
        marginTop: 0,
        marginRight: 0,
        paddingLeft: 0,
        paddingRight: 0,
        color: 'gray'
    },
    subTitle:{
        width: "99%",
        float: "right",
        backgroundColor: "rgba(250,250,250,0.96)",
        marginRight:0,
        marginTop: 1,
        marginBottom: 0,
        padding: 0,
        border:0,
        borderBottomLeftRadius: 4,
        color: "gray",
        textAlign:"justify",
        fontSize:15
    },
    textArea:{
        width:"99%",
        float: "right",
        height:"100%",
        color: "gray",
        padding: 8,
        fontSize: 12,
        borderRadius: 0,
        border: 0 ,
        margin: 0,
        marginTop: 1,
        borderBottomLeftRadius: 4,
        height: 58,
        maxHeight : 58,
        maxWidth : "99%",
        minWidth : "99%",
        textAlign:"justify",
        resize: "none"
    },
    stick:{
        width:"1%",
        height: "100%",
        minHeight: 80,
        height: "100%",
        float: "left",
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        margin: 0,
        border: 0 ,
        color:window.colors.white,
        verticalAlign: "middle",
        align: "center"
    },
    line:{
        width:"100%",
        height: 1,
        float: "center",
        backgroundColor: "gray",
    }
};


const mapStateToProps = (state) => {
  const { medicamentosDgis, unidadesMedida, viasAdministracion, vehiculos } = state.Think;
  return { medicamentosDgis, unidadesMedida, viasAdministracion, vehiculos };
};


export default connect(mapStateToProps)(TratamientosItemTimeLine);

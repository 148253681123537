import http from "./http";

const middleware = (typeRequest) => {
  return async (url, ...props) => {
    const request = {
      url,
      server: window.adminBackEndUrl,
    };

    http[typeRequest](request, ...props);
  };
};

export default {
  get: middleware("get"),
  post: middleware("post"),
  delete: middleware("delete"),
  put: middleware("put"),
};

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
} from "app/containers/Think/actions";

import repository from "app/utils/repository";
import notifications from "app/utils/notifications";
import eventManager from "app/utils/eventManager";
import { START_VIEW_TRANSITION } from "app/constants";

import SubMenuModulos from "app/material-ui/SubMenuModulos";
import { Box } from "@material-ui/core";

import moment from "moment";
import api from "./api";
import Sidebar from "./sidebar";
import Table from "app/material-ui/Table";
import TimeLine from "app/hospitalizacion/components/TimeLine";

const sexos = ["No Especificado", "Masculino", "Femenino", "Indistinto"];
const columns = [
  {
    name: "vc_nombreCompleto",
    label: "Nombre del Paciente",
    options: {
      cellProps: {
        className: "fill",
      },
      typographyProps: {
        color: "primary",
        variant: "subtitle1",
      },
    },
  },
  { name: "vc_curpText", label: "Curp" },
  { name: "vc_sexo", label: "Sexo" },
  { name: "vc_edad", label: "Edad" },
];

const Index = (props) => {
  const institucion = window.amplify.store("institution") || {};

  const [selected, setSelected] = React.useState({
    index: -1,
    row: null,
  });
  const [opcion, setOpcion] = React.useState("actual");
  const [acciones, setAcciones] = React.useState([]);
  const [opciones, setOpciones] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState([]);
  const [table, setTable] = React.useState([]);

  React.useEffect(() => {
    props.setHelperSidebarRender(() => (
      <Sidebar selected={selected.row} actions={actions} />
    ));
    props.setDisplaySidebar(true);

    // return () => props.setDisplaySidebar(false);
  }, [selected.row]);

  React.useEffect(() => {
    const opcionActual = props.opciones.find((v) => v.id === opcion);

    const auxAcciones = opcionActual.acciones.map((accion) => {
      return {
        ...accion,
        renderPrint: () => {},
        // accion.id === "imprimir" ? this.renderPrint.bind(this) : null,
        action: () => {
          // if (accion.id !== "imprimir" && this.clickAccion)
          //   this.clickAccion(accion);
        },
      };
    });

    const auxOpciones = props.opciones.map((opcion) => {
      return {
        ...opcion,
        action: () => {
          // this.onChangeModule(moduloActual.id, opcion.id, "agregar");
        },
      };
    });

    setAcciones(auxAcciones);
    setOpciones(auxOpciones);
  }, [opcion]);

  const {
    preguntas,
    logOut,
    setPacienteActual,
    setLoading,
    medicamentosDgis,
    diagnosticos,
  } = props;

  const handleSelect = (row, index) => {
    const newSelected = { index: -1, row: null };

    if (selected.index !== index) {
      newSelected.index = index;
      newSelected.row = row;
    }

    setSelected(newSelected);
  };

  const actions = {
    async timeLine() {
      setLoading("Cargando Expediente del Paciente...");
      try {
        const res = await api.getTimeLine(selected.row);

        // openModal("timeLine", true, { paciente: selected.row, atencion: res });
      } catch (err) {
        notifications.error(err.texto);
      }

      setLoading(null);
    },
  };

  // React.useEffect(() => {
  //   if (selected.row) handleSelect(null, -1);

  //   (async () => {
  //     const res = await api.getPatients(search);
  //     setData(res);
  //   })();
  // }, [search]);

  // React.useEffect(() => {
  //   setTable(
  //     data.map((v) => {
  //       const vc_nombreCompleto = `${v.vc_nombre} ${v.vc_apellidoPaterno} ${v.vc_apellidoMaterno}`.trim();
  //       const vc_curpText = v.vc_curp
  //         ? v.vc_curp.toUpperCase()
  //         : "no especificado";
  //       const vc_edad = v.dt_nacimiento
  //         ? moment().diff(moment(v.dt_nacimiento, "YYYY-MM-DD"), "years") +
  //           " años"
  //         : "no especificado";
  //       const vc_sexo = sexos[v.id_sexo || 0];
  //       return {
  //         ...v,
  //         vc_nombreCompleto,
  //         vc_curpText,
  //         vc_edad,
  //         vc_sexo,
  //       };
  //     })
  //   );
  // }, [data]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      flexGrow={1}
      bgcolor="rgba(200,200,200,0.2)"
    >
      <SubMenuModulos
        type={"search"}
        searchText={search}
        actions={acciones}
        options={opciones}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Box px={2} py={1} flex="1 1 auto" height="0px" overflow="scroll">
        <Table
          data={table}
          columns={columns}
          onSelect={handleSelect}
          value={selected.row?.id}
        />
      </Box>
    </Box>
  );
};

Index.propTypes = {
  setHelperSidebarRender: PropTypes.func,
  setPacienteActual: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
  logOut: PropTypes.func,
};

const actions = {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
};

const mapStateToProps = (state) => {
  const { preguntas, medicamentosDgis, diagnosticos } = state.Think;

  return {
    preguntas,
    medicamentosDgis,
    diagnosticos,
  };
};

export default connect(mapStateToProps, actions)(Index);

import React from 'react';
import { connect } from 'react-redux';
import SubMenuModulos from '../../../material-ui/SubMenuModulos';
import { Box, Paper, Typography, AppBar, Toolbar, Button, Input, IconButton, Divider, Popover, ListItem, Popper, ButtonGroup, ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';
import GridUI from '@material-ui/core/Grid';
import Search from '@material-ui/icons/Search';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import CircularProgress from '@material-ui/core/CircularProgress';
import { menuConfiguracion } from '../../../containers/Think/listados';
import { Grid, List, Menu, Dropdown, Card, Image, Modal, Loader } from 'semantic-ui-react';
import QRReader from '../../../material-ui/QRReader';
import DeleteForever from '@material-ui/icons/DeleteForever';
import * as firebase from 'firebase/app';
import { styled } from '@material-ui/core/styles';
import { FadeInView, Acordion } from '../../../components';
import moment from 'moment';
import { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar } from '../../../containers/Think/actions';
import { isArray } from 'util';
import internalAPI from '../../../utils/internalAPI';
import PatientsItem from '../../../components/PatientsItem';
import SearchSelect from '../../../material-ui/ThinkForm/SearchSelect';
import PaymentMethod from '../../../material-ui/PaymentMethod';
import CreditCardItem from '../../../material-ui/CreditCardItem';

const { Row } = Grid;

const AccountItem = styled(Paper)({
  border: 0,
  borderRadius: 5,
  padding: '15px',
  marginBottom: '10px',
  border: '1px solid transparent',
  '&.selected': {
    borderColor:window.colors.itemSelected,
  },
  '& h5': {
    color:window.colors.itemSelected,
    marginBottom: '10px',
  },
  '& label': {
    marginRight: '5px',
  }
});

class Index extends React.Component {
  constructor() {
    super();
    this.app = firebase;
    this.db = this.app.database().ref();
    this.state = {
      opcionActual: 'listado',
      moduloActual: 'cuentas',
      data: [],
      accountsOpen: [],
      accounts: [],
      productSeachText: '',
      reporteActual: {},
      loading: true
    };
  }
  onChangeSearch() {

  }
  setRegistro() {

  }
  payFunction() {

  }
  accountSelected(accountSelected, indexSelected) {
    if (indexSelected === this.state.indexSelected) {
      this.setState({ accountSelected: null, indexSelected: null }, () => {
        this.updateHelperSidebar();
        this.props.setDisplaySidebar(false);
        this.props.resetHelperSidebarRender()
      });
    } else {
      this.setState({ accountSelected, indexSelected }, () => {
        this.updateHelperSidebar();
        this.props.setDisplaySidebar(true);
      });
    }
  }
  componentWillMount() {
    this.props.resetHelperSidebarRender();
  }

  updateHelperSidebar() {
    if (this.state.accountSelected)
      this.props.setHelperSidebarRender(this.renderHelperSidebar.bind(this));
  }
  deletePaymentAccount() {
    this.setState({ removing: true });
    internalAPI.deletePaymentAccount(this.state.accountSelected.id, (error, response) => {
      console.log('repsonse', response)
      if (error) {
        console.log('data error', error);
        this.setState({ removing: false, notification: { title: '!Ups¡', message: error || 'comprueba tu conexión a internet' } });
        // notifications.error(error.error);
        // eventManager.emit(START_VIEW_TRANSITION, '#/admin');
      } else {
        let accounts = this.state.accounts || [];
        if (response.message) {
          accounts.splice(this.state.indexSelected, 1);
          this.setState({ accounts, itemSelected: null, accountModal: false, removing: false, notification: { title: response.message, message: '' } }, () => {
            this.props.setDisplaySidebar(false);
            this.props.resetHelperSidebarRender()
          });
        } else {
          this.setState({ accounts, accountModal: false, removing: false, notification: { title: response.message, message: '' } });
        }
      }
    });
  }
  renderHelperSidebar() {
    const { opcionActual, accountSelected } = this.state
    if (!this.state.accountSelected) return null;
    return (
      <Box style={{ height: '100vh', backgroundColor: 'rgba(200,200,200,0.2)' }}>
        <AppBar position={'static'} color='primary' key={`header`} style={{ height: 95 }}>
          <Toolbar color='primary' key={`header`} style={{ height: 95 }}>
            <Typography style={{ textAlign: 'center', width: '100%', flexDirection: 'column', verticalAlign: 'center', justifyItems: 'center' }}>
              {'Metodo de Pago'}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box p={1} id='scrollSelected' style={{ height: 'calc(100vh - 95px)', overflowX: 'scroll', WebkitOverflowScrolling: 'touch', paddingBottom: 20, paddingTop: 16 }}>
          <CreditCardItem
            {...this.state.accountSelected || {}}
          />
          <Button
            disabled={this.state.removing}
            endIcon={this.state.removing && <CircularProgress size={15} color='secondary' />}
            variant='outlined' fullWidth color='primary' style={{ marginTop: 16 }}
            onClick={() => {
              this.state.accountSelected && this.deletePaymentAccount();
            }}
          >
            Eliminar
          </Button>
        </Box>
      </Box>
    )
  }
  renderPrint() {

  }
  clickAccion(accion) {
    switch (accion.id) {
      case 'nuevo': {
        this.setState({ accountModal: true })
      }
    }
  }
  onChangeModule(modulo, opcion, subOpcionActualModulo) {
    console.log('modulo, opcion, subOpcionActualModulo', modulo, opcion, subOpcionActualModulo)
    if (typeof modulo === 'string') {
      const moduloActual = menuConfiguracion.filter((e) => e.id == modulo)[0];
      this.setState({
        indexSeleccionado: null,
        moduloActual: moduloActual.id,
        opcionActual: opcion || moduloActual.opciones[0].id,
        searchText: '',
      }, () => {
        this.updateHelperSidebar();
      });
    } else {
      this.setState({
        moduloActual: modulo.id,
        indexSeleccionado: null,
        opcionActual: opcion || modulo.opciones[0].id,
        subOpcionActualModulo: subOpcionActualModulo || 'agregar',
        searchText: '',
      }, () => {
        this.updateHelperSidebar();
      });
    }
  }

  renderHeaderMenu() {
    const moduloActual = menuConfiguracion.find((e, index) => e.id == this.state.moduloActual);
    console.log('this.state.opcionActual', this.state.opcionActual)
    const opcionActual = moduloActual.opciones.find((e, index) => e.id == this.state.opcionActual);
    const acciones = opcionActual.acciones.map((accion) => {
      return {
        ...accion,
        action: () => {
          this.clickAccion(accion);
        }
      }
    });

    const opciones = moduloActual.opciones.map((opcion) => {
      return {
        ...opcion,
        action: () => {
          this.onChangeModule(moduloActual.id, opcion.id, 'agregar')
        }
      }
    });
    const opcionesDisplay = ['Cuentas', (opcionActual.vc_nombre || '')]
    return (
      <SubMenuModulos
        type={null}
        searchText={this.state.searchText}
        onChange={this.onChangeSearch.bind(this)}
        options={opciones}
        actions={acciones || []}
        optionDisplay={opcionesDisplay}
      />
    )
  }
  renderLoading() {
    if (this.state.loadingOn != null) {
      return (
        <Modal open style={{ boxShadow: '1px 0px 10px rgba(0, 0,0, 0)', backgroundColor: 'rgba(0, 0,0, 0.9)' }}>
          <Loader size='massive' active inverted>
            {this.state.loadingOn}
          </Loader>
        </Modal>
      );
    }
    return null;
  }
  renderTable() {
    if (this.state.loading) {
      return (
        <div style={{ width: '100%', height: '100%', padding: '2%', margin: 0 }}>
          <div style={{ height: 100 }}>
            <Loader size='massive' active inverted>
              {this.state.loadingOn}
            </Loader>
          </div>
        </div>
      );
    }

    if (this.state.accounts.length === 0) {
      return (
        <div style={{ width: '100%', height: '100%', padding: '2%', margin: 0 }}>
          <div style={{ height: 100, textAlign: 'center' }}>
            <Typography variant='h5'>
              No tienes metodos de pago registrados
            </Typography>
            {
              !this.state.accountModal &&
              <Button onClick={() => this.clickAccion({ id: 'nuevo' })} color='primary' variant='contained' style={{ marginTop: 30 }}>
                Registrar Metodo de Pago
              </Button>
            }

          </div>
        </div>
      );
    }

    console.log('render.........', this.state.accounts)
    return (
      <div style={{ width: '100%', padding: 15, height: 'calc(100% - 56px)', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>
        <FadeInView>
          {this.state.accounts.map((account, index) => {
            let vc_nombreCompleto = account.nombre;

            let opacity = this.state.indexSelected === index ? 1 : 0.5;
            if (!this.state.selectedRow) opacity = 1;

            return (
              <AccountItem
                key={index}
                className={this.state.indexSelected === index ? 'selected' : ''}
                style={{ opacity }}
                onClick={() => {
                  this.accountSelected(account, index)
                }
                }
              >
                <h5>{vc_nombreCompleto}</h5>
                <p>
                  <label>{account.marca}</label>
                  <label>{'   '}</label>

                  <label>{'**** **** **** '}{account.ultimosDigitos}</label>
                </p>
              </AccountItem>
            );
          })}
        </FadeInView>
      </div>
    );
  }

  renderFilters() {
    return (this.state.reporteActual.filters || []).map((filter, index) => {
      return (
        <Paper key={index} style={{ width: '100%', padding: 8, marginBottom: 4 }}>
          <SearchSelect
            key={index}
            required
            name={'Tipo Filtro'}
            fullWidth
            naked
            value={this.state.reporteActual ? this.state.reporteActual.id_tipo : null}
            label={'Tipo de Filtro'}
            options={[
              { value: 1, label: 'Notas' },
              { value: 2, label: 'Diagnosticos' },
              { value: 3, label: 'Medicamentos' },
              { value: 4, label: 'Procedimientos' },
              { value: 5, label: 'Solicitudes' },
              { value: 6, label: 'Pacientes' },
              { value: 7, label: 'Registros' }
            ]}
            onChangeCapture={(e) => this.setState({ reporteActual: { ...this.state.reporteActual, id_tipoReporte: e.target.value } }, this.updateHelperSidebar)}
          />
        </Paper>
      )
    })
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary>
          Filtros:
        </ExpansionPanelSummary>
      </ExpansionPanel>
    )
  }
  renderContent() {
    switch (true) {
      default: {
        return this.renderTable()
      }
    }
  }
  getTotal() {
    let total = 0;
    let subTotal = 0;
    let iva = 0;
    (Object.values(this.state.accountSelected.details) || []).map((item, index) => {
      const value = (item.nu_quantity * item.nu_unitValue);
      const ivaParc = value * (item.nu_tax > -1 ? item.nu_tax : 0.16)
      subTotal = subTotal + value;
      iva = iva + ivaParc;
    })
    total = iva + subTotal;
    return { subTotal, iva, total }
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  renderNewAccountModal() {
    return (
      <Modal onClose={() => this.setState({ accountModal: false })} open={this.state.accountModal} size='tiny' style={{ backgroundColor: 'transparent', boxShadow: '0px 0px 0px transparent' }}>
        <PaymentMethod
          // total={300}
          type='naked'
          onSubmit={({ id, deviceDataId, card }) => {
            // const paymentMethod = {
            //   tarjetaToken: id,
            //   nombre: card.holder_name,
            //   ultimosDigitos: card.card_number.slice(12, 16),
            //   marca: card.brand,
            //   dispositivoId: deviceDataId
            // }
            // this.setState({ paymentMethod, step: this.state.steps.find(e => e.id === 4) }, () => {
            //   this.pausedRegister({ institucion: this.state.institucion, paymentMethod: null, packageSelected: this.state.packageSelected });
            // })
          }}
          // callbackLoading={this.setLoading.bind(this)}
          // name={this.getNameUser()}
        />

        {/* <PaymentMethod
          onSubmit={({ id, deviceDataId, card }) => {
            const paymentMethod = {
              tarjetaToken: id,
              nombre: card.holder_name,
              ultimosDigitos: card.card_number.slice(12, 16),
              marca: card.brand,
              dispositivoId: deviceDataId
            }
            const institucion = window.amplify.store('institution');
            paymentMethod.id_institucion = institucion.id_institucion || null;
            internalAPI.addPaymentMethod(paymentMethod, (error, response) => {
              if (error) {
                console.log('data error', error);
                this.setState({ loading: false, notification: { title: '!Ups¡', message: error || 'comprueba tu conexión a internet' } });
                // notifications.error(error.error);
                // eventManager.emit(START_VIEW_TRANSITION, '#/admin');
              } else {
                let accounts = this.state.accounts || [];
                if (response.id) {
                  accounts.push(response);
                }
                this.setState({ accounts, accountModal: false, loading: false, notification: { title: response.message, message: '' } });
              }
            });
          }} 
        /> */}
      </Modal>
    )
  }
  render() {
    return (
      <div style={{ width: '100%', height: '100vh' }}>
        {this.renderHeaderMenu()}
        <div style={styles.principal}>
          {this.renderContent()}
          {this.renderNewAccountModal()}
        </div>
        {this.renderLoading()}
      </div>
    );
  }

  componentDidMount() {
    this.getPaymentAccount({})
    this.updateHelperSidebar()
  }
  componentDidUpdate() {
    // const {drawersLayout} = this.props;
    // console.log('drawer',drawersLayout)
    // if (drawersLayout.sidebar && !this.state.itemSelected){
    //   this.props.setDisplaySidebar(false);
    // }
  }
  getPaymentAccount(filters) {
    const institucion = window.amplify.store('institution');
    console.log('listo', institucion)
    if (institucion) {
      this.setState({ loading: true });
      internalAPI.getPaymentAccount(institucion.id_usuario, (error, response) => {
        if (error) {
          console.log('data error', error);
          this.setState({ accounts: [], optionsModal: false, loading: false, notification: { title: '!Ups¡', message: error || 'comprueba tu conexión a internet' } });
          // notifications.error(error.error);
          // eventManager.emit(START_VIEW_TRANSITION, '#/admin');
        } else {
          console.log('data', response);
          let accounts = response.length > 0 ? response : [];
          this.setState({ accounts, optionsModal: false, loading: false });
        }
      });
    }
  }
  componentWillUnmount() {
    this.props.setDisplaySidebar(false)
    this.props.resetHelperSidebarRender()
  }
  addObserverPatient(id_patient) {
    const institucion = window.amplify.store('institution') || {};
    this.db.child("erp").child('branches_products').child(institucion.id_institucion).child(id_patient).child('details').on('value', snapshot => {
      if (snapshot.val() != null) {
        this.setState({ accountSelected: { ...this.state.accountSelected, details: snapshot.val() } });
      } else {
        this.setState({ accountSelected: { ...this.state.accountSelected, details: [] } });
      }
    });
  }
  removeObserver(id_patient) {
    const institucion = window.amplify.store('institution') || {};
    this.db.child("erp").child('branches_products').child(institucion.id_institucion).child(id_patient).child('details').off();
  }
  suscribeFirebase() {
    const institucion = window.amplify.store('institution') || {};
    if (institucion.id_institucion) {
      this.db.child('erp').child('orders').child(`${institucion.id_institucion}`).on('value', snapshot => {
        if (snapshot.val() != null) {
          this.setState({ accountsOpen: Object.values(snapshot.val()) || [] });
        } else {
          this.setState({ accountsOpen: [] });
        }
      });

    }
  }
}
const styles = {
  container: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100vh',
    width: '100%',
    padding: 0,
    margin: 0
  },
  principal: {
    overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
    overflowX: 'hidden',
    padding: 0,
    paddingBottom: 0,
    margin: 0,
    height: 'calc(100vh - 37px)',
    width: "100%",
  },
  rightHeader: {
    color: 'white',
    background: 'transparent',
    textAlign: "center",
    margin: 0,
  },
  centralColumn: {
    overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
    background: 'rgba(50,50,50,0.4)',
    padding: 0,
    paddingTop: 0,
    margin: 0,
    width: "100%",
    height: window.innerHeight
  },
  item: {
    padding: 0,
    paddingBottom: 3,
    margin: 0,
    width: "100%"
  }
}
const actions = { setHelperSidebarRender, setDisplaySidebar, resetHelperSidebarRender }
const mapStateToProps = (state) => {
  const { catalogos, drawersLayout } = state.Think;
  return { catalogo: catalogos.find(e => e.vc_nameTable === 'ins_pacientes'), drawersLayout };
};

export default connect(mapStateToProps, actions)(Index);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import upperFirst from 'lodash/upperFirst';
import { styled, withStyles } from '@material-ui/core/styles';
import { setHelperSidebarRender, resetHelperSidebarRender, setState, setDisplaySidebar } from '../../../containers/Think/actions';
import { Paper, Typography } from '@material-ui/core';
import { Image } from 'semantic-ui-react';
import UsuarioBar from './UsuarioBar';
import { Modal, Loader } from 'semantic-ui-react';
import ExpansionPanel from '../../../material-ui/ExpansionPanel';
import internalAPI from '../../../utils/internalAPI';
import SideBar from './SideBar';

const modulo = 'settings';

const UserItem = styled(Paper)({
  border: 0,
  borderRadius: 5,
  padding: '15px',
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: '1px solid transparent',
  '&.selected': {
    borderColor:window.colors.itemSelected,
  },
  '& h6': {
    color:window.colors.itemSelected,
    fontSize: '1rem',
    marginBottom: '10px',
    fontWeight: '700',
    lineHeight: '1.28571429em'
  },
  '& label': {
    marginRight: '5px',
  }
});

const styles = theme => ({
  userData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flext-start',
    alignItems: 'flex-start',
    width: '100%',
    paddingLeft: '16px'
  }
});

class ListadoUsuarios extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      searchText: ' ',
      selectedRow: null,
      indexSelected: null
    }

    this.selectedRow = this.selectedRow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.edit = this.edit.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    this.getUsers();
  }

  componentWillUnmount() {
    this.props.setDisplaySidebar(false);
    this.props.resetHelperSidebarRender()
  }

  edit(selectedRow) {
    console.log('selectedrow',selectedRow)
    this.props.setOpcion('usuarioEdicion', selectedRow)
  }

  remove(selectedRow) {
    internalAPI.desvincularUsuario(selectedRow.id, (err, response) => {
      this.getUsers();
      this.props.setDisplaySidebar(false);
      this.props.resetHelperSidebarRender()
    });
  }

  selectedRow(selectedRow, indexSelected) {
    if (indexSelected === this.state.indexSelected) {
      this.setState({ selectedRow: null, indexSelected: null }, () => {
        this.props.setDisplaySidebar(false);
        this.props.resetHelperSidebarRender()
      });
    } else {
      this.setState({ selectedRow, indexSelected }, () => {
        this.props.setHelperSidebarRender(
          () => (
            <SideBar
              typeRender="user"
              selectedRow={selectedRow}
              edit={() => this.edit(selectedRow)}
              remove={() => this.remove(selectedRow)}
            />
          )
        );

        this.props.setDisplaySidebar(true);
      });
    }
  }

  getUsers() {
    if (!window.amplify.store('institution')) {
      return;
    }

    const body = {
      id_institucion: window.amplify.store('institution').id_institucion,
      texto: this.state.searchText || ''
    };

    this.setState({ loading: true }, () => {
      internalAPI.searchUsers(body, (error, response) => {
        console.log('response',response)
        this.setState({ loading: false });
        if (!error) {
          const data = response.map((item) => ({
            sexo: item.id_sexo === 1 ? 'Masculino' : 'Femenino',
            ...item
          }));
          
          this.setState({ data });
        }
      });
    });
  }

  handleChange(prop) {
    return event => {
      this.setState({
        [prop]: event.target.value,
        selectedRow: null,
        indexSelected: null
      }, () => {
        this.getUsers();
        this.props.setDisplaySidebar(false);
        this.props.resetHelperSidebarRender()
      });
    };
  }

  renderContent() {
    return this.state.data.map((item, index) => {
      let opacity = this.state.indexSelected === index ? 1 : 0.5;
      const { agendas, roles, detalle = {} } = item;
      const { vc_nombre = '', vc_apellidoPaterno = '', vc_apellidoMaterno = '' } = detalle || {};
      const nombreCompleto = `${vc_nombre} ${vc_apellidoPaterno} ${vc_apellidoMaterno}`;

      return (
        <UserItem
          key={index}
          className={this.state.indexSelected === index ? 'selected' : ''}
          onClick={() => this.selectedRow(item, index)}
        >
          <div className={this.props.classes.userData}>
            <Typography variant="subtitle1">{upperFirst(nombreCompleto.toLowerCase())}</Typography>
          </div>
        </UserItem>
      );
    });
  }

  render() {
    return (
      <React.Fragment>
        <UsuarioBar
          type="search"
          onChange={this.handleChange}
          add={() => this.props.setOpcion('usuarioNuevo')}
        />

        <div style={{ width: '100%', padding: 15, height: 'calc(100% - 65px)', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>
          {this.renderContent()}
        </div>

        {this.state.loading && (
          <div style={{ width: '100%', height: '100%', padding: '2%', margin: 0 }}>
            <div style={{ height: 100 }}>
              <Loader size='massive' active inverted>
                {this.state.loadingOn}
              </Loader>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

ListadoUsuarios.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  setState: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  return {
    state: state.Think[modulo] || null,
    catalogos
  };
};
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState,
  setDisplaySidebar
};
export default connect(mapStateToProps, actions)(withStyles(styles)(ListadoUsuarios));

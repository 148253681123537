import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { setHelperSidebarRender, resetHelperSidebarRender, setState, setDisplaySidebar } from '../../../actions';
import { Paper, Typography, Box, MenuItem, Divider, Grid } from '@material-ui/core';
import TextField from '../../../../../material-ui/TextField';
import internalAPI from '../../../../../utils/internalAPI';
import SearchSelect from '../../../../../material-ui/ThinkForm/SearchSelect';
import SubMenuModulos from '../../../../../material-ui/SubMenuModulos';
import ConfirmAlert from '../../../../../material-ui/ConfirmAlert';
import notifications from '../../../../../utils/notifications';
// Components
import Horario from './components/Horario'

const styles = theme => ({
   boxFieldset: {
      display: 'flex',
      flexWrap: 'wrap'
   },
   heading: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightMedium,
      flexGrow: 1
   }
});

const useFormFields = initialState => {
   const [fields, setValues] = React.useState(initialState);

   return [
      fields,
      (key, value) => {
         let nv = Array.isArray(key) ?
            key.reduce((pv, cv) => ({ ...pv, [cv.key]: cv.value }), {}) :
            { [key]: value }

         setValues({ ...fields, ...nv })
      }
   ];
}


const selectConfig = params => {
   return {
      relation: {
         get: {
            method: 'get',
            backEndURL: window.backEndURL,
            vc_url: "/app/api/institucion/localizacion/buscar",
            searchKey: "text",
            parameters: [
               {
                  type: 'fixed',
                  key: 'id_institucion',
                  value: (window.amplify.store('institution') || { id_institucion: 0 }).id_institucion
               },
               ...params.map(({ key, value }) => ({ type: 'fixed', key, value }))
            ]
         },
         keyTable: "id",
         fieldsDisplay: ["vc_nombre"],
         fieldsDisplaySeparators: [],
      }
   }
}

const Modal = ({ open, errors, accept }) => {
   return (open &&
      <ConfirmAlert
         title='¡Revisa los siguientes campos!'
         message={
            <Box style={{ maxHeight: '90vh' }}>
               <Divider />
               <MenuItem> No deben estar vacios: </MenuItem>
               <Divider />
               {errors.map((v, i) => <MenuItem key={i}>{v}</MenuItem>)}

            </Box>
         }

         onAccept={accept}
      />
   )
}


const onChange = (field, callback) => {
   let $input = window.$(`#${field}`)

   if (field.includes('vc_'))
      waitWrite(field, callback)
   else
      $input.on('change', e => callback(e.target.value))
}
const waitWrite = (field, callback) => {
   let timeOut, time = 1500, $input = window.$(`#${field}`)

   $input.on('keyup', e => {
      clearTimeout(timeOut)
      timeOut = setTimeout(() => callback(e.target.value), time)
   })

   $input.on('keydown', () => {
      clearTimeout(timeOut);
   })
}

const Formulario = props => {
   // const [data, setData] = React.useState(!!props.data ? props.data : {})
   const [data, setData] = useFormFields(!!props.data ? props.data : {})
   const [horarios, setHorarios] = React.useState(!!props.data ? [] : [
      {
         detalle: [
            { nu_dia: 1, tm_inicio: '08:00:00', tm_fin: '20:00:00' },
            { nu_dia: 2, tm_inicio: '08:00:00', tm_fin: '20:00:00' },
            { nu_dia: 3, tm_inicio: '08:00:00', tm_fin: '20:00:00' },
            { nu_dia: 4, tm_inicio: '08:00:00', tm_fin: '20:00:00' },
            { nu_dia: 5, tm_inicio: '08:00:00', tm_fin: '20:00:00' },
            { nu_dia: 6, tm_inicio: '08:00:00', tm_fin: '20:00:00' },
            { nu_dia: 7, tm_inicio: '08:00:00', tm_fin: '20:00:00' },
         ]
      }
   ])

   const [modal, setModal] = React.useState({
      open: false,
      errors: []
   })

   React.useEffect(() => {
      const { agendasEndPoints } = internalAPI

      if (data.id) {
         agendasEndPoints.edit({ id: data.id })
            .then(res => {
               setData(res)
               setHorarios(res.horarios)
            })
            .catch(res => { })
      }

      onChange('vc_nombre', value => setData('vc_nombre', value))
      
      return () => {
         props.setHelperSidebarRender(() => null);
         props.setDisplaySidebar(false);
      }
   }, [])

   React.useEffect(() => {
      const errors = []
      const fields = [
         { key: 'vc_nombre', field: 'Nombre' },
         { key: 'id_dependencia', field: 'Dependencia' },
         // { key: 'id_area', field: 'Area' },
         // { key: 'id_modulo', field: 'Modulo' },
         { key: 'id_especialidad', field: 'Especialidad' },
         { key: 'nu_duracion', field: 'Duracion' }
      ]

      fields.forEach(v => {
         !data[v.key] && errors.push([v.field])
      })

      setModal({ ...modal, errors })
   }, [data.vc_nombre, data.id_dependencia, data.id_area, data.id_modulo, data.id_especialidad, data.nu_duracion])

   const onSubmit = () => {
      const { agendasEndPoints } = internalAPI

      if (modal.errors.length)
         return setModal(ps => ({ ...ps, open: true }))

      const { id = null, id_institucion = null, id_dependencia = null, id_area = null, id_modulo = null, nu_latitud = 0, nu_longitud = 0, id_municipio = 1, id_especialidad, vc_nombre, nu_duracion, } = data

      const params = {
         id, id_dependencia, id_area, id_modulo,
         id_municipio, id_especialidad, vc_nombre,
         nu_latitud, nu_longitud, nu_duracion,
         horarios,
         id_institucion: id_institucion ? id_institucion : window.amplify.store('institution').id_institucion
      }

      agendasEndPoints.store(params)
         .then(res => { 
            props.changeState({ opcion: 'listado' }) 
            notifications.success('AGENDA CREADA CORRECTAMENTE');
         })
         .catch(res => { })
   }

   const duraciones = () => {
      const partes = 1440 / 5;
      let horarios = []
      for (let i = 1; i < partes; i++) {
         horarios.push({ label: `${i * 5} minutos`, value: i * 5 })
      };
      return horarios;
   }



   const inputProps = field => ({ key: field, fieldid: field, name: field, required: true, fullWidth: true })

   return (
      <div>
         <SubMenuModulos back={() => props.changeState({ opcion: 'listado' })} actions={[{ vc_nombre: 'Guardar', action: onSubmit }]} />

         <Box style={{ backgroundColor: 'transparent', boxShadow: 'none' }} p={1}>

            <div style={{ width: '100%', paddingLeft: 20, minHeight: 45, display: 'flex', alignItems: 'center' }}>
               <Typography> General </Typography>
            </div>

            <Paper style={{ padding: 10, width: '100%', position: "relative" }}>
               <Grid container spacing={1}>
                  <Grid item xs={3}>
                     <SearchSelect
                        {...inputProps('id_dependencia')}
                        onChange={option => {
                           setData([
                              { key: 'id_dependencia', value: option.value },
                              { key: 'id_area', value: null },
                              { key: 'id_modulo', value: null },
                           ])
                        }}
                        value={data.id_dependencia || null}
                        label={'Dependencia'}
                        fieldConfig={selectConfig([
                           { key: 'tipo', value: 'dependencia' },
                        ])}
                     />
                  </Grid>
                  <Grid item xs={3}>
                     <SearchSelect
                        {...inputProps('id_area')}
                        onChange={option => {
                           setData([
                              { key: 'id_area', value: option.value },
                              { key: 'id_modulo', value: null },
                           ])
                        }}
                        value={data.id_area || null}
                        label={'Area'}
                        fieldConfig={selectConfig([
                           { key: 'tipo', value: 'area' },
                           { key: 'id_dependencia', value: data.id_dependencia },
                        ])}
                        required={false}
                     />
                  </Grid>
                  <Grid item xs={3}>
                     <SearchSelect
                        {...inputProps('id_modulo')}
                        // onChange={option => setData('id_modulo', option.value)}
                        value={data.id_modulo || null}
                        label={'Modulo'}
                        fieldConfig={selectConfig([
                           { key: 'tipo', value: 'modulo' },
                           { key: 'id_dependencia', value: data.id_dependencia },
                           { key: 'id_area', value: data.id_area },
                        ])}
                        required={false}
                     />
                  </Grid>
                  <Grid item xs={3}>
                     <TextField
                        {...inputProps('vc_nombre')}
                        // onChange={text => setData('vc_nombre', text)}
                        value={data.vc_nombre || ''}
                        label={'Nombre'}
                        type={'text'}
                     />
                  </Grid>
                  <Grid item xs={3}>
                     <SearchSelect
                        {...inputProps('id_especialidad')}
                        onChange={option => setData('id_especialidad', option.value)}
                        value={data.id_especialidad || null}
                        label={'Especialidad'}
                        options={props.especialidades.map(({ vc_nombre: label, id: value }) => ({ label, value }))}
                     />
                  </Grid>
                  <Grid item xs={3}>
                     <SearchSelect
                        {...inputProps('nu_duracion')}
                        onChange={option => setData('nu_duracion', option.value)}
                        value={data.nu_duracion || null}
                        label={'Duracion Cita'}
                        options={duraciones()}
                     />
                  </Grid>
               </Grid>

            </Paper>

            {horarios.map((h, i) => <Horario key={i} data={h} onChange={d => {
               const h = [...horarios]
               h[i].detalle = d
               setHorarios(h)
            }} />)}



         </Box>

         <Modal open={modal.open} errors={modal.errors} accept={() => setModal({ ...modal, open: false })} />
      </div>
   )
}


Formulario.propTypes = {
   viewTitle: PropTypes.string,
   setViewTitle: PropTypes.func,
   setActiveView: PropTypes.func,
   setSubMenuOptions: PropTypes.func,
   resetHelperSidebarRender: PropTypes.func,
   setHelperSidebarRender: PropTypes.func,
   setState: PropTypes.func,
};

const mapStateToProps = (state) => {
   const { catalogos, formacionAcademica, especialidades, municipios, roles, dias } = state.Think;

   return {
      state: state.Think['settings'] || null,
      catalogos,
      formacionAcademica,
      especialidades,
      municipios,
      rolesConf: roles,
      dias
   };
};
const actions = {
   setHelperSidebarRender,
   resetHelperSidebarRender,
   setState,
   setDisplaySidebar
};

export default connect(mapStateToProps, actions)(withStyles(styles)(Formulario));
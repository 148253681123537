import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setHelperSidebarRender, resetHelperSidebarRender, setState } from '../../../containers/Think/actions';
import ListadoUsuarios from './ListadoUsuarios';
import UsuarioForm from './UsuarioForm';

const modulo = 'settings';

class UsuariosView extends React.Component {
  constructor() {
    super();
    this.state = {
      opcion: 'listadoUsuarios',
      data: {}
    }

    this.setOpcion = this.setOpcion.bind(this);
  }

  setOpcion(opcion, data = {}) {
    this.setState({ opcion, data });
  }

  renderContent() {
    switch (this.state.opcion) {
      case 'listadoUsuarios':
        return <ListadoUsuarios setOpcion={this.setOpcion} />;
      case 'usuarioNuevo':
        return <UsuarioForm setOpcion={this.setOpcion} />;
      case 'usuarioEdicion':
        return <UsuarioForm setOpcion={this.setOpcion} data={this.state.data} />;
      default: 
        return null;
    }
  }
  
  render() {
    return this.renderContent();
  }
}

UsuariosView.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  updateCatalogs: PropTypes.func,
};

const mapStateToProps = (state) => {
  return { 
    state: state.Think[modulo] || {}  
  };
};

const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState
};

export default connect(mapStateToProps, actions)(UsuariosView);
import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import {
  resetHelperSidebarRender,
  setHelperSidebarRender,
  setState,
  setPacienteActual
} from "../../../containers/Think/actions";
import SearchBar from "../SearchBar";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import ListView from "./ListView";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Paper from "../../../material-ui/Paper";
import { ButtonBase, Box, Button, Modal } from "@material-ui/core";
import * as firebase from "firebase/app";
import TriageModule from "../../../containers/Think/views/notas/preguntas/TriageModule";
const modulo = "backoffice";
class ListadoRegistrosCatalogos extends React.Component {
  constructor() {
    super();
    this.app = firebase;
    this.db = this.app.database().ref();
    this.state = {
      searchText: "",
      data: [],
    };
  }
  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };
  getFilter(data) {
    if (this.state.searchText === "") return data;
    const pattern = new RegExp(this.state.searchText, "gi");
    const catalogo = this.props.catalogo;
    return data.filter((e) => {
      let pass = false;
      if (`${this.getName(e)}`.match(pattern)) {
        pass = true;
      }
      return pass;
    });
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    console.log("listado registros", newProps);
  }
  componentDidMount() {
    const routes = this.props.catalogo.routes || {};
    const tipoApi = routes.index ? routes.index.tipoApi : 1;
    switch (tipoApi) {
      case 1: {
        this.setState({ data: this.props.catalogo.registros || [] });
        break;
      }
      case 2: {
        this.setState({ data: this.props.catalogo.registros || [] });
        break;
      }
      case 3: {
        this.onFirebaseSuscribe.bind(this)();
        break;
      }
      default: {
        this.setState({ data: this.props.catalogo.registros || [] });
        break;
      }
    }
  }
  onFirebaseSuscribe() {
    let ref = this.db;
    if (!this.props.catalogo.routes) return;
    this.props.catalogo.routes.index.vc_path.split("/").map((path) => {
      ref = ref.child(path);
    });
    ref.on("value", (snapshot) => {
      if (snapshot.val() != null) {
        this.setState({ data: Object.values(snapshot.val()) });
      }
    });
  }

  // getName(item) {
  //   let display = '';
  //   this.props.catalogo.fieldsDisplay.map((field, index) => {
  //     display = `${display}${item[field] || ''}${this.props.catalogo.fieldsDisplaySeparators[index] || ''}`
  //   })
  //   return display;
  // }
  getName = (item, relation) => {
    let display = "";
    this.props.catalogo.fieldsDisplay.map((field, index) => {
      let itemEdit = item;
      field.split(".").map((key, i) => {
        if (itemEdit) itemEdit = itemEdit[key];
      });
      display = `${display}${itemEdit || ""}${this.props.catalogo.fieldsDisplaySeparators[index] || ""
        }`;
    });
    return display;
  };

  renderContent() {
    const catalogo = this.props.catalogo;

    return this.getFilter(this.state.data).map((item, index) => {
      return (
        <div key={index} style={{ marginBottom: 8 }}>
          <Paper
            title={this.getName(item)}
            iconSet={
              <Box>
                {
                  this.props.catalogo.vc_name === 'Preguntas' && item.id_tipoPregunta && item.id_tipoPregunta === 4 && item.versiones[0].id_preguntaEnlace &&
                  <IconButton
                    onClick={(e) => {
                      console.log('se selecciono esto')
                      this.props.setPacienteActual({
                        detalle: { dt_nacimiento: "2000-06-06", id_sexo: 2 },
                        preguntasTexto: [item]
                      });
                      this.setState({ itemSelected: item });
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                }
                <IconButton
                  onClick={() => {
                    this.props.setState({
                      modulo,
                      state: { opcion: "editorRegistros", registro: item },
                    });
                  }}
                >
                  <Edit />
                </IconButton>
              </Box>
            }
          />
        </div>
      );
    });
  }

  render() {
    return (
      <div style={{ height: window.innerHeight - 40 }}>
        <div
          style={{
            width: "100%",
            padding: 15,
            paddingTop: 85,
            height: "calc(100% - 1px)",
            overflowY: "scroll",
            WebkitOverflowScrolling: "touch",
          }}
        >
          {this.renderContent()}
        </div>
        <SearchBar
          title={this.props.catalogo.vc_name}
          type="search"
          onChange={this.handleChange}
          back={() => {
            this.props.setState({
              modulo,
              state: { catalogo: null, opcion: "listadoCatalogos" },
            });
          }}
          add={() => {
            this.props.setState({
              modulo,
              state: { opcion: "editorRegistros", registro: {} },
            });
          }}
        />

        <Modal
          onClose={() => this.setState({ itemSelected: null })}
          open={!!this.state.itemSelected}
          style={{
            boxShadow: "0px 0px 0px transparent",
            backgroundColor: "transparent",
            margin: window.isMobile ? 20 : "50px 30vh 50px 30vh",
            height: "100%",
          }}

        >
          <Box>
            {
              this.props.pacienteActual &&
              this.props.pacienteActual.preguntasTexto &&
              this.props.pacienteActual.preguntasTexto[0] && (
                <TriageModule
                  callbackFinish={() => {
                    this.props.setPacienteActual(null);
                    this.setState({ selectedRow: null, showTriageCOVID19: false });
                  }}
                  item={
                    this.props.pacienteActual
                      ? this.props.pacienteActual.preguntasTexto[0]
                      : null
                  }
                  preguntasEnlace={this.state.preguntasEnlace || []}
                />
              )
            }
          </Box>
        </Modal>
      </div>
    );
  }
}

ListadoRegistrosCatalogos.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  setState: PropTypes.func,
};

const mapStateToProps = (state) => {
  const catalogo = state.Think[modulo] ? state.Think[modulo].catalogo : null;
  if (catalogo) {
    catalogo.registros = state.Think[catalogo.vc_nameReducer] || [];
  }
  const { pacienteActual } = state.Think;
  return {
    catalogo,
    pacienteActual
  };
};
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState,
  setPacienteActual
};
export default connect(mapStateToProps, actions)(ListadoRegistrosCatalogos);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  selectMeeting,
  setPacienteActual,
  setDisplaySidebar,
} from "app/containers/Think/actions";

import SubMenuModulos from "app/material-ui/SubMenuModulos";
import { Box, Typography, Breadcrumbs, Button } from "@material-ui/core";

import Agenda from "./agenda";
import Citas from "./citas";

import SearchMeetingModal from "app/agenda/components/SearchMeetingModal";
import ButtonMenu from "app/components/Form/ButtonMenu";
import moment from "moment";
import Sidebar from "./sidebar";

const Index = (props) => {
  const { agendas: ins_agendas = [] } =
    window.amplify.store("institution") || {};
  const { modulo, opciones, especialidades = [] } = props;

  const ref = React.useRef(null);

  const [dia, setDia] = React.useState(moment().format("YYYY-MM-DD"));
  const [dias, setDias] = React.useState([]);
  const [agenda, setAgenda] = React.useState(ins_agendas[0]);
  const [agendas, setAgendas] = React.useState([]);
  const [especialidad, setEspecialidad] = React.useState("");
  const [estatus, setEstatus] = React.useState([]);

  const [contenido, setContenido] = React.useState("agenda");
  const [content, setContent] = React.useState(null);
  const [modal, setModal] = React.useState(false);

  const [vista, setVista] = React.useState("dia");
  const vistas = [
    { value: "dia", text: "Día" },
    // { value: "semana", text: "Semana" },
    { value: "2dias", text: "2 Días" },
    { value: "3dias", text: "3 Días" },
    { value: "citas", text: "Citas" },
  ];

  const menuEspecialidades = [
    { value: "", text: "Todos" },
    ...especialidades
      .filter(({ id }) =>
        ins_agendas.find(({ id_especialidad: ide }) => ide === id)
      )
      .map((v) => ({
        value: v.id,
        text: v.vc_nombre,
      })),
  ];

  const menuAgendas = ins_agendas
    .filter((v) => !especialidad || v.id_especialidad === especialidad)
    .map((v) => ({ value: v.id, text: v.vc_nombre }));

  const showCalendar = () => {
    props.setHelperSidebarRender(() => (
      <Sidebar
        agendaId={agenda?.id}
        opcion={contenido}
        date={dia}
        dates={dias}
        onChangeDate={setDia}
        onStatusChange={setEstatus}
        estatus={estatus}
        addMeeting={(turno) => {
          if (!agenda || !ref || !ref.current) return;

          const inicio = moment(turno.tm_inicio);
          const fin = moment(turno.tm_fin);

          ref.current.addMeeting({
            id_agenda: agenda.id,
            dt_dia: inicio.format("YYYY-MM-DD"),
            inicio: inicio.format("HH:mm:ss"),
            fin: fin.format("HH:mm:ss"),
          });
        }}
      />
    ));
    props.setDisplaySidebar(true);
  };

  console.log(ref);

  React.useEffect(() => {
    if (vista === "citas") {
      setDias([dia]);
      setContenido("citas");
    } else {
      switch (vista) {
        case "dia":
          setDias([dia]);
          break;
        case "2dias":
        case "3dias":
        case "semana":
          let auxDate = moment(dia, "YYYY-MM-DD").subtract(1, "day");
          let nuDias = 3;
          if (vista === "2dias") {
            nuDias = 2;
            // auxDate = moment(dia, "YYYY-MM-DD").day(0).subtract(1, "day");
          }
          if (vista === "semana") {
            nuDias = 7;
            auxDate = moment(dia, "YYYY-MM-DD").day(0).subtract(1, "day");
          }

          const aux = Array(nuDias)
            .fill(0)
            .map(() => {
              const clone = auxDate.add(1, "day");
              return clone.format("YYYY-MM-DD");
            });

          setDias(aux);
          break;

        default:
          break;
      }
      setContenido("agenda");
    }
  }, [vista, dia]);

  React.useEffect(() => {
    showCalendar();
  }, [dias, estatus, ref]);

  React.useEffect(() => {
    if (agenda) setAgendas([agenda]);
    showCalendar();
  }, [agenda, ref]);

  React.useEffect(() => {
    switch (contenido) {
      case "agenda":
        setContent(
          <Agenda
            ref={(el) => {
              ref.current = el;
            }}
            showCalendar={showCalendar}
            dias={dias}
            agendas={agendas}
            insAgendas={ins_agendas}
          />
        );
        break;
      case "citas":
        setContent(
          <Citas
            ref={(el) => (ref.current = el)}
            showCalendar={showCalendar}
            dates={dias}
            agendaId={agenda ? agenda.id : null}
            estatus={estatus}
          />
        );
        break;

      default:
        setContent(null);
        break;
    }
  }, [contenido, dias, agendas, estatus]);

  React.useEffect(() => {
    if (especialidad)
      setAgenda(
        [...ins_agendas.filter((v) => v.id_especialidad === especialidad)].pop()
      );
  }, [especialidad]);

  const Header = (props) => {
    const especialidadesFilter = especialidades.filter((e) =>
      ins_agendas.find((a) => a.id_especialidad == e.id)
    );

    let auxAgendas = ins_agendas.map((v) => ({
      ...v,
      action: () => setAgenda(v),
    }));

    if (especialidad) {
      auxAgendas = auxAgendas.filter((v) => v.id_especialidad === especialidad);
    }

    return (
      <SubMenuModulos
        // title={dayAgenda.format("LL")}
        title={
          <Typography color="inherit">
            <ButtonMenu
              variant="outlined"
              data={vistas}
              value={vista}
              onClick={(v) => setVista(v.value)}
              hideSearch
            />

            <Button
              size="small"
              color="inherit"
              variant="text"
              style={{ marginLeft: "8px" }}
              onClick={() => setModal(true)}
            >
              Buscar Citas
            </Button>
          </Typography>
        }
        rightComponent={
          <Breadcrumbs separator="›">
            <Typography color="inherit">Agendas</Typography>
            <Typography color="inherit">
              <ButtonMenu
                data={menuEspecialidades}
                value={especialidad}
                onClick={(v) => setEspecialidad(v.value)}
                hideSearch
              />
            </Typography>
            <Typography color="inherit">
              <ButtonMenu
                data={menuAgendas}
                value={agenda ? agenda.id : null}
                onClick={(v) =>
                  setAgenda(ins_agendas.find((a) => a.id === v.value))
                }
              />
            </Typography>
          </Breadcrumbs>
        }
      />
    );
  };

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" flexGrow={1}>
      <Header />

      {content}

      <SearchMeetingModal
        open={modal}
        agendaId={agenda ? agenda.id : null}
        close={() => setModal(false)}
        accept={() => {
          setModal(false);
        }}
      />
    </Box>
  );
};

Index.propTypes = {
  setHelperSidebarRender: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  selectMeeting: PropTypes.func,
  setPacienteActual: PropTypes.func,
  setActiveView: PropTypes.func,
  setViewTitle: PropTypes.func,
  setMenuVisibility: PropTypes.func,
  navigate: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
};

const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  selectMeeting,
  setPacienteActual,
  setDisplaySidebar,
};

const mapStateToProps = (state) => {
  const { especialidades } = state.Think;

  return {
    especialidades,
  };
};

export default connect(mapStateToProps, actions)(Index);

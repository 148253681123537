import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    outlinedInput: {
        padding: '8px 14px'
    },
    inputLabel: {
        transform: 'translate(14px, 10px) scale(1)'
    }
}));
export default props => {

    const textTransform = (textTransform, text) => {
        switch (textTransform) {
            case 'uppercase': {
                return text.toUpperCase()
            }
            case 'capitalize': {
                return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
            }
            default: {
                return text;
            }
        }
    }
    const classes = useStyles();
    const { fullWidth, label, meta = {}, validation = () => { }, ...moreInputProps } = props;

    const inputProps = Object.assign({
        autoComplete: 'off',
        autoCorrect: 'off',
        spellCheck: 'off',
        className: classes.outlinedInput,
        style: { textTransform: props.texttransform || null }
    }, moreInputProps, { label: moreInputProps.required ? `${label} *` : label });

    const InputLabelProps = { className: classes.inputLabel };
    const [valueConfirm, setConfirm] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [prevValueProps, setPrevValueProps] = React.useState(null);

    useEffect(() => {
        if (props.value != value.value && props.value != prevValueProps) {
            if (props.value !== null) {
                setPrevValueProps(props.value)
                setValue(props.value)
            }
        }
    }, [props])
    const onChangeCapture = (e) => {
        if (!props.maxLength) {
            setValue(props.texttransform ? textTransform(props.texttransform, e.target.value) : e.target.value)
        } else if (props.maxLength >= e.target.value.length) {
            console.log('maxlength', props.maxLength)
            setValue(props.texttransform ? textTransform(props.texttransform, e.target.value) : e.target.value)
        }
    }
    let val = true;
    let regExp = props.regExp;
    if (regExp) {
        val = new RegExp(regExp).test(value);
        console.log(val)
    }
    return (
        <div>
            <TextField
                variant={props.variant || 'outlined'}
                margin="normal"
                autoComplete='off'
                autoCorrect='off'
                onChangeCapture={onChangeCapture}
                value={value}
                id={props.fieldid}
                label={props.required ? `${props.label}*` : props.label}
                InputLabelProps={{ ...InputLabelProps }}
                fullWidth={window.isMobile ? true : fullWidth}
                autoComplete="off"
                helperText={!val ? props.errormessage || '*Formato invalido' : ''}
                error={false}
                style={{ marginRight: 10, marginTop: 9 }}
                type={'password'}

            />
            <TextField
                key={`${props.fieldid}Confirm`}
                variant={props.variant || 'outlined'}
                margin="normal"
                autoCorrect='off'
                onChangeCapture={(e) => {
                    setConfirm(e.target.value)
                }}
                id={`${props.fieldid}Confirm`}
                label={'Confirmar Contraseña'}
                InputLabelProps={{ ...InputLabelProps }}
                fullWidth={window.isMobile ? true : fullWidth}
                helperText={valueConfirm !== value ? '*No coinciden' : ''}
                error={false}
                style={{ marginRight: 10, marginTop: 9 }}
                type={'password'}
                />
        </div>
    );
};


// {
//     <FormControl>
//         <TextField
//             key={field.fieldid}
//             fieldid={field.fieldid}
//             field={field.key}
//             onChangeCapture={this.handleChange.bind(this)}
//             name={field.fieldid}
//             readOnly={field.readOnly || false}
//             value={value}
//             required={!field.nullify}
//             label={field.vc_name}
//             type={field.type}
//         />
//         <TextField
//             key={`${field.fieldid}Confirm`}
//             fieldid={`${field.fieldid}Confirm`}
//             field={field.key}
//             onChangeCapture={(e) => {
//                 this.setState({ [`${field.fieldid}Confirm`]: e.target.value })
//             }}
//             value
//             required={!field.nullify}
//             name={`${field.fieldid}Confirm`}
//             readOnly={field.readOnly || false}
//             label={'Confirmar Contraseña'}
//             value={this.state[`${field.fieldid}Confirm`] || ''}
//             errormessage={document.getElementById(field.fieldid) ? document.getElementById(field.fieldid).value !== this.state[`${field.fieldid}Confirm`] ? 'No coinciden' : '' : ''}
//             type={field.type}
//         />
//     </FormControl>
// </div >

// }
import React from "react";

import Table from "app/sales/components/Common/MaterialTable";
import Icon from "app/sales/components/Common/Icon";
import { Box, IconButton } from "@material-ui/core";

import Input from "app/components/Form/Input";
import { useDispatch } from "react-redux";

import { deleteProducto } from "app/sales/store/actions";
import useConfirm from "app/sales/hooks/useConfirm";

const Actions = ({ row }) => {
  const dispatch = useDispatch();
  console.log("ROW===>>>", row.producto.vc_nombre);
  const { Alert, onOpen } = useConfirm({
    message: `Seguro de querer eliminar el producto ${row.producto.vc_nombre}?`,
    onAccept() {
      dispatch(deleteProducto(row.uuid));
    },
  });

  const handler = () => {
    onOpen();
  };

  return (
    <Box>
      <Alert />
      <IconButton onClick={handler}>
        <Icon icon="DeleteOutline" />
      </IconButton>
    </Box>
  );
};

const columns = [
  { label: "Descripcion", name: "producto.vc_nombre" },
  {
    label: "U.M.",
    name: "producto.unidadMedida.vc_unidadMedida",
    options: {
      cellProps: { width: "300px", nowrap: true },
    },
  },
  {
    label: "P.U.",
    name: "vc_precio",
    options: {
      headerCellProps: { align: "right" },
      cellProps: { align: "right", width: "150px", nowrap: true },
    },
  },
  {
    label: "Cantidad",
    name: "nu_cantidad",
    options: {
      cellProps: { width: "150px" },
      render: (value) => <Input value={value} noMargin dense />,
    },
  },
  {
    label: "Importe",
    name: "vc_precioImporte",
    options: {
      headerCellProps: { align: "right" },
      cellProps: { align: "right", width: "150px", nowrap: true },
    },
  },
  {
    label: "",
    options: {
      cellProps: { width: "20px", nowrap: true },
      render: (value, row, index) => <Actions {...{ value, row, index }} />,
    },
  },
];
const Index = ({ data, ...props }) => {
  return (
    <Box {...props}>
      <Table columns={columns} data={data} />
    </Box>
  );
};

Index.defaulProps = { data: [] };
export default Index;

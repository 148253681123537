import React from 'react';
import { Button, Menu, MenuItem, Fade, Divider, Box, Input, Popover } from '@material-ui/core';
import { Search, Check } from '@material-ui/icons';

const ButtonMenu = props => {
  const { options = [], showSelected = false, display = '', height = 300 } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)

  const [value, setValue] = React.useState('')
  const [items, setItems] = React.useState(options)
  
  // const option = options.find(o => o.value == props.value)
  // const [selected, setSelected] = React.useState(option)
  const [selected, setSelected] = React.useState(null)

  React.useEffect(() => {
    setItems(props.options)
  }, [props.options])

  React.useEffect(() => {
    const filtered = options.filter(v => v.vc_nombre.toLowerCase().includes(value.toLowerCase()))

    setItems(value ? filtered : options)
  }, [value])

  const openMenu = (e) => {
    setMenuAnchorEl(e.currentTarget)
  }
  const closeMenu = () => {
    setMenuAnchorEl(null)
  }

  return (
    <div>
      <Button size="small" color='inherit' onClick={openMenu}>{display} </Button>
      <Popover
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
        anchorEl={menuAnchorEl}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box padding={0} style={{
          height,
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Box padding={0} paddingTop={1} paddingLeft={1} paddingRight={1} style={{ outline: 'none' }}>
            <Input
              value={value}
              autoFocus
              placeholder={'Buscar'}
              onClick={(e) => e.stopPropagation()}
              fullWidth
              disableUnderline
              onChangeCapture={(event) => setValue(event.target.value)}
              startAdornment={
                <Search style={{ marginRight: 8 }} />
              }
            />
          </Box>
          <Divider variant='fullWidth' />
          <Box padding={0} style={{ flex: '1 1 auto', overflowY: 'auto', minHeight: 0, height: 0 }}>
            {items.map((v, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  closeMenu()
                  v.action()
                  setValue('')
                  // setSelected(v)
                }}
              >
                <p>{v.vc_nombre}</p>
                {selected}
                {
                  selected && v.value === selected.value ?
                    <Check color='primary' style={{ float: 'right' }} />
                    : null
                }
              </MenuItem>
            ))}
          </Box>
        </Box>
      </Popover>
    </div>
  )
}

export default ButtonMenu
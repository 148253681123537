import React from 'react';
import { Paper, AppBar, Typography, Button } from '@material-ui/core';
import moment from 'moment'
import TextView from '../../../../material-ui/TextView'
import TimeSelectPicker from '../../../../material-ui/TimeSelectPicker'
import SearchSelect from '../../../../material-ui/ThinkForm/SearchSelect';

const ButtonSidebar = props => <Button variant="outlined" color='primary' style={{ width: "100%", margin: '3px 0px', marginBottom: 0 }} {...props}>{props.children}</Button>
export const Sidebar = props => {
    const { index = -1, events, duracion } = props
    const defaultData = { nu_dia: 1, tm_inicio: '8:00:00', tm_fin: '20:00:00', vc_observaciones: '' }

    const [editing, setEditing] = React.useState(!!props.data && index)
    const [message, setMessage] = React.useState('')
    const [disabled, setDisabled] = React.useState(false)
    const [data, setData] = React.useState(editing ? props.data : defaultData)

    const handleChange = (key, value) => {
        let nv = Array.isArray(key) ?
            key.reduce((pv, cv) => ({ ...pv, [cv.key]: cv.value }), {}) :
            { [key]: value }

        setData({ ...data, ...nv })
    }

    const save = () => {
        props.save(data, index)
    }
    
    const remove = () => {
        props.remove(data, index)
    }

    const inputProps = field => ({ key: field, fieldid: field, name: field, required: true })
    const options = [
        { value: 1, label: 'Domingo' },
        { value: 2, label: 'Lunes' },
        { value: 3, label: 'Martes' },
        { value: 4, label: 'Miercoles' },
        { value: 5, label: 'Jueves' },
        { value: 6, label: 'Viernes' },
        { value: 7, label: 'Sabado' },
    ]

    const eq = (a, b) => a.diff(b, 'minutes') == 0
    const gt = (a, b) => a.diff(b, 'minutes') > 0
    const lt = (a, b) => a.diff(b, 'minutes') < 0
    const gte = (a, b) => a.diff(b, 'minutes') >= 0
    const lte = (a, b) => a.diff(b, 'minutes') <= 0


    React.useEffect(() => {
        setData(!!props.data ? props.data : defaultData)
        setEditing(!!props.data && props.index > -1)
    }, [props.data])

    React.useEffect(() => {
        setMessage('')

        const a = moment(data.tm_inicio, 'HH:mm:ss'), b = moment(data.tm_fin, 'HH:mm:ss')
        const diff = b.diff(a, 'minutes')

        setDisabled(!data.nu_dia || !a.isValid() || !b.isValid() || diff < 60)

        if (a.isValid() && b.isValid && diff < 60) {
            setMessage('- La duración del detalle debe ser mayor a una hora')
        }

        if (a.isValid() && b.isValid && diff >= 60) {
            const some = (events || [])
                .filter((v, i) => i != index)
                .filter(v => v.nu_dia == data.nu_dia)
                .some(v => {
                    const va = moment(v.tm_inicio, 'HH:mm:ss'),
                        vb = moment(v.tm_fin, 'HH:mm:ss')

                    if (lte(a, va) && gte(b, va) && lte(b, vb))
                        return true
                    else if (gte(a, va) && lt(a, vb) && gte(b, vb))
                        return true
                    else if (lte(a, vb) && gte(b, va))
                        return true
                    else if (lte(a, va) && gte(b, vb))
                        return true

                    return false
                })

            if (some) {
                setMessage('- El detalle choca con otro detalle')
                setDisabled(true)
            }
        }

    }, [data.nu_dia, data.tm_inicio, data.tm_fin])

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <AppBar position="relative" style={{ height: 95, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography>{editing ? 'Editar Detalle': 'Nuevo Detalle'}</Typography>
            </AppBar>
            <div style={{ flex: 1, padding: '10px' }}>
                <Paper>
                    <SearchSelect label={'Día'} value={data.nu_dia} options={options} onChange={v => handleChange('nu_dia', v.value)} fullWidth {...inputProps('nu_dia')} />
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <TimeSelectPicker minutesStep={duracion} defaultValue={data.tm_inicio} onChange={v => handleChange('tm_inicio', v)} label={'Hora de inicio'} {...inputProps('tm_inicio')} />
                        <TimeSelectPicker minutesStep={duracion} defaultValue={data.tm_fin} onChange={v => handleChange('tm_fin', v)} label={'Hora de fin'} {...inputProps('tm_fin')} style={{ marginRight: 0 }} />
                    </div>
                    {/* <TextView
                        label={'Observaciones'}
                        value={data.vc_observaciones}
                        type={'text'}
                        multiline
                        fullWidth
                        {...inputProps('vc_observaciones')}
                        required={false}
                        onChange={v => handleChange('vc_observaciones', v)}
                    /> */}

                    <ButtonSidebar disabled={disabled} onClick={save}>{editing ? 'Guardar' : 'Agregar'}</ButtonSidebar>
                    {editing && <ButtonSidebar onClick={remove}>Eliminar</ButtonSidebar>}

                    <Typography style={{ margin: '10px 0px' }}>{message}</Typography>
                </Paper>
            </div>
        </div>
    )
}
import React, { Component } from 'react';
import { Form, Checkbox } from 'semantic-ui-react';
import _ from 'lodash';

// crear componente
class CheckboxInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.defaultValue ? props.defaultValue : false,
            valid: true,
            dirty: false
        };
    }


    // -----------------------------
    // ------ life cycle events ----
    // -----------------------------
    componentDidMount() {
        this.setForm();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.dirty && nextProps.defaultValue) {
            this.setState({ value: nextProps.defaultValue, valid: true });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextState, this.state) || !_.isEqual(nextProps, this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.eq(prevState, this.state)) {
            this.setForm();
        }
    }


    // -----------------------
    // ------ user events ----
    // -----------------------
    onChange(e, data) {
        this.setState({ value: data.checked, dirty: true });
    }

    setForm() {
        this.props.setFormData(this.props.name, this.state);
    }

    getValue() {
        return this.state.value;
    }

    dirtInput() {
        this.setState({ dirty: true });
    }

    resetInput() {
        this.setState({
            value: this.props.defaultValue ? this.props.defaultValue : false,
            valid: true,
            dirty: false
        });
    }


    // --------------------------
    // ------ render methods ----
    // --------------------------
    render() {
        return (
            <Form.Field>
                <Checkbox
                    name={this.props.name}
                    label={this.props.label}
                    checked={this.state.value}
                    onChange={this.onChange.bind(this)}
                    disabled={this.props.readOnly}
                    className={this.props.className}
                    style={this.props.inputStyle}
                    onClick={() => this.props.callbackSelectInput()}
                />
            </Form.Field>
        );
    }
}


// exportar componente
export default CheckboxInput;

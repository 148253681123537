import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Image, Card, Input, Modal, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import internalAPI from "../../../../utils/internalAPI";
import notifications from "../../../../utils/notifications";
import { FadeInView } from "../../../../components";
import { START_VIEW_TRANSITION } from "../../../../constants";
import NewPatientModal from "./NewPatientModal";
import {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setPacienteActual,
  logOut,
  setDisplaySidebar,
  setState,
} from "../../actions";
import BuscadorEstudiosHelpBar from "../notas/buscadorEstudiosHelpBar";
import { menuPacientes } from "../../listados";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ContactSupport from "@material-ui/icons/ContactSupport";
import Check from "@material-ui/icons/Check";
import Cancel from "@material-ui/icons/Cancel";
import Dvr from "@material-ui/icons/Dvr";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import Healing from "@material-ui/icons/Healing";
import Search from "@material-ui/icons/Search";
import AddAlert from "@material-ui/icons/AddAlert";
import ModalUI from "@material-ui/core/Modal";
import eventManager from "../../../../utils/eventManager";
// Material UI
import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ThinkForm from "../../../../material-ui/ThinkForm";
import SubMenuModulos from "../../../../material-ui/SubMenuModulos";
import QRReader from "../../../../material-ui/QRReader";
import repository from "../../../../utils/repository";
import ConfirmAlert from "../../../../material-ui/ConfirmAlert";
import {
  Box,
  Divider,
  MenuItem,
  IconButton,
  ListItem,
  Switch,
  FormControlLabel,
  Avatar,
} from "@material-ui/core";
import TriageModule from "../notas/preguntas/TriageModule";
import moment from "moment";
import * as firebase from "firebase/app";
import GoogleMaps from "./GoogleMaps";
import Selector from "./covid/Selector";
import UploadPicture from "../../../../components/UploadPicture";

import Table from "app/material-ui/Table";

const PatientItem = styled(Paper)({
  border: 0,
  borderRadius: 5,
  padding: "15px",
  marginBottom: "10px",
  border: "1px solid transparent",
  "&.selected": {
    borderColor: window.colors.itemSelected,
  },
  "& h5": {
    color: window.colors.itemSelected,
    marginBottom: "10px",
  },
  "& label": {
    marginRight: "5px",
  },
});

const { Row } = Grid;
class PatientsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showStats: false,
      menuPacientes,
      moduloActual: this.props.modalMode ? "registrar" : "listado",
      opcionActualModulo: this.props.modalMode
        ? this.props.opcionActualModulo || "registrar"
        : "listado",
      loading: true,
      loadingOn: null,
      data: [],
      patientSelected: null,
      dependentPaciente: null,
      selectedRow: null,
      stringQR: "",
      functions: {},
      searchText: "",
      showTriageCOVID19: false,
      patientsCallcenter: [],
      opcionActualModuloPaciente: "vc_curp",
      fields: [
        { key: "vc_nombre", require: true },
        { key: "vc_apellidoPaterno", require: true },
        { key: "id_sexo", require: true },
        { key: "dt_nacimiento", require: true },
      ],
      statusCovid: [
        {
          id: 1,
          vc_nombre: "Solicitud de Información",
          vc_abreviatura: "Informados",
          vc_descripcion:
            "Es el ciudadano que se comunica por informacion referente a covid per ono presenta sintomatologia compatible",
        },
        {
          id: 2,
          vc_nombre: "Paciente (no sospechoso)",
          vc_abreviatura: "Descartados",
          vc_descripcion:
            "Es el paciente que se comunica por informacion referente a covid y además presenta sintomatologia sin embargo esta no cumple con definicion operacional de caso para covid.",
        },
        {
          id: 3,
          vc_nombre: "Paciente (no sospechoso) posiblemente grave",
          vc_abreviatura: "Otros Graves",
          vc_descripcion:
            "Es el paciente que se comunica por informacion referente a covid, no presenta sintomatologia compatible con definicion operacional de caso para covid-19, pero si datos de gravedad.",
        },
        {
          id: 4,
          vc_nombre: "Paciente tipo ETI (sospechoso)",
          vc_abreviatura: "ETI",
          vc_descripcion:
            "Es el paciente que se comunica por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso",
        },
        {
          id: 5,
          vc_nombre: "Paciente tipo IRAG (sospechoso grave) ",
          vc_abreviatura: "IRAG",
          vc_descripcion:
            "Es el paciente que se comunica por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso, y ademas signos de gravedad.",
        },
      ],
      showSetFilters: false,
      showMapCases: false,
      filtersCases: [
        {
          id: 3,
          vc_nombre: "Paciente (no sospechoso) posiblemente grave",
          vc_descripcion:
            "Es el paciente que se comunica por informacion referente a covid, no presenta sintomatologia compatible con definicion operacional de caso para covid-19, pero si datos de gravedad.",
        },
        {
          id: 4,
          vc_nombre: "Paciente tipo ETI (sospechoso)",
          vc_descripcion:
            "Es el paciente que se comunica por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso",
        },
        {
          id: 5,
          vc_nombre: "Paciente tipo IRAG (sospechoso grave) ",
          vc_descripcion:
            "Es el paciente que se comunica por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso, y ademas signos de gravedad.",
        },
        {
          id: 6,
          vc_nombre: "Paciente Confirmado",
          vc_descripcion:
            "Es el paciente que se subio a la plataforma por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso",
        },
        {
          id: 7,
          vc_nombre: "Paciente Negativo",
          vc_descripcion:
            "Es el paciente que se subio a la plataforma por informacion referente a covid y además tiene resultado negativo para covid",
        },
        {
          id: 8,
          vc_nombre: "Paciente Muerto",
          vc_descripcion:
            "Es el paciente que se subio a la plataforma y fallecio. por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso",
        },
        {
          id: 9,
          vc_nombre: "En espera",
          vc_abreviatura: "Espera",
          vc_descripcion:
            "Es el paciente que se subio a la plataforma y fallecio. por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso",
        },
        {
          id: 10,
          vc_nombre: "Plataforma",
          vc_abreviatura: "SINAVE",
          vc_descripcion:
            "Es el paciente que se subio a la plataforma y fallecio. por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso",
        },
      ],
      filtersPlatformUnSelected: [],
      filtersPlatform: [
        {
          id: 1,
          vc_nombre: "Edad",
          vc_descripcion: null,
          opciones: [
            { id: 1, vc_nombre: "< 1", vc_descripcion: "SI" },
            { id: 2, vc_nombre: "1-4", vc_descripcion: "SI" },
            { id: 3, vc_nombre: "4-9", vc_descripcion: "SI" },
            { id: 4, vc_nombre: "10-14", vc_descripcion: "SI" },
            { id: 5, vc_nombre: "15-19", vc_descripcion: "SI" },
            { id: 6, vc_nombre: "20-24", vc_descripcion: "SI" },
            { id: 7, vc_nombre: "25-29", vc_descripcion: "SI" },
            { id: 8, vc_nombre: "30-34", vc_descripcion: "SI" },
            { id: 9, vc_nombre: "35-39", vc_descripcion: "SI" },
            { id: 10, vc_nombre: "40-44", vc_descripcion: "SI" },
            { id: 11, vc_nombre: "45-49", vc_descripcion: "SI" },
            { id: 12, vc_nombre: "50-54", vc_descripcion: "NO" },
            { id: 13, vc_nombre: "55-59", vc_descripcion: "NO" },
            { id: 14, vc_nombre: "60-64", vc_descripcion: "NO" },
            { id: 15, vc_nombre: "65-69", vc_descripcion: "NO" },
            { id: 16, vc_nombre: "70-74", vc_descripcion: "NO" },
            { id: 17, vc_nombre: "75-79", vc_descripcion: "NO" },
            { id: 18, vc_nombre: ">80", vc_descripcion: "NO" },
          ],
        },
        {
          id: 2,
          vc_nombre: "Muestreo",
          vc_descripcion: "TOMA MUESTRA",
          opciones: [
            { id: 1, vc_nombre: "Muestreados", vc_descripcion: "SI" },
            { id: 2, vc_nombre: "No muestreados", vc_descripcion: "NO" },
          ],
        },
        {
          id: 3,
          vc_nombre: "Sexo",
          vc_descripcion: null,
          opciones: [
            { id: 1, vc_nombre: "Masculino", vc_descripcion: null },
            { id: 2, vc_nombre: "Femenino", vc_descripcion: null },
          ],
        },
        {
          id: 4,
          vc_nombre: "Municipio",
          vc_descripcion: null,
          opciones: [
            { id: "001", vc_nombre: "Ahome", vc_descripcion: null },
            { id: "002", vc_nombre: "Angostura", vc_descripcion: null },
            { id: "003", vc_nombre: "Badiraguato", vc_descripcion: null },
            { id: "004", vc_nombre: "Concordia", vc_descripcion: null },
            { id: "005", vc_nombre: "Cosalá", vc_descripcion: null },
            { id: "006", vc_nombre: "Culiacán", vc_descripcion: null },
            { id: "007", vc_nombre: "Choix", vc_descripcion: null },
            { id: "008", vc_nombre: "Elota", vc_descripcion: null },
            { id: "009", vc_nombre: "Escuinapa", vc_descripcion: null },
            { id: "010", vc_nombre: "El Fuerte", vc_descripcion: null },
            { id: "011", vc_nombre: "Guasave", vc_descripcion: null },
            { id: "012", vc_nombre: "Mazatlán", vc_descripcion: null },
            { id: "013", vc_nombre: "Mocorito", vc_descripcion: null },
            { id: "014", vc_nombre: "Rosario", vc_descripcion: null },
            { id: "015", vc_nombre: "Salvador Alvarado", vc_descripcion: null },
            { id: "016", vc_nombre: "San Ignacio", vc_descripcion: null },
            { id: "017", vc_nombre: "Sinaloa", vc_descripcion: null },
            { id: "018", vc_nombre: "Navolato", vc_descripcion: null },
          ],
        },
        {
          id: 5,
          vc_nombre: "Tipo Paciente",
          vc_descripcion: null,
          opciones: [
            {
              id: 1,
              vc_nombre: "Hospitalizado",
              vc_descripcion: "HOSPITALIZADO",
            },
            { id: 2, vc_nombre: "Ambulatorio", vc_descripcion: "AMBULATORIO" },
          ],
        },
        {
          id: 6,
          vc_nombre: "Ventilación Mecánica",
          vc_descripcion: null,
          opciones: [
            { id: 1, vc_nombre: "Requirio Ventilación", vc_descripcion: "SI" },
            { id: 2, vc_nombre: "Sin Ventilación", vc_descripcion: "NO" },
          ],
        },
        {
          id: 7,
          vc_nombre: "Defunción",
          vc_descripcion: null,
          opciones: [
            { id: 1, vc_nombre: "Defuncion", vc_descripcion: "SI" },
            { id: 2, vc_nombre: "-", vc_descripcion: "NO" },
          ],
        },
        {
          id: 8,
          vc_nombre: "Gravedad",
          vc_descripcion: null,
          opciones: [
            {
              id: 1,
              vc_nombre: "ETI",
              vc_descripcion: "ENFERMEDAD TIPO INFLUENZA (ETI)",
            },
            {
              id: 2,
              vc_nombre: "IRAG",
              vc_descripcion: "INFECCION RESPIRATORIA AGUDA GRAVE (IRAG)",
            },
          ],
        },
        {
          id: 9,
          vc_nombre: "Resultados",
          vc_descripcion: null,
          opciones: [
            { id: 1, vc_nombre: "Proceso", vc_descripcion: null },
            { id: 2, vc_nombre: "Rechazado", vc_descripcion: "RECHAZADA" },
            { id: 3, vc_nombre: "No Muestreado", vc_descripcion: null },
            { id: 4, vc_nombre: "SARS-CoV-2", vc_descripcion: "SARS-CoV-2" },
            { id: 5, vc_nombre: "Influenza (A H3)", vc_descripcion: "A H3" },
            {
              id: 6,
              vc_nombre: "Influenza (A H1N1 PDM)",
              vc_descripcion: "INF AH1N1 PMD",
            },
            { id: 7, vc_nombre: "Influenza (B)", vc_descripcion: "B" },
            { id: 8, vc_nombre: "Adenovirus", vc_descripcion: "ADENOVIRUS" },
            {
              id: 9,
              vc_nombre: "Enterovirus/Rhinovirus",
              vc_descripcion: "ENTEROV//RHINOVIRUS",
            },
            {
              id: 10,
              vc_nombre: "Virus Sincitial Respiratorio",
              vc_descripcion: "VSR",
            },
            { id: 11, vc_nombre: "Influenza A", vc_descripcion: "INF A" },
            { id: 12, vc_nombre: "No adecuada", vc_descripcion: "NO ADECUADO" },
            { id: 13, vc_nombre: "Negativo", vc_descripcion: "NEGATIVO" },
          ],
        },
      ],
      filtersOptions: [
        {
          id: 1,
          vc_nombre: "Solicitud de Información",
          vc_abreviatura: "Informados",
          vc_descripcion:
            "Es el ciudadano que se comunica por informacion referente a covid per ono presenta sintomatologia compatible",
        },
        {
          id: 2,
          vc_nombre: "Paciente (no sospechoso)",
          vc_abreviatura: "Descartados",
          vc_descripcion:
            "Es el paciente que se comunica por informacion referente a covid y además presenta sintomatologia sin embargo esta no cumple con definicion operacional de caso para covid.",
        },
        {
          id: 3,
          vc_nombre: "Paciente (no sospechoso) posiblemente grave",
          vc_abreviatura: "Otros Graves",
          vc_descripcion:
            "Es el paciente que se comunica por informacion referente a covid, no presenta sintomatologia compatible con definicion operacional de caso para covid-19, pero si datos de gravedad.",
        },
        {
          id: 4,
          vc_nombre: "Paciente tipo ETI (sospechoso)",
          vc_abreviatura: "ETI",
          vc_descripcion:
            "Es el paciente que se comunica por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso",
        },
        {
          id: 5,
          vc_nombre: "Paciente tipo IRAG (sospechoso grave) ",
          vc_abreviatura: "IRAG",
          vc_descripcion:
            "Es el paciente que se comunica por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso, y ademas signos de gravedad.",
        },
        {
          id: 6,
          vc_nombre: "Paciente Confirmado",
          vc_abreviatura: "Positivos",
          vc_descripcion:
            "Es el paciente que se subio a la plataforma por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso",
        },
        {
          id: 7,
          vc_nombre: "Paciente Negativo",
          vc_abreviatura: "Negativos",
          vc_descripcion:
            "Es el paciente que se subio a la plataforma por informacion referente a covid y además tiene resultado negativo para covid",
        },
        {
          id: 8,
          vc_nombre: "Paciente Muerto",
          vc_abreviatura: "Fallecidos",
          vc_descripcion:
            "Es el paciente que se subio a la plataforma y fallecio. por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso",
        },
        {
          id: 9,
          vc_nombre: "En espera",
          vc_abreviatura: "Espera",
          vc_descripcion:
            "Es el paciente que se subio a la plataforma y fallecio. por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso",
        },
        {
          id: 10,
          vc_nombre: "Plataforma",
          vc_abreviatura: "SINAVE",
          vc_descripcion:
            "Es el paciente que se subio a la plataforma y fallecio. por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso",
        },
      ],
      casesPlatform: [],
    };
  }

  renderNotification() {
    return (
      <div>
        {this.state.errorMessages && (
          <ConfirmAlert
            title={this.state.errorMessages.title}
            message={
              <Box style={{ maxHeight: "90vh" }}>
                {this.state.errorMessages.message || ""}
                {this.state.errorMessages.noNulls &&
                  this.state.errorMessages.noNulls.length > 0 && (
                    <Box>
                      <Divider />
                      <MenuItem key={"vacios"}>No deben estar vacios:</MenuItem>
                      <Divider />
                      {this.state.errorMessages.noNulls.map((field, index) => {
                        return <MenuItem key={index}>{field}</MenuItem>;
                      })}
                    </Box>
                  )}
                {this.state.errorMessages.noSuccess &&
                  this.state.errorMessages.noSuccess.length > 0 && (
                    <Box>
                      <Divider />
                      <MenuItem key={"formatos"}>Formato invalido:</MenuItem>
                      <Divider />
                      {this.state.errorMessages.noSuccess.map(
                        (field, index) => {
                          return <MenuItem key={index}>{field}</MenuItem>;
                        }
                      )}
                    </Box>
                  )}
              </Box>
            }
            onAccept={
              this.state.errorMessages.onAccept ||
              (() => {
                this.setState({ errorMessages: null });
              })
            }
          />
        )}
        {this.state.successMessage && (
          <ConfirmAlert
            title={this.state.successMessage.title || ""}
            message={this.state.successMessage.message || ""}
            onAccept={
              this.state.successMessage.onAccept ||
              (() => {
                this.setState({ errorMessages: null, successMessage: null });
              })
            }
          />
        )}
      </div>
    );
  }
  renderTriageCovid19() {
    return (
      <ModalUI
        open={this.state.showTriageCOVID19}
        onClose={() => this.setState({ showTriageCOVID19: false })}
        style={{
          boxShadow: "0px 0px 0px transparent",
          backgroundColor: "transparent",
          margin: window.isMobile ? 20 : "50px 30vh 50px 30vh",
        }}
      >
        <Paper
          style={{
            width: "100%",
            borderRadius: 10,
            overflow: "hidden",
            height: "100%",
            outline: "none",
          }}
        >
          {this.props.pacienteActual &&
            this.props.pacienteActual.preguntasTexto &&
            this.props.pacienteActual.preguntasTexto[0] && (
              <TriageModule
                callbackFinish={() =>
                  this.setState({ selectedRow: null, showTriageCOVID19: false })
                }
                item={
                  this.props.pacienteActual
                    ? this.props.pacienteActual.preguntasTexto[0]
                    : null
                }
              />
            )}
        </Paper>
      </ModalUI>
    );
  }
  renderNotaInicialCovid19() {
    return (
      <ModalUI
        open={this.state.showTriageCOVID19}
        onClose={() =>
          this.setState({ showTriageCOVID19: false, selectedRow: null })
        }
        style={{
          boxShadow: "0px 0px 0px transparent",
          backgroundColor: "transparent",
          margin: window.isMobile ? 20 : "50px 30vh 50px 30vh",
          height: "100%",
        }}
      >
        <Paper
          style={{
            width: "100%",
            borderRadius: 10,
            overflow: "hidden",
            height: "100%",
          }}
        >
          {this.props.pacienteActual &&
            this.props.pacienteActual.preguntasTexto &&
            this.props.pacienteActual.preguntasTexto[0] && (
              <TriageModule
                callbackFinish={() =>
                  this.setState({ selectedRow: null, showTriageCOVID19: false })
                }
                item={
                  this.props.pacienteActual
                    ? this.props.pacienteActual.preguntasTexto[0]
                    : null
                }
                preguntasEnlace={this.state.preguntasEnlace || []}
              />
            )}
        </Paper>
      </ModalUI>
    );
  }
  renderFilter() {
    return (
      <ModalUI
        open={this.state.showSetFilters}
        onClose={() => this.setState({ showSetFilters: false })}
        style={{
          boxShadow: "0px 0px 0px transparent",
          backgroundColor: "transparent",
          margin: window.isMobile ? 20 : "50px 30vh 50px 30vh",
          height: "100%",
        }}
      >
        <Paper style={{ outline: "none" }}>
          <Box>
            <Typography
              variant="h6"
              style={{
                padding: 16,
                paddingLeft: 30,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {"Selecciona los filtros:"}
              <Button
                onClick={() =>
                  this.setState({ filtersCases: this.state.statusCovid })
                }
              >
                {"Agregar Todos"}
              </Button>
            </Typography>
          </Box>
          <Divider />
          {this.state.statusCovid.map((status, index) => {
            return (
              <ListItem
                divider
                selected={
                  !!this.state.filtersCases.find((e) => e.id === status.id)
                }
                button
                key={index}
                onClick={() => {
                  if (this.state.filtersCases.find((e) => e.id === status.id)) {
                    const filtersCases = this.state.filtersCases.filter(
                      (e) => e.id !== status.id
                    );
                    this.setState({ filtersCases });
                  } else {
                    const filtersCases = this.state.filtersCases;
                    filtersCases.push(status);
                    this.setState({ filtersCases });
                  }
                }}
                style={{ width: "100%" }}
              >
                <IconButton>{this.getIcon(status.id)}</IconButton>
                <Typography>{status.vc_nombre}</Typography>
                {this.state.filtersCases.find((e) => e.id === status.id) && (
                  <IconButton style={{ float: "right" }}>
                    <Check color="primary" />
                  </IconButton>
                )}
              </ListItem>
            );
          })}
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              padding: 8,
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              onClick={() => this.setState({ showSetFilters: false })}
            >
              {"Aceptar"}
            </Button>
          </Box>
        </Paper>
      </ModalUI>
    );
  }
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: this.props.modalMode ? "100%" : "100%",
          backgroundColor: "rgba(200,200,200,0.2)",
        }}
      >
        {this.renderHeaderMenuPacientes()}
        <div
          style={{
            ...styles.principal,
            ...(this.props.modalMode ? { height: "calc(100% - 47px)" } : {}),
          }}
        >
          {this.renderContent()}
          {this.renderTriageCovid19()}
        </div>
        {this.renderEstudiosSelector()}
        {this.renderLoading()}
        {this.renderNotification()}
        {this.renderFilter()}
        {this.renderMap()}
        {this.renderStats()}
      </div>
    );
  }

  renderHeaderMenuPacientes() {
    const moduloActual = this.state.menuPacientes.filter(
      (e) => e.id === this.state.moduloActual
    )[0];

    const options = moduloActual.opciones.map((opcion) => {
      return {
        ...opcion,
        vc_nombre: `${opcion.vc_nombre} (${this.getNumberCases(
          opcion.id,
          null
        )})`,
        action: () => {
          this.onChangeModule(moduloActual, opcion.id, "agregar");
        },
      };
    });
    const opcionActual =
      options.find((e) => e.id == this.state.opcionActualModulo) ||
      moduloActual.opciones[0];
    const acciones = opcionActual ? opcionActual.acciones : [];
    acciones.forEach((accion) => {
      accion.action = () => {
        this.clickAccion(accion);
      };
    });
    return (
      <SubMenuModulos
        title={
          this.state.moduloActual === "callcenter"
            ? opcionActual.vc_nombre
            : null
        }
        type={this.state.moduloActual !== "registrar" ? "search" : null}
        searchText={this.state.searchText}
        onChange={
          this.state.moduloActual === "callcenter"
            ? this.searchPatientCallcenter.bind(this)
            : this.searchPatient.bind(this)
        }
        actions={acciones}
        // options={options}
        optionDisplay={
          this.state.moduloActual === "callcenter"
            ? ["Responsabilidad", opcionActual.vc_nombre]
            : null
        }
        scanner={
          this.state.moduloActual !== "callcenter" ? (
            <QRReader
              getRegistro={this.state.functions.getRegistro}
              setRegistro={this.setRegistro.bind(this)}
            />
          ) : null
        }
      />
    );
  }

  renderContent() {
    const { moduloActual: modulo } = this.state;

    switch (modulo) {
      case "listado":
        return <Row> {this.renderTable()} </Row>;
      case "registrar":
        return (
          <ThinkForm
            type="form"
            setFunctions={(functions) => {
              this.setState({ functions });
            }}
            withOutHeader
            tags={[]}
            registro={
              this.state.patientSelected
                ? {
                    id: this.state.patientSelected.id,
                    detalle: {
                      ...this.state.patientSelected,
                      id_paciente: this.state.patientSelected.id,
                    },
                  }
                : this.state.patientEdit || {}
            }
            catalogo={this.props.catalogo}
          />
        );
      case "callcenter": {
        return <Row> {this.renderTableCallCenter()} </Row>;
      }
      default:
        return null;
    }
  }

  renderEstudiosSelector() {
    if (this.state.estudiosSelectorModal) {
      return (
        <Modal
          open
          size="mini"
          style={{ height: window.screen.availHeight * 0.8 }}
          onClose={() => {
            this.setState({ estudiosSelectorModal: false });
          }}
        >
          <BuscadorEstudiosHelpBar
            callback={({ item, estudio }) => {
              if (estudio) {
                // code
              } else if (item) {
                this.iniciarEstudios([item.id]);
              }
            }}
            opcionEstudios="agregar"
            opcion="todos"
          />
        </Modal>
      );
    }
    return null;
  }

  renderTable() {
    if (this.state.loading) {
      return (
        <div
          style={{ width: "100%", height: "100%", padding: "2%", margin: 0 }}
        >
          <div style={{ height: 100 }}>
            <Loader size="massive" active inverted>
              {this.state.loadingOn}
            </Loader>
          </div>
        </div>
      );
    }

    const columns = [
      {
        name: "vc_nombreCompleto",
        label: "Nombre del Paciente",
        options: {
          cellProps: {
            className: "fill",
          },
          typographyProps: {
            color: "primary",
            variant: "subtitle1",
          },
        },
      },
      { name: "vc_curpTabla", label: "Curp" },
      { name: "vc_sexo", label: "Sexo" },
      { name: "nu_edad", label: "Edad" },
    ];
    const dataAux = this.state.data.map((v) => {
      const pacienteObj = v;
      let vc_nombreCompleto = "no especificado, la nota no podrá ser guardada.";
      let vc_sexo = "no especificado";
      let nu_edad = "no especificada";
      let vc_curpTabla = "no especificado";

      if (pacienteObj !== {} && pacienteObj !== null) {
        nu_edad = this.timeSince(
          new Date(),
          new Date(pacienteObj.dt_nacimiento)
        );
        vc_sexo =
          pacienteObj.id_sexo === 1
            ? "Masculino"
            : pacienteObj.id_sexo === 2
            ? "Femenino"
            : "no especificado";
        vc_curpTabla = pacienteObj.vc_curp
          ? pacienteObj.vc_curp.toUpperCase()
          : "no especificado";
        vc_nombreCompleto =
          `${pacienteObj.vc_nombre}` +
          " " +
          `${pacienteObj.vc_apellidoPaterno}` +
          " " +
          `${
            pacienteObj.vc_apellidoMaterno ? pacienteObj.vc_apellidoMaterno : ""
          }`.trim();
      }

      return { ...v, vc_sexo, nu_edad, vc_curpTabla, vc_nombreCompleto };
    });

    return (
      <Box px={2} py={1}>
        <FadeInView>
          <Table
            columns={columns}
            data={dataAux}
            onSelect={this.selectedRow.bind(this)}
            value={this.state.selectedRow?.id}
          />
        </FadeInView>
      </Box>
    );
    return (
      <div style={{ width: "100%", height: "100%", padding: "2%", margin: 0 }}>
        {window.isMobile ? (
          this.state.data.map((paciente, index) => {
            const pacienteObj = paciente;
            let vc_nombreCompleto =
              "no especificado, la nota no podrá ser guardada.";
            let sexo = "no especificado";
            let edad = "no especificada";
            let curp = "no especificado";

            if (pacienteObj !== {} && pacienteObj !== null) {
              edad = this.timeSince(
                new Date(),
                new Date(pacienteObj.dt_nacimiento)
              );
              sexo =
                pacienteObj.id_sexo === 1
                  ? "Masculino"
                  : pacienteObj.id_sexo === 2
                  ? "Femenino"
                  : "no especificado";
              curp = pacienteObj.vc_curp
                ? pacienteObj.vc_curp.toUpperCase()
                : "no especificado";
              vc_nombreCompleto =
                `${pacienteObj.vc_nombre}` +
                " " +
                `${pacienteObj.vc_apellidoPaterno}` +
                " " +
                `${
                  pacienteObj.vc_apellidoMaterno
                    ? pacienteObj.vc_apellidoMaterno
                    : ""
                }`.trim();
            }

            let opacity = this.state.indexSelected === index ? 1 : 0.5;
            if (!this.state.selectedRow) opacity = 1;

            return (
              <PatientItem
                key={index}
                className={this.state.indexSelected === index ? "selected" : ""}
                style={{ opacity }}
                onClick={() => {
                  this.selectedRow(paciente, index);
                }}
              >
                <h5>{vc_nombreCompleto}</h5>
                <p>
                  <label>
                    <b>Sexo:</b> {sexo}
                  </label>
                  <label>
                    <b>Edad:</b> {edad}
                  </label>
                  <label>
                    <b>CURP:</b> {curp}
                  </label>
                </p>
              </PatientItem>
            );
          })
        ) : (
          <FadeInView>
            {
              // Pintamos los pacientes
              this.state.data.map((paciente, index) => {
                const pacienteObj = paciente;
                let vc_nombreCompleto =
                  "no especificado, la nota no podrá ser guardada.";
                let sexo = "no especificado";
                let edad = "no especificada";
                let curp = "no especificado";

                if (pacienteObj !== {} && pacienteObj !== null) {
                  edad = this.timeSince(
                    new Date(),
                    new Date(pacienteObj.dt_nacimiento)
                  );
                  sexo =
                    pacienteObj.id_sexo === 1
                      ? "Masculino"
                      : pacienteObj.id_sexo === 2
                      ? "Femenino"
                      : "no especificado";
                  curp = pacienteObj.vc_curp
                    ? pacienteObj.vc_curp.toUpperCase()
                    : "no especificado";
                  vc_nombreCompleto =
                    `${pacienteObj.vc_nombre}` +
                    " " +
                    `${pacienteObj.vc_apellidoPaterno}` +
                    " " +
                    `${
                      pacienteObj.vc_apellidoMaterno
                        ? pacienteObj.vc_apellidoMaterno
                        : ""
                    }`.trim();
                }

                let opacity = this.state.indexSelected === index ? 1 : 0.5;
                if (!this.state.selectedRow) opacity = 1;

                return (
                  <PatientItem
                    key={index}
                    className={
                      this.state.indexSelected === index ? "selected" : ""
                    }
                    style={{ opacity }}
                    onClick={() => {
                      this.selectedRow(paciente, index);
                    }}
                  >
                    <h5>{vc_nombreCompleto}</h5>
                    <p>
                      <label>
                        <b>Sexo:</b> {sexo}
                      </label>
                      <label>
                        <b>Edad:</b> {edad}
                      </label>
                      <label>
                        <b>CURP:</b> {curp}
                      </label>
                    </p>
                  </PatientItem>
                );
              })
            }
          </FadeInView>
        )}
      </div>
    );
  }
  getIcon = (id_status) => {
    switch (id_status) {
      case 1: {
        return <ContactSupport style={{}} />;
      }
      case 2: {
        return <Healing style={{}} />;
      }
      case 3: {
        return <AddAlert style={{}} />;
      }
      case 4: {
        return <Search style={{}} />;
      }
      case 5: {
        return <AddAlert style={{}} />;
      }
      case 6: {
        return <AddCircleOutline style={{}} />;
      }
      case 7: {
        return <RemoveCircleOutline style={{}} />;
      }
      case 8: {
        return <Cancel style={{}} />;
      }
    }
  };

  filtersApply() {
    let patientsCallcenter = [];
    this.state.filtersCases.map((filter, index) => {
      patientsCallcenter = patientsCallcenter.concat(
        this.state.patientsCallcenter.filter(
          (e) => e.detalle.id_status === filter.id
        )
      );
    });
    const opcionActualModulo =
      this.state.moduloActual && this.state.opcionActualModulo
        ? this.state.opcionActualModulo ||
          (
            (
              menuPacientes.find((e) => e.id === this.state.moduloActual) || {
                opciones: [],
              }
            ).opciones[0] || {}
          ).id ||
          null
        : null;
    if (opcionActualModulo && opcionActualModulo != "11") {
      patientsCallcenter = patientsCallcenter.filter(
        (e) => e.detalle.id_responsable == opcionActualModulo
      );
    }
    if (this.state.searchText.length > 0) {
      patientsCallcenter = patientsCallcenter.filter((e) =>
        `${e.detalle.vc_nombre} ${e.detalle.vc_apellidoPaterno} ${e.detalle.vc_apellidoMaterno}`
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase())
      );
    }
    return patientsCallcenter;
  }
  renderTableCallCenter() {
    if (this.state.loading) {
      return (
        <div
          style={{ width: "100%", height: "100%", padding: "2%", margin: 0 }}
        >
          <div style={{ height: 100 }}>
            <Loader size="massive" active inverted>
              {this.state.loadingOn}
            </Loader>
          </div>
        </div>
      );
    }
    const patientsFilter = this.filtersApply();
    if (patientsFilter.length === 0) {
      return (
        <div
          style={{ width: "100%", height: "100%", padding: "2%", margin: 0 }}
        >
          <Paper style={{ textAlign: "center", padding: 30 }}>
            <Typography variant="h6">
              {"No hay pacientes con estos filtros"}
            </Typography>
          </Paper>
        </div>
      );
    }
    return (
      <div style={{ width: "100%", height: "100%", padding: "2%", margin: 0 }}>
        <FadeInView>
          {
            // Pintamos los pacientes
            patientsFilter.map((paciente, index) => {
              const pacienteObj = paciente.detalle;
              let vc_nombreCompleto =
                "no especificado, la nota no podrá ser guardada.";
              let sexo = "no especificado";
              let edad = "no especificada";
              let curp = "no especificado";
              if (pacienteObj !== {} && pacienteObj !== null) {
                edad = pacienteObj.dt_nacimiento
                  ? this.timeSince(
                      new Date(),
                      new Date(pacienteObj.dt_nacimiento)
                    )
                  : pacienteObj.nu_edad || "";
                sexo =
                  pacienteObj.id_sexo === 1
                    ? "Masculino"
                    : pacienteObj.id_sexo === 2
                    ? "Femenino"
                    : "no especificado";
                curp = pacienteObj.vc_curp
                  ? pacienteObj.vc_curp.toUpperCase()
                  : "no especificado";
                vc_nombreCompleto =
                  `${pacienteObj.vc_nombre}` +
                  " " +
                  `${pacienteObj.vc_apellidoPaterno}` +
                  " " +
                  `${
                    pacienteObj.vc_apellidoMaterno
                      ? pacienteObj.vc_apellidoMaterno
                      : ""
                  }`.trim();
              }

              let opacity = this.state.indexSelected === index ? 1 : 0.5;
              if (!this.state.selectedRow) opacity = 1;

              return (
                <PatientItem
                  key={index}
                  className={
                    this.state.indexSelected === index ? "selected" : ""
                  }
                  style={{ opacity }}
                  onClick={() => {
                    this.selectedRow(paciente, index);
                  }}
                >
                  <h5>
                    {paciente.id}
                    {" - "}
                    {vc_nombreCompleto.toUpperCase()}

                    <IconButton style={{ float: "right" }}>
                      {this.getIcon(paciente.detalle.id_status)}
                    </IconButton>
                    {(paciente.notas || []).length && (
                      <IconButton style={{ float: "right" }}>
                        <Dvr />
                        <div
                          style={{
                            position: "absolute",
                            top: 2,
                            right: 2,
                            fontSize: 15,
                          }}
                        >
                          {(paciente.notas || []).length}
                        </div>
                      </IconButton>
                    )}
                  </h5>
                  <p>
                    <label>
                      <b>Sexo:</b> {sexo}
                    </label>
                    <label>
                      <b>Edad:</b> {edad}
                    </label>
                    <label>
                      <b>CURP:</b> {curp}
                    </label>
                    <label>
                      <b>Clasificación COVID-19:</b>{" "}
                      {paciente.detalle.id_status
                        ? (
                            this.state.statusCovid.find(
                              (e) => e.id === paciente.detalle.id_status
                            ) || {}
                          ).vc_nombre || "No especificado"
                        : ""}
                    </label>
                  </p>
                </PatientItem>
              );
            })
          }
        </FadeInView>
      </div>
    );
  }
  // ---------------------------------
  // ------ life cycle events --------
  // ---------------------------------
  componentDidMount() {
    const institucion = window.amplify.store("institution") || {};

    if (
      !this.state.patientsCallcenter.length &&
      institucion.id_institucion === 2
    ) {
      this.getPatientsCallcenter();
    }
    if (!this.props.modalMode) {
      this.props.setActiveView("listado");
      this.props.setViewTitle("Catalogo de Pacientes");
      this.setMenuOptions();
      // window.addEventListener('resize', this.updateDimensions.bind(this));
      this.updateHelperSidebar();
      this.getPatients();
      if (this.props.setDisplaySidebar) this.props.setDisplaySidebar(false);
    }
  }
  getNumberCases(id_responsable, id_status) {
    let patientsCallcenter = this.state.patientsCallcenter || [];
    if (id_responsable) {
      patientsCallcenter = patientsCallcenter.filter(
        (e) => e.detalle.id_responsable == id_responsable
      );
    }
    if (id_status) {
      patientsCallcenter = patientsCallcenter.filter(
        (e) => e.detalle.id_status == id_status
      );
    }
    return patientsCallcenter.length;
  }
  getPacientesCallback() {
    internalAPI.getPatientsCovid19(null, (error, response) => {
      if (error) {
      } else {
        const casesPlatform = response.filter((e) => e.nu_lat && e.nu_lon);

        this.setState({ casesPlatform });
      }
    });
    internalAPI.patientsCallcenter(
      this.state.lastUpdate || "2020-03-31",
      null,
      (error, response) => {
        if (error) {
        } else {
          const institucion = window.amplify.store("institution") || {};
          if (institucion.id_institucion) {
            response.map((item, index) => {
              firebase
                .database()
                .ref()
                .child("epidemiology")
                .child(`${institucion.id_institucion}`)
                .child("covid19")
                .child("reports")
                .child(item.id)
                .child("detalle")
                .set(item.detalle);
            });
            firebase
              .database()
              .ref()
              .child("epidemiology")
              .child(`${institucion.id_institucion}`)
              .child("covid19")
              .child("lastUpdate")
              .set(moment().format("YYYY-MM-DD HH:mm:ss"));
          }
        }
      }
    );
  }
  componentDidUpdate() {
    if (!this.props.formActual) {
      // this.props.setState({modulo:'ins_pacientes', state: {modulo:'ins_pacientes', catalogo: this.props.catalogo, registro: this.state.patientSelected || {<}}})
    }
  }
  componentWillUnmount() {
    // this.props.resetHelperSidebarRender();
    this.removeObserver();
    // window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  // ------------------------
  // ------  methods --------
  // ------------------------
  onSave(sn_peticion) {
    const { editPatient } = this.state;
    const errorMessages = repository.validationBody(
      editPatient,
      this.props.catalogo,
      this.props.catalogos
    );
    if (
      errorMessages.noNulls.length === 0 &&
      errorMessages.noSuccess.length === 0
    ) {
      if (this.state.addressData) {
        const domicilios = this.state.addressData.map((address) => {
          return {
            ...address,
            sn_drenaje: parseInt(address.sn_drenaje, 10),
            sn_agua: parseInt(address.sn_agua, 10),
            sn_energia: parseInt(address.sn_energia, 10),
            sn_zoonosis: parseInt(address.sn_zoonosis, 10),
            id_domicilio: 1,
            id_codigoPostal: 1,
            id_calle: 1,
            id_asentamiento: 1,
            vc_domicilioInterior: "",
            nu_latitud: 0.000001,
            nu_altitud: -10.0,
            nu_longitud: 10.1,
            sn_confirmado: 1,
          };
        });
      }

      const body = {
        ...editPatient.detalle,
        id: editPatient.detalle.id || null,
        id_institucion: window.amplify.store("institution").id_institucion,
        id_usuario: window.amplify.store("user_id"),
        domicilios: [],
      };

      if (sn_peticion) {
        body.sn_enlace = 1;
      }
      this.setState({ loadingOn: "Guardando Paciente..." });
      const api = body.id ? internalAPI.editPatients : internalAPI.savePatient;
      api(body, (error, response) => {
        if (error) {
          this.setState({
            error: error.texto,
            success: false,
            loadingOn: null,
            editPatient,
            successMessage: {
              title: "Ups!",
              message: error.texto || "Verifica tu conexión a internet.",
            },
          });
        } else if (response.peticion === true) {
          let isNew = true;
          let data = this.state.data.map((paciente, index) => {
            if (paciente.id === body.id) {
              isNew = false;
              return response.paciente;
            } else {
              return paciente;
            }
          });
          if (isNew) {
            data.push(response.paciente);
          }
          this.setState(
            {
              error: "",
              success: true,
              addAddress: false,
              dependentPatient: body,
              loadingOn: null,
              patientSelected: response.paciente,
              patientEdit: null,
              indexSelected: 0,
              data: [response.paciente],
              moduloActual: "listado",
              successMessage: {
                title: body.id
                  ? "El paciente se editó correctamente"
                  : "El paciente se guardó correctamente",
              },
            },
            () => {
              this.updateHelperSidebar();

              // this.props.setDisplaySidebar(true)
              this.props.saveEditPatient &&
                this.props.saveEditPatient(response.paciente);
            }
          );
        } else if (response.peticion === false) {
          this.props.setState({
            modulo: "notifications",
            state: {
              title: `Paciente ${response.paciente.vc_nombre} ¡Ya es parte de Thin-k!`,
              message: response.paciente.vc_email
                ? `¿Desea enlazar la cuenta con el correo: ${response.paciente.vc_email}?`
                : `Se enlazará la cuenta al CURP: ${response.paciente.vc_curp}`,
              onCancel: () => {
                this.props.setState({
                  modulo: "notifications",
                  state: null,
                  loadingOn: null,
                });
                this.setState({ success: false, loadingOn: null });
              },
              onAccept: () => {
                this.props.setState({
                  modulo: "notifications",
                  state: null,
                  loadingOn: null,
                });
                this.setState({ success: false, loadingOn: null });
                this.onSave(true);
              },
            },
          });
          this.props.saveEditPatient &&
            this.props.saveEditPatient(response.paciente);
        } else {
          this.setState({
            error: "Ocurrio un error al guardar paciente",
            success: false,
            loadingOn: null,
            errorMessages: { title: "¡Ups!", message: response.error },
          });
        }
      });
    } else {
      this.setState({
        error: "faltan campos requeridos",
        loadingOn: null,
        editPatient,
        errorMessages,
      });
    }
  }

  onChangeModule(modulo, opcionActualModulo) {
    const moduloActual = modulo.id;
    this.props.setViewTitle(modulo.vc_nombre);

    switch (modulo.id) {
      case "listado":
        this.setState(
          {
            moduloActual,
            selectedRow: null,
            indexSelected: null,
            patientSelected: { detalle: {} },
          },
          () => {
            this.props.setDisplaySidebar(false);
            this.updateHelperSidebar();
            this.getPatients();
          }
        );
        break;

      case "registrar":
        this.setState(
          {
            moduloActual,
            opcionActualModuloPaciente: "vc_apellidoPaterno",
            opcionActualModulo: "nuevo",
            patientSelected: { detalle: {} },
          },
          () => {
            this.props.setDisplaySidebar(false);
            this.props.resetHelperSidebarRender();
          }
        );
        break;
      case "callcenter":
        this.setState(
          {
            moduloActual,
            opcionActualModulo:
              opcionActualModulo || modulo.opciones[0] || null,
            selectedRow: null,
            indexSelected: null,
            patientSelected: { detalle: {} },
          },
          () => {
            this.props.setDisplaySidebar(false);
            this.updateHelperSidebar();
          }
        );
        break;
    }
  }

  setMenuOptions() {
    const institucion = window.amplify.store("institution") || {};
    const options = menuPacientes
      .filter((e) => e.id !== "callcenter" || institucion.id_institucion === 2)
      .map((option) => ({
        ...option,
        onClick: this.onChangeModule.bind(this),
      }));
    this.props.setSubMenuOptions(options);
  }

  getPatients() {
    const body = {
      id_institucion: window.amplify.store("institution").id_institucion,
      texto: "",
    };
    this.setState({ loading: true });
    internalAPI.searchPatient(body, (error, response) => {
      this.setState({ loading: false });
      if (error) {
        notifications.error(error.error || "Verifica tu conexión a internet");
      } else {
        const data = response.map((item) => ({
          sexo: item.id_sexo === 1 ? "Masculino" : "Femenino",
          ...item,
        }));
        this.setState({ data });
      }
    });
  }

  getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  editPatient(rowData) {
    this.setState(
      {
        patientSelected: { id: rowData.id, detalle: rowData },
        moduloActual: "registrar",
        opcionActualModulo: "editar",
      },
      () => {
        this.updateHelperSidebar();
      }
    );
  }

  updateDimensions() {
    // this.forceUpdate();
  }

  updateHelperSidebar() {
    !this.props.notRefreshSidebar &&
      this.props.setHelperSidebarRender(this.renderHelperSidebar.bind(this));
  }

  selectedRow(selectedRow, indexSelected) {
    if (indexSelected === this.state.indexSelected) {
      this.setState({ selectedRow: null, indexSelected: null }, () => {
        this.updateHelperSidebar();
        this.props.setDisplaySidebar(false);
      });
    } else {
      this.setState({ selectedRow, indexSelected }, () => {
        this.updateHelperSidebar();
        this.props.setDisplaySidebar(true);
      });
    }
  }
  searchPatientCallcenter(event) {
    this.setState(
      {
        searchText: event.target.value,
        selectedRow: null,
        indexSelected: null,
      },
      () => {
        this.updateHelperSidebar();
        this.props.setDisplaySidebar(false);
      }
    );
  }
  searchPatient(event) {
    const body = {
      id_institucion: window.amplify.store("institution").id_institucion,
      texto: event.target.value,
    };

    this.setState({ selectedRow: null, indexSelected: null }, () => {
      // this.updateHelperSidebar();
      this.props.setDisplaySidebar(false);
    });

    // if (event.target.value) {
    internalAPI.searchPatient(body, (error, response) => {
      if (error) {
        notifications.error(error.error || "Verifica tu conexión a internet");
      } else {
        this.setState({ data: response });
      }
    });
    // }
  }

  consultarFuncion() {
    if (this.state.selectedRow) {
      const id_paciente = this.state.selectedRow.id;
      this.setState(
        { loadingOn: "Cargando Expediente del Paciente..." },
        () => {
          if (window.isMobile) {
            this.props.setDisplaySidebar(false);
          }
        }
      );

      internalAPI.getExpediente(
        {
          id_paciente,
          id_rol: 5,
          sn_pacienteRespuestas: true,
          sn_diagnosticos: true,
          sn_procedimientos: true,
          sn_medicamentos: true,
          sn_solicitudes: true,
          sn_respuestas: true,
          sn_signosAlarma: true,
          sn_atenciones: true,
          sn_notas: true,
          id_tipoNota: 4,
        },
        (err, response) => {
          if (err) {
            this.setState({ loadingOn: null });
            if (err.texto) {
              notifications.error(err.texto);
              logOut();
            }
          } else {
            const preguntasNumericas = [];
            const preguntasTexto = [];
            const triageCOVID19 = this.props.preguntas.find(
              (e) => e.id === 364
            );
            if (triageCOVID19) preguntasTexto.push(triageCOVID19);
            response.data.preguntas.forEach((item) => {
              if (
                item.id_tipoPregunta === 4 &&
                (item.id_sexo === 3 ||
                  response.data.paciente.id_sexo === item.id_sexo)
              ) {
                preguntasTexto.push(item);
              } else if (
                item.id_sexo === 3 ||
                response.data.paciente.id_sexo === item.id_sexo
              ) {
                preguntasNumericas.push(item);
              }
            });

            const paciente = {
              detalle: response.data.paciente,
              citaActual: this.state.meetingSelected
                ? this.state.meetingSelected
                : null,
              tratamientosPaciente: response.data.tratamientos,
              medicamentosPaciente: response.data.medicamentos,
              diagnosticosPaciente: response.data.diagnosticos,
              procedimientosPaciente: response.data.procedimientos,
              solicitudes: response.data.solicitudes,
              signosAlarma: response.data.signosAlarma,
              pacienteRespuestas: response.data.pacientesRespuestas,
              atenciones: response.data.atenciones,
              notas: response.data.notas,
              preguntasNumericas,
              preguntasTexto,
            };
            this.setState({ loadingOn: null }, () => {
              this.props.setPacienteActual(paciente);
              eventManager.emit(START_VIEW_TRANSITION, `#/admin/notas`);
            });
          }
        }
      );
    }
  }
  removeObserver() {
    const institucion = window.amplify.store("institution") || {};
    if (institucion.id_institucion) {
      firebase
        .database()
        .ref()
        .child("epidemiology")
        .child(`${institucion.id_institucion}`)
        .child("covid19")
        .child("reports")
        .off();
      firebase
        .database()
        .ref()
        .child("epidemiology")
        .child(`${institucion.id_institucion}`)
        .child("covid19")
        .child("lastUpdate")
        .off();
    }
  }
  getPatientsCallcenter() {
    const institucion = window.amplify.store("institution") || {};
    if (institucion.id_institucion) {
      firebase
        .database()
        .ref()
        .child("epidemiology")
        .child(`${institucion.id_institucion}`)
        .child("covid19")
        .child("reports")
        .on("value", (snapshot) => {
          if (snapshot.val() != null) {
            const value = snapshot.val();
            const values = Object.values(snapshot.val());
            const keys = Object.keys(snapshot.val());
            const patientsCallcenter = values.map((value, index) => {
              return {
                ...value,
                id: keys[index],
              };
            });
            this.setState({ patientsCallcenter });
          } else {
            this.setState({ patientsCallcenter: [] });
          }
        });
      firebase
        .database()
        .ref()
        .child("epidemiology")
        .child(`${institucion.id_institucion}`)
        .child("covid19")
        .child("lastUpdate")
        .on("value", (snapshot) => {
          if (snapshot.val() != null) {
            this.setState({ lastUpdate: snapshot.val() }, () => {
              this.getPacientesCallback();
            });
          } else {
            this.setState({ lastUpdate: "2020-03-31" }, () => {
              this.getPacientesCallback();
            });
          }
        });
    }
  }
  initContactCovid(id_preguntaBaseInput) {
    const preguntasNumericas = [];
    const preguntasTexto = [];
    let id_preguntaBase =
      this.state.selectedRow.id_statusSeguimiento === 1 ? 374 : 376;
    if (id_preguntaBaseInput) {
      id_preguntaBase = id_preguntaBaseInput;
    }
    const triageCOVID19 = this.props.preguntas.find(
      (e) => e.id === id_preguntaBase
    );
    const paciente = {
      id: this.state.selectedRow.id,
      detalle: this.state.selectedRow.detalle,
      citaActual: null,
      medicamentosPaciente: [],
      diagnosticosPaciente: this.state.selectedRow.diagnosticosPaciente || [],
      procedimientosPaciente: [],
      solicitudes: [],
      signosAlarma: [],
      pacienteRespuestas: this.state.selectedRow.pacienteRespuestas || [],
      atenciones: this.state.selectedRow.atenciones || [],
      notas: this.state.selectedRow.notas || [],
      preguntasNumericas,
      preguntasTexto,
    };
    if (triageCOVID19) {
      triageCOVID19.respuestasInternas = [
        {
          id_pregunta: 101,
          vc_sentencia: repository.displaySummaryPatient(paciente, {
            medicamentosDgis: this.props.medicamentosDgis,
            diagnosticos: this.props.diagnosticos,
          }),
        },
      ];
      paciente.preguntasTexto.push(triageCOVID19);
    }
    this.props.setPacienteActual(paciente);
    this.setState({ showTriageCOVID19: true }, () => {
      this.props.setDisplaySidebar(false);
    });
  }
  triageFuncion(id_preguntaBaseDefault) {
    if (this.state.selectedRow) {
      const id_paciente = this.state.selectedRow.id;
      this.setState(
        { loadingOn: "Cargando Expediente del Paciente..." },
        () => {
          if (window.isMobile) {
            this.props.setDisplaySidebar(false);
          }
        }
      );

      internalAPI.getExpediente(
        {
          id_paciente,
          id_rol: 5,
          sn_pacienteRespuestas: true,
          sn_diagnosticos: true,
          sn_procedimientos: true,
          sn_medicamentos: true,
          sn_solicitudes: true,
          sn_respuestas: true,
          sn_signosAlarma: true,
          sn_atenciones: true,
          sn_notas: true,
          id_tipoNota: 4,
        },
        (err, response) => {
          this.setState({ loadingOn: null });
          if (err) {
            if (err.texto) {
              notifications.error(err.texto);
              logOut();
            }
          } else {
            const preguntasNumericas = [];
            const id_preguntaBase = id_preguntaBaseDefault || 364;
            const triageCOVID19 = this.props.preguntas.find(
              (e) => e.id === id_preguntaBase
            );
            // const triageCOVID19 = this.props.preguntas.find(e => e.id === 402);
            const triageCOVID19Questions = this.props.preguntas
              .filter((e) => e.id >= 377)
              .map((e) => e.id);

            const paciente = {
              detalle: response.data.paciente,
              citaActual: this.state.meetingSelected
                ? this.state.meetingSelected
                : null,
              medicamentosPaciente: response.data.medicamentos,
              diagnosticosPaciente: response.data.diagnosticos,
              procedimientosPaciente: response.data.procedimientos,
              solicitudes: response.data.solicitudes,
              signosAlarma: response.data.signosAlarma,
              pacienteRespuestas: response.data.pacientesRespuestas,
              atenciones: response.data.atenciones,
              notas: response.data.notas,
              preguntasNumericas,
              preguntasTexto: [],
            };

            if (triageCOVID19) {
              triageCOVID19.respuestasInternas = [
                {
                  id_pregunta: 101,
                  vc_sentencia: repository.displaySummaryPatient(paciente, {
                    medicamentosDgis: this.props.medicamentosDgis,
                    diagnosticos: this.props.diagnosticos,
                  }),
                },
              ];
              paciente.preguntasTexto.push(triageCOVID19);
            }
            this.props.setPacienteActual(paciente);
            this.setState({
              showTriageCOVID19: true,
              preguntasEnlace: triageCOVID19Questions,
            });
          }
        }
      );
    }
  }

  iniciarEstudios(estudios) {
    if (this.state.selectedRow) {
      const id_paciente = this.state.selectedRow.id;
      this.setState({ loadingOn: "Cargando Expediente del Paciente..." });

      internalAPI.getExpediente(
        {
          id_paciente,
          id_rol: 5,
          sn_pacienteRespuestas: true,
          sn_diagnosticos: true,
          sn_procedimientos: true,
          sn_medicamentos: true,
          sn_solicitudes: true,
          sn_respuestas: true,
          sn_signosAlarma: true,
          sn_atenciones: true,
          id_tipoNota: null,
          ids_estudios: estudios,
        },
        (err, response) => {
          this.setState({ loadingOn: null });
          if (err) {
            if (err.texto) {
              notifications.error(err.texto);
              // logOut();
            }
          } else {
            const preguntasNumericas = [];
            const preguntasTexto = [];

            response.data.preguntas.forEach((item) => {
              if (
                item.id_tipoPregunta === 4 &&
                (item.id_sexo === 3 ||
                  response.data.paciente.id_sexo === item.id_sexo)
              ) {
                preguntasTexto.push(item);
              } else if (
                item.id_sexo === 3 ||
                response.data.paciente.id_sexo === item.id_sexo
              ) {
                preguntasNumericas.push(item);
              }
            });

            const paciente = {
              detalle: response.data.paciente,
              citaActual: this.state.meetingSelected
                ? this.state.meetingSelected
                : null,
              medicamentos: response.data.medicamentos,
              diagnosticos: response.data.diagnosticos,
              solicitudes: response.data.solicitudes,
              signosAlarma: response.data.signosAlarma,
              pacienteRespuestas: response.data.pacientesRespuestas,
              atenciones: response.data.atenciones,
              preguntasNumericas,
              preguntasTexto,
            };

            this.props.setPacienteActual(paciente);
            eventManager.emit(START_VIEW_TRANSITION, `#/admin/estudios`);
          }
        }
      );
    }
  }

  // ------------------------------
  // ------ render methods --------
  // ------------------------------
  changeValueAutoform(value, name) {
    let patientSelected = this.state.patientSelected;
    switch (name) {
      case "vc_apellidoPaterno":
        patientSelected.detalle.vc_apellidoPaterno = value;
        this.setState({ patientSelected }, () => {
          this.updateHelperSidebar();
        });
        break;
      case "vc_apellidoMaterno":
        patientSelected.detalle.vc_apellidoMaterno = value;
        this.setState({ patientSelected }, () => {
          this.updateHelperSidebar();
        });
        break;
      case "vc_nombre":
        patientSelected.detalle.vc_nombre = value;
        this.setState({ patientSelected }, () => {
          this.updateHelperSidebar();
        });
        break;
      case "dt_nacimiento":
        patientSelected.detalle.dt_nacimiento = value;
        this.setState({ patientSelected }, () => {
          this.updateHelperSidebar();
        });
        break;
    }
  }

  timeSince(refDate, date) {
    var seconds = Math.floor((refDate - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " años";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " meses";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " días";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " horas";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutos";
    }
    return "un instante";
  }

  successRatio(paciente) {
    const itemsRequired = this.state.fields.filter(
      (item) => item.require === true
    );
    let completed = 0;
    if (!paciente || !paciente.detalle) {
      return 0;
    }
    itemsRequired.forEach((item) => {
      if (
        paciente.detalle[item.key] !== null &&
        paciente.detalle[item.key] !== undefined
      ) {
        completed++;
      }
    });

    const ratio = (completed / itemsRequired.length) * 100;
    return parseInt(ratio, 10);
  }

  clickAccion(accion) {
    switch (this.state.moduloActual) {
      case "listado":
        let accionString = "nuevo";
        if (accion.id === "editar") {
          accionString = "editar";
        }
        this.setState(
          {
            patientSelected: { detalle: {} },
            moduloActual: "registrar",
            opcionActualModulo: accionString,
            opcionActualModuloPaciente: "vc_apellidoPaterno",
          },
          () => {
            this.updateHelperSidebar();
          }
        );
        break;

      case "registrar":
        switch (accion.id) {
          case "limpiar":
            this.setState(
              {
                moduloActual: this.state.moduloActual,
                opcionActualModulo: this.state.opcionActualModulo,
                opcionActualModuloPaciente: "vc_apellidoPaterno",
                patientSelected: { detalle: {} },
              },
              () => this.updateHelperSidebar()
            );
            break;
          case "guardar":
            this.setState(
              { editPatient: this.state.functions.getRegistro() },
              () => {
                this.onSave(false);
                this.updateHelperSidebar();
              }
            );
            break;
          default:
            break;
        }
        break;
      case "callcenter": {
        switch (accion.id) {
          case "filtrar":
            this.setState({ showSetFilters: true }, () =>
              this.updateHelperSidebar()
            );
            break;
          case "mapear":
            this.setState({ showMapCases: true }, () =>
              this.updateHelperSidebar()
            );
            break;
          case "stats":
            this.setState({ showStats: true }, () =>
              this.updateHelperSidebar()
            );
            break;
          default:
            break;
        }
        break;
      }
      default:
        break;
    }
  }

  // -----------------------------
  // ------ render methods -------
  // -----------------------------
  editSelectRow(dataEdit) {
    if (this.state.selectedRow) {
      let selectedRow = null;
      const data = this.state.data.map((item, index) => {
        if (item.id === this.state.selectedRow.id) {
          selectedRow = {
            ...item,
            ...dataEdit,
          };
          return { ...selectedRow };
        } else {
          return item;
        }
      });
      this.setState(
        {
          data,
          selectedRow,
        },
        () => {
          this.updateHelperSidebar();
        }
      );
    }
  }

  renderHelperSidebar() {
    let avatar = "assets/images/hombre.png";
    if (this.state.selectedRow?.id_sexo === 2)
      avatar = "assets/images/mujer.png";
    if (this.state.selectedRow?.vc_url) avatar = this.state.selectedRow.vc_url;

    switch (this.state.moduloActual) {
      case "listado":
        if (
          this.state.selectedRow != null &&
          !this.state.estudiosSelectorModal
        ) {
          const sexos = [
            "No especificado",
            "Masculino",
            "Femenino",
            "Indistinto",
          ];

          return (
            <Paper
              style={{
                padding: 0,
                border: 0,
                borderRadius: 0,
                height: window.screen.availHeight,
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  WebkitOverflowScrolling: "touch",
                  padding: 0,
                  margin: 0,
                  paddingTop: 84,
                }}
              >
                <Grid
                  centered
                  style={{
                    width: "100%",
                    padding: 4,
                    paddingBottom: 0,
                    margin: 0,
                  }}
                >
                  <Paper
                    style={{
                      width: "100%",
                      padding: 0,
                      margin: 0,
                      paddingTop: 15,
                    }}
                  >
                    <Box style={{ position: "relative", padding: 30 }}>
                      <Image
                        style={{
                          borderRadius: 5,
                          overflow: "hidden",
                          marginBottom: 10,
                          // height: "15vw",
                          width: "75%",
                        }}
                        size="small"
                        centered
                        src={avatar}
                      />
                      <div
                        style={{
                          position: "absolute",
                          right: "calc(5vw - 20px)",
                          top: 10,
                        }}
                      >
                        <UploadPicture
                          id_paciente={this.state.selectedRow.id}
                          editPatient={this.editSelectRow.bind(this)}
                        />
                      </div>
                    </Box>
                    <Card.Content>
                      <Card.Header>
                        {this.state.selectedRow.vc_nombre}{" "}
                        {this.state.selectedRow.vc_apellidoPaterno}{" "}
                        {this.state.selectedRow.vc_apellidoMaterno}
                      </Card.Header>
                      <Card.Description>
                        Sexo: {sexos[this.state.selectedRow.id_sexo]}
                      </Card.Description>
                      <Card.Description>
                        Edad:{" "}
                        {this.getAge(this.state.selectedRow.dt_nacimiento)}
                      </Card.Description>
                      <Card.Description>
                        Teléfono: {this.state.selectedRow.vc_telefono}
                      </Card.Description>
                      <Card.Description>
                        email: {this.state.selectedRow.vc_email}
                      </Card.Description>
                      <Card.Description>
                        CURP: {this.state.selectedRow.vc_curp}
                      </Card.Description>
                    </Card.Content>
                  </Paper>
                </Grid>
                <Row style={{ flexDirection: "column" }}>
                  {repository.validateOptionsRol && (
                    <Button
                      key={"consultar"}
                      disabled={
                        !(
                          this.props.preguntas.length &&
                          this.props.diagnosticos.length
                        )
                      }
                      variant="outlined"
                      color="primary"
                      onClick={this.consultarFuncion.bind(this)}
                      style={{
                        width: "calc(100% - 6px)",
                        margin: 3,
                        marginBottom: 0,
                      }}
                    >
                      Consultar
                    </Button>
                  )}
                  {repository.validateOptionsRol("cancelar cita") && (
                    <Button
                      key={"cancelar cita"}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        this.props.setActiveView("registrar");
                        this.setState(
                          {
                            patientSelected: this.state.selectedRow,
                            moduloActual: "registrar",
                            opcionActualModulo: "editar",
                          },
                          () => this.props.setDisplaySidebar(false)
                        );
                      }}
                      style={{
                        width: "calc(100% - 6px)",
                        margin: 3,
                        marginBottom: 0,
                      }}
                    >
                      Editar
                    </Button>
                  )}
                  {repository.validateOptionsRol("formularios") && (
                    <Button
                      key={"formularios"}
                      variant="outlined"
                      disabled
                      color="primary"
                      style={{
                        width: "calc(100% - 6px)",
                        margin: 3,
                        marginBottom: 0,
                      }}
                    >
                      Formularios
                    </Button>
                  )}
                  {repository.validateOptionsRol("covid19") && (
                    <Button
                      key={"covid"}
                      onClick={() => this.triageFuncion(364)}
                      variant="outlined"
                      color="primary"
                      style={{
                        width: "calc(100% - 6px)",
                        margin: 3,
                        marginBottom: 0,
                      }}
                    >
                      Notificación
                    </Button>
                  )}
                  {repository.validateOptionsRol("covid19") && (
                    <Button
                      key={"evolucion"}
                      onClick={() => this.triageFuncion(402)}
                      variant="outlined"
                      color="primary"
                      style={{
                        width: "calc(100% - 6px)",
                        margin: 3,
                        marginBottom: 0,
                      }}
                    >
                      Evolución
                    </Button>
                  )}
                </Row>
              </div>
              <AppBar position="absolute" style={{ height: 95, zIndex: 10 }}>
                <Toolbar>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      flexDirection: "column",
                      verticalAlign: "center",
                      justifyItems: "center",
                    }}
                  >
                    Paciente
                  </Typography>
                </Toolbar>
              </AppBar>
            </Paper>
          );
        }
        return null;
        return (
          <Paper
            style={{
              padding: 0,
              border: 0,
              borderRadius: 0,
              height: window.screen.availHeight,
            }}
          >
            <button
              style={{
                width: "100%",
                padding: 0,
                border: 0,
                height: 74,
                textAlign: "center",
                color: "white",
                backgroundColor: window.colors.menu,
              }}
            >
              Seleccione un paciente:
            </button>
            <div style={styles.titleHelp}>
              <Input
                value={this.state.searchText}
                focus
                placeholder="Especifique..."
                style={{ width: "100%", height: 31 }}
              />
            </div>
          </Paper>
        );

      case "registrar":
        return null;
      case "callcenter":
        if (
          this.state.selectedRow != null &&
          !this.state.estudiosSelectorModal
        ) {
          return (
            <Paper
              style={{
                padding: 0,
                border: 0,
                borderRadius: 0,
                height: window.screen.availHeight,
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  WebkitOverflowScrolling: "touch",
                  padding: 0,
                  margin: 0,
                  paddingTop: 84,
                }}
              >
                <Grid
                  centered
                  style={{
                    width: "100%",
                    padding: 4,
                    paddingBottom: 0,
                    margin: 0,
                  }}
                >
                  <Paper
                    style={{
                      width: "100%",
                      padding: 0,
                      margin: 0,
                      paddingTop: 15,
                    }}
                  >
                    <Image
                      style={{
                        borderRadius: 5,
                        overflow: "hidden",
                        marginBottom: 10,
                        height: "15vw",
                        width: "15vw",
                      }}
                      size="small"
                      centered
                      src={avatar}
                    />
                    <Card.Content>
                      <Card.Header>
                        {this.state.selectedRow.detalle.vc_nombre}
                      </Card.Header>
                      <Card.Header>
                        {this.state.selectedRow.detalle.vc_apellidoPaterno}
                      </Card.Header>
                      <Card.Header>
                        {this.state.selectedRow.detalle.vc_apellidoMaterno}
                      </Card.Header>
                      <Card.Description>
                        Sexo:{" "}
                        {this.state.selectedRow.detalle.id_sexo === 1
                          ? "Masculino"
                          : "Femenino"}
                      </Card.Description>
                      <Card.Description>
                        Edad:{" "}
                        {this.state.selectedRow.detalle.dt_nacimiento
                          ? this.getAge(
                              this.state.selectedRow.detalle.dt_nacimiento
                            )
                          : this.state.selectedRow.detalle.nu_edad}
                      </Card.Description>
                      <Card.Description>
                        Teléfono: {this.state.selectedRow.detalle.vc_telefono}
                      </Card.Description>
                      <Card.Description>
                        email: {this.state.selectedRow.detalle.vc_email}
                      </Card.Description>
                      <Card.Description>
                        CURP: {this.state.selectedRow.detalle.vc_curp}
                      </Card.Description>
                      <Card.Description>
                        Clasificación de COVID-19:{" "}
                        {this.state.selectedRow.detalle.id_status
                          ? (
                              this.state.statusCovid.find(
                                (e) =>
                                  e.id ===
                                  this.state.selectedRow.detalle.id_status
                              ) || {}
                            ).vc_nombre || "No especificado"
                          : ""}
                      </Card.Description>
                      <Card.Description>Registro de Llamada:</Card.Description>
                      <Card.Description>
                        {moment(
                          this.state.selectedRow.detalle.dt_registro
                        ).format("dddd D MMMM YYYY hh:mm A") || ""}
                      </Card.Description>
                    </Card.Content>
                  </Paper>
                </Grid>
                <Row style={{ flexDirection: "column" }}>
                  {repository.validateOptionsRol("formularios") && (
                    <Button
                      key={"formularios"}
                      variant="outlined"
                      disabled
                      color="primary"
                      style={{
                        width: "calc(100% - 6px)",
                        margin: 3,
                        marginBottom: 0,
                      }}
                    >
                      Formularios
                    </Button>
                  )}
                  {/* {
                    repository.validateOptionsRol('covid19') &&
                    <Button key={'covid'} onClick={this.triageFuncion.bind(this)} variant="outlined" color='primary' style={{ width: "calc(100% - 6px)", margin: 3, marginBottom: 0 }}>Triage COVID-19</Button>
                  } */}
                  {this.state.selectedRow.detalle.id_statusSeguimiento ===
                    1 && (
                    <Button
                      key={"covid"}
                      onClick={this.initContactCovid.bind(this)}
                      variant="outlined"
                      color="primary"
                      style={{
                        width: "calc(100% - 6px)",
                        margin: 3,
                        marginBottom: 0,
                      }}
                    >
                      Contacto Inicial COVID-19
                    </Button>
                  )}
                  {this.state.selectedRow.detalle.id_statusSeguimiento ===
                    2 && (
                    <Button
                      key={"covid"}
                      onClick={this.initContactCovid.bind(this)}
                      variant="outlined"
                      color="primary"
                      style={{
                        width: "calc(100% - 6px)",
                        margin: 3,
                        marginBottom: 0,
                      }}
                    >
                      Seguimiento COVID-19
                    </Button>
                  )}
                </Row>
              </div>
              <AppBar position="absolute" style={{ height: 95, zIndex: 10 }}>
                <Toolbar>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      flexDirection: "column",
                      verticalAlign: "center",
                      justifyItems: "center",
                    }}
                  >
                    Paciente
                  </Typography>
                </Toolbar>
              </AppBar>
            </Paper>
          );
        }

      default:
        break;
    }

    return null;
  }

  renderModalNewEditPtient() {
    if (this.state.moduloActual === "registrar") {
      return (
        <div
          style={{
            margin: 0,
            backgroundColor: window.colors.white,
            borderRadius: 5,
          }}
        >
          <NewPatientModal
            opcionPaciente={this.state.opcionActualModuloPaciente}
            patientSelected={
              this.state.patientSelected == null
                ? { detalle: {} }
                : this.state.patientSelected
            }
            style={{ backgroundColor: window.colors.white, width: "100%" }}
            callbackSelectInput={({ opcionesModuloNext }) => {
              this.setState(
                {
                  moduloActual: "registrar",
                  patientSelected: this.state.patientSelected,
                  opcionActualModuloPaciente: opcionesModuloNext,
                },
                () => {
                  this.updateHelperSidebar();
                }
              );
            }}
            callbackChange={({ value, name }) => {
              this.changeValueAutoform(value, name);
            }}
            callbackRefresh={({ patientSelected, opcionesModuloNext }) => {
              this.setState(
                {
                  moduloActual: "registrar",
                  patientSelected,
                  opcionActualModuloPaciente: opcionesModuloNext,
                },
                () => {
                  this.updateHelperSidebar();
                }
              );
            }}
          />
        </div>
      );
    }

    return null;
  }
  // filtersOptions: [
  //   { id: 1, vc_nombre: 'Solicitud de Información', vc_abreviatura: 'Informados', vc_descripcion: 'Es el ciudadano que se comunica por informacion referente a covid per ono presenta sintomatologia compatible' },
  //   { id: 2, vc_nombre: 'Paciente (no sospechoso)', vc_abreviatura: 'Descartados', vc_descripcion: 'Es el paciente que se comunica por informacion referente a covid y además presenta sintomatologia sin embargo esta no cumple con definicion operacional de caso para covid.' },
  //   { id: 3, vc_nombre: 'Paciente (no sospechoso) posiblemente grave', vc_abreviatura: 'Otros Graves', vc_descripcion: 'Es el paciente que se comunica por informacion referente a covid, no presenta sintomatologia compatible con definicion operacional de caso para covid-19, pero si datos de gravedad.' },
  //   { id: 4, vc_nombre: 'Paciente tipo ETI (sospechoso)', vc_abreviatura: 'ETI', vc_descripcion: 'Es el paciente que se comunica por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso' },
  //   { id: 5, vc_nombre: 'Paciente tipo IRAG (sospechoso grave) ', vc_abreviatura: 'IRAG', vc_descripcion: 'Es el paciente que se comunica por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso, y ademas signos de gravedad.' },
  //   { id: 6, vc_nombre: 'Paciente Confirmado', vc_abreviatura: 'Positivos', vc_descripcion: 'Es el paciente que se subio a la plataforma por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso' },
  //   { id: 7, vc_nombre: 'Paciente Negativo', vc_abreviatura: 'Negativos', vc_descripcion: 'Es el paciente que se subio a la plataforma por informacion referente a covid y además tiene resultado negativo para covid' },
  //   { id: 8, vc_nombre: 'Paciente Muerto', vc_abreviatura: 'Fallecidos', vc_descripcion: 'Es el paciente que se subio a la plataforma y fallecio. por informacion referente a covid y además presenta sintomatologia compatible con definicion operacional de caso' },
  // ],

  validateFiltersPlatform() {
    let casesPlatform = this.state.casesPlatform;
    let cases = [];
    this.state.filtersPlatformUnSelected.map((filter, index) => {
      let filterObj = (
        this.state.filtersPlatform.find((e) => e.id === filter.id_filter) || {}
      ).opciones.find((e) => e.id === filter.id_option);
      if (filterObj) {
      }
    });
    if (!this.state.filtersCases.find((e) => e.id === 10)) {
      return [];
    }
    if (!this.state.filtersCases.find((e) => e.id === 9)) {
      casesPlatform = casesPlatform.filter(
        (e) => !e.CVE_RES_LESP && e.CVE_RES_LESP !== undefined
      );
    }
    if (!this.state.filtersCases.find((e) => e.id === 8)) {
      casesPlatform = casesPlatform.filter((e) => !e.dt_dead);
    }
    if (!this.state.filtersCases.find((e) => e.id === 7)) {
      casesPlatform = casesPlatform.filter((e) => e.CVE_RES_LESP != 8);
    }
    if (!this.state.filtersCases.find((e) => e.id === 6)) {
      casesPlatform = casesPlatform.filter((e) => e.CVE_RES_LESP != 30);
    }
    return casesPlatform;
  }
  renderMap() {
    const points = [];
    if (this.state.showMapCases) {
      this.filtersApply().map((patient, index) => {
        const pointRespuestas = (patient.pacienteRespuestas || []).filter(
          (e) => e.nu_lat && e.nu_lon
        );
        if (pointRespuestas.length)
          points.push({ ...pointRespuestas.reverse()[0], patient });
      });
    }
    return (
      <ModalUI
        open={this.state.showMapCases}
        onClose={() => this.setState({ showMapCases: false })}
        style={{
          boxShadow: "0px 0px 0px transparent",
          backgroundColor: "transparent",
          margin: window.isMobile ? 50 : 100,
          overflow: "hidden",
        }}
      >
        <Paper
          style={{
            padding: 0,
            borderRadius: 20,
            overflow: "hidden",
            margin: 50,
            marginLeft: window.isMobile ? 50 : 100,
            outline: "none",
            flexDirection: "row",
            height: "calc(100% - 100px)",
          }}
        >
          <Box display={"flex"} padding={2} justifyContent="space-between">
            {this.state.filtersOptions.map((status, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Switch
                      size="small"
                      checked={
                        !!this.state.filtersCases.find(
                          (e) => e.id === status.id
                        )
                      }
                      onChange={() => {
                        if (
                          this.state.filtersCases.find(
                            (e) => e.id === status.id
                          )
                        ) {
                          const filtersCases = this.state.filtersCases.filter(
                            (e) => e.id !== status.id
                          );
                          this.setState({ filtersCases });
                        } else {
                          const filtersCases = this.state.filtersCases;
                          filtersCases.push(status);
                          this.setState({ filtersCases });
                        }
                      }}
                      name={`${status.id}`}
                      color="primary"
                    />
                  }
                  label={status.vc_abreviatura}
                />
              );
            })}
          </Box>
          <GoogleMaps points={points} cases={this.validateFiltersPlatform()} />
        </Paper>
      </ModalUI>
    );
  }
  renderStats() {
    const points = [];
    if (this.state.showMapCases) {
      this.filtersApply().map((patient, index) => {
        const pointRespuestas = (patient.pacienteRespuestas || []).filter(
          (e) => e.nu_lat && e.nu_lon
        );
        if (pointRespuestas.length)
          points.push({ ...pointRespuestas.reverse()[0], patient });
      });
    }
    return (
      <ModalUI
        open={this.state.showStats}
        onClose={() => this.setState({ showStats: false })}
        style={{
          boxShadow: "0px 0px 0px transparent",
          backgroundColor: "transparent",
          alignContent: "center",
          position: "relative",
          height: "100%",
        }}
      >
        <Paper
          style={{
            padding: 0,
            borderRadius: 20,
            overflow: "hidden",
            margin: 50,
            marginLeft: window.isMobile ? 50 : 100,
            outline: "none",
            flexDirection: "row",
            height: "calc(100% - 100px)",
          }}
        >
          <Selector />
        </Paper>
      </ModalUI>
    );
  }

  setRegistro(registro) {
    const patientEdit = { ...this.state.patientEdit, ...registro };
    const moduloActual = "registrar";
    const opcionActualModulo = patientEdit.id ? "editar" : "nuevo";
    this.setState(
      { patientSelected: null, patientEdit, moduloActual, opcionActualModulo },
      () => {
        this.updateHelperSidebar();
      }
    );
  }
  renderLoading() {
    if (this.state.loadingOn != null) {
      return (
        <Modal
          open
          style={{
            boxShadow: "1px 0px 10px rgba(0, 0,0, 0)",
            backgroundColor: "rgba(0, 0,0, 0.9)",
          }}
        >
          <Loader size="massive" active>
            {this.state.loadingOn}
          </Loader>
        </Modal>
      );
    }
    return null;
  }
}

const styles = {
  titleHelp: {
    width: "100%",
    padding: 3,
    height: 37,
    color: "white",
  },
  principal: {
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    overflowX: "hidden",
    padding: 0,
    paddingBottom: 0,
    margin: 0,
    height: "calc(100% - 95px)",
    width: "100%",
  },
  item: {
    padding: 0,
    paddingBottom: 3,
    margin: 0,
    width: "100%",
  },
};

PatientsView.propTypes = {
  setActiveView: PropTypes.func,
  setViewTitle: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setPacienteActual: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
};

const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  setState,
};
const mapStateToProps = (state) => {
  const {
    catalogos,
    preguntas,
    pacienteActual,
    medicamentosDgis,
    diagnosticos,
  } = state.Think;
  return {
    pacienteActual,
    catalogo: catalogos.find((e) => e.vc_nameTable === "ins_pacientes"),
    catalogos,
    preguntas,
    medicamentosDgis,
    diagnosticos,
  };
};

export default connect(mapStateToProps, actions)(PatientsView);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
} from "app/containers/Think/actions";

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";

import Formulario from "./formulario";
import Listado from "./listado";

import { database, auth } from "firebase";

const Index = (props) => {
  const { open, onClose } = props;

  const [app] = React.useState({
    auth: auth(),
    db: database().ref(),
  });

  const [user, setUser] = React.useState(null);
  const [formularios, setFormularios] = React.useState([]);
  const [tab, setTab] = React.useState(0);
  const [dataForm, setDataForm] = React.useState({});
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    app.auth.onAuthStateChanged(() => setUser(app.auth.currentUser));
  }, []);

  React.useEffect(() => {
    let ref = null;

    if (user && !user.isAnonymous) {
      console.log("entró aqui");
      ref = app.db
        .child(`settings/customNotes`)
        .orderByChild("uid")
        .equalTo(user.uid);

      ref.on("value", (snapshot) => {
        const val = Object.values(snapshot.val() || []);
        const aux = [];
        snapshot.forEach((v) => {
          aux.push({ key: v.key, ...v.val() });
        });
        setFormularios(aux);
      });
    }

    return () => ref && ref.off();
  }, [user]);

  React.useEffect(() => {
    const { nombre, preguntas = [], tipoNota } = dataForm;
    setDisabled(!(!!nombre && !!(preguntas || []).length && !!tipoNota));
  }, [dataForm]);

  React.useEffect(() => {
    if (open) {
      setDataForm({});
      setTab(0);
    }
  }, [open]);

  const handleTab = (e, nv) => {
    if (nv === tab) return;

    setDataForm({});
    setTab(nv);
  };
  const guardar = () => {
    if (!!dataForm.key) {
      app.db.child(`settings/customNotes/${dataForm.key}`).update({
        preguntas: dataForm.preguntas,
        nombre: dataForm.nombre,
        tipoNota: dataForm.tipoNota,
        preguntasBase: dataForm.preguntasBase,
      });
    } else {
      app.db.child(`settings/customNotes`).push({
        uid: user.uid,
        activo: true,
        nombre: dataForm.nombre,
        preguntas: dataForm.preguntas,
        tipoNota: dataForm.tipoNota,
        preguntasBase: dataForm.preguntasBase,
      });
    }

    setTab(0);
  };

  const handleActive = (formulario) => {
    app.db.child(`settings/customNotes/${formulario.key}`).update({
      activo: !formulario.activo,
    });
  };

  const handleSelect = (formulario) => {
    setDataForm(formulario);
    setTimeout(() => {
      setTab(1);
    }, 200);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTab}
        aria-label="disabled tabs example"
      >
        <Tab label="Formularios" />
        <Tab label="Registro" />
      </Tabs>
      <Box hidden={tab !== 0}>
        <DialogContent
          style={{
            minWidth: "500px",
            height: "500px",
          }}
        >
          <Listado
            formularios={formularios}
            onChange={handleActive}
            onClick={handleSelect}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
        </DialogActions>
      </Box>

      <Box hidden={tab !== 1}>
        <DialogContent
          style={{
            minWidth: "500px",
            height: "500px",
          }}
        >
          <Formulario data={dataForm} onChange={setDataForm} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button color="primary" disabled={disabled} onClick={guardar}>
            Guardar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

Index.propTypes = {
  setHelperSidebarRender: PropTypes.func,
  setPacienteActual: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
  logOut: PropTypes.func,
};

const actions = {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
};

const mapStateToProps = (state) => {
  const {
    preguntas,
    medicamentosDgis,
    diagnosticos,
    pacienteActual,
  } = state.Think;

  return {
    preguntas,
    medicamentosDgis,
    diagnosticos,
    paciente: pacienteActual,
  };
};

export default connect(mapStateToProps, actions)(Index);

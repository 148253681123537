import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfirmAlert from '../../material-ui/ConfirmAlert';
import { setState } from '../../containers/Think/actions';

class Index extends Component { 
  state = {
    hasError: false,
  }

  render () {
    if (!this.props.notifications) {
      return null;
    }
    const {title, message, options, onAccept, onCancel} = this.props.notifications;
    const setNull = () => {this.props.setState({modulo: "notifications", state: null})};
    return (
      <ConfirmAlert
      title={title}
      message={message}
      options={options}
      onAccept={onAccept || setNull}
      onCancel={onCancel}
    />
    );
  }
}
const actions = {
    setState
};
const mapStateToProps = (state) => {
  const { notifications } = state.Think;
  return { notifications };
};

export default connect(mapStateToProps, actions)(Index);

import React from 'react';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { teal } from '@material-ui/core/colors';
import { Paper } from '@material-ui/core';

class Detalle extends React.Component {
    constructor() {
        super();
        this.state = {
            detalle: null,
            position: {
                x: 0,
                y: 0,
            },
            x: 0,
            y: 0,
            left: 0,
            right: 0,
            paddingLeft: 0,
            width: 50,
            value: 0,
        };
    }

    componentDidMount() {
        console.log('DETALLE DID MOUNT')
        const fullWidth = this.props.fullWidth || 100;
        const scale = fullWidth / 1440
        const paddingLeft = this.timeToLayout(this.props.data.tm_inicio, scale)
        const maxX = this.timeToLayout(this.props.data.tm_fin, scale)
        const width = maxX - paddingLeft;

        const parentWidth = this.refs.detalle.parentNode.clientWidth
        const position = {
            x: (parentWidth * paddingLeft)/100,
            y: 0,
        }

        this.setState({
            fullWidth,
            scale,
            data: this.props.data,
            width,
            paddingLeft,
            maxX: this.props.maxX || 100,
            minX: this.props.minX || 0,
            
            position
        })
        // const {data} = this.props
        // const {draggable} = data
        // console.log('DATA', data)
        // this.setState({
        //     position: draggable.position,
        //     bounds: draggable.bounds,
        //     width: draggable.width,
        // })
    }

    render() {
        const key = this.props.key
        const { width, paddingLeft, value } = this.getWidth();
        const { position } = this.state;

        return (
            <Draggable
                key={key}
                bounds='parent'
                axis='x'
                handle=".handleMove"
                // defaultPosition={{ x: 0, y: 0 }}
                // position={{ x: 0, y: 0 }}
                position={position}
                grid={[20, 1]}
                scale={1}
                onStart={this.handleStart}
                onDrag={this.handleDrag}
                onStop={this.handleStop}
            >
                <div ref="detalle" style={{ display: 'flex', width: `${width}%`, height: '100%', alignItems: 'stretch' }}>
                    <Draggable
                        axis={'none'}
                        handle=".handleLeft"
                        defaultPosition={{ x: 0, y: 0 }}
                        position={{ x: 0, y: 0 }}
                        grid={[20, 1]}
                        scale={1}
                    >
                        <Paper id='-'
                            className="handleLeft"
                            style={{
                                backgroundColor: 'rgb(47, 150, 136)',
                                width: 10,
                                borderRadius: '5px 0px 0px 5px',
                            }}
                        />
                    </Draggable>

                    <Paper id='|'
                        className="handleMove"
                        style={{
                            backgroundColor: 'rgba(47, 150, 136, .2)',
                            flex: 1
                        }}
                    >
                        <div>x: {position.x.toFixed(0)}, y: {position.y.toFixed(0)}</div>
                    </Paper>

                    <Draggable
                        axis={'none'}
                        handle=".handleRight"
                        defaultPosition={{ x: 0, y: 0 }}
                        position={{ x: 0, y: 0 }}
                        grid={[100 / 1440, 1]}
                        scale={1}
                    >
                        <Paper id='+'
                            className="handleRight"
                            style={{
                                backgroundColor: 'rgb(47, 150, 136)',
                                width: 10,
                                borderRadius: '0px 5px 5px 0px',
                            }}
                        />
                    </Draggable>
                </div>
            </Draggable>
        )

        return (
            <div
                id={`agendaHorarioDetalle-${this.props.data.id}`}
                style={{ height: 40, width, position: 'relative' }}
            >
                <Draggable
                    axis={'none'}
                    handle=".handle"
                    defaultPosition={{ x: 0, y: 0 }}
                    position={{ x: 0, y: 0 }}
                    grid={[20, 1]}
                    scale={1}
                    onStart={this.handleStart}
                    onDrag={this.handleDrag}
                    onStop={this.handleStop}>
                    <Paper id='|'
                        className="handle"
                        style={{
                            height: '100%',
                            backgroundColor: 'rgba(47, 150, 136, .2)',
                            width: `calc(${width}% - 20px)`,
                            borderRadius: 0,
                            position: 'absolute',
                            left: `calc(${paddingLeft}% + 10px)`
                        }}
                    />
                </Draggable>

                <Draggable
                    axis={'none'}
                    handle=".handle"
                    defaultPosition={{ x: 0, y: 0 }}
                    position={{ x: 0, y: 0 }}
                    grid={[20, 1]}
                    scale={1}
                    onStart={this.handleStart}
                    onDrag={this.handleDrag}
                    onStop={this.handleStop}>
                    <Paper id='-'
                        className="handle"
                        style={{
                            height: '100%',
                            backgroundColor: 'rgb(47, 150, 136)',
                            width: 10,
                            borderRadius: '5px 0px 0px 5px',
                            position: 'absolute',
                            left: `${paddingLeft}%`
                        }}
                    />
                </Draggable>

                <Draggable
                    axis={'none'}
                    handle=".handle"
                    defaultPosition={{ x: 0, y: 0 }}
                    position={{ x: 0, y: 0 }}
                    grid={[100 / 1440, 1]}
                    scale={1}
                    onStart={this.handleStart}
                    onDrag={this.handleDrag}
                    onStop={this.handleStop}>
                    <Paper id='+'
                        className="handle"
                        style={{
                            height: '100%',
                            backgroundColor: 'rgb(47, 150, 136)',
                            width: 10,
                            borderRadius: '0px 5px 5px 0px',
                            position: 'absolute',
                            left: `calc(${paddingLeft + width}% - 10px)`
                        }}
                    />
                </Draggable>
            </div>

        );
    }

    handleStart = (e, data) => {
        console.log('HANDLE START',e)
        switch (e.target.id) {
            case '-': {
                this.setState({ right: false, center: false, left: true, value: 0 })
                break;
            }
            case '|': {
                this.setState({ right: false, center: true, left: false, value: 0 })
                break;
            }
            case '+': {
                this.setState({ right: true, center: false, left: false, value: 0 })
                break
            }
        }

        // this.props.start(e.target.id, data)
    }
    
    handleStop = (e, data) => {
        const { width, paddingLeft } = this.getWidth();
        this.setState({ value: 0, width, paddingLeft }, () => {
            // this.props.stop(width, data)
        })
    }

    handleDrag = (e, data) => {
        const { position } = this.state
        const ratio = e.path[1].clientWidth / 100
        const value = data.x / ratio;

        // if (this.validateLayouts(value, ratio))
        this.setState({
            value, position: {
                y: 0, x: position.x + data.deltaX
            }
        }, () => {
            // this.props.drag(value, data)
        })
    }

    getWidth() {
        let paddingLeft = this.state.paddingLeft;
        let width = this.state.width;

        switch (true) {
            case (this.state.right): {
                paddingLeft = this.state.paddingLeft
                width = this.state.width + (this.state.value);
                return {
                    width,
                    paddingLeft,
                };
            }
            case (this.state.left): {
                width = this.state.width - this.state.value
                paddingLeft = this.state.paddingLeft + this.state.value
                return {
                    width,
                    paddingLeft
                };
            }
            case (this.state.center): {
                paddingLeft = this.state.paddingLeft + this.state.value
                return {
                    width,
                    paddingLeft,
                    x: this.state.value
                };
            }
            default: {
                return {
                    width,
                    paddingLeft
                };
            }
        }
    }


    timeToLayout(timeString, scale) {
        const timeSegments = timeString.split(':');
        const hours = timeSegments[0] * 1;
        const minutes = timeSegments[1] * 1;

        console.log('hours', hours);
        console.log('minutes', minutes);

        return (minutes + (hours * 60)) * scale;
    }


    validateLayouts(value, ratio) {
        switch (true) {
            case (this.state.right && (((this.state.paddingLeft + this.state.width + value) > this.state.maxX) || (this.state.paddingLeft + (20 / ratio)) >= (this.state.paddingLeft + this.state.width + value))): {
                return false;
            }
            case (this.state.left && (((this.state.paddingLeft + value) < this.state.minX) || (this.state.paddingLeft + value + (20 / ratio)) >= (this.state.paddingLeft + this.state.width))): {
                return false;
            }
            case (this.state.center && (((this.state.paddingLeft + value) < this.state.minX) || this.state.maxX < (this.state.paddingLeft + this.state.width))): {
                return false;
            }
            default: {
                return true;
            }
        }
    }




}

const mapStateToProps = (state) => {
    const { catalogos, dias } = state.Think;
    return { catalogo: catalogos.find(e => e.vc_nameTable === 'ins_agendaHorariosDetalle'), dias };
};

export default connect(mapStateToProps, null)(Detalle);
import React from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Typography, Box, Button } from "@material-ui/core";
import Paciente from "app/components/Paciente/PacienteSidebar";

const Index = (props) => {
  const { selected, actions } = props;
  const { timeLine = () => {} } = actions;

  const botones = [
    {
      text: "Time Line",
      onClick: timeLine,
      disabled: false,
      validate: true,
    },
  ].filter((v) => v.validate);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <AppBar
        position="relative"
        style={{
          height: 95,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Typography style={{ textAlign: "center", width: "100%" }}>
            Paciente
          </Typography>
        </Toolbar>
      </AppBar>
      <Box flex={1} display="flex" flexDirection="column">
        <Paciente paciente={selected} />
        <Box height="0px" overflow="scroll" flex="1 1 auto">
          {botones.map((a, i) => (
            <Box mb={1} mx={1} key={i}>
              <Button
                key={i}
                variant="outlined"
                color="primary"
                fullWidth
                disabled={a.disabled}
                onClick={a.onClick}
              >
                {a.text}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

Index.defaultProps = { selected: null, actions: {} };

Index.propTypes = {
  selected: PropTypes.object,
  actions: PropTypes.object,
};

export default Index;

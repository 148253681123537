import React from "react";

import _ from "lodash";

import { Popover, Button, Box, Divider } from "@material-ui/core";
import DatePicker from "./DatePicker";
import moment from "moment";

export default (props) => {
  const {
    onChange = () => {},
    value = [],
    closeOnChange = false,
    showAcceptButton = false,
    disabledPastDates = false,
    DatePickerProps = {},
    ...restProps
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);

  const formated = value ? moment(value).format("ll") : "";
  const mindate = disabledPastDates ? new Date() : null;

  React.useEffect(() => {
    setDisabled(date === null);

    if (date) {
      if (!showAcceptButton && closeOnChange) {
        onChange(date);
        setAnchorEl(null);
      }
      if (!showAcceptButton && !closeOnChange) {
        onChange(date);
      }
    }
  }, [date]);

  return [
    <Button
      {...restProps}
      onClick={(e) => {
        setDate(null);
        setAnchorEl(e.target);
      }}
      color="inherit"
    >
      {formated || "Seleccionar fecha"}
    </Button>,
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <DatePicker
        {...DatePickerProps}
        value={value}
        onChange={(res) => {
          setDate(res);
        }}
      />
      {showAcceptButton && [
        <Divider />,
        <Box display="flex" justifyContent="flex-end" p={1}>
          <Button
            disabled={disabled}
            color="primary"
            variant="contained"
            onClick={() => {
              setAnchorEl(null);
              onChange(date);
            }}
          >
            Aceptar
          </Button>
        </Box>,
      ]}
    </Popover>,
  ];
};

'use strict';
import React from 'react';
import Mic from '@material-ui/icons/Mic';
import { IconButton } from '@material-ui/core';
import { useSpeechRecognition } from "react-speech-kit";

const SpeechButton = props => {
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      console.log('entro', result)
      props.callbackPreview && props.callbackPreview(result)
    },
    onEnd: () => {
      props.callbackFinal && props.callbackFinal()
    }
  });
  const start = () => {
    try {
      listen();
    }catch{

    }
  };
  if (window.isMobile) {
    return (
      <React.Fragment>
        <IconButton onTouchStart={(e) => {
          listen()
          e.stopPropagation();
          e.preventDefault();
        }} onTouchEnd={(e) => {
          stop()
          e.stopPropagation();
          e.preventDefault();
        }} disableFocusRipple size='small' style={{ margin: 5 }}>
          <Mic />
        </IconButton>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <IconButton onMouseDown={start} onMouseUp={stop} disableFocusRipple size='small' style={{ margin: 5 }}>
        <Mic />
      </IconButton>
    </React.Fragment>
  )
}

export default SpeechButton;
export const menuStatistics = [
  // {
  //     id: 'productividad',
  //     vc_nombre: 'Productividad',
  //     enable: true,
  //     sidebar: {
  //         open: false,
  //         enable: false,
  //     },
  //     opciones: [
  //         {
  //             id: 'graficas',
  //             vc_nombre: 'Graficas',
  //             acciones: [
  //                 {
  //                     id: 'imprimir',
  //                     vc_nombre: 'Imprimir',
  //                     icon: null
  //                 },
  //                 {
  //                     id: 'exportar',
  //                     vc_nombre: 'Exportar',
  //                     icon: null
  //                 },
  //                 ,
  //                 {
  //                     id: 'enviar',
  //                     vc_nombre: 'Enviar',
  //                     icon: null
  //                 },
  //             ]
  //         },
  //         {
  //             id: 'mapas',
  //             vc_nombre: 'Geo-Statistics',
  //             acciones: [
  //                 {
  //                     id: 'imprimir',
  //                     vc_nombre: 'Imprimir',
  //                     icon: null
  //                 },
  //                 {
  //                     id: 'exportar',
  //                     vc_nombre: 'Exportar',
  //                     icon: null
  //                 },
  //                 ,
  //                 {
  //                     id: 'enviar',
  //                     vc_nombre: 'Enviar',
  //                     icon: null
  //                 },
  //             ]
  //         }
  //     ]
  // },
  {
    id: "reportes",
    vc_nombre: "Reportes",
    enable: true,
    sidebar: {
      open: false,
      enable: false,
    },
    opciones: [
      {
        id: "hojadiaria",
        vc_nombre: "Hoja diaria",
      },
    ],
  },
];

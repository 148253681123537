import React from "react";

import { connect } from "react-redux";
import SubMenuModulos from "../../../material-ui/SubMenuModulos";
import * as firebase from "firebase/app";
import { FadeInView } from "../../../components";
import moment from "moment";

import ButtonDateRange from "./ButtonDateRange";
import MUIDataTable from "mui-datatables";

import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "../../../containers/Think/actions";

import Sidebar from "./Sidebar";

const currencyFormat = (num) =>
  `$ ${num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

const Index = (props) => {
  const institucion = window.amplify.store("institution") || {};
  const db = firebase.database().ref();
  const [dbRef, setDbRef] = React.useState(null);
  const [cuts, setCuts] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [range, setRange] = React.useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);
  const [rowSelected, setRowSelected] = React.useState({
    index: -1,
    row: null,
  });

  React.useEffect(() => {
    props.setDisplaySidebar(false);
    props.setHelperSidebarRender(null);
  }, []);

  React.useEffect(() => {
    if (institucion.id_institucion) {
      if (dbRef) dbRef.off();

      const ref = db
        .child(`erp/cashRegistersCuts/${institucion.id_institucion}`)
        .orderByChild("dt_start")
        .startAt(moment(range[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss"))
        .endAt(moment(range[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss"));

      ref.on("value", (snapshot) => {
        console.log("SNAPSHOT", snapshot.val());
        let values = [];

        if (snapshot.val() != null) {
          values = Object.values(snapshot.val()) || [];
        }

        setCuts(values);
      });

      setDbRef(ref);
    }
  }, [range]);

  React.useEffect(() => {
    setData(
      cuts.map((cut) => ({
        caja: cut.vc_name,
        cajero: cut.close?.vc_nameUser || "",
        inicio: moment(cut.dt_start).format("LLLL"),
        fin: cut.dt_end ? moment(cut.dt_end).format("LLLL") : null,
        montoinicial: currencyFormat(cut.nu_initReceived),
        montocorte: cut.close ? currencyFormat(cut.close.nu_quantity) : null,
        ...cut,
      }))
    );
  }, [cuts]);

  React.useEffect(() => {
    props.setHelperSidebarRender(() =>
      Boolean(rowSelected.row) ? <Sidebar data={rowSelected.row} /> : null
    );
    props.setDisplaySidebar(Boolean(rowSelected.row));
  }, [rowSelected]);

  const handleSelect = (row, index) => {
    const newRowSelected = { index: -1, row: null };

    if (rowSelected.index !== index) {
      newRowSelected.index = index;
      newRowSelected.row = row;
    }

    setRowSelected(newRowSelected);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 48px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SubMenuModulos
        title={<ButtonDateRange value={range} onChange={setRange} />}
      />

      <div
        style={{
          ...styles.principal,
          flex: 1,
          ...{ height: "calc(100% - 47px)" },
          padding: "10px",
        }}
      >
        <Table data={data} onClick={handleSelect} />
      </div>
    </div>
  );
};

const Table = (props) => {
  const { data = [], onClick = () => {} } = props;
  const options = { filter: true, sort: true };
  const columns = [
    {
      name: "caja",
      label: "Caja",
      options,
    },
    {
      name: "cajero",
      label: "Cajero",
      options,
    },
    {
      name: "montoinicial",
      label: "Monto Inicial",
      options,
    },
    {
      name: "montocorte",
      label: "Corte",
      options,
    },
    {
      name: "inicio",
      label: "Inicio",
      options,
    },
    {
      name: "fin",
      label: "Fin",
      options,
    },
  ];

  const optionsTable = {
    elevation: 0,
    filter: false,
    fixedSelectColumn: false,
    print: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    onRowClick(rowData, { dataIndex, rowIndex }) {
      onClick(data[dataIndex], dataIndex);
    },
  };
  return (
    <FadeInView>
      <MUIDataTable
        title="Cortes"
        data={data}
        columns={columns}
        options={optionsTable}
      />
    </FadeInView>
  );
};

const styles = {
  container: {
    overflowX: "hidden",
    overflowY: "hidden",
    height: "100vh",
    width: "100%",
    padding: 0,
    margin: 0,
  },
  principal: {
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    overflowX: "hidden",
    padding: 0,
    paddingBottom: 0,
    margin: 0,
    height: "calc(100vh - 95px)",
    width: "100%",
  },
  rightHeader: {
    color: "white",
    background: "transparent",
    textAlign: "center",
    margin: 0,
  },
  centralColumn: {
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    background: "rgba(50,50,50,0.4)",
    padding: 0,
    paddingTop: 0,
    margin: 0,
    width: "100%",
    height: window.innerHeight,
  },
  item: {
    padding: 0,
    paddingBottom: 3,
    margin: 0,
    width: "100%",
  },
};
const actions = { setHelperSidebarRender, setDisplaySidebar };
const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  return {
    catalogo: catalogos.find((e) => e.vc_nameTable === "ins_pacientes"),
  };
};

export default connect(mapStateToProps, actions)(Index);

import internalAPI from "app/utils/internalAPI";
import notifications from "app/utils/notifications";
import { logOut } from "app/containers/Think/actions";
import moment from "moment";

export default {
  day(id_agenda, dt_fecha) {
    return new Promise((resolve, reject) => {
      internalAPI.getDiaryByDay({ dt_fecha, id_agenda }, (err, response) => {
        if (err) {
          reject();
        } else {
          const meetings = response.citas.map((item) => {
            const buildDateStart = moment(`${item.dt_cita} ${item.tm_inicio}`);
            const buildDateEnd = moment(`${item.dt_cita} ${item.tm_fin}`);

            return {
              title: `${item.vc_nombre} ${item.vc_apellidoPaterno} ${item.vc_apellidoMaterno}`,
              start: new Date(buildDateStart),
              end: new Date(buildDateEnd),
              tm_inicio: item.tm_inicio,
              ...item,
            };
          });
          const availables = response.proximas.map((item) => {
            const buildDateStart = moment(`${item.tm_inicio}`);
            const buildDateEnd = moment(`${item.tm_fin}`);

            return {
              start: new Date(buildDateStart),
              end: new Date(buildDateEnd),
              tm_inicio: item.tm_inicio,
              tm_fin: item.tm_fin,
              ...item,
            };
          });
          const disponibles = response.disponibles.map((item) => {
            const buildDateStart = moment(`${item.tm_inicio}`);
            const buildDateEnd = moment(`${item.tm_fin}`);

            return {
              start: new Date(buildDateStart),
              end: new Date(buildDateEnd),
              tm_inicio: item.tm_inicio,
              tm_fin: item.tm_fin,
              ...item,
            };
          });

          const horary = response.horario[0];
          const mixArr = meetings.concat(disponibles).sort(function (a, b) {
            return a.start - b.start;
          });
          const turns = mixArr;

          resolve({
            meetings,
            horary,
            availables,
            turns,
          });
        }
      });
    });
  },
  days(id_agenda, fechas) {
    return new Promise((resolve, reject) => {
      internalAPI.getDiaryByDays({ fechas, id_agenda }, (err, response) => {
        if (err) return reject();

        // const citas = response.citas.map((item) => {
        //   const buildDateStart = moment(`${item.dt_cita} ${item.tm_inicio}`);
        //   const buildDateEnd = moment(`${item.dt_cita} ${item.tm_fin}`);

        //   return {
        //     title: `${item.vc_nombre} ${item.vc_apellidoPaterno} ${item.vc_apellidoMaterno}`,
        //     start: new Date(buildDateStart),
        //     end: new Date(buildDateEnd),
        //     ...item,
        //   };
        // });

        resolve(response);
      });
    });
  },
  month(id_agenda, dt_fecha) {
    return new Promise((resolve, reject) => {
      internalAPI.getDiaryByMonth({ dt_fecha, id_agenda }, (err, response) => {
        if (err) {
          reject(err);
        } else {
          const daysOfCalendar = response.map((j) => {
            return {
              dt_inicio: j.dt_inicio,
              sn_hoy: j.sn_hoy,
              sn_mesActual: j.sn_mesActual,
              nu_citas: j.nu_citas,
              nu_disponibles: j.nu_disponibles,
            };
          });

          resolve({
            daysOfCalendar,
          });
        }
      });
    });
  },
  expediente(citaActual) {
    const { id_paciente } = citaActual;
    return new Promise((resolve, reject) => {
      internalAPI.getExpediente(
        {
          id_paciente,
          id_rol: 5,
          sn_pacienteRespuestas: true,
          sn_diagnosticos: true,
          sn_procedimientos: true,
          sn_medicamentos: true,
          sn_respuestas: true,
          sn_solicitudes: true,
          sn_signosAlarma: true,
          sn_atenciones: true,
          sn_notas: true,
          id_tipoNota: 4,
        },
        (err, response) => {
          if (err) {
            if (err.texto) {
              notifications.error(err.texto);
              logOut();
              reject();
            }
          } else {
            const preguntasNumericas = [];
            const preguntasTexto = [];
            response.data.preguntas.map((item, index) => {
              if (
                item.id_tipoPregunta == 4 &&
                (item.id_sexo == 3 ||
                  response.data.paciente.id_sexo == item.id_sexo)
              ) {
                preguntasTexto.push(item);
              } else if (
                item.id_sexo == 3 ||
                response.data.paciente.id_sexo == item.id_sexo
              ) {
                preguntasNumericas.push(item);
              }
            });
            const paciente = {
              detalle: response.data.paciente,
              citaActual,
              tratamientosPaciente: response.data.tratamientos,
              medicamentosPaciente: response.data.medicamentos,
              diagnosticosPaciente: response.data.diagnosticos,
              solicitudes: response.data.solicitudes,
              signosAlarma: response.data.signosAlarma,
              pacienteRespuestas: response.data.pacientesRespuestas,
              atenciones: response.data.atenciones,
              notas: response.data.notas,
              preguntasNumericas: preguntasNumericas,
              preguntasTexto: preguntasTexto,
            };
            resolve(paciente);
          }
        }
      );
    });
  },
  cancelMeeting(cita) {
    return new Promise((resolve, reject) => {
      internalAPI.cancelMeeting(
        { ...cita, id_estatusCita: 7 },
        (error, response) => {
          if (error) {
            reject();
          } else {
            resolve();
          }
        }
      );
    });
  },
  changeStatus(cita, id_estatusCita) {
    return new Promise((resolve, reject) => {
      internalAPI.cancelMeeting(
        { ...cita, id_estatusCita },
        (error, response) => {
          if (error) {
            reject();
          } else {
            resolve();
          }
        }
      );
    });
  },
};

export const menuNotas = [
  {
    id: "notas",
    vc_nombre: "Notas",
    enable: true,
    sidebar: {
      open: true,
      enable: false,
    },
    opciones: [
      {
        id: "notas",
        vc_nombre: "Notas",
        acciones: [
          {
            id: "pausar",
            vc_nombre: "Pausar",
            icon: null,
          },
          {
            id: "guardar",
            vc_nombre: "Guardar",
            icon: null,
          },
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "historial",
        vc_nombre: "Historial",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
    ],
  },
  {
    id: "solicitudes",
    vc_nombre: "Solicitudes",
    enable: true,
    sidebar: {
      open: true,
      enable: true,
    },
    opciones: [
      {
        id: "laboratorio",
        vc_nombre: "Laboratorio",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "imagenologia",
        vc_nombre: "Imagenología",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "patologia",
        vc_nombre: "Patologia",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "todos",
        vc_nombre: "Todos",
        acciones: [
          {
            id: "guardar",
            vc_nombre: "Guardar",
            icon: null,
          },
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "historial",
        vc_nombre: "Historial",
        acciones: [],
      },
    ],
  },
  {
    id: "diagnosticos",
    vc_nombre: "Diagnosticos",
    enable: true,
    sidebar: {
      open: true,
      enable: true,
    },
    opciones: [
      {
        id: "activos",
        vc_nombre: "Activos",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "agregados",
        vc_nombre: "Agregados",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "eliminados",
        vc_nombre: "Eliminados",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "historial",
        vc_nombre: "Historial",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
    ],
  },
  {
    id: "tratamientos",
    vc_nombre: "Tratamientos",
    enable: true,
    sidebar: {
      open: true,
      enable: true,
    },
    opciones: [
      {
        id: "actual",
        vc_nombre: "Actual",
        acciones: [
          {
            id: "guardar",
            vc_nombre: "Guardar",
            icon: null,
          },
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "agregados",
        vc_nombre: "Agregados",
        acciones: [
          {
            id: "guardar",
            vc_nombre: "Guardar",
            icon: null,
          },
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "eliminados",
        vc_nombre: "Eliminados",
        acciones: [
          {
            id: "guardar",
            vc_nombre: "Guardar",
            icon: null,
          },
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "historial",
        vc_nombre: "Historial",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
    ],
  },
  //   {
  //       id: 'indicaciones',
  //       vc_nombre: 'Indicaciones',
  //       enable: true,
  //       sidebar: {
  //         open: true,
  //         enable: true,
  //       },
  //       opciones: [
  //           {
  //               id: 'actual',
  //               vc_nombre: 'Actual',
  //               acciones: [
  //                   {
  //                       id: 'guardar',
  //                       vc_nombre: 'Guardar',
  //                       icon: null
  //                   },
  //                   {
  //                       id: 'imprimir',
  //                       vc_nombre: 'Imprimir',
  //                       icon: null
  //                   }
  //               ]
  //           },
  //           {
  //               id: 'agregados',
  //               vc_nombre: 'Agregados',
  //               acciones: [
  //                   {
  //                       id: 'guardar',
  //                       vc_nombre: 'Guardar',
  //                       icon: null
  //                   },
  //                   {
  //                       id: 'imprimir',
  //                       vc_nombre: 'Imprimir',
  //                       icon: null
  //                   }
  //               ]
  //           },
  //           {
  //               id: 'eliminados',
  //               vc_nombre: 'Eliminados',
  //               acciones: [
  //                   {
  //                       id: 'guardar',
  //                       vc_nombre: 'Guardar',
  //                       icon: null
  //                   },
  //                   {
  //                       id: 'imprimir',
  //                       vc_nombre: 'Imprimir',
  //                       icon: null
  //                   }
  //               ]
  //           },
  //           {
  //               id: 'historial',
  //               vc_nombre: 'Historial',
  //               acciones: [
  //                   {
  //                       id: 'imprimir',
  //                       vc_nombre: 'Imprimir',
  //                       icon: null
  //                   }
  //               ]
  //           }
  //       ]
  //   },
  {
    id: "procedimientos",
    vc_nombre: "Procedimientos",
    enable: true,
    sidebar: {
      open: true,
      enable: true,
    },
    opciones: [
      {
        id: "agregados",
        vc_nombre: "Agregados",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "convalecientes",
        vc_nombre: "Convalecientes",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "historial",
        vc_nombre: "Historial",
        acciones: [
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
    ],
  },
  // {
  //   id: "estudios",
  //   vc_nombre: "Multimedia",
  //   enable: true,
  //   sidebar: {
  //     open: false,
  //     enable: false,
  //   },
  //   opciones: [
  //     {
  //       id: "visualizar",
  //       vc_nombre: "Visualizar Multimedia",
  //       acciones: [],
  //     },
  //     {
  //       id: "registro",
  //       vc_nombre: "Capturar Multimedia",
  //       acciones: [],
  //     },
  //   ],
  // },
  //   {
  //     id: "historia",
  //     vc_nombre: "Historia Clinica",
  //     enable: true,
  //     opciones: [
  //       {
  //         id: "ahf",
  //         vc_nombre: "Heredofamiliares",
  //         acciones: [
  //           {
  //             id: "guardar",
  //             vc_nombre: "Guardar",
  //             icon: null,
  //           },
  //           {
  //             id: "imprimir",
  //             vc_nombre: "Imprimir",
  //             icon: null,
  //           },
  //         ],
  //       },
  //       {
  //         id: "apnp",
  //         vc_nombre: "No Patologicos",
  //         acciones: [
  //           {
  //             id: "guardar",
  //             vc_nombre: "Guardar",
  //             icon: null,
  //           },
  //           {
  //             id: "imprimir",
  //             vc_nombre: "Imprimir",
  //             icon: null,
  //           },
  //         ],
  //       },
  //       {
  //         id: "gineco-obstetricos",
  //         vc_nombre: "Gineco-obstetricos",
  //         acciones: [
  //           {
  //             id: "guardar",
  //             vc_nombre: "Guardar",
  //             icon: null,
  //           },
  //           {
  //             id: "imprimir",
  //             vc_nombre: "Imprimir",
  //             icon: null,
  //           },
  //         ],
  //       },
  //       {
  //         id: "app",
  //         vc_nombre: "Patologicos",
  //         acciones: [
  //           {
  //             id: "guardar",
  //             vc_nombre: "Guardar",
  //             icon: null,
  //           },
  //           {
  //             id: "imprimir",
  //             vc_nombre: "Imprimir",
  //             icon: null,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  {
    id: "timeLine",
    vc_nombre: "Linea del Tiempo",
    enable: true,
    sidebar: {
      open: true,
      enable: false,
    },
    opciones: [
      {
        id: "ahf",
        vc_nombre: "Heredofamiliares",
        acciones: [
          {
            id: "guardar",
            vc_nombre: "Guardar",
            icon: null,
          },
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "apnp",
        vc_nombre: "No Patologicos",
        acciones: [
          {
            id: "guardar",
            vc_nombre: "Guardar",
            icon: null,
          },
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "gineco-obstetricos",
        vc_nombre: "Gineco-obstetricos",
        acciones: [
          {
            id: "guardar",
            vc_nombre: "Guardar",
            icon: null,
          },
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
      {
        id: "app",
        vc_nombre: "Patologicos",
        acciones: [
          {
            id: "guardar",
            vc_nombre: "Guardar",
            icon: null,
          },
          {
            id: "imprimir",
            vc_nombre: "Imprimir",
            icon: null,
          },
        ],
      },
    ],
  },
  // {
  //     id: 'telemedicina',
  //     vc_nombre: 'Telemedicina',
  //     enable: true,
  //     sidebar: {
  //         open: false,
  //         enable: true,
  //     },
  //     opciones: [
  //         {
  //             id: 'video',
  //             vc_nombre: 'Video Consulta',
  //             acciones: [
  //                 {
  //                     id: 'iniciar',
  //                     vc_nombre: 'Iniciar',
  //                     icon: null
  //                 },
  //                 {
  //                     id: 'invitar',
  //                     vc_nombre: 'Invitar',
  //                     icon: null
  //                 },
  //                 {
  //                     id: 'interconsulta',
  //                     vc_nombre: 'Interconsultar',
  //                     icon: null
  //                 }
  //             ]
  //         }
  //     ]
  // }
];

import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core';
import SubMenuModulos from '../../../material-ui/SubMenuModulos';
import QRReader from '../../../material-ui/QRReader';
import ListadoProductos from './ListadoProductos';

const ProductsInstitution = (props) => {
    const { opciones = [], acciones = [], options = [] } = props;
    console.log(props)
    const [opcionActual, setOpcionActual] = useState(opciones[0] || null)
    const [searchText, setSearchText] = useState('');
    const searchItems = (e) => {
        setSearchText(e.target.value);
    }
    const renderContent = () => {
        if (opcionActual)
        switch(opcionActual.id){
            case 'listado':{
                return (
                    <ListadoProductos/>
                )
            }
        }
    }
    return (
        <Box>
            <SubMenuModulos
                // title={opcionActual ? opcionActual.vc_nombre : null}
                type={opcionActual !== 'registro' ? 'search' : null}
                searchText={searchText}
                onChange={searchItems.bind(this)}
                actions={acciones}
                options={opciones}
                optionDisplay={null}
                scanner={
                    <QRReader
                    />
                }
            />
            {renderContent()}
        </Box>
    )
};
export default (ProductsInstitution);
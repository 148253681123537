import React, { Component } from 'react';
import { Router, hashHistory } from 'react-router';
import containers from './containers';


export default class AppRoutes extends Component {
  render() {
    return (
      <Router history={hashHistory}>
        { containers.Routes }
      </Router>
    );
  }
}

import authCases from './authCases';
import layoutCases from './layoutCases';
import userCases from './userCases';
import paciente_actual from './patientCase';
import notasCases from './notasCases';
import updateCat from './catCases';
import institutionsCases from './institutionsCases';
import componentsCases from './componentsCases';
import { teal } from '@material-ui/core/colors';

const INITIAL_STATE = {
	user: {},
	currentModule: {},
	sidebar: {
		main: null,
		helper: null
	},
	themeSelected: {
		menuBar:{
			type: 'dark',
			primary: teal
		},
		mainScreen:{
			type: 'light',
			primary: teal
		},
		sidebar:{
			type: 'dark',
			primary: teal
		}
	},
	covid19: window.amplify.store('covid19') || {},
	catalogos: [],
	casosClinicosEnarm: [],
	preguntasEnarm: [],
	respuestasEnarm: [],
	dias: [],
	sets: [],
	paises: [],
	diagnosticos: [],
	procedimientos: [],
	medicamentosDgis: [],
	medicamentosThink: [],
	medicamentosSustanciasActivas: [],
	preguntas: [],
	preguntasVersiones: [],
	preguntasRespuestas: [],
	respuestas: [],
	unidadesMedida: [],
	viasAdministracion: [],
	vehiculos: [],
	estudios: [],
	gruposEstudios: [],
	gruposEstudios_estudios: [],
	paquetesEstudios: [],
	paquetesEstudios_estudios_grupos: [],
	niveles: [],
	formacionAcademica: [],
	roles: [],
	religiones: [],
	clues: [],
	tiposAreas: [],
	tiposTurnos: [],
	entidades: [],
	municipios: [],
	localidades: [],
	tiposInstituciones: [],
	tiposPreguntas: [],
	tiposEstudios: [],
	tiposEventos: [],
	tiposOficios: [],
	tiposPrioridad: [],
	sexos: [],
	regimenesFiscales: [],
	correos: [],
	estadosCiviles: [],
	lenguasIndigenas: [],
	nacionalidades: [],
	especialidades: [],
	nombres: [],
	gpc: [],
	apellidos: [],
	drawersLayout: {
		menu: false,
		sidebar: false,
	},
	itemEdit: null,
	preguntaActual: null,
	preguntasNotas: null,
	pacienteActual: null,
	institucionActual: {},
	institucion: null,
	notifications: null
};


export default (state = INITIAL_STATE, action) => {
	const authResult = authCases(state, INITIAL_STATE, action);
	if (authResult) { return authResult; }

	const layoutResult = layoutCases(state, INITIAL_STATE, action);
	if (layoutResult) { return layoutResult; }

	const userResult = userCases(state, INITIAL_STATE, action);
	if (userResult) { return userResult; }

	const patientResult = paciente_actual(state, INITIAL_STATE, action);
	if (patientResult) { return patientResult; }

	const catResults = updateCat(state, INITIAL_STATE, action);
	if (catResults) { return catResults; }

	const institutionsResults = institutionsCases(state, INITIAL_STATE, action);
	if (institutionsResults) { return institutionsResults; }

	const notasResults = notasCases(state, INITIAL_STATE, action);
	if (notasResults) { return notasResults; }
	
	const componentsResults = componentsCases(state, INITIAL_STATE, action);
	if (componentsResults) { return componentsResults; }

	return state;
};

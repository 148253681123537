export default {
    vc_name: 'Marcas',
    vc_path: 'productos/marcas',
    fields: [
        {
            "type": "number",
            "vc_name": "Id",
            "vc_icon": 'FormatListNumberedOutlined',
            "key": "id",
            "readOnly": true,
            "nullify": true,
            "component": "textField",
            "filter": true
        },
        {
            "type": "text",
            "vc_icon": 'ShortTextOutlined',
            "vc_name": "Nombre",
            "key": "vc_nombre",
            "component": "textField",
            "filter": true
        }
    ]
};
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { styled, withStyles } from '@material-ui/core/styles';
import { setHelperSidebarRender, resetHelperSidebarRender, setState, setDisplaySidebar } from '../../../actions';
import { Paper, Typography } from '@material-ui/core';
import { Modal, Loader } from 'semantic-ui-react';
import internalAPI from '../../../../../utils/internalAPI';
import SideBar from './SideBar';

const modulo = 'settings';

const Item = styled(Paper)({
  border: 0,
  borderRadius: 5,
  padding: '15px',
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: '1px solid transparent',
  '&.selected': {
    borderColor:window.colors.itemSelected,
  },
  '& h6': {
    color:window.colors.itemSelected,
    fontSize: '1rem',
    marginBottom: '10px',
    fontWeight: '700',
    lineHeight: '1.28571429em'
  },
  '& label':{
    marginRight: '5px',
  }
});

const styles = theme => ({
  userData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flext-start',
    alignItems: 'flex-start',
    width: '100%',
    paddingLeft: '16px'
  }
});

class Listado extends React.Component {
  constructor(props) {
    super(props);

    const { agendas = [] } = window.amplify.store('institution') || [];
    let especialidad = null
    const data = agendas.map(v => {
      especialidad = props.especialidades.find(e => e.id == v.id_especialidad)
      return {
        ...v,
        vc_especialidad: especialidad ? especialidad.vc_alias : ''
      }
    })

    this.state = {
      data,
      searchText: ' ',
      selectedRow: null,
      indexSelected: null
    }
    this.selectedRow = this.selectedRow.bind(this);
    this.edit = this.edit.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentWillUnmount() {
    this.props.setHelperSidebarRender(() => null);
    this.props.setDisplaySidebar(false);
  }

  edit(selectedRow) {
    this.props.changeState({opcion:'editar', data: selectedRow })
  }

  remove(selectedRow) {
    internalAPI.desvincularUsuario(selectedRow.id, (err, response) => {
      this.getUsers();
      this.props.setHelperSidebarRender(() => null);
      this.props.setDisplaySidebar(false);
    });
  }

  selectedRow(selectedRow, indexSelected) {
    if (indexSelected === this.state.indexSelected) {
      this.setState({ selectedRow: null, indexSelected: null }, () => { 
        this.props.setHelperSidebarRender(() => null);

        this.props.setDisplaySidebar(false);
      });
    }else{
      this.setState({ selectedRow, indexSelected }, () => { 
        this.props.setHelperSidebarRender(
          () => (
            <SideBar
              selectedRow={selectedRow}
              edit={() => this.edit(selectedRow)}
              remove={() => this.remove(selectedRow)}
            />
          )
        );

        this.props.setDisplaySidebar(true);
      });
    }
  }

  renderContent() {
    return this.state.data.map((item, index) => {
      return (
        <Item 
          key={index} 
          className={this.state.indexSelected === index ? 'selected' : '' }
          onClick={() => this.selectedRow(item, index)}
        >
          <div className={this.props.classes.userData}>
            <Typography variant="subtitle1">{item.vc_nombre}</Typography>
          </div>
        </Item>
      );
    });
  }

  render() {
    return (
      <React.Fragment>
        {/* <UsuarioBar
          type="search"
          add={() => this.props.changeState('usuarioNuevo')}
        /> */}

        <div style={{ width: '100%', padding: 15, height: 'calc(100% - 65px)', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>
          {this.renderContent()}
        </div>

        {this.state.loading && (
          <Modal open style={{ boxShadow: '1px 0px 10px rgba(0, 0,0, 0)', backgroundColor: 'rgba(0, 0,0, 0.9)' }}>
            <Loader size='massive' active inverted>
              Cargando...
            </Loader>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

Listado.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  setState: PropTypes.func,
  setDisplaySidebar: PropTypes.func
};

const mapStateToProps = (state) => {
  const { especialidades } = state.Think;
  return {
    state: state.Think[modulo] || null,
    especialidades
  };
};
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState,
  setDisplaySidebar
};
export default connect(mapStateToProps, actions)(withStyles(styles)(Listado));

import { SET_HELPER_SIDEBAR_RENDER, RESET_HELPER_SIDEBAR_RENDER, SET_CHANGE_DISPLAY_MENU, SET_CHANGE_DISPLAY_SIDEBAR } from '../actions/types';


export default (state, INITIAL_STATE, action) => {
	var drawersLayout = null;
    switch (action.type) {
		case SET_HELPER_SIDEBAR_RENDER:
			return { ...state, sidebar: { ...state.sidebar, helper: action.payload } };
			case RESET_HELPER_SIDEBAR_RENDER:
				
			return { ...state, sidebar: { ...state.sidebar, helper: null } };
			case SET_CHANGE_DISPLAY_SIDEBAR:
					drawersLayout = {...state.drawersLayout}
					if (state.sidebar.helper === null && drawersLayout.sidebar === false){
						return state;
					}
					switch(true){
						case (action.payload === true) : {
							drawersLayout.sidebar = true;
							break;
						}
						case (action.payload === false) : {
							drawersLayout.sidebar = false;
							break;
						}
						default: {
							drawersLayout.sidebar = drawersLayout.sidebar ? false : true;
						}
					}
			return { ...state, drawersLayout };
			case SET_CHANGE_DISPLAY_MENU:
					drawersLayout = {...state.drawersLayout}
					switch(true){
						case (action.payload === true) : {
							drawersLayout.menu = true;
							break;
						}
						case (action.payload === false) : {
							drawersLayout.menu = false;
							break;
						}
						default: {
							drawersLayout.menu = drawersLayout.menu ? false : true;
						}
					}
			return { ...state, drawersLayout };
		default:
			return null;
	}
};

import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import eventManager from '../utils/eventManager';

export default class GlobalWrapper extends Component {
  componentDidMount() {
    if (this.props.location.pathname === '/') {
      window.location = `#/${window.initialRoute}`;
    }
    eventManager.on('SHOW_NOTIFICATION', (data) => {
      this.refs.notificationSystem.addNotification({
        level: data.type,
        message: data.message
      });
    });
  }
  render() {
    return (
      <div style={styles.container}>
        { this.props.children }
        <NotificationSystem ref="notificationSystem" style={{ backgroundColor: 'white' }} />
      </div>
    );
  }
}


const styles = {
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // paddingBottom: window.isMobile ?  window.screen.height - window.screen.availHeight : 0
  }
};

import React from "react";
import { connect } from 'react-redux';
import { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar, setPacienteActual } from '../../../actions';
import { position } from 'caret-pos';
const InputEdit = (props) => {
    const { index, input, id_preguntaTexto } = props;
    const [over, setOver] = React.useState(null);
    const id = props.idInput;
    const setPregunta = () => {
        console.log('id_preguntaTexto', id_preguntaTexto)
        const preguntasTexto = (props.pacienteActual.preguntasTexto || []).map((pregunta, index) => {
            if (id_preguntaTexto === pregunta.id) {
                const value = document.getElementById(id).innerText;
                const respuestasInternas = [].concat(pregunta.respuestasInternas);
                if (index > -1) {
                    respuestasInternas[index] = {
                        vc_sentencia: value || ''
                    }
                } else {
                    respuestasInternas.push({
                        vc_sentencia: value || ''
                    })
                }
                return {
                    ...pregunta,
                    respuestasInternas
                };
            } else {
                return pregunta;
            }
        });
        const newPacienteActual = { ...props.pacienteActual, preguntasTexto };
        setValue('')
        props.setPacienteActual(newPacienteActual);
    }
    const [prevValueProps, setPrevValueProps] = React.useState(null);
    const [value, setValue] = React.useState(input.vc_sentencia || '');
    React.useEffect(() => {
        const valueReceibed = props.input.vc_sentencia ? props.input.vc_sentencia : '';
        if (valueReceibed != value && valueReceibed != prevValueProps) {
            if (props.input.vc_sentencia !== null) {
                setPrevValueProps(valueReceibed)
                setValue(valueReceibed)
            }
        }
    }, [props])
    const onchangeInner = (e) => {
        const value = e.target.value || ''
        setValue(value);
    }
    return (
        <i
            id={props.idInput}
            contentEditable
            suppressContentEditableWarning
            onMouseOver={() => {
                setOver(index);
            }}
            onMouseLeave={() => {
                setOver(null);
            }}
            onBlur={props.callback}
            onChange={onchangeInner}
            onKeyDown={(e) => {
                const pos = position(e.target);
                switch (true) {
                    case (e.key === 'ArrowRight' && pos.pos > -1 && pos.pos < e.currentTarget.innerText.length - 1): {
                        console.log(e.key, 'right')
                        break;
                    }
                    case (e.key === 'ArrowLeft' && pos.pos > 0): {
                        break;
                    }
                    case (e.key === 'ArrowRight'): {
                        console.log(e.key, 'right')
                        let input = document.getElementById(`respuestaId=${id_preguntaTexto}:${index + 1}:${'prev'}`)
                        if (input) {
                            console.log(e.key, 'right')
                            input.focus()
                        } else if (!input) {
                            input = document.getElementById(`respuestaId=${id_preguntaTexto}:last`)
                            if (input) {
                                input.focus()
                            }
                        }
                        break;
                    }
                    case (e.key === 'ArrowLeft'): {
                        let input = document.getElementById(`respuestaId=${id_preguntaTexto}:${index - 1}:${'prev'}`)
                        if (input) {
                            input.focus()
                        }
                        break;
                    }
                }
            }}
            // contentEditable={over === index && input.id_pregunta ? false : true}
            style={{
                outline: "none",
                fontStyle: 'normal', textAlign: 'justify', fontFamily: "Helvetica Neue", fontSize: 14, padding: 0, margin: 0, borderRadius: 5, overflow: 'hiden',
                position: 'relative',
            }}
        >
            {value}
        </i>
    );
};
const actions = { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar, setPacienteActual };
const mapStateToProps = (state) => {
    const { preguntas, pacienteActual } = state.Think;
    return { preguntas, pacienteActual };
};

export default connect(mapStateToProps, actions)(InputEdit);

export const SET_LOGGEDIN = "sales/set_loggedin";
export const SET_EMPRESA = "sales/set_empresa";
export const SET_CAJA = "sales/set_caja";
export const RESET_VENTA = "sales/reset_venta";
export const SET_CLIENTE = "sales/set_cliente";
export const SET_CUENTA = "sales/set_cuenta";

export const SET_CATALOGO = "sales/set_catalogo";

export const ADD_PRODUCTO = "sales/add_producto";
export const EDIT_PRODUCTO = "sales/edit_producto";
export const DELETE_PRODUCTO = "sales/delete_producto";

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid, Image, Card, Input } from 'semantic-ui-react';
import internalAPI from '../../../utils/internalAPI';
import HelpBarThink from '../../../material-ui/HelpBarThink';
import ConfirmAlert from '../../../material-ui/ConfirmAlert';

const useStyles = makeStyles(theme => ({}));

const styles = {
  titleHelp: {
    width: '100%',
    padding: 3,
    height: 37,
    color: 'white',
  },
  item: {
    padding: 0,
    paddingBottom: 3,
    margin: 0,
    width: '100%'
  }
};

const EditButton = props => {
  const {onClick} = props
  const style = {width: "calc(100% - 6px)", margin: 3,marginBottom:0}
  const roles = window.amplify.store('roles');
  const rolesCan = [2,5] // Roles que tienen permitido editar
  const enable = roles.some(v => rolesCan.includes(v.id_rol))

  return ( <Button 
    variant="outlined" color='primary' style={style}
    onClick={onClick} 
    disabled={!enable}
  >
    Editar
  </Button> );
}

const RemoveButton = props => {
  const [confirm, setConfirm] = React.useState(false);
  const style = {width: "calc(100% - 6px)", margin: 3,marginBottom:0}
  const institucion = window.amplify.store('institution');
  const roles = window.amplify.store('roles');
  const rolesCan = [2] // Roles que tienen permitido eliminar

  const enable = roles.some(v => rolesCan.includes(v.id_rol))
  const isUserAdmin = (institucion && institucion.id_usuario === props.id_usuario);

  return (
    <React.Fragment>
      {confirm && (
        <ConfirmAlert
          title={`¿Desea eliminar ${props.title}?`}
          onAccept={() => {
            props.remove();
          }}
          onCancel={(e) => {
            e.stopPropagation();
            setConfirm(false);
          }}
        />
      )}

      <Button 
        variant="outlined" color='primary' style={style}
        onClick={() => setConfirm(true)}  
        disabled={isUserAdmin || !enable} 
      >
        Eliminar
      </Button>
    </React.Fragment>
  );
}

export default props => {
  const classes = useStyles();
  const { typeRender, selectedRow } = props;

  const [searchText, setSearchText] = React.useState('');
  const [usuarios, setUsuarios] = React.useState([]);
  const [indexSelected, setIndexSelected] = React.useState(0);

  const onKeyUp = e => {
    e.preventDefault();
    e.stopPropagation();

    if (e.keyCode===38) {
      if (indexSelected>0) {
        setIndexSelected(indexSelected - 1);
      }
    } else if (e.keyCode===40) {
      if (indexSelected<usuarios.length) {
        setIndexSelected(indexSelected + 1);
      }
    } else if (e.keyCode===13) {
      const options = usuarios.map(usuario => {
        const vc_nombre = `${usuario.vc_nombre} ${usuario.vc_apellidoPaterno} ${usuario.vc_apellidoMaterno}`;
        return { vc_nombre, value: usuario.id };
      });

      props.onAdd(options[indexSelected]);
    }
  }

  React.useEffect(() => {
    if (searchText.length>2) {
      const id_institucion = window.amplify.store('institution').id_institucion;
      const body = {
        id_institucion,
        texto: searchText
      };

      internalAPI.searchUsuarios(body, (err, response) => {
        if (response.length>0) {
          setUsuarios(response);
        }
      });
    }
  }, [searchText]);

  let title = '';
  let content = null;

  if (typeRender==='user') {
    const { roles, agendas, detalle } = selectedRow;
    const {
      id_sexo = 0,
      vc_nombre = '',
      vc_apellidoPaterno = '',
      vc_apellidoMaterno = '',
      vc_email = '',
      id_usuario
    } = detalle || {};

    const maxShow = 2
    const lengthAgendas = agendas.length

    title = 'Usuario';

    content = (
      <React.Fragment>
        <Grid centered style={{width: '100%', padding: 4, paddingBottom: 0, margin: 0}}>
          <Paper style={{width: '100%', padding: 0, margin: 0, paddingTop: 15}}>
            <Image size='small' centered src={id_sexo === 1 ? 'assets/images/hombre.png' : 'assets/images/mujer.png'} />
            <Card.Content>
              <Card.Header>
                {vc_nombre}
              </Card.Header>
              <Card.Header>
                {vc_apellidoPaterno}
              </Card.Header>
              <Card.Header>
                {vc_apellidoMaterno}
              </Card.Header>
              <Card.Description>
                Sexo: {id_sexo === 1 ? 'Masculino' : 'Femenino'}
              </Card.Description>
              <Card.Description>
                email: {vc_email}
              </Card.Description>
              <Card.Description>
                {roles.length>0 && (
                  `Roles asignados: ${roles.map(rol => rol.vc_nombre).join(', ')}`
                )}

                {roles.length===0 && (
                  'Sin roles asignados'
                )}
              </Card.Description>

              <Card.Description>
                {lengthAgendas > 0 && lengthAgendas <= maxShow && (
                  `Agendas asignados: ${agendas.map(agenda => agenda.vc_nombre).join(', ')}`
                )}
                
                {lengthAgendas > maxShow && (
                  `Agendas asignados: ${[...agendas].slice(0, maxShow).map(agenda => agenda.vc_nombre).join(', ')}, +${lengthAgendas - maxShow}`
                )}

                {agendas.length===0 && (
                  'Sin agendas asignadas.'
                )}
              </Card.Description>
            </Card.Content>
          </Paper>
        </Grid>
        <Grid.Row style={{flexDirection: 'column'}}>
          
          {/* <Button variant="outlined" color='primary' onClick={props.edit} style={{width: "calc(100% - 6px)", margin: 3,marginBottom:0}}>Editar</Button> */}
          <EditButton onClick={props.edit} />
          <RemoveButton remove={props.remove} id_usuario={id_usuario} title={`${vc_nombre} ${vc_apellidoPaterno} ${vc_apellidoMaterno}`} />

        </Grid.Row>
      </React.Fragment>
    );
  } else if (typeRender==='search') {
    const options = usuarios.map(item => {
      let usuario = {};

      if (Boolean(item.detalle)) {
        usuario = item.detalle;
      }

      const vc_nombre = `${usuario.vc_nombre} ${usuario.vc_apellidoPaterno} ${usuario.vc_apellidoMaterno}`;
      return { vc_nombre, value: usuario.id };
    });

    const labelDisplays = [
      {key: 'vc_nombre', style:{width:'100%'}},
      {key: 'cve_cie10', style: {color:window.colors.itemSelected, textAlign:'right', width:'100%' }}
    ];

    const optionStyle = {
      flexDirection: 'column',
    }

    return (
      <HelpBarThink
          header="Seleccione usuario"
          searchBar={true}
          options={options}
          optionsAction={user => props.onAdd(usuarios.find(usuario => usuario.id===user.value))}
          indexSeleccionado={indexSelected}
          onChangeValue={e => setSearchText(e.target.value)}
          onKeyUp={onKeyUp}
          searchText={searchText}
          labelDisplays={labelDisplays}
          optionStyle={optionStyle}
      />
    );
  }

  return (
    <Paper style={{ padding: 0, border: 0, borderRadius: 0, height: '100%' }}>
      <AppBar position="relative" style={{ height: 95 }}>
        <Toolbar>
          <Typography variant="h6" style={{textAlign: 'center', width: '100%', flexDirection: 'column', verticalAlign: 'center', justifyItems:'center'}}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ height: 'calc(100% - 95px)', width: '100%', overflow: 'hidden', WebkitOverflowScrolling: 'touch', padding: 0, margin: 0 }}>
        {content}
      </div>
    </Paper>
  );
};
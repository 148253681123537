import React from 'react';
import { Route } from 'react-router';
import { combineReducers } from 'redux';
import GlobalWrapper from './GlobalWrapper';
import Think from './Think';
import Sales from 'app/sales/store/reducer'


const modules = {
  reducers: combineReducers({
    Think: Think.reducer,
    Sales,
  }),
  Routes: (
    <Route path="/" component={GlobalWrapper}>
      { Think.Routes }
    </Route>
  )
};


export default modules;

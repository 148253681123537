import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import { Avatar } from '@material-ui/core';
let theme = createMuiTheme({
    palette: {
        primary: teal,
        secondary: {
            main: '#fafafa'
        },
        type: 'light'
    }
});

class PrintForm extends Component {

    render() {
        const logoCentral = (this.props.rol || {}).vc_urlLogoCentral || null;
        console.log('logo', this.props)
        return (
            <div style={{ position: 'relative' }}>
                {/* {this.props.header}
                {this.props.content}
                {this.props.footer} */}
                <ThemeProvider theme={theme}>
                    <table style={{ width: '100%', minHeight: '100%' }}>
                        <thead>
                            <tr>
                                <td style={{ marginBottom: 10, breakAfter: 'page', breakBefore: 'page', color: 'black', minHeight: '100%', position: 'relative' }}>
                                    {this.props.header}
                                    <div style={{
                                        width: '100%',
                                        height: 700,
                                        float: 'center',
                                        position: this.props.viewMode ? 'absolute' : 'fixed',
                                        // color: 'black',
                                        opacity: 0.05,
                                        // backgroundImage: logoCentral ? `url(${logoCentral || 'logo512.png'})` : undefined,
                                        // backgroundRepeat: 'no-repeat',
                                        // backgroundSize: '600px 600px',
                                        // backgroundPosition: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyItems: 'center',
                                        display: 'flex'
                                    }}>
                                        <img src={logoCentral} alt="Girl in a jacket" width="600" height="600" style={{ display: 'flex', alignSelf: 'center'}}/>
                                    </div>
                                </td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>
                                    {this.props.content}
                                </td>
                            </tr>
                        </tbody>

                        <tfoot>
                            <tr>
                                <td style={{ width: '100%', }}>
                                    <div style={{ opacity: 0, bottom: 10, width: '100%', }}>
                                        {this.props.footer}
                                    </div>
                                    <div style={{ width: '100%', margin: 0, position: this.props.viewMode ? 'relative' : 'fixed', bottom: 10, breakAfter: 'page', breakBefore: 'page' }}>
                                        {this.props.footer}
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                </ThemeProvider>

                {this.props.backResource ?
                    this.props.backResource
                    :
                    <div />
                }
            </div>

            // <div style={{width: '100%', margin: 0, height: '100%'}}>
            //     <table style={{width: '100%', margin: 0}}>
            //         <thead style={{width: '100%', margin: 0}}>
            //             <tr style={{width: '100%', margin: 0}}>
            //                 <td style={{width: '100%', margin: 0, marginBottom: this.props.marginHeader || 10, breakAfter: 'page', breakBefore: 'page'}}>
            //                     {this.props.header}
            //                 </td>
            //             </tr>
            //         </thead>
            //         <tbody style={{width: '100%', margin: 0}}>
            //             <tr style={{width: '100%', margin: 0}}>
            //                 <td style={{width: '100%', margin: 0}}>
            //                     <div style={{width: '100%', margin: 0}}>{this.props.content}</div>
            //                 </td>
            //             </tr>
            //         </tbody>
            //         <tfoot style={{width: '100%', margin: 0, height: '100%'}}>
            //             <tr style={{width: '100%', margin: 0, height: '100%'}}>
            //                 <td style={{width: '100%', margin: 0, height: '100%'}}>

            //                     <div style={{opacity: 0, bottom: 10, marginTop: this.props.marginFooter || 10}}>
            //                         {this.props.footer}                          
            //                     </div>

            //                     {/* <div style={{width: '100%', margin: 0, position: 'fixed', bottom: 10, breakAfter: 'page', breakBefore: 'page', overflow: 'hidden'}}>
            //                         {this.props.backResource ? 
            //                             <div style={{width: '100%', maxWidth: '90%', margin: 0, height: 600}}>
            //                                 {this.props.backResource}
            //                             </div>
            //                         :
            //                         <div/>
            //                         }
            //                         {this.props.footer}                          
            //                     </div> */}
            //                 </td>
            //             </tr>
            //         </tfoot>
            //     </table>                
            // </div>
        )
    }
}
export default (PrintForm);

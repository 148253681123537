export const menuPacientes = [
    {
        id: 'listado',
        vc_nombre: 'Catalogo de Pacientes',
        enable: true,
        sidebar: {
            open: false,
            enable: false,
        },
        opciones: [
            {
                id: 'listado',
                vc_nombre: 'Listado de Pacientes',
                acciones: [
                    // {
                    //     id: 'nuevo',
                    //     vc_nombre: 'Nuevo',
                    //     icon: null
                    // }
                ]
            }
        ]
    },
    {
        id: 'registrar',
        vc_nombre: 'Registro de Pacientes',
        enable: true,
        sidebar: {
            open: false,
            enable: false,
        },
        opciones: [
            {
                id: 'nuevo',
                vc_nombre: 'Agregar Paciente',
                acciones: [
                    {
                        id: 'guardar',
                        vc_nombre: 'Guardar',
                        icon: null
                    },
                    {
                        id: 'limpiar',
                        vc_nombre: 'Limpiar',
                        icon: null
                    },
                ]
            },
            {
                id: 'editar',
                vc_nombre: 'Agregar Paciente',
                acciones: [
                    {
                        id: 'guardar',
                        vc_nombre: 'Actualizar',
                        icon: null
                    },
                ]
            }
        ]
    },
    {
        id: 'callcenter',
        vc_nombre: 'Covid-19',
        enable: true,
        sidebar: {
            open: false,
            enable: false,
        },
        opciones: [
            {
                id: '1',
                vc_nombre: 'Jurisdicción I',
                acciones: [
                    {
                        id: 'filtrar',
                        vc_nombre: 'Filtrar',
                        icon: null
                    },
                    {
                        id: 'mapear',
                        vc_nombre: 'Mapa',
                        icon: null
                    },
                    {
                        id: 'stats',
                        vc_nombre: 'Graficas',
                        icon: null
                    }
                ]
            },
            {
                id: '2',
                vc_nombre: 'Jurisdicción II',
                acciones: [
                    {
                        id: 'filtrar',
                        vc_nombre: 'Filtrar',
                        icon: null
                    },
                    {
                        id: 'mapear',
                        vc_nombre: 'Mapa',
                        icon: null
                    },
                    {
                        id: 'stats',
                        vc_nombre: 'Graficas',
                        icon: null
                    }
                ]
            },
            {
                id: '3',
                vc_nombre: 'Jurisdicción III',
                acciones: [
                    {
                        id: 'filtrar',
                        vc_nombre: 'Filtrar',
                        icon: null
                    },
                    {
                        id: 'mapear',
                        vc_nombre: 'Mapa',
                        icon: null
                    },
                    {
                        id: 'stats',
                        vc_nombre: 'Graficas',
                        icon: null
                    }
                ]
            },
            {
                id: '4',
                vc_nombre: 'Jurisdicción IV',
                acciones: [
                    {
                        id: 'filtrar',
                        vc_nombre: 'Filtrar',
                        icon: null
                    },
                    {
                        id: 'mapear',
                        vc_nombre: 'Mapa',
                        icon: null
                    },
                    {
                        id: 'stats',
                        vc_nombre: 'Graficas',
                        icon: null
                    }
                ]
            },
            {
                id: '5',
                vc_nombre: 'Jurisdicción VI',
                acciones: [
                    {
                        id: 'filtrar',
                        vc_nombre: 'Filtrar',
                        icon: null
                    },
                    {
                        id: 'mapear',
                        vc_nombre: 'Mapa',
                        icon: null
                    },
                    {
                        id: 'stats',
                        vc_nombre: 'Graficas',
                        icon: null
                    }
                ]
            },
            {
                id: '6',
                vc_nombre: 'IMSS',
                acciones: [
                    {
                        id: 'filtrar',
                        vc_nombre: 'Filtrar',
                        icon: null
                    },
                    {
                        id: 'mapear',
                        vc_nombre: 'Mapa',
                        icon: null
                    },
                    {
                        id: 'stats',
                        vc_nombre: 'Graficas',
                        icon: null
                    }
                ]
            },
            {
                id: '7',
                vc_nombre: 'ISSSTE',
                acciones: [
                    {
                        id: 'filtrar',
                        vc_nombre: 'Filtrar',
                        icon: null
                    },
                    {
                        id: 'mapear',
                        vc_nombre: 'Mapa',
                        icon: null
                    },
                    {
                        id: 'stats',
                        vc_nombre: 'Graficas',
                        icon: null
                    }
                ]
            },
            {
                id: '8',
                vc_nombre: 'SEDENA',
                acciones: [
                    {
                        id: 'filtrar',
                        vc_nombre: 'Filtrar',
                        icon: null
                    },
                    {
                        id: 'mapear',
                        vc_nombre: 'Mapa',
                        icon: null
                    },
                    {
                        id: 'stats',
                        vc_nombre: 'Graficas',
                        icon: null
                    }
                ]
            },
            {
                id: '9',
                vc_nombre: 'SEMAR',
                acciones: [
                    {
                        id: 'filtrar',
                        vc_nombre: 'Filtrar',
                        icon: null
                    },
                    {
                        id: 'mapear',
                        vc_nombre: 'Mapa',
                        icon: null
                    },
                    {
                        id: 'stats',
                        vc_nombre: 'Graficas',
                        icon: null
                    }
                ]
            },
            {
                id: '10',
                vc_nombre: 'PEMEX',
                acciones: [
                    {
                        id: 'filtrar',
                        vc_nombre: 'Filtrar',
                        icon: null
                    },
                    {
                        id: 'mapear',
                        vc_nombre: 'Mapa',
                        icon: null
                    },
                    {
                        id: 'stats',
                        vc_nombre: 'Graficas',
                        icon: null
                    }
                ]
            },
            {
                id: '11',
                vc_nombre: 'Todos',
                acciones: [
                    {
                        id: 'filtrar',
                        vc_nombre: 'Filtrar',
                        icon: null
                    },
                    {
                        id: 'mapear',
                        vc_nombre: 'Mapa',
                        icon: null
                    },
                    {
                        id: 'stats',
                        vc_nombre: 'Graficas',
                        icon: null
                    }
                ]
            }
        ]
    }
];

import React from 'react';
import { connect } from 'react-redux';
import { isString } from 'util';
import { setState } from '../../containers/Think/actions';

// Material ui
import { styled, withStyles } from '@material-ui/core/styles';
import Search from "@material-ui/icons/Search";
import { teal } from '@material-ui/core/colors';
import { Box, ListItem, Toolbar, Typography, AppBar, List, Input, ListItemText, Paper, Button, Tabs, Tab, Tooltip, Divider } from '@material-ui/core';
import Check from '@material-ui/icons/Check';

// const TooltipThink = styled(Tooltip)({
//     backgroundColor: 'green'
// });

const TooltipThink = withStyles(theme => ({
    tooltip: {
        backgroundColor: 'transparent !important',
        // border: '1px solid #dadde9',
    },
}))(Tooltip);

const modulo = 'helpbar';
class Index extends React.Component {
    constructor() {
        super();

        this.state = {
            searchText: '',
            value: 0,
            open: false,
        };
    }

    render() {
        if (this.state.value != 0)
            this.setState({ value: 0 })

        return (
            <Paper style={{ height: '100%', overflow: 'hidden', borderRadius: 0 }}>
                <Box>
                    {this.renderHeader()}
                    {this.renderItemSelected()}
                    {this.renderMenu()}
                    {this.renderSearchBar()}
                    <Divider />
                </Box>
                <div id='scrollSelected' style={{ maxHeight: 'calc(100% - 136px)', overflow: 'scroll', WebkitOverflowScrolling: 'touch', paddingBottom: 20 }}>
                    {this.renderOptions()}
                    {this.renderOptionsRender()}

                </div>
            </Paper>
        );


        return (
            <Paper style={{ height: 'calc(100% - 1px + 1px)', overflowX: 'hidden', paddingTop: 111 }}>
                {this.renderOptions()}
                {/* <Box style={{position: 'absolute', top: 0, width: '100%'}}> */}
                <Paper style={{ position: 'relative', top: 0, width: '100%' }}>
                    {this.renderHeader(1)}
                    {this.renderSearchBar(1)}
                </Paper >
                {/* </Box > */}
            </Paper>
        );
    }

    // ---------------------------------
    // ------ life cycle events --------
    // ---------------------------------

    componentDidMount() {
        this.setState({ someKey: 'otherValue' });
    }

    componentDidUpdate() {
        const select = document.getElementById('selected');
        if (select && this.props.scrollToTop !== undefined) {
            select.scrollIntoView({
                block: 'nearest'
            })

        }
    }

    // ------------------------
    // ------  methods --------
    // ------------------------

    handleSearch(e) {
        this.setState({ searchText: e.target.value })
    }

    isSelected(item) {
        const isSelected = window._.get(this.props, 'selections', []).find(e => e.id === item.id)
        if (isSelected)
            return <Check color='primary' style={{ float: 'right' }} />
    }

    // ------------------------------
    // ------ render methods --------
    // ------------------------------


    renderHeader(opacity) {
        let { header } = this.props;
        if (isString(header)) {
            header = { title: header };
        }
        return (
            <AppBar position={'static'} color='primary' key={`header-${opacity}`} style={{ height: header.height || 95, opacity }}>
                <Toolbar color='primary' key={`header-${opacity}`} style={{ height: header.height || 95, opacity }}>
                    <Typography style={{ textAlign: 'center', width: '100%', flexDirection: 'column', verticalAlign: 'center', justifyItems: 'center' }}>
                        {header.title || 'Title'}
                    </Typography>
                </Toolbar>
            </AppBar>

        )
    }

    renderMenu() {
        const { menu } = this.props;

        if (menu && menu.length) {
            return (
                <Box>
                    <Tabs
                        value={this.props.menuValue}
                        onChange={(e, v) => { }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {menu.map((v, i) => {
                            return <Tab
                                key={i}
                                value={v.value}
                                label={v.vc_nombre}
                                style={{ minWidth: 'auto' }}
                                onClick={() => {
                                    v.action()
                                }}
                            />
                        })}
                    </Tabs>
                    <Divider />
                </Box>
            );
        }
    }

    renderSearchBar() {
        if (!this.props.searchBar) return;
        const searchBarHeight = this.props.searchBar.height || 40;
        return (
            <ListItem style={{ padding: '5px 8px 3px 10px', height: searchBarHeight, zIndex: 0 }}>
                <Input
                    onChangeCapture={this.props.onChangeValue}
                    onKeyUp={this.props.onKeyUp}
                    value={this.props.searchText || ''}
                    placeholder="Buscar..."
                    disableUnderline
                    style={{ width: '100%' }}
                    startAdornment={this.props.searchStartIcon || <Search style={{ margin: 10 }} />}
                    endAdornment={this.props.searchEndIcon || null}
                />
            </ListItem>
        );
    }

    renderLabels(item) {
        const labelDisplays = this.props.labelDisplays || [{ key: 'vc_nombre' }]
        return labelDisplays.map((atributte, index) => {
            return (
                <Typography key={index} style={{ whiteSpace: 'normal', ...atributte.style || {} }}>
                    {item[atributte.key]}
                </Typography>
            )
        })
    }

    renderOptions() {
        const options = this.props.options || [];
        const optionStyle = this.props.optionStyle || {};

        if (options.length === 0) return;
        const optionsAction = this.props.optionsAction || null;
        return options.map((option, index) => {
            const tooltipContent = this.props.tooltip ? this.props.tooltip(option) : null;
            return (
                tooltipContent ?
                    <TooltipThink title={tooltipContent} key={index} placement="left-start" style={{ backgroundColor: 'transparent' }} >
                        <ListItem
                            selected={index === this.props.indexSeleccionado}
                            id={index === this.props.indexSeleccionado ? 'selected' : `helpbar-${index}`}
                            button
                            onClick={() => {
                                optionsAction(option)
                            }}
                            key={index}
                            divider
                            style={{ ...optionStyle }}
                        >
                            {this.renderLabels(option)}

                            <div style={{ float: 'right' }}>
                                {
                                    this.props.selections ?
                                        this.isSelected(option)
                                        :
                                        null
                                }
                            </div>
                        </ListItem>

                    </TooltipThink>


                    :
                    <ListItem
                        divider
                        selected={index === this.props.indexSeleccionado}
                        id={index === this.props.indexSeleccionado ? 'selected' : `helpbar-${index}`}
                        button
                        onClick={() => {
                            optionsAction(option)
                        }}
                        key={index}
                        style={{ ...optionStyle }}
                    >
                        {this.renderLabels(option)}

                        <div style={{ float: 'right' }}>
                            {
                                this.props.selections ?
                                    this.isSelected(option)
                                    :
                                    null
                            }
                        </div>
                    </ListItem>
            );
        })
    }

    renderOptionsRender() {
        const options = this.props.optionsRender || [];
        if (options.length === 0) return;
        const optionsAction = this.props.optionsAction || null;
        return options.map((option, index) => {
            return (
                <ListItem
                    key={index}
                >
                    {option}
                    {/* <ListItemText primary={option.vc_nombre} style={{whiteSpace: 'normal', minHeight: 50}}/> */}
                    <div style={{ float: 'right' }}>
                        {
                            this.props.selections ?
                                this.isSelected(option)
                                :
                                null
                        }
                    </div>
                </ListItem>
            );
        })
    }


    renderItemSelected() {
        if (this.props.item && this.props.itemSkeleton && window.isMobile) {
            const Component = this.props.itemSkeleton;
            return (
                <Component
                    item={this.props.item}
                    sn_printVersion={true}
                    reload={(e => {
                        this.forceUpdate();
                    })}
                />
            );
        }

    }



}
const mapStateToProps = (state) => {
    return {
        state: state.Think[modulo] || null
    };
};


const actions = {
    setState
};

export default connect(mapStateToProps, actions)(Index);

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Paper, Tooltip, Typography, makeStyles, Box } from "@material-ui/core";
import moment from "moment";
import { Lens } from "@material-ui/icons";
import classNames from "classnames";

const useStyles = makeStyles((theme) => {
  return {
    box: {
      position: "absolute",
      width: "100%",
      zIndex: 20,
      padding: "0 3px",
    },
    event: {
      height: "100%",
      width: "100%",
      textTransform: "capitalize",
      padding: "0px 10px",
      display: "flex",
      alignItems: "center",
      border: "1px solid transparent",
      boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
      "& .nombre": {
        fontWeight: "bold",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      "&.selected": {
        borderColor: theme.palette.primary.main,
      },
      "&.notSelected": {
        opacity: 0.8,
      },
    },
  };
});
const getMinutes = (time) => time.hours() * 60 + time.minutes();

const Index = (props) => {
  const classes = useStyles();
  const {
    data,
    from,
    rowHeight,
    rowTime,
    onClick,
    estatusCitas,
    selected,
  } = props;

  const { tm_inicio: inicio, tm_fin: fin } = data;

  const minFrom = getMinutes(moment(from, "HH:mm:ss"));
  const minInicio = getMinutes(moment(inicio, "HH:mm:ss")) - minFrom;
  const minFin = getMinutes(moment(fin, "HH:mm:ss"));

  const ratio = rowHeight / rowTime;
  const top = minInicio * ratio;
  const height = (minFin - minInicio - minFrom) * ratio - 1;
  const fontSize = height <= 16 ? height - 3 : 15;
  const estatus = estatusCitas.find((v) => v.id === data.id_estatusCita);

  const classesNames = classNames(classes.event, {
    selected: selected && selected.id === data.id,
    notSelected: selected && selected.id !== data.id,
  });

  return (
    <Box className={classes.box} top={top} height={height}>
      <Paper className={classesNames} onClick={onClick}>
        <Tooltip title={estatus ? estatus.vc_nombre : ""}>
          <Lens
            style={{
              marginRight: "4px",
              fontSize,
              color: estatus ? estatus.vc_color : "primary",
            }}
          />
        </Tooltip>
        <Typography variant="caption" style={{ marginRight: "8px" }}>
          {moment(inicio, "HH:mm:ss").format("HH:mm")}
        </Typography>
        <Typography className="nombre" color="primary" style={{ fontSize }}>
          {`${data.vc_nombre} ${data.vc_apellidoPaterno} ${data.vc_apellidoMaterno}`
            .trim()
            .toLowerCase()}
        </Typography>
      </Paper>
    </Box>
  );
};

Index.defaultProps = {
  event: null,
  estatusCitas: [],
};

Index.propTypes = {
  data: PropTypes.array,
  from: PropTypes.string,
  rowTime: PropTypes.number,
  rowHeight: PropTypes.number,
  onClick: PropTypes.func,
  estatusCitas: PropTypes.array,
  selected: PropTypes.object,
};

const actions = {};

const mapStateToProps = ({ Think: { estatusCitas } }) => ({ estatusCitas });

export default connect(mapStateToProps, actions)(Index);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Segment, Loader, Dimmer, Header, Button } from 'semantic-ui-react';
import { SidebarForm, CustomTable } from '../../../../../components';
import internalAPI from '../../../../../utils/internalAPI';
import notifications from '../../../../../utils/notifications';
import formatter from '../../../../../utils/formatter';
import { LIGHT_GREEN } from '../../colores';
import { CheckBoxCell, BotonesAccion } from './Cells';


const { Row, Column } = Grid;


class VicularUsuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ubicacion: {},
      detalle: {
        id_sexo: props.selectedUser.id_sexo,
        vc_nombre: props.selectedUser.vc_nombre,
        vc_apellidoPaterno: props.selectedUser.vc_apellidoPaterno,
        vc_apellidoMaterno: props.selectedUser.vc_apellidoMaterno,
        vc_email: props.selectedUser.vc_email,
      },
      rolesList: [],
      institucion: null,
      selectedDependencia: {},
      id_dependencia: null,
      selectedArea: {},
      id_area: null,
      agendasList: [],
      checkboxesSelected: [],
      editarUbicacion: false,
      agregarRol: false,
      roles: [],
      siderInfoRoles: null,
    };
  }

  componentDidMount() {
    this.getInstitution();
    this.getRoles();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.editarUbicacion !== this.state.editarUbicacion && !this.state.editarUbicacion) || (prevState.agregarRol !== this.state.agregarRol && !this.state.agregarRol)) {
      this.props.activaCapturaUsuario();
    }
  }

  // ---------------------------
  // -------- methods ----------
  // ---------------------------
  onAgregarRol() {
    const { siderInfoRoles, agregarRol } = this.state;
    const { formacionesList, especialidadesList } = this.props;

    if (agregarRol) {
      if (!siderInfoRoles.id_rol) {
        notifications.error('Falta seleccionar el rol');
      } else if (!siderInfoRoles.id_formacion) {
        notifications.error('Falta seleccionar la formacion academica');
      } else if (!siderInfoRoles.id_especialidad) {
        notifications.error('Falta seleccionar la especialidad');
      } else if (!siderInfoRoles.vc_cedulaProfecial) {
        notifications.error('Falta ingresar la cedula profesional');
      } else {
        const rolName = this.state.rolesList.filter((rol) => rol.id === siderInfoRoles.id_rol)[0];
        const formacionName = formacionesList.filter((rol) => rol.id === siderInfoRoles.id_formacion)[0];
        const especialidadName = especialidadesList.filter((rol) => rol.id === siderInfoRoles.id_especialidad)[0];

        const { roles } = this.state;

        siderInfoRoles.nombreRol = rolName.vc_nombre;
        siderInfoRoles.nombreFormacion = formacionName.vc_nombre;
        siderInfoRoles.nombreEspecialidad = especialidadName.vc_nombre;


        roles.push(siderInfoRoles);

        this.setState({ roles, agregarRol: !agregarRol });
      }
    } else {
      this.setState({ agregarRol: !agregarRol });
    }
  }

  onDeleteRol(rol) {
    const roles = [];

    this.state.roles.forEach((rolItem) => {
      if (rolItem.id_rol !== rol.id_rol) {
        roles.push(rolItem);
      }
    });

    this.setState({ roles });
  }

  getInstitution() {
    const id = window.amplify.store('institution').id_institucion;

    internalAPI.getInstitution(id, (error, response) => {
      this.setState({ loading: false });
      if (error) {
        notifications.error(error.error);
      } else {
        const agendasList = [];

        response.dependencias.forEach(dependencia => {
          dependencia.agendas.forEach(agenda => agendasList.push({
            ...agenda,
            nombre_dependencia: dependencia.vc_nombre
          }));
        });

        this.setState({ institucion: response, agendasList });
      }
    });
  }

  getRoles() {
    internalAPI.getRoles((error, response) => {
      this.setState({ loading: false });
      if (error) {
        notifications.error(error.error);
      } else {
        this.setState({ rolesList: response });
      }
    });
  }

  getInputsUbicacion() {
    const { institucion, selectedDependencia } = this.state;
    const opcionesDependencia = institucion.dependencias.map((dependencia) => ({
      label: dependencia.vc_nombre,
      value: dependencia.id,
    }));

    let optionsArea = [];

    if (Object.keys(selectedDependencia).length) {
      optionsArea = selectedDependencia.areas.map((area) => ({
        label: area.vc_nombre,
        value: area.id_area,
      }));
    }

    return [
      {
        name: 'id_dependencia',
        type: 'dropdown',
        label: 'Depéndencia',
        placeholder: 'Ingresa',
        options: opcionesDependencia,
        required: true,
      },
      {
        name: 'id_area',
        type: 'dropdown',
        label: 'Área',
        placeholder: 'Ingresa',
        options: optionsArea,
        required: false,
      },
      {
        name: 'vc_registroSSA',
        label: 'Registro SSA',
        placeholder: 'Ingresa',
        options: [],
        required: false,
      },
      {
        name: 'vc_urlLogoIzquierdo',
        label: 'Logo izquierdo',
        placeholder: 'Ingresa',
        options: [],
        required: false,
      },
      {
        name: 'vc_urlLogoCentral',
        label: 'Logo central',
        placeholder: 'Ingresa',
        options: [],
        required: false,
      },
      {
        name: 'vc_direccionReceta',
        label: 'Dirección receta',
        placeholder: 'Ingresa',
        options: [],
        required: false,
      }
    ];
  }

  getInputsRoles() {
    const { rolesList } = this.state;
    const opcionesRoles = rolesList.map((dependencia) => ({
      label: dependencia.vc_nombre,
      value: dependencia.id,
    }));

    const opcionesformacionAcademica = this.props.formacionesList.map((dependencia) => ({
      label: dependencia.vc_nombre,
      value: dependencia.id,
    }));

    const opcionesEspecialidades = this.props.especialidadesList.map((dependencia) => ({
      label: dependencia.vc_nombre,
      value: dependencia.id,
    }));

    return [
      {
        name: 'id_rol',
        label: 'Roles',
        placeholder: 'Ingresa',
        options: opcionesRoles,
        required: true,
      },
      {
        name: 'id_formacion',
        label: 'Formación',
        placeholder: 'Ingresa',
        options: opcionesformacionAcademica,
        required: false,
        optimized: true,
      },
      {
        name: 'id_especialidad',
        label: 'Especialidad',
        placeholder: 'Ingresa',
        options: opcionesEspecialidades,
        required: false,
      },
      {
        name: 'vc_cedulaProfecial',
        label: 'Cedula profesional',
        placeholder: 'Ingresa',
        options: [],
        required: false,
      },
    ];
  }

  getData() {
    const id_institucion = window.amplify.store('institution').id_institucion;
    const { selectedUser } = this.props;
    const { ubicacion, checkboxesSelected } = this.state;
    const detalle = [
      {
        id_institucion,
        ...this.state.detalle,
      }
    ];

    const agendas = [];

    checkboxesSelected.forEach(id => {
      agendas.push({ id_agenda: id });
    });

    const roles = this.state.roles.map((rol) => ({
      id_rol: rol.id_rol,
      id_formacion: rol.id_formacion,
      id_especialidad: rol.id_especialidad,
      vc_cedulaProfecial: rol.vc_cedulaProfecial,
    }));

    const ubicacionData = {};

    Object.keys(ubicacion).forEach((fieldName) => {
      ubicacionData[fieldName] = ubicacion[fieldName] ? ubicacion[fieldName].value : '';
    });


    if (!ubicacionData.id_dependencia) {
      notifications.error('Falta seleccionar una dependencia');
    }

    return {
      id_institucion,
      id_usuarioGlobal: selectedUser.id,
      id_dependencia: ubicacionData.id_dependencia,
      id_area: ubicacionData.id_area,
      vc_registroSSA: ubicacionData.vc_registroSSA,
      vc_urlLogoIzquierdo: ubicacionData.vc_urlLogoIzquierdo,
      vc_urlLogoCentral: ubicacionData.vc_urlLogoCentral,
      vc_direccionReceta: ubicacionData.vc_direccionReceta,
      detalle,
      agendas,
      roles,
    };
  }

  getHeaders() {
    return [
      {
        label: '',
        align: 'center',
        component: (
          <CheckBoxCell
            checkboxesSelected={this.state.checkboxesSelected}
            selectToInvoice={this.selectTravelToInvoice.bind(this)}
          />
        ),
      },
      {
        attribute: 'nombre_dependencia',
        label: 'NOMBRE DEPEDENCIA',
        align: 'center',
      },
      {
        attribute: 'vc_nombre',
        label: 'NOMBRE AGENDA',
        align: 'center',
      },
      {
        attribute: 'dt_registro',
        label: 'FECHA DE REGISTRO',
        format: 'dateTime',
      },
    ];
  }

  getHeadersRoles() {
    return [
      {
        attribute: 'nombreRol',
        label: 'NOMBRE ROL',
        align: 'center',
      },
      {
        attribute: 'nombreFormacion',
        label: 'FORMACION ACADEMICA',
        align: 'center',
      },
      {
        attribute: 'nombreEspecialidad',
        label: 'ESPECIALIDAD',
        align: 'center',
      },
      {
        attribute: 'vc_cedulaProfecial',
        label: 'CEDULA PROFESIONAL',
        align: 'center',
      },
      {
        label: '',
        component: (
          <BotonesAccion
            onDeleteRol={this.onDeleteRol.bind(this)}
          />),
      },
    ];
  }

  selectTravelToInvoice(agendaId) {
    const { checkboxesSelected } = this.state;
    let newCheckboxesSelected = [];

    if (checkboxesSelected.includes(agendaId)) {
      checkboxesSelected.forEach(checkbox => {
        if (checkbox !== agendaId) {
          newCheckboxesSelected.push(checkbox);
        }
      });
    } else {
      newCheckboxesSelected = checkboxesSelected;
      newCheckboxesSelected.push(agendaId);
    }

    this.setState({ checkboxesSelected: newCheckboxesSelected });
  }

  // ---------------------------
  // ------ methods render -----
  // ---------------------------
  renderUbicacion() {
    if (this.state.institucion && this.state.editarUbicacion) {
      return (
        <Grid style={{ margin: 5 }} >
          <Column width={16}>
            <SidebarForm
              ref={(ref) => {
                if (ref && ref.getWrappedInstance) {
                  this.SidebarFormUbicacion = ref.getWrappedInstance();
                }
              }}
              style={{ paddingRight: 10, paddingLeft: 10, marginTop: 20 }}
              fields={this.getInputsUbicacion()}
              onSubmit={(values) => {
                this.setState({ ubicacion: values, editarUbicacion: !this.state.editarUbicacion });
              }}
              onChange={(values) => {
                if (values) {
                  const state = {};
                  if (values.id_dependencia && this.state.id_dependencia !== values.id_dependencia.value.value) {
                    const selectedDependencia = this.state.institucion.dependencias.filter((dependencia) => dependencia.id === values.id_dependencia.value)[0];

                    this.setState({
                      selectedDependencia,
                      id_dependencia: values.id_dependencia.value,
                      completeUbicación: true,
                      ubicacion: values
                    });
                  }

                  if (values.id_area && this.state.id_area !== values.id_area.value) {
                    const selectedArea = this.state.selectedDependencia.areas.filter((area) => area.id_area === values.id_area.value)[0];
                    this.setState({ selectedArea, id_area: values.id_area.value, ubicacion: values });
                  }
                  this.setState(state);
                }
              }}
              defaultValue={this.state.ubicacion}
            />
          </Column>
        </Grid>
      );
    }

    const { ubicacion, selectedDependencia, selectedArea } = this.state;


    return (
      <Grid style={{ margin: 5 }}>
        <Row>
          <Column width={5}>
            <p style={styles.label}> Dependencia: </p>
            <p style={styles.value}> {selectedDependencia.vc_nombre || '-----'} </p>
          </Column>
          <Column width={5}>
            <p style={styles.label}> Area: </p>
            <p style={styles.value}> {selectedArea.vc_nombre || '-----'} </p>
          </Column>
          <Column width={5}>
            <p style={styles.label}> Registro SSA: </p>
            <p style={styles.value}> {ubicacion.vc_registroSSA ? ubicacion.vc_registroSSA.value : '-----'} </p>
          </Column>
          <Column width={5}>
            <p style={styles.label}> Logo izquierdo: </p>
            <p style={styles.value}> {ubicacion.vc_urlLogoIzquierdo ? ubicacion.vc_urlLogoIzquierdo.value : '-----'} </p>
          </Column>

          <Column width={5}>
            <p style={styles.label}> Logo central: </p>
            <p style={styles.value}> {ubicacion.vc_urlLogoCentral ? ubicacion.vc_urlLogoCentral.value : '-----'} </p>
          </Column>

          <Column width={5}>
            <p style={styles.label}> Dirección receta: </p>
            <p style={styles.value}> {ubicacion.vc_direccionReceta ? ubicacion.vc_direccionReceta.value : '-----'} </p>
          </Column>
        </Row>
      </Grid>
    );
  }

  renderTableAgenda() {
    if (this.state.agendasList) {
      return (
        <Grid style={{ margin: 5 }}>
          <Column width={16}>
            <CustomTable
              animated
              headers={this.getHeaders()}
              data={this.state.agendasList}
              defaultOrder="createdAt"
              orderDirection="descending"
              style={{ width: '100%' }}
              footerDisabled
            />
          </Column>
        </Grid>
      );
    }

    return null;
  }

  renderSiderbarRoles() {
    if (this.state.agregarRol) {
      return (
        <Grid style={{ margin: 5 }} >
          <Column width={16}>
            <SidebarForm
              ref={(ref) => {
                if (ref && ref.getWrappedInstance) {
                  this.SidebarFormRol = ref.getWrappedInstance();
                }
              }}
              style={{ paddingRight: 10, paddingLeft: 10, marginTop: 20 }}
              fields={this.getInputsRoles()}
              onSubmit={(values) => {
                const siderInfoRoles = formatter.optionsValues(values);
                this.setState({ siderInfoRoles }, () => this.onAgregarRol());
              }}
            />
          </Column>
        </Grid>
      );
    }

    if (this.state.roles.length) {
      return (
        <Grid style={{ margin: 5 }}>
          <Column width={16}>
            <CustomTable
              animated
              headers={this.getHeadersRoles()}
              data={this.state.roles}
              defaultOrder="createdAt"
              orderDirection="descending"
              style={{ width: '100%' }}
              footerDisabled
            />
          </Column>
        </Grid>
      );
    }

    return null;
  }

  renderButtonAgregarRol() {
    if (!this.state.agregarRol) {
      return (
        <Button
          onClick={this.onAgregarRol.bind(this)}
          style={styles.button}
        >
          Agregar
        </Button>
      );
    }

    return null;
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ width: '100%', height: '100%', padding: '2%', margin: 40 }}>
          <Segment style={{ height: 100 }}>
            <Dimmer active inverted style={{ height: 100 }}>
              <Loader content="Cargando usuarios" />
            </Dimmer>
          </Segment>
        </div>
      );
    }

    const { detalle, checkboxesSelected, editarUbicacion, roles } = this.state;

    return (
      <Segment color='blue'>
        <Grid style={styles.box}>
          <Row columns={2}>
            <Column width={10} style={{ display: 'flex', alignItems: 'center' }}>
              <span style={styles.header}>
                Vincular usuario { `${detalle.vc_nombre} ${detalle.vc_apellidoPaterno} ${detalle.vc_apellidoMaterno}` }
              </span>
            </Column>
            <Column width={6} textAlign="right">
              <Button
                onClick={() => {
                  this.props.onCancel();
                }}
                style={styles.button}
              >
                Volver
              </Button>
            </Column>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Column textAlign="left">
              <Row>
                <span style={styles.title}>
                Detalle
                </span>
              </Row>
              <Grid style={{ margin: 5 }}>
                <Row style={{ paddingTop: 0 }}>
                  <Column width={5}>
                    <p style={styles.label}> Nombre: </p>
                    <p style={styles.value}> {detalle.vc_nombre} </p>
                  </Column>
                  <Column width={5}>
                    <p style={styles.label}> Apellido paterno: </p>
                    <p style={styles.value}> {detalle.vc_apellidoPaterno} </p>
                  </Column>
                  <Column width={5}>
                    <p style={styles.label}> Apellido materno: </p>
                    <p style={styles.value}> {detalle.vc_apellidoMaterno} </p>
                  </Column>
                  <Column width={5}>
                    <p style={styles.label}> Sexo: </p>
                    <p style={styles.value}> {detalle.id_sexo === 1 ? 'Masculino' : 'Femenino'} </p>
                  </Column>
                </Row>
              </Grid>
            </Column>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Column width={16}>
              <Grid>
                <Row>
                  <Column width={5} textAlign="left">
                    <span style={styles.title}>
                      Ubicación
                    </span>
                  </Column>

                  <Column width={11} textAlign="right">
                      <Button
                        onClick={() => {
                          this.setState({ editarUbicacion: !this.state.editarUbicacion });
                        }}
                        style={styles.button}
                      >
                        { editarUbicacion ? 'Guardar' : 'Capturar'}
                      </Button>
                  </Column>
                </Row>
              </Grid>
            </Column>
            <Column width={16} textAlign="left">
              { this.renderUbicacion() }
            </Column>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Column width={16} textAlign="left">
              <Grid>
                <Row>
                  <Column width={5}>
                    <span style={styles.title}>
                    Agenda
                    </span>
                  </Column>
                  <Column width={11} textAlign="right" style={{ paddingRight: 40 }}>
                    <Header as="h4">
                      { `${checkboxesSelected.length} - agendas seleccionadas` }
                    </Header>
                  </Column>
                </Row>
              </Grid>
            </Column>

            <Column width={16}>
              { this.renderTableAgenda() }
            </Column>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Column width={16} textAlign="left">
              <Grid>
                <Row>
                  <Column width={5} textAlign="left">
                    <span style={styles.title}>
                      Roles
                    </span>
                  </Column>
                  <Column width={5}>
                    <Header as="h4" color='red'>
                      { roles.length ? '' : 'No se han agregado valores' }
                    </Header>
                  </Column>
                  <Column width={6} textAlign="right">
                    { this.renderButtonAgregarRol() }
                  </Column>
                </Row>
              </Grid>
            </Column>
            <Column width={16} textAlign="left">
              { this.renderSiderbarRoles() }
            </Column>
          </Row>
        </Grid>
      </Segment>
    );
  }
}


const styles = {
  box: {
    width: '100%',
    height: 'calc(100% - 30px)',
    padding: 10,
    overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
    backgroundColor: 'transparent',
  },
  header: {
    fontSize: 20,
    color: LIGHT_GREEN,
    paddingBottom: 5,
    fontWeight: 'bold',
    borderBottom: `3px solid ${LIGHT_GREEN}`,
  },
  title: {
    fontSize: 18,
    color: LIGHT_GREEN,
    paddingBottom: 5,
    fontWeight: 'bold',
  },
  label: {
    color: '#9e9e9e',
    marginTop: 5,
    marginBottom: 5,
  },
  value: {
    fontSize: 14,
    marginTop: 5,
    marginBottom: 5,
    color: 'black'
  },
  button: {
    backgroundColor: 'white',
    margin: 3,
    marginBottom: 0,
    boxShadow: '1px 1px 1px lightGray'
  }
};


VicularUsuarios.propTypes = {
  selectedUser: PropTypes.object,
  activaCapturaUsuario: PropTypes.func,
  onCancel: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { especialidades, formacionAcademica } = state.Think;
  return { especialidadesList: especialidades, formacionesList: formacionAcademica  };
};
const actions = {

};
export default connect(mapStateToProps, actions)(VicularUsuarios);


import React from "react";
import _ from "lodash";
import {
  Box,
  Divider,
  Popover,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Search, Check, ArrowDropDown } from "@material-ui/icons";

import Input from "./Input";

export default ({
  onClick = () => {},
  onChange = () => {},
  data = [],
  value = "",
  hideSearch = false,
  maxRows = 10,
  showAllRows = false,
  showNoSelect = false,
  fullWidthPopOver = true,

  noDataText = "Sin Registros",
  showNoDataText = false,
  multiple = false,
  renderInputValue,
  ...props
}) => {
  const ref = React.useRef();
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {
    setRows(getRows(data));
  }, [data]);

  React.useEffect(() => {
    if (multiple) setSelected(value);
    else setSelected([value]);
  }, [value, data]);

  React.useEffect(() => {
    if (search) {
      const filtered = data.filter(({ text }) =>
        text.toLowerCase().includes(search.toLowerCase())
      );
      setRows(getRows(filtered));
    } else {
      setRows(getRows(data));
    }
  }, [search]);

  React.useEffect(() => {
    const dataSelected = data.filter((v) => selected.includes(v.value));
    const texts = dataSelected.map((v) => v.text);

    if (renderInputValue && dataSelected.length) {
      setInputValue(renderInputValue(dataSelected));
      return;
    }

    if (multiple) setInputValue(texts.join(", "));
    else if (dataSelected.length) setInputValue(texts[0]);
    else setInputValue("");
  }, [selected, data]);

  const getRows = (a) => (showAllRows ? a : [...a].splice(0, maxRows));

  const handleSelect = (row) => {
    const isSelected = selected.includes(row.value);
    let newSelected = [];

    if (multiple) {
      if (isSelected) newSelected = selected.filter((v) => v !== row.value);
      else newSelected = [...selected, row.value];
    } else {
      if (isSelected) newSelected = [...selected];
      else newSelected = [row.value];
    }

    setSelected(newSelected);
    const dataSelected = data.filter((v) => newSelected.includes(v.value));

    if (multiple) {
      onClick(dataSelected);
      onChange(dataSelected);
    } else if (dataSelected.length) {
      onClick(dataSelected[0]);
      onChange(dataSelected[0]);
    } else {
      onClick(row);
      onChange(row);
    }

    if (!multiple) {
      setAnchorEl(null);
      setSearch("");
    }
  };

  return [
    <Input
      {...props}
      value={inputValue}
      readOnly
      onClick={(e) => {
        console.log(props.preventDefault);
        if (props.preventDefault) e.preventDefault();
        setAnchorEl(e.target);
        setSearch("");
        setRows(getRows(data));
      }}
      ref={ref}
    />,
    <Popover
      key="popover"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      PaperProps={{
        style: {
          minWidth: 200,
          width: fullWidthPopOver ? ref?.current?.clientWidth : undefined,
          // width: "auto",
        },
      }}
    >
      <Box
        padding={0}
        style={{
          maxHeight: "300px",
          display: "flex",
          minWidth: "100%",
          flexDirection: "column",
        }}
      >
        {!hideSearch && [
          <Box padding={1} paddingBottom={0}>
            <Input
              key="search"
              inputProps={{}}
              autoFocus
              onClick={(e) => e.stopPropagation()}
              value={search}
              onChange={setSearch}
              startAdornment={<Search style={{ marginRight: 8 }} />}
              style={{ margin: 0 }}
              dense
              hasBackground={false}
            />
          </Box>,
          <Divider variant="fullWidth" />,
        ]}
        <Box
          padding={0}
          style={{
            flex: "1 1 auto",
            overflowY: "auto",
          }}
        >
          {showNoSelect && (
            <ListItem
              key="noSelect"
              button
              onClick={() => handleSelect({ value: null, text: "" })}
            >
              <ListItemText primary={<em>* Sin selección</em>} />
            </ListItem>
          )}

          {rows.map((row, index) => (
            <ListItem key={index} button onClick={(_) => handleSelect(row)}>
              <ListItemText primary={row.text} />
              {selected.includes(row.value) && (
                <ListItemIcon style={{ justifyContent: "flex-end", width: 20 }}>
                  <Check color="primary" />
                </ListItemIcon>
              )}
            </ListItem>
          ))}
          {showNoDataText && !rows.length && (
            <ListItem
              key="noDataText"
              button
              onClick={() => handleSelect({ value: null, text: "" })}
            >
              <ListItemText primary={<em>{noDataText}</em>} />
            </ListItem>
          )}
        </Box>
      </Box>
    </Popover>,
  ];
};

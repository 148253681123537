import React from "react";
import { Box, CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useApi from "app/sales/hooks/useApi";

function usePrevious(value) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    ref.current = value ? value : null;
  });
  return ref.current;
}

const Index = ({ onClick, onChange, value: defaultValue, ...props }) => {
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const prevValue = usePrevious(value);

  const { list } = useApi("session/clientes");
  const buscar = (searchText) =>
    list({
      searchText,
      filters: "vc_nombre,vc_apellidoPaterno,vc_apellidoMaterno,vc_curp",
    });

  const fetchData = async () => {
    setData([]);
    if (!inputValue || inputValue.length < 3) return;

    try {
      const res = await buscar(inputValue);
      setLoading(false);
      setData(res.items);
    } catch (error) {
      setLoading(false);
      setData([]);
    }
  };

  React.useEffect(() => {
    setLoading(!!inputValue);
    const timeout = setTimeout(async () => {
      await fetchData();
    }, 300);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  React.useEffect(() => {
    if (JSON.stringify(prevValue) !== JSON.stringify(value)) {
      onClick(value);
      onChange(value);
    }
  }, [value]);

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
      setData([defaultValue]);
    } else {
      setValue(null);
    }
  }, [defaultValue]);

  return [
    <Autocomplete
      filterOptions={(x) => x}
      options={inputValue ? data : []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      openOnFocus={false}
      freeSolo
      value={value}
      autoHighlight
      getOptionLabel={(option) => {
        return typeof option === "string"
          ? option
          : `${option.vc_nombre} ${option.vc_apellidoPaterno} ${option.vc_apellidoMaterno}`;
      }}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") return;
        if (newValue && "loading" in newValue) return;

        setValue(newValue || null);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      loading={loading}
      style={{ margin: 0 }}
      renderInput={(params) => (
        <TextField
          label="Cliente"
          placeholder="Buscar cliente"
          variant="outlined"
          {...props}
          {...params}
          size="small"
          InputProps={{
            inputRef: params.InputProps.ref,
            ...params.InputProps,
            endAdornment: (
              <Box>
                {loading && !value && (
                  <CircularProgress size={20} color="inherit" />
                )}
                {!loading && params.InputProps.endAdornment}
              </Box>
            ),
          }}
        />
      )}
      renderOption={(data, option) => {
        return (
          <Box>
            {data.vc_nombre} {data.vc_apellidoPaterno} {data.vc_apellidoMaterno}
          </Box>
        );
      }}
    />,
  ];
};

Index.defaultProps = {
  onClick() {},
  onChange() {},
};

export default Index;

import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Work from '@material-ui/icons/Work';
import Home from '@material-ui/icons/Home';
import School from '@material-ui/icons/School';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import Timelapse from '@material-ui/icons/Timelapse';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import notifications from '../../utils/notifications';
import { ListItem, FormControl, Menu, TextField, Popover, Popper, Paper, ButtonGroup, IconButton } from '@material-ui/core';
import SearchSelect from '../ThinkForm/SearchSelect';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const useStyles = makeStyles(theme => ({
    outlinedInput: {
        padding: '8px 14px',
        marginLeft: 8
    },
    inputLabel: {
        transform: 'translate(14px, 10px) scale(1)'
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function GoogleMaps(props) {
    const { fullWidth, label, meta = {}, validation = () => { }, ...moreInputProps } = props;

    const classes = useStyles();
    const [inputValue, setInputValue] = React.useState({});
    const [options, setOptions] = React.useState([]);
    const [over, setOver] = React.useState(null);

    let active = true;
    const handleChange = event => {
        console.log('event', event)
        setInputValue({ ...inputValue, ...{ [event.target.id]: event.target.value } });
    };
    const InputLabelProps = { className: classes.inputLabel };

    const fetch = React.useMemo(
        () =>
            throttle(({ input }, callback) => {
                console.log('peticiones', input)
                // autocompleteService.current.getPlacePredictions(input, callback);
                if (input.split(' ').length > 2 && input.split(' ').find(e => (e * 1) > -1)) {
                    window.$.ajax({
                        url: `https://locationiq.org/v1/search.php?key=86b3be5516a8e1&format=json&q=${input}`,
                        method: 'GET'
                    }).then((data, status, xhr, error) => {
                        console.log('response', data)
                        setOptions(data)
                    }).fail((data, status, xhr, error) => {
                        notifications.error(error);
                    });
                }
                console.log('entro')
            }, 100),
        [],
    );
    const getAdress = ((adressParts) => {
        console.log('entro', adressParts);
        switch (true) {
            case (adressParts.length === 6): {
                const vc_numero = inputValue.vc_calle.split(' ').find(e => (e * 1) > -1) || null;
                setInputValue({
                    vc_calle: adressParts[0],
                    ...{ vc_numero: vc_numero || inputValue.vc_numero },
                    vc_colonia: adressParts[1],
                    vc_municipio: adressParts[2],
                    vc_entidad: adressParts[3],
                    vc_codigoPostal: adressParts[4]
                })
            }
            default: {
                return
            }
        }
    });

    React.useEffect(() => {

        if (inputValue === '') {
            setOptions([]);
            return undefined;
        }

        fetch({ input: inputValue.vc_calle || '' }, results => {
            setOptions(results || []);
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetch]);
    const style = { paddingRight: 10, paddingTop: 5 };
    const anchorEl = document.getElementById('vc_calle') || null;
    return (
        <FormControl
            fullWidth
        >
            <Grid container style={{ padding: 8 }}>
                <SearchSelect
                    options={[
                        { value: 1, label: 'Hogar' },
                        { value: 2, label: 'Empleo' },
                        { value: 3, label: 'Escuela' },
                        { value: 4, label: 'Familiares' },
                        { value: 5, label: 'Temporal' },
                        { value: 6, label: 'Condición de Calle' },
                        { value: 0, label: 'Otros' }
                    ]}
                    id={'id_tipoDomicilio'}
                    label={'Tipo'}
                />
                <Grid item xs={3} style={style}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ ...InputLabelProps }}
                        id={'vc_calle'}
                        variant='outlined'
                        label={'Calle'}
                        onChange={handleChange}
                        value={inputValue.vc_calle || ''}
                    />
                </Grid>
                <Grid item xs={1} style={style}>

                    <TextField
                        fullWidth
                        InputLabelProps={{ ...InputLabelProps }}
                        id={'vc_numero'}
                        variant='outlined'
                        label={'No.'}
                        onChange={handleChange}
                        value={inputValue.vc_numero || ''}
                    />
                </Grid>
                <Grid item xs={2} style={style}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ ...InputLabelProps }}
                        id={'vc_colonia'}
                        variant='outlined'
                        label={'Colonia'}
                        onChange={handleChange}
                        value={inputValue.vc_colonia || ''}
                    />
                </Grid>
                <Grid item xs={1} style={style}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ ...InputLabelProps }}
                        id={'vc_codigoPostal'}
                        variant='outlined'
                        label={'C.P.'}
                        onChange={handleChange}
                        value={inputValue.vc_codigoPostal || ''}
                    />
                </Grid>
                <Grid item xs={2} style={style}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ ...InputLabelProps }}
                        id={'vc_municipio'}
                        variant='outlined'
                        label={'Municipio'}
                        onChange={handleChange}
                        value={inputValue.vc_municipio || ''}
                    />
                </Grid>
                <Grid item xs={2} style={style}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ ...InputLabelProps }}
                        id={'vc_entidad'}
                        variant='outlined'
                        label={'Entidad'}
                        onChange={handleChange}
                        value={inputValue.vc_entidad || ''}
                    />
                </Grid>
            </Grid>
            <Popper
                placement={'bottom-start'}
                open={options.length > 0} anchorEl={anchorEl}
                style={{ paddingTop: 5, paddingBottom: 5 }}
            >
                <Paper>
                    {options.map((option, index) => {
                        return (
                            <div onClick={(e) => {
                                getAdress(option.display_name.split(','));
                                setOptions([]);
                                e.stopPropagation()
                            }}>
                                <ListItem button key={index} button>

                                    <LocationOnIcon className={classes.icon} />
                                    <Typography color="textSecondary">
                                        {option.display_name}
                                    </Typography>
                                </ListItem>

                            </div>

                        );
                    })}
                </Paper>
            </Popper>
        </FormControl >
    )

    return (
        <Autocomplete
            id="google-map-demo"
            style={{ width: 300 }}
            getOptionLabel={option => (typeof option === 'string' ? option.display_name : option.description)}
            filterOptions={x => x}
            options={options}
            autoComplete
            includeInputInList
            freeSolo
            disableOpenOnFocus
            renderInput={params => (
                <TextField
                    {...params}
                    label="Add a location"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ ...InputLabelProps }}
                    onChange={handleChange}
                />
            )}
            renderOption={option => {

                return (
                    <div onClick={(e) => {
                        active = false
                        e.stopPropagation()
                    }} container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                            {/* {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))} */}

                            <Typography variant="body2" color="textSecondary">
                                {option.display_name}
                            </Typography>
                        </Grid>
                    </div>
                );
            }}
        />
    );
}



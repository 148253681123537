import React from "react";
import { connect } from "react-redux";

import { Typography, IconButton, Box } from "@material-ui/core";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";

import {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setDisplaySidebar,
} from "../../../../containers/Think/actions";

import { Sidebar } from "./HorarioComponents";
import Schedule from "./Schedule";

const Horario = (props) => {
  const { dias, data, onChange } = props;
  const [events, setEvents] = React.useState([]);
  const [sidebar, setSidebar] = React.useState({
    data: {},
    index: -1,
    open: false,
  });

  React.useEffect(() => {
    setEvents(data.detalle);
  }, [data]);
  React.useEffect(() => {
    setSidebar({ data: null, index: -1, open: false });
  }, [events]);

  React.useEffect(() => {
    const content = sidebar.open ? (
      <Sidebar
        {...sidebar}
        duracion={5}
        events={events}
        save={save}
        remove={remove}
      />
    ) : null;

    props.setHelperSidebarRender(() => content);
    props.setDisplaySidebar(sidebar.open);
  }, [sidebar.data]);

  const remove = (detalle, index) => {
    const eventos = [...events];
    const el = eventos.splice(index, 1);

    setEvents(eventos);
    onChange(eventos);
  };

  const save = (detalle, index) => {
    const eventos = [...events];
    console.log("index", index);
    console.log("eventos", eventos);
    if (index >= 0) eventos[index] = detalle;
    else eventos.push(detalle);

    setEvents(eventos);
    onChange(eventos);
  };

  const openSidebar = (data, index) => {
    setSidebar({ data, index, open: true });
  };

  return (
    <Box style={{ backgroundColor: "transparent", boxShadow: "none" }}>
      <div style={{ width: "100%", paddingLeft: 20 }}>
        <Typography>
          Horario
          <IconButton color="inherit" onClick={() => openSidebar()}>
            <PlaylistAdd />
          </IconButton>
        </Typography>
      </div>

      <Schedule
        from="00:00"
        to="24:00"
        dias={dias}
        events={events}
        add={openSidebar}
        edit={openSidebar}
        rowTime={120}
        rowHeight={40}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({ dias: state.Think.dias });
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setDisplaySidebar,
};
export default connect(mapStateToProps, actions)(Horario);

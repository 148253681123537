import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Image, Card, Input } from "semantic-ui-react";
import {
  AppBar,
  Paper,
  Toolbar,
  Typography,
  Button,
  Divider,
  Box,
} from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";
import moment from "moment";

const useStyles = makeStyles((theme) => ({}));
const sexos = ["No Especificado", "Masculino", "Femenino", "Indistinto"];
const styles = {
  titleHelp: {
    width: "100%",
    padding: 3,
    height: 37,
    color: "white",
  },
  item: {
    padding: 0,
    paddingBottom: 3,
    margin: 0,
    width: "100%",
  },
};

const SidebarButton = (props) => {
  const style = { width: "calc(100% - 6px)", margin: 3, marginBottom: 0 };
  console.log(props);
  return (
    <Button variant="outlined" color="primary" style={style} {...props}>
      {props.children}
    </Button>
  );
};

const Row = (props) => {
  const { cantidad, descripcion } = props;
  return (
    <div>
      <GridUI container spacing={0} style={{ padding: 0 }} {...props}>
        <GridUI item xs={2} style={{ padding: "8px", textAlign: "right" }}>
          {cantidad}
        </GridUI>
        <GridUI
          item
          xs={10}
          style={{
            padding: "8px",
            textAlign: "left",
            overflow: "hidden",
            minWidth: 0,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {descripcion}
        </GridUI>
      </GridUI>
      <Divider />
    </div>
  );
};

const round = (num) => {
  const indexOf = `${num}`.indexOf(".");
  if (indexOf > 0) return Number(`${num}`.slice(0, indexOf + 3));
  return num;
};

export default (props) => {
  const classes = useStyles();
  const {
    opcion = "",
    selectedRow,
    revisar = () => {},
    eliminar = () => {},
  } = props;
  const { client, details = [] } = selectedRow;

  let title = "";
  let content = null;
  const getTotal = () => {
    const productsTotal = details.map((v) => {
      const nu_unitValue = Number(`${v.nu_unitValue}`.split(",").join(""));
      let subtotal = v.nu_quantity * nu_unitValue;
      const descuento = (subtotal * (v.nu_discount || 0)) / 100;

      subtotal -= descuento;

      const iva = round(subtotal * (v.nu_tax > -1 ? v.nu_tax : 0.16));
      const total = subtotal + iva;
      return {
        ...v,
        subtotal,
        descuento,
        total,
        iva,
      };
    });

    const iva = productsTotal.reduce((sum, v) => sum + v.iva, 0);
    const subTotal = productsTotal.reduce((sum, v) => sum + v.subtotal, 0);
    const total = productsTotal.reduce((sum, v) => sum + v.total, 0);

    return { subTotal, iva, total };
  };

  switch (opcion) {
    case "accountsDay":
    case "accountsOpen":
    case "accountsHistory":
      let rol = (window.amplify.store("roles") || []).find(
        (e) => e.id_rol === 5
      );
      rol = true;

      const clientName = `${client.vc_nombre} ${client.vc_apellidoPaterno} ${client.vc_apellidoMaterno}`;
      title = "Cuenta Abierta";
      const total = getTotal().total.toLocaleString("en-GB", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      });
      content = (
        <Grid
          centered
          style={{
            width: "100%",
            padding: "15px 0",
            paddingBottom: 0,
            margin: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Paper elevation={0} style={{ width: "100%", padding: 0, margin: 0 }}>
            <Card.Content>
              <Card.Header>
                <Typography
                  style={{
                    margin: "5px 0px",
                    fontSize: "15px",
                    fontWeight: 500,
                    textTransform: "capitalize",
                  }}
                >
                  {clientName}
                </Typography>
              </Card.Header>
              <Card.Description>
                {" "}
                Sexo: {sexos[client.id_sexo || 0]}
              </Card.Description>
              <Card.Description>
                {" "}
                Edad: {moment().diff(
                  moment(client.dt_nacimiento),
                  "years"
                )}{" "}
                {" años"}{" "}
              </Card.Description>
              <Card.Description>
                {" "}
                Teléfono: {client.vc_telefono}{" "}
              </Card.Description>
              <Card.Description> email: {client.vc_email} </Card.Description>
              <Card.Description> CURP: {client.vc_curp} </Card.Description>
            </Card.Content>
          </Paper>
          <div style={{ padding: "0px 0px", width: "100%" }}>
            <Divider />
            <GridUI container spacing={0} style={{ padding: 0 }}>
              <GridUI item xs={2} style={{ padding: "16px 0px" }}>
                Cant.
              </GridUI>
              <GridUI item xs={10} style={{ padding: "16px 0px" }}>
                Descripción.
              </GridUI>
            </GridUI>
            <Divider />
            {details.length ? (
              details.map((v, i) => (
                <Row
                  key={i}
                  cantidad={v.nu_quantity}
                  descripcion={v.vc_description}
                />
              ))
            ) : (
              <Typography key={0} align="center" style={{ padding: 16 }}>
                No hay cargos
              </Typography>
            )}
            <Typography
              style={{
                textAlign: "right",
                width: "100%",
                fontWeight: "bold",
                padding: 10,
              }}
            >
              TOTAL {total}
            </Typography>
          </div>
        </Grid>
      );
      break;
  }

  return (
    <Paper
      elevation={0}
      style={{ padding: 0, border: 0, borderRadius: 0, height: "100%" }}
    >
      <AppBar
        position="relative"
        style={{
          height: 95,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Typography style={{ textAlign: "center", width: "100%" }}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <div
        style={{
          height: "calc(100% - 95px)",
          width: "100%",
          overflow: "hidden",
          WebkitOverflowScrolling: "touch",
          padding: 0,
          margin: 0,
        }}
      >
        {content}
        {
          <Box style={{ flexDirection: "column" }}>
            <SidebarButton onClick={() => revisar()}>Revisar</SidebarButton>
            {!details.length && (
              <SidebarButton onClick={() => eliminar()}>Eliminar</SidebarButton>
            )}
          </Box>
        }
      </div>
    </Paper>
  );
};

import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';

const FORMAT_STRING = 'YYYY-MM-DD';


// crear componente
class DateInput extends Component {
    constructor(props) {
        super(props);

        let valid;
        if (props.defaultValue) {
            valid = true;
        } else {
            valid = !props.required;
        }

        let value = '';
        if (props.defaultValue) {
            value = moment(props.defaultValue).format(FORMAT_STRING);
        }

        this.state = {
            value,
            valid,
            message: props.required ? `El campo ${props.label} es requerido` : '',
            dirty: false
        };
    }


    // -----------------------------
    // ------ life cycle events ----
    // -----------------------------
    componentDidMount() {
        this.setForm();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.dirty && nextProps.defaultValue) {
            const value = moment(nextProps.defaultValue).format(FORMAT_STRING);
            this.setState({ value, valid: true });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextState, this.state) || !_.isEqual(nextProps, this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.eq(prevState, this.state)) {
            this.setForm();
        }
    }


    // -----------------------
    // ------ user events ----
    // -----------------------
    onChange(e) {
        // actualizar valor
        this.setState({ value: e.target.value, dirty: true });

        // validar input
        if (this.props.required && !e.target.value) {
            this.setState({ valid: false, message: `El campo ${this.props.label} es requerido` });
        } else {
            this.setState({ valid: true, message: '' });
        }
    }

    setForm() {
        this.props.setFormData(this.props.name, this.state);
    }

    getValue() {
        if (this.state.value) {
            let date = new Date(this.state.value);
            date = moment(date).add(date.getTimezoneOffset(), 'minutes')._d;
            return this.props.format ? moment(date).format(this.props.format) : date;    
        }

        return '';
    }

    dirtInput() {
        this.setState({ dirty: true });
    }

    resetInput() {
        let valid;
        if (this.props.defaultValue) {
            valid = true;
        } else {
            valid = !this.props.required;
        }

        this.setState({
            value: this.props.defaultValue ? this.props.defaultValue : '',
            valid,
            message: this.props.required ? `El campo ${this.props.label} es requerido` : '',
            dirty: false
        });
    }


    // --------------------------
    // ------ render methods ----
    // --------------------------
    render() {
        const min = this.props.min ? moment(this.props.min).format(FORMAT_STRING) : '';
        const max = this.props.max ? moment(this.props.max).format(FORMAT_STRING) : '';
        const invalidInput = this.state.dirty && !this.state.valid;
        let className = '';

        let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
        let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

        if (this.props.className) {
            className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
        } else {
            className = invalidInput ? 'invalid' : 'valid';
        }

        if (invalidInput) {
            finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
            finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
        }

        // render input
        if (this.props.inlineLabel) {
            return (
                <Form.Field>
                    <Input
                        label={this.props.label}
                        type="date"
                        value={this.state.value}
                        min={min}
                        max={max}
                        name={this.props.name}
                        className={className}
                        onChange={this.onChange.bind(this)}
                        disabled={this.props.readOnly}
                        style={finalInputStyle}
                        onClick={() => this.props.callbackSelectInput()}
                    />
                </Form.Field>
            );
        }

        return (
            <Form.Field>
                <label style={finalLabelStyle}>
                    { this.props.label }
                </label>
                <input
                    type="date"
                    value={this.state.value}
                    min={min}
                    max={max}
                    name={this.props.name}
                    className={className}
                    onChange={this.onChange.bind(this)}
                    disabled={this.props.readOnly}
                    style={finalInputStyle}
                    onClick={() => this.props.callbackSelectInput()}
                />
            </Form.Field>
        );
    }
}


// estilos
const styles = {
    label: {

    },
    errorLabel: {
        color: '#9F3A38'
    },
    input: {

    },
    errorInput: {
        background: 'rgba(224, 180, 180, 0.48)',
        border: '1px solid #9F3A38',
        color: '#9F3A38'
    }
};


// exportar componente
export default DateInput;

import React from "react";
import { Route, IndexRoute } from "react-router";
import DashboardLayout from "../views/layout/DashboardLayout";
import LoginView from "../views/login/LoginView";
import IndexView from "../views/IndexView/index";
import UsersView from "../views/users/UsersView";
import PatientsView from "../views/patients/PatientsView";
import DiaryView from "../views/diary/DiaryView";
import NotasView from "../views/notas/NotasView";
import ListaUsuarios from "../views/users/listaUsuarios";
import BackOfficeRoutes from "../../../backOffice/routes";
import Academy from "../../../academy/routes";
import Shop from "../../../shop/routes";
import Settings from "../../../settings/routes";
import Statistics from "../../../statistics/routes";
import Privacity from "./simpleViews/Privacity";

import agendas from "app/agenda/routes";
import pacientes from "app/patients/routes";
import hospitalizacion from "app/hospitalizacion/routes";
import notas from "app/notas/routes";
import erp from "app/sales/routes";
import Login from "app/login/containers";

const institucion = window.amplify.store("institution") || {};
const usuario = (window.amplify.store("user") || {}).usuario || {};
const roles = institucion.roles || [];
const Routes = [
  // <Route key="admin-1" path="login" component={LoginView} />,
  <Route key="admin-3" path="login" component={Login} />,
  <Route key="admin-5" path="recuperar" component={Login} />,
  <Route key="admin-2" path="/admin" component={DashboardLayout}>
    <IndexRoute component={IndexView} />
    <Route path="inicio" component={IndexView} />
    <Route path="usuarios" component={UsersView} />
    {/* <Route path="pacientes" component={PatientsView} /> */}
    {/* <Route path="agenda" component={DiaryView} /> */}
    <Route path="notas" component={NotasView} />
    <Route path="listaUsuarios" component={ListaUsuarios} />
    <Route path="aviso-privacidad" component={Privacity} />
    {BackOfficeRoutes}
    {agendas}
    {pacientes}
    {hospitalizacion}
    {notas}

    {Academy}
    {Statistics}
    {Settings}

    {Shop}
    {erp}
  </Route>,
  <Route key="admin-4" path="aviso-privacidad" component={Privacity}></Route>,
];

export default Routes;

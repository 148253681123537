import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import NumberFormat from 'react-number-format';
import internalAPI from '../../../utils/internalAPI';
import omit from 'lodash/omit';
import { Loader } from 'semantic-ui-react';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
  grid: {
    height: '100%'
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(1),
  },
  iconSelected: {
    color: 'green',
    width: '30px',
    height: '30px',
    position: 'absolute',
    left: 0,
    top: 0
  }
});

class ProductSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
        loadingOn:'Cargando Paquetes...',
        packages: [] };

    this.handleSelectedPackage = this.handleSelectedPackage.bind(this);
  }

  componentDidMount() {
    // const { data: { institucion } } = this.props;
    this.setState({ loading: true});
    internalAPI.getPackages(1, (err, response) => {
        if (!err){
            this.setState({ packages: response.length ? response : [], loading: false });
        }else{
            this.setState({ loading: false });
        }
    });
  }

  handleSelectedPackage(item) {
    return e => {
      const { data: { institucion, paqueteInstitucion } } = this.props;

      let data = { paqueteId: item.id, institucionId: institucion.id };

      if (paqueteInstitucion && paqueteInstitucion.id) {
        data.id = paqueteInstitucion.id;
      }
      internalAPI.addPackage(data, (err, paqueteInstitucion) => {
        const paquete = omit(item, ['PaqueteInstituciones']);

        this.props.updateData({ paqueteInstitucion, paquete }, this.props.handleNextStep);
      });
    };
  }

  render() {
    const { classes } = this.props;
    const { packages } = this.state;
    if (this.state.loading) {
        return (
          <div style={{ width: '100%', height: '100%', padding: '2%', margin: 0 }}>
            <div style={{display:'flex', height: '100%', justifyContent:'center', alignItems:'center' }}>
              <CircularProgress color='primary' size={70}>
                {this.state.loadingOn}
              </CircularProgress>
            </div>
          </div>
        );
      }
    return (
      <Grid className={classes.grid} container spacing={2} alignItems="center" justify="space-evenly" direction="row">
        {packages.map((item, index) => {
            return (
                (
                    <Grid key={index} item>
                      <Card>
                        <CardHeader
                          title={item.nombre}
                          titleTypographyProps={{ align: 'center' }}
                          subheaderTypographyProps={{ align: 'center' }}
                          action={item.PaqueteInstituciones.length===1 ? <DoneIcon className={classes.iconSelected} /> : null}
                        />
                        <CardContent>
                          <div className={classes.cardPricing}>
                            <Typography component="h2" variant="h4" color="textPrimary">
                              <NumberFormat
                                value={item.precio}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </Typography>
                          </div>
                          <Box display="flex" justifyContent="center" mb={2}>
                            {item.rutaImagen && (
                              <img src={item.rutaImagen} width="105" height="92" />
                            )}
          
                            {!item.rutaImagen && (
                              <img
                                src="https://locationiq.com/startup/common-files/icons/Bulb@2x.png"
                                width="105"
                                height="92"
                              />
                            )}
                          </Box>
                          {item.PaqueteProductos.map((product, index) => (
                            <Typography variant="subtitle1" key={index}>
                              <NumberFormat
                                value={Number.parseInt(product.cantidad, 10)}
                                displayType={'text'}
                                thousandSeparator={true}
                                suffix=" "
                              />
          
                              {product.Producto.descripcion}
                            </Typography>
                          ))}
                        </CardContent>
                        <CardActions>
                          <Button
                            fullWidth
                            variant={item === this.props.packageSelected ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => this.props.callbackSelect(item)}
                          >
                            ADQUIRIR
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
            );
        })}
      </Grid>
    );
  }
}

export default withStyles(styles)(ProductSelect);
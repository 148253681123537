import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import { menuConfiguracion } from "../../containers/Think/listados";
import {
  resetHelperSidebarRender,
  setHelperSidebarRender,
  setState,
} from "../../containers/Think/actions";
import UsuariosView from "../components/UsuariosView";
import InstitucionesView from "../components/InstitucionesView";
import AccountsView from "../components/AccountsView";
import notifications from "../../utils/notifications";
import ProductsInstitution from "../components/ProductsInstitution";

import Dependencias from "./Dependencias";

function getModuleContent(moduloActual) {
  switch (moduloActual) {
    case "institucion":
      return <InstitucionesView />;
    case "dependencias":
      return <Dependencias />;
    case "usuarios":
      return <UsuariosView />;
    case "productos":
      return (
        <ProductsInstitution
          {...menuConfiguracion.find((e) => e.id === "productos")}
        />
      );
    case "cuentas":
      return <AccountsView />;
    default:
      return <Box />;
  }
}

const Index = (props) => {
  const [moduloActual, setModuloActual] = React.useState("institucion");
  const [content, setContent] = React.useState(null);
  const isLogged = window.amplify.store("token") != null;

  React.useEffect(() => {
    props.setActiveView("institucion");
    const options = menuConfiguracion.map((option) => ({
      ...option,
      disabled: !window.amplify.store("institution"),
      onClick: () => {
        if (isLogged) {
          props.setViewTitle(option.vc_nombre);
          setModuloActual(option.id);
        } else {
          notifications.info(
            "¡Registrate con un plan para acceder a todas las herramientas!"
          );
        }
      },
    }));

    props.setSubMenuOptions(options);
  }, []);

  React.useEffect(() => {
    setContent(
      React.cloneElement(getModuleContent(moduloActual), {
        navigate: props.navigate,
        institucion: props.institucion
      })
    );
  }, [moduloActual, props.institucion]);

  return (
    <Box height="100%" bgcolor="rgba(200,200,200,0.1)">
      {content}
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState,
};

export default connect(mapStateToProps, actions)(Index);

import React, { Component } from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'
import Button from '@material-ui/core/Button';
import Thumb from './Thumb';
import IconButton from '@material-ui/core/IconButton';
import CloudUpload from '@material-ui/icons/CloudUpload';
export default class DropzoneDialogExample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      files: []
    };
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  handleSave(files) {
    //Saving files to state for further use and closing Modal.
    this.setState({
      files: this.state.files.concat(files),
      open: false
    });
  }

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  render() {
    const thumbsContainer = {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      objectFit: "cover",
      objectPosition: "center",
      overflow: 'hidden',
      margin: 5
    };
    return (
      <div style={{border: `1px solid ${'rgba(200,200,200,9)'}`, borderRadius: 5, marginBottom: 8}}>
        <div style={{ flexDirection: 'row-reverse', height: 60 }}>
        <IconButton
            edge="end"
            aria-label="Account of current user"
            aria-haspopup="true"
            onClick={this.handleOpen.bind(this)}
            color="inherit"
            style={{...thumbsContainer, float: 'right'}}
          >
            <CloudUpload/>
          </IconButton>
        {
          this.state.files.length > 0 ?
            this.state.files.map((file, i) => (<Thumb style={thumbsContainer} key={i} file={file} />))
            :
            null
        }
        </div>
        <DropzoneDialog
          open={this.state.open}
          dropzoneText='Arrastre y suelte un archivo aquí o haga clic'
          onSave={this.handleSave.bind(this)}
          acceptedFiles={['image/*', 'video/*', 'audio/*', 'application/msword', 'application/pdf', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint/*' ]}
          showPreviews={true}
          maxFileSize={50000000}
          onClose={this.handleClose.bind(this)}
        />
      </div>
    );
  }
}
import internalAPI from "app/utils/internalAPI";

export default {
  login(form) {
    return new Promise((resolve, reject) => {
      if (!form.vc_email || !form.vc_password)
        return reject("Verifica los campos.");

      const regExp = new RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      );
      const validEmail = regExp.test(form.vc_email);
      if (!validEmail) return reject("Correo no valido");

      const body = {
        ...form,
        vc_uuid: "web",
        vc_descripcion: "mac",
      };
      window.$.ajax({
        url: `${window.backEndURL}/app/login`,
        data: body,
        method: "POST",
      })
        .then((data, status, xhr, error) => {
          const result = {
            ...data,
            token: xhr.getResponseHeader("Authorization"),
            institution: data.instituciones[0],
            roles: data.instituciones[0]
              ? data.instituciones[0].roles || []
              : [],
          };
          resolve(result);
        })
        .fail((data, status, xhr, error) => {
          reject(
            data?.responseJSON?.error || "Verifica tu conexión a internet"
          );
        });
    });
  },
  recuperar(vc_email) {
    return new Promise((resolve, reject) => {
      if (!vc_email) return reject("Verifica los campos.");

      const regExp = new RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      );
      const validEmail = regExp.test(vc_email);
      if (!validEmail) return reject("Correo no valido");

      const body = {
        vc_email,
        vc_uuid: "web",
        vc_descripcion: "mac",
      };

      window.$.ajax({
        url: `${window.backEndURL}/app/resetPasswordMail/${body.vc_email}`,
        method: "GET",
      })
        .then((data, status, xhr, error) => {
          resolve(data);
        })
        .fail((data, status, xhr, error) => {
          reject(
            data?.responseJSON?.error || "Verifica tu conexión a internet"
          );
        });
    });
  },
  restablecer({ vc_email, vc_token, vc_password }) {
    return new Promise((resolve, reject) => {
      const body = {
        vc_email,
        vc_token,
        vc_password,
        vc_uuid: "web",
        vc_descripcion: "mac",
      };

      const headers = { Authorization: vc_token };
      window.$.ajax({
        url: `${window.backEndURL}/app/resetPassword`,
        data: body,
        headers,
        method: "POST",
      })
        .then((data, status, xhr, error) => {
          resolve(data);
        })
        .fail((data, status, xhr, error) => {
          reject(
            data?.responseJSON?.error || "Verifica tu conexión a internet"
          );
        });
    });
  },
};

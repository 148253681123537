import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Box, Button } from "@material-ui/core";

import SubMenuModulos from "app/material-ui/SubMenuModulos";
import ConfirmAlert from "app/material-ui/ConfirmAlert";

import ModalForm from "app/sales/components/Clientes/ModalForm";
import Sidebar from "app/sales/components/Clientes/Sidebar";
import Table from "app/sales/components/Clientes/Table";

import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "app/containers/Think/actions";

const Index = (props) => {
  const institucion = window.amplify.store("institution") || {};
  const [cajas, setCajas] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [rowSelected, setRowSelected] = React.useState({
    index: -1,
    row: null,
  });
  const [modalForm, setModalForm] = React.useState(false);
  const [modalConfirm, setModalConfirm] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    props.setDisplaySidebar(false);
    props.setHelperSidebarRender(null);
  }, []);

  React.useEffect(() => {
    const dataAux = cajas
      .map((v) => ({ ...v }))
      .filter((v) =>
        v.vc_description.toLowerCase().includes(searchText.toLowerCase())
      );

    if (rowSelected.row) {
      const index = dataAux.findIndex((v) => v.id == rowSelected.row.id);
      setRowSelected({ index, row: dataAux[index] });
    }

    setData(dataAux);
  }, [cajas, searchText]);

  React.useEffect(() => {
    props.setHelperSidebarRender(() =>
      rowSelected.row ? (
        <Sidebar
          data={rowSelected.row}
          editar={() => setModalForm(true)}
          eliminar={() => setModalConfirm(true)}
        />
      ) : null
    );
    props.setDisplaySidebar(Boolean(rowSelected.row));
  }, [rowSelected]);

  const handleSelect = (row, index) => {
    const newRowSelected = { index: -1, row: null };

    if (rowSelected.index !== index) {
      newRowSelected.index = index;
      newRowSelected.row = row;
    }

    setRowSelected(newRowSelected);
  };

  const eliminar = () => {
    const { row } = rowSelected;

    setModalConfirm(false);
    handleSelect(null, -1);
  };

  const guardar = (data) => {
    setModalForm(false);
  };

  return (
    <Box>
      <SubMenuModulos
        title={
          <Button
            color="inherit"
            onClick={() => {
              handleSelect(null, -1);
              setModalForm(true);
            }}
          >
            Agregar
          </Button>
        }
        type={"search"}
        searchText={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <Box>
        <Table data={data} onClick={handleSelect} />
      </Box>

      {modalForm && (
        <ModalForm
          open
          close={() => setModalForm(false)}
          accept={guardar}
          data={rowSelected.row}
        />
      )}

      {modalConfirm && (
        <ConfirmAlert
          title={`¿Desea eliminar el producto: ${rowSelected.row?.vc_description}?`}
          onAccept={eliminar}
          onCancel={() => setModalConfirm(false)}
        />
      )}
    </Box>
  );
};

const actions = { setHelperSidebarRender, setDisplaySidebar };
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(Index);

import React from "react";

import _ from "lodash";
import * as firebase from "firebase/app";

import { Paper, Button, Divider, Dialog, Slide, Grid } from "@material-ui/core";

import PatientsItem from "../../../../components/PatientsItem";

import AbonoModal from "../AbonoModal";
import SearchProduct from "./SearchProduct";
import Products from "./Products";
import Abonos from "./Pays";
import Total, { getTotales } from "./Total";
import moment from "moment";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Account = (props) => {
  const {
    accountSelected = {},
    cashRegisterSelected: cashRegister = {},
    pagar = () => {},
    onClose = () => {},
  } = props;

  const db = firebase.database().ref();
  const institucion = window.amplify.store("institution") || {};
  const usuario = (window.amplify.store("user") || {}).usuario || {};
  const [account, setAccount] = React.useState({});

  const [abonoModal, setAbonoModal] = React.useState(false);
  const [searchProduct, setSearchProduct] = React.useState("");

  const { saleCut = null, pays = [], details: products = [] } = account;
  const totales = getTotales(account);
  const abonado = pays.reduce((ac, v) => ac + v.nu_quantity, 0);

  const disabledFinalizar = !products.length || totales.total > abonado;

  React.useEffect(() => {
    if (!account.saleCut) {
      db.child("erp")
        .child("orders")
        .child(`${institucion.id_institucion}`)
        .child(`${account.id}`)
        .update({
          ...account,
          pays,
          details: products,
        });
    }
  }, [account]);

  React.useEffect(() => {
    setAccount(_.cloneDeep(accountSelected));
  }, [accountSelected]);

  const addProduct = (product) => {
    const details = _.cloneDeep(products);

    if (institucion.id_institucion) {
      const indexProduct = products.findIndex(
        (e) => e.id_product === product.id_product
      );
      if (indexProduct < 0) {
        details.push({
          ...product,
          nu_quantity: 1,
        });
      } else {
        details[indexProduct].nu_quantity++;
      }
    }

    setAccount({
      ...account,
      details,
    });
  };

  const removeProduct = (product, index) => {
    const { details = [] } = accountSelected;
    let min = 1;
    // const productOriginal = details.find(
    //   (v) => v.id_product === product.id_product
    // );

    // if (productOriginal) min = productOriginal.nu_quantity;
    const nuevoTotal = totales.total - product.nu_total;
    if (abonado && nuevoTotal < abonado) {
      return;
    }

    const data = _.cloneDeep(products);
    if (data[index].nu_quantity > min) {
      data[index].nu_quantity = data[index].nu_quantity - 1;
      // } else if (min == 1 && !productOriginal) {
    } else if (min == 1) {
      data.splice(index, 1);
    }

    setAccount({
      ...account,
      details: data,
    });
  };

  const onChangeProduct = (product, index, value) => {
    // if (value && !Number(value)) return;

    // products[index].nu_quantity = Number(value);

    products[index] = {
      ...products[index],
      ...product,
    };

    setAccount({
      ...account,
      details: products,
    });
  };

  const abonar = (pay) => {
    return new Promise((resolve, reject) => {
      const institucion = window.amplify.store("institution") || {};
      if (!account.saleCut) {
        setAccount({
          ...account,
          pays: [...pays, pay],
        });
        resolve();
      }
    });
  };

  const finalizar = () => {
    const { subTotal, iva, total } = totales;

    const {
      vc_nombre,
      vc_apellidoPaterno,
      vc_apellidoMaterno = "",
    } = usuario;

    const saleCut = {
      subTotal,
      iva,
      total,
      countProductos: products.reduce((ac, v) => ac + 1 * v.nu_quantity, 0),
      payments: pays.length,
      totalPayments: abonado,
      dt_created: moment().format("YYYY-MM-DD HH:mm:ss"),
      vc_nameUser: `${vc_nombre} ${vc_apellidoPaterno} ${vc_apellidoMaterno}`,
    };

    pagar({
      ...account,
      saleCut,
    });
    onClose();
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      fullWidth
      maxWidth={"md"}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ style: { overflowX: "hidden" } }}
    >
      {abonoModal && (
        <AbonoModal
          open={abonoModal}
          data={{ cashRegister, account, products, pays }}
          abonar={abonar}
          close={() => setAbonoModal(false)}
        />
      )}

      <Paper
        style={{
          minHeight: "calc(100vh - 70px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={0}>
          <PatientsItem pacienteActual={{ detalle: account.client }} />
        </Grid>
        <Divider />

        <SearchProduct
          saleCut={saleCut}
          products={props.products}
          onSelect={addProduct}
          onChange={setSearchProduct}
        />

        <Products
          saleCut={saleCut}
          search={searchProduct}
          data={products}
          addClick={addProduct}
          removeClick={removeProduct}
          onChange={onChangeProduct}
        />

        <Grid container>
          <Grid item xs={8}>
            <Abonos data={pays} account={account} />
          </Grid>
          <Grid
            item
            xs={4}
            style={{ borderLeft: "1px solid rgba(200,200,200,0.2)" }}
          >
            <Total products={products} pays={pays}>
              {!saleCut && [
                <Button
                  disabled={products.length === 0}
                  onClick={() => {
                    setAbonoModal(true);
                  }}
                  variant="contained"
                  color="primary"
                  style={{ width: "100%", marginBottom: 10 }}
                >
                  {" "}
                  Abonar{" "}
                </Button>,
                <Button
                  disabled={disabledFinalizar}
                  onClick={() => {
                    finalizar();
                  }}
                  variant="outlined"
                  color="primary"
                  style={{ width: "100%", marginBottom: 10 }}
                >
                  {" "}
                  Finalizar Cuenta{" "}
                </Button>,
              ]}
              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                style={{ width: "100%", marginBottom: 10 }}
              >
                {" "}
                salir{" "}
              </Button>
            </Total>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};

export default Account;

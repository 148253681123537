import React, { Component } from 'react';
import { Grid, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const { Column } = Grid;


class CheckBoxCell extends Component {
  onChange() {
    if (!this.isInvoiced()) {
      const { data, selectToInvoice } = this.props;
      selectToInvoice(data.id);
    }
  }

  isInvoiced() {
    const { invoice } = this.props.data;

    if (invoice && invoice.invoiced) {
      return true;
    }

    return false;
  }

  render() {
    const { checkboxesSelected, data } = this.props;
    const isInvoiced = this.isInvoiced();

    return (
      <Grid>
        <Column>
          <Checkbox
            readOnly={isInvoiced}
            checked={isInvoiced || checkboxesSelected.includes(data.id)}
            onChange={this.onChange.bind(this)}
            style={{ marginLeft: 10, opacity: isInvoiced ? 0.5 : 1 }}
          />
        </Column>
      </Grid>
    );
  }
}


CheckBoxCell.propTypes = {
  data: PropTypes.object,
  selectToInvoice: PropTypes.func,
  checkboxesSelected: PropTypes.array,
};


export { CheckBoxCell };

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Icon } from "semantic-ui-react";
import { FadeInOutView } from "../../../../components";
import eventManager from "../../../../utils/eventManager";
import notifications from "../../../../utils/notifications";
import { isEqual } from "lodash";
import formatter from "../../../../utils/formatter";
import internalAPI from "../../../../utils/internalAPI";
import { START_VIEW_TRANSITION } from "../../../../constants";
import PrincipalHeaderItem from "../notas/principalHeaderItem";
import HelperSidebar from "./HelperSidebar";
import { opcionesMenu } from "../../listados";
import {
  logOut,
  setDisplaySidebar,
  setDisplayMenu,
  setState,
  updateCatalogs,
  setLibs,
} from "../../actions";
import Drawers from "./Drawers";
// MATERIAL-UI
import GridUI from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Check from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {
  teal,
  grey,
  blue,
  orange,
  pink,
  indigo,
  blueGrey,
} from "@material-ui/core/colors";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FolderShared from "@material-ui/icons/FolderShared";
import List from "@material-ui/core/List";
import {
  MenuList,
  IconButton,
  Input,
  DialogContent,
  Divider,
  Box,
  Paper,
  Typography,
  Button,
  Snackbar,
  SnackbarContent,
  Modal,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import NotificationsCentral from "../../../../components/NotificationsCentral";
import VideoCall from "../../../../material-ui/VideoCall/VideoCall";
import TextFieldThink from "../notas/preguntas/TextFieldThink/TextFieldThink";
import ToolBottomBar from "./Drawers/ToolBottomBar";

const { Row, Column } = Grid;
const themeSelected = window.amplify.store("theme") || null;
const isMobile = window.isMobile;

let theme = createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiCardHeader: {
      root: {
        position: "relative",
        outline: "none",
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: "4px",
        marginBottom: "8px",
      },
      root: {
        marginTop: "4px",
        marginBottom: "8px",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "8px 14px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 10px) scale(1)",
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: "4px 0px 0",
      },
    },
    MuiDrawer: {
      paper: {
        // backgroundColor: grey
      },
    },
    MuiFormHelperText: {
      root: {
        position: "absolute",
        bottom: "-4px",
        left: "-14px",
        color: "#f3564b",
        width: "100%",
      },
      contained: {
        margin: "0px 14px 0",
      },
    },
    MuiInputBase: {
      root: {
        marginBottom: "10px",
      },
    },
  },
  palette: {
    primary: themeSelected ? themeSelected.primary : teal,
    secondary: {
      main: "#fafafa",
    },
    type: themeSelected ? themeSelected.typeMain : "dark",
  },
  MuiExpansionPanelSummary: {
    root: {
      "&$expanded": {
        boxShadow: "0 1px 12px 0 rgba(0,0,0,.11)",
      },
    },
  },
});
class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.deferredPrompt = null;
    this.state = {
      title: "",
      headerComponent: null,
      activeView: "",
      showVideoCall: false,
      menuVisible: true,
      mainMenuOptions: opcionesMenu,
      subMenuOptions: [],
      mainSectionData: null,
      institucion: null,
      value: 0,
      searchText: "",
      menuOptionActual: "inicio",
      showInstallPromotion: true,
    };

    this.updateSectionData = this.updateSectionData.bind(this);
  }

  // -------------------------
  // --- life cycle events ---
  // -------------------------
  showInstallPromotion() {
    this.setState({ showInstallPromotion: true });
  }
  componentDidMount() {
    window.addEventListener("beforeinstallprompt", (e) => {
      console.log("even listener");
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.showInstallPromotion();
    });
    if (!window.amplify.store("token")) {
      this.navigate(`#/login`);
    }
    this.updateSectionData();
    let institucion = null;
    switch (true) {
      case window.amplify.store("institution") ? true : false:
        institucion = window.amplify.store("institution");
        break;
      case window.amplify.store("institutions") ? true : false:
        institucion = window.amplify.store("institutions")[0] || null;
        break;
      default:
        break;
    }
    this.setState(
      {
        institucion,
      },
      () => {
        const covid = window.amplify.store("covid19") || {};
        const lastDate = covid.update;
        // if (!lastDate || moment(lastDate).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') || !covid.structured) {
        this.goToDataCovid();
        this.update();
        // }
      }
    );
    // this.props.setDisplayMenu(this.changeDisplayMenu);
    // this.props.setDisplaySidebar(this.changeDisplaySidebar);
    // window.addEventListener('resize', this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    // window.removeEventListener('resize', this.updateDimensions.bind(this));
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return (
      !isEqual(nextState, this.state) ||
      !isEqual(clean(nextProps), clean(this.props))
    );
  }
  componentWillMount() {
    if (!window.amplify.store("token")) {
      this.navigate(`#/login`);
    }
  }
  goToDataCovid() {
    let covid = window.amplify.store("covid19") || {};
    const url = "https://corona.lmao.ninja/v2/jhucsse";
    const request = internalAPI.general(url, null, "GET", (error, response) => {
      if (error) {
        console.log("error", error);
      } else {
        covid = { update: moment().format("YYYY-MM-DD"), countries: response };
        window.amplify.store("covid19", covid);
        this.props.setState({ modulo: "covid19", state: covid });
        this.structureDataCovidHistorical();
      }
    });
  }
  structureDataCovidHistorical() {
    let covid = window.amplify.store("covid19") || {};
    const url = "https://corona.lmao.ninja/v2/historical";
    const request = internalAPI.general(url, null, "GET", (error, response) => {
      if (error) {
        console.log("error", error);
      } else {
        // console.log("response", response);
        const countries = covid.countries || [];
        covid.countries = (countries.length ? countries : []).map(
          (item, index) => {
            const country = item;
            const countryNew = (Array.isArray(response) ? response : []).find(
              (e) =>
                e.country.toLowerCase() === country.country.toLowerCase() &&
                (e.province || "").toLowerCase() ===
                  (country.province || "").toLowerCase()
            );
            if (countryNew) {
              return {
                ...country,
                ...countryNew.timeline,
                vc_nombre:
                  (
                    this.props.paises.find(
                      (e) => e.vc_abreviatura === countryNew.country_code
                    ) || {}
                  ).vc_nombre || null,
              };
            }
            // console.log('no encontrado timeline', country.country, country.province)
            return country;
          }
        );
        covid.structured = true;
        covid.historical = response;
        window.amplify.store("covid19", covid);
        this.props.setState({ modulo: "covid19", state: covid });
        // this.structureDataCovid();
      }
    });
  }
  structureDataCovid() {
    let covid = window.amplify.store("covid19") || {};
    // console.log('covid', covid)
    const url = "https://coronavirus-tracker-api.herokuapp.com/v2/locations";
    const request = internalAPI.general(url, null, "GET", (error, response) => {
      if (error) {
        console.log("error", error);
      } else {
        covid.countries = covid.countries.map((item, index) => {
          const country = item;
          switch (item.country) {
            case "uk": {
              country.country = "United Kingdom";
              if (country.province === "uk")
                country.province = "United Kingdom";
              break;
            }
            case "usa": {
              country.country = "US";
              if (country.province === "u.s. virgin islands")
                country.province = "Virgin Islands";
              break;
            }
            case "bosnia": {
              country.country = "Bosnia and Herzegovina";
              break;
            }
            case "uae": {
              country.country = "United Arab Emirates";
              break;
            }
            case "czech republic": {
              country.country = "Czechia";
              break;
            }
          }
          const countryNew = response.locations.find(
            (e) =>
              e.country.toLowerCase() === country.country.toLowerCase() &&
              ((e.province || "").toLowerCase() ===
                (country.province || "").toLowerCase() ||
                (country.province === null && e.province === ""))
          );
          if (countryNew) {
            return {
              ...country,
              ...countryNew,
              vc_nombre:
                (
                  this.props.paises.find(
                    (e) => e.vc_abreviatura === countryNew.country_code
                  ) || {}
                ).vc_nombre || null,
            };
          }
          // console.log('no encontrado', country.country, country.province)
          return country;
        });
        covid.structured = true;
        // console.log('covid', covid)
        window.amplify.store("covid19", covid);
        this.props.setState({ modulo: "covid19", state: covid });
      }
    });
  }
  update() {
    (async () => {
      const libs = require("app/libs/index");
      this.props.setLibs(libs.default);
    })();

    internalAPI.updateCatalogs(
      {
        actualizacion: [
          {
            id_catalogo: 3,
            dt_actualizacion: "2017-01-01",
          },
          {
            id_catalogo: 5,
            dt_actualizacion: "2017-01-01",
          },
          {
            id_catalogo: 6,
            dt_actualizacion: "2017-01-01",
          },
          {
            id_catalogo: 7,
            dt_actualizacion: "2017-01-01",
          },
          {
            id_catalogo: 9,
            dt_actualizacion: "2017-01-01",
          },
          {
            id_catalogo: 10,
            dt_actualizacion: "2017-01-01",
          },
          {
            id_catalogo: 17,
            dt_actualizacion: "2017-01-01",
          },
        ],
      },
      (err, response) => {
        if (err) {
          console.log("Error: ", err);
        } else {
          this.props.updateCatalogs(response);
        }
      }
    );

    internalAPI.updatePreguntas(
      {
        dt_actualizacion: "2017-01-01",
      },
      (err, response) => {
        if (err) {
          console.log("Error: ", err);
        } else {
          this.props.updateCatalogs([
            {
              vc_nombreTabla: "cat_preguntas",
              registros: response,
            },
          ]);
        }
      }
    );
  }

  componentDidUpdate() {
    this.updateSectionData();
    if (!window.amplify.store("token")) {
      this.navigate(`#/login`);
    }
  }
  pendentsRequest(restantsRequest) {
    const institucion = window.amplify.store("institution");
    if (institucion.id_institucion) {
      const pendentsRequest =
        window.amplify.store(`pendentRequest-${institucion.id_institucion}`) ||
        [];

      if (restantsRequest) {
      }
    }
  }
  // sendPendentsRequest(pendentsRequest) {
  //   const api = internalAPI[`${pendentsRequest.routeName}`];
  //   if (api) {
  //     api(nota, (err, response) => {
  //       if (err) {
  //         notifications.error(`Error: ${err}`);
  //         this.props.setState({ modulo: "notifications", state: null })
  //       } else {
  //         eventManager.emit(START_VIEW_TRANSITION, `#/admin/agenda`);
  //       }
  //     });
  //   }
  // }
  // ----------------------
  // ------ methods -------
  // ----------------------
  onMainOptionClicked(option) {
    const { activeView } = this.state;
    const { url } = option;
    const userLogged = window.amplify.store("token") !== null;
    // if (activeView === url) {
    //   return;
    // }
    if (url === null) {
      logOut();
    } else if (url === "inicio") {
      this.setState({ menuOptionActual: option.id }, () => {
        this.navigate(`#/admin/${url}`);
        window.isMobile && this.props.setDisplayMenu();
      });
    } else if (userLogged && this.validateOptionsAccess(url)) {
      this.setState({ menuOptionActual: option.id }, () => {
        this.navigate(`#/admin/${url}`);
        window.isMobile && this.props.setDisplayMenu();
      });
    } else {
      notifications.info(
        "Inicia sesión para acceder a todas las herramientas."
      );
    }
  }

  setSubMenuOptions(subMenuOptions) {
    this.setState({ subMenuOptions });
  }

  setViewTitle(title) {
    this.setState({ title });
  }

  setCustomHeader(headerComponent) {
    this.setState({ headerComponent, title: "" });
  }

  setActiveView(value) {
    this.setState({ value });
  }

  setMenuVisibility(value) {
    this.setState({ menuVisible: value });
  }

  validateOptionsAccess(url) {
    switch (url) {
      case "usuarios":
        return true;
      case "pacientes":
        if (window.amplify.store("institution") != null) {
          return true;
        }
        notifications.info(
          "Se requiere pertenecer a una institución para el modulo de pacientes."
        );
        notifications.info(
          "Si no tienes institución puedes crearla en configuración."
        );
        return false;
      // case 'agenda':
      case "agendas":
        if (!window.amplify.store("institution")) {
          notifications.info(
            "Se requiere pertenecer a una institución para el modulo de agendas."
          );
          notifications.info(
            "Si no tienes institución puedes crearla en configuración."
          );
          return false;
        }

        return true;
      case "hospitalizacion":
        return true;
      case "shop":
        if (!window.amplify.store("institution")) {
          notifications.info(
            "Se requiere pertenecer a una institución para el modulo de ventas."
          );
          notifications.info(
            "Si no tienes institución puedes crearla en configuración."
          );
          return false;
        }
        return true;
      case "statistics":
        if (!window.amplify.store("institution")) {
          return true;
        }
        return true;
      case "backoffice":
        const usuario =
          (window.amplify.store("user") &&
            window.amplify.store("user").usuario) ||
          {};
        console.log("usuario", usuario);
        if (usuario.vc_email === "sedf_83@hotmail.com") {
          console.log("entro", usuario.vc_email);
          return true;
        } else {
          console.log("no entro", usuario.vc_email);
          return false;
        }
      case "academy":
        return true;
      case "configuracion":
        const institucion = window.amplify.store("institution") || {};
        if (institucion.roles) {
          const admin = institucion.roles.find((e) => e.id_rol === 2);
          return !!admin;
        } else if (!window.amplify.store("institution")) {
          return true;
        }
        return false;
      case "aviso-privacidad":
        return true;
      default:
        return false;
    }
  }

  navigate(url) {
    eventManager.emit(START_VIEW_TRANSITION, url);
  }

  updateSectionData() {
    const $mainMenu = window.$("#mainMenu");

    const windows = {
      height: window.innerHeight,
      width: window.innerWidth,
    };
    const top = $mainMenu.offset()
      ? $mainMenu.offset().top + $mainMenu.height()
      : $mainMenu.height();
    const mainSection = {
      top,
      width: $mainMenu.width(),
      height: windows.height - top,
    };

    this.setState({ mainSectionData: mainSection });
  }

  // ----------------------
  // --- render methods ---
  // ----------------------

  renderChildren() {
    // console.log('this.props.children', this.props.children)
    const component = this.props.children;

    const props = {
      navigate: this.navigate,
      showVideoCall: this.state.showVideoCall,
      setVideoCall: this.setVideoCall,
      setSubMenuOptions: this.setSubMenuOptions.bind(this),
      setViewTitle: this.setViewTitle.bind(this),
      setCustomHeader: this.setCustomHeader.bind(this),
      setActiveView: this.setActiveView.bind(this),
      setMenuVisibility: this.setMenuVisibility.bind(this),
      mainSectionData: this.state.mainSectionData,
      viewTitle: this.state.title,
      institucion: this.state.institucion,
      pageSize: {
        width: this.state.width || window.innerWidth,
        height: this.state.height || window.innerHeight,
      },
    };
    return React.cloneElement(component, props);
  }

  setVideoCall(showVideoCall) {
    this.setState({ showVideoCall });
  }

  filterMenuOptions(menuOptions) {
    let options = menuOptions;
    const institucion = window.amplify.store("institution") || {};
    const usuario = (window.amplify.store("user") || {}).usuario || {};
    const roles = (institucion.roles || []).map((r) => r.id_rol);

    options = options.filter((v) => v.roles.some((r) => roles.includes(r)) || v.id === 'logout');

    switch (true) {
      case !roles.find((r) => r === 2): {
        options = options.filter((e) => e.id !== "configuracion");
      }
      case usuario.vc_email !== "sedf_83@hotmail.com" &&
        usuario.vc_email !== "admin@thin-k.mx": {
        options = options.filter((e) => e.id !== "backoffice");
      }
    }
    return options;
  }
  renderMenuOptions(institucionActual) {
    const { mainMenuOptions } = this.state;
    // console.log(mainMenuOptions, 'MAINMENUOPTIONS')
    return this.filterMenuOptions(mainMenuOptions).map((opcion, index) => {
      let label;
      let subIcon;
      let isUserAdmin = false;
      const institucion = window.amplify.store("institution");
      if (institucion) {
        isUserAdmin =
          institucion.id_usuario === window.amplify.store("user_id");
      }

      // if (opcion.id === 'configuracion' && !isUserAdmin) {
      //   return null;
      // }

      if (opcion.id !== "logout") {
        label = opcion.vc_nombre;
      } else if (window.amplify.store("token") !== null) {
        label = "Cerrar Sesión";
      } else {
        label = "Iniciar Sesión";
      }

      if (!window.amplify.store("token")) {
        switch (opcion.id) {
          case "inicio":
            subIcon = (
              <Icon
                color="green"
                name="checkmark"
                style={{ marginLeft: 3, float: "right" }}
              />
            );
            break;
          case "logout":
            break;
          default:
            subIcon = (
              <Icon
                color="grey"
                name="lock"
                style={{ marginLeft: 3, float: "right" }}
              />
            );
            break;
        }
      }
      return (
        <MenuItem
          button
          key={opcion.id}
          onClick={() => this.onMainOptionClicked(opcion)}
          selected={this.state.menuOptionActual === opcion.id}
        >
          <ListItemIcon style={{ minWidth: 35 }}>
            <Icon name={opcion.icon} />
          </ListItemIcon>
          <ListItemText color={"#f5f5f5"} primary={label} />
        </MenuItem>
      );
    });
  }

  renderMenu() {
    const menuStyle = {
      borderRadius: 0,
      margin: 0,
      // boxShadow: '1px 0px 10px rgba(0, 0,0, 0.7)',
      left: 0,
      right: 0,
      top: 0,
    };

    const dropdownStyle = {
      fontSize: 13,
      width: 37,
      padding: 8,
      color: window.colors.white,
    };

    if (!this.state.menuVisible) {
      return null;
    }

    return (
      <div>
        <Tabs
          style={{
            marginLeft: window.isMobile ? 50 : 0,
            width: window.isMobile ? "calc(100% - 100px)" : "100%",
          }}
          variant="scrollable"
          value={this.state.value}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="primary"
          onChange={(event, value) => {
            this.setState({ value });
          }}
          aria-label="disabled tabs example"
        >
          {this.renderSubMenu()}
          {/* <Tab label="Active" />
          <Tab label="Disabled" disabled />
          <Tab label="Active" /> */}
        </Tabs>

        {window.isMobile ? (
          <IconButton
            style={{ left: 0, top: 0, position: "absolute" }}
            onClick={this.props.setDisplayMenu}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        {/* {
          window.isMobile ?
            <IconButton style={{ right: 0, top: 0, position: 'absolute' }} onClick={this.props.setDisplaySidebar}>
              <MoreVert />
            </IconButton>
            :
            null
        } */}
      </div>
    );
  }

  renderSubMenu() {
    const { subMenuOptions } = this.state;
    const menuStyle = {
      borderRadius: 0,
      margin: 0,
      width: "calc(100% - 37px)",
      height: 37,
      overflowX: "scroll",
      overflowY: "hidden",
      WebkitOverflowScrolling: "touch",
    };

    return subMenuOptions.map((subOption, index) => {
      if (!subOption.enable) {
        return null;
      }
      return (
        <Tab
          key={index}
          disabled={subOption.disabled}
          value={subOption.id}
          label={subOption.vc_nombre}
          selected={this.state.subMenuOptions.id === subOption.id}
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => {
            subOption.onClick(subOption);
          }}
        />
      );
    });
  }

  renderHeader() {
    const { title, headerComponent } = this.state;

    if (title) {
      return <PrincipalHeaderItem title={this.state.title} />;
    }

    if (headerComponent) {
      return headerComponent;
    }

    return null;
  }

  renderMainSection() {
    return (
      <div style={{ ...stylesInit.container, height: "100%" }}>
        {this.renderMenu()}
        <FadeInOutView duration={300}>{this.renderChildren()}</FadeInOutView>
        <NotificationsCentral />
      </div>
    );
  }

  optionsPalettes() {
    return [
      {
        id: 1,
        vc_nombre: "Thin-k Dark",
        typeMenu: "dark",
        typeMain: "dark",
        typeSidebar: "dark",
        primary: teal,
      },
      {
        id: 2,
        vc_nombre: "Thin-k Light",
        typeMenu: "dark",
        typeMain: "light",
        typeSidebar: "dark",
        primary: teal,
      },
      {
        id: 3,
        vc_nombre: "Blue Dark",
        typeMenu: "dark",
        typeMain: "dark",
        typeSidebar: "dark",
        primary: blue,
      },
      {
        id: 4,
        vc_nombre: "Blue Light",
        typeMenu: "dark",
        typeMain: "light",
        typeSidebar: "dark",
        primary: blue,
      },
      {
        id: 5,
        vc_nombre: "Pink Dark",
        typeMenu: "dark",
        typeMain: "dark",
        typeSidebar: "dark",
        primary: pink,
      },
      {
        id: 6,
        vc_nombre: "Pink Light",
        typeMenu: "dark",
        typeMain: "light",
        typeSidebar: "dark",
        primary: pink,
      },
      {
        id: 7,
        vc_nombre: "Indigo Dark",
        typeMenu: "dark",
        typeMain: "dark",
        typeSidebar: "dark",
        primary: indigo,
      },
      {
        id: 8,
        vc_nombre: "Indigo Light",
        typeMenu: "dark",
        typeMain: "light",
        typeSidebar: "dark",
        primary: indigo,
      },
      {
        id: 9,
        vc_nombre: "Thin-k Vintage Dark",
        typeMenu: "dark",
        typeMain: "dark",
        typeSidebar: "dark",
        primary: orange,
      },
      {
        id: 10,
        vc_nombre: "Thin-k Vintage Light",
        typeMenu: "dark",
        typeMain: "light",
        typeSidebar: "dark",
        primary: orange,
      },
      {
        id: 11,
        vc_nombre: "Thin-k Gray Dark",
        typeMenu: "dark",
        typeMain: "dark",
        typeSidebar: "dark",
        primary: blueGrey,
      },
      {
        id: 12,
        vc_nombre: "Thin-k Gray Light",
        typeMenu: "dark",
        typeMain: "light",
        typeSidebar: "dark",
        primary: blueGrey,
      },
    ];
  }
  optionsInstituciones() {
    const instituciones = window.amplify.store("institutions") || [];
    return instituciones
      .filter((e) =>
        e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase())
      )
      .map((institucion, index) => {
        const text = (
          <div style={{ textTransform: "capitalize" }}>
            {institucion.vc_nombre.toLowerCase()}
          </div>
        );
        return { key: index, value: institucion.id_institucion, text };
      });
  }
  onChangeTheme(theme) {
    this.setState({ showInstitutions: false, showMenuPalets: false }, () => {
      window.amplify.store("theme", theme);
      window.location.reload();
      this.props.refreshTheme && this.props.refreshTheme();
    });
  }
  onChangeInstitution = ({ value }) => {
    const institucion = window.amplify
      .store("institutions")
      .find((e) => e.id_institucion === value);
    if (institucion) {
      window.amplify.store("institution", institucion);
      window.amplify.store("user_id", institucion.id_usuario);
      window.amplify.store("roles", institucion.roles);
      this.setState(
        { institucion, showInstitutions: false, menuOptionActual: "inicio" },
        () => {
          this.navigate("#/admin");
        }
      );
    } else {
      this.setState(
        { showInstitutions: false, menuOptionActual: "inicio" },
        () => {
          this.navigate("#/admin");
        }
      );
    }
  };
  handleClose(opcion) {
    this.setState({ showInstitutions: false, showMenuPalets: false });
  }
  handleOpenMenu() {
    // console.log('menu open')
    this.setState({ showInstitutions: !this.state.showInstitutions }, () => {});
  }

  handleOpenTheme() {
    this.setState({ showMenuPalets: !this.state.showMenuPalets });
  }
  renderMenuSidebar() {
    return (
      <MenuList style={{ width: "100%", paddingTop: 0, paddingRight: 1 }}>
        {/* <MenuItem>
          <ListItemIcon>
            <Grid centered>
              <Avatar
              src={'assets/images/iconThin-k.png'}
              />
            </Grid>
          </ListItemIcon>
        </MenuItem> */}
        <MenuItem
          button
          key={"institutionSelector"}
          onClick={this.handleOpenMenu.bind(this)}
        >
          <ListItemIcon style={{ minWidth: 35 }}>
            {/* <Dropdown
              key='institucion'
              additionLabel='Crear nueva institución'
              additionPosition='bottom'
              placeholder={this.state.showMenu ? 'Selecciona institucion' : ''}
              icon='hospital outline'
              fluid
              search
              allowAdditions={window.amplify.store('institutions') ? window.amplify.store('institutions').length === 0 : false}
              renderLabel={(text, index) => {
                console.log('renderizando label', index)
                return (
                  <ListItem button key={'institutionSelector'} onClick={() => this.onMainOptionClicked(opcion)}>
                    <ListItemIcon>
                      <Icon name='hospital outline' />
                    </ListItemIcon>
                    <ListItemText color={'gray'} primary={text} />
                  </ListItem>
                )
              }}
              selection
              options={this.optionsInstituciones()}
              onChange={this.onChangeInstitution}
              // value={window.amplify.store('institution') ? window.amplify.store('institution').id_institucion : false}
              style={{ width: '100%', padding: 15, borderBottom: 'solid 1px gray', borderRight: `solid -5px ${colors.itemSelected}`, color: 'white', fontSize: 15, fontWeight: 10, border: 0, backgroundColor: 'transparent' }}
            /> */}
            <Icon name={"hospital"} />
          </ListItemIcon>
          <ListItemText
            primary={
              window.amplify.store("institution")
                ? window.amplify.store("institution").vc_nombre
                : "Crea una Institución"
            }
            style={{ paddingTop: 3 }}
          />
        </MenuItem>
        {this.renderMenuOptions()}
        <MenuItem
          button
          key={"themeSelector"}
          onClick={this.handleOpenTheme.bind(this)}
        >
          <ListItemIcon style={{ minWidth: 35 }}>
            <Icon name={"paint brush"} />
          </ListItemIcon>
          <ListItemText primary={"Personalizar"} style={{ paddingTop: 3 }} />
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={(() => {
            if (
              this.props.pacienteActual &&
              this.props.pacienteActual.detalle &&
              !isMobile
            ) {
              return false;
            }
            return true;
          })()}
          button
          key={"videocall"}
          onClick={() =>
            this.setState({ showVideoCall: !this.state.showVideoCall })
          }
        >
          <ListItemIcon style={{ minWidth: 35 }}>
            <Icon name={"video"} />
          </ListItemIcon>
          <ListItemText
            primary={"Video Conferencia"}
            style={{ paddingTop: 3 }}
          />
        </MenuItem>
      </MenuList>
    );
  }
  searchInstitutions(e) {
    this.setState({ searchText: e.target.value });
  }
  renderInstitutionSelector() {
    const institucionActual = this.state.institucion || {};
    const { router } = this.props;
    return (
      <Dialog
        scroll="paper"
        onClose={this.handleClose.bind(this)}
        aria-labelledby="simple-dialog-title"
        open={this.state.showInstitutions || false}
      >
        <ListItem style={{ padding: "10px 20px 0px 20px" }}>
          <Search style={{ margin: 10 }} />
          <Input
            onChangeCapture={this.searchInstitutions.bind(this)}
            placeholder="Buscar..."
            disableUnderline
            style={{ width: "100%" }}
          />
        </ListItem>
        <List style={{ height: "100%" }}>
          <Divider style={{ margin: 0 }} />
          {this.optionsInstituciones().map((institucion) => (
            <ListItem
              button
              onClick={() => this.onChangeInstitution(institucion)}
              key={institucion.key}
            >
              <IconButton>
                <FolderShared />
              </IconButton>
              <ListItemText primary={institucion.text} />
              <div style={{ float: "right", width: 50 }}>
                {window.amplify.store("institution") &&
                institucionActual.id_institucion === institucion.value ? (
                  <Check color="primary" style={{ float: "right" }} />
                ) : null}
              </div>
            </ListItem>
          ))}
          {
            // window.amplify.store('user') && window.amplify.store('user').usuario && window.amplify.store('user').usuario.vc_email === 'demo@thin-k.mx' &&
            <ListItem
              button
              onClick={() => {
                window.amplify.store("institution", null);
                this.setState(
                  {
                    showInstitutions: false,
                    institucionActual: null,
                    menuOptionActual: "configuracion",
                    institucion: null,
                  },
                  () => {
                    this.navigate("#/admin");
                    this.navigate("#/admin/configuracion");
                  }
                );
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Agregar Institución" />
            </ListItem>
          }
        </List>
      </Dialog>
    );
  }
  renderDesignSelector() {
    const institucionActual = this.state.institucion || {};
    const { router } = this.props;

    return (
      <Dialog
        scroll="paper"
        onClose={this.handleClose.bind(this)}
        aria-labelledby="simple-dialog-title"
        open={this.state.showMenuPalets || false}
      >
        <Typography align="center" style={{ padding: "10px 20px 0px 20px" }}>
          Selecciona un Tema:
        </Typography>
        <List style={{ height: "100%" }}>
          <Divider style={{ margin: 0 }} />
          {this.optionsPalettes().map((theme) => (
            <ListItem
              button
              onClick={() => this.onChangeTheme(theme)}
              key={theme.id}
            >
              <Paper
                style={{
                  width: 60,
                  height: 40,
                  overflow: "hidden",
                  marginLeft: 8,
                  marginRight: 16,
                  backgroundColor: "white",
                }}
              >
                <GridUI
                  container
                  style={{
                    maxWidth: 60,
                    height: "100%",
                    display: "flex",
                    borderTop: `5px solid ${theme.primary[400]}`,
                  }}
                >
                  <GridUI
                    item
                    xs={2}
                    style={{
                      backgroundColor:
                        theme.typeMenu === "light" ? "white" : "black",
                      opacity: 0.5,
                      borderRight: "1px solid gray",
                    }}
                  />
                  <GridUI
                    item
                    xs={7}
                    style={{
                      backgroundColor:
                        theme.typeMain === "light" ? "white" : "black",
                      opacity: 0.5,
                    }}
                  />
                  <GridUI
                    item
                    xs={3}
                    style={{
                      backgroundColor:
                        theme.typeSidebar === "light" ? "white" : "black",
                      opacity: 0.5,
                      borderLeft: "1px solid gray",
                    }}
                  />
                </GridUI>
              </Paper>
              <ListItemText primary={theme.vc_nombre} />
              <div style={{ float: "right", width: 50 }}>
                {(window.amplify.store("theme") || []).id === theme.id ? (
                  <Check color="primary" style={{ float: "right" }} />
                ) : null}
              </div>
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }
  render() {
    if (isMobile) {
      return (
        <ThemeProvider theme={theme}>
          <Drawers
            sidebar={this.renderMenuSidebar()}
            onMainOptionClicked={this.onMainOptionClicked.bind(this)}
            main={this.renderMainSection()}
            helpBar={<HelperSidebar />}
            showVideoCall={!!this.state.showVideoCall}
          />
          {this.renderInstitutionSelector()}
          {this.renderDesignSelector()}
          {
            <Snackbar
              open={!!this.state.showInstallPromotion}
              autoHideDuration={15000}
              onClose={() => this.setState({ showInstallPromotion: false })}
            >
              <SnackbarContent
                message={"Instala la App para acceder mas rapido"}
                action={
                  <Button
                    onClick={() => {
                      // Hide the app provided install promotion
                      this.setState({ showInstallPromotion: false });
                      // Show the install prompt
                      this.deferredPrompt.prompt();
                      // Wait for the user to respond to the prompt
                      this.deferredPrompt.userChoice.then((choiceResult) => {
                        if (choiceResult.outcome === "accepted") {
                          console.log("User accepted the install prompt");
                        } else {
                          console.log("User dismissed the install prompt");
                        }
                      });
                    }}
                    color="primary"
                  >
                    {"Instalar"}
                  </Button>
                }
              />
            </Snackbar>
          }
        </ThemeProvider>
      );
    }
    return (
      <ThemeProvider theme={theme}>
        <ToolBottomBar
          setHiddeVideoCall={() => this.setState({ showVideoCall: false })}
          content={
            <Drawers
              sidebar={this.renderMenuSidebar()}
              onMainOptionClicked={this.onMainOptionClicked.bind(this)}
              main={this.renderMainSection()}
              helpBar={<HelperSidebar />}
              showVideoCall={!!this.state.showVideoCall}
            />
          }
          showVideoCall={!!this.state.showVideoCall}
        />
        {this.renderInstitutionSelector()}
        {this.renderDesignSelector()}
        {
          <Snackbar
            open={!!this.state.showInstallPromotion}
            autoHideDuration={15000}
            onClose={() => this.setState({ showInstallPromotion: false })}
          >
            <SnackbarContent
              message={"Instala la App para acceder mas rapido"}
              action={
                <Button
                  onClick={() => {
                    // Hide the app provided install promotion
                    this.setState({ showInstallPromotion: false });
                    // Show the install prompt
                    this.deferredPrompt.prompt();
                    // Wait for the user to respond to the prompt
                    this.deferredPrompt.userChoice.then((choiceResult) => {
                      if (choiceResult.outcome === "accepted") {
                        console.log("User accepted the install prompt");
                      } else {
                        console.log("User dismissed the install prompt");
                      }
                    });
                  }}
                  color="primary"
                >
                  {"Instalar"}
                </Button>
              }
            />
          </Snackbar>
        }
      </ThemeProvider>
    );

    return (
      <ThemeProvider theme={theme}>
        <Drawers
          sidebar={this.renderMenuSidebar()}
          onMainOptionClicked={this.onMainOptionClicked.bind(this)}
          main={
            <ToolBottomBar
              content={this.renderMainSection()}
              showVideoCall={!!this.state.showVideoCall}
            />
          }
          helpBar={<HelperSidebar />}
        />
        {this.renderInstitutionSelector()}
        {this.renderDesignSelector()}
        {/* {
          this.state.showVideoCall &&
          <VideoCall
            open={!!this.state.showVideoCall}
            mode={'draggable'}
          />
        } */}
        {
          <Snackbar
            open={!!this.state.showInstallPromotion}
            autoHideDuration={15000}
            onClose={() => this.setState({ showInstallPromotion: false })}
          >
            <SnackbarContent
              message={"Instala la App para acceder mas rapido"}
              action={
                <Button
                  onClick={() => {
                    // Hide the app provided install promotion
                    this.setState({ showInstallPromotion: false });
                    // Show the install prompt
                    this.deferredPrompt.prompt();
                    // Wait for the user to respond to the prompt
                    this.deferredPrompt.userChoice.then((choiceResult) => {
                      if (choiceResult.outcome === "accepted") {
                        console.log("User accepted the install prompt");
                      } else {
                        console.log("User dismissed the install prompt");
                      }
                    });
                  }}
                  color="primary"
                >
                  {"Instalar"}
                </Button>
              }
            />
          </Snackbar>
        }
      </ThemeProvider>
    );
  }
}

const styles = {
  container: {
    overflowX: "hidden",
    overflowY: "hidden",
    height: "100%",
    width: "100%",
    padding: 0,
    margin: 0,
    flexDirection: "row",
  },
  body: {
    top: 0,
    height: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
    position: "relative",
    margin: 0,
    padding: 0,
  },
  bodyMin: {
    height: "100%",
    margin: 0,
    padding: 0,
    width: "100%",
    overflowX: "scroll",
    WebkitOverflowScrolling: "touch",
  },
  layerContainer: {
    height: "100vh",
    width: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    float: "center",
    padding: 0,
    // backgroundImage: 'url(assets/images/quirofano.jpg)',
    backgroundImage: "linear-gradient(white, gray)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    filter: "blur(100px)",
  },
  image: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    zIndex: -1,
  },
  centralColumn: {
    overflowY: "hidden",
    height: "100%",
    background: "rgba(50,50,50,0.4)",
    padding: 0,
    margin: 0,
  },
  centralColumnMin: {
    overflowY: "hidden",
    height: "100%",
    background: "rgba(50,50,50,0.4)",
    padding: 0,
    margin: 0,
    float: "left",
    // width: window.screen.availWidth
  },
  mainSidebar: {
    background: "rgba(0,0,0,0.9)",
    width: "10%",
    margin: 0,
    padding: 0,
    // boxShadow: '1px 0px 10px rgba(0, 0,0, 0.7)'
  },
  LeftSidebar: {
    background: "rgba(0,0,0,0.9)",
    overflowX: "hidden",
    paddingRight: 0,
    // boxShadow: '1px 0px 10px rgba(0, 0,0, 0.7)'
  },
  helperSidebar: {
    background: "rgba(255,255,255,0.9)",
    overflowX: "hidden",
    overflowY: "hidden",
    top: 0,
    bottom: 0,
    // width: '30%',
    margin: 0,
    padding: 0,
    // boxShadow: '-1px 0px 10px rgba(0, 0, 0, 0.7)'
  },
  helperSidebarMin: {
    background: "rgba(255,255,255,0.9)",
    overflowX: "hidden",
    overflowY: "hidden",
    height: "100%",
    width: window.screen.availWidth,
    margin: 0,
    float: "left",
    padding: 0,
    // boxShadow: '-1px 0px 10px rgba(0, 0, 0, 0.7)'
  },
  notifications: {
    background: "rgba(255,255,255,0.9)",
    overflowX: "hidden",
    overflowY: "hidden",
    position: "absolute",
    height: "80%",
    width: "80%",
    borderRadius: 5,
    margin: "10%",
    padding: "10%",
    // boxShadow: '-1px 0px 10px rgba(0, 0, 0, 0.7)'
  },
  partialView: {
    background: "rgba(255,255,255,0.9)",
    margin: 0,
    padding: 0,
    height: "100%",
    borderRadius: "0px",
    backgroundColor: "transparent",
  },
};

const stylesInit = {
  layerContainer: {
    overflow: "hidden",
    height: "100%",
    float: "center",
    width: "100%",
    backgroundSize: "cover",
  },
  container: {
    width: "100%",
    overflow: "hidden",
    float: "center",
    borderRadius: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    // boxShadow: '1px 0px 10px rgba(0, 0,0, 0.7)'
  },
};

DashboardLayout.propTypes = {
  children: PropTypes.element,
};

const actions = {
  setDisplayMenu,
  setDisplaySidebar,
  setState,
  updateCatalogs,
  setLibs,
};
const mapStateToProps = (state) => {
  const { paises, pacienteActual } = state.Think;
  return { paises, pacienteActual };
};

export default connect(mapStateToProps, actions)(DashboardLayout);

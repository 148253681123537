import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Container, Input, Loader } from 'semantic-ui-react';

class MultimediaHelpBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: "",
            indexHover: null,
            indexSelected: this.props.indexSelected,
            opcionPreSelected: null,
        };
    }

    componentDidMount() {
        document.onkeypress = this.modifyEnterKeyPressAsTab.bind(this);
        document.onkeydown = this.pressArrows.bind(this);
    }

    componentDidUpdate() {
        if (document.activeElement != window.$('#searchBar')) {
            //window.$('#searchBar').focus();
        }
    }
    pressArrows(e) {
        let opcionPreSelected = this.state.opcionPreSelected;
        if (e.keyCode == 38) {
            if (opcionPreSelected == null || opcionPreSelected == 0) {
                opcionPreSelected = 0;
            } else {
                opcionPreSelected = (opcionPreSelected - 1);
            }
        } else if (e.keyCode == 40) {
            if (opcionPreSelected == null) {
                opcionPreSelected = 0;
            } else if (this.opcionesArray().length > (opcionPreSelected + 1)) {
                opcionPreSelected = (opcionPreSelected + 1);
            }
        }
        this.setState({ opcionPreSelected });
    }
    modifyEnterKeyPressAsTab(e) {
        if (window.event && window.event.keyCode == 13) {
            switch (this.props.opcionEstudios) {
                case 'agregar':
                    if (this.state.opcionPreSelected != null) {
                        const item = this.opcionesArray()[this.state.opcionPreSelected];
                        this.add(item, null);
                    }
                    break;
                case 'prioridad':
                    if (this.state.opcionPreSelected != null) {
                        const item = this.opcionesArray()[this.state.opcionPreSelected];
                        this.setPrioridad(item, null);
                    }
                    break;
                case 'observaciones':
                    if (this.state.searchText / 1 > 0) {
                        const item = {
                            vc_nombre: `${this.state.searchText / 1}`,
                            valor: `${this.state.searchText / 1}`
                        };
                        this.setObservaciones(item, null);
                    }
                    break;
            };
        }
    }
    // descripcion estudio
    timeSince(refDate, date) {

        var seconds = Math.floor((refDate - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " años";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " minutos";
        }
        return "un instante";
    }
    statusDxToString(id_estatusDiagnostico) {
        switch (id_estatusDiagnostico) {
            case 1:
                return "presuntivo";
            case 2:
                return "definitivo ó confirmado";
            case 3:
                return "descartado";
            default:
                return "presuntivo";
        }
    }

    isDxActive(dt_vigencia) {
        if ((new Date(dt_vigencia) < (new Date()))) {
            return false
        }
        return true
    }
    //
    onChangeValue(event) {
        this.setState({ searchText: event.target.value });
    }
    searchBarEvent(event) {
        let opcionPreSelected = null;
        if (this.opcionesArray().filter(e => e.vc_nombre.toLowerCase().includes(event.target.value.toLowerCase()) ).filter((e, index) => index < 30).length > 0) {
            opcionPreSelected = 0;
        } else {
            opcionPreSelected = null
        }
        this.setState({ searchText: event.target.value, opcionPreSelected });
    }
    add(item, index) {
        this.setState({ searchText: '' }, () => this.props.callback({ item, opcionesModuloNext: 'visualizar' }));
    }
    addVideo(item, index) {
        this.setState({ searchText: '' }, () => this.props.callbackVideo({ item, opcionesModuloNext: 'registro' }));
    }
    setPrioridad(item, index) {
        const estudio = this.props.estudio;
        estudio.id_prioridad = item.valor;
        this.setState({ searchText: '' }, () => this.props.callback({ estudio, opcionesModuloNext: 'observaciones' }));
    }
    setObservaciones(item, index) {
        const estudio = this.props.estudio;
        estudio.vc_observaciones = item.valor;
        this.setState({ searchText: '' }, () => this.props.callback({ estudio, opcionesModuloNext: 'siguienteEstudio' }));
    }
    renderTitleHelpBar() {
        const { date, medicamento, opcionEstudios, opcion } = this.props;
        const lastDetalle = medicamento ? medicamento.detalles[medicamento.detalles.length - 1] : null;
        let umInicio = null;
        let umStringInicio = null;

        switch (opcionEstudios) {
            case "visualizar":
                return (
                    <div style={{ padding: 0, border: 0 }}>
                        <button style={{ padding: 0, border: 0, height: 74, width: '100%', textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                            Buscar Imagenes
                    </button>
                        <div style={styles.titleHelp}>
                            <Input
                                id='searchBar'
                                value={this.state.searchText}
                                focus placeholder='Buscar...'
                                onChange={this.searchBarEvent.bind(this)}
                                style={{ width: '100%', height: 31 }}
                            />
                        </div>
                    </div>
                );
            case "registro":
                return (
                    <div style={{ padding: 0, border: 0 }}>
                        <button style={{ padding: 0, border: 0, height: 74, width: '100%', textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                            Buscar Videos
                    </button>
                        <div style={styles.titleHelp}>
                            <Input
                                id='searchBar'
                                value={this.state.searchText}
                                focus placeholder='Buscar...'
                                onChange={this.searchBarEvent.bind(this)}
                                style={{ width: '100%', height: 31 }}
                            />
                        </div>
                    </div>
                );
        };
    }
    quitaacentos(s) {
        var r = s.toLowerCase();
        r = r.replace(new RegExp(/\s/g), "");
        r = r.replace(new RegExp(/[àáâãäå]/g), "a");
        r = r.replace(new RegExp(/[èéêë]/g), "e");
        r = r.replace(new RegExp(/[ìíîï]/g), "i");
        r = r.replace(new RegExp(/ñ/g), "n");
        r = r.replace(new RegExp(/[òóôõö]/g), "o");
        r = r.replace(new RegExp(/[ùúûü]/g), "u");
        return r;
    }
    opcionesArray() {
        const { date, multimedias, opcionEstudios, opcion } = this.props;
        let opciones = [];
        let i = 0;
        switch (opcionEstudios) {
            case "visualizar":
                const tiposLesiones = [
                    {
                        id: 1,
                        vc_nombre: 'heridas'
                    },
                    {
                        id: 2,
                        vc_nombre: 'contusión'
                    },
                    {
                        id: 3,
                        vc_nombre: 'laceración'
                    },
                    {
                        id: 4,
                        vc_nombre: 'masas'
                    },
                    {
                        id: 5,
                        vc_nombre: 'otras lesiones'
                    },
                    {
                        id: 6,
                        vc_nombre: 'Imagenologia'
                    },
                    {
                        id: 7,
                        vc_nombre: 'Laboratorio'
                    }
                ]
                if (this.state.searchText === '') {
                    return multimedias;
                }
                return multimedias.filter(e => tiposLesiones[e.id_tipoLesion].vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) ).filter((e, index) => index < 60);
            case "registro":
                const estudios = this.props.estudios;
                if (this.state.searchText === '') {
                    return multimedias;
                }
                return multimedias.filter(e => e.id_tipoEstudio ? estudios[e.id_estudio].vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase())  : false).filter((e, index) => index < 60);
        };
    }
    iconTipoEstudio(item) {
        if (item.id_tipoEstudio) {
            switch (item.id_tipoEstudio) {
                case 1:
                    return 'lab'
                    break;
                case 2:
                    return 'id badge'
                    break;
                case 3:
                    return 'lab'
                    break;
            }
        } else {
            return 'cubes'
        }
    }
    renderLoader(item) {
        if (!item.url) {
            return (
                <Loader content='Registrando Imagen' />
            );
        }
    }
    renderOpcionesHelpBar() {
        const { date, opcionEstudios } = this.props;
        let opciones = [];
        let i = 0;
        switch (opcionEstudios) {
            case "visualizar":
                const tiposLesiones = [
                    {
                        id: 1,
                        vc_nombre: 'heridas'
                    },
                    {
                        id: 2,
                        vc_nombre: 'contusión'
                    },
                    {
                        id: 3,
                        vc_nombre: 'laceración'
                    },
                    {
                        id: 4,
                        vc_nombre: 'masas'
                    },
                    {
                        id: 5,
                        vc_nombre: 'otras lesiones'
                    },
                    {
                        id: 6,
                        vc_nombre: 'Imagenologia'
                    },
                    {
                        id: 7,
                        vc_nombre: 'Laboratorio'
                    }
                ]
                return (
                    this.opcionesArray().map((item, index) =>
                        <div key={index} onClick={() => { item.url && this.add(item, index) }} key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp}>
                            <div>
                                {/* {tiposLesiones[item.id_tipoLesion - 1].vc_nombre}<Loader size='mini' active={item.url ? false : true} inline style={{float: 'right'}}/> */}
                            </div>
                            <div style={{ width: '100%', float: 'right', textAlign: 'right', fontSize: 10, color: window.colors.itemSelected }}>
                                {moment(item.timeStamp).format('dddd D MMMM YYYY')}
                            </div>
                            <div style={{ width: '100%', float: 'right', textAlign: 'right', fontSize: 10, color: window.colors.itemSelected }}>
                                {moment(item.timeStamp).format('HH:mm')}
                            </div>
                        </div>
                    )
                );
            case "registro":
                return (
                    this.opcionesArray().map((item, index) =>
                        <div key={index} onClick={() => { item.url && this.addVideo(item, index) }} key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp}>
                            <div>
                                {/* { item.id_estudio ? this.estudiosCat[0].vc_nombre : 'Video'}<Loader size='mini' active={item.url ? false : true} inline style={{float: 'right'}}/> */}
                            </div>
                            <div style={{ width: '100%', float: 'right', textAlign: 'right', fontSize: 10, color: window.colors.itemSelected }}>
                                {moment(item.timeStamp).format('dddd D MMMM YYYY')}
                            </div>
                            <div style={{ width: '100%', float: 'right', textAlign: 'right', fontSize: 10, color: window.colors.itemSelected }}>
                                {moment(item.timeStamp).format('HH:mm')}
                            </div>
                        </div>
                    )
                );
            default: return [];
        };
    }
    estudiosCat(item) {
        const estudiosFilter = this.props.estudios.filter((e) => e.id === item.id);
        return estudiosFilter;
    }
    render() {
        const { index } = this.props;
        const shadow = this.state.indexSelected == index ? '1px 1px 20px rgba(0, 0,0, 1)' : '1px 0px 10px rgba(0, 0,0, 0.7)';
        const results = this.opcionesArray();
        return (
            <Container key={index} style={{ ...styles.container, height: this.props.pageSize.height }}>
                {this.renderTitleHelpBar()}
                <div
                    id='scrollOptions'
                    style={{
                        ...styles.containerSub,
                        height: this.props.pageSize.height - 111
                    }}>
                    {this.renderOpcionesHelpBar(results)}
                    <div style={{ textAlign: 'center', alignContent: 'center', color: window.colors.textArea, padding: 10 }}>
                        {results.length === 1 ? ' Solo 1 resultado encontrado.' : `${results.length} resultados encontrados.`}
                    </div>
                </div>
            </Container>
        );
    }
}


const styles = {
    container: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0
    },
    containerSub: {
        overflowX: 'hidden',
        overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
        width: '100%',
        height: 'calc(100% - 111px)',
        margin: 0,
        padding: 0
    },
    containerHelp: {
        margin: 0,
        marginBottom: 2,
        borderRadius: 3,
        overflowY: "hidden",
        padding: 10,
        color: "black",
        backgroundColor: window.colors.white
    },
    containerSelectedHelp: {
        margin: 0,
        marginBottom: 2,
        borderRadius: 3,
        overflowY: "hidden",
        padding: 10,
        marginBottom: 2,
        color: "white",
        backgroundColor: window.colors.itemSelected
    },
    descriptionHelp: {
        margin: 0,
        marginTop: 5,
        marginLeft: 10,
        marginRight: 10,
        textAlign: "left",
        fontSize: 14,
        minHeight: 40,
        color: "gray"
    },
    descriptionSelectedHelp: {
        margin: 0,
        marginTop: 5,
        marginLeft: 10,
        marginRight: 10,
        textAlign: "left",
        fontSize: 14,
        minHeight: 40,
        color: "white"
    },
    stickHelp: {
        margin: 0,
        marginLeft: "85%",
        marginBottom: 2,
        width: 30,
        overflowY: "hidden",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color: window.colors.itemSelected
    },
    subTitleHelp: {
        margin: 0,
        marginLeft: 10,
        marginRight: 10,
        width: "100%",
        overflowY: "scroll",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color: "black"
    },
    subTitleRightHelp: {
        margin: 0,
        marginLeft: 10,
        marginRight: 10,
        width: "100%",
        paddingTop: 0,
        textAlign: "right",
        float: "right",
        fontSize: 12,
        color: window.colors.itemSelected
    },
    titleHelp: {
        width: "100%",
        padding: 3,
        height: 37,
        color: "white",
    }
};


const mapStateToProps = (state) => {
    const { estudios, gruposEstudios, paquetesEstudios, gruposEstudios_estudios, paquetesEstudios_estudios_grupos, pacienteActual } = state.Think;
    return { estudios, gruposEstudios, paquetesEstudios, gruposEstudios_estudios, paquetesEstudios_estudios_grupos, pacienteActual };
};


export default connect(mapStateToProps)(MultimediaHelpBar);

import React from "react";
import LoginDialog from "app/sales/components/Common/LoginDialog";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setEmpresa, setCaja } from "app/sales/store/actions";
import useApi from "app/sales/hooks/useApi";

export default () => {
  const api = useApi("auth");
  const { post: logout } = useApi("auth/logout");
  const state = useSelector((state) => state.Sales);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(state.isLoggedIn);

  const { vc_nombre } = window.amplify.store("institution") || {
    vc_nombre: "Institucion",
  };

  const onOpen = () => setIsOpen(true);
  const handleAccept = async (vc_pin) => {
    const institucion = window.amplify.store("institution");
    const ref_idUsuario = window.amplify.store("user_id");
    const { id_institucion: ref_idInstitucion } = institucion;

    try {
      const res = await api.post({
        vc_pin,
        ref_idUsuario,
        ref_idInstitucion,
      });

      dispatch(setEmpresa(res.empresa.uuid));
      if (res.caja) dispatch(setCaja(res.caja.uuid));

      setIsLoggedIn(true);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogged = (value) => {
    setIsLoggedIn(value);
    dispatch(setLoggedIn(value));
  };

  const validateSession = async (iniciar = false) => {
    try {
      const res = await api.get();

      dispatch(setEmpresa(res.empresa.uuid));
      if (res.caja) dispatch(setCaja(res.caja.uuid));

      handleLogged(true);

      return true;
    } catch (err) {
      handleLogged(false);
      iniciar && onOpen();
      return false;
    }
  };

  React.useEffect(() => {
    validateSession(true);

    return () => logout();
  }, []);

  const Dialog = React.useCallback(
    () => (
      <LoginDialog
        open={isOpen}
        institucion={vc_nombre}
        onClose={() => setIsOpen(false)}
        onAccept={handleAccept}
      />
    ),
    [isOpen]
  );

  return {
    LoginDialog: Dialog,
    validateSession,
    onOpen,
    isLoggedIn,
  };
};

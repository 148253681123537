import React from "react";

import { connect } from "react-redux";
import SubMenuModulos from "app/material-ui/SubMenuModulos";
import * as firebase from "firebase/app";
import moment from "moment";

import ButtonDateRange from "app/components/Form/ButtonDateRange";

import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "app/containers/Think/actions";

import HojaDiaria from "./views/HojaDiaria";
import { Box } from "@material-ui/core";

const getContent = (opcion) => {
  switch (opcion) {
    case "hojadiaria": {
      return <HojaDiaria />;
    }
    default:
      return <Box />;
  }
};

const Index = ({ modulo, ...props }) => {
  const [content, setContent] = React.useState(null);
  const [opcionActual, setOpcionActual] = React.useState(
    modulo?.opciones[0]?.id || ""
  );
  const [range, setRange] = React.useState([
    moment().startOf("day"),
    moment().endOf("day"),
  ]);

  React.useEffect(() => {
    props.setDisplaySidebar(false);
    props.setHelperSidebarRender(null);
  }, []);

  React.useEffect(() => {
    setContent(React.cloneElement(getContent(opcionActual), { range }));
  }, [opcionActual, range]);

  const opciones = modulo.opciones.map((opcion) => {
    return {
      ...opcion,
      action: () => {
        setOpcionActual(opcion.id);
      },
    };
  });
  const opcion = opciones.find((v) => v.id === opcionActual);
  const opcionesDisplay = ["Reportes", opcion?.vc_nombre || ""];

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <SubMenuModulos
        title={<ButtonDateRange value={range} onChange={setRange} />}
        options={opciones}
        optionDisplay={opcionesDisplay}
      />
      <Box flex="1 1 auto" height="0px" overflow="scroll" bgcolor="background.paper">
        {content}
      </Box>
    </Box>
  );
};

const actions = { setHelperSidebarRender, setDisplaySidebar };
const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  return {
    catalogo: catalogos.find((e) => e.vc_nameTable === "ins_pacientes"),
  };
};

export default connect(mapStateToProps, actions)(Index);

import moment from 'moment';
import _ from 'lodash';
import 'moment/locale/es';

const formatter = {
	date(date, formatString = 'D MMMM YYYY') {
		if (!date) return '';

		moment.locale('es');
		return moment(date).format(formatString);
	},
	currency(number = 0) {
		const formattedNumber = `$${number.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
		const arrayNumber = formattedNumber.split('.');
		return arrayNumber[1] === '00' ? arrayNumber[0] : formattedNumber;
	},
	thousandSeparator(number = 0) {
		const formattedNumber = `${number.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
		const arrayNumber = formattedNumber.split('.');
		return arrayNumber[1] === '00' ? arrayNumber[0] : formattedNumber;
	},
	capitalize(text = '') {
		if (!text.length) {
			return text;
		}

		if (text.length === 1) {
			return text.toUpperCase();
		}

		const firstLetter = text.substring(0, 1);
		const remainingText = text.substring(1);
		return `${firstLetter.toUpperCase()}${remainingText.toLowerCase()}`;
	},
	clean(object) {
		const result = {};
		const objectAttributes = Object.keys(object);

		objectAttributes.forEach(attribute => {
			const attributeValue = object[attribute];

			if (_.isPlainObject(attributeValue)) {
				result[attribute] = formatter.clean(attributeValue);
			} else if (!_.isFunction(attributeValue)) {
				result[attribute] = attributeValue;
			}
		});

		return result;
	},
	optionsValues(object) {
		const values = {};

		Object.keys(object).forEach((fieldName) => {
			values[fieldName] = object[fieldName] ? object[fieldName].value : '';
		});

		return values;
	}
};

// exportar formateador
export default formatter;

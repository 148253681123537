import httpAdmin from '../httpAdmin';

const getQueryStringFromObject = o =>
  Object.keys(o).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(o[k])}`).join('&');

export default {
  unique(payload, callback) {
    const params = getQueryStringFromObject(payload);
    const headers = { Authorization: window.amplify.store('token') };

    httpAdmin.get(`/api/v1/unique?${params}`, headers, callback);
  },
  getPackages(institucionId, callback) {
    const headers = { Authorization: window.amplify.store('token') };

    httpAdmin.get(`/api/v1/paquetes?institucionId=${institucionId}`, headers, callback);
  },
  addPackage(data, callback) {
    const headers = { Authorization: window.amplify.store('token') };

    let method = 'post';
    let url = '/api/v1/paquetes';

    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }

    httpAdmin[method](url, headers, data, (err, response) => {
      callback(err, response);
    });
  },
  updatePackage(data, callback) {
    const headers = { Authorization: window.amplify.store('token') };

    httpAdmin.put(`/api/v1/paquetes/${data.id}`, headers, data, (err, response) => {
      callback(err, response);
    });
  },
  makePayment(data, callback) {
    const headers = { Authorization: window.amplify.store('token') };

    let method = 'post';
    let url = '/api/v1/venta';

    httpAdmin.post(url, headers, data, (err, response) => {
      callback(err, response);
    });
  },
  addPaymentMethod(data, callback) {
    const headers = { Authorization: window.amplify.store('token') };

    let method = 'post';
    let url = '/api/v1/formaPagos';

    httpAdmin.post(url, headers, data, (err, response) => {
      callback(err, response);
    });
  },
  deletePaymentAccount(id, callback) {
    console.log('callback', callback)
    const headers = { Authorization: window.amplify.store('token') };
    const url = `/api/v1/formaPagos/${id}`;
    httpAdmin.delete(url, headers,{}, callback);
  }
};

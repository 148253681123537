import React from "react";
import { fade, makeStyles, createMuiTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import Save from "@material-ui/icons/Save";
import FileCopy from "@material-ui/icons/FileCopy";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Button, Breadcrumbs, Link } from "@material-ui/core";
import { isString, isArray } from "util";
import PrintComponents from "react-print-components";
import Fade from "@material-ui/core/Fade";
import { ThemeProvider } from "styled-components";
import { teal } from "@material-ui/core/colors";
const themeSelected = window.amplify.store("theme") || null;
let theme = createMuiTheme({
  palette: {
    primary: themeSelected ? themeSelected.primary : teal,
    secondary: {
      main: "#fafafa",
    },
    type: "dark",
  },
});

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: "inherit",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    margin: 0,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    color: "inherit",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    color: "inherit",
  },
}));

export default function SubMenuModulos(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";

  let optionDisplay = isString(props.optionDisplay)
    ? [props.optionDisplay]
    : props.optionDisplay;
  optionDisplay = isArray(optionDisplay) ? optionDisplay : [];

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.grow} style={{ flex: 0, position: "relative" }}>
        <AppBar style={{ height: 47, position: "relative", flex: 0 }}>
          <Toolbar style={{ minHeight: "100%" }}>
            {props.back && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="Open drawer"
                onClick={props.back}
              >
                <ArrowBackIos />
              </IconButton>
            )}
            {props.title ? (
              <Typography className={classes.title} variant="subtitle1" noWrap>
                {props.title}
              </Typography>
            ) : null}
            {props.type === "search" ? (
              <div key={"search"} className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Buscar..."
                  onChange={props.onChange}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{
                    "aria-label": "Search",
                    autoComplete: "search",
                  }}
                />
              </div>
            ) : null}
            {(props.actions || []).map((action, index) => {
              if (action.id === "imprimir")
                return (
                  <PrintComponents
                    key={index}
                    trigger={
                      <Button color="inherit" key={action.id} variant="text">
                        {action.vc_nombre}
                      </Button>
                    }
                  >
                    {action.renderPrint()}
                  </PrintComponents>
                );
              return (
                <Button color="inherit" key={index} onClick={action.action}>
                  {action.vc_nombre}
                </Button>
              );
            })}
            <div key={"grow"} className={classes.grow} />

            {props.rightComponent && props.rightComponent}

            <div key={"options"}>
              {(props.options || []).length > 0 && (
                <IconButton
                  aria-label="Show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                  className={classes.sectionMobile}
                >
                  <MoreIcon />
                </IconButton>
              )}

              {(props.options || []).length > 0 ? (
                <Breadcrumbs className={classes.sectionDesktop} separator="›">
                  {optionDisplay.map((option, index) => {
                    if (index + 1 == optionDisplay.length)
                      return (
                        <Button
                          key={index}
                          size="small"
                          color="inherit"
                          onClick={handleMobileMenuOpen}
                        >
                          {option}{" "}
                        </Button>
                      );
                    else
                      return (
                        <Typography key={index} color="inherit">
                          {option}
                        </Typography>
                      );
                  })}
                </Breadcrumbs>
              ) : null}
              {props.scanner && props.scanner}
              {props.rightIcon && !window.isMobile ? props.rightIcon : null}
              {(props.options || []).length > 0 && (
                <Menu
                  id={mobileMenuId}
                  keepMounted
                  open={isMobileMenuOpen}
                  onClose={handleMobileMenuClose}
                  anchorEl={mobileMoreAnchorEl}
                  TransitionComponent={Fade}
                >
                  {(props.options || []).map((option, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          handleMobileMenuClose();
                          option.action();
                        }}
                      >
                        <p>{option.vc_nombre}</p>
                        {/* <IconButton
                                            edge="end"
                                            aria-label="Account of current user"
                                            aria-controls={menuId}
                                            aria-haspopup="true"
                                            onClick={props.add}
                                            color="inherit"
                                        >
                                            {
                                                props.type !== 'form' ?
                                                    <PlaylistAdd />
                                                    :
                                                    <FileCopy />
                                            }
                                        </IconButton> */}
                      </MenuItem>
                    );
                  })}
                  {props.scanner && (props.options || []).length > 0
                    ? props.scanner
                    : null}
                  {props.rightIcon ? props.rightIcon : null}
                </Menu>
              )}
            </div>

            {props.children}
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  );
}

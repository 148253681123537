import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import moment from 'moment';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

BigCalendar.momentLocalizer(moment);

const { Row, Column } = Grid;

class ChangeTime extends Component {
  render() {
    const { horary, events, change } = this.props;
    const { min, max } = horary;

    return (
      <Grid>
        <Row>
          <Column>
            <h1>Selecciona otra hora</h1>
          </Column>
        </Row>
        <Row>
          <Column>
          <BigCalendar
            selectable
            style={{ height: 1000 }}
            defaultView='day'
            events={events}
            step={15}
            min={min}
            max={max}
            onSelectSlot={(slotInfo) => { change(slotInfo); }}
          />
          </Column>
        </Row>
      </Grid>
    );
  }
}

export default ChangeTime;

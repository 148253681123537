import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setHelperSidebarRender, resetHelperSidebarRender, setState } from '../../../containers/Think/actions';
import SearchBar from '../SearchBar';
import Paper from '../../../material-ui/Paper';
import { ButtonBase, Box, Button } from '@material-ui/core';
const modulo = 'backoffice';

class ListadoCatalogos extends React.Component {
  constructor() {
    super();
    this.state = {
      searchText: ''
    }
  }
  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };
  getFilterCatalogos() {
    const pattern = new RegExp(this.state.searchText, 'gi');
    return this.props.catalogos.filter(e => e.vc_name.match(pattern));
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    console.log('listado catalogos')
  }
  renderContent() {
    return this.getFilterCatalogos().map((catalogo, index) => {
      return (
        <ButtonBase
          key={index}
          onClick={() => {
            this.props.setState({ modulo, state: { catalogo, opcion: 'listadoRegistrosCatalogos' } })
          }}
          style={{ width: '100%', marginBottom: 8 }}>
          <Paper
            title={catalogo.vc_name}
            description={catalogo.vc_description}
          />
        </ButtonBase>

      )
    })
  }

  render() {
    return (
      <div style={{ height: window.innerHeight - 40}}>
        <div style={{ width: '100%', padding: 15, paddingTop: 85, height: 'calc(100% - 1px)', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', WebkitOverflowScrolling: 'touch' }}>
          {this.renderContent()}
        </div>
        <SearchBar
          title={'Catalogos'}
          type='search'
          style={{ position: 'absolute' }}
          onChange={this.handleChange}
          add={() => {
            // this.props.setState({ modulo, state: { catalogo, opcion: 'listadoRegistrosCatalogos' } })
          }}
          style={{position: 'absolute'}}
        />
      </div>
    );
  }
}

ListadoCatalogos.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  setState: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  return {
    state: state.Think[modulo] || null,
    catalogos
  };
};
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState
};
export default connect(mapStateToProps, actions)(ListadoCatalogos);

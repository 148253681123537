import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
} from "app/containers/Think/actions";

import {
  Modal,
  Paper,
  Box,
  Tabs,
  Tab,
  createMuiTheme,
  MuiThemeProvider,
  Divider,
} from "@material-ui/core";
import NotasViewer from "app/containers/Think/views/notas/notasViewer";

const themeDark = createMuiTheme({
  palette: {
    type: "dark",
  },
});

const Index = (props) => {
  const { open, paciente, onFinish, onClose } = props;

  const [tab, setTab] = React.useState(0);
  const handleTab = (e, nv) => {
    if (nv === tab) return;

    setTimeout(() => {
      setTab(nv);
    }, 200);
  };

  React.useEffect(() => {
    setTab(0);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        boxShadow: "0px 0px 0px transparent",
        backgroundColor: "transparent",
        margin: window.isMobile ? 20 : "50px 30vh 50px 30vh",
      }}
    >
      <Paper
        dark
        style={{
          width: "100%",
          borderRadius: 10,
          overflow: "hidden",
          height: "100%",
          outline: "none",
        }}
      >
        <Box>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTab}
            aria-label="disabled tabs example"
          >
            <Tab label="Notas" />
            <Tab label="Tratamientos" />
          </Tabs>
          <Divider style={{ marginTop: "-1px" }} />
        </Box>

        <Box
          height="100%"
          overflow="scroll"
          padding={4}
          bgcolor="rgb(66 66 66)"
        >
          <MuiThemeProvider theme={themeDark}>
            {/* {tab === 0 && <NotasViewer />} */}
            <NotasViewer recetaMode={tab === 1} />
          </MuiThemeProvider>
        </Box>
      </Paper>
    </Modal>
  );
};

Index.propTypes = {
  setHelperSidebarRender: PropTypes.func,
  setPacienteActual: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
  logOut: PropTypes.func,
};

const actions = {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
};

const mapStateToProps = (state) => {
  const {
    preguntas,
    medicamentosDgis,
    diagnosticos,
    pacienteActual,
  } = state.Think;

  return {
    preguntas,
    medicamentosDgis,
    diagnosticos,
    paciente: pacienteActual,
  };
};

export default connect(mapStateToProps, actions)(Index);

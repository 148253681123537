import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Divider,
  Typography,
} from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import Autocomplete from "app/sales/components/Common/Form/Autocomplete";
import InputProducto from "app/sales/components/Common/InputProducto";
import InputCliente from "app/sales/components/Common/InputCliente";
import InputCuenta from "app/sales/components/Common/InputCuenta";
// import Input from "app/components/Form/Input";
import Input from "app/sales/components/Common/Form/Input";
import Select from "app/sales/components/Common/Form/Select";
import Icon from "app/sales/components/Common/Icon";

import useApi from "app/sales/hooks/useApi";
import useSelect from "app/sales/hooks/form/useSelect";
import useCrearProducto from "app/sales/hooks/useCrearProducto";
import { useDispatch, useSelector } from "react-redux";
import { addProducto, editProducto } from "app/sales/store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiGrid-item": {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  control: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
  },
}));

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const Index = ({ children, ...props }) => {
  const { custom } = useApi("session/productos");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { productos } = useSelector((state) => state.Sales.venta);

  const [producto, setProducto] = React.useState(null);
  const [cantidad, setCantidad] = React.useState(1);

  const { options, value, setData, setValue } = useSelect({ keyvalue: "uuid" });

  const { ModalForm, onOpen } = useCrearProducto({ onCreate() {} });

  React.useEffect(() => {
    if (producto) {
      custom(`${producto.producto.uuid}/proveedores`)
        .then((res) => setData(res.proveedores))
        .catch(() => setData([]));
    } else setData([]);
  }, [producto]);

  const handlerAgregar = () => {
    const formCantidad = cantidad > 0 ? cantidad : 1;
    if (producto) {
      const existe = productos.find((value) => value.uuid === producto.uuid);
      if (existe) {
        const nu_cantidad = existe.nu_cantidad + formCantidad;
        const nu_precioImporte = existe.nu_precio * nu_cantidad;

        dispatch(editProducto({ ...existe, nu_cantidad, nu_precioImporte }));
      } else {
        const nu_cantidad = formCantidad;
        const impuesto = producto.impuesto.nu_factor * producto.nu_precioBase;
        const nu_precio = producto.nu_precioBase + impuesto;
        const nu_precioImporte = nu_precio * nu_cantidad;

        dispatch(
          addProducto({
            ...producto,
            uuid_proveedor: value,
            nu_cantidad,
            nu_precio,
            nu_precioImporte,
            vc_precio: formatter.format(v.nu_precio),
            vc_precioImporte: formatter.format(v.nu_precioImporte),
          })
        );
      }

      setProducto(null);
      setCantidad(1);
    }
  };

  return [
    <Card>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Seleccionar producto
        </Typography>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={6}>
            <InputProducto value={producto} onChange={setProducto} />
          </Grid>
          {/* <Grid item xs={4}>
            <Select
              label="Marca"
              variant="outlined"
              fullWidth
              data={marcas}
              value={marca}
              onChange={(option) => setMarca(option.value)}
              selectOnOne
            />
          </Grid> */}
          <Grid item xs={4}>
            <Select
              label="Proveedor"
              variant="outlined"
              fullWidth
              selectOnOne
              disabled={!options.length}
              hideSearch
              value={value}
              onChange={(option) => setValue(option.value)}
              data={options}
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              number
              inputProps={{
                decimalScale: 0,
              }}
              variant="outlined"
              fullWidth
              label="cantidad"
              value={cantidad}
              onChange={(e) => setCantidad(Number(e.target.value || 1))}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box flex="1" />
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handlerAgregar}
        >
          Agregar
        </Button>
        <Button size="small" color="primary" onClick={() => onOpen()}>
          Crear producto
        </Button>
      </CardActions>
    </Card>,
    <ModalForm />,
  ];
};

export default Index;

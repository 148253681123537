import React from "react";
import { connect } from 'react-redux';
import { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar, setPacienteActual } from '../../../actions';
import { Tooltip, Typography, IconButton } from "@material-ui/core";
import Delete from '@material-ui/icons/Delete'
import { position } from 'caret-pos';
const InputResponse = (props) => {
    const { index, input, showCode, id_preguntaTexto } = props;
    const [over, setOver] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const setPregunta = () => {
        const preguntasTexto = (props.pacienteActual.preguntasTexto || []).map((pregunta, index) => {
            if (id_preguntaTexto === pregunta.id) {
                const respuestasInternas = [].concat(pregunta.respuestasInternas);
                if (index > -1) {
                    respuestasInternas.splice(index, 1);
                }
                return {
                    ...pregunta,
                    respuestasInternas
                };
            } else {
                return pregunta;
            }
        });
        const newPacienteActual = { ...props.pacienteActual, preguntasTexto };
        props.setPacienteActual(newPacienteActual);
    }

    return (
        <Tooltip
            onClose={() => {
                setOpen(false)
            }}
            interactive
            open={open}
            disableFocusListener title={
                <Typography style={{ fontSize: 18, padding: 8 }}>
                    <IconButton size='small' style={{ marginLeft: -30, backgroundColor: 'rgba(0,0,0,0.1)', float: 'left' }} onClick={setPregunta}>
                        <Delete color='error' />
                    </IconButton>
                    {
                        showCode ?
                            `"${input.vc_sentencia || 'No aplica'}"`
                            :
                            `Code Thin-k: [${input.cve_respuesta || input.id_pregunta}]`
                    }
                </Typography>
            } placement="top-start"
        >
            <i>
                <i
                    id={`respuestaId=${id_preguntaTexto}:${index}:${'prev'}`}
                    contentEditable onKeyDown={(e) => {
                        const pos = position(e.target);
                        switch (true) {
                            case (e.key === 'ArrowRight' && pos.pos > -1 && pos.pos < e.currentTarget.innerText.length - 1): {
                                console.log(e.key, 'right')
                                break;
                            }
                            case (e.key === 'ArrowLeft' && pos.pos > 0): {
                                break;
                            }
                            case (e.key === 'ArrowRight'): {
                                console.log(e.key, 'right')
                                let input = document.getElementById(`respuestaId=${id_preguntaTexto}:${index + 1}:${'prev'}`)
                                if (input) {
                                    console.log(e.key, 'right')
                                    input.focus()
                                } else if (!input) {
                                    input = document.getElementById(`respuestaId=${id_preguntaTexto}:last`)
                                    if (input) {
                                        input.focus()
                                    }
                                }
                                break;
                            }
                            case (e.key === 'ArrowLeft'): {
                                let input = document.getElementById(`respuestaId=${id_preguntaTexto}:${index - 1}:${'prev'}`)
                                if (input) {
                                    input.focus()
                                }
                                break;
                            }
                            default: break;
                        }
                    }}
                    style={{
                        outline: "none",
                        fontStyle: 'normal', textAlign: 'justify', fontFamily: "Helvetica Neue", fontSize: 14, padding: 0, margin: 0, borderRadius: 5, overflow: 'hiden',
                        position: 'relative',
                    }}
                />
                <i
                    id={`respuestaId=${id_preguntaTexto}:${index}`}
                    onMouseOver={() => {
                        setOver(index);
                    }}
                    onMouseLeave={() => {
                        setOver(null);
                    }}
                    onClick={() => {
                        setOpen(true)
                    }}
                    style={{
                        outline: "none",
                        backgroundColor:
                            over === index ? 'rgba(0,0,0,0.01)' : null,
                        opacity:
                            (over === index && input.id_pregunta) ? 0.7 : 1,
                        textShadow: over === index && input.id_pregunta ? '0px 0px 5px rgba(50,50,50,0.5)' : null,
                        cursor: '-webkit-grab',
                        fontStyle: 'normal', textAlign: 'justify', fontFamily: "Helvetica Neue", fontSize: 14, lineHeight: 0, padding: 0, margin: 0, borderRadius: 5, overflow: 'hiden',
                        position: 'relative',
                        zIndex: 10000

                    }}
                >
                    {showCode ? `[${input.cve_respuesta || input.id_pregunta}] ` : input.vc_sentencia}
                </i>
            </i>
        </Tooltip>
    );
};
const actions = { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar, setPacienteActual };
const mapStateToProps = (state) => {
    const { preguntas, pacienteActual } = state.Think;
    return { preguntas, pacienteActual };
};

export default connect(mapStateToProps, actions)(InputResponse);
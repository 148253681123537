import React from 'react';
import { Paper, Box, Card, CardContent, Typography, List, ListItem, ListItemText, Divider, IconButton, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar } from '../../../containers/Think/actions';
import HelpBarThink from '../../../material-ui/HelpBarThink';
import { menuAcademy } from '../../../containers/Think/listados';
import SubMenuModulos from '../../../material-ui/SubMenuModulos';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import * as firebase from 'firebase/app';
import { Item, Modal } from 'semantic-ui-react';
import ConfirmAlert from '../../../material-ui/ConfirmAlert';
import { Button } from '@material-ui/core';

class Index extends React.Component {
    constructor() {
        super();
        this.app = firebase;
        this.db = this.app.database().ref();
        this.state = {
            date: new Date("Feb 22, 2020 14:00:00").getTime(),
            casoClinicoActual: null,
            preguntaActual: null,
            moduloActual: 'simulador',
            opcion: 'enarm',
            respuestas: [],
            preguntas: [],
            evaluaciones: [
                {
                    id: 1,
                    vc_nombre: 'Primera Evaluación Curso ENARM 2019-2020',
                    vc_descripcion: '(Medicina interna, Pediatria, Ginecologia)',
                    preguntaInicio: 1,
                    preguntaFin: 119,
                    nu_preguntas: 119
                },
                {
                    id: 2,
                    vc_nombre: 'Segunda Evaluación Curso ENARM 2019-2020',
                    vc_descripcion: '(Cirugia)',
                    preguntaInicio: 138,
                    preguntaFin: 232,
                    nu_preguntas: 184
                }
            ]
        };
    }

    componentDidMount() {
        // this.setMenuOptions();
        // window.addEventListener('resize', this.updateDimensions.bind(this));
        this.updateHelperSidebar();
        const usuario = window.amplify.store('user') && window.amplify.store('user').usuario || {}
        if (usuario.vc_email) {
            const vc_curp = usuario.detalle && usuario.detalle.vc_curp || null;
            console.log('usuario', usuario)
            this.setState({ vc_email: window.amplify.store('user').usuario.vc_email.replace('.', ''), vc_curp })
        }
        if (this.props.setDisplaySidebar)
            this.props.setDisplaySidebar(false);

    }
    updateHelperSidebar() {
        this.props.setHelperSidebarRender(this.renderHelperSidebar.bind(this));
    }
    onChangeValue(e) {
        this.setState({ searchText: e.target.value })
    }
    startFirebase = () => {
        this.db.child('academy').child('courses').child('enarm').child('tests').child(`${this.state.vc_curp || this.state.vc_email}`).child(`${this.state.testActual.id}`).child(moment().format('YYYY-MM-DD')).child('dt_start').set(moment().format('DD-MM-YYY HH:mm:ss'));
    }
    endFirebase = () => {
        this.db.child('academy').child('courses').child('enarm').child('tests').child(`${this.state.vc_curp || this.state.vc_email}`).child(`${this.state.testActual.id}`).child(moment().format('YYYY-MM-DD')).child('dt_end').set(moment().format('DD-MM-YYY HH:mm:ss'));
        this.db.child('academy').child('courses').child('enarm').child('tests').child(`${this.state.vc_curp || this.state.vc_email}`).child(`${this.state.testActual.id}`).child(moment().format('YYYY-MM-DD')).child('respuestas').set(this.state.respuestas);
        this.setState({ respuestas: [], testActual: null, preguntas: [], preguntaActual: null })
    }
    pauseFirebase = () => {
        this.db.child('academy').child('courses').child('enarm').child('tests').child(`${this.state.vc_curp || this.state.vc_email}`).child(moment().format('YYYY-MM-DD')).child('dt_start').set(moment().format('DD-MM-YYY HH:mm:ss'));
    }
    startTest(test) {
        window.alert('hola')
        const message = {
            title: test.vc_nombre,
            message: '¿Deseas iniciar tu simulación?',
            onAccept: (() => {
                const preguntas = this.props.preguntasEnarm.filter(e => e.id >= test.preguntaInicio && e.id <= test.preguntaFin);
                this.setState({ testActual: test, preguntas, preguntaActual: preguntas.find(e => e.id === test.preguntaInicio) || null, register: true }, () => {
                    this.startFirebase()
                })
            })
        }
        this.setState({ message })
    }
    renderHeaderSubMenu() {
        console.log(menuAcademy);
        const moduloActual = menuAcademy.find((e) => e.id == this.state.moduloActual);
        const opcionActual = moduloActual.opciones.find((e) => e.id == this.state.opcion);
        const acciones = this.state.preguntaActual ? opcionActual.acciones.map((accion, index) => {
            switch (accion.id) {
                case 'iniciar': {
                    return {
                        ...accion, action: () => {
                            // if(new Date().getTime() < this.state.date){
                            if (this.state.preguntaActual) {
                                const message = {
                                    title: this.state.testActual.vc_nombre,
                                    message: `¿Finalizar el examen faltan ${this.state.testActual.nu_preguntas - this.state.respuestas.length} preguntas?`,
                                    onAccept: (() => {
                                        this.setState({ preguntaActual: null }, () => {
                                            this.endFirebase()
                                            this.setState({ testActual: null })
                                        })
                                    })
                                }
                                this.setState({ message })
                            }
                        },
                        ...{ vc_nombre: this.state.respuestas.length > 0 ? 'Finalizar' : 'Iniciar' }
                    }
                }
                default: {
                    return accion;
                }
            }
        }) :
            []
            ;

        const opciones = moduloActual.opciones.map((opcion) => {
            return {
                ...opcion,
                action: () => {
                    this.onChangeModule(moduloActual.id, opcion.id, 'agregar')
                }
            }
        });

        return (
            <SubMenuModulos
                title={this.state.testActual ? `${this.state.testActual.vc_nombre} (${((this.state.respuestas.length / this.state.testActual.nu_preguntas) * 100).toFixed(0)}%)` : 'Evaluaciones'}
                actions={acciones}
                options={opciones}
                optionDisplay={[
                    moduloActual.vc_nombre,
                    opcionActual.vc_nombre
                ]}
            />
        )
    }

    renderHelperSidebar() {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <HelpBarThink
                    header={'Selecciona un caso clínico:'}
                    searchBar={true}
                    options={this.props.casosClinicosEnarm.filter(e => e.id > 64) || []}
                    indexSeleccionado={this.state.opcionPreSelected}
                    onChangeValue={this.onChangeValue.bind(this)}
                    searchText={this.state.searchText}
                />
            </div>
        )
    }
    clickRespuesta = (respuesta) => {
        console.log('respuesta', respuesta)
        const { sn_multiple, id: id_preguntaActual } = this.state.preguntaActual;
        let { respuestas } = this.state
        const respuestaPreguntaIndex = respuestas.findIndex(e => e.id_pregunta);

        const respuestaPregunta = respuestas.find(e => e.id_pregunta) || { id_pregunta: id_preguntaActual, seleccionadas: [] };
        if (sn_multiple) {
            console.log('seleccion multiple')
            const seleccionadas = respuestaPregunta.seleccionadas
            const indexSelected = seleccionadas.findIndex(e => e.id === respuesta.id);
            if (indexSelected > 0) {
                seleccionadas.splice(indexSelected, 1);
            } else {
                seleccionadas.push(respuesta);
            }
            const respuestasRequires = this.props.respuestasEnarm.filter(e => e.id_pregunta === id_preguntaActual && e.sn_correcta).length;
            const respuestasCorrectas = seleccionadas.filter(e => e.sn_correcta).length;
            respuestaPregunta.sn_correcta = respuestasCorrectas === respuestasRequires;
            if (respuestaPreguntaIndex) {
                respuestas[respuestaPregunta] = respuestaPregunta
            } else {
                respuestas.push(respuestaPregunta);
            }
            this.setState({ respuestas })
        } else {
            console.log('respuesta', respuesta)
            const respuestaPregunta = {};
            respuestaPregunta.id_pregunta = respuesta.id_pregunta;
            respuestaPregunta.seleccionadas = [respuesta];
            respuestaPregunta.sn_correcta = respuesta.sn_correcta;
            respuestas.push(respuestaPregunta);
            const sigPregunta = this.state.preguntas.find(e => e.id === id_preguntaActual + 1) || null;
            if (sigPregunta) {
                this.setState({ respuestas, preguntaActual: sigPregunta })
            } else {
                const message = {
                    title: this.state.testActual.vc_nombre,
                    message: 'Finalizar el examen',
                    onAccept: (() => {
                        this.setState({ preguntaActual: null }, () => {
                            this.endFirebase()
                            this.setState({ testActual: null })
                        })
                    })
                }
                this.setState({ message })
            }
        }

    }
    renderContent() {
        const arrayLetters = [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I'
        ]
        switch (true) {
            // case (moment() < moment('2020-02-22 14:00:00')): {
            case (false): {
                return (
                    <Paper style={{ marginTop: 8 }}>
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                ¡Bienvenido!
                            </Typography>
                            <Typography color="textSecondary">
                                El Examen iniciara a las 14:00 hrs.
                            </Typography>
                        </CardContent>
                    </Paper>
                )
            }
            case (!this.state.preguntaActual): {
                return this.state.evaluaciones.map((evaluacion, index) => {
                    return (
                        <Paper key={index} onClick={() => this.startTest(evaluacion)} style={{ marginTop: 8 }}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    {evaluacion.vc_nombre}
                                </Typography>
                                <Typography color="textSecondary">
                                    {evaluacion.vc_descripcion || ''}
                                </Typography>
                                <Typography color="textSecondary">
                                    {`${evaluacion.nu_preguntas} preguntas`}
                                </Typography>
                            </CardContent>
                        </Paper>
                    )
                })
            }
            case (this.state.preguntaActual != null): {
                const casoClinicoActual = this.props.casosClinicosEnarm.find(e => e.id === this.state.preguntaActual.id_casoClinico) || { vc_descripcion: '' };
                const respuestasEnarm = this.props.respuestasEnarm.filter(e => e.id_pregunta === this.state.preguntaActual.id) || [];
                return (
                    <Paper style={{ marginTop: 8 }}>
                        <CardContent key={'casoClinico'} style={{ padding: 10 }}>
                            <Typography variant="h5" component="h2" style={{ padding: 10 }}>
                                Caso Clínico:
                            </Typography>
                            <Divider />
                            <Typography color="textSecondary" style={{ padding: 10 }}>
                                {casoClinicoActual.vc_descripcion || ''}
                            </Typography>
                        </CardContent>
                        <CardContent key={'pregunta'} style={{ padding: 10 }}>
                            <Typography variant="h5" component="h2" style={{ padding: 10 }}>
                                Pregunta:
                            </Typography>
                            <Divider />
                            <Typography color="textSecondary" style={{ padding: 10 }}>
                                {this.state.preguntaActual.vc_descripcion || ''}
                            </Typography>
                        </CardContent>
                        <CardContent key={'respuestas'} style={{ padding: 10 }}>
                            <List>
                                {
                                    respuestasEnarm.map((respuesta, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                id={respuesta.id}
                                                onClick={() => {
                                                    this.clickRespuesta(respuesta)
                                                }}
                                                button
                                                key={index}>
                                                <ListItemText
                                                    primary={`${arrayLetters[index]}.- ${respuesta.vc_descripcion || ''}`} />
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </CardContent>
                    </Paper>
                )
            }
        }
    }
    render() {
        const avance = ((this.state.respuestas.length / this.state.preguntas.length) * 100).toFixed(0)
        console.log('avance', avance)
        return (
            <Box style={{ height: '100%', width: '100%' }}>
                {this.renderHeaderSubMenu()}
                <div style={{ width: '100%', padding: 15, height: 'calc(100% - 56px)', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>
                    {this.renderContent()}
                </div>
                <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 1000 }}>
                    <CircularProgress title={`${avance || 0}%`} variant="static" value={avance * 1 || 0} />
                </div>
                {
                    this.state.message &&
                    <ConfirmAlert
                        title={this.state.message.title || ''}
                        message={this.state.message.message || ''}
                        open={!!this.state.message}
                        onAccept={() => {
                            this.setState({ message: null }, this.state.message.onAccept || null)
                        }}
                        onCancel={() => {
                            this.setState({ message: null }, this.state.message.onCancel || null)
                        }}
                    />
                }
            </Box>
        );
    }
}

const actions = {
    setHelperSidebarRender,
    resetHelperSidebarRender,
    setDisplaySidebar
}
const mapStateToProps = (state) => {
    const { preguntasEnarm, respuestasEnarm, casosClinicosEnarm } = state.Think;
    return { preguntasEnarm, respuestasEnarm, casosClinicosEnarm };
};
export default connect(mapStateToProps, actions)(Index);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState,
  setDisplaySidebar,
} from "../../../containers/Think/actions";
import {
  Paper,
  Typography,
  Box,
  Button,
  MenuItem,
  Divider,
  Grid,
} from "@material-ui/core";

import internalAPI from "app/utils/internalAPI";
import SearchSelect from "app/material-ui/ThinkForm/SearchSelect";
import SubMenuModulos from "app/material-ui/SubMenuModulos";
import ConfirmAlert from "app/material-ui/ConfirmAlert";
import notifications from "app/utils/notifications";

// Components
import Dia from "./components/Dia";
import Horario from "./components/Horario";
import Schedule from "./components/Schedule";

import TextField from "app/material-ui/forms/TextField";
import Select from "app/material-ui/forms/Select";

const styles = (theme) => ({
  boxFieldset: {
    display: "flex",
    flexWrap: "wrap",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    flexGrow: 1,
  },
});

const useForm = (initialState) => {
  const [fields, setValues] = React.useState(initialState);

  return [
    fields,
    (key, value) => {
      let nv = Array.isArray(key)
        ? key.reduce((pv, cv) => ({ ...pv, [cv.key]: cv.value }), {})
        : { [key]: value };

      setValues({ ...fields, ...nv });
    },
  ];
};

const selectConfig = (params) => {
  return {
    relation: {
      get: {
        method: "get",
        backEndURL: window.backEndURL,
        vc_url: "/app/api/institucion/localizacion/buscar",
        searchKey: "text",
        parameters: [
          {
            type: "fixed",
            key: "id_institucion",
            value: (
              window.amplify.store("institution") || { id_institucion: 0 }
            ).id_institucion,
          },
          ...params.map(({ key, value }) => ({ type: "fixed", key, value })),
        ],
      },
      keyTable: "id",
      fieldsDisplay: ["vc_nombre"],
      fieldsDisplaySeparators: [],
    },
  };
};

const Modal = ({ open, errors, accept }) => {
  return (
    open && (
      <ConfirmAlert
        title="¡Revisa los siguientes campos!"
        message={
          <Box style={{ maxHeight: "90vh" }}>
            <Divider />
            <MenuItem> No deben estar vacios: </MenuItem>
            <Divider />
            {errors.map((v, i) => (
              <MenuItem key={i}>{v}</MenuItem>
            ))}
          </Box>
        }
        onAccept={accept}
      />
    )
  );
};

const onChange = (field, callback) => {
  let $input = window.$(`#${field}`);

  if (field.includes("vc_")) waitWrite(field, callback);
  else $input.on("change", (e) => callback(e.target.value));
};
const waitWrite = (field, callback) => {
  let timeOut,
    time = 1500,
    $input = window.$(`#${field}`);

  $input.on("keyup", (e) => {
    clearTimeout(timeOut);
    timeOut = setTimeout(() => callback(e.target.value), time);
  });

  $input.on("keydown", () => {
    clearTimeout(timeOut);
  });
};

const fetchLocalizacion = (body) => {
  return new Promise((resolve, reject) => {
    internalAPI.getLocalizacion(
      {
        id_institucion: (
          window.amplify.store("institution") || { id_institucion: 0 }
        ).id_institucion,
        ...body,
      },
      (err, res) => {
        if (err) reject(err);
        else resolve(res);
      }
    );
  });
};
const toCapitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const Formulario = (props) => {
  const [horarios, setHorarios] = React.useState([]);
  const [data, setData] = useForm(!!props.data ? props.data : {});
  const [dependencias, setDependencias] = React.useState([]);
  const [areas, setAreas] = React.useState([]);
  const [modulos, setModulos] = React.useState([]);
  const especialidades = props.especialidades.map((v) => ({
    value: v.id,
    text: toCapitalize(v.vc_nombre.toLowerCase()),
  }));

  const duraciones = [
    { text: "15 min.", value: 15 },
    { text: "20 min.", value: 20 },
    { text: "25 min.", value: 25 },
    { text: "30 min.", value: 30 },
    { text: "45 min.", value: 45 },
    { text: "60 min.", value: 60 },
    { text: "90 min.", value: 90 },
    { text: "2 hrs.", value: 120 },
    { text: "2 hrs. y media", value: 150 },
    { text: "3 hrs.", value: 180 },
    { text: "3 hrs. y media", value: 210 },
    { text: "4 hrs", value: 240 },
  ];

  React.useEffect(() => {
    (async () => {
      const { agendasEndPoints } = internalAPI;

      const de = await fetchLocalizacion({ tipo: "dependencia" });
      setDependencias(de.map((v) => ({ value: v.id, text: v.vc_nombre })));

      if (data.id) {
        const res = await agendasEndPoints.edit({ id: data.id });

        setData([
          { key: "id_dependencia", value: res.id_dependencia },
          { key: "id_area", value: res.id_area },
          { key: "id_modulo", value: res.id_modulo },
          { key: "vc_nombre", value: res.vc_nombre },
        ]);
        console.log(res);
        setHorarios(res.horarios);
      } else {
        setHorarios([
          {
            detalle: [1, 2, 3, 4, 5, 6, 7].map((nu_dia) => ({
              nu_dia,
              tm_inicio: "08:00:00",
              tm_fin: "20:00:00",
            })),
          },
        ]);
      }
    })();

    return () => {
      props.setHelperSidebarRender(() => null);
      props.setDisplaySidebar(false);
    };
  }, []);

  React.useEffect(() => {
    (async () => {
      let auxAreas = [];
      let auxModulos = [];

      if (data.id_dependencia) {
        auxAreas = await fetchLocalizacion({
          id_dependencia: data.id_dependencia,
          tipo: "area",
        });
      }

      if (data.id_area) {
        auxModulos = await fetchLocalizacion({
          id_dependencia: data.id_dependencia,
          id_area: data.id_area,
          tipo: "modulo",
        });
      }

      setAreas(auxAreas.map((v) => ({ value: v.id, text: v.vc_nombre })));
      setModulos(auxModulos.map((v) => ({ value: v.id, text: v.vc_nombre })));
    })();
  }, [data]);

  const inputProps = (field) => ({
    key: field,
    fieldid: field,
    name: field,
    required: true,
    fullWidth: true,
  });

  const [modal, setModal] = React.useState({
    open: false,
    errors: [],
  });

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    const errors = [];
    const fields = [
      { key: "vc_nombre", field: "Nombre" },
      { key: "id_dependencia", field: "Dependencia" },
      // { key: 'id_area', field: 'Area' },
      // { key: 'id_modulo', field: 'Modulo' },
      { key: "id_especialidad", field: "Especialidad" },
      { key: "nu_duracion", field: "Duracion" },
    ];

    fields.forEach((v) => {
      !data[v.key] && errors.push([v.field]);
    });

    setModal((ps) => ({ ...ps, errors }));
  }, [data]);

  const onSubmit = () => {
    const { agendasEndPoints } = internalAPI;

    if (modal.errors.length) return setModal((ps) => ({ ...ps, open: true }));

    const {
      id = null,
      id_institucion = null,
      id_dependencia = null,
      id_area = null,
      id_modulo = null,
      nu_latitud = 0,
      nu_longitud = 0,
      id_municipio = 1,
      id_especialidad,
      vc_nombre,
      nu_duracion,
    } = data;

    const params = {
      id,
      id_dependencia,
      id_area,
      id_modulo,
      id_municipio,
      id_especialidad,
      vc_nombre,
      nu_latitud,
      nu_longitud,
      nu_duracion,
      horarios,
      id_institucion: id_institucion
        ? id_institucion
        : window.amplify.store("institution").id_institucion,
    };

    const editando = Boolean(id);
    const user = window.amplify.store("user");
    const isAdmin = user.roles.some((r) => r.id_rol === 2);

    agendasEndPoints
      .store(params)
      .then((res) => {
        const institucion = window.amplify.store("institution");

        const oneDay = 1000 * 60 * 60 * 24;

        if (editando) {
          const agendaIndex = institucion.agendas.findIndex(
            (v) => v.id == params.id
          );

          institucion.agendas[agendaIndex] = res[1];
        } else if (isAdmin) {
          institucion.agendas.push(res[1]);
        }

        window.amplify.store("institution", institucion, { expires: oneDay });

        props.changeState({ opcion: "listado" });
        notifications.success("AGENDA CREADA CORRECTAMENTE");
      })
      .catch((res) => {});
  };

  console.log(horarios);
  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" flexGrow={1}>
      <SubMenuModulos
        back={() => props.changeState({ opcion: "listado" })}
        actions={[{ vc_nombre: "Guardar", action: onSubmit }]}
      />
      <Box
        display="flex"
        flexGrow={1}
        flexShrink={1}
        flexBasis="auto"
        height={"0px"}
        overflow="scroll"
        flexDirection="column"
        p={2}
      >
        <Modal
          open={modal.open}
          errors={modal.errors}
          accept={() => setModal({ ...modal, open: false })}
        />
        <div
          style={{
            width: "100%",
            paddingLeft: 20,
            minHeight: 45,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography> General </Typography>
        </div>

        <Paper style={{ padding: 10, width: "100%", position: "relative" }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Select
                label={"Dependencia"}
                fullWidth
                required
                showAllRows
                value={data.id_dependencia}
                data={dependencias}
                onChange={(option) =>
                  setData([
                    { key: "id_dependencia", value: option.value },
                    { key: "id_area", value: null },
                    { key: "id_modulo", value: null },
                  ])
                }
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                label={"Area"}
                fullWidth
                value={data.id_area}
                data={areas}
                showAllRows
                onChange={(option) =>
                  setData([
                    { key: "id_area", value: option.value },
                    { key: "id_modulo", value: null },
                  ])
                }
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                label={"Modulo"}
                fullWidth
                showAllRows
                value={data.id_modulo}
                data={modulos}
                onChange={(option) => setData("id_modulo", option.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={data.vc_nombre}
                fullWidth
                onChange={(e) => setData("vc_nombre", e.target.value)}
                required
                label={"Nombre"}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                label={"Especialidad"}
                fullWidth
                value={data.id_especialidad}
                data={especialidades}
                required
                showAllRows
                onChange={(o) => setData("id_especialidad", o.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                label={"Duracion"}
                fullWidth
                value={data.nu_duracion}
                data={duraciones}
                hideSearch
                showAllRows
                onChange={(o) => setData("nu_duracion", o.value)}
              />
            </Grid>
          </Grid>
        </Paper>

        {horarios.map((h, i) => (
          <Horario
            key={i}
            data={h}
            onChange={(d) => {
              const h = [...horarios];
              console.log(d);
              h[i].detalle = d;
              setHorarios(h);
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

Formulario.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  setState: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    catalogos,
    formacionAcademica,
    especialidades,
    municipios,
    roles,
    dias,
  } = state.Think;

  return {
    state: state.Think["settings"] || null,
    catalogos,
    formacionAcademica,
    especialidades,
    municipios,
    rolesConf: roles,
    dias,
  };
};
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState,
  setDisplaySidebar,
};

export default connect(
  mapStateToProps,
  actions
)(withStyles(styles)(Formulario));

import http from "../http";
import httpAdmin from "../httpAdmin";

export default {
  getInstitution(institution, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.get(`/app/api/instituciones/${institution}`, headers, callback);
  },
  getDependencias(callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.get(
      "/app/api/dependencias",
      headers,
      (dependenciasError, dependenciasResponse) => {
        if (dependenciasError) {
          callback(dependenciasError);
        } else {
          let count = 0;
          const dependencias = [];

          if (!dependenciasResponse.length) {
            callback(null, dependencias);
          }

          dependenciasResponse.forEach((dependencia) => {
            http.get(
              `/app/api/dependencias/${dependencia.id}/cargarAreas`,
              headers,
              (innerError, innerResponse) => {
                count += 1;

                if (!innerError && innerResponse && innerResponse.length) {
                  dependencias.push({ ...dependencia, areas: innerResponse });
                }

                if (count === dependenciasResponse.length) {
                  callback(null, dependencias);
                }
              }
            );
          });
        }
      }
    );
  },
  registrarInstitucion(body, callback) {
    const headers = {
      Authorization: window.amplify.store("token"),
    };

    let method = "post";
    let urlCreate = "/app/api/institucion/crear";
    let urlAdminCreate = "/api/v1/instituciones";
    body.id_conekta = 1;
    if (body.id) {
      urlCreate = `/app/api/institucion/editar`;
      urlAdminCreate = `/api/v1/instituciones`;
    }
    http.post(urlCreate, headers, callback);
  },
  guardarInstitucion(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };

    let method = "post";
    let urlCreate = "/app/api/institucion/crear";
    let urlAdminCreate = "/api/v1/instituciones";

    const rootBody = {
      id: body.id,
      id_tipoInstitucion: body.tipoInstitucionId,
      vc_nombre: body.nombre,
      vc_abreviatura: body.abreviatura,
      vc_direccion: body.direccion,
      id_pais: body.paisId,
      id_estado: body.estadoId,
      nu_codigoPostal: body.codigoPostal,
      sn_activo: 1, // parece ser que no funciona
      vc_email: body.correo,
      vc_razonSocial: body.razonSocial,
      vc_rfc: body.rfc,
      vc_telefono: body.telefono,
      dependencias: [],
    };

    if (body.id) {
      urlCreate = `/app/api/institucion/editar`;
      urlAdminCreate = `/api/v1/clientes/startup`;
      // por alguna razón es necesario eliminarlo
      delete rootBody.dependencias;
    }

    http.post(urlCreate, headers, rootBody, (err, response) => {
      const { institucion } = response;

      const childBody = Object.assign({}, body, {
        id: institucion.id,
        usuarioId: window.amplify.store("user_id"),
      });

      httpAdmin[method](urlAdminCreate, headers, childBody, (err, response) => {
        callback(err, response);
      });
    });
  },
  getInstitucionIfExists(callback) {
    const headers = { Authorization: window.amplify.store("token") };
    const userId = window.amplify.store("user_id");
    httpAdmin.get(
      `/api/v1/instituciones?usuarioId=${userId}&estatus=0`,
      headers,
      callback
    );
  },
  getProductsInstitution(searchText, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    httpAdmin.get(
      `/api/v1/instituciones/productos?text=${searchText}`,
      headers,
      callback
    );
  },
  setProductInstitution(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    let method = "post";
    let url = `/app/api/institucion/productos`;
    const rootBody = {
      id: body.id,
      id_tipoInstitucion: body.tipoInstitucionId,
      vc_nombre: body.nombre,
      vc_abreviatura: body.abreviatura,
      vc_direccion: body.direccion,
      id_pais: body.paisId,
      id_estado: body.estadoId,
      nu_codigoPostal: body.codigoPostal,
      sn_activo: 1, // parece ser que no funciona
      vc_email: body.correo,
      vc_razonSocial: body.razonSocial,
      vc_rfc: body.rfc,
      vc_telefono: body.telefono,
      dependencias: [],
    };

    if (body.id) {
      url = `/app/api/institucion/productos/editar`;
    }
    httpAdmin.get(url, headers, rootBody, callback);
  },
  apiInstitucionExist(vc_rfc, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.get(`/app/api/institucion/exist?vc_rfc=${vc_rfc}`, headers, callback);
  },
  searchInstitucion(texto, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.get(`/app/api/institucion/buscar?texto=${texto}`, headers, callback);
  },
  searchUsers(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.post("/app/api/institucion/usuarios/buscar", headers, body, callback);
  },
  getLocalizacion(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    const qs = '?' + Object.keys(body).reduce((a, k) => [...a, `${k}=${encodeURIComponent(body[k])}`], []).join('&')
    http.get(`/app/api/institucion/localizacion/buscar${qs}`, headers, callback);
  },
};

import * as React from "react";
import TextField from "@material-ui/core/TextField";
import {
  StaticDateRangePicker,
  DateRangeDelimiter,
  DateRange,
  LocalizationProvider,
} from "@material-ui/pickers";

import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";

export default (props) => {
  const [value, setValue] = React.useState(props.value || [null, null]);

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      <StaticDateRangePicker
        displayStaticWrapperAs="desktop"
        value={value}
        onChange={(nvalue) => {
          setValue(nvalue);
          props.onChange(nvalue);
          // if (!nvalue.includes(null)) {
          // }
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <DateRangeDelimiter> to </DateRangeDelimiter>
            <TextField {...endProps} />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

import React from "react";
import PropTypes from "prop-types";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  DialogContentText,
} from "@material-ui/core";

// import ConfirmAlert from "app/material-ui/ConfirmAlert";
import ConfirmAlert from "app/agenda/components/ConfirmDialog";
import Paciente from "app/components/Paciente/PacienteSidebar";
import api from "app/agenda/api";

import Input from "app/components/Form/Input";

const Index = (props) => {
  const { selected, onActionCallback } = props;
  const [confirmAlert, setConfirmAlert] = React.useState({
    title: "",
    open: false,
    callback: () => {},
  });

  const [motivo, setMotivo] = React.useState("");

  const cambiarEstatus = (accion, id_estatusCita) => {
    const nombrePaciente = `${selected.vc_nombre} ${selected.vc_apellidoPaterno} ${selected.vc_apellidoMaterno}`;
    setMotivo("");
    setConfirmAlert({
      open: true,
      title: `¿Desea ${accion} la cita del paciente: ${nombrePaciente}?`,
      callback: async (vc_observaciones) => {
        await api.changeStatus({
          ...selected,
          id_estatusCita,
          vc_observaciones,
        });
        setConfirmAlert((prev) => ({ ...prev, open: false }));
        onActionCallback();
      },
    });
  };

  const botones = [
    {
      text: "Confirmar",
      onClick: () => cambiarEstatus("confirmar", 2),
      disabled: false,
      validStatus: [1, 8],
    },
    {
      text: "Registrar llegada",
      onClick: () => cambiarEstatus("registrar llegada", 3),
      disabled: false,
      validStatus: [1, 2],
    },
    {
      text: "Pasar a consulta",
      onClick: () => cambiarEstatus("pasar a consulta", 4),
      disabled: false,
      validStatus: [1, 2, 3],
    },
    {
      text: "Finalizar",
      onClick: () => cambiarEstatus("finalizar", 5),
      disabled: false,
      validStatus: [1, 2, 3, 4],
    },
    {
      text: "Cancelar",
      onClick: () => cambiarEstatus("cancelar", 7),
      disabled: false,
      validStatus: [1, 2, 3, 8],
    },
  ].filter((v) => v.validStatus.includes(selected.id_estatusCita));

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <AppBar
        position="relative"
        style={{
          height: 95,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Typography style={{ textAlign: "center", width: "100%" }}>
            Paciente
          </Typography>
        </Toolbar>
      </AppBar>
      <Box flex={1} display="flex" flexDirection="column">
        <Paciente paciente={selected} />
        <Box paddingX={1} height="0px" overflow="scroll" flex="1 1 auto">
          {botones.map((a, i) => (
            <Box mb={1} key={i}>
              <Button
                key={i}
                variant="outlined"
                color="primary"
                fullWidth
                disabled={a.disabled}
                onClick={a.onClick}
              >
                {a.text}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>

      <ConfirmAlert
        open={confirmAlert.open}
        title={confirmAlert.title}
        accept={() => confirmAlert.callback(motivo)}
        close={() => setConfirmAlert((prev) => ({ ...prev, open: false }))}
      >
        <DialogContentText style={{ margin: 0 }}>Motivo</DialogContentText>
        <Input
          multiline
          rows={2}
          rowsMax={2}
          value={motivo}
          autoFocus
          onChange={(value) => setMotivo(value)}
        />
      </ConfirmAlert>
    </Box>
  );
};

Index.defaultProps = {
  selected: {},
  onActionCallback: () => {},
};

Index.propTypes = {
  selected: PropTypes.object,
  onActionCallback: PropTypes.func,
};

export default Index;

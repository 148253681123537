import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle, InfoWindow, Polygon } from 'react-google-maps';
import { compose, withProps, lifecycle } from 'recompose';
import formatter from '../../../../utils/formatter';
import _ from 'lodash';
import moment from 'moment';
import styleMap from './styleMap.json';
import { Paper } from '@material-ui/core';

const Map = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyALFV0i5CA9nuaUYvabwL09uCsETeGWTQM&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: 'calc(100% - 50px)' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        bounds: null,
        center: {
          lat: 24.80414877342678, lng: -107.37487792718753
        },
        addressCoordinates: null,
        formattedAddress: null,
        markers: [],
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          });
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();
          let formattedAddress;
          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });

          if (places.length) {
            formattedAddress = places[0].formatted_address;
          }
          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }));
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

          const addressCoordinates = {
            latitud: nextCenter.lat(),
            longitud: nextCenter.lng(),
            altitud: 0
          };

          this.setState({
            center: nextCenter,
            markers: nextMarkers,
            addressCoordinates,
            formattedAddress
          });
          // refs.map.fitBounds(bounds);
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const addressMap = {
    address: props.formattedAddress,
    coordinates: props.addressCoordinates
  };
  const paths = (props.points || []).map((p) => {
    return { lat: p.nu_lat * 1, lng: p.nu_lon * 1 }
  })
  const [markerSelect, setMarkerSelect] = React.useState(null);
  const [path, setPath] = React.useState(paths);
  const polygonRef = React.useRef(null);
  const listenersRef = React.useRef([]);

  console.log('meeeeeeee')
  const onEdit = React.useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = React.useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = React.useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  return (<GoogleMap
    ref={props.onMapMounted}
    defaultZoom={7}
    defaultCenter={props.center}
    onBoundsChanged={props.onBoundsChanged}
    defaultOptions={{
      disableDefaultUI: true, // disable default map UI
      draggable: true, // make map draggable
      keyboardShortcuts: false, // disable keyboard shortcuts
      scaleControl: true, // allow scale controle
      scrollwheel: true, // allow scroll wheel
      styles: styleMap // change default map styles
    }}
  >
    {(props.circles || []).filter(e => e.coordinates && e.coordinates.latitude).map((marker, index) => {
      const date = props.date;
      let values = {};
      if (date && marker.timeline) {
        values.confirmed = marker.timeline.cases[moment(date).format('D/MM/YY')] * 1 || 0
        values.deaths = marker.timeline.deaths[moment(date).format('D/MM/YY')] * 1 || 0
        values.recovered = marker.timeline.recovered[moment(date).format('D/MM/YY')] * 1 || 0
      } else {
        values = marker.stats || {};
      }
      const coordinates = { lat: marker.coordinates.latitude * 1, lng: marker.coordinates.longitude * 1 }
      if (values.confirmed * 1 === 0) return null;
      return (
        <div key={index}>
          <Circle
            key={'cases'}
            center={coordinates}
            radius={values.confirmed < 40000 ? 40000 : values.confirmed < 100000 ? values.confirmed : 40000}
            options={{
              fillColor: "#f00",
              fillOpacity: 0.2,
              strokeColor: "#f00",
              strokeWeight: 0
            }}
          />
        </div>
      )
    })}

    {(props.points || []).map((marker, index) => {
      const patient = marker.patient || {};
      const notas = patient.notas || [];
      return (
        <Marker onClick={() => setMarkerSelect(markerSelect === index ? null : index)} key={index} title={marker.vc_sentencia} position={{ lat: marker.nu_lat, lng: marker.nu_lon }}>
          {
            markerSelect === index &&
            <InfoWindow
              options={{ closeBoxURL: ``, enableEventPropagation: true }}
              children={
                <div style={{ whiteSpace: 'pre-wrap', overflowX: 'hidden', padding: 16, color: 'gray' }}>
                  {marker.vc_sentencia || (notas.reverse()[0] || {}).vc_respuesta || ''}
                </div>
              }
            />
          }
        </Marker>
      )
    })}
    {
      (path || []).length > 1 &&
      <Polygon
        // Make the Polygon editable / draggable
        editable
        draggable
        path={path}
        Event used when manipulating and adding points
        onMouseUp={onEdit}
        // Event used when dragging the whole Polygon
        onDragEnd={onEdit}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          fillColor: "blue",
          fillOpacity: 0.5,
          strokeColor: "blue",
          strokeWeight: 0
        }}
      />
    }
    {(props.cases || []).map((marker, index) => {
      return (
        <Marker animation={true} onClick={() => setMarkerSelect(markerSelect === index ? null : index)} key={index} title={marker.vc_sentencia} position={{ lat: marker.nu_lat * 1, lng: marker.nu_lon * 1 }}>
          {
            markerSelect === index &&
            <InfoWindow
              options={{ closeBoxURL: ``, enableEventPropagation: true }}
              children={
                <div style={{ whiteSpace: 'pre-wrap', overflowX: 'hidden', padding: 16 }}>
                  {'capturado en plataforma'}
                </div>
              }
            />
          }
        </Marker>
      )
    })}
    {/* {props.controls} */}
  </GoogleMap>);
}
);

class GoogleMaps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressCoordinates: null
    };
  }

  // ---------------------------------
  // ------ life cycle events --------
  // ---------------------------------
  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !_.isEqual(nextState, this.state) || !_.isEqual(clean(nextProps), clean(this.props));
  }


  // ------------------------
  // ------  methods --------
  // ------------------------
  address(value) {
    const { getAddressMap } = this.props;
    if (value) {
      console.log('value', value)
      // getAddressMap(value);
    }
  }

  // ------------------------------
  // ------ render methods --------
  // ------------------------------
  render() {
    console.log('this.props.circles', this.props.circles)
    return (
      <Map
        getAddressMap={this.address.bind(this)}
        points={this.props.points}
        cases={this.props.cases}
        circles={this.props.circles}
        controls={this.props.controls}
      />

    );
  }
}


export default GoogleMaps;

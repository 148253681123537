import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setHelperSidebarRender, resetHelperSidebarRender } from '../../../actions';
import EstudiosItem from '../estudiosItem';
import { Typography } from '@material-ui/core';
import { FadeInView } from '../../../../../components';

class Solicitudes extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.listado = this.listado.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.getIndex = this.getIndex.bind(this);
    }

    render() {
        return (
            <div style={{ position: 'relative', top: 0, left: 0, width: '100%', height: window.innerHeight - 100, overflowY: 'scroll', WebkitOverflowScrolling: 'touch', padding: 0 }}>
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        const {indexSeleccionado} = this.props
        const listado = this.listado()

        return (<FadeInView duration={100} key={'solicitudes-fadein'}>
            {
                listado.map((tp, i) => {
                    return (
                    <div key={i}>
                        <Typography key={i} style={{margin: '10px 0', fontWeight: 500}}>{tp.vc_nombre}</Typography>

                        {tp.niveles.map((n, i) => {
                            return (
                                <div key={i} style={{overflow: 'auto', marginLeft: '15px'}}>
                                    <Typography key={i} style={{margin: '10px 0', fontWeight: 500, opacity: 0.8}}>{n.vc_nombre}</Typography>
                                    {n.estudios.map((item, i) => {
                                        const index = this.getIndex(item);
                                        return <EstudiosItem
                                            callbackSelect={(data)=>{
                                                this.selectItem(data, index)
                                            }}
                                            callbackDelete={(item) => {
                                                this.deleteItem(item, index)
                                            }}
                                            indexSeleccionado={indexSeleccionado}
                                            item={item}
                                            sn_printVersion={false}
                                            index={index}
                                            key={index}
                                            style={{ width: 'auto' }}
                                        />
                                    })}        
                                </div>
                            )
                        })}
                    </div>)
                })
            }
        </FadeInView>);
    }

    listado(){
        const {opcion, niveles} = this.props
        const estudios = this.props.pacienteActual.solicitudActual.estudios || []
        let tiposEstudios = [
            { id_tipoEstudio: 1, vc_nombre: 'Laboratorio', niveles: [], estudios: estudios.filter(v => v.id_tipoEstudio == 1), },
            { id_tipoEstudio: 2, vc_nombre: 'Imagenología', niveles: [], estudios: estudios.filter(v => v.id_tipoEstudio == 2), },
            { id_tipoEstudio: 3, vc_nombre: 'Patología', niveles: [], estudios: estudios.filter(v => v.id_tipoEstudio == 3), },
        ]

        tiposEstudios = tiposEstudios.filter(v => v.estudios.length).map(v=>v)

        tiposEstudios.forEach(t => {
            t.estudios.forEach(e => {
                const nivel = t.niveles.find(n => n.id == e.id_nivel)
                if( nivel ){
                    nivel.estudios.push(e)
                }
                else{
                    const {id, vc_nombre} = niveles.find(n => n.id == e.id_nivel)
                    t.niveles.push({
                        id,
                        vc_nombre,
                        estudios: [e]
                    })
                }
            })
        })

        switch (opcion) {
            case 'laboratorio': return tiposEstudios.filter(e => e.id_tipoEstudio == 1);
            case 'imagenologia': return tiposEstudios.filter(e => e.id_tipoEstudio == 2);
            case 'patologia': return tiposEstudios.filter(e => e.id_tipoEstudio == 3);
            default: return tiposEstudios;
        }
    }

    selectItem({ opcionesModuloNext, sn_seleccionado, indexSeleccionado }, index){
        const {updateState} = this.props

        if (sn_seleccionado == 1) {
            updateState({ indexSeleccionado: index, subOpcionActualModulo: 'prioridad' });
        } else {
            updateState({ indexSeleccionado: null, subOpcionActualModulo: 'agregar' });
        }
    }

    deleteItem(item, index){
        const {updateState} = this.props
        
        let solicitudActual = this.props.pacienteActual.solicitudActual || { vc_observaciones: '' };

        if (index > -1) {
            solicitudActual.estudios.splice(index, 1);
            updateState({ indexSeleccionado: null, subOpcionActualModulo: 'agregar' }, () => {
                this.props.setPacienteActual({ ...this.props.pacienteActual, solicitudActual });
            });
        }
    }

    getIndex(item) {
        const estudios = this.props.pacienteActual.solicitudActual.estudios || []
        const {opcion} = this.props

        switch (opcion) {
            case 'laboratorio': return estudios.filter((e, index) => e.id_tipoEstudio == 1).indexOf(item);
            case 'imagenologia': return estudios.filter((e, index) => e.id_tipoEstudio == 2).indexOf(item);
            case 'patologia': return estudios.filter((e, index) => e.id_tipoEstudio == 3).indexOf(item);
            default: return estudios.indexOf(item);
        }
    }

    componentDidMount() {
        // this.props.showSidebar(true);
    }
}


Solicitudes.propTypes = {
    viewTitle: PropTypes.string,
    setViewTitle: PropTypes.func,
    setActiveView: PropTypes.func,

    setHelperSidebarRender: PropTypes.func,
    resetHelperSidebarRender: PropTypes.func,
    setSubMenuOptions: PropTypes.func,
    setActiveView: PropTypes.func,
};


const mapStateToProps = (state) => {
    const { niveles } = state.Think;
    return {
        niveles,
    };
};


const actions = {
    setHelperSidebarRender,
    resetHelperSidebarRender,
};


export default connect(mapStateToProps, actions)(Solicitudes);

import React from "react";

export default ({ keyvalue = "id", keytext = "vc_nombre", ...props }) => {
  const [data, setData] = React.useState(props.data || []);
  const [value, setValue] = React.useState(props.value || null);

  React.useEffect(() => {
    if (!data.lenght) {
      setValue(null);
    }
  }, [data]);

  return {
    data,
    value,
    options: data.map((v) => ({
      value: v[keyvalue],
      text: v[keytext],
    })),
    setData,
    setValue,
  };
};

import React from "react";
import internalAPI from "app/utils/internalAPI";

const api = new Proxy(
  {},
  {
    get(target, name) {
      return (url, params) => {
        return new Promise((resolve, reject) => {
          internalAPI.erp.general(url, name, params, (err, res) => {
            if (!err) resolve(res);
            else reject(err);
          });
        });
      };
    },
  }
);

export default (uri = "") => {
  const [path, setPath] = React.useState(uri);

  return {
    get(params = {}) {
      return api.get(path, params);
    },
    post(params = {}) {
      return api.post(path, params);
    },
    put(params = {}) {
      return api.put(path, params);
    },
    delete(params = {}) {
      return api.delete(path, params);
    },

    list({ limit = 10, page = 1, ...params } = {}) {
      return api.get(path, { limit, page, ...params });
    },
    custom(url, params, method = "get") {
      return api[method](`${path}/${url}`, params);
    },
  };
};

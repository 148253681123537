import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  outlinedInput: {
    padding: '8px 15px',
  },
  inputLabel: {
    transform: 'translate(14px, 10px) scale(1)'
  }
}));
const PhoneNumberFormat = props => {
  const {
    inputRef,
    onChange,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
    />
  );
};

export default props => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [prevValueProps, setPrevValueProps] = React.useState(null);
  useEffect(() => {
    if (props.value != value.value && props.value != prevValueProps) {
      if (props.value !== null) {
        setPrevValueProps(props.value)
        setValue(props.value)
      }
    }
  }, [props])
  const onChangeCapture = (e) => {
    if (!props.maxLength) {
      setValue(e.target.value)
    } else if (props.maxLength >= e.target.value.length) {
      console.log('maxlength', props.maxLength)
      setValue(e.target.value)
    }
  }
  return (
    <TextField
      variant={'outlined'}
      id={props.fieldid}
      fullWidth={props.fullWidth}
      onChangeCapture={onChangeCapture}
      value={value}
      InputProps={{
        inputComponent: PhoneNumberFormat
      }}
      inputProps={{
        className: classes.outlinedInput,
        format: "(###) #-##-##-##"
      }}
      label={props.required ? `${props.label}*` : props.label}
      // {...props}
      style={{ marginTop: 9.5, marginRight: 10 }}
    />
  );
};
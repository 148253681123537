export const menuPrivacity = [
    {
        id: 'privacity',
        vc_nombre: 'Aviso de Privacidad',
        enable: true,
        sidebar: {
          open: false,
          enable: false,
        },
        opciones: [
            {
                id: 'aviso',
                vc_nombre: 'Aviso de Privacidad',
                acciones: [
                    {
                        id: 'aceptar',
                        vc_nombre: 'Aceptar',
                        icon: null
                    },
                    {
                        id: 'salir',
                        vc_nombre: 'Salir',
                        icon: null
                    }
                ]
            }
        ]
    }
  ];
import React from "react";
import _ from "lodash";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  Grid,
} from "@material-ui/core";

import Input from "app/components/Form/Input";

import InputSearch from "./InputSearchInstitution";
import Icon from "app/sales/components/Common/Icon";

import {
  fields,
  fieldsRequired,
  fieldsForm,
} from "app/sales/containers/Proveedores/fields";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
  const { open, close, accept, data } = props;
  const [form, setForm] = React.useState({});
  const [disabled, setDisabled] = React.useState(false);

  const handleInputChange = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleRfc = (value) => {
    const base = {
      ref_id_institucion: value.id,
      vc_rfc: value.vc_rfc,
      vc_nombre: value.vc_nombre,
      vc_razonSocial: value.vc_razonSocial,
      vc_domicilioFiscal: value.vc_direccion,
      nu_codigoPostal: value.vc_codigoPostal,
    };

    setForm((prev) => ({
      ...prev,
      ...base,
    }));
  };

  React.useEffect(() => {
    const disabled = Object.keys(form).some(
      (field) => fieldsRequired.includes(field) && !form[field]
    );
    setDisabled(disabled);
  }, [form]);

  React.useEffect(() => {
    setForm(_.pick(data, fields));
  }, [data]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Proveedor</DialogTitle>
      <DialogContent>
        <Grid container key="rfc">
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <Icon icon="DescriptionOutlined" />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            <InputSearch value={form.vc_rfc} onClick={handleRfc} />
          </Grid>
        </Grid>

        {fieldsForm.map((field, i) => (
          <Grid container key={i}>
            <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
              <DialogContentText>
                <Icon icon={field.icon} />
              </DialogContentText>
            </Grid>
            <Grid item xs={11}>
              <Input
                {...field.props}
                value={form[field.name]}
                onChange={(value) => handleInputChange(field.name, value)}
              />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={close}
          style={{ alignSelf: "right" }}
        >
          Cancelar
        </Button>

        <Button
          onClick={() => {
            accept(form);
          }}
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          disabled={disabled}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  header: {
    color: "black",
    "& .avatar": {
      flexGrow: 0,
      height: "auto",
      width: 80,
      "& img": {
        height: "auto",
      },
    },
    "& .info": {
      flex: 1,
      textAlign: "center",
    },
    "& .qr": {
      float: "right",
      width: "80px !important",
      height: "80px !important",
      margin: 0,
      borderRadius: 5,
      padding: 3,
    },
    "& .title": {
      height: 30,
      textAlign: "center",
      border: "1px solid gray",
      borderRadius: 5,
      padding: 5,
      fontSize: 15,
    },
    "& .date": {
      textAlign: "right",
    },
  },
}));

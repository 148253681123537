import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import internalAPI from "app/utils/internalAPI";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Checkbox,
  makeStyles,
  Grow,
  FormControlLabel,
  CircularProgress,
  Fade,
} from "@material-ui/core";

import {
  Lens,
  CalendarTodayOutlined,
  ContactsOutlined,
  PersonOutline,
  ScheduleOutlined,
  SubjectOutlined,
  TodayOutlined,
  DateRangeOutlined,
} from "@material-ui/icons";

import Input from "app/components/Form/Input";
import InputSearchPatient from "app/components/Form/InputSearchPatient";
import ItemPatient from "app/components/Form/ItemPatient";
import Select from "app/components/Form/Select";
import DateRange from "app/components/Form/ButtonDateRange";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const Index = (props) => {
  const classes = useStyles();
  const { agendas = [] } = window.amplify.store("institution") || {};
  const { agendaId, open, close, accept, estatusCitas } = props;
  const [paciente, setPaciente] = React.useState(null);
  const [agenda, setAgenda] = React.useState(null);
  const [form, setForm] = React.useState({
    id_agenda: agendaId,
    todas: true,
  });
  const [disabled, setDisabled] = React.useState(false);
  const [error, setError] = React.useState("");
  const [range, setRange] = React.useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);
  const [data, setData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [fetched, setFetched] = React.useState(false);

  const cat_agendas = agendas.map((v) => ({ value: v.id, text: v.vc_nombre }));

  const handleInputChange = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  React.useEffect(() => {
    setError("");
    setDisabled(!form.id_agenda || !paciente);

    setData([]);
    setFetched(false);
  }, [form, paciente, range]);

  React.useEffect(() => {
    const found = agendas.find((v) => v.id === form.id_agenda);
    setAgenda(found);
  }, [form.id_agenda]);

  React.useEffect(() => {
    if (open) {
      setPaciente(null);
      handleInputChange("todas", true);
    }
  }, [open]);

  const buscar = () => {
    const params = {
      id_institucion: agenda.id_institucion,
      id_dependencia: agenda.id_dependencia,
      id_area: agenda.id_area,
      id_agenda: agenda.id,
      id_usuario: window.amplify.store("user_id"),
      id_paciente: paciente.id,
    };

    if (!form.todas) {
      params.dt_inicio = moment(range[0]).format("YYYY-MM-DD");
      params.dt_fin = moment(range[1]).format("YYYY-MM-DD");
    }

    setLoading(true);
    setFetched(false);
    internalAPI.getMeetings(params, (err, res) => {
      setLoading(false);
      setFetched(true);
      if (err) {
        setError(err.error);
      } else {
        setData(res.data);
      }
    });
  };

  React.useEffect(() => {
    if (data.length) {
      const aux = data.sort((a, b) =>
        moment(`${a.dt_cita} ${a.tm_inicio}`).diff(
          moment(`${b.dt_cita} ${b.tm_inicio}`)
        )
      );

      setRows(aux);
    } else {
      setTimeout(() => {
        setRows([]);
      }, 100);
    }
  }, [data]);

  return (
    open && (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Buscar Citas</DialogTitle>
        <DialogContent style={{ maxWidth: '100%' }}>
          <Grid container key="agenda">
            <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
              <DialogContentText>
                <TodayOutlined />
              </DialogContentText>
            </Grid>
            <Grid item xs={11}>
              <Select
                data={cat_agendas}
                value={form.id_agenda}
                hideSearch
                onClick={(v) => handleInputChange("id_agenda", v.value)}
              />
            </Grid>
          </Grid>
          <Grid container key="paciente">
            <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
              <DialogContentText>
                <PersonOutline />
              </DialogContentText>
            </Grid>
            <Grid item xs={11}>
              {!paciente && (
                <Box py={1}>
                  <InputSearchPatient
                    onClick={setPaciente}
                    autoFocus
                    addPatient={false}
                  />
                </Box>
              )}
              {paciente && (
                <List dense>
                  <ItemPatient
                    data={paciente}
                    dense
                    disableGutters
                    onDelete={() => setPaciente(null)}
                  />
                </List>
              )}
            </Grid>
          </Grid>

          <Grid container key="todas">
            <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
              <DialogContentText>
                <DateRangeOutlined />
              </DialogContentText>
            </Grid>
            <Grid item xs={11}>
              <Box py={1}>
                <FormControlLabel
                  label="Todas las citas"
                  control={
                    <Checkbox
                      checked={form.todas}
                      onChange={(e) =>
                        handleInputChange("todas", e.target.checked)
                      }
                      color="primary"
                      label="Todas las citas"
                    />
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Fade in={!form.todas} key="rango">
            <Grid container>
              <Grid
                item
                xs={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <DialogContentText>
                  <DateRangeOutlined />
                </DialogContentText>
              </Grid>
              <Grid item xs={11}>
                <DateRange value={range} onChange={setRange} showAcceptButton />
              </Grid>
            </Grid>
          </Fade>

          {fetched && !data.length && (
            <Box display="flex" justifyContent="center" p={1} key="nocitas">
              <Typography variant="subtitle2">
                No se encontraron citas
              </Typography>
            </Box>
          )}

          <Fade
            in={!!data.length}
            {...(data.length ? { timeout: 1000 } : {})}
            key="citas"
          >
            <List dense className={classes.list} disablePadding>
              {rows.map((v, i) => {
                const estatus = estatusCitas.find(
                  (e) => e.id === v.id_estatusCita
                );
                return (
                  <ListItem disableGutters key={i}>
                    <ListItemText
                      primary={
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="subtitle2">
                            {moment(`${v.dt_cita} ${v.tm_inicio}`).format(
                              "hh:mm a, DD [de] MMM [de] YYYY"
                            )}
                          </Typography>
                          <Box display="flex" alignItems="center">
                            <Lens
                              style={{
                                marginRight: "4px",
                                fontSize: "14px",
                                color: estatus ? estatus.vc_color : undefined,
                              }}
                            />
                            <Typography variant="subtitle2">
                              {estatus ? estatus.vc_nombre : "indefinido"}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </Fade>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={close}
            style={{ alignSelf: "right" }}
          >
            Cerrar
          </Button>

          <Button
            onClick={buscar}
            variant="contained"
            color="primary"
            style={{ marginLeft: 8 }}
            disabled={disabled}
          >
            {loading && (
              <CircularProgress
                size={20}
                color="white"
                style={{ marginRight: 8 }}
              />
            )}
            Buscar
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

Index.defaultProps = {
  open: false,
  agendas: [],
  agendaId: 0,
  close: () => {},
  accept: () => {},

  estatusCitas: [],
};

Index.propTypes = {
  open: PropTypes.bool,
  agendas: PropTypes.array,
  agendaId: PropTypes.number,
  close: PropTypes.func,
  accept: PropTypes.func,

  estatusCitas: PropTypes.array,
};

const mapStateToProps = ({ Think: { estatusCitas } }) => ({ estatusCitas });

export default connect(mapStateToProps, [])(Index);

import internalAPI from "../../../utils/internalAPI";
import notifications from "../../../utils/notifications";
import { logOut } from "../../../containers/Think/actions";
import moment from "moment";

export default {
  getPatients(texto) {
    const { id_institucion = 0 } = window.amplify.store("institution") || {};

    const body = {
      id_institucion,
      texto,
    };

    return internalAPI.hospitalizacion.getAtenciones(body);
  },
  getTimeLine(paciente) {
    const { id_institucion, id: id_paciente, id_atencion } = paciente;

    return internalAPI.hospitalizacion.getAtencion({
      id_institucion,
      id_paciente,
      id_atencion,
    });
  },
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, Label } from "semantic-ui-react";
import moment from "moment";

// Material UI
import { styled } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {
  getIndexLastDetalle,
  getLastDetalle,
  isDetalleActive,
} from "../../../../utils/repository";
import { Box } from "@material-ui/core";
const isMobile = window.isMobile;
const TratamientoItem = styled(ExpansionPanel)({
  marginBottom: "10px",
  "& .cie10": {
    marginRight: 10,
    // color:window.colors.red,
    width: "40px",
  },
  "& .MuiExpansionPanelSummary-root": {
    minHeight: "48px",
    height: "48px",
  },

  "& .MuiExpansionPanelDetails-root": {
    padding: "8px",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
  "& .icon": {
    marginLeft: 10,
    float: "right",
    color: "gray",

    "&.delete": {
      display: "none",
      cursor: "pointer",
      color:window.colors.red,
    },
  },

  "&:hover": {
    "& .icon": {
      // display: 'none',
      "&.delete": {
        display: "block",
      },
    },
  },
});

class TratamientosItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverIndex: null,
      inactiveSelect: false,
      indexSelected: null,

      anchorEl: null,
      open: false,

      tratamiento: {
        id: null,
        id_estatus: 1,
        id_institucion: null,
        id_tratamiento: null,
        id_medicamento: null,
        id_usuario: null,
        id_paciente: null,
        sn_activo: 1,
        dt_registro: null,
        detalle: {
          id: null,
          id_institucion: null,
          id_estatus: 1,
          id_tratamientoOrigen: null,
          id_tratamientoActual: null,
          id_tratamientoMedicamento: null,
          id_usuario: null,
          id_um_dosis: null,
          id_um_duracion: null,
          id_um_frecuencia: null,
          id_um_intervalo: null,
          id_conciliacion: null,
          vc_dosis: null,
          nu_dosis: null,
          id_vehiculo: null,
          nu_vehiculos: null,
          id_viaAdministracion: null,
          nu_duracion: null,
          nu_frecuencia: null,
          nu_intervalo: null,
          dt_vigencia: null,
          dt_inicio: null,
          tm_inicio: null,
          vc_observaciones: "",
          sn_activo: 1,
          dt_registro: null,
        },
      },
    };
  }

  // ------------------------------
  // ------ render methods --------
  // ------------------------------

  render() {
    const { item, sn_printVersion, historyMode = false } = this.props;
    const detalle = getLastDetalle(item.detalles, "dt_vigencia");
    if (sn_printVersion && detalle && isDetalleActive(detalle))
      return this.renderPrint();
    if (historyMode && detalle) return this.renderPrint();
    else if (!sn_printVersion) return this.renderItem();
    else return null;
  }

  renderItem() {
    const {
      date,
      medicamentosDgis,
      item,
      index,
      indexSeleccionado,
    } = this.props;

    const medicamentoDgis = medicamentosDgis.find(
      (r) => r.id == item.id_medicamento
    );
    const title =
      item.id_medicamento == 0
        ? item.vc_medicamento
        : `${
            medicamentoDgis.vc_nombre
          }  ${medicamentoDgis.vc_concentracion.toLowerCase()}`;
    const formaFarmaceutica =
      item.id_medicamento == 0 ? "" : `${medicamentoDgis.vc_farmaceutica}`;
    const lastDetalle = getLastDetalle(item.detalles, "dt_vigencia");

    let anchorEl;

    const handleClick = (event) => {
      event.stopPropagation();
      anchorEl = event.currentTarget;
      this.open = true;

      this.setState({ anchorEl, open: this.open });
    };

    const handleClose = () => {
      anchorEl = null;
      this.open = false;

      this.setState({ anchorEl, open: this.open });
    };

    const color = this.colorDx(lastDetalle);
    return (
      <TratamientoItem
        expanded={true}
        onChange={(e, expanded) => {
          e.stopPropagation();
          this.actionsMedicamento("select", index);
        }}
        style={{
          width: index == indexSeleccionado ? "calc(100% + 8px)" : "100%",
          borderRadius: 8,
          borderLeft:
            index == indexSeleccionado && window.isMobile
              ? `8px solid ${color}`
              : null,
          borderRight: index == indexSeleccionado ? `8px solid ${color}` : null,
        }}
      >
        <ExpansionPanelSummary
          expandIcon={null}
          style={{ borderBottom: `1px solid ${color}` }}
        >
          <Typography>{title}</Typography>
          <Typography style={{ marginLeft: "5px" }}>
            <span style={{ color: color }}>{formaFarmaceutica}</span>
            <Icon
              name="delete"
              onClick={handleClick}
              style={{ float: "right" }}
            />
            <Popover
              open={this.state.open}
              anchorEl={this.state.anchorEl}
              onClose={handleClose}
              onClick={(e) => e.stopPropagation()}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              transformOrigin={{ vertical: "center", horizontal: "center" }}
            >
              <div
                style={{
                  padding: "20px",
                  height: "100px",
                  textAlign: "center",
                }}
              >
                <Typography>
                  ¿Seguro de querer eliminar el tratamiento?
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.deleteItem(item);
                    handleClose();
                  }}
                >
                  Aceptar
                </Button>
                <Button size="small" onClick={handleClose}>
                  Cancelar
                </Button>
              </div>
            </Popover>
          </Typography>
        </ExpansionPanelSummary>
        {this.renderDetallesVigentes(item)}
        <Divider />
        <ExpansionPanelActions
          style={{ justifyContent: "center", cursor: "pointer" }}
          onClick={() => this.addDetalle()}
        >
          <Icon name="chevron down" />
        </ExpansionPanelActions>
      </TratamientoItem>
    );
  }

  renderDetallesVigentes(item) {
    let sn_continuacion = 0;
    const lastIndex = getIndexLastDetalle(item.detalles, "dt_vigencia");
    return item.detalles.map((detalle, index) => {
      let sn_base = 0;
      if (sn_continuacion == 0) {
        sn_base = 1;
        sn_continuacion = 1;
      }
      const isActive = this.isActive(detalle.dt_vigencia);
      if (isActive || lastIndex === index) {
        return (
          <ExpansionPanelDetails
            key={index}
            style={{ padding: 0, flexDirection: "column" }}
            onClick={(e) => {
              e.stopPropagation();
              this.actionsMedicamento(
                isActive ? "selectDetalle" : "select",
                index
              );
            }}
            onMouseEnter={() => this.setState({ detalle: index })}
            onMouseLeave={() => this.setState({ detalle: null })}
          >
            {this.stringDescriptionMedicamento(detalle, index, sn_base)}
            <Divider />
          </ExpansionPanelDetails>
        );
      } else {
        return null;
      }
    });
  }

  renderPrint() {
    const { medicamentosDgis, item, index } = this.props;

    const medicamentoDgis = medicamentosDgis.find(
      (r) => r.id == item.id_medicamento
    );
    const title =
      item.id_medicamento == 0
        ? item.vc_medicamento
        : `${
            medicamentoDgis.vc_nombre
          }  ${medicamentoDgis.vc_concentracion.toLowerCase()}`;
    const formaFarmaceutica =
      item.id_medicamento == 0 ? "" : `${medicamentoDgis.vc_farmaceutica}`;

    return (
      <div
        key={index}
        id={`medicamento-${item.id_medicamento}`}
        style={{
          width: "100%",
          minHeight: 55,
          margin: 0,
          padding: 0,
        }}
      >
        <div style={stylesTx.titlePrint}>
          {title} {formaFarmaceutica ? " - " : ""} {formaFarmaceutica}
        </div>
        <div style={stylesTx.subTitlePrint}>
          {this.renderDetallesVigentesReceta(item)}
        </div>
      </div>
    );
  }

  renderDetallesVigentesReceta(item) {
    return item.detalles.map((detalle, index) => {
      if (detalle.id == null || item.detalles.length - 1 == index) {
        return (
          <div key={index} style={{ width: "100%", marginBottom: 5 }}>
            {this.stringDescriptionMedicamentoReceta(detalle)}
            <div key={index} style={{ width: "100%", marginBottom: 5 }}>
              {detalle.vc_observaciones}
            </div>
          </div>
        );
      }
    });
  }

  // ------------------------------
  // ---------- methods -----------
  // ------------------------------

  actionsMedicamento(command, index) {
    let {
      remove,
      detalle,
      addDetalle,
      removeDetalle,
      detalleEdit,
    } = this.state;

    switch (command) {
      case "select":
        if (
          this.props.index == this.props.indexSeleccionado &&
          this.props.indexSeleccionado != null
        ) {
          this.props.callbackSelect({
            opcionesModuloNext: "agregar",
            sn_seleccionado: 0,
            indexSeleccionado: null,
            indexDetalleSeleccionado: null,
          });
        } else {
          this.props.callbackSelect({
            opcionesModuloNext: "historial",
            sn_seleccionado: 1,
            indexSeleccionado: this.props.index,
            indexDetalleSeleccionado: getIndexLastDetalle(
              this.props.item.detalles,
              "dt_vigencia"
            ),
          });
        }
        // else if ( this.props.item.id_vehiculo == null ){
        //     this.props.callbackSelect({ opcionesModuloNext: 'vehiculo', sn_seleccionado: 1 , indexSeleccionado: this.props.index, indexDetalleSeleccionado: (this.props.item.detalles.length - 1)});
        // } else {
        //     this.props.callbackSelect({ opcionesModuloNext: detalleEdit = 'dosis', sn_seleccionado: 1 , indexSeleccionado: this.props.index, indexDetalleSeleccionado: (this.props.item.detalles.length - 1)});
        // }
        break;
      case "detalleEdit":
        this.props.callbackSelect({
          opcionesModuloNext: detalleEdit,
          sn_seleccionado: 1,
          indexSeleccionado: this.props.index,
          indexDetalleSeleccionado:
            index > -1
              ? index
              : getIndexLastDetalle(this.props.item.detalles, "dt_vigencia"),
        });
        break;
      case "selectDetalle":
        if (detalle.id_vehiculo == null) {
          this.props.callbackSelect({
            opcionesModuloNext: "vehiculo",
            sn_seleccionado: 1,
            indexSeleccionado: this.props.index,
            indexDetalleSeleccionado:
              index > -1
                ? index
                : getIndexLastDetalle(this.props.item.detalles, "dt_vigencia"),
          });
        } else {
          this.props.callbackSelect({
            opcionesModuloNext: (detalleEdit = "dosis"),
            sn_seleccionado: 1,
            indexSeleccionado: this.props.index,
            indexDetalleSeleccionado:
              index > -1
                ? index
                : getIndexLastDetalle(this.props.item.detalles, "dt_vigencia"),
          });
        }
        // this.props.callbackSelect({ opcionesModuloNext: 'dosis', sn_seleccionado: 1 , indexSeleccionado: this.props.index, indexDetalleSeleccionado:detalle});
        break;
      case "remove":
        this.deleteItem(this.props.item);
        break;
      case "removeDetalle":
        this.deleteItemDetalle(index);
        break;
    }
  }

  deleteItem(item) {
    if (item.id == null) {
      this.props.callbackDelete({
        opcionesModuloNext: "agregar",
        sn_seleccionado: 0,
        indexSeleccionado: null,
        item,
      });
    } else {
      this.props.callbackDelete({
        opcionesModuloNext: "agregar",
        sn_seleccionado: 0,
        indexSeleccionado: null,
        item,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !window._.isEqual(nextState, this.state) || !window._.isEqual(nextProps, this.props)
    );
  }
  // onChangeValue(event) {
  //     this.props.callbackPreg({ value: event.target.value, index: this.props.index });
  //     this.setState({ value: event.target.value });
  // }

  respuestaValor(item) {
    switch (item.id_tipoPregunta) {
      case (1, 2):
        if (item.respuesta != undefined) {
          return `${
            item.versiones[0].respuestas.filter(
              (r) => r.id === item.respuesta.id_respuesta
            )[0].vc_respuesta
          }`;
        }
      case 3:
        if (item.respuesta != undefined) {
          return `${item.respuesta.nu_respuesta}`;
        }
      case 4:
        if (item.respuesta != undefined) {
          return `${item.respuesta.vc_respuesta}`;
        }
      case 5:
        if (item.respuesta != undefined) {
          return `${item.respuesta.vc_respuesta}`;
        }
      case 6:
        if (item.respuesta != undefined) {
          return `${item.respuesta.tm_respuesta}`;
        }
      default:
        return null;
    }
    return "";
  }

  timeSince(refDate, date) {
    var seconds = Math.floor((refDate - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " años";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " meses";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " días";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " horas";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutos";
    }
    return "un instante";
  }
  statusDxToString(id_estatusDiagnostico) {
    switch (id_estatusDiagnostico) {
      case 1:
        return "presuntivo";
      case 2:
        return "definitivo ó confirmado";
      case 3:
        return "descartado";
      default:
        return "presuntivo";
    }
  }
  
  isActive(dt_vigencia) {
    if (moment(dt_vigencia) < moment()) {
      return false;
    }
    return true;
  }
  dosisString(detalle) {
    let dosisString = "";
    const administracionesArr = detalle.administraciones
      ? detalle.administraciones
      : [];
    administracionesArr.map((item, index) => {
      if (index > 0) {
        dosisString = dosisString + " - ";
      }
      const time = item.tm_toma;
      if (item.nu_vehiculos != null && detalle.id_vehiculo != null) {
        if (item.nu_vehiculos == 1) {
          dosisString =
            dosisString +
            `${item.nu_vehiculos}` +
            " " +
            `${
              this.props.vehiculos.filter(
                (r) => r.id === detalle.id_vehiculo
              )[0].vc_abreviaturaSingular
            } ${
              item.tm_toma
                ? moment(
                    `${moment().format("YYYY-MM-DD")} ${item.tm_toma}`
                  ).format("h:mm a")
                : ""
            }`;
        } else {
          dosisString =
            dosisString +
            `${item.nu_vehiculos}` +
            " " +
            `${
              this.props.vehiculos.filter(
                (r) => r.id === detalle.id_vehiculo
              )[0].vc_abreviaturaPlural
            } ${
              item.tm_toma
                ? moment(
                    `${moment().format("YYYY-MM-DD")} ${item.tm_toma}`
                  ).format("h:mm a")
                : ""
            }`;
        }
      } else if (item.vc_dosis != null) {
        dosisString = dosisString + `${item.vc_dosis}`;
      } else {
        dosisString = dosisString + "toma: no especificada";
      }
    });
    return dosisString == "" ? "Dosis:" : dosisString;
  }
  frecuenciastring(detalle) {
    let string = " frecuéncia: no especificada";
    if (detalle.nu_frecuencia != null && detalle.id_um_frecuencia != null) {
      if (detalle.nu_frecuencia == 1) {
        string =
          " cada " +
          `${detalle.nu_frecuencia}` +
          " " +
          `${
            this.props.unidadesMedida.filter(
              (r) => r.id === detalle.id_um_frecuencia
            )[0].vc_nombre
          }`.toLowerCase();
      } else if (detalle.id_um_frecuencia == 5) {
        string =
          " cada " +
          `${detalle.nu_frecuencia}` +
          " " +
          `${
            this.props.unidadesMedida.filter(
              (r) => r.id === detalle.id_um_frecuencia
            )[0].vc_nombre
          }`.toLowerCase() +
          "es";
      } else {
        string =
          " cada " +
          `${detalle.nu_frecuencia}` +
          " " +
          `${
            this.props.unidadesMedida.filter(
              (r) => r.id === detalle.id_um_frecuencia
            )[0].vc_nombre
          }`.toLowerCase() +
          "s";
      }
    }
    return string;
  }
  viaAdministracionString(detalle) {
    if (detalle.id_viaAdministracion != null) {
      const viaAdministracion = this.props.viasAdministracion.filter(
        (r) => r.id === detalle.id_viaAdministracion
      )[0];
      if (viaAdministracion) {
        return " via " + `${viaAdministracion.vc_nombre.toLowerCase()}`;
      } else {
        return " via de administración no especificáda";
      }
    } else {
      return " via de administración no especificáda";
    }
  }
  duracionString(detalle) {
    if (detalle.nu_duracion != null && detalle.id_um_duracion != null) {
      if (detalle.id_um_duracion === 34) return ' sin suspender';
      if (detalle.nu_duracion == 1) {
        return (
          " durante " +
          `${detalle.nu_duracion}` +
          " " +
          `${
            this.props.unidadesMedida.filter(
              (r) => r.id === detalle.id_um_duracion
            )[0].vc_nombre
          }`.toLowerCase()
        );
      } else if (detalle.id_um_frecuencia == 5) {
        return (
          " durante " +
          `${detalle.nu_duracion}` +
          " " +
          `${
            this.props.unidadesMedida.filter(
              (r) => r.id === detalle.id_um_duracion
            )[0].vc_nombre
          }`.toLowerCase() +
          "es"
        );
      } else if (detalle.id_um_frecuencia == 34) {
        return " sin suspender.";
      } else {
        return (
          " durante " +
          `${detalle.nu_duracion}` +
          " " +
          `${
            this.props.unidadesMedida.filter(
              (r) => r.id === detalle.id_um_duracion
            )[0].vc_nombre
          }`.toLowerCase() +
          "s"
        );
      }
    } else {
      return " duracion: no especificada";
    }
  }
  stringDescriptionMedicamento(detalle, index, sn_base) {
    const sty = { margin: 3 };
    const items = {
      dosis: (
        <Label
          as="a"
          style={sty}
          onClick={(e) => {
            this.actionsMedicamento("detalleEdit", index);
            e.stopPropagation();
          }}
          onMouseEnter={() => this.setState({ detalleEdit: "dosis" })}
          onMouseLeave={() => this.setState({ detalleEdit: null })}
        >
          {this.dosisString(detalle)}
        </Label>
      ),
      via: (
        <Label
          as="a"
          style={sty}
          onClick={(e) => {
            this.actionsMedicamento("detalleEdit", index);
            e.stopPropagation();
          }}
          onMouseEnter={() => this.setState({ detalleEdit: "via" })}
          onMouseLeave={() => this.setState({ detalleEdit: null })}
        >
          {this.viaAdministracionString(detalle)}
        </Label>
      ),
      frecuencia: (
        <Label
          as="a"
          style={sty}
          onClick={(e) => {
            this.actionsMedicamento("detalleEdit", index);
            e.stopPropagation();
          }}
          onMouseEnter={() => this.setState({ detalleEdit: "nu_frecuencia" })}
          onMouseLeave={() => this.setState({ detalleEdit: null })}
        >
          {this.frecuenciastring(detalle)}
        </Label>
      ),
      duracion: (
        <Label
          as="a"
          style={sty}
          onClick={(e) => {
            this.actionsMedicamento("detalleEdit", index);
            e.stopPropagation();
          }}
          onMouseEnter={() => this.setState({ detalleEdit: "nu_duracion" })}
          onMouseLeave={() => this.setState({ detalleEdit: null })}
        >
          {this.duracionString(detalle)}
        </Label>
      ),
      observaciones: (
        <Label
          as="a"
          style={sty}
          onClick={(e) => {
            this.actionsMedicamento("detalleEdit", index);
            e.stopPropagation();
          }}
          onMouseEnter={() =>
            this.setState({ detalleEdit: "vc_observaciones" })
          }
          onMouseLeave={() => this.setState({ detalleEdit: null })}
        >
          Observaciones: {detalle.vc_observaciones}
        </Label>
      ),
    };
    let descripcion = "";
    const isActive = this.isActive(detalle.dt_vigencia);
    if (isActive && detalle.id != null) {
      descripcion =
        `${this.dosisString(detalle)}` +
        `${this.viaAdministracionString(detalle)}` +
        `${this.frecuenciastring(detalle)}` +
        `${this.duracionString(detalle)}` +
        ", actualmente activo, el cual inició hace " +
        `${this.timeSince(new Date(), new Date(detalle.dt_inicio))}` +
        " y termina en " +
        `${this.timeSince(new Date(detalle.dt_vigencia), new Date())}.`;
    } else if (detalle.id == null) {
      descripcion =
        `${this.dosisString(detalle)}` +
        `${this.viaAdministracionString(detalle)}` +
        `${this.frecuenciastring(detalle)}` +
        `${this.duracionString(detalle)}` +
        ", medicamento agregado recientemente, aún no ha sido registrado.";
    } else {
      descripcion =
        `${this.dosisString(detalle)}` +
        `${this.viaAdministracionString(detalle)}` +
        `${this.frecuenciastring(detalle)}` +
        `${this.duracionString(detalle)}` +
        ", actualmente inactivo, el cual inició hace " +
        `${this.timeSince(new Date(), new Date(detalle.dt_inicio))}` +
        " y estuvo vigente durante " +
        `${this.timeSince(
          new Date(detalle.dt_vigencia),
          new Date(detalle.dt_inicio)
        )}.`;
    }

    const hover =
      index == this.state.detalle ||
      index == this.props.indexDetalleSeleccionado;
    const propsButtons = {
      style: {
        justifyContent: "center",
        margin: "0 5px",
        padding: "3px 9px",
        fontSize: "0.8125rem",
        height: "30px",
      },
      variant: window.isMobile ? "outlined" : hover ? "outlined" : null,
      // variant: window.isMobile ? 'outlined' : null,
      // variant: 'outlined',
      color: "inherit",
      size: "small",
    };
    return (
      <div
        style={{
          width: "100%",
          padding: 8,
          position: "relative",
          backgroundColor:
            index === this.props.indexDetalleSeleccionado &&
            this.props.index === this.props.indexSeleccionado &&
            isActive
              ? `rgba(200,200,200,0.2)`
              : null,
        }}
      >
        {
          !isActive || this.props.index !== this.props.indexSeleccionado ? (
            <Box p={1}>
              <Typography>
                {this.stringDescriptionMedicamentoReceta(detalle)}
              </Typography>
              <Typography>{detalle.vc_observaciones}</Typography>
              {(this.state.detalle == index || isMobile) &&
              getIndexLastDetalle(this.props.item.detalles, "dt_vigencia") ==
                index &&
              this.props.item.detalles.filter((e) => isDetalleActive(e))
                .length > 1 ? (
                <Icon
                  name="delete"
                  onClick={(e) => {
                    this.deleteItemDetalle(index);
                    e.stopPropagation();
                  }}
                  style={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    height: "100%",
                    verticalAlign: "center",
                    color:window.colors.red,
                  }}
                />
              ) : (
                ""
              )}
            </Box>
          ) : (
            <div>
              {items.dosis}
              {items.via}
              {items.frecuencia}
              {items.duracion}
              {items.observaciones}
              {(this.state.detalle == index || isMobile) &&
              getIndexLastDetalle(this.props.item.detalles, "dt_vigencia") ==
                index &&
              this.props.item.detalles.filter((e) => isDetalleActive(e))
                .length > 1 ? (
                <Icon
                  name="delete"
                  onClick={(e) => {
                    this.deleteItemDetalle(index);
                    e.stopPropagation();
                  }}
                  style={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    height: "100%",
                    verticalAlign: "center",
                    color:window.colors.red,
                  }}
                />
              ) : (
                ""
              )}
            </div>
          )
          // <div style={{ display: 'flex', flexDirection: 'row' }}>
          //     <Button key='dosis' onClick={() => { this.actionsMedicamento('detalleEdit', index) }} onMouseEnter={() => this.setState({ detalleEdit: 'dosis' })} onMouseLeave={() => this.setState({ detalleEdit: null })} {...propsButtons}>{this.dosisString(detalle)}</Button>
          //     <Button key='via' onClick={() => { this.actionsMedicamento('detalleEdit', index) }} onMouseEnter={() => this.setState({ detalleEdit: 'via' })} onMouseLeave={() => this.setState({ detalleEdit: null })} {...propsButtons}>{this.viaAdministracionString(detalle)}</Button>
          //     <Button key='nu_frecuencia' onClick={() => { this.actionsMedicamento('detalleEdit', index) }} onMouseEnter={() => this.setState({ detalleEdit: 'nu_frecuencia' })} onMouseLeave={() => this.setState({ detalleEdit: null })} {...propsButtons}>{this.frecuenciastring(detalle)}</Button>
          //     <Button key='nu_duracion' onClick={() => { this.actionsMedicamento('detalleEdit', index) }} onMouseEnter={() => this.setState({ detalleEdit: 'nu_duracion' })} onMouseLeave={() => this.setState({ detalleEdit: null })} {...propsButtons}>{this.duracionString(detalle)}</Button>
          //     <Button key='vc_observaciones' onClick={() => { this.actionsMedicamento('detalleEdit', index) }} onMouseEnter={() => this.setState({ detalleEdit: 'vc_observaciones' })} onMouseLeave={() => this.setState({ detalleEdit: null })} {...propsButtons}>Observaciones: {detalle.vc_observaciones}</Button>
          //     {this.state.detalle == index && (this.props.item.detalles.length - 1) == index && sn_base == 0 ? <Icon name='delete' onClick={() => this.deleteItemDetalle(index)} onMouseEnter={() => this.setState({ remove: true })} onMouseLeave={() => this.setState({ remove: false })} style={{ float: 'right', height: '100%', margin: 8, color:window.colors.red }} /> : ''}
          // </div>
        }
      </div>
    );
  }
  rangeString(detalle) {

  }
  stringDescriptionMedicamentoReceta(detalle) {
    if (
      this.isActive(detalle.dt_vigencia) &&
      detalle.id != null &&
      detalle.id_um_duracion != 34
    ) {
      return (
        `${this.dosisString(detalle)}` +
        `${this.viaAdministracionString(detalle)}` +
        `${this.frecuenciastring(detalle)}` +
        ` indicado ${this.duracionString(detalle)}` +
        " y termina en " +
        `${this.timeSince(new Date(detalle.dt_vigencia), new Date())} mas.`
      );
    } else if (this.isActive(detalle.dt_vigencia) && detalle.id != null) {
      return (
        `${this.dosisString(detalle)}` +
        `${this.viaAdministracionString(detalle)}` +
        `${this.frecuenciastring(detalle)}` +
        `${this.duracionString(detalle)}` +
        " indicado " +
        `el ${moment(detalle.dt_inicio).format("DD-MM-YYYY")}.`
      );
    } else if (detalle.id == null && detalle.id_um_duracion != 34) {
      return (
        `${this.dosisString(detalle)}` +
        `${this.viaAdministracionString(detalle)}` +
        `${this.frecuenciastring(detalle)}` +
        `${this.duracionString(detalle)} indicado ${
          moment(detalle.dt_inicio).format("YYYY-MM-DD") ==
          moment().format("YYYY-MM-DD")
            ? "hoy"
            : `el ${moment(detalle.dt_inicio).format("DD-MMM-YYYY")}`
        } hasta el ${moment(detalle.dt_vigencia).format("DD-MMM-YYYY")}.`
      );
    } else if (detalle.id == null) {
      return (
        `${this.dosisString(detalle)}` +
        `${this.viaAdministracionString(detalle)}` +
        `${this.frecuenciastring(detalle)}` +
        `${this.duracionString(detalle)} indicado ${
          moment(detalle.dt_inicio).format("YYYY-MM-DD") ==
          moment().format("YYYY-MM-DD")
            ? "hoy"
            : `el ${moment(detalle.dt_inicio).format("DD-MMM-YYYY")}`
        }.`
      );
    } else {
      return (
        `Actualmente suspendido - ${this.dosisString(detalle)}` +
        `${this.viaAdministracionString(detalle)}` +
        `${this.frecuenciastring(detalle)}` +
        `${this.duracionString(detalle)} indicado ${
          moment(detalle.dt_inicio).format("YYYY-MM-DD") ==
          moment().format("YYYY-MM-DD")
            ? "hoy"
            : `el ${moment(detalle.dt_inicio).format("DD-MMM-YYYY")}`
        }.`
      );
    }
  }
  colorDx(detalle) {
    if (detalle && this.isActive(detalle.dt_vigencia)) {
      if (detalle.id != null) {
        return window.colors.green;
      } else {
        return window.colors.itemSelected;
      }
    } else {
      return window.colors.red;
    }
  }
  getInputs(item) {
    const respuesta = item.respuesta ? item.respuesta : "";
    return [
      {
        type: "number",
        placeholder: respuesta,
        required: true,
        onChange: this.onChangeValue.bind(this),
        style: stylesTx.textField,
      },
    ];
  }

  deleteItemDetalle(index) {
    let medicamento = this.props.item;
    if (medicamento.detalles[index].id == null) {
      medicamento.detalles.splice(index, 1);
      this.props.callbackEditDetalle({
        medicamento,
        indexDetalleSeleccionado: medicamento.detalles.length - 1,
      });
    }
  }
  setVigencia(detalle) {
    const milisegundos =
      this.props.unidadesMedida.find(
        (e, index) => e.id == detalle.id_um_duracion
      ).nu_ratio *
      detalle.nu_duracion *
      60000;
    const timeInterval = moment(detalle.dt_vigencia) + milisegundos;
    const fecha = new Date(timeInterval);
    const dt_vigencia = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
    return dt_vigencia;
  }

  addDetalle() {
    let medicamento = this.props.item;
    const lastDetalle = getLastDetalle(medicamento.detalles, "dt_vigencia");
    const isActive = this.isActive(lastDetalle.dt_vigencia);
    if (lastDetalle.id == null) {
      medicamento.detalles.push({
        ...lastDetalle,
        id: null,
        id_tratamientoActual: null,
        dt_inicio: lastDetalle.dt_vigencia,
        dt_vigencia: this.setVigencia(lastDetalle),
      });
    } else {
      // medicamento.detalles.push({
      //     ...lastDetalle,
      //     id: null,
      //     id_tratamientoActual: null,
      // });
      const detalle = !isActive
        ? // this.newDetalle(medicamento.id_medicamento)
          {
            ...lastDetalle,
            id: null,
            id_tratamientoActual: null,
            dt_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            dt_vigencia: this.setVigencia({
              ...lastDetalle,
              dt_vigencia: moment().format("YYYY-MM-DD HH:mm:ss"),
            }),
          }
        : {
            ...lastDetalle,
            id: null,
            id_tratamientoActual: null,
            dt_inicio: lastDetalle.dt_vigencia,
            dt_vigencia: this.setVigencia(lastDetalle),
          };

      medicamento.detalles.push(detalle);
    }
    this.props.callbackEditDetalle({
      medicamento,
      indexDetalleSeleccionado: medicamento.detalles.length - 1,
    });
  }

  newDetalle(id_medicamento) {
    const {
      medicamentosSustanciasActivas,
      medicamentosThink,
      unidadesMedida,
    } = this.props;

    const sustanciaActiva = this.props.medicamentosSustanciasActivas.find(
      (v) => v.id_medicamento === id_medicamento
    );
    const medicamento = this.props.medicamentosThink.find(
      (v) => v.id == id_medicamento
    );
    let nu_vehiculos = null;
    let nu_dosis = null;
    let id_um_dosis = null;
    let dt_vigencia = null;
    let id_viaAdministracion = null;
    let id_vehiculo = null;

    if (medicamento && sustanciaActiva && sustanciaActiva.nu_dosisDiaria) {
      const nu_unidadesDiarias =
        sustanciaActiva.nu_dosisDiaria / sustanciaActiva.nu_concentracion;
      if (nu_unidadesDiarias && this.tomasDia(medicamento) != null) {
        const tomasDia = this.tomasDia(medicamento);
        nu_vehiculos = nu_unidadesDiarias / tomasDia;
        nu_dosis = sustanciaActiva.nu_dosisDiaria / tomasDia;
        id_um_dosis = sustanciaActiva.id_um_concentracion;
      }
    }

    if (
      medicamento &&
      medicamento.nu_duracion != null &&
      medicamento.id_um_duracion != null
    ) {
      const um_duracion = this.props.unidadesMedida.find(
        (v) => v.id == medicamento.id_um_duracion
      );
      if (um_duracion) {
        const nu_duracionMilisegundos =
          um_duracion.nu_ratio * medicamento.nu_duracion * 60000;
        const refDate = moment();
        const interval = refDate + nu_duracionMilisegundos;
        dt_vigencia = new Date(interval);
      }
    }

    id_viaAdministracion =
      medicamento && medicamento.id_viaAdministracion
        ? medicamento.id_viaAdministracion
        : 34;
    id_vehiculo =
      medicamento && medicamento.id_vehiculo ? medicamento.id_vehiculo : 28;

    return {
      id: null,
      id_institucion: window.amplify.store("institution").id_institucion,
      id_tratamientoOrigen: null,
      id_tratamientoActual: null,
      id_tratamientoMedicamento: null,
      id_usuario: window.amplify.store("user_id"),
      id_estatus: 1,
      id_um_dosis: id_um_dosis,
      id_um_duracion:
        medicamento && medicamento.id_um_duracion
          ? medicamento.id_um_duracion
          : null,
      id_um_frecuencia:
        medicamento && medicamento.id_um_frecuencia
          ? medicamento.id_um_frecuencia
          : null,
      id_um_intervalo:
        medicamento && medicamento.id_um_intervalo
          ? medicamento.id_um_intervalo
          : null,
      id_conciliacion: 1,
      administraciones: [
        {
          nu_dosis: nu_dosis,
          nu_vehiculos: nu_vehiculos,
          vc_dosis: null,
          tm_toma: null,
        },
      ],
      id_vehiculo: id_vehiculo,
      id_viaAdministracion: id_viaAdministracion,
      nu_duracion:
        medicamento && medicamento.nu_duracion ? medicamento.nu_duracion : null,
      nu_frecuencia:
        medicamento && medicamento.nu_frecuencia
          ? medicamento.nu_frecuencia
          : null,
      nu_intervalo:
        medicamento && medicamento.nu_intervalo
          ? medicamento.nu_intervalo
          : null,
      dt_vigencia: dt_vigencia,
      dt_inicio: new Date(),
      tm_inicio: null,
      vc_observaciones: "",
      sn_activo: 1,
      dt_registro: new Date(),
    };
  }

  tomasDia(medicamento) {
    const um_frecuencia = this.props.unidadesMedida.find(
      (v) => v.id == medicamento.id_um_frecuencia
    );

    if (um_frecuencia) {
      const nu_frecHoras =
        (um_frecuencia.nu_ratio * medicamento.nu_frecuencia) / 60;
      if (nu_frecHoras <= 24) {
        return 24 / nu_frecHoras;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  }
}

const stylesTx = {
  container: {
    width: "100%",
    minHeight: 100,
    margin: 0,
    marginBottom: 10,
    padding: 0,
  },
  subContainer: {
    width: "100%",
    margin: 0,
    marginBottom: 5,
    padding: 0,
    minHeight: 90,
    borderRadius: 4,
    backgroundColor:window.colors.itemSelected,
    boxShadow: "1px 0px 10px rgba(0, 0,0, 0.7)",
  },
  title: {
    width: "59%",
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    borderTopLeftRadius: 4,
    margin: 0,
    padding: 5,
    paddingLeft: 8,
    paddingRight: 8,
    color:window.colors.white,
  },
  titlePrint: {
    width: "100%",
    marginTop: 10,
    marginRight: 0,
    paddingLeft: 8,
    paddingRight: 8,
    color: "black",
    fontSize: 16,
  },
  subTitlePrint: {
    width: "100%",
    marginTop: 0,
    marginRight: 0,
    paddingLeft: 20,
    paddingRight: 8,
    color: "black",
  },
  subTitle: {
    width: "99%",
    backgroundColor: "rgba(250,250,250,0.96)",
    marginRight: 0,
    marginTop: 1,
    marginBottom: 0,
    padding: 0,
    border: 0,
    borderBottomLeftRadius: 4,
    color: "black",
    textAlign: "justify",
    fontSize: 15,
  },
  textArea: {
    width: "99%",
    height: "100%",
    color: "gray",
    padding: 8,
    fontSize: 12,
    borderRadius: 0,
    border: 0,
    margin: 0,
    marginTop: 1,
    borderBottomLeftRadius: 4,
    height: 58,
    maxHeight: 58,
    maxWidth: "99%",
    minWidth: "99%",
    textAlign: "justify",
    resize: "none",
  },
};

const mapStateToProps = (state) => {
  const {
    medicamentosDgis,
    unidadesMedida,
    viasAdministracion,
    vehiculos,
    medicamentosSustanciasActivas,
    medicamentosThink,
  } = state.Think;
  return {
    medicamentosDgis,
    unidadesMedida,
    viasAdministracion,
    vehiculos,
    medicamentosSustanciasActivas,
    medicamentosThink,
  };
};

export default connect(mapStateToProps)(TratamientosItem);

import internalAPI from "../../../utils/internalAPI";
import notifications from "../../../utils/notifications";
import { logOut } from "../../../containers/Think/actions";
import moment from "moment";
import _ from "lodash";

export default {
  getPatients(texto) {
    const { id_institucion = 0 } = window.amplify.store("institution") || {};

    return new Promise((resolve, reject) => {
      const body = {
        id_institucion,
        texto,
      };

      internalAPI.searchPatient(body, (error, response) => {
        if (error) reject(error.error);
        else resolve(response);
      });
    });
  },
  getExpediente(paciente, sn_atencion = false) {
    const id_paciente = paciente.id;
    const datos = [
      "sn_pacienteRespuestas",
      "sn_diagnosticos",
      "sn_procedimientos",
      "sn_medicamentos",
      "sn_solicitudes",
      "sn_respuestas",
      "sn_signosAlarma",
      "sn_atenciones",
      "sn_notas",
    ];
    const body = {
      id_paciente,
      id_rol: 5,
      ...datos.reduce((obj, c) => ({ ...obj, [c]: true }), {}),
      id_tipoNota: 4,
      // sn_atencion,
    };
    return new Promise((resolve, reject) => {
      internalAPI.getExpediente(body, (err, response) => {
        if (err) {
          reject(err);
        } else {
          const data = response.data;
          const { paciente: px, preguntas } = data;
          const preguntasSexo = preguntas.filter(
            (v) => v.id_sexo === 3 || px.id_sexo === v.id_sexo
          );
          const preguntasNumericas = preguntasSexo.filter(
            (v) => v.id_tipoPregunta !== 4
          );
          const preguntasTexto = preguntasSexo.filter(
            (v) => v.id_tipoPregunta === 4
          );

          resolve({
            preguntas,
            paciente: {
              detalle: px,
              citaActual: null,
              tratamientosPaciente: data.tratamientos,
              medicamentosPaciente: data.medicamentos,
              diagnosticosPaciente: data.diagnosticos,
              procedimientosPaciente: data.procedimientos,
              solicitudes: data.solicitudes,
              signosAlarma: data.signosAlarma,
              pacienteRespuestas: data.pacientesRespuestas,
              atenciones: data.atenciones,
              notas: data.notas,
              preguntasNumericas,
              preguntasTexto,
            },
          });
        }
      });
    });
  },
  getExpedienteFormulario(paciente, formulario) {
    const id_paciente = paciente.id;
    const datos = [
      "sn_pacienteRespuestas",
      "sn_diagnosticos",
      "sn_procedimientos",
      "sn_medicamentos",
      "sn_solicitudes",
      "sn_respuestas",
      "sn_signosAlarma",
      "sn_atenciones",
      "sn_notas",
    ];
    const body = {
      id_paciente,
      id_rol: 5,
      ...datos.reduce((obj, c) => ({ ...obj, [c]: true }), {}),
      id_tipoNota: formulario.id_tipoNota || 4,
    };

    return new Promise((resolve, reject) => {
      internalAPI.getExpediente(body, (err, response) => {
        if (err) {
          reject(err);
        } else {
          const data = response.data;
          const { paciente: px, preguntas } = data;

          let preguntasBase = _.cloneDeep(formulario.preguntas);
          if (formulario.preguntasBase) {
            preguntasBase = [
              ...preguntas,
              ...formulario.preguntas.filter(
                (v) => !preguntas.some((p) => p.id === v.id)
              ),
            ];
          }

          const preguntasSexo = preguntasBase.filter(
            (v) => v.id_sexo === 3 || px.id_sexo === v.id_sexo
          );
          const preguntasNumericas = preguntasSexo.filter(
            (v) => v.id_tipoPregunta !== 4
          );
          const preguntasTexto = preguntasSexo.filter(
            (v) => v.id_tipoPregunta === 4
          );

          console.log("formulario", formulario);
          console.log("preguntasNumericas", preguntasNumericas);
          console.log("preguntasTexto", preguntasTexto);

          resolve({
            preguntas,
            paciente: {
              detalle: px,
              citaActual: null,
              tratamientosPaciente: data.tratamientos,
              medicamentosPaciente: data.medicamentos,
              diagnosticosPaciente: data.diagnosticos,
              procedimientosPaciente: data.procedimientos,
              solicitudes: data.solicitudes,
              signosAlarma: data.signosAlarma,
              pacienteRespuestas: data.pacientesRespuestas,
              atenciones: data.atenciones,
              notas: data.notas,
              preguntasNumericas,
              preguntasTexto,
            },
          });
        }
      });
    });
  },
};

import React, { useCallback, useEffect, useState } from "react";
import CuentaDialog from "app/sales/components/Cuentas/CuentaDialog";

export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const [cuenta, setCuenta] = useState(null);

  const onOpen = (cuenta = {}) => {
    if (!cuenta) return;

    setCuenta(cuenta);
    setIsOpen(true);
  };

  const Dialog = useCallback(
    () => (
      <CuentaDialog
        open={isOpen}
        cuenta={cuenta}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    ),
    [isOpen, cuenta]
  );

  return {
    CuentaDialog: Dialog,
    onOpen,
  };
};

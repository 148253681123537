import React from "react";

import {
  Box,
  Popover,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Search, PersonAddOutlined } from "@material-ui/icons";
import Input from "app/components/Form/Input";
import RegistrarPaciente from "app/components/Paciente/RegistroPaciente";
import internalAPI from "app/utils/internalAPI";
import ItemPatient from "./ItemPatient";
import PatientsView from "app/containers/Think/views/patients/PatientsView";
import { omit } from "lodash";

const searchPatient = (texto) => {
  const body = {
    id_institucion: window.amplify.store("institution").id_institucion,
    texto,
  };

  return new Promise((resolve, reject) => {
    internalAPI.searchPatient(body, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default ({ onClick, addPatient = true, ...props }) => {
  const [openNewPatient, setOpenNewPatient] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const fetchData = async () => {
    const res = await searchPatient(inputValue);
    setLoading(false);
    setData(res);
  };

  React.useEffect(() => {
    setLoading(!!inputValue);
    const timeout = setTimeout(async () => {
      if (inputValue) {
        await fetchData();
      } else {
        setData([]);
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  React.useEffect(() => {
    onClick(value);
  }, [value]);

  return [
    <Autocomplete
      filterOptions={(x) => x}
      options={inputValue && !data.length ? [{ loading: true }] : data}
      autoComplete
      fullWidth
      includeInputInList
      filterSelectedOptions
      openOnFocus={false}
      freeSolo
      value={value}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : `${option.vc_nombre || ""} ${option.vc_apellidoPaterno || ""} ${
              option.vc_apellidoMaterno || ""
            }`
      }
      onChange={(event, newValue) => {
        if (typeof newValue === "string") return;
        if ("loading" in newValue) return;

        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      style={{ margin: 0 }}
      renderInput={(params) => (
        <Input
          inputRef={params.InputProps.ref}
          inputProps={omit(params.inputProps, ["className"])}
          getEvent
          autoFocus
          placeholder={"Buscar paciente"}
          // startAdornment={
          //   <Box display='flex' alignItems='center'>
          //     {addPatient && (
          //       <IconButton
          //         color='primary'
          //         onClick={() => setOpenNewPatient(true)}
          //       >
          //         <PersonAddOutlined />
          //       </IconButton>
          //     )}
          //     <Search style={{ marginRight: 8 }} />
          //   </Box>
          // }
          endAdornment={
            <Box display="flex" alignItems="center">
              {loading && (
                <CircularProgress
                  size={20}
                  color="white"
                  style={{ marginRight: 8 }}
                />
              )}
              {addPatient && !loading && (
                <IconButton
                  color="inherit"
                  onClick={() => setOpenNewPatient(true)}
                  style={{ marginRight: 8 }}
                >
                  <PersonAddOutlined />
                </IconButton>
              )}
            </Box>
          }
        />
      )}
      renderOption={(data, option) => <ItemPatient data={data} dense />}
    />,
    <RegistrarPaciente
      init="form"
      open={openNewPatient}
      close={() => setOpenNewPatient(false)}
      finish={(patient) => {
        setValue(patient);
      }}
    />,
    // <Dialog
    //   open={openNewPatient}
    //   onClose={() => setOpenNewPatient(false)}
    //   TransitionComponent={Transition}
    //   aria-labelledby="alert-dialog-slide-title"
    //   aria-describedby="alert-dialog-slide-description"
    //   fullWidth
    // >
    //   <Box height={600} maxHeight={800}>
    //     <PatientsView
    //       modalMode
    //       opcionActualModulo="nuevo"
    //       notRefreshSidebar
    //       saveEditPatient={(patient) => {
    //         setValue(patient);
    //         setOpenNewPatient(false);
    //       }}
    //     />
    //   </Box>
    // </Dialog>,
  ];
};

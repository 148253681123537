import authEndPoints from "./authEndPoints";
import imgEndPoints from "./imgEndPoints";
import diaryEndPoints from "./diaryEndPoints";
import patientEndPoints from "./patientEndPoints";
import notasEndPoints from "./notasEndPoint";
import statisticsEndPoints from "./statisticsEndPoints";
import resourcesEndPoints from "./resourcesEndPoints";
import updateEndPoints from "./updateEndPoints";
import institutionsEndPoints from "./institutionsEndPoints";
import usersEndPoints from "./usersEndPoints";
import localizacionEndPoints from "./localizacionEndPoints";
import miscAdminEndPoints from "./miscAdminEndPoints";
import renovacionesEndPoints from "./renovacionesEndPoints";
import agendasEndPoints from "./agendasEndPoints";
import generalEndPoints from "./generalEndPoints";
import catalogsEndPoints from "./catalogsEndPoints";
import openPayEndPoints from "./openPayEndPoints";
import externalEndPoints from "./externalEndPoints";
import settingsEndPoints from "./settingsEndPoints";
import hospitalizacion from "./hospitalizacionEndPoints";
import erpEndPoints from "./erpEndPoints";
import reportes from "./reportesEndPoints";

export default {
  ...openPayEndPoints,
  ...authEndPoints,
  ...imgEndPoints,
  ...diaryEndPoints,
  ...catalogsEndPoints,
  ...patientEndPoints,
  ...notasEndPoints,
  ...resourcesEndPoints,
  ...updateEndPoints,
  ...institutionsEndPoints,
  ...usersEndPoints,
  ...localizacionEndPoints,
  ...miscAdminEndPoints,
  ...renovacionesEndPoints,
  ...generalEndPoints,
  ...statisticsEndPoints,
  ...externalEndPoints,
  erp: erpEndPoints,
  agendasEndPoints,
  settingsEndPoints,
  hospitalizacion,
  reportes,
};

export default {
    vc_name: 'Cajas',
    vc_path: 'cajas',
    fields: [
        {
            "type": "number",
            "vc_name": "Id",
            "vc_icon": 'FormatListNumberedOutlined',
            "key": "id",
            "readOnly": true,
            "nullify": true,
            "component": "textField",
            "filter": true
        },
        {
            "type": "number",
            "vc_name": "Dependencia",
            "key": "id_dependencia",
            "id_icon": "ApartmentOutlined",
            "component": "select",
            "fullWidth": true,
            "relation": {
                "id_type": 2,
                "vc_nameTable": "ins_dependencias",
                "vc_nameReducer": "dependencias",
                "key": "id_dependencia",
                "get": {
                    "backEndURL": "https://api.thin-k.mx",
                    "method": "GET",
                    "vc_url": "/app/api/institucion/localizacion/buscar",
                    "searchKey": "text",
                    "parameters": [
                        {
                            "key": "id_institucion",
                            "type": "localStorageValue",
                            "basePath": "institution",
                            "paths": [
                                "id_institucion"
                            ]
                        },
                        {
                            "key": "tipo",
                            "type": "fixed",
                            "value": "dependencia"
                        }
                    ]
                },
                "keyTable": "id",
                "fieldsDisplay": [
                    "id",
                    "vc_nombre",
                ],
                "fieldsDisplaySeparators": [
                    " - "
                ],
                "rules": []
            }
        },
        {
            "type": "number",
            "vc_name": "Area",
            "key": "id_area",
            "id_icon": "EditLocationOutlined",
            "component": "select",
            "nullify": true,
            "fullWidth": true,
            "relation": {
                "id_type": 2,
                "vc_nameTable": "ins_areas",
                "vc_nameReducer": "areas",
                "key": "id_area",
                "get": {
                    "backEndURL": "https://api.thin-k.mx",
                    "method": "GET",
                    "vc_url": "/app/api/institucion/localizacion/buscar",
                    "searchKey": "text",
                    "parameters": [
                        {
                            "key": "id_institucion",
                            "type": "localStorageValue",
                            "basePath": "institution",
                            "paths": [
                                "id_institucion"
                            ]
                        },
                        {
                            "key": "id_institucion",
                            "type": "localStorageValue",
                            "basePath": "institution",
                            "paths": [
                                "id_institucion"
                            ]
                        },
                        {
                            "key": "id_dependencia",
                            "type": "fieldValue",
                            "field": "id_dependencia"
                        },
                        {
                            "key": "tipo",
                            "type": "fixed",
                            "value": "area"
                        }
                    ]
                },
                "keyTable": "id",
                "fieldsDisplay": [
                    "id",
                    "vc_nombre",
                ],
                "fieldsDisplaySeparators": [
                    " - "
                ],
                "rules": []
            }
        },
        {
            "type": "text",
            "vc_icon": 'ShortTextOutlined',
            "vc_name": "Nombre",
            "textTransform": "uppercase",
            "key": "vc_nombre",
            "component": "textField",
            "filter": true
        }
    ]
};
import React, { Component } from 'react';
import { Grid, Modal, Button } from 'semantic-ui-react';

const { Row, Column } = Grid;

class PatientConsultModal extends Component {
  render() {
    const { visible, close, consult } = this.props;

    return (
      <Modal visible={visible} onClose={() => { close(); }}>
        <Grid>
          <Row>
            <Column textAlign='center'>
              <h3>¿Desea iniciar la consulta?</h3>
              <Button color='blue' onClick={() => { consult(); }}>Aceptar</Button>
              <Button color='red' onClick={() => { close(); }}>Cancelar</Button>
            </Column>
          </Row>
        </Grid>
      </Modal>
    );
  }
}

export default PatientConsultModal;

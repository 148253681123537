import React, { Component } from 'react';
import PropTypes from 'prop-types';
const INITIAL_VALUE = 0;
const FINAL_VALUE = 1;

class FadeInView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opacity: INITIAL_VALUE,
    };
  }

  componentDidMount() {
    // const delay = this.props.delay || 0;
    // const params = {
    //   initialValue: INITIAL_VALUE,
    //   finalValue: FINAL_VALUE,
    //   duration: this.props.duration || 500,
    // };

    // setTimeout(() => {
    //   transitions.easePolyOut(params, (response) => {
    //     if (!this.unmounted) {
    //       this.setState({ opacity: response.value });
    //     }
    //   });
    // }, delay);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }


  // ------------------------------
  // ------- render methods -------
  // ------------------------------
  render() {
    const { opacity } = this.state;

    return (
      <div style={{ opacity: 1, width: '100%', ...this.props.style }}>
        { this.props.children }
      </div>
    );
  }
}


FadeInView.propTypes = {
  delay: PropTypes.number,
  duration: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.any,
};


export { FadeInView };

import http from '../http';
import moment from 'moment';

export default {
    getMedicamentosPLM(body, callback) {
        const headers = {
            'Referer': 'https://app.thin-k.mx',
            'origin': 'www.medicamentosplm.com',
            'authority': 'www.medicamentosplm.com',
            'method': 'GET',
            'path': '/RestPharmaSearchEngine/Medicamentos / actron',
            'scheme': 'https',
            'accept': 'text / html, application / xhtml + xml, application / xml; q = 0.9, image / webp, image / apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
            'accept-language': 'es-419,es;q=0.9',
            'upgrade-insecure-requests': 1
        };
        window.$.ajax({
            url: `https://www.medicamentosplm.com/RestPharmaSearchEngine/Medicamentos/${body.searchText}`,
            method: 'GET',
            headers
        }).then((data) => {
            callback(null, data)
        }).fail((data, status, xhr, error) => {
            callback(error, [])
        });
    },
    getPatientsCovid19(body, callback) {
        const headers = {
            Authorization: window.amplify.store('token')
        };
        window.$.ajax({
            url: 'http://localhost:8080/api/v1/pacientes/covid',
            headers,
            method: 'GET'
        }).then((data) => {
            callback(null, data)
        }).fail((data, status, xhr, error) => {
            callback(error, [])
        });
    },
    patientsCallcenter(dt_inicio, dt_fin, callback) {

        const operadores = [
            { id: 1, vc_nombre: 'Jurisdicción I', municipios: ['AHOME', 'EL FUERTE', 'CHOIX'] },
            { id: 2, vc_nombre: 'Jurisdicción II', municipios: ['GUASAVE', 'SINALOA'] },
            { id: 3, vc_nombre: 'Jurisdicción III', municipios: ['ANGOSTURA', 'SALVADOR ALVARADO', 'MOCORITO'] },
            { id: 4, vc_nombre: 'Jurisdicción IV', municipios: ['BADIRAGUATO', 'CULIACAN', 'NAVOLATO', 'ELOTA', 'COSALA'] },
            { id: 5, vc_nombre: 'Jurisdicción VI', municipios: ['SAN IGNACIO', 'MAZATLAN', 'CONCORDIA', 'EL ROSARIO', 'ESCUINAPA'] },
            { id: 6, vc_nombre: 'IMSS' },
            { id: 7, vc_nombre: 'ISSSTE' },
            { id: 8, vc_nombre: 'SEDENA' },
            { id: 9, vc_nombre: 'SEMAR' },
            { id: 10, vc_nombre: 'PEMEX' }
        ]

        const gravedadCovid = (call) => {
            let score = 0;
            score = score + (call.Fiebre === 'SI' ? 1 : 0);
            score = score + (call.DolorCabeza === 'SI' ? 1 : 0);
            score = score + (call.Tos === 'SI' ? 1 : 0);

            switch (true) {
                case ((score >= 2) && call.DificultadRespirar === 'SI'): {
                    return 1;
                }
                default: {
                    return 0;
                }
            }
        }
        const classCovid = (call) => {
            let score = 0;
            score = score + (call.Fiebre === 'SI' ? 1 : 0);
            score = score + (call.DolorCabeza === 'SI' ? 1 : 0);
            score = score + (call.Tos === 'SI' ? 1 : 0);
            let softScore = 0;
            softScore = softScore + (call.Fiebre === 'SI' ? 1 : 0);
            softScore = softScore + (call.DolorCabeza === 'SI' ? 1 : 0);
            softScore = softScore + (call.Tos === 'SI' ? 1 : 0);
            softScore = softScore + (call.DolorMuscular === 'SI' ? 1 : 0);
            softScore = softScore + (call.DolorGarganta === 'SI' ? 1 : 0);
            softScore = softScore + (call.DolorPecho === 'SI' ? 1 : 0);
            softScore = softScore + (call.DolorOjos === 'SI' ? 1 : 0);

            switch (true) {
                case ((score >= 2) && call.DificultadRespirar === 'SI'): {
                    return 5;
                }
                case ((score >= 2) && call.DificultadRespirar === 'NO'): {
                    return 4;
                }
                case (call.DificultadRespirar === 'SI'): {
                    return 3;
                }
                case ((softScore > 0) && call.DificultadRespirar === 'NO'): {
                    return 2;
                }
                default: {
                    return 1;
                }
            }
        }
        const asignacion = (call) => {
            const dh = call.Derechohabiencia || '';
            switch (true) {
                case (dh.includes('IMSS')): {
                    return 6;
                }
                case (dh.includes('ISSSTE')): {
                    return 7;
                }
                case (dh.includes('SEDENA')): {
                    return 8;
                }
                case (dh.includes('SEMAR')): {
                    return 9;
                }
                case (dh.includes('PEMEX')): {
                    return 10;
                }
                case ((call.Municipio || '').includes('CULIACAN') || (call.Municipio || '').includes('BADIRAGUATO') || (call.Municipio || '').includes('NAVOLATO') || (call.Municipio || '').includes('COSALA') || (call.Municipio || '').includes('ELOTA')): {
                    return 4;
                }
                case ((call.Municipio || '').includes('SAN IGNACIO') || (call.Municipio || '').includes('MAZATLAN') || (call.Municipio || '').includes('CONCORDIA') || (call.Municipio || '').includes('EL ROSARIO') || (call.Municipio || '').includes('ESCUINAPA')): {
                    return 5;
                }
                case ((call.Municipio || '').includes('AHOME') || (call.Municipio || '').includes('EL FUERTE') || (call.Municipio || '').includes('CHOIX')): {
                    return 1;
                }
                case ((call.Municipio || '').includes('GUASAVE') || (call.Municipio || '').includes('SINALOA')): {
                    return 2;
                }
                case ((call.Municipio || '').includes('ANGOSTURA') || (call.Municipio || '').includes('SALVADOR ALVARADO') || (call.Municipio || '').includes('MOCORITO')): {
                    return 3;
                }
            }
        }
        return new Promise((resolve, reject) => {
            const headers = {
                Authorization: window.amplify.store('token')
            };
            const body = {
                "servidor": "servidor1",
                "valida": "1",
                "fechainicial": dt_inicio ? moment(dt_inicio).format('YYYYMMDD') : "20200101",
                "fechafinal": dt_fin ? moment(dt_fin).format('YYYYMMDD') : moment().format('YYYYMMDD')
            }
            
            window.$.ajax({
                url: 'http://localhost:8080/api/v1/pacientes/callcenter',
                data: body,
                headers,
                method: 'POST'
            }).then((data, status, xhr, error) => {
                if (data.length) {
                    console.log(data)
                    const calls = data.map((call, index) => {
                        const dt_nacimientoVal = moment(call.FechaNacimiento, 'DD/MM/YYYY hh:mm:ss A').isValid()
                        return {
                            id: call.IdPaciente,
                            detalle: {
                                vc_nombre: call.Nombre,
                                vc_apellidoPaterno: call.ApellidoPaterno,
                                vc_apellidoMaterno: call.ApellidoMaterno,
                                dt_registro: moment(call.FechaRegistro, 'DD/MM/YYYY hh:mm:ss A').format('YYYY-MM-DD HH:mm:ss') || null,
                                dt_nacimiento: dt_nacimientoVal ? moment(call.FechaNacimiento, 'DD/MM/YYYY hh:mm:ss A').format('YYYY-MM-DD HH:mm:ss') : null,
                                nu_edad: call.Edad || null,
                                id_sexo: call.Sexo === 'M' ? 1 : 2,
                                vc_email: call.Email || null,
                                vc_curp: call.Curp || null,
                                vc_telefono: call.Telefono,
                                vc_domicilioDescripcion: call.Domicilio,
                                id_status: classCovid(call),
                                sn_irag: gravedadCovid(call),
                                id_responsable: asignacion(call),
                                id_statusSeguimiento: 1
                            }
                        }
                    })
                    callback(null, calls)
                } else {
                    console.log('data', []);
                }

            }).fail((data, status, xhr, error) => {
                callback(error, {})
            });
        })
    }
};

import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Button, TextField, Paper, Modal, Grid, Box, Typography } from '@material-ui/core'
import Draggable from 'react-draggable'
import internalAPI from '../../utils/internalAPI';
import CryptoJS from 'crypto-js';
import CallFrame from './CallFrame';
import MenuCall from './MenuCall';

const VideoCall = (props) => {
    const { setHiddeVideoCall, pacienteActual } = props;
    const user = (window.amplify.store('user') || {}).usuario || { vc_nombre: 'No disponible' }
    let vc_email = null;
    let password = null;
    let roomName = null;
    
    if (pacienteActual && pacienteActual.detalle) {
        password = pacienteActual.detalle.vc_apellidoMaterno || ''
        vc_email = pacienteActual.detalle.vc_email || '';
        roomName = props.roomName || CryptoJS.MD5(`${pacienteActual.detalle.vc_nombre} ${pacienteActual.detalle.vc_apellidoPaterno} ${pacienteActual.detalle.vc_apellidoMaterno}`, pacienteActual.detalle.vc_apellidoPaterno).toString().slice(0, 20);
    }
    const [displayName, setDisplayName] = useState(`${user.vc_nombre}`)
    const pd = props.pacienteActual && props.pacienteActual.detalle ? props.pacienteActual.detalle : null;
    const [pacienteActualDetalle, setPacienteActualDetalle] = useState(pd)
    const [email, setEmail] = useState(vc_email || '')
    const [onCall, setOnCall] = useState(true)
    const [api, setApi] = useState(null)
    React.useEffect(() => {
        const pacienteActualDetalleProps = props.pacienteActual && props.pacienteActual.detalle ? props.pacienteActual.detalle : null;
        if (pacienteActualDetalle !== pacienteActualDetalleProps && pacienteActualDetalleProps) {
            if (onCall) {
                setOnCall(false);
                setPacienteActualDetalle(pacienteActualDetalleProps);
                setOnCall(true);
            }
        } else if (!pacienteActualDetalleProps) {
            setOnCall(false);
            props.setHiddeVideoCall && props.setHiddeVideoCall()
        }
    }, [props])
    if (pacienteActualDetalle) {
        roomName = (pacienteActualDetalle.vc_nombre + '-' + CryptoJS.MD5(`${pacienteActualDetalle.vc_nombre} ${pacienteActualDetalle.vc_apellidoPaterno} ${pacienteActualDetalle.vc_apellidoMaterno}`, pacienteActualDetalle.vc_apellidoPaterno).toString().slice(0, 25)).replace(' ', '_');
    }
    console.log('roomname', roomName)
    return (
        <Paper className="box no-cursor" style={{ margin: 0, height: '100%', width: '100%', borderRadius: 0, boxShadow: '0px 0px 10px rgba(0,0,0,0.4)', overflow: 'hidden', outline: 'none !important', position: 'absolute', bottom: 0, left: 0, zIndex: 10000 }}>
            <div style={{ width: '100%', height: '100%' }}>
                {
                    roomName ?
                        <Grid container style={{ height: '100%' }}>
                            <CallFrame
                                {...{ setApi, roomName, api, onCall, setOnCall, displayName, setHiddeVideoCall, password }}
                            />
                            {
                                props.pacienteActual &&
                                <div style={{ position: 'absolute', left: 8, top: 8 }}>
                                    <MenuCall
                                        roomName={roomName}
                                        api={api} />
                                </div>
                            }
                        </Grid>
                        :
                        <Grid container>
                            <Typography variant='h5'>
                                {'Video no disponible'}
                            </Typography>
                        </Grid>
                }
            </div>
            <div style={{ position: 'absolute', right: 8, top: 8, zIndex: 1000 }}>
                <Button onClick={() => {
                    api && api.dispose()
                    api && props.setHiddeVideoCall && props.setHiddeVideoCall()
                }}>
                    {
                        onCall ?
                            'Salir' : 'salir'
                    }
                </Button>
            </div>
        </Paper>
    )
}

const mapStateToProps = (state) => {
    const { pacienteActual } = state.Think;
    return { pacienteActual };
};
export default connect(mapStateToProps, null)(VideoCall);

import React from "react";

export default () => {
  const [rowSelected, setRowSelected] = React.useState({
    index: -1,
    row: null,
  });

  const onSelect = (row, index) => {
    const newRowSelected = { index: -1, row: null };

    if (rowSelected.index !== index) {
      newRowSelected.index = index;
      newRowSelected.row = row;
    }

    setRowSelected(newRowSelected);
  };

  return {
    rowSelected,
    onSelect,
  };
};

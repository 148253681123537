import { SET_STATE } from '../actions/types';


export default (state, INITIAL_STATE, action) => {
    switch (action.type) {
		case SET_STATE:
			const prevState = state[action.payload.modulo]
			if (action.payload.state === null) {
				return { ...state, [action.payload.modulo]: null };
			}
			return { ...state, [action.payload.modulo]: { ...prevState, ...action.payload.state } };
		default:
			return null;
	}
};

import React from 'react';
import { connect } from 'react-redux';
import { Paper, Box, Typography, ListItem, Divider, Input, IconButton, AppBar, Toolbar, TextField, Grid, Button, Fab } from '@material-ui/core';
import Search from "@material-ui/icons/Search";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Map from "@material-ui/icons/Map";
import Clear from "@material-ui/icons/Clear";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Backspace from "@material-ui/icons/Backspace";
import SubdirectoryArrowLeft from "@material-ui/icons/SubdirectoryArrowLeft";
import NotificationImportantOutlined from "@material-ui/icons/NotificationImportantOutlined";
import Check from "@material-ui/icons/Check";
import Redo from "@material-ui/icons/Redo";
// import TextField from '../TextField';
import moment from 'moment';
import { setPacienteActual } from '../../containers/Think/actions';
import ThinkNumberKeyboard from '../ThinkNumberKeyboard';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DateSelectPicker from '../DateSelectPicker';
import Switcher from '../ThinkForm/Switcher';
import repository from '../../utils/repository';
// import GoogleMaps from '../../containers/Think/views/patients/GoogleMaps';
import SpeechButton from '../SpeechButton';
const sidebarWidth = window.sidebarWidth;
const isMobile = window.isMobile;
const colors = window.colors;

class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            searchText: '',
            searchTextPrev: '',
            speechPreview: '',
            cve_respuestaBase: '',
            preguntaActual: null,
            respuestasSeleccionadas: [],
            preguntasPendientes: [],
            heightSideBar: sidebarWidth * 0.8,
            showMap: false,
            countries: [
                { vc_nombre: 'china', nu_muertes: 3000, nu_confirmados: 70000 },
                { vc_nombre: 'italia', nu_muertes: 3000, nu_confirmados: 7000 },
                { vc_nombre: 'estados unidos', nu_muertes: 3000, nu_confirmados: 70000 },
                { vc_nombre: 'china', nu_muertes: 3000, nu_confirmados: 70000 },
                { vc_nombre: 'china', nu_muertes: 3000, nu_confirmados: 70000 },
                { vc_nombre: 'china', nu_muertes: 3000, nu_confirmados: 70000 },
                { vc_nombre: 'china', nu_muertes: 3000, nu_confirmados: 70000 },
                { vc_nombre: 'china', nu_muertes: 3000, nu_confirmados: 70000 },
                { vc_nombre: 'china', nu_muertes: 3000, nu_confirmados: 70000 },

            ]
        };
    }

    renderHeader() {
        let title = '';
        let prev = '';
        switch (true) {
            case (!!this.state.preguntaActual): {
                title = this.state.preguntaActual.vc_pregunta
                prev = this.state.preguntaActual.vc_prev || ''
                return (
                    <AppBar position='sticky' color='primary' key={`header`} style={{ height: 95 }}>
                        <Toolbar color='primary' key={`header`} style={{ height: 95 }}>
                            <Typography style={{ textAlign: 'center', width: '100%', flexDirection: 'column', verticalAlign: 'center', justifyItems: 'center' }}>
                                {prev}{title || 'No hay pregunta seleccionada'}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                )
            }
            default: {
                return (
                    <AppBar position='sticky' color='primary' key={`header`} style={{ height: 95}}>
                        <Toolbar color='primary' key={`header`} style={{ height: 95 }}>
                            <Typography style={{ textAlign: 'center', width: '100%', flexDirection: 'column', verticalAlign: 'center', justifyItems: 'center' }}>
                                {title || 'Selecciona una pregunta'}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                )
            }
        }
    }
    componentDidUpdate() {
        if (!window.isMobile && !this.props.noAutofocus) window.$('#textFieldResponse').focus()
    }
    getInput(item) {
        let searchText = this.state.searchText;
        switch (true) {
            case (item.key.length === 1 && (this.state.preguntaActual.id_tipoPregunta === 1 || this.state.preguntaActual.id_tipoPregunta === 2)): {
                this.keyDown({ ...item, ctrlKey: true })
                break;
            }
            case (item.key.length === 1): {
                searchText = `${searchText}${item.keyCode}`
                this.setState({ searchText })
                break;
            }
            case (item.key.length === 1): {
                searchText = `${searchText}${item.keyCode}`
                this.setState({ searchText })
                break;
            } case (item.keyCode === 8): {
                if (searchText.length > 0) {
                    searchText = searchText.slice(0, searchText.length - 1)
                }
                this.setState({ searchText })
                break;
            }
            case (item.keyCode === 37): {
                break;
            }
            case (item.keyCode === 37): {
                break;
            }
            default: {
                this.keyDown(item)
                break;
            }
        }
    }
    renderKeyboardNumeric() {
        return (
            <ThinkNumberKeyboard
                pressDown={this.getInput.bind(this)}
            />
        )
        const keys = [
            { keyCode: '7', key: '7' },
            { keyCode: '8', key: '8' },
            { keyCode: '9', key: '9' },
            { keyCode: '4', key: '4' },
            { keyCode: '5', key: '5' },
            { keyCode: '6', key: '6' },
            { keyCode: '1', key: '1' },
            { keyCode: '2', key: '2' },
            { keyCode: '3', key: '3' },
            { keyCode: '0', key: '0' },
            { keyCode: '.', key: '.' }
        ]
        return (
            <Grid container>
                <Grid key={'numbers'} item container xs={9}>
                    {
                        keys.map((item, index) => {
                            return (
                                <Grid key={index} item xs={item.keyCode === '0' ? 8 : 4} style={{ padding: 12 }}>
                                    <Button onClick={() => this.getInput(item)} fullWidth style={{ height: ((sidebarWidth - 24) / 4) - 20, width: '100%', borderRadius: item.key === '0' ? ((sidebarWidth - 26) / 8) - 3 : '50%', backgroundColor: 'rgba(200,200,200,0.1)', boxShadow: '0px 1px 3px rgba(0,0,0,0.5)' }}>
                                        <Typography variant='h3'>
                                            {item.keyCode}
                                        </Typography>
                                    </Button>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Grid key={'funtions'} item xs={3}>
                    <Grid key={'back'} item xs={12} style={{ padding: 12 }}>
                        <Button onClick={() => this.getInput({ keyCode: 37, key: 'ArrowLeft' })} variant='contained' color={'primary'} fullWidth style={{ height: ((sidebarWidth - 26) / 4) - 20, width: '100%', borderRadius: '50%', boxShadow: '0px 1px 3px rgba(0,0,0,0.5)' }}>
                            <ArrowBack />
                        </Button>
                    </Grid>
                    <Grid key={'fow'} item xs={12} style={{ padding: 12 }}>
                        <Button onClick={() => this.getInput({ keyCode: 39, key: 'ArrowRight' })} variant='contained' color={'primary'} fullWidth style={{ height: ((sidebarWidth - 26) / 4) - 20, width: '100%', borderRadius: '50%', boxShadow: '0px 1px 3px rgba(0,0,0,0.5)' }}>
                            <ArrowForward />
                        </Button>
                    </Grid>
                    <Grid key={'backspace'} item xs={12} style={{ padding: 12 }}>
                        <Button onClick={() => this.getInput({ keyCode: 8, key: 'Backspace' })} variant='contained' color={'primary'} fullWidth style={{ height: ((sidebarWidth - 26) / 4) - 20, width: '100%', borderRadius: '50%', boxShadow: '0px 1px 3px rgba(0,0,0,0.5)' }}>
                            <Backspace />
                        </Button>
                    </Grid>
                    <Grid key={'enter'} item xs={12} style={{ padding: 12 }}>
                        <Button onClick={() => this.getInput({ keyCode: 13, key: 'Enter' })} variant='contained' color={'primary'} fullWidth style={{ height: ((sidebarWidth - 26) / 4) - 20, width: '100%', borderRadius: '50%', boxShadow: '0px 1px 3px rgba(0,0,0,0.5)' }}>
                            <SubdirectoryArrowLeft />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    renderSearchBar() {
        const searchBarHeight = 40;
        return (
            <ListItem style={{ display: 'flex', alignItems: 'center', height: searchBarHeight, zIndex: 0, padding: 5 }}>
                <IconButton disabled={(this.state.respuestasSeleccionadas || []).length === 0 && this.state.preguntaActual === null} onClick={this.backAnswer.bind(this)} size='small'>
                    <ArrowBack />
                </IconButton>
                <Input
                    autoComplete={'off'}
                    autoCorrect='off'
                    id={'textFieldResponse'}
                    startAdornment={<Search style={{ marginLeft: 10, marginRight: 10, color: 'gray' }} />}
                    onChangeCapture={(e) => this.setState({ searchText: e.target.value }, () => {
                        if ((this.state.preguntaActual || {}).id_tipoPregunta === 9) {
                            this.searchLocations(this.state.searchText);
                        }
                    })}
                    endAdornment={
                        <SpeechButton
                            callbackPreview={(speechPreview) => {
                                this.setState({ speechPreview }, () => {
                                    if ((this.state.preguntaActual || {}).id_tipoPregunta === 9) {
                                        this.searchLocations(this.state.searchText + ' ' + this.state.speechPreview);
                                    }
                                })
                            }}
                            callbackFinal={(text) => {
                                console.log('llego callback', text)
                                this.setState({ searchText: this.state.speechPreview ? this.state.searchText + ' ' + this.state.speechPreview : '', speechPreview: '' })
                            }}
                        />
                    }
                    value={(this.state.searchText || '') + (this.state.speechPreview ? ` ${this.state.speechPreview}` : '')}
                    placeholder={((this.state.preguntaActual || {}).id_tipoPregunta === 3) ? 'Capture...' : "Buscar..."}
                    disableUnderline
                    disabled={((this.state.preguntaActual || {}).id_tipoPregunta === 3) && isMobile}
                    style={{ width: '100%', height: '100%', margin: 0 }}
                    autoFocus={!window.isMobile && !this.props.noAutofocus}
                />
            </ListItem>
        );
    }
    getCveRespuestaEnd(respuesta) {
        switch (this.state.preguntaActual.id_tipoPregunta) {
            case 3: {
                return `#`
            }
            case 4: {
                return `@`
            }
            case 5: {
                return `#`
            }
            case 6: {
                return `#`
            }
            case 9: {
                return `#${respuesta.cve_respuesta || ''}`
            }
            default: {
                return respuesta.id_respuesta
            }
        }
    }
    answerClick(respuesta, noLoad) {
        const respuestaSeleccionadaPrevia = this.isMultiple(this.state.preguntaActual.id_tipoPregunta) ? (this.state.respuestasMultiples || []).find(e => e.id_respuesta === respuesta.id) : null;
        let noChargePreguntas = false;
        if (!!respuestaSeleccionadaPrevia) {
            noChargePreguntas = true;
            const index = (this.state.respuestasSeleccionadas || []).indexOf(respuestaSeleccionadaPrevia)
            const indexMultiple = (this.state.respuestasMultiples || []).indexOf(respuestaSeleccionadaPrevia)
            if (index) {
                let preguntasPendientes = [...this.state.preguntasPendientes];
                if (respuestaSeleccionadaPrevia.cve_respuesta) {
                    preguntasPendientes = [...preguntasPendientes].filter(e => e.pregunta.cve_respuestaBase !== respuestaSeleccionadaPrevia.cve_respuesta)
                }
                const respuestasSeleccionadas = this.state.respuestasSeleccionadas
                const respuestasMultiples = (this.state.respuestasMultiples || [])
                let cve_respuestaStart = null;
                respuestasSeleccionadas.splice(index, 1)
                respuestasMultiples.splice(indexMultiple, 1)
                if (respuestaSeleccionadaPrevia.vc_sentencia.length > 1 && respuestasMultiples.length) {
                    respuestasMultiples[0].vc_sentencia = respuestaSeleccionadaPrevia.vc_sentencia;
                    cve_respuestaStart = respuestasMultiples[0].cve_respuesta;
                }
                const preguntasTexto = (this.props.pacienteActual.preguntasTexto || []).map((pregunta, index) => {
                    if (this.state.id_preguntaPadre && this.state.id_preguntaPadre === pregunta.id) {
                        let vc_respuesta = pregunta.respuesta ? pregunta.respuesta.vc_respuesta : '';
                        vc_respuesta = this.renderRespuestasDisplay(respuestasSeleccionadas);
                        return {
                            ...pregunta,
                            respuesta: { ...pregunta.respuesta || {}, vc_respuesta }
                        };
                    } else {
                        return pregunta;
                    }
                });
                const newPacienteActual = { ...pacienteActual, preguntasTexto };
                this.setState({ preguntasPendientes, respuestasMultiples, respuestasSeleccionadas, searchText: '', noLoadPreguntaEnlace: (respuestasMultiples || []).length === 0 ? false : true, respuestaLocation: null }, () => {
                    this.removeAnswerMultiple(respuestaSeleccionadaPrevia.cve_respuesta, cve_respuestaStart);
                    // this.setPacienteAnswers(newPacienteActual, preguntaActual)
                });
            }
            return
        };
        const { preguntasPendientes, preguntaActual, preguntasFiltradas, noLoadPreguntaEnlace } = this.state;
        const { pacienteActual, preguntas } = this.props;
        let newPreguntaActual = null;
        let newRespuesta = null;
        let preguntaEnlace = null;
        let newPreguntasPendientes = preguntasPendientes || [];
        let version = preguntaActual.version;
        const respuestalogicaNumerica = preguntaActual.id_tipoPregunta === 3 ? this.getRespuestaLogicaNumerica(preguntaActual, version.respuestas, respuesta.nu_respuesta) : null;
        if (version.id_preguntaEnlace && !noLoadPreguntaEnlace && this.props.mode !== 'scores' && !noChargePreguntas) {
            newPreguntasPendientes.push({ pregunta: preguntasFiltradas.find(e => e.id === version.id_preguntaEnlace), cve_respuestaBase: this.state.preguntaActual.cve_respuestaBase || '' })
        }
        if (respuesta.id_preguntaEnlace && !noChargePreguntas) {
            newPreguntasPendientes.push({ pregunta: { ...preguntasFiltradas.find(e => e.id === respuesta.id_preguntaEnlace), cve_respuestaBase: `${preguntaActual.id}:${respuesta.id ? respuesta.id : respuestalogicaNumerica ? respuestalogicaNumerica.id : `${this.getCveRespuestaEnd(respuesta)}`}` }, ...respuesta.vc_prev ? { vc_prev: respuesta.vc_prev } : {} })
        }
        newRespuesta = {
            id_pregunta: version.id_pregunta,
            id_respuesta: respuesta.id || (respuestalogicaNumerica || {}).id || null,
            dt_respuesta: respuesta.dt_respuesta || null,
            sn_patologico: respuesta.sn_patologico || (respuestalogicaNumerica || {}).sn_patologico || 0,
            tm_respuesta: respuesta.tm_respuesta || null,
            nu_respuesta: respuesta.nu_respuesta || null,
            vc_respuesta: respuesta.vc_respuesta || null,
            ...respuesta.nu_lat ? { nu_lat: respuesta.nu_lat } : {},
            ...respuesta.nu_lon ? { nu_lon: respuesta.nu_lon } : {},
            ...respuesta.vc_prev ? { vc_prev: respuesta.vc_prev } : {},
            cve_respuesta: `${this.state.preguntaActual.cve_respuestaBase || ''}${this.state.preguntaActual.cve_respuestaBase ? '-' : ''}${preguntaActual.id}:${respuesta.id ? respuesta.id : respuestalogicaNumerica ? respuestalogicaNumerica.id : `${this.getCveRespuestaEnd(respuesta)}`}`,
            vc_sentencia: this.renderRespuestaDisplay(respuesta, respuestalogicaNumerica)
        };
        const respuestasSeleccionadas = [...this.state.respuestasSeleccionadas];
        respuestasSeleccionadas.push(newRespuesta)

        const preguntasTexto = (this.props.pacienteActual.preguntasTexto || []).map((pregunta, index) => {
            if (this.state.id_preguntaPadre && this.state.id_preguntaPadre === pregunta.id) {
                let vc_respuesta = pregunta.respuesta ? pregunta.respuesta.vc_respuesta : '';
                vc_respuesta = vc_respuesta + this.renderRespuestasDisplay([newRespuesta]);
                const respuestasInternas = pregunta.respuestasInternas || [];
                respuestasInternas.push(newRespuesta);
                return {
                    ...pregunta,
                    respuesta: { ...pregunta.respuesta || {}, vc_respuesta },
                    respuestasInternas
                };
            } else {
                return pregunta;
            }
        });
        const newPacienteActual = { ...pacienteActual, preguntasTexto };

        if (noLoad === true) {
            console.log('No carga nueva pregunta')
            const respuestasMultiples = this.state.respuestasMultiples || [];
            respuestasMultiples.push(newRespuesta)
            const respuestasSeleccionadas = [...this.state.respuestasSeleccionadas];
            respuestasSeleccionadas.push(newRespuesta)
            this.setState({ respuestasMultiples, respuestasSeleccionadas, preguntasPendientes: newPreguntasPendientes, searchText: '', noLoadPreguntaEnlace: true, respuestaLocation: null }, () => {
                this.setPacienteAnswers(newPacienteActual, preguntaActual)
            });
        } else {
            console.log('Si carga nueva pregunta')
            preguntaEnlace = newPreguntasPendientes.pop() || null;
            newPreguntaActual = preguntaEnlace ? preguntaEnlace.pregunta : null;
            this.setState({ respuestasMultiples: null, respuestaLocation: null, noLoadPreguntaEnlace: false, respuestasSeleccionadas, preguntaActual: newPreguntaActual, cve_respuestaBase: preguntaEnlace ? preguntaEnlace.cve_respuestaBase : '', preguntasPendientes: newPreguntasPendientes, searchText: '', respuestaLocation: null }, () => {
                this.setPacienteAnswers(newPacienteActual, preguntaActual)
            });
        }
    }
    nextPregunta() {
        let newPreguntasPendientes = this.state.preguntasPendientes || [];
        const version = this.state.preguntaActual ? this.state.preguntaActual.version : null;
        const preguntaEnlace = newPreguntasPendientes.pop() || null;
        const newPreguntaActual = preguntaEnlace ? preguntaEnlace.pregunta : null;
        if (newPreguntaActual) {
            this.setState({ respuestasMultiples: null, respuestaLocation: null, noLoad: false, noLoadPreguntaEnlace: false, preguntaActual: newPreguntaActual, cve_respuestaBase: preguntaEnlace ? preguntaEnlace.cve_respuestaBase || '' : '', preguntasPendientes: newPreguntasPendientes, searchText: '', respuestaLocation: null });
        } else {
            this.setState({ respuestasMultiples: null, respuestaLocation: null, noLoad: false, noLoadPreguntaEnlace: false, preguntaActual: null, cve_respuestaBase: '', preguntasPendientes: newPreguntasPendientes, searchText: '', respuestaLocation: null }, () => {
                this.props.callbackFinish && this.props.callbackFinish()
            });
        }
    }
    getRespuestaLogicaNumerica(preguntaActual, respuestas, nu_respuesta) {
        const { unidadesMedida } = this.props;
        const um_pregunta = unidadesMedida.find(e => e.id === preguntaActual.id_um_numerico);
        let respuestaReturn = null;
        let trunk = false;
        for (let i = 0; i < respuestas.length && !trunk; i++) {
            const respuesta = respuestas[i];
            const um_limiteInferior = unidadesMedida.find(e => e.id === respuesta.id_um_limiteInferior);
            const um_limiteSuperior = unidadesMedida.find(e => e.id === respuesta.id_um_limiteSuperior);
            const nu_limiteInferior = respuesta.nu_limiteInferior * (um_limiteInferior ? um_limiteInferior.nu_ratio : null);
            const nu_limiteSuperior = respuesta.nu_limiteSuperior * (um_limiteSuperior ? um_limiteSuperior.nu_ratio : null);
            const nu_respuestaReal = nu_respuesta * um_pregunta.nu_ratio;

            if (nu_limiteInferior && nu_limiteSuperior) {
                if (nu_limiteInferior <= nu_respuestaReal && nu_limiteSuperior >= nu_respuestaReal) {
                    respuestaReturn = respuesta;
                    trunk = true;
                }
            } else if (nu_limiteInferior && !nu_limiteSuperior) {
                if (nu_limiteInferior <= nu_respuestaReal) {
                    respuestaReturn = respuesta;
                    trunk = true;
                }
            } else if (!nu_limiteInferior && nu_limiteSuperior) {
                if (nu_limiteSuperior >= nu_respuestaReal) {
                    respuestaReturn = respuesta;
                    trunk = true;
                }
            }
        }
        return respuestaReturn;
    }
    backAnswer() {
        if (this.state.respuestasSeleccionadas.length === 0) {
            if (!this.props.id_preguntaPadre) {
                this.setState({ preguntaActual: null, respuestasMultiples: null, respuestaLocation: null, respuestaLocation: null });
            }
        } else if (this.props.id_preguntaPadre) {
            this.removeAnswer()
        }

    }
    removeAnswerMultiple(cve_respuesta, cve_respuestaStart) {
        const pacienteActual = this.props.pacienteActual;
        const preguntaPadre = pacienteActual.preguntasTexto.find(e => e.id === this.props.id_preguntaPadre);
        console.log('atras multiple')
        if (preguntaPadre) {
            pacienteActual.preguntasTexto.map((pregunta, index) => {
                if (preguntaPadre.id === pregunta.id) {
                    let respuestasInternas = [...(preguntaPadre.respuestasInternas || [])];
                    let lastRespuesta = null;
                    if (cve_respuesta) {
                        lastRespuesta = respuestasInternas.find(e => e.cve_respuesta === cve_respuesta);
                    } else {
                        lastRespuesta = respuestasInternas.filter(e => e.id_pregunta).pop();
                    }

                    if (cve_respuestaStart && lastRespuesta.vc_sentencia) {
                        preguntaPadre.respuestasInternas = [...preguntaPadre.respuestasInternas].map(e => {
                            if (e.cve_respuesta === cve_respuestaStart) {
                                e.vc_sentencia = lastRespuesta.vc_sentencia;
                            }
                            return e;
                        });
                    }
                    preguntaPadre.respuestasInternas = [...preguntaPadre.respuestasInternas.filter(e => !(e.cve_respuesta && lastRespuesta.cve_respuesta && e.cve_respuesta.startsWith(lastRespuesta.cve_respuesta)))];
                    return preguntaPadre;
                }
                return pregunta;
            })
        }
        this.props.setPacienteActual(pacienteActual);
    }
    getCveRespuestaBase(cve_respuesta) {
        const arr = cve_respuesta.split(':')
        arr.pop();
        return arr.reduce((t, e) => {
            return t + e + ':'
        })
    }
    removeAnswer(cve_respuesta) {
        const pacienteActual = this.props.pacienteActual;
        const preguntaPadre = pacienteActual.preguntasTexto.find(e => e.id === this.props.id_preguntaPadre);
        console.log('atras')
        if (preguntaPadre) {
            pacienteActual.preguntasTexto.map((pregunta, index) => {
                if (preguntaPadre.id === pregunta.id) {
                    const respuestasInternas = [...(preguntaPadre.respuestasInternas || [])];
                    let lastRespuesta = null;
                    if (cve_respuesta) {
                        lastRespuesta = respuestasInternas.find(e => e.cve_respuesta === cve_respuesta);
                    } else {
                        lastRespuesta = respuestasInternas.filter(e => e.id_pregunta).pop();
                    }
                    preguntaPadre.respuestasInternas = [...preguntaPadre.respuestasInternas.filter(e => !(e.cve_respuesta && lastRespuesta.cve_respuesta && e.cve_respuesta.startsWith(this.getCveRespuestaBase(lastRespuesta.cve_respuesta))))];
                    return preguntaPadre;
                }
                return pregunta;
            })
        }
        let respuestasSeleccionadas = [...this.state.respuestasSeleccionadas];
        let preguntasPendientes = [...this.state.preguntasPendientes]
        if (this.state.preguntaActual && this.state.preguntaActual.version && this.state.preguntaActual.version.id_preguntaEnlace) {
            preguntasPendientes = preguntasPendientes.filter(e => e.pregunta.id !== this.state.preguntaActual.version.id_preguntaEnlace)
        }
        const lastRespuesta = respuestasSeleccionadas.filter(e => !(this.state.preguntaActual && e.cve_respuesta.startsWith(this.state.preguntaActual.cve_respuestaBase))).pop();
        let preguntaActual = null;
        if(lastRespuesta){
            preguntaActual = this.state.preguntasFiltradas.find(e => e.id === lastRespuesta.id_pregunta);
            respuestasSeleccionadas = this.state.respuestasSeleccionadas.filter(e => e.id_pregunta !== preguntaActual.id && !(this.state.preguntaActual && e.cve_respuesta.startsWith(this.state.preguntaActual.cve_respuestaBase)));
        }
        this.setState({ preguntaActual, respuestasSeleccionadas, respuestasMultiples: null, preguntasPendientes, respuestaLocation: null }, () => {
            this.props.setPacienteActual(pacienteActual);
        })
    }
    searchLocations = (searchText) => {
        if (searchText.split(' ').length >= 2) {
            this.setState({ loadingLocations: true })
            window.$.ajax({
                url: `https://maps.googleapis.com/maps/api/geocode/json?address=${searchText}&key=AIzaSyALFV0i5CA9nuaUYvabwL09uCsETeGWTQM`,
                method: 'GET'
            }).then((data, status, xhr, error) => {
                // console.log('response', data)
                const brand = searchText.split(',').length > 1 ? searchText.split(',')[0].toLocaleLowerCase() + ' ' : '';
                this.setState({ options: (data || {}).results || [], loadingLocations: false, brand, respuestaLocation: null })
            }).fail((data, status, xhr, error) => {
                // notification.info(error);
                this.setState({ loadingLocations: false })
            });
        } else {
            this.setState({ options: [] })
        }
    }
    getRespuestaLogicaLocation(respuesta) {
        const pa = this.state.preguntaActual;
        const inicio = pa.vc_concatenar && respuesta.dt_respuesta ? pa.vc_concatenar + moment(respuesta.dt_respuesta).format('dddd D MMMM') : '';
        const duracion = pa.vc_concatenarFinal && respuesta.dt_respuesta && respuesta.dt_vigencia ? pa.vc_concatenarFinal + this.getDiff(moment(respuesta.dt_respuesta), moment(respuesta.dt_vigencia)) : '';
        const fin = pa.vc_textoFinal && respuesta.dt_vigencia ? pa.vc_textoFinal + moment(respuesta.dt_vigencia).format('dddd D MMMM') : '';
        return pa.vc_textoInicial + respuesta.vc_respuesta || '' + inicio + duracion + fin;
    }
    setPacienteAnswers(newPacienteActual, preguntaActual) {
        switch (true) {
            case (this.props.mode === 'scores'): {
                this.props.setPacienteActual(newPacienteActual)
                break;
            }
            default: {
                this.props.setPacienteActual(newPacienteActual)
            }
        }
        preguntaActual === null && this.finishAnalitycs(this.state.respuestasSeleccionadas);
    }
    finishAnalitycs(respuestasSeleccionadas) {
        let score = 0;
        let viaje = 0;
        let contacto = 0;
        let sinatomatologia = 0;
        let cronico = 0;
        let edad = 0;
        let hombre = 0;
        respuestasSeleccionadas.map((respuesta, index) => {
            const pregunta = this.props.preguntas.find(e => e.id === respuesta.id_pregunta);
            if (pregunta.id_tipoPregunta === 9) {
            }
        });
    }
    getDiff(dt_inicio, dt_fin) {
        const inicio = dt_inicio || moment();
        const fin = dt_fin || moment();
        const diff = fin.diff(inicio, 'H');
        const days = fin.diff(inicio, 'days');
        const months = fin.diff(inicio, 'months');
        const years = fin.diff(inicio, 'years');

        switch (true) {
            case (diff < 1): {
                return 'un momento'
            }
            case (diff < 72): {
                return `${(diff).toFixed(0)} ${(diff).toFixed(0) > 1 ? 'horas' : 'hora'}`
            }
            case (diff < (31 * 24)): {
                return `${(diff / 24).toFixed(0)} ${(diff / 24).toFixed(0) > 1 ? 'días' : 'día'}`
            }
            case (months < 2): {
                return `${(months).toFixed(0)} meses y ${(days - 30).toFixed(0) > 1 ? 'días' : 'día'}`
            }
            case (months < 12): {
                return `${(months).toFixed(0)} meses`
            }
            case (months.toFixed(0) === 12): {
                return `un año`
            }
            case (months > 12 && months < 24): {
                return `un año y ${(months - 12).toFixed(0)} ${(months - 12).toFixed(0) === 1 ? 'mes' : 'meses'}`
            }
            default: {
                return `${(years).toFixed(0)} años`
            }
        }
    }

    getRisk() {

    }
    renderOptions() {
        let options = '';
        switch (true) {
            case (!!this.state.preguntaActual && this.state.preguntaActual.id_tipoPregunta === 5): {
                return (
                    <Box>
                        <Box p={1}>
                            <DateSelectPicker
                                fullWidth
                                key={'dt_inicio'}
                                fieldid={'dt_inicio'}
                                required
                                value={null}
                                label={'Fecha'}
                            />
                        </Box>
                        <Box p={1}>
                            <Button variant='outlined' fullWidth color='primary' onClick={() => {
                                const respuesta = {};
                                const dt_inicioValue = document.getElementById('dt_inicio').value;
                                const dt_inicio = moment(dt_inicioValue, 'DD-MM-YYYY')
                                if (dt_inicio.isValid()) {
                                    respuesta.dt_respuesta = dt_inicio.format('YYYY-MM-DD HH:mm:ss');
                                    respuesta.sn_patologico = 1;
                                    respuesta.vc_respuestaLogica = `${this.state.preguntaActual.vc_textoInicial || ''}${dt_inicio.format('dddd D MMMM YYYY')}`
                                    this.answerClick(respuesta);
                                }
                            }}>
                                {'Siguiente'}
                            </Button>
                        </Box>
                    </Box>
                )
            }
            case (!!this.state.preguntaActual && this.state.preguntaActual.id_tipoPregunta !== 9): {
                options = this.state.preguntaActual.version.respuestas
                return options.map((respuesta, index) => {
                    return (
                        <ListItem divider key={index} onClick={() => {
                            if (this.state.preguntaActual.id_tipoPregunta === 1 || this.state.preguntaActual.id_tipoPregunta === 2) {
                                if (this.isMultiple(this.state.preguntaActual.id_tipoPregunta)) {
                                    let noPatologicoSelected = true;
                                    let patologicoSelected = true;
                                    if ((this.state.respuestasMultiples || []).length) {
                                        noPatologicoSelected = this.state.respuestasMultiples.some(e => { return e.sn_patologico === 0 }) && respuesta.sn_patologico === 0;
                                        patologicoSelected = this.state.respuestasMultiples.some(e => { return e.sn_patologico === 1 }) && respuesta.sn_patologico === 1;
                                    }
                                    const optionsNoPatologicas = options.filter(e => e.sn_patologico === 0).length;
                                    if (patologicoSelected || noPatologicoSelected) this.answerClick(respuesta, respuesta.sn_patologico === 0 && optionsNoPatologicas === 1 ? false : true);
                                } else {
                                    this.answerClick(respuesta);
                                }
                            }
                        }} button disableGutters selected={!!(this.state.respuestasMultiples || []).find(e => e === respuesta.id)} style={{ display: 'flex', width: '100%', height: 60, alignItems: 'center', justifyContent: 'left', borderLeft: !!(this.state.respuestasMultiples || []).find(e => (e.id_respuesta && e.id_respuesta === respuesta.id)) ? `5px solid ${colors.itemSelected}` : null }}>
                            {
                                (this.state.control || isMobile) &&
                                <Typography style={{ position: 'absolute', left: 10 }}>
                                    {index}
                                </Typography>
                            }
                            {
                                (this.state.control || isMobile) && respuesta.sn_patologico === 0 &&
                                <Check style={{ position: 'absolute', right: 5, top: 5, color: 'green' }} />
                            }
                            {
                                (this.state.control || isMobile) && respuesta.sn_patologico === 1 &&
                                <NotificationImportantOutlined style={{ position: 'absolute', right: 5, top: 5, color: 'yellow', opacity: 0.4 }} />
                            }
                            <Typography style={{ padding: 16, paddingLeft: 24 }}>
                                {respuesta.vc_respuesta || 'No Aplica'}
                            </Typography>
                        </ListItem>
                    )
                })
            }
            case (!!this.state.preguntaActual && this.state.preguntaActual.id_tipoPregunta === 9 && !this.state.respuestaLocation): {
                options = this.state.options || [];
                return (
                    <Box>
                        {
                            options.map((respuesta, index) => {
                                return (
                                    <ListItem divider key={index} onClick={() => {
                                        this.setState({
                                            respuestaLocation: {
                                                option: 'dt_inicio-dt_fin',
                                                prevOption: null,
                                                respuesta: {
                                                    id_pregunta: this.state.preguntaActual.id,
                                                    vc_respuesta: respuesta.formatted_address,
                                                    nu_lon: respuesta.geometry.location.lng * 1,
                                                    nu_lat: respuesta.geometry.location.lat * 1,
                                                    vc_prev: `¿Despues de ${respuesta.formatted_address} `,
                                                    cve_respuesta: respuesta.place_id || '#',
                                                }
                                            }
                                        });

                                    }} button selected={!!(this.state.respuestasMultiples || []).find(e => e === respuesta.id)} style={{ display: 'flex', width: '100%', height: 60, alignItems: 'center', borderLeft: !!(this.state.respuestasMultiples || []).find(e => e.id_respuesta === respuesta.id) ? `5px solid ${colors.itemSelected}` : null }}>
                                        {
                                            (this.state.control || isMobile) &&
                                            <Typography style={{ position: 'absolute', left: 10, color: 'gray' }}>
                                                {index}
                                            </Typography>
                                        }
                                        {
                                            (this.state.control || isMobile) && respuesta.sn_patologico === 0 &&
                                            <Check style={{ position: 'absolute', right: 5, top: 5, color: 'green' }} />
                                        }
                                        {
                                            (this.state.control || isMobile) && respuesta.sn_patologico === 1 &&
                                            <NotificationImportantOutlined style={{ position: 'absolute', right: 5, top: 5, color: 'yellow', opacity: 0.4 }} />
                                        }
                                        <Typography style={{ padding: 16 }}>
                                            {respuesta.formatted_address || 'No Aplica'}
                                        </Typography>
                                        <LocationOnIcon style={{ float: 'right', height: '100%' }} />

                                    </ListItem>
                                )
                            })
                        }
                        {
                            !options.length &&
                            <Box display='flex' alignContent='center'>
                                <Typography variant='h6' style={{ width: '100%', textAlign: 'center', padding: 20 }}>
                                    {'No hay coincidencias...'}
                                </Typography>
                            </Box>
                        }
                    </Box>
                )
            }
            case (!!this.state.preguntaActual && this.state.preguntaActual.id_tipoPregunta === 9 && (this.state.respuestaLocation || {}).option === 'dt_inicio-dt_fin'): {
                return (
                    <Box>
                        <ListItem>
                            <Typography style={{ padding: 16 }}>
                                {(this.state.respuestaLocation || {}).respuesta.vc_respuesta || 'No Aplica'}
                            </Typography>
                            <LocationOnIcon style={{ float: 'right', height: '100%' }} />
                        </ListItem>
                        <Box p={1}>
                            <DateSelectPicker
                                fullWidth
                                key={'dt_inicio'}
                                fieldid={'dt_inicio'}
                                required
                                value={null}
                                label={'Desde'}
                            />
                        </Box>
                        <Box p={1}>
                            <DateSelectPicker
                                fullWidth
                                key={'dt_fin'}
                                fieldid={'dt_fin'}
                                required
                                value={null}
                                label={'Hasta'}
                            />
                        </Box>
                        <Box p={1}>
                            <Switcher
                                id={'sn_contacto'}
                                key={'sn_contacto'}
                                fieldid={'sn_contacto'}
                                required
                                defaultValue={0}
                                label={'Riesgo:'}
                                fullWidth
                            />
                        </Box>
                        <Box p={1}>
                            <Button variant='outlined' fullWidth color='primary' onClick={() => {
                                const respuesta = (this.state.respuestaLocation || {}).respuesta || {};
                                const sn_contacto = document.getElementById('sn_contacto').value;
                                const dt_inicioValue = document.getElementById('dt_inicio').value;
                                const dt_finValue = document.getElementById('dt_fin').value;
                                const dt_inicio = moment(dt_inicioValue, 'DD-MM-YYYY')
                                const dt_fin = dt_finValue ? moment(dt_finValue, 'DD-MM-YYYY') : null;
                                const brand = this.state.brand || '';
                                if (dt_inicio.isValid() && dt_fin && dt_fin.isValid() && dt_inicio <= dt_fin) {
                                    respuesta.dt_respuesta = dt_inicio.format('YYYY-MM-DD HH:mm:ss');
                                    respuesta.dt_vigencia = dt_fin.format('YYYY-MM-DD HH:mm:ss');;
                                    respuesta.sn_patologico = sn_contacto;
                                    respuesta.nu_score = sn_contacto ? 100 : 0;
                                    respuesta.nu_estancia = dt_fin.diff(dt_inicio, 'hours');
                                    respuesta.vc_respuestaLogica = `${this.state.preguntaActual.vc_textoInicial || ''}${brand}${respuesta.vc_respuesta}, desde el ${dt_inicio.format('dddd D MMMM')} con una estancia de aproximadamente ${this.getDiff(dt_inicio, dt_fin || null)}${dt_fin ? ` hasta el ${dt_fin.format('dddd D MMMM')}` : 'hasta la fecha'}`
                                    this.answerClick(respuesta);
                                } else if (dt_inicio.isValid() && dt_fin === null) {
                                    respuesta.dt_respuesta = dt_inicio.format('YYYY-MM-DD HH:mm:ss');
                                    respuesta.dt_vigencia = null;
                                    respuesta.sn_patologico = sn_contacto;
                                    respuesta.nu_score = sn_contacto ? 100 : 0;
                                    respuesta.nu_estancia = dt_fin ? dt_fin.diff(dt_inicio, 'hours') : moment().diff(dt_inicio, 'hours');
                                    respuesta.vc_respuestaLogica = `${this.state.preguntaActual.vc_textoInicial || ''}${brand}${respuesta.vc_respuesta}, desde el ${dt_inicio.format('dddd D MMMM')} con una estancia de aproximadamente ${this.getDiff(dt_inicio, dt_fin || null)}${dt_fin ? ` hasta el ${dt_fin.format('dddd D MMMM')}` : ' hasta la fecha'}`
                                    this.answerClick(respuesta);
                                } else if (respuesta.nu_lat && respuesta.nu_lon) {
                                    respuesta.dt_vigencia = null;
                                    respuesta.vc_respuestaLogica = `${this.state.preguntaActual.vc_textoInicial || ''}${respuesta.vc_respuesta}`
                                    this.answerClick(respuesta);
                                }
                            }}>
                                {'Siguiente'}
                            </Button>
                        </Box>
                    </Box>
                )
            }
            default: {
                const { preguntasFiltradas } = this.state;
                options = preguntasFiltradas.filter(e => e.vc_pregunta.toLowerCase().includes(this.state.searchText.toLowerCase()) ).filter((e, i) => i < 50);
                return options.map((pregunta, index) => {
                    return (
                        <ListItem key={index} button onClick={() => this.setState({ preguntaActual: pregunta })} style={{ display: 'flex', width: '100%', height: 60, alignItems: 'center', justifyContent: 'left' }}>
                            <div style={{ padding: 16, textAlign: 'justify' }}>
                                <Typography>
                                    {pregunta.vc_pregunta || 'No Aplica'}
                                </Typography>
                            </div>
                        </ListItem>
                    )
                })
            }
        }
    }
    concatenarFinalDisplay(respuesta, pregunta) {
        const { respuestasSeleccionadas } = this.state;
        let respuestas = [];
        switch (true) {
            case (pregunta.id_tipoPregunta === 2): {
                respuestas = respuestasSeleccionadas.filter(e => {
                    return e.id_pregunta === respuesta.id_pregunta
                })
                return pregunta.version.vc_textoFinal || '';
            }
            case (pregunta.id_tipoPregunta === 8): {
                return pregunta.version.vc_textoFinal || '';
            }
            default: {
                return pregunta.version.vc_textoFinal || '';
            }
        }
    }
    getValueRespuesta(pregunta, respuesta) {
        switch (pregunta.id_tipoPregunta) {
            case 1: {
                return respuesta.vc_respuestaLogica || '';
            }
            case 2: {
                return respuesta.vc_respuestaLogica || '';
            }
            case 3: {
                return `${respuesta.nu_respuesta}`.replace('.00', '');
            }
            case 4: {
                return respuesta.vc_respuesta || '';
            }
            case 5: {
                return moment(respuesta.dt_respuesta).format('dddd D MMMM');
            }
            case 9: {
                return respuesta.vc_respuestaLogica || '';
            }
            default: {
                return '';
            }
        }
    }
    renderRespuestaDisplay(respuesta, respuestalogicaNumerica) {
        const pregunta = this.state.preguntasFiltradas.find(e => e.id === this.state.preguntaActual.id) || {};
        const vc_textoInicial = (this.state.respuestasMultiples || []).length ? '' : pregunta.version.vc_textoInicial || '';
        const vc_textoFinal = this.concatenarFinalDisplay(respuesta, pregunta)
        return `${(respuestalogicaNumerica || {}).vc_respuestaLogica || vc_textoInicial}${this.getValueRespuesta(pregunta, respuesta)}${vc_textoFinal}`

    }
    renderRespuestasDisplay(respuestasSeleccionadas) {
        let text = '';
        respuestasSeleccionadas.map((respuesta, index) => {
            text = `${text}${respuesta.vc_sentencia}`
        });
        return text;
    }
    showKeyboard() {
        if (!this.state.preguntaActual) return false;
        switch (this.state.preguntaActual.id_tipoPregunta) {
            case 3: {
                return isMobile;
            }
            case 5: {
                return isMobile;
            }
            case 6: {
                return isMobile;
            }
            default: {
                return false
            }
        }
    }

    render() {
        const { preguntaActual } = this.state;
        const heightFather = this.props.heightFather || null;
        return (
            <Paper id={'ThinkResponse'} style={{ maxWidth: '100%', height: '100%', position: 'relative' }}>
                {this.renderHeader()}
                <Divider />
                {this.renderSearchBar()}
                <Divider />
                <Divider />
                <Box style={{ height: `calc(100% - ${this.showKeyboard() ? 140 + this.state.heightSideBar : 140}px)`, overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>
                    {this.renderOptions()}
                    <Fab
                        color='primary'
                        onClick={() => {
                            if (!!this.state.preguntaActual && this.isMultiple(this.state.preguntaActual.id_tipoPregunta)) {
                                this.getInput({ keyCode: 13, key: 'Enter' })
                            } else {
                                this.nextPregunta();
                            }
                        }}
                        style={{ position: 'absolute', bottom: 72, right: 8 }}
                    >
                        {
                            !!this.state.preguntaActual && this.isMultiple(this.state.preguntaActual.id_tipoPregunta) ?
                                <ArrowForward /> :
                                <Redo />
                        }
                    </Fab>
                    <Fab
                        color='primary'
                        onClick={this.props.callbackFinish || null}
                        style={{ position: 'absolute', bottom: 8, right: 8 }}
                    >
                        <Clear />
                    </Fab>
                </Box>
                <Divider />
                {
                    this.showKeyboard() &&
                    this.renderKeyboardNumeric()
                }
                {/* {this.renderTrackingMap()} */}
            </Paper>
        );
    }

    // renderTrackingMap() {
    //     const points = this.state.respuestasSeleccionadas.filter(e => e.nu_lat && e.nu_lon);
    //     return (
    //         <Modal
    //             open={this.state.showMap}
    //             onClose={() => this.setState({ showMap: false })}
    //             style={{ boxShadow: '0px 0px 0px transparent', backgroundColor: 'transparent' }}
    //         >
    //             <Paper style={{padding: 0, borderRadius: 20, overflow: 'hidden'}}>
    //                 <GoogleMaps
    //                     points={points}
    //                     circles={(this.props.covid19 || {}).countries || []}
    //                 />
    //             </Paper>
    //         </Modal>
    //     )
    // }
    componentWillMount() {
        const preguntasFiltradas = [];
        const id_preguntaPadre = this.props.id_preguntaPadre || null;
        const preguntasPendientes = [];
        this.props.preguntas.map((pregunta, index) => {
            const version = this.preguntaVersion(pregunta);
            if (version) {
                preguntasFiltradas.push({
                    ...pregunta,
                    vc_pregunta: version.vc_pregunta,
                    version
                })
            }

            if ((this.props.preguntasEnlace || []).find(e => e === pregunta.id)) {
                if (version)
                    preguntasPendientes.push({
                        pregunta: {
                            ...pregunta,
                            vc_pregunta: version.vc_pregunta,
                            version
                        },
                        cve_respuestaBase: ''
                    })
            }
        })
        let preguntaActual = null;
        if (preguntasPendientes.length > 0) {
            const preguntaPendiente = preguntasPendientes.pop();
            preguntaActual = preguntaPendiente.pregunta;
        }
        this.setState({
            preguntasFiltradas,
            preguntasPendientes,
            id_preguntaPadre,
            preguntaActual
        });
    }
    pressArrows(e) {

    }
    validatePatologyNuAnswer(nu_respuesta) {

    }
    isMultiple(id_tipoPregunta) {
        switch (id_tipoPregunta) {
            case 2: {
                return true;
            }
            case 8: {
                return true;
            }
            default: {
                return false;
            }
        }
    }
    keyDown(e) {
        if (e.key === 'Control') { this.setState({ control: true }) }
        if ((this.state.control || e.ctrlKey) && this.state.preguntaActual && e.key * 1 > -1) {
            const optionsNoPatologicas = this.state.preguntaActual.version.respuestas.filter(e => e.sn_patologico === 0).length;
            this.answerClick(this.state.preguntaActual.version.respuestas[e.key * 1], this.state.preguntaActual.version.respuestas[e.key * 1].sn_patologico === 0 && optionsNoPatologicas === 1 ? false : this.isMultiple(this.state.preguntaActual.id_tipoPregunta))
        } else if (e.keyCode === 13 && this.state.preguntaActual) {
            switch (true) {
                case (this.state.preguntaActual.id_tipoPregunta === 1): {
                    this.nextPregunta()
                    e.stopPropagation && e.stopPropagation();
                    e.preventDefault && e.preventDefault();
                    break;
                }
                case (this.state.preguntaActual.id_tipoPregunta === 2): {
                    this.nextPregunta()
                    e.stopPropagation && e.stopPropagation();
                    e.preventDefault && e.preventDefault();
                    break;
                }
                case (this.state.preguntaActual.id_tipoPregunta === 3): {
                    const textField = document.getElementById('textFieldResponse');
                    const value = textField && textField.value;
                    console.log('value', value)
                    if (value * 1 > -1) {
                        this.answerClick({
                            nu_respuesta: (value * 1).toFixed(2)
                        })
                    }
                    e.stopPropagation && e.stopPropagation();
                    e.preventDefault && e.preventDefault();
                    break;
                }
                case (this.state.preguntaActual.id_tipoPregunta === 4): {
                    const textField = document.getElementById('textFieldResponse');
                    const value = textField && textField.value;
                    console.log('value', value)
                    if (value.length > 0) {
                        this.answerClick({
                            vc_respuesta: value
                        })
                    }
                    e.stopPropagation && e.stopPropagation();
                    e.preventDefault && e.preventDefault();
                    break;
                }
                case (this.state.preguntaActual.id_tipoPregunta === 5): {
                    const textField = document.getElementById('textFieldResponse');
                    const value = textField && textField.value;
                    console.log('value', value)
                    if (moment(value).isValid()) {
                        this.answerClick({
                            dt_respuesta: moment(value).format('YYYY-MM-DD')
                        })
                    }
                    e.stopPropagation && e.stopPropagation();
                    e.preventDefault && e.preventDefault();
                    break;
                }
            }
        }
    }
    componentDidMount() {
        this.addObservers()
    }
    addObservers() {
        document.getElementById('ThinkResponse').addEventListener('keydown', this.keyDown.bind(this))
        document.getElementById('ThinkResponse').addEventListener('keyup', (e) => {
            if (e.key === 'Control') { this.setState({ control: false }) }
        })
        if (this.props.getDefault) {
            const preguntasTexto = (this.props.pacienteActual.preguntasTexto || []).map((pregunta, index) => {
                if (this.state.id_preguntaPadre && this.state.id_preguntaPadre === pregunta.id) {
                    let vc_respuesta = pregunta.respuesta ? pregunta.respuesta.vc_respuesta : '';
                    vc_respuesta = repository.displaySummaryPatient(this.props.pacienteActual, { medicamentosDgis: this.props.medicamentosDgis, diagnosticos: this.props.diagnosticos });
                    return {
                        ...pregunta,
                        respuesta: { ...pregunta.respuesta || {}, vc_respuesta },
                        respuestasInternas: [{ vc_sentencia: vc_respuesta }]
                    };
                } else {
                    return pregunta;
                }
            });
            const newPacienteActual = { ...this.props.pacienteActual, preguntasTexto };
            this.props.setPacienteActual(newPacienteActual);
        }

    }
    //Methods//


    preguntaVersion = (preguntaActual) => {
        const { date, PreguntasNumericas, respuestas, pacienteActual } = this.props;
        const pacienteDetalle = pacienteActual.detalle || {};
        const id_sexo = pacienteDetalle.id_sexo;
        const nu_edadMinutos = (moment() - moment(pacienteDetalle.dt_nacimiento)) / 60000;
        let version = null;
        preguntaActual.versiones.map((preguntaVersion, index) => {
            if (this.isValidAge(nu_edadMinutos, preguntaVersion) && this.isValidSex(id_sexo, preguntaVersion.id_sexo)) {
                version = preguntaVersion;
            }
        });

        return version;
    }
    finder = (array, key, value) => {
        const object = array.find((e) => { return e[`${key}`] === value });
        if (object) {
            return object;
        }
        return null;
    }
    isValidSex = (id_sexoPaciente, id_sexoPregunta) => {
        if (id_sexoPaciente === id_sexoPregunta || id_sexoPregunta === 3) {
            return true;
        } else {
            return false;
        }
    }
    isValidAge = (nuAgeMinutesPaciente, item) => {
        const { unidadesMedida } = this.props;
        let limiteInferiorAge = null;
        let limiteSuperiorAge = null;
        if (item.nu_minEdad > 0 && item.id_um_minEdad != null) {
            const nuUmBase = this.finder(unidadesMedida, 'id', item.id_um_minEdad).nu_ratio;
            limiteInferiorAge = nuUmBase * item.nu_minEdad;

        }
        if (item.nu_maxEdad > 0 && item.id_um_maxEdad != null) {
            const nuUmBase = this.finder(unidadesMedida, 'id', item.id_um_maxEdad).nu_ratio;
            limiteSuperiorAge = nuUmBase * item.nu_maxEdad;
        }
        if (limiteInferiorAge != null && nuAgeMinutesPaciente < limiteInferiorAge) {
            return false;
        }
        if (limiteSuperiorAge != null && nuAgeMinutesPaciente > limiteSuperiorAge) {
            return false;
        }
        return true;
    }

}
const mapStateToProps = (state) => {
    let { pacienteActual, unidadesMedida, preguntas, preguntasRespuestas, respuestas, preguntasVersiones, diagnosticos, medicamentosDgis, covid19 } = state.Think;
    if (!pacienteActual) {
        pacienteActual = { detalle: { id_sexo: 1, dt_nacimiento: '1999-12-12' } }
    }
    return { unidadesMedida, preguntas, preguntasRespuestas, respuestas, preguntasVersiones, pacienteActual, diagnosticos, medicamentosDgis, covid19 };
};


export default connect(mapStateToProps, { setPacienteActual })(Index);

import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';


class YellowButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    };
  }

  render() {
    let styleButton = {
      ...styles.yellowButton,
      borderRadius: '25px'
    };

    if (this.state.hovering) {
      styleButton = {
        ...styleButton,
        background: window.colors.itemSelected,
        color: 'rgba(255, 255, 255, 0.85)',
        backgroundImage: `linear-gradient(${window.colors.itemSelectedLight}, ${window.colors.itemSelected})`
      };
    }

    return (
      <Button
        type={this.props.type ? this.props.type : 'button'}
        style={{ ...styleButton, ...this.props.style }}
        onClick={this.props.onClick}
        loading={this.props.loading}
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
      >
        { this.props.label}
      </Button>
    );
  }
}


const styles = {
  yellowButton: {
    background: window.colors.itemSelected,
    color: 'rgba(255, 255, 255, 0.85)',
    marginLeft: '10px'
  }
};


export { YellowButton };

import React from 'react';

function Encabezado(props) {
  return (
    <div style={{ width: '100%', justifyContent: 'center', backgroundColor: 'transparent', minHeight: 100 }}>
      <h2 >{props.title || ''}</h2>
      <h2 >{props.message || ''}</h2>
      <div style={{
        position:'absolute',
        backgroundImage: 'url(assets/iconThin-k.png)',
        backgroundPosition: 'center',
        backgroundSize: '100px 100px',
        backgroundRepeat: 'no-repeat',
        height: 100, 
        width: 100,
        top: 30,
        right: 30
      }} />
    </div>
  );
}

export default Encabezado;

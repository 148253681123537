import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import formatter from '../../utils/formatter';

const { Column } = Grid;


class HoverableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  onClick() {
    const { onClick, field } = this.props;

    if (onClick) {
      onClick(field);
    }
  }

  getIconName() {
    const { field, value } = this.props;

    if (field.required && !value.value) {
      return 'remove';
    }

    if (value.value) {
      return 'checkmark';
    }

    return 'minus';
  }

  getColor() {
    const { field, value } = this.props;

    if (field.required && !value.value) {
      return 'red';
    }

    if (value.value) {
      return 'green';
    }

    return 'gray';
  }

  isCurrentFieldSelected() {
    const { selectedField, field } = this.props;
    return selectedField.name === field.name;
  }


  // -----------------------------
  // ------ render methods -------
  // -----------------------------
  render() {
    const { field, value } = this.props;
    const opacity = this.state.isHovering ? 0.8 : 0.5;
    const boxShadow = `1px 0px 5px rgba(0,0,0, ${opacity})`;
    const rowStyle = { boxShadow, ...styles.row };

    if (this.isCurrentFieldSelected()) {
      rowStyle.backgroundColor = 'rgb(240, 240, 240)';
    }

    return (
      <Grid
        columns={3}
        onClick={this.onClick.bind(this)}
        style={rowStyle}
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        <Column style={styles.column}>
          { field.label }
        </Column>

        <Column style={styles.column}>
          <span style={{ color: this.getColor() }}>
            { value.label || '---------------'}
          </span>
        </Column>

        <Column style={styles.column}>
          <Icon name={this.getIconName()} style={{ float: 'right', color: this.getColor() }} />
        </Column>
      </Grid>
    );
  }
}


const styles = {
  row: {
    color: 'gray',
    marginBottom: 15,
    borderRadius: 5,
    fontSize: 14,
    paddingRight: 5,
    paddingLeft: 5,
    cursor: 'pointer'
  },
  column: {
    paddingTop: 5,
    paddingBottom: 5
  }
};


HoverableRow.propTypes = {
  value: PropTypes.object,
  onClick: PropTypes.func,
  field: PropTypes.object,
  selectedField: PropTypes.object,
};


export default HoverableRow;

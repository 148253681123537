/* eslint-disable */
import React from "react";
import { LocalizationProvider, TimePicker } from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import { TextField } from "@material-ui/core";

export default (props) => {
  const { minutesStep = 1 } = props;
  const minWidth = props.label ? props.label.length * 10 : 120;
  const useStyles = makeStyles((theme) => ({
    formControl: {
      marginTop: 4.5,
      marginRight: 10,
      marginBottom: 4,
    },
  }));
  const classes = useStyles();
  const { required, label, fullWidth, options = [], input, fieldid } = props;

  const [selectedDate, setTime] = React.useState(null);
  // props.defaultValue ? moment(props.defaultValue, "HH:mm:ss") : null
  const [prevValue, setPrevValue] = React.useState(null);

  React.useEffect(() => {
    // setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    const newTime = moment(props.defaultValue, "HH:mm:ss");
    if (newTime.isValid()) setTime(newTime);
    else setTime(null);
  }, [props.defaultValue]);

  const inputName = label.replace(" ", "-");
  const inputId = `outlined-${inputName}`;
  const handleTimeChange = (date, str) => {
    if (date === null) {
      setPrevValue(null);
      props.onChange && props.onChange(null);
    } else if (moment(date).isValid()) {
      setTime(date);
      props.onChange && props.onChange(moment(date).format("HH:mm:ss"));
    } else {
      setTime(date);
      props.onChange && props.onChange(moment(date).format("HH:mm:ss"));
    }
  };
  return (
    <FormControl
      variant="outlined"
      required={required}
      fullWidth={fullWidth}
      className={classes.formControl}
      style={props.style || {}}
    >
      <LocalizationProvider dateAdapter={DateFnsAdapter}>
        <TimePicker
          minutesStep={minutesStep}
          autoOk
          placeholder="hh:mm (24hrs)"
          name={inputName}
          variant="inline"
          inputVariant="outlined"
          label={props.required ? `${props.label}*` : props.label}
          mask="__:__"
          inputFormat={"HH:mm"}
          value={selectedDate}
          InputAdornmentProps={{
            position: "end",
          }}
          ampm={false}
          InputLabelProps={props.InputLabelProps || {}}
          InputProps={{ id: fieldid }}
          renderInput={(_) => {
            return (
              <TextField
                variant={"outlined"}
                {..._}
                error={false}
                helperText={
                  moment(selectedDate).isValid() &&
                  moment(selectedDate).format &&
                  moment(selectedDate).format("yyyy") * 1 > 1900
                    ? ""
                    : "*Hora invalida"
                }
              />
            );
          }}
          onChange={handleTimeChange}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

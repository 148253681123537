import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import formatter from '../../utils/formatter';
import HoverableButton from './HoverableButton';
import { FadeInView } from '../FadeInView';


class SidebarInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue || '',
      selectedIndex: null,
    };
  }

  componentDidMount() {
    this.focusInput();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate() {
    const options = this.getFilteredOptions();

    if (this.state.selectedIndex > options.length - 1) {
      this.setState({ selectedIndex: options.length - 1 }); // eslint-disable-line
    }
  }


  // -----------------------
  // ------ methods --------
  // -----------------------
  onTextChanged(event) {
    this.setState({ value: event.target.value.toUpperCase() });

    if (this.props.onChange && !this.waitingFinishedTyping) {
      this.waitingFinishedTyping = true;

      setTimeout(() => {
        this.waitingFinishedTyping = false;
        this.props.onChange(this.state.value);
      }, 1500);
    }
  }

  onOptionClick(option) {
    const { onSelect, fieldName } = this.props;
    onSelect(fieldName, option);
  }

  onEnterPressed() {
    const { onSelect, fieldName, type } = this.props;
    const { selectedIndex, value } = this.state;
    const options = this.getFilteredOptions();
    const isDropdown = type === 'dropdown';

    if (!options.length && value && !isDropdown) {
      const selectedOption = { label: value, value };
      onSelect(fieldName, selectedOption);
    } else if (options.length && selectedIndex === null && value && !isDropdown) {
      const selectedOption = { label: value, value };
      onSelect(fieldName, selectedOption);
    } else if (selectedIndex !== null && selectedIndex >= 0) {
      const selectedOption = options[selectedIndex];
      onSelect(fieldName, selectedOption);
    }
  }

  onArrowUpPressed() {
    this.focusInput();
    const { selectedIndex } = this.state;

    if (selectedIndex > 0) {
      const newIndex = selectedIndex - 1;
      this.setState({ selectedIndex: newIndex });
    }
  }

  onArrowDownPressed() {
    this.focusInput();
    const { selectedIndex } = this.state;
    const options = this.getFilteredOptions();

    if (selectedIndex === null) {
      this.setState({ selectedIndex: 0 });
    } else if (selectedIndex < options.length - 1) {
      const newIndex = selectedIndex + 1;
      this.setState({ selectedIndex: newIndex });
    }
  }

  setValue(value) {
   this.setState({ value });
  }

  setKeyboardListener() {
    document.onkeydown = null;
    document.onkeydown = this.keyboardListener.bind(this);
  }

  getFilteredOptions() {
    const { value } = this.state;
    const { options, optimized } = this.props;
    const pattern = new RegExp(value, 'gi');

    if (!value) {
      return options;
    }

    const filteredOptions = options.filter((option) => (
      option.label.match(pattern)
    ));

    if (!optimized) {
      return filteredOptions;
    }

    const subOptionsArray = [];

    for (let index = 0; index < 30; index++) {
      const currentOption = filteredOptions[index];

      if (currentOption) {
        subOptionsArray.push(currentOption);
      }
    }

    return subOptionsArray;
  }

  keyboardListener(e) {
    const event = e || window.event;
    const options = this.getFilteredOptions();

    if (event.keyCode === 13) {
      this.onEnterPressed();
    } else if (event.keyCode === 38 && options.length) {
      this.onArrowUpPressed();
    } else if (event.keyCode === 40 && options.length) {
      this.onArrowDownPressed();
    }
  }

  focusInput() {
    this.Input.focus();
  }

  resetInput() {
    this.setState({ value: '', selectedIndex: null });
    this.focusInput();
  }


  // ------------------------------
  // ------ render methods --------
  // ------------------------------
  renderOptions() {
    if (!this.props.options.length) {
      return null;
    }

    if (this.props.optimized && this.state.value.length < 3) {
      return null;
    }

    const options = this.getFilteredOptions().map((option, index) => (
      <div key={`${index}-${option.value}`} style={{ paddingLeft: 5, paddingRight: 5 }}>
        <FadeInView delay={index * 50}>
          <HoverableButton
            option={option}
            index={index}
            selectedIndex={this.state.selectedIndex}
            onClick={this.onOptionClick.bind(this)}
          />
        </FadeInView>
      </div>
    ));

    return (
      <div style={styles.containerSub}>
        { options }
      </div>
    );
  }

  renderLoader() {
    const { loading } = this.props;

    if (loading) {
      return (
        <div style={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
          <Icon name="spinner" loading />
          <span>Cargando...</span>
        </div>
      );
    }

    return null;
  }

  render() {
    const { value } = this.state;
    const { label, placeholder } = this.props;

    return (
      <div style={styles.container}>
        <div style={{ padding: 0, border: 0 }}>
          <button style={{ background:window.colors.menu, padding: 0, border: 0, width: '100%', height: 74, textAlign: 'center', color: 'white' }}>
            { label.toUpperCase() }
          </button>

          <div style={styles.inputContainer}>
            <Input
              ref={(ref) => { this.Input = ref; }}
              placeholder={placeholder}
              value={value}
              onChange={this.onTextChanged.bind(this)}
              style={{ width: '100%', height: 31 }}
            />
          </div>
        </div>

        { this.renderOptions() }
        { this.renderLoader() }
      </div>
    );
  }
}


const styles = {
  container: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    backgroundColor:window.colors.black
  },
  containerSub: {
    overflowX: 'hidden',
    overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
    width: '100%',
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent'
  },
  inputContainer: {
    width: '100%',
    padding: 3,
    marginTop: 0,
    marginBottom: 5,
    height: 37,
    color: 'white',
    borderBottom: 'solid 1px rgb(240,240,240)'
  }
};


SidebarInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  optimized: PropTypes.bool,
};


export default SidebarInput;

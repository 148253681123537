import React, { useState } from 'react';
import { connect } from 'react-redux';
import internalAPI from '../../utils/internalAPI';
import Search from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import { Input, CircularProgress, Dialog, DialogTitle, ListItem, List, ListItemAvatar, Avatar, ListItemText, DialogActions, DialogContent, DialogContentText, Button, Typography, Divider, Slide, Box, Popover, IconButton, Paper } from '@material-ui/core';
import TextField from '../TextField';
import EmailCustomInput from '../EmailCustomInput';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const InvitationsModal = (props) => {
    const { type, onClose, formacionAcademica } = props;
    const [loading, setLoading] = useState(false);
    const [usersOptions, setUsersOptions] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchText, setSearchText] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(true);
    const getUsers = (e) => {
        const body = {
            texto: e.target.value || ''
        };
        setLoading(true);
        window.$('searchInput').focus();
        if (e.target.value.length) {
            const target = document.getElementById('searchBarItem');
            setAnchorEl(target)
        }
        setSearchText(e.target.value)
        internalAPI.searchUsuarios(body, (error, response) => {
            setLoading(false);
            if (error) {
                setUsersOptions([]);
            } else {
                const results = []
                const data = response.map((item) => {
                    const rol = formacionAcademica.find(c => c.id === item.detalle.id_rol);
                    if (rol)
                        results.push({
                            rol: rol.vc_nombre,
                            sexo: item.id_sexo === 1 ? 'Masculino' : 'Femenino',
                            ...item
                        })
                });
                setUsersOptions(results);
            }
            window.$('searchInput').focus();
        });
    }
    const addInterconsult = (user) => {
        const newUsers = [...users];
        newUsers.push(user)
        setUsers(newUsers);
        setAnchorEl(null);
        setSearchText('');
    }
    React.useEffect(() => {
        if (props.open && !open) {
            setOpen(true)
        }
    }, [props])
    return (
        <Dialog
            TransitionComponent={Transition}
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                {type === 'interConsult' ? 'Interconsultar' : 'Enviar Invitación'}
            </DialogTitle>
            {
                type === 'interConsult' &&
                <ListItem id='searchBarItem' style={{ padding: '10px 20px 0px 10px' }}>
                    <Search style={{ margin: 10 }} />
                    <Input
                        id='searchInput'
                        onChangeCapture={getUsers.bind(this)}
                        value={searchText || ''}
                        placeholder="Buscar..." disableUnderline style={{ width: '100%' }}
                        endAdornment={
                            <div style={{ width: 20, height: 20, padding: 3 }}>
                                {
                                    loading &&
                                    <CircularProgress size={15} color='secondary' />
                                }
                            </div>
                        }
                        autoFocus
                    />
                    <Paper
                        style={{ position: 'absolute', marginTop: 125, left: 4, width: 'calc(100% - 8px)', zIndex: 1000, height: anchorEl ? 200 : 0, overflow: 'hidden' }}>
                        <List style={{ height: 200, overflowY: 'scroll', width: anchorEl && anchorEl.width ? anchorEl.width : '100%' }}>
                            {usersOptions.map((user, index) => (
                                <ListItem button onClick={() => addInterconsult(user)} key={index}>
                                    <ListItemAvatar>
                                        <Avatar >
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={`${user.detalle.vc_nombre || ''} ${user.detalle.vc_apellidoPaterno || ''} ${user.detalle.vc_apellidoMaterno || ''}`.toUpperCase()} />
                                    <ListItemText secondary={`${user.rol || ''}`.toUpperCase()} />
                                </ListItem>
                            ))}
                            {
                                usersOptions.length === 0 && !loading &&
                                <Typography style={{ padding: 30, textAlign: 'center' }}>
                                    {'No hay coincidencias'}
                                </Typography>
                            }
                            {
                                loading &&
                                <ListItem key={'loading'} alignItems='center' style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress color='inherit' />
                                </ListItem>
                            }
                        </List>
                    </Paper>

                </ListItem>
            }
            {
                type === 'invited' &&
                <Box>
                    <Divider />
                    <Box style={{ padding: '8px 20px 10px 20px' }}>
                        <EmailCustomInput
                            onCharge={(vc_email) => {
                                const newUsers = [...users];
                                newUsers.push({
                                    detalle: {
                                        vc_email,
                                        vc_nombre: vc_email
                                    }
                                })
                                setUsers(newUsers);
                                setAnchorEl(null)
                            }}
                            key={'vc_email'}
                            fieldid={'vc_email'}
                            field={'vc_email'}
                            name={'vc_email'}
                            placeholder='Email...'
                            fullWidth={true}
                            readOnly={false}
                            value={props.vc_email || ''}
                            label={''}
                            regExp={new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)}
                            type={'email'}
                            errormessage={'E-mail no valido'}
                        />
                    </Box>
                </Box>
            }
            <Divider />
            <List style={{ height: 300, overflowY: 'scroll', minWidth: 400 }}>
                {users.map((user, index) => (
                    <ListItem button key={index}>
                        <ListItemAvatar>
                            <Avatar >
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${user.detalle.vc_nombre || ''} ${user.detalle.vc_apellidoPaterno || ''} ${user.detalle.vc_apellidoMaterno || ''}`.toUpperCase()} />
                        <IconButton onClick={() => {
                            const newUsers = [...users].filter(e => e !== user);
                            setUsers(newUsers);
                        }}>
                            <DeleteForeverOutlined />
                        </IconButton>
                    </ListItem>
                ))}
                {
                    users.length === 0 &&
                    <Typography style={{ padding: 30, textAlign: 'center' }}>
                        {'No hay coincidencias'}
                    </Typography>
                }
            </List>
            <Divider />
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false)
                    onClose();
                }} color="primary">
                    {'Cancelar'}
                </Button>
                {
                    users.length > 0 &&
                    <Button onClick={() => {
                        setOpen(false)
                        onClose(users);
                    }} color="primary">
                        {'Enviar'}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
const mapStateToProps = (state) => {
    const { pacienteActual, formacionAcademica } = state.Think;
    return { pacienteActual, formacionAcademica };
};
export default connect(mapStateToProps, null)(InvitationsModal);

import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Box, IconButton, ListItem, ListItemAvatar, Avatar, ListItemText, Dialog, DialogTitle, List, Popover, Input, SnackbarContent, Snackbar, Button } from '@material-ui/core';
import ContactsOutlined from '@material-ui/icons/ContactsOutlined';
import PersonalVideoOutlined from '@material-ui/icons/PersonalVideoOutlined';
import ChevronRightOutlined from '@material-ui/icons/ChevronRightOutlined';
import Person from '@material-ui/icons/Person';
import notifications from '../../utils/notifications';
import InvitationsModal from './InvitationsModal';
import internalAPI from '../../utils/internalAPI';
const Index = (props) => {
    const { api, roomName, pacienteActual } = props;
    const [anchorEl, setAnchorEl] = useState(null)
    const [notifications, setNotifications] = useState([])
    const [showInvitations, setShowInvitations] = useState(null)
    const participants = api ? Object.values((api._participants || {})) || [] : [];

    const sendMessage = ({ type, detalle }) => {
        const vc_email = detalle.vc_email || null;
        let newNotifications = [];
        if (vc_email) {
            let body = {
                vc_titulo: 'Video Consulta Thin-k Health',
                vc_mensaje: 'Hola tienes una invitación para Video Consulta Thin-k Health',
                vc_url: `https://salud.thin-k.mx/#/videoconsulta/${roomName}`,
                vc_email,
                vc_asunto: 'Thin-k Health Video Consulta'
            }
            switch (type) {
                case 'interConsult': {
                    body.vc_titulo = 'Video Interconsulta Thin-k Health';
                    body.vc_mensaje = 'Hola tienes una invitación para Video Interconsulta en la plataforma Thin-k Health';
                    body.vc_asunto = 'Thin-k Health Video Interconsulta';
                    break;
                }
                default: break;
            }
            console.log('roomName---->', roomName)
            internalAPI.sendMessage(body, (error, response) => {
                if (error) {
                    console.log('error', error)
                    newNotifications.push({
                        vc_email: body.vc_email,
                        vc_message: 'Error al enviar la invitación' + ' al correo: ' + body.vc_email
                    })
                } else {
                    const newNotifications = [...notifications];
                    console.log('message', response)
                    newNotifications.push({
                        vc_email: body.vc_email,
                        vc_message: response.message ? response.message + ' a la dirección: ' + body.vc_email : 'Mensaje no identificado.'
                    })
                    setNotifications(newNotifications);
                }
            })
        }
    }
    const sendMessageInternal = () => {
        // api.executeCommand('password', password);
        const participantsIds = api ? Object.keys((api._participants || {})) || [] : [];
        const participants = (api ? Object.values((api._participants || {})) || [] : []).map((participant, index) => {
            return {
                ...participant,
                id: participantsIds[index]
            }
        });
        api.executeCommand('sendEndpointTextMessage', 'holaa')

    }
    return (
        <Box>
            <IconButton onClick={(e) => setAnchorEl(anchorEl ? null : e.target)}>
                <ContactsOutlined />
            </IconButton>
            <Popover
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl} onClose={() => setAnchorEl(null)} aria-labelledby="simple-dialog-title" open={!!anchorEl}>
                <List>
                    {participants.map((participant, index) => {
                        return (
                            <ListItem button key={index} onClick={() => {
                                sendMessageInternal()
                            }}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Person />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={participant.formattedDisplayName || ''} />
                            </ListItem>
                        )
                    })}
                    {
                        (pacienteActual && pacienteActual.detalle && pacienteActual.detalle.vc_email) ?
                        <ListItem button onClick={() => {
                            const { pacienteActual } = props;
                            if (pacienteActual && pacienteActual.detalle && pacienteActual.detalle.vc_email) {
                                sendMessage({ type: 'invited', detalle: pacienteActual.detalle });
                            }
                            setAnchorEl(null)
                        }} key={'addPerson'}>
                            <ListItemAvatar>
                                <Avatar>
                                    <PersonalVideoOutlined />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`Invitar a paciente y/o tutor (${pacienteActual.detalle.vc_email})`} />
                            <ChevronRightOutlined style={{ float: 'right' }} />
                        </ListItem>
                        : 
                        null
                    }
                    <ListItem button onClick={() => {
                        setShowInvitations('invited')
                        setAnchorEl(null)
                    }} key={'addPerson'}>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonalVideoOutlined />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Invitar a Familiar" />
                        <ChevronRightOutlined style={{ float: 'right' }} />
                    </ListItem>
                    <ListItem button onClick={() => {
                        setShowInvitations('interConsult')
                        setAnchorEl(null)
                    }} key={'addMedic'}>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonalVideoOutlined />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Interconsultar a Medico" />
                        <ChevronRightOutlined style={{ float: 'right' }} />
                    </ListItem>
                </List>
            </Popover>
            {
                !!showInvitations &&
                <InvitationsModal
                    type={showInvitations}
                    open={showInvitations}
                    onClose={(users) => {
                        setShowInvitations(null)
                        console.log('users finish', users)
                        if (users) {
                            users.map((user, index) => {
                                sendMessage({ ...user, type: showInvitations })
                            })
                        }
                    }}
                />
            }
            {
                notifications.map((notification, index) => {
                    console.log('notification', notification)
                    return (
                        <Snackbar
                            key={index}
                            onClose={() => {
                                setNotifications(notifications.filter(e => e !== notification));
                            }}
                            open={true} autoHideDuration={2000}>
                            <SnackbarContent color='inherit'
                                message={notification.vc_message || '¿?'}
                                action={
                                    <Button
                                        color='primary'
                                    >
                                        {'Ok'}
                                    </Button>
                                }
                            />
                        </Snackbar>
                    )
                })
            }
        </Box>
    )
}

const mapStateToProps = (state) => {
    const { pacienteActual } = state.Think;
    return { pacienteActual };
};


export default connect(mapStateToProps)(Index);
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  List,
  ListItem,
  ListItemText,
  DialogContentText,
  Box,
  Grid,
  ListItemIcon,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@material-ui/core";
import {
  SubjectOutlined,
  Assignment,
  ListAlt,
  Search as SearchIcon,
} from "@material-ui/icons";
import Input from "app/components/Form/Input";
import Select from "app/components/Form/Select";
import { isEqual } from "lodash";

function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Index = (props) => {
  const { onChange, data } = props;
  const [search, setSearch] = React.useState("");
  const [key, setKey] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [tipoNota, setTipoNota] = React.useState(null);
  const [preguntasBase, setPreguntasBase] = React.useState(true);
  const [preguntas, setPreguntas] = React.useState([]);
  const [preguntasFiltered, setPreguntasFiltered] = React.useState([]);
  const [preguntasSelected, setPreguntasSelected] = React.useState([]);

  const prevData = usePrevious(data);

  const handlePreguntasSelected = (id) => {
    if (preguntasSelected.includes(id))
      setPreguntasSelected(preguntasSelected.filter((v) => v !== id));
    else setPreguntasSelected([...preguntasSelected, id]);
  };

  React.useEffect(() => {
    const aux = props.preguntas
      .filter((v) => [3, 4].includes(v.id_tipoPregunta))
      .filter((v) => v.versiones.some((v) => v.id_preguntaEnlace))
      .map((v) => ({
        ...v,
        version: [...v.versiones].pop(),
      }));

    setPreguntas(aux);
  }, []);

  React.useEffect(() => {
    setPreguntasFiltered(
      preguntas.filter((v) =>
        v.version.vc_pregunta.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, preguntas]);

  React.useEffect(() => {
    if (!isEqual(prevData, data)) {
      console.log('DATOS', data)
      setNombre(data.nombre || "");
      setPreguntasSelected(data.preguntas || []);
      setTipoNota(data.tipoNota || null);
      setPreguntasBase("preguntasBase" in data ? data.preguntasBase : true);
      setKey(data.key || "");
    }
  }, [data]);

  React.useEffect(() => {
    onChange({
      key,
      nombre,
      preguntas: preguntasSelected,
      tipoNota,
      preguntasBase,
    });
  }, [key, nombre, preguntasSelected, tipoNota, preguntasBase]);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Grid container key="nombre">
        <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
          <DialogContentText>
            <SubjectOutlined />
          </DialogContentText>
        </Grid>
        <Grid item xs={11}>
          <Input
            placeholder="Nombre del formulario*"
            value={nombre}
            onChange={setNombre}
          />
        </Grid>
      </Grid>

      <Grid container key="tipoNota">
        <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
          <DialogContentText>
            <Assignment />
          </DialogContentText>
        </Grid>
        <Grid item xs={11}>
          <Select
            placeholder="Tipo de nota*"
            value={tipoNota}
            onClick={(option) => setTipoNota(option.value)}
            data={[
              { value: 1, text: "Nota de Triage" },
              { value: 2, text: "Nota de Enfermeria" },
              { value: 3, text: "Nota de Valoracion Obstetrica Ambulatoria" },
              { value: 4, text: "Nota de Consulta" },
              { value: 5, text: "Nota Inicial" },
              { value: 6, text: "Nota de Ingreso" },
              { value: 7, text: "Nota de Evolucion" },
              { value: 8, text: "Nota de Interconsulta" },
              { value: 9, text: "Nota Pretransfusional" },
              { value: 10, text: "Nota Postrasfusional" },
              { value: 11, text: "Nota de Referencia" },
              { value: 12, text: "Nota de Traslado" },
              { value: 13, text: "Nota Postoperatoria" },
              { value: 14, text: "Nota Preoperatoria" },
              {
                value: 15,
                text: "Nota Preanestesica, Vigilancia y Registro Anestesico",
              },
              { value: 16, text: "Nota de Defuncion" },
              { value: 17, text: "Nota de Muerte Fetal" },
              { value: 18, text: "Nota de Egreso" },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container key="preguntasbase">
        <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
          <DialogContentText>
            <ListAlt />
          </DialogContentText>
        </Grid>
        <Grid item xs={11}>
          <Box pb={1}>
            <FormControlLabel
              label="Utilizar preguntas base"
              control={
                <Checkbox
                  checked={preguntasBase}
                  onChange={(e) => {
                    setPreguntasBase(e.target.checked);
                  }}
                  color="primary"
                />
              }
            />
          </Box>
        </Grid>
      </Grid>

      <Divider />
      <DialogContentText style={{ margin: "12px 0px" }}>
        Seleccionar las preguntas.
      </DialogContentText>
      <Input
        dense
        placeholder="Buscar"
        value={search}
        onChange={setSearch}
        style={{ margin: 0 }}
        startAdornment={<SearchIcon style={{ margin: "0px 8px" }} />}
      />
      <Box height="0px" overflow="scroll" flexGrow={1}>
        <List>
          {preguntasFiltered.map((p, i) => (
            <ListItem
              alignItems="flex-start"
              key={i}
              button
              dense
              onClick={() => handlePreguntasSelected(p.id)}
              style={{ alignItems: "center" }}
            >
              <ListItemIcon style={{ margin: 0 }}>
                <Checkbox
                  edge="start"
                  checked={preguntasSelected.includes(p.id)}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText primary={p.version.vc_pregunta} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

Index.defaultProps = {
  data: {},
  preguntas: [],
  onChange: () => {},
};

Index.propTypes = {
  data: PropTypes.object,
  preguntas: PropTypes.array,
  onChange: PropTypes.func,
};

const actions = {};

const mapStateToProps = (state) => {
  const { preguntas } = state.Think;

  return { preguntas };
};

export default connect(mapStateToProps, actions)(Index);

import httpAdmin from '../httpAdmin';
import http from '../http';

export default {
    editarCrearInstitucion(body, callback) {
        const headers = { Authorization: window.amplify.store('token') };
        let urlCreate = '/app/api/institucion/crear';
        if (body.id) {
            urlCreate = `/app/api/institucion/editar`;
        }
        body.dependencias = [];
        http.post(urlCreate, headers, body, (err, response) => {
            callback(err, response);
        });
    },
    clientesStartup(data, callback) {
        const headers = { Authorization: window.amplify.store('token') };
        let method = 'post';
        let url = '/api/v1/clientes/startup';

        httpAdmin.post(url, headers, data, (err, response) => {
            callback(err, response);
        });
    }
};

/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Notas from "./Notas";
import Solicitudes from "./Solicitudes";
import Diagnosticos from "./Diagnosticos";
import Tratamientos from "./Tratamientos";
import Procedimientos from "./Procedimientos";
import Multimedia from "./Multimedia";
import TimeLine from "./TimeLine";

import { Box } from "@material-ui/core";

import { Modal, Loader } from "semantic-ui-react";
import { menuNotas as menu } from "app/containers/Think/listados";

const getContent = (modulo) => {
  switch (modulo) {
    case "notas":
      return <Notas />;
    case "solicitudes":
      return <Solicitudes />;
    case "diagnosticos":
      return <Diagnosticos />;
    case "tratamientos":
      return <Tratamientos />;
    case "procedimientos":
      return <Procedimientos />;
    case "multimedia":
      return <Multimedia />;
    case "timeLine":
      return <TimeLine />;
    default:
      return <Box />;
  }
};

const index = (props) => {
  const [moduloActual, setModuloActual] = useState("notas");
  const [content, setContent] = useState(null);
  const [loading, setLoading] = React.useState(null);

  React.useEffect(() => {
    const options = menu
      .map((option) => ({
        ...option,
        onClick: (m) => {
          setModuloActual(m.id);
        },
      }))
      .filter((v) => v.id !== "callcenter");

    props.setSubMenuOptions(options);
    props.setActiveView("notas");
  }, []);

  React.useEffect(() => {
    const modulo = menu.find((v) => v.id === moduloActual);
    const { opciones = [] } = modulo || {};

    setContent(
      React.cloneElement(getContent(moduloActual), {
        navigate: props.navigate,
        opciones,
      })
    );
  }, [moduloActual]);

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      {content}
      <Modal
        open={!!loading}
        style={{
          boxShadow: "1px 0px 10px rgba(0, 0,0, 0)",
          backgroundColor: "rgba(0, 0,0, 0.9)",
        }}
      >
        <Loader size="massive" active>
          {loading}
        </Loader>
      </Modal>
    </Box>
  );
};

index.propTypes = {
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  navigate: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const actions = {};

export default connect(mapStateToProps, actions)(index);

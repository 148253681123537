export const LOGIN = 'login';
export const LOG_OUT = 'log_out';
export const SELECT_MODULE = 'select_module';
export const SELECT_MEETING = 'select_meeting';
export const PACIENTE_ACTUAL = 'paciente_actual';
export const PREGUNTA_ACTUAL = 'pregunta_actual';
export const PREGUNTAS_NOTAS = 'preguntas_notas';
export const SET_HELPER_SIDEBAR_RENDER = 'set_helper_sidebar_render';
export const RESET_HELPER_SIDEBAR_RENDER = 'reset_helper_sidebar_render';
export const UPDATE_CATALOGS = 'update_catalogs';
export const SET_INSTITUCION = 'set_institucion';
export const SET_CHANGE_DISPLAY_SIDEBAR = 'set_change_display_sidebar';
export const SET_CHANGE_DISPLAY_MENU = 'set_change_display_menu';
export const SET_ITEM_EDIT = 'set_edit_item';
export const SET_STATE = 'set_state';
export const SET_THEME = 'set_theme';
export const SET_LIBS = 'set_libs';

// colors
export const RED = 'rgb(156,21,47)';

import React from 'react';
import { Paper, Typography, Grid, Box, Image } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
const themeSelected = window.amplify.store('theme') || null;

class CreditCardItem extends React.Component {
    constructor() {
        super();
        this.state = {
            someKey: 'someValue'
        };
    }

    render() {
        const { vc_ultimosDigitos, vc_nombre, ultimosDigitos, nombre, marca } = this.props.paymentMethod || {};;
        const primary = themeSelected ? themeSelected.primary : teal;
        return (
            <Grid container alignItems='center' alignContent='center' justify='center'>
                <Grid item style={{ alignSelf: 'center', backgroundImage: `linear-gradient(${primary[300]},${primary[600]})`, borderRadius: '3mm', height: '54mm', width: '85mm', padding: '6mm', boxShadow: '0px 3px 20px rgba(0,0,0,0.5)' }}>
                    <Grid container style={{ width: '100%', marginTop: '3mm', fontSize: '1.4rem', textAlign: 'center', height: '8mm' }}>
                        <Grid item xs={3}>
                            <Typography style={{ fontSize: '1.4rem' }}>
                                {'* * * *'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{ fontSize: '1.4rem' }}>
                                {'* * * *'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{ fontSize: '1.4rem' }}>
                                {'* * * *'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{ fontSize: '1.4rem' }}>
                                {ultimosDigitos || vc_ultimosDigitos || ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{ width: '100%', marginTop: '8mm', textAlign: 'center', height: '8mm' }}>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{ fontSize: '1.2rem' }}>
                                {'* *'}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography style={{ fontSize: '1.2rem' }}>
                                {'/'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{ fontSize: '1.2rem' }}>
                                {'* * * *'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{ width: '100%', marginTop: '2mm', fontSize: '1.4rem', textAlign: 'left', height: '8mm' }}>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: '1.2rem' }}>
                            {nombre || vc_nombre || ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{ width: '100%', fontSize: '1.4rem', textAlign: 'center' }}>
                        <Grid item xs={10}>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{ fontSize: '1.4rem' }}>
                                {marca || ''}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Image style={{ borderRadius: 5, overflow: 'hidden', marginBottom: 10, height: '15vw', width: '15vw', position:'absolute', bottom: 8, right: 8 }} size='small' src={'assets/images/logoOpenPay.png'} /> */}
            </Grid>
        );
    }

    componentDidMount() {
        this.setState({
            someKey: 'otherValue'
        });
    }
}

export default CreditCardItem;

import React from "react";

import { connect } from "react-redux";
import { Box, Paper, Typography, Button } from "@material-ui/core";
import * as firebase from "firebase/app";
import { styled } from "@material-ui/core/styles";
import { FadeInView } from "../../../components";
import moment from "moment";
import _ from "lodash";

import SubMenuModulos from "../../../material-ui/SubMenuModulos";
import ConfirmAlert from "../../../material-ui/ConfirmAlert";

import ModalForm from "./ModalForm";
import MUIDataTable from "mui-datatables";

import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "../../../containers/Think/actions";

import Sidebar from "./Sidebar";

const currencyFormat = (num) =>
  `$ ${Number(num)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

const tiposProductos = [
  { id: 1, vc_nombre: "Producto" },
  { id: 2, vc_nombre: "Servicio" },
  { id: 3, vc_nombre: "Procedimiento" },
];
const areasProductos = [
  { id: 1, vc_nombre: "Doctor y area de curacion" },
  { id: 2, vc_nombre: "Podologia" },
  { id: 3, vc_nombre: "Farmacia / Inventario" },
];

const Index = (props) => {
  const institucion = window.amplify.store("institution") || {};
  const db = firebase.database().ref();
  const [products, setProducts] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [rowSelected, setRowSelected] = React.useState({
    index: -1,
    row: null,
  });
  const [modalForm, setModalForm] = React.useState(false);
  const [modalConfirm, setModalConfirm] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    props.setDisplaySidebar(false);
    props.setHelperSidebarRender(null);

    if (institucion.id_institucion) {
      const ref = db.child(
        `erp/branches_products/${institucion.id_institucion}`
      );

      ref.on("value", (snapshot) => {
        let values = [];

        if (snapshot.val() != null) {
          values = Object.values(snapshot.val()) || [];
        }
        console.log("prodcutos", values);
        setProducts(values);
      });

      return () => {
        ref.off();
      };
    }
  }, []);

  React.useEffect(() => {
    const dataAux = products
      .map((v) => ({
        nombre: v.vc_description,
        unidadMedida: v.measureUnit,
        tipo: tiposProductos.find((t) => t.id == v.id_type).vc_nombre,
        area: areasProductos.find((a) => a.id == v.id_productArea).vc_nombre,
        precioUnitario: currencyFormat(v.nu_unitValue),
        iva: v.nu_tax,
        total: currencyFormat(v.nu_total),
        ...v,
      }))
      .filter((v) =>
        v.vc_description.toLowerCase().includes(searchText.toLowerCase())
      );

    if (rowSelected.row) {
      const index = dataAux.findIndex((v) => v.id == rowSelected.row.id);
      setRowSelected({ index, row: dataAux[index] });
    }

    setData(dataAux);
  }, [products, searchText]);

  React.useEffect(() => {
    props.setHelperSidebarRender(() =>
      rowSelected.row ? (
        <Sidebar
          data={rowSelected.row}
          editar={() => setModalForm(true)}
          eliminar={() => setModalConfirm(true)}
        />
      ) : null
    );
    props.setDisplaySidebar(Boolean(rowSelected.row));
  }, [rowSelected]);

  const handleSelect = (row, index) => {
    const newRowSelected = { index: -1, row: null };

    if (rowSelected.index !== index) {
      newRowSelected.index = index;
      newRowSelected.row = row;
    }

    setRowSelected(newRowSelected);
  };

  const eliminarProducto = async () => {
    const refstring = `erp/branches_products/${institucion.id_institucion}`;
    const { row: producto } = rowSelected;
    let id_product = null;
    let key = null;

    id_product = producto.id_product;

    const snapshot = await db
      .child(refstring)
      .orderByChild("id_product")
      .equalTo(producto.id_product)
      .once("value");

    snapshot.forEach((a) => {
      key = a.key;
    });

    await db.child(`${refstring}/${key}`).remove();

    setModalConfirm(false);
    handleSelect(null, -1);
  };

  const guardarProducto = async (producto) => {
    const refstring = `erp/branches_products/${institucion.id_institucion}`;

    let id_product = null;
    let key = null;
    if (producto.id_product) {
      id_product = producto.id_product;

      const snapshot = await db
        .child(refstring)
        .orderByChild("id_product")
        .equalTo(producto.id_product)
        .once("value");

      snapshot.forEach((a) => {
        key = a.key;
      });
    } else {
      key = firebase.database().ref().child("posts").push().key;
      id_product = key + 1;
    }

    await db.child(`${refstring}/${key}`).set({
      ..._.pick(producto, [
        "id_product",
        "id_productArea",
        "id_type",
        "measureUnit",
        "nu_tax",
        "vc_description",
      ]),
      nu_unitValue: Number(producto.nu_unitValue),
      nu_total: Number(producto.nu_total),
      id_product,
    });

    setModalForm(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 48px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SubMenuModulos
        title={
          <Button
            color="inherit"
            onClick={() => {
              handleSelect(null, -1);
              setModalForm(true);
            }}
          >
            Agregar
          </Button>
        }
        type={"search"}
        searchText={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      {modalForm && (
        <ModalForm
          open
          close={() => setModalForm(false)}
          accept={guardarProducto}
          data={rowSelected.row}
        />
      )}

      {modalConfirm && (
        <ConfirmAlert
          title={`¿Desea eliminar el producto: ${rowSelected.row?.vc_description}?`}
          onAccept={eliminarProducto}
          onCancel={() => setModalConfirm(false)}
        />
      )}

      <div
        style={{
          ...styles.principal,
          flex: 1,
          ...{ height: "calc(100% - 47px)" },
          padding: "10px",
        }}
      >
        <Table data={data} onClick={handleSelect} />
      </div>
    </div>
  );
};

const Table = (props) => {
  const { data = [], onClick = () => {} } = props;
  const options = { filter: true, sort: true };

  const columns = [
    {
      name: "nombre",
      label: "Nombre",
      options,
    },
    {
      name: "unidadMedida",
      label: "Unidad de Medida",
      options,
    },
    {
      name: "tipo",
      label: "Tipo",
      options,
    },
    {
      name: "area",
      label: "Area",
      options,
    },
    {
      name: "precioUnitario",
      label: "Precio Unitario",
      options,
    },
    {
      name: "iva",
      label: "Iva",
      options,
    },
    {
      name: "total",
      label: "Total",
      options,
    },
  ];

  const optionsTable = {
    elevation: 0,
    search: false,
    filter: false,
    fixedSelectColumn: false,
    print: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    onRowClick(rowData, { dataIndex }) {
      onClick(data[dataIndex], dataIndex);
    },
  };
  return (
    <FadeInView>
      <MUIDataTable
        title="Productos"
        data={data}
        columns={columns}
        options={optionsTable}
      />
    </FadeInView>
  );
};

const styles = {
  container: {
    overflowX: "hidden",
    overflowY: "hidden",
    height: "100vh",
    width: "100%",
    padding: 0,
    margin: 0,
  },
  principal: {
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    overflowX: "hidden",
    padding: 0,
    paddingBottom: 0,
    margin: 0,
    height: "calc(100vh - 95px)",
    width: "100%",
  },
  rightHeader: {
    color: "white",
    background: "transparent",
    textAlign: "center",
    margin: 0,
  },
  centralColumn: {
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    background: "rgba(50,50,50,0.4)",
    padding: 0,
    paddingTop: 0,
    margin: 0,
    width: "100%",
    height: window.innerHeight,
  },
  item: {
    padding: 0,
    paddingBottom: 3,
    margin: 0,
    width: "100%",
  },
};
const actions = { setHelperSidebarRender, setDisplaySidebar };
const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  return {
    catalogo: catalogos.find((e) => e.vc_nameTable === "ins_pacientes"),
  };
};

export default connect(mapStateToProps, actions)(Index);

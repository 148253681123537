import React, { Component } from 'react';
import { Grid, Form, Input } from 'semantic-ui-react';
import _ from 'lodash';

const { Row, Column } = Grid;


// crear componente
class ConfirmPasswordInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            secondValue: '',
            valid: false,
            message: `El campo ${props.label} es requerido`,
            dirty: false
        };
    }


    // -----------------------------
    // ------ life cycle events ----
    // -----------------------------
    componentDidMount() {
        this.setForm();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextState, this.state) || !_.isEqual(nextProps, this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.eq(prevState, this.state)) {
            this.setForm();
        }
    }


    // -----------------------
    // ------ user events ----
    // -----------------------
    onChange(e) {
        // actualizar valor
        this.setState({ value: e.target.value });

        // validar input
        if (!e.target.value) {
            this.setState({ 
                valid: false, 
                message: 'El campo de contraseña es requerido' 
            });
        } else if (e.target.value && !this.state.secondValue) {
            this.setState({ 
                valid: false, 
                message: 'Falta la confirmación de la contraseña' 
            });
        } else if (this.state.dirty && e.target.value !== this.state.secondValue) {
            this.setState({ 
                valid: false, 
                message: 'Las contraseñas no coinciden' 
            });
        } else if (e.target.value === this.state.secondValue) {
            this.setState({ valid: true, message: '' });
        }
    }

    onChangeSecond(e) {
        // actualizar valor
        this.setState({ secondValue: e.target.value, dirty: true });

        // validar inputs
        if (this.state.value !== '' && this.state.value === e.target.value) {
            this.setState({ valid: true, message: '' });
        } else {
            this.setState({ valid: false, message: 'Las contraseñas no coinciden' });
        }
    }

    setForm() {
        this.props.setFormData(this.props.name, this.state);
    }

    getValue() { 
        return this.state.value;
    }

    dirtInput() {
        this.setState({ dirty: true });
    }

    resetInput() {
        this.setState({
            value: '',
            secondValue: '',
            valid: false,
            message: `El campo ${this.props.label} es requerido`,
            dirty: false
        });
    }


    // --------------------------
    // ------ render methods ----
    // --------------------------
    renderInputs() {
        const invalidInput = this.state.dirty && !this.state.valid;
        let className = '';
        let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
        let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

        if (this.props.className) {
            className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
        } else {
            className = invalidInput ? 'invalid' : 'valid';
        }

        if (invalidInput) {
            finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
            finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
        }

        if (this.props.display === 'row' && !this.props.inlineLabel) {
            return [
                <Row key={1}>
                    <Column textAlign="left">
                        <label style={finalLabelStyle}>
                            { this.props.label }
                        </label>
                        <input
                            type="password"
                            autocomplete="off"
                            value={this.state.value}
                            name={this.props.name}
                            className={className}
                            onChange={this.onChange.bind(this)}
                            placeholder={this.props.placeholder}
                            style={finalInputStyle}
                            onClick={() => this.props.callbackSelectInput()}
                        />
                    </Column>
                </Row>,
                <Row key={2}>
                    <Column textAlign="left">
                        <label style={finalLabelStyle}>
                            Confirmar contraseña
                        </label>
                        <input
                            type="password"
                            autocomplete="off"
                            value={this.state.secondValue}
                            name={`confirmacion${this.props.name}`}
                            className={className}
                            onChange={this.onChangeSecond.bind(this)}
                            placeholder={this.props.secondPlaceholder}
                            style={finalInputStyle}
                            onClick={() => this.props.callbackSelectInput()}
                        />
                    </Column>
                </Row>
            ];
        }

        if (this.props.display === 'row' && this.props.inlineLabel) {
            return [
                <Row key={1}>
                    <Column textAlign="left">
                        <input
                            label={this.props.label}
                            type="password"
                            autocomplete="off"
                            value={this.state.value}
                            name={this.props.name}
                            className={className}
                            onChange={this.onChange.bind(this)}
                            placeholder={this.props.placeholder}
                            style={finalInputStyle}
                            onClick={() => this.props.callbackSelectInput()}
                        />
                    </Column>
                </Row>,
                <Row key={2}>
                    <Column textAlign="left">
                        <input
                            label="Confirmar contraseña"
                            autocomplete="off"
                            type="password"
                            value={this.state.secondValue}
                            name={`confirmacion${this.props.name}`}
                            className={className}
                            onChange={this.onChangeSecond.bind(this)}
                            placeholder={this.props.secondPlaceholder}
                            style={finalInputStyle}
                            onClick={() => this.props.callbackSelectInput()}
                        />
                    </Column>
                </Row>
            ];
        } 
        
        if (this.props.display === 'column' && !this.props.inlineLabel) {
            return (
                <Row>
                    <Column width={8} textAlign="left">
                        <label style={finalLabelStyle}>
                            { this.props.label }
                        </label>
                        <input
                            type="password"
                            autocomplete="off"
                            value={this.state.value}
                            name={this.props.name}
                            className={className}
                            onChange={this.onChange.bind(this)}
                            placeholder={this.props.placeholder}
                            style={finalInputStyle}
                            onClick={() => this.props.callbackSelectInput()}
                        />
                    </Column>
                    <Column width={8}>
                        <label style={finalLabelStyle}>
                            Confirmar contraseña
                        </label>
                        <input
                            type="password"
                            autocomplete="off"
                            value={this.state.secondValue}
                            name={`confirmacion${this.props.name}`}
                            className={className}
                            onChange={this.onChangeSecond.bind(this)}
                            placeholder={this.props.secondPlaceholder}
                            style={finalInputStyle}
                            onClick={() => this.props.callbackSelectInput()}
                        />
                    </Column>
                </Row>
            );
        }
        if (this.props.display === 'column' && this.props.inlineLabel) {
            return (
                <Row>
                    <Column width={8} textAlign="left">
                        <input
                            label={this.props.label}
                            type="password"
                            value={this.state.value}
                            name={this.props.name}
                            className={className}
                            onChange={this.onChange.bind(this)}
                            placeholder={this.props.placeholder}
                            style={finalInputStyle}
                            onClick={() => this.props.callbackSelectInput()}
                        />
                    </Column>
                    <Column width={8}>
                        <input
                            label="Confirmar contraseña"
                            type="password"
                            value={this.state.secondValue}
                            name={`confirmacion${this.props.name}`}
                            className={className}
                            onChange={this.onChangeSecond.bind(this)}
                            placeholder={this.props.secondPlaceholder}
                            style={finalInputStyle}
                            onClick={() => this.props.callbackSelectInput()}
                        />
                    </Column>
                </Row>
            );
        }
    }

    render() {
        return (
            <Form.Field>
                <Grid stackable>
                    { this.renderInputs() }
                </Grid>
            </Form.Field>
        );
    }
}


// estilos
const styles = {
    label: {

    },
    errorLabel: {
        color: '#9F3A38'
    },
    input: {

    },
    errorInput: {
        background: 'rgba(224, 180, 180, 0.48)',
        border: '1px solid #9F3A38',
        color: '#9F3A38'
    }
};


// exportar componente
export default ConfirmPasswordInput;

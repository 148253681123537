import React from "react";

import {
  Button,
  Input,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
} from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";
import { AttachMoney, Sort, Payment } from "@material-ui/icons";
import moment from "moment";
import _ from "lodash";
import InputSelect from "../InputSelect";
import PrintTicket from "./PrintTicketAbono";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const round = (num) => {
  const indexOf = `${num}`.indexOf(".");
  if (indexOf > 0) return Number(`${num}`.slice(0, indexOf + 3));
  return num;
};

const payMethods = [
  { value: "efectivo", text: "Efectivo" },
  { value: "cheque", text: "Cheque" },
  { value: "tarjeta", text: "Tarjeta" },
  { value: "transferencia", text: "Transferencia" },
  { value: "vale", text: "Vale de Descuento" },
];
const cardTypes = [
  { value: "debito", text: "Debito" },
  { value: "credito", text: "Credito" },
];

export default ({
  open,
  data: { account, products, pays, cashRegister } = {},
  close,
  abonar,
}) => {
  const abono = pays.reduce((sum, v) => v.nu_quantity + sum, 0);
  const productsTotal = products.map((v) => {
    const nu_unitValue = Number(`${v.nu_unitValue}`.split(",").join(""));
    let subtotal = v.nu_quantity * nu_unitValue;
    const descuento = (subtotal * (v.nu_discount || 0)) / 100;

    subtotal -= descuento;

    const iva = round(subtotal * (v.nu_tax > -1 ? v.nu_tax : 0.16));
    const total = subtotal + iva;
    return {
      ...v,
      subtotal,
      descuento,
      total,
      iva,
    };
  });

  const iva = productsTotal.reduce((sum, v) => sum + v.iva, 0);
  const subtotal = productsTotal.reduce((sum, v) => sum + v.subtotal, 0);
  const total = productsTotal.reduce((sum, v) => sum + v.total, 0);

  const [disabled, setDisabled] = React.useState(false);

  const [deuda, setDeuda] = React.useState(0);
  const [recibido, setRecibido] = React.useState(0);
  const [cambio, setCambio] = React.useState(0);

  const [referencia, setReferencia] = React.useState("");
  const [pay, setPay] = React.useState({});
  const [payMethod, setPayMethod] = React.useState("efectivo");
  const [cardType, setCardType] = React.useState("debito");
  const ticketsRef = React.useRef(null);

  React.useEffect(() => {
    const institucion = window.amplify.store("institution") || {};
    const usuario = (window.amplify.store("user") || {}).usuario || {
      vc_nombre: "",
    };

    setDisabled(
      deuda <= 0 ||
        recibido <= 0 ||
        (["tarjeta", "cheque"].includes(payMethod) && !referencia)
    );

    setPay({
      folio: moment().unix(),
      institucion: {
        id_usuario: institucion.id_usuario,
        vc_urlLogo: institucion.vc_urlLogo,
        vc_razonSocial: institucion.vc_razonSocial,
        vc_calle: institucion.vc_calle,
        vc_colonia: institucion.vc_colonia,
        vc_codigoPostal: institucion.vc_codigoPostal,
      },
      id_cashRegister: cashRegister.id,
      id_cut: cashRegister.cut.id,
      id_user: institucion.id_usuario,
      id_method: payMethod,
      id_cardType: payMethod == "tarjeta" ? cardType : "",
      vc_reference: ["tarjeta", "cheque", "vale"].includes(payMethod)
        ? referencia
        : "",
      vc_nameUser: `${usuario.vc_nombre} ${usuario.vc_apellidoPaterno || ""} ${
        usuario.vc_apellidoMaterno || ""
      }`,
      dt_register: moment() * 1,
      cardEnd: null,
      documentNumber: null,
      nu_received: Number(recibido),
      nu_change: Number(cambio),
      nu_quantity: Number(recibido) - Number(cambio),
      details: products,
      pays: pays.map((v) => ({ nu_quantity: v.nu_quantity })),

      subTotal: subtotal,
      iva,
      total,
    });
  }, [recibido, cambio, payMethod, cardType, referencia]);

  React.useEffect(() => {
    const rest = total - abono;
    const deuda = Number((total - abono).toFixed(2));
    // const deuda = (rest > 0 ? rest : 0).toFixed(2);

    setDeuda(deuda);
    setRecibido(deuda);
  }, [products, pays]);

  React.useEffect(() => {
    let cambioAux = 0;

    if (Number(recibido) > Number(deuda)) cambioAux = recibido - deuda;

    setCambio(cambioAux.toFixed(2));
  }, [recibido]);

  const handleChangeRecibido = (e) => {
    let value = e.target.value;
    const DECIMALREG = /^\d{1,}(\.\d{0,4})?$/;
    const match = value.match(DECIMALREG);

    if (value !== "" && !Number(value)) return;

    if (!!match && !!match[1] && match[1].length > 3)
      value = Number(value).toFixed(2);

    setRecibido(value);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth={"xs"}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Abono</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <GridUI container>
            <GridUI
              item
              xs="4"
              style={{ display: "flex", alignItems: "center" }}
            >
              Total
            </GridUI>
            <GridUI item xs="8">
              <Input
                disableUnderline
                value={total.toFixed(2)}
                disabled
                startAdornment={
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <AttachMoney />
                  </IconButton>
                }
                inputProps={{
                  style: {
                    padding: 8,
                    width: "100%",
                    backgroundColor: "rgba(200,200,200,0.2)",
                    borderRadius: 5,
                    textAlign: "right",
                  },
                }}
                fullWidth
              />
            </GridUI>
          </GridUI>
          <GridUI container>
            <GridUI
              item
              xs="4"
              style={{ display: "flex", alignItems: "center" }}
            >
              Cantidad Abonado
            </GridUI>
            <GridUI item xs="8">
              <Input
                disableUnderline
                value={abono.toFixed(2)}
                disabled
                startAdornment={
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <AttachMoney />
                  </IconButton>
                }
                inputProps={{
                  style: {
                    padding: 8,
                    width: "100%",
                    backgroundColor: "rgba(200,200,200,0.2)",
                    borderRadius: 5,
                    textAlign: "right",
                  },
                }}
                fullWidth
              />
            </GridUI>
          </GridUI>
          <GridUI container>
            <GridUI
              item
              xs="4"
              style={{ display: "flex", alignItems: "center" }}
            >
              Cantidad a Abonar
            </GridUI>
            <GridUI item xs="8">
              <Input
                disableUnderline
                value={deuda}
                disabled
                startAdornment={
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <AttachMoney />
                  </IconButton>
                }
                inputProps={{
                  style: {
                    padding: 8,
                    width: "100%",
                    backgroundColor: "rgba(200,200,200,0.2)",
                    borderRadius: 5,
                    textAlign: "right",
                  },
                }}
                fullWidth
              />
            </GridUI>
          </GridUI>
          <GridUI container>
            <GridUI
              item
              xs="4"
              style={{ display: "flex", alignItems: "center" }}
            >
              Forma de Pago
            </GridUI>
            <GridUI item xs="8">
              <InputSelect
                startAdornment={
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <Payment />
                  </IconButton>
                }
                data={payMethods}
                value={payMethod}
                onClick={(v) => setPayMethod(v.value)}
              />
            </GridUI>
          </GridUI>

          {payMethod == "tarjeta" && (
            <GridUI container>
              <GridUI
                item
                xs="4"
                style={{ display: "flex", alignItems: "center" }}
              >
                Tipo de Tarjeta
              </GridUI>
              <GridUI item xs="8">
                <InputSelect
                  startAdornment={
                    <IconButton
                      size="small"
                      style={{ marginLeft: 10, marginRight: 10 }}
                    >
                      <Payment />
                    </IconButton>
                  }
                  data={cardTypes}
                  value={cardType}
                  onClick={(v) => setCardType(v.value)}
                />
              </GridUI>
            </GridUI>
          )}

          {["tarjeta", "cheque", "vale"].includes(payMethod) && (
            <GridUI container>
              <GridUI
                item
                xs="4"
                style={{ display: "flex", alignItems: "center" }}
              >
                Referencia
              </GridUI>
              <GridUI item xs="8">
                <Input
                  value={referencia}
                  onChange={(e) => {
                    setReferencia(e.target.value);
                  }}
                  autoFocus
                  disableUnderline
                  startAdornment={
                    <IconButton
                      size="small"
                      style={{ marginLeft: 10, marginRight: 10 }}
                    >
                      <Sort />
                    </IconButton>
                  }
                  inputProps={{
                    maxLength: 10,
                    style: {
                      padding: 8,
                      width: "100%",
                      backgroundColor: "rgba(200,200,200,0.2)",
                      borderRadius: 5,
                      textAlign: "right",
                    },
                  }}
                  fullWidth
                />
              </GridUI>
            </GridUI>
          )}

          <GridUI container>
            <GridUI
              item
              xs="4"
              style={{ display: "flex", alignItems: "center" }}
            >
              Cantidad Recibida
            </GridUI>
            <GridUI item xs="8">
              <Input
                value={recibido}
                onChange={handleChangeRecibido}
                autoFocus
                disableUnderline
                startAdornment={
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <AttachMoney />
                  </IconButton>
                }
                inputProps={{
                  style: {
                    padding: 8,
                    width: "100%",
                    backgroundColor: "rgba(200,200,200,0.2)",
                    borderRadius: 5,
                    textAlign: "right",
                  },
                }}
                fullWidth
              />
            </GridUI>
          </GridUI>
          <GridUI container>
            <GridUI
              item
              xs="4"
              style={{ display: "flex", alignItems: "center" }}
            >
              Cambio
            </GridUI>
            <GridUI item xs="8">
              <Input
                value={cambio}
                disableUnderline
                disabled
                startAdornment={
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <AttachMoney />
                  </IconButton>
                }
                inputProps={{
                  style: {
                    padding: 8,
                    width: "100%",
                    backgroundColor: "rgba(200,200,200,0.2)",
                    borderRadius: 5,
                    textAlign: "right",
                  },
                }}
                fullWidth
              />
            </GridUI>
          </GridUI>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={close}
          style={{ alignSelf: "right" }}
        >
          Cancelar
        </Button>

        <PrintTicket
          print={{
            account,
            pay,
          }}
          ref={ticketsRef}
        >
          <Button
            onClick={() => {
              const ticket = ticketsRef.current;
              ticket.print();
              abonar(pay).then(() => {
                close();
              });
            }}
            variant="contained"
            disabled={disabled}
            color="primary"
            style={{ marginLeft: 8 }}
          >
            Abonar
          </Button>
        </PrintTicket>
      </DialogActions>
    </Dialog>
  );
};

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: '8px 8px 0px 0px',
    width: 200,
  }
}));

export default props => {
  const classes = useStyles();
  const { required, label, fullWidth, options = [], input, meta, onChange } = props;

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const inputName = label.replace(' ', '-')
  const inputId = `outlined-${inputName}`;

  return (
    <FormControl
      variant="outlined"
      required={required}
      fullWidth={fullWidth}
      className={classes.formControl}
    >
      <InputLabel ref={inputLabel} htmlFor={inputId}>
        {label}
      </InputLabel>
      <Select
        input={<OutlinedInput labelWidth={labelWidth} name={inputName} id={inputId} />}
        {...input}
        onChange={e => {
          input.onChange(e);

          if (onChange) {
            onChange(e);
          }
        }}
      >
        <MenuItem value="">
          <em>Sin selección</em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
      <FormHelperText>
      </FormHelperText>
    </FormControl>
  );
}
import React, { Component } from "react";
import * as THREE from "three";
import GLTFLoader from 'three-gltf-loader';
import OrbitControls from "three-orbitcontrols";
import { DecalGeometry } from './DecalGeometry';
import loaderObject3D from "./loaderObject3D";
// import Models from "./Models";

class Decals extends Component {
  constructor() {
    super()
    this.state = {
      editable: true,
      children: null,
      updatedInputs: [],
      decals: [
      ]
    };
    this.container = document.getElementById('container');
    this.renderer = null;
    this.decalRemove = null;
    this.scene = null;
    this.camera = null;
    this.mesh = null;
    this.raycaster = null;
    this.line = null;
    this.controls = null;
    this.moved = null;
    this.mount = null;
    this.loaderObject3D = loaderObject3D.bind(this);
    this.intersection = {
      intersects: false,
      point: new THREE.Vector3(),
      normal: new THREE.Vector3()
    };
    this.mouse = new THREE.Vector2();

    this.textureLoader = new THREE.TextureLoader();
    this.decalDiffuse = this.textureLoader.load('assets/images/textures/circulo-diffuse.png');
    this.decalNormal = this.textureLoader.load('assets/images/textures/circulo-normal.jpg');

    this.decalMaterial = new THREE.MeshPhongMaterial({
      specular: 0x444444,
      map: this.decalDiffuse,
      normalMap: this.decalNormal,
      normalScale: new THREE.Vector2(1, 1),
      shininess: 30,
      transparent: true,
      depthTest: true,
      depthWrite: false,
      polygonOffset: true,
      polygonOffsetFactor: - 4,
      wireframe: false
    });

    this.decals = [];
    this.mouseHelper = null;
    this.position = new THREE.Vector3();
    this.orientation = new THREE.Euler();
    this.size = new THREE.Vector3(10, 10, 10);

    this.params = {
      minScale: 10,
      maxScale: 20,
      rotate: true,
      clear: function () {

        this.removeDecals();

      }
    };
  };

  componentDidMount() {
    window.addEventListener('focus', this.start)
    window.addEventListener('resize', this.onWindowResize.bind(this));
    window.addEventListener('blur', this.stop)
    this.loadLeePerrySmith.bind(this);
    this.loadResourse3D.bind(this)
    this.onWindowResize.bind(this)
    this.removeDecals.bind(this)
    this.init()
    this.props.updateLayoutCallback && this.props.updateLayoutCallback(this.onWindowResize.bind(this))
  }
  componentWillReceiveProps(newProps) {
    console.log('hola')
    this.renderer.setSize(newProps.width, newProps.height);
  }
  unmount = () => {
    this.stop();
    this.removeDecals.bind(this)()

    this.mount.removeChild(this.renderer.domElement);
  }
  componentWillUnmount() {
    this.unmount();
    try {
      window.removeEventListener('focus')
      window.removeEventListener('blur')
      window.removeEventListener('resize');
    } catch (error) {

    }

  }
  init = () => {
    const width = this.props.width;
    const height = this.props.height;
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(45, width / width, 1, 1000);
    this.camera.position.z = 500;
    this.camera.target = new THREE.Vector3();
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setClearColor("#FF000000");
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);

    // var loader = new THREE.TextureLoader();
    // loader.load(
    //   url(`/assets/images/iconThin-k.png`),
    //   this.onLoad,
    //   this.onProgress,
    //   this.onError,
    // );
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.minDistance = 50;
    this.controls.maxDistance = 800;

    //LIGHTS.
    var lights = [];
    lights[0] = new THREE.PointLight(0x304ffe, 1, 0);
    lights[1] = new THREE.PointLight(0xffffff, 1, 0);
    lights[2] = new THREE.PointLight(0xffffff, 1, 0);
    lights[0].position.set(0, 200, 0);
    lights[1].position.set(100, 200, 100);
    lights[2].position.set(-100, -200, -100);
    this.scene.add(lights[0]);
    this.scene.add(lights[1]);
    this.scene.add(lights[2]);
    this.scene.add(new THREE.AmbientLight(0x443333));

    // var mtlLoader = new MTLLoader();
    // mtlLoader.setTexturePath("../../../../assets/");
    // // load material
    // mtlLoader.load("../../../../assets/3Dmodels/bodyMen.mtl", function(materials) {
    //   materials.preload();
    //   console.log("loaded Material");

    //   // load Object
    //   var objLoader = new THREE.OBJLoader();
    //   objLoader.setMaterials(materials);
    //   objLoader.load(
    //     "../../../../assets/3Dmodels/bodyMen.obj",
    //     function(object) {
    //       this.mesh = object;
    //       this.mesh.position.setY(-10); //or  this
    //       this.mesh.position.set; //or  this
    //       this.mesh.scale.set(1, 1, 1);
    //       this.scene.add(mesh);
    //     },
    //     function(xhr) {
    //     //   console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    //     },
    //     // called when loading has errors
    //     function(error) {
    //       console.log("An error happened" + error);
    //     }
    //   );
    // });
    var geometry = new THREE.BufferGeometry();
    geometry.setFromPoints([new THREE.Vector3(), new THREE.Vector3()]);
    this.line = new THREE.Line(geometry, new THREE.LineBasicMaterial());

    this.scene.add(this.line);

    // this.loadLeePerrySmith()
    this.loaderObject3D('/female.glb');
    // this.loaderObject3D('../../../../assets/3Dmodels/bodyMen.glb');
    // this.loaderObject3D('../../../../assets/3Dmodels/exported.glb');
    this.raycaster = new THREE.Raycaster();

    this.mouseHelper = new THREE.Mesh(new THREE.BoxBufferGeometry(1, 1, 10), new THREE.MeshNormalMaterial());
    this.mouseHelper.visible = false;
    this.scene.add(this.mouseHelper);
    this.controls.addEventListener('change', () => this.moved = true);
    document.getElementById('scene').addEventListener('mousedown', () => this.moved = false, false);
    document.getElementById('scene').addEventListener('mouseup', this.mouseUp.bind(this))
    document.getElementById('scene').addEventListener('mousemove', this.onTouchMove.bind(this));
    document.getElementById('scene').addEventListener('touchmove', this.onTouchMove.bind(this));
    document.getElementById('scene').addEventListener('touchstart', this.pressIn.bind(this), false);
    this.onWindowResize.bind(this)();
  }
  onTouchMove = (event) => {
    var x, y;
    if (event.changedTouches) {
      x = event.changedTouches[0].pageX;
      y = event.changedTouches[0].pageY;

    } else {
      x = event.clientX;
      y = event.clientY;

    }
    this.mouse.x = ((x - (window.innerWidth - this.props.width)) / (this.props.width)) * 2 - 1;
    this.mouse.y = - ((y - (window.innerHeight - (this.props.height))) / this.props.height) * 2 + 1;


    this.checkIntersection();

  }
  checkIntersection() {

    // if ( ! this.mesh ) return;

    this.raycaster.setFromCamera(this.mouse, this.camera);
    console.log('this.scene.children', this.scene.children)
    const childs = this.scene.children.find(e => e.name === "integumentary_system") || {};
    var intersects = this.raycaster.intersectObjects([...this.decals, ...childs.children || []]);
    if (intersects.length > 0) {
      var p = intersects[0].point;
      this.camera.lookAt(p.x, p.y, p.z);
      this.mouseHelper.position.copy(p);
      this.intersection.point.copy(p);
      // console.log('intersections', intersects)
      var n = intersects[0].face.normal.clone();
      n.transformDirection(intersects[0].object.matrixWorld);
      n.multiplyScalar(1);
      n.add(intersects[0].point);

      this.intersection.normal.copy(intersects[0].face.normal);
      this.mouseHelper.lookAt(n);

      var positions = this.line.geometry.attributes.position;
      positions.setXYZ(0, p.x, p.y, p.z);
      positions.setXYZ(1, n.x, n.y, n.z);
      positions.needsUpdate = true;
      this.intersection.mesh = intersects[0].object;
      this.intersection.intersects = true;

    } else {
      this.intersection.intersects = false;

    }

  }


  shoot() {
    this.position.copy(this.intersection.point);
    this.orientation.copy(this.mouseHelper.rotation);

    // if ( params.rotate ) orientation.z = Math.random() * 2 * Math.PI;

    var scale = this.params.minScale;
    this.size.set(scale, scale, scale);

    var material = this.decalMaterial.clone();
    material.color.setHex(window.colors.red);
    var m = new THREE.Mesh(new DecalGeometry(this.intersection.mesh, this.position, this.orientation, this.size), material);

    this.decals.push(m);
    this.scene.add(m);

  }
  mouseUp() {
    this.checkIntersection();

    if (!this.moved && this.intersection.intersects && !this.decalRemove) {
      // console.log(this.intersection)
      this.shoot();
    } else if (!this.moved && this.intersection.intersects && this.decalRemove) {
      console.log('decal', this.decalRemove)
    }

  }
  pressIn() {
    this.checkIntersection();

    if (this.intersection.intersects && !this.decalRemove) {
      // console.log(this.intersection)
      this.shoot();
    } else if (this.intersection.intersects && this.decalRemove) {
      // console.log('decal', this.decalRemove)
    }

  }
  onWindowResize() {
    try {
      this.camera.aspect = this.props.width / this.props.height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.props.width, this.props.height);
    } catch (error) {
      console.log('onWindowResize error', error)

    }
  }
  loadResourse3D(gltf) {
    console.log('loading')
    this.mesh = gltf.scene.children[0];
    this.mesh.material = new THREE.MeshPhongMaterial({
      specular: 0x111111,
      map: this.textureLoader.load('../../../../assets/3Dmodels/LeePerrySmith/Map-COL.jpg'),
      specularMap: this.textureLoader.load('../../../../assets/3Dmodels/LeePerrySmith/Map-SPEC.jpg'),
      normalMap: this.textureLoader.load('../../../../assets/3Dmodels/LeePerrySmith/Infinite-Level_02_Tangent_SmoothUV.jpg'),
      shininess: 25,

    });
    // console.log('loading')
    this.scene.add(this.mesh);
    this.mesh.scale.set(20, 20, 20);
  }
  loadLeePerrySmith() {
    var loader = new GLTFLoader();
    loader.load('../../../../assets/3Dmodels/LeePerrySmith/body.glb', this.loadResourse3D.bind(this));
    // loader.load('https://firebasestorage.googleapis.com/v0/b/consultathinkfirebase.appspot.com/o/models%2FLeePerrySmith.glb?alt=media&token=97188511-22a2-4f1d-a5eb-6dcd1dc2598d', this.loadResourse3D.bind(this));
  }
  start = () => {
    console.log('entrooo')
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };
  stop = () => {
    cancelAnimationFrame(this.frameId);
    this.frameId = null;
  };
  animate = () => {
    // if (this.mesh) this.mesh.rotation.y += 0.01;
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  };
  renderScene = () => {
    if (this.renderer) this.renderer.render(this.scene, this.camera);
  };

  onLoad = texture => {
    var objGeometry = new THREE.BufferGeometry(10, 35, 35);
    var objMaterial = new THREE.MeshPhongMaterial({
      map: texture,
    });

    this.earthMesh = new THREE.Mesh(objGeometry, objMaterial);
    this.scene.add(this.earthMesh);
    this.renderScene();
    //start animation
    this.start();
  };

  onProgress = xhr => {
    // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  };

  // Function called when download errors
  onError = error => {
    console.log("An error happened" + error);
  };
  removeDecals = () => {

    this.decals.forEach((d) => {
      // console.log('decal', d)
      this.scene.remove(d);
    });
    this.decals = [];

  }
  render() {
    // return <Models/>;
    return (
      <div
        id='container'
        style={{ width: '100%', height: '100%' }}
      >

        <div
          id='scene'
          style={{ width: '100%', height: '100%' }}
          ref={mount => {
            this.mount = mount;
          }}
        />
        <div style={{ width: '100%', height: '100%', textAlign: 'center', color: 'white', backgroundColor: 'transparent' }}>
          {this.camera ? `${this.camera.position}` : 'cargando'}
        </div>
      </div>

    );
  }
}
export { Decals };

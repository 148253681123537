import { PREGUNTA_ACTUAL, PREGUNTAS_NOTAS } from '../actions/types';


export default (state, INITIAL_STATE, action) => {
    switch (action.type) {
        case PREGUNTA_ACTUAL:
            console.log('in reducer notasCases ->', action.payload);
			return { ...state, preguntaActual: action.payload };
        case PREGUNTAS_NOTAS:
            console.log('in reducer notasCases ->', action.payload);
			return { ...state, preguntasNotas: action.payload };
		default:
			return null;
	}
};

import React from "react";
import { Box, TextField, Button, Divider } from "@material-ui/core";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Cliente from "app/sales/components/Caja/Cliente";
import Select from "app/sales/components/Common/Form/Select";
import { get } from "lodash";
import useApi from "app/sales/hooks/useApi";
import { get as _get } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { resetVenta } from "app/sales/store/actions";
import useConfirm from "app/sales/hooks/useConfirm";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: "300px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.25)",
    paddingBottom: theme.spacing(7),

    "& .inputs": {
      padding: theme.spacing(2),
      // paddingTop: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
    },

    "& .buttons": {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),

      "& > button": {
        marginBottom: theme.spacing(2),
      },
    },
  },
}));

const Index = ({ children, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { post } = useApi("session/ventas");
  const { Alert, onOpen } = useConfirm({
    message: "Seguro de querer cancelar la venta?",
    onAccept: () => dispatch(resetVenta()),
  });

  const [formasPago, setFormasPago] = React.useState([]);
  const [formaPago, setFormaPago] = React.useState(null);
  const [ingreso, setIngreso] = React.useState(null);

  const { catalogos, venta } = useSelector((state) => state.Sales);
  const { productos } = venta;

  React.useEffect(() => {
    const data = get(catalogos, "formasPago", []).map((v) => ({
      value: v.uuid,
      text: v.vc_nombre,
    }));

    setFormasPago(data);
    setFormaPago(data[0]?.value || null);
  }, []);

  const isOk = productos.length && !!ingreso && !!formaPago;

  const items = productos.map((v) => ({
    ...v,
    nu_precio: v.nu_precioBase,
    nu_subtotal: v.nu_precioBase * v.nu_cantidad,
    nu_impuesto: v.nu_precioBase * v.impuesto.nu_factor * v.nu_cantidad,
    nu_total: v.nu_precioImporte,
  }));

  const impuestos = items.reduce((pv, cv) => pv + cv.nu_impuesto, 0);
  const subtotal = items.reduce((pv, cv) => pv + cv.nu_subtotal, 0);
  const total = items.reduce((pv, cv) => pv + cv.nu_total, 0);

  const cancelarVenta = () => {
    const isOk = productos.length || venta.cliente;
    console.log(isOk);
    isOk && onOpen();
  };

  const generarVenta = async () => {
    try {
      const params = {
        uuid_cliente: venta.cliente.uuid || null,
        uuid_formaPago: formaPago,
        uuid_cuenta: venta.uuid_cuenta,
        nu_subtotal: subtotal,
        nu_impuesto: impuestos,
        nu_total: total,
        vc_referencia: "a",
        items: items.map((v) => {
          return {
            uuid_producto: v.producto.uuid,
            uuid_productoProveedor: v.uuid,
            uuid_marca: _get(v.producto, "marcas[0].uuid", null),
            nu_precio: v.nu_precio,
            nu_cantidad: v.nu_cantidad,
            nu_subtotal: v.nu_subtotal,
            nu_impuesto: v.nu_impuesto,
            nu_total: v.nu_total,
            vc_comentario: "",
          };
        }),
      };

      const res = await post(params);

      dispatch(resetVenta());
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box className={classes.sidebar} {...props}>
      <Cliente />

      <Divider />
      <Box className="inputs">
        <TextField
          label="SubTotal"
          variant="filled"
          readOnly
          fullWidth
          margin="dense"
          disable
          value={subtotal}
          InputProps={{ disableUnderline: true, readOnly: true }}
        />

        <TextField
          label="Total"
          variant="filled"
          fullWidth
          margin="dense"
          disable
          value={total}
          InputProps={{ disableUnderline: true, readOnly: true }}
        />

        <Select
          label="Metodo de pago"
          variant="filled"
          required
          fullWidth
          margin="dense"
          disable
          InputProps={{
            disableUnderline: true,
            readOnly: true,
          }}
          hideSearch
          value={formaPago}
          data={formasPago}
          onChange={(o) => setFormaPago(o.value)}
        />

        <TextField
          label="Ingreso"
          variant="filled"
          required
          fullWidth
          margin="dense"
          value={ingreso}
          onChange={(e) => setIngreso(e.target.value || null)}
          InputProps={{ disableUnderline: true }}
        />

        <TextField
          label="Cambio"
          variant="filled"
          fullWidth
          margin="dense"
          disable
          InputProps={{ disableUnderline: true, readOnly: true }}
        />
      </Box>
      <Divider />
      <Alert />
      <Box className="buttons">
        <Button
          color="primary"
          full
          variant="contained"
          disabled={!isOk}
          onClick={generarVenta}
        >
          Generar Venta
        </Button>
        <Button color="primary" full variant="outlined" onClick={cancelarVenta}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};
export default Index;

import React from "react";

import { Box, CircularProgress, TextField } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Input from "app/components/Form/Input";
import internalAPI from "app/utils/internalAPI";
import { omit } from "lodash";

const searchInstitution = (texto) => {
  return new Promise((resolve, reject) => {
    internalAPI.searchInstitucion(texto, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
};

const Index = ({ onClick, ...props }) => {
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const fetchData = async () => {
    if (!inputValue) return setData([]);

    const res = await searchInstitution(inputValue);
    setLoading(false);
    setData(res);
  };

  React.useEffect(() => {
    setLoading(!!inputValue);
    const timeout = setTimeout(async () => {
      await fetchData();
    }, 300);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  React.useEffect(() => {
    if (value) {
      onClick(value);
      setInputValue("");
      setValue(null);
    }
  }, [value]);

  React.useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  return [
    <Autocomplete
      filterOptions={(x) => x}
      options={inputValue ? data : []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      openOnFocus={false}
      freeSolo
      value={value}
      autoHighlight
      getOptionLabel={(option) => {
        return typeof option === "string" ? option : option.vc_nombre;
      }}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") return;
        if (newValue && "loading" in newValue) return;

        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      loading={loading}
      style={{ margin: 0 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Cuenta"
          placeholder="Buscar cuenta"
          variant="outlined"
          size="small"
          InputProps={{
            inputRef: params.InputProps.ref,
            ...params.InputProps,
            endAdornment: (
              <Box>
                {loading && <CircularProgress size={20} color="inherit" />}
              </Box>
            ),
          }}
        />
      )}
      renderOption={(data, option) => {
        return <Box>{data.vc_nombre}</Box>;
      }}
    />,
  ];
};

Index.defaultProps = {
  onClick() {},
};

export default Index;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Apartment, Call, LocationOn } from "@material-ui/icons";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";

import Input from "app/components/Form/Input";
import LocationSearch from "app/components/Form/LocationSearch";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const Index = (props) => {
  const { open, close, accept, data } = props;
  const [form, setForm] = React.useState({
    vc_nombre: "",
    vc_telefono: "",
  });
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleInputChange = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  React.useEffect(() => {
    if (Object.keys(data).length) {
      setError("");
      setForm(data);
    } else {
      setForm({});
    }
  }, [data]);

  React.useEffect(() => {
    setError("");
    setDisabled(!form.vc_nombre || !form.id_place);
  }, [form]);

  const guardar = () => {
    if (loading) return;
    if (!form.id_place) return setError("Seleccionar localización");

    setLoading(true);
    accept(form).finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={close}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Dependencia</DialogTitle>
      <Divider variant="middle" />
      <DialogContent style={{ maxWidth: '100%', paddingTop: 20 }}>
        <GridUI key="nombre" container>
          <GridUI item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <Apartment />
            </DialogContentText>
          </GridUI>
          <GridUI item xs={11}>
            <Input
              placeholder="Nombre de la dependencia *"
              value={form.vc_nombre}
              onChange={(value) => handleInputChange("vc_nombre", value)}
            />
          </GridUI>
        </GridUI>
        <GridUI key="localizacion" container>
          <GridUI item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <LocationOn />
            </DialogContentText>
          </GridUI>
          <GridUI item xs={11}>
            <LocationSearch
              placeholder="Buscar Localización *"
              value={form.vc_direccion}
              onChangeInput={(value) => {
                if (value !== form.vc_direccion)
                  handleInputChange("id_place", null);
              }}
              onChange={(value) => {
                handleInputChange(
                  "json_addressComponents",
                  value.address_components
                );
                handleInputChange("vc_direccion", value.formatted_address);
                handleInputChange("id_place", value.place_id);
              }}
            />
          </GridUI>
        </GridUI>
        <GridUI key="telefono" container>
          <GridUI item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <Call />
            </DialogContentText>
          </GridUI>
          <GridUI item xs={11}>
            <Input
              phoneNumber
              placeholder="Telefono"
              value={form.vc_telefono}
              onChange={(value) => handleInputChange("vc_telefono", value)}
            />
          </GridUI>
        </GridUI>

        {error && (
          <Typography variant="body2" color="error">
            - {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={close}
          style={{ alignSelf: "right" }}
        >
          Cancelar
        </Button>
        <Button
          onClick={guardar}
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          disabled={disabled}
          endIcon={loading && <CircularProgress color="inherit" size={15} />}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Index.defaultProps = {
  open: false,
  agendas: [],
  agendaId: 0,
  data: {},
  close: () => {},
  accept: () => {},

  procedimientos: [],
};

Index.propTypes = {
  open: PropTypes.bool,
  agendas: PropTypes.array,
  agendaId: PropTypes.number,
  data: PropTypes.object,
  close: PropTypes.func,
  accept: PropTypes.func,

  procedimientos: PropTypes.array,
};

const mapStateToProps = ({ Think: { procedimientos } }) => ({ procedimientos });

export default connect(mapStateToProps, [])(Index);

import { SET_HELPER_SIDEBAR_RENDER, RESET_HELPER_SIDEBAR_RENDER, SET_CHANGE_DISPLAY_MENU, SET_CHANGE_DISPLAY_SIDEBAR } from './types';


export const setHelperSidebarRender = (renderFunction) => ({
    type: SET_HELPER_SIDEBAR_RENDER,
    payload: renderFunction
});

export const resetHelperSidebarRender = () => ({
    type: RESET_HELPER_SIDEBAR_RENDER
});

export const setDisplaySidebar = (payload) => ({
    type: SET_CHANGE_DISPLAY_SIDEBAR,
    payload
});

export const setDisplayMenu = (payload) => ({
    type: SET_CHANGE_DISPLAY_MENU,
    payload
});

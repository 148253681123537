import React, { Component } from 'react';
import Draggable from 'react-draggable'; // <DraggableCore>
import moment from 'moment';


class DosisDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            position:{x:0,y:0},
            limits:{left:0, right: 10}
        };
    }
    // eventLogger = (e: MouseEvent, data: Object) => {
    //     console.log('Event: ', e);
    //     console.log('Data: ', data);
    // };

    handleDrag = (e) => {
        console.log('parent', );

        this.setState({limits:{left: 0, right:window.$('#dosisDisplayContainner')[0].clientWidth - 10}});
    }
    movement = (m) => {
        console.log(m)
    }
    minutesTime(time) {
        const dateString = moment().format('YYYY-MM-DD');
        const dateTimeInterval = moment(`${dateString} ${time}`) * 1;
        const dateTimeCeroInterval = moment(`${dateString} 00:00:00`) * 1;
        return  (dateTimeInterval - dateTimeCeroInterval) / 60000
    }
    renderTomas(){
        let maxWidth = 500;
        let minuteWidth = maxWidth/1440;
        return this.props.detalle.administraciones.map((dosis, index) => {
            const minutes = this.minutesTime(dosis.tm_toma);
            console.log('toma en minutos',minutes);
            return (
                <Draggable
                    key={index}
                    axis="x"
                    handle=".handle"
                    enableUserSelectHack
                    bounds={this.state.limits}
                    defaultPosition={{x: (minuteWidth * minutes), y: 0}}
                    grid={[1, 1]}
                    onStart={this.handleStart}
                    onDrag={this.handleDrag}
                    onStop={this.handleStop}>
                    <div>
                    <div className="handle"
                        style={{width: 10, height: 10, borderRadius: 5, boxShadow: '1px 0px 2px gray', backgroundColor:window.colors.itemSelected}}
                    ></div>
                    </div>
                </Draggable>
            )
        })
    }
    render() {
        return (
            <div id='dosisDisplayContainner' style={{width: '100%', height: 40, padding: 0, height: 10, borderRadius: 5, boxShadow: '1px 0px 2px gray'}}>
                {this.renderTomas()}
            </div>

        );
    }
}
const stylesReceta = {
    container: {
        padding: 2,
        float: 'left'

    },
    layerBackgroundTx: {
        float: 'center',
        position: 'absolute',
        height: '100%',
		width: '100%',
		overflow: 'hidden',
		margin: 0,
        padding: 0,
        opacity: 0.05,
		backgroundImage: 'url(assets/images/iconThin-k.png)',
		backgroundPosition: 'center',
        backgroundSize: '800px 800px',
        backgroundRepeat: 'no-repeat'
	},
    title:{
        backgroundColor: 'white',
        color:window.colors.black,
        padding:5,
        borderRadius: 3,
    }
};


export { DosisDisplay };

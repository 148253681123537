import React from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  Grid,
  makeStyles,
} from "@material-ui/core";

import Input from "app/components/Form/Input";
import InputSelect from "app/components/Form/Select";

import { SubjectOutlined } from "@material-ui/icons";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => {
  return {
    input: {
      padding: 8,
      width: "100%",
      backgroundColor: "rgba(200,200,200,0.2)",
      borderRadius: 5,
      textAlign: "right",
    },
  };
});

export default (props) => {
  const classes = useStyles();
  const { open, close, accept, data } = props;
  const [form, setForm] = React.useState(
    data || {
      id_productArea: 1,
      id_type: 1,
      measureUnit: "unidad",
      nu_tax: 0,
      nu_total: 0,
      nu_unitValue: 0,
      vc_description: "",
    }
  );
  const [disabled, setDisabled] = React.useState(false);

  const tiposProductos = [
    { value: 1, text: "Producto" },
    { value: 2, text: "Servicio" },
    { value: 3, text: "Procedimiento" },
  ];
  const areasProductos = [
    { value: 1, text: "Doctor y area de curacion" },
    { value: 2, text: "Podologia" },
    { value: 3, text: "Farmacia / Inventario" },
  ];
  const impuestos = [
    { value: 0, text: "0%" },
    { value: 0.16, text: "16%" },
  ];

  const handleInputChange = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleUnitValue = (v) => {
    let value = v;
    const match = value.match(/^\d{1,}(\.\d{0,4})?$/);
    if (value !== "" && !Number(value)) return;

    if (!!match && !!match[1] && match[1].length > 3)
      value = Number(value).toFixed(2);

    handleInputChange("nu_unitValue", value);
  };

  React.useEffect(() => {
    if (form.nu_unitValue) {
      const iva = form.nu_unitValue * form.nu_tax;
      const total = Number((Number(form.nu_unitValue) + iva).toFixed(2));
      handleInputChange("nu_total", total);
    } else handleInputChange("nu_total", 0);
  }, [form.nu_tax, form.nu_unitValue]);

  React.useEffect(() => {
    setDisabled(!form.vc_description || form.nu_unitValue < 0);
  }, [form]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Cliente</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <SubjectOutlined />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            <Input
              placeholder="Nombre*"
              value={form.nombre}
              onChange={(value) => handleInputChange("nombre", value)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <SubjectOutlined />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            <Input
              placeholder="Descripción"
              multiline
              rows={3}
              rowsMax={3}
              value={form.descripcion}
              onChange={(value) => handleInputChange("descripcion", value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={close}
          style={{ alignSelf: "right" }}
        >
          Cancelar
        </Button>

        <Button
          onClick={() => {
            accept(form);
          }}
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          disabled={disabled}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from "react";

import {
  Box,
  Input,
  IconButton,
  Divider,
  Popover,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import Search from "@material-ui/icons/Search";
import Check from "@material-ui/icons/Check";
import Payment from "@material-ui/icons/Payment";
import _ from "lodash";

export default (props) => {
  const { onClick, data, value, startAdornment = null } = props;
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState(
    data.find((v) => v.value === value)
  );

  React.useEffect(() => {
    setRows([...data].splice(0, 10));
  }, []);

  React.useEffect(() => {
    if (search) {
      setRows(
        data
          .filter((e) => e.text.toLowerCase().includes(search.toLowerCase()))
          .splice(0, 10)
      );
    } else {
      setRows([...data].splice(0, 10));
    }
  }, [search]);

  const select = (row) => {
    onClick(row);
    setAnchorEl(null);
    setSearch("");
    setRows([...data].splice(0, 10));
    setSelected(row);
  };

  return (
    <div>
      <Input
        disableUnderline
        value={selected.text}
        readOnly
        startAdornment={startAdornment}
        onClick={(e) => {
          setAnchorEl(e.target);
          setSearch("");
        }}
        inputProps={{
          style: {
            padding: 8,
            width: "100%",
            backgroundColor: "rgba(200,200,200,0.2)",
            borderRadius: 5,
            cursor: "pointer",
            textAlign: "right",
          },
        }}
        fullWidth
      />

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box
          padding={0}
          style={{
            minHeight: "250px",
            maxHeight: "300px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            padding={0}
            paddingTop={1}
            paddingLeft={1}
            paddingRight={1}
            style={{ outline: "none" }}
          >
            <Input
              autoFocus
              onClick={(e) => e.stopPropagation()}
              fullWidth
              disableUnderline
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              startAdornment={<Search style={{ marginRight: 8 }} />}
              style={{ margin: 0 }}
            />
          </Box>
          <Divider variant="fullWidth" />
          <Box
            padding={0}
            style={{
              flex: "1 1 auto",
              overflowY: "auto",
              minHeight: 0,
              height: 0,
            }}
          >
            {rows.map((row, index) => (
              <ListItem key={index} button onClick={(_) => select(row)}>
                <ListItemText primary={row.text} />
                {selected.value === row.value && (
                  <ListItemIcon style={{ justifyContent: "flex-end" }}>
                    <Check color="primary" />
                  </ListItemIcon>
                )}
              </ListItem>
            ))}
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

import React, { Component } from "react";
import {
  Grid,
  List,
  Dropdown,
  Modal,
  Button,
  TextArea,
} from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import { post } from "axios";
import * as firebase from "firebase/app";
import eventManager from "../../../../utils/eventManager";
import notifications from "../../../../utils/notifications";
import internalAPI from "../../../../utils/internalAPI";
import { FadeInView, ActionSheet } from "../../../../components";
import { START_VIEW_TRANSITION } from "../../../../constants";
import PreguntasTexto from "./preguntas/preguntasTexto";
import PreguntasTextoUI from "./preguntas/PreguntasTextoUI";
import DiagnosticosItem from "./diagnosticosItem";
import ProcedimientosItem from "./procedimientosItem";
import TratamientosItem from "./tratamientosItem";
import EstudiosItem from "./estudiosItem";
import PacienteItem from "./pacienteItem";
import DiagnosticosHelpBar from "./diagnosticosHelpBar";
import ProcedimientosHelpBar from "./procedimientosHelpBar";

import MedicamentosHelpBar from "./medicamentosHelpBar";

import MultimediaHelpBar from "./multimediaHelpBar";
import ResponderHelpBar from "./responderHelpBar";
import NotificationsModal from "../notificationsModal";
import EstudiosHelpBar from "./estudiosHelpBar";
// import NotasPrint from "./notasPrint";
import NotasPrint from "./notasViewer/Nota";
import TimeLineHealth from "./timeLineHealth";
import RecetasPrint from "./recetasPrint";
import SolicitudesPrint from "./solicitudesPrint";
import {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  logOut,
  setPacienteActual,
  setDisplaySidebar,
} from "../../actions";
import VideoRecord from "../videoRecord";
import { menuNotas } from "../../listados";
import NotasViewer from "./notasViewer";
import Decals from "../../../../components/Anatomy/Test";
// import { Decals } from '../../../../components';
import Indicaciones from "./Indicaciones";
import repository from "./repository";
import { getLastDetalle, isDetalleActive } from "../../../../utils/repository";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Box,
  Divider,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import PreguntasNumeticasUI from "./preguntas/PreguntasNumeticasUI";
import PatientsItem from "../../../../components/PatientsItem";
import SubMenuModulos from "../../../../material-ui/SubMenuModulos";

// views
import SolicitudesView from "./solicitudes";
import { getIndexLastDetalle } from "../../../../utils/repository";
import VideoCall from "../../../../material-ui/VideoCall/VideoCall";

const sidebarWidth = window.sidebarWidth;

const { Icon } = List;

class NotasView extends Component {
  constructor(props) {
    super(props);
    this.app = firebase;
    this.db = this.app.database().ref();
    this.storage = this.app.storage().ref();
    this.state = {
      vc_email: window.amplify.store("vc_email")
        ? `${window.amplify.store("vc_email").replace(".", "")}`
        : null,
      hoverIndexPrincipal: null,
      hoverIndexHelp: null,
      searchText: "",
      umTiempo: null,
      moduloActual: "notas",
      opcionActualModulo: "notas",
      subOpcionActualModulo: "notas",
      indexSeleccionado: null,
      notasTipoEditor: null,
      sendings: {
        tratamientos: false,
        nota: false,
        solicitud: false,
      },
      modal: null,
      estudios: [],
      estudiosVideos: [],
      id_estudio: null,
      menuNotas,
      notPrint: true,

      ...this.poseModuloToolBar("notas"),
    };

    this.newStateUpdate = this.newStateUpdate.bind(this);
    this.addMedicamento = this.addMedicamento.bind(this);
  }

  render() {
    if (!this.props.pacienteActual) return <div />;
    return (
      <div
        style={{
          ...styles.container,
          backgroundColor: "rgba(200,200,200,0.2)",
        }}
      >
        {/* <div style={styles.container}> */}
        {this.renderHeaderMenuNotas()}
        {this.renderPatient()}
        <Divider />
        <div
          key={1}
          style={{
            ...styles.principal,
            paddingTop: this.state.moduloActual === "notas" ? "1%" : "2%",
          }}
        >
          {this.renderProcedimientos()}
          {this.renderDiagnosticos()}
          {this.renderMedicamentos()}
          {this.renderPreguntasTexto()}
          {this.renderNotasPrevias()}
          {this.renderSolicitudes()}
          {this.renderEstudios()}
          {this.renderTimeLine()}
          {this.renderTelemedicina()}
          <Box
            style={{
              display: "flex",
              width: "100%",
              height: 60,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography component="p">
              © Copyright {new Date().getFullYear()} - Thin-k Soluciones Médicas S.A. de C.V. - Todos
              los derechos reservados
            </Typography>
          </Box>
        </div>
        {/* </div> */}
        {this.renderNotificationModal()}
        {this.renderActionSheet()}
        {this.renderToolBar()}
        {/* {this.renderPrint()} */}
      </div>
    );
  }

  // ---------------------------------
  // ------ life cycle events --------
  // ---------------------------------

  componentWillReceiveProps(nextProps) {
    if (nextProps.pacienteActual && this.props.pacienteActual) {
      if (
        nextProps.pacienteActual.detalle &&
        this.props.pacienteActual.detalle &&
        nextProps.pacienteActual.detalle.id !==
          this.props.pacienteActual.detalle.id &&
        !this.state.finish
      ) {
        this.pausarNota();
      }
    }
  }

  componentDidMount() {
    window.onerror = this.pausarNota.bind(this);
    this.props.setActiveView("notas");
    this.props.setCustomHeader(<PacienteItem />);

    this.setMenuOptions();

    let newPacienteActual = {
      ...this.props.pacienteActual,
    };

    newPacienteActual.solicitudActual = {
      id_area: window.amplify.store("area")
        ? window.amplify.store("area").id
        : null,
      id_atencion: window.amplify.store("atencion")
        ? window.amplify.store("atencion").id
        : null,
      id_dependencia: window.amplify.store("dependencia")
        ? window.amplify.store("dependencia").id
        : null,
      id_institucion: window.amplify.store("institution")
        ? window.amplify.store("institution").id
        : null,
      id_paciente: this.props.pacienteActual
        ? this.props.pacienteActual.detalle.id
        : null,
      id_usuario: window.amplify.store("user_id"),
      estudios: [],
    };
    newPacienteActual.tratamientoActual = {
      vc_observaciones: null,
    };

    this.props.setPacienteActual(newPacienteActual);
    this.updateHelperSidebar();
    if (this.props.pacienteActual) {
      this.suscriptionsFirebase(this.props.pacienteActual.detalle);
      this.chargePausedNote(
        this.props.pacienteActual.detalle,
        newPacienteActual
      );
    } else {
      this.props.setPacienteActual(newPacienteActual);
    }

    this.newStateUpdate.bind(this);
    this.onChangeText.bind(this);
    if (!!window.isMobile) this.props.setDisplaySidebar(true);
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.updateDimensions.bind(this));
    if (this.state.vc_email != null && this.props.pacienteActual.detalle) {
      const firebaseUser = this.state.vc_email.split(".").join("");
      this.db
        .child("patientsActive")
        .child(firebaseUser)
        .child(`${this.props.pacienteActual.detalle.id}`)
        .remove();
      this.db
        .child("assistantComands")
        .child(firebaseUser)
        .child(`${this.props.pacienteActual.detalle.id}`)
        .off();
    }
    if (!this.state.finish) {
      this.pausarNota();
    }
    window.onerror = null;
    this.props.setPacienteActual(null);
    this.props.resetHelperSidebarRender();
  }

  // ------------------------------
  // ------ render methods --------
  // ------------------------------

  renderHeaderMenuNotas() {
    const moduloActual = this.state.menuNotas.filter(
      (e, index) => e.id == this.state.moduloActual
    )[0];
    const opcionActual = moduloActual.opciones.filter(
      (e, index) => e.id == this.state.opcionActualModulo
    )[0];
    const acciones = opcionActual.acciones.map((accion) => {
      return {
        ...accion,
        renderPrint:
          accion.id === "imprimir" ? this.renderPrint.bind(this) : null,
        action: () => {
          if (accion.id !== "imprimir" && this.clickAccion)
            this.clickAccion(accion);
        },
      };
    });

    const opciones = moduloActual.opciones.map((opcion) => {
      return {
        ...opcion,
        action: () => {
          this.onChangeModule(moduloActual.id, opcion.id, "agregar");
        },
      };
    });
    return (
      <SubMenuModulos
        actions={acciones}
        options={opciones}
        optionDisplay={[moduloActual.vc_nombre, opcionActual.vc_nombre]}
      />
    );
  }

  renderPatient() {
    return <PatientsItem pacienteActual={this.props.pacienteActual} />;
  }

  renderProcedimientos(procedimientos) {
    if (this.state.moduloActual == "procedimientos") {
      let procedimientosFiltrados = this.filtroProcedimientos(
        this.state.opcionActualModulo
      );

      return (
        <FadeInView duration={100}>
          {procedimientosFiltrados.map((item, index) => (
            <ProcedimientosItem
              callbackSelect={({
                opcionesModuloNext,
                sn_seleccionado,
                indexSeleccionado,
              }) => {
                if (sn_seleccionado == 1) {
                  this.setState(
                    {
                      indexSeleccionado: index,
                      subOpcionActualModulo: "principal",
                    },
                    () => {
                      this.updateHelperSidebar();
                    }
                  );
                } else {
                  this.setState(
                    {
                      indexSeleccionado: null,
                      subOpcionActualModulo: "agregar",
                    },
                    () => {
                      this.updateHelperSidebar();
                    }
                  );
                }
              }}
              callbackDelete={({ item }) => {
                this.deleteItems(item);
              }}
              sn_printVersion={false}
              indexSeleccionado={this.state.indexSeleccionado}
              item={item}
              index={index}
              key={index}
            />
          ))}
        </FadeInView>
      );
    }
  }
  renderTelemedicina() {
    if (this.state.moduloActual === "telemedicina") {
      return (
        <Box style={{ width: "100%", height: "100%" }}>
          <VideoCall
            open={true}
            onClose={() => this.setState({ showVideoCall: false })}
          />
        </Box>
      );
    }
  }
  renderDiagnosticos(diagnosticos) {
    if (this.state.moduloActual == "diagnosticos") {
      let diagnosticosFiltrados = this.filtroDiagnosticos(
        this.state.opcionActualModulo
      );

      return (
        <FadeInView duration={100}>
          {diagnosticosFiltrados.map((item, index) => {
            return (
              <DiagnosticosItem
                callbackSelect={({
                  opcionesModuloNext,
                  sn_seleccionado,
                  indexSeleccionado,
                }) => {
                  if (sn_seleccionado == 1) {
                    if (!diagnosticosFiltrados[index].id) {
                      this.setState(
                        {
                          indexSeleccionado: index,
                          subOpcionActualModulo: "clasificar",
                        },
                        () => {
                          this.updateHelperSidebar();
                        }
                      );
                    } else if (opcionesModuloNext === "historial") {
                      this.setState(
                        {
                          indexSeleccionado: index,
                          subOpcionActualModulo: "historial",
                        },
                        () => {
                          this.updateHelperSidebar();
                        }
                      );
                    } else {
                      this.setState(
                        {
                          indexSeleccionado: index,
                          subOpcionActualModulo: "vc_observaciones",
                        },
                        () => {
                          this.updateHelperSidebar();
                        }
                      );
                    }
                  } else {
                    this.setState(
                      {
                        indexSeleccionado: null,
                        subOpcionActualModulo: "agregar",
                      },
                      () => {
                        this.updateHelperSidebar();
                      }
                    );
                  }
                }}
                callbackDelete={({ item }) => {
                  this.deleteItems(item);
                }}
                indexSeleccionado={this.state.indexSeleccionado}
                item={item}
                index={index}
                key={index}
              />
            );
          })}
        </FadeInView>
      );
    }
  }

  renderMedicamentos(medicamentos, sn_printVersion) {
    if (this.state.moduloActual == "tratamientos") {
      let medicamentosFiltrados = this.filtroMedicamentos(
        this.state.opcionActualModulo
      );
      return (
        <FadeInView duration={100}>
          {medicamentosFiltrados.map((item, index) => {
            return (
              <TratamientosItem
                callbackSelect={({
                  opcionesModuloNext,
                  sn_seleccionado,
                  indexSeleccionado,
                  indexDetalleSeleccionado = null,
                }) => {
                  if (sn_seleccionado == 1) {
                    const medicamentosFitrados = this.filtroMedicamentos(
                      this.state.opcionActualModulo
                    );
                    const detalle =
                      indexDetalleSeleccionado > -1
                        ? item.detalles[indexDetalleSeleccionado]
                        : getLastDetalle(item.detalles, "dt_vigencia");
                    if (detalle.id_vehiculo == null) {
                      this.setState(
                        {
                          indexSeleccionado: index,
                          subOpcionActualModulo: opcionesModuloNext,
                          indexDetalleSeleccionado,
                        },
                        () => {
                          this.updateHelperSidebar();
                          !window.isMobile &&
                            indexDetalleSeleccionado > -1 &&
                            this.props.setDisplaySidebar(true);
                        }
                      );
                    } else {
                      this.setState(
                        {
                          indexSeleccionado: index,
                          subOpcionActualModulo: opcionesModuloNext,
                          indexDetalleSeleccionado,
                        },
                        () => {
                          this.updateHelperSidebar();
                          !window.isMobile &&
                            indexDetalleSeleccionado > -1 &&
                            this.props.setDisplaySidebar(true);
                        }
                      );
                    }
                  } else {
                    this.setState(
                      {
                        indexSeleccionado: null,
                        subOpcionActualModulo: opcionesModuloNext,
                        indexDetalleSeleccionado,
                      },
                      () => {
                        this.updateHelperSidebar();
                      }
                    );
                  }
                }}
                callbackDelete={({ item }) => {
                  this.deleteItems(item);
                }}
                callbackEditDetalle={({
                  medicamento,
                  indexDetalleSeleccionado,
                }) => {
                  const medicamentosPaciente = this.props.pacienteActual
                    .medicamentosPaciente;
                  const indexItem = medicamentosPaciente.indexOf(item);
                  medicamentosPaciente[indexItem] = medicamento;

                  if (
                    this.state.opcionActualModulo == "historial" ||
                    this.state.opcionActualModulo == "eliminados"
                  ) {
                    this.setState(
                      {
                        indexSeleccionado: null,
                        subOpcionActualModulo: "agregar",
                        indexDetalleSeleccionado: null,
                        opcionActualModulo: "actual",
                      },
                      () => {
                        this.props.setPacienteActual({
                          ...this.props.pacienteActual,
                          medicamentosPaciente,
                        });
                        this.updateHelperSidebar();
                      }
                    );
                  } else {
                    this.setState(
                      {
                        indexSeleccionado: index,
                        subOpcionActualModulo: "dosis",
                        indexDetalleSeleccionado,
                      },
                      () => {
                        this.props.setPacienteActual({
                          ...this.props.pacienteActual,
                          medicamentosPaciente,
                        });
                        this.updateHelperSidebar();
                      }
                    );
                  }
                }}
                indexSeleccionado={this.state.indexSeleccionado}
                indexDetalleSeleccionado={this.state.indexDetalleSeleccionado}
                item={item}
                sn_printVersion={false}
                index={index}
                key={index}
              />
            );
          })}
        </FadeInView>
      );
    }
  }
  renderIndicaciones() {
    if (this.state.moduloActual == "indicaciones") {
      return (
        <FadeInView duration={100}>
          <Indicaciones />
        </FadeInView>
      );
    }
  }

  renderPreguntasTexto() {
    if (this.state.moduloActual == "notas") {
      const preguntas = this.props.pacienteActual.preguntasTexto || [];
      switch (this.state.opcionActualModulo) {
        case "notas":
          const pacienteRespuestas = this.props.pacienteActual
            ? this.props.pacienteActualRespuestas || []
            : [];
            
          return preguntas.map((item, index) => (
            <PreguntasTextoUI
              callbackPreg={({ state, inputs, value }) => {
                const preguntasTexto = preguntas;
                preguntasTexto[index].respuesta = {
                  id_pregunta: item.id,
                  vc_respuesta: value,
                };
                // preguntasTexto[index].respuestaStruct = inputs;
                // preguntasTexto[index].state = state;
                // preguntasTexto[index].activeCharacter = state.activeCharacter || null;
                this.setState(
                  { indexSeleccionado: index, notasTipoEditor: "principal" },
                  () => {
                    this.props.setPacienteActual({
                      ...this.props.pacienteActual,
                      preguntasTexto,
                    });
                    this.updateHelperSidebar();
                  }
                );
              }}
              callbackFinish={() => {
                this.setState(
                  { indexSeleccionado: null, notasTipoEditor: "principal" },
                  () => {
                    this.updateHelperSidebar();
                  }
                );
              }}
              callbackWizard={({ indexSeleccionado }) => {
                if (indexSeleccionado == this.state.indexSeleccionado) {
                  this.setState(
                    { indexSeleccionado: null, notasTipoEditor: "lateral" },
                    () => {
                      this.updateHelperSidebar();
                    }
                  );
                } else {
                  this.setState(
                    { indexSeleccionado, notasTipoEditor: "principal" },
                    () => {
                      this.updateHelperSidebar();
                    }
                  );
                }
              }}
              respuestas={pacienteRespuestas.filter(
                (respuesta) =>
                  respuesta.id == null &&
                  respuesta.cve_respuesta.startsWith(`${item.id}`)
              )}
              item={item}
              index={index}
              key={index}
              reload={(e) => {
                this.forceUpdate();
              }}
            />
          ));
        case "historial":
          return (
            <div>
              <NotasViewer />
            </div>
          );
        default:
          break;
      }
    }
  }

  renderNotasPrevias() {}

  renderSolicitudes(sn_printVersion) {
    const { moduloActual, opcionActualModulo, indexSeleccionado } = this.state;
    if (moduloActual == "solicitudes") {
      const { pacienteActual, setPacienteActual } = this.props;

      return (
        <SolicitudesView
          key={"solicitudesView"}
          opcion={opcionActualModulo}
          updateState={this.newStateUpdate}
          pacienteActual={pacienteActual}
          setPacienteActual={setPacienteActual}
          indexSeleccionado={indexSeleccionado}
        />
      );
    }
  }

  renderEstudios() {
    const { VideoCustom } = this.props;
    if (this.state.moduloActual == "estudios") {
      if (this.state.opcionActualModulo === "registro") {
        if (
          this.state.multimediaVideoCargado &&
          this.state.multimediaVideoCargado.url
        ) {
          return (
            <Modal open size="small" style={{ borderRadius: 10 }}>
              <VideoCustom
                src={this.state.multimediaVideoCargado.url}
                style={{
                  height: "100%",
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.9",
                  margin: 0,
                  padding: 0,
                  borderRadius: 10,
                }}
              />
              <Icon
                name="remove"
                style={{ position: "absolute", float: "right" }}
                onClick={() => {
                  this.setState({ multimediaVideoCargado: null });
                }}
              />
            </Modal>
          );
        }
        return (
          <Modal open size="small" style={{ borderRadius: 10 }}>
            <VideoRecord
              ref="app"
              callbackClose={() => {
                this.setState({ opcionActualModulo: "visualizar" }, () => {
                  this.updateHelperSidebar();
                });
              }}
              callback={({ blob }) => {
                this.uploadFileServer(blob);
              }}
            />
          </Modal>
        );
      } else if (this.state.multimediaCargado) {
        return (
          <Modal
            open
            size="small"
            style={{
              borderRadius: 10,
              maxHeight: "90%",
              maxWidth: "90%",
              padding: 0,
            }}
          >
            <Grid centered style={{ padding: 0, margin: 0 }}>
              <VideoCustom
                radius={10}
                src={this.state.multimediaCargado.url}
                style={{
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.9",
                  height: "calc(100% - 100px)",
                  margin: 0,
                  padding: 0,
                  borderRadius: 10,
                }}
              />
              <div style={{ position: "absolute", width: "100%" }}>
                <Icon
                  name="remove"
                  style={{ float: "right", marginTop: 10, color: "red" }}
                  onClick={() => {
                    this.setState({ multimediaCargado: null });
                  }}
                />
              </div>
            </Grid>
          </Modal>
        );
      }
    }
  }

  renderTimeLine() {
    if (this.state.moduloActual === "timeLine" && !window.isMobile) {
      const preguntasNumericas =
        this.props.pacienteActual.preguntasNumericas || [];
      const preguntasTexto = this.props.pacienteActual.preguntasTexto || [];
      return (
        <TimeLineHealth
          diagnosticosPaciente={
            this.props.pacienteActual.diagnosticosPaciente || []
          }
          procedimientosPaciente={
            this.props.pacienteActual.procedimientosPaciente || []
          }
          medicamentos={this.props.pacienteActual.medicamentosPaciente || []}
          pacienteRespuestas={this.props.pacienteActualRespuestas || []}
          videos={this.state.estudiosVideos}
          imagenes={this.state.estudios}
          preguntas={preguntasNumericas.concat(preguntasTexto)}
          key="nota"
          style={{
            margin: 0,
            padding: 0,
            height: window.screen.availHeight * 0.8,
          }}
          scala={84000000}
        />
      );
    }
  }

  renderHelperSidebar() {
    const { preguntasTexto, preguntasNumericas } = this.props.pacienteActual;
    switch (this.state.moduloActual) {
      case "notas":
        return <PreguntasNumeticasUI preguntasNumericas={preguntasNumericas} />;
        let selectedPregunta = null;
        switch (this.state.notasTipoEditor) {
          case "principal":
            if (this.state.indexSeleccionado != null) {
              selectedPregunta = preguntasTexto[this.state.indexSeleccionado];
            } else {
              selectedPregunta = preguntasTexto[0];
            }
            break;
          case "lateral":
            if (this.state.indexSeleccionado != null) {
              selectedPregunta =
                preguntasNumericas[this.state.indexSeleccionado];
            } else {
              selectedPregunta = preguntasNumericas[0];
            }
            break;
        }
        return (
          <div style={styles.subMenuBar}>
            <ResponderHelpBar
              pageSize={this.props.pageSize}
              returnLateral={() => {
                this.setState({ notasTipoEditor: null });
              }}
              callbackPregunta={({ pregunta, opcionesModuloNext }) => {
                if (pregunta) {
                  const indexItemNumericas = preguntasNumericas.indexOf(
                    selectedPregunta
                  );
                  const indexItemText = preguntasTexto.indexOf(
                    selectedPregunta
                  );

                  if (indexItemNumericas) {
                    preguntasNumericas[indexItemNumericas] = pregunta;
                    if (
                      preguntasNumericas[indexItemNumericas].id == 241 ||
                      preguntasNumericas[indexItemNumericas].id == 242
                    ) {
                      if (
                        preguntasNumericas.filter((e, index) => e.id == 241)
                          .length > 0 &&
                        preguntasNumericas.filter((e, index) => e.id == 242)
                          .length > 0
                      ) {
                        if (
                          preguntasNumericas.filter(
                            (e, index) => e.id == 241
                          )[0].respuesta &&
                          preguntasNumericas.filter(
                            (e, index) => e.id == 242
                          )[0].respuesta
                        ) {
                          if (
                            preguntasNumericas.filter(
                              (e, index) => e.id == 241
                            )[0].respuesta.nu_respuesta &&
                            preguntasNumericas.filter(
                              (e, index) => e.id == 242
                            )[0].respuesta.nu_respuesta
                          ) {
                            const tallaMts =
                              preguntasNumericas.filter(
                                (e, index) => e.id == 241
                              )[0].respuesta.nu_respuesta / 100;
                            const imc =
                              preguntasNumericas.filter(
                                (e, index) => e.id == 242
                              )[0].respuesta.nu_respuesta /
                              (tallaMts * tallaMts);
                            preguntasNumericas.filter(
                              (e, index) => e.id == 243
                            )[0].respuesta = {
                              id_pregunta: 243,
                              nu_respuesta: imc.toFixed(1),
                            };
                          }
                        }
                      }
                    }
                  } else if (indexItemText) {
                    preguntasTexto[indexItemText] = pregunta;
                  } else if (this.state.indexSeleccionado) {
                    preguntasTexto[this.state.indexSeleccionado] = pregunta;
                  }
                  if (opcionesModuloNext == "siguientePregunta") {
                    if (this.state.notasTipoEditor == "principal") {
                      if (
                        this.state.indexSeleccionado + 1 <
                        preguntasTexto.length
                      ) {
                        this.setState(
                          {
                            preguntasTexto,
                            subOpcionActualModulo: "responder",
                            indexSeleccionado: this.state.indexSeleccionado + 1,
                          },
                          () => this.updateHelperSidebar()
                        );
                      } else {
                        this.setState(
                          {
                            preguntasTexto,
                            subOpcionActualModulo: "historial",
                            indexSeleccionado: null,
                          },
                          () => this.updateHelperSidebar()
                        );
                      }
                    } else {
                      if (
                        this.state.indexSeleccionado + 1 <
                        preguntasNumericas.length
                      ) {
                        this.setState(
                          {
                            preguntasNumericas,
                            subOpcionActualModulo: "responder",
                            indexSeleccionado: this.state.indexSeleccionado + 1,
                          },
                          () => this.updateHelperSidebar()
                        );
                      } else {
                        this.setState(
                          {
                            preguntasNumericas,
                            subOpcionActualModulo: "historial",
                            indexSeleccionado: null,
                          },
                          () => this.updateHelperSidebar()
                        );
                      }
                    }
                  } else {
                    this.setState(
                      {
                        preguntasNumericas,
                        preguntasTexto,
                        subOpcionActualModulo: opcionesModuloNext,
                      },
                      () => this.updateHelperSidebar()
                    );
                  }
                }
              }}
              callbackPreguntasNumericas={({ preguntasNumericas }) => {
                this.setState({ preguntasNumericas }, () =>
                  this.updateHelperSidebar()
                );
              }}
              callbackWizard={({ indexSeleccionado }) => {
                if (indexSeleccionado == this.state.indexSeleccionado) {
                  this.setState(
                    { indexSeleccionado: null, notasTipoEditor: "lateral" },
                    () => {
                      this.updateHelperSidebar();
                    }
                  );
                } else {
                  this.setState(
                    { indexSeleccionado, notasTipoEditor: "lateral" },
                    () => {
                      this.updateHelperSidebar();
                    }
                  );
                }
              }}
              indexSeleccionado={
                this.state.indexSeleccionado == null
                  ? 0
                  : this.state.indexSeleccionado
              }
              preguntaActual={selectedPregunta}
              preguntaPadre={
                this.state.indexSeleccionado !== null &&
                this.state.notasTipoEditor === "principal"
                  ? preguntasTexto[this.state.indexSeleccionado]
                  : null
              }
              preguntasNumericas={this.state.preguntasNumericas}
              notasTipoEditor={
                this.state.notasTipoEditor != null
                  ? this.state.notasTipoEditor
                  : "lateral"
              }
              opcion={this.state.subOpcionActualModulo}
              reload={(e) => {
                this.forceUpdate();
              }}
            />
          </div>
        );
        break;
      case "procedimientos":
        const selectedProcedimiento = this.filtroProcedimientos(
          this.state.opcionActualModulo
        )[this.state.indexSeleccionado];
        return (
          <div style={styles.subMenuBar}>
            <ProcedimientosHelpBar
              pageSize={this.props.pageSize}
              callbackProcedimiento={({
                id_procedimiento,
                procedimiento,
                opcionesModuloNext,
                umTiempo,
              }) => {
                if (procedimiento != null) {
                  let procedimientosPaciente = this.props.pacienteActual
                    .procedimientosPaciente;
                  const indexItem = procedimientosPaciente.indexOf(
                    selectedProcedimiento
                  );
                  procedimientosPaciente[indexItem] = procedimiento;
                  if (opcionesModuloNext == "siguienteDx") {
                    if (
                      this.state.indexSeleccionado + 1 <
                      this.filtroProcedimientos(this.state.opcionActualModulo)
                        .length
                    ) {
                      this.setState(
                        {
                          procedimientosPaciente,
                          umTiempo,
                          subOpcionActualModulo: "clasificar",
                          indexSeleccionado: this.state.indexSeleccionado + 1,
                        },
                        () => {
                          this.updateHelperSidebar();
                        }
                      );
                    } else {
                      this.setState(
                        {
                          procedimientosPaciente,
                          umTiempo,
                          subOpcionActualModulo: "agregar",
                          indexSeleccionado: null,
                        },
                        () => {
                          this.updateHelperSidebar();
                        }
                      );
                    }
                  } else {
                    this.setState(
                      {
                        procedimientosPaciente,
                        umTiempo,
                        subOpcionActualModulo: opcionesModuloNext,
                      },
                      () => {
                        this.updateHelperSidebar();
                      }
                    );
                  }
                } else if (umTiempo != null) {
                  this.setState(
                    { umTiempo, subOpcionActualModulo: opcionesModuloNext },
                    () => {
                      this.updateHelperSidebar();
                    }
                  );
                } else if (id_procedimiento) {
                  this.addProcedimiento(id_procedimiento);
                }
              }}
              procedimiento={selectedProcedimiento}
              umTiempo={this.state.umTiempo}
              opcionProcedimientos={this.state.subOpcionActualModulo}
            />
          </div>
        );
      case "diagnosticos":
        const selectedDx = this.filtroDiagnosticos(
          this.state.opcionActualModulo
        )[this.state.indexSeleccionado];
        return (
          <div style={styles.subMenuBar}>
            <DiagnosticosHelpBar
              pageSize={this.props.pageSize}
              callbackDx={({
                id_diagnostico,
                diagnostico,
                opcionesModuloNext,
                umTiempo,
              }) => {
                if (diagnostico != null) {
                  let diagnosticosPaciente = this.props.pacienteActual
                    .diagnosticosPaciente;
                  const indexItem = diagnosticosPaciente.indexOf(selectedDx);
                  diagnosticosPaciente[indexItem] = diagnostico;
                  if (opcionesModuloNext == "siguienteDx") {
                    if (
                      this.state.indexSeleccionado + 1 <
                      this.filtroDiagnosticos(this.state.opcionActualModulo)
                        .length
                    ) {
                      this.setState(
                        {
                          diagnosticosPaciente,
                          umTiempo,
                          subOpcionActualModulo: "clasificar",
                          indexSeleccionado: this.state.indexSeleccionado + 1,
                        },
                        () => {
                          this.updateHelperSidebar();
                        }
                      );
                    } else {
                      this.setState(
                        {
                          diagnosticosPaciente,
                          umTiempo,
                          subOpcionActualModulo: "agregar",
                          indexSeleccionado: null,
                        },
                        () => {
                          this.updateHelperSidebar();
                        }
                      );
                    }
                  } else {
                    this.setState(
                      {
                        diagnosticosPaciente,
                        umTiempo,
                        subOpcionActualModulo: opcionesModuloNext,
                      },
                      () => {
                        this.updateHelperSidebar();
                      }
                    );
                  }
                } else if (umTiempo != null) {
                  this.setState(
                    { umTiempo, subOpcionActualModulo: opcionesModuloNext },
                    () => {
                      this.updateHelperSidebar();
                    }
                  );
                } else if (id_diagnostico) {
                  this.addDx(id_diagnostico);
                }
              }}
              diagnostico={selectedDx}
              umTiempo={this.state.umTiempo}
              opcionDiagnosticos={this.state.subOpcionActualModulo}
            />
          </div>
        );
      case "tratamientos":
        let selectedMedicamento = this.filtroMedicamentos(
          this.state.opcionActualModulo
        )[this.state.indexSeleccionado];
        return (
          <div style={styles.subMenuBar}>
            <MedicamentosHelpBar
              pageSize={this.props.pageSize}
              callbackMedicamento={({
                id_medicamento,
                medicamento,
                opcionesModuloNext,
                indexDetalle,
                umTiempo,
              }) => {
                // Editando el medicamento
                if (medicamento) {
                  const medicamentosFitrados = this.filtroMedicamentos(
                    this.state.opcionActualModulo
                  );
                  const selectedMedicamento =
                    medicamentosFitrados[this.state.indexSeleccionado];

                  let medicamentosPaciente = this.props.pacienteActual
                    .medicamentosPaciente;
                  const indexItem = medicamentosPaciente.indexOf(
                    selectedMedicamento
                  );
                  medicamentosPaciente[indexItem] = medicamento;

                  if (opcionesModuloNext == "siguienteMedicamento") {
                    // if (this.state.indexSeleccionado + 1 < medicamentosFitrados.length) {
                    //     this.newStateUpdate({ fecha: Date(), medicamentosPaciente, subOpcionActualModulo: opcionesModuloNext, indexSeleccionado: this.state.indexSeleccionado + 1 });
                    // } else {
                    this.newStateUpdate(
                      {
                        fecha: Date(),
                        subOpcionActualModulo: "agregar",
                        indexSeleccionado: null,
                        indexDetalleSeleccionado: indexDetalle,
                        umTiempo,
                      },
                      () => {
                        this.props.setPacienteActual({
                          ...this.props.pacienteActual,
                          medicamentosPaciente,
                        });
                      }
                    );
                    // }
                  } else {
                    this.newStateUpdate(
                      {
                        fecha: Date(),
                        subOpcionActualModulo: opcionesModuloNext,
                        indexDetalleSeleccionado: indexDetalle,
                        umTiempo,
                      },
                      () => {
                        this.props.setPacienteActual({
                          ...this.props.pacienteActual,
                          medicamentosPaciente,
                        });
                      }
                    );
                  }
                } else if (id_medicamento) {
                  this.addMedicamento(id_medicamento, null);
                }
              }}
              callbackMedicamentoString={({
                medicamentoString,
                opcionesModuloNext,
              }) => {
                this.addMedicamento(0, medicamentoString);
              }}
              readOnly={
                (this.state.opcionActualModulo === "historial" ||
                  this.state.opcionActualModulo === "eliminados") &&
                this.state.subOpcionActualModulo !== "agregar"
              }
              medicamento={selectedMedicamento}
              indexDetalleSeleccionado={this.state.indexDetalleSeleccionado}
              umTiempo={this.state.umTiempo}
              opcionMedicamentos={this.state.subOpcionActualModulo}
            />
          </div>
        );
      case "indicaciones":
        return (
          <div style={styles.subMenuBar}>
            <MedicamentosHelpBar
              pageSize={this.props.pageSize}
              callbackMedicamento={({
                id_medicamento,
                medicamento,
                opcionesModuloNext,
                indexDetalle,
              }) => {
                // Editando el medicamento
                if (medicamento) {
                  const medicamentosFitrados = this.filtroMedicamentos(
                    this.state.opcionActualModulo
                  );
                  const selectedMedicamento =
                    medicamentosFitrados[this.state.indexSeleccionado];

                  let medicamentosPaciente = this.props.pacienteActual
                    .medicamentosPaciente;
                  const indexItem = medicamentosPaciente.indexOf(
                    selectedMedicamento
                  );
                  medicamentosPaciente[indexItem] = medicamento;

                  if (opcionesModuloNext == "siguienteMedicamento") {
                    // if (this.state.indexSeleccionado + 1 < medicamentosFitrados.length) {
                    //     this.newStateUpdate({ fecha: Date(), medicamentosPaciente, subOpcionActualModulo: opcionesModuloNext, indexSeleccionado: this.state.indexSeleccionado + 1 });
                    // } else {
                    this.newStateUpdate(
                      {
                        fecha: Date(),
                        subOpcionActualModulo: "agregar",
                        indexSeleccionado: null,
                        indexDetalleSeleccionado: indexDetalle,
                      },
                      () => {
                        this.props.setPacienteActual({
                          ...this.props.pacienteActual,
                          medicamentosPaciente,
                        });
                      }
                    );
                    // }
                  } else {
                    this.newStateUpdate(
                      {
                        fecha: Date(),
                        subOpcionActualModulo: opcionesModuloNext,
                        indexDetalleSeleccionado: indexDetalle,
                      },
                      () => {
                        this.props.setPacienteActual({
                          ...this.props.pacienteActual,
                          medicamentosPaciente,
                        });
                      }
                    );
                  }
                } else if (id_medicamento) {
                  this.addMedicamento(id_medicamento, null);
                }
              }}
              callbackMedicamentoString={({
                medicamentoString,
                opcionesModuloNext,
              }) => {
                this.addMedicamento(0, medicamentoString);
              }}
              readOnly={
                (this.state.opcionActualModulo === "historial" ||
                  this.state.opcionActualModulo === "eliminados") &&
                this.state.subOpcionActualModulo !== "agregar"
              }
              medicamento={selectedMedicamento}
              indexDetalleSeleccionado={this.state.indexDetalleSeleccionado}
              opcionMedicamentos={this.state.subOpcionActualModulo}
            />
          </div>
        );
      case "solicitudes":
        const selectedSolicitud = this.filtroSolicitudes(
          this.state.opcionActualModulo
        )[this.state.indexSeleccionado];
        return (
          <div style={styles.subMenuBar}>
            <EstudiosHelpBar
              pageSize={this.props.pageSize}
              estudio={selectedSolicitud}
              clearSearch={true}
              callback={({ item, estudio, opcionesModuloNext }) => {
                if (estudio) {
                  let estudios = this.props.pacienteActual.solicitudActual
                    .estudios;
                  const indexItem = estudios.indexOf(selectedSolicitud);
                  estudios[indexItem] = estudio;

                  this.newStateUpdate({
                    fecha: Date(),
                    estudios,
                    indexSeleccionado: null,
                    subOpcionActualModulo: "agregar",
                  });
                } else if (item) {
                  this.addEstudios(item);
                }
              }}
              opcionEstudios={this.state.subOpcionActualModulo}
              opcion={this.state.opcionActualModulo}
            />
          </div>
        );
      case "estudios":
        let estudiosLoad = [];
        switch (this.state.opcionActualModulo) {
          case "visualizar":
            estudiosLoad = this.state.estudiosVideos;
            break;
          case "registro":
            estudiosLoad = this.state.estudiosVideos;
            break;
        }
        return (
          <div style={styles.subMenuBar}>
            <MultimediaHelpBar
              pageSize={this.props.pageSize}
              multimedias={estudiosLoad}
              callback={({ item }) => {
                this.setState(
                  { multimediaCargado: item },
                  this.updateHelperSidebar()
                );
              }}
              callbackVideo={({ item }) => {
                this.setState(
                  { multimediaVideoCargado: item },
                  this.updateHelperSidebar()
                );
              }}
              opcionEstudios={this.state.opcionActualModulo}
              opcion={this.state.opcionActualModulo}
            />
          </div>
        );
      case "timeLine":
        return null
        return (
          <div style={styles.subMenuBar}>
            <AppBar
              position={"static"}
              color="primary"
              key={`header`}
              style={{ height: 95 }}
            >
              <Toolbar color="primary" key={`header`} style={{ height: 95 }}>
                <Typography
                  style={{
                    textAlign: "center",
                    width: "100%",
                    flexDirection: "column",
                    verticalAlign: "center",
                    justifyItems: "center",
                  }}
                >
                  Anatomia 3D
                </Typography>
              </Toolbar>
            </AppBar>
            <Decals
              height={window.innerHeight - 95}
              width={sidebarWidth}
              id_sexo={
                this.props.pacienteActual &&
                this.props.pacienteActual.detalle &&
                this.props.pacienteActual.detalle.id_sexo
              }
              style={{
                width: "100%",
                height: "calc(100% - 95px)",
                backgroundColor: window.colors.menu,
                top: 0,
                margin: 0,
                borderRadius: 0,
                color: "white",
              }}
            />
          </div>
        );
      default:
        return (
          <div style={styles.subMenuBar}>
            <button
              style={{
                width: "100%",
                height: 74,
                backgroundColor: window.colors.menu,
                position: "absolute",
                top: 0,
                margin: 0,
              }}
            >
              Visor 3D
            </button>
          </div>
        );
    }
  }

  renderNotificationModal() {
    if (this.state.modal != null) {
      return (
        <NotificationsModal
          basic
          multiple
          size="small"
          title={this.state.modal != null ? this.state.modal.title : ""}
          visible={this.state.modal != null ? true : false}
          message={this.state.modal != null ? this.state.modal.message : ""}
          options={this.state.modal != null ? this.state.modal.options : []}
          success={() => {
            this.state.modal.success && this.state.modal.success();
          }}
          close={() => {
            this.setState({ modal: null });
          }}
        />
      );
    }
  }

  renderActionSheet() {
    if (this.state.actionSheet) {
      return (
        <ActionSheet
          open
          content={this.state.actionSheet ? this.state.actionSheet : {}}
        />
      );
    }
  }

  renderContentToolbar() {
    const { pacienteActual: paciente } = this.props;
    const { expansionValue: value } = this.state;
    const {
      solicitudActual: solicitud,
      tratamientoActual: tratamiento,
    } = paciente;
    const style = {
      fontSize: "1.2rem",
      backgroundColor: "transparent",
      width: "100%",
      minHeight: 200,
      maxHeight: 400,
      minWidth: "100%",
      border: 0,
      outline: "none",
      padding: 15,
      color: "inherit",
      overflow: "auto",
    };

    switch (this.state.moduloActual) {
      case "tratamientos":
        return (
          <TextareaAutosize
            key="tratamiento-textarea"
            autoFocus
            placeholder="Escribe las observaciones...."
            defaultValue={value}
            onBlur={(e) => {
              tratamiento.vc_observaciones = e.target.value;
              this.props.setPacienteActual({
                ...paciente,
                tratamientoActual: tratamiento,
              });
            }}
            style={style}
          />
        );
      case "solicitudes":
        return (
          <TextareaAutosize
            key="solicitud-textarea"
            autoFocus
            placeholder="Escribe las observaciones...."
            defaultValue={value}
            onBlur={(e) => {
              solicitud.vc_observaciones = e.target.value;
              this.props.setPacienteActual({
                ...this.props.pacienteActual,
                solicitudActual: solicitud,
              });
            }}
            style={style}
          />
        );
      default:
        return null;
    }
  }

  renderToolBar() {
    const { toolBar_Pose, moduloActual, expansionOpen } = this.state;
    return (
      toolBar_Pose && (
        <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
          <ExpansionPanel
            expanded={expansionOpen}
            onChange={(e, expanded) => {
              this.setState({ expansionOpen: expanded });
            }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Indicaciones</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {this.renderContentToolbar()}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      )
    );
  }

  // ------------------------
  // ------  methods --------
  // ------------------------

  uploadFileServer(blob) {
    const url = `${
      window.mediaBackEndURL
    }/api/v1/upload?id_pacienteRespuesta=109&token=${window.amplify.store(
      "token"
    )}`;
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("originalname", `${moment() * 1}.webm`);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const { VideoCustom } = this.props;
    const urlVideo = URL.createObjectURL(blob);
    const actionSheet = {
      title: `Video listo para guardar`,
      message: "Seleccione",
      frame: (
        <VideoCustom
          src={urlVideo}
          autoPlay
          style={{
            width: "100%",
            boxShadow: "1px 1px 1px rgba(0,0,0,0.9",
            margin: 0,
            padding: 0,
            borderRadius: 0,
            float: "center",
          }}
        />
      ),
      actions: [
        {
          title: "Guardar",
          action: () => {
            this.setState({ actionSheet: null }, () => {
              post(url, formData, config)
                .then((response) => {
                  const actionSheet = {
                    title: `Se ha registrado el video correctamente con el id: ${response.data.id}`,
                    message: "",
                    actions: [],
                    cancel: {
                      title: "Ok",
                      action: () => {
                        this.setState({ actionSheet: null });
                      },
                    },
                  };
                  this.setState({ error: "", loadingOn: null, actionSheet });
                })
                .catch((e) => {
                  const actionSheet = {
                    title: `No se ha registrado el video`,
                    message: "Intente de nuevo por favor",
                    actions: [],
                    cancel: {
                      title: "Ok",
                      action: () => {
                        this.setState({ actionSheet: null });
                      },
                    },
                  };
                  this.setState({ error: "", loadingOn: null, actionSheet });
                });
            });
          },
        },
      ],
      cancel: {
        title: "Eliminar",
        action: () => {
          this.setState({ actionSheet: null });
        },
      },
    };
    this.setState({ error: "", loadingOn: null, actionSheet });
  }

  uploadFile(blob) {
    const paciente = this.props.pacienteActual.detalle;
    const timeStamp = moment() * 1;
    const firebaseUser = this.state.vc_email.split(".").join("");

    let ref = this.storage.child(
      `videos/${firebaseUser}/${paciente.id}/${timeStamp}.webm`
    );
    const refFire = this.db
      .child("videos")
      .child(`${firebaseUser}`)
      .child(`${paciente.id}`)
      .child(`${timeStamp}`);
    const id_estudio = this.state.id_estudio;
    refFire.set({
      id_usuario: window.amplify.store("institution")
        ? window.amplify.store("institution").id
        : null,
      id_paciente: paciente.id,
      id_estudio: id_estudio,
      timeStamp: timeStamp,
      url: null,
    });
    let uploadTask = ref.put(blob);
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      },
      function (error) {
        const firebaseUser = this.state.vc_email.split(".").join("");
        this.db
          .child("videos")
          .child(firebaseUser)
          .child(`${paciente.id}`)
          .child(`${timeStamp}`)
          .remove();
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          refFire.set({
            id_usuario: window.amplify.store("institution")
              ? window.amplify.store("institution").id
              : null,
            id_paciente: paciente.id,
            id_estudio: id_estudio,
            timeStamp: timeStamp,
            url: downloadURL,
          });
        });
      }
    );
  }

  updateDimensions() {
    this.updateHelperSidebar();
  }

  navigate(url) {
    if (url == null) {
      logOut();
    } else {
      eventManager.emit(START_VIEW_TRANSITION, `#/admin/${url}`);
    }
  }

  chargePausedNote(paciente, newPacienteActual) {
    if (paciente != null) {
      if (
        paciente.id != null &&
        this.state.vc_email != null &&
        paciente.id != undefined &&
        this.state.vc_email != undefined
      ) {
        const firebaseUser = this.state.vc_email.split(".").join("");

        this.db
          .child("pausedNotes")
          .child(firebaseUser)
          .child(`${paciente.id}`)
          .once("value", (snapshot) => {
            if (snapshot.val() != null) {
              this.props.setPacienteActual({
                ...newPacienteActual,
                ...JSON.parse(snapshot.val()),
              });
              this.db
                .child("pausedNotes")
                .child(firebaseUser)
                .child(`${this.props.pacienteActual.detalle.id}`)
                .remove();
            } else {
              this.props.setPacienteActual(newPacienteActual);
            }
          });
      }
    }
  }

  async suscriptionsFirebase(paciente) {
    if (paciente != null) {
      if (
        paciente.id != null &&
        this.state.vc_email != null &&
        paciente.id != undefined &&
        this.state.vc_email != undefined
      ) {
        const firebaseUser = this.state.vc_email.split(".").join("");

        this.db
          .child("patientsActive")
          .child(firebaseUser)
          .child(`${paciente.id}`)
          .update(paciente);

        // Agregamos la cuenta
        // const institucion = window.amplify.store('institution') || {};
        // if (institucion.id_institucion) {
        //     const snapshot = await this.db.child('erp').child('orders').child(`${institucion.id_institucion}`).once("value")
        //     const orders = Object.values( snapshot.val() )
        //     const account = orders.find(
        //         (v) => v.client.id === paciente.id && !("saleCut" in v)
        //       );

        //     if (!account){
        //         const accountId = moment().unix();
        //         await this.db
        //           .child("erp")
        //           .child("orders")
        //           .child(`${institucion.id_institucion}`)
        //           .child(`${accountId}`)
        //           .update({
        //             id: accountId,
        //             client: paciente,
        //             datails: [],
        //           });
        //     }
        // }

        this.db
          .child("assistantComands")
          .child(firebaseUser)
          .child(`${paciente.id}`)
          .on("value", (snapshot) => {
            console.log("comand received", snapshot.val());
            if (snapshot.val() != null) {
              this.onComansReceived(snapshot.val());
            }
          });
        this.db
          .child("images")
          .child(firebaseUser)
          .child(`${paciente.id}`)
          .on("value", (snapshot) => {
            if (snapshot.val() != null) {
              this.onImageReceived(snapshot.val());
            }
          });
        this.db
          .child("videos")
          .child(firebaseUser)
          .child(`${paciente.id}`)
          .on("value", (snapshot) => {
            if (snapshot.val() != null) {
              this.onVideoReceived(snapshot.val());
            }
          });
      } else {
        console.log(
          "fallo registro paciente en firebase:",
          " paciente:",
          paciente,
          "usuario:",
          this.state.vc_email
        );
      }
    } else {
      console.log("fallo registro paciente en firebase: el paciente el null");
    }
  }

  printerNotas() {
    if (this.props.pacienteActual) {
      window.$("#impresion").print();
    } else {
      window.$("#impresion").print();
      notifications.error("No hay ningun paciente cargado.");
    }
  }

  setMenuOptions() {
    let options = this.state.menuNotas.map((option) => ({
      ...option,
      onClick: this.onChangeModule.bind(this),
    }));

    // options = options.filter((v) => v.id !== "timeLine");

    this.props.setSubMenuOptions(options);
  }

  onComansReceived(comands) {
    if (comands != null) {
      let moduloActual = "notas";
      let opcionActualModulo = "notas";
      let searchText = this.state.searchText;
      let diagnosticosFiltrados = this.state.diagnosticosFiltrados;
      let medicamentosFiltrados = this.state.medicamentosFiltrados;
      const preguntasTexto = this.props.pacienteActual.preguntasTexto || [];
      const preguntasNumericas =
        this.props.pacienteActual.preguntasNumericas || [];

      if (Object.values(comands) != undefined) {
        const firebaseUser = this.state.vc_email.split(".").join("");
        Object.keys(comands).map((key) => {
          this.db
            .child("assistantComands")
            .child(firebaseUser)
            .child(`${this.props.pacienteActual.detalle.id}`)
            .child(`${key}`)
            .remove();
        });
        Object.values(comands).map((comand) => {
          switch (`${comand.id_comand}`) {
            case "2":
              searchText = comand.comand;
              moduloActual = "solicitudes";
            case "3":
              searchText = comand.comand;
              diagnosticosFiltrados = this.props.diagnosticos
                .filter(
                  (e) =>
                    e.vc_nombre
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                )
                .filter((e, index) => index < 30);
              moduloActual = "diagnosticos";
            case "4":
              searchText = comand.comand;
              medicamentosFiltrados = this.props.medicamentosDgis
                .filter(
                  (e) =>
                    e.vc_nombre
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                )
                .filter((e, index) => index < 30);
              moduloActual = "tratamientos";
            case 11:
              break;
            case 12:
              break;
            case 13:
              break;
            case 14:
              break;
            default:
              moduloActual = "notas";
              comand.respuestas.map((respuesta) => {
                preguntasTexto.map((pregunta, index) => {
                  if (respuesta.id_pregunta == pregunta.id) {
                    const respuestaNueva =
                      preguntasTexto[index].respuesta != undefined
                        ? `${preguntasTexto[index].respuesta.vc_respuesta}, ${respuesta.vc_respuesta}`
                        : `${respuesta.vc_respuesta}`;
                    if (!preguntasTexto[index].respuesta) {
                      preguntasTexto[index].respuesta = {
                        vc_respuesta: `${respuestaNueva}`,
                      };
                    } else {
                      preguntasTexto[index].respuesta = {
                        vc_respuesta:
                          (preguntasTexto[index].respuesta.vc_respuesta || "") +
                          `${respuestaNueva}`,
                      };
                    }
                  }
                });
                preguntasNumericas.map((pregunta, index) => {
                  if (respuesta.id_pregunta == pregunta.id) {
                    if (pregunta.id_tipoPregunta == 3) {
                      preguntasNumericas[index].respuesta = {
                        id_pregunta: respuesta.id_pregunta,
                        nu_respuesta: respuesta.nu_respuesta.toFixed(1),
                      };
                    } else {
                      preguntasNumericas[index].respuesta = respuesta;
                    }
                  }
                  if (pregunta.id == 243) {
                    if (
                      preguntasNumericas.filter((e, index) => e.id == 241)
                        .length > 0 &&
                      preguntasNumericas.filter((e, index) => e.id == 242)
                        .length > 0
                    ) {
                      if (
                        preguntasNumericas.filter((e, index) => e.id == 241)[0]
                          .respuesta &&
                        preguntasNumericas.filter((e, index) => e.id == 242)[0]
                          .respuesta
                      ) {
                        if (
                          preguntasNumericas.filter(
                            (e, index) => e.id == 241
                          )[0].respuesta.nu_respuesta &&
                          preguntasNumericas.filter(
                            (e, index) => e.id == 242
                          )[0].respuesta.nu_respuesta
                        ) {
                          const tallaMts =
                            preguntasNumericas.filter(
                              (e, index) => e.id == 241
                            )[0].respuesta.nu_respuesta / 100;
                          const imc =
                            preguntasNumericas.filter(
                              (e, index) => e.id == 242
                            )[0].respuesta.nu_respuesta /
                            (tallaMts * tallaMts);
                          preguntasNumericas[index].respuesta = {
                            id_pregunta: respuesta.id_pregunta,
                            nu_respuesta: imc.toFixed(1),
                          };
                        }
                      }
                    }
                  }
                });
              });
          }
        });
      }

      this.setState(
        {
          indexSeleccionado: null,
          subOpcionActualModulo: null,
          opcionActualModulo,
          moduloActual,
          searchText,
          medicamentosFiltrados,
          diagnosticosFiltrados,
        },
        () => {
          this.props.setPacienteActual({
            ...this.props.pacienteActual,
            preguntasNumericas,
            preguntasTexto,
          });
          this.updateHelperSidebar();
        }
      );
    }
  }

  onImageReceived(images) {
    if (images != null) {
      if (images != undefined) {
        const multimedias = Object.values(images);
        this.setState({ estudios: multimedias }, () => {
          this.updateHelperSidebar();
        });
      }
    }
  }

  onVideoReceived(video) {
    if (video != null) {
      if (video != undefined) {
        const multimediasVideos = Object.values(video);
        this.setState({ estudiosVideos: multimediasVideos }, () => {
          this.updateHelperSidebar();
        });
      }
    }
  }

  updateHelperSidebar() {
    this.props.setHelperSidebarRender(this.renderHelperSidebar.bind(this));
  }

  addEstudios(item) {
    let solicitudActual = this.props.pacienteActual.solicitudActual || {};
    const {
      estudios,
      gruposEstudios,
      gruposEstudios_estudios,
      paquetesEstudios_estudios_grupos,
    } = this.props;

    switch (item.vc_componente) {
      case "estudio": {
        const existe = solicitudActual.estudios
          .filter((v) => "estudio" in v)
          .some((e) => e.id == item.id);

        if (existe) {
          return notifications.error("Este estudio ya ha sido agregado.");
        }

        solicitudActual.estudios.push({
          id: item.id,
          id_nivel: item.id_nivel,
          id_pregunta: item.id_pregunta,
          id_tipoEstudio: item.id_tipoEstudio,
          vc_observaciones: item.vc_observaciones,
          estudio: item,
          id_prioridad: 1,
        });
        break;
      }
      case "grupo": {
        if (item.sn_mostrar == 1) {
          const gruposEstudiosFilter = gruposEstudios_estudios.filter(
            (e) => e.id_grupo == item.id
          );

          gruposEstudiosFilter.forEach((v) => {
            const estudio = estudios.find((e) => e.id == v.id_estudio);

            // HAY GRUPOS CON ID_ESTUDIO QUE NO EXISTEN EN ESTUDIOS
            if (!estudio) return;

            const existe = solicitudActual.estudios
              .filter((v) => "estudio" in v)
              .some((e) => e.id == estudio.id);

            if (!existe) {
              solicitudActual.estudios.push({
                id: estudio.id,
                id_nivel: estudio.id_nivel,
                id_pregunta: estudio.id_pregunta,
                id_tipoEstudio: estudio.id_tipoEstudio,
                estudio: estudio,
                grupo: item,
                vc_observaciones: null,
                id_prioridad: 1,
              });
            }
            // else {
            //     notifications.error('Este grupo de estudios ya ha sido agregado y mostrado.');
            // }
          });
        } else {
          const existe = solicitudActual.estudios
            .filter((v) => "grupo" in v && !v.grupo.sn_mostrar)
            .some((e) => e.id == item.id);

          if (existe)
            return notifications.error(
              "Este grupo de estudios ya ha sido agregado."
            );

          solicitudActual.estudios.push({
            id: item.id,
            id_nivel: item.id_nivel,
            // id_pregunta: item.id_pregunta,
            id_tipoEstudio: item.id_tipoEstudio,
            grupo: item,
            vc_observaciones: null,
            id_prioridad: 1,
          });
        }
        break;
      }
      case "paquete": {
        const paquetesEstudios = paquetesEstudios_estudios_grupos.filter(
          (e) => e.id_paquete == item.id
        );

        // Recorremos el contenido del paquete
        paquetesEstudios.forEach((estudio_grupo) => {
          // Validamos si es estudio
          if (estudio_grupo.id_estudio != null) {
            const estudio = estudios.find(
              (e) => e.id == estudio_grupo.id_estudio
            );

            // HAY GRUPOS CON ID_ESTUDIO QUE NO EXISTEN EN ESTUDIOS
            if (!estudio) return;

            const existe = solicitudActual.estudios
              .filter((v) => "estudio" in v)
              .some((e) => e.id == estudio.id);

            if (!existe) {
              solicitudActual.estudios.push({
                id: estudio.id,
                id_nivel: estudio.id_nivel,
                id_pregunta: estudio.id_pregunta,
                id_tipoEstudio: estudio.id_tipoEstudio,
                estudio: estudio,
                paquete: item,
                vc_observaciones: null,
                id_prioridad: 1,
              });
            }
          }
          // Validamos si es grupo
          else if (estudio_grupo.id_grupo != null) {
            const grupo = gruposEstudios.find(
              (e) => e.id == estudio_grupo.id_grupo
            );

            if (item.sn_mostrar == 1) {
              const gruposEstudiosFilter = gruposEstudios_estudios.filter(
                (e) => e.id_grupo == item.id
              );

              gruposEstudiosFilter.forEach((v) => {
                const estudio = estudios.find((e) => e.id == v.id_estudio);

                // HAY GRUPOS CON ID_ESTUDIO QUE NO EXISTEN EN ESTUDIOS
                if (!estudio) return;

                const existe = solicitudActual.estudios
                  .filter((v) => "estudio" in v)
                  .some((e) => e.id == item.id);

                if (!existe) {
                  solicitudActual.estudios.push({
                    id: estudio.id,
                    id_nivel: estudio.id_nivel,
                    id_pregunta: estudio.id_pregunta,
                    id_tipoEstudio: estudio.id_tipoEstudio,
                    estudio: estudio,
                    grupo: grupo,
                    paquete: item,
                    vc_observaciones: null,
                    id_prioridad: 1,
                  });
                }
                // else {
                //     notifications.error('Este grupo de estudios ya ha sido agregado y mostrado.');
                // }
              });
            } else {
              const existe = solicitudActual.estudios
                .filter((v) => "grupo" in v && !v.grupo.sn_mostrar)
                .some((e) => e.id == item.id_grupo);

              if (existe)
                return notifications.error(
                  "Este grupo de estudios ya ha sido agregado."
                );

              solicitudActual.estudios.push({
                id: grupo.id,
                id_nivel: grupo.id_nivel,
                id_tipoEstudio: grupo.id_tipoEstudio,
                grupo: grupo,
                vc_observaciones: null,
                id_prioridad: 1,
              });
            }
          }
        });
        break;
      }
    }
    this.setState({ solicitudActual }, () => {
      this.props.setPacienteActual({
        ...this.props.pacienteActual,
        solicitudActual,
      });
      this.updateHelperSidebar();
    });
  }

  addDx(id_diagnostico) {
    const diagnosticosPaciente =
      this.props.pacienteActual.diagnosticosPaciente || [];
    diagnosticosPaciente.push({
      id: null,
      estatus: 2,
      id_institucion: window.amplify.store("institution").id_institucion,
      id_nota: null,
      id_diagnostico: id_diagnostico,
      id_paciente: this.props.pacienteActual.id_paciente,
      id_usuario: window.amplify.store("user_id"),
      sn_activo: 1,
      dt_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
      detalles: [
        {
          id: null,
          id_institucion: window.amplify.store("institution").id_institucion,
          id_notaOrigen: null,
          id_notaActual: null,
          id_notaDiagnostico: null,
          id_diagnostico: id_diagnostico,
          id_estatusDiagnostico: 2,
          id_usuario: window.amplify.store("user_id"),
          id_conciliacion: 1,
          id_um_convalecencia: null,
          nu_convalecencia: null,
          dt_inicio: moment(),
          dt_vigencia: moment(moment() + 604800000),
          sn_principal: 0,
          sn_subsecuente: 0,
          sn_activo: 1,
          dt_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
          vc_observaciones: "",
        },
      ],
    });
    let opcionActualModulo = this.state.opcionActualModulo;
    if (
      opcionActualModulo == "eliminados" ||
      opcionActualModulo == "historial"
    ) {
      opcionActualModulo = "activos";
    }

    this.setState({ diagnosticosPaciente, opcionActualModulo }, () => {
      this.props.setPacienteActual({
        ...this.props.pacienteActual,
        diagnosticosPaciente,
      });
      this.updateHelperSidebar();
    });
  }

  addProcedimiento(id_procedimiento) {
    let procedimientosPaciente =
      this.props.pacienteActual.procedimientosPaciente || [];
    procedimientosPaciente.push({
      id: null,
      estatus: 2,
      id_institucion: window.amplify.store("institution").id_institucion,
      id_nota: null,
      id_diagnostico: null,
      id_procedimiento: id_procedimiento,
      id_paciente: this.props.pacienteActual.id_paciente,
      id_usuario: window.amplify.store("user_id"),
      sn_activo: 1,
      dt_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
      detalles: [
        {
          id: null,
          id_institucion: window.amplify.store("institution").id_institucion,
          id_notaOrigen: null,
          id_notaActual: null,
          id_notaProcedimiento: null,
          id_procedimiento: id_procedimiento,
          id_diagnostico: null,
          id_estatusProcedimiento: 2,
          id_usuario: window.amplify.store("user_id"),
          id_conciliacion: 1,
          id_um_convalecencia: null,
          nu_convalecencia: null,
          dt_convalecencia: moment(moment() + 86400000),
          dt_inicio: moment(),
          dt_vigencia: moment(moment() + 604800000),
          sn_principal: 0,
          sn_subsecuente: 0,
          sn_activo: 1,
          dt_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
          vc_observaciones: "",
        },
      ],
    });
    let opcionActualModulo = this.state.opcionActualModulo;
    if (
      opcionActualModulo == "eliminados" ||
      opcionActualModulo == "historial"
    ) {
      opcionActualModulo = "agregados";
    }

    this.setState({ opcionActualModulo }, () => {
      this.props.setPacienteActual({
        ...this.props.pacienteActual,
        procedimientosPaciente,
      });
      this.updateHelperSidebar();
    });
  }

  tomasDia(medicamentoThink) {
    const um_frecuencia = this.props.unidadesMedida.filter(
      (e, index) => e.id == medicamentoThink.id_um_frecuencia
    )[0];
    if (um_frecuencia != undefined) {
      const nu_frecHoras =
        (um_frecuencia.nu_ratio * medicamentoThink.nu_frecuencia) / 60;
      if (nu_frecHoras <= 24) {
        return 24 / nu_frecHoras;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  }

  // addMedicamento(id_medicamento, medicamentoString) {
  //     const medicamentosPaciente = this.props.pacienteActual.medicamentosPaciente || [];
  //     const id_paciente = this.props.pacienteActual ? this.props.pacienteActual.id_paciente : null;
  //     const medicamento = medicamentosPaciente.find(e => e.id_medicamento == id_medicamento)
  //     const indexMedicamento = medicamentosPaciente.findIndex(e => e.id_medicamento == id_medicamento)
  //     const existe = medicamentosPaciente.some(e => e.id_medicamento == id_medicamento)

  //     if ( medicamentoString == null ) {
  //         let nu_vehiculos = null;
  //         let nu_dosis = null;
  //         let id_um_dosis = null;
  //         let dt_vigencia = null;
  //         let id_viaAdministracion = null;
  //         let id_vehiculo = null;

  //         let lastDetalle = null;
  //         if (medicamento) {
  //             lastDetalle = [...medicamento.detalles].pop()
  //         }
  //         const isActive = isDetalleActive(lastDetalle)
  //         const sustanciaActiva = this.props.medicamentosSustanciasActivas.find(v => v.id_medicamento == id_medicamento);
  //         const medicamentoThink = this.props.medicamentosThink.find(v => v.id == id_medicamento);

  //         if( sustanciaActiva && sustanciaActiva.nu_dosisDiaria ){
  //             const nu_unidadesDiarias = sustanciaActiva.nu_dosisDiaria / sustanciaActiva.nu_concentracion;

  //             if (nu_unidadesDiarias && this.tomasDia(medicamentoThink) != null) {
  //                 const tomasDia = this.tomasDia(medicamentoThink);
  //                 nu_vehiculos = nu_unidadesDiarias / tomasDia;
  //                 nu_dosis = sustanciaActiva.nu_dosisDiaria / tomasDia;
  //                 id_um_dosis = sustanciaActiva.id_um_concentracion
  //             }
  //         }

  //         const um_duracion = this.props.unidadesMedida.find(v => v.id == medicamentoThink.id_um_duracion);
  //         if (medicamentoThink.nu_duracion != null && medicamentoThink.id_um_duracion != null && um_duracion) {
  //             const nu_duracionMilisegundos = (um_duracion.nu_ratio * medicamentoThink.nu_duracion) * 60000;
  //             const refDate = moment();
  //             const interval = (refDate + nu_duracionMilisegundos);
  //             dt_vigencia = moment(interval);
  //         }

  //         id_viaAdministracion = medicamentoThink.id_viaAdministracion || 34
  //         id_vehiculo = medicamentoThink.id_vehiculo || 28

  //         if( existe && isActive){
  //             notifications.error('Este medicamento ya ha sido agregado.');
  //         }
  //         else if( existe && !isActive){
  //             medicamento.detalles.push({
  //                 id: null,
  //                 id_institucion: window.amplify.store('institution').id_institucion,
  //                 id_tratamientoOrigen: null,
  //                 id_tratamientoActual: null,
  //                 id_tratamientoMedicamento: null,
  //                 id_usuario: window.amplify.store('user_id'),
  //                 id_estatus: 1,
  //                 id_um_dosis: id_um_dosis,
  //                 id_um_duracion: medicamentoThink.id_um_duracion,
  //                 id_um_frecuencia: medicamentoThink.id_um_frecuencia,
  //                 id_um_intervalo: medicamentoThink.id_um_intervalo,
  //                 id_conciliacion: 1,
  //                 administraciones: [
  //                     {
  //                         nu_dosis: nu_dosis,
  //                         nu_vehiculos: nu_vehiculos,
  //                         vc_dosis: null,
  //                         tm_toma: '06:00:00'
  //                     }
  //                 ],
  //                 id_vehiculo: id_vehiculo,
  //                 id_viaAdministracion: id_viaAdministracion,
  //                 nu_duracion: medicamentoThink.nu_duracion,
  //                 nu_frecuencia: medicamentoThink.nu_frecuencia,
  //                 nu_intervalo: medicamentoThink.nu_intervalo,
  //                 dt_vigencia: dt_vigencia,
  //                 dt_inicio: moment(),
  //                 tm_inicio: null,
  //                 vc_observaciones: '',
  //                 sn_activo: 1,
  //                 dt_registro: moment().format('YYYY-MM-DD HH:mm:ss')
  //             })

  //             medicamentosPaciente[indexMedicamento] = medicamento
  //         }
  //         else{
  //             medicamentosPaciente.push({
  //                 id: null,
  //                 id_estatus: 1,
  //                 id_institucion: window.amplify.store('institution').id_institucion,
  //                 id_nota: null,
  //                 id_tratamiento: null,
  //                 id_medicamento: id_medicamento,
  //                 vc_medicamento: null,
  //                 id_paciente: id_paciente,
  //                 id_usuario: window.amplify.store('user_id'),
  //                 sn_activo: 1,
  //                 dt_registro: moment().format('YYYY-MM-DD HH:mm:ss'),
  //                 detalles: [
  //                     {
  //                         id: null,
  //                         id_institucion: window.amplify.store('institution').id_institucion,
  //                         id_tratamientoOrigen: null,
  //                         id_tratamientoActual: null,
  //                         id_tratamientoMedicamento: null,
  //                         id_usuario: window.amplify.store('user_id'),
  //                         id_estatus: 1,
  //                         id_um_dosis: id_um_dosis,
  //                         id_um_duracion: medicamentoThink.id_um_duracion,
  //                         id_um_frecuencia: medicamentoThink.id_um_frecuencia,
  //                         id_um_intervalo: medicamentoThink.id_um_intervalo,
  //                         id_conciliacion: 1,
  //                         administraciones: [
  //                             {
  //                                 nu_dosis: nu_dosis,
  //                                 nu_vehiculos: nu_vehiculos,
  //                                 vc_dosis: null,
  //                                 tm_toma: '06:00:00'
  //                             }
  //                         ],
  //                         id_vehiculo: id_vehiculo,
  //                         id_viaAdministracion: id_viaAdministracion,
  //                         nu_duracion: medicamentoThink.nu_duracion,
  //                         nu_frecuencia: medicamentoThink.nu_frecuencia,
  //                         nu_intervalo: medicamentoThink.nu_intervalo,
  //                         dt_vigencia: dt_vigencia,
  //                         dt_inicio: moment(),
  //                         tm_inicio: null,
  //                         vc_observaciones: '',
  //                         sn_activo: 1,
  //                         dt_registro: moment().format('YYYY-MM-DD HH:mm:ss')
  //                     }
  //                 ]
  //             })
  //         }

  //         let opcionActualModulo = this.state.opcionActualModulo;
  //         if (opcionActualModulo == 'eliminados' || opcionActualModulo == 'historial') {
  //             opcionActualModulo = 'actual'
  //         }
  //         this.setState({ opcionActualModulo }, () => {
  //             this.props.setPacienteActual({ ...this.props.pacienteActual, medicamentosPaciente });
  //             this.updateHelperSidebar();
  //         });
  //     }

  //     if (medicamentoString != null) {
  //         medicamentosPaciente.push({
  //             id: null,
  //             id_estatus: 1,
  //             id_institucion: window.amplify.store('institution').id_institucion,
  //             id_nota: null,
  //             id_tratamiento: null,
  //             id_medicamento: id_medicamento,
  //             vc_medicamento: medicamentoString.vc_nombre,
  //             id_paciente: id_paciente,
  //             id_usuario: window.amplify.store('user_id'),
  //             sn_activo: 1,
  //             dt_registro: moment().format('YYYY-MM-DD HH:mm:ss'),
  //             detalles: [
  //                 {
  //                     id: null,
  //                     id_institucion: window.amplify.store('institution').id_institucion,
  //                     id_tratamientoOrigen: null,
  //                     id_tratamientoActual: null,
  //                     id_tratamientoMedicamento: null,
  //                     id_usuario: window.amplify.store('user_id'),
  //                     id_estatus: 1,
  //                     id_um_dosis: null,
  //                     id_um_duracion: 3,
  //                     id_um_frecuencia: 2,
  //                     id_um_intervalo: null,
  //                     id_conciliacion: 1,
  //                     administraciones: [
  //                         {
  //                             nu_dosis: null,
  //                             nu_vehiculos: 1,
  //                             vc_dosis: null,
  //                             tm_toma: '06:00:00'
  //                         }
  //                     ],
  //                     id_vehiculo: 28,
  //                     id_viaAdministracion: 34,
  //                     nu_duracion: null,
  //                     nu_frecuencia: 24,
  //                     nu_intervalo: null,
  //                     dt_vigencia: null,
  //                     dt_inicio: moment(),
  //                     tm_inicio: '06:00:00',
  //                     vc_observaciones: '',
  //                     sn_activo: 1,
  //                     dt_registro: moment().format('YYYY-MM-DD HH:mm:ss')
  //                 }
  //             ]
  //         });
  //         let opcionActualModulo = this.state.opcionActualModulo;
  //         if (opcionActualModulo == 'eliminados' || opcionActualModulo == 'historial') {
  //             opcionActualModulo = 'actual'
  //         }
  //         this.setState({ opcionActualModulo }, () => {
  //             this.props.setPacienteActual({ ...this.props.pacienteActual, medicamentosPaciente });
  //             this.updateHelperSidebar();
  //         });
  //     }
  // }

  addMedicamento(id_medicamento, medicamentoString) {
    const medicamentosPaciente =
      this.props.pacienteActual.medicamentosPaciente || [];
    const id_paciente = this.props.pacienteActual
      ? this.props.pacienteActual.id_paciente
      : null;
    const existe = medicamentosPaciente.some(
      (e) =>
        e.id_medicamento == id_medicamento &&
        isDetalleActive(getLastDetalle(e.detalles, "dt_vigencia"))
    );

    if (medicamentoString == null) {
      const medicamentoSustanciaActiva = this.props.medicamentosSustanciasActivas.filter(
        (e, index) => e.id_medicamento == id_medicamento
      );
      const medicamentoThink = this.props.medicamentosThink.filter(
        (e, index) => e.id == id_medicamento
      )[0];
      let nu_vehiculos = null;
      let nu_dosis = null;
      let id_um_dosis = null;
      let dt_vigencia = null;
      let id_viaAdministracion = null;
      let id_vehiculo = null;
      if (medicamentoSustanciaActiva.length > 0) {
        if (medicamentoSustanciaActiva[0].nu_dosisDiaria) {
          const nu_unidadesDiarias =
            medicamentoSustanciaActiva[0].nu_dosisDiaria /
            medicamentoSustanciaActiva[0].nu_concentracion;
          if (nu_unidadesDiarias && this.tomasDia(medicamentoThink) != null) {
            const tomasDia = this.tomasDia(medicamentoThink);
            nu_vehiculos = nu_unidadesDiarias / tomasDia;
            nu_dosis = medicamentoSustanciaActiva[0].nu_dosisDiaria / tomasDia;
            id_um_dosis = medicamentoSustanciaActiva[0].id_um_concentracion;
            const vehiculo = this.props.vehiculos.find(
              (e) => e.id === medicamentoThink.id_vehiculo
            );
            if (vehiculo) {
              const fixs = (nu_vehiculos / (vehiculo.nu_scala || 1)).toFixed(0);
              nu_vehiculos = fixs / (vehiculo.nu_scala || 1);
              if (nu_vehiculos < 1) {
                nu_vehiculos = 1;
              }
              nu_dosis =
                nu_vehiculos * medicamentoSustanciaActiva[0].nu_concentracion;
            }
          }
        }
      }
      if (
        medicamentoThink.nu_duracion != null &&
        medicamentoThink.id_um_duracion != null
      ) {
        const um_duracion = this.props.unidadesMedida.filter(
          (e, index) => e.id == medicamentoThink.id_um_duracion
        )[0];
        if (um_duracion != undefined) {
          const nu_duracionMilisegundos =
            um_duracion.nu_ratio * medicamentoThink.nu_duracion * 60000;
          const refDate = moment();
          const interval = refDate + nu_duracionMilisegundos;
          dt_vigencia = moment(interval);
        }
      }
      if (medicamentoThink.id_viaAdministracion != null) {
        id_viaAdministracion = medicamentoThink.id_viaAdministracion;
      } else {
        id_viaAdministracion = 34;
      }
      if (medicamentoThink.id_vehiculo != null) {
        id_vehiculo = medicamentoThink.id_vehiculo;
      } else {
        id_vehiculo = 28;
      }
      medicamentosPaciente.push({
        id: null,
        id_estatus: 1,
        id_institucion: window.amplify.store("institution").id_institucion,
        id_nota: null,
        id_tratamiento: null,
        id_medicamento: id_medicamento,
        vc_medicamento: null,
        id_paciente: id_paciente,
        id_usuario: window.amplify.store("user_id"),
        sn_activo: 1,
        dt_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
        detalles: [
          {
            id: null,
            id_institucion: window.amplify.store("institution").id_institucion,
            id_tratamientoOrigen: null,
            id_tratamientoActual: null,
            id_tratamientoMedicamento: null,
            id_usuario: window.amplify.store("user_id"),
            id_estatus: 1,
            id_um_dosis: id_um_dosis,
            id_um_duracion: medicamentoThink.id_um_duracion,
            id_um_frecuencia: medicamentoThink.id_um_frecuencia,
            id_um_intervalo: medicamentoThink.id_um_intervalo,
            id_conciliacion: 1,
            administraciones: [
              {
                nu_dosis: nu_dosis,
                nu_vehiculos: nu_vehiculos,
                vc_dosis: null,
                tm_toma: null,
              },
            ],
            id_vehiculo: id_vehiculo,
            id_viaAdministracion: id_viaAdministracion,
            nu_duracion: medicamentoThink.nu_duracion,
            nu_frecuencia: medicamentoThink.nu_frecuencia,
            nu_intervalo: medicamentoThink.nu_intervalo,
            dt_vigencia: dt_vigencia,
            dt_inicio: moment(),
            tm_inicio: null,
            vc_observaciones: "",
            sn_activo: 1,
            dt_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        ],
      });
      let opcionActualModulo = this.state.opcionActualModulo;
      if (
        opcionActualModulo == "eliminados" ||
        opcionActualModulo == "historial"
      ) {
        opcionActualModulo = "actual";
      }
      this.setState({ opcionActualModulo }, () => {
        this.props.setPacienteActual({
          ...this.props.pacienteActual,
          medicamentosPaciente,
        });
        this.updateHelperSidebar();
      });
    } else if (medicamentoString != null) {
      medicamentosPaciente.push({
        id: null,
        id_estatus: 1,
        id_institucion: window.amplify.store("institution").id_institucion,
        id_nota: null,
        id_tratamiento: null,
        id_medicamento: id_medicamento,
        vc_medicamento: medicamentoString.vc_nombre,
        id_paciente: id_paciente,
        id_usuario: window.amplify.store("user_id"),
        sn_activo: 1,
        dt_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
        detalles: [
          {
            id: null,
            id_institucion: window.amplify.store("institution").id_institucion,
            id_tratamientoOrigen: null,
            id_tratamientoActual: null,
            id_tratamientoMedicamento: null,
            id_usuario: window.amplify.store("user_id"),
            id_estatus: 1,
            id_um_dosis: null,
            id_um_duracion: 3,
            id_um_frecuencia: 2,
            id_um_intervalo: null,
            id_conciliacion: 1,
            administraciones: [
              {
                nu_dosis: null,
                nu_vehiculos: 1,
                vc_dosis: null,
                tm_toma: null,
              },
            ],
            id_vehiculo: 28,
            id_viaAdministracion: 34,
            nu_duracion: 7,
            nu_frecuencia: 24,
            nu_intervalo: null,
            dt_vigencia: moment().add("days", 7).format("YYYY-MM-DD HH:mm:ss"),
            dt_inicio: moment(),
            tm_inicio: null,
            vc_observaciones: "",
            sn_activo: 1,
            dt_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        ],
      });
      let opcionActualModulo = this.state.opcionActualModulo;
      if (
        opcionActualModulo == "eliminados" ||
        opcionActualModulo == "historial"
      ) {
        opcionActualModulo = "actual";
      }
      this.setState({ opcionActualModulo }, () => {
        this.props.setPacienteActual({
          ...this.props.pacienteActual,
          medicamentosPaciente,
        });
        this.updateHelperSidebar();
      });
    } else {
      notifications.error("No existe medicamento para agregar.");
    }
  }

  getLimitArray(arr, limit) {
    let results = [];
    let limitReal = arr.length < limit ? arr.length : limit;
    for (var i = 0; i < limitReal; i++) {
      results.push(arr[i]);
    }
    return results;
  }

  getAge(dateString) {
    var today = moment();
    var birthDate = moment(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  pausarNota(error) {
    if (
      this.props.pacienteActual &&
      this.props.pacienteActual.detalle &&
      this.state.vc_email
    ) {
      const paciente = this.props.pacienteActual.detalle;
      const firebaseUser = this.state.vc_email.split(".").join("");
      this.db
        .child("pausedNotes")
        .child(firebaseUser)
        .child(`${paciente.id}`)
        .set(JSON.stringify(this.props.pacienteActual));
      if (error) {
        this.db
          .child("errors")
          .child(firebaseUser)
          .set({
            error,
            pacienteActual: JSON.stringify(this.props.pacienteActual),
            state: this.state,
          });
        console.log("error in catch error notas ", error);
        eventManager.emit(START_VIEW_TRANSITION, `#/admin/notas`);
      }
    }
  }

  guardarTratamiento() {
    // ESTATUS
    // 1 -> vigentes
    // 2 -> nuevos
    // 3 -> eliminados

    const now = moment();
    const { medicamentosPaciente = [] } = this.props.pacienteActual || {};
    const medicamentos = medicamentosPaciente.map((medicamento) => {
      const detalles = medicamento.detalles.map((v) => ({
        ...v,
        dt_registro: moment(v.dt_registro).format("YYYY-MM-DD HH:mm:ss"),
        dt_inicio: moment(v.dt_inicio).format("YYYY-MM-DD HH:mm:ss"),
        dt_vigencia: moment(v.dt_vigencia).format("YYYY-MM-DD HH:mm:ss"),
      }));

      const lastIndex = getIndexLastDetalle(
        medicamento.detalles,
        "dt_vigencia"
      );

      const lastDetalle = medicamento.detalles[lastIndex];

      const medicamentoEdit = {
        dt_registro: moment(lastDetalle.dt_registro).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        id_medicamento: medicamento.id_medicamento,
        vc_medicamento: medicamento.vc_medicamento,
        id_um_dosis: lastDetalle.id_um_dosis,
        detalles,
      };

      const vigencia = moment(lastDetalle.dt_vigencia);
      if (!medicamento.id) {
        medicamentoEdit.estatus = 2;
      } else if (vigencia > now) {
        medicamentoEdit.id_tratamientoMedicamento = medicamento.id;
        medicamentoEdit.id_tratamientoOrigen = medicamento.id_tratamiento;
        medicamentoEdit.estatus = 1;
      } else if (vigencia <= now) {
        medicamentoEdit.id_tratamientoMedicamento = medicamento.id;
        medicamentoEdit.id_tratamientoOrigen = medicamento.id_tratamiento;
        medicamentoEdit.estatus = 3;
      }

      return medicamentoEdit;
    });

    // const medicamentos = [];
    // this.props.pacienteActual.medicamentosPaciente
    //   .filter(
    //     (e, index) => getLastDetalle(e.detalles, "dt_vigencia").id == null
    //   )
    //   .map((medicamento, index) => {
    //     let detalles = [];
    //     medicamento.detalles
    //       .filter((item) => item.id == null)
    //       .map((detalle, index) => {
    //         detalles.push({
    //           id_um_duracion: detalle.id_um_duracion,
    //           id_um_frecuencia: detalle.id_um_frecuencia,
    //           id_vehiculo: detalle.id_vehiculo,
    //           id_viaAdministracion: detalle.id_viaAdministracion,
    //           dt_registro: moment(detalle.dt_registro).format(
    //             "YYYY-MM-DD HH:mm:ss"
    //           ),
    //           dt_inicio: moment(detalle.dt_inicio).format(
    //             "YYYY-MM-DD HH:mm:ss"
    //           ),
    //           dt_vigencia: moment(detalle.dt_vigencia).format(
    //             "YYYY-MM-DD HH:mm:ss"
    //           ),
    //           administraciones: detalle.administraciones,
    //           nu_duracion: detalle.nu_duracion,
    //           nu_frecuencia: detalle.nu_frecuencia,
    //           vc_observaciones: detalle.vc_observaciones,
    //         });
    //       });
    //     const lastDetalleIndex = getIndexLastDetalle(
    //       medicamento.detalles,
    //       "dt_vigencia"
    //     );
    //     const medicamentoEdit = {
    //       dt_registro: moment(
    //         medicamento.detalles[lastDetalleIndex].dt_registro
    //       ).format("YYYY-MM-DD HH:mm:ss"),
    //       id_medicamento: medicamento.id_medicamento,
    //       vc_medicamento: medicamento.vc_medicamento,
    //       id_um_dosis: medicamento.detalles[lastDetalleIndex].id_um_dosis,
    //       detalles: detalles,
    //     };

    //     console.log("MEDICAMENTOEDIT =====> ", medicamentoEdit);
    //     if (medicamento.id == null) {
    //       console.log("ESTATUS =====> 2");
    //       medicamentoEdit.estatus = 2;
    //     } else if (
    //       medicamento.detalles[lastDetalleIndex].id == null &&
    //       moment(medicamento.detalles[lastDetalleIndex].dt_vigencia) > moment()
    //     ) {
    //       console.log("ESTATUS =====> 1");
    //       medicamentoEdit.id_tratamientoMedicamento = medicamento.id;
    //       medicamentoEdit.id_tratamientoOrigen = medicamento.id_tratamiento;
    //       medicamentoEdit.estatus = 1;
    //     } else if (
    //       medicamento.detalles[lastDetalleIndex].id == null &&
    //       moment(medicamento.detalles[lastDetalleIndex].dt_vigencia) <= moment()
    //     ) {
    //       console.log("ESTATUS =====> 3");
    //       medicamentoEdit.id_tratamientoMedicamento = medicamento.id;
    //       medicamentoEdit.id_tratamientoOrigen = medicamento.id_tratamiento;
    //       medicamentoEdit.estatus = 3;
    //     }

    //     console.log("MEDICAMENTOEDIT =====> ", medicamentoEdit);
    //     medicamentos.push(medicamentoEdit);
    //   });
    const tratamiento = {
      id_institucion: window.amplify.store("institution").id_institucion,
      id_dependencia: window.amplify.store("dependencia")
        ? window.amplify.store("dependencia").id
        : 1,
      id_area: window.amplify.store("area")
        ? window.amplify.store("area").id
        : 1,
      id_modulo: window.amplify.store("modulo")
        ? window.amplify.store("modulo").id
        : 1,
      id_usuario: window.amplify.store("user_id"),
      id_paciente: this.props.pacienteActual.detalle.id,
      id_atencion: this.props.pacienteActual.atencionActual
        ? this.props.pacienteActual.atencionActual.id
        : 1,
      medicamentos,
    };

    if (medicamentos.length > 0) {
      const sendings = this.state.sendings;
      sendings.tratamientos = true;
      this.setState({ sendings });
      internalAPI.registrarTratamiento(tratamiento, (err, response) => {
        if (err) {
          sendings.tratamientos = false;
          this.setState({ sendings });
        } else {
          if (response.message) {
            notifications.success(response.message);
            this.reloadMedicamentos(response);
          } else {
            sendings.tratamientos = false;
            this.setState({ sendings });
          }
        }
      });
    } else {
      notifications.error("no hay ningun medicamento editado para guardar.");
    }
  }

  reloadMedicamentos(response) {
    console.log("NUEVO RESPONSE", response);
    const { pacienteActual } = this.props;
    const { sendings } = this.state;
    const { tratamientos, medicamentos, tratamiento } = response;

    if (!!tratamientos) pacienteActual.tratamientosPaciente = tratamientos;
    if (!!medicamentos) pacienteActual.medicamentosPaciente = medicamentos;
    if (!!tratamiento) pacienteActual.tratamientoActual = tratamiento;

    pacienteActual.tratamientosNota = [
      ...(pacienteActual.tratamientosNota || []),
      tratamiento.id,
    ];

    notifications.success("tratamiento guardado correctamente.");
    sendings.tratamientos = false;

    this.setState({
      sendings,
      medicamentosPaciente: pacienteActual.medicamentosPaciente,
    });

    this.props.setPacienteActual(pacienteActual);

    // const medicamentosPaciente = this.props.pacienteActual.medicamentosPaciente.filter(
    //   (e, index) => getLastDetalle(e.detalles, "dt_vigencia").id != null
    // );

    // let id_tratamiento = null;
    // if (response.tratamiento) {
    //   id_tratamiento = response.tratamiento.id;
    //   response.tratamiento.medicamentos.map((medicamento, index) => {
    //     medicamentosPaciente.push(medicamento);
    //   });
    //   notifications.success("tratamiento guardado correctamente.");
    //   this.setState({ sendings, medicamentosPaciente });
    // } else {
    //   notifications.error("no se guardo el tratamiento.");
    //   this.setState({ sendings, medicamentosPaciente });
    // }
    // const sendings = this.state.sendings;
    // sendings.tratamiento = false;
    // this.setState({ sendings, medicamentosPaciente, id_tratamiento }, () => {
    //   const pacienteActual = this.props.pacienteActual;
    //   pacienteActual.medicamentosPaciente = [...medicamentosPaciente];
    //   this.props.setPacienteActual(pacienteActual);
    // });
  }

  renderCatDX(diagnosticos) {
    if (this.state.moduloActual == "diagnosticos") {
      return diagnosticos.map((item, index) => (
        <div
          onClick={() => this.addDx(item, index)}
          key={index}
          style={styles.containerHelp}
        >
          <div style={styles.descriptionHelp}>{item.vc_nombre}</div>
          <div style={styles.stickHelp}>{item.cve_cie10}</div>
          <div style={styles.line} />
        </div>
      ));
    }
  }

  renderCatMedicamentos(medicamentos) {
    if (this.state.moduloActual == "tratamientos") {
      return medicamentos.map((item, index) => (
        <div
          onClick={() => this.addMedicamento(item.id, null)}
          key={index}
          style={styles.containerHelp}
        >
          <div style={styles.descriptionHelp}>{item.vc_nombre}</div>
          <div style={styles.subTitleHelp}>{item.vc_concentracion}</div>
          <div style={styles.subTitleRightHelp}>{item.vc_farmaceutica}</div>
          <div style={styles.line} />
        </div>
      ));
    }
  }

  filtroDiagnosticos(opcionActualModulo) {
    const diagnosticosPaciente =
      this.props.pacienteActual.diagnosticosPaciente || [];
    switch (opcionActualModulo) {
      case "activos":
        return diagnosticosPaciente.filter(
          (e, index) =>
            isDetalleActive(getLastDetalle(e.detalles)) || e.id == null
        );
        break;
      case "agregados":
        return diagnosticosPaciente.filter((e, index) => e.id == null);
        break;
      case "eliminados":
        return diagnosticosPaciente.filter(
          (e, index) =>
            isDetalleActive(getLastDetalle(e.detalles)) != true && e.id != null
        );
        break;
      case "historial":
        return diagnosticosPaciente.filter((e, index) => e.id != null);
        break;
      default:
        return [];
        break;
    }
  }

  filtroProcedimientos(opcionActualModulo) {
    const procedimientosPaciente =
      this.props.pacienteActual.procedimientosPaciente || [];
    switch (opcionActualModulo) {
      case "convalecientes":
        return procedimientosPaciente.filter(
          (e, index) =>
            isDetalleActive(getLastDetalle(e.detalles)) || e.id == null
        );
        break;
      case "agregados":
        return procedimientosPaciente.filter((e, index) => e.id == null);
        break;
      case "historial":
        return procedimientosPaciente.filter((e, index) => e.id != null);
      default:
        return [];
    }
  }

  // filtroMedicamentos(opcionActualModulo) {
  //     const medicamentosPaciente = this.props.pacienteActual.medicamentosPaciente || [];
  //     switch ( opcionActualModulo ) {
  //         case 'actual':
  //         return medicamentosPaciente.filter((e) => isDetalleActive(e.detalles[e.detalles.length - 1].dt_vigencia) || e.id == null );
  //         break;
  //         case 'agregados':
  //         return medicamentosPaciente.filter((e) => e.id == null );
  //         break;
  //         case 'eliminados':
  //         return medicamentosPaciente.filter((e) => isDetalleActive(e.detalles[e.detalles.length - 1].dt_vigencia) != true && e.id != null );
  //         break;
  //         case 'historial':
  //         return medicamentosPaciente.filter((e) => e.id != null );
  //         break;
  //         default:
  //         return [];
  //         break;
  //     }
  // }

  filtroMedicamentos(opcionActualModulo) {
    const medicamentosPaciente =
      this.props.pacienteActual.medicamentosPaciente || [];

    switch (opcionActualModulo) {
      case "actual":
        return medicamentosPaciente.filter(
          (e) =>
            isDetalleActive(getLastDetalle(e.detalles, "dt_vigencia")) ||
            e.id == null
        );
      case "agregados":
        return medicamentosPaciente.filter((e) => e.id == null);
      case "eliminados":
        return medicamentosPaciente.filter(
          (e) =>
            !isDetalleActive(getLastDetalle(e.detalles, "dt_vigencia")) &&
            e.id != null
        );
      case "historial":
        return medicamentosPaciente.filter((e) => e.id != null);
      default:
        return [];
    }
  }

  newStateUpdate(state, callback = () => {}) {
    this.setState(state, () => {
      callback();
      this.updateHelperSidebar();
      this.forceUpdate();
    });
  }

  deleteItems(item) {
    let index = null;
    switch (this.state.moduloActual) {
      case "solicitudes":
        let solicitudActual = this.props.pacienteActual.solicitudActual || {
          vc_observaciones: "",
        };
        index = solicitudActual.estudios.indexOf(item);
        if (index > -1) {
          solicitudActual.estudios.splice(index, 1);
          this.setState(
            { indexSeleccionado: null, subOpcionActualModulo: "agregar" },
            () => {
              this.props.setPacienteActual({
                ...this.props.pacienteActual,
                solicitudActual,
              });
              this.updateHelperSidebar();
            }
          );
        }
        break;
      case "procedimientos":
        let procedimientosPaciente =
          this.props.pacienteActual.procedimientosPaciente || [];
        index = procedimientosPaciente.indexOf(item);
        if (item.id == null) {
          if (index > -1) {
            procedimientosPaciente.splice(index, 1);
            this.setState(
              { indexSeleccionado: null, subOpcionActualModulo: "agregar" },
              () => {
                this.props.setPacienteActual({
                  ...this.props.pacienteActual,
                  procedimientosPaciente,
                });
                this.updateHelperSidebar();
              }
            );
          }
        } else {
          const actionSheet = {
            title: "Procedimiento registrado",
            message: "Seleccione",
            actions: [
              {
                title: "Suspender (recomendado)",
                action: () => {
                  let px = procedimientosPaciente[index];
                  const lastDetalleIndex = getIndexLastDetalle(px.detalles);
                  if (px.detalles[lastDetalleIndex].id != null) {
                    let lastDetalle = px.detalles[lastDetalleIndex];
                    lastDetalle.id = null;
                    lastDetalle.id_notaActual = null;
                    lastDetalle.dt_vigencia = moment().format(
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    px.detalles.push(lastDetalle);
                    procedimientosPaciente[index] = px;
                  } else {
                    px.detalles[lastDetalleIndex].id = null;
                    px.detalles[lastDetalleIndex].id_notaActual = null;
                    px.detalles[lastDetalleIndex].dt_vigencia = moment().format(
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    procedimientosPaciente[index] = px;
                  }
                  this.setState({ actionSheet: null }, () => {
                    this.props.setPacienteActual({
                      ...this.props.pacienteActual,
                      procedimientosPaciente,
                    });
                    this.renderHelperSidebar();
                  });
                },
              },
              {
                title: "Eliminar",
                action: () => {
                  let px = procedimientosPaciente[index];
                  const lastDetalleIndex = getIndexLastDetalle(px.detalles);
                  if (px.detalles[lastDetalleIndex].id != null) {
                    let lastDetalle = px.detalles[lastDetalleIndex];
                    lastDetalle.id = null;
                    lastDetalle.sn_activo = 0;
                    lastDetalle.id_notaActual = null;
                    lastDetalle.dt_vigencia = moment().format(
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    px.detalles.push(lastDetalle);
                    procedimientosPaciente[index] = px;
                  } else {
                    px.detalles[lastDetalleIndex].sn_activo = 0;
                    px.detalles[lastDetalleIndex].id = null;
                    px.detalles[lastDetalleIndex].id_notaActual = null;
                    px.detalles[lastDetalleIndex].dt_vigencia = moment().format(
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    procedimientosPaciente[index] = px;
                  }
                  this.setState({ actionSheet: null }, () => {
                    this.props.setPacienteActual({
                      ...this.props.pacienteActual,
                      procedimientosPaciente,
                    });
                    this.renderHelperSidebar();
                  });
                },
              },
            ],
            cancel: {
              title: "Cancelar",
              action: () => {
                this.setState({ actionSheet: null });
              },
            },
          };
          this.setState({ error: "", loadingOn: null, actionSheet });
        }
        break;
      case "diagnosticos":
        let diagnosticosPaciente =
          this.props.pacienteActual.diagnosticosPaciente || [];
        index = diagnosticosPaciente.indexOf(item);
        if (item.id == null) {
          if (index > -1) {
            diagnosticosPaciente.splice(index, 1);
            this.setState(
              { indexSeleccionado: null, subOpcionActualModulo: "agregar" },
              () => {
                this.props.setPacienteActual({
                  ...this.props.pacienteActual,
                  diagnosticosPaciente,
                });
                this.updateHelperSidebar();
              }
            );
          }
        } else {
          const actionSheet = {
            title: "Diagnostico registrado",
            message: "Seleccione",
            actions: [
              {
                title: "Suspender (recomendado)",
                action: () => {
                  let dx = diagnosticosPaciente[index];
                  const lastDetalleIndex = getIndexLastDetalle(dx.detalles);
                  if (dx.detalles[lastDetalleIndex].id != null) {
                    let lastDetalle = dx.detalles[lastDetalleIndex];
                    lastDetalle.id = null;
                    lastDetalle.id_notaActual = null;
                    lastDetalle.dt_vigencia = moment().format(
                      "YYYY-MM-DD HH:MM:SS"
                    );
                    dx.detalles.push(lastDetalle);
                    diagnosticosPaciente[index] = dx;
                  } else {
                    dx.detalles[lastDetalleIndex].id = null;
                    dx.detalles[lastDetalleIndex].id_notaActual = null;
                    dx.detalles[lastDetalleIndex].dt_vigencia = moment().format(
                      "YYYY-MM-DD HH:MM:SS"
                    );
                    diagnosticosPaciente[index] = dx;
                  }
                  this.setState({ actionSheet: null }, () => {
                    this.props.setPacienteActual({
                      ...this.props.pacienteActual,
                      diagnosticosPaciente,
                    });
                    this.renderHelperSidebar();
                  });
                },
              },
              {
                title: "Eliminar",
                action: () => {
                  let dx = diagnosticosPaciente[index];
                  const lastDetalleIndex = getIndexLastDetalle(dx.detalles);
                  if (dx.detalles[lastDetalleIndex].id != null) {
                    let lastDetalle = dx.detalles[lastDetalleIndex];
                    lastDetalle.id = null;
                    lastDetalle.sn_activo = 0;
                    lastDetalle.id_notaActual = null;
                    lastDetalle.dt_vigencia = moment().format(
                      "YYYY-MM-DD HH:MM:SS"
                    );
                    dx.detalles.push(lastDetalle);
                    diagnosticosPaciente[index] = dx;
                  } else {
                    dx.detalles[lastDetalleIndex].sn_activo = 0;
                    dx.detalles[lastDetalleIndex].id = null;
                    dx.detalles[lastDetalleIndex].id_notaActual = null;
                    dx.detalles[lastDetalleIndex].dt_vigencia = moment().format(
                      "YYYY-MM-DD HH:MM:SS"
                    );
                    diagnosticosPaciente[index] = dx;
                  }
                  this.setState({ actionSheet: null }, () => {
                    this.props.setPacienteActual({
                      ...this.props.pacienteActual,
                      diagnosticosPaciente,
                    });
                    this.renderHelperSidebar();
                  });
                },
              },
            ],
            cancel: {
              title: "Cancelar",
              action: () => {
                this.setState({ actionSheet: null });
              },
            },
          };
          this.setState({ error: "", loadingOn: null, actionSheet });
        }
        break;
      case "tratamientos":
        let medicamentosPaciente =
          this.props.pacienteActual.medicamentosPaciente || [];
        index = medicamentosPaciente.indexOf(item);
        if (item.id == null) {
          if (index > -1) {
            medicamentosPaciente.splice(index, 1);
            this.setState(
              { indexSeleccionado: null, subOpcionActualModulo: "agregar" },
              () => {
                this.props.setPacienteActual({
                  ...this.props.pacienteActual,
                  medicamentosPaciente,
                });
                this.updateHelperSidebar();
              }
            );
          }
        } else {
          const actionSheet = {
            title: "Medicamento registrado",
            message: "Seleccione",
            actions: [
              {
                title: "Suspender",
                action: () => {
                  let medicamento = medicamentosPaciente[index];
                  medicamento.detalles = medicamento.detalles.map(
                    (detalle, index) => {
                      if (isDetalleActive(detalle)) {
                        detalle.dt_vigencia = moment().format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                      }
                      return detalle;
                    }
                  );
                  medicamentosPaciente[index] = medicamento;
                  this.setState({ actionSheet: null }, () => {
                    this.props.setPacienteActual({
                      ...this.props.pacienteActual,
                      medicamentosPaciente,
                    });
                    this.renderHelperSidebar();
                  });
                },
              },
            ],
            cancel: {
              title: "Cancelar",
              action: () => {
                this.setState({ actionSheet: null });
              },
            },
          };
          this.setState({ error: "", loadingOn: null, actionSheet });
        }
        break;
      default: {
        break;
      }
    }
  }

  renderPrint() {
    let solicitudActual = null;
    let tratamientoActual = null;
    let pacienteActual = {};
    let preguntasTexto = [];
    let preguntasNumericas = [];

    const institucion = window.amplify.store("institution");
    const {
      vc_nombre = "",
      vc_apellidoPaterno = "",
      vc_apellidoMaterno = "",
      id_sexo = 0,
    } = window.amplify.store("user")?.usuario || {};
    const rol =
      window.amplify
        .store("roles")
        ?.find((e) => e.id_especialidad && e.vc_cedulaProfesional) || {};
    console.log(window.amplify.store("roles"));
    console.log(rol);
    const especialidad = this.props.especialidades.find(
      (e) => e.id === rol?.id_especialidad || null
    );
    const prefijo = ["", "Dr.", "Dra."][id_sexo];

    const firma = {
      vc_nombre: `${prefijo} ${vc_nombre} ${vc_apellidoPaterno} ${
        vc_apellidoMaterno || ""
      }`.trim(),
      vc_especialidad: especialidad?.vc_nombre || "",
      vc_cedulaProfesional: rol?.vc_cedulaProfesional || "",
      vc_registroSSA: rol?.vc_registroSSA || "",
      vc_direccionReceta: rol?.vc_direccionReceta || "",
      vc_institucionAval: rol?.vc_institucionAval || "",
      vc_urlLogoIzquierdo: rol?.vc_urlLogoIzquierdo || "",
      vc_urlLogoCentral: rol?.vc_urlLogoCentral || "",
      vc_institucion: institucion?.vc_nombre || "",
    };

    switch (this.state.moduloActual) {
      case "tratamientos":
        tratamientoActual = this.props.pacienteActual
          ? this.props.pacienteActual.tratamientoActual || {
              vc_observaciones: "",
            }
          : { vc_observaciones: "" };
        pacienteActual = this.props.pacienteActual || {};
        preguntasTexto = pacienteActual.preguntasTexto || [];
        preguntasNumericas = pacienteActual.preguntasNumericas || [];
        return (
          <NotasPrint
            date={moment()}
            recetaMode={true}
            diagnosticosPaciente={this.filtroDiagnosticos("activos")}
            procedimientosPaciente={pacienteActual.procedimientosPaciente || []}
            medicamentosNotas={this.filtroMedicamentos("actual")}
            preguntas={preguntasNumericas.concat(preguntasTexto)}
            tratamiento={tratamientoActual}
            key="nota"
            firmas={[firma]}
            paciente={this.props.pacienteActual}
          />
        );
        return (
          <RecetasPrint
            med={this.props.medicamentosPaciente}
            medicamentos={this.filtroMedicamentos("actual")}
            vc_observaciones={tratamientoActual.vc_observaciones || null}
            key="receta"
            style={{ margin: 0, padding: 0 }}
          />
        );
      case "solicitudes":
        solicitudActual = this.props.pacienteActual
          ? this.props.pacienteActual.solicitudActual || {
              vc_observaciones: "",
            }
          : { vc_observaciones: "" };
        return (
          <SolicitudesPrint
            solicitudActual={solicitudActual}
            vc_observaciones={solicitudActual.vc_observaciones || null}
            key="solicitudes"
            filtro={this.state.opcionActualModulo}
            style={{ margin: 0, padding: 0 }}
            firmas={[firma]}
            paciente={this.props.pacienteActual}
          />
        );
      default:
        pacienteActual = this.props.pacienteActual || {};
        preguntasTexto = pacienteActual.preguntasTexto || [];
        preguntasNumericas = pacienteActual.preguntasNumericas || [];
        return (
          <NotasPrint
            date={moment()}
            // diagnosticosPaciente={pacienteActual.diagnosticosPaciente || []}
            diagnosticosPaciente={this.filtroDiagnosticos("activos")}
            procedimientosPaciente={pacienteActual.procedimientosPaciente || []}
            medicamentosNotas={pacienteActual.medicamentosPaciente || []}
            preguntas={preguntasNumericas.concat(preguntasTexto)}
            key="nota"
            firmas={[firma]}
            paciente={this.props.pacienteActual}
          />
        );
    }
  }

  filtroSolicitudes(opcionActualModulo) {
    const solicitudActual = this.props.pacienteActual.solicitudActual || {
      estudios: [],
    };
    switch (opcionActualModulo) {
      case "laboratorio":
        return solicitudActual.estudios.filter(
          (e, index) => e.id_tipoEstudio == 1
        );
      case "imagenologia":
        return solicitudActual.estudios.filter(
          (e, index) => e.id_tipoEstudio == 2
        );
      case "patologia":
        return solicitudActual.estudios.filter(
          (e, index) => e.id_tipoEstudio == 3
        );
      default:
        return solicitudActual.estudios;
    }
  }

  clickAccion(accion) {
    switch (accion.id) {
      case "imprimir":
        break;
      case "guardar":
        switch (this.state.moduloActual) {
          case "notas":
            if (this.state.sendings.nota == false) {
              const actionSheet = {
                title: "¿Desesa guardar la nota?",
                message: "Seleccione",
                actions: [
                  {
                    title: "Guardar Nota y Terminar",
                    action: () => {
                      this.setState({ actionSheet: null, finish: true }, () => {
                        repository.guardarNota(this.props.pacienteActual);
                      });
                    },
                  },
                  {
                    title: "Pausar",
                    action: () => {
                      this.setState({ actionSheet: null }, () => {
                        this.pausarNota();
                      });
                    },
                  },
                ],
                cancel: {
                  title: "Cancelar",
                  action: () => {
                    this.setState({ actionSheet: null });
                  },
                },
              };
              this.setState({ error: "", loadingOn: null, actionSheet });
            } else {
              console.log("sistema ocupado guardando la nota.");
            }
            break;
          case "tratamientos":
            if (this.state.sendings.tratamientos == false) {
              this.guardarTratamiento();
            } else {
              console.log("sistema ocupado enviando tratamiento.");
            }
            break;
        }
        break;
      case "pausar":
        switch (this.state.moduloActual) {
          case "notas":
            if (this.state.sendings.nota == false) {
              this.pausarNota();
            } else {
              console.log("sistema ocupado pausando la nota.");
            }
            break;
        }
        break;
    }
  }

  poseModuloToolBar(modulo) {
    const { pacienteActual: paciente } = this.props;

    switch (modulo) {
      case "tratamientos":
        return {
          toolBar_Pose: true,
          expansionValue:
            "tratamientoActual" in paciente
              ? paciente.tratamientoActual.vc_observaciones || ""
              : "",
          expansionOpen: false,
        };
      case "solicitudes":
        return {
          toolBar_Pose: true,
          expansionValue:
            "solicitudActual" in paciente
              ? paciente.solicitudActual.vc_observaciones || ""
              : "",
          expansionOpen: false,
        };
      default:
        return {
          toolBar_Pose: false,
          expansionValue: "",
        };
    }
  }

  onChangeModule(modulo, opcion, subOpcionActualModulo) {
    if (typeof modulo === "string") {
      const moduloActual = this.state.menuNotas.filter(
        (e) => e.id == modulo
      )[0];
      this.setState(
        {
          indexSeleccionado: null,
          moduloActual: moduloActual.id,
          opcionActualModulo: opcion || moduloActual.opciones[0].id,
          subOpcionActualModulo: subOpcionActualModulo || "agregar",
          searchText: "",

          ...this.poseModuloToolBar(modulo),
        },
        () => {
          this.updateHelperSidebar();
        }
      );
    } else {
      this.setState(
        {
          moduloActual: modulo.id,
          indexSeleccionado: null,
          opcionActualModulo: opcion || modulo.opciones[0].id,
          subOpcionActualModulo: subOpcionActualModulo || "agregar",
          searchText: "",

          ...this.poseModuloToolBar(modulo.id),
        },
        () => {
          this.updateHelperSidebar();
          if (modulo.id === 'timeLine'){
            this.props.setDisplaySidebar(false);
          }
        }
      );
    }
  }

  onChangeText(e) {
    const tratamientoActual = {
      ...this.state.tratamientoActual,
      vc_observaciones: e.target.value,
    };
    this.setState({ tratamientoActual });
  }
}

const styles = {
  container: {
    overflowX: "hidden",
    overflowY: "hidden",
    height: "100%",
    margin: 0,
    padding: 0,
    backgroundColor: "rgba(200, 200, 200, 0.1)",
    display: "flex",
    flexDirection: "column",
  },
  containerHelp: {
    margin: 0,
    borderRadius: 3,
    overflowY: "hidden",
    padding: 0,
    marginBottom: 2,
    backgroundColor: window.colors.white,
  },
  layerBackgroundTx: {
    float: "center",
    position: "absolute",
    height: "100vh",
    width: "100%",
    overflow: "hidden",
    margin: 0,
    padding: 0,
    opacity: 0.04,
    backgroundImage: 'url("assets/images/iconThin-k.png")',
    backgroundPosition: "center",
    backgroundSize: "800px 800px",
    backgroundRepeat: "no-repeat",
  },
  descriptionHelp: {
    margin: 0,
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "left",
    fontSize: 14,
    color: "gray",
  },
  stickHelp: {
    margin: 0,
    marginLeft: "85%",
    marginBottom: 2,
    width: 30,
    overflowY: "hidden",
    paddingTop: 0,
    textAlign: "left",
    float: "left",
    fontSize: 12,
    color: window.colors.itemSelected,
  },
  subTitleHelp: {
    margin: 0,
    marginLeft: 10,
    marginRight: 10,
    width: "100%",
    overflowY: "scroll",
    paddingTop: 0,
    textAlign: "left",
    float: "left",
    fontSize: 12,
    color: "black",
  },
  subTitleRightHelp: {
    margin: 0,
    marginLeft: 10,
    marginRight: 10,
    width: "100%",
    paddingTop: 0,
    textAlign: "right",
    float: "right",
    fontSize: 12,
    color: window.colors.itemSelected,
  },
  nota: {
    backgroundColor: window.colors.white,
    color: "white",
    margin: 0,
    overflowY: "hidden",
    borderRadius: 5,
    paddingTop: 0,
    paddingBottom: 10,
    marginBottom: 20,
  },
  notaTitle: {
    backgroundColor: window.colors.itemSelected,
    lineHeight: 2.5,
    color: "white",
    textAlign: "center",
    padding: 0,
    margin: 0,
    marginBottom: 5,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    width: "100%",
    height: 35,
  },
  subHeader: {
    backgroundColor: window.colors.itemSelected,
    lineHeight: 2.5,
    color: "white",
    textAlign: "center",
    padding: 0,
    margin: 0,
    width: "100%",
    height: 40,
  },
  subHeaderHelp: {
    backgroundColor: window.colors.black,
    color: "white",
    textAlign: "center",
    padding: 5,
    margin: 0,
    width: "100%",
    height: 60,
  },
  principal: {
    overflowY: "auto",
    flex: "1 1 auto",
    minHeight: 0,
    height: 0,
    padding: "2%",
  },
  subMenuBar: {
    overflowY: "hidden",
    overflowX: "hidden",
    float: "left",
    backgroundColor: window.colors.black,
    padding: 0,
    margin: 0,
    height: "100%",
    width: "100%",
  },
  line: {
    width: "100%",
    height: 1,
    float: "left",
    backgroundColor: "gray",
  },
};

const mapStateToProps = (state) => {
  const {
    gruposEstudios_estudios,
    gruposEstudios,
    estudios,
    paquetesEstudios_estudios_grupos,
    preguntas,
    medicamentosDgis,
    medicamentosThink,
    unidadesMedida,
    medicamentosSustanciasActivas,
    diagnosticos,
    pacienteActual,
    especialidades,
    preguntaActual,
    vehiculos,
  } = state.Think;
  return {
    gruposEstudios_estudios,
    gruposEstudios,
    especialidades,
    estudios,
    paquetesEstudios_estudios_grupos,
    preguntas,
    medicamentosDgis,
    medicamentosThink,
    unidadesMedida,
    medicamentosSustanciasActivas,
    diagnosticos,
    pacienteActual,
    preguntaActual,
    vehiculos,
  };
};

export default connect(mapStateToProps, {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
})(NotasView);

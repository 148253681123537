import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined'; import Typography from '@material-ui/core/Typography';
import Mic from '@material-ui/icons/Mic';
import PrintOutlined from '@material-ui/icons/PrintOutlined';
import HearingOutlined from '@material-ui/icons/HearingOutlined';
import SportsEsportsOutlined from '@material-ui/icons/SportsEsportsOutlined';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { IconButton, Box, TextField, Paper, Slider, Grid, AppBar, Toolbar, Button, Divider, ButtonGroup } from '@material-ui/core';
import ModalUI from '@material-ui/core/Modal';
import ThinkResponse from '../../../../../material-ui/ThinkResponse';
import { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar, setPacienteActual } from '../../../actions';
import TextView from '../../../../../material-ui/TextView';
import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";
import { Modal } from 'semantic-ui-react';
import GoogleMaps from '../../patients/GoogleMaps';
import Map from "@material-ui/icons/Map";
import moment from 'moment';
import PatientsItem from '../../../../../components/PatientsItem';
import internalAPI from '../../../../../utils/internalAPI';
import * as firebase from 'firebase/app';
import ConfirmAlert from '../../../../../material-ui/ConfirmAlert';
import TextViewThink from './TextViewThink';
import repository from '../repository';
import PrintComponents from 'react-print-components';
import PrintFormInstitution from '../../../../../material-ui/PrintFormInstitution';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 8,
        fontSize: theme.typography.pxToRem(20),
        position: 'relative'
    },
    textArea: {
        color: theme.palette.type === 'dark' ? 'white' : 'gray',
        border: theme.palette.type === 'dark' ? '1px solid rgba(200,200,200,0.2)' : '1px solid rgba(100,100,100,0.2)'
    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightMedium,
        width: '100%'
    },
    InputLabels: {
        fontSize: 30
    }
}));
function addDx(id_diagnostico, pacienteActual) {
    const isCronical = false;
    const diagnosticosPaciente = pacienteActual.diagnosticosPaciente || [];
    if (diagnosticosPaciente.filter((e, index) => e.id_diagnostico == id_diagnostico).length == 0) {
        diagnosticosPaciente.push({
            id: null,
            estatus: 2,
            id_institucion: window.amplify.store('institution').id_institucion,
            id_nota: null,
            id_diagnostico: id_diagnostico,
            id_paciente: pacienteActual.id_paciente,
            id_usuario: window.amplify.store('user_id'),
            sn_activo: 1,
            dt_registro: moment().format('YYYY-MM-DD HH:mm:ss'),
            detalles: [
                {
                    id: null,
                    id_institucion: window.amplify.store('institution').id_institucion,
                    id_notaOrigen: null,
                    id_notaActual: null,
                    id_notaDiagnostico: null,
                    id_diagnostico: id_diagnostico,
                    id_estatusDiagnostico: 2,
                    id_usuario: window.amplify.store('user_id'),
                    id_conciliacion: 1,
                    id_um_convalecencia: null,
                    nu_convalecencia: null,
                    dt_inicio: moment(),
                    dt_vigencia: isCronical ? moment().add(100, "years") : moment().add(3, 'days'),
                    sn_principal: 0,
                    sn_subsecuente: 0,
                    sn_activo: 1,
                    dt_registro: moment().format('YYYY-MM-DD HH:mm:ss'),
                    vc_observaciones: ""
                }
            ]
        });
        pacienteActual.diagnosticosPaciente = diagnosticosPaciente;
    } else {
        pacienteActual.diagnosticosPaciente = diagnosticosPaciente.map((dx, index) => {
            if (dx.id_diagnostico === id_diagnostico) {
                dx.detalles.push({
                    id: null,
                    id_institucion: window.amplify.store('institution').id_institucion,
                    id_notaOrigen: null,
                    id_notaActual: null,
                    id_notaDiagnostico: null,
                    id_diagnostico: id_diagnostico,
                    id_estatusDiagnostico: 2,
                    id_usuario: window.amplify.store('user_id'),
                    id_conciliacion: 1,
                    id_um_convalecencia: null,
                    nu_convalecencia: null,
                    dt_inicio: moment(),
                    dt_vigencia: isCronical ? moment().add(100, "years") : moment().add(3, 'days'),
                    sn_principal: 0,
                    sn_subsecuente: 0,
                    sn_activo: 1,
                    dt_registro: moment().format('YYYY-MM-DD HH:mm:ss'),
                    vc_observaciones: ""
                })
            }
            return dx
        });
    }
    return pacienteActual;
}

function TriageModule(props) {
    const { item, index } = props;
    const classes = useStyles();
    const steps = moment().diff(moment('2020-01-22'), 'days').toFixed(0);
    const [prevValueProps, setPrevValueProps] = React.useState(null);
    const [notification, setNotification] = React.useState(null);
    const [value, setValue] = React.useState(item.respuesta ? item.respuesta.vc_respuesta : '');
    const [hidde, setHidde] = React.useState(window.isMobile ? true : false);
    const [showCode, setShowCode] = React.useState(false);
    const [step, setStep] = React.useState(steps);
    const [showMap, setShowMap] = React.useState(false);
    const [finish, setFinish] = React.useState(false);
    const [over, setOver] = React.useState(false);
    const onChangeValue = (e) => {
        const value = e.target.value || ''
        props.callbackPreg({ value });
    }
    const { speak, speaking, cancel } = useSpeechSynthesis();

    const saveNote = () => {
        const pacienteActual = addDx(14377, props.pacienteActual);
        repository.guardarNota(pacienteActual);
        sendRegister();
    }
    const sendRegister = () => {
        const institucion = window.amplify.store('institution') || {};
        if (institucion.id_institucion) {
            const usuario = (window.amplify.store('user') || {}).usuario || {};
            console.log('usuario', usuario)
            const notas = props.pacienteActual.notas;
            notas.push({ id_pregunta: item.id, vc_respuesta: value, dt_registro: moment().format('YYYY-MM-DD HH:mm:ss') });
            const pacienteRespuestas = props.pacienteActual.pacienteRespuestas.concat(item.respuestasInternas || []);

            if (props.pacienteActual.id) {
                firebase.database().ref().child('epidemiology').child(`${institucion.id_institucion}`).child('covid19').child('reports').child(props.pacienteActual.id).set({
                    detalle: { ...props.pacienteActual.detalle, id_statusSeguimiento: 2 },
                    medicamentosPaciente: props.pacienteActual.medicamentosPaciente || [],
                    diagnosticosPaciente: props.pacienteActual.diagnosticosPaciente || [],
                    procedimientosPaciente: props.pacienteActual.procedimientosPaciente || [],
                    solicitudes: props.pacienteActual.solicitudes || [],
                    signosAlarma: props.pacienteActual.signosAlarma || [],
                    pacienteRespuestas,
                    atenciones: props.pacienteActual.atenciones || [],
                    notas
                })
                setNotification({ title: 'Paciente en seguimiento:', message: '!Se agregó correctamente la valoración!' })
            } else {
                firebase.database().ref().child('epidemiology').child(`${institucion.id_institucion}`).child('covid19').child('reports').push({
                    detalle: { ...props.pacienteActual.detalle, id_statusSeguimiento: 2 },
                    medicamentosPaciente: props.pacienteActual.medicamentosPaciente || [],
                    diagnosticosPaciente: props.pacienteActual.diagnosticosPaciente || [],
                    procedimientosPaciente: props.pacienteActual.procedimientosPaciente || [],
                    solicitudes: props.pacienteActual.solicitudes || [],
                    signosAlarma: props.pacienteActual.signosAlarma || [],
                    pacienteRespuestas,
                    atenciones: props.pacienteActual.atenciones || [],
                    notas
                });
                setNotification({ title: 'Paciente nuevo:', message: '!Se agregó correctamente la valoración!' })
            }

        }
    }
    const onchangeInner = (e) => {
        console.log('console change inner', e.target.value)
        const value = e.target.value || ''
        setValue(value);
    }
    const preguntasEnlace = []
    if (item.version && item.version.id_preguntaEnlace) {
        preguntasEnlace.push(item.version.id_preguntaEnlace)
    } else if (item.versiones[0].id_preguntaEnlace) {
        preguntasEnlace.push(item.versiones[0].id_preguntaEnlace)
    }
    (props.preguntasEnlace || []).map((id_pregunta, index) => {
        preguntasEnlace.push(id_pregunta)
    })

    const version = item.versiones[0] || {};
    useEffect(() => {
        console.log('props.pacienteActual', props.pacienteActual)
    }, [props.pacienteActual])
    useEffect(() => {
        const valueReceibed = props.item.respuesta ? props.item.respuesta.vc_respuesta : '';
        if (valueReceibed != value && valueReceibed != prevValueProps) {
            if (props.value !== null) {
                setPrevValueProps(valueReceibed)
                setValue(valueReceibed)
            }
        }
    }, [prevValueProps, props, value])
    const points = (item.respuestasInternas || []).filter(e => e.nu_lat && e.nu_lon);
    const father = document.getElementById('containerThinkresponse')
    let heightFather = null;
    if (father) {
        heightFather = father.clientHeight
    }
    const [voice, setVoice] = React.useState('');
    const { listen, listening, stop } = useSpeechRecognition({
        onResult: result => {
            setVoice(result + ' ');
        },
        onEnd: () => {
            console.log('volicessss', voice)
            setPregunta(voice);
            setVoice('');
        }
    });
    const setPregunta = (textValue) => {
        const pacienteActual = props.pacienteActual;
        const preguntasTexto = (props.pacienteActual.preguntasTexto || []).map((pregunta, index) => {
            if (item.id === pregunta.id) {
                let vc_respuesta = pregunta.respuesta ? pregunta.respuesta.vc_respuesta : '';
                vc_respuesta = vc_respuesta + textValue;
                const respuestasInternas = pregunta.respuestasInternas || [];
                respuestasInternas.push({ vc_sentencia: textValue });
                return {
                    ...pregunta,
                    respuesta: { ...pregunta.respuesta || {}, vc_respuesta },
                    respuestasInternas
                };
            } else {
                return pregunta;
            }
        });
        const newPacienteActual = { ...pacienteActual, preguntasTexto };
        props.setPacienteActual(newPacienteActual);
    }
    const start = () => {
        try {
            listen();
        } catch {

        }
    }
    return (
        <Paper id={'containerThinkresponse'} onMouseLeave={() => setOver(false)} onMouseEnter={() => setOver(true)} style={{ height: window.isMobile ? 'calc(100vh - 40px)' : 'calc(100vh - 100px)' }}>
            <Grid container direction='row-reverse' style={{ height: '100%' }}>
                {
                    !hidde &&
                    <Grid item xs={hidde ? 0 : window.isMobile ? 12 : 6} style={{ height: '100%', position: 'relative', maxHeight: '100%' }}>
                        <AppBar position={'static'} color='primary' key={`header`} style={{ height: 95, padding: 20 }}>
                            <Typography>
                                {`Nombre: ${props.pacienteActual.detalle.vc_nombre} ${props.pacienteActual.detalle.vc_apellidoPaterno} ${props.pacienteActual.detalle.vc_apellidoMaterno}`}
                            </Typography>
                            <Typography>
                                {`${props.pacienteActual.detalle.id_sexo === 1 ? 'Sexo: Masculino' : 'Sexo: Femenino'} - Edad: ${moment().diff(moment(props.pacienteActual.detalle.dt_nacimiento), 'years')} años`}
                            </Typography>
                        </AppBar>
                        <Box style={{ height: '100%' }}>
                            <Box style={{ height: 43, paddingLeft: 16, paddingRight: 16, borderBottom: '2px solid rgba(200,200,200,0.2)' }}>
                                <Box style={{ height: 40, flexDirection: 'column', float: 'left', alignItems: 'center', alignContent: 'center' }}>
                                    <Typography style={{ display: 'flex', height: 40, alignContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}>
                                        {item.versiones[0].vc_pregunta}
                                    </Typography>
                                </Box>
                                <Box style={{ height: 40, display: 'flex', flexDirection: 'row', float: 'right', alignItems: 'center', verticalAlign: 'middle' }}>
                                    {
                                        points.length > 0 &&
                                        <IconButton disableFocusRipple size='small' onClick={() => setShowMap(true)} style={{ marginRight: 5 }}>
                                            <Map fontSize='small' />
                                        </IconButton>
                                    }

                                    <IconButton disableFocusRipple size='small' onClick={() => {
                                        if (speaking) {
                                            cancel()
                                        } else {
                                            const docu = (document.getElementById(`preguntaTexto:${item.id}`) || {}).innerText || 'No hay texto que decir'
                                            speak({ text: docu, voice: window.speechSynthesis.getVoices().filter(e => e.lang === 'es-MX')[0] || undefined })
                                        }
                                    }} style={{ marginRight: 5 }}>
                                        <HearingOutlined fontSize='small' />
                                    </IconButton>
                                    <IconButton onMouseUp={stop} onMouseDown={start} disableFocusRipple size='small' style={{ marginRight: 5 }}>
                                        <Mic fontSize='small' />
                                    </IconButton>
                                    <IconButton disableFocusRipple size='small' onClick={() => {
                                        if (showCode) {
                                            setShowCode(!showCode)
                                        } else {
                                            setShowCode(!showCode)
                                        }
                                    }} style={{ marginRight: 5 }}>
                                        {
                                            showCode ?
                                                <VisibilityOutlined fontSize='small' /> :
                                                <VisibilityOffOutlined fontSize='small' />
                                        }
                                    </IconButton>
                                    <PrintComponents
                                        trigger={
                                            <IconButton disableFocusRipple size='small' style={{ marginRight: 5 }}>
                                                <PrintOutlined fontSize='small' />
                                            </IconButton>
                                            // <Button variant='contained' color='primary' fullWidth>Imprimir</Button>
                                        }
                                    >
                                        {
                                            document.getElementById(`preguntaTexto:${item.id}`) &&
                                            <PrintFormInstitution
                                                module={'Nota'}
                                                content={
                                                    <div style={{ fontSize: 16, color: 'black', paddingTop: 16, whiteSpace: 'pre-wrap' }}>
                                                        {document.getElementById(`preguntaTexto:${item.id}`).innerText}
                                                    </div>
                                                }
                                            />
                                        }
                                    </PrintComponents>
                                </Box>
                            </Box>
                            <Box
                                id={`preguntaTexto:${item.id}`}
                                style={{ height: window.isMobile ? 'calc(100vh - 220px)' : 'calc(100vh - 282px)', padding: 8, overflowY: 'scroll' }}
                            >
                                {console.log(item)}
                                <TextViewThink
                                    showCode={showCode}
                                    item={item}
                                    voice={voice}
                                    fullHeight
                                    callbackFinish={() => {
                                        setHidde(false)
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                }
                {
                    (!window.isMobile || hidde) &&
                    <Grid item xs={hidde ? 12 : 6} style={{ height: 'calc(100% - 50px)' }}>
                        {
                            !finish ?
                                <ThinkResponse
                                    noAutofocus
                                    heightFather={heightFather ? heightFather - 50 : null}
                                    onchangeInner={onchangeInner}
                                    preguntasEnlace={preguntasEnlace}
                                    id_preguntaPadre={item.id}
                                    callbackFinish={() => {
                                        setFinish(true)
                                    }}
                                />
                                :
                                <Box>
                                    <AppBar position={'static'} color='primary' key={`header`} style={{ height: 95, padding: 20 }}>
                                        <Typography>
                                            {`Nota`}
                                        </Typography>
                                        <Typography>
                                            {'Completa'}
                                        </Typography>
                                    </AppBar>
                                    <Box p={1} alignContent='center'>
                                        <Typography variant='h4' style={{ width: '100%', marginTop: 20, textAlign: 'center' }}>
                                            {'¡Listo para guardar!'}
                                        </Typography>
                                    </Box>
                                </Box>
                        }
                    </Grid>
                }
                <Box
                    style={{ position: 'absolute', bottom: 0, width: '100%', padding: 8, paddingTop: 0 }}
                >
                    <ButtonGroup fullWidth>
                        <Button onClick={() => setFinish(false)} variant='contained' color='primary'>
                            {'Reiniciar'}
                        </Button>
                        <Button variant='contained' color='primary' onClick={saveNote} fullWidth>Guardar</Button>
                    </ButtonGroup>
                </Box>
            </Grid>
            <ModalUI
                open={showMap}
                onClose={() => setShowMap(false)}
                style={{ boxShadow: '0px 0px 0px transparent', backgroundColor: 'transparent', margin: window.isMobile ? 50 : 100 }}
                size={'fullscreen'}
            >
                <Paper style={{ padding: 0, borderRadius: 20, overflow: 'hidden', height: '100%', width: '100%', outline: 'none' }}>
                    <GoogleMaps
                        points={points}
                        circles={(props.covid19 || {}).countries || []}
                        controls={
                            <div style={{ width: '100%', padding: '0px 60px 0px 60px', paddingTop: 15, marginBottom: 10, marginTop: 10, bottom: 0 }}>
                                <Slider
                                    defaultValue={step}
                                    getAriaValueText={(value) => {
                                        return `${moment('2020-01-22').add(value, 'days').format('DD-MM')}`
                                    }}
                                    onChange={(value) => {
                                        setStep(value);
                                    }}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay={(value) => {
                                        return `${moment('2020-01-22').add(value, 'days').format('DD-MM')}`
                                    }}
                                    step={1}
                                    marks
                                    min={0}
                                    max={steps}
                                />
                            </div>
                        }
                    />
                    <div style={{ width: '100%', padding: '0px 60px 0px 60px', paddingTop: 15, marginBottom: 10, marginTop: 10, bottom: 0 }}>
                        <Slider
                            defaultValue={step}
                            getAriaValueText={(value) => {
                                return `${moment('2020-01-22').add(value, 'days').format('DD-MM')}`
                            }}
                            onChange={(value) => {
                                setStep(value);
                            }}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay={(value) => {
                                return `${moment('2020-01-22').add(value, 'days').format('DD-MM')}`
                            }}
                            step={1}
                            marks
                            min={0}
                            max={steps}
                        />
                    </div>
                </Paper>
            </ModalUI>
            <Button color='secondary' onClick={() => setHidde(!hidde)} style={{ position: 'absolute', right: 4, top: 4, zIndex: 3000 }}>{hidde ? 'Mostrar' : 'Ocultar'}</Button>
            {notification &&
                <ConfirmAlert
                    title={notification.title || ''}
                    message={notification.message || ''}
                    onAccept={() => {
                        setNotification(null);
                        props.callbackFinish && props.callbackFinish()
                    }}
                />
            }
        </Paper>
    );
}

const actions = { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar, setPacienteActual };
const mapStateToProps = (state) => {
    const { preguntas, pacienteActual, diagnosticos, covid19 } = state.Think;
    return { preguntas, pacienteActual, diagnosticos, covid19 };
};

export default connect(mapStateToProps, actions)(TriageModule);

import http from '../http';
import httpAdmin from '../httpAdmin';


// servicioGET(body, callback, headers) {
//     http.get(`/unServicio/${body}`, headers, callback);
// },

export default {
  registrarUsuario(body, callback) {
    const headers = { Authorization: window.amplify.store('token') };
    http.post('/api/usuariosRegistro', headers, body, callback);
  },
  getUsuarios(callback) {
    const headers = { Authorization: window.amplify.store('token') };
    const institucionId = window.amplify.store('institution').id_institucion;
    http.get(`/app/api/instituciones/${institucionId}`, headers, callback);
    // http.get(`/app/api/institucionesUsuarios/${institucionId}`, headers, callback);
  },
  searchUsuarios(body, callback) {
    const headers = { Authorization: window.amplify.store('token') };
    http.post('/app/api/usuarios/buscar', headers, body, callback);
  },
  getRoles(callback) {
    const headers = { Authorization: window.amplify.store('token') };

    http.get('/app/api/roles', headers, callback);
  },
  vincularUsuario(body, callback) {
    const headers = { Authorization: window.amplify.store('token') };
    http.post('/app/api/institucionesUsuarios', headers, body, callback);
  },
  guardarUsuarioInstitucion(body, callback) {
    const headers = { Authorization: window.amplify.store('token') };
    const institucionId = window.amplify.store('institution').id_institucion;
    const usuarioId = window.amplify.store('user_id');
    const url = `/api/v1/licenciaUsuarios?institucionId=${institucionId}&usuarioId=${usuarioId}`;
    httpAdmin.post(url, headers, body, callback);
  },
  desvincularUsuario(id, callback) {
    const headers = { Authorization: window.amplify.store('token') };
    const body = {};
    //quitar roles, licencias y agendas desde el backend
    http.delete(`/app/api/institucionesUsuarios/${id}`, headers, body, callback);
  },
  crearEditarUsuarioInstitucion(body, callback) {
    const headers = { Authorization: window.amplify.store('token') };
    //crearEditar roles, licencias y agendas desde el backend
    body.id_institucion = window.amplify.store('institution').id_institucion;
    body.agendas = (body.agendas || []).filter(v => v.sn_activo)
    body.roles = (body.roles || []).filter(v => v.sn_activo)
    http.post(`/app/api/institucionesUsuarios`, headers, body, callback);
  },
};

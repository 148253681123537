import React from 'react';
import { connect } from 'react-redux';
import ThinkForm from '../../../../material-ui/ThinkForm';
import { ThemeProvider, Paper, Box, FormControl, Typography, Button, Divider, CircularProgress } from '@material-ui/core';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { teal } from '@material-ui/core/colors';
import TextField from '../../../../material-ui/TextField';
import notifications from '../../../../utils/notifications';
import * as firebase from 'firebase/app';
import PasswordConfirm from '../../../../material-ui/PasswordConfirm';
import ConfirmAlert from '../../../../material-ui/ConfirmAlert';
const themeSelected = window.amplify.store('theme') || null;

let theme = createMuiTheme({
    overrides: {
        MuiCardHeader: {
            root: {
                position: 'relative',
            }
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: '4px',
                marginBottom: '8px'
            },
            root: {
                marginTop: '4px',
                marginBottom: '8px'
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px 14px'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 10px) scale(1)'
            }
        },
        MuiFormHelperText: {
            contained: {
                margin: '4px 0px 0'
            }
        },
        MuiDrawer: {
            paper: {
                // backgroundColor: grey
            }
        },
        MuiFormHelperText: {
            root: {
                position: 'absolute',
                bottom: '-4px',
                left: '-14px',
                color: '#f3564b',
                width: '100%'
            },
            contained: {
                margin: '0px 14px 0'
            }
        },
        MuiInputBase: {
            root: {
                marginBottom: '10px'
            }
        }
    },
    palette: {
		primary: themeSelected ? themeSelected.primary : teal,
        secondary: {
            main: '#fafafa'
        },
        type: themeSelected ? themeSelected.typeMain : 'dark'
    },
    MuiExpansionPanelSummary: {

        root: {
            "&$expanded": {
                boxShadow: "0 1px 12px 0 rgba(0,0,0,.11)"
            }
        }
    },
});

class RecuperacionUsuarioModal extends React.Component {
    constructor() {
        super();
        this.state = {
            vc_password: '',
            vc_passwordConfirm: ''
        };
    }
    handleChange(event) {
        console.log('hola', event.target.value)
        this.setState({ [event.target.id]: event.target.value })
    }

    sendRecovery() {
        const { vc_email, vc_token } = this.props
        const vc_password = document.getElementById('vc_passwordRecovery') ? document.getElementById('vc_passwordRecovery').value : '';
        const vc_passwordConfirm = document.getElementById('vc_passwordRecoveryConfirm') ? document.getElementById('vc_passwordRecoveryConfirm').value : '';

        const isValid = vc_password.length >= 6 && vc_password === vc_passwordConfirm;
        if (isValid) {
            const body = {
                vc_email,
                vc_password,
                vc_uuid: 'web',
                vc_token,
                vc_descripcion: 'mac'
            };
            this.setState({ loading: true });
            const headers = { Authorization: vc_token };
            window.$.ajax({
                url: `${window.backEndURL}/app/resetPassword`,
                data: body,
                headers,
                method: 'POST'
            }).then((data, status, xhr, error) => {
                if (data.message) {
                    this.setState({ result: null, optionsModal: false, loading: false, notification: { title: '¡Ups!', message: data.message || 'Revisa tu conexión a internet.', onAccept: this.props.backButton || null } });
                } else if (data.error) {
                    this.setState({ result: null, optionsModal: false, loading: false, notification: { title: '¡Ups!', message: data.error || 'Revisa tu conexión a internet.' } });
                }
            }).fail((data, status, xhr, error) => {
                this.setState({ result: null, optionsModal: false, loading: false, notification: { title: '¡Ups!', message: error || 'Revisa tu conexión a internet.' } });
            });
        }else{
            this.setState({ result: null, optionsModal: false, loading: false, notification: { title: '¡Ups!', message: 'Verifica los campos deben contener al menos 6 caracteres y deben coincidir' } });
        }
    }

    render() {
        const field = {
            id: 'vc_passwordRecovery',
            field: 'Nueva contraseña',
            vc_name: 'Nueva contraseña',
            key: 'vc_passwordRecovery',
            fieldid: 'vc_passwordRecovery',
            type: 'password'
        }
        const value = this.state.vc_password || ''
        return (
            <ThemeProvider theme={theme}>
                <Paper key='passwords' style={{ padding: 30, alignContent: 'center', borderRadius: 8 }}>
                    <Typography>
                        {'La contraseña de tu cuenta '} {this.props.vc_email || ''} {' será modificada'}
                    </Typography>
                    <Typography>
                        {''}
                    </Typography>
                    <FormControl fullWidth>
                        <PasswordConfirm
                            key={field.fieldid}
                            fieldid={field.fieldid}
                            required={!field.nullify}
                            field={field.key}
                            name={field.fieldid}
                            fullWidth
                            label={field.vc_name}
                            regExp={field.regExp}
                        />
                    </FormControl>
                    <Divider />
                    <Box display='flex' flexWrap='wrap' flexDirection='row-reverse' style={{ width: '100%', marginTop: 15 }}>
                        <Button
                            key={'cancelar'}
                            color='primary'
                            onClick={this.sendRecovery.bind(this)}
                            endIcon={this.state.loading && <CircularProgress size={15} color='secondary' />}
                        >
                            Enviar
                        </Button>
                        <Button
                            key={'enviar'}
                            onClick={this.props.backButton || (() => { })}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </Paper>
                {this.state.notification &&
                    <ConfirmAlert
                        title={this.state.notification.title || ''}
                        message={this.state.notification.message || ''}
                        onAccept={this.state.notification.onAccept || (() => {
                            this.setState({ notification: null })
                        })}
                    />
                }
            </ThemeProvider>
        );
    }

    componentDidMount() {
        this.setState({
            someKey: 'otherValue'
        });
    }
}

const mapStateToProps = (state) => {
    const { catalogos } = state.Think;
    return { catalogo: catalogos.find(e => e.vc_nameTable === 'usuariosDetalle') };
};
export default connect(mapStateToProps, [])(RecuperacionUsuarioModal);

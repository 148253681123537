import ReactApexChart from "react-apexcharts";
import React from 'react';
import repository from '../repository'
import Encabezado from "./Encabezado";
const tasaAtaque = 0.0044;
const tasaDuplicacion = 6.2;
// const tasaDuplicacion = 7.2;
const poblacion = 3100000;
// const poblacion = 5119504;
const locales = [{
    "name": "es",
    "options": {
        "months": [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
        "shortMonths": [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic"
        ],
        "days": [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
        ],
        "shortDays": [
            "Dom",
            "Lun",
            "Mar",
            "Mie",
            "Jue",
            "Vie",
            "Sab"
        ],
        "toolbar": {
            "exportToSVG": "Descargar SVG",
            "exportToPNG": "Descargar PNG",
            "exportToCSV": "Descargar CSV",
            "menu": "Menu",
            "selection": "Seleccionar",
            "selectionZoom": "Seleccionar Zoom",
            "zoomIn": "Aumentar",
            "zoomOut": "Disminuir",
            "pan": "Navegación",
            "reset": "Reiniciar Zoom"
        }
    }
}]
class Selector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            seriesFilter: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            series: [
                {
                    id: 1,
                    name: 'Casos COVID-19',
                    data: repository.getCasesData({ tasaAtaque, tasaDuplicacion, poblacion }),
                    type: 'area',
                    color: 'rgba(255, 217, 0, 0.4)',
                    fillOpacity: 0.3,
                    tooltip: {
                        valueDecimals: 0,
                    },
                },
                {
                    id: 2,
                    name: 'Prevalencia casos COVID-19',
                    data: repository.getCasesPrevalencyData({ tasaAtaque, tasaDuplicacion, poblacion }),
                    type: 'area',
                    color: 'rgba(255, 217, 0, 0.4)',
                    fillOpacity: 0.3,
                    tooltip: {
                        valueDecimals: 0,
                    },
                },
                {
                    id: 3,
                    name: 'Capacidad de hospitalizacion',
                    data: repository.getHospitalizedCapacityData({ tasaAtaque, tasaDuplicacion, poblacion }),
                    type: 'area',
                    fillOpacity: 0.3,
                    color: 'rgba(0, 110, 255, 0.5)',
                    tooltip: {
                        valueDecimals: 0,
                    },
                },
                {
                    id: 4,
                    name: 'Casos Hospitalizados',
                    data: repository.getCasesHospitalizedData({ tasaAtaque, tasaDuplicacion, poblacion }),
                    type: 'area',
                    color: 'rgba(252, 156, 0, 0.5)',
                    fillOpacity: 0.3,
                    tooltip: {
                        valueDecimals: 0,
                    },
                },
                {
                    id: 5,
                    name: 'Prevalencia casos Hospitalizados',
                    data: repository.getCasesHospitalizedPrevalencyData({ tasaAtaque, tasaDuplicacion, poblacion }),
                    type: 'area',
                    color: 'rgba(252, 156, 0, 0.5)',
                    fillOpacity: 0.3,
                    tooltip: {
                        valueDecimals: 0,
                    },
                },
                {
                    id: 6,
                    name: 'Capacidad UCI',
                    data: repository.getTerapyCapacityData({ tasaAtaque, tasaDuplicacion, poblacion }),
                    type: 'area',
                    color: 'rgba(0, 110, 255, 0.5)',
                    fillOpacity: 0.3,
                    tooltip: {
                        valueDecimals: 0,
                    },
                },
                {
                    id: 7,
                    name: 'Casos graves',
                    data: repository.getCasesSeriusData({ tasaAtaque, tasaDuplicacion, poblacion }),
                    type: 'area',
                    color: 'rgba(176, 4, 4, 0.5)',
                    fillOpacity: 0.3,
                    tooltip: {
                        valueDecimals: 0,
                    },
                },
                {
                    id: 8,
                    name: 'Prevalencia casos graves',
                    data: repository.getCasesSeriusPrevalencyData({ tasaAtaque, tasaDuplicacion, poblacion }),
                    type: 'area',
                    color: 'rgba(176, 4, 4, 0.5)',
                    fillOpacity: 0.3,
                    tooltip: {
                        valueDecimals: 0,
                    },
                },
                {
                    id: 9,
                    name: 'Muertes esperadas',
                    data: repository.getCasesDeadData({ tasaAtaque, tasaDuplicacion, poblacion }),
                    type: 'area',
                    fillOpacity: 1,
                    color: 'rgba(0,0,0, 0.8)',
                    tooltip: {
                        valueDecimals: 0,
                    },
                }
            ],
            options: {
                chart: {
                    id: 'chartyear',
                    type: 'area',
                    height: 160,
                    toolbar: {
                        show: false,
                        autoSelected: 'pan'
                    },
                    locales,
                    defaultLocale: "es",

                },
                selection: {
                    enabled: true,
                    xaxis: {
                        min: new Date('5 Jan 2020').getTime(),
                        max: new Date('5 Dic 2020').getTime()
                    }
                },
                stroke: {
                    width: 2,
                    curve: 'straight',
                    lineCap: 'round'
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'solid',
                    opacity: 0.5
                },
                yaxis: {
                    show: true,
                    tickAmount: 3,
                    forceNiceScale: true,
                    decimalsInFloat: 2
                },
                xaxis: {
                    type: 'datetime',
                },
                legend: {
                    position: window.screen.width < 600 ? 'left' : 'top',
                    horizontalAlign: 'center',
                    itemMargin: {
                        horizontal: 16,
                        vertical: 16
                    },
                },
            },

            seriesYears: [
                {
                    id: 1,
                    name: 'Casos COVID-19',
                    data: repository.getCasesData({ tasaAtaque, tasaDuplicacion, poblacion }),
                    type: 'area',
                    color: 'rgba(255, 217, 0, 0.4)',
                    fillOpacity: 0.3,
                    tooltip: {
                        valueDecimals: 0,
                    },
                }
            ],
            optionsYears: {
                chart: {
                    id: 'chart1',
                    height: 130,
                    type: 'area',
                    brush: {
                        target: 'chartyear',
                        enabled: true
                    },
                    selection: {
                        enabled: true,
                        xaxis: {
                            min: new Date('1 Jan 2020').getTime(),
                            max: new Date('14 Jun 2020').getTime()
                        }
                    },
                },
                // chart: {
                //     height: 100,
                //     type: 'line',
                //     toolbar: {
                //         autoSelected: 'selection',
                //     },
                //     brush: {
                //         enabled: true,
                //         target: 'chartyear'
                //     },
                //     selection: {
                //         enabled: true,
                //         xaxis: {
                //             min: new Date('5 Jan 2020').getTime(),
                //             max: new Date('5 Dic 2020').getTime()
                //         }
                //     },
                //     locales,
                //     defaultLocale: "es"
                // },
                colors: ['rgba(0,0,0,0)'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 4,
                    curve: 'smooth',
                    lineCap: 'round'
                },
                fill: {
                    type: 'solid',
                    opacity: 0.9,
                    colors: ['rgba(0,0,0,0.1)'],
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                yaxis: {
                    show: false,
                    tickAmount: 3,
                    forceNiceScale: true,
                    decimalsInFloat: 0
                },
                xaxis: {
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    }
                },
            },


        };
    }

    filterSeries() {
        const seriesFiltered = []
        this.state.seriesFilter.forEach(id => {
            const serie = this.state.series.find(e => e.id === id);
            if (serie) {
                seriesFiltered.push(serie)
            }
        })
        return seriesFiltered;
    }

    render() {

        const options = this.state.options;
        const series = this.state.series;
        options.colors = series.map((serie, index) => {
            return serie.color;
        })
        return (
            <div style={{ width: '100%', padding: 30, overflowX: 'scroll' }}>
                <Encabezado
                    title={'Proyecciones COVID-19 para gobiernos'}
                    message={`Calculado con una tasa de ataque de ${(tasaAtaque * 100).toFixed(2)}% aplicada a una poblacion de ${poblacion} habitantes.`}
                />
                <div style={{position:'relative', padding: 0, boxShadow: '0px 0px 5px rgba(5,5,5,0.5)', backgroundColor: 'white', borderRadius: 10, overflow: 'hidden', width: 'calc(100% -60px)' }}>
                    <div>
                        <ReactApexChart id="chart-months" options={options || null} series={series || null} height={500} />
                    </div>
                    <div style={{ borderTop: '1px solid gray' }}>
                        <ReactApexChart options={this.state.optionsYears} series={this.state.seriesYears} height={100} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', padding: 10, overflow: 'hidden', textAlign: 'center' }}>
                            {'Thin-k Soluciones Médicas S.A. de C.V.    -'} {'    Contacto: admin@thin-k.mx - WhatsApp: 6672003438'}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default (Selector);

import React from "react";
import PropTypes, { func } from 'prop-types';
import { connect } from 'react-redux';
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowUp from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { grey, teal } from "@material-ui/core/colors";
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { SwipeableDrawer, Paper, Drawer, Box } from "@material-ui/core";
import { setDisplaySidebar, setDisplayMenu, setPacienteActual } from '../../../actions';
import Paused from '../Paused';
import Actives from '../Actives';
const themeSelected = window.amplify.store('theme') || null;
const menuWidth = window.menuWidth;
const sidebarWidth = window.sidebarWidth;
const isMobile = window.isMobile;

let menuTheme = createMuiTheme({
    overrides: {
        MuiDrawer: {
            paper: {
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px 14px'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 10px) scale(1)'
            }
        },
        MuiFormHelperText: {
            contained: {
                margin: '4px 0px 0'
            }
        },
    },
    palette: {
        primary: themeSelected ? themeSelected.primary : teal,
        secondary: grey,
        type: themeSelected ? themeSelected.typeMenu : 'dark',
    }
});
let darkTheme = createMuiTheme({
    overrides: {
        MuiDrawer: {
            paper: {
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px 14px'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 10px) scale(1)'
            }
        },
        MuiFormHelperText: {
            contained: {
                margin: '4px 0px 0'
            }
        },
    },
    palette: {
        primary: themeSelected ? themeSelected.primary : teal,
        secondary: grey,
        type: themeSelected ? themeSelected.typeSidebar : 'dark',
    }
});
let darkThemeTransparent = createMuiTheme({
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: 'transparent'
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px 14px'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 10px) scale(1)'
            }
        },
        MuiFormHelperText: {
            contained: {
                margin: '4px 0px 0'
            }
        },
    },
    palette: {
        primary: themeSelected ? themeSelected.primary : teal,
        secondary: grey,
        type: themeSelected ? themeSelected.typeSidebar : 'dark',
    }
});
var userAgent = navigator.userAgent.toLowerCase();
const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
const minWidthSideBar = window.isMobile ? 0 : 50;

function DrawerLayout(props) {
    const useStyles = makeStyles(theme => ({
        root: {
            display: "flex",
            backgroundImage: 'linear-gradient(rgba(75,75,75, 0.95), rgba(100,100,100,0.9), rgba(75,75,75, 0.95))',
            height: '100%'
        },
        appBar: {
            height: 0,
            overflow: 'hidden',
            width: '100%',
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            })
        },
        appBarShift2: {
            marginLeft: menuWidth,
            width: `calc(100% - ${menuWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        appBarShift3: {
            marginRight: sidebarWidth,
            width: `calc(100% - ${menuWidth + sidebarWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        appBarShift4: {
            marginRight: sidebarWidth,
            width: `calc(100% - ${sidebarWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        menuButton: {
            marginRight: 45
        },
        hide: {
            display: "none"
        },
        sidebar: {
            height: props.showVideoCall ? 'calc(100% - 300px)' : '100%',
            width: menuWidth,
            flexShrink: 0,
            whiteSpace: "nowrap"
        },
        sidebarOpen: {
            height: props.showVideoCall ? 'calc(100% - 300px)' : '100%',
            width: menuWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        sidebarClose: {
            height: props.showVideoCall ? 'calc(100% - 300px)' : '100%',
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            overflowX: "hidden",
            width: minWidthSideBar,
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(6) + 1
            }
        },
        barButton: {
            position: 'absolute',
            overflow: "hidden",
            maxWidth: 10,
            width: 10,
            height: '100%',
            float: 'left',
            backgroundImage: 'linear-gradient(rgba(0,0,0, 0.95), rgb(0,0,0), rgba(0,0,0,, 0.95))'
        },
        barButtonOpen: {
            position: 'absolute',
            width: 0,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            }),
            backgroundImage: 'linear-gradient(rgba(0,0,0, 0.95), rgb(0,0,0), rgba(0,0,0,, 0.95))'
        },
        barButtonClose: {
            position: 'absolute',
            width: 10,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            }),
            backgroundImage: 'linear-gradient(rgba(10,10,10,0.8), rgb(10,10,10), rgba(10,10,10,0.8))'
        },
        drawer: {
            height: props.showVideoCall ? 'calc(100% - 300px)' : '100%',
            right: 0,
            position: window.screen.availWidth < 1000 ? 'fixed' : 'relative',
            width: window.isMobile ? null : sidebarWidth,
            flexShrink: 0,
            padding: 0,
            overflow: 'hidden',
        },
        drawerOpen: {
            height: props.showVideoCall ? 'calc(100% - 300px)' : '100%',
            overflow: 'hidden',
            borderRadius: window.isMobile ? '10px 0px 0px 10px' : 0,
            zIndex: theme.zIndex.drawer + 1,
            width: window.isMobile ? null : sidebarWidth,
            transition: theme.transitions.create(["width", "padding"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            }),
        },
        drawerClose: {
            height: props.showVideoCall ? 'calc(100% - 300px)' : '100%',
            transition: theme.transitions.create(["width", "padding"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            overflowX: "hidden",
            width: false ? sidebarWidth : 0,
            [theme.breakpoints.up("sm")]: {
                width: window.isMobile ? 0 : 0
            },
        },

        toolbar: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0 8px",
            ...theme.mixins.toolbar
        },
        content: {
            height: '100%',
            padding: 0,
            position: 'relative',
            width: `calc(100% - ${sidebarWidth}px)`,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            flex: 1,
        },
        contentShift2: {
            height: '100%',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        contentShift3: {
            height: '100%',
            width: `calc(100% - ${menuWidth + sidebarWidth}px)`,
            transition: theme.transitions.create(["width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        contentShift4: {
            height: '100%',
            width: `calc(100% - ${sidebarWidth + minWidthSideBar}px)`,
            transition: theme.transitions.create(["width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        }
    }));

    const classes = useStyles();
    const theme = useTheme();
    const { setDisplayMenu, drawersLayout, setDisplaySidebar } = props;

    const [value, setValue] = React.useState(1);
    function stateDrawersLayout() {
        switch (true) {
            case (!props.drawersLayout.menu && !props.drawersLayout.sidebar): {
                return 1;
            }
            case (props.drawersLayout.menu && !props.drawersLayout.sidebar): {
                return 2;
            }
            case (props.drawersLayout.menu && props.drawersLayout.sidebar): {
                return 3;
            }
            case (!props.drawersLayout.menu && props.drawersLayout.sidebar): {
                return 4;
            }
            default: {
                return 4;
            }
        }
    }
    function handleSidebarClose() {
        setDisplaySidebar(false)
    }
    function handleSidebarOpen() {
        setDisplaySidebar(true)
    }
    function handleMenuOpen() {
        setDisplayMenu(true)
    }
    function handleMenuClose() {
        setDisplayMenu(false)
    }
    // React.useEffect(() => {
    //     if (!props {
    //         setDisplayMenu
    //     }
    //     if (!prop) {
    //         setDisplaySideba)
    //     }
    //     if (prop && props {
    //         return function cleanUp() {
    //             setDisplayMenu(null);
    //             setDisplaySidebar(null);
    //         }
    //     }

    // })
    return (
        <div className={classes.root} style={{ backgroundColor: 'rgba(200,200,200,0.2)' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift2]: (stateDrawersLayout() === 2),
                    [classes.appBarShift3]: (stateDrawersLayout() === 3),
                    [classes.appBarShift4]: (stateDrawersLayout() === 4)

                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={setDisplayMenu}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: (stateDrawersLayout() === 2 || stateDrawersLayout() === 3)
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Mini variant drawer
                    </Typography>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={setDisplaySidebar}
                        className={clsx((stateDrawersLayout() > 2) && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <ThemeProvider theme={menuTheme}>
                <SwipeableDrawer
                    onClose={handleMenuClose}
                    onOpen={handleMenuOpen}
                    variant={window.isMobile ? 'temporary' : 'permanent'}
                    className={clsx(classes.sidebar, {
                        [classes.sidebarOpen]: drawersLayout.menu,
                        [classes.sidebarClose]: !drawersLayout.menu
                    })}
                    classes={{
                        paper: clsx({
                            [classes.sidebarOpen]: drawersLayout.menu,
                            [classes.sidebarClose]: !drawersLayout.menu
                        })
                    }}
                    open={drawersLayout.menu}
                    theme={menuTheme}
                >
                    {/* <div style={{backgroundImage: 'linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,1), rgba(0,0,0,0.9))', width: '100%', height: '100%', position: 'absolute'}}/> */}
                    <div style={{ height: 47, overflow: 'hidden' }}>
                        <IconButton onClick={setDisplayMenu} style={{ margin: 1 }}>
                            {props.drawersLayout.menu ? (
                                <ChevronLeftIcon />
                            ) : (
                                    <ChevronRightIcon />
                                )}
                        </IconButton>
                    </div>
                    {props.sidebar}
                    <Divider />
                </SwipeableDrawer>

            </ThemeProvider>
            <Paper
                className={clsx(classes.content, {
                    [classes.content]: isMobile,
                    [classes.contentShift2]: (stateDrawersLayout() === 2),
                    [classes.contentShift3]: (stateDrawersLayout() === 3),
                    [classes.contentShift4]: (stateDrawersLayout() === 4)
                })}
            >
                {/* <div className={classes.toolbar} /> */}
                {props.main}
                <Paused
                    setPacienteActual={props.setPacienteActual}
                />
                
            </Paper>
            <ThemeProvider theme={window.isMobile ? darkThemeTransparent : darkTheme}>
                <SwipeableDrawer
                    onOpen={() => { }}
                    onClose={handleSidebarClose}
                    variant={window.isMobile ? 'temporary' : 'permanent'}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: drawersLayout.sidebar,
                        [classes.drawerClose]: !drawersLayout.sidebar
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawer]: isMobile,
                            [classes.drawerOpen]: drawersLayout.sidebar,
                            [classes.drawerClose]: !drawersLayout.sidebar
                        })
                    }}
                    anchor={false ? 'bottom' : 'right'}
                    open={drawersLayout.sidebar}
                >
                    <Paper id={'drawerHelpBar'} style={{height: '100%', ... window.isMobile ? {width: sidebarWidth} : {}}}>
                        {props.helpBar}
                    </Paper>
                    <div style={{ position: "absolute", top: 1, right: 1, zIndex: 1000 }}>
                        <IconButton onClick={setDisplaySidebar}>
                            {theme.direction === "rtl" ? (
                                window.isMobile ? <ExpandMore /> : <ChevronRightIcon />
                            ) : (
                                    window.isMobile ? <ExpandMore /> : <ChevronRightIcon />
                                )}
                        </IconButton>
                    </div>
                    <div className={clsx(classes.barButton, {
                        [classes.barButtonOpen]: drawersLayout.sidebar,
                        [classes.barButtonClose]: !drawersLayout.sidebar
                    })}>
                    </div>
                </SwipeableDrawer>
            </ThemeProvider>
            <div style={{ position: "absolute", top: 1, right: 1 }}>
                <IconButton onClick={setDisplaySidebar}>
                    {theme.direction === "rtl" ? (
                        false ? <ArrowUp style={{ opacity: drawersLayout.sidebar ? 0 : 1 }} /> : <ChevronLeftIcon />
                    ) : (
                            false ? <ArrowUp style={{ opacity: drawersLayout.sidebar ? 0 : 1 }} /> : <ChevronLeftIcon />
                        )}
                </IconButton>
            </div>
            {/* <div onMouseEnter={stateDrawersLayout() < 3 ? setDisplaySidebar : null} style={{ position: "absolute", top: 60, right: 0, width: 8, height: 'calc(100% - 60px)' }} /> */}
        </div>
    );
}

DrawerLayout.propTypes = {

};

const mapStateToProps = (state) => {
    const { drawersLayout } = state.Think;
    return { drawersLayout };
};

const actions = {
    setDisplaySidebar, setDisplayMenu, setPacienteActual
};


export default connect(mapStateToProps, actions)(DrawerLayout);

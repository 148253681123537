import React from "react";

import _ from "lodash";

import { Popover, Button, Box, Divider } from "@material-ui/core";
import DateRangePicker from "./DateRangePicker";
import moment from "moment";

export default (props) => {
  const {
    onChange = () => {},
    value = [],
    closeOnChange = false,
    showAcceptButton = false,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [range, setRange] = React.useState([null, null]);
  const [disabled, setDisabled] = React.useState(false);

  const inicio = value[0] ? moment(value[0]).format("ll") : "";
  const fin = value[1] ? moment(value[1]).format("ll") : "";

  React.useEffect(() => {
    setDisabled(range.includes(null));

    if (!range.includes(null)) {
      if (!showAcceptButton && closeOnChange) {
        onChange(range);
        setAnchorEl(null);
      }
      if (!showAcceptButton && !closeOnChange) {
        onChange(range);
      }
    }
  }, [range]);

  return (
    <div>
      <Button
        onClick={(e) => {
          setRange([null, null]);
          setAnchorEl(e.target);
        }}
        color="inherit"
      >
        {inicio && fin ? `${inicio} - ${fin}` : "Seleccionar rango"}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <DateRangePicker
          value={value}
          onChange={(res) => {
            setRange(res);
          }}
        />
        {showAcceptButton && [
          <Divider />,
          <Box display="flex" justifyContent="flex-end" p={1}>
            <Button
              disabled={disabled}
              color="primary"
              variant="contained"
              onClick={() => {
                setAnchorEl(null);
                onChange(range);
              }}
            >
              Aceptar
            </Button>
          </Box>,
        ]}
      </Popover>
    </div>
  );
};

import React from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Input from "app/components/Form/Input";
import { omit } from "lodash";
import SpeechButton from "app/material-ui/SpeechButton";

const search = (texto) => {
  return new Promise((resolve, reject) => {
    if (texto.split(" ").length < 2) return resolve({ brand: "", options: [] });

    window.$.ajax({
      url: `https://maps.googleapis.com/maps/api/geocode/json?address=${texto}&key=AIzaSyALFV0i5CA9nuaUYvabwL09uCsETeGWTQM`,
      method: "GET",
    })
      .then((data, status, xhr, error) => {
        const { results: options = [] } = data || {};
        const brand =
          texto.split(",").length > 1
            ? texto.split(",")[0].toLocaleLowerCase() + " "
            : "";

        resolve({ brand, options });
      })
      .fail((data, status, xhr, error) => {
        reject("Error al obtener las localizaciones");
      });
  });
};
function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export default ({
  onChange = () => {},
  onChangeInput = () => {},
  addPatient = true,
  ...props
}) => {
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const prevValue = usePrevious(props.value);
  const [loading, setLoading] = React.useState(false);
  const fetchData = async () => {
    const res = await search(inputValue);
    setLoading(false);

    setData(res.options);
  };

  React.useEffect(() => {
    if (props.value !== prevValue) setInputValue(props.value || "");
  }, [props.value]);

  React.useEffect(() => {
    onChangeInput(inputValue);
    setLoading(!!inputValue);
    const timeout = setTimeout(async () => {
      if (inputValue) {
        await fetchData();
      } else {
        setData([]);
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  React.useEffect(() => {
    if (value) onChange(value);
  }, [value]);

  return [
    <Autocomplete
      filterOptions={(x) => x}
      options={inputValue && !data.length ? [{ loading: true }] : data}
      autoComplete
      fullWidth
      filterSelectedOptions
      openOnFocus={true}
      freeSolo
      open={undefined}
      value={value}
      inputValue={inputValue}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : option.formatted_address || "No Aplica"
      }
      onChange={(event, newValue) => {
        if (typeof newValue === "string") return;
        if (newValue && "loading" in newValue) return;

        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      style={{ margin: 0 }}
      renderInput={(params) => (
        <Input
          inputRef={params.InputProps.ref}
          inputProps={omit(params.inputProps, ["className"])}
          getEvent
          placeholder={props.placeholder || "Buscar Localización"}
          endAdornment={
            <SpeechButton
              callbackPreview={(speech) => {
                setInputValue(`${inputValue} ${speech}`);
              }}
              callbackFinal={(speech) => {}}
            />
          }
        />
      )}
    />,
  ];
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import moment from "moment";
import DiagnosticosItem from "../diagnosticosItem";
import ProcedimientosItem from "../procedimientosItem";
import TratamientosItem from "../tratamientosItem";
import PrintForm from "app/components/PrintForm";
import HeaderPrint from "app/material-ui/Print/HeaderPrint";
import FooterPrint from "app/material-ui/Print/FooterPrint";

import { withStyles, Typography, Box } from "@material-ui/core";

const useStyles = (theme) => ({
  firmas: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "black",
    "& .line": {
      flex: "1 1 1px",
      width: "50%",
      backgroundColor: "black",
      marginBottom: theme.spacing(1),
    },
  },
});

const amplify = window.amplify;

class NotasPrint extends Component {
  render() {
    const classes = this.props.classes;
    const firma = [...(this.props.firmas || [])].pop() || {};
    return (
      <PrintForm
        viewMode={this.props.viewMode}
        header={[
          this.props.actions ? this.props.actions : null,
          <HeaderPrint
            key="header"
            module="Notas"
            paciente={this.props.paciente}
            date={this.props.date}
            printerMode={this.props.printerMode}
            data={firma}
          />,
        ]}
        rol={firma}
        content={
          <div style={{ maxWidth: "100%" }}>
            {this.renderPreguntas(this.props.preguntas)}

            {!this.props.recetaMode && (
              <h3 key={"diagnosticos"} style={{ fontSize: 15, color: "black" }}>
                Diagnósticos:
              </h3>
            )}
            {!this.props.recetaMode && this.renderDiagnosticos()}

            {!this.props.recetaMode && (
              <h3
                key={"procedimientos"}
                style={{ fontSize: 15, color: "black" }}
              >
                Procedimientos:
              </h3>
            )}
            {!this.props.recetaMode && this.renderProcedimientos()}

            <h3 key={"medicamentos"} style={{ fontSize: 15, color: "black" }}>
              Medicamentos:
            </h3>
            {this.renderMedicamentos()}

            {this.props.tratamiento && this.props.tratamiento.vc_observaciones && (
              <div>
                <h3
                  key={"diagnosticos"}
                  style={{ fontSize: 15, color: "black" }}
                >
                  Indicaciones
                </h3>
                <div
                  style={{
                    width: "100%",
                    marginTop: 10,
                    fontSize: 13,
                    color: "black",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {this.props.tratamiento.vc_observaciones}
                </div>
              </div>
            )}
          </div>
        }
        footer={<FooterPrint key="footer" firma={firma} />}
        marginFooter={20}
      />
    );
  }

  nivelesPreguntas(preguntas) {
    const nivelesPreguntas = [];
    preguntas.map((pregunta) => {
      if (!nivelesPreguntas.includes(pregunta.id_nivel) && pregunta.respuesta) {
        nivelesPreguntas.push(pregunta.id_nivel);
      }
      return null;
    });
    return nivelesPreguntas;
  }
  titleNumericas(vc_pregunta) {
    let textEdit = "";
    const text = vc_pregunta.split("(")[0];
    if (text.split(" ").length > 1 && text.split(" ")[1] !== "") {
      text.split(" ").map((word) => {
        if (textEdit === "") {
          textEdit = word.substr(0, 1) + ". ";
        } else {
          textEdit =
            textEdit +
            word.substr(0, 1).toUpperCase() +
            word
              .substr(1, word.length <= 4 ? word.length - 1 : 3)
              .toLowerCase() +
            ".";
        }
      });
      return textEdit;
    } else if (text.length > 4) {
      textEdit =
        text.substr(0, 1).toUpperCase() + text.substr(1, 3).toLowerCase() + ".";
      return textEdit;
    }
    return (
      text.substr(0, 1).toUpperCase() +
      text.substr(1, text.length - 1).toLowerCase()
    );
  }
  respuestasPreguntas(pregunta) {
    const { unidadesMedida } = this.props;
    switch (pregunta.id_tipoPregunta) {
      case 3:
        const um = unidadesMedida.filter(
          (e) => e.id === pregunta.id_um_numerico
        )[0];
        if (um && pregunta.respuesta) {
          return `${pregunta.respuesta.nu_respuesta} ${um.vc_abreviatura}`;
        }
        break;
      case 4:
        if (pregunta.respuesta) {
          return `${pregunta.respuesta.vc_respuesta}`;
        }
        break;
      default:
        return "";
    }
  }
  isValidSex(id_sexoPaciente, id_sexoPregunta) {
    if (id_sexoPaciente == id_sexoPregunta || id_sexoPregunta == 3) {
      return true;
    } else {
      return false;
    }
  }
  isValidAge(nuAgeMinutesPaciente, item) {
    const { unidadesMedida } = this.props;
    let limiteInferiorAge = null;
    let limiteSuperiorAge = null;
    if (item.nu_minEdad > 0 && item.id_um_minEdad != null) {
      const nuUmBase = this.finder(unidadesMedida, "id", item.id_um_minEdad)
        .nu_ratio;
      limiteInferiorAge = nuUmBase * item.nu_minEdad;
    }
    if (item.nu_maxEdad > 0 && item.id_um_maxEdad != null) {
      const nuUmBase = this.finder(unidadesMedida, "id", item.id_um_maxEdad)
        .nu_ratio;
      limiteSuperiorAge = nuUmBase * item.nu_maxEdad;
    }
    if (limiteInferiorAge != null && nuAgeMinutesPaciente < limiteInferiorAge) {
      return false;
    }
    if (limiteSuperiorAge != null && nuAgeMinutesPaciente > limiteSuperiorAge) {
      return false;
    }
    return true;
  }
  finder(array, key, value) {
    const object = array.find((e) => {
      return e[`${key}`] === value;
    });
    if (object) {
      return object;
    }
    return null;
  }
  preguntaVersion(preguntaActual) {
    let pacienteDetalle = null;
    const pacienteObj = this.props.pacienteActual || {};
    if (pacienteObj !== {} && pacienteObj.detalle) {
      pacienteDetalle = pacienteObj.detalle;
    }
    const idSexo = pacienteDetalle.id_sexo;
    const nuEdadMinutos =
      (new Date() - new Date(pacienteDetalle.dt_nacimiento)) / 60000;
    let version = null;
    preguntaActual.versiones.map((preguntaVersion) => {
      if (
        this.isValidAge(nuEdadMinutos, preguntaVersion) &&
        this.isValidSex(idSexo, preguntaVersion.id_sexo)
      ) {
        version = preguntaVersion;
      }
      return null;
    });
    return version;
  }
  renderPreguntas(preguntas) {
    const { niveles } = this.props;
    return this.nivelesPreguntas(preguntas).map((nivel, index) => {
      const niv = niveles.filter((e) => e.id === nivel)[0];
      const nombreNivel = niv.vc_nombre;
      return (
        <div
          key={index}
          style={{
            float: "left",
            textAlign: "center",
            fontSize: 16,
            width: "100%",
          }}
        >
          <h3
            key="somatometria"
            style={{
              float: "left",
              textAlign: "left",
              fontSize: 16,
              width: "100%",
              color: "black",
              margin: 0,
            }}
          >
            {nombreNivel.toLowerCase() === "somatometria" ||
            nombreNivel.toLowerCase() === "pruebas rapidas" ||
            nombreNivel.toLowerCase() === "signos vitales"
              ? nombreNivel
              : ""}
          </h3>
          {preguntas
            .filter((e) => e.id_nivel === nivel && e.respuesta)
            .map((pregunta, indexPregunta) => {
              const version = this.preguntaVersion(pregunta);
              if (pregunta.id_tipoPregunta === 3) {
                return (
                  <div
                    key={indexPregunta}
                    style={{
                      margin: 5,
                      marginLeft: 0,
                      float: "left",
                      textAlign: "center",
                      fontSize: 12,
                      opacity: 0.7,
                      backgroundColor: "black",
                      borderRadius: "50px",
                      padding: 3,
                      paddingLeft: 8,
                      paddingRight: 8,
                      color: "white",
                    }}
                  >
                    {this.titleNumericas(version.vc_pregunta)}:{" "}
                    {this.respuestasPreguntas(pregunta)}
                  </div>
                );
              }
              if (this.props.recetaMode) {
                return <div key={indexPregunta} />;
              }
              return (
                <div
                  key={indexPregunta}
                  style={{ float: "left", textAlign: "left", width: "100%" }}
                >
                  {version != null ? (
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: 16,
                        width: "100%",
                        color: "black",
                      }}
                    >
                      {version.vc_pregunta}:
                    </div>
                  ) : (
                    ""
                  )}
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: 14,
                      width: "100%",
                      marginBottom: 8,
                      color: window.colors.textPrint,
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {this.respuestasPreguntas(pregunta)}
                  </p>
                </div>
              );
            })}
        </div>
      );
    });
  }
  isActive(dt_vigencia) {
    if (new Date(dt_vigencia) < new Date()) {
      return false;
    }
    return true;
  }
  renderDiagnosticos() {
    return this.props.diagnosticosPaciente.map((item, index) => (
      <DiagnosticosItem
        item={item}
        index={index}
        key={index}
        recetaMode={this.props.recetaMode}
        sn_printVersion={true}
      />
    ));
  }
  renderProcedimientos() {
    return this.props.procedimientosPaciente.map((item, index) => (
      <ProcedimientosItem
        item={item}
        index={index}
        key={index}
        sn_printVersion={true}
      />
    ));
  }
  renderMedicamentos() {
    return this.props.medicamentosNotas.map((item, index) => (
      <TratamientosItem
        item={item}
        sn_printVersion={true}
        index={index}
        key={index}
        reload={(e) => {
          this.forceUpdate();
        }}
        historyMode
      />
    ));
  }
  cedulaTitulo(rol) {
    if (rol) {
      const especialidad = this.props.especialidades.find(
        (e) => e.id === rol.id_especialidad
      );
      return {
        vc_especialidad: especialidad ? especialidad.vc_alias : "",
        vc_cedulaProfesional: rol.vc_cedulaProfesional || "",
        vc_registroSSA: rol.vc_registroSSA || "",
      };
    }
    return {
      vc_especialidad: "",
      vc_cedulaProfesional: "",
      vc_registroSSA: "",
    };
  }
}

const stylesReceta = {
  container: {
    padding: 2,
    float: "left",
  },
  layerBackgroundTx: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    opacity: 0.1,
    backgroundImage: "url(assets/images/iconThin-k.png)",
    backgroundPosition: "center",
    backgroundSize: "50%",
    backgroundRepeat: "no-repeat",
  },
  title: {
    backgroundColor: "white",
    color: window.colors.black,
    padding: 5,
    borderRadius: 3,
  },
};

const mapStateToProps = (state) => {
  const {
    diagnosticos,
    pacienteActual,
    niveles,
    unidadesMedida,
    especialidades,
  } = state.Think;
  return {
    diagnosticos,
    pacienteActual,
    niveles,
    unidadesMedida,
    especialidades,
  };
};

export default connect(mapStateToProps)(withStyles(useStyles)(NotasPrint));

import React from "react";
import { Grid, Card } from "semantic-ui-react";
import {
  AppBar,
  Paper,
  Toolbar,
  Typography,
  Divider,
  Box,
  Button,
} from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";
import PrintTicket from "../CashRegister/PrintTicketCorte";

const currencyFormat = (num) =>
  `$ ${num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

const Row = (props) => {
  const { cantidad, descripcion } = props;
  return (
    <div>
      <GridUI container spacing={0} style={{ padding: 0 }} {...props}>
        <GridUI
          item
          xs={9}
          style={{
            padding: "8px",
            textAlign: "left",
            overflow: "hidden",
            minWidth: 0,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {descripcion}
        </GridUI>
        <GridUI item xs={3} style={{ padding: "8px", textAlign: "right" }}>
          {currencyFormat(cantidad)}
        </GridUI>
      </GridUI>
      <Divider />
    </div>
  );
};

export default (props) => {
  const { data = {} } = props;
  const { close: { pays = [] } = {} } = data;
  const ticketsRef = React.useRef(null);

  console.log("DATASIDEBAR", data);
  const suma = pays.reduce((sum, v) => sum + v.nu_quantity, 0);
  const total = suma + data.nu_initReceived;
  const cobros = [
    {
      title: "Efectivo",
      total: pays
        .filter((v) => v.id_method == "efectivo")
        .reduce((sum, v) => sum + v.nu_quantity, 0),
    },
    {
      title: "Cheque",
      total: pays
        .filter((v) => v.id_method == "cheque")
        .reduce((sum, v) => sum + v.nu_quantity, 0),
    },
    {
      title: "Tarjeta",
      total: pays
        .filter((v) => v.id_method == "tarjeta")
        .reduce((sum, v) => sum + v.nu_quantity, 0),
    },
    {
      title: "Transferencia",
      total: pays
        .filter((v) => v.id_method == "transferencia")
        .reduce((sum, v) => sum + v.nu_quantity, 0),
    },
    {
      title: "Vales de Descuento",
      total: pays
        .filter((v) => v.id_method == "vale")
        .reduce((sum, v) => sum + v.nu_quantity, 0),
    },
  ];

  return (
    <Paper
      elevation={0}
      style={{ padding: 0, border: 0, borderRadius: 0, height: "100%" }}
    >
      <AppBar
        position="relative"
        style={{
          height: 95,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Typography style={{ textAlign: "center", width: "100%" }}>
            Corte
          </Typography>
        </Toolbar>
      </AppBar>
      <Box height="calc(100% - 95px)">
        <Box my={2} textAlign="center">
          <Card.Content>
            <Card.Header>
              <Typography
                style={{
                  margin: "5px 0px",
                  fontSize: "15px",
                  fontWeight: 500,
                  textTransform: "capitalize",
                }}
              >
                {data.caja}
              </Typography>
            </Card.Header>
            <Card.Description>Inicio: {data.inicio}</Card.Description>
            <Card.Description>Fin: {data.fin}</Card.Description>
          </Card.Content>
        </Box>
        <Box mb={2}>
          <Divider />
          <Row descripcion={"Monto Inicial"} cantidad={data.nu_initReceived} />
          {cobros.map((v, i) => (
            <Row key={i} descripcion={v.title} cantidad={v.total} />
          ))}
          {data.close && [
            <Row
              descripcion={"Monto Retirado"}
              cantidad={data.close.nu_quantity}
            />,
            <Row
              descripcion={"Saldo de Caja"}
              cantidad={data.close.nu_balance}
            />,
          ]}
        </Box>
        <Box px={1}>
          {data.fin && (
            <PrintTicket
              print={{
                cashRegister: {
                  cut: {
                    nu_initReceived: data.nu_initReceived,
                  },
                },
                pays,
                monto: data.close.nu_quantity,
                saldo: data.close.nu_balance,
                vc_nameUser: data.close.vc_nameUser,
                dt_end: data.dt_end || null,
              }}
              ref={ticketsRef}
            >
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  ticketsRef.current.print();
                }}
              >
                Imprimir
              </Button>
            </PrintTicket>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid, Image, Card, Input } from 'semantic-ui-react';
import internalAPI from '../../../../../utils/internalAPI';
import HelpBarThink from '../../../../../material-ui/HelpBarThink';

const useStyles = makeStyles(theme => ({}));

const styles = {
    titleHelp: {
        width: '100%',
        padding: 3,
        height: 37,
        color: 'white',
    },
    item: {
        padding: 0,
        paddingBottom: 3,
        margin: 0,
        width: '100%'
    }
};

export default props => {
    const classes = useStyles();
    const { typeSidebar = 'agenda', selectedRow } = props;
    console.log(selectedRow)
    switch (typeSidebar) {
        case 'agenda':
            return (
                <Paper style={{ padding: 0, border: 0, borderRadius: 0, height: '100%' }}>
                    <AppBar position="relative" style={{ height: 95 }}>
                    <Toolbar>
                        <Typography variant="h6" style={{textAlign: 'center', width: '100%', flexDirection: 'column', verticalAlign: 'center', justifyItems:'center'}}>
                            Agenda
                        </Typography>
                    </Toolbar>
                    </AppBar>
                    <div style={{ height: 'calc(100% - 95px)', width: '100%', overflow: 'hidden', WebkitOverflowScrolling: 'touch', padding: 0, margin: 0 }}>
                        <React.Fragment>
                            <Grid centered style={{ width: '100%', padding: 4, paddingBottom: 0, margin: 0 }}>
                                <Paper style={{ width: '100%', padding: 0, margin: 0, paddingTop: 45, paddingBottom: 45 }}>
                                    <Card.Content>
                                        <Card.Header style={{fontSize:'18px'}}>
                                            {selectedRow.vc_nombre}
                                        </Card.Header>
                                        <Card.Header>
                                            {selectedRow.vc_especialidad}
                                        </Card.Header>
                                    </Card.Content>
                                </Paper>
                            </Grid>
                            <Grid.Row style={{ flexDirection: 'column' }}>
                                <Button variant="outlined" color='primary' onClick={props.edit} style={{ width: "calc(100% - 6px)", margin: 3, marginBottom: 0 }}>Editar</Button>

                                <Button variant="outlined" color='primary' onClick={props.remove} style={{ width: "calc(100% - 6px)", margin: 3, marginBottom: 0 }}>Eliminar</Button>
                            </Grid.Row>
                        </React.Fragment>
                    </div>
                </Paper>
                );

        default:
            return null;
    }
};
import React from 'react';
import ReactDOM from 'react-dom';
import './app/config/global';
import App from './app';
import { CleanConsole } from '@eaboy/clean-console';
import * as serviceWorker from './serviceWorker';

// Hide console statements
if (process.env.NODE_ENV === 'production') {
  CleanConsole.init()
}

window.onbeforeunload = function () {}

ReactDOM.render(<App />, document.querySelector('.global-container'));
if (window.location.protocol === 'http:' && window.location.host !== 'localhost:3000' && window.redirectSafe) {
  // window.location = window.redirectSafe;
}else{
  serviceWorker.register();
}

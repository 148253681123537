export default {
    vc_name: 'Unidades de medida',
    vc_path: 'productos/unidades-medidas',
    fields: [
        {
            "type": "number",
            "vc_name": "Id",
            "vc_icon": 'FormatListNumberedOutlined',
            "key": "id",
            "readOnly": true,
            "nullify": true,
            "component": "textField"
        },
        {
            "type": "text",
            "vc_icon": 'ShortTextOutlined',
            "vc_name": "Clave SAT",
            "key": "vc_cve",
            "component": "textField"
        },
        {
            "type": "text",
            "vc_icon": 'ShortTextOutlined',
            "vc_name": "Tipo",
            "key": "vc_tipoUm",
            "component": "textField"
        },
        {
            "type": "text",
            "vc_icon": 'ShortTextOutlined',
            "vc_name": "Nombre",
            "key": "vc_unidadMedida",
            "component": "textField"
        }
    ]
};
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Login from "./Login";

const index = (props) => {
  return <Login />;
};

index.propTypes = {
  setActiveView: PropTypes.func,
  navigate: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const actions = {};

export default connect(mapStateToProps, actions)(index);

import React, { Component } from 'react';


class ProgressBar extends Component {
    constructor(props) {
      super(props);

      this.state = {
        id: null
      };
    }

    render() {
      const percent = Math.floor(this.props.percent);

      return (
        <div style={{ border: 0, height: '100%', width: '100%', backgroundColor: this.props.backgroundColor, borderRadius: 3 }}>
          <div style={{ border: 0, height: '100%', width: `${percent}%`, backgroundColor: this.props.rangeColor, borderRadius: 3 }}>
            <div style={{ padding: 0, float: 'right', textAlign: 'right', fontSize: 6, color: 'white', border: 0, height: '100%', width: `${percent}%` }}>
              {/* <div>{`${percent}%`}</div> */}
            </div>
          </div>
        </div>
      );
    }
}


export { ProgressBar };

export default {
    vc_name: 'Impuestos',
    vc_path: 'productos/impuestos',
    fields: [
        {
            "type": "number",
            "vc_name": "Id",
            "vc_icon": 'FormatListNumberedOutlined',
            "key": "id",
            "readOnly": true,
            "nullify": true,
            "component": "textField",
            "filter": true
        },
        {
            "type": "text",
            "vc_icon": 'ShortTextOutlined',
            "vc_name": "Nombre",
            "key": "vc_nombre",
            "component": "textField",
            "filter": true
        },
        {
            "type": "number",
            "vc_icon": 'GavelOutlined',
            "vc_name": "Tasa",
            "key": "nu_factor",
            "component": "textField"
        }
    ]
};
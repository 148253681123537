import React from "react";
import PropTypes from "prop-types";

import { AppBar, Toolbar, Typography, Box, Button } from "@material-ui/core";

const Index = (props) => {
  const { selected, actions } = props;

  const botones = [
    {
      text: "Editar",
      onClick: () => actions?.editar(),
      disabled: false,
    },
    // {
    //   text: "Eliminar",
    //   onClick: () => actions?.editar(),
    //   disabled: false,
    // },
  ];

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <AppBar
        position="relative"
        style={{
          height: 95,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Typography style={{ textAlign: "center", width: "100%" }}>
            Dependencia
          </Typography>
        </Toolbar>
      </AppBar>
      <Box flex={1} display="flex" flexDirection="column">
        <Box p={2} py={4}>
          <Typography align="center">{selected.vc_nombre}</Typography>
          <Typography align="center">{selected.vc_direccion}</Typography>
        </Box>
        <Box paddingX={1} height="0px" overflow="scroll" flex="1 1 auto">
          {botones.map((a, i) => (
            <Box mb={1} key={i}>
              <Button
                key={i}
                variant="outlined"
                color="primary"
                fullWidth
                disabled={a.disabled}
                onClick={a.onClick}
              >
                {a.text}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

Index.defaultProps = { selected: null, actions: {} };

Index.propTypes = {
  selected: PropTypes.object,
  actions: PropTypes.object,
};

export default Index;

import React, { useEffect } from "react";

import {
    DialogContentText,
    Slide,
    makeStyles,
    InputBase,
    Typography,
    Box,
    Paper,
} from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";

import Input from "../../components/Form/Input";
import InputSelect from "../../components/Form/Select";
import { LabelOutlined } from "@material-ui/icons";
import { useState } from "react";
import NumberFormat from "react-number-format";
import SearchSelect from "./SearchSelect";

const useStyles = makeStyles((theme) => {
    return {
        input: {
            padding: 8,
            width: "100%",
            backgroundColor: "rgba(200,200,200,0.2)",
            borderRadius: 5,
            textAlign: "right",
        },
    };
});
const HiddeField = (props) => {
    const { field, fieldid } = props;
    const [value, setValue] = useState(props.value || '')
    const [errorMessage, setErrorMessage] = useState(null);
    const Icon = require(`@material-ui/icons/${field.vc_icon || 'LabelOutlined'}`).default;

    useEffect(() => {
        setValue(props.value || '');
    }, [props.value])

    return (
        <div style={{ width: 0, height: 0, overflow:'hidden' }}>
            <TextField
                key={fieldid}
                id={fieldid}
                value={value.value || ''}
                field={field}
                name={props.vc_name}
                disabled
                style={{ width: 0, height: 0, position: 'absolute' }}

            />
        </div>
    );
}
const TextField = (props) => {
    const { field } = props;
    const [value, setValue] = useState(props.value || '')
    const [errorMessage, setErrorMessage] = useState(null);
    const Icon = require(`@material-ui/icons/${field.vc_icon || 'LabelOutlined'}`).default;

    useEffect(() => {
        setValue(props.value || '');
    }, [props.value])

    return (
        <GridUI container style={{ position: 'relative' }}>
            <InputBase
                key={field.key}
                id={field.key}
                placeholder={field.nullify ? field.vc_name : field.vc_name + '*'}
                startAdornment={<Icon style={{ marginRight: 5, color: 'gray', width: 50 }} />}
                fullWidth
                multiline
                type={field.type}
                value={value}
                onChange={(e) => {
                    let t = '';
                    if (field.maxLength && `${e.target.value}`.length > field.maxLength)
                        return;
                    switch (field.textTransform) {
                        case 'uppercase': {
                            t = `${e.target.value}`.toUpperCase();
                            break;
                        }
                        case 'lowercase': {
                            t = `${e.target.value}`.toLowerCase();
                            break;
                        }
                        default: {
                            t = e.target.value;
                        }
                    }
                    setValue(t)
                    if (field.regExp) {
                        const regExp = new RegExp(field.regExp)
                        const val = regExp.test(t)
                        if (!val && t !== '') {
                            console.log('regExp.test(t)', regExp.test(t))
                            setErrorMessage('*Formato invalido')
                        } else if (errorMessage) {
                            setErrorMessage(null);
                        }
                    }

                }}

                inputProps={{ style: { padding: 18, borderRadius: 5, boxShadow: '0px 0px 5px rgba(10,10,10,0.0)', backgroundColor: 'rgba(200,200,200,0.2)' } }} />
            <Typography variant='inherit' style={{ position: 'absolute', marginTop: 55, width: '100%', textAlign: 'right', padding: 3, color: 'gray' }}>
                {errorMessage || ''}
            </Typography>
        </GridUI>
    )
}


const PhoneNumberFormat = props => {
    const {
        inputRef,
        onChange,
        ...other
    } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
        />
    );
};

const PhoneNumber = (props) => {
    const { field } = props;
    const [value, setValue] = useState(props.value || '')
    const [errorMessage, setErrorMessage] = useState(null);
    const Icon = require(`@material-ui/icons/${field.vc_icon || 'LabelOutlined'}`).default;
    return (
        <GridUI container style={{ position: 'relative' }}>
            <InputBase
                key={field.key}
                id={field.key}
                placeholder={field.nullify ? field.vc_name : field.vc_name + '*'}
                startAdornment={<Icon style={{ marginRight: 5, color: 'gray', width: 50 }} />}
                fullWidth
                multiline
                type='text'
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
                inputComponent={PhoneNumberFormat}
                inputProps={{ format: "(###) #-##-##-##", style: { padding: 18, borderRadius: 5, boxShadow: '0px 0px 5px rgba(10,10,10,0.0)', backgroundColor: 'rgba(200,200,200,0.2)' } }} />
            <Typography variant='inherit' style={{ position: 'absolute', marginTop: 55, width: '100%', textAlign: 'right', padding: 3, color: 'gray' }}>
                {errorMessage || ''}
            </Typography>
        </GridUI>
    )
}


export default (props) => {
    const classes = useStyles();
    const { value, field = {}, handleInputChange, disabled = false } = props;

    let Component = null;
    switch (field.component) {
        case 'textField': {
            Component = TextField
            break;
        }
        case 'select': {
            Component = SearchSelect;
            break;
        }
        case 'phone': {
            Component = PhoneNumber;
            break;
        }
        default: {
            console.log(field.component)
            Component = Input;
            break;
        }
    }
    return (
        <GridUI container>
            <Component
                field={field}
                value={value}
            />
        </GridUI>
    );
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { menuCatalogos, menuInicio } from '../../containers/Think/listados';
import {resetHelperSidebarRender, setHelperSidebarRender, setState} from '../../containers/Think/actions';
import CatalogosView from '../components/CatalogosView';
import notifications from '../../utils/notifications';
const modulo = 'backoffice'
function getSteps() {
  return ['Registro de institución', 'Paquetes', 'Forma de págo', 'Finalizar'];
}

function getModuleContent(moduloActual) {
  switch (moduloActual) {
    case 'catalogos':
      return <CatalogosView/>;
      default : 
      return <CatalogosView/>;
    }
}

class BackOfficeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { activeStep: 0, data: {}, submitting: false, moduloActual: 'catalogos', opcion: 'listadoCatalogos' };

    this.handlePrevStep = this.handlePrevStep.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.updateData = this.updateData.bind(this);
    console.log('aki')
    this.validateLogin = this.validateLogin.bind(this);
  }

  componentDidMount() {
    this.props.setActiveView('catalogos');
    this.props.setViewTitle('¡Bienvenido!');
    this.setMenuOptions();
    this.props.resetHelperSidebarRender();
    if (!this.props.state) {
      this.props.setState({modulo, state:this.state})
    }
  }
  
  setMenuOptions() {
    const options = menuCatalogos.map((option) => ({
      ...option,
      onClick: this.onChangeModule.bind(this),
    }));

    this.props.setSubMenuOptions(options);
  }
  validateLogin() {
		if (window.amplify.store('token') != null) {
			return true;
		}
		return false;
  }
  onChangeModule(modulo) {
		if (this.validateLogin()) {
      this.props.setViewTitle(modulo.vc_nombre);

			this.setState({ moduloActual: modulo.id }, () => {
				this.props.resetHelperSidebarRender();
			});
		} else if (modulo.id === 'gpc') {
      notifications.success('¡Registrate gratis para acceder a las Guias!');
    } else {
      notifications.success('¡Registrate con un plan para acceder a todas las herramientas!');
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.submitting && this.state.submitting) {
      const successStep = document
      .getElementById('institution')
      .dispatchEvent(new Event('submit', { cancelable: true }))
      if (!successStep) {
        this.setState({submitting: false})
      };
    }
  }

  handlePrevStep() {
    this.setState({ activeStep: this.state.activeStep - 1, submitting: false });
  }

  handleNextStep() {
    this.setState({ activeStep: this.state.activeStep + 1, submitting: false });
  }

  updateData(data, cb) {
    this.setState({ data: Object.assign({}, this.state.data, data) }, cb);
  }

  render() {
    const { classes } = this.props;
    const { moduloActual } = this.state;
    const currentStep = React.cloneElement(
      getModuleContent(moduloActual),
      {
        navigate: this.props.navigate
      }
    );
    if (!this.props.state) return <div/>
    return (
     <div style={{height: '100%'}}>
        {currentStep}
     </div>
    );
  }
}
BackOfficeView.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  updateCatalogs: PropTypes.func,
  setState: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  return { 
    state: state.Think[modulo] || null,
    catalogos
   };
};


const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState
};


export default connect(mapStateToProps, actions)(BackOfficeView);

import React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';

import { START_VIEW_TRANSITION } from '../../../../../constants';
import eventManager from '../../../../../utils/eventManager';

import * as firebase from 'firebase/app';
import { Popover, Typography, Button, Badge } from '@material-ui/core';

class Index extends React.Component {
    constructor() {
        super();
        this.app = firebase;
        this.db = this.app.database().ref();
        this.storage = this.app.storage().ref();
        this.state = {
            open: false,
            prevHidden: true,
            notasPausadas: []
        };
    }
    getActions() {
        const { id_institucion = null } = window.amplify.store('institution') || {}
        if (!id_institucion) return []

        return this.state.notasPausadas
            .filter(v => v.detalle && v.detalle.id_institucion == id_institucion)
            .map(nota => ({
                icon: <PermContactCalendar />,
                name: `${nota.detalle.vc_nombre} ${nota.detalle.vc_apellidoPaterno}`,
                nota
            }))

        // const institucion = window.amplify.store('institution') || {};
        // const id_institucion = institucion.id_institucion || null;
        // if (!id_institucion) return [];
        // const actions = [];
        // this.state.notasPausadas.map((nota, index) => {
        //     if (nota.detalle.id_institucion === id_institucion)
        //         actions.push({ icon: <PermContactCalendar />, name: `${nota.detalle.vc_nombre} ${nota.detalle.vc_apellidoPaterno}`, nota })
        // })
        // return actions;
    }

    render() {
        return (
            <div style={{ position: 'absolute', right: this.state.open ? 8 : 36, bottom: 8, zIndex: 1000, width: this.state.open ? '' : 0 }}>
                <SpeedDial
                    ariaLabel="SpeedDial openIcon example"
                    hidden={(this.getActions().length === 0)}
                    icon={
                        <Badge overlap="rectangle">
                            <PermContactCalendar />
                        </Badge>
                    }
                    onClose={() => this.setState({ open: false })}
                    onOpen={() => this.setState({ open: true })}
                    open={this.state.open}
                >
                    {this.getActions().map((action, index) => (
                        <SpeedDialAction
                            key={index}
                            icon={action.icon}
                            tooltipPlacement='left'
                            tooltipTitle={action.name}
                            onClick={(e) => {
                                this.setState({ open: false, anchorEl: e.currentTarget, showNotification: true, notaSelected: action.nota })
                            }}
                        />
                    ))}
                </SpeedDial>
                <Popover
                    open={!!this.state.showNotification}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({ open: false, anchorEl: null, showNotification: false, notaSelected: null })}
                    onClick={(e) => e.stopPropagation()}
                    anchorOrigin={{ vertical: 'center', horizontal: 'center', }}
                    transformOrigin={{ vertical: 'center', horizontal: 'center', }}
                >
                    <div style={{ padding: '20px', height: '100px', textAlign: 'center' }}>
                        <Typography>{`Nota pausada de: ${this.state.notaSelected && this.state.notaSelected.detalle.vc_nombre} ${this.state.notaSelected && this.state.notaSelected.detalle.vc_apellidoPaterno} ${this.state.notaSelected && this.state.notaSelected.detalle.vc_apellidoMaterno}`}</Typography>
                        <Typography>{`¿Quieres reanudar la nota?`}</Typography>
                        <Button size="small" color="primary" onClick={(e) => {
                            const nota = { ...this.state.notaSelected };
                            this.setState({ open: false, showNotification: false, notaSelected: null }, () => {
                                const user = window.amplify.store('user').usuario;
                                const firebaseUser = user.vc_email.split('.').join('')

                                if (user) {
                                    this.db.child('pausedNotes').child(firebaseUser).child(`${nota.detalle.id}`).remove();
                                }
                            })
                            e.stopPropagation();
                        }} style={{ margin: 8 }}>Eliminar</Button>
                        <Button size="small" color="primary" onClick={(e) => {
                            const nota = { ...this.state.notaSelected };
                            this.setState({ open: false, showNotification: false, notaSelected: null }, () => {
                                const user = window.amplify.store('user').usuario;
                                const firebaseUser = user.vc_email.split('.').join('')
                                if (user) {
                                    this.db.child('pausedNotes').child(firebaseUser).child(`${nota.detalle.id}`).remove();
                                }
                                this.props.setPacienteActual(nota);
                                eventManager.emit(START_VIEW_TRANSITION, `#/admin/notas`);
                            })
                            e.stopPropagation();
                        }} style={{ margin: 8 }}>Reanudar</Button>
                        <Button size="small" color='default' onClick={() => this.setState({ open: false, anchorEl: null, showNotification: false, notaSelected: null })} style={{ margin: 8 }}>Cancelar</Button>
                    </div>
                </Popover>
            </div>
        );
    }

    componentDidMount() {
        const user = (window.amplify.store('user') || {}).usuario;
        const firebaseUser = user ? user.vc_email.split('.').join('') : null

        if (firebaseUser) {
            this.db.child('pausedNotes').child(firebaseUser).on('value', snapshot => {
                if (snapshot.val() && Object.values(snapshot.val()) != null) {
                    const objects = [];
                    Object.values(snapshot.val()).map((value, index) => {
                        objects.push(JSON.parse(value))
                    })
                    this.setState({ notasPausadas: objects })
                }
            });

            this.db.child('pausedNotes').child(firebaseUser).on('child_removed', snapshot => {
                this.setState({ notasPausadas: [] })
            });
        }

    }
}

export default Index;

export default {
    vc_name: 'Proveedores',
    vc_path: 'proveedores',
    fields: [
        {
            "type": "number",
            "vc_name": "Id",
            "vc_icon": 'FormatListNumberedOutlined',
            "key": "id",
            "readOnly": true,
            "nullify": true,
            "component": "textField",
            "filter": true
        },
        {
            "type": "text",
            "filter": true,
            "vc_icon": 'DescriptionOutlined',
            "vc_name": "RFC",
            "key": "vc_rfc",
            "textTransform": "uppercase",
            "maxLength": 13,
            "regExp": "[A-Z]{1}([AEIOU]{1}|[A-Z]{1})([A-Z]{2}|[A-Z]{1})[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[0-9A-Z]{3}$",
            "component": "textField"
        },
        {
            "type": "text",
            "filter": true,
            "vc_icon": 'PersonOutlineOutlined',
            "vc_name": "Nombre completo",
            "textTransform": "uppercase",
            "key": "vc_nombre",
            "component": "textField"
        },
        {
            "type": "text",
            "filter": true,
            "vc_icon": 'ShortTextOutlined',
            "vc_name": "Razón Social",
            "key": "vc_razonSocial",
            "component": "textField"
        },
        {
            "type": "text",
            "vc_icon": 'PhoneOutlined',
            "vc_name": "Telefono",
            "key": "vc_telefono",
            "component": "phone",
            "nullify": true
        },
        {
            "type": "text",
            "vc_icon": 'MailOutlineOutlined',
            "vc_name": "Email",
            "key": "vc_email",
            "regExp": /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
            "component": "textField",
            "nullify": true
        },
        {
            "type": "text",
            "vc_icon": 'LocationOnOutlined',
            "vc_name": "Domicilio Fiscal",
            "key": "vc_domicilioFiscal",
            "component": "textField",
            "nullify": true
        },
        // {
        //     "type": "number",
        //     "vc_name": "Proveedor",
        //     "key": "id_proveedor",
        //     "component": "select",
        //     "fullWidth": true,
        //     "relation": {
        //         "id_type": 2,
        //         "vc_nameTable": "usuariosDetalle",
        //         "vc_nameReducer": "usuarios",
        //         "key": "id_usuario",
        //         "get": {
        //             "backEndURL": "https://api.thin-k.mx",
        //             "vc_url": "/app/api/usuarios/buscar",
        //             "searchKey": "text",
        //             "parameters": [
        //                 {
        //                     "key": "id_institucion",
        //                     "type": "fieldValue",
        //                     "field": "id_usuarioTurnado"
        //                 }
        //             ]
        //         },
        //         "keyTable": "id",
        //         "fieldsDisplay": [
        //             "detalle.vc_nombre",
        //             "detalle.vc_apellidoPaterno",
        //             "detalle.vc_apellidoMaterno",
        //             "detalle.vc_curp"
        //         ],
        //         "fieldsDisplaySeparators": [
        //             " ",
        //             " ",
        //             " - "
        //         ],
        //         "rules": []
        //     }
        // }
    ]
};
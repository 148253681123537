import React, { Component } from 'react';
import transitions from '../utils/transitions';

const INITIAL_VALUE = 0;
const FINAL_VALUE = 1;


class InsertCell extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valueTransition: INITIAL_VALUE,
            index: null
        };
    }

    componentDidMount() {
        const delay = this.props.delay || 0;
        const params = {
            initialValue: INITIAL_VALUE,
            finalValue: FINAL_VALUE,
            index: this.props.index || null,
            duration: this.props.duration || 1000,
            width: "0%"
        };

        setTimeout(() => {
            transitions.easePolyOut(params, (response) => {
                this.setState({ valueTransition: response.value, index: params.index });
            });
        }, delay);
    }

    render() {
        const { valueTransition, index } = this.state;
        const h = `${valueTransition * 10}` + "%"
        const stylesInsert = {
            width: "100%",
            height: h,
            margin:0,
            padding:0
        }
        const styles = {
            width: "100%",
            margin:0,
            padding:0
        }
        console.log(h);
        if ( index == 0 ) {
            return (
                <div style={stylesInsert}>
                    {/* { this.props.children } */}
                </div>
            );
        }else{
            return (
                <div style={styles}>
                    { this.props.children }
                </div>
            );
        }
    }
}


export { InsertCell };

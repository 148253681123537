import React from "react";

import {
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Slide,
    makeStyles,
    Snackbar,
} from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";

import Fields from "./fields";
import erpEndPoints from "app/utils/internalAPI/endPoints/erpEndPoints";
import { isNumber } from "lodash";

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => {
    return {
        input: {
            padding: 8,
            width: "100%",
            backgroundColor: "rgba(200,200,200,0.2)",
            borderRadius: 5,
            textAlign: "right",
        },
    };
});

export default (props) => {
    const classes = useStyles();
    const { open, close, accept, data, catalog } = props;
    const [form, setForm] = React.useState(
        data || {

        }
    );
    const [disabled, setDisabled] = React.useState(false);

    const handleInputChange = (key, value) => {
        setForm((prev) => ({ ...prev, [key]: value }));
    };

    const getDataForm = () => {
        const obj = {};
        const nulls = [];
        const notFormat = [];
        catalog.fields.map(field => {
            console.log('document.getElementById(field.key)', document.getElementById(field.key)?.value)
            let val = (document.getElementById(field.key) || {}).value || null;
            let editValue = null;
            if (field.regExp) {
                const regExp = new RegExp(field.regExp)
                const v = regExp.test(val)
                if (!v && val) {
                    notFormat.push(field);
                }
            }
            if (!field.nullify && (val === null || val.length === 0)) {
                nulls.push(field);
            }
            switch (field.type) {
                case 'number': {
                    editValue = isNumber(val * 1) && val !== null ? (val * 1) : null;
                    break;
                }
                default: {
                    editValue = val !== null ? `${val}` : null;
                    break;
                }
            }
            if (editValue !== null){
                obj[field.key] = editValue; 
            }
        })
        return { error: nulls.length || notFormat.length, notFormat, nulls, obj };
    }
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            fullWidth
            maxWidth={"xs"}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{catalog.vc_name || 'No definido'}</DialogTitle>
            <DialogContent>
                {
                    catalog.fields.map((field, index) => {
                        return (
                            <Fields
                                key={index}
                                field={field}
                                value={form[field.key] || null}
                                handleInputChange={handleInputChange}
                            />
                        )
                    })
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={close}
                    style={{ alignSelf: "right" }}
                >
                    {'Cancelar'}
                </Button>
                <Button
                    onClick={() => {
                        const dataEdit = getDataForm();
                        console.log('data', dataEdit)
                        if (!dataEdit.error) {
                            switch (true) {
                                case (!!dataEdit.id): {
                                    erpEndPoints.general(catalog.vc_path, 'PUT', dataEdit.obj, (err, res) => {
                                        console.log('err', err);
                                        console.log('res', res);
                                        if (err) {
                                            if (err.text) {
                                                
                                            }
                                        } else {
                                            setForm(res);
                                        }
                                    });
                                    break;
                                }
                                case (!dataEdit.obj.id): {
                                    erpEndPoints.general(catalog.vc_path, 'POST', dataEdit.obj, (err, res) => {
                                        console.log('err', err);
                                        console.log('res', res);
                                        if (err) {
                                            if (err.text) {
                                                
                                            }
                                        } else {
                                            setForm(res);
                                        }
                                    });
                                    break;
                                }
                            }
                        } else {
                            setDisabled(true);
                        }
                    }}

                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 8 }}
                    disabled={disabled}
                >
                    {'Guardar'}
                </Button>
            </DialogActions>
            {/* <Dialog
                open={open}
                TransitionComponent={Transition}
                fullWidth
                maxWidth={"xs"}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >

            </Dialog> */}
            <Snackbar
                autoHideDuration={2000}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={disabled}
                onClose={() => { setDisabled(false) }}
                TransitionComponent={Transition}
                message="Favor de verificar los campos"
                key={Transition.name || ''}
            />
        </Dialog>
    );
};

import http from '../http';


export default {
    getDependencias(id_institucion){
        return new Promise((resolve, reject) => {
            const headers = { Authorization: window.amplify.store('token') };

            http.get(`/app/api/dependencias?id_institucion=${id_institucion}`, headers, (err, res) => {
                if(err) reject(err)
                else resolve(res)
            });    
        })
    },
    storeDependencia(body){
        return new Promise((resolve, reject) => {
            const headers = { Authorization: window.amplify.store('token') };
            
            http.post(`/app/api/dependencias`, headers, body, (err, res) => {
                if(err) reject(res)
                else resolve(res)
            }); 
        })
    },
    updateDependencia(body){
        return new Promise((resolve, reject) => {
            const headers = { Authorization: window.amplify.store('token') };
            
            http.put(`/app/api/dependencias/${body.id}`, headers, body, (err, res) => {
                if(err) reject(res)
                else resolve(res)
            }); 
        })
    }
};

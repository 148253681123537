import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Divider,
  Box,
  Paper
} from "@material-ui/core";
import moment from "moment";

const sexos = ["No Especificado", "Masculino", "Femenino", "Indistinto"];

export default (props) => {
  const buttons = [
    {
      variant: "outlined",
      color: "primary",
      label: "Revisar",
      onClick: props.revisar,
    },
    { variant: "outlined", color: "primary", label: "Eliminar", onClick() {} },
  ];

  const { cuenta } = props;

  console.log(cuenta);

  const nombreCompleto = `${cuenta.vc_nombre} ${cuenta.vc_apellidoPaterno} ${cuenta.vc_apellidoMaterno}`;

  const typoStyle = {
    margin: "5px 0px",
    fontSize: "15px",
    fontWeight: 500,
    textTransform: "capitalize",
  };

  return (
    <Box height="100%">
      <AppBar
        position="relative"
        style={{
          height: 95,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Typography style={{ textAlign: "center", width: "100%" }}>Cuenta</Typography>
        </Toolbar>
      </AppBar>
      {/* flex box scroll */}
      <Box>
        <Paper elevation={0} style={{ width: "100%", padding: 16 }}>
          <Typography style={typoStyle}>
            {nombreCompleto}
          </Typography>
          <Typography style={typoStyle}>
            Sexo: {sexos[cuenta.id_sexo || 0]}
          </Typography>
          <Typography style={typoStyle}>
            Edad: {moment().diff(
                moment(cuenta.dt_nacimiento),
                "years"
              )}{" "}
              {" años"}{" "}
          </Typography>
          <Typography style={typoStyle}>
            CURP: {cuenta.vc_curp}
          </Typography>
        </Paper>

        <Divider />

        <Box px={1} mt={1} display="flex" flexDirection="column">
          {buttons.map(({ label, ...props }) => (
            <Button style={{marginBottom: 10}} {...props}>{label}</Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

import React from "react";
import { Typography, Grid, Paper, styled } from "@material-ui/core";
import moment from "moment";

import Table from "app/material-ui/Table";

const Item = styled(Paper)({
  border: 0,
  borderRadius: 5,
  padding: "12px",
  marginBottom: "15px",
  border: "1px solid transparent",
  "&.selected": {
    borderColor: window.colors.itemSelected,
  },
  "& h5": {
    color: window.colors.itemSelected,
    marginBottom: "0",
  },
  "& label": {
    marginRight: "5px",
  },
});

export default (props) => {
  const { data = [], onClick = () => {}, selected } = props;
  const columns = [
    {
      name: "vc_nombreCompleto",
      label: "Nombre del Cliente",
      options: {
        cellProps: {
          className: "fill",
        },
        typographyProps: {
          color: "primary",
          variant: "subtitle1",
        },
      },
    },
    {
      name: "vc_curp",
      label: "Curp",
      options: { cellProps: { className: "sm-hide" } },
    },
    {
      name: "vc_sexo",
      label: "Sexo",
      options: { cellProps: { className: "sm-hide" } },
    },
    {
      name: "nu_edad",
      label: "Edad",
      options: { cellProps: { className: "sm-hide" } },
    },
    { name: "dt_atencion", label: "Atencion" },
    // { name: "dt_finalizacion", label: "Finalizacion" },
  ];

  const dataAux = data.map((item) => {
    const {
      vc_curp = "No Especificado",
      id_sexo = 0,
      dt_nacimiento,
    } = item || {};

    const nu_edad = moment().diff(moment(dt_nacimiento), "years");
    const vc_sexo = ["No Especificado", "Masculino", "Femenino", "Indistinto"][
      id_sexo
    ];

    const dt_atencion = moment(item.dt_registro).format("hh:mm a, DD-MM-YYYY");

    return {
      ...item,
      vc_curp,
      nu_edad,
      vc_sexo,
      dt_atencion,
      dt_finalizacion: 0,
      vc_nombreCompleto: `${item.vc_nombre} ${item.vc_apellidoPaterno} ${item.vc_apellidoMaterno}`.trim(),
    };
  });

  return (
    <Table
      columns={columns}
      data={dataAux}
      onSelect={onClick}
      value={selected?.id}
    />
  );
};

import React, { useCallback, useEffect, useState } from "react";
import AbrirCajaDialog from "app/sales/components/Cuentas/AbrirCajaDialog";
import useApi from "app/sales/hooks/useApi";
import { useSelector, useDispatch } from "react-redux";
import { setCaja } from "app/sales/store/actions";

export default () => {
  const state = useSelector((state) => state.Sales);
  const dispatch = useDispatch();

  const { get } = useApi("session/cajas");
  const { post } = useApi("session/cajas/apertura");

  const [isOpen, setIsOpen] = useState(false);

  const [selected, setSelected] = useState(!!state.uuidCaja);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  const onOpen = () => setIsOpen(true);
  const handleAccept = async (uuid, cantidad) => {
    const userId = window.amplify.store("user_id");

    try {
      const res = await post({
        nu_montoInicial: cantidad,
        ref_idUsuario: userId,
        uuid_caja: uuid,
      });

      console.log("RESAPERTURA", res);

      dispatch(setCaja(uuid));
      setSelected(true);
    } catch (error) {
      dispatch(setCaja(null));
    }
  };

  useEffect(() => {
    get()
      .then((res) => {
        const opciones = res.items
          .filter((v) => !v.uuid_apertura)
          .map((v) => ({ value: v.uuid, text: v.vc_nombre }));
        setOptions(opciones);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const Dialog = useCallback(
    () => (
      <AbrirCajaDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onAccept={handleAccept}
        value={value}
        options={options}
      />
    ),
    [isOpen]
  );

  return {
    AbrirCajaDialog: Dialog,
    onOpen,
    selected,
  };
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { position } from "caret-pos";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    width: "100%",
    textAlign: "left",
    position: "relative",
  },
  iconSet: {
    position: "absolute",
    bottom: 8,
    right: 8,
  },
}));

export default function PaperSheet(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" component="h3">
        {props.title || ""}
      </Typography>
      <Typography component="p">{props.description || ""}</Typography>
      <Box className={classes.iconSet}>{props.iconSet || null}</Box>
    </Paper>
  );
}

import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ResponderHelpBar from "./responderHelpBar";
import HelpBarThink from "../../../../material-ui/HelpBarThink";

import { getLastDetalle, getIndexLastDetalle } from "app/utils/repository";

import {
  StaticDatePicker,
  StaticTimePicker,
  LocalizationProvider,
} from "@material-ui/pickers";

import { Button, IconButton, Popover, DialogActions } from "@material-ui/core";
import { Today } from "@material-ui/icons";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const IconDatePicker = (props) => {
  const { value, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [date, setDate] = React.useState(value);
  const select = (newDate) => {
    console.log("------------>", date);
    if (newDate) {
      onChange(moment(newDate));
      setAnchorEl(null);
    } else if (date) {
      onChange(moment(date));
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        size="small"
        style={{ marginLeft: 10, marginRight: 10 }}
        onClick={(e) => {
          setAnchorEl(e.target);
        }}
      >
        <Today />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onInput={(e) => console.log("input")}
      >
        <LocalizationProvider dateAdapter={MomentAdapter}>
          <StaticDatePicker
            allowKeyboardControl
            displayStaticWrapperAs="desktop"
            autoOk
            openTo="date"
            views={["date", "month", "year"]}
            value={value}
            onChange={setDate}
            onYearChange={(newDate) => {
              setDate(newDate);
              select(newDate);
            }}
          />
        </LocalizationProvider>
        <DialogActions>
          <Button color="primary" onClick={() => setAnchorEl(null)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={select}>
            Aceptar
          </Button>
        </DialogActions>
      </Popover>
    </div>
  );
};

class ProcedimientosHelpBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      indexHover: null,
      indexSelected: this.props.indexSelected,
      preguntaActual: null,
      preguntasRespondidas: [],
      preguntasPendientes: [],
      preguntasNumericas: [],
      preguntasTexto: [],
      opcionPreSelected: null,
      umTiempo: this.props.umTiempo,
      procedimiento: this.props.procedimiento,
    };
  }

  render() {
    const { index, procedimiento, opcionProcedimientos } = this.props;

    if (this.state.preguntaActual != null) {
      return (
        <ResponderHelpBar
          callbackPregunta={({ pregunta, opcionesModuloNext }) => {
            if (pregunta) {
              const preguntasNumericas = this.props.pacienteActual
                .preguntasNumericas;
              const preguntasTexto = this.props.pacienteActual.preguntasTexto;
              const indexItemNumericas = preguntasNumericas.indexOf(
                this.state.preguntaActual
              );
              const indexItemText = preguntasTexto.indexOf(
                this.state.preguntaActual
              );
              if (indexItemNumericas) {
                preguntasNumericas[indexItemNumericas] = pregunta;
                if (
                  preguntasNumericas[indexItemNumericas].id == 241 ||
                  preguntasNumericas[indexItemNumericas].id == 242
                ) {
                  if (
                    preguntasNumericas.filter((e, index) => e.id == 241)
                      .length > 0 &&
                    preguntasNumericas.filter((e, index) => e.id == 242)
                      .length > 0
                  ) {
                    if (
                      preguntasNumericas.filter((e, index) => e.id == 241)[0]
                        .respuesta &&
                      preguntasNumericas.filter((e, index) => e.id == 242)[0]
                        .respuesta
                    ) {
                      if (
                        preguntasNumericas.filter((e, index) => e.id == 241)[0]
                          .respuesta.nu_respuesta &&
                        preguntasNumericas.filter((e, index) => e.id == 242)[0]
                          .respuesta.nu_respuesta
                      ) {
                        const tallaMts =
                          preguntasNumericas.filter(
                            (e, index) => e.id == 241
                          )[0].respuesta.nu_respuesta / 100;
                        const imc =
                          preguntasNumericas.filter(
                            (e, index) => e.id == 242
                          )[0].respuesta.nu_respuesta /
                          (tallaMts * tallaMts);
                        preguntasNumericas.filter(
                          (e, index) => e.id == 243
                        )[0].respuesta = {
                          id_pregunta: 243,
                          nu_respuesta: imc.toFixed(1),
                        };
                      }
                    }
                  }
                }
              } else if (indexItemText) {
                preguntasTexto[indexItemText] = pregunta;
              }
              this.siguientePregunta(pregunta);
            }
          }}
          callbackPreguntasNumericas={({ preguntasNumericas }) => {
            this.setState({ preguntasNumericas });
          }}
          callbackWizard={({ indexSeleccionado }) => {
            // console.log(indexSeleccionado);
          }}
          indexSeleccionado={0}
          preguntaActual={this.state.preguntaActual}
          preguntasNumericas={this.props.pacienteActual.preguntasNumericas}
          notasTipoEditor="principal"
          opcion={this.state.subOpcionActualModulo}
          // reload={(e => {
          //     this.forceUpdate();
          // })}
        />
      );
    }

    let optionStyle = {
      flexDirection: "column",
    };

    let labelDisplays = [
      { key: "vc_nombre", style: { width: "100%" } },
      {
        key: "vc_cveCIE9",
        style: {
          color: window.colors.itemSelected,
          textAlign: "right",
          width: "100%",
        },
      },
    ];

    return (
      <HelpBarThink
        header={this.titleHelpBar()}
        searchBar={true}
        menu={this.getMenu()}
        menuValue={this.getMenuValue()}
        options={this.opcionesArray()}
        optionsAction={this.optionsAction()}
        indexSeleccionado={this.state.opcionPreSelected}
        item={procedimiento}
        // itemSkeleton={ProcedimientosItem}
        onChangeValue={this.onChangeValue.bind(this)}
        searchText={this.state.searchText}
        searchEndIcon={this.getSearchBarIcon()}
        labelDisplays={labelDisplays}
        optionStyle={optionStyle}
      />
    );
  }

  // ---------------------------------
  // ------ life cycle events --------
  // ---------------------------------

  componentDidMount() {
    document.onkeypress = this.modifyEnterKeyPressAsTab.bind(this);
    document.onkeydown = this.pressArrows.bind(this);
  }

  componentDidUpdate() {
    if (document.activeElement != window.$("#searchBar")) {
      //window.$('#searchBar').focus();
    }
  }

  componentWillReceiveProps() {
    this.setState({ searchText: "" });
  }

  // ------------------------
  // ------  methods --------
  // ------------------------

  getSearchBarIcon() {
    const { opcionDiagnosticos } = this.props;
    switch (opcionDiagnosticos) {
      case "um_inicio":
      case "nu_inicio":
        const diagnostico = this.addDetalleEdit(this.props.diagnostico);
        const indexDetalle = getIndexLastDetalle(diagnostico.detalles);

        return (
          <IconDatePicker
            value={new Date()}
            onChange={(date) => {
              console.log("cambiamos la fecha", date);
              console.log(diagnostico, indexDetalle);

              diagnostico.detalles[indexDetalle].dt_inicio = date.format(
                "YYYY-MM-DD"
              );
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackDx({
                  diagnostico,
                  opcionesModuloNext: "um_duracion",
                  umTiempo: null,
                })
              );
            }}
          />
        );

      default:
        return null;
    }
  }

  titleHelpBar() {
    const { procedimiento, opcionProcedimientos } = this.props;
    let umString = "";
    if (this.props.umTiempo) {
      const um = this.props.unidadesMedida.filter(
        (e, index) => e.id == this.props.umTiempo
      )[0];
      umString =
        um.vc_nombre == "mes"
          ? `${um.vc_nombre}es`
          : `${um.vc_nombre}s`.toLowerCase();
    }
    // const lastDetalle = medicamento ? medicamento.detalles[medicamento.detalles.length - 1]: null ;

    switch (opcionProcedimientos) {
      case "agregar":
        return "Agregar";
      case "conciliar":
        return "Consciliar";
      case "clasificar":
        return "Clasifique el procedimiento CIE-9";
      case "principal":
        return "Es el procedimiento causa de la ateción";
      case "status":
        return "Espcifique el estatus";
      case "subsecuencia":
        return "¿Es subsecuente?";
      case "um_inicio":
        return "El procedimiento inició hace";
      case "nu_inicio":
        return `¿Hace cuantos ${umString} inició el procedimiento?`;
      case "um_duracion":
        return "El procedimiento duró ó durará:";
      case "nu_duracion":
        return `¿aproximadamente cuantos ${umString} duró ó durará el procedimiento?`;
      case "um_convalecencia":
        return "La convalecencia duró o durará:";
      case "nu_convalecencia":
        return `¿aproximadamente cuantos ${umString} duró ó durará el la convalecencia?`;
      case "vc_observaciones":
        return "Descripción del procedimiento";
    }
  }

  opcionesArray() {
    let opciones = [];
    let i = 0;
    let lastDetalle = null;

    switch (this.props.opcionProcedimientos) {
      case "agregar":
        return this.props.procedimientos
          .filter((e) =>
            `${e.vc_nombre} ${e.vc_cveCIE9}`
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
        break;
      case "conciliar":
        return this.props.procedimiento.detalles
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "clasificar":
        const procedimiento = this.props.procedimientos.filter(
          (e, index) => e.id == this.props.procedimiento.id_procedimiento
        )[0];
        return this.props.procedimientos
          .filter((e, index) =>
            e.vc_cveCIE9.startsWith(procedimiento.vc_cveCIE9.substring(0, 3))
          )
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "principal":
        lastDetalle = this.props.procedimiento
          ? this.props.procedimiento.detalles[
              this.props.procedimiento.detalles.length - 1
            ]
          : null;
        return [
          {
            vc_nombre: "Principal",
            valor: 1,
            style:
              lastDetalle.sn_principal == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Secundario",
            valor: 0,
            style:
              lastDetalle.sn_principal == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "status":
        lastDetalle = this.props.procedimiento
          ? this.props.procedimiento.detalles[
              this.props.procedimiento.detalles.length - 1
            ]
          : null;
        return [
          {
            vc_nombre: "Programado",
            valor: 1,
            style:
              lastDetalle.id_estatusProcedimiento == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "en atención",
            valor: 2,
            style:
              lastDetalle.id_estatusProcedimiento == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Realizado",
            valor: 3,
            style:
              lastDetalle.id_estatusProcedimiento == 2
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Reprogramado",
            valor: 4,
            style:
              lastDetalle.id_estatusProcedimiento == 3
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Cancelado",
            valor: 5,
            style:
              lastDetalle.id_estatusProcedimiento == 3
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
        break;
      case "subsecuencia":
        lastDetalle = this.props.procedimiento
          ? this.props.procedimiento.detalles[
              this.props.procedimiento.detalles.length - 1
            ]
          : null;
        return [
          {
            vc_nombre: "Primera Vez",
            valor: 0,
            style:
              lastDetalle.sn_subsecuente == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Subsecuente",
            valor: 1,
            style:
              lastDetalle.sn_subsecuente == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
        break;
      case "um_inicio":
        return [
          {
            vc_nombre: "Minutos",
            valor: 1,
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Horas",
            valor: 2,
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Días",
            valor: 3,
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Semanas",
            valor: 4,
            style:
              this.props.indexSeleccionado == 3
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Meses",
            valor: 5,
            style:
              this.props.indexSeleccionado == 4
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Años",
            valor: 6,
            style:
              this.props.indexSeleccionado == 5
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Al nacer",
            valor: 34,
            style:
              this.props.indexSeleccionado == 6
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
        break;
      case "nu_inicio":
        opciones = [];
        i = 0;
        for (i = 0; i < 200; i++) {
          const opcion = {
            vc_nombre: `${i + 1}`,
            valor: `${i + 1}`,
            style:
              this.props.indexSeleccionado == i
                ? styles.containerHelp
                : styles.containerHelp,
          };
          opciones.push(opcion);
        }
        return opciones
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
        break;
      case "um_convalecencia":
        return [
          {
            vc_nombre: "Minutos",
            valor: 1,
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Horas",
            valor: 2,
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Días",
            valor: 3,
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Semanas",
            valor: 4,
            style:
              this.props.indexSeleccionado == 3
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Meses",
            valor: 5,
            style:
              this.props.indexSeleccionado == 4
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Años",
            valor: 6,
            style:
              this.props.indexSeleccionado == 5
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Indefinida",
            valor: 34,
            style:
              this.props.indexSeleccionado == 6
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
        break;
      case "nu_convalecencia":
        opciones = [];
        i = 0;
        for (i = 0; i < 200; i++) {
          const opcion = {
            vc_nombre: `${i + 1}`,
            valor: `${i + 1}`,
            style:
              this.props.indexSeleccionado == i
                ? styles.containerHelp
                : styles.containerHelp,
          };
          opciones.push(opcion);
        }
        return opciones
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
        break;
      case "um_duracion":
        return [
          {
            vc_nombre: "Minutos",
            valor: 1,
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Horas",
            valor: 2,
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Días",
            valor: 3,
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Semanas",
            valor: 4,
            style:
              this.props.indexSeleccionado == 3
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Meses",
            valor: 5,
            style:
              this.props.indexSeleccionado == 4
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Años",
            valor: 6,
            style:
              this.props.indexSeleccionado == 5
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Indefinida",
            valor: 34,
            style:
              this.props.indexSeleccionado == 6
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
        break;
      case "nu_duracion":
        opciones = [];
        i = 0;
        for (i = 0; i < 200; i++) {
          const opcion = {
            vc_nombre: `${i + 1}`,
            valor: `${i + 1}`,
            style:
              this.props.indexSeleccionado == i
                ? styles.containerHelp
                : styles.containerHelp,
          };
          opciones.push(opcion);
        }
        return opciones
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
        break;
      case "vc_observaciones":
        opciones = [
          {
            vc_nombre: "Ninguna",
            valor: null,
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "En proceso",
            valor: "actualmente en proceso",
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Finalizado",
            valor: "actualmente finalizado",
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ];

        return opciones
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);

        const procedimientoCat = this.props.procedimientos.filter(
          (e, index) => e.id === this.props.procedimiento.id_procedimiento
        )[0];
        if (procedimientoCat) {
          if (procedimientoCat.id_preguntaEnlace != null) {
            const pregunta = this.props.preguntas.filter(
              (e) => e.id === procedimientoCat.id_preguntaEnlace
            )[0];
            if (pregunta) {
              return [
                {
                  vc_nombre: "Thin-k Response",
                  valor: pregunta,
                  style:
                    this.props.indexSeleccionado == i
                      ? styles.containerHelp
                      : styles.containerHelp,
                },
              ];
            }
          }
        }
        return [];
        break;
      default:
        return [];
    }

    return opciones;
  }

  optionsAction() {
    switch (this.props.opcionProcedimientos) {
      case "agregar":
        return this.addProcedimiento.bind(this);
      case "clasificar":
        return this.setProcedimiento.bind(this);
      case "principal":
        return this.setCausa.bind(this);
      case "status":
        return this.setStatus.bind(this);
      case "subsecuencia":
        return this.setSubsecuencia.bind(this);
      case "um_inicio":
        return this.setUmInicio.bind(this);
      case "nu_inicio":
        return this.setNuInicio.bind(this);
      case "um_duracion":
        return this.setUmDuracion.bind(this);
      case "nu_duracion":
        return this.setNuDuracion.bind(this);
      case "um_convalecencia":
        return this.setUmConvalecencia.bind(this);
      case "nu_convalecencia":
        return this.setNuConvalecencia.bind(this);
      case "vc_observaciones":
        return this.setObservaciones.bind(this);
    }
  }

  getMenu() {
    const { procedimiento, opcionProcedimientos } = this.props;
    let menu = [];

    switch (opcionProcedimientos) {
      case "agregar":
        break;
      default:
        menu = [
          {
            value: "principal",
            vc_nombre: "Principal",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackProcedimiento({
                  id_procedimiento: null,
                  procedimiento,
                  opcionesModuloNext: "principal",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "status",
            vc_nombre: "Estatus",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackProcedimiento({
                  id_procedimiento: null,
                  procedimiento,
                  opcionesModuloNext: "status",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "subsecuencia",
            vc_nombre: "Subsecuencia",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackProcedimiento({
                  id_procedimiento: null,
                  procedimiento,
                  opcionesModuloNext: "subsecuencia",
                  umTiempo: null,
                })
              );
            },
          },

          {
            value: "clasificar",
            vc_nombre: "Clasificar",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackProcedimiento({
                  id_procedimiento: null,
                  procedimiento,
                  opcionesModuloNext: "clasificar",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "duracion",
            vc_nombre: "Duración",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackProcedimiento({
                  id_procedimiento: null,
                  procedimiento,
                  opcionesModuloNext: "um_duracion",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "convalecencia",
            vc_nombre: "Convalecencia",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackProcedimiento({
                  id_procedimiento: null,
                  procedimiento,
                  opcionesModuloNext: "um_convalecencia",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "observaciones",
            vc_nombre: "Observaciones",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackProcedimiento({
                  id_procedimiento: null,
                  procedimiento,
                  opcionesModuloNext: "vc_observaciones",
                  umTiempo: null,
                })
              );
            },
          },
        ];
        break;
    }

    return menu;
  }

  getMenuValue() {
    const { procedimiento, opcionProcedimientos } = this.props;

    switch (opcionProcedimientos) {
      case "agregar":
        return "agregar";
      case "conciliar":
        return "consciliar";
      case "clasificar":
        return "clasificar";
      case "principal":
        return "principal";
      case "status":
        return "status";
      case "subsecuencia":
        return "subsecuencia";
      case "um_inicio":
        return "duracion";
      case "nu_inicio":
        return `duracion`;
      case "um_duracion":
        return "duracion";
      case "nu_duracion":
        return `duracion`;
      case "um_convalecencia":
        return "convalecencia";
      case "nu_convalecencia":
        return `convalecencia`;
      case "vc_observaciones":
        return "observaciones";
    }
  }

  onChangeValue(event) {
    this.setState({ searchText: event.target.value, opcionPreSelected: null });
  }

  pressArrows(e) {
    let opcionPreSelected = this.state.opcionPreSelected;
    switch (e.keyCode) {
      case 38:
        opcionPreSelected = !!opcionPreSelected ? opcionPreSelected - 1 : 0;
        this.setState({ opcionPreSelected });
        break;
      case 40:
        if (opcionPreSelected == null) {
          opcionPreSelected = 0;
        } else if (this.opcionesArray().length > opcionPreSelected + 1) {
          opcionPreSelected = opcionPreSelected + 1;
        }
        this.setState({ opcionPreSelected });
        break;
    }
  }

  modifyEnterKeyPressAsTab(e) {
    if (e.keyCode == 13) {
      const action = this.optionsAction();
      const item = this.opcionesArray()[this.state.opcionPreSelected];
      if (item) {
        action(item);
      } else if (
        this.state.searchText != "" &&
        this.props.opcionProcedimientos == "vc_observaciones"
      ) {
        const item = {
          vc_nombre: this.state.searchText,
          valor: this.state.searchText,
        };
        this.setObservaciones(item, null);
      }
      // else {
      //     const procedimiento = this.addDetalleEdit(this.props.procedimiento);
      //     const indexDetalle = this.props.indexDetalleSeleccionado > -1 ? this.props.indexDetalleSeleccionado : medicamento.detalles.length - 1;
      //     procedimiento.detalles[indexDetalle].vc_observaciones = null;
      //     this.setState({opcionPreSelected: null, searchText: ''}, () => this.props.callbackProcedimiento({ id_procedimiento: null, procedimiento, opcionesModuloNext: 'vc_observaciones', umTiempo: null }));

      //// }
    }
  }

  // ----------------------------
  // ------ item methods --------
  // ----------------------------

  addProcedimiento(item) {
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackProcedimiento({
        id_procedimiento: item.id,
        procedimiento: null,
        opcionesModuloNext: "agregar",
        umTiempo: null,
      })
    );
  }

  addDetalleEdit(procedimiento) {
    const indexLastDetalle = procedimiento.detalles.length - 1;
    if (this.props.procedimiento.detalles[indexLastDetalle].id != null) {
      const detalleAdd = this.props.procedimiento.detalles[indexLastDetalle];
      detalleAdd.id = null;
      detalleAdd.sn_subsecuente = 1;
      detalleAdd.sn_principal = 0;
      detalleAdd.id_notaActual = null;
      detalleAdd.vc_observaciones = null;
      procedimiento.detalles.push(detalleAdd);
      return procedimiento;
    } else {
      return procedimiento;
    }
  }

  setProcedimiento(item) {
    const procedimiento = this.addDetalleEdit(this.props.procedimiento);
    const indexLastDetalle = procedimiento.detalles.length - 1;
    procedimiento.detalles[indexLastDetalle].id_procedimiento = item.id;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackProcedimiento({
        id_procedimiento: null,
        procedimiento,
        opcionesModuloNext: "principal",
        umTiempo: null,
      })
    );
  }

  setCausa(item) {
    const procedimiento = this.addDetalleEdit(this.props.procedimiento);
    const indexLastDetalle = procedimiento.detalles.length - 1;
    procedimiento.detalles[indexLastDetalle].sn_principal = item.valor;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackProcedimiento({
        id_procedimiento: null,
        procedimiento,
        opcionesModuloNext: "status",
        umTiempo: null,
      })
    );
  }

  setStatus(item) {
    const procedimiento = this.addDetalleEdit(this.props.procedimiento);
    const indexLastDetalle = procedimiento.detalles.length - 1;
    procedimiento.detalles[indexLastDetalle].id_estatusProcedimiento =
      item.valor;
    if (procedimiento.id != null) {
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackProcedimiento({
          id_procedimiento: null,
          procedimiento,
          opcionesModuloNext: "um_inicio",
          umTiempo: null,
        })
      );
    } else {
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackProcedimiento({
          id_procedimiento: null,
          procedimiento,
          opcionesModuloNext: "subsecuencia",
          umTiempo: null,
        })
      );
    }
  }

  setSubsecuencia(item) {
    const procedimiento = this.addDetalleEdit(this.props.procedimiento);
    const indexLastDetalle = procedimiento.detalles.length - 1;
    procedimiento.detalles[indexLastDetalle].sn_subsecuente = item.valor;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackProcedimiento({
        id_procedimiento: null,
        procedimiento,
        opcionesModuloNext: "um_inicio",
        umTiempo: null,
      })
    );
  }

  setUmInicio(item) {
    if (item.valor == 34 && this.props.pacienteActual) {
      const procedimiento = this.addDetalleEdit(this.props.procedimiento);
      const indexLastDetalle = procedimiento.detalles.length - 1;
      procedimiento.detalles[
        indexLastDetalle
      ].dt_inicio = this.props.pacienteActual.detalle.dt_nacimiento;
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackProcedimiento({
          id_procedimiento: null,
          procedimiento,
          opcionesModuloNext: "um_duracion",
          umTiempo: null,
        })
      );
    } else {
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackProcedimiento({
          id_procedimiento: null,
          procedimiento: null,
          opcionesModuloNext: "nu_inicio",
          umTiempo: item.valor,
        })
      );
    }
  }

  setNuInicio(item) {
    const milisegundos =
      this.props.unidadesMedida.filter(
        (e, index) => e.id == this.props.umTiempo
      )[0].nu_ratio *
      item.valor *
      60000;
    const timeInterval = moment() - milisegundos;
    const fecha = new Date(timeInterval);
    const dt_inicio = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
    const procedimiento = this.addDetalleEdit(this.props.procedimiento);
    const indexLastDetalle = procedimiento.detalles.length - 1;
    procedimiento.detalles[indexLastDetalle].dt_inicio = dt_inicio;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackProcedimiento({
        procedimiento,
        opcionesModuloNext: "um_duracion",
      })
    );
  }

  setUmDuracion(item) {
    if (item.valor == 34) {
      const procedimiento = this.addDetalleEdit(this.props.procedimiento);
      const indexLastDetalle = procedimiento.detalles.length - 1;
      const dt_inicio = procedimiento.detalles[indexLastDetalle].dt_inicio;
      const timeInterval = moment(dt_inicio) + 3153600000000;
      const fecha = new Date(timeInterval);
      const dt_vigencia = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
      procedimiento.detalles[indexLastDetalle].dt_vigencia = dt_vigencia;
      procedimiento.detalles[indexLastDetalle].id_um_duracion = 34;
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackProcedimiento({
          procedimiento,
          opcionesModuloNext: "um_convalecencia",
        })
      );
    } else {
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackProcedimiento({
          umTiempo: item.valor,
          opcionesModuloNext: "nu_duracion",
        })
      );
    }
  }

  setNuDuracion(item) {
    const milisegundos =
      this.props.unidadesMedida.filter(
        (e, index) => e.id == this.props.umTiempo
      )[0].nu_ratio *
      item.valor *
      60000;
    const procedimiento = this.addDetalleEdit(this.props.procedimiento);
    const indexLastDetalle = procedimiento.detalles.length - 1;
    const dt_inicio = procedimiento.detalles[indexLastDetalle].dt_inicio;
    const timeInterval = moment(dt_inicio) + milisegundos;
    const fecha = new Date(timeInterval);
    const dt_vigencia = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
    procedimiento.detalles[indexLastDetalle].dt_vigencia = dt_vigencia;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackProcedimiento({
        procedimiento,
        opcionesModuloNext: "um_convalecencia",
      })
    );
  }

  setNuConvalecencia(item) {
    const milisegundos =
      this.props.unidadesMedida.filter(
        (e, index) => e.id == this.props.umTiempo
      )[0].nu_ratio *
      item.valor *
      60000;
    const procedimiento = this.addDetalleEdit(this.props.procedimiento);
    const indexLastDetalle = procedimiento.detalles.length - 1;
    const dt_inicio = procedimiento.detalles[indexLastDetalle].dt_inicio;
    const timeInterval = moment(dt_inicio) + milisegundos;
    const fecha = new Date(timeInterval);
    const dt_convalecencia = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
    procedimiento.detalles[
      indexLastDetalle
    ].dt_convalecencia = dt_convalecencia;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackProcedimiento({
        procedimiento,
        opcionesModuloNext: "vc_observaciones",
      })
    );
  }

  setUmConvalecencia(item) {
    if (item.valor == 34) {
      const procedimiento = this.addDetalleEdit(this.props.procedimiento);
      const indexLastDetalle = procedimiento.detalles.length - 1;
      const dt_inicio = procedimiento.detalles[indexLastDetalle].dt_inicio;
      const timeInterval = moment(dt_inicio) + 3153600000000;
      const fecha = new Date(timeInterval);
      const dt_convalecencia = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
      procedimiento.detalles[
        indexLastDetalle
      ].dt_convalecencia = dt_convalecencia;
      procedimiento.detalles[indexLastDetalle].id_um_convalecencia = 34;
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackProcedimiento({
          procedimiento,
          opcionesModuloNext: "vc_observaciones",
        })
      );
    } else {
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackProcedimiento({
          umTiempo: item.valor,
          opcionesModuloNext: "nu_convalecencia",
        })
      );
    }
  }
  f;

  setObservaciones(item) {
    const procedimiento = this.addDetalleEdit(this.props.procedimiento);
    const indexLastDetalle = procedimiento.detalles.length - 1;
    procedimiento.detalles[indexLastDetalle].vc_observaciones = item.valor;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackProcedimiento({
        procedimiento,
        opcionesModuloNext: "agregar",
      })
    );
  }

  hoverIndex(index) {
    this.props.callbackHover({ indexHover: index });
  }
  searchBarEvent(event) {
    let opcionPreSelected = null;
    if (
      this.opcionesArray()
        .filter((e) =>
          e.vc_nombre.toLowerCase().includes(event.target.value.toLowerCase())
        )
        .filter((e, index) => index < 30).length > 0
    ) {
      opcionPreSelected = 0;
    } else {
      opcionPreSelected = null;
    }
    this.setState({ searchText: event.target.value, opcionPreSelected });
  }

  // renderTitleHelpBar() {
  //     const { date,procedimientos, opcionProcedimientos } = this.props;
  //     let um = null;
  //     let umString = null;
  //     switch (opcionProcedimientos) {
  //         case "agregar":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     Agregar
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                     placeholder='Buscarprocedimientos...'
  //                     onChange={this.searchBarEvent.bind(this)}
  //                     style={{ width: '100%', height: 31 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //             break;
  //         case "conciliar":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     Conciliar
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                     focus placeholder='Buscarprocedimientos...'
  //                     onChange={this.searchBarEvent.bind(this)}
  //                     style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "clasificar":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     Clasifique el procedimiento CIE-9
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                         value={this.state.searchText}
  //                         focus placeholder='Buscarprocedimientos...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                         />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "principal":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     Es el procedimiento causa de la ateción
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "status":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     Especifique el estatus
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "subsecuencia":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     ¿Es subsecuente?
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "um_inicio":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     El procedimiento inició hace
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "nu_inicio":
  //         const umInicio = this.props.unidadesMedida.filter((e, index) => e.id == this.props.umTiempo)[0];
  //         const umStringInicio = umInicio.vc_nombre == 'mes' ? `${umInicio.vc_nombre}es` : `${umInicio.vc_nombre}s`;
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     ¿Hace cuantos {umStringInicio} inició el procedimiento?
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "um_duracion":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                 El procedimiento duró ó durará:
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "nu_duracion":
  //         um = this.props.unidadesMedida.filter((e, index) => e.id == this.props.umTiempo)[0];
  //         umString = um.vc_nombre == 'mes' ? `${um.vc_nombre}es` : `${um.vc_nombre}s`;
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                 ¿aproximadamente cuantos {umString.toLowerCase()} duró ó durará el procedimiento?
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "um_convalecencia":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                 La convalecencia duró ó durará:
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "nu_convalecencia":
  //         um = this.props.unidadesMedida.filter((e, index) => e.id == this.props.umTiempo)[0];
  //         umString = um.vc_nombre == 'mes' ? `${um.vc_nombre}es` : `${um.vc_nombre}s`;
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                 ¿aproximadamente cuantos {umString.toLowerCase()} duró ó durará el la convalecencia?
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "vc_observaciones":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     Descripción del Procedimiento:
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Describa...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //     };
  // }

  // renderOpcionesHelpBar() {
  //     const { date,procedimientos, opcionProcedimientos } = this.props;
  //     const lastDetalle = this.props.procedimiento ? this.props.procedimiento.detalles[this.props.procedimiento.detalles.length - 1]: null ;
  //     let opciones = [];
  //     let i = 0;

  //     switch ( opcionProcedimientos) {
  //         case "conciliar":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div key={index} style={styles.containerHelp}>
  //                     <div style={styles.descriptionHelp}>
  //                         {item.dt_registro}
  //                     </div>
  //                     <div style={styles.stickHelp}>
  //                         {/* {date(item.dt_registro, 'dddd D MMMM YYYY')} */}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "clasificar":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setProcedimiento(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                     <div style={styles.stickHelp}>
  //                         {item.vc_cveCIE9}
  //                     </div>
  //                     <div style={styles.line}/>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "principal":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setCausa(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "status":
  //             return (
  //             this.opcionesArray().map((item,index) =>
  //                     <div onClick={() =>  this.setStatus(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                         <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                             {item.vc_nombre}
  //                         </div>
  //                     </div>
  //                 )
  //             );
  //             break;
  //         case "subsecuencia":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setSubsecuencia(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "um_inicio":
  //             return (
  //                 this.opcionesArray().map((item,index) =>
  //                     <div onClick={() =>  this.setUmInicio(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                         <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                             {item.vc_nombre}
  //                         </div>
  //                     </div>
  //                 )
  //             );
  //             break;
  //         case "nu_inicio":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setNuInicio(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "um_duracion":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setUmDuracion(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "nu_duracion":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setNuDuracion(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         case "um_convalecencia":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setUmConvalecencia(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "nu_convalecencia":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setNuConvalecencia(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         case "vc_observaciones":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setState({ preguntaActual: item.valor }) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         default:
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.addProcedimiento(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                     <div style={styles.stickHelp}>
  //                         {item.vc_cveCIE9}
  //                     </div>
  //                     <div style={styles.line}/>
  //                 </div>
  //             )
  //         );
  //         break;
  //     };
  // }

  // isValidSex(id_sexoPaciente,id_sexoPregunta) {
  //     if (id_sexoPaciente == id_sexoPregunta || id_sexoPregunta == 3 ) {
  //         return true;
  //     } else {
  //         return false;
  //     }
  // }
  // isValidAge(nuAgeMinutesPaciente, item) {
  //     const { unidadesMedida } = this.props;
  //     let limiteInferiorAge = null;
  //     let limiteSuperiorAge = null;
  //     if (item.nu_minEdad > 0 && item.id_um_minEdad != null) {
  //         const nuUmBase = this.finder(unidadesMedida, 'id', item.id_um_minEdad).nu_ratio;
  //         limiteInferiorAge = nuUmBase * item.nu_minEdad;
  //     }
  //     if (item.nu_maxEdad > 0 && item.id_um_maxEdad != null) {
  //         const nuUmBase = this.finder(unidadesMedida, 'id', item.id_um_maxEdad).nu_ratio;
  //         limiteSuperiorAge = nuUmBase * item.nu_maxEdad;
  //     }
  //     if (limiteInferiorAge != null && nuAgeMinutesPaciente < limiteInferiorAge) {
  //         return false;
  //     }
  //     if (limiteSuperiorAge != null && nuAgeMinutesPaciente > limiteSuperiorAge) {
  //         return false;
  //     }
  //     return true;
  // }
  // finder(array, key, value) {
  //     const object = array.find((e) => {return e[`${key}`] === value });
  //     if (object) {
  //         return object;
  //     }
  //     return null;
  // }
  // preguntaVersion(pacienteDetalle) {
  //     const { date, PreguntasNumericas, respuestas } = this.props;
  //     const id_sexo = pacienteDetalle.id_sexo;
  //     const nu_edadMinutos = (new Date() - new Date(pacienteDetalle.dt_nacimiento)) / 60000;
  //     let version = null;

  //     this.state.preguntaActual.versiones.map((preguntaVersion, index) => {
  //         if (this.isValidAge(nu_edadMinutos,preguntaVersion) && this.isValidSex(id_sexo, preguntaVersion.id_sexo)) {
  //             version = preguntaVersion;
  //         }
  //     });
  //     return version;
  // }

  // siguientePregunta(pregunta) {
  //     const { preguntas, respuestas, pacienteActual } = this.props;
  //     const preguntaVersion = this.preguntaVersion( pacienteActual.detalle );
  //     const preguntasPendientes = this.state.preguntasPendientes;
  //     const preguntasRespondidas = this.state.preguntasRespondidas;
  //     preguntasRespondidas.push(pregunta);
  //     let preguntaActual = null;

  //     if (preguntaVersion) {
  //         if (preguntaVersion.id_preguntaEnlace != null) {
  //             preguntasPendientes.push(preguntaVersion.id_preguntaEnlace);
  //         }
  //     }
  //     if (pregunta.respuesta) {
  //         if (pregunta.respuesta.id_respuesta) {
  //             const respuesta = respuestas.filter((e) => e.id === pregunta.respuesta.id_respuesta)[0];
  //             if (respuesta.id_preguntaEnlace != null) {
  //                 preguntasPendientes.push(respuesta.id_preguntaEnlace);
  //             }
  //         }
  //     }
  //     if (preguntasPendientes[0]) {
  //         const id_preguntaEnlace = preguntasPendientes.pop();
  //         preguntaActual = preguntas.filter((e) => e.id === id_preguntaEnlace)[0];
  //         this.setState({ preguntaActual, preguntasPendientes, preguntasRespondidas });
  //     } else {
  //         const procedimiento = this.props.procedimiento;
  //         this.setState({opcionPreSelected: null, searchText: '',preguntaActual: null }, () => this.props.callbackProcedimiento({ id_procedimiento: null, procedimiento, opcionesModuloNext: 'agregar', umTiempo: null }));
  //     }
  // }
}

const styles = {
  container: {
    overflowX: "hidden",
    overflowY: "hidden",
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
  },
  containerSub: {
    overflowX: "hidden",
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    width: "100%",
    height: "calc(100% - 111px)",
    margin: 0,
    padding: 0,
    paddingLeft: 1,
    paddingRight: 1,
  },
  containerHelp: {
    margin: 0,
    marginBottom: 2,
    borderRadius: 3,
    overflowY: "hidden",
    padding: 10,
    minHeight: 40,
    color: "black",
    backgroundColor: window.colors.white,
  },
  containerSelectedHelp: {
    margin: 0,
    marginBottom: 2,
    borderRadius: 3,
    overflowY: "hidden",
    padding: 10,
    minHeight: 40,
    marginBottom: 2,
    color: "white",
    backgroundColor: window.colors.itemSelected,
  },
  descriptionHelp: {
    margin: 0,
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "left",
    fontSize: 14,
    minHeight: 40,
    color: "gray",
  },
  descriptionSelectedHelp: {
    margin: 0,
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "left",
    fontSize: 14,
    minHeight: 40,
    color: "white",
  },
  stickHelp: {
    margin: 0,
    marginLeft: "85%",
    marginBottom: 2,
    width: 30,
    overflowY: "hidden",
    paddingTop: 0,
    textAlign: "left",
    float: "left",
    fontSize: 12,
    color: window.colors.itemSelected,
  },
  subTitleHelp: {
    margin: 0,
    marginLeft: 10,
    marginRight: 10,
    width: "100%",
    overflowY: "scroll",
    paddingTop: 0,
    textAlign: "left",
    float: "left",
    fontSize: 12,
    color: "black",
  },
  subTitleRightHelp: {
    margin: 0,
    marginLeft: 10,
    marginRight: 10,
    width: "100%",
    paddingTop: 0,
    textAlign: "right",
    float: "right",
    fontSize: 12,
    color: window.colors.itemSelected,
  },
  titleHelp: {
    width: "100%",
    padding: 3,
    height: 37,
    color: "white",
  },
};

const mapStateToProps = (state) => {
  const {
    procedimientos,
    unidadesMedida,
    pacienteActual,
    preguntas,
    respuestas,
  } = state.Think;
  return {
    procedimientos,
    unidadesMedida,
    pacienteActual,
    preguntas,
    respuestas,
  };
};

export default connect(mapStateToProps)(ProcedimientosHelpBar);

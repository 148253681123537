import React from "react";

import { connect } from "react-redux";
import { Box, Button } from "@material-ui/core";

import ConfirmAlert from "app/material-ui/ConfirmAlert";
import SubMenuModulos from "app/material-ui/SubMenuModulos";

import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "app/containers/Think/actions";
import Table from "app/sales/components/CashRegister/AccountsTable";
import Sidebar from "app/sales/components/Cuentas/Sidebar";

import useAgregarCuenta from "app/sales/hooks/useAgregarCuenta";
import useCuenta from "app/sales/hooks/useCuenta";
import useRowSelected from "app/sales/hooks/useRowSelected";
import useApi from "app/sales/hooks/useApi";

import _ from "lodash";

const EliminarCuenta = ({ open, data, onAccept, onCancel }) => {
  const { client } = data || {};
  return open ? (
    <ConfirmAlert
      title={`¿Desea eliminar la cuenta del cliente: ${client.vc_nombre} ${client.vc_apellidoPaterno} ${client.vc_apellidoMaterno}?`}
      onAccept={onAccept}
      onCancel={onCancel}
    />
  ) : null;
};

const Index = (props) => {
  const [opcion, setOpcion] = React.useState("accountsDay");
  const [data, setData] = React.useState([]);
  const [searchText, setSearchText] = React.useState([]);

  const { rowSelected, onSelect } = useRowSelected();
  const { CuentaDialog, onOpen: cuentaOpen } = useCuenta();

  const { AgregarCuentaDialog, onOpen: agregarCuentaOpen } = useAgregarCuenta({
    onAccept: cuenta => {
      cuentaOpen(cuenta);
    },
  });

  const api = useApi("session/clientes");

  React.useEffect(() => {
    (async () => {
      const data = await api.get({
        limit: 10000,
        page: 1
      });

      setData(data.items);
    })();
  }, []);

  // Sidebar
  React.useEffect(() => {
    props.setHelperSidebarRender(() =>
      rowSelected.row ? (
        <Sidebar
          cuenta={rowSelected.row}
          revisar={() => cuentaOpen(rowSelected.row)}
          eliminar={() => {}}
        />
      ) : null
    );
    props.setDisplaySidebar(!!rowSelected.row);
  }, [rowSelected]);

  React.useEffect(() => {
    if (searchText.length>3) {
      console.log(searchText);
    }
  }, [searchText]);

  return (
    <Box>
      <SubMenuModulos
        type={"search"}
        searchText={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        rightComponent={
          <Button color="inherit" onClick={() => agregarCuentaOpen()}>
            Agregar cuenta
          </Button>
        }
      />
      <Box>
        <Table data={data} onClick={onSelect} selected={rowSelected.row} />
      </Box>

      {/* <AbrirCajaDialog />
      <CerrarCajaDialog /> */}
      <AgregarCuentaDialog />
      <CuentaDialog />
    </Box>
  );
};

const actions = { setHelperSidebarRender, setDisplaySidebar };
const mapStateToProps = () => ({});

export default connect(mapStateToProps, actions)(Index);

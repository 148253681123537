import React from 'react';
import { Grid, Button, Typography, Box, Paper } from '@material-ui/core';
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Backspace from "@material-ui/icons/Backspace";
import SubdirectoryArrowLeft from "@material-ui/icons/SubdirectoryArrowLeft";

class ThinkNumberKeyboard extends React.Component {
    constructor() {
        super();
        this.state = {
            someKey: 'someValue'
        };
    }
    render() {
        const keys = [
            { keyCode: '7', key: '7' },
            { keyCode: '8', key: '8' },
            { keyCode: '9', key: '9' },
            { keyCode: 37, key: 'ArrowLeft', icon: <ArrowBack /> },
            { keyCode: '4', key: '4' },
            { keyCode: '5', key: '5' },
            { keyCode: '6', key: '6' },
            { keyCode: 39, key: 'ArrowRight', icon: <ArrowForward /> },
            { keyCode: '1', key: '1' },
            { keyCode: '2', key: '2' },
            { keyCode: '3', key: '3' },
            { keyCode: 8, key: 'Backspace', icon: <Backspace /> },
            { keyCode: '0', key: '0' },
            { keyCode: '.', key: '.' },
            { keyCode: 13, key: 'Enter', icon: <SubdirectoryArrowLeft /> }
        ]
        console.log('this', this)
        const heightItem = ((window.sidebarWidth - 16) / 4) * 0.8;
        return (
            <Box p={1} style={{ backgroundColor: 'rgba(200,200,200, 0.1)' }}>
                <Paper style={{ padding: 8, padding: 0, borderRadius: 10 }}>
                    <Grid id={'ThinkNumberKeyboard'} spacing={1} container>
                        <Grid key={'numbers'} item container xs={12}>
                            {
                                keys.map((item, index) => {
                                    const isFuntionType = item.key.length !== 1;
                                    return (
                                        <Grid key={index} item xs={item.keyCode === '0' ? 6 : 3} style={{ height: (heightItem) }}>
                                            <Box style={{ width: '100%', height: heightItem, padding: 5 }}>
                                                <Button color={isFuntionType ? 'primary' : 'inherit'} variant={isFuntionType ? 'contained' : 'text'} onClick={() => this.props.pressDown(item)} style={{ height: 'calc(100% - 3px)', width: item.key === '0' ? '100%' : 'calc(100% - 10px)', borderRadius: item.key === '0' ? heightItem / 2 : '50%', backgroundColor: item.key.length === 1 ? 'rgba(200,200,200,0.1)' : null, boxShadow: '0px 1px 3px rgba(0,0,0,0.5)' }}>
                                                    {
                                                        item.icon ||
                                                        <Typography variant='h5'>
                                                            {item.keyCode}
                                                        </Typography>
                                                    }
                                                </Button>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        {/* <Grid key={'funtions'} item xs={3}>
                            <Grid key={'back'} item xs={12} style={{ padding: 12 }}>
                                <Button onClick={() => this.props.pressDown({ keyCode: 37, key: 'ArrowLeft' })} variant='contained' color={'primary'} fullWidth style={{ height: (heightItem) - 0, width: '100%', borderRadius: '50%', boxShadow: '0px 1px 3px rgba(0,0,0,0.5)' }}>
                                    <ArrowBack />
                                </Button>
                            </Grid>
                            <Grid key={'fow'} item xs={12} style={{ padding: 12 }}>
                                <Button onClick={() => this.props.pressDown({ keyCode: 39, key: 'ArrowRight' })} variant='contained' color={'primary'} fullWidth style={{ height: (heightItem) - 0, width: '100%', borderRadius: '50%', boxShadow: '0px 1px 3px rgba(0,0,0,0.5)' }}>
                                    <ArrowForward />
                                </Button>
                            </Grid>
                            <Grid key={'backspace'} item xs={12} style={{ padding: 12 }}>
                                <Button onClick={() => this.props.pressDown({ keyCode: 8, key: 'Backspace' })} variant='contained' color={'primary'} fullWidth style={{ height: (heightItem) - 0, width: '100%', borderRadius: '50%', boxShadow: '0px 1px 3px rgba(0,0,0,0.5)' }}>
                                    <Backspace />
                                </Button>
                            </Grid>
                            <Grid key={'enter'} item xs={12} style={{ padding: 12 }}>
                                <Button onClick={() => this.props.pressDown({ keyCode: 13, key: 'Enter' })} variant='contained' color={'primary'} fullWidth style={{ height: (heightItem) - 0, width: '100%', borderRadius: '50%', boxShadow: '0px 1px 3px rgba(0,0,0,0.5)' }}>
                                    <SubdirectoryArrowLeft />
                                </Button>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Paper>
            </Box>
        )
    }

    componentDidMount() {

    }
}

export default ThinkNumberKeyboard;

import React from "react";
import PropTypes from "prop-types";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogContentText,
  Box,
} from "@material-ui/core";

import Switch from "@material-ui/core/Switch";

const Index = (props) => {
  const { formularios, onClick, onChange } = props;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <DialogContentText>Seleccionar el formulario.</DialogContentText>
      <Box height="0px" overflow="scroll" flexGrow={1}>
        <List>
          {formularios.map((f, i) => (
            <ListItem
              alignItems="flex-start"
              key={i}
              button
              onClick={() => onClick(f)}
            >
              <ListItemText primary={f.nombre} />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  color="primary"
                  onChange={() => onChange(f)}
                  checked={f.activo}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

Index.defaultProps = {
  formularios: [],
  onClick: () => {},
  onChange: () => {},
};

Index.propTypes = {
  formularios: PropTypes.array,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

export default Index;

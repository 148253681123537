import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  login: {
    backgroundImage: "url(assets/images/background.png)",
    backgroundSize: "40vh 100vh",
    backgroundRepeat: "repeat",
    "&::before": {
      content: '" "',
      backgroundImage: "linear-gradient(#000000d9,#000000a8)",
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
    },

    "& .logo-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-end",
      },

      "& .logo": {
        width: 200,
        height: 200,
        backgroundImage: "url(assets/images/iconThin-k.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        filter: "grayscale(100%)",
      },
    },

    "& .form-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },

      "& .form": {
        maxWidth: "350px",
        "& > *": {
          marginBottom: theme.spacing(2),
        },
        "& > .MuiButton-root": {
          border: "1px solid #fff",
          color: "#fff",
          padding: theme.spacing(1.5),
          borderRadius: 10,
          "&:hover": {
            backgroundColor: "rgba(200,200,200,0.5)",
          },
        },
        "& > .MuiInput-root": {
          padding: 0,
          backgroundColor: "rgba(200,200,200,0.2)",
          borderRadius: 10,
          "& > input": {
            border: "1px solid rgba(200,200,200,0.5)",
            padding: theme.spacing(2, 1),
            borderRadius: 10,
            color: "#fff !important",
            opacity: "1 !important",
            "&:focus": {
              borderColor: "white",
            },
          },
        },
        "& .extra .MuiButton-root": { color: "white" },
      },
    },
  },
}));

import React from 'react'
import ContentEditable from 'react-contenteditable'
 
class ContentEditableDiv extends React.Component {
  constructor() {
    super()
    this.contentEditable = React.createRef();
    this.state = {html: "<b>Hello <i>World</i></b>"};
  };
 
  handleChange = evt => {
      console.log('event', evt.target)
      const inputs = []
      evt.target.value.split('</i>').map((item, index) => {
          const input = {sentence: item.split('}>').pop()}
          inputs.push(input);
      })
    this.props.callbackPregunta({state: {}, inputs});
  };
  renderContentHTML() {
    let inputs = this.props.pregunta.respuestaStruct || [
        {
            sentence: 'hola,'
        },
        {
            id_pregunta: 1,
            sentence: ' respuesta del componente,'
        },
    ];
    let html = '<b>'
    inputs.map((input, index) => {
        const tag = `<i key='${index}' contentEditable={${!input.id_pregunta ? true : false}}>`
        html = html + `${tag}${input.sentence}</i>`
    })
    html = html + '</br>'
    return html;
  }
  render = () => {
    return <ContentEditable
              innerRef={this.contentEditable}
              html={this.renderContentHTML()} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onChange={this.handleChange} // handle innerHTML change
              tagName='article' // Use a custom HTML tag (uses a div by default)
              style={{width: '100%', minHeight: 200, padding: 15, backgroundColor: 'white', borderRadius: 5, overflow: 'scroll', WebkitOverflowScrolling: 'touch'}}
            />
  };
};

export default (ContentEditableDiv);
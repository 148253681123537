import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import notifications from "../../utils/notifications";
import { menuStatistics as menu } from "../../containers/Think/listados";
import { Box } from "@material-ui/core";

import Reports from "./Reports";

const getContent = (moduloActual) => {
  switch (moduloActual) {
    case "reportes": {
      return <Reports />;
    }
    default:
      return <Box />;
  }
};

const index = (props) => {
  const [moduloActual, setModuloActual] = React.useState("reportes");
  const [content, setContent] = React.useState(null);
  const isLogged = window.amplify.store("token") != null;

  const modulo = menu.find((v) => v.id === moduloActual);

  React.useEffect(() => {
    const options = menu.map((option) => ({
      ...option,
      onClick: () => {
        if (isLogged) {
          props.setViewTitle(option.vc_nombre);
          setModuloActual(option.id);
        } else {
          notifications.info(
            "¡Registrate con un plan para acceder a todas las herramientas!"
          );
        }
      },
    }));

    props.setSubMenuOptions(options);
    props.setActiveView("reportes");
  }, []);

  const changeModulo = (modulo) => {
    setModuloActual(modulo);
    props.setActiveView(modulo);
  };

  React.useEffect(() => {
    setContent(
      React.cloneElement(getContent(moduloActual), {
        moduloActual,
        modulo,
        changeModulo,
      })
    );
  }, [moduloActual]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      bgcolor="rgba(200, 200, 200, 0.2)"
    >
      {content}
    </Box>
  );
};

index.propTypes = {
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  navigate: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const actions = {};

export default connect(mapStateToProps, actions)(index);

import React from "react";
import moment from "moment";

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Divider,
} from "@material-ui/core";
import Calendar from "app/agenda/components/Calendar";
import StatusFilter from "./StatusFilter";
import api from "app/agenda/api";

const Index = (props) => {
  const {
    opcion = "agenda",
    date = "",
    dates = [],
    data = {},
    onChangeDate = () => {},
    onStatusChange = () => {},
    addMeeting = () => {},
    estatus = [],
    agendaId,
  } = props;

  const [upcomings, setUpcomings] = React.useState([]);

  React.useEffect(() => {
    if (opcion !== "citas" && agendaId) {
      api
        .upcomings({ id_agenda: agendaId, dt_fecha: date, nu_proximas: 10 })
        .then((res) => {
          setUpcomings(res);
        });
    }
  }, [date, agendaId]);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <AppBar
        position="relative"
        style={{
          height: 95,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Typography style={{ textAlign: "center", width: "100%" }}>
            Calendario
          </Typography>
        </Toolbar>
      </AppBar>
      <Box flex={1} display="flex" flexDirection="column">
        <Calendar
          data={data.month}
          date={date}
          dates={dates}
          onChangeDate={onChangeDate}
        />
        {opcion !== "citas" && (
          <Box display="flex" flexDirection="column" flex="1">
            <AppBar
              position="static"
              color="primary"
              style={{ height: 40, zIndex: 1 }}
            >
              <Toolbar color="primary" style={{ height: 40, minHeight: 40 }}>
                <Typography style={{ textAlign: "center", width: "100%" }}>
                  Turnos disponibles
                </Typography>
              </Toolbar>
            </AppBar>
            <Box height="0px" flex="1 1 auto" overflow="scroll">
              {upcomings
                .map((v) => ({
                  ...v,
                  label: moment(v.tm_inicio).format(
                    "hh:mm a DD [de] MMM [de] YYYY"
                  ),
                }))
                .map((v, i) => [
                  <Button
                    fullWidth
                    key={i}
                    onClick={() => addMeeting(v)}
                    size="large"
                  >
                    {v.label}
                  </Button>,
                  <Divider key={`divider-${i}`} />,
                ])}
            </Box>
          </Box>
        )}

        {opcion === "citas" && [
          <AppBar
            position="static"
            color="primary"
            style={{ height: 40, zIndex: 1 }}
          >
            <Toolbar color="primary" style={{ height: 40, minHeight: 40 }}>
              <Typography style={{ textAlign: "center", width: "100%" }}>
                Filtro de citas
              </Typography>
            </Toolbar>
          </AppBar>,
          <StatusFilter onStatusChange={onStatusChange} status={estatus} />,
        ]}
      </Box>
    </Box>
  );
};

export default Index;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  timeline: {
    padding: 0,
    "& .MuiTimelineItem-root:last-child": {
      "& .MuiTimelineConnector-root": {
        display: "none",
      },
    },
    "& .MuiTimelineItem-root:before": {
      flex: 0,
      content: "",
      padding: 0,
    },
    "& .MuiTimelineSeparator-root": {
      minWidth: "30px",
      "& .MuiTimelineDot-root": {
        alignSelf: "center",
      },
    },
  },
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

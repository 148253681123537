import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import MoreVert from '@material-ui/icons/MoreVert';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import moment from 'moment';
import { Avatar, IconButton } from '@material-ui/core';
import * as firebase from 'firebase/app';
import internalAPI from '../utils/internalAPI';
import notifications from '../utils/notifications';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        borderTop: `2px solid ${window.colors.itemSelected}`
    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightMedium,
        alignSelf: 'center',
        marginLeft: 30
    },
    headingMobile: {
        fontSize: theme.typography.pxToRem(13),
        fontWeight: theme.typography.fontWeightLight,
        alignSelf: 'center',
        marginLeft: 30
    },
    header: {
        borderBottom: `2px solid ${theme.palette.primary}`,
        alignItems: 'left',
        flexDirection: 'row'

    },
    button: {
        backgroundColor: theme.palette.primary[500],
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary[700],
            color: 'white'
        }
    },
    input: {
        display: 'none',
    }
}));
const pacienteMuestra = {
    detalle: {
        id_sexo: 1,
        dt_nacimiento: '01-01-2000',
        vc_nombre: 'Sergio Emmanuel',
        vc_apellidoPaterno: 'Delgado',
        vc_apellidoMaterno: 'Félix'
    }
}
const uploadFile = (blob, id_paciente, editPatient) => {
    const timeStamp = moment() * 1;
    const storage = firebase.storage().ref()
    let ref = storage.child(`images/ins_pacientes/${id_paciente}/${timeStamp}.${blob.type.split('/').pop()}`);
    let uploadTask = ref.put(blob)
    uploadTask.on('state_changed', function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'

                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'

                break;
        }
    }, function (error) {
        // console.log('error')
    }, function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            const body = {
                id: id_paciente,
                vc_url: downloadURL
            }
            internalAPI.editPatientPhoto(body, (error, response) => {
                if (error) {
                    notifications.error(`Error: ${error.texto}`);
                } else if (response.texto) {
                    notifications.success(response.texto);
                    editPatient && editPatient({vc_url: downloadURL});
                }
            });
        });
    });
}

export default function UploadPicture(props) {
    let { id_paciente, editPatient } = props;
    const classes = useStyles();
    if (!id_paciente) return <div />;
    return (
        <div>
            <input accept="image/*" size={50000} className={classes.input} id="icon-button-file" type="file" onChange={(e) => {
                if (e.target.files) {
                    const blob = new File(e.target.files, 'vc_url', {
                        type: e.target.files[0].type
                    });
                    uploadFile(blob, id_paciente, editPatient);
                }
            }} />
            <label htmlFor="icon-button-file">
                <IconButton
                    color="primary"
                    className={classes.button}
                    aria-label="upload picture"
                    component="span"
                >
                    <PhotoCamera />
                </IconButton>
            </label>
        </div>
    );
}

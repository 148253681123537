import React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from "@material-ui/core";
import Icon from "app/sales/components/Common/Icon";
import Input from "app/components/Form/Input";

const Index = (props) => {
  const { open, onClose, onAccept, institucion } = props;

  const [disabled, setDisabled] = React.useState(false);
  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    if (!password || (password && password.length !== 6)) setDisabled(true);
    else setDisabled(false);
  }, [password]);

  return (
    <Dialog open={open} keepMounted fullWidth maxWidth={"xs"}>
      <DialogTitle>Inicio de sesión</DialogTitle>
      <DialogContent>
        <Grid key="institucion" container>
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <Icon icon="ApartmentOutlined" />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            <DialogContentText>{institucion}</DialogContentText>
          </Grid>
        </Grid>
        <Grid key="password" container>
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <Icon icon="Lock" />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            <Input
              number
              inputProps={{
                autoComplete: "new-password",
                type: "password",
                maxLength: 6,
                thousandSeparator: false,
              }}
              placeholder="Contraseña"
              value={password}
              onChange={setPassword}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={onClose} variant="outlined" style={{ marginLeft: 8 }}>
          Cancelar
        </Button> */}
        <Button
          disabled={disabled}
          variant="contained"
          onClick={() => onAccept(password)}
          color="primary"
          style={{ marginLeft: 8 }}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Index;

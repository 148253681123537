import React, { useEffect, useState } from 'react'
import { Box, Paper, Typography, ListItem } from '@material-ui/core';
import SubMenuModulos from '../../../material-ui/SubMenuModulos';
import QRReader from '../../../material-ui/QRReader';

const ListadoProductos = ({ opciones = [], acciones = [] }) => {
    const optionsDefault = [
        { id: 1, vc_nombre: 'Consulta medica', vc_descripcion: 'consulta de medicina general', nu_precio: 300 },
        { id: 2, vc_nombre: 'Consulta medica de especialidad', vc_descripcion: 'consulta de medicina de especialidad', nu_precio: 300 }
    ]
    const [options, setOptions] = useState(optionsDefault || []);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const searchItems = (e) => {
        setSearchText(e.target.value);
        setLoading(true);

    }
    const getProductos = () => {

    }
    return (
        <Box p={2}>
            {
                options.map((item, index) => {
                    return (
                        <Paper key={index} style={{ marginBottom: 10, overflow: 'hidden' }}>
                            <ListItem button>
                                <Box style={{ fontWeight: 600, width: '100%' }}>
                                    <Typography color={'primary'}>
                                        {item.vc_nombre}
                                    </Typography>
                                </Box>
                                <Box style={{ width: '100%' }}>
                                    <Typography>
                                        {item.vc_descripcion}
                                    </Typography>
                                </Box>
                            </ListItem>
                        </Paper>
                    )
                })
            }
        </Box>
    )
};
export default (ListadoProductos);
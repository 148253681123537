import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { setHelperSidebarRender, resetHelperSidebarRender, setState, setDisplaySidebar } from '../../../containers/Think/actions';
import { Paper, Typography, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import UsuarioBar from './UsuarioBar';
import ExpansionPanel from '../../../material-ui/ExpansionPanel';
import AsyncSearch from '../../../material-ui/ThinkForm/SearchSelect';
import ConfirmAlert from '../../../material-ui/ConfirmAlert';
import TextField from '../form/TextField';
import Select from '../form/Select';
import internalAPI from '../../../utils/internalAPI';
import SideBar from './SideBar';
import { required } from '../validations';
import { isArray } from 'util';
import ThinkForm from '../../../material-ui/ThinkForm';
const modulo = 'settings';

const styles = theme => ({
  boxFieldset: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    flexGrow: 1
  }
});

const TitleFieldset = props => {
  const { title, fields, index, classes } = props;
  const [confirm, setConfirm] = React.useState(false);

  return (
    <React.Fragment>
      {confirm && (
        <ConfirmAlert
          title={`¿Desea eliminar ${title}?`}
          onAccept={() => {
            fields.remove(index);
          }}
          onCancel={(e) => {
            e.stopPropagation();
            setConfirm(false);
          }}
        />
      )}

      <Typography className={classes.heading}>
        {title}
      </Typography>
      <IconButton onClick={e => {
        e.stopPropagation();
        setConfirm(true);
      }}>
        <RemoveCircleIcon />
      </IconButton>
    </React.Fragment>
  );
}

class UsuarioForm extends React.Component {
  constructor(props) {
    super(props);

    const { agendas = [] } = window.amplify.store('institution') || [];

    let data = { roles: [], agendas: [] };
    let loaded = false;

    if (props.data) {
      console.log('llego el usuario', props.data.id_usuarioGlobal || props.data.detalle.id_usuarioGlobal)
      data = {
        ...props.data.detalle,
        id_institucion: props.data.id_institucion,
        id_usuarioGlobal: props.data.id_usuarioGlobal || props.data.detalle.id_usuarioGlobal,
        sn_activo: props.data.sn_activo,
        id: props.data.id,
        agendas: props.data.agendas || [],
        roles: props.data.roles.slice()
      };

      loaded = true;
    }

    this.state = { data, roles: [], agendas, licencias: [], loaded };
    this.onAdd = this.onAdd.bind(this);
    this.back = this.back.bind(this);

    this.renderFieldsetPersonales = this.renderFieldsetPersonales.bind(this);
    this.renderFieldsetRoles = this.renderFieldsetRoles.bind(this);
    this.renderFieldsetAgendas = this.renderFieldsetAgendas.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    internalAPI.getRoles((err, roles) => {
      const { id_usuarioGlobal } = this.state.data;

      internalAPI.getLicencias(id_usuarioGlobal, (err, res) => {
        let licencias = [];
        console.log('licencias', res);
        if (isArray(res)) {
          licencias = res;
        }
        const dataRoles = this.state.data.roles.map(rol => {
          const licencia = licencias.find(licencia => licencia.LicenciaProducto.rolId === rol.id_rol && licencia.LicenciasUsuarioAsignados.length > 0);

          if (licencia) {
            return Object.assign({}, rol, { id_licencia: licencia.id });
          }

          return rol;
        });

        this.setState({
          roles,
          licencias,
          data: Object.assign({}, this.state.data, { roles: dataRoles })
        });
      });
    });

    if (!this.props.data) {
      this.props.setHelperSidebarRender(() => <SideBar typeRender="search" onAdd={this.onAdd} />);
      this.props.setDisplaySidebar(true);

      return;
    }
  }

  componentWillUnmount() {
    this.props.setHelperSidebarRender(() => null);
    this.props.setDisplaySidebar(false);
  }

  onSubmit(values, formApi) {
    console.log('intento guardar')
    internalAPI.guardarUsuarioInstitucion(values, response => {
      this.back();
    });
  }

  onAdd(user) {
    let data = {};
    if (user.id_institucion) {
      data = {
        ...user.detalle,
        id: user.id,
        id_institucion: user.id_institucion,
        id_usuarioGlobal: user.id_usuarioGlobal,
        sn_activo: user.sn_activo,
        agendas: user.agendas,
        roles: user.roles.slice()
      };
    } else {
      data = {
        id_institucion: window.amplify.store('institution').id_institucion,
        id_usuarioGlobal: user.id,
        sn_activo: 1,
        ...user.detalle,
        agendas: [],
        roles: []
      };
    }

    this.setState({ data, loaded: true }, () => {
      this.props.setDisplaySidebar(false);
    });
  }

  back() {
    this.props.setOpcion('listadoUsuarios');
  }

  renderFieldsetPersonales() {
    return (
      <Box p={1}>
        <Field
          name="vc_nombre"
          component={TextField}
          label="Nombre"
          type="text"
          required
          validate={required}
        />

        <Field
          name="vc_apellidoPaterno"
          component={TextField}
          label="Apellido paterno"
          type="text"
          required
          validate={required}
        />

        <Field
          name="vc_apellidoMaterno"
          component={TextField}
          label="Apellido materno"
          type="text"
          required
          validate={required}
        />
      </Box>
    );
  }

  renderFieldsetRoles({ push, pop, setValue }) {
    const { classes, rolesConf } = this.props;
    const { roles } = this.state;
    const optionsRoles = roles.map(rol => ({ label: rol.vc_nombre, value: rol.id }));
    const optionsFormacionAcademica = this.props.formacionAcademica.map(formacionAcademica => ({
      label: formacionAcademica.vc_nombre,
      value: formacionAcademica.id
    }));

    const optionsEspecialidades = this.props.especialidades.map(especialidad => ({
      label: especialidad.vc_nombre,
      value: especialidad.id
    }));

    return (
      <Box p={1} width="100%">
        <FieldArray name="roles">
          {({ fields, meta: { data } }) => {
            const { value } = fields;

            return fields.map((name, index) => {
              if (Boolean(value[index].sn_eliminado)) {
                return null;
              }

              const rol = roles.find(rol => rol.id === value[index].id_rol) || {};
              console.log(this.state.licencias)
              const optionsLicencias = (this.state.licencias || []).filter(licencia => {
                return licencia.LicenciaProducto.rolId === rol.id;
              }).map(licencia => ({
                label: `${licencia.id} - ${licencia.LicenciaProducto.Producto.descripcion} - ${licencia.vigencia}`,
                value: licencia.id
              }));

              const rolConf = rolesConf.find(rolConf => String(rolConf.id) === String(rol.id)) || {};

              const headerRol = (
                <TitleFieldset
                  classes={classes}
                  title={Boolean(rol.id) ? rol.vc_nombre : 'Nuevo rol'}
                  fields={fields}
                  index={index}
                />
              );

              let rolesSeleccionados = value.filter(item => item.id && item.id !== rol.id);

              if (rolesSeleccionados.length > 0) {
                rolesSeleccionados = rolesSeleccionados.map(item => item.id);
              }

              const optionsSelectables = optionsRoles.filter(
                item => rolesSeleccionados.indexOf(item.id) === -1
              );

              return (
                <ExpansionPanel
                  key={index}
                  header={headerRol}
                  defaultExpanded={index === 0}
                  description={
                    <Box p={1} width="100%" className={classes.boxFieldset}>
                      <Field
                        name={`${name}.id_usuario`}
                        component={() => null}
                      />

                      <Field
                        name={`${name}.id_rol`}
                        component={Select}
                        label="Rol"
                        options={optionsSelectables}
                        required
                        validate={required}
                        onChange={e => {
                          setValue(`${name}.id_licencia`, 0);
                          setValue(`${name}.id_formacion`, 0);
                          setValue(`${name}.id_especialidad`, 0);
                          setValue(`${name}.vc_cedulaProfesional`, '');
                          setValue(`${name}.vc_registroSSA`, '');
                          setValue(`${name}.vc_cedulaProfesional`, '');
                        }}
                      />

                      <Field name={`${name}.id_rol`} subscription={{ value: true }}>
                        {({ input: { value } }) => {
                          let propsField = {};

                          if (Boolean(rolConf.id_licencia)) {
                            if (
                              rolConf.id_licencia.hasOwnProperty('display')
                              &&
                              !Boolean(rolConf.id_licencia.display)
                            ) {
                              return null;
                            }

                            if (optionsLicencias.length !== 0) {
                              propsField.validate = required;
                              propsField.required = true;
                            } else {
                              propsField.disabled = true;
                            }
                          }

                          return (
                            <Field
                              name={`${name}.id_licencia`}
                              component={Select}
                              label="Licencia"
                              options={optionsLicencias}
                              {...propsField}
                            />
                          );
                        }}
                      </Field>

                      <Field name={`${name}.id_rol`} subscription={{ value: true }}>
                        {({ input: { value } }) => {
                          let propsField = {};

                          if (Boolean(rolConf.id_formacion)) {
                            if (
                              rolConf.id_formacion.hasOwnProperty('display')
                              &&
                              !Boolean(rolConf.id_formacion.display)
                            ) {
                              return null;
                            }

                            if (!Boolean(rolConf.id_formacion.nullify)) {
                              propsField.validate = required;
                              propsField.required = true;
                            }
                          }

                          return (
                            <Field
                              name={`${name}.id_formacion`}
                              component={AsyncSearch}
                              label="Formación académica"
                              options={optionsFormacionAcademica}
                              {...propsField}
                            />
                          );
                        }}
                      </Field>

                      <Field name={`${name}.id_rol`} subscription={{ value: true }}>
                        {({ input: { value } }) => {
                          let propsField = {};

                          if (Boolean(rolConf.id_especialidad)) {
                            if (
                              rolConf.id_especialidad.hasOwnProperty('display')
                              &&
                              !Boolean(rolConf.id_especialidad.display)
                            ) {
                              return null;
                            }

                            if (!Boolean(rolConf.id_especialidad.nullify)) {
                              propsField.validate = required;
                              propsField.required = true;
                            }
                          }

                          return (
                            <Field
                              name={`${name}.id_especialidad`}
                              component={AsyncSearch}
                              label="Especialidad"
                              options={optionsEspecialidades}
                              {...propsField}
                            />
                          );
                        }}
                      </Field>

                      <Field name={`${name}.id_rol`} subscription={{ value: true }}>
                        {({ input: { value } }) => {
                          let propsField = {};

                          if (Boolean(rolConf.vc_cedulaProfesional)) {
                            if (
                              rolConf.vc_cedulaProfesional.hasOwnProperty('display')
                              &&
                              !Boolean(rolConf.vc_cedulaProfesional.display)
                            ) {
                              return null;
                            }

                            if (!Boolean(rolConf.vc_cedulaProfesional.nullify)) {
                              propsField.validate = required;
                              propsField.required = true;
                            }
                          }

                          return (
                            <Field
                              name={`${name}.vc_cedulaProfesional`}
                              component={TextField}
                              label="Cédula profesional"
                              type="text"
                              {...propsField}
                            />
                          );
                        }}
                      </Field>

                      <Field name={`${name}.id_rol`} subscription={{ value: true }}>
                        {({ input: { value } }) => {
                          let propsField = {};

                          if (Boolean(rolConf.vc_registroSSA)) {
                            if (
                              rolConf.vc_registroSSA.hasOwnProperty('display')
                              &&
                              !Boolean(rolConf.vc_registroSSA.display)
                            ) {
                              return null;
                            }

                            if (!Boolean(rolConf.vc_registroSSA.nullify)) {
                              propsField.validate = required;
                              propsField.required = true;
                            }
                          }

                          return (
                            <Field
                              name={`${name}.vc_registroSSA`}
                              component={TextField}
                              label="Registro SSA"
                              type="text"
                              {...propsField}
                            />
                          );
                        }}
                      </Field>

                      <Field name={`${name}.id_rol`} subscription={{ value: true }}>
                        {({ input: { value } }) => {
                          let propsField = {};

                          if (Boolean(rolConf.vc_institucionAval)) {
                            if (
                              rolConf.vc_institucionAval.hasOwnProperty('display')
                              &&
                              !Boolean(rolConf.vc_institucionAval.display)
                            ) {
                              return null;
                            }

                            if (!Boolean(rolConf.vc_institucionAval.nullify)) {
                              propsField.validate = required;
                              propsField.required = true;
                            }
                          }

                          return (
                            <Field
                              name={`${name}.vc_institucionAval`}
                              component={TextField}
                              label="Institución aval"
                              type="text"
                              {...propsField}
                            />
                          );
                        }}
                      </Field>

                      <Field name={`${name}.id_rol`} subscription={{ value: true }}>
                        {({ input: { value } }) => {
                          let propsField = {};

                          if (Boolean(rolConf.vc_direccionReceta)) {
                            if (
                              rolConf.vc_direccionReceta.hasOwnProperty('display')
                              &&
                              !Boolean(rolConf.vc_direccionReceta.display)
                            ) {
                              return null;
                            }

                            if (!Boolean(rolConf.vc_direccionReceta.nullify)) {
                              propsField.validate = required;
                              propsField.required = true;
                            }
                          }

                          return (
                            <Field
                              name={`${name}.vc_direccionReceta`}
                              component={TextField}
                              label="Dirección para la receta"
                              type="text"
                              {...propsField}
                            />
                          );
                        }}
                      </Field>
                    </Box>
                  }
                />
              );
            });
          }}
        </FieldArray>
      </Box>
    );
  }

  renderFieldsetAgendas() {
    const { classes } = this.props;
    const { agendas } = this.state;
    const optionsAgendas = agendas.map(agenda => ({ label: agenda.vc_nombre, value: agenda.id }));

    const optionsNivelAcceso = [{
      label: 'Lectura',
      value: 1
    }, {
      label: 'Escritura',
      value: 2
    }];

    return (
      <Box p={1} width="100%">
        <FieldArray name="agendas">
          {({ fields }) => {
            const { value } = fields;

            return fields.map((name, index) => {
              if (Boolean(value[index].sn_eliminado)) {
                return null;
              }

              const agenda = agendas.find(agenda => agenda.id === value[index].id_agenda) || {};

              const headerAgenda = (
                <TitleFieldset
                  classes={classes}
                  title={Boolean(agenda.id) ? agenda.vc_nombre : 'Nueva agenda'}
                  fields={fields}
                  index={index}
                />
              );

              return (
                <ExpansionPanel
                  key={index}
                  header={headerAgenda}
                  defaultExpanded={index === 0}
                  description={
                    <Box p={1} width="100%" className={classes.boxFieldset}>
                      <Field
                        name={`${name}.id_usuario`}
                        component={() => null}
                      />

                      <Field
                        name={`${name}.id_agenda`}
                        component={Select}
                        label="Agenda"
                        options={optionsAgendas}
                        validate={required}
                        required
                      />

                      <Field
                        name={`${name}.nivel`}
                        component={Select}
                        label="Nivel de acceso"
                        options={optionsNivelAcceso}
                        validate={required}
                      />
                    </Box>
                  }
                />
              );
            });
          }}
        </FieldArray>
      </Box>
    );
  }

  render() {
    const { classes, catalogos } = this.props;
    let data = null;
    let agendas = [];
    let roles = [];
    if (this.props.data) {
      this.props.data.agendas.map((agenda) => {
        agendas.push({
         ...agenda,
         id_agenda: agenda.id,
         id_usuario: this.props.data.detalle.id_usuario || this.props.data.id
       })
     })
     this.props.data.roles.map((rol) => {
       roles.push({
         ...rol,
         id_usuario: this.props.data.detalle.id_usuario || this.props.data.id
       })
     })
      data = {
        ...this.props.data.detalle,
        id_usuarioGlobal: this.state.data.id_usuarioGlobal,
        id: this.state.data.id,
        agendas,
        roles
      };
    }else if (this.state.data) {
      this.state.data.agendas.map((agenda) => {
         agendas.push({
          ...agenda,
          id_agenda: agenda.id,
          id_usuario: this.state.data.id_usuario || this.state.data.id
        })
      })
      this.state.data.roles.map((rol) => {
        roles.push({
          ...rol,
          id_usuario: this.state.data.id_usuario || this.state.data.id
        })
      })
      data = {
        ...this.state.data,
        agendas,
        roles
      };
    }
    console.log(data);
    return (
      <React.Fragment>
        <div style={{ width: '100%', height: 'calc(100vh - 47px)', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>
          {
            data && 
            <ThinkForm
            backButton={this.back}
            type='form'
            setFunctions={(functions) => {
              this.setState({ functions });
            }}
            tags={[]}
            registro={data}
            catalogo={catalogos.find(e => e.vc_nameTable === 'ins_usuarios')}
          />
          }
        </div>
      </React.Fragment>
    )
    return (
      <React.Fragment>
        <UsuarioBar
          type="capture"
          back={this.back}
          save={e => {
            console.log('save')
            document.getElementById('usuario')
              .dispatchEvent(new Event('submit', { cancelable: true }));
          }}
        />

        <div style={{ width: '100%', padding: 15, height: 'calc(100% - 65px)', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>



          <Form
            onSubmit={this.onSubmit}
            initialValues={this.state.data}
            mutators={{
              ...arrayMutators,
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              }
            }}
            render={({ handleSubmit, submitting, form: { mutators } }) => {
              const { data: { id } } = this.state;

              const headerRoles = (
                <React.Fragment>
                  <Typography className={classes.heading}>Roles</Typography>
                  <IconButton onClick={e => {
                    e.stopPropagation();
                    mutators.push('roles', { id_usuario: id });
                  }}>
                    <PlaylistAdd />
                  </IconButton>
                </React.Fragment>
              );

              const headerAgendas = (
                <React.Fragment>
                  <Typography className={classes.heading}>Agendas</Typography>
                  <IconButton onClick={e => {
                    e.stopPropagation();
                    mutators.push('agendas', { id_usuario: id });
                  }}>
                    <PlaylistAdd />
                  </IconButton>
                </React.Fragment>
              );

              return (
                <form onSubmit={handleSubmit} id="usuario">
                  <ExpansionPanel
                    fullWidth
                    title="Personales"
                    defaultExpanded={this.state.loaded}
                    disabled={!this.state.loaded}
                    description={this.renderFieldsetPersonales()}
                  />

                  <ExpansionPanel
                    fullWidth
                    header={headerRoles}
                    disabled={!this.state.loaded}
                    description={this.renderFieldsetRoles(mutators)}
                  />

                  <ExpansionPanel
                    fullWidth
                    header={headerAgendas}
                    disabled={!this.state.loaded}
                    description={this.renderFieldsetAgendas()}
                  />
                </form>
              );
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

UsuarioForm.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  setState: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { catalogos, formacionAcademica, especialidades, roles } = state.Think;

  return {
    state: state.Think[modulo] || null,
    catalogos,
    formacionAcademica,
    especialidades,
    rolesConf: roles
  };
};
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState,
  setDisplaySidebar
};

export default connect(mapStateToProps, actions)(withStyles(styles)(UsuarioForm));
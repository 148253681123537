import React from "react";

import _ from "lodash";

import {
  Box,
  Input,
  IconButton,
  Divider,
  Popover,
  ListItem,
} from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";
import Search from "@material-ui/icons/Search";
import Add from "@material-ui/icons/Add";

export default (props) => {
  const { onClick, data } = props;
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    setRows([...data].splice(0, 10));
  }, []);

  React.useEffect(() => {
    if (search) {
      setRows(
        data
          .filter((e) => e.text.toLowerCase().includes(search.toLowerCase()))
          .splice(0, 10)
      );
    } else {
      setRows([...data].splice(0, 10));
    }
  }, [search]);

  const select = (row) => {
    onClick(row);
    setAnchorEl(null);
    setSearch("");
    setRows([...data].splice(0, 10));
  };

  return (
    <div>
      <IconButton
        size="small"
        style={{ marginLeft: 10, marginRight: 10 }}
        onClick={(e) => {
          setAnchorEl(e.target);
          setSearch("");
        }}
      >
        <Add />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box
          padding={0}
          style={{
            height: "300px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            padding={0}
            paddingTop={1}
            paddingLeft={1}
            paddingRight={1}
            style={{ outline: "none" }}
          >
            <Input
              autoFocus
              onClick={(e) => e.stopPropagation()}
              fullWidth
              disableUnderline
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              startAdornment={<Search style={{ marginRight: 8 }} />}
              style={{ margin: 0 }}
            />
          </Box>
          <Divider variant="fullWidth" />
          <Box
            padding={0}
            style={{
              flex: "1 1 auto",
              overflowY: "auto",
              minHeight: 0,
              height: 0,
            }}
          >
            {rows.map((row, index) => (
              <ListItem key={index} button onClick={(_) => select(row)}>
                <GridUI container spacing={2}>
                  <GridUI
                    item
                    xs={1}
                    style={{
                      padding: 8,
                      paddingTop: 10,
                      paddingBottom: 8,
                      fontSize: 12,
                      textAlign: "right",
                    }}
                  >
                    {row.vc_code || "N/A"}
                  </GridUI>
                  <GridUI
                    item
                    xs={8}
                    style={{
                      padding: 8,
                      paddingTop: 10,
                      paddingBottom: 8,
                      fontSize: 12,
                      textAlign: "left",
                    }}
                  >
                    {row.vc_description || "N/A"}
                  </GridUI>
                  <GridUI
                    item
                    xs={2}
                    style={{
                      padding: 8,
                      paddingTop: 10,
                      paddingBottom: 8,
                      fontSize: 12,
                      textAlign: "right",
                    }}
                  >
                    {"$ "}
                    {(`${row.nu_unitValue}`.split(",").join("") * 1).toFixed(
                      2
                    ) || "N/A"}
                  </GridUI>
                </GridUI>
              </ListItem>
            ))}
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Button, Paper, Grid, Card, CardHeader, CardContent, Box } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import CreditCardItem from '../../../material-ui/CreditCardItem';
import DoneIcon from '@material-ui/icons/Done';
const isMobile = window.isMobile;
const styles = theme => ({
    grid: {

    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(1),
    },
    iconSelected: {
        color: 'green',
        width: '30px',
        height: '30px',
        position: 'absolute',
        left: 0,
        top: 0
    }
});
class FinishPay extends React.Component {
    constructor() {
        super();
        this.state = {
            someKey: 'someValue'
        };
    }
    clickAccion() {

    }
    render() {
        const { classes } = this.props;
        console.log('entro a finish')
        return (
            <div style={{ width: '100%', margin: 0, minHeight: '100%', alignContent: 'center' }}>
                <Grid spacing={2} justify='center' alignItems='center' alignContent='center' container>
                    <Grid container spacing={2} justify='center'>
                        <Grid key={'institution'} item xs={isMobile ? 12 : 4} style={{ borderRadius: 10 }}>
                            <Card style={{ height: isMobile ? null : '100%', paddingTop: 'calc((100% - 55mm - 60px)/2)', borderRadius: 10, padding: isMobile ? 16 : null }}>
                                <CardHeader
                                    key='inst'
                                    title={`Institucion: ${this.props.institucion.vc_nombre || ''}`}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={this.props.packageSelected.PaqueteInstituciones.length === 1 ? <DoneIcon className={classes.iconSelected} /> : null}
                                />
                                <CardHeader
                                    key='rfc'
                                    title={`RFC: ${this.props.institucion.vc_rfc || ''}`}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={this.props.packageSelected.PaqueteInstituciones.length === 1 ? <DoneIcon className={classes.iconSelected} /> : null}
                                />
                                <CardHeader
                                    key='domicilio'
                                    title={`Domicilio: ${this.props.institucion.vc_calle || ''} ${this.props.institucion.vc_numeroExterior || ''} ${this.props.institucion.vc_numeroInterior || ''}`}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={this.props.packageSelected.PaqueteInstituciones.length === 1 ? <DoneIcon className={classes.iconSelected} /> : null}
                                />
                                <CardHeader
                                    key='colonia'
                                    title={`Colonia: ${this.props.institucion.vc_colonia || ''}`}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={this.props.packageSelected.PaqueteInstituciones.length === 1 ? <DoneIcon className={classes.iconSelected} /> : null}
                                />
                                <CardHeader
                                    key='cp'
                                    title={`Codigo Postal: ${this.props.institucion.vc_codigoPostal || ''}`}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={this.props.packageSelected.PaqueteInstituciones.length === 1 ? <DoneIcon className={classes.iconSelected} /> : null}
                                />
                            </Card>
                        </Grid>
                        <Grid key={'package'} item xs={isMobile ? 12 : 4} style={{ borderRadius: 10 }}>
                            <Card style={{ borderRadius: 10 }}>
                                <CardHeader
                                    title={`Paquete ${this.props.packageSelected.nombre}`}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={this.props.packageSelected.PaqueteInstituciones.length === 1 ? <DoneIcon className={classes.iconSelected} /> : null}
                                />
                                <CardContent>
                                    <div className={classes.cardPricing}>
                                        <Typography component="h2" variant="h4" color="textPrimary">
                                            <NumberFormat
                                                value={this.props.packageSelected.precio}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                            />
                                        </Typography>
                                    </div>
                                    <Box display="flex" justifyContent="center" mb={2}>
                                        {this.props.packageSelected.rutaImagen && (
                                            <img src={this.props.packageSelected.rutaImagen} width="105" height="92" />
                                        )}

                                        {!this.props.packageSelected.rutaImagen && (
                                            <img
                                                src="https://locationiq.com/startup/common-files/icons/Bulb@2x.png"
                                                width="105"
                                                height="92"
                                            />
                                        )}
                                    </Box>
                                    <Box >
                                        {this.props.packageSelected.PaqueteProductos.map((product, index) => (
                                            <Typography variant="subtitle1" key={index} style={{ width: '100%', justifyContent: 'center', textAlign: 'center' }}>
                                                <NumberFormat
                                                    value={Number.parseInt(product.cantidad, 10)}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    suffix=" "
                                                />

                                                {product.Producto.descripcion}
                                            </Typography>
                                        ))}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid key={'payMethod'} item xs={isMobile ? 12 : 4} style={{ borderRadius: 10 }}>
                            <Card style={{ height: isMobile ? null : '100%', paddingTop: 'calc((100% - 55mm - 60px)/2)', borderRadius: 10, padding: isMobile ? 16 : null }}>
                                <CardHeader
                                    title={`Metodo de pago`}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={this.props.packageSelected.PaqueteInstituciones.length === 1 ? <DoneIcon className={classes.iconSelected} /> : null}
                                />
                                <CreditCardItem
                                    paymentMethod={{...this.props.paymentMethod || {}}}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
    componentDidMount() {
        this.setState({
            someKey: 'otherValue'
        });
    }
}
export default withStyles(styles)(FinishPay);

import http from '../http';
import httpAdmin from '../httpAdmin';


// servicioGET(body, callback, headers) {
//     http.get(`/unServicio/${body}`, headers, callback);
// },

export default {
  buscarPreguntas(body, callback) {
    const headers = { Authorization: window.amplify.store('token') };
    http.post('/app/api/preguntas/buscar', headers, body, callback);
  },
  crearEditarPreguntas(body, callback) {
    const headers = { Authorization: window.amplify.store('token') };
    //crearEditar roles, licencias y agendas desde el backend
    body.id_institucion = window.amplify.store('institution').id_institucion;
    body.preguntasVersiones = body.versiones || [];
    delete body.versiones;
    const editBody = {
        ...body,
        id_sexo: body.id_sexo || 3,
        preguntasVersiones: []
    }
    body.preguntasVersiones.map((version, index) => {
        if (version.sn_activo) {
            const versionEdit = {
                ...version,
                id_pregunta: version.id_pregunta || editBody.id,
                id_sexo: version.id_sexo || editBody.id_sexo || 3, 
                respuestas:[]
            }
            if (!versionEdit.id_pregunta) {
                delete versionEdit.id_pregunta;
            }
            const respuestas = version.respuestas || [];
            respuestas.map((respuesta, indexR) => {
                const respuestaEdit = {
                    ...respuesta, 
                    id_preguntaVersion: version.id || null,
                    id_nivel: respuesta.id_nivel || editBody.id_nivel, 
                    id_sexo: respuesta.id_sexo || version.id_sexo || 3}
                if (!respuestaEdit.id_preguntaVersion) {
                    delete respuestaEdit.id_preguntaVersion;
                }
                if (respuestaEdit.sn_activo){
                    versionEdit.respuestas.push(respuestaEdit);
                }
            })
            editBody.preguntasVersiones.push(versionEdit);
        }
    })
    if (body.id){
        http.put(`/app/api/preguntas/edit/${body.id}`, headers, editBody, callback);
    }else{
        http.post(`/app/api/preguntas/create`, headers, editBody, callback);
    }
  },
};

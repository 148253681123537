import React from 'react';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import NumberFormat from 'react-number-format';
import LockIcon from '@material-ui/icons/Lock';
import NumberCard from './forms/NumberCard';
import CardExpiry from './forms/CardExpiry';
import {
  composeValidators,
  required,
  mustBeLengthNumber
} from './validations';
import CircularProgress from '@material-ui/core/CircularProgress';
import { teal } from '@material-ui/core/colors';
import { Paper } from '@material-ui/core';
import CVCCard from './forms/CVCCard';
import TextField from './forms/TextField';

const styles = theme => ({
  card: {

  },
  cardHeader: {
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center'
  }
});
const isValidName = value => value.split(' ').length < 2 ? 'invalido' : undefined;
const isValidCardNumber = value => !window.OpenPay.card.validateCardNumber(value) ? 'Número invalido' : undefined;
const isValidCardDate = value => !window.OpenPay.card.validateExpiry(`${value.slice(0,2)}`,`20${value.slice(2,2)}`) ? 'inválido' : undefined;
const isValidCVCNumber = value => !window.OpenPay.card.validateCVC(value) ? 'El código de seguridad es inválido' : undefined;
const isValidcardType = value => !window.OpenPay.card.cardType(value) ? 'El tarjeta es inválida' : undefined;

const validateName = composeValidators(required, isValidName);
const validateCardNumber = composeValidators(required, isValidCardNumber);
const validateExpCard = composeValidators(required, isValidCardDate);
const validateCVC = composeValidators(required, mustBeLengthNumber(3), isValidCVCNumber);
const themeSelected = window.amplify.store('theme') || null;

class PaymenthMethod extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      loading: false
    }
  }

  componentWillMount() {
    
  }
  luhnCheck(val) {
    var sum = 0;
    for (var i = 0; i < val.length; i++) {
      var intVal = parseInt(val.substr(i, 1));
      if (i % 2 == 0) {
        intVal *= 2;
        if (intVal > 9) {
          intVal = 1 + (intVal % 10);
        }
      }
      sum += intVal;
    }
    return (sum % 10) == 0;
  }
  getMarca(number) {
    switch (true) {
      case (number.startsWith('4')): {
        return 'Visa'
      }
      case (number.startsWith('51') || number.startsWith('55')): {
        return 'Mastercard'
      }
      case (number.startsWith('6011') || number.startsWith('644') || number.startsWith('65')): {
        return 'Discover'
      }
      case (number.startsWith('34') || number.startsWith('37')): {
        return 'American Express'
      }
      default: {
        return 'invalida'
      }
    }
  }
  onSubmit(card) {
    const { card_number, holder_name, card_expiry, cvv2 } = card;
    console.log('cardexpiry', card_expiry);
    console.log('cardexpiry', card_expiry.slice(0,2));
    console.log('cardexpiry', card_expiry.slice(2,2));
    var deviceDataId = window.OpenPay.deviceData.setup('paymentMethod');
    this.setState({loading: true});
    window.OpenPay.token.create({
      card_number,
      holder_name,
      expiration_month: card_expiry.slice(0,2),
      expiration_year: card_expiry.slice(2,4),
      cvv2,
      address: this.props.address || null
    }, (res) => {
      this.setState({loading: true});
      if (res.data && res.status === 200){
        this.props.onSubmit({...res.data,deviceDataId });
      }
    }, () => {
      console.log("hay algo incorrecto con los datos de la tarjeta");
    });
  }

  render() {
    const { classes, total, onSubmit, buttonLabel } = this.props;

    const TotalToPay = (
      <NumberFormat
        value={total}
        displayType={'text'}
        thousandSeparator={true}
        prefix={' $ '}
        suffix=" MXN "
      />
    );
    const primary = themeSelected ? themeSelected.primary : teal;
    return (
      <Form
        onSubmit={this.onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} id="paymentMethod">
            <input
              autoComplete="false"
              name="hidden"
              type="text"
              style={{ display: 'none' }}
            />
            <Grid container spacing={0} alignItems="center" justify="center" direction="row" style={{ backgroundColor: 'transparent', boxShadow: '0px 0px 0px transparent' }}>
              <Grid item xs={12} className={classes.gridItem} style={{ backgroundColor: 'transparent', borderRadius: 10, boxShadow: '0px 0px 0px transparent' }}>
                <Paper className={classes.card} style={{ backgroundColor: this.props.type === 'naked' ? null : '#5d5d5d', boxShadow: '0px 0px 0px transparent', padding: 16, borderRadius: 8 }}>
                  {
                    false &&
                    <CardHeader
                      title={TotalToPay}
                      className={classes.cardHeader}
                    />
                  }
                  <CardContent style={{ backgroundImage: `linear-gradient(${primary[300]},${primary[600]})`, borderRadius: '3mm', height: '54mm', width: '85mm', padding: '6mm', boxShadow: '0px 3px 20px rgba(0,0,0,0.5)' }}>
                    <Field
                      name="holder_name"
                      component={TextField}
                      fullWidth
                      label=''
                      defaultValue={this.props.name || ''}
                      InputProps={{ disableUnderline: true }}
                      placeholder='Nombre'
                      data-conekta={'card[name]'}
                      validate={validateName}
                      variant={'standard'}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                    />
                    <Field
                      name="card_number"
                      label=''
                      placeholder='Numero de Tarjeta'
                      component={NumberCard}
                      required
                      validate={validateCardNumber}
                      variant={'standard'}
                    />

                    <Box display="flex" alignItems="center" justifyContent='space-around'>
                      <Field
                        name="card_expiry"
                        label=''
                        component={CardExpiry}
                        placeholder={'MM / YY'}
                        validate={validateExpCard}
                        variant={'standard'}
                      />

                      {/* <Field
                        name="expiration_year"
                        label=''
                        placeholder='YY'
                        component={ExpirateYear}
                        required
                        validate={validateExpYear}
                        variant={'standard'}
                      /> */}
                      <Field
                        name="cvv2"
                        component={CVCCard}
                        placeholder='CVC'
                        required
                        label=''
                        validate={validateCVC}
                        variant={'standard'}
                        style={{ width: 40 }}
                      />
                    </Box>
                  </CardContent>
                  <CardActions style={{ padding: 0, marginTop: 16 }}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={submitting}
                      startIcon={<LockIcon />}
                      endIcon={ this.state.loading && <CircularProgress size={15} color='secondary' />}
                    >
                      {submitting && 'Procesando'}
                      {!submitting && buttonLabel}
                      Guardar
                    </Button>
                  </CardActions>
                </Paper>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}

export default withStyles(styles)(PaymenthMethod);
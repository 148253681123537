import React from "react";
import { connect } from "react-redux";
import QrReader from "react-qr-reader";
import useMeasure from 'react-use-measure';
import ThinkForm from "app/material-ui/ThinkForm";
import CreateButton from "app/material-ui/ThinkForm/CreateButton";

import {
  ThemeProvider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  Button,
  Box,
  Divider,
  FormControlLabel,
  Typography,
  Checkbox,
  Avatar,
} from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { teal } from "@material-ui/core/colors";
import termsText from "./terms";
import moment from "moment";

const themeSelected = window.amplify.store("theme") || null;
const themeBase = createMuiTheme({
  overrides: {
    MuiCardHeader: {
      root: {
        position: "relative",
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: "4px",
        marginBottom: "8px",
      },
      root: {
        marginTop: "4px",
        marginBottom: "8px",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "8px 14px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 10px) scale(1)",
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: "4px 0px 0",
      },
    },
    MuiDrawer: {
      paper: {
        // backgroundColor: grey
      },
    },
    MuiFormHelperText: {
      root: {
        position: "absolute",
        bottom: "-4px",
        left: "-14px",
        color: "#f3564b",
        width: "100%",
      },
      contained: {
        margin: "0px 14px 0",
      },
    },
    MuiInputBase: {
      root: {
        marginBottom: "10px",
      },
    },
  },
  palette: {
    primary: themeSelected ? themeSelected.primary : teal,
    secondary: {
      main: "#fafafa",
    },
    type: "light",
  },
  MuiExpansionPanelSummary: {
    root: {
      "&$expanded": {
        boxShadow: "0 1px 12px 0 rgba(0,0,0,.11)",
      },
    },
  },
});
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const Index = ({ open, close, catalogo, ...props }) => {
  const [ref, { height, width }] = useMeasure();
  const [functions, setFunctions] = React.useState(null);

  const [buttonHelper, setButtonHelper] = React.useState({
    text: "",
    action: () => { },
  });
  const [user, setUser] = React.useState({});
  const [type, setType] = React.useState("intro");
  const [help, setHelp] = React.useState("");
  const [actions, setActions] = React.useState([]);
  const [content, setContent] = React.useState(null);
  const [acceptTerms, setAcceptTerms] = React.useState(false);

  const handleScan = (data) => {
    if (data) {
      const curpInfo = data.split("|");
      const [
        vc_curp,
        a,
        vc_apellidoPaterno,
        vc_apellidoMaterno,
        vc_nombre,
        vc_sexo,
        dt_nacimiento,
        endidad,
        id_entidad,
      ] = curpInfo;

      if (["HOMBRE", "MUJER"].includes(vc_sexo)) {
        const params = {
          vc_curp,
          vc_nombre,
          vc_apellidoPaterno,
          vc_apellidoMaterno,
          id_sexo: vc_sexo === "HOMBRE" ? 1 : 2,
          dt_nacimiento: moment(dt_nacimiento, "DD/MM/YYYY").format(
            "YYYY/MM/DD"
          ),
          id_entidad: Number(id_entidad),
        };

        console.log(functions, params);
        if (functions) functions.setRegistro && functions.setRegistro(params);

        setUser(params);
        setType("form");
      }
    }
  };
  const handleError = (err) => {
    setType("form");
  };

  React.useEffect(() => {
    setType("intro");
    setAcceptTerms(false);
  }, [open]);

  React.useEffect(() => {
    switch (type) {
      case "qr":
        setActions([
          <Button onClick={() => setType("form")} color="primary">
            {"Lo hare manual"}
          </Button>,
        ]);
        break;
      case "form":
        setActions([
          <Button onClick={() => setType("qr")} color="primary">
            {"Leer CURP"}
          </Button>,
          functions && functions.getRegistro && (
            <CreateButton
              disabled={!acceptTerms}
              color="primary"
              getRegistro={functions.getRegistro.bind(this)}
              catalogo={catalogo}
              saveComplete={() => {
                close();
              }}
            />
          ),
        ]);
        break;
      default:
        setActions([
          <Button key={"curp"} onClick={() => setType("qr")} color="primary">
            Tengo a la mano mi CURP
          </Button>,
          <Button
            key={"manual"}
            onClick={() => setType("form")}
            color="primary"
          >
            Lo hare manual
          </Button>,
        ]);
        break;
    }
  }, [type, functions, acceptTerms, catalogo]);

  React.useEffect(() => {
    switch (type) {
      case "qr":
      case "terms":
        setButtonHelper({ text: "Atrás", action: () => setType("form") });
        break;
      default:
        setButtonHelper({ text: "Salir", action: close });
        break;
    }

    switch (type) {
      case "intro":
        setHelp(
          "Para iniciar con su consulta es necesario que cree una cuenta, puede hacerlo con su CURP de manera automatica, ó con el formulario."
        );
        setContent(null);
        break;
      case "qr":
        setHelp("Coloque el código QR frente a su cámara por favor.");
        setContent(
          <Box position="relative">
            <QrReader
              showViewFinder={false}
              delay={0}
              onError={handleError}
              onScan={handleScan}
              style={{
                width: "100%",
                margin: 0,
                padding: 0,
                borderRadius: 0,
                overflow: "hidden",
              }}
            />
            <Box position="absolute" p={4} top={0} width="100%" height="100%">
              <Box height="100%" border="2px solid rgba(20,20,20,0.2)" />
            </Box>
          </Box>
        );

        break;
      case "form":
        setHelp("Cápture sus datos por favor.");
        setContent(
          <Box>
            <Box height={height - 300} bgcolor="rgba(200,200,200,0.1)">
              <Divider />

              <ThinkForm
                fullWidth
                type="form"
                setFunctions={(functions) => {
                  setFunctions(functions);
                }}
                withOutHeader
                tags={[]}
                registro={user}
                catalogo={catalogo}
              />

              <Divider />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTerms}
                  onChange={() => {
                    setAcceptTerms(!acceptTerms);
                    if (type === "terms" && !acceptTerms) setType("form");
                  }}
                  name="acceptTerms"
                  color="primary"
                />
              }
              label={
                <div>
                  Acepto{" "}
                  <a
                    onClick={(e) => {
                      setType("terms");
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    terminos y condiciones
                  </a>
                </div>
              }
              labelPlacement="end"
              style={{ padding: 16 }}
            />
            <Divider />
          </Box>
        );
        break;
      case "terms":
        setHelp("Términos y condiciones.");
        setContent(
          <Box>
            <Divider />
            <Box
              p={2}
              fontSize="1rem"
              textAlign="justify"
              overflow="scroll"
              whiteSpace="pre-wrap"
              height={height - 300}
            >
              {termsText}
            </Box>
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTerms}
                  onChange={() => {
                    setAcceptTerms(!acceptTerms);
                    if (type === "terms" && !acceptTerms) setType("form");
                  }}
                  name="acceptTerms"
                  color="primary"
                />
              }
              label={
                <div>
                  Acepto{" "}
                  <a
                    onClick={(e) => {
                      setType("terms");
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    terminos y condiciones
                  </a>
                </div>
              }
              labelPlacement="end"
              style={{ padding: 16 }}
            />
            <Divider />
          </Box>
        );
        break;
    }
  }, [type, acceptTerms, catalogo]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={close}
      keepMounted
      BackdropProps={{ ref: ref }}
    >
      <ThemeProvider theme={themeBase}>
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src="assets/images/iconThin-k-80-80.png"
                style={{
                  boxShadow: "0px 0px 5px rgba(200,200,200,0)",
                  backgroundColor: "transparent",
                  height: 50,
                  width: 50,
                  marginRight: "16px",
                }}
              />
              Bienvenido a Thin-k Health
            </Box>
            <Button onClick={buttonHelper.action} color="primary">
              {buttonHelper.text}
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent style={{ minWidth: "400px" }}>
          <DialogContentText>{help}</DialogContentText>
        </DialogContent>
        {content}
        {actions.length && <DialogActions>{actions}</DialogActions>}
      </ThemeProvider>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { catalogos } = state.Think;

  return {
    catalogo: catalogos.find((e) => e.vc_nameTable === "usuariosDetalle"),
  };
};
export default connect(mapStateToProps, [])(Index);

import React from "react";

import { Input, IconButton } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import _ from "lodash";

import AddProduct from "./AddProduct";

export default (props) => {
  const {
    onSelect = () => {},
    onChange = () => {},
    products = [],
    saleCut = null,
  } = props;

  return (
    <Input
      onChange={(e) => onChange(e.target.value)}
      disableUnderline
      startAdornment={
        <IconButton size="small" style={{ marginLeft: 10, marginRight: 10 }}>
          <Search />
        </IconButton>
      }
      endAdornment={
        !saleCut && (
          <AddProduct
            onClick={onSelect}
            data={products.map((p) => ({ ...p, text: p.vc_description }))}
          />
        )
      }
      inputProps={{
        style: {
          padding: 8,
          width: "100%",
          backgroundColor: "rgba(200,200,200,0.2)",
          borderRadius: 5,
        },
      }}
      style={{ margin: 0, width: "100%", padding: 8 }}
    />
  );
};

import React from "react";
import { connect } from "react-redux";

import { Typography, Box, CircularProgress } from "@material-ui/core";

export default ({
  show = true,
  text = "Cargando",
  color = "primary",
  cancelButton = null,
  ...props
}) =>
  show && (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <CircularProgress color={color} />
      <Box>
        <Typography color={color} style={{ marginTop: 8 }}>
          {text}
        </Typography>
        {cancelButton}
      </Box>
    </Box>
  );

import React from "react";
import ConfirmAlert from "app/material-ui/ConfirmAlert";

export default ({
  onAccept,
  title: baseTitle = "",
  message: baseMessage = "",
}) => {
  const [title, setTitle] = React.useState(baseTitle);
  const [message, setMessage] = React.useState(baseMessage);
  const [isOpen, setIsOpen] = React.useState(false);

  const onOpen = ({ title, message } = {}) => {
    if (title) setTitle(title);
    if (message) setMessage(message);

    setIsOpen(true);
  };
  const handleAccept = () => {
    onAccept && onAccept();
    setIsOpen(false);
  };

  const Dialog = React.useCallback(
    () => (
      <ConfirmAlert
        open={isOpen}
        title={title}
        message={message}
        onCancel={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        onAccept={handleAccept}
      />
    ),
    [isOpen, title, message]
  );

  return {
    Alert: Dialog,
    onOpen,
  };
};

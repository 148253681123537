import React from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Box,
  Grid,
} from "@material-ui/core";
import { AttachMoney, Computer } from "@material-ui/icons";
import Select from "app/components/Form/Select";
import Input from "app/components/Form/Input";
import NumberInput from "app/material-ui/NumberInput";

const Index = (props) => {
  const {
    open,
    onClose,
    onAccept,
    onChange,
    options = [],
    value,
    init = "100",
  } = props;

  const [disabled, setDisabled] = React.useState(false);
  const [option, setOption] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [cut, setCut] = React.useState(null);
  const [received, setReceived] = React.useState(0);

  React.useEffect(() => {
    if (value && open) setSelected(value);
  }, [value, open]);

  React.useEffect(() => {
    if (selected) {
      const aux = options.find((v) => v.value === selected);
      setOption(aux);
      setCut(aux && aux.cut ? aux.cut : null);

      let nu_received = init;
      const { lastCutEnded } = aux || {};
      if (lastCutEnded) nu_received = lastCutEnded.close.nu_balance;

      setReceived(nu_received);
    } else {
      setOption(null);
      setCut(null);
      setReceived(null);
    }
  }, [options, selected]);

  React.useEffect(() => {
    if (!option) setDisabled(true);
    else {
      console.log(cut);
      console.log(received);
      setDisabled(!cut && !received);
    }
  }, [option, received]);

  const accept = (_) => {
    const id_cashRegister = selected;
    const nu_initReceived = Number(
      `${received}`.replace("$", "").split(",").join("").trim()
    );
    const option = options.find((v) => v.value === selected);

    if (option.value && option.cut) {
      onAccept(id_cashRegister, null);
      onClose();
      return;
    }

    if (id_cashRegister) {
      onAccept(id_cashRegister, nu_initReceived);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      keepMounted
      fullWidth
      maxWidth={"xs"}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {!cut ? "¿Deseas abrir un corte de caja?" : "Seleccionar caja"}
      </DialogTitle>
      <DialogContent>
        <Grid key={"caja"} container>
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <Computer />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            <Select
              data={options}
              placeholder="Seleccionar Caja"
              showNoSelect
              value={selected}
              hideSearch
              onClick={(o) => setSelected(o.value)}
            />
          </Grid>
        </Grid>
        {selected && !cut && (
          <Grid key={"monto"} container>
            <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
              <DialogContentText>
                <AttachMoney />
              </DialogContentText>
            </Grid>
            <Grid item xs={11}>
              <Input
                number
                placeholder="Monto Inicial"
                value={received}
                onChange={setReceived}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" style={{ marginLeft: 8 }}>
          {" "}
          Cancelar{" "}
        </Button>
        <Button
          disabled={disabled}
          variant="contained"
          onClick={accept}
          color="primary"
          style={{ marginLeft: 8 }}
        >
          {selected && !cut ? "Abrir" : "Seleccionar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Index;

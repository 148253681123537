import React, { Component } from 'react';
import PropTypes from 'prop-types';
import transitions from '../utils/transitions';
import eventManager from '../utils/eventManager';
import { START_VIEW_TRANSITION } from '../constants';

const INITIAL_VALUE = 0;
const FINAL_VALUE = 1;


class FadeInOutView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callback: null,
      opacity: INITIAL_VALUE,
    };
  }

  // --------------------------------
  // ------ life cycle events -------
  // --------------------------------
  componentDidMount() {
    this.fadeInAnimation();
    const eventType = this.props.eventType || START_VIEW_TRANSITION;

    this.callbackID = eventManager.on(eventType, (callback) => {
      this.setState({ callback });
      this.fadeInOutAnimation();
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
    const eventType = this.props.eventType || START_VIEW_TRANSITION;

    eventManager.unsubscribe(eventType, this.callbackID);
  }


  // ----------------------------
  // --------- methods ----------
  // ----------------------------
  fadeInAnimation() {
    const params = {
      initialValue: INITIAL_VALUE,
      finalValue: FINAL_VALUE,
      duration: this.props.duration || 500,
    };

    transitions.easePolyOut(params, (response) => {
      if (!this.unmounted) {
        this.setState({ opacity: response.value });
      }
    });
  }

  fadeInOutAnimation() {
    const initialParams = {
      initialValue: FINAL_VALUE,
      finalValue: INITIAL_VALUE,
      duration: Math.round(this.props.duration || 500 / 2),
    };

    const finalParams = {
      initialValue: INITIAL_VALUE,
      finalValue: FINAL_VALUE,
      duration: Math.round(this.props.duration || 500 / 2),
    };

    transitions.easePolyOut(initialParams, (firstResponse) => {
      if (!this.unmounted) {
        this.setState({ opacity: firstResponse.value });
      }

      if (firstResponse.finished) {
        if (typeof this.state.callback === 'string') {
          window.location = this.state.callback;
        } else {
          this.state.callback();
        }

        transitions.easePolyOut(finalParams, (secondResponse) => {
          if (!this.unmounted) {
            this.setState({ opacity: secondResponse.value });
          }
        });
      }
    });
  }


  // -----------------------------
  // ------ render methods -------
  // -----------------------------
  render() {
    const { opacity } = this.state;

    return (
      <div style={{ opacity, width: '100%', height: '100%', ...this.props.style }}>
        { this.props.children }
      </div>
    );
  }
}


FadeInOutView.propTypes = {
  duration: PropTypes.number,
  eventType: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
};


export { FadeInOutView };

import React from 'react';
import { connect } from 'react-redux';
import ThinkForm from '../../../../material-ui/ThinkForm';
import { ThemeProvider, Paper, Box } from '@material-ui/core';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { teal } from '@material-ui/core/colors';
const themeSelected = window.amplify.store('theme') || null;

let theme = createMuiTheme({
    overrides: {
        MuiCardHeader: {
            root: {
                position: 'relative',
            }
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: '4px',
                marginBottom: '8px'
            },
            root: {
                marginTop: '4px',
                marginBottom: '8px'
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px 14px'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 10px) scale(1)'
            }
        },
        MuiFormHelperText: {
            contained: {
                margin: '4px 0px 0'
            }
        },
        MuiDrawer: {
            paper: {
                // backgroundColor: grey
            }
        },
        MuiFormHelperText: {
            root: {
                position: 'absolute',
                bottom: '-4px',
                left: '-14px',
                color: '#f3564b',
                width: '100%'
            },
            contained: {
                margin: '0px 14px 0'
            }
        },
        MuiInputBase: {
            root: {
                marginBottom: '10px'
            }
        }
    },
    palette: {
        primary: themeSelected ? themeSelected.primary : teal,
        secondary: {
            main: '#fafafa'
        },
        type: themeSelected ? themeSelected.typeMain : 'dark',
    },
    MuiExpansionPanelSummary: {

        root: {
            "&$expanded": {
                boxShadow: "0 1px 12px 0 rgba(0,0,0,.11)"
            }
        }
    },
});

class RegistroUsuario extends React.Component {
    constructor() {
        super();
        this.state = {
            someKey: 'someValue'
        };
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <ThinkForm
                    type='form'
                    setFunctions={(functions) => {
                        this.setState({ functions });
                    }}
                    backButton={this.props.backButton}
                    tags={[]}
                    registro={{}}
                    catalogo={this.props.catalogo}
                />
            </ThemeProvider>
        );
    }

    componentDidMount() {
        this.setState({
            someKey: 'otherValue'
        });
    }
}

const mapStateToProps = (state) => {
    const { catalogos } = state.Think;
    return { catalogo: catalogos.find(e => e.vc_nameTable === 'usuariosDetalle') };
};
export default connect(mapStateToProps, [])(RegistroUsuario);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Modal, Divider } from 'semantic-ui-react';

// Material UI
import { styled } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Timelapse from '@material-ui/icons/Timelapse';
import { Button, IconButton, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { getLastDetalle } from '../../../../utils/repository';

const DiagnosticoItem = styled(ExpansionPanel)({
    marginBottom: '10px',
    '& .cie10': {
        marginRight: 10,
        // color:window.colors.red,
        width: '40px',
    },
    '& .MuiExpansionPanelSummary-root': {
        minHeight: '48px',
        height: '48px'
    },

    '& .MuiExpansionPanelDetails-root': {
        borderTop: '1px solid',
    },
    '& .icon': {
        marginLeft: 10,
        float: 'right',
        color: 'gray',

        '&.delete': {
            display: 'none',
            cursor: 'pointer',
            color:window.colors.red,
        },
    },

    '&:hover': {
        '& .icon': {
            display: 'none',
            '&.delete': {
                display: 'block',
            }
        },
    },
});


class DiagnosticosItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverIndex: null,
            hoverDelete: false,
            indexSeleccionado: null,

            anchorEl: null,
            open: false,

            diagnostico: {
                id: null,
                id_estatus: null,
                id_institucion: null,
                id_nota: null,
                id_diagnostico: null,
                id_paciente: null,
                id_usuario: null,
                sn_activo: null,
                dt_registro: null,
                detalles: [
                    {
                        id: null,
                        id_institucion: null,
                        id_notaOrigen: null,
                        id_notaActual: null,
                        id_notaDiagnostico: null,
                        id_diagnostico: null,
                        id_estatusDiagnostico: null,
                        id_usuario: null,
                        id_conciliacion: null,
                        id_um_convalecencia: null,
                        nu_convalecencia: null,
                        dt_inicio: null,
                        dt_vigencia: null,
                        sn_principal: null,
                        sn_subsecuente: null,
                        sn_activo: null,
                        dt_registro: null,
                        vc_observaciones: null
                    }
                ]
            }
        };
    }

    selectDx() {
        if (!this.state.hoverDelete) {
            if (this.props.index == this.props.indexSeleccionado && this.props.indexSeleccionado != null) {
                this.props.callbackSelect({ opcionesModuloNext: 'agregar', sn_seleccionado: 0, indexSeleccionado: this.props.index });
            } else {
                this.props.callbackSelect({ opcionesModuloNext: 'clasificar', sn_seleccionado: 1, indexSeleccionado: this.props.index });
            }
        }
    }


    // onChangeValue(event) {
    //     this.props.callbackPreg({ value: event.target.value, index: this.props.index });
    //     this.setState({ value: event.target.value });
    // }

    respuestaValor(item) {
        switch (item.id_tipoPregunta) {
            case 1, 2:
                if (item.respuesta != undefined) {
                    return `${item.versiones[0].respuestas.filter(r => r.id === item.respuesta.id_respuesta)[0].vc_respuesta}`;
                }
            case 3:
                if (item.respuesta != undefined) {
                    return `${item.respuesta.nu_respuesta}`;
                }
            case 4:
                if (item.respuesta != undefined) {
                    return `${item.respuesta.vc_respuesta}`;
                }
            case 5:
                if (item.respuesta != undefined) {
                    return `${item.respuesta.vc_respuesta}`;
                }
            case 6:
                if (item.respuesta != undefined) {
                    return `${item.respuesta.tm_respuesta}`;
                }
            default:
                return null;
        }
        return "";
    }

    timeSince(refDate, date) {

        var seconds = Math.floor((refDate - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " años";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " minutos";
        }
        return "un instante";
    }
    statusDxToString(id_estatusDiagnostico) {
        switch (id_estatusDiagnostico) {
            case 1:
                return "presuntivo";
            case 2:
                return "definitivo ó confirmado";
            case 3:
                return "descartado";
            default:
                return "presuntivo";
        }
    }

    isDxActive(dt_vigencia) {
        if ((new Date(dt_vigencia) < (new Date()))) {
            return false
        }
        return true
    }
    stringSubsecuencia(lastDetalle) {
        if (lastDetalle.sn_subsecuente == 1) {
            return 'subsecuente, con estatus ';
        } else {
            return 'de primera vez, con estatus ';
        }
    }
    stringDescriptionDx(lastDetalle) {
        if (this.isDxActive(lastDetalle.dt_vigencia)) {
            return "Diagnostico " + `${this.stringSubsecuencia(lastDetalle)}` + `${this.statusDxToString(lastDetalle.id_estatusDiagnostico)}` + ' actualmente vigente, el cual inició hace ' + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` + `${lastDetalle.vc_observaciones != null ? `${lastDetalle.vc_observaciones}.` : '.'}`
        } else {
            return "Diagnostico " + `${this.stringSubsecuencia(lastDetalle)}` + `${this.statusDxToString(lastDetalle.id_estatusDiagnostico)}` + ", el cual inició hace " + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` + ' con un periodo de convalecencia registrado de ' + `${this.timeSince(new Date(lastDetalle.dt_vigencia), new Date(lastDetalle.dt_inicio))}` + ', actualmente no se encuentra vigente ó la convalecencia ha terminado' + `${lastDetalle.vc_observaciones != null ? `, ${lastDetalle.vc_observaciones}.` : '.'}`
        }
    }
    colorDx(lastDetalle) {
        if (this.isDxActive(lastDetalle.dt_vigencia)) {
            if (lastDetalle.id != null) {
                return window.colors.green;
            } else {
                return window.colors.itemSelected;
            }
        } else {
            return window.colors.red;
        }
    }
    getInputs(item) {
        const respuesta = item.respuesta ? item.respuesta : "";

        return [
            {
                type: 'number',
                placeholder: respuesta,
                required: true,
                onChange: this.onChangeValue.bind(this),
                style: stylesDx.textField
            }
        ];
    }

    deleteItem(item) {
        if (item.id == null) {
            this.props.callbackDelete({ opcionesModuloNext: 'agregar', sn_seleccionado: 0, indexSeleccionado: null, item });
        } else {
            this.props.callbackDelete({ opcionesModuloNext: 'estatusFinal', sn_seleccionado: 0, indexSeleccionado: null, item });
        }
    }
    render() {
        const { date, diagnosticos, item, index, sn_printVersion } = this.props;
        const shadow = this.state.hoverIndex == index ? '1px 1px 20px rgba(0, 0,0, 1)' : '1px 0px 10px rgba(0, 0,0, 0.7)';
        const lastDetalle = getLastDetalle(item.detalles);
        
        const description = this.stringDescriptionDx(lastDetalle);

        if (sn_printVersion) {
            return (
                <div key={index} style={stylesDx.containerPrint}>
                    <div style={{
                        width: "100%",
                        margin: 0,
                        marginBottom: 5,
                        padding: 0,
                    }}>
                        <div style={{...stylesDx.titlePrint, color: window.colors.textPrint}}>
                            {`${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].cve_cie10} - ${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre}`}
                        </div>
                        <div readOnly value={description} style={
                            {
                                width: "100%",
                                float: "right",
                                paddingTop: 0,
                                borderRadius: 0,
                                border: 0,
                                margin: 0,
                                marginTop: 1,
                                textAlign: "justify",
                                resize: "none",
                                color:window.colors.textPrint
                            }
                        }>
                            {!this.props.recetaMode &&
                                description
                            }
                        </div>
                    </div>
                </div>
            );
        }

        let anchorEl = null;

        let handleClick = (event) => {
            event.stopPropagation();
            anchorEl = event.currentTarget;
            const open = true;

            this.setState({ anchorEl, open })
        }

        let handleClose = () => {
            anchorEl = null;
            const open = false;

            this.setState({ anchorEl, open })
        }
        const color = this.colorDx(lastDetalle);
        return (
            <DiagnosticoItem
                expanded={true}
                onChange={(event, expanded) => {
                    this.selectDx()
                }}
                style={{width: this.props.index == this.props.indexSeleccionado ? 'calc(100% + 8px)':'100%', borderRadius: 8, borderRight: this.props.index == this.props.indexSeleccionado ? `8px solid ${color}` : null}}
            >
                <ExpansionPanelSummary
                    expandIcon={null}
                    onMouseMove={() => this.setState({over:true})}
                    onMouseLeave={() => this.setState({over:false})}
                >
                    <Typography className="cie10" style={{ color: color }}>{diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].cve_cie10}</Typography>
                    <Typography>
                        {diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre}
                        {/* <Icon name='delete' onClick={() => this.deleteItem(item) } onClick={handleClick}/> */}
                        <Icon name='delete' onClick={handleClick} />
                        {
                            this.state.over &&
                            <IconButton onClick={(e) => {
                                // this.setState({ showHistory: true })
                                this.props.callbackSelect({ opcionesModuloNext: 'historial', sn_seleccionado: 1, indexSeleccionado: this.props.index });
                                e.stopPropagation();
                            }} style={{position:'absolute', right: 8, top: 0}}>
                            <Timelapse name='delete' />
                        </IconButton>
                        }
                        <Popover
                            open={this.state.open}
                            anchorEl={this.state.anchorEl}
                            onMouseMove={(e)=> e.stopPropagation()}
                            onClose={handleClose}
                            onClick={(e) => e.stopPropagation()}
                            anchorOrigin={{ vertical: 'center', horizontal: 'center', }}
                            transformOrigin={{ vertical: 'center', horizontal: 'center', }}
                        >
                            <div style={{ padding: '20px', textAlign: 'center' }}>
                                <Typography>¿Seguro de querer eliminar el diagnostico?</Typography>
                                <Button size="small" color="primary" onClick={(e) => {
                                    e.stopPropagation();
                                    this.deleteItem(item)
                                    handleClose()
                                }}>Aceptar</Button>
                                <Button size="small" color='primary' onClick={handleClose}>Cancelar</Button>
                            </div>
                        </Popover>

                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ borderColor: this.colorDx(lastDetalle) }}>
                    <Typography>
                        {description}
                    </Typography>
                </ExpansionPanelDetails>
                <Modal
                    key={'modal'}
                    size='small'
                    open={this.state.showHistory}
                    onClose={() => this.setState({ showHistory: false })}
                    style={{backgroundColor: 'transparent'}}
                >
                    <Paper>
                        {
                            item.detalles.sort(function (a, b) { return moment(a.dt_registro) - moment(b.dt_registro) }).reverse().map((detalle, index) => {
                                return (
                                    <Box p={3} key={index}>
                                        <Typography>
                                            {this.stringDescriptionDx(detalle)}
                                        </Typography>
                                        <Divider />
                                    </Box>
                                )
                            })
                        }
                    </Paper>
                </Modal>
            </DiagnosticoItem>
        );

        // if ( this.state.sn_selected ) {
        //     return (
        //         <Container key={index} style={stylesDx.container}>
        //             <div  style={stylesDx.subContainer}>
        //                 <div style={stylesDx.title}>
        //                     {index + 1}
        //                 </div>
        //                 <div style={stylesDx.title}>
        //                 {diagnosticos.filter(r => r.id == item.detalles[item.detalles.length - 1])[0].vc_nombre}
        //                 </div>
        //                 <div style={stylesDx.line}/>
        //             </div>
        //         </Container>
        //     );
        // }
        // else if (!sn_printVersion) {
        // return (
        //     <Container key={index} style={stylesDx.container}>
        //         <div onClick={() => this.selectDx() } onMouseEnter={() => this.setState({ hoverIndex: index })} onMouseLeave={() => this.setState({ hoverIndex: null })} style={{
        //             backgroundColor: this.colorDx(lastDetalle),
        //             width: "100%",
        //             margin:0,
        //             marginBottom: 1,
        //             padding:0,
        //             minHeight: 90,
        //             borderRadius: 4,
        //             boxShadow: shadow
        //         }}>
        //             <div style={stylesDx.stick}>
        //                 <div style={{textAlign: "center", verticalAlign: "middle",height: "100%", width: "100%"}}></div>
        //             </div>
        //             <div style={{
        //                 color:this.colorDx(lastDetalle),
        //                 width: "6%",
        //                 textBorderLeft: 5,
        //                 height: 30,
        //                 borderTopLeftRadius: 4,
        //                 float: "left",
        //                 textAlign: "center",
        //                 backgroundColor: "rgba(50,50,50,1)",
        //                 margin: 0,
        //                 marginTop: 1,
        //                 // color:window.colors.itemSelected,
        //                 lineHeight: 2
        //             }}>
        //                 {diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].cve_cie10}
        //             </div>
        //             <div style={stylesDx.title}>
        //                 {diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre} 
        //                 {this.state.hoverIndex == index ? <Icon name='delete' onClick={() => this.deleteItem(item) } onMouseEnter={() => this.setState({ hoverDelete: true })} onMouseLeave={() => this.setState({ hoverDelete: false })} style={{position:'absolute',marginLeft:8,float:'right', color:window.colors.red}}/> :''}
        //             </div>
        //             <div readOnly value={description} style={
        //                 {
        //                     width:"99%",
        //                     float: "right",
        //                     height:"100%",
        //                     color: "gray",
        //                     fontSize: 12,
        //                     padding: 10,
        //                     borderRadius: 0,
        //                     border: 0 ,
        //                     margin: 0,
        //                     marginTop: 1,
        //                     borderBottomLeftRadius: 4,
        //                     height: 58,
        //                     maxHeight : 58,
        //                     maxWidth : "99%",
        //                     minWidth : "99%",
        //                     textAlign:"justify",
        //                     resize: "none",
        //                     color: (this.props.indexSeleccionado == index ? 'white' : 'gray') ,
        //                     backgroundColor: (this.props.indexSeleccionado == index ? this.colorDx(lastDetalle) : 'white'),
        //                 }
        //             }>
        //                 {description}
        //             </div>
        //         </div>
        //     </Container>
        // );
        // }

        // const description = this.stringDescriptionDx(item);
        // const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro}).reverse()[0];

        // return (
        //     <div key={index} style={stylesDx.containerPrint}>
        //         <div style={{
        //             width: "100%",
        //             margin:0,
        //             marginBottom: 5,
        //             padding:0,
        //         }}>
        //             <div style={stylesDx.titlePrint}>
        //                 {`${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].cve_cie10} - ${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre}`}
        //             </div>
        //             <div readOnly value={description} style={
        //                 {
        //                     width:"100%",
        //                     float: "right",
        //                     paddingTop: 0,
        //                     borderRadius: 0,
        //                     border: 0 ,
        //                     margin: 0,
        //                     marginTop: 1,
        //                     textAlign:"justify",
        //                     resize: "none",
        //                 }
        //             }>
        //                 {description}
        //             </div>
        //         </div>
        //     </div>
        // );
    }
}


const stylesDx = {
    container: {
        width: "100%",
        minHeight: 100,
        margin: 0,
        padding: 0,
    },
    containerPrint: {
        width: "100%",
        margin: 0,
        padding: 0,
    },
    subContainer: {
        width: "100%",
        margin: 0,
        marginBottom: 5,
        padding: 0,
        minHeight: 90,
        borderRadius: 4,
        backgroundColor:window.colors.itemSelected,
        boxShadow: '1px 0px 10px rgba(0, 0,0, 0.7)'
    },
    CIE10: {
        width: "6%",
        textBorderLeft: 5,
        height: 30,
        borderTopLeftRadius: 4,
        float: "left",
        textAlign: "center",
        backgroundColor: "rgba(50,50,50,1)",
        margin: 0,
        marginTop: 1,
        // color:window.colors.itemSelected,
        lineHeight: 2
    },
    title: {
        width: "93%",
        float: "right",
        marginTop: 1,
        marginRight: 0,
        height: 30,
        backgroundColor: "rgba(50,50,50,1)",
        color:window.colors.white,
        lineHeight: 2
    },
    titlePrint: {
        width: "100%",
        float: "right",
        marginTop: 1,
        marginRight: 0,
        color:window.colors.itemSelected,
    },
    subTitle: {
        width: "99%",
        float: "right",
        backgroundColor: "rgba(250,250,250,0.96)",
        marginRight: 0,
        marginTop: 1,
        marginBottom: 0,
        padding: 0,
        border: 0,
        borderBottomLeftRadius: 4,
        color: "gray",
        textAlign: "justify",
        fontSize: 15
    },
    textArea: {
        width: "99%",
        float: "right",
        height: "100%",
        color: "gray",
        fontSize: 14,
        padding: 10,
        borderRadius: 0,
        border: 0,
        margin: 0,
        marginTop: 1,
        borderBottomLeftRadius: 4,
        height: 58,
        maxHeight: 58,
        maxWidth: "99%",
        minWidth: "99%",
        textAlign: "justify",
        resize: "none"
    },
    stick: {
        width: "1%",
        height: "100%",
        minHeight: 80,
        height: "100%",
        float: "left",
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        margin: 0,
        border: 0,
        color:window.colors.white,
        verticalAlign: "middle",
        align: "center"
        // lineHeight: "500%"
    },
    line: {
        width: "100%",
        height: 1,
        float: "left",
        backgroundColor: "gray",
    }
};


const mapStateToProps = (state) => {
    const { diagnosticos } = state.Think;
    return { diagnosticos };
};


export default connect(mapStateToProps)(DiagnosticosItem);

import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  firmas: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "black",
    "& .line": {
      flex: "1 1 1px",
      width: "50%",
      backgroundColor: "black",
      marginBottom: theme.spacing(1),
    },
  },
}));

export const menuConfiguracion = [
    {
        id: 'institucion',
        vc_nombre: 'Institución',
        enable: true,
        roles: [2],
        sidebar: {
          open: false,
          enable: false,
        },
        opciones: [
            {
                id: 'generales',
                vc_nombre: 'Generales',
                acciones: [
                    {
                        id: 'guardar',
                        vc_nombre: 'Guardar',
                        icon: null
                    }
                ]
            }
        ]
    },
    {
        id: 'dependencias',
        vc_nombre: 'Dependencias',
        enable: true,
        sidebar: {
          open: false,
          enable: true,
        },
        roles: [2],
    },
    {
        id: 'usuarios',
        vc_nombre: 'Usuarios',
        enable: true,
        sidebar: {
          open: false,
          enable: true,
        },
        roles: [2],
        opciones: [
            {
                id: 'todos',
                vc_nombre: 'Todos',
                acciones: [
                    {
                        id: 'nuevo',
                        vc_nombre: 'Nuevo',
                        icon: null
                    }
                ]
            }
        ]
    },
    {
        id: 'productos',
        vc_nombre: 'Mis Productos',
        enable: true,
        sidebar: {
          open: false,
          enable: false,
        },
        roles: [2],
        opciones: [
            {
                id: 'listado',
                vc_nombre: 'Mis Productos',
                acciones: [
                    {
                        id: 'nuevo',
                        vc_nombre: 'Nuevo',
                        icon: null
                    }
                ]
            },
            {
                id: 'registro',
                vc_nombre: 'Adquirir productos',
                acciones: [
                    {
                        id: 'pagar',
                        vc_nombre: 'Pagar',
                        icon: null
                    }
                ]
            },
            {
                id: 'renovar',
                vc_nombre: 'Renovar productos',
                acciones: [
                    {
                        id: 'pagar',
                        vc_nombre: 'Guardar',
                        icon: null
                    }
                ]
            }
        ]
    },
    {
        id: 'notas',
        vc_nombre: 'Mis Formularios',
        enable: true,
        sidebar: {
          open: false,
          enable: false,
        },
        roles: [5, 2],
        opciones: [
        ]
    },
    {
        id: 'cuentas',
        vc_nombre: 'Cuentas de Pago',
        enable: true,
        sidebar: {
          open: false,
          enable: false,
        },
        opciones: [
            {
                id: 'listado',
                vc_nombre: 'Mis Cuentas de Pago',
                acciones: [
                    {
                        id: 'nuevo',
                        vc_nombre: 'Nuevo',
                        icon: null
                    }
                ]
            }
        ]
    }
  ];

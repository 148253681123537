import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import MoreVert from "@material-ui/icons/MoreVert";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import moment from "moment";
import { Avatar, IconButton } from "@material-ui/core";
import * as firebase from "firebase/app";
import internalAPI from "../utils/internalAPI";
import notifications from "../utils/notifications";

import { getAge } from "app/utils/repository";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderTop: `2px solid ${window.colors.itemSelected}`,
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightMedium,
    alignSelf: "center",
    marginLeft: 30,
  },
  headingMobile: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightLight,
    alignSelf: "center",
    marginLeft: 30,
  },
  header: {
    borderBottom: `2px solid ${theme.palette.primary}`,
    alignItems: "left",
    flexDirection: "row",
  },
  button: {},
  input: {
    display: "none",
  },
}));
const pacienteMuestra = {
  detalle: {
    id_sexo: 1,
    dt_nacimiento: "01-01-2000",
    vc_nombre: "Sergio Emmanuel",
    vc_apellidoPaterno: "Delgado",
    vc_apellidoMaterno: "Félix",
  },
};
const uploadFile = (blob, id_paciente) => {
  console.log("ddddd", blob);
  const timeStamp = moment() * 1;
  const storage = firebase.storage().ref();
  let ref = storage.child(
    `images/ins_pacientes/${id_paciente}/${timeStamp}.${blob.type
      .split("/")
      .pop()}`
  );
  let uploadTask = ref.put(blob);
  uploadTask.on(
    "state_changed",
    function (snapshot) {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          break;
      }
    },
    function (error) {
      console.log("error");
    },
    function () {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        const body = {
          id: id_paciente,
          vc_url: downloadURL,
        };
        internalAPI.editPatientPhoto(body, (error, response) => {
          if (error) {
            notifications.error(`Error: ${error.texto}`);
            this.setState({
              error: error.texto,
              success: false,
              loadingOn: null,
            });
          } else if (response.peticion === true) {
            notifications.success("Se guardo correctamente la imagen");
            this.setState({
              error: "",
              success: true,
              addAddress: false,
              dependentPatient: body,
              loadingOn: null,
            });
          } else if (response.peticion === false) {
            notifications.success(`¡El paciente ya se encuente en Thin-k!`);
          }
        });
      });
    }
  );
};

const sexos = ["No Especificado", "Masculino", "Femenino", "Indistinto"];

export default function PatientsItem(props) {
  let { pacienteActual = pacienteMuestra } = props;
  if (!pacienteActual.detalle) {
    pacienteActual = pacienteMuestra;
  }
  // console.log(pacienteActual)
  const classes = useStyles();
  const onChangeValue = ({ state, inputs }) => {};
  const [image, setImage] = React.useState(null);

  const vc_url = image || pacienteActual.detalle.vc_url || null;

  return (
    <ExpansionPanelSummary
      aria-controls="panel2a-content"
      id="panel2a-header"
      className={classes.header}
    >
      <div>
        <input
          accept="image/*"
          size={50000}
          className={classes.input}
          id="icon-button-file"
          type="file"
          onChange={(e) => {
            console.log("data", e.target.files);

            if (e.target.files) {
              const blob = new File(e.target.files, "vc_url", {
                type: e.target.files[0].type,
              });
              let url = URL.createObjectURL(blob);
              console.log(pacienteActual, "PACIENTEACTUAAAAAAAL");
              console.log(pacienteActual.detalle.id);
              uploadFile(blob, pacienteActual.detalle.id);
              setImage(url);
            }
          }}
        />
        <label htmlFor="icon-button-file">
          <IconButton
            color="primary"
            className={classes.button}
            aria-label="upload picture"
            component="span"
          >
            {" "}
            {vc_url ? (
              <Avatar src={vc_url} className={classes.bigAvatar} />
            ) : (
              <PhotoCamera />
            )}
          </IconButton>
        </label>
      </div>
      <Typography
        className={window.isMobile ? classes.headingMobile : classes.heading}
      >{`Paciente:\n${pacienteActual.detalle.vc_nombre || ""} ${
        pacienteActual.detalle.vc_apellidoPaterno || ""
      } ${pacienteActual.detalle.vc_apellidoMaterno || ""}`}</Typography>
      <Typography
        className={window.isMobile ? classes.headingMobile : classes.heading}
      >{`Sexo: ${sexos[pacienteActual.detalle.id_sexo || 0]}`}</Typography>
      <Typography
        className={window.isMobile ? classes.headingMobile : classes.heading}
      >{`Edad: ${
        getAge(pacienteActual.detalle.dt_nacimiento)
      }`}</Typography>
    </ExpansionPanelSummary>
  );
}

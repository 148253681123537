import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Grid, Typography } from "@material-ui/core";

const Detalle = ({ data }) => {
  return (
    <React.Fragment>
      <Box height={64} spacing={0}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          {data.map((dato, index) => (
            <React.Fragment>
              <Grid
                container
                direction="column"
                xs={2}
                justify="space-around"
                alignItems="center"
                style={{ paddingTop: 8 }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  {dato.label.toUpperCase().replace(':', '')}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {dato.value}
                </Typography>
              </Grid>
              {index!==data.length-1 && (
                <Divider orientation="vertical" flexItem />
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Box>
      <Divider />
    </React.Fragment>
  );
};

Detalle.defaultProps = {
  data: [],
};

Detalle.propTypes = {
  data: PropTypes.array,
};

export default Detalle;

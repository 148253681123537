import proveedores from './proveedores';
import marcas from './marcas';
import unidadesMedida from './unidadesMedida';
import impuestos from './impuestos';
import cajas from './cajas';

export default [
    proveedores,
    marcas,
    unidadesMedida,
    impuestos,
    cajas
]
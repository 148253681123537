import React from "react";
import PropTypes from "prop-types";

import { Box, makeStyles } from "@material-ui/core";

import Day from "./Day";

const useStyles = makeStyles((theme) => {
  return {
    days: {
      width: "calc(100% - 60px)",
      height: "100%",
      marginLeft: "60px",
      display: "flex",
      flexDirection: "row",
      position: "absolute",
      top: 0,
      left: 0,
      padding: "10px 10px 10px 0px",
    },
  };
});

const Index = ({ dias, events, ...props }) => {
  const classes = useStyles();
  const days = dias.map((v) => ({
    ...v,
    detalles: events.filter((e) => e.nu_dia === v.id || e.dt_cita === v.dt_dia),
  }));

  return (
    <Box className={classes.days}>
      {days.map((v, i) => (
        <Day key={i} dia={v} detalles={v.detalles} events={events} {...props} />
      ))}
    </Box>
  );
};

Index.defaultProps = {
  events: [],
  dias: [],
};

Index.propTypes = {
  dias: PropTypes.array,
  events: PropTypes.array,
};

export default Index;

import React from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";

const Table = (props) => {
  const { data, onClick } = props;
  const options = { filter: true, sort: true };

  const columns = [
    {
      name: "nombre",
      label: "Nombre",
      options,
    },
    {
      name: "descripcion",
      label: "Descripcion",
      options,
    },
  ];

  const optionsTable = {
    elevation: 0,
    search: false,
    filter: false,
    fixedSelectColumn: false,
    print: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    onRowClick(rowData, { dataIndex }) {
      onClick(data[dataIndex], dataIndex);
    },
  };
  return (
    <MUIDataTable
      title="Clientes"
      data={data}
      columns={columns}
      options={optionsTable}
    />
  );
};

Table.defaultProps = {
  data: [],
  onClick: () => {},
};

Table.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
};

export default Table;

import React from 'react';
import { Box, Tooltip, Typography, Divider, IconButton } from '@material-ui/core';
import AddAlarm from '@material-ui/icons/AddAlarm';
import moment from 'moment';

class AdministracionesItem extends React.Component {
    constructor() {
        super();
        this.state = {
            ranges: []
        };
    }
    addRange(e) {
        const ranges = this.state.ranges;
        const parent = window.$('#miover');
        const nu_start = e.pageX - parent.offset().left;

        ranges.push({
            start: `${(nu_start / parent.width()) * 100}%`,
            ratio: nu_start / parent.width(),
        });
        this.setState({ ranges })
    }
    onMouseMove(e) {
        const parent = window.$('#miover');
        const nu_start = e.pageX - parent.offset().left;
        const percentaje = (nu_start / parent.width()) * 100;
        this.setState({
            mouse: {
                start: `${percentaje >= 0 ? percentaje : 0}%`,
                ratio: nu_start / parent.width(),
            }
        })
    }
    onMouseLeave(e) {
        this.setState({ mouse: null })
    }
    selectRange(e) {
        e.stopPropagation()
    }
    renderRanges() {
        const baseStart = this.props.baseStart ? moment(this.props.baseStart) : moment(moment().format('YYYY-MM-DD'))
        const baseEnd = this.props.baseEnd ? moment(this.props.baseEnd) : moment(baseStart).add(1, 'day');
        const rangeInner = baseEnd - baseStart
        return this.state.ranges.map((range, index) => {

            const timestamp = (rangeInner * range.ratio) + baseStart;
            return (
                <Tooltip title={
                    <Box style={{ justifyContent: 'center' }} >
                        <Typography style={{ width: '100%', textAlign: 'center' }}>
                            Aplicación:
                        </Typography>
                        <Typography style={{ width: '100%', textAlign: 'center' }}>
                            {range.vc_dosisDescripcion || '400 mg via intravenosa'}
                        </Typography>
                        <Typography style={{ width: '100%', textAlign: 'center' }}>
                            {timestamp ? moment(timestamp).format('DD-MM-YYYY HH:mm') : 'No especificada'}
                        </Typography>
                    </Box>
                } placement='top' arrow style={{ boxShadow: '2px 0px 1px black' }}>
                    <Box onMouseOver={(e) => e.stopPropagation()} onClick={this.selectRange.bind(this)} key={index} draggable style={{ position: 'absolute', left: range.start, height: 40, width: 5, borderRadius: 2, backgroundColor:window.colors.itemSelected, zIndex: 100 }}>

                    </Box>
                </Tooltip>
            )
        })
    }
    renderRangesSugest() {
        return (this.props.sugest || []).map((range, index) => {
            return (
                <Box color='red' onClick={this.selectRange.bind(this)} key={index} draggable style={{ position: 'absolute', left: range.start, height: 40, width: 5, borderRadius: 2, backgroundColor:window.colors.itemSelected }}>
                    <AddAlarm />
                </Box>
            )
        })
    }
    renderMouseOver() {
        if (this.state.mouse) {
            const baseStart = this.props.baseStart ? moment(this.props.baseStart) : moment(moment().format('YYYY-MM-DD'))
            const baseEnd = this.props.baseEnd ? moment(this.props.baseEnd) : moment(baseStart).add(1, 'day');
            const rangeInner = baseEnd - baseStart
            const timestamp = (rangeInner * this.state.mouse.ratio) + baseStart;
            return (
                <Tooltip title={
                    <Box style={{ justifyContent: 'center' }}>
                        <Typography style={{ width: '100%', textAlign: 'center' }}>
                            {moment(timestamp).format('YYYY-MM-DD')}
                        </Typography>
                        <Typography style={{ width: '100%', textAlign: 'center' }}>
                            {moment(timestamp).format('HH:mm')}
                        </Typography>
                    </Box>
                } placement='top' arrow>
                    <Box style={{ position: 'absolute', left: this.state.mouse.start, height: '100%', width: 1, backgroundColor: 'rgba(200,200,200,0.1)' }}></Box>
                </Tooltip>
            )
        }
    }
    renderTitle() {
        
        return (
            <Typography style={{ width: '100%' }} onClick={this.addRange.bind(this)}>
                {this.props.title}
            </Typography>
        )
    }
    render() {
        return (
            <Box padding={1} onMouseOver={() => this.setState({ over: true })} onMouseLeave={() => this.setState({ over: false })}>
                {this.renderTitle()}
                <Box id={'miover'} onMouseMove={this.onMouseMove.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)} style={{ position: 'relative', width: '100%', minHeight: 40, backgroundColor: 'rgba(200,200,200,0.2)', borderRadius: 5, marginBottom: 5 }} onClick={this.addRange.bind(this)}>
                    {this.renderRanges()}
                    {this.renderRangesSugest()}
                    {this.renderMouseOver()}
                </Box>
                <Divider />
            </Box>
        );
    }

    componentDidMount() {
        this.renderRanges.bind(this)
        this.addRange.bind(this)
        this.setState({
            someKey: 'otherValue'
        });
    }
}

export default AdministracionesItem;

import React from "react";
import { connect } from "react-redux";

import { Box, FormControlLabel, Checkbox } from "@material-ui/core";

const mixStatus = (arr1, arr2) =>
  arr1
    .filter((v) => [1, 2, 3, 4, 5, 7].includes(v.id))
    .map((v) => {
      const s = arr2.find((s) => s.id === v.id);
      return { ...v, checked: s ? s.checked : true };
    });

const Index = ({ estatusCitas = [], onStatusChange, status = [] }) => {
  const [estatus, setEstatus] = React.useState(mixStatus(estatusCitas, status));

  const handleCheckboxChange = (id, checked) => {
    setEstatus(estatus.map((e) => (e.id !== id ? e : { ...e, checked })));
  };
  const handleAllChange = (checked) =>
    setEstatus(estatus.map((e) => ({ ...e, checked })));

  React.useEffect(() => {
    if (JSON.stringify(estatus) !== JSON.stringify(status)) {
      onStatusChange(estatus);
    }
  }, [estatus]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={2}
      height="0px"
      overflow="scroll"
      flex="1 1 auto"
    >
      <FormControlLabel
        label="Todos"
        control={
          <Checkbox
            indeterminate={
              estatus.some((v) => v.checked) && estatus.some((v) => !v.checked)
            }
            checked={estatus.every((v) => v.checked)}
            onChange={(e) => handleAllChange(e.target.checked)}
            color="primary"
          />
        }
      />
      {estatus.map((v, i) => (
        <FormControlLabel
          key={i}
          label={v.vc_nombre}
          control={
            <Checkbox
              checked={v.checked}
              onChange={(e) => handleCheckboxChange(v.id, e.target.checked)}
              style={{
                color: v.vc_color,
              }}
            />
          }
        />
      ))}
    </Box>
  );
};

const mapStateToProps = ({ Think: { estatusCitas } }) => ({ estatusCitas });
export default connect(mapStateToProps, {})(Index);

import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField, Box, Menu, Input, IconButton, Divider, Popover, MenuList, InputBase } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import Check from '@material-ui/icons/Check';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: '5px 10px 0px 0px',
        minWidth: 120,
    }
}));

export default props => {
    const classes = useStyles();
    const { required, label, fullWidth, options = [], field, name, fieldid, meta, input, fieldConfig = {} } = props;

    const optionSelected = options.find(e => e.value === props.value);
    const [value, setValue] = React.useState(optionSelected || { value: null, label: '' });
    const [prevValueProps, setSttributes] = React.useState(null);
    const [attributes, setPrevValueProps] = React.useState(null);
    const [searchOptions, setSearch] = React.useState({ searchText: '', options: options.filter((e, index) => index < 100) });
    const onChangeCapture = (event) => {
        const filterOptions = options.filter(e => e.label.toLowerCase().includes(event.target.value.toLowerCase())).filter((e, index) => index < 50);
        setSearch({ searchText: event.target.value, options: filterOptions });
        event.stopPropagation();
    }
    const getNameOption = (item, relation) => {
        let display = '';
        relation.fieldsDisplay.map((field, index) => {
            let itemEdit = item;
            field.split('.').map((key, i) => {
                itemEdit = itemEdit[key]
            })
            display = `${display}${itemEdit || ''}${relation.fieldsDisplaySeparators[index] || ''}`
        })
        return display;
    }
    const onChangeCaptureAsync = (event) => {
        const { get } = fieldConfig.relation || {};
        if (get) {
            setSearch({ ...searchOptions, loading: true, searchText: event.target.value });
            const headers = { Authorization: window.amplify.store('token') };
            const body = { [`${get.searchKey}`]: event.target.value };
            (get.parameters || []).forEach((parameter, index) => {
                if (parameter.type === 'fieldValue') {
                    const field = document.getElementById(parameter.field);
                    // console.log('campo', field)
                    // console.log('value', field.value)
                    body[`${parameter.key}`] = field.value || null;
                }

                if (parameter.type === 'fixed') {
                    body[`${parameter.key}`] = parameter.value
                }

            })

            window.$.ajax({
                url: `${get.backEndURL}${get.vc_url}`,
                data: body,
                method: get.method || 'POST',
                headers
            }).then((data, status, xhr, error) => {
                if (data) {
                    let options = [];
                    data.map((item) => {
                        options.push({
                            value: item[fieldConfig.relation.keyTable],
                            label: getNameOption(item, fieldConfig.relation)
                        })
                    });
                    // const filterOptions = options.filter(e => e.label.toLowerCase().includes(body[get.searchKey].toLowerCase())).filter((e, index) => index < 50);
                    // setSearch({ options: filterOptions, loading: false });
                    setSearch({ options: options.slice(0, 50), loading: false });
                    const optionSelected = options.find(e => e.value === props.value);
                    if (optionSelected) {
                        setValue(optionSelected)
                    }
                } else if (data.error) {
                    console.log('error ----->', data.error)
                    setSearch({ options: [], loading: false });
                } else {
                    setSearch({ options: [], loading: false });
                }
            }).fail((data, status, xhr, error) => {
                if (data.statusText) {
                    setSearch({ options: [] });
                }
            });
        } else {
            setSearch({ searchText: event.target.value, options: [] });
        }

    }
    const getOptionsLocalStorage = (prevProps) => {
        let options = [];
        let data = [];
        console.log('hola')
        if (fieldConfig.relation && fieldConfig.relation && fieldConfig.relation.localStorage) {
            let localStorage = window.amplify.store(fieldConfig.relation.localStorage.basePath);
            if (localStorage.length > -1) {
                data = [...localStorage]
            }
            fieldConfig.relation.localStorage.paths.map((path, index) => {
                const internValue = localStorage[path];
                if (internValue.length > -1) {
                    data = [...internValue]
                }
                localStorage = internValue;
            })
            data.map((item) => {
                options.push({
                    value: item[fieldConfig.relation.keyTable],
                    label: getNameOption(item, fieldConfig.relation)
                })
            });
            const option = (options || []).find(e => e.value === prevProps.value);
            setPrevValueProps(props.value)
            setSearch({ options });
            setValue(option || { value: null, label: '' })
        }
    }
    useEffect(() => {
        if (fieldConfig.relation && fieldConfig.relation.get) {
            onChangeCaptureAsync({ target: { value: searchOptions.searchText } })
        }

        // if (!!(fieldConfig.relation && fieldConfig.relation.localStorage)) {
        //     getOptionsLocalStorage(props);
        // }
        if (props.value != value.value && props.value != prevValueProps && props.value) {
            const option = options.find(e => e.value === props.value);
            setPrevValueProps(props.value)
            setValue(option || { value: null, label: '' })
        }

        if (input) {
            const optionSelected = options.find(e => e.value === input.value);
            setValue(optionSelected || { value: null, label: '' })
        }

    }, [props])
    const [anchorEl, setAnchorEl] = React.useState(null);
    function handleClick(event) {
        if (fieldConfig.relation && fieldConfig.relation.get) {
            onChangeCaptureAsync({ target: { value: searchOptions.searchText } })
        } else if (!!(fieldConfig.relation && fieldConfig.relation.localStorage)) {
            getOptionsLocalStorage(props);
        } else {
            setSearch({ searchText: '', options: searchOptions.options.filter((e, index) => index < 100) });
        }
        const currentTarget = document.getElementById(fieldid);
        setAnchorEl(currentTarget);
    }
    function handleClose() {
        setSearch({ searchText: '', options: searchOptions.options.filter((e, index) => index < 100) });
        setAnchorEl(null);
    }
    let labelModified = !required ? label : `${label} *`;
    return (
        <FormControl
            variant="outlined"
            required={required}
            fullWidth={fullWidth}
            className={classes.formControl}
            key={fieldid}
            value={value.value}
            field={field}
            name={name}
        >
            <div style={{ width: 0, height: 0 }}>
                <TextField
                    key={fieldid}
                    id={fieldid}
                    value={value.value || 'hola'}
                    field={field}
                    name={name}
                    disabled
                    style={{ width: 0, height: 0 }}
                />
            </div>
            {
                props.naked ?
                    <InputBase
                        variant='outlined'
                        label={labelModified}
                        onClick={handleClick}
                        fullWidth={fullWidth}
                        value={value.label}
                        autoFocus={false}
                        readOnly={true}
                        endAdornment={<ArrowDropDown />}
                        placeholder={props.label || 'Sin selección'}
                        style={{ paddingLeft: 8 }}
                        inputProps={{ style: { padding: 8, width: '100%', backgroundColor: 'rgba(200,200,200,0.2)', borderRadius: 5 } }}
                    /> :
                    <TextField
                        variant='outlined'
                        label={labelModified}
                        onClick={handleClick}
                        fullWidth={fullWidth}
                        value={value.label}
                        autoFocus={false}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <ArrowDropDown />,
                            placeholder: 'Sin selección'
                        }}
                    />
            }
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                PaperProps={{
                    style: {
                        overflow: 'hidden'
                    }
                }}
            >
                <Box style={{ outline: 'none', position: 'absolute', top: 0, left: 0, height: 50, width: '100%' }}>
                    <Input
                        autoFocus
                        placeholder={value.label}
                        onClick={(e) => e.stopPropagation()}
                        fullWidth
                        disableUnderline
                        onChangeCapture={fieldConfig.relation && fieldConfig.relation.get ? onChangeCaptureAsync : fieldConfig.relation && fieldConfig.relation.localStorage ? getOptionsLocalStorage : onChangeCapture}
                        startAdornment={
                            <Search style={{ marginRight: 8 }} />
                        }
                        endAdornment={
                            <div style={{ width: 20, height: 20, padding: 3 }}>
                                {
                                    searchOptions.loading &&
                                    <CircularProgress size={15} color='secondary' />
                                }
                            </div>
                        }
                        style={{padding: '10px 16px 0px 16px'}}
                    />
                    <Divider variant='fullWidth' />
                </Box>
                <MenuList autoFocus={false} style={{
                    height: 240,
                    marginTop: 50,
                    overflowY: 'scroll'
                }}>
                    {
                        !searchOptions.loading &&
                        searchOptions.options.map((option, index) => {
                            return (
                                <MenuItem selected={option.value === value.value} button onClick={() => {
                                    setValue({ value: option.value, label: option.label });
                                    setAnchorEl(null);
                                    props.onChange && props.onChange(option)
                                }} key={index} label={option.label} value={option.value}
                                    style={{ width: '100%' }}
                                >
                                    <Box width={'100%'}>
                                        <div style={{ width: 0, height: 0, opacity: 0 }}>
                                            {'-'}
                                        </div>
                                        {option.label}
                                        {
                                            option.value === value.value ?
                                                <Check color='primary' style={{ float: 'right' }} />
                                                : null
                                        }
                                    </Box>
                                </MenuItem>
                            )
                        })
                    }
                    <MenuItem onClick={() => {
                        setValue({ value: null, label: '' });
                        props.onChange && props.onChange({ value: null, label: '' })
                        setAnchorEl(null);
                    }}>
                        <em>* Sin selección</em>
                    </MenuItem>
                </MenuList>
            </Popover>
        </FormControl>

    );
}
import { SELECT_MODULE, SELECT_MEETING } from './types';


export const selectModule = (moduleName) => ({
	type: SELECT_MODULE, payload: moduleName
});

// Selecciona una Cita para ser manipulada
export const selectMeeting = (meetingSelected) => ({
	type: SELECT_MEETING, payload: meetingSelected
});
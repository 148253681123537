import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { TextField, makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  outlinedInput: {
    padding: '8px 14px'
  },
  inputLabel: {
    transform: 'translate(14px, 10px) scale(1)'
  }
}));

export default function SwitchLabels(props) {
  const textTransform = (textTransform, text) => {
    switch (textTransform) {
      case 'uppercase': {
        return text.toUpperCase()
      }
      case 'capitalize': {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
      }
      default: {
        return text;
      }
    }
  }
  const classes = useStyles();
  const { fullWidth, label, meta = {}, validation = () => { }, ...moreInputProps } = props;

  const inputProps = Object.assign({
    autoComplete: 'off',
    autoCorrect: 'off',
    spellCheck: 'off',
    focus: null,
    className: classes.outlinedInput,
    style: { textTransform: props.texttransform || null }
  }, moreInputProps, { label: moreInputProps.required ? `${label} *` : label });

  const [value, setValue] = React.useState(props.defaultValue || 0);

  const handleChange = event => {
    setValue(event.target.checked ? 1 : 0);
  };

  return (
    <TextField
    variant={props.variant || 'outlined'}
    fullWidth={props.fullWidth}
    readOnly
    value={props.label}
    InputProps={{
      endAdornment: <Switch size='small' color='primary' inputProps={{id: props.fieldid}} checked={!!value} onChange={handleChange} value={value} />,
    }}
    style={{margin: '9px 9px 9px 0px'}}
    />
  )
  return (
    <FormGroup fullWidth={props.fullWidth || false} row>
      <FormControlLabel
        labelPlacement='start'
        control={
          <Switch color='primary' inputProps={{id: props.fieldid}} checked={!!value} onChange={handleChange} value={value} />
        }
        label={props.label}
      />
    </FormGroup>
  );
}
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import Box from '@material-ui/core/Box';
import { menuAcademy, menuInicio } from '../../containers/Think/listados';
import { resetHelperSidebarRender, setHelperSidebarRender, setState } from '../../containers/Think/actions';
import Simulador from '../components/Simulador';
import notifications from '../../utils/notifications';
const modulo = 'Academy'

class Academy extends React.Component {
  constructor(props) {
    super(props);

    this.state = { activeStep: 0, data: {}, submitting: false, moduloActual: 'simulador', opcion: 'enarm' };

    this.handlePrevStep = this.handlePrevStep.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.updateData = this.updateData.bind(this);
    this.validateLogin = this.validateLogin.bind(this);
  }

  componentDidMount() {
    this.props.setActiveView('simulador');
    this.props.setViewTitle('¡Bienvenido!');
    this.setMenuOptions();
    if (!this.props.state) {
      this.props.setState({ modulo, state: this.state })
    }
  }
  
  renderContent() {
    switch (this.state.moduloActual) {
      case 'simulador':
        return <Simulador/>;
      default:
        return <Box />
    }
  }
  validateLogin() {
    if (window.amplify.store('token') != null) {
      return true;
    }
    return false;
  }

  setMenuOptions() {
    const options = menuAcademy.map((option) => ({
      ...option,
      onClick: this.onChangeModule.bind(this),
    }));

    this.props.setSubMenuOptions(options);
  }
  onChangeModule(modulo) {
    if (this.validateLogin()) {
      this.props.setViewTitle(modulo.vc_nombre);

      this.setState({ moduloActual: modulo.id }, () => {
        //this.updateHelperSidebar();
      });
    } else if (modulo.id === 'gpc') {
      notifications.success('¡Registrate gratis para acceder a las Guias!');
    } else {
      notifications.success('¡Registrate con un plan para acceder a todas las herramientas!');
    }
  }
  renderDropdownMenu(dropItems) {
    let finalItems = [];
    const { moduloActual } = this.state;
    const menuFind = menuInicio.find(mn => mn.id === moduloActual);

    if (moduloActual === 'configuracion') {
      finalItems = menuFind ? menuFind.opciones : [];
    } else {
      finalItems = dropItems ? dropItems.opciones : [];
    }

    return finalItems.map((opcion, index) => (
      <Dropdown.Item
        key={index}
        onClick={() => this.onChangeModule(moduloActual, opcion, 'agregar')}
      >
        {opcion.vc_nombre}
      </Dropdown.Item>
    ));
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.submitting && this.state.submitting) {
      const successStep = document
        .getElementById('institution')
        .dispatchEvent(new Event('submit', { cancelable: true }))
      if (!successStep) {
        this.setState({ submitting: false })
      };
    }
  }

  handlePrevStep() {
    this.setState({ activeStep: this.state.activeStep - 1, submitting: false });
  }

  handleNextStep() {
    this.setState({ activeStep: this.state.activeStep + 1, submitting: false });
  }

  updateData(data, cb) {
    this.setState({ data: Object.assign({}, this.state.data, data) }, cb);
  }
  render() {
    return (
      <div style={{ height: '100%', backgroundColor: 'rgba(200,200,200,0.1' }}>
          {this.renderContent()}
      </div>
    );
  }
}
Academy.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  updateCatalogs: PropTypes.func,
  setState: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  return {
    state: state.Think[modulo] || null,
    catalogos
  };
};


const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState
};


export default connect(mapStateToProps, actions)(Academy);

import React from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import { getAge } from "app/utils/repository";

import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import internalApi from "app/utils/internalAPI";

const { reportes } = internalApi;

const CustomToolbar = ({ value, onChange }) => {
  const [pacientes, setPacientes] = React.useState(false);
  const [medicamentos, setMedicamentos] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setPacientes(false);
    setMedicamentos(false);

    if (value === "pacientes") setPacientes(true);
    if (value === "medicamentos") setMedicamentos(true);
  }, [value]);

  return (
    <React.Fragment>
      <Tooltip title={"Custom List"}>
        <IconButton onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem button onClick={(e) => onChange("pacientes")}>
          <ListItemText primary="Pacientes" />
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              checked={pacientes}
              color="primary"
              onChange={(e) => onChange("pacientes")}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem button onClick={() => onChange("medicamentos")}>
          <ListItemText primary="Medicamentos" />
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              checked={medicamentos}
              color="primary"
              onChange={(e) => onChange("medicamentos")}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </Menu>
    </React.Fragment>
  );
};

const Index = (props) => {
  const [data, setData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [type, setType] = React.useState("pacientes");
  const options = { filter: true, sort: true };

  React.useEffect(() => {
    (async () => {
      const dt_inicio = moment(props.range[0]).format("YYYY-MM-DD");
      const dt_fin = moment(props.range[1]).format("YYYY-MM-DD");
      const institucion = window.amplify.store("institution");
      try {
        const res = await reportes.getReporte("hojadiaria", {
          dt_inicio,
          dt_fin,
          id_institucion: institucion?.id_institucion || 0,
        });

        const data = res.notas.map((v) => {
          const txs = res.medicamentos.filter((m) => m.id_nota === v.id);
          const vc_tratamiento = txs
            .map((v) => v.vc_medicamento || v.vc_nombre)
            .join(" ");
          const dxs = res.diagnosticos.filter((d) => d.id_notaActual === v.id);
          const dxsO = dxs.filter((d) => d.id_notaActual === d.id_notaOrigen);
          const vc_diagnostico = dxsO.map((d) => d.vc_nombre).join(" ");

          return {
            ...v,
            vc_edad: getAge(v.dt_nacimiento),
            vc_diagnostico,
            vc_tratamiento,
            medicamentos: txs,
            diagnosticos: dxs,
          };
        });

        setData(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [props.range]);

  React.useEffect(() => {
    console.log(type);
    switch (type) {
      case "medicamentos":
        const aux = data.reduce((pv, cv) => {
          let row = [cv];
          if (cv.medicamentos.length > 1) {
            row = cv.medicamentos.map((v) => ({
              ...cv,
              vc_tratamiento: v.vc_medicamento || v.vc_nombre,
            }));
          }
          return [...pv, ...row];
        }, []);
        setRows(aux);
        break;

      default:
        setRows(data);
        break;
    }
  }, [type, data]);

  const columns = [
    // {
    //   name: "id",
    //   label: "Id",
    //   options,
    // },
    {
      name: "vc_nombrePaciente",
      label: "Paciente",
      options,
    },
    {
      name: "vc_genero",
      label: "Genero",
      options,
    },
    {
      name: "vc_edad",
      label: "Edad",
      options,
    },
    {
      name: "vc_nombreMedico",
      label: "Medico",
      options,
    },
    {
      name: "vc_diagnostico",
      label: "Diagnostico",
      options,
    },
    {
      name: "vc_tratamiento",
      label: "Tratamiento",
      options,
    },
    {
      name: "dt_registro",
      label: "Fecha",
      options,
    },
  ];

  const optionsTable = {
    elevation: 0,
    filter: false,
    fixedSelectColumn: false,
    // print: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    // onRowClick(rowData, { dataIndex, rowIndex }) {
    //   props.onClick(data[dataIndex], dataIndex);
    // },
    expandableRows: true,
    customToolbar: () => {
      return <CustomToolbar value={type} onChange={setType} />;
    },
    renderExpandableRow(rowData, rowMeta) {
      const row = data[rowMeta.dataIndex];
      return (
        <React.Fragment>
          <tr>
            <td colSpan={columns.length + 1}>
              <Box p={2} px={3}>
                <Grid container>
                  <Grid item xs={6}>
                    <List
                      subheader={<ListSubheader>Diagnosticos</ListSubheader>}
                    >
                      {row.diagnosticos.map((v) => (
                        <ListItem>
                          <ListItemText primary={v.vc_nombre} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List
                      subheader={<ListSubheader>Tratamientos</ListSubheader>}
                    >
                      {row.medicamentos.map((v) => (
                        <ListItem>
                          <ListItemText
                            primary={v.vc_medicamento || v.vc_nombre}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
            </td>
          </tr>
        </React.Fragment>
      );
    },
  };

  return (
    <Box 
    style={{overflow: 'scroll', height: '100%'}}
    >
      <MUIDataTable
        title="Hoja Diaria"
        data={rows}
        columns={columns}
        options={optionsTable}
      />
    </Box>
  );
};

export default Index;

import React, { Component } from 'react';
import { Grid, Popup, List, Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FadeInView } from '../../../../../../components';

const { Column } = Grid;


class BotonesAccion extends Component {
  onDelete() {
    this.props.onDeleteRol(this.props.data);
  }

  // ----------------------
  // --- render methods ---
  // ----------------------
  render() {
    const triggerPopup = (
      <Button icon style={{ background: 'transparent' }}>
        <Icon name="ellipsis vertical" />
      </Button>
    );

    return (
      <Grid>
        <Column>
          <Popup trigger={triggerPopup} flowing hoverable on="click">
            <FadeInView>
              <Grid centered>
                <Column>
                  <List selection>
                    <List.Item onClick={this.onDelete.bind(this)}>
                      <List.Icon name="minus circle" />
                      <List.Content>Eliminar</List.Content>
                    </List.Item>
                  </List>
                </Column>
              </Grid>
            </FadeInView>
          </Popup>
        </Column>
      </Grid>
    );
  }
}


BotonesAccion.propTypes = {
  data: PropTypes.object,
  onDeleteRol: PropTypes.func,
};


export { BotonesAccion };

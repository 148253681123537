import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar } from '../../../actions';
import Listado from './listado';
import Formulario from './formulario';
import SubMenuModulos from '../../../../../material-ui/SubMenuModulos';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import { IconButton } from '@material-ui/core';

class ConfiguracionAgendas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opcion: 'listado',
            data: {},
            searchText: '',
        };

        this.changeState = this.changeState.bind(this);
    }

    render() {
        return (
            <div style={{ position: 'relative', top: 0, left: 0, width: '100%', height: window.innerHeight - 100, overflowY: 'scroll', WebkitOverflowScrolling: 'touch', padding: 0 }}>
                {this.renderHeaderMenu()}
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        const { opcion } = this.state;

        switch (opcion) {
            case 'listado':
                return <Listado searchText={this.state.searchText} changeState={this.changeState} />;

            case 'agregar':
                return <Formulario changeState={this.changeState} />;

            case 'editar':
                return <Formulario changeState={this.changeState} data={this.state.data} />;

            default:
                return null;
        }
    }

    renderHeaderMenu() {
        const { opcion } = this.state;
        switch (opcion) {
            case 'listado':
                return (<SubMenuModulos
                    type='search'
                    searchText={this.state.searchText}
                    onChange={this.search.bind(this)}
                    rightIcon={
                        <IconButton
                            onClick={() => {
                                this.setState({ opcion: 'agregar', data: { horarios: [{ detalle: [] }] } })
                            }}
                        >
                            <PlaylistAdd />
                        </IconButton>
                    }
                />)
            case 'agregar':
            case 'editar':
                return null
                return (<SubMenuModulos
                    back={() => { this.changeState({ opcion: 'listado' }) }}
                    actions={[
                        {
                            vc_nombre: 'Guardar', action: () => {
                                const successStep = document
                                    .getElementById('agenda')
                                    .dispatchEvent(new Event('submit', { cancelable: true }))
                                if (!successStep) {
                                    console.log('se cargo')
                                    this.setState({ submitting: false })
                                } else {
                                    console.log('no se cargo')
                                }
                            }
                        }
                    ]}
                />)
        }
    }

    componentDidMount() {
        this.props.setHelperSidebarRender(() => null);
        this.props.setDisplaySidebar(false);
    }

    changeState({ opcion, data = {} }, callback = () => { }) {
        this.setState({ opcion, data }, callback);
    }

    search(event) {
    }
}


ConfiguracionAgendas.propTypes = {
    viewTitle: PropTypes.string,
    setViewTitle: PropTypes.func,
    setActiveView: PropTypes.func,
    setHelperSidebarRender: PropTypes.func,
    resetHelperSidebarRender: PropTypes.func,
    setSubMenuOptions: PropTypes.func,
    setActiveView: PropTypes.func,
    setDisplaySidebar: PropTypes.func
};


const mapStateToProps = (state) => {
    const { catalogos } = state.Think;

    return {
        catalogos
    };
};


const actions = {
    setHelperSidebarRender,
    resetHelperSidebarRender,
    setDisplaySidebar
};


export default connect(mapStateToProps, actions)(ConfiguracionAgendas);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Icon, Input } from 'semantic-ui-react';
import HelpBarThink from '../../../../material-ui/HelpBarThink';

class BuscadorEstudiosHelpBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: "",
            indexHover: null,
            indexSelected: this.props.indexSelected,
            opcionPreSelected: null,
        };
    }

    componentDidMount() {
        document.onkeypress = this.modifyEnterKeyPressAsTab.bind(this);
        document.onkeydown = this.pressArrows.bind(this);
    }

    componentDidUpdate() {
        if (document.activeElement != window.$('#searchBar')) {
            //window.$('#searchBar').focus();
        }
    }
    pressArrows(e){
        let opcionPreSelected = this.state.opcionPreSelected;
        if ( e.keyCode == 38 ) {
            if ( opcionPreSelected == null || opcionPreSelected == 0 ) {
                opcionPreSelected = 0;
            }else{
                opcionPreSelected = (opcionPreSelected - 1);
            }
        }else if ( e.keyCode == 40 ) {
            if ( opcionPreSelected == null ) {
                opcionPreSelected = 0;
            }else if ( this.opcionesArray().length > (opcionPreSelected + 1) ) {
                opcionPreSelected = (opcionPreSelected + 1);
            }
        }
        this.setState({opcionPreSelected});
    }
    modifyEnterKeyPressAsTab(e) {
        if (window.event && window.event.keyCode == 13) {
            switch ( this.props.opcionEstudios ) {
                case 'agregar':
                    if ( this.state.opcionPreSelected != null ) {
                        const item = this.opcionesArray()[this.state.opcionPreSelected];
                        this.add(item,null);
                    }
                break;
                case 'prioridad':
                    if ( this.state.opcionPreSelected != null ) {
                        const item = this.opcionesArray()[this.state.opcionPreSelected];
                        this.setPrioridad(item,null);
                    }
                break;
                case 'observaciones':
                    if ( this.state.searchText / 1 > 0 ) {
                        const item = {
                            vc_nombre: `${this.state.searchText / 1}`,
                            valor: `${this.state.searchText / 1}`
                        };
                        this.setObservaciones(item, null);
                    }
                break;
            };
        }
    }
    // descripcion estudio
    timeSince(refDate, date) {

        var seconds = Math.floor((refDate - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
        return interval + " años";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
        return interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
        return interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
        return interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
        return interval + " minutos";
        }
        return "un instante";
    }
    statusDxToString(id_estatusDiagnostico){
        switch (id_estatusDiagnostico) {
            case 1:
            return "presuntivo";
            case 2:
            return "definitivo ó confirmado";
            case 3:
            return "descartado";
            default:
            return "presuntivo";
        }
    }
    isDxActive(dt_vigencia){
        if ( (new Date(dt_vigencia) < (new Date())) ) {
            return false
        }
        return true
    }
    //
    onChangeValue(event) {
        this.setState({ searchText: event.target.value });
    }
    searchBarEvent(event) {
        let opcionPreSelected = null;
        if ( this.opcionesArray().filter(e => e.vc_nombre.toLowerCase().includes(event.target.value.toLowerCase()) ).filter((e, index) => index < 30 ).length > 0 ) {
            opcionPreSelected = 0;
        }else{
            opcionPreSelected = null
        }
        this.setState({ searchText: event.target.value, opcionPreSelected });
    }
    add(item){
        this.setState({searchText:''},() => this.props.callback({ item, opcionesModuloNext: 'agregar' }));
    }
    setPrioridad(item){
        const estudio = this.props.estudio;
        estudio.id_prioridad = item.valor;
        this.setState({searchText:''}, () => this.props.callback({ estudio , opcionesModuloNext: 'observaciones' }));
    }
    setObservaciones(item){
        const estudio = this.props.estudio;
        estudio.vc_observaciones = item.valor;
        this.setState({searchText:''}, () => this.props.callback({ estudio , opcionesModuloNext: 'siguienteEstudio' }));
    }
    titleHelpBar() {
        switch ( this.props.opcionEstudios ) {
            case "historial":
            return 'Conciliaciones';
            case "prioridad":
            return 'Prioridad';
            case "observaciones":
            return 'Observaciones';
            default:
            return 'Agregar Estudio'
        };
    }
    quitaacentos(s) {
        var r = s.toLowerCase();
                    r = r.replace(new RegExp(/\s/g),"");
                    r = r.replace(new RegExp(/[àáâãäå]/g),"a");
                    r = r.replace(new RegExp(/[èéêë]/g),"e");
                    r = r.replace(new RegExp(/[ìíîï]/g),"i");
                    r = r.replace(new RegExp(/ñ/g),"n");
                    r = r.replace(new RegExp(/[òóôõö]/g),"o");
                    r = r.replace(new RegExp(/[ùúûü]/g),"u");
         return r;
        }
    opcionesArray() {
        const { date, estudios, gruposEstudios, paquetesEstudios, gruposEstudios_estudios, paquetesEstudios_estudios_grupos, opcionEstudios, opcion } = this.props;
        let opciones = [];
        let i = 0;
        switch ( opcionEstudios ) {
            case "agregar":
            let lista = [];
            switch ( opcion ) {
                case 'laboratorio':
                estudios.filter((e, index) => e.id_tipoEstudio == 1 ).map((item,index) => {
                    item.vc_componente = 'estudio'
                    lista.push(item)
                });
                gruposEstudios.filter((e, index) => e.id_tipoEstudio == 1 ).map((item,index) => {
                    item.vc_componente = 'grupo'
                    lista.push(item)
                });
                break;
                case 'imagenologia':
                estudios.filter((e, index) => e.id_tipoEstudio == 2 ).map((item,index) => {
                    item.vc_componente = 'estudio'
                    lista.push(item)
                });
                gruposEstudios.filter((e, index) => e.id_tipoEstudio == 2 ).map((item,index) => {
                    item.vc_componente = 'grupo'
                    lista.push(item)
                });
                break;
                case 'patologia':
                estudios.filter((e, index) => e.id_tipoEstudio == 3 ).map((item,index) => {
                    item.vc_componente = 'estudio'
                    lista.push(item)
                });
                gruposEstudios.filter((e, index) => e.id_tipoEstudio == 3 ).map((item,index) => {
                    item.vc_componente = 'grupo'
                    lista.push(item)
                });
                break;
                case 'todos':
                estudios.map((item,index) => {
                    item.vc_componente = 'estudio'
                    lista.push(item)
                });
                gruposEstudios.map((item,index) => {
                    item.vc_componente = 'grupo'
                    lista.push(item)
                });
                paquetesEstudios.map((item,index) => {
                    item.vc_componente = 'paquete'
                    lista.push(item)
                });
                break;
            };
            return lista.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) ).filter((e, index) => index < 60 );
            break;
            case "prioridad":
            return [
                {
                    vc_nombre:"Normal",
                    valor:1,
                    style: this.props.indexSeleccionado == 0 ? styles.containerSelectedHelp : styles.containerHelp
                },
                {
                    vc_nombre:"Urgente",
                    valor:2,
                    style: this.props.indexSeleccionado == 1 ? styles.containerSelectedHelp : styles.containerHelp
                },
                {
                    vc_nombre:"Inmediata",
                    valor:3,
                    style: this.props.indexSeleccionado == 2 ? styles.containerSelectedHelp : styles.containerHelp
                }
            ].filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            break;
            case "observaciones":
            return [
                {
                    vc_nombre:"En ayuno",
                    valor:1,
                    style: this.props.indexSeleccionado == 0 ? styles.containerSelectedHelp : styles.containerHelp
                }
            ].filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            break;
        };
    }
    iconTipoEstudio(item) {
        if ( item.id_tipoEstudio ) {
            switch ( item.id_tipoEstudio ) {
                case 1:
                return 'lab'
                break;
                case 2:
                return 'id badge'
                break;
                case 3:
                return 'lab'
                break;
            }
        }else{
            return 'cubes'
        }
    }
    optionsAction() {
        const { opcionEstudios } = this.props;
        switch ( opcionEstudios ) {
            case "agregar":
            return this.add;
            case "prioridad":
            return this.setPrioridad;
            default:
            return null;
        };
    }
    // renderOpcionesHelpBar() {
    //     const { date, estudios, gruposEstudios, paquetesEstudios, gruposEstudios_estudios, paquetesEstudios_estudios_grupos, opcionEstudios } = this.props;
    //     let opciones = [];
    //     let i = 0;
    //     switch ( opcionEstudios ) {
    //         case "agregar":
    //         return (
    //             this.opcionesArray().map((item,index) =>
    //                 <div onClick={() =>  this.add(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
    //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
    //                         {item.vc_nombre}
    //                     </div>
    //                     <div style={styles.subTitleHelp}>
    //                         {item.vc_component}
    //                     </div>
    //                     <div style={this.state.opcionPreSelected == index ? styles.subTitleSelectedRightHelp : styles.subTitleRightHelp }>
    //                         <Icon name={this.iconTipoEstudio(item)}/>
    //                     </div>
    //                 </div>
    //             )
    //         );
    //         case "prioridad":
    //         return (
    //             this.opcionesArray().map((item,index) =>
    //                 <div key={index} style={styles.containerHelp}>
    //                     <div style={styles.descriptionHelp}>
    //                         {item.vc_nombre}
    //                     </div>
    //                 </div>
    //             )
    //         );
    //     };
    // }

    render() {
        return (
            <HelpBarThink
            indexSeleccionado={this.state.opcionPreSelected}
            header={this.titleHelpBar()}
            searchBar
            options={this.opcionesArray()}
            optionsAction={this.optionsAction()}
            />
        )
        // return (
        //     <Container key={index} style={{...styles.container, height: this.props.pageSize.height}}>
        //         {this.renderTitleHelpBar()}
        //         <div 
        //         id='scrollOptions'
        //         style={{
        //             ...styles.containerSub,
        //             height: this.props.pageSize.height - 111
        //         }}>
        //             {this.renderOpcionesHelpBar(results)}
        //             <div style={{ textAlign: 'center', alignContent: 'center', color:window.colors.textArea, padding: 10}}>
        //                 { results.length === 1 ? ' Solo 1 resultado encontrado.': `${results.length} resultados encontrados.` }
        //             </div>
        //         </div>
        //     </Container>
        // );
    }
}


const styles = {
	container: {
		overflowX: 'hidden',
		overflowY: 'hidden',
        width: '100%',
        height: '100%',
        margin: 0,
		padding: 0
    },
    containerSub: {
		overflowX: 'hidden',
		overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
        width: '100%',
        height: 'calc(100% - 111px)',
        margin: 0,
		padding: 0
    },
    containerHelp: {
        margin:0,
        marginBottom:2,
        borderRadius:3,
        overflowY: "hidden",
        padding: 10,
        minHeight: 40,
        color: "black",
        backgroundColor:window.colors.white
    },
    containerSelectedHelp: {
        margin:0,
        marginBottom:2,
        borderRadius:3,
        overflowY: "hidden",
        padding: 10,
        minHeight: 40,
        marginBottom: 2,
        color: "white",
        backgroundColor:window.colors.itemSelected
    },
    descriptionHelp: {
        margin:0,
        marginTop:5,
        marginLeft:10,
        marginRight:10,
        textAlign: "left",
        fontSize: 14,
        minHeight: 40,
        color: "gray"
    },
    descriptionSelectedHelp: {
        margin:0,
        marginTop:5,
        marginLeft:10,
        marginRight:10,
        textAlign: "left",
        fontSize: 14,
        minHeight: 40,
        color: "white"
    },
    stickHelp: {
        margin:0,
        marginLeft:"85%",
        marginBottom: 2,
        width: 30,
        overflowY: "hidden",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color:window.colors.itemSelected
    },
    subTitleHelp: {
        margin:0,
        marginLeft:10,
        marginRight:10,
        width: "100%",
        overflowY: "scroll",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color: "black"
    },
    subTitleRightHelp: {
        margin:0,
        marginLeft:10,
        marginRight:10,
        width: "100%",
        paddingTop: 0,
        textAlign: "right",
        float: "right",
        fontSize: 12,
        color:window.colors.itemSelected
    },
	titleHelp:{
        width:"100%",
        padding:3,
        height: 37,
        color: "white",
    }
};


const mapStateToProps = (state) => {
  const { estudios, gruposEstudios, paquetesEstudios, gruposEstudios_estudios, paquetesEstudios_estudios_grupos, pacienteActual } = state.Think;
  return { estudios, gruposEstudios, paquetesEstudios, gruposEstudios_estudios, paquetesEstudios_estudios_grupos, pacienteActual };
};


export default connect(mapStateToProps)(BuscadorEstudiosHelpBar);

import React from "react";
import { connect } from "react-redux";
import ThinkForm from "../../../material-ui/ThinkForm";
import ModalPaymentWizard from "./ModalPaymentWizard";
import _ from "lodash";
import { Box, Button, Modal, Paper } from "@material-ui/core";
import { database, auth } from "firebase";
import PaypalPays from "app/components/PaypalPays/test";
import internalAPI from "app/utils/internalAPI";
import { login } from "app/containers/Think/actions";
import { FormatListBulletedOutlined } from "@material-ui/icons";

const Index = (props) => {
  const [app] = React.useState({
    auth: auth(),
    db: database().ref(),
  });
  const [institucion, setInstitucion] = React.useState(null);
  const [functions, setFunctions] = React.useState({});
  const [payments, setPayments] = React.useState(null);
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    app.auth.onAuthStateChanged(() => setUser(app.auth.currentUser));
  }, []);

  React.useEffect(() => {
    let ref = null;

    if (institucion && institucion.id) {
      setPayments(null);
    } else if (user && !user.isAnonymous) {
      ref = app.db
        .child(`payments/root/${user.uid}`)
        .orderByChild("active")
        .equalTo(1);

      ref.on("value", (snapshot) => {
        const val = Object.values(snapshot.val() || []);
        console.log("======>>", val);
        const aux = [];
        snapshot.forEach((v) => aux.push({ paymentKey: v.key, ...v.val() }));
        setPayments(aux);
      });
    }

    return () => ref && ref.off();
  }, [user, institucion]);

  React.useEffect(() => {
    const institution = window.amplify.store("institution") || {};
    const data = {
      id: institution.id_institucion || null,
      ...institution,
    };

    setInstitucion(data);
    if (functions) functions.setRegistro && functions.setRegistro(data);
  }, [props, functions]);
  const usuario = (window.amplify.store("user") || {}).usuario || {};

  return (
    <Box height="100%">
      <ThinkForm
        type="form"
        setFunctions={setFunctions}
        tags={[]}
        registro={institucion}
        catalogo={props.catalogo}
        onSubmit={(data) => {
          internalAPI.loginUser((err, res) => {
            if (!err) {
              const user = window.amplify.store("user");
              const institution =
                res.instituciones.find((v) => v.vc_rfc === data.vc_rfc) ||
                res.instituciones[0];

              const result = {
                ...user,
                ...res,
                institution,
                roles: institution?.roles || [],
              };

              props.login(result, false);

              // Si se crea la institucion
              if (!institucion.id) {
                props.navigate("#/admin/inicio");

                const payment = payments[0];
                const child = `${app.auth.currentUser.uid}/${payment.paymentKey}`;
                const update = { active: 0 };
                // app.db.child(`payments/user/${child}`).update(update);
                // app.db.child(`payments/root/${child}`).update(update);
              }
            }
          });
        }}
      />

      {payments && payments.length === 0 && usuario.vc_email !== "admin@thin-k.mx" && (
        <PaypalPays
          onCompleted={(pay, campaing) => {
            app.db.child(`payments/user/${app.auth.currentUser.uid}`).push({
              uid: app.auth.currentUser.uid,
              campaing,
              ...pay,
            });
            app.db.child(`payments/root/${app.auth.currentUser.uid}`).push({
              active: 1,
              uid: app.auth.currentUser.uid,
              campaing,
              ...pay,
            });
          }}
          onClose={() => props.navigate("#/admin/inicio")}
          onClose={() => props.navigate("#/admin/inicio")}
          open={payments && payments.length === 0}
        />
      )}
    </Box>
  );
};

// class Index extends React.Component {
//   renderPaymentMethod() {
//     // const user = window.amplify.store('user') || {}
//     // const institucion = window.amplify.store(`${user.id}-newInstitucion`);
//     const institucion = window.amplify.store(`institution`);
//     return (
//       // <Modal onClose={() => this.setState({ accountModal: false })} open={!institucion} size='fullscreen' style={{ backgroundColor: 'transparent', boxShadow: '0px 0px 0px transparent' }}>
//       <ModalPaymentWizard navigate={this.props.navigate} />
//       // </Modal>
//     );
//   }

//   render() {
//     const institucion = window.amplify.store("institution") || {};
//     if (institucion.id_institucion) {
//       institucion.id = institucion.id_institucion;
//     }
//     return (
//       <div style={{ height: "100%", width: "100%" }}>
//         {!!window.amplify.store("institution")
//           ? this.props.catalogo && (
//               <ThinkForm
//                 type="form"
//                 setFunctions={(functions) => {
//                   this.setState({ functions });
//                 }}
//                 tags={[]}
//                 registro={institucion}
//                 catalogo={this.props.catalogo}
//                 onSubmit={(data) => {
//                   const oneDay = 1000 * 60 * 60 * 24;
//                   const keys = Object.keys(institucion);

//                   const newInstitucion = {
//                     ...institucion,
//                     ..._.pick(_.omit(data, ["id"]), keys),
//                   };

//                   window.amplify.store("institution", newInstitucion, {
//                     expires: oneDay,
//                   });
//                 }}
//               />
//             )
//           : this.renderPaymentMethod()}
//       </div>
//     );
//   }
// }

const actions = { login };
const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  return {
    catalogos,
    catalogo: catalogos.find((e) => e.vc_nameTable === "ins_instituciones"),
  };
};

export default connect(mapStateToProps, actions)(Index);

/**
 *
 <div id="smart-button-container">
      <div style="text-align: center;">
        <div id="paypal-button-container"></div>
      </div>
    </div>
  <script src="https://www.paypal.com/sdk/js?client-id=AT5JdxDXd-bg1Ogq7j0m5z42c43dy8bvnPvPlhbaxN9GthTDUxydDQzKeCXooNsxAJjWc1NauwX2n__X&currency=MXN" data-sdk-integration-source="button-factory"></script>
  <script>
    function initPayPalButton() {
      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'white',
          layout: 'vertical',
          label: 'pay',

        },

        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [{"description":"Membresía Anual","amount":{"currency_code":"MXN","value":6960,"breakdown":{"item_total":{"currency_code":"MXN","value":6000},"shipping":{"currency_code":"MXN","value":0},"tax_total":{"currency_code":"MXN","value":960}}}}]
          });
        },

        onApprove: function(data, actions) {
          return actions.order.capture().then(function(details) {
            alert('Transaction completed by ' + details.payer.name.given_name + '!');
          });
        },

        onError: function(err) {
          console.log(err);
        }
      }).render('#paypal-button-container');
    }
    initPayPalButton();
  </script>
 */

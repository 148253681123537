import internalApi from "../../../utils/internalAPI";

const { settingsEndPoints } = internalApi;
export default {
  getDependencias() {
    const { id_institucion } = window.amplify.store("institution") || {};

    return settingsEndPoints.getDependencias(id_institucion);
  },
  saveEditDependencia(body) {
    const { id_institucion } = window.amplify.store("institution") || {};

    const endPoint = body.id
      ? settingsEndPoints.updateDependencia
      : settingsEndPoints.storeDependencia;

    return endPoint({ id_institucion, ...body });
  },
};

const roles = [

]
export const opcionesMenu = [
  {
    id: "inicio",
    vc_nombre: "inicio",
    icon: "home",
    url: "inicio",
    roles: [1, 2, 5, 6, 7, 8, 9],
  },
  {
    id: "pacientes",
    vc_nombre: "Pacientes",
    icon: "users",
    url: "pacientes",
    roles: [2, 5, 6, 7, 8, 9, 12],
  },
  {
    id: "agendas",
    vc_nombre: "Agendas",
    icon: "calendar",
    url: "agendas",
    roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 12],
  },
  {
    id: "statistics",
    vc_nombre: "Productividad",
    icon: "chart line",
    url: "statistics",
    roles: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    id: "hospitalizacion",
    vc_nombre: "Hospitalizacion",
    icon: "hospital outline",
    url: "hospitalizacion",
    roles: [5, 6, 7, 8, 12],
  },
  {
    id: "shop",
    vc_nombre: "Ventas",
    icon: "credit card outline",
    url: "shop",
    roles: [2, 3, 4, 9, 12],
  },
  // {
  //     id: 'usuarios',
  //     vc_nombre: 'Configuración',
  //     icon: 'settings',
  //     url: 'usuarios',
  //     roles: [1,2,3,4,5,6,7,8,9]
  // },
  {
    id: "configuracion",
    vc_nombre: "Configuración",
    icon: "cogs",
    url: "configuracion",
    roles: [1, 2],
  },
  {
    id: "academy",
    vc_nombre: "Academia",
    icon: "graduation cap",
    url: "academy",
    roles: [1,2, 3, 5, 6, 7, 8,9,10],
  },
  {
    id: "backoffice",
    vc_nombre: "Development",
    icon: "database",
    url: "backoffice",
    roles: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    id: "aviso-privacidad",
    vc_nombre: "Aviso de Privacidad",
    icon: "user secret",
    url: "aviso-privacidad",
    roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
  },
  {
    id: "logout",
    vc_nombre: "Cerrar Sesión",
    icon: "power",
    url: null,
    roles: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
];

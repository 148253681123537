export const menuAcademy = [
    {
        id: 'simulador',
        vc_nombre: 'Simuladores',
        enable: true,
        sidebar: {
          open: true,
          enable: true,
        },
        opciones: [
            {
                id: 'enarm',
                vc_nombre: 'ENARM',
                acciones: [
                    {
                        id: 'iniciar',
                        vc_nombre: 'Iniciar',
                        icon: null
                    },
                    {
                        id: 'guardar',
                        vc_nombre: 'Guardar',
                        icon: null
                    }
                ]
            }
        ]
    }
  ];

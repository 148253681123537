import React from "react";
import { Typography, Grid, Paper, styled } from "@material-ui/core";
import moment from "moment";

import Table from "app/material-ui/Table";

const Item = styled(Paper)({
  border: 0,
  borderRadius: 5,
  padding: "12px",
  marginBottom: "15px",
  border: "1px solid transparent",
  "&.selected": {
    borderColor: window.colors.itemSelected,
  },
  "& h5": {
    color: window.colors.itemSelected,
    marginBottom: "0",
  },
  "& label": {
    marginRight: "5px",
  },
});

export default (props) => {
  const { data = [], onClick = () => {}, selected } = props;
  const columns = [
    {
      name: "vc_nombreCompleto",
      label: "Nombre del Cliente",
      options: {
        cellProps: {
          className: "fill",
        },
        typographyProps: {
          color: "primary",
          variant: "subtitle1",
        },
      },
    },
    {
      name: "vc_curp",
      label: "Curp",
      options: { cellProps: { className: "sm-hide" } },
    },
    {
      name: "vc_sexo",
      label: "Sexo",
      options: { cellProps: { className: "sm-hide" } },
    },
    {
      name: "nu_edad",
      label: "Edad",
      options: { cellProps: { className: "sm-hide" } },
    },
    { name: "dt_atencion", label: "Atencion" },
    // { name: "dt_finalizacion", label: "Finalizacion" },
  ];
  const dataAux = data.map((v) => {
    const {
      vc_curp = "No Especificado",
      id_sexo = 0,
      dt_nacimiento,
      vc_nombreCompleto,
    } = v.client;

    const nu_edad = moment().diff(moment(dt_nacimiento), "years");
    const vc_sexo = ["No Especificado", "Masculino", "Femenino", "Indistinto"][
      id_sexo
    ];
    const dt_atencion = moment(v.dt_created).format("hh:mm a, DD-MM-YYYY");
    return {
      ...v,
      vc_curp,
      nu_edad,
      vc_sexo,
      dt_atencion,
      dt_finalizacion: 0,
      vc_nombreCompleto,
    };
  });

  return (
    <Table
      columns={columns}
      data={dataAux}
      onSelect={onClick}
      value={selected?.id}
    />
  );

  return (
    <div>
      <div style={{ padding: 12 }}>
        <Grid container spacing={3}>
          <Grid item xs={4} container direction="row" alignItems="center">
            <Typography color="textSecondary" variant="subtitle2">
              Nombre del Cliente
            </Typography>
          </Grid>
          <Grid item xs={3} container direction="row" alignItems="center">
            <Typography color="textSecondary" variant="subtitle2">
              Curp
            </Typography>
          </Grid>
          <Grid item xs={2} container direction="row" alignItems="center">
            <Typography color="textSecondary" variant="subtitle2">
              Sexo
            </Typography>
          </Grid>
          <Grid item xs={1} container direction="row" alignItems="center">
            <Typography color="textSecondary" variant="subtitle2">
              Edad
            </Typography>
          </Grid>
          <Grid item xs={2} container direction="row" alignItems="center">
            <Typography color="textSecondary" variant="subtitle2">
              Atención
            </Typography>
          </Grid>
        </Grid>
      </div>

      {data.map((v, i) => {
        const {
          vc_curp: curp = "No Especificado",
          id_sexo = 0,
          dt_nacimiento,
          vc_nombreCompleto: nombre,
        } = v.client;

        const edad = moment().diff(moment(dt_nacimiento), "years");
        const sexo = ["No Especificado", "Masculino", "Femenino", "Indistinto"][
          id_sexo
        ];
        const fecha = moment(v.dt_created).format("hh:mm a, DD-MM-YYYY");

        return (
          <Item
            key={i}
            className={selected && selected.id === v.id ? "selected" : ""}
            onClick={() => onClick(v, i)}
          >
            <Grid container spacing={3}>
              <Grid item xs={4} container direction="row" alignItems="center">
                <h5>{nombre}</h5>
              </Grid>
              <Grid item xs={3} container direction="row" alignItems="center">
                <b>{curp}</b>
              </Grid>
              <Grid item xs={2} container direction="row" alignItems="center">
                <b>{sexo}</b>
              </Grid>
              <Grid item xs={1} container direction="row" alignItems="center">
                <b>{edad}</b>
              </Grid>
              <Grid item xs={2} container direction="row" alignItems="center">
                <b>{fecha}</b>
              </Grid>
            </Grid>
          </Item>
        );
      })}
    </div>
  );
};

export const fields = ['id_empresa', "id_sucursal", "id_area", "vc_nombre", "vc_descripcion"];

export const fieldsRequired = ["id_sucursal", "id_area", "vc_nombre"];

export const fieldsForm = [
  {
    icon: "ShortText",
    name: "vc_nombre",
    props: { placeholder: "Nombre*" },
  },
  {
    icon: "Subject",
    name: "vc_descripcion",
    props: { placeholder: "Descripción", multiline: true, rows: 3, rowsMax: 3 },
  },
];

export const columns = [
  { name: "vc_nombre", label: "Nombre" },
  { name: "vc_descripcion", label: "Descripcion" },
];

import React from "react";
import NumberFormat from "react-number-format";

export const PhoneNumber = (props) => {
  const { inputRef, onChange, ...other } = props || {};

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};

export const Number = (props) => {
  const { inputRef, onChange, ...other } = props || {};

  return (
    <NumberFormat
      {...other}
      // prefix="$ "
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};

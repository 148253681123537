import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import CreditCardOutlined from '@material-ui/icons/CreditCardOutlined';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import AddShoppingCartOutlined from '@material-ui/icons/AddShoppingCartOutlined';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { teal } from '@material-ui/core/colors';
const themeSelected = window.amplify.store('theme') || null;
const color = themeSelected ? themeSelected.primary : teal;
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        `linear-gradient( 95deg,${color[100]} 0%,${color[400]} 50%,${color[700]} 100%)`,
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
      `linear-gradient( 95deg,${color[100]} 0%,${color[400]} 50%,${color[700]} 100%)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 45,
    height: 45,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
    `linear-gradient( 95deg,${color[100]} 0%,${color[400]} 50%,${color[700]} 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
    `linear-gradient( 95deg,${color[100]} 0%,${color[400]} 50%,${color[700]} 100%)`,
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <AddShoppingCartOutlined />,
    3: <CreditCardOutlined />,
    4: <Check />,
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Configura tu Institución o Consultorio', 'Selecciona un paquete inicial', 'Metodo de pago', 'Finalizar'];
}

function getStepContent(step) {
    return getSteps[step];
  switch (step) {
    case 0:
      return 'Select campaign settings...';
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown step';
  }
}

export default function CustomizedSteppers(props) {
  const classes = useStyles();
  const {step, steps} = props;

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={step.id - 1} connector={<ColorlibConnector/>} style={{backgroundColor: 'transparent'}}>
        {steps.map((item, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{item.vc_descripcion}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {/* <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <Button key='back' disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              <Button
              key='foward'
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
}

import configLocal from './config.local';
import configDev from './config.development';
import configTest from './config.test';
import configProd from './config.production';

let config; //eslint-disable-line

switch (process.env.NODE_ENV) {
  case 'local':
    config = configLocal;
    break;
  case 'development':
    config = configDev;
    break;
  case 'test':
    config = configTest;
    break;
  case 'production':
    config = configProd;
    break;
  default:
    config = configDev;
    break;
}


export default config;

import React from 'react';
import { connect } from 'react-redux';
import { IconButton, Button, Box, Grid, Paper } from '@material-ui/core';
import CropFree from '@material-ui/icons/CropFree';
import { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar } from '../../containers/Think/actions';
import QrReader from 'react-qr-reader';
import notifications from '../../utils/notifications';

class Index extends React.Component {
  constructor() {
    super();
    this.state = { delay: 'someValue' };
  }
  handleScan(data) {
    if (data) {
      console.log('CURP: ', data);
      const curpInfo = data.split('|');
      const fecha = curpInfo[6].split('/');
      const paciente = this.props.getRegistro ? this.props.getRegistro() : { detalle: {} };
      if (!paciente.detalle){
        paciente.detalle = {};
      }
      if (curpInfo[5] === 'HOMBRE' || curpInfo[5] === 'MUJER') {
        paciente.detalle.vc_curp = curpInfo[0];
        paciente.detalle.vc_apellidoPaterno = curpInfo[2];
        paciente.detalle.vc_apellidoMaterno = curpInfo[3];
        paciente.detalle.vc_nombre = curpInfo[4];
        paciente.detalle.id_sexo = curpInfo[5] === 'HOMBRE' ? 1 : 2;
        paciente.detalle.dt_nacimiento = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;
        paciente.detalle.id_entidad = parseInt(curpInfo[8], 10);

        notifications.success('Se registro CURP y los datos generales');
        console.log('hola')
        this.props.setDisplaySidebar(false);
        this.props.resetHelperSidebarRender();
        this.props.setRegistro(paciente);
      }
    }
  }

  handleError(err) {
    console.error(err);
    this.setState({ showReadQR: false });
  }

  renderHelpBar() {
    return (
      <Grid style={{ width: '100%', margin: 0, padding: 3, backgroundColor: 'transparent' }}>
        <Paper style={{width: '100%', height: '100%'}}>
          <QrReader
            delay={0}
            onError={this.handleError.bind(this)}
            onScan={this.handleScan.bind(this)}
            style={{ width: '100%', margin: 0, padding: 0, borderRadius: 4 }}
          />
          <Button
            style={{ width: '100%', marginTop: 5 }}
            variant='outlined' color='primary' onClick={() => {
              this.props.setDisplaySidebar(false);
              this.props.resetHelperSidebarRender();
            }}>
            Cancelar
            </Button>
        </Paper>
      </Grid>
    )
  }
  showQRReader() {
    this.props.setHelperSidebarRender(this.renderHelpBar.bind(this));
    this.props.setDisplaySidebar(true);
  }
  render() {
    return (
      <Box>
        <IconButton onClick={this.showQRReader.bind(this)}>
          <CropFree />
        </IconButton>
      </Box>
    );
  }

  componentDidMount() {
    this.setState({ someKey: 'otherValue' });
  }
}
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setDisplaySidebar
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, actions)(Index);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "app/containers/Think/actions";

import { Box, Button } from "@material-ui/core";

import SubMenuModulos from "app/material-ui/SubMenuModulos";
import Table from "app/material-ui/Table";

import api from "./api";
import Sidebar from "./Sidebar";
import ModalForm from "app/settings/components/Dependencias/ModalForm";

const columns = [
  {
    name: "vc_nombre",
    label: "Nombre",
    options: {
      typographyProps: {
        color: "primary",
        variant: "subtitle1",
      },
    },
  },
  {
    name: "vc_telefonoDependencia",
    label: "Telefono",
  },
  {
    name: "vc_direccionDependencia",
    label: "Localizacion",
    options: {
      cellProps:{
        width: '300px'
      },
      typographyProps: {
        style: {
          maxWidth: "250px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "inline-block",
        },
      },
    },
  },
];
const Index = (props) => {
  const [data, setData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [modalForm, setModalForm] = React.useState({ open: false, data: {} });
  const [selected, setSelected] = React.useState({
    index: -1,
    row: null,
  });

  const handleSelect = (row, index) => {
    const newSelected = { index: -1, row: null };

    if (selected.index !== index) {
      newSelected.index = index;
      newSelected.row = row;
    }

    setSelected(newSelected);
  };

  const actions = {
    async fetch() {
      const fetched = await api.getDependencias();
      setData(fetched.data);
    },
    editar() {
      setModalForm({ open: true, data: selected.row });
    },
    guardar(body) {
      return new Promise((resolve, reject) => {
        api
          .saveEditDependencia(body)
          .then(() => {
            resolve();
            actions.fetch();
            setModalForm({ open: false, data: {} });
          })
          .catch(() => {
            reject();
          });
      });
    },
  };
  React.useEffect(() => {
    actions.fetch();
  }, []);

  React.useEffect(() => {
    setRows(
      data.map((v) => {
        return {
          ...v,
          vc_telefonoDependencia: v.vc_telefono || "No Especificado",
          vc_direccionDependencia: v.vc_direccion || "No Especificado",
        };
      })
    );
  }, [data]);

  React.useEffect(() => {
    props.setHelperSidebarRender(() =>
      selected.row ? (
        <Sidebar selected={selected.row} actions={actions} />
      ) : null
    );
    props.setDisplaySidebar(!!selected.row);
  }, [selected.row]);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <SubMenuModulos
        type="search"
        title={
          <Button
            onClick={() => setModalForm({ open: true, data: {} })}
            color="inherit"
          >
            Agregar
          </Button>
        }
      />
      <Box height={0} flex="1 1 auto" p={2} pt={1}>
        <Table
          columns={columns}
          data={rows}
          onSelect={handleSelect}
          value={selected.row?.id}
        />
      </Box>

      <ModalForm
        {...modalForm}
        close={() => setModalForm((prev) => ({ ...prev, open: false }))}
        accept={actions.guardar}
      />
    </Box>
  );
};

Index.propTypes = {
  setHelperSidebarRender: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
};

const actions = {
  setHelperSidebarRender,
  setDisplaySidebar,
};

export default connect(null, actions)(Index);

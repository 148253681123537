import React from "react";
import Route from "react-router/es/Route";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Settings from "../containers/Settings";

const styles = {
  position: "relative",
  height: "calc(100% - 40px)",
  backgroundColor: "transparent",
  borderRadius: 0,
};

const skeletonApp = (Component) => {
  return (props) => (
    <Box m={0} height="100%">
      <Component {...props} />
    </Box>
  );
};

export default (() => {
  return <Route path="configuracion" component={skeletonApp(Settings)} />;
})();

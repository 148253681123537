import React from "react";

import { Box, Slide, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Input from "app/components/Form/Input";
import internalAPI from "app/utils/internalAPI";
import { omit } from "lodash";

const searchInstitution = (texto) => {
  return new Promise((resolve, reject) => {
    internalAPI.searchInstitucion(texto, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
};

const Index = ({ onClick, ...props }) => {
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const fetchData = async () => {
    if (!inputValue) return setData([]);

    const res = await searchInstitution(inputValue);
    setLoading(false);
    setData(res);
  };

  React.useEffect(() => {
    setLoading(!!inputValue);
    const timeout = setTimeout(async () => {
      await fetchData();
    }, 300);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  React.useEffect(() => {
    if (value) {
      onClick(value);
      setInputValue("");
      setValue(null);
    }
  }, [value]);

  React.useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  return [
    <Autocomplete
      filterOptions={(x) => x}
      options={inputValue ? data : []}
      autoComplete
      fullWidth
      includeInputInList
      filterSelectedOptions
      openOnFocus={false}
      freeSolo
      value={value}
      getOptionLabel={(option) => {
        return typeof option === "string" ? option : option.vc_rfc;
      }}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") return;
        if (newValue && "loading" in newValue) return;

        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      style={{ margin: 0 }}
      renderInput={(params) => (
        <Input
          inputRef={params.InputProps.ref}
          inputProps={{
            style: { margin: 0 },
            ...omit(params.inputProps, ["className"]),
          }}
          style={{ margin: 0 }}
          getEvent
          autoFocus
          placeholder="Buscar productos"
          dense
          endAdornment={
            <Box display="flex" alignItems="center">
              {loading && (
                <CircularProgress
                  size={20}
                  color="white"
                  style={{ marginRight: 8 }}
                />
              )}
            </Box>
          }
        />
      )}
      renderOption={(data, option) => {
        return <Box>{data.vc_nombre}</Box>;
      }}
    />,
  ];
};

Index.defaultProps = {
  onClick() {},
};

export default Index;

import React from "react";
import _ from "lodash";
import internalAPI from "app/utils/internalAPI";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  Grid,
} from "@material-ui/core";

import Input from "app/components/Form/Input";
import Select from "app/components/Form/Select";
import Icon from "app/sales/components/Common/Icon";

import {
  fields,
  fieldsRequired,
  fieldsForm,
} from "app/sales/containers/Cajas/fields";

const localizacion = () => {
  const { id_institucion = 0 } = window.amplify.store("institution") || {};
  return new Promise((resolve, reject) => {
    internalAPI.getLocalizacion({ id_institucion }, (err, res) => {
      if (!err) resolve(res);
      else reject(err);
    });
  });
}

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
  const { open, close, accept, data } = props;

  const [dependencias, setDependencias] = React.useState([]);
  const [areas, setAreas] = React.useState([]);
  const [form, setForm] = React.useState({});
  const [disabled, setDisabled] = React.useState(false);

  const handleInputChange = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  React.useEffect(() => {
    const formKeys = Object.keys(form);
    const keysExist = fieldsRequired.every((field) => formKeys.includes(field));
    const ok = formKeys.every(
      (field) =>
        keysExist && fieldsRequired.includes(field) && Boolean(form[field])
    );

    setDisabled(!ok);
  }, [form]);

  React.useEffect(() => {
    setForm(_.pick(data, fields));

    localizacion().then((res) => {
      setDependencias(
        res.map((dep) => ({
          value: dep.id,
          text: dep.vc_nombre,
          areas: dep.areas || [],
        }))
      );
    });
  }, [data]);

  React.useEffect(() => {
    let areas = [];
    if (form.id_sucursal) {
      const dep = dependencias.find((v) => v.value === form.id_sucursal);
      areas = dep.areas;
    }

    handleInputChange("id_area", null);
    setAreas(areas.map((area) => ({ value: area.id, text: area.vc_nombre })));
  }, [form.id_sucursal]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Caja</DialogTitle>
      <DialogContent>
        <Grid container key="dependencias">
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <Icon icon="ApartmentOutlined" />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            <Select
              placeholder="Dependencia*"
              data={dependencias}
              value={form.id_sucursal}
              onChange={(option) =>
                handleInputChange("id_sucursal", option.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container key="areas">
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <Icon icon="BusinessOutlined" />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            <Select
              placeholder="Area*"
              data={areas}
              value={form.id_area}
              onChange={(option) => handleInputChange("id_area", option.value)}
            />
          </Grid>
        </Grid>

        {fieldsForm.map((field, i) => (
          <Grid container key={i}>
            <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
              <DialogContentText>
                <Icon icon={field.icon} />
              </DialogContentText>
            </Grid>
            <Grid item xs={11}>
              <Input
                {...field.props}
                value={form[field.name]}
                onChange={(value) => handleInputChange(field.name, value)}
              />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={close}
          style={{ alignSelf: "right" }}
        >
          Cancelar
        </Button>

        <Button
          onClick={() => {
            accept(form);
          }}
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          disabled={disabled}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

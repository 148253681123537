/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { menuAgendas } from "../../containers/Think/listados";

import Agendas from "./agendas";
import Configuracion from "./Configuracion";
import { Box } from "@material-ui/core";

const index = (props) => {
  const [moduloActual, setModuloActual] = useState("agendas");

  const modulo = menuAgendas.find((v) => v.id === moduloActual);
  const { opciones = [] } = modulo;

  useEffect(() => {
    const options = menuAgendas.map((option) => ({
      ...option,
      onClick: (m) => setModuloActual(m.id),
    }));

    props.setSubMenuOptions(options);
    props.setActiveView("agendas");
  }, [setModuloActual]);

  switch (moduloActual) {
    case "agendas":
      return (
        <Agendas
          modulo={modulo}
          opciones={opciones}
          navigate={props.navigate}
        />
      );
    case "configuracion":
      return <Configuracion />;
    default:
      return <Box />;
  }
};

index.propTypes = {
  setActiveView: PropTypes.func,
  navigate: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const actions = {};

export default connect(mapStateToProps, actions)(index);

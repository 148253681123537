import React from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  makeStyles,
} from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";

import Input from "../../../components/Form/Input";
import InputSelect from "../../../components/Form/Select";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => {
  return {
    input: {
      padding: 8,
      width: "100%",
      backgroundColor: "rgba(200,200,200,0.2)",
      borderRadius: 5,
      textAlign: "right",
    },
  };
});

export default (props) => {
  const classes = useStyles();
  const { open, close, accept, data } = props;
  const [form, setForm] = React.useState(
    data || {
      id_productArea: 1,
      id_type: 1,
      measureUnit: "unidad",
      nu_tax: 0,
      nu_total: 0,
      nu_unitValue: 0,
      vc_description: "",
    }
  );
  const [disabled, setDisabled] = React.useState(false);

  const tiposProductos = [
    { value: 1, text: "Producto" },
    { value: 2, text: "Servicio" },
    { value: 3, text: "Procedimiento" },
  ];
  const areasProductos = [
    { value: 1, text: "Doctor y area de curacion" },
    { value: 2, text: "Podologia" },
    { value: 3, text: "Farmacia / Inventario" },
  ];
  const impuestos = [
    { value: 0, text: "0%" },
    { value: 0.16, text: "16%" },
  ];

  const handleInputChange = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleUnitValue = (v) => {
    let value = v;
    const match = value.match(/^\d{1,}(\.\d{0,4})?$/);
    if (value !== "" && !Number(value)) return;

    if (!!match && !!match[1] && match[1].length > 3)
      value = Number(value).toFixed(2);

    handleInputChange("nu_unitValue", value);
  };

  React.useEffect(() => {
    if (form.nu_unitValue) {
      const iva = form.nu_unitValue * form.nu_tax;
      const total = Number((Number(form.nu_unitValue) + iva).toFixed(2));
      handleInputChange("nu_total", total);
    } else handleInputChange("nu_total", 0);
  }, [form.nu_tax, form.nu_unitValue]);

  React.useEffect(() => {
    setDisabled(!form.vc_description || form.nu_unitValue < 0);
  }, [form]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth={"xs"}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Producto</DialogTitle>
      <DialogContent>
        <GridUI container>
          <GridUI item xs="4" style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>Nombre</DialogContentText>
          </GridUI>
          <GridUI item xs="8">
            <Input
              value={form.vc_description}
              onChange={(value) => handleInputChange("vc_description", value)}
            />
          </GridUI>
        </GridUI>
        <GridUI container>
          <GridUI item xs="4" style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>Tipo</DialogContentText>
          </GridUI>
          <GridUI item xs="8">
            <InputSelect
              data={tiposProductos}
              value={form.id_type}
              hideSearch
              onClick={(v) => handleInputChange("id_type", v.value)}
            />
          </GridUI>
        </GridUI>
        <GridUI container>
          <GridUI item xs="4" style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>Area</DialogContentText>
          </GridUI>
          <GridUI item xs="8">
            <InputSelect
              data={areasProductos}
              value={form.id_productArea}
              hideSearch
              onClick={(v) => handleInputChange("id_productArea", v.value)}
            />
          </GridUI>
        </GridUI>
        <GridUI container>
          <GridUI item xs="4" style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>Precio Unitario</DialogContentText>
          </GridUI>
          <GridUI item xs="8">
            <Input
              textAlign="right"
              value={form.nu_unitValue}
              onChange={handleUnitValue}
            />
          </GridUI>
        </GridUI>
        <GridUI container>
          <GridUI item xs="4" style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>Iva</DialogContentText>
          </GridUI>
          <GridUI item xs="8">
            <InputSelect
              textAlign="right"
              data={impuestos}
              value={form.nu_tax}
              hideSearch
              onClick={(v) => handleInputChange("nu_tax", v.value)}
            />
          </GridUI>
        </GridUI>
        <GridUI container>
          <GridUI item xs="4" style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>Total</DialogContentText>
          </GridUI>
          <GridUI item xs="8">
            <Input textAlign="right" value={form.nu_total} disabled />
          </GridUI>
        </GridUI>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={close}
          style={{ alignSelf: "right" }}
        >
          Cancelar
        </Button>

        <Button
          onClick={() => {
            accept(form);
          }}
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          disabled={disabled}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

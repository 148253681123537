import React from "react";

import _ from "lodash";

import { Popover, Button } from "@material-ui/core";
import DateRangePicker from "./DateRangePicker";
import moment from 'moment';

export default (props) => {
  const { onChange = () => {}, value = [] } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const inicio = value[0] ? moment(value[0]).format('ll') : '';
  const fin = value[1] ? moment(value[1]).format('ll') : '';
  return (
    <div>
      <Button
        onClick={(e) => {
          setAnchorEl(e.target);
        }}
        color="inherit"
      >
        {inicio && fin ? `${inicio} - ${fin}` : 'Seleccionar rango'}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <DateRangePicker value={value} onChange={onChange} />
      </Popover>
    </div>
  );
};

import React, { Component } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';

// crear componente
class DropdownInput extends Component {
    constructor(props) {
        super(props);

        let valid;
        if (!props.multiple && props.defaultValue !== '' && props.defaultValue !== undefined && props.defaultValue !== null) {
            valid = true;
        } else if (props.multiple && props.defaultValue && props.defaultValue.length) {
            valid = true;
        } else {
            valid = !props.required;
        }

        let value = props.multiple ? [] : '';
        if (props.defaultValue !== '' && props.defaultValue !== undefined && props.defaultValue !== null) {
            value = props.defaultValue;
        }

        this.state = {
            value,
            valid,
            message: props.required ? `El campo ${props.label} es requerido` : '',
            dirty: false
        };
    }


    // -----------------------------
    // ------ life cycle events ----
    // -----------------------------
    componentDidMount() {
        this.setForm();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const defaultForSingleOption = !nextProps.multiple && nextProps.defaultValue !== '' && nextProps.defaultValue !== undefined && nextProps.defaultValue !== null;
        const defaultForMultipleOptions = nextProps.multiple && nextProps.defaultValue && nextProps.defaultValue.length;

        if (!this.state.dirty && (defaultForSingleOption || defaultForMultipleOptions)) {
            this.setState({ value: nextProps.defaultValue, valid: true });
        }

        if (!_.isEqual(this.props.options, nextProps.options)) {
            const initialValue = this.props.multiple ? [] : '';
            this.setState({ value: initialValue });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextState, this.state) || !_.isEqual(nextProps, this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.eq(prevState, this.state)) {
            this.setForm();
        }
    }


    // -----------------------
    // ------ user events ----
    // -----------------------
    onChange(e, data) {
        // actualizar valor
        this.setState({ value: data.value, dirty: true });
        const invalidValue = data.value === '' || data.value === undefined || data.value === null || data.value.length === 0;

        // validar input
        if (this.props.required && invalidValue) {
            this.setState({ valid: false, message: `El campo ${this.props.label} es requerido` });
        } else {
            this.setState({ valid: true, message: '' });
        }
    }

    setForm() {
        this.props.setFormData(this.props.name, this.state);
    }

    getValue() {
        return this.state.value;
    }

    dirtInput() {
        this.setState({ dirty: true });
    }

    resetInput() {
        let valid;
        if (!this.props.multiple && this.props.defaultValue) {
            valid = true;
        } else if (this.props.multiple && this.props.defaultValue && this.props.defaultValue.length) {
            valid = true;
        } else {
            valid = !this.props.required;
        }

        let value = this.props.multiple ? [] : '';
        if (this.props.defaultValue) {
            value = this.props.defaultValue;
        }

        this.setState({
            value,
            valid,
            message: this.props.required ? `El campo ${this.props.label} es requerido` : '',
            dirty: false
        });
    }


    // --------------------------
    // ------ render methods ----
    // --------------------------
    render() {
        const invalidInput = this.state.dirty && !this.state.valid;
        let className = '';
        let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
        let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

        if (this.props.className) {
            className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
        } else {
            className = invalidInput ? 'invalid' : 'valid';
        }

        if (invalidInput) {
            finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
            finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
        }

        return (
            <Form.Field>
                <label style={finalLabelStyle}>
                    { this.props.label }
                </label>
                <Dropdown
                    fluid
                    selection
                    name={this.props.name}
                    className={className}
                    value={this.state.value}
                    onChange={this.onChange.bind(this)}
                    disabled={this.props.readOnly}
                    placeholder={this.props.placeholder}
                    multiple={this.props.multiple}
                    search={this.props.search}
                    options={this.props.options}
                    loading={this.props.loading}
                    style={finalInputStyle}
                    onClick={() => this.props.callbackSelectInput()}
                />
            </Form.Field>
        );
    }
}


// estilos
const styles = {
    label: {

    },
    errorLabel: {
        color: '#9F3A38'
    },
    input: {

    },
    errorInput: {
        background: 'rgba(224, 180, 180, 0.48)',
        border: '1px solid #9F3A38',
        color: '#9F3A38'
    }
};


// exportar componente
export default DropdownInput;

import { UPDATE_CATALOGS, SET_ITEM_EDIT, SET_LIBS } from '../actions/types';

export default (state, INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_CATALOGS:
            const prevState = state;
            const newState = {};
            // console.log('payload', action.payload)
            action.payload.map((catalogo) => {
                const vc_nombreTabla = catalogo.vc_nombreTabla === 'cat_medicamentos' ? 'cat_medicamentosThink' : catalogo.vc_nombreTabla;
                const catPrev = prevState[vc_nombreTabla.replace('cat_', '')] ? prevState[vc_nombreTabla.replace('cat_', '')].slice() : null;
                if (catPrev) {
                    catalogo.registros.map((row) => {
                        const filter = catPrev.filter((e) => e.id === row.id);
                        const lastObj = filter[0];
                        if (lastObj) {
                            const i = catPrev.indexOf(lastObj);
                            catPrev[i] = row;
                        } else {
                            catPrev.push(row);
                        }
                    });
                    newState[vc_nombreTabla.replace('cat_', '')] = catPrev;
                }
            });
            return { ...state, ...newState };
        case SET_LIBS:
            return { ...state, ...action.payload };
        case SET_ITEM_EDIT:
            return { ...state, itemEdit: action.payload };
        default:
            return null;
    }
};

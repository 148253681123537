import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
} from "app/containers/Think/actions";

import repository from "app/utils/repository";
import notifications from "app/utils/notifications";
import eventManager from "app/utils/eventManager";
import { START_VIEW_TRANSITION } from "app/constants";

import SubMenuModulos from "app/material-ui/SubMenuModulos";
import { Box } from "@material-ui/core";

import moment from "moment";
import api from "./api";
import Sidebar from "./sidebar";
import Table from "app/material-ui/Table";
import ModuloCovid from "app/patients/components/ModuloCovid";
import Expediente from "app/patients/components/Expediente";
import Formularios from "app/patients/components/Formularios";
import QRReader from "app/material-ui/QRReader";

import { getAge } from "app/utils/repository";

const sexos = ["No Especificado", "Masculino", "Femenino", "Indistinto"];
const columns = [
  {
    name: "vc_nombreCompleto",
    label: "Nombre del Paciente",
    options: {
      cellProps: {
        className: "fill",
      },
      typographyProps: {
        color: "primary",
        variant: "subtitle1",
      },
    },
  },
  { name: "vc_curpText", label: "Curp" },
  { name: "vc_sexo", label: "Sexo" },
  { name: "vc_edad", label: "Edad" },
];

const Index = (props) => {
  const institucion = window.amplify.store("institution") || {};
  const {
    preguntas,
    logOut,
    setPacienteActual,
    setLoading,
    medicamentosDgis,
    diagnosticos,
  } = props;
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState([]);
  const [table, setTable] = React.useState([]);
  const [selected, setSelected] = React.useState({
    index: -1,
    row: null,
  });

  const [expediente, setExpediente] = React.useState({
    open: false,
  });
  const [formularios, setFormularios] = React.useState({
    open: false,
  });
  const [moduloCovid, setModuloCovid] = React.useState({
    open: false,
    paciente: null,
    preguntaBase: 364,
  });

  const openModal = (modal, open = true) => {
    switch (modal) {
      case "moduloCovid":
        setModuloCovid((prev) => ({ ...prev, open }));
        break;
      case "expediente":
        setExpediente((prev) => ({ ...prev, open }));
        break;
      case "formularios":
        setFormularios((prev) => ({ ...prev, open }));
        break;
    }
  };

  const handleSelect = (row, index) => {
    const newSelected = { index: -1, row: null };

    if (selected.index !== index) {
      newSelected.index = index;
      newSelected.row = row;
    }

    setSelected(newSelected);
  };

  const actions = {
    expediente() {
      setLoading("Cargando Expediente del Paciente...");
      api
        .getExpediente(selected.row)
        .then(({ paciente }) => {
          const { preguntasTexto } = paciente;

          const triageCOVID19 = preguntas.find((e) => e.id === 364);
          if (triageCOVID19) preguntasTexto.push(triageCOVID19);

          console.log("PACIENTERESULTADO", paciente);
          setPacienteActual({ ...paciente, preguntasTexto });
          setExpediente({
            open: true,
            paciente: { ...paciente, preguntasTexto },
          });
        })
        .catch((err) => {
          notifications.error(err.texto);
        })
        .finally(() => setLoading(null));
    },
    consultar() {
      setLoading("Cargando Expediente del Paciente...");
      api
        .getExpediente(selected.row, true)
        .then(({ paciente }) => {
          const { preguntasTexto } = paciente;

          const triageCOVID19 = preguntas.find((e) => e.id === 364);
          if (triageCOVID19) preguntasTexto.unshift(triageCOVID19);

          setPacienteActual({ ...paciente, preguntasTexto });
          eventManager.emit(START_VIEW_TRANSITION, `#/admin/notas`);
        })
        .catch((err) => {
          notifications.error(err.texto);
          // logOut();
        })
        .finally(() => setLoading(null));
    },
    consultarFormulario(formulario) {
      setLoading("Cargando Expediente del Paciente...");

      const preguntasFormulario = preguntas.filter((v) =>
        formulario.preguntas.includes(v.id)
      );

      console.log("FORMULARIO", {
        ...formulario,
        preguntas: preguntasFormulario,
      });

      preguntasFormulario.forEach((v) => {
        delete v.respuestasInternas;
      });

      api
        .getExpedienteFormulario(selected.row, {
          ...formulario,
          preguntas: preguntasFormulario,
        })
        .then(({ paciente }) => {
          const { preguntasTexto } = paciente;

          const triageCOVID19 = preguntas.find((e) => e.id === 364);
          if (triageCOVID19) preguntasTexto.unshift(triageCOVID19);

          setPacienteActual({ ...paciente, preguntasTexto });
          eventManager.emit(START_VIEW_TRANSITION, `#/admin/notas`);
        })
        .catch((err) => {
          notifications.error(err.texto);
          // logOut();
        })
        .finally(() => setLoading(null));
    },
    editar() {
      const px = selected.row;
      props.setHelperSidebarRender(() => null);
      props.setDisplaySidebar(false);
      props.editar(px);
    },
    triage(preguntaBase = 364) {
      setLoading("Cargando Expediente del Paciente...");
      api
        .getExpediente(selected.row)
        .then(({ paciente }) => {
          const triageCOVID19 = preguntas.find((e) => e.id === preguntaBase);

          if (triageCOVID19) {
            triageCOVID19.respuestasInternas = [
              {
                id_pregunta: 101,
                vc_sentencia: repository.displaySummaryPatient(paciente, {
                  medicamentosDgis,
                  diagnosticos,
                }),
              },
            ];

            setPacienteActual({
              ...paciente,
              preguntasTexto: [triageCOVID19],
            });
          }

          setModuloCovid({
            open: true,
          });
        })
        .catch((err) => notifications.error(err.texto))
        .finally(() => setLoading(null));
    },
    formularios() {
      openModal("formularios");
    },
  };

  React.useEffect(() => {
    props.setHelperSidebarRender(() =>
      selected.row ? (
        <Sidebar selected={selected.row} actions={actions} />
      ) : null
    );
    props.setDisplaySidebar(!!selected.row);
  }, [selected.row]);

  React.useEffect(() => {
    if (selected.row) handleSelect(null, -1);

    (async () => {
      const res = await api.getPatients(search);
      setData(res);
    })();
  }, [search]);

  React.useEffect(() => {
    setTable(
      data.map((v) => {
        const vc_nombreCompleto =
          `${v.vc_nombre} ${v.vc_apellidoPaterno} ${v.vc_apellidoMaterno}`.trim();
        const vc_curpText = v.vc_curp
          ? v.vc_curp.toUpperCase()
          : "no especificado";
        // const vc_edad = v.dt_nacimiento
        //   ? moment().diff(moment(v.dt_nacimiento, "YYYY-MM-DD"), "years") +
        //     " años"
        //   : "no especificado";
        const vc_edad = getAge(v.dt_nacimiento)
        const vc_sexo = sexos[v.id_sexo || 0];

        return {
          ...v,
          vc_nombreCompleto,
          vc_curpText,
          vc_edad,
          vc_sexo,
        };
      })
    );
  }, [data]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      flexGrow={1}
      bgcolor="rgba(200,200,200,0.2)"
    >
      <SubMenuModulos
        type={"search"}
        searchText={search}
        onChange={(e) => setSearch(e.target.value)}
        scanner={
          <QRReader
            // getRegistro={(data) => console.log("get", data)}
            setRegistro={(data) => props.addPatient(data.detalle)}
          />
        }
      />
      <Box px={2} py={1} flex="1 1 auto" height="0px" overflow="scroll">
        <Table
          data={table}
          columns={columns}
          onSelect={handleSelect}
          value={selected.row?.id}
        />
      </Box>

      <Expediente
        {...expediente}
        onClose={() => openModal("expediente", false)}
      />
      <Formularios
        {...formularios}
        onClose={() => openModal("formularios", false)}
      />
      <ModuloCovid
        {...moduloCovid}
        onFinish={() => {}}
        onClose={() => openModal("moduloCovid", false)}
      />
    </Box>
  );
};

Index.propTypes = {
  setHelperSidebarRender: PropTypes.func,
  setPacienteActual: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
  logOut: PropTypes.func,
};

const actions = {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
};

const mapStateToProps = (state) => {
  const { preguntas, medicamentosDgis, diagnosticos } = state.Think;

  return {
    preguntas,
    medicamentosDgis,
    diagnosticos,
  };
};

export default connect(mapStateToProps, actions)(Index);

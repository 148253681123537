import React from "react";
import { connect } from "react-redux";
import QRCode from "qrcode.react";
import moment from "moment";
import { Image } from "semantic-ui-react";
import { Avatar, Box, Typography } from "@material-ui/core";
import useStyles from "./useStyles";

const Index = ({ module, paciente, printerMode, data = {}, date }) => {
  const classes = useStyles();
  const qrString = "https://wwww.thin-k.mx";
  const logoIzquierdo =
    data.vc_urlLogoIzquierdo || "assets/images/iconThin-k.png";

  const {
    vc_nombre = "",
    vc_apellidoPaterno = "",
    vc_apellidoMaterno = "",
    id_sexo = 0,
    dt_nacimiento,
  } = paciente?.detalle || {};

  const nombrePaciente = `${vc_nombre} ${vc_apellidoPaterno} ${vc_apellidoMaterno}`.trim();
  const sexo = ["- Sexo: NE", "- Sexo: M", "- Sexo: F"][id_sexo];
  const edad = dt_nacimiento
    ? `- Edad: ${moment().diff(moment(dt_nacimiento), "years")} años`
    : "";

  return (
    <Box className={classes.header}>
      <Box display="flex" alignItems="center" mb={1}>
        <Avatar className="avatar" variant="square" src={logoIzquierdo} />
        <Box className="info">
          <Typography style={{fontSize: 20, fontWeight: 600}}>{data?.vc_institucion || 'No Especificado'}</Typography>
          <Typography>{data?.vc_institucionAval || 'No Especificado'}</Typography>
          <Typography>{(data?.vc_especialidad || 'No Especificado').toLocaleUpperCase()}</Typography>
        </Box>
        <QRCode fgColor="black" value={qrString} className="qr" />
      </Box>
      {/* <Box className="title" mb={1}>
        Paciente
      </Box> */}
      <Typography>
        {'Paciente: '}{nombrePaciente.toLocaleUpperCase()} {sexo} {edad}
      </Typography>
      {/* <Box className="title" my={1}>
        {module}
      </Box> */}
      {date && (
        <Box className="date">
          {date.format("[Fecha:] dddd D MMMM YYYY - hh:mm a")}
        </Box>
      )}
    </Box>
  );
};

export default Index;

import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tooltip,
  IconButton,
  AccordionActions,
  Divider,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";

import Productos from "./Productos";
import InputProductos from "./InputProductos";
import useCrearProducto from "app/sales/hooks/useCrearProducto";

const Sales = ({ sales }) => {
  const { ModalForm, onOpen } = useCrearProducto();

  const [openNewSale, setOpenNewSale] = React.useState(false);
  const [currentSale, setCurrentSale] = React.useState([{
    cantidad: 1, nombre: "Mi producto", proveedor: "x", total: 214
  }, {
    cantidad: 2, nombre: "Otro producto", proveedor: "x", total: 322
  }]);

  const handleProducto = (producto) => {
    setProductos((prev) => [...prev, {}]);
  };

  return (
    <React.Fragment>
      <Box p={1} display="flex" mb={1}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          px={1}
        >
          <SearchIcon />
        </Box>

        <InputProductos onClick={handleProducto} />

        <Box
          display="flex"
          direction="row"
          justifyContent="center"
          alignItems="center"
          px={1}
        >
          <Tooltip title="Filtrar ventas">
            <IconButton size="small">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Crear venta">
            <IconButton disabled={openNewSale} size="small" onClick={() => setOpenNewSale(true)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {openNewSale && (
        <Accordion expanded>
          <AccordionSummary style={{ cursor: 'auto' }}>
            <Typography>Nueva venta</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Productos data={currentSale} editable />
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button onClick={() => {
              setOpenNewSale(false);
              setCurrentSale([]);
            }} size="small">Cancelar</Button>
          </AccordionActions>
        </Accordion>
      )}

      {sales.map(sale => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>#123 con fecha del {sale.fecha}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Productos data={sale.productos} editable={false} />
          </AccordionDetails>
        </Accordion>
      ))}

      <ModalForm />
    </React.Fragment>
  );
};

export default Sales;

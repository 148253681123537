import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';
import moment from 'moment';
import { Input } from '@material-ui/core';
import PreguntasNumericas from '../containers/Think/views/notas/preguntas/preguntasNumericas';

class TextAreaAutoCompleteComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: "",
            indexHover: null,
            indexSelected: this.props.indexSelected,
            opcionPreSelected: null,
            dia: null,
            fechaString: null
        };
    }
    componentDidMount() {
        document.onkeypress = this.modifyEnterKeyPressAsTab.bind(this);
        document.onkeydown = this.pressArrows.bind(this);
    }

    pressArrows(e) {
        let opcionPreSelected = this.state.opcionPreSelected;
        if (e.keyCode == 38) {
            if (opcionPreSelected == null || opcionPreSelected == 0) {
                opcionPreSelected = 0;
            } else {
                opcionPreSelected = (opcionPreSelected - 1);
            }
        } else if (e.keyCode == 40) {
            if (opcionPreSelected == null) {
                opcionPreSelected = 0;
            } else if (this.opcionesArray().length > (opcionPreSelected + 1)) {
                opcionPreSelected = (opcionPreSelected + 1);
            }
        }
        this.setState({ opcionPreSelected });
    }
    modifyEnterKeyPressAsTab(e) {
        if (e && e.keyCode === 13) {
            let pregunta = null;
            switch (this.props.notasTipoEditor) {
                case 'lateral':
                    pregunta = this.props.preguntaActual;
                    switch (pregunta.id_tipoPregunta) {
                        case 1:
                            if (this.state.opcionPreSelected != null) {
                                this.setRespuesta(this.opcionesArray()[this.state.opcionPreSelected], this.state.opcionPreSelected);
                            } else {
                                this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                            }
                            break;
                        case 3:
                            if (this.state.searchText / 1 > 0) {
                                const respuesta = {
                                    nu_respuesta: (this.state.searchText / 1).toFixed(1),
                                    id_pregunta: pregunta
                                };
                                pregunta.respuesta = respuesta;
                                this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                            } else {
                                this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                            }
                            break;
                        case 4:
                            let respuesta = null;
                            if (pregunta.respuesta) {
                                respuesta = {
                                    vc_respuesta: `${pregunta.respuesta.vc_respuesta} ${this.state.searchText}`,
                                    id_pregunta: pregunta
                                };
                            } else {
                                respuesta = {
                                    vc_respuesta: this.state.searchText,
                                    id_pregunta: pregunta
                                };
                            }
                            pregunta.respuesta = respuesta;
                            this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                            break;
                        default:
                            this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                            break;
                    }
                    break;
                case 'principal':
                    pregunta = this.props.preguntaActual;
                    switch (pregunta.id_tipoPregunta) {
                        case 1:
                            if (this.state.opcionPreSelected != null) {
                                this.setRespuesta(this.opcionesArray()[this.state.opcionPreSelected], this.state.opcionPreSelected);
                            } else {
                                this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                            }
                            break;
                        case 3:
                            if (this.state.searchText / 1 > 0) {
                                const respuesta = {
                                    nu_respuesta: (this.state.searchText / 1).toFixed(1),
                                    id_pregunta: pregunta
                                };
                                pregunta.respuesta = respuesta;
                                this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                            } else {
                                this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                            }
                            break;
                        case 4:
                            let respuesta = null;
                            if (pregunta.respuesta) {
                                respuesta = {
                                    vc_respuesta: `${pregunta.respuesta.vc_respuesta} ${this.state.searchText}`,
                                    id_pregunta: pregunta
                                };
                            } else {
                                respuesta = {
                                    vc_respuesta: this.state.searchText,
                                    id_pregunta: pregunta
                                };
                            }
                            pregunta.respuesta = respuesta;
                            this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                            break;
                        default:
                            this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                            break;
                    }
                    break;
                default:
                    break;
            };
        }
    }

    timeSince(refDate, date) {

        var seconds = Math.floor((refDate - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " años";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " minutos";
        }
        return "un instante";
    }
    //
    hoverIndex(index) {
        this.props.callbackHover({ indexHover: index })
    }
    onChangeValue(event) {
        this.setState({ searchText: event.target.value });
    }
    searchBarEvent(event) {
        switch (this.props.preguntaActual.id_tipoPregunta) {
            case 1:
                let opcionPreSelected = null;
                const primeraOpcion = this.opcionesArray().filter(e => e.vc_respuesta.toLowerCase().includes(event.target.value.toLowerCase()))[0]
                if (primeraOpcion) {
                    if (primeraOpcion.vc_respuesta.toLowerCase() == event.target.value.toLowerCase()) {
                        opcionPreSelected = 0;
                    }
                } else {
                    opcionPreSelected = null
                }
                this.setState({ searchText: event.target.value, opcionPreSelected });
                break;
            case 3:
                if (event.target.value / 1) {
                    this.setState({ searchText: event.target.value, opcionPreSelected: null });
                }
                break;
            case 4:
                this.setState({ searchText: event.target.value, opcionPreSelected: null });
                break;
        }
    }
    isValidSex(id_sexoPaciente, id_sexoPregunta) {
        if (id_sexoPaciente === id_sexoPregunta || id_sexoPregunta === 3) {
            return true;
        } else {
            return false;
        }
    }
    isValidAge(nuAgeMinutesPaciente, item) {
        const { unidadesMedida } = this.props;
        let limiteInferiorAge = null;
        let limiteSuperiorAge = null;
        if (item.nu_minEdad > 0 && item.id_um_minEdad != null) {
            const nuUmBase = this.finder(unidadesMedida, 'id', item.id_um_minEdad).nu_ratio;
            limiteInferiorAge = nuUmBase * item.nu_minEdad;

        }
        if (item.nu_maxEdad > 0 && item.id_um_maxEdad != null) {
            const nuUmBase = this.finder(unidadesMedida, 'id', item.id_um_maxEdad).nu_ratio;
            limiteSuperiorAge = nuUmBase * item.nu_maxEdad;

        }
        if (limiteInferiorAge != null && nuAgeMinutesPaciente < limiteInferiorAge) {
            return false;
        }
        if (limiteSuperiorAge != null && nuAgeMinutesPaciente > limiteSuperiorAge) {
            return false;
        }
        return true;
    }
    finder(array, key, value) {
        const object = array.find((e) => { return e[`${key}`] === value });
        if (object) {
            return object;
        }
        return null;
    }
    preguntaVersion(pacienteDetalle) {
        const { date, PreguntasNumericas, preguntaActual, respuestas } = this.props;
        const id_sexo = pacienteDetalle.id_sexo;
        const nu_edadMinutos = (new Date() - new Date(pacienteDetalle.dt_nacimiento)) / 60000;
        let version = null;
        preguntaActual.versiones.map((preguntaVersion, index) => {
            if (this.isValidAge(nu_edadMinutos, preguntaVersion) && this.isValidSex(id_sexo, preguntaVersion.id_sexo)) {
                version = preguntaVersion;
            }
        });
        return version;
    }
    opcionesNumericas(preguntaVersion) {
        const { preguntaActual, unidadesMedida } = this.props;
        const rango = preguntaActual.nu_superior - preguntaActual.nu_limiteInferior;
        const um = this.finder(unidadesMedida, 'id', preguntaVersion.id_um_numerico);
        const nu_opciones = rango / um.nu_escala;
        let opciones = [];
        let i = 0;
        for (i = 0; i < nu_opciones; i++) {
            const nu = preguntaVersion.nu_limiteInferior + (um.nu_escala * i)
            const opcion = {
                vc_respuesta: `${nu}`,
                valor: nu,
                style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
            }
            opciones.push(opcion);
        }
        return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()));
    }
    opcionesFechaHora() {
        let i = 0;
        let opciones = [];
        let pre = '';
        switch (this.state.opcionPregunta) {
            case 'minutos':
                opciones = [];
                i = 0;
                for (i = 0; i < 60; i++) {
                    pre = i < 10 ? '0' : '';
                    const opcion = {
                        vc_respuesta: `${pre}${i}`,
                        valor: `${pre}${i}`,
                        style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
                    }
                    opciones.push(opcion);
                }
                return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()));
            case 'horas':
                opciones = [];
                i = 0;
                for (i = 0; i < 24; i++) {
                    const pre = i < 10 ? '0' : '';
                    const opcion = {
                        vc_respuesta: `${pre}${i}`,
                        valor: `${pre}${i}`,
                        style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
                    }
                    opciones.push(opcion);
                }
                return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()));
            case 'dia':
                opciones = [];
                i = 0;
                for (i = 0; i < 31; i++) {
                    const pre = i < 9 ? '0' : '';
                    const opcion = {
                        vc_respuesta: `${pre}${i + 1}`,
                        valor: `${pre}${i + 1}`,
                        style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
                    }
                    opciones.push(opcion);
                }
                return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()));
            case 'mes':
                opciones = [];
                i = 0;
                for (i = 0; i < 12; i++) {
                    const nu = i < 9 ? `0${i + 1}` : `${i + 1}`;
                    const opcion = {
                        vc_respuesta: `${pre}${i + 1}`,
                        valor: `${pre}${i + 1}`,
                        style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
                    }
                    if ((nu == '02' && this.state.dia <= 29) || (nu == '04' && this.state.dia <= 30) || (nu == '06' && this.state.dia <= 30) || (nu == '09' && this.state.dia <= 30) || (nu == '11' && this.state.dia <= 30)) {
                        opciones.push(opcion);
                    }
                }
                return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()));
            case 'año':
                opciones = [];
                i = 0;
                for (i = 0; i < 150; i++) {
                    const año = `${moment().format('YYYY')}`;
                    const nu = (año - i);
                    const opcion = {
                        vc_respuesta: `${nu}`,
                        valor: `${nu}`,
                        style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
                    }
                    opciones.push(opcion);
                }
                return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()));
            default: return opciones;
        }
    }
    opcionesArray() {
        const { date, preguntaActual, pacienteActual, respuestas, preguntasRespuestas } = this.props;
        const lastDetalle = this.props.medicamento ? this.props.medicamento.detalles[this.props.medicamento.detalles.length - 1] : null;
        let opciones = [];
        let i = 0;
        const preguntaVersion = this.preguntaVersion(pacienteActual.detalle);

        if (preguntaVersion != null) {

            const preguntasVersionRespuestas = preguntasRespuestas.filter((e, index) => e.id_preguntaVersion === preguntaVersion.id);

            const respuestasVersion = [];
            preguntasVersionRespuestas.map((item) => {
                const respuesta = respuestas.filter((e, index) => e.id === item.id_respuesta)[0];
                if (respuesta) {
                    respuestasVersion.push(respuesta);
                }
            });

            switch (preguntaActual.id_tipoPregunta) {
                case 1:
                    return respuestasVersion.filter((e, index) => e.id_preguntaVersion === preguntaVersion.id).filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()));
                    break;
                case 2:
                    return respuestasVersion.filter((e, index) => e.id_preguntaVersion === preguntaVersion.id).filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()));
                    break;
                case 3:
                    return [];//this.opcionesNumericas(preguntaVersion);
                    break;
                case 5:
                    return [];//this.opcionesFechaHora();
                    break;
                case 6:
                    return [];//this.opcionesFechaHora();
                    break;
                default:
                    return []
                    break;
            };
        } else {
            return []
        }
    }
    renderTitleHelpBar() {
        const { date, preguntaActual, opcionMedicamentos, pacienteActual } = this.props;
        if (preguntaActual != null) {
            const version = this.preguntaVersion(pacienteActual.detalle);
            if (version != null) {
                return (
                    <div style={{ padding: 0, border: 0 }}>
                        <button style={{ width: '100%', padding: 10, border: 0, height: 74, textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                            {version.vc_pregunta}
                        </button>
                        <div style={styles.titleHelp}>
                            <Input
                                id='searchBar'
                                focus placeholder='Capture...'
                                value={this.state.searchText}
                                onChange={this.searchBarEvent.bind(this)}
                                style={{ width: '100%', height: 31 }}
                            />
                        </div>
                    </div>
                );
            } else {
                return (
                    <div style={{ padding: 0, border: 0 }}>
                        <button style={{ width: '100%', padding: 10, border: 0, height: 74, textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                            No hay ninguna version valida para el paciente actual
                        </button>
                        <div style={styles.titleHelp}>
                            <Input
                                id='searchBar'
                                focus placeholder='Buscar...'
                                value={this.state.searchText}
                                onChange={this.searchBarEvent.bind(this)}
                                style={{ width: '100%', height: 31 }}
                            />
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div style={{ padding: 0, border: 0 }}>
                    <button style={{ width: '100%', padding: 10, border: 0, height: 74, textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                        Seleccione una pregunta
                    </button>
                    <div style={styles.titleHelp}>
                        <Input
                            id='searchBar'
                            focus placeholder='Buscar...'
                            value={this.state.searchText}
                            onChange={this.searchBarEvent.bind(this)}
                            style={{ width: '100%', height: 31 }}
                        />
                    </div>
                </div>
            );
        }
    }
    setRespuesta(respuesta, index) {
        const pregunta = this.props.preguntaActual;
        pregunta.respuesta = {
            id_pregunta: pregunta.id,
            id_respuesta: respuesta.id
        };
        console.log('respuesta');
        this.setState({ searchText: '', opcionPreSelected: null }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
    }
    renderPreguntasNumericas(preguntas) {
        return preguntas.map((item, index) =>
            <PreguntasNumericas
                callbackPreg={({ value, index }) => {
                    const preguntasNumericas = preguntas;
                    if ((value * 1 || value == '' || value == '0' || value == '0.') && preguntasNumericas[index].id_tipoPregunta == 3) {
                        preguntasNumericas[index].respuesta = { id_pregunta: item.id, nu_respuesta: value };
                        if (preguntasNumericas[index].id == 241 || preguntasNumericas[index].id == 242) {
                            if (preguntasNumericas.filter((e, index) => e.id == 241).length > 0 && preguntasNumericas.filter((e, index) => e.id == 242).length > 0) {
                                if (preguntasNumericas.filter((e, index) => e.id == 241)[0].respuesta && preguntasNumericas.filter((e, index) => e.id == 242)[0].respuesta) {
                                    if (preguntasNumericas.filter((e, index) => e.id == 241)[0].respuesta.nu_respuesta && preguntasNumericas.filter((e, index) => e.id == 242)[0].respuesta.nu_respuesta) {
                                        const tallaMts = (preguntasNumericas.filter((e, index) => e.id == 241)[0].respuesta.nu_respuesta / 100);
                                        const imc = ((preguntasNumericas.filter((e, index) => e.id == 242)[0].respuesta.nu_respuesta) / (tallaMts * tallaMts));
                                        preguntasNumericas.filter((e, index) => e.id == 243)[0].respuesta = {
                                            id_pregunta: 243,
                                            nu_respuesta: imc.toFixed(1)
                                        };
                                    }
                                }
                            }
                        }
                    } else if ((value * 1 || value == '' || value == '0' || value == '0.') && preguntasNumericas[index].id_tipoPregunta == 5) {

                    }
                    this.props.callbackPreguntasNumericas({ preguntasNumericas });
                }}
                callbackWizard={({ indexSeleccionado }) => {
                    this.props.callbackWizard({ indexSeleccionado: index });
                }}
                item={item}
                sn_selected={this.state.indexSeleccionado == index ? true : false}
                index={index}
                key={index}
            />
        );
    }
    renderOpcionesHelpBar() {

        const { date, indexSeleccionado, notasTipoEditor, preguntasNumericas, pregunta, opcion } = this.props;

        if (notasTipoEditor == 'principal') {
            return (
                this.opcionesArray().map((item, index) =>
                    <div onClick={() => this.setRespuesta(item, index)} key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp}>
                        <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp}>
                            {item.vc_respuesta}
                        </div>
                    </div>
                )
            );
        } else {
            return (
                <div style={{ border: 0, padding: 0 }}>
                    {this.renderPreguntasNumericas(preguntasNumericas)}
                </div>
            );
        }
    }
    textoRespuesta(index) {
        return `${this.props.preguntas.filter((p) => (p.id === 3))[0].versiones[0].respuestas[index].vc_respuestaLogica}, `;
    }
    // textoRespuesta(index) {
    //     // return {
    //     //     id_respuesta: this.props.preguntas.filter((p) => (p.id === 3))[0].versiones[0].respuestas[index].id,
    //     //     vc_respuesta: `${this.props.preguntas.filter((p) => (p.id === 3))[0].versiones[0].respuestas[index].vc_respuestaLogica}, `,
    //     // }
    //     return `${this.props.preguntas.filter((p) => (p.id === 3))[0].versiones[0].respuestas[index].vc_respuestaLogica}, `;
    // }
    renderOptionsPopUp(name, char) {

        if (char == '0') {
            return (<Popup
                trigger={<div>{`${(char * 1) + 1}.-${name}`}</div>}
                content={this.props.preguntaActual.versiones[0].vc_pregunta}
                verticalOffset={50}
                position='top center'
            />)
        }
        return (<div>{`${(char * 1) + 1}.-${name}`}</div>)
    }
    render() {
        const { indexSeleccionado, index, opcion } = this.props;
        const shadow = this.state.indexSelected == index ? '1px 1px 20px rgba(0, 0,0, 1)' : '1px 0px 10px rgba(0, 0,0, 0.7)';
        const Item = ({ entity: { name, char } }) => this.renderOptionsPopUp(name, char);
        const Loading = ({ data }) => <div>Loading</div>;
        return (
            <ReactTextareaAutocomplete
                className="my-textarea"
                loadingComponent={Loading}
                style={{
                    fontSize: "12px",
                    lineHeight: "20px",
                    padding: 5
                }}
                ref={(rta) => { this.rta = rta; }}
                innerRef={(textarea) => {
                    console.log('textArea', textarea);
                    this.textarea = textarea;
                }}
                containerStyle={{
                    marginTop: 20,
                    width: 'calc(100% - 10px)',
                    height: 'calc(100% - 5px)',
                    marginLeft: 5,
                    marginRight: 5,
                    marginBottom: 5
                }}
                minChar={0}
                trigger={{
                    " ": {
                        dataProvider: token => {
                            const opciones = this.props.preguntaActual.versiones[0].respuestas;
                            console.log('respuestas', opciones.length);
                            return opciones.map(({ vc_respuesta }, index) => ({ name: vc_respuesta, char: `${index}` }));
                        },
                        component: Item,
                        output: (item, trigger) => this.textoRespuesta(item.char)
                    }
                    // output: (item, trigger) => {
                    //     let respuestas = this.state.respuestas;
                    //     respuestas.push()
                    //    this.setState({respuestas});
                    // }
                    // }
                }}
            />
        );
    }
}
const styles = {
    container: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0
    },
    containerSub: {
        overflowX: 'hidden',
        overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
        width: '100%',
        height: 'calc(100% - 111px)',
        margin: 0,
        padding: 0
    },
    containerHelp: {
        margin: 0,
        marginBottom: 2,
        borderRadius: 3,
        overflowY: "hidden",
        padding: 10,
        minHeight: 40,
        color: "black",
        backgroundColor: window.colors.white
    },
    containerSelectedHelp: {
        margin: 0,
        marginBottom: 2,
        borderRadius: 3,
        overflowY: "hidden",
        padding: 10,
        minHeight: 40,
        marginBottom: 2,
        color: "white",
        backgroundColor: window.colors.itemSelected
    },
    descriptionHelp: {
        margin: 0,
        marginTop: 5,
        marginLeft: 10,
        marginRight: 10,
        textAlign: "left",
        fontSize: 14,
        minHeight: 40,
        color: "gray"
    },
    descriptionSelectedHelp: {
        margin: 0,
        marginTop: 5,
        marginLeft: 10,
        marginRight: 10,
        textAlign: "left",
        fontSize: 14,
        minHeight: 40,
        color: "white"
    },
    stickHelp: {
        margin: 0,
        marginLeft: "85%",
        marginBottom: 2,
        width: 30,
        overflowY: "hidden",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color: window.colors.itemSelected
    },
    subTitleHelp: {
        margin: 0,
        marginLeft: 10,
        marginRight: 10,
        width: "100%",
        overflowY: "scroll",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color: "black"
    },
    subTitleRightHelp: {
        margin: 0,
        marginLeft: 10,
        marginRight: 10,
        width: "100%",
        paddingTop: 0,
        textAlign: "right",
        float: "right",
        fontSize: 12,
        color: window.colors.itemSelected
    },
    titleHelp: {
        width: "100%",
        padding: 3,
        height: 37,
        color: "white",
    }
};


const mapStateToProps = (state) => {
    const { pacienteActual, unidadesMedida, preguntas, preguntasRespuestas, respuestas } = state.Think;
    return { pacienteActual, unidadesMedida, preguntas, preguntasRespuestas, respuestas };
};


export const TextAreaAutoComplete = connect(mapStateToProps)(TextAreaAutoCompleteComponent);

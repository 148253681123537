import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input, Button, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import notifications from '../../../../utils/notifications';
import { setHelperSidebarRender, resetHelperSidebarRender, updateCatalogs, setDisplaySidebar } from '../../actions';
import GpcHelpBar from '../gpcHelpBar';
import { menuInicio, opcionesMenu } from '../../listados';
import NewsPublications from './NewsPublications';
import * as firebase from 'firebase/app';
// import WizardCard from '../../../../admin/components/WizardCard';
import SubMenuModulos from '../../../../material-ui/SubMenuModulos';
import Coronavirus from './Coronavirus';
import AdministacionesItem from '../notas/Indicaciones/AdministracionesItem';
import SpeechButton from '../../../../material-ui/SpeechButton';
const amplify = window.amplify;
const $ = window.$;
const colors = window.colors;
const themes = window.themes;

class IndexView extends Component {
  constructor(props) {
    super(props);
    this.search = null;
    this.app = firebase;
    this.db = this.app.database().ref();
    this.state = {
      indexSelected: null,
      moduloActual: 'gpc',
      opcionActualModulo: 'rr',
      noticias: [],
      menuInicio,
      opcionesMenu,
      publications: [],
      loginSocket: null,
      questionsOpen: true,
    };
  }

  componentDidMount() {
    this.updateHelperSidebar();
    const myParam = window.location.search.split('vc_token=')[1];
    if (myParam) {
      this.setState({ validating: true });
      $.ajax({
        url: `${window.backEndURL}/api/validarUsuario?vc_token=${myParam}`,
        method: 'GET'
      }).then((data) => {
        if (data.error) {
          notifications.info(data.error);
        }

        if (data.message) {
          notifications.info(data.message);
          window.location = '#/login';
        }
        this.setState({ loading: false });
      }).fail((data, status, xhr, error) => {
        if (data.error) {
          notifications.info(data.error);
        } else if (error) {
          notifications.info(data.error);
        }
        this.setState({ loading: false });
      });
    }

    this.props.setActiveView('gpc');
    this.props.setViewTitle('¡Bienvenido!');
    this.setMenuOptions();
    this.suscriptionsFirebaseNews()
  }

  componentWillUnmount() {
    this.props.resetHelperSidebarRender();
  }


  // ----------------------
  // ------ methods -------
  // ----------------------


  suscriptionsFirebaseNews() {
    this.db.child("publicaciones").child("pacientes").limitToLast(10).on('value', snapshot => {
      if (snapshot.val() != null) {
        console.log(snapshot.val())
        this.setState({ publications: Object.values(snapshot.val()) })
      }
    });
  }

  onChangeModule(modulo, opcion, subOpcionActualModulo) {

    if (this.validateLogin()) {
      if (typeof modulo === 'string') {
        const moduloActual = menuInicio.filter((e) => e.id == modulo)[0];
        this.setState({
          indexSeleccionado: null,
          moduloActual: moduloActual.id,
          opcionActualModulo: opcion || moduloActual.opciones[0].id,
          subOpcionActualModulo: subOpcionActualModulo || 'agregar',
          searchText: '',
        }, () => {
          if (modulo.sidebar) {
            if (!this.props.drawersLayout.sidebar && modulo.sidebar.open) {
              this.props.setDisplaySidebar(true);
            } else if (!this.props.drawersLayout.sidebar || !modulo.sidebar.open) {
              this.props.setDisplaySidebar(false);
            }
          }
          this.updateHelperSidebar();
        });
      } else {
        this.setState({
          moduloActual: modulo.id,
          indexSeleccionado: null,
          opcionActualModulo: opcion || modulo.opciones[0].id,
          subOpcionActualModulo: subOpcionActualModulo || 'agregar',
          searchText: ''
        }, () => {
          if (modulo.sidebar) {
            if (!this.props.drawersLayout.sidebar && modulo.sidebar.open) {
              this.props.setDisplaySidebar(true);
            } else if (!this.props.drawersLayout.sidebar || !modulo.sidebar.open) {
              this.props.setDisplaySidebar(false);
            }
          }
          this.updateHelperSidebar();
        });
      }




      // this.props.setViewTitle(modulo.vc_nombre);
      // this.setState({ moduloActual: modulo.id }, () => {
      //   if (modulo.sidebar) {
      //     if (!this.props.drawersLayout.sidebar && modulo.sidebar.open) {
      //       this.props.setDisplaySidebar(true);
      //     } else if (!this.props.drawersLayout.sidebar || !modulo.sidebar.open) {
      //         this.props.setDisplaySidebar(false);
      //     }
      //   }
      // 	this.updateHelperSidebar();
      // });
    } else if (modulo.id === 'gpc') {
      notifications.success('¡Registrate gratis para acceder a las Guias!');
    } else {
      notifications.success('¡Registrate con un plan para acceder a todas las herramientas!');
    }
  }

  setMenuOptions() {
    const options = this.state.menuInicio.map((option) => ({
      ...option,
      onClick: this.onChangeModule.bind(this),
    }));

    this.props.setSubMenuOptions(options);
  }

  renderHelperSidebar() {
    switch (this.state.moduloActual) {
      case 'gpc':
        return (
          <div style={{ width: '100%', height: '100%', padding: 0, border: 0, backgroundColor: colors.black }}>
            <GpcHelpBar
              callbackGPC={(gpcSelected) => {
                this.setState({ gpcSelected, viewTitle: null }, () => {
                  this.updateHelperSidebar();
                });
              }}
            />
          </div>
        );
      default:
        break;
    }
    return null;
  }

  clickAccion(accion) { // eslint-disable-line
    switch (this.state.moduloActual) {
      case 'gpc':
        this.setState({ gpcSelected: null }, () => {
          this.updateHelperSidebar();
        });
        break;

      // eslint-disable-next-line no-case-declarations
      case 'configuracion':
        notifications.info('Tu primer Institución o Consultorio debe Ser Creado por un Asesor.');
        break;
      default:
        break;
    }
  }
  getModuloActual(e) {
    const { moduloActual } = this.state;

    let find = false;

    if (e.id === moduloActual) {
      find = e.id === moduloActual;
    } else if (e.parentId === moduloActual) {
      find = e.parentId === moduloActual;
    }

    return find;
  }

  validateLogin() {
    if (amplify.store('token') != null) {
      return true;
    }
    return false;
  }

  validateUsuarioInstitucion() {
    if (
      !amplify.store('user') &&
      !amplify.store('user_id') &&
      !amplify.store('institution') &&
      !amplify.store('token')
    ) {
      return true;
    }
    return false;
  }

  updateHelperSidebar() {
    this.props.setHelperSidebarRender(this.renderHelperSidebar.bind(this));
  }

  // update() {
  //   internalAPI.updateCatalogs({
  //     actualizacion:
  //       [
  //         {
  //           id_catalogo: 3,
  //           dt_actualizacion: '2017-01-01'
  //         },
  //         {
  //           id_catalogo: 5,
  //           dt_actualizacion: '2017-01-01'
  //         },
  //         {
  //           id_catalogo: 6,
  //           dt_actualizacion: '2017-01-01'
  //         },
  //         {
  //           id_catalogo: 7,
  //           dt_actualizacion: '2017-01-01'
  //         },
  //         {
  //           id_catalogo: 9,
  //           dt_actualizacion: '2017-01-01'
  //         },
  //         {
  //           id_catalogo: 10,
  //           dt_actualizacion: '2017-01-01'
  //         },
  //         {
  //           id_catalogo: 17,
  //           dt_actualizacion: '2017-01-01'
  //         }

  //       ]
  //   }, (err, response) => {
  //     if (err) {
  //       console.log('Error: ', err);
  //     } else {
  //       this.props.updateCatalogs(response);
  //     }
  //   });

  //   internalAPI.updatePreguntas({
  //     dt_actualizacion
  //   }, (err, response) => {
  //     if (err) {
  //       console.log('Error: ', err);
  //     } else {
  //       this.props.updateCatalogs([
  //         {
  //           vc_nombreTabla: 'cat_preguntas',
  //           registros: response
  //         }
  //       ]);
  //     }
  //   });
  // }

  dropDownNavText(modActual, opActual) {
    const { moduloActual: actual, opcionActualModulo } = this.state;

    const ini = menuInicio.find(mn => mn.id === actual);
    const iniOpArr = ini ? ini.opciones : [];
    const findOpAr = (it) => it.id === opcionActualModulo;
    const opIni = iniOpArr.find(findOpAr) || iniOpArr[0];

    if (actual === 'configuracion') {
      return `${ini.vc_nombre} > ${opIni.vc_nombre}`;
    }

    return `${modActual.vc_nombre} > ${opActual.vc_nombre}`;
  }


  // -----------------------------
  // ------ render methods -------
  // -----------------------------
  renderDropdownMenu(dropItems) {
    let finalItems = [];
    const { moduloActual } = this.state;
    const menuFind = menuInicio.find(mn => mn.id === moduloActual);

    if (moduloActual === 'configuracion') {
      finalItems = menuFind ? menuFind.opciones : [];
    } else {
      finalItems = dropItems ? dropItems.opciones : [];
    }

    return finalItems.map((opcion, index) => (
      <Dropdown.Item
        key={index}
        onClick={() => this.onChangeModule(moduloActual, opcion, 'agregar')}
      >
        {opcion.vc_nombre}
      </Dropdown.Item>
    ));
  }

  renderHelperMenu() {
    const { menuInicio: menu, opcionActualModulo } = this.state;
    const moduloActual = menu.filter(this.getModuloActual.bind(this))[0];
    const arrOpcionesMA = moduloActual ? moduloActual.opciones : [];
    const opcionActual = arrOpcionesMA.filter((e) => e.id === opcionActualModulo)[0] ?
      arrOpcionesMA.filter((e) => e.id === opcionActualModulo)[0] : [];
    const greyNavWidth = window.innerWidth / 1.45;
    const acciones = (opcionActual.acciones || []).map((accion) => {
      return {
        ...accion,
        action: () => {
          console.log('dallls')
          this.setState({ gpcSelected: null });
        }
      }
    });
    const opciones = moduloActual.opciones.map((opcion) => {
      return {
        ...opcion,
        action: () => {
          this.onChangeModule(moduloActual.id, opcion.id, 'agregar')
        }
      }
    });
    return (
      <SubMenuModulos
        actions={acciones}
        options={opciones}
        optionDisplay={[
          moduloActual.vc_nombre,
          opcionActual.vc_nombre
        ]}
      />
    );

    const DropDownMenu = (
      <Dropdown simple text={this.dropDownNavText(moduloActual, opcionActual)} style={{ fontSize: 13, height: '100%', padding: 8 }}>
        <Dropdown.Menu style={{ width: '100%', backgroundColor: 'rgb(230,230,230)' }}>
          {this.renderDropdownMenu(moduloActual)}
        </Dropdown.Menu>
      </Dropdown>
    );

    const Search = (name) => (
      <Input
        style={{ width: greyNavWidth }}
        placeholder={name}
      />
    );

    const options = acciones.map((accion, index) => {
      if (accion.id === 'buscar') {
        return Search(accion.vc_nombre);
      }

      return (
        <Button key={index} size='mini' basic onClick={() => this.clickAccion(accion)}>
          {`${accion.vc_nombre}`}
        </Button>
      );
    });
    return (
      <div style={themes.helperMenuStyle}>
        {acciones.find(ac => ac.id === 'buscar') ? null : DropDownMenu}
        <div style={{ height: 35, float: 'left', textAlign: 'center', borderRadius: 0, padding: 3, margin: 0 }}>
          {options}
        </div>
      </div>
    );
  }

  renderContent() {
    const { gpcSelected, opcionActualModulo } = this.state;
    switch (this.state.moduloActual) {

      case 'noticias':
        if (this.state.noticias.length === 0) {
          return (
            <Coronavirus />
          )
          return (
            <div style={{ width: '100%', height: '100%', padding: 0, margin: 0 }}>
              <NewsPublications
                publications={this.state.publications}
              />
            </div>
          );
        }
        return (
          <div style={{ width: '100%', height: '100%', padding: '2%', margin: 0 }} />
        );
      // eslint-disable-next-line no-case-declarations
      case 'gpc':
        if (!this.state.gpcSelected) {
          return (
            <div style={{ width: '100%', height: '100%', padding: '2%', margin: 0 }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  padding: '5%',
                  margin: 0,
                  borderRadius: 10,
                  // boxShadow: '1px 0px 10px rgba(0, 0,0, 0.7)',
                  backgroundImage: 'url(assets/images/descktopMedical.jpg)',
                  backgroundSize: 'cover'
                }}
              >
                <h1 style={{ width: '50%', color: 'black', marginTop: '17%' }}>
                  Thin-k:
                  </h1>
                <h2 style={{ width: '50%', color: colors.white }}>
                  Las Guias de Practica Clínica del CENETEC disponibles a un click.
                  </h2>
              </div>
            </div>
          );
        }
        const url = opcionActualModulo === 'er' ? gpcSelected.vc_urlEyR : gpcSelected.vc_urlReferenciaRapida;
        return (
          <div style={{ width: '100%', height: '100%', padding: '2%', margin: 0 }}>
            <iframe
              id={'pdfViewer'}
              onAbort={() => console.log('salio del load')}
              name={gpcSelected.vc_nombre}
              src={url}
              style={{
                width: '100%',
                height: '100%',
                padding: 0,
                margin: 0,
                borderRadius: 5,
                // boxShadow: '1px 0px 10px rgba(0, 0,0, 0.7)'
              }}
            />
          </div>
        );
      default:
        break;
    }
    return null;
  }

  render() {
    // return (
    //   <AdministacionesItem title={'Ceftriaxona 1gr iv'}/>
    // )
    return (
      <div>
        {this.renderHelperMenu()}
        <div style={{ width: '100%', height: window.innerHeight - 74, overflowY: 'scroll', WebkitOverflowScrolling: 'touch', padding: 0 }}>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

IndexView.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  updateCatalogs: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { preguntas, drawersLayout, catalogos } = state.Think;
  return { preguntas, drawersLayout, catalogos };
};


const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  updateCatalogs,
  setDisplaySidebar
};


export default connect(mapStateToProps, actions)(IndexView);

import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  Box,
  IconButton,
  Tabs,
  Tab
} from "@material-ui/core";
import useApi from "app/sales/hooks/useApi";
import PatientsItem from "app/components/PatientsItem";
import Sales from "./Sales";
import Payments from './Payments';
import Detalle from "./Detalle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={{ height: '100%', width: '100%', overflowY: 'auto' }}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Account = (props) => {
  console.log(props);
  const { onClose = () => {}, open, cuenta = {} } = props;

  const [sales, setSales] = React.useState([]);
  const [payments, setPayments] = React.useState([]);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => setValue(newValue);

  const detalle = [
    { label: "Subtotal:", value: "$ 1,000" },
    { label: "Iva:", value: "$ 120" },
    { label: "Total:", value: "$ 1,120" },
    { label: "Abonado:", value: "$ 7000" },
  ];

  let api = null;

  if (Boolean(cuenta)) {
    api = useApi(`clientes/${cuenta.uuid}/cuentas`);
  }

  React.useEffect(() => {
    (async () => {
      if (Boolean(api)) {
        const data = await api.get();
      }
    })();
  }, []);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth={"md"}
      PaperProps={{ style: { overflowX: "hidden" } }}
    >
      <PatientsItem pacienteActual={{ detalle: props.cuenta }} />

      <Divider />

      <DialogContent style={{ height: 'calc(100vh - 100px)', padding: 0 }}>
        <Box display="flex" flexDirection="column">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Cargos" />
            <Tab label="Historial de pagos" />
          </Tabs>

          <TabPanel value={value} index={0}>
            <Sales sales={sales} />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Payments data={payments} />
          </TabPanel>
        </Box>
      </DialogContent>

      <DialogActions style={{ height: 128, marginBottom: 8 }}>
        <Box width="100%">
          <Divider />

          <Detalle data={detalle} />

          <Box style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 8
          }}>
            <Button
              onClick={onClose}
              variant="outlined"
              style={{ marginLeft: 8 }}
            >
              CANCELAR
            </Button>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: 8 }}
              >
                Finalizar
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 8 }}
              >
                Abonar
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 8 }}
              >
                Abonar e imprimir
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

Account.defaultProps = {
  open: false,
  onClose: () => {},
};

Account.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Account;

export const menuShop = [
  {
    id: "shop",
    vc_nombre: "Caja",
    enable: true,
    sidebar: {
      open: false,
      enable: true,
    },
    opciones: [
      {
        id: "accountsDay",
        vc_nombre: "Cuentas del Dia",
        acciones: [
          {
            id: "agregar",
            vc_nombre: "Agregar Cuenta",
            icon: null,
          },
          {
            id: "corte",
            vc_nombre: "Corte de Caja",
            icon: null,
          },
        ],
      },
      {
        id: "accountsOpen",
        vc_nombre: "Cuentas Abiertas",
        acciones: [],
      },
      {
        id: "accountsHistory",
        vc_nombre: "Historial",
        acciones: [],
      },
    ],
  },
  {
    id: "cuts",
    vc_nombre: "Cortes",
    enable: true,
    sidebar: {
      open: false,
      enable: true,
    },
    // opciones: [
    //     {
    //         id: 'accountsOpen',
    //         vc_nombre: 'Cuentas Abiertas',
    //         acciones: [
    //             {
    //                 id: 'agregar',
    //                 vc_nombre: 'Agregar Cuenta',
    //                 icon: null
    //             },
    //             {
    //                 id: 'corte',
    //                 vc_nombre: 'Corte de Caja',
    //                 icon: null
    //             }
    //         ]
    //     },
    //     {
    //         id: 'history',
    //         vc_nombre: 'Historial',
    //         acciones: [

    //         ]
    //     }
    // ]
  },
  {
    id: "products",
    vc_nombre: "Productos",
    enable: true,
    sidebar: {
      open: false,
      enable: true,
    },
  },
  // {
  //   id: "settings",
  //   vc_nombre: "Configuración",
  //   enable: true,
  //   sidebar: {
  //     open: false,
  //     enable: true,
  //   },
  // },
];

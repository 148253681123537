import * as THREE from "three";
import GLTFLoader from 'three-gltf-loader';

const loadResourse3D = function (gltf) {
  this.mesh = gltf.scene.children[0];
  console.log('mesh', this.mesh)
  // this.mesh.material = new THREE.MeshPhongMaterial({
  //   specular: 0x111111,
  //   map: this.textureLoader.load('../../../../assets/3Dmodels/LeePerrySmith/Map-COL.jpg'),
  //   specularMap: this.textureLoader.load('../../../../assets/3Dmodels/LeePerrySmith/Map-SPEC.jpg'),
  //   normalMap: this.textureLoader.load('../../../../assets/3Dmodels/LeePerrySmith/Infinite-Level_02_Tangent_SmoothUV.jpg'),
  //   shininess: 10,
  // });

  // this.scene.add(this.mesh);

  console.log('mesh ->', this.mesh.children[0].geometry)
  this.mesh.children.map((child, index) => {
    const geometry = child.geometry;

    const wireframe = new THREE.WireframeGeometry(geometry);

    const line = new THREE.LineSegments(wireframe);
    line.scale.set(1, 1, 1);
    line.position.set(0, -100, 0);
    line.material.depthTest = false;
    line.material.opacity = 0.25;
    line.material.transparent = true;
    if (index !== 4)
    this.scene.add(line);
  })
  this.start();
}
const loadGLB = function (url) {
  var loader = new GLTFLoader();
  loader.load(url, loadResourse3D.bind(this));
}

const loaderObject3D = function (url) {
  console.log(this)
  switch (url.split('.').pop()) {
    case 'glb': {
      loadGLB.bind(this)(url);
      break;
    }
    case 'obj': {
      loadGLB.bind(this)(url);
      break;
    }
  }
}
export default (loaderObject3D);
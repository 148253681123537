import React from "react";

import { Box, Input, IconButton, Divider, ListItem } from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import _ from "lodash";

import Select from "app/components/Form/Select";

// const round = (num) => +(Math.round(num + "e+2") + "e-2");
const round = (num) => {
  const indexOf = `${num}`.indexOf(".");
  if (indexOf > 0) return Number(`${num}`.slice(0, indexOf + 3));
  return num;
};

const columnStyle = {
  padding: 8,
  paddingTop: 10,
  paddingBottom: 8,
  fontSize: 12,
  textAlign: "center",
};

export default (props) => {
  const {
    data = [],
    addClick = () => {},
    removeClick = () => {},
    onChange = () => {},
    search = "",
    saleCut = null,
  } = props;

  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    const filtered = data.filter((v) =>
      `${v.measureUnit}${v.vc_code}${v.vc_description}`
        .toLowerCase()
        .includes(search.toLowerCase())
    );

    const aux = filtered.map((item) => {
      const nu_unitValue = Number(`${item.nu_unitValue}`.split(",").join(""));
      const subtotal = item.nu_quantity * nu_unitValue;
      const iva = round(subtotal * (item.nu_tax > -1 ? item.nu_tax : 0.16));
      let total = subtotal + iva;
      const descuento = (total * (item.nu_discount || 0)) / 100;

      total = total - descuento;

      return {
        id_product: item.id_product,
        nu_quantity: item.nu_quantity,
        vc_description: item.vc_description || "N/A",
        measureUnit: item.measureUnit || "N/A",
        vc_code: item.vc_code || "N/A",

        nu_discount: item.nu_discount || null,
        nu_unitValue: nu_unitValue ? `$ ${nu_unitValue.toFixed(2)}` : "N/A",
        iva: iva ? `$ ${iva.toFixed(2)}` : "N/A",
        total: total ? `$ ${total.toFixed(2)}` : "N/A",
      };
    });

    setProducts(aux);
  }, [search, data]);

  const handleChange = (item, value) => {
    if (value && !Number(value)) return;

    const index = data.findIndex((v) => v.id_product == item.id_product);
    const product = data[index];

    product.nu_quantity = Number(value);

    onChange(product, index);
  };

  const handleDiscount = (item, value) => {
    const index = data.findIndex((v) => v.id_product == item.id_product);
    const product = data[index];

    product.nu_discount = value;

    onChange(product, index);
  };

  return [
    <Divider key={"divider1"} />,
    <GridUI key={"header"} container spacing={0}>
      <GridUI
        item
        xs={!!saleCut ? 1 : 2}
        style={{ padding: 8, textAlign: "center" }}
      >
        Cant.
      </GridUI>
      <GridUI item xs={1} style={{ padding: 8 }}>
        Unidad
      </GridUI>
      <GridUI item xs={1} style={{ padding: 8 }}>
        Codigo
      </GridUI>
      <GridUI item xs={!!saleCut ? 5 : 4} style={{ padding: 8 }}>
        Descripción
      </GridUI>
      <GridUI item xs={1} style={{ padding: 8 }}>
        Descuento
      </GridUI>
      <GridUI item xs={1} style={{ padding: 8, textAlign: "right" }}>
        Unitario
      </GridUI>
      <GridUI item xs={1} style={{ padding: 8, textAlign: "right" }}>
        IVA
      </GridUI>
      <GridUI item xs={1} style={{ padding: 8, textAlign: "right" }}>
        Importe
      </GridUI>
    </GridUI>,
    <Divider />,
    <Box
      key={"data"}
      style={{ flex: "1 1 auto", overflowY: "auto", minHeight: 0, height: 0 }}
    >
      {products.map((item, index) => {
        return (
          <ListItem
            // onClick={(_) => !saleCut && addClick(item, index)}
            key={index}
            // button
            style={{ padding: 0, width: "100%" }}
          >
            <GridUI container spacing={0}>
              <GridUI
                item
                xs={!!saleCut ? 1 : 2}
                style={{
                  ...columnStyle,
                  paddingTop: !!saleCut ? 10 : 3,
                  paddingBottom: 3,
                }}
              >
                {!!saleCut ? (
                  item.nu_quantity
                ) : (
                  <Input
                    disableUnderline
                    startAdornment={
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeClick(item, index);
                        }}
                      >
                        {" "}
                        <Remove color="primary" />{" "}
                      </IconButton>
                    }
                    endAdornment={
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          addClick(item, index);
                        }}
                      >
                        {" "}
                        <Add color="primary" />{" "}
                      </IconButton>
                    }
                    value={item.nu_quantity}
                    onChange={(e) => handleChange(item, e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    inputProps={{
                      style: { textAlign: "center", padding: 0, margin: 0 },
                    }}
                    style={{ padding: 0, margin: 0 }}
                  />
                )}
              </GridUI>
              <GridUI item xs={1} style={columnStyle}>
                {item.measureUnit}
              </GridUI>
              <GridUI item xs={1} style={columnStyle}>
                {item.vc_code}
              </GridUI>
              <GridUI
                item
                xs={!!saleCut ? 5 : 4}
                style={{
                  ...columnStyle,
                  textAlign: "left",
                }}
              >
                {item.vc_description}
              </GridUI>
              <GridUI
                item
                xs={1}
                style={{
                  padding: 0,
                  textAlign: "left",
                }}
              >
                {saleCut && (item.nu_discount ? `${item.nu_discount}%` : "N/A")}
                {!saleCut && (
                  <Select
                    preventDefault
                    dense
                    hideSearch
                    value={item.nu_discount}
                    onClick={(option) => handleDiscount(item, option.value)}
                    data={[
                      { value: null, text: "N/A" },
                      { value: 10, text: "10%" },
                      { value: 20, text: "20%" },
                      { value: 30, text: "30%" },
                    ]}
                  />
                )}
              </GridUI>
              <GridUI
                item
                xs={1}
                style={{
                  ...columnStyle,
                  textAlign: "right",
                }}
              >
                {item.nu_unitValue}
              </GridUI>
              <GridUI
                item
                xs={1}
                style={{
                  ...columnStyle,
                  textAlign: "right",
                }}
              >
                {item.iva}
              </GridUI>
              <GridUI
                item
                xs={1}
                style={{
                  ...columnStyle,
                  textAlign: "right",
                }}
              >
                {item.total}
              </GridUI>
            </GridUI>
          </ListItem>
        );
      })}
    </Box>,
    <Divider key={"divider2"} />,
  ];
};

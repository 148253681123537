import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import TimeSpace from "./TimeSpace";

const Index = (props) => {
  const { from, to, rowTime, hideTitleDay } = props;
  const tm_from = moment(from, "HH:mm:ss");
  const tm_to = moment(to, "HH:mm:ss");
  const diff = tm_to.diff(tm_from, "minutes");
  const date = tm_from.clone().subtract(rowTime, "minutes");

  const lapsos = diff / rowTime;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingTop: !hideTitleDay ? 20 : 0,
      }}
    >
      {Array(Number(lapsos.toFixed()))
        .fill(0)
        .map((v, i) => {
          const dateRow = date.add(rowTime, "minutes");
          const inicio = dateRow.format("HH:mm:ss");

          return <TimeSpace key={`hora-${i}`} {...props} inicio={inicio} />;
        })}
    </div>
  );
};

Index.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  rowTime: PropTypes.number,
  hideTitleDay: PropTypes.bool,
};

export default Index;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import { Typography } from "@material-ui/core";
import QRCode from "qrcode.react";

import moment from "moment";
import EstudiosItem from "./estudiosItem";
import PrintForm from "app/components/PrintForm";
import HeaderPrint from "app/material-ui/Print/HeaderPrint";
import FooterPrint from "app/material-ui/Print/FooterPrint";

class SolicitudesPrint extends Component {
  constructor() {
    super();

    this.listado = this.listado.bind(this);
    this.getDatos = this.getDatos.bind(this);
  }

  render() {
    const firma = [...(this.props.firmas || [])].pop();

    return (
      <PrintForm
        header={
          <HeaderPrint
            key="header"
            module="Solicitudes"
            paciente={this.props.paciente}
            data={firma}
          />
        }
        rol={firma}
        content={
          <div style={{ align: "justified", float: "center", width: "100%" }}>
            {this.renderSolicitudes()}

            {this.props.vc_observaciones ? (
              <div style={{ width: "100%" }}>
                <Typography
                  style={{
                    margin: "10px 0",
                    fontWeight: 500,
                    color: window.colors.itemSelected,
                  }}
                >
                  Indicaciones:
                </Typography>
                <div
                  style={{
                    width: "100%",
                    whiteSpace: "pre-wrap",
                    color: "black",
                  }}
                >
                  {this.props.vc_observaciones}
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        }
        footer={<FooterPrint key="footer" firma={firma} />}
        marginFooter={30}
      />
    );
  }

  renderSolicitudes() {
    const listado = this.listado();

    return listado.map((tp, i) => {
      return (
        <div key={i}>
          <Typography
            key={i}
            style={{
              margin: "10px 0",
              fontWeight: 500,
              color: window.colors.itemSelected,
            }}
          >
            {tp.vc_nombre}
          </Typography>

          {tp.niveles.map((n, indexNiveles) => {
            return (
              <div
                key={indexNiveles}
                style={{ overflow: "auto", marginLeft: "15px" }}
              >
                <Typography
                  key={i}
                  style={{
                    margin: "10px 0",
                    fontWeight: 500,
                    color: "#000",
                    opacity: 0.8,
                  }}
                >
                  {n.vc_nombre}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    overflow: "hidden",
                  }}
                >
                  {n.estudios.map((item, indexEstudios) => (
                    <EstudiosItem
                      key={indexEstudios}
                      item={item}
                      sn_printVersion={true}
                      index={indexEstudios}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  }

  listado() {
    const { filtro, niveles } = this.props;
    const estudios = this.props.solicitudActual.estudios || [];
    let tiposEstudios = [
      {
        id_tipoEstudio: 1,
        vc_nombre: "Laboratorio",
        niveles: [],
        estudios: estudios.filter((v) => v.id_tipoEstudio == 1),
      },
      {
        id_tipoEstudio: 2,
        vc_nombre: "Imagenología",
        niveles: [],
        estudios: estudios.filter((v) => v.id_tipoEstudio == 2),
      },
      {
        id_tipoEstudio: 3,
        vc_nombre: "Patología",
        niveles: [],
        estudios: estudios.filter((v) => v.id_tipoEstudio == 3),
      },
    ];

    tiposEstudios = tiposEstudios
      .filter((v) => v.estudios.length)
      .map((v) => v);

    tiposEstudios.forEach((t) => {
      t.estudios.forEach((e) => {
        const nivel = t.niveles.find((n) => n.id == e.id_nivel);
        if (nivel) {
          nivel.estudios.push(e);
        } else {
          const { id, vc_nombre } = niveles.find((n) => n.id == e.id_nivel);
          t.niveles.push({
            id,
            vc_nombre,
            estudios: [e],
          });
        }
      });
    });

    switch (filtro) {
      case "laboratorio":
        return tiposEstudios.filter((e) => e.id_tipoEstudio == 1);
      case "imagenologia":
        return tiposEstudios.filter((e) => e.id_tipoEstudio == 2);
      case "patologia":
        return tiposEstudios.filter((e) => e.id_tipoEstudio == 3);
      default:
        return tiposEstudios;
    }
  }

  getDatos() {
    const { date } = this.props;
    const h = window.screen.height;
    let paciente = "no especificado, la nota no podrá ser guardada.";
    let sexo = "sexo: no especificado";
    let edad = "edad: no especificada";
    const pacienteObj = this.props.pacienteActual || {};
    let sn_paciente = true;
    if (pacienteObj.detalle) {
      edad = `edad: ${this.timeSince(
        new Date(),
        new Date(pacienteObj.detalle.dt_nacimiento)
      )}`;
      sexo =
        pacienteObj.detalle.id_sexo == 1
          ? "sexo: masculino"
          : pacienteObj.detalle.id_sexo == 2
          ? "sexo: femenino"
          : "sexo: no especificado";
      if (pacienteObj.detalle.vc_apellidoMaterno != undefined) {
        paciente =
          `${pacienteObj.detalle.vc_nombre}` +
          " " +
          `${pacienteObj.detalle.vc_apellidoPaterno}` +
          " " +
          `${pacienteObj.detalle.vc_apellidoMaterno}`;
      } else {
        paciente =
          `${pacienteObj.detalle.vc_nombre}` +
          " " +
          `${pacienteObj.detalle.vc_apellidoPaterno}`;
      }
    } else {
      sn_paciente = false;
    }

    const usuario = window.amplify.store("user").usuario;
    const institucion = window.amplify.store("institution");
    const nombreUsuario = `${usuario.vc_nombre} ${usuario.vc_apellidoPaterno} ${usuario.vc_apellidoMaterno}`;

    const nombreInstitucion = `${institucion.vc_nombre}`;
    const prefijo = usuario.id_sexo === 1 ? "Dr. " : "Dra.";
    const rol = (institucion.roles || []).filter(
      (e) => e.id_especialidad && e.vc_cedulaProfesional
    )[0];
    console.log("rol", window.amplify.store("roles"));
    const cedulaTitulo = this.cedulaTitulo(rol);
    const especialidad = cedulaTitulo.especialidad;

    return {
      nombreInstitucion,
      especialidad,
      paciente,
      cedulaTitulo,
      rol,
      sexo,
      edad,
      sn_paciente,
    };
  }

  timeSince(refDate, date) {
    var seconds = Math.floor((refDate - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " años";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " meses";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " días";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " horas";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutos";
    }
    return "un instante";
  }

  cedulaTitulo(rol) {
    if (rol) {
      const especialidad = this.props.especialidades.find(
        (e) => e.id === rol.id_especialidad
      );
      return {
        especialidad: especialidad ? especialidad.vc_alias : "",
        vc_cedulaProfesional: rol.vc_cedulaProfesional,
        vc_registroSSA: rol.vc_registroSSA,
      };
    }
    return {
      especialidad: "",
      vc_cedulaProfesional: "",
      vc_registroSSA: "",
    };
  }
}

const stylesReceta = {
  container: {
    padding: 2,
    float: "left",
  },
  layerBackgroundTx: {
    float: "center",
    position: "absolute",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    margin: 0,
    padding: 0,
    opacity: 0.05,
    backgroundImage: "url(assets/images/iconThin-k.png)",
    backgroundPosition: "center",
    backgroundSize: "800px 800px",
    backgroundRepeat: "no-repeat",
  },
  title: {
    backgroundColor: "white",
    color: "black",
    padding: 5,
    borderRadius: 3,
  },
};

const mapStateToProps = (state) => {
  const { diagnosticos, especialidades, pacienteActual, niveles } = state.Think;
  return { diagnosticos, pacienteActual, especialidades, niveles };
};

export default connect(mapStateToProps)(SolicitudesPrint);

import React from "react";

import {
  Box,
  Popover,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Search, PersonAddOutlined } from "@material-ui/icons";
import Input from "app/components/Form/Input";
import RegistrarPaciente from "app/components/Paciente/RegistroPaciente";
import internalAPI from "app/utils/internalAPI";
import ItemPatient from "./ItemPatient";
import PatientsView from "app/containers/Think/views/patients/PatientsView";
import { omit } from "lodash";

const searchUser = (texto) => {
  const body = {
    id_institucion: window.amplify.store("institution").id_institucion,
    texto,
  };

  return new Promise((resolve, reject) => {
    internalAPI.searchUsers(body, (error, response) => {
      if (error) {
        reject(error);
      } else {
        const data = response
          .filter((v) =>
            [5, 6, 7, 8, 9, 12].some((j) =>
              v.roles.map((r) => r.id).includes(j)
            )
          )
          .map((v) => v.detalle);
        resolve(data);
      }
    });
  });
};

export default ({ onClick, addPatient = true, ...props }) => {
  const [openNewPatient, setOpenNewPatient] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const fetchData = async () => {
    const res = await searchUser(inputValue);
    setLoading(false);
    setData(res);
  };

  React.useEffect(() => {
    setLoading(!!inputValue);
    const timeout = setTimeout(async () => {
      if (inputValue) {
        await fetchData();
      } else {
        setData([]);
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  React.useEffect(() => {
    onClick(value);
  }, [value]);

  return [
    <Autocomplete
      filterOptions={(x) => x}
      options={inputValue && !data.length ? [{ loading: true }] : data}
      autoComplete
      fullWidth
      includeInputInList
      filterSelectedOptions
      openOnFocus={false}
      freeSolo
      value={value}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : `${option.vc_nombre || ""} ${option.vc_apellidoPaterno || ""} ${
              option.vc_apellidoMaterno || ""
            }`
      }
      onChange={(event, newValue) => {
        if (typeof newValue === "string") return;
        if ("loading" in newValue) return;

        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      style={{ margin: 0 }}
      renderInput={(params) => (
        <Input
          inputRef={params.InputProps.ref}
          inputProps={omit(params.inputProps, ["className"])}
          getEvent
          placeholder={props.placeholder || "Buscar usuario"}
          endAdornment={
            <Box display="flex" alignItems="center">
              {loading && (
                <CircularProgress
                  size={20}
                  color="white"
                  style={{ marginRight: 8 }}
                />
              )}
            </Box>
          }
        />
      )}
      renderOption={(data, option) => <ItemPatient data={data} dense />}
    />,
  ];
};

import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  List,
  Typography,
} from "@material-ui/core";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const Index = ({ open, close, accept, title, message, children, ...props }) => {
  return (
    open && (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={close}
        keepMounted
        {...props}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {message && <DialogContentText>{message}</DialogContentText>}
          {children}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={close}
            style={{ alignSelf: "right" }}
          >
            Cancelar
          </Button>

          <Button
            onClick={accept}
            variant="contained"
            color="primary"
            style={{ marginLeft: 8 }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

Index.defaultProps = {
  open: false,
  title: "",
  message: "",
  close: () => {},
  accept: () => {},
};

Index.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  close: PropTypes.func,
  accept: PropTypes.func,
};

export default Index;

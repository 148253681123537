import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Container, Input } from 'semantic-ui-react';
import PreguntasNumericas from './preguntas/preguntasNumericas';
import { setPacienteActual } from '../../actions';
import notifications from '../../../../utils/notifications';
import HelpBarThink from '../../../../material-ui/HelpBarThink';

class ResponderHelpBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: "",
            indexHover: null,
            indexSelected: this.props.indexSelected,
            opcionPreSelected: null,
            dia: null,
            fechaString: null,
            cve_respuestaBase: ''
        };
    }
    componentDidMount() {
        this.modifyEnterKeyPressAsTab.bind(this)
        document.onkeypress = this.modifyEnterKeyPressAsTab.bind(this);
        document.onkeydown = this.pressArrows.bind(this);
    }

    pressArrows(e) {
        if (this.state.focus) {
            let opcionPreSelected = this.state.opcionPreSelected;
            if (e.keyCode == 38) {
                if (opcionPreSelected == null || opcionPreSelected == 0) {
                    opcionPreSelected = 0;
                } else {
                    opcionPreSelected = (opcionPreSelected - 1);
                }
            } else if (e.keyCode == 40) {
                if (opcionPreSelected == null) {
                    opcionPreSelected = 0;
                } else if (this.opcionesArray().length > (opcionPreSelected + 1)) {
                    opcionPreSelected = (opcionPreSelected + 1);
                }
            }
            this.setState({ opcionPreSelected });
        }
    }
    getPreguntaHija() {
        const preguntaPadre = this.props.preguntaPadre || {};
        const state = preguntaPadre.state || {};
        let pregunta = null;
        const activeCharacter = state.activeCharacter || {};
        if (activeCharacter.input > -1) {
            const input = preguntaPadre.respuestaStruct[activeCharacter.input] || {};
            const newPregunta = this.props.preguntas.find(e => e.id === input.id_pregunta);
            if (newPregunta && (state.indexEdit === activeCharacter.input || state.indexHover === activeCharacter.input)) {
                pregunta = {
                    ...newPregunta,
                    id_respuestaSelected: input.id_respuesta || null
                };
            } else {
                pregunta = state.preguntaHija || null;
            }
        } else {
            pregunta = this.state.preguntaHija || null;
        }

        return pregunta;
    }
    modifyEnterKeyPressAsTab(e) {
        if (this.state.focus) {

            let preguntaHija = this.getPreguntaHija();
            if (e && e.keyCode === 13) {
                let pregunta = null;
                switch (this.props.notasTipoEditor) {
                    case 'lateral':
                        pregunta = this.props.preguntaActual;
                        switch (pregunta.id_tipoPregunta) {
                            case 1:
                                if (this.state.opcionPreSelected != null) {
                                    this.setRespuesta(this.opcionesArray()[this.state.opcionPreSelected], this.state.opcionPreSelected);
                                } else {
                                    this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                                }
                                break;
                            case 3:
                                if (this.state.searchText / 1 > 0) {
                                    const respuesta = {
                                        nu_respuesta: (this.state.searchText / 1).toFixed(1),
                                        id_pregunta: pregunta
                                    };
                                    pregunta.respuesta = respuesta;
                                    this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                                } else {
                                    this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                                }
                                break;
                            case 4:
                                let respuesta = null;
                                if (pregunta.respuesta) {
                                    respuesta = {
                                        vc_respuesta: `${pregunta.respuesta.vc_respuesta} ${this.state.searchText}`,
                                        id_pregunta: pregunta
                                    };
                                } else {
                                    respuesta = {
                                        vc_respuesta: this.state.searchText,
                                        id_pregunta: pregunta
                                    };
                                }
                                pregunta.respuesta = respuesta;
                                this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                                break;
                            default:
                                this.setState({ searchText: '' }, () => this.props.callbackPregunta({ pregunta, opcionesModuloNext: 'siguientePregunta' }));
                                break;
                        }
                        break;
                    case 'principal':
                        pregunta = preguntaHija || this.props.preguntaPadre || this.props.preguntaActual;
                        switch (pregunta.id_tipoPregunta) {
                            case 1:
                                if (this.state.opcionPreSelected != null) {
                                    this.setRespuestaHija(this.opcionesArray()[this.state.opcionPreSelected]);
                                }
                                break;
                            case 2:
                                if (this.state.opcionPreSelected != null) {
                                    this.setRespuestaHija(this.opcionesArray()[this.state.opcionPreSelected]);
                                }
                                break;
                            case 3:
                                console.log('entro a numerico')
                                if ((this.state.searchText / 1)) {
                                    const respuesta = {
                                        nu_respuesta: (this.state.searchText / 1).toFixed(1),
                                        id_pregunta: pregunta
                                    };
                                    pregunta.respuesta = respuesta;
                                    if (this.props.preguntaPadre && preguntaHija) {
                                        this.setRespuestaHija(respuesta)
                                    }
                                }
                                break;
                            case 4:
                                let respuesta = null;
                                if (pregunta.respuesta) {
                                    respuesta = {
                                        vc_respuesta: `${pregunta.respuesta.vc_respuesta} ${this.state.searchText}`,
                                        id_pregunta: pregunta
                                    };
                                } else {
                                    respuesta = {
                                        vc_respuesta: this.state.searchText,
                                        id_pregunta: pregunta
                                    };
                                }
                                pregunta.respuesta = respuesta;
                                this.setRespuestaHija(respuesta);
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                };
            } else if (e.ctrlKey) {
                const key = e.key * 1;
                const opciones = this.opcionesArray();
                if (opciones[key]) {
                    this.setState({ opcionPreSelected: key }, () => {
                        if (this.props.preguntaPadre && preguntaHija) {
                            this.setRespuestaHija(opciones[key])
                        }
                    });
                } else {
                    console.log('no existe ese indice')
                }
            }
        }
    }

    timeSince(refDate, date) {

        var seconds = Math.floor((refDate - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " años";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " minutos";
        }
        return "un instante";
    }
    //
    hoverIndex(index) {
        this.props.callbackHover({ indexHover: index })
    }
    onChangeValue(event) {
        this.setState({ searchText: event.target.value });
    }
    searchBarEvent(event) {
        switch (this.props.preguntaActual.id_tipoPregunta) {
            case 1:
                let opcionPreSelected = null;
                const primeraOpcion = this.opcionesArray().filter(e => e.vc_respuesta.toLowerCase().includes(event.target.value.toLowerCase()) )[0]
                if (primeraOpcion) {
                    if (primeraOpcion.vc_respuesta.toLowerCase() == event.target.value.toLowerCase()) {
                        opcionPreSelected = 0;
                    }
                } else {
                    opcionPreSelected = null
                }
                this.setState({ searchText: event.target.value, opcionPreSelected });
                break;
            case 3:
                if (event.target.value / 1 || event.target.value === '') {
                    this.setState({ searchText: event.target.value, opcionPreSelected: null });
                }
                break;
            case 4:
                this.setState({ searchText: event.target.value, opcionPreSelected: null });
                break;
            default: break;
        }
    }
    isValidSex(id_sexoPaciente, id_sexoPregunta) {
        if (id_sexoPaciente === id_sexoPregunta || id_sexoPregunta === 3) {
            return true;
        } else {
            return false;
        }
    }
    isValidAge(nuAgeMinutesPaciente, item) {
        const { unidadesMedida } = this.props;
        let limiteInferiorAge = null;
        let limiteSuperiorAge = null;
        if (item.nu_minEdad > 0 && item.id_um_minEdad != null) {
            const nuUmBase = this.finder(unidadesMedida, 'id', item.id_um_minEdad).nu_ratio;
            limiteInferiorAge = nuUmBase * item.nu_minEdad;

        }
        if (item.nu_maxEdad > 0 && item.id_um_maxEdad != null) {
            const nuUmBase = this.finder(unidadesMedida, 'id', item.id_um_maxEdad).nu_ratio;
            limiteSuperiorAge = nuUmBase * item.nu_maxEdad;
        }
        if (limiteInferiorAge != null && nuAgeMinutesPaciente < limiteInferiorAge) {
            return false;
        }
        if (limiteSuperiorAge != null && nuAgeMinutesPaciente > limiteSuperiorAge) {
            return false;
        }
        return true;
    }
    finder(array, key, value) {
        const object = array.find((e) => { return e[`${key}`] === value });
        if (object) {
            return object;
        }
        return null;
    }
    preguntaVersion(preguntaActual, pacienteDetalle) {
        const { date, PreguntasNumericas, respuestas } = this.props;
        const id_sexo = pacienteDetalle.id_sexo;
        const nu_edadMinutos = (new Date() - new Date(pacienteDetalle.dt_nacimiento)) / 60000;
        let version = null;
        preguntaActual.versiones.map((preguntaVersion, index) => {
            if (this.isValidAge(nu_edadMinutos, preguntaVersion) && this.isValidSex(id_sexo, preguntaVersion.id_sexo)) {
                version = preguntaVersion;
            }
        });
        return version;
    }
    opcionesNumericas(preguntaVersion) {
        const { preguntaActual, unidadesMedida } = this.props;
        const rango = preguntaActual.nu_superior - preguntaActual.nu_limiteInferior;
        const um = this.finder(unidadesMedida, 'id', preguntaVersion.id_um_numerico);
        const nu_opciones = rango / um.nu_escala;
        let opciones = [];
        let i = 0;
        for (i = 0; i < nu_opciones; i++) {
            const nu = preguntaVersion.nu_limiteInferior + (um.nu_escala * i)
            const opcion = {
                vc_respuesta: `${nu}`,
                valor: nu,
                style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
            }
            opciones.push(opcion);
        }
        return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) );
    }
    opcionesFechaHora() {
        let i = 0;
        let opciones = [];
        let pre = '';
        switch (this.state.opcionPregunta) {
            case 'minutos':
                opciones = [];
                i = 0;
                for (i = 0; i < 60; i++) {
                    pre = i < 10 ? '0' : '';
                    const opcion = {
                        vc_respuesta: `${pre}${i}`,
                        valor: `${pre}${i}`,
                        style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
                    }
                    opciones.push(opcion);
                }
                return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case 'horas':
                opciones = [];
                i = 0;
                for (i = 0; i < 24; i++) {
                    pre = i < 10 ? '0' : '';
                    const opcion = {
                        vc_respuesta: `${pre}${i}`,
                        valor: `${pre}${i}`,
                        style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
                    }
                    opciones.push(opcion);
                }
                return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case 'dia':
                opciones = [];
                i = 0;
                for (i = 0; i < 31; i++) {
                    pre = i < 9 ? '0' : '';
                    const opcion = {
                        vc_respuesta: `${pre}${i + 1}`,
                        valor: `${pre}${i + 1}`,
                        style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
                    }
                    opciones.push(opcion);
                }
                return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case 'mes':
                opciones = [];
                i = 0;
                for (i = 0; i < 12; i++) {
                    const nu = i < 9 ? `0${i + 1}` : `${i + 1}`;
                    const opcion = {
                        vc_respuesta: `${pre}${i + 1}`,
                        valor: `${pre}${i + 1}`,
                        style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
                    }
                    if ((nu == '02' && this.state.dia <= 29) || (nu == '04' && this.state.dia <= 30) || (nu == '06' && this.state.dia <= 30) || (nu == '09' && this.state.dia <= 30) || (nu == '11' && this.state.dia <= 30)) {
                        opciones.push(opcion);
                    }
                }
                return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case 'año':
                opciones = [];
                i = 0;
                for (i = 0; i < 150; i++) {
                    const año = `${moment().format('YYYY')}`;
                    const nu = (año - i);
                    const opcion = {
                        vc_respuesta: `${nu}`,
                        valor: `${nu}`,
                        style: this.props.indexSeleccionado == i ? styles.containerSelectedHelp : styles.containerHelp
                    }
                    opciones.push(opcion);
                }
                return opciones.filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            default: return opciones;
        }
    }
    opcionesArray() {
        const { preguntaActual, pacienteActual, preguntaPadre, respuestas, preguntasRespuestas, preguntasVersiones } = this.props;
        let preguntaHija = this.getPreguntaHija();
        let preguntaVersion = null;
        if (preguntaActual) {
            preguntaVersion = this.preguntaVersion(preguntaActual, pacienteActual.detalle);
        }
        if (preguntaVersion != null && !preguntaPadre) {

            const preguntasVersionRespuestas = preguntasRespuestas.filter((e, index) => e.id_preguntaVersion === preguntaVersion.id);

            const respuestasVersion = [];
            preguntasVersionRespuestas.map((item) => {
                const respuesta = respuestas.filter((e, index) => e.id === item.id_respuesta)[0];
                if (respuesta) {
                    respuestasVersion.push(respuesta);
                }
            });

            switch (preguntaActual.id_tipoPregunta) {
                case 1:
                    return respuestasVersion.filter((e, index) => e.id_preguntaVersion === preguntaVersion.id).filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) );
                    break;
                case 2:
                    return respuestasVersion.filter((e, index) => e.id_preguntaVersion === preguntaVersion.id).filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) );
                    break;
                case 3:
                    return [];//this.opcionesNumericas(preguntaVersion);
                    break;
                case 5:
                    return [];//this.opcionesFechaHora();
                    break;
                case 6:
                    return [];//this.opcionesFechaHora();
                    break;
                default:
                    return []
                    break;
            };
        } else if (preguntaPadre && !preguntaHija) {
            const inputs = this.props.preguntaPadre.respuestaStruct || [];
            const activeCharacter = preguntaPadre.state ? preguntaPadre.state.activeCharacter || {} : {};
            const indexInput = activeCharacter.input || this.props.preguntaPadre.respuestaStruct.length - 1;
            const input = inputs[indexInput];
            let searchText = this.state.searchText;
            if (input && !input.id_pregunta) {
                searchText = input.sentence.split(',')[input.sentence.split(',').length - 1] || '';
                searchText = `${searchText.startsWith(' ') ? searchText.slice(1, searchText.length) : searchText}`;
            }
            if (searchText === '') return []
            const preguntasFilter = []
            this.props.preguntas.forEach(p => {
                const version = this.preguntaVersion(p, this.props.pacienteActual.detalle);
                if (!version) return false;
                if (version.vc_pregunta.toLowerCase().includes(searchText.toLowerCase()) > -1) {
                    preguntasFilter.push(p);
                }
            })
            return preguntasFilter;
        } else if (preguntaHija != null) {

            const version = this.preguntaVersion(preguntaHija, pacienteActual.detalle) || {};
            const preguntasVersionRespuestas = preguntasRespuestas.filter((e, index) => e.id_preguntaVersion === version.id);
            const respuestasVersion = [];
            preguntasVersionRespuestas.map((item) => {
                const respuesta = respuestas.filter((e, index) => e.id === item.id_respuesta)[0];
                if (respuesta) {
                    respuestasVersion.push(respuesta);
                }
            });
            return respuestasVersion
            switch (preguntaActual.id_tipoPregunta) {
                case 1:
                    return this.state.searchText !== '' ? respuestasVersion.filter((e, index) => e.id_version === version.id).filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) ) : respuestasVersion;
                    break;
                case 2:
                    return this.state.searchText !== '' ? respuestasVersion.filter((e, index) => e.id_version === version.id).filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) ) : respuestasVersion;
                    break;
                case 3:
                    return this.state.searchText !== '' ? respuestasVersion.filter((e, index) => e.id_version === version.id).filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) ) : respuestasVersion;
                    break;
                case 5:
                    return [];//this.opcionesFechaHora();
                    break;
                case 6:
                    return [];//this.opcionesFechaHora();
                    break;
                default:
                    return []
                    break;
            };
        } else {
            if (this.state.searchText.length > 0) {
                return preguntasVersiones.filter((e, index) => e.vc_pregunta.toLowerCase().includes(this.state.searchText.toLowerCase()) > -1).filter((e, i) => i < 50);
            }
            return preguntasVersiones.filter((e, i) => i < 50);
        }
    }
    renderTitleHelpBar() {
        const { date, preguntaActual, preguntaPadre, opcionMedicamentos, pacienteActual } = this.props;
        let preguntaHija = this.getPreguntaHija();
        if (preguntaActual != null && (!preguntaPadre || this.props.notasTipoEditor === 'lateral')) {
            const version = this.preguntaVersion(preguntaActual, pacienteActual.detalle);
            if (version != null) {
                return (
                    <div style={{ padding: 0, border: 0 }}>
                        <button onClick={() => this.props.returnLateral()} style={{ width: '100%', padding: 10, border: 0, height: 74, textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                            {version.vc_pregunta}
                        </button>
                        <div style={styles.titleHelp}>
                            <Input
                                autoComplete='off'
                                id='searchBar'
                                focus placeholder='Capture...'
                                value={this.state.searchText}
                                onChange={this.searchBarEvent.bind(this)}
                                style={{ width: '100%', height: 31 }}
                            />
                        </div>
                    </div>
                );
            } else {
                return (
                    <div style={{ padding: 0, border: 0 }}>
                        <button onClick={() => this.props.returnLateral()} style={{ width: '100%', padding: 10, border: 0, height: 74, textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                            No hay ninguna version valida para el paciente actual
                        </button>
                        <div style={styles.titleHelp}>
                            <Input
                                autoComplete='off'
                                id='searchBar'
                                focus placeholder='Buscar...'
                                value={this.state.searchText}
                                onChange={this.searchBarEvent.bind(this)}
                                style={{ width: '100%', height: 31 }}
                            />
                        </div>
                    </div>
                );
            }
        } else if (this.props.notasTipoEditor === 'lateral') {
            return 'Seleccione una pregunta'
            return (
                <div style={{ padding: 0, border: 0 }}>
                    <button onClick={() => this.props.returnLateral()} style={{ width: '100%', padding: 10, border: 0, height: 74, textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                        Seleccione una pregunta
                    </button>
                    <div style={styles.titleHelp}>
                        <Input
                            autoComplete='off'
                            id='searchBar'
                            focus placeholder='Buscar...'
                            value={this.state.searchText}
                            onChange={this.searchBarEvent.bind(this)}
                            style={{ width: '100%', height: 31 }}
                        />
                    </div>
                </div>
            );
        } else if (this.props.preguntaPadre && !preguntaHija) {
            const inputs = this.props.preguntaPadre.respuestaStruct || [];
            const searchText = inputs.length > 0 ? this.props.preguntaPadre.respuestaStruct[this.props.preguntaPadre.respuestaStruct.length - 1].sentence : this.state.searchText;
            return 'Seleccione una pregunta'
            return (
                <div style={{ padding: 0, border: 0 }}>
                    <button onClick={() => this.props.returnLateral()} style={{ width: '100%', padding: 10, border: 0, height: 74, textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                        Seleccione una pregunta
                    </button>
                    <div style={styles.titleHelp}>
                        <Input
                            autoComplete='off'
                            id='searchBar'
                            focus placeholder='Buscar...'
                            value={searchText}
                            onChange={this.searchBarEvent.bind(this)}
                            style={{ width: '100%', height: 31 }}
                        />
                    </div>
                </div>
            );
        } else if (preguntaHija) {
            const version = this.preguntaVersion(preguntaHija, pacienteActual.detalle);
            if (version != null) {
                return version.vc_pregunta;
                return (
                    <div style={{ padding: 0, border: 0 }}>
                        <button onClick={() => this.props.returnLateral()} style={{ width: '100%', padding: 10, border: 0, height: 74, textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                            {version.vc_pregunta}
                        </button>
                        <div style={styles.titleHelp}>
                            <Input
                                autoComplete='off'
                                id='searchBar'
                                focus placeholder='Capture...'
                                value={this.state.searchText}
                                onChange={this.searchBarEvent.bind(this)}
                                style={{ width: '100%', height: 31 }}
                            />
                        </div>
                    </div>
                );
            } else {
                return 'No hay ninguna version valida para el paciente actual'
                return (
                    <div style={{ padding: 0, border: 0 }}>
                        <button onClick={() => this.props.returnLateral()} style={{ width: '100%', padding: 10, border: 0, height: 74, textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                            No hay ninguna version valida para el paciente actual
                        </button>
                        <div style={styles.titleHelp}>
                            <Input
                                autoComplete='off'
                                id='searchBar'
                                focus placeholder='Buscar...'
                                value={this.state.searchText}
                                onChange={this.searchBarEvent.bind(this)}
                                style={{ width: '100%', height: 31 }}
                            />
                        </div>
                    </div>
                );
            }
        } else {
            return 'Seleccione una pregunta'
            return (
                <div style={{ padding: 0, border: 0 }}>
                    <button onClick={() => this.props.returnLateral()} style={{ width: '100%', padding: 10, border: 0, height: 74, textAlign: 'center', color: 'white', backgroundColor: window.colors.menu }}>
                        Seleccione una pregunta
                    </button>
                    <div style={styles.titleHelp}>
                        <Input
                            autoComplete='off'
                            id='searchBar'
                            focus placeholder='Buscar...'
                            value={this.state.searchText}
                            onChange={this.searchBarEvent.bind(this)}
                            style={{ width: '100%', height: 31 }}
                        />
                    </div>
                </div>
            );
        }
    }
    removeLastSearchInput(sentence) {
        const removeSentence = sentence.split(',');
        removeSentence.pop()
        let text = '';
        removeSentence.map((item, index) => {
            text = text + item;
        })
        return text;
    }
    respuestaLogica(respuesta, id_tipoPregunta) {
        switch (id_tipoPregunta) {
            case 1:
                return respuesta.vc_respuestaLogica;
            case 2:
                return respuesta.vc_respuestaLogica;
            case 3:
                return respuesta.nu_respuesta;
            case 4:
                return respuesta.vc_respuesta;
            case 5:
                return respuesta.dt_respuesta;
            case 6:
                return respuesta.tm_respuesta;
            default:
                return '';
        }

    }
    setTextRespuesta({ preguntaPadre, preguntaHija, version, respuesta }) {
        const vc_textoInicial = version.vc_textoInicial || '';
        const vc_concatenar = version.vc_concatenar || '';
        const vc_concatenarFinal = version.vc_concatenarFinal || '';
        const vc_textoFinal = version.vc_textoFinal || '';
        const id_tipoPregunta = preguntaHija.id_tipoPregunta;
        const respuestalogicaNumerica = id_tipoPregunta === 3 ? this.getRespuestaLogicaNumerica(preguntaHija, version.respuestas, respuesta.nu_respuesta) : null;
        let newPregunta = preguntaPadre;
        console.log('version', version);
        if (newPregunta.respuestaStruct.length === 0) {
            newPregunta.respuestaStruct.push({
                id_pregunta: preguntaHija.id,
                sentence: ''
            })
        }
        let activeCharacter = preguntaPadre.activeCharacter || { input: newPregunta.respuestaStruct.length - 1 };
        switch (true) {
            // valido si el input es de tipo texto o si viene de una respuesta de pregunta.
            case (newPregunta.respuestaStruct[activeCharacter.input] ? !newPregunta.respuestaStruct[activeCharacter.input].id_pregunta : false):
                // es input no estructurado 'texto'
                // valido si la sentencia del input contiene al menos un separador logico.
                if (newPregunta.respuestaStruct[activeCharacter.input].sentence.split(',').length > 1) {
                    // se elimina el anexo posterior a la coma utilizado para la busqueda de la pregunta hija y se asigna el valor al input seleccinado.
                    newPregunta.respuestaStruct[activeCharacter.input] = {
                        ...newPregunta.respuestaStruct[activeCharacter.input],
                        sentence: this.removeLastSearchInput(newPregunta.respuestaStruct[activeCharacter.input].sentence) + ' '
                    }
                    // se agrega la respuesta seleccionada y se crea un nuevo input con ella.
                    newPregunta.respuestaStruct.splice(activeCharacter.input + 1, 0, {
                        id_pregunta: preguntaHija.id,
                        id_respuesta: respuesta.id ? respuesta.id : respuestalogicaNumerica ? respuestalogicaNumerica.id : null,
                        cve_respuesta: `${this.state.cve_respuestaBase}-${preguntaHija.id}:${respuesta.id ? respuesta.id : respuestalogicaNumerica ? respuestalogicaNumerica.id : `#${respuesta.nu_respuesta}`}`,
                        sentence: ' ' + vc_textoInicial + (respuestalogicaNumerica ? respuestalogicaNumerica.vc_respuestaLogica : '') + this.respuestaLogica(respuesta, id_tipoPregunta) + vc_textoFinal
                    })
                    // se indica como input seleccionado al indice ingresado
                    activeCharacter = { input: activeCharacter.input + 2 };
                } else {
                    // se sustituye el contenido del input por la respuesta nueva.
                    newPregunta.respuestaStruct.splice(newPregunta.activeCharacter.input, 1, {
                        id_pregunta: preguntaHija.id,
                        id_respuesta: respuesta.id ? respuesta.id : respuestalogicaNumerica ? respuestalogicaNumerica.id : null,
                        cve_respuesta: `${this.state.cve_respuestaBase}-${preguntaHija.id}:${respuesta.id ? respuesta.id : respuestalogicaNumerica ? respuestalogicaNumerica.id : `#${respuesta.nu_respuesta}`}`,
                        sentence: ' ' + vc_textoInicial + (respuestalogicaNumerica ? respuestalogicaNumerica.vc_respuestaLogica : '') + this.respuestaLogica(respuesta, id_tipoPregunta) + vc_textoFinal
                    })
                }
                break;
            // se valida si el input seleccionado es una respuesta de la pregunta hija actual.
            case (newPregunta.respuestaStruct[activeCharacter.input].id_pregunta === preguntaHija.id):
                // se edita el input seleccionado.
                newPregunta.respuestaStruct[activeCharacter.input].id_pregunta = preguntaHija.id;
                newPregunta.respuestaStruct[activeCharacter.input].id_respuesta = respuesta.id ? respuesta.id : respuestalogicaNumerica ? respuestalogicaNumerica.id : null;
                newPregunta.respuestaStruct[activeCharacter.input].cve_respuesta = `${this.state.cve_respuestaBase}-${preguntaHija.id}:${respuesta.id ? respuesta.id : respuestalogicaNumerica ? respuestalogicaNumerica.id : `#${respuesta.nu_respuesta}`}`;
                newPregunta.respuestaStruct[activeCharacter.input].sentence = ' ' + vc_textoInicial + (respuestalogicaNumerica ? respuestalogicaNumerica.vc_respuestaLogica : '') + this.respuestaLogica(respuesta, id_tipoPregunta) + vc_textoFinal;
                break;
            default:
                // se agrega como input nuevo con su respuesta.
                newPregunta.respuestaStruct.push({
                    id_pregunta: preguntaHija.id,
                    id_respuesta: respuesta.id ? respuesta.id : respuestalogicaNumerica ? respuestalogicaNumerica.id : null,
                    cve_respuesta: `${this.state.cve_respuestaBase}-${preguntaHija.id}:${respuesta.id ? respuesta.id : respuestalogicaNumerica ? respuestalogicaNumerica.id : `#${respuesta.nu_respuesta}`}`,
                    sentence: ' ' + vc_textoInicial + (respuestalogicaNumerica ? respuestalogicaNumerica.vc_respuestaLogica : '') + this.respuestaLogica(respuesta, id_tipoPregunta) + vc_textoFinal
                })
                activeCharacter = { input: activeCharacter.input + 1 };
                break;
        }

        newPregunta.activeCharacter = activeCharacter;
        console.log('newPregunta', newPregunta);
        return newPregunta;
    }
    getRespuestaLogicaNumerica(preguntaHija, respuestas, nu_respuesta) {
        const { unidadesMedida } = this.props;
        const um_pregunta = unidadesMedida.find(e => e.id === preguntaHija.id_um_numerico);
        let respuestaReturn = null;
        let trunk = false;
        for (let i = 0; i < respuestas.length && !trunk; i++) {
            const respuesta = respuestas[i];
            const um_limiteInferior = unidadesMedida.find(e => e.id === respuesta.id_um_limiteInferior);
            const um_limiteSuperior = unidadesMedida.find(e => e.id === respuesta.id_um_limiteSuperior);
            const nu_limiteInferior = respuesta.nu_limiteInferior * (um_limiteInferior ? um_limiteInferior.nu_ratio : null);
            const nu_limiteSuperior = respuesta.nu_limiteSuperior * (um_limiteSuperior ? um_limiteSuperior.nu_ratio : null);
            const nu_respuestaReal = nu_respuesta * um_pregunta.nu_ratio;

            if (nu_limiteInferior && nu_limiteSuperior) {
                if (nu_limiteInferior <= nu_respuestaReal && nu_limiteSuperior >= nu_respuestaReal) {
                    respuestaReturn = respuesta;
                    trunk = true;
                }
            } else if (nu_limiteInferior && !nu_limiteSuperior) {
                if (nu_limiteInferior <= nu_respuestaReal) {
                    respuestaReturn = respuesta;
                    trunk = true;
                }
            } else if (!nu_limiteInferior && nu_limiteSuperior) {
                if (nu_limiteSuperior >= nu_respuestaReal) {
                    respuestaReturn = respuesta;
                    trunk = true;
                }
            }
        }
        console.log('respuestaReturn ', respuestaReturn)

        return respuestaReturn;
    }
    setRespuestaHija(respuesta, index) {
        const { preguntasPendientes } = this.state;
        const preguntaHija = this.getPreguntaHija();
        const { preguntaPadre, pacienteActual, preguntaActual, preguntas } = this.props;
        let newPreguntaPadre = null;
        let preguntaEnlace = null;
        let newPreguntasPendientes = preguntasPendientes || [];
        let version = this.preguntaVersion(preguntaHija || preguntaPadre || preguntaActual, pacienteActual.detalle) || {}
        switch (true) {
            case (preguntaHija !== null && preguntaPadre !== null):
                if (version.id_preguntaEnlace) {
                    newPreguntasPendientes.push({ pregunta: preguntas.find(e => e.id === version.id_preguntaEnlace), cve_respuestaBase: this.state.cve_respuestaBase })
                }
                if (respuesta.id_preguntaEnlace) {
                    newPreguntasPendientes.push({ pregunta: preguntas.find(e => e.id === respuesta.id_preguntaEnlace), cve_respuestaBase: this.state.cve_respuestaBase })
                }
                preguntaEnlace = newPreguntasPendientes.pop() || null;
                newPreguntaPadre = this.setTextRespuesta({ preguntaPadre, preguntaHija, version, respuesta });
                newPreguntaPadre.state.preguntaHija = preguntaEnlace ? preguntaEnlace.pregunta : null;
                newPreguntaPadre.state.indexEdit = null;
                this.setState({ preguntaHija: preguntaEnlace ? preguntaEnlace.pregunta : null, cve_respuestaBase: preguntaEnlace ? preguntaEnlace.cve_respuestaBase : '', preguntasPendientes: newPreguntasPendientes, searchText: '' }, () => {
                    console.log('pregunta editada', newPreguntaPadre)
                    const preguntasTexto = this.props.pacienteActual.preguntasTexto;
                    preguntasTexto[this.props.indexSeleccionado] = {
                        ...preguntasTexto[this.props.indexSeleccionado],
                        ...newPreguntaPadre,
                        state: { ...newPreguntaPadre.state, indexEdit: null, indexHover: null }
                    };
                    this.props.setPacienteActual({
                        ...this.props.pacienteActual,
                        preguntasTexto
                    })
                })
        }
    }
    setPreguntaHija({ preguntaHija }) {
        //duda de modificacion
        // const preguntasTexto = this.props.pacienteActual.preguntasTexto;
        // const newPreguntaPadre = preguntasTexto[this.props.indexSeleccionado];
        const preguntasTexto = this.props.pacienteActual.preguntasTexto;
        const newPreguntaPadre = preguntasTexto[this.props.indexSeleccionado];
        
        newPreguntaPadre.state.preguntaHija = preguntaHija;
        preguntasTexto[this.props.indexSeleccionado] = {
            ...preguntasTexto[this.props.indexSeleccionado],
            ...newPreguntaPadre,
            state: { ...newPreguntaPadre.state, indexEdit: null, indexHover: null }
        };
        this.props.setPacienteActual({
            ...this.props.pacienteActual,
            preguntasTexto
        })
    }
    validateValue({ value, index, preguntasNumericas }) {
        const item = preguntasNumericas[index];
        switch (item.id_tipoPregunta) {
            case 3:
                if ((value * 1 || value == '' || value == '0' || value == '0.') && preguntasNumericas[index].id_tipoPregunta == 3) {
                    preguntasNumericas[index].respuesta = { id_pregunta: item.id, nu_respuesta: value };
                    if (preguntasNumericas[index].id == 241 || preguntasNumericas[index].id == 242) {
                        if (preguntasNumericas.filter((e, index) => e.id == 241).length > 0 && preguntasNumericas.filter((e, index) => e.id == 242).length > 0) {
                            if (preguntasNumericas.filter((e, index) => e.id == 241)[0].respuesta && preguntasNumericas.filter((e, index) => e.id == 242)[0].respuesta) {
                                if (preguntasNumericas.filter((e, index) => e.id == 241)[0].respuesta.nu_respuesta && preguntasNumericas.filter((e, index) => e.id == 242)[0].respuesta.nu_respuesta) {
                                    const tallaMts = (preguntasNumericas.filter((e, index) => e.id == 241)[0].respuesta.nu_respuesta / 100);
                                    const imc = ((preguntasNumericas.filter((e, index) => e.id == 242)[0].respuesta.nu_respuesta) / (tallaMts * tallaMts));
                                    preguntasNumericas.filter((e, index) => e.id == 243)[0].respuesta = {
                                        id_pregunta: 243,
                                        nu_respuesta: imc.toFixed(1)
                                    };
                                }
                            }
                        }
                    }
                }
                this.props.callbackPreguntasNumericas({ preguntasNumericas });
                break;
            case 5:
                if (moment(value)) {
                    preguntasNumericas[index].respuesta = { id_pregunta: item.id, dt_respuesta: value };
                }
                break;
            case 6:
                if (moment(`01-01-2000 ${value}`)) {
                    preguntasNumericas[index].respuesta = { id_pregunta: item.id, tm_respuesta: value };
                }
                break;

        }
    }
    renderPreguntasNumericas(preguntas) {
        return preguntas.map((item, index) =>
            <div
                key={index}
                style={{ border: 'solid 1px gray' }}
            >
                <PreguntasNumericas
                    callbackPreg={({ value, index }) => {
                        this.validateValue({ value, index, preguntasNumericas: preguntas });
                    }}
                    callbackWizard={({ indexSeleccionado }) => {
                        this.props.callbackWizard({ indexSeleccionado: index });
                    }}
                    item={item}
                    sn_selected={this.state.indexSeleccionado == index ? true : false}
                    index={index}
                    key={index}
                />
            </div>
        );
    }

    renderOpcionesHelpBar() {
        const { date, indexSeleccionado, notasTipoEditor, pacienteActual, pregunta, opcion } = this.props;
        const preguntasNumericas = pacienteActual ? pacienteActual.preguntasNumericas || [] : [];
        let preguntaHija = this.getPreguntaHija();
        if (notasTipoEditor == 'principal' && this.props.notasTipoEditor !== 'lateral') {
            if (preguntaHija) {
                return (
                    this.opcionesArray().map((item, index) =>
                        <div onClick={() => {
                            if (preguntaHija.id_tipoPregunta !== 3) {
                                this.setRespuestaHija(item, index)
                            } else {
                                notifications.info('respuesta informativa favor de capturar un valor numerico.');
                            };
                        }} key={index} style={this.state.opcionPreSelected == index || preguntaHija.id_respuestaSelected === item.id ? styles.containerSelectedHelp : styles.containerHelp}>
                            <button style={{ height: 25, borderRadius: 12.5, minWidth: 25, backgroundColor: item.sn_patologica ? window.colors.red : window.colors.green, color: 'white', position: 'sticky', float: 'right' }}>
                                {index}
                            </button>
                            <div style={this.state.opcionPreSelected === index || preguntaHija.id_respuestaSelected === item.id ? styles.descriptionSelectedHelp : styles.descriptionHelp}>
                                {item.vc_respuesta}
                            </div>
                        </div>
                    )
                )
            }
            return (
                this.opcionesArray().map((item, index) =>
                    <div onClick={() => this.setPreguntaHija({ preguntaHija: item })} key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp}>
                        <button style={{ height: 25, borderRadius: 12.5, minWidth: 25, backgroundColor: item.sn_patologica ? window.colors.red : window.colors.green, color: 'white', position: 'sticky', float: 'right' }}>
                            {index}
                        </button>
                        <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp}>
                            {this.preguntaVersion(item, this.props.pacienteActual.detalle).vc_pregunta}
                        </div>
                    </div>
                )
            );
        } else {
            return (
                <div style={{ border: 0, padding: 0 }}>
                    {this.renderPreguntasNumericas(preguntasNumericas)}
                </div>
            );
        }
    }
    optionsAction() {
        const { date, indexSeleccionado, notasTipoEditor, pacienteActual, pregunta, opcion } = this.props;
        let preguntaHija = this.getPreguntaHija();
        switch (true) {
            case ((notasTipoEditor == 'principal' && this.props.notasTipoEditor !== 'lateral') && preguntaHija): {
                return (item, index) => {
                    if (preguntaHija.id_tipoPregunta !== 3) {
                        this.setRespuestaHija(item, index)
                    } else {
                        notifications.info('respuesta informativa favor de capturar un valor numerico.');
                    };
                };
            }
            default: {
                return (item) => { this.setPreguntaHija({ preguntaHija: item }) };
            }
        }
    }
    labelDisplays() {
        const { notasTipoEditor, pacienteActual } = this.props;
        let preguntaHija = this.getPreguntaHija();
        switch (true) {
            case ((notasTipoEditor == 'principal' && this.props.notasTipoEditor !== 'lateral') && preguntaHija): {
                return [
                    { key: 'vc_respuesta', style: { width: '100%', padding: 8 } },
                ]
            }
            default: {
                return [
                    { key: 'vc_pregunta', style: { width: '100%', padding: 8 } },
                ]
            }
        }
    }
    render() {
        const { indexSeleccionado, index, opcion } = this.props;

        let optionStyle = {
            flexDirection: 'column',
        }

        let labelDisplays = [
            { key: 'vc_nombre', style: { width: '100%' } },
            { key: 'vc_cveCIE9', style: { color: window.colors.itemSelected, textAlign: 'right', width: '100%' } }
        ]
        return (
            <HelpBarThink
                header={this.renderTitleHelpBar()}
                searchBar={true}
                // menu={this.getMenu()}
                // menuValue={this.getMenuValue()}
                options={this.opcionesArray()}
                optionsAction={this.optionsAction()}
                indexSeleccionado={this.state.opcionPreSelected}
                onChangeValue={this.onChangeValue.bind(this)}
                searchText={this.state.searchText}
                labelDisplays={this.labelDisplays()}
                optionStyle={optionStyle}
            />
        )
        return (
            <Container onMouseEnter={() => {
                this.setState({ focus: true })
            }} onMouseLeave={() => this.setState({ focus: false })} id='responderHelpBar' key={index} style={styles.container}>
                {this.renderTitleHelpBar()}
                <div style={{
                    ...styles.containerSub
                }}>
                    {this.renderOpcionesHelpBar()}
                </div>
            </Container>
        );

    }
}

const styles = {
    container: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0
    },
    containerSub: {
        overflowX: 'hidden',
        overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
        width: '100%',
        height: 'calc(100% - 111px)',
        margin: 0,
        padding: 0
    },
    containerHelp: {
        margin: 0,
        marginBottom: 2,
        borderRadius: 3,
        overflowY: "hidden",
        padding: 10,
        minHeight: 40,
        color: "black",
        backgroundColor: window.colors.white
    },
    containerSelectedHelp: {
        margin: 0,
        marginBottom: 2,
        borderRadius: 3,
        overflowY: "hidden",
        padding: 10,
        minHeight: 40,
        marginBottom: 2,
        color: "black",
        border: `solid 2px ${window.colors.itemSelected}`
    },
    descriptionHelp: {
        margin: 0,
        marginTop: 5,
        marginLeft: 10,
        marginRight: 10,
        textAlign: "left",
        fontSize: 14,
        minHeight: 40,
        color: "black"
    },
    descriptionSelectedHelp: {
        margin: 0,
        marginTop: 5,
        marginLeft: 10,
        marginRight: 10,
        textAlign: "left",
        fontSize: 14,
        minHeight: 40,
        color: "black"
    },
    stickHelp: {
        margin: 0,
        marginLeft: "85%",
        marginBottom: 2,
        width: 30,
        overflowY: "hidden",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color: window.colors.itemSelected
    },
    subTitleHelp: {
        margin: 0,
        marginLeft: 10,
        marginRight: 10,
        width: "100%",
        overflowY: "scroll",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color: "black"
    },
    subTitleRightHelp: {
        margin: 0,
        marginLeft: 10,
        marginRight: 10,
        width: "100%",
        paddingTop: 0,
        textAlign: "right",
        float: "right",
        fontSize: 12,
        color: window.colors.itemSelected
    },
    titleHelp: {
        width: "100%",
        padding: 3,
        height: 37,
        color: "white",
    }
};


const mapStateToProps = (state) => {
    const { pacienteActual, unidadesMedida, preguntas, preguntasRespuestas, respuestas, preguntasVersiones } = state.Think;
    return { unidadesMedida, preguntas, preguntasRespuestas, respuestas, preguntasVersiones };
};


export default connect(mapStateToProps, { setPacienteActual })(ResponderHelpBar);

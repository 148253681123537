import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { StaticDatePicker, LocalizationProvider } from "@material-ui/pickers";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";

export default ({ value, onChange, ...props }) => {
  const [date, setDate] = React.useState(value || [null, null]);

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      {/* <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} utils={MomentUtils}> */}
      <StaticDatePicker
        {...props}
        displayStaticWrapperAs="desktop"
        value={date}
        onChange={(nvalue) => {
          setDate(nvalue);
          onChange(moment(nvalue));
        }}
        renderInput={(props) => <TextField {...props} />}
      />
    </LocalizationProvider>
  );
};

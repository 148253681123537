import React from "react";

import {
  Button,
  Input,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Box,
} from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";
import AttachMoney from "@material-ui/icons/AttachMoney";
import PrintTicket from "app/sales/components/CashRegister/PrintTicketCorte";

const ModalInput = (props) => (
  <ModalInput
    disableUnderline
    inputProps={{
      style: {
        padding: 8,
        width: "100%",
        backgroundColor: "rgba(200,200,200,0.2)",
        borderRadius: 5,
        textAlign: "right",
      },
    }}
    style={{ margin: 0, width: "100%", padding: 8 }}
    {...props}
  />
);

export default (props) => {
  const { open, onClose } = props;

  return (
    <Dialog open={open} fullWidth maxWidth={"xs"} keepMounted>
      <DialogTitle>Corte de caja</DialogTitle>
      <DialogContent>
        <DialogContentText>corte</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          style={{ alignSelf: "right" }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          style={{ alignSelf: "right" }}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from 'react'
import posed from 'react-pose';
import {Icon} from 'semantic-ui-react';
import { Tooltip, List, ListItem } from '@material-ui/core';
const SelectableDiv = posed.i({
    open: {
      position: 'relative',
      backgroundColor: 'rgba(255,255,255,0)',
      color: '#00874c',
      borderRadius: 0,
      padding: 0,
      border: '0px solid rgba(0,0,0,0.1)',
      boxShadow: '1px 5px 10px rgba(0,0,0,0)',
    },
    close: {
      width: 0, 
      color: 'rgba(173, 1, 18, 1)',
      boxShadow: '1px 5px 10px rgba(0,0,0,0)',
    },
    select: {
      fontFamily: 'Roboto',
      position: 'absolute',
      backgroundColor: '#ffffff',
      color: '#00874c',
      // color:'#ffffff',
      // backgroundColor:window.colors.itemSelectedLight,
      border: '1px solid rgba(0,0,0,0.1)',
      borderRadius: 5,
      padding: 4,
      boxShadow: '3px 3px 10px rgba(100,100,100,0.8)',
    }
});
class TextField extends React.Component {
  constructor() {
    super()
    this.state = {
      editable:true,
      children: null,
      indexActive: null
    };
  };
 
  componentDidMount() {
    document.getElementById(`editable-${this.props.index}`).addEventListener('keydown',this.handlePressKey.bind(this))
  }
  
  componentDidUpdate() {
    if (this.state.caretPosition && document.activeElement === document.getElementById(`editable-${this.props.index}`)) {
      console.log('cursor---', this.state.caretPosition)
      this.setCurrentCursorPosition(this.state.caretPosition)
    }  
  }

  callbackPregunta(state, inputs){
      this.props.callbackPregunta({state, inputs});
  }
  createRange(node, chars, range) {
    if (!range) {
        range = document.createRange()
        range.selectNode(node);
        range.setStart(node, 0);
    }

    if (chars.count === 0) {
        range.setEnd(node, chars.count);
    } else if (node && chars.count >0) {
        if (node.nodeType === Node.TEXT_NODE) {
            if (node.textContent.length < chars.count) {
                chars.count -= node.textContent.length;
            } else {
                range.setEnd(node, chars.count);
                chars.count = 0;
            }
        } else {
           for (var lp = 0; lp < node.childNodes.length; lp++) {
                range = this.createRange(node.childNodes[lp], chars, range);

                if (chars.count === 0) {
                    break;
                }
            }
        }
    } 

    return range;
  }
  setCurrentCursorPosition(chars) {
    if (chars >= 0) {
        var selection = window.getSelection();
        const range = this.createRange(document.getElementById(`editable-${this.props.index}`).parentNode, { count: chars });
        if (range) {
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }
  }

  getPos(target) {
    // for contentedit field
    if (target.isContentEditable) {
        let _range = document.getSelection().getRangeAt(0)
        let range = _range.cloneRange()
        range.selectNodeContents(target)
        range.setEnd(_range.endContainer, _range.endOffset)
        return range.toString().length;
    }
    // for texterea/input element
    return target.selectionStart
  }

  handlePressKey(event) {
    if (this.state.focus) {
      console.log('entro')
      switch(true) {
        case (event.keyCode === 13):
        this.allowEnter({character: event.key, indexCharacter: this.getPos(event.target)}); 
        event.preventDefault()
        break;
        case (event.keyCode === 8):
        if (!this.state.funtionEndAnimation) {
          this.allowRemove(this.getPos(event.target))
        }
        event.preventDefault()
        break;
        case (event.keyCode === 37 || event.keyCode === 38 || event.keyCode === 39 || event.keyCode === 40):
        this.allowMoveCursor({character: event.keyCode, indexCharacter: this.getPos(event.target)}); 
        break;
        default:
        this.allowEdit({character: event.key, indexCharacter: this.getPos(event.target)}); 
        event.preventDefault()
        break;
      }
      console.log(this.getPos(event.target))
    }else{
      event.preventDefault()
    }
  }
  allowEdit({character,indexCharacter}){
    const {inputs} = this.props;
    const activeCharacter = {};
    let trunk = false;
    if (inputs.length > 0) {
      let lastCharacterPrev = 0;
      for( let i = 0 ; i < inputs.length && !trunk; i++) {
        if (indexCharacter >= lastCharacterPrev && indexCharacter <= lastCharacterPrev + inputs[i].sentence.length) {
          trunk = true;
          activeCharacter.input = i;
          if (inputs[i].id_pregunta) {
            inputs.splice(i + 2,0,{
              sentence: character
            });
            activeCharacter.input = i + 1
            console.log('akientre w ')
            const inputCaret = this.charactersInput(i);
            this.setState({ caretPosition: inputCaret.lastCharacter + 1, activeCharacter, preguntaHija: null, indexEdit: null}, () => {
                this.callbackPregunta(this.state, inputs);
            });
          }else{
            inputs[i].sentence = inputs[i].sentence.slice(0, (indexCharacter - lastCharacterPrev)) + character + inputs[i].sentence.slice( indexCharacter - lastCharacterPrev, inputs[i].sentence.length)
            this.setState({ caretPosition: indexCharacter + 1, activeCharacter, preguntaHija: null, indexEdit: null }, () => {
                this.callbackPregunta(this.state, inputs);
            });
          }
        }else{
          lastCharacterPrev = lastCharacterPrev + inputs[i].sentence.length;
        }
      } 
    }else{
      console.log('aki')
        inputs.push({
            sentence: ''
        })
        trunk = true;
        activeCharacter.input = 0
        inputs[0].sentence = character;
        this.setState({ caretPosition: 1, activeCharacter, preguntaHija: null, indexEdit: null }, () => {
            this.callbackPregunta(this.state, inputs);
        });
    }
    if (trunk === false) {
        this.callbackPregunta(this.state, inputs);
    }
  }
  inputCaret(indexCharacter) {
    const {inputs} = this.props;
    let trunk = false;
    let inputCaret = null;
    if (inputs.length > 0) {
      let lastCharacterPrev = 0;
      for( let i = 0 ; i < inputs.length && !trunk; i++) {
        if (indexCharacter >= lastCharacterPrev + 1 && indexCharacter <= lastCharacterPrev + inputs[i].sentence.length) {
          trunk = true;
          if (inputs[i].id_pregunta) {
            inputCaret = {
              index:i, 
              id_pregunta:inputs[i].id_pregunta,
              inputLeftLastCaret: lastCharacterPrev,
              inputRightFirstCaret: lastCharacterPrev + inputs[i].sentence.length + 1
          };
          }else{
            inputCaret = {
              index:i, 
              inputLeftLastCaret: lastCharacterPrev,
              inputRightFirstCaret: lastCharacterPrev + inputs[i].sentence.length + 1
          };
            }
        }else{
          lastCharacterPrev = lastCharacterPrev + inputs[i].sentence.length;
        }
      } 
    }
    return inputCaret;
  }
  charactersInput(input) {
    const {inputs} = this.props;
    let trunk = false;
    let inputCaret = null;
    if (inputs.length > 0) {
      let lastCharacterPrev = 0;
      for( let i = 0 ; i < inputs.length && !trunk; i++) {
        if (input === i) {
          trunk = true;
          if (inputs[i].id_pregunta) {
            inputCaret = {
              index:i, 
              id_pregunta:inputs[i].id_pregunta,
              firtsCharacter: lastCharacterPrev + 1,
              lastCharacter: lastCharacterPrev + inputs[i].sentence.length
          };
          }else{
            inputCaret = {
              index:i, 
              firtsCharacter: lastCharacterPrev + 1,
              lastCharacter: lastCharacterPrev + inputs[i].sentence.length
          };
            }
        }else{
          lastCharacterPrev = lastCharacterPrev + inputs[i].sentence.length;
        }
      } 
    }
    return inputCaret;
  }

  allowMoveCursor({character,indexCharacter}) {
    const {inputs} = this.props;
    let trunk = false;
    let caretInput = null;
    let setCaret = null;
    switch(character) {
      case 37:
        // se verifica si el caracter se encuentra en un input
      caretInput = this.inputCaret(indexCharacter)
      // si hay un input resaltado y no hay un inpur en el caracter enviado
      if (this.state.indexHover !== null && !caretInput){
        console.log('aki no deberia entrar')
        const charactersInput = this.charactersInput(this.state.indexHover);
        setCaret = this.inputCaret(charactersInput.firtsCharacter - 1);
        if (setCaret) {
          if (setCaret.id_pregunta) {
            this.setState({indexHover: setCaret.index, caretPosition: caretInput.inputLeftLastCaret + 1, funtionEndAnimation: null})
          }else{
            this.setState({indexHover: null, caretPosition: caretInput.inputLeftLastCaret, funtionEndAnimation: null})
          }
        }
        // se verifica si hay un input resaltado y un el caracter pertenece a un input.
      }else if (this.state.indexHover !== null && caretInput) {
        console.log('hay un input resaltado y un el caracter pertenece a un input')
        setCaret = this.inputCaret(this.state.indexHover !== caretInput.index ? indexCharacter : caretInput.inputLeftLastCaret );
        if (setCaret) {
          if (setCaret.id_pregunta) {
            this.setState({indexHover: setCaret.index, caretPosition: caretInput.inputLeftLastCaret + 1, funtionEndAnimation: null})
          }else{
            this.setState({indexHover: null, caretPosition: caretInput.inputLeftLastCaret, funtionEndAnimation: null})
          }
        }
        // se verifica que no hay un input resaltado pero si un input en el caracter seleccionado
      }else if (caretInput) {
        console.log('input hover', this.state.indexHover);
        console.log('no hay un input resaltado pero si un input en el caracter seleccionado')
        if (caretInput.id_pregunta) {
          this.setState({indexHover: caretInput.index, caretPosition:caretInput.inputLeftLastCaret + 1, funtionEndAnimation: null})
        }else{
          this.setState({indexHover: null, caretPosition: indexCharacter, funtionEndAnimation: null})
        }
      }
      break;
      case 39:
        // se verifica si el caracter se encuentra en un input
        caretInput = this.inputCaret(indexCharacter)
        // si hay un input resaltado y no hay un inpur en el caracter enviado
        if (this.state.indexHover !== null && !caretInput){
          console.log('aki no deberia entrar')
          const charactersInput = this.charactersInput(this.state.indexHover);
          setCaret = this.inputCaret(charactersInput.lastCharacter + 1);
          if (setCaret) {
            if (setCaret.id_pregunta) {
              this.setState({indexHover: setCaret.index, indexEdit: null, caretPosition: caretInput.inputRightFirstCaret - 1, funtionEndAnimation: null})
            }else{
              this.setState({indexHover: null, indexEdit: null, caretPosition: caretInput.inputRightFirstCaret, funtionEndAnimation: null})
            }
          }
          // se verifica si hay un input resaltado y un el caracter pertenece a un input.
        }else if (this.state.indexHover !== null && caretInput) {
          console.log('hay un input resaltado y un el caracter pertenece a un input')
          setCaret = this.inputCaret(caretInput.inputRightFirstCaret);
          if (setCaret) {
            if (setCaret.id_pregunta) {
              this.setState({indexHover: setCaret.index, indexEdit: null, caretPosition: caretInput.inputRightFirstCaret - 1, funtionEndAnimation: null})
            }else{
              this.setState({indexHover: null, indexEdit: null, caretPosition: caretInput.inputRightFirstCaret, funtionEndAnimation: null})
            }
          }
          // se verifica que no hay un input resaltado pero si un input en el caracter seleccionado
        }else if (caretInput) {
          console.log('input hover', this.state.indexHover);
          console.log('no hay un input resaltado pero si un input en el caracter seleccionado')
          if (caretInput.id_pregunta) {
            this.setState({indexHover: caretInput.index, indexEdit: null, caretPosition: caretInput.inputRightFirstCaret - 1})
          }else{
            this.setState({indexHover: null, indexEdit: null, caretPosition: null})
          }
        }
      break;
      default:
         // se verifica si el caracter se encuentra en un input
         caretInput = this.inputCaret(indexCharacter)
         if (caretInput) {
            console.log('input hover', this.state.indexHover);
            console.log('no hay un input resaltado pero si un input en el caracter seleccionado')
            if (caretInput.id_pregunta) {
              this.setState({indexHover: caretInput.index, indexEdit: null, caretPosition: caretInput.inputRightFirstCaret - 1})
            }else{
              this.setState({indexHover: null, indexEdit: null, caretPosition: null})
            }
          }else{
            this.setState({indexHover: null, indexEdit: null, caretPosition: indexCharacter})
          }
      break;
    }
  }
  allowEnter({indexCharacter}){
    const {inputs} = this.props;
    let trunk = false;
    if (inputs.length > 0) {
      let lastCharacterPrev = 0;
      for( let i = 0 ; i < inputs.length && !trunk; i++) {
        if (indexCharacter >= lastCharacterPrev + 1 && indexCharacter <= lastCharacterPrev + inputs[i].sentence.length) {
          trunk = true;
          if (!inputs[i].id_pregunta) {
            inputs[i].sentence = inputs[i].sentence.slice(0, (indexCharacter - lastCharacterPrev)) + '\n ' + inputs[i].sentence.slice( indexCharacter - lastCharacterPrev, inputs[i].sentence.length)
            this.setState({ caretPosition: indexCharacter + 1 }, () => {
                this.callbackPregunta(this.state, inputs);
            });
          }else{
            inputs.push({
              sentence: '\n '
            })
            this.setState({indexHover: null, indexEdit: null,caretPosition: lastCharacterPrev + inputs[i].sentence.length + 1 })
          }
        }else{
          lastCharacterPrev = lastCharacterPrev + inputs[i].sentence.length;
        }
      } 
    }else{
        inputs.push({
            sentence: ''
        })
        inputs[0].sentence = '\n ';
        this.setState({ caretPosition: 1 }, () => {
            this.callbackPregunta(this.state, inputs);
        });
    }
    if (trunk === false) {
        this.callbackPregunta(this.state, inputs);
    }
  }
  allowRemove(indexCharacter){
    const {inputs} = this.props;
    let trunk = false;
    console.log('indexCharacter',indexCharacter)
    console.log('inputs count',inputs.length)
    const inputHover = inputs[this.state.indexHover] || {};
    if (inputHover.id_pregunta){
      console.log('entro ',indexCharacter)

      this.setState({indexRemove: this.state.indexHover, indexEdit: null, funtionEndAnimation: () => {
        console.log('se borro, indice:', this.state.indexHover)
        trunk = true;
        inputs.splice(this.state.indexHover, 1);
        this.setState({ preguntaHija: null,indexHover: null, caretPosition: null, indexRemove: null, activeCharacter: null, funtionEndAnimation: null}, () => {
            this.callbackPregunta(this.state, inputs);
        });
      }})
    } else if (inputs.length > 0 && indexCharacter > 0) {
      console.log('entro en indexhover not selected')
      let lastCharacterPrev = 0;
      for( let i = 0 ; i < inputs.length && !trunk; i++) {
        if (indexCharacter >= lastCharacterPrev + 1 && indexCharacter <= lastCharacterPrev + inputs[i].sentence.length) {
          console.log('input:', i, ' : (',lastCharacterPrev,'-',lastCharacterPrev + inputs[i].sentence.length, ') : id_pregunta = ',inputs[i].id_pregunta);
          trunk = true;
          if (inputs[i].id_pregunta) {
            this.setState({indexRemove: i, indexEdit: null, funtionEndAnimation: () => {
                console.log('se borro, indice:', i)
                inputs.splice(i, 1);
                this.setState({ preguntaHija: null,indexHover: null, caretPosition: null, indexRemove: null, activeCharacter: null, funtionEndAnimation: null}, () => {
                    this.callbackPregunta(this.state, inputs);
                });
            }})
          }else{
            inputs[i].sentence = inputs[i].sentence.slice(0, (indexCharacter - lastCharacterPrev) - 1) + inputs[i].sentence.slice( indexCharacter - lastCharacterPrev, inputs[i].sentence.length)
            this.setState({ preguntaHija: null, indexHover: null, indexEdit: null, caretPosition:  indexCharacter > 0 ? indexCharacter - 1 : indexCharacter}, () => {
                this.callbackPregunta(this.state, inputs);
            });
          }
        }else{
          lastCharacterPrev = lastCharacterPrev + inputs[i].sentence.length;
        }
      } 
    }else{
      console.log('no entro en ninguno ')
    }
    if (trunk === false) {
        this.callbackPregunta(this.state, inputs);
    }
  }
    unSetHover = ({index}) => {
      if (this.state.indexEdit !== index)
        this.setState({indexHover: null}, () => {
            this.callbackPregunta(this.state, this.props.inputs);
        });
    }
    setHover = ({index}) => {
        this.setState({indexHover: index, activeCharacter: {input:index}, editable: this.props.inputs[index].id_pregunta ? false : true}, () => {
            this.callbackPregunta(this.state, this.props.inputs);
        });
    }
    setEdit = ({index}) => {
      this.setState({indexHover: index,indexEdit: index, activeCharacter: {input:index}}, () => {
          this.callbackPregunta(this.state, this.props.inputs);
      });
  }
  renderInputs() {
    const {inputs} = this.props;
    return inputs.map((input, index) => {
      if (index + 1 === inputs.length) {
        return (
          <Tooltip
          open
          title={
            <div style={{minWidth: 60, minHeight: 30, flexDirection: 'column'}}>
              <Tooltip
              open
              placement='top'
              title='Seleccione'
              >
                <i contentEditable focus style={{ fontSize: this.props.fontSize || 20, whiteSpace:'pre-wrap'}} accessKey={input.id_pregunta || null} key={index} id={`${this.props.pregunta.id}-index:${index}`} onMouseEnter={() => this.setState({indexHover: index, editable: input.id_pregunta ? false : true})} onMouseLeave={() => this.setState({indexHover: null})}>
                  {input.sentence}
                </i>
              </Tooltip>
              <List>
                <ListItem>
                  Medico
                </ListItem>
                <ListItem>
                  Medico
                </ListItem>
                <ListItem>
                  Medico
                </ListItem>
                <ListItem>
                  Medico
                </ListItem>
                <ListItem>
                  Medico
                </ListItem>
                <ListItem>
                  Medico
                </ListItem>
              </List>
            </div>
          }
          placement='right-start'
          >
            <i style={{ opacity: 0,fontSize: this.props.fontSize || 20, whiteSpace:'pre-wrap'}} accessKey={input.id_pregunta || null} key={index} id={`${this.props.pregunta.id}-index:${index}`} onMouseEnter={() => this.setState({indexHover: index, editable: input.id_pregunta ? false : true})} onMouseLeave={() => this.setState({indexHover: null})}>
              
            </i>
          </Tooltip>
        )
      }
      if (input.id_pregunta) {
        return (
          <SelectableDiv key={index} onPoseComplete={() => {
            console.log('end animation', index);
            if (this.state.funtionEndAnimation) this.state.funtionEndAnimation();
          }} className="i" pose={(this.state.indexHover === index && this.state.indexRemove !== index ) || this.state.indexEdit === index ? 'select' : this.state.indexRemove === index ? 'close' : 'open'}>
            <i contentEditable={false} style={{fontSize: this.props.fontSize || 20, whiteSpace:'pre-wrap'}} accessKey={input.id_pregunta || null} key={index} id={`${this.props.pregunta.id}-index:${index}`} 
            onMouseMove={() => {
              if (this.state.indexRemove !== index)
              this.setHover({index})
            }} 
            onMouseLeave={() => this.unSetHover({index})}>
              <i contentEditable={false} onClick={() => this.setEdit({index: this.state.indexEdit === index ? null : index})}>
              {input.sentence}
              </i>
              {
              this.state.indexHover === index && this.state.indexRemove !== index ?
                <Icon 
                onClick={(e) => {
                  console.log('remove this')
                  this.allowRemove(this.getPos(e.target))
                }}
                name='delete' style={{color: 'red', textAlign: 'center'}}
                
                />
              :
              <i/>
            }
            </i>
            
          </SelectableDiv>
        )
      }
      if  (input.sentence.includes('\n')) {
        return input.sentence.split('\n').map((item, index) => {
          return (
            <i style={{fontSize: this.props.fontSize || 20, whiteSpace:'pre-wrap'}} accessKey={input.id_pregunta || null} key={index} id={`${this.props.pregunta.id}-index:${index}`} onMouseEnter={() => this.setState({indexHover: index, editable: input.id_pregunta ? false : true})} onMouseLeave={() => this.setState({indexHover: null})}>
              {item}
              <br/>
            </i>
          )
        })
      }

      return (
        <i style={{fontSize: this.props.fontSize || 20, whiteSpace:'pre-wrap'}} accessKey={input.id_pregunta || null} key={index} id={`${this.props.pregunta.id}-index:${index}`} onMouseEnter={() => this.setState({indexHover: index, editable: input.id_pregunta ? false : true})} onMouseLeave={() => this.setState({indexHover: null})}>
          {input.sentence}
        </i>
      )
    })
  }
  renderContent() {
    return (
      <div id={`editable-${this.props.index}`} suppressContentEditableWarning contentEditable={true} style={{width: '100%', minHeight: this.props.minHeight || 200, outline: 'none'}}>
        {this.renderInputs()}
      </div>
    )
  }
  render(){
      
    return (
      <div onMouseEnter={() => this.setState({focus:true})} onMouseLeave={() => this.setState({focus:false})} style={{width: '100%', justifyItems: 'left', textJustify:'left', padding: 0, borderRadius: 10, textAlign: 'left', outline: 'none'}}>
        {this.renderContent()}
      </div>
      
    )
  };
};

export default (TextField);
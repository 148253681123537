import React from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  outlinedInput: {
    padding: '8px 14px'
  },
  inputLabel: {
    transform: 'translate(14px, 10px) scale(1)'
  }
}));

export default props => {
  const classes = useStyles();
  const { fullWidth = false, label, input = {}, meta = {}, ...moreInputProps } = props;

  const inputProps = Object.assign({
    autoComplete: 'off',
    autoCorrect: 'off',
    spellCheck: 'off',
    className: classes.outlinedInput
  }, moreInputProps, { label: moreInputProps.required ? `${label} *` : label });

  const InputProps = { inputProps };
  const InputLabelProps = { className: classes.inputLabel };

  return (
    <TextField
      variant="outlined"
      margin="normal"
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      fullWidth={fullWidth}
      helperText={meta.touched && meta.error}
      error={Boolean(meta.touched && meta.error)}
      autoComplete="off"
      {...input}
      {...props}
      style={{marginRight: 10}}
    />
  );
};
import React, { Component } from 'react';
import { connect } from 'react-redux';

class PrincipalHeaderItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }
    timeSince(refDate, date) {

        var seconds = Math.floor((refDate - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " años";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " minutos";
        }
        return "un instante";
    }
    render() {
        const { date } = this.props;
        const h = window.screen.height;
        let paciente = "no especificado, la nota no podrá ser guardada."
        let sexo = 'sexo: no especificado'
        let edad = 'edad: no especificada'
        let curp = 'CURP: no especificado'
        const pacienteObj = this.props.pacienteActual;
        if (pacienteObj != {} && pacienteObj != null) {
            edad = `edad: ${this.timeSince(new Date(), new Date(pacienteObj.detalle.dt_nacimiento))}`;
            sexo = pacienteObj.detalle.id_sexo == 1 ? 'sexo: masculino' : (pacienteObj.detalle.id_sexo == 2 ? 'sexo: femenino' : 'sexo: no especificado');
            curp = pacienteObj.detalle.vc_curp ? `CURP: ${pacienteObj.detalle.vc_curp.toUpperCase()}` : 'Curp: no especificado';
            if (pacienteObj.detalle.vc_apellidoMaterno != undefined) {
                paciente = `${pacienteObj.detalle.vc_nombre}` + " " + `${pacienteObj.detalle.vc_apellidoPaterno}` + " " + `${pacienteObj.detalle.vc_apellidoMaterno}`;
            } else {
                paciente = `${pacienteObj.detalle.vc_nombre}` + " " + `${pacienteObj.detalle.vc_apellidoPaterno}`
            }
        }
        const usuario = window.amplify.store('user') ? window.amplify.store('user').usuario : null;
        const institucion = window.amplify.store('institution') ? window.amplify.store('institution') : null;
        const nombreUsuario = usuario != null ? `${usuario.vc_nombre} ${usuario.vc_apellidoPaterno} ${usuario.vc_apellidoMaterno}` : 'usuario no logeado';
        const nombreInstitucion = institucion != null ? `${institucion.vc_nombre}` : '';
        const rol = usuario != null ? (usuario.id_sexo == 1 ? 'Dr. ' : 'Dra.') : null;
        return (
            <div style={{ float: 'center', width: '100%', height: 74, padding: 0, margin: 0, backgroundColor: window.colors.black }}>
                <div style={{ float: 'left', height: 74, width: 74, padding: 0, marginLeft: 5 }}>
                    <div style={{
                        float: 'center',
                        height: 64,
                        width: '100%',
                        margin: 0,
                        marginTop: 8,
                        borderRadius: 32,
                        backgroundImage: 'url("assets/images/iconThin-k.png")',
                        backgroundPosition: 'center',
                        backgroundSize: '74px 74px',
                        backgroundRepeat: 'no-repeat'
                    }} />
                </div>
                <div style={{ height: '100%', width: 'calc(100% - 153px)', padding: 25, float: 'left', margin: 0, color: window.colors.white, textAlign: 'center' }}>
                    <h3>{this.props.title}</h3>
                </div>
                <div style={{ float: 'right', height: 74, width: 74, padding: 0, margin: 0 }}>
                    <div style={{
                        height: 54,
                        width: 54,
                        margin: 10,
                        border: `2px solid ${window.colors.itemSelected}`,
                        backgroundColor: window.colors.white,
                        borderRadius: 32,
                        backgroundImage: usuario != null ? (usuario.id_sexo == 1 ? 'url("assets/images/doctor.png")' : (usuario.id_sexo == 2 ? 'url("assets/images/doctora.png")' : null)) : 'url("assets/images/doctor.png")',
                        backgroundPosition: 'center',
                        backgroundSize: '54px 54px',
                        backgroundRepeat: 'no-repeat'
                    }} />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    const { pacienteActual } = state.Think;
    return { pacienteActual };
};


export default connect(mapStateToProps)(PrincipalHeaderItem);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Input } from 'semantic-ui-react';
import moment from 'moment';

class PreguntasNumericas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            indexSelected: null
        };
    }
    validateDateInput(value) {
        const demo = '01-01-2000';
        const complete = `${value}${demo.substring(value.length, 10)}`;
        console.log('complete : ', complete);
        try {
            const date = moment(complete);
            console.log(date.format('DD-MM-YYYY'));
            if (date.isValid()){
                if (value.length == 10) {
                    this.props.callbackPreg({ value: value, index: this.props.index });
                }else{
                    this.setState({value});
                }
            }
        } catch (e) {
            console.log('error -----> ', e);
        }
    }
    validateValueInput(value) {
        const {item} = this.props;
        console.log(item)
        console.log('state value : ---' , this.state.value);
        switch (item.id_tipoPregunta) {
            case 3:
            if (value * 1) {
                this.props.callbackPreg({ value: value, index: this.props.index });
                this.setState({value});
            }
            break;
            case 5:
            this.validateDateInput(value);
            break;
        }
    }
    onChangeValue(event) {
        console.log('event', event.target.value)
        this.validateValueInput(event.target.value);
    }
    respuestaValor(item) {

        switch ( item.id_tipoPregunta ) {
            case 1,2:
                if ( item.respuesta != undefined && item.respuesta != null) {
                    return `${item.versiones[0].respuestas.filter(r => r.id === item.respuesta.id_respuesta)[0].vc_respuesta}`;
                }
            case 3:
                if ( item.respuesta != undefined && item.respuesta != null) {
                    return `${item.respuesta.nu_respuesta}`;
                }
            case 4:
                if ( item.respuesta != undefined && item.respuesta != null) {
                    return `${item.respuesta.vc_respuesta}`;
                }
            case 5:
                if ( item.respuesta != undefined && item.respuesta != null) {
                    return `${item.respuesta.dt_respuesta}`;
                }
            case 6:
                if ( item.respuesta != undefined && item.respuesta != null) {
                    return `${item.respuesta.tm_respuesta}`;
                }
            default:
                return null;
        }
        return null;
    }

    getInputs(item) {
        const respuesta = item.respuesta ? item.respuesta: "";

		return [
			{
				type: 'number',
				placeholder: respuesta,
                required: true,
                onChange: this.onChangeValue.bind(this),
                style: stylesPreguntas.textField
			}
		];
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
          this.tabNext();
        }
    }
    saltar() {
        window.$("input[tabindex], textarea[tabindex]").each(function () {
            window.$(this).on("keypress", function (e) {
                if (e.keyCode === 13)
                {
                    var nextElement = window.$('[tabindex="' + (this.tabIndex + 1) + '"]');
                    if (nextElement.length) {
                        window.$('[tabindex="' + (this.tabIndex + 1) + '"]').focus();
                        e.preventDefault();
                    } else
                        window.$('[tabindex="1"]').focus();
                }
            });
        });
    }

    render() {
        const { item, index } = this.props;
        const backgroundColor = this.state.sn_selected == true ?window.colors.itemSelected :window.colors.white;
        const respuesta = this.respuestaValor(item) != null ? this.respuestaValor(item) : this.state.value;
        return (
            <Container key={index} onClick={() => this.props.callbackWizard({ indexSeleccionado: index })} onMouseEnter={() => this.setState({ indexSelected: index })} onMouseLeave={() => this.setState({ indexSelected: null })} style={{
                width: "100%",
                margin:0,
                padding:0,
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={stylesPreguntas.title}>
                    <label style={stylesPreguntas.textLabel}>
                        {item.versiones[0].vc_pregunta}
                    </label>
                </div>
                <div className="" style={stylesPreguntas.value}>
                    <Input
                        key={index}
                        maxLength={ item.id_tipoPregunta == 5 ? 10 : item.id_tipoPregunta == 6 ? 8 : 6}
                        value={respuesta}
                        placeholder={respuesta}
                        style={stylesPreguntas.textField}
                        onKeyPress={this.saltar.bind(this)}
                        onChange={this.onChangeValue.bind(this)}
                    />
                </div>

            </Container>
        );
    }
}


const stylesPreguntas = {
    container: {
        width: "100%",
        margin:0,
        padding:0,
    },
    title:{
        minHeight: 35,
        width: '70%',
        color: "blue",
        padding: 10
    },
    value:{
        width: '30%',
        minHeight: 35,
        padding: 8,
        color: "white"
    },
    textLabel:{
        maxLength: 6,
        width:"100%",
        height:"100%",
        padding: 3,
        color: "gray",
        fontSize: window.screen.width * 0.010512,
        border: 0 ,
        textAlign:"left"
    },
    textField:{
        width:"100%",
        height: window.screen.width * 0.015,
        fontSize: window.screen.width * 0.009,
        borderRadius: 4,
        margin: 0,
        border: 0 ,
        minHeight: 30,
        color: "black",
        textAlign: "center"
    },
    line:{
        width:"100%",
        height: 1,
        float: "left",
        backgroundColor: "gray",
    }
};


const mapStateToProps = (state) => {
    const { preguntas } = state.Think;
    return { preguntas };
};


export default connect(mapStateToProps)(PreguntasNumericas);

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { isString } from "util";
import { Divider } from "@material-ui/core";

export default (props) => {
  const { title, message, onAccept, onCancel, onClose, open = true } = props;
  const [show, setShow] = useState(open);
  const inOnclose = () => {
    setShow(false);
  }
  useEffect(() => {
    if (!show) {
      onClose && onClose();
    }
  }, [show])
  useEffect(() => {
    setShow(open);
  }, [open])
  return (
    <Dialog style={{ minWidth: 300 }} open={open} onClose={inOnclose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ padding: 30, paddingTop: 0 }}>
        {isString(message) ? (
          <DialogContentText>{message}</DialogContentText>
        ) : (
          message
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        {onCancel && (
          <Button onClick={onCancel} color="primary">
            Cancelar
          </Button>
        )}

        {/* {onClose && (
          <Button onClick={onClose} color="primary">
            Cerrar
          </Button>
        )} */}

        <Button
          onClick={onAccept}
          variant="contained"
          color="primary"
          autoFocus
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Mic from '@material-ui/icons/Mic';
import HearingOutlined from '@material-ui/icons/HearingOutlined';
import SportsEsportsOutlined from '@material-ui/icons/SportsEsportsOutlined';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { IconButton, Box, TextField, Paper, Slider } from '@material-ui/core';
import ThinkResponse from '../../../../../material-ui/ThinkResponse';
import { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar, setPacienteActual } from '../../../actions';
import TextView from '../../../../../material-ui/TextView';
import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";
import { Modal } from 'semantic-ui-react';
import ModalUI from '@material-ui/core/Modal';
import GoogleMaps from '../../patients/GoogleMaps';
import Map from "@material-ui/icons/Map";
import moment from 'moment';
import InputResponse from './InputResponse';
import InputEdit from './InputEdit';
import { position, offset } from 'caret-pos';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 8,
        fontSize: theme.typography.pxToRem(20),
        position: 'relative'
    },
    textArea: {
        color: theme.palette.type === 'dark' ? 'white' : 'gray',
        border: theme.palette.type === 'dark' ? '1px solid rgba(200,200,200,0.2)' : '1px solid rgba(100,100,100,0.2)'
    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightMedium,
        width: '100%'
    },
    InputLabels: {
        fontSize: 30
    }
}));

function TextViewThink(props) {
    const { item, index, showCode } = props;
    const classes = useStyles();
    const steps = moment().diff(moment('2020-01-22'), 'days').toFixed(0);
    const [prevValueProps, setPrevValueProps] = React.useState(null);
    const [value, setValue] = React.useState(item || {});
    const [think, setThink] = React.useState(false);
    const [state, setState] = React.useState({ pos: null, editInput: null, index: null });
    const [editingIndex, setEditingIndex] = React.useState(null);
    const [step, setStep] = React.useState(steps);
    const [showMap, setShowMap] = React.useState(false);
    const [speech, setSpeech] = React.useState(false);
    const [over, setOver] = React.useState(false);
    const onChangeValue = () => {
        const pacienteActual = props.pacienteActual;
        const preguntasTexto = pacienteActual.preguntasTexto.map((pregunta, index) => {
            if (pregunta.id === value.id) {
                pregunta.respuestasInternas = value.respuestasInternas;
            }
            return pregunta;
        });
        pacienteActual.preguntasTexto = preguntasTexto;
        console.log('se guardo')
        props.setPacienteActual(pacienteActual);
    }

    const onchangeInner = (e) => {
        const value = e.target.value || ''
        setValue(value);
    }
    const preguntasEnlace = []
    // console.log('versiones', value)
    if (value.version && value.version.id_preguntaEnlace) {
        preguntasEnlace.push(value.version.id_preguntaEnlace)
    } else if (value.versiones[0].id_preguntaEnlace) {
        preguntasEnlace.push(value.versiones[0].id_preguntaEnlace)
    }
    const renderHelperSideBar = () => {
        return (
            <ThinkResponse
                preguntasEnlace={preguntasEnlace}
                id_preguntaPadre={value.id}
                getDefault={value.id === 364}
                callbackFinish={() => {
                    props.callbackFinish && props.callbackFinish()
                }}
            />
        )
    }
    
    const updateHelperSideBar = (e) => {
        e.stopPropagation();
        props.setHelperSidebarRender(renderHelperSideBar)
        props.setDisplaySidebar(true)
    }
    const setCaret = (input) => {
        // const input = document.getElementById('preguntaTexto:' + value.id)
        if (input) {
            console.log('getposition')
            try {
                const pos = position(input).pos;
                console.log('pos', pos)
                // if (pos > -1)
                //     position(input, pos + 1)
            } catch (error) {
                console.log('error', error)
            }
        }
    }
    useEffect(() => {
        if (value === null || props.item !== value) {
            setPrevValueProps(props.item)
            setValue(props.item)
        }
    }, [props])
    const points = (value.respuestasInternas || []).filter(e => e.nu_lat && e.nu_lon);

    // console.log('ITEM ==========>>>', item)
    return (
        <Box
            style={{ border: '1.5px solid gray', borderRadius: 5, padding: 16, width: '100%', whiteSpace: 'pre-wrap', height: props.fullHeight ? '100%' : 'auto', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', textAlign: 'justify' }}>
            <Box id={'preguntaTexto:' + value.id} suppressContentEditableWarning onBlur={onChangeValue}
                style={{ width: '100%', minHeight: 50, whiteSpace: 'pre-wrap', outline: 'none', height: '100%' }}>
                {
                    (value.respuestasInternas || [{ vc_sentencia: '' }]).map((input, index) => {
                        if (input.id_pregunta) {
                            return (
                                <InputResponse
                                    key={index}
                                    input={input}
                                    isEditing={index === editingIndex}
                                    index={index}
                                    id_preguntaTexto={value.id}
                                    showCode={showCode}
                                    callbackFinish={props.callbackFinish || null}
                                />
                            )
                        } else {
                            return (
                                <InputEdit
                                    idInput={`respuestaId=${value.id}:${index}:${'prev'}`}
                                    key={index}
                                    input={input}
                                    id_preguntaTexto={value.id}
                                    index={index}
                                    callback={(e) => {
                                        const valueEdit = { ...value }
                                        valueEdit.respuestasInternas = value.respuestasInternas || [];
                                        valueEdit.respuestasInternas[index] = { ...input, vc_sentencia: e.currentTarget.innerText }
                                        setValue(valueEdit);
                                    }}
                                />
                            )
                        }
                    })
                }
                <InputEdit
                    idInput={`respuestaId=${value.id}:${(value.respuestasInternas || [{ vc_sentencia: '' }]).length}:${'prev'}`}
                    key={'last'}
                    input={{ vc_sentencia: props.voice || '' }}
                    id_preguntaTexto={value.id}
                    index={(value.respuestasInternas || [{ vc_sentencia: '' }]).length}
                    callback={(e) => {
                        const valueEdit = { ...value }
                        valueEdit.respuestasInternas = value.respuestasInternas || [];
                        valueEdit.respuestasInternas.push({ vc_sentencia: e.currentTarget.innerText });
                        setValue(valueEdit);
                    }}
                />
                <ModalUI
                    open={showMap}
                    onClose={() => setShowMap(false)}
                    style={{ boxShadow: '0px 0px 0px transparent', backgroundColor: 'transparent', margin: window.isMobile ? 50 : 100 }}
                >
                    <Paper style={{ padding: 0, borderRadius: 20, overflow: 'hidden', height: '100%', width: '100%', outline: 'none' }}>
                        <GoogleMaps
                            points={points}
                            circles={(props.covid19 || {}).countries || []}
                        />
                    </Paper>
                </ModalUI>
            </Box>
        </Box>
    );
}

const actions = { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar, setPacienteActual };
const mapStateToProps = (state) => {
    const { preguntas, pacienteActual, diagnosticos, covid19 } = state.Think;
    return { preguntas, pacienteActual, diagnosticos, covid19 };
};

export default connect(mapStateToProps, actions)(TextViewThink);


// import React, { useEffect } from 'react';
// import { connect } from 'react-redux';
// import { makeStyles } from '@material-ui/core/styles';
// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import Typography from '@material-ui/core/Typography';
// import Mic from '@material-ui/icons/Mic';
// import HearingOutlined from '@material-ui/icons/HearingOutlined';
// import SportsEsportsOutlined from '@material-ui/icons/SportsEsportsOutlined';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import { IconButton, Box, TextField, Paper, Slider } from '@material-ui/core';
// import ThinkResponse from '../../../../../material-ui/ThinkResponse';
// import { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar, setPacienteActual } from '../../../actions';
// import TextView from '../../../../../material-ui/TextView';
// import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";
// import { Modal } from 'semantic-ui-react';
// import ModalUI from '@material-ui/core/Modal';
// import GoogleMaps from '../../patients/GoogleMaps';
// import Map from "@material-ui/icons/Map";
// import moment from 'moment';
// import InputResponse from './InputResponse';
// import InputEdit from './InputEdit';
// import { position, offset } from 'caret-pos';

// const useStyles = makeStyles(theme => ({
//     root: {
//         width: '100%',
//         padding: 8,
//         fontSize: theme.typography.pxToRem(20),
//         position: 'relative'
//     },
//     textArea: {
//         color: theme.palette.type === 'dark' ? 'white' : 'gray',
//         border: theme.palette.type === 'dark' ? '1px solid rgba(200,200,200,0.2)' : '1px solid rgba(100,100,100,0.2)'
//     },
//     heading: {
//         fontSize: theme.typography.pxToRem(17),
//         fontWeight: theme.typography.fontWeightMedium,
//         width: '100%'
//     },
//     InputLabels: {
//         fontSize: 30
//     }
// }));

// function TextViewThink(props) {
//     const { item, index, showCode } = props;
//     const classes = useStyles();
//     const steps = moment().diff(moment('2020-01-22'), 'days').toFixed(0);
//     const [prevValueProps, setPrevValueProps] = React.useState(null);
//     const [value, setValue] = React.useState(item || {});
//     const [think, setThink] = React.useState(false);
//     const [state, setState] = React.useState({ pos: null, editInput: null, index: null });
//     const [editingIndex, setEditingIndex] = React.useState(null);
//     const [step, setStep] = React.useState(steps);
//     const [showMap, setShowMap] = React.useState(false);
//     const [speech, setSpeech] = React.useState(false);
//     const [over, setOver] = React.useState(false);
//     const onChangeValue = () => {
//         const pacienteActual = props.pacienteActual;
//         const preguntasTexto = pacienteActual.preguntasTexto.map((pregunta, index) => {
//             if (pregunta.id === value.id) {
//                 pregunta.respuestasInternas = value.respuestasInternas;
//             }
//             return pregunta;
//         });
//         pacienteActual.preguntasTexto = preguntasTexto;
//         console.log('se guardo')
//         props.setPacienteActual(pacienteActual);
//     }

//     const onchangeInner = (e) => {
//         const value = e.target.value || ''
//         setValue(value);
//     }
//     const preguntasEnlace = []
//     // console.log('versiones', value)
//     if (value.version && value.version.id_preguntaEnlace) {
//         preguntasEnlace.push(value.version.id_preguntaEnlace)
//     } else if (value.versiones[0].id_preguntaEnlace) {
//         preguntasEnlace.push(value.versiones[0].id_preguntaEnlace)
//     }
//     const renderHelperSideBar = () => {
//         return (
//             <ThinkResponse
//                 preguntasEnlace={preguntasEnlace}
//                 id_preguntaPadre={value.id}
//                 getDefault={value.id === 364}
//                 callbackFinish={() => {
//                     props.callbackFinish && props.callbackFinish()
//                 }}
//             />
//         )
//     }
    
//     const updateHelperSideBar = (e) => {
//         e.stopPropagation();
//         props.setHelperSidebarRender(renderHelperSideBar)
//         props.setDisplaySidebar(true)
//     }
//     const setCaret = (input) => {
//         // const input = document.getElementById('preguntaTexto:' + value.id)
//         if (input) {
//             console.log('getposition')
//             try {
//                 const pos = position(input).pos;
//                 console.log('pos', pos)
//                 // if (pos > -1)
//                 //     position(input, pos + 1)
//             } catch (error) {
//                 console.log('error', error)
//             }
//         }
//     }
//     useEffect(() => {
//         if (value === null || props.item !== value) {
//             setPrevValueProps(props.item)
//             setValue(props.item)

//         }
//     }, [props])
//     const points = (value.respuestasInternas || []).filter(e => e.nu_lat && e.nu_lon);
//     return (
//         <Box
//             style={{ border: '1.5px solid gray', borderRadius: 5, padding: 16, width: '100%', whiteSpace: 'pre-wrap', height: props.fullHeight ? '100%' : 'auto', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', textAlign: 'justify' }}>
//             <Box id={'preguntaTexto:' + value.id} suppressContentEditableWarning onBlur={onChangeValue}
//                 style={{ width: '100%', minHeight: 50, whiteSpace: 'pre-wrap', outline: 'none', height: '100%' }}>
//                 {
//                     (value.respuestasInternas || [{ vc_sentencia: '' }]).map((input, index) => {
//                         if (input.id_pregunta) {
//                             return (
//                                 <InputResponse
//                                     key={index}
//                                     input={input}
//                                     isEditing={index === editingIndex}
//                                     index={index}
//                                     id_preguntaTexto={value.id}
//                                     showCode={showCode}
//                                     callbackFinish={props.callbackFinish || null}
//                                 />
//                             )
//                         } else {
//                             return (
//                                 <InputEdit
//                                     idInput={`respuestaId=${value.id}:${index}:${'prev'}`}
//                                     key={index}
//                                     input={input}
//                                     id_preguntaTexto={value.id}
//                                     index={index}
//                                     callback={(e) => {
//                                         const valueEdit = { ...value }
//                                         valueEdit.respuestasInternas = value.respuestasInternas || [];
//                                         valueEdit.respuestasInternas[index] = { ...input, vc_sentencia: e.currentTarget.innerText }
//                                         setValue(valueEdit);
//                                     }}
//                                 />
//                             )
//                         }
//                     })
//                 }
//                 <InputEdit
//                     idInput={`respuestaId=${value.id}:${(value.respuestasInternas || [{ vc_sentencia: '' }]).length}:${'prev'}`}
//                     key={'last'}
//                     input={{ vc_sentencia: props.voice || '' }}
//                     id_preguntaTexto={value.id}
//                     index={(value.respuestasInternas || [{ vc_sentencia: '' }]).length}
//                     callback={(e) => {
//                         const valueEdit = { ...value }
//                         valueEdit.respuestasInternas = value.respuestasInternas || [];
//                         valueEdit.respuestasInternas.push({ vc_sentencia: e.currentTarget.innerText });
//                         setValue(valueEdit);
//                     }}
//                 />
//                 <ModalUI
//                     open={showMap}
//                     onClose={() => setShowMap(false)}
//                     style={{ boxShadow: '0px 0px 0px transparent', backgroundColor: 'transparent', margin: window.isMobile ? 50 : 100 }}
//                 >
//                     <Paper style={{ padding: 0, borderRadius: 20, overflow: 'hidden', height: '100%', width: '100%', outline: 'none' }}>
//                         <GoogleMaps
//                             points={points}
//                             circles={(props.covid19 || {}).countries || []}
//                         />
//                     </Paper>
//                 </ModalUI>
//                 <div id='bottomField' style={{height: 20, width: '100%'}}></div>
//             </Box>
//         </Box>
//     );
// }

// const actions = { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar, setPacienteActual };
// const mapStateToProps = (state) => {
//     const { preguntas, pacienteActual, diagnosticos, covid19 } = state.Think;
//     return { preguntas, pacienteActual, diagnosticos, covid19 };
// };

// export default connect(mapStateToProps, actions)(TextViewThink);

import React, { Component } from 'react';
import MediaCapturer from 'react-multimedia-capture';
import { Grid, Icon, Button } from 'semantic-ui-react';


class VideoRecord extends Component {
	constructor() {
		super();
		this.state = {
			granted: false,
			rejectedReason: '',
			recording: false,
			paused: false
		};

		this.handleGranted = this.handleGranted.bind(this);
		this.handleDenied = this.handleDenied.bind(this);
		this.handleStart = this.handleStart.bind(this);
		this.handleStop = this.handleStop.bind(this);
		this.handlePause = this.handlePause.bind(this);
		this.handleResume = this.handleResume.bind(this);
		this.setStreamToVideo = this.setStreamToVideo.bind(this);
		this.releaseStreamFromVideo = this.releaseStreamFromVideo.bind(this);
		this.downloadVideo = this.downloadVideo.bind(this);
	}
	handleGranted() {
		this.setState({ granted: true });
		console.log('Permission Granted!');
	}
	handleDenied(err) {
		this.setState({ rejectedReason: err.name });
		console.log('Permission Denied!', err);
	}
	handleStart(stream) {
		this.setState({
			recording: true
		});

		this.setStreamToVideo(stream);
		console.log('Recording Started.');
	}
	handleStop(blob) {
		this.setState({
			recording: false
		});

		this.releaseStreamFromVideo();

		console.log('Recording Stopped.');

		this.downloadVideo(blob);
	}
	handlePause() {
		this.releaseStreamFromVideo();

		this.setState({
			paused: true
		});
	}
	handleResume(stream) {
		this.setStreamToVideo(stream);

		this.setState({
			paused: false
		});
	}
	handleError(err) {
		console.log(err);
	}
	setStreamToVideo(stream) {
		let video = this.refs.app.querySelector('video');
		if(window.URL) {
			video.src = window.URL.createObjectURL(stream);
		}
		else {
			video.src = stream;
		}
	}
	releaseStreamFromVideo() {
		this.refs.app.querySelector('video').src = '';
	}
	downloadVideo(blob) {
        console.log('subiendo video');
		this.props.callback({blob});


		// let url = URL.createObjectURL(blob);
		// let a = document.createElement('a');
		// a.style.display = 'none';
		// a.href = url;
		// a.target = '_blank';
		// document.body.appendChild(a);

		// a.click();
	}
	render() {
		const granted = this.state.granted;
		const rejectedReason = this.state.rejectedReason;
		const recording = this.state.recording;
		const paused = this.state.paused;

		return (
			<div ref="app">
				<MediaCapturer
					mimeType='video/webm'
					constraints={{
                        audio: true,
                        video: true
                    }}
					timeSlice={10}
					onGranted={this.handleGranted}
					onDenied={this.handleDenied}
					onStart={this.handleStart}
					onStop={this.handleStop}
					onPause={this.handlePause}
					onResume={this.handleResume}
					onError={this.handleError}
					render={({ start, stop, pause, resume }) =>
					<Grid centered style={{width: '100%', margin: 0 , padding: 0}}>
                        <Button style={{width: '100%', color: 'gray', backgroundColor:window.colors.black, height: 40, margin: 0, padding: 5, borderRadius: 10, borderBottomLeftRadius:0, borderBottomRightRadius:0}}>{ recording ? 'Grabando...' : paused ? 'Grabacion en pausa...' : 'Grabadora Thin-k'} <Icon name='delete' style={{float: 'right'}} onClick={() => {this.props.callbackClose()}} /></Button>
                        <Grid centered style={{width: '100%', height: (window.screen.availHeight - 111) * 0.7, margin: 0 , padding: 0,backgroundColor: 'black',backgroundImage: 'url(assets/images/iconThin-k.png)', backgroundPosition: 'center', backgroundRepeat: ' no-repeat' }}>
                            <video autoPlay>
                            </video>
                        </Grid>
                        <Button.Group style={{width: '100%', backgroundColor:window.colors.black, height: 40, margin: 0, padding:0, borderRadius: 10, borderTopLeftRadius:0, borderTopRightRadius:0}}>
							{
								recording ? 
								<Button icon='pause' style={{width: '20%', height: 40, color: recording ?window.colors.white: 'gray', backgroundColor: 'transparent'}} onClick={recording ? pause: null}/>
								:
								<Button icon='record' style={{width: '20%', height: 40, color:window.colors.red , backgroundColor: 'transparent'}} onClick={ paused ? resume : start}/>
							}
                            <Button icon='stop' style={{width: '20%', height: 40, color: recording || paused ?window.colors.white : 'gray', backgroundColor: 'transparent'}} onClick={ recording || paused ? stop : null}/>
                            <Button icon='step forward' style={{width: '20%', height: 40, color:  paused ?window.colors.white : 'gray' , backgroundColor: 'transparent'}} onClick={ paused ? resume: null}/>
                            <Button icon='trash' style={{width: '20%', height: 40, color: recording || paused ?window.colors.red : 'gray' , backgroundColor: 'transparent'}} onClick={ recording || paused ? stop : null }/>
                        </Button.Group>
					</Grid>
                    // <p>Granted: {granted.toString()}</p>
                    // <p>Rejected Reason: {rejectedReason}</p>
                    // <p>Recording: {recording.toString()}</p>
                    // <p>Paused: {paused.toString()}</p>
				} />
			</div>
		);
	}
}


export default VideoRecord;

import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    "& thead": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      "& > tr > th": {
        textAlign: "left",
        color: theme.palette.text.secondary,
        padding: theme.spacing(0, 2),
        ...theme.typography.subtitle2,
      },
    },
    "& tbody > tr": {
      boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
      borderRadius: theme.shape.borderRadius,

      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: theme.palette.background.paper,
        marginBottom: "10px",
        "& .fill": {
          flex: "1 1 100%",
        },
        "& .sm-hide": {
          display: "none",
        },
      },

      "&.selected": {
        [theme.breakpoints.down("sm")]: {
          border: "1px solid",
          borderColor: theme.palette.primary.main,
        },
        [theme.breakpoints.up("md")]: {
          "& > td": {
            borderTopColor: theme.palette.primary.main,
            borderBottomColor: theme.palette.primary.main,
          },
          "& > td:first-child": {
            borderColor: theme.palette.primary.main,
          },
          "& > td:last-child": {
            borderColor: theme.palette.primary.main,
          },
        },
      },

      "& > td:first-child": {
        borderLeft: "1px solid transparent",
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      },
      "& > td:last-child": {
        borderRight: "1px solid transparent",
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
      "& > td": {
        borderBottom: "1px solid transparent",
        borderTop: "1px solid transparent",
        whiteSpace: "nowrap",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1, 2),

        "& > *": {
          maxWidth: "100px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          minWidth: "100%",
        },
      },
    },
  },
}));

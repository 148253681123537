import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./useStyles";

const Index = ({ firma }) => {
  const classes = useStyles();

  return (
    <Box className={classes.firmas}>
      <Box className="line" />
      <Typography>{firma?.vc_nombre || 'No Especificado'}</Typography>
      <Typography style={{textTransform: 'capitalize'}}>{firma?.vc_especialidad || 'No Especificado'}</Typography>
      <Typography>
        {`Ced. Prof.: ${firma?.vc_cedulaProfesional || 'No Especificado'} - Reg. SSA: ${firma?.vc_registroSSA || 'No Especificado'}`}
      </Typography>
      <Typography style={{ textAlign: 'center', padding: 5}}>
        {firma?.vc_direccionReceta || "Dirección no especificada"}
      </Typography>
    </Box>
  );
};

export default Index;

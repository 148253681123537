import React, { Component } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import * as Firebase from 'firebase';
import Router from './Router';
import containers from './containers';
import config from './config';

Firebase.initializeApp(config);

const middleWares = [ReduxThunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middleWares))(createStore);

export default class App extends Component {
  render() {
    return (
      <Provider store={createStoreWithMiddleware(containers.reducers)}>
        <Router/>
      </Provider>
    );
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import NotaPrint from "./Nota";

// Material UI
import { styled } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { PrintOutlined } from "@material-ui/icons";
import PrintComponents from "react-print-components";

const NotaItem = styled(Paper)({
  border: 0,
  borderRadius: 5,
  marginBottom: "10px",
  padding: "30px",
  backgroundColor: "white",
});

const useStyles = makeStyles((theme) => ({
  actions: {
    position: "relative",
    top: "-25px",
    right: "-25px",
    marginBottom: "-25px",
    textAlign: "right",
  },
}));

const getNota = (nota, index) => (
  <NotaPrint
    date={moment(nota.dt_registro)}
    diagnosticosPaciente={nota.diagnosticos}
    procedimientosPaciente={nota.procedimientos}
    medicamentosNotas={nota.medicamentos}
    preguntas={nota.preguntas}
    key={index}
    style={{ margin: 0, padding: 0 }}
    firmas={nota.firmas || []}
  />
);

const Index = (props) => {
  const classes = useStyles();
  const id_institucion = window.amplify.store("institution")?.id_institucion;
  const [notas, setNotas] = React.useState([]);
  const {
    pacienteActual: paciente = {},
    preguntas: cat_preguntas = [],
    recetaMode = false,
  } = props;

  React.useEffect(() => {
    const {
      notas: notasArray = [],
      diagnosticosPaciente: diagnosticosArray = [],
      tratamientosPaciente: tratamientosArray = [],
      medicamentosPaciente: medicamentosArray = [],
      procedimientosPaciente: procedimientosArray = [],
      pacienteRespuestas: respuestasArray = [],
    } = paciente;

    let notasAux = notasArray.map((nota) => {
      const medicamentos = tratamientosArray
        .filter((t) => t.id_nota === nota.id)
        .map((t) => ({
          ...t,
          medicamentos: medicamentosArray
            .map((m) => ({
              ...m,
              detalles: m.detalles.filter(
                (d) => d.id_tratamientoOrigen === t.id
              ),
            }))
            .filter((m) => m.detalles.length),
        }))
        .reduce((pv, cv) => [...pv, ...cv.medicamentos], []);

      const diagnosticos = diagnosticosArray
        .filter(
          // (dx) => dx.detalles.filter((d) => d.id_notaActual === nota.id).length
          (dx) => dx.detalles.filter((d) => d.id_notaOrigen === nota.id).length
        )
        .map((dx) => ({
          ...dx,
          detalles: dx.detalles.filter((d) => d.id_notaActual === nota.id),
        }));
      const procedimientos = procedimientosArray
        .filter(
          (px) => px.detalles.filter((d) => d.id_notaActual === nota.id).length
        )
        .map((px) => ({
          ...px,
          detalles: px.detalles.filter((d) => d.id_notaActual === nota.id),
        }));

      const preguntas = respuestasArray
        .filter(
          (r) =>
            r.id_nota === nota.id &&
            cat_preguntas.find((p) => p.id === r.id_pregunta)
        )
        .map((respuesta) => ({
          ...cat_preguntas.find((p) => p.id === respuesta.id_pregunta),
          respuesta,
        }));

      return {
        ...nota,
        diagnosticos,
        procedimientos,
        preguntas,
        medicamentos,
      };
    });

    if (recetaMode) notasAux = notasAux.filter((v) => v.medicamentos.length);

    setNotas(notasAux);
  }, [recetaMode]);

  return (
    <div style={{ float: "center", position: "relative", width: "100%" }}>
      {!notas.length && (
        <Typography variant="h6" gutterBottom align="center">
          El paciente no cuenta con {recetaMode ? "tratamientos" : "expediente"}
        </Typography>
      )}
      {notas.reverse().map((nota, index) => {
        const element = React.cloneElement(getNota(nota, index), {
          paciente,
          recetaMode,
        });
        const notaPrint = React.cloneElement(element, {
          viewMode: true,
          recetaMode,
          actions: nota.id_institucion === id_institucion && (
            <Box className={classes.actions}>
              <PrintComponents
                trigger={
                  <IconButton aria-label="add to favorites">
                    <PrintOutlined />
                  </IconButton>
                }
              >
                {element}
              </PrintComponents>
            </Box>
          ),
        });
        return <NotaItem key={index}>{notaPrint}</NotaItem>;
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { pacienteActual, preguntas } = state.Think;
  return { pacienteActual, preguntas };
};

export default connect(mapStateToProps)(Index);

export const menuInicio = [
{
      id: 'noticias',
      vc_nombre: 'Noticias',
      enable: true,
      sidebar: {
        open: false,
        enable: false,
      },
      opciones: [
          {
              id: 'recientes',
              vc_nombre: 'Recientes',
              acciones: [
                  {
                      id: 'publicar',
                      vc_nombre: 'Publicar',
                      icon: null
                  }
              ]
          },
          {
              id: 'relevantes',
              vc_nombre: 'Relevantes',
              acciones: [
                  {
                      id: 'publicar',
                      vc_nombre: 'Publicar',
                      icon: null
                  }
              ]
          },
          {
              id: 'todas',
              vc_nombre: 'Todas',
              acciones: [
                  {
                      id: 'publicar',
                      vc_nombre: 'Publicar',
                      icon: null
                  }
              ]
          }
      ]
  },
  {
      id: 'gpc',
      vc_nombre: 'Guias de Practica Clinica',
      sidebar: {
        open: true,
        enable: true
      },
      enable: true,
      opciones: [
          {
              id: 'rr',
              vc_nombre: 'Referencia Rapida',
              acciones: [
                  {
                      id: 'salir',
                      vc_nombre: 'Salir de la GPC',
                      icon: null
                  }
              ]
          },
          {
              id: 'er',
              vc_nombre: 'Evidencias y Recomendaciones',
              acciones: [
                  {
                      id: 'salir',
                      vc_nombre: 'Salir de la GPC',
                      icon: null
                  }
              ]
          }
      ]
  },
  {
      id: 'configuracion',
      enable: false,
      vc_nombre: 'Configuración',
      opciones: [
          {
            id: 'usuario',
            vc_nombre: 'Registrar Usuario',
            acciones: [
                {
                    id: 'guardar',
                    vc_nombre: 'Guardar',
                    icon: null
                }
            ]
          },
          {
              id: 'institucion',
              vc_nombre: 'Institución',
              acciones: [
                  {
                      id: 'guardar',
                      vc_nombre: 'Guardar',
                      icon: null
                  }
              ]
          },
          {
              id: 'dependencias',
              vc_nombre: 'Dependencias',
              acciones: [
                  {
                      id: 'nueva',
                      vc_nombre: 'Agregar',
                      icon: null
                  }
              ]
          },
          {
              id: 'areas',
              vc_nombre: 'Areas',
              acciones: [
                  {
                      id: 'nueva',
                      vc_nombre: 'Agregar',
                      icon: null
                  }
              ]
          },
          {
            id: 'modulos',
            vc_nombre: 'Modulos',
            acciones: [
                {
                    id: 'nueva',
                    vc_nombre: 'Agregar',
                    icon: null
                }
            ]
          },
          {
            id: 'camas',
            vc_nombre: 'Camas/Consultorios',
            acciones: [
                {
                    id: 'nueva',
                    vc_nombre: 'Agregar',
                    icon: null
                }
            ]
          },
      ]
  },
  /*         {
    id: 'registro',
    vc_nombre: 'Usuario',
    opciones: [
        {
          id: 'rr',
          vc_nombre: 'Referencia Rapida',
          acciones: [
              {
                  id: 'salir',
                  vc_nombre: 'Salir de la GPC',
                  icon: null
              }
          ]
      },
      {
          id: 'er',
          vc_nombre: 'Evidencias y Recomendaciones',
          acciones: [
              {
                  id: 'salir',
                  vc_nombre: 'Salir de la GPC',
                  icon: null
              }
          ]
      }
    ]
  }, */
];


export const menuConfiguraciones = [
  {
    id: 'usuarios',
    enable: true,
    parentId: 'configuracion',
    vc_nombre: 'Usuarios',
    opciones: [
        {
          id: 'buscar',
          vc_nombre: 'Buscar Usuario',
          acciones: [
              {
                  id: 'buscar',
                  vc_nombre: 'Buscar Usuario',
                  icon: null
              }
          ]
      },
    ]
  },
  {
    id: 'instituciones',
    parentId: 'configuracion',
    vc_nombre: 'Institución',
    enable: true,
    opciones: [
        {
          id: 'rr',
          vc_nombre: 'Referencia Rapida',
          acciones: [
              {
                  id: 'salir',
                  vc_nombre: 'Salir de la GPC',
                  icon: null
              }
          ]
      },
      {
          id: 'er',
          vc_nombre: 'Evidencias y Recomendaciones',
          acciones: [
              {
                  id: 'salir',
                  vc_nombre: 'Salir de la GPC',
                  icon: null
              }
          ]
      }
    ]
  },
  {
    id: 'dependencias',
    parentId: 'configuracion',
    vc_nombre: 'Dependencias',
    enable: true,
    opciones: [
        {
          id: 'rr',
          vc_nombre: 'Referencia Rapida',
          acciones: [
              {
                  id: 'salir',
                  vc_nombre: 'Salir de la GPC',
                  icon: null
              }
          ]
      },
      {
          id: 'er',
          vc_nombre: 'Evidencias y Recomendaciones',
          acciones: [
              {
                  id: 'salir',
                  vc_nombre: 'Salir de la GPC',
                  icon: null
              }
          ]
      }
    ]
  },
  {
    id: 'cuentas',
    parentId: 'configuracion',
    vc_nombre: 'Cuentas',
    enable: true,
    opciones: []
  },
];

import React from "react";

import _ from "lodash";

import { Box, Divider } from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";
import { LeakRemoveTwoTone } from "@material-ui/icons";

const round = (num) => {
  const indexOf = `${num}`.indexOf(".");
  if (indexOf > 0) return Number(`${num}`.slice(0, indexOf + 3));
  return num;
};

export const getTotales = (account) => {
  const { details: products = [], pays = [] } = account;

  const abono = pays.reduce((sum, v) => v.nu_quantity + sum, 0);

  const productsTotal = products.map((v) => {
    const nu_unitValue = Number(`${v.nu_unitValue}`.split(",").join(""));
    let subtotal = v.nu_quantity * nu_unitValue;
    const descuento = (subtotal * (v.nu_discount || 0)) / 100;
    
    subtotal -= descuento

    const iva = round(subtotal * (v.nu_tax > -1 ? v.nu_tax : 0.16));
    const  total = subtotal + iva;
    return {
      ...v,
      subtotal,
      descuento,
      total,
      iva,
    };
  });

  const subtotal = productsTotal.reduce((sum, v) => sum + v.subtotal, 0);
  const iva = productsTotal.reduce((sum, v) => sum + v.iva, 0);
  const total = productsTotal.reduce((sum, v) => sum + v.total, 0);

  return {
    abono: Number(abono.toFixed(2)),
    subTotal: Number(subtotal.toFixed(2)),
    iva: Number(iva.toFixed(2)),
    total: Number(total.toFixed(2)),
  };
};

export default (props) => {
  const { products = [], pays = [], children } = props;
  const style = { padding: 10, textAlign: "right" };

  const { abono, subTotal, iva, total } = getTotales({
    details: products,
    pays,
  });

  return (
    <Box>
      <GridUI container spacing={0}>
        <GridUI item xs={6} style={style}>
          {" "}
          Sub-total:{" "}
        </GridUI>
        <GridUI item xs={6} style={style}>
          {" "}
          {"$ "}
          {subTotal.toFixed(2)}{" "}
        </GridUI>
      </GridUI>
      <GridUI container spacing={0}>
        <GridUI item xs={6} style={style}>
          {" "}
          IVA:{" "}
        </GridUI>
        <GridUI item xs={6} style={style}>
          {" "}
          {"$ "}
          {iva.toFixed(2)}{" "}
        </GridUI>
      </GridUI>
      <GridUI container spacing={0}>
        <GridUI item xs={6} style={style}>
          {" "}
          Total:{" "}
        </GridUI>
        <GridUI item xs={6} style={style}>
          {" "}
          {"$ "}
          {total.toFixed(2)}{" "}
        </GridUI>
      </GridUI>
      <GridUI container spacing={0}>
        <GridUI item xs={6} style={style}>
          {" "}
          Abonado:{" "}
        </GridUI>
        <GridUI item xs={6} style={style}>
          {" "}
          {"$ "}
          {abono.toFixed(2)}{" "}
        </GridUI>
      </GridUI>
      <Divider />
      <GridUI container spacing={0}>
        <Box style={{ padding: 10, width: "100%" }}>{children}</Box>
      </GridUI>
    </Box>
  );
};

import React from "react";
import FieldsetGroup from "./FieldsetGroup";
import TextField from "../TextField";
import TextView from "../TextView";
import IconButton from "@material-ui/core/IconButton";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import SearchBar from "../SearchBar";
import { connect } from "react-redux";
import {
  resetHelperSidebarRender,
  setHelperSidebarRender,
  setState,
} from "../../containers/Think/actions";
import Switch from "@material-ui/core/Switch";
import Switcher from "./Switcher";
import DateSelectPicker from "../DateSelectPicker";
import TimeSelectPicker from "../TimeSelectPicker";
import ExpansionPanel from "../ExpansionPanel";
import { fade, makeStyles } from "@material-ui/core/styles";
import PhoneNumber from "../PhoneNumber";
import FileUploader from "../FileUploader";
import { Box, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import CreateButton from "./CreateButton";
import SearchSelect from "./SearchSelect";
import ActiveFieldButton from "./ActiveFieldButton";
import LocationSearch from "../Maps/LocationSearch";
import PasswordConfirm from "../PasswordConfirm";
let modulo = "backoffice";
let fields = {};
let fieldsLocals = {};
const isMobile = window.isMobile;
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
RegExp.escape = function (string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
};

class ThinkForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tipoInstituciones: [], institucion: null };
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    this.props.setRemoveField &&
      this.props.setRemoveField(this.removeField.bind(this));
  }
  UNSAFE_componentWillMount() {
    this.handleChange.bind(this);
    this.handleValidate.bind(this);
    this.validateRules.bind(this);
    if (this.props.registro) this.setState({ registro: this.props.registro });
    if (this.props.setFunctions) {
      this.props.setFunctions({
        guardar: this.guardar.bind(this),
        getRegistro: this.getRegistro.bind(this),
        setRegistro: (registro) => this.setState({ registro }),
        limpiar: () => this.setState({ registro: {} }),
      });
    }
  }
  componentWillUnmount() {
    if (!this.props.isChildren) {
      fields = {};
      fieldsLocals = {};
      // this.setState({ newRows: {} })
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.registro !== this.props.registro &&
      !(nextProps.registro.length > -1)
    ) {
      this.setState({
        registro: { ...this.props.registro, ...nextProps.registro },
      });
    }
  }
  onSubmit(values, formApi) {
    const { dirty } = formApi.getState();
    if (!dirty) {
    }
  }
  getName = (item, cat) => {
    let display = "";
    cat.fieldsDisplay.map((field, index) => {
      let itemEdit = item;
      field.split(".").map((key, i) => {
        if (itemEdit) itemEdit = itemEdit[key];
      });
      display = `${display}${itemEdit || ""}${
        cat.fieldsDisplaySeparators[index] || ""
      }`;
    });
    return display;
  };
  getNameOption(item, relation) {
    let display = "";
    relation.fieldsDisplay.map((field, index) => {
      display = `${display}${item[field] || ""}${
        relation.fieldsDisplaySeparators[index] || ""
      }`;
    });
    return display;
  }

  getOptions(field) {
    const relation = field.relation || null;
    if (!relation) return [];
    let catalogosOptions = this.props.libraries[relation.vc_nameReducer] || [];
    (relation.rules || []).map((rule) => {
      switch (true) {
        case rule.rule === "==" && rule.id_typeRule === 1:
          catalogosOptions = catalogosOptions.filter(
            (e) => e[rule.field] === rule.value
          );
          break;
        case rule.rule === "==" && rule.id_typeRule === 2:
          catalogosOptions = catalogosOptions.filter(
            (e) => e[rule.field] === rule.value
          );
          break;
      }
    });
    let options = [];
    catalogosOptions.map((item) => {
      options.push({
        value: item[relation.keyTable],
        label: this.getNameOption(item, relation),
      });
    });
    return options;
  }
  handleValidate = (value) => {
    // this.setState({ registro: { ...value } })
    return true;
  };
  getTagsString(tags) {
    let result = "";
    tags.map((tag, index) => {
      result += `${result.length === 0 ? "" : "."}${tag}`;
    });
    return result;
  }

  handleChange(event) {
    const registro = this.state.registro;
    registro[event.target.id.split(".").pop()] = event.target.value;
    this.setState({ registro });
  }
  textTransform(textTransform, text) {
    switch (textTransform) {
      case "uppercase": {
        return text.toUpperCase();
      }
      case "capitalize": {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
      }
      default: {
        return text;
      }
    }
  }
  renderFields(field) {
    let value = this.state.registro
      ? this.state.registro[field.key] || ""
      : field.relation
      ? field.relation.defaultValue
      : "";
    let val = true;
    let regExp = null;
    if (field.hidden) {
      return (
        <input
          disabled={field.readOnly}
          id={field.fieldid}
          key={field.fieldid}
          defaultValue={value}
          style={{
            width: 0,
            height: 0,
            opacity: 0,
            overflow: "hidden",
            margin: 0,
            padding: 0,
            float: "left",
          }}
        />
      );
    }
    switch (field.component) {
      case "textField":
        return (
          <TextField
            hidden={field.hidden}
            key={field.fieldid}
            fieldid={field.fieldid}
            required={!field.nullify}
            field={field.key}
            maxLength={field.maxLength || null}
            texttransform={field.textTransform}
            onChangeCapture={this.handleChange.bind(this)}
            name={field.fieldid}
            fullWidth={window.isMobile || field.fullWidth}
            readOnly={field.readOnly || false}
            value={this.textTransform(field.textTransform, value)}
            label={field.vc_name}
            regExp={field.regExp}
            type={field.type || "text"}
            multiline={!!field.multiline}
          />
        );
      case "textArea":
        return (
          <TextView
            hidden={field.hidden}
            key={field.fieldid}
            fieldid={field.fieldid}
            required={!field.nullify}
            field={field.key}
            maxLength={field.maxLength || null}
            texttransform={field.textTransform}
            onChangeCapture={this.handleChange.bind(this)}
            name={field.fieldid}
            fullWidth={window.isMobile || field.fullWidth}
            readOnly={field.readOnly || false}
            value={this.textTransform(field.textTransform, value)}
            label={field.vc_name}
            regExp={field.regExp}
            type={field.type || "text"}
          />
        );
      case "select":
        const options = this.getOptions(field);
        return (
          <SearchSelect
            key={field.fieldid}
            fieldid={field.fieldid}
            field={field.key}
            required={!field.nullify}
            name={field.fieldid}
            fullWidth={window.isMobile || field.fullWidth}
            readOnly={field.readOnly || false}
            value={value}
            label={field.vc_name}
            options={options}
            fieldConfig={field}
            onChangeCapture={this.handleChange.bind(this)}
          />
        );
      case "switch":
        return (
          <Switcher
            key={field.fieldid}
            fieldid={field.fieldid}
            required={!field.nullify}
            defaultValue={value}
            label={field.vc_name}
            fullwidth={window.isMobile || field.fullWidth}
          />
        );
      case "date":
        return (
          <DateSelectPicker
            fullWidth={window.isMobile || field.fullWidth}
            key={field.fieldid}
            fieldid={field.fieldid}
            required={!field.nullify}
            value={value}
            label={field.vc_name}
          />
        );
      case "time":
        return (
          <TimeSelectPicker
            fullWidth={window.isMobile || field.fullWidth}
            key={field.fieldid}
            fieldid={field.fieldid}
            required={!field.nullify}
            value={value}
            label={field.vc_name}
          />
        );
      case "phone":
        return (
          <PhoneNumber
            key={field.fieldid}
            fieldid={field.fieldid}
            required={!field.nullify}
            fullWidth={window.isMobile || field.fullWidth}
            value={value}
            label={field.vc_name}
            type={field.type || "text"}
            autoComplete="off"
            onChangeCapture={this.handleChange.bind(this)}
          />
        );
      case "e-mail":
        regExp = new RegExp(
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
        return (
          <TextField
            key={field.fieldid}
            fieldid={field.fieldid}
            field={field.key}
            name={field.fieldid}
            fullWidth={window.isMobile || field.fullWidth}
            readOnly={field.readOnly || false}
            value={value}
            required={!field.nullify}
            label={field.vc_name}
            regExp={regExp}
            type={field.type || "text"}
            errormessage={"E-mail no valido"}
          />
        );
      case "passwordConfirm":
        return (
          <PasswordConfirm
            key={field.fieldid}
            fieldid={field.fieldid}
            required={!field.nullify}
            field={field.key}
            name={field.fieldid}
            fullWidth={window.isMobile || field.fullWidth}
            label={field.vc_name}
            regExp={field.regExp}
          />
        );
      case "adress": {
        return (
          <LocationSearch
            hidden={field.hidden}
            key={field.fieldid}
            fieldid={field.fieldid}
            required={!field.nullify}
            field={field.key}
            onChangeCapture={this.handleChange.bind(this)}
            name={field.fieldid}
            fullWidth={window.isMobile || field.fullWidth}
            readOnly={field.readOnly || false}
            label={field.vc_name}
          />
        );
      }
      case "file":
        if (!field.hidden)
          return (
            <FileUploader
              addFile={this.addFile}
              files={[]}
              key={field.fieldid}
            />
          );
    }
  }
  validateRules(rules) {
    if (!rules) return true;
    if (rules.length === 0) return true;
    let pass = false;
    let passAbsolute = true;
    const registro = this.props.registro || this.props.registro;
    rules
      .filter((e) => e.id_typeRule === 1)
      .map((rule, index) => {
        switch (rule.rule) {
          case "==":
            if (registro[rule.field] === rule.value) {
              pass = true;
            } else if (rule.absolute) {
              passAbsolute = false;
            }
            break;
        }
      });
    return pass && passAbsolute;
  }
  getPrincipalRegister() {
    if (this.props.isChildren) return;
  }
  getRegisters(set) {
    if (this.props.registro === {}) return [];
    let rows = this.props.registro[set.catalog.vc_nameObject];
    if (rows) {
      return rows;
    }
    rows = [];
    const catalogoStruct = (this.props.catalogos || []).find(
      (e) => e.vc_nameTable === set.catalog.vc_nameTable
    );
    if (!catalogoStruct) return [];
    const catalogo = this.props.libraries[catalogoStruct.vc_nameReducer];
    if (!catalogo) return [];
    const relationCatalog = this.props.libraries[
      set.catalog.relation.vc_nameReducer
    ];
    const relation = set.catalog.relation;
    const registro = this.props.registro;
    if (relationCatalog) {
      relationCatalog
        .filter((e) => e[relation.referKey] === registro[relation.key])
        .map((item) => {
          const row = catalogo.find(
            (e) => e[relation.dataKey] === item[relation.dataReferKey]
          );
          if (row) rows.push(row);
        });
    } else {
      const row = catalogo.find(
        (e) => e[relation.dataKey] === registro[relation.key]
      );
      if (row) rows.push(row);
    }
    return rows;
  }

  addChildren(tag) {
    const newRows = this.state[tag] || [];
    newRows.push({});
    this.setState({ [tag]: newRows });
  }
  getNameRenderFields(row, cat, tagsText) {
    let display = "";
    const catalogo = cat || this.props.catalogo;
    catalogo.fieldsDisplay.map((field, index) => {
      const fieldid = `${tagsText}${tagsText === "" ? "" : "."}${field}`;
      const docRender = document.getElementById(fieldid);
      const value = docRender && docRender.value;
      display = `${display}${value || ""}${
        value ? catalogo.fieldsDisplaySeparators[index] : ""
      }`;
    });
    return display;
  }

  renderSets(registro) {
    return (this.props.catalogo.fieldsConfig?.sets || [])
      .filter((e) => this.validateRules(e.rules))
      .map((set, index) => {
        const fields = set.fields || [];
        if (set.id_typeSet === 2) {
          const catalogo = (this.props.catalogos || []).find(
            (e) => e.vc_nameTable === set.catalog.vc_nameTable
          );
          // const registros = registro[set.catalog.vc_nameReducer]
          const tag = set.catalog.vc_nameObject;
          const preTags = this.getTagsString(this.props.tags) || "";
          const newRegisters = this.state[tag] || [];
          const saveRegisters = this.getRegisters(set);
          const registros = saveRegisters.concat(newRegisters);
          const menuId = "primary-search-account-menu";
          return (
            <Box key={index} fullwidth={`${true}`}>
              <div key={"header"} style={{ width: "100%", paddingLeft: 20 }}>
                <Typography>
                  {`${catalogo.vc_name} (${registros.length})`}
                  <IconButton
                    edge="end"
                    aria-label="Account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={(e) => {
                      this.addChildren.bind(this)(tag);
                      e.stopPropagation();
                    }}
                    color="inherit"
                  >
                    <PlaylistAdd />
                  </IconButton>
                </Typography>
              </div>
              <div
                key={"content"}
                p={0}
                title={set.vc_name || "Formulario:"}
                style={{ padding: 0 }}
              >
                {registros.map((row, index) => {
                  const tags = [];
                  tags.push(
                    tags.length === 0
                      ? set.catalog.vc_nameObject
                      : `.${set.catalog.vc_nameObject}`
                  );
                  tags.push(index);
                  const tagsText = this.getTagsString(tags);
                  const fieldid = `${preTags}${
                    preTags === "" ? "" : "."
                  }${tagsText}${tagsText === "" ? "" : "."}${"sn_activo"}`;
                  const titleRowValue = this.getName(row, catalogo);
                  const title =
                    titleRowValue !== ""
                      ? titleRowValue
                      : this.getNameRenderFields(row, catalogo, tagsText) ||
                        "(Nuevo)";
                  this.setField({
                    key: "sn_activo",
                    vc_name: "Activo",
                    fieldid,
                  });
                  let removeField = null;
                  return (
                    <ExpansionPanel
                      fullWidth
                      key={index}
                      header={
                        <div
                          key={"header"}
                          style={{ width: "100%", paddingLeft: 20 }}
                        >
                          <Typography>
                            {title}
                            <ActiveFieldButton
                              value={1}
                              fieldid={fieldid}
                              delete={(e) => {
                                if (index - saveRegisters.length >= 0) {
                                  const newRows = this.state[tag] || [];
                                  newRows.splice(
                                    index - saveRegisters.length,
                                    1
                                  );
                                  removeField &&
                                    removeField({
                                      key: "sn_activo",
                                      vc_name: "Activo",
                                      fieldid,
                                    });
                                  this.setState({ [tag]: newRows });
                                }
                                e.stopPropagation();
                              }}
                            />
                          </Typography>
                        </div>
                      }
                      description={
                        <div style={{ width: "100%" }}>
                          <ThinkForm
                            isChildren
                            setRemoveField={(removeFieldChild) => {
                              removeField = removeFieldChild;
                            }}
                            addField={this.addField}
                            tags={[...(this.props.tags || []), ...tags]}
                            registro={row}
                            libraries={this.props.libraries}
                            catalogo={catalogo}
                            catalogos={this.props.catalogos}
                            upperObject={this.props.registro || {}}
                          />
                        </div>
                      }
                    />
                  );
                })}
              </div>
            </Box>
          );
          return (
            <ExpansionPanel
              fullWidth
              defaultExpanded={!set.defaultHidde}
              key={index}
              title={
                <div style={{ width: "100%", padding: 3 }}>
                  <Typography>
                    {`${catalogo.vc_name} (${registros.length})`}
                    <IconButton
                      edge="end"
                      aria-label="Account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={() => {
                        let registro = this.props.registro || {};
                        const tags = this.props.tags || [];
                        let newRegistro = registro;
                        tags.map((tag) => {
                          newRegistro = registro[tag];
                        });
                        newRegistro[set.catalog.vc_nameReducer].push({});
                        this.setState({ modulo, state: { registro } });
                      }}
                      color="inherit"
                    >
                      <PlaylistAdd />
                    </IconButton>
                  </Typography>
                </div>
              }
              description={
                <div
                  p={0}
                  title={set.vc_name || "Formulario:"}
                  style={{ padding: 0 }}
                >
                  {registros.map((row, index) => {
                    const tags = [];
                    tags.push(
                      tags.length === 0
                        ? set.catalog.vc_nameReducer
                        : `.${set.catalog.vc_nameReducer}`
                    );
                    tags.push(index);
                    return (
                      <ExpansionPanel
                        fullWidth
                        key={index}
                        title={this.getName(row, catalogo)}
                        description={
                          <ThinkForm
                            isChildren
                            addField={this.addField}
                            tags={tags}
                            registro={row}
                            libraries={this.props.libraries}
                            catalogo={catalogo}
                            catalogos={this.props.catalogos}
                            upperObject={this.props.registro || {}}
                          />
                        }
                      />
                    );
                  })}
                </div>
              }
            />
          );
        } else if (set.id_typeSet === 3) {
          const catalogo = this.props.catalogos.find(
            (e) => e.vc_nameTable === set.catalog.vc_nameTable
          );
          const registro =
            this.props.registro[set.catalog.vc_nameReducer] ||
            this.getRegisters(set) ||
            {};
          const tags = this.props.tags || [];
          tags.push(
            tags.length === 0
              ? set.catalog.vc_nameObject
              : `.${set.catalog.vc_nameObject}`
          );
          return (
            <ThinkForm
              fullWidth
              key={index}
              isChildren
              tags={tags}
              addField={this.addField}
              registro={registro}
              libraries={this.props.libraries}
              catalogo={catalogo}
              catalogos={this.props.catalogos}
              upperObject={this.props.registro || {}}
            />
          );
        }
        const tags = this.getTagsString(this.props.tags);
        if (!set.vc_name) {
          return (
            <Paper
              key={index}
              fullwidth={`${!!(set.fullWidth || isMobile)}`}
              style={{ padding: 10, paddingTop: 0 }}
            >
              {fields.map((field, index) => {
                const fieldid = `${tags}${tags === "" ? "" : "."}${field.key}`;
                this.setField({ ...field, fieldid });
                return this.renderFields({ ...field, index, fieldid });
              })}
            </Paper>
          );
        }
        if (set.withOutExpand) {
          return (
            <Box
              key={index}
              fullwidth={`${!!(set.fullWidth || isMobile)}`}
              style={{ backgroundColor: "transparent", boxShadow: "none" }}
            >
              <div style={{ width: "100%", paddingLeft: 20 }}>
                <Typography>
                  {`${set.vc_name}`}
                  <IconButton disabled color="inherit" style={{ opacity: 0 }}>
                    <PlaylistAdd />
                  </IconButton>
                </Typography>
              </div>
              <Paper
                fullwidth={`${!!(set.fullWidth || isMobile)}`}
                style={{ padding: 10 }}
              >
                {fields.map((field, index) => {
                  const fieldid = `${tags}${tags === "" ? "" : "."}${
                    field.key
                  }`;
                  this.setField({ ...field, fieldid });

                  return this.renderFields({ ...field, index, fieldid });
                })}
              </Paper>
            </Box>
          );
        }
        return (
          <ExpansionPanel
            defaultExpanded={!set.defaultHidde}
            key={index}
            fullWidth={!!(set.fullWidth || isMobile)}
            title={set.vc_name || "Formulario:"}
            description={
              <div style={{ padding: 10 }}>
                {fields.map((field, index) => {
                  const fieldid = `${tags}${tags === "" ? "" : "."}${
                    field.key
                  }`;
                  this.setField({ ...field, fieldid });

                  return this.renderFields({ ...field, index, fieldid });
                })}
              </div>
            }
          />
        );
      });
  }

  getLocalRegister() {
    let registro = this.props.registro || {};
    const tags = this.props.tags || [];
    let registroLocal;
    tags.map((tag) => {
      const newRegistro = registro[tag];
      registroLocal = newRegistro;
    });
    return registroLocal;
  }
  addField(field) {
    fields = {
      ...fields,
      ...field,
    };
    const valueRemove = Object.values(field);
    if (valueRemove.length > 1) {
      // console.log('fields', fields)
    }
  }
  addFieldLocal(field) {
    fieldsLocals = {
      ...fieldsLocals,
      ...field,
    };
  }
  removeField(fieldActive) {
    let removeFieldsObject = {};
    Object.values(fieldsLocals).map((field, index) => {
      removeFieldsObject = {
        ...removeFieldsObject,
        ...{ [field.fieldid]: { ...field, sn_delete: true } },
      };
    });
    removeFieldsObject = {
      ...removeFieldsObject,
      ...{ [fieldActive.fieldid]: { ...fieldActive, sn_delete: true } },
    };
    this.props.addField && this.props.addField(removeFieldsObject);
  }
  setField(field) {
    if (this.props.isChildren) {
      this.addFieldLocal({ [field.fieldid]: { ...field, sn_delete: false } });
      this.props.addField({ [field.fieldid]: { ...field, sn_delete: false } });
    } else {
      this.addField({ [field.fieldid]: { ...field, sn_delete: false } });
    }
  }
  getRegistro() {
    let register = {};
    Object.keys(fields).map((key, index) => {
      if (!fields[key].sn_delete) {
        const target = document.getElementById(key) || {};
        let value = target.value || null;
        if (key.split(".").pop().startsWith("dt_")) {
          const timeValue = moment(value, "DD-MM-YYYY");
          value = timeValue.isValid() ? timeValue.format("YYYY-MM-DD") : value;
        }
        let registerEdit = register;
        if (key.includes(".")) {
          key.split(".").map((path, index) => {
            if (index + 1 !== key.split(".").length) {
              if (!registerEdit[path]) {
                switch (true) {
                  case key.split(".")[index + 1] > -1:
                    registerEdit[path] = [];
                    break;
                  default:
                    registerEdit[path] = {};
                    break;
                }
              }
              registerEdit = registerEdit[path];
            }
          });
          const keyFinal = key.split(".").pop();
          if (value != null && value != undefined && value != "") {
            registerEdit[keyFinal] = !!(
              keyFinal.startsWith("id") ||
              keyFinal.startsWith("nu_") ||
              keyFinal.startsWith("sn_")
            )
              ? value * 1
              : value;
            // console.log('render field', registerEdit[keyFinal], keyFinal)
          } else if (keyFinal !== "id") {
            registerEdit[keyFinal] = null;
          }
        } else {
          if (value != null && value != undefined && value != "") {
            registerEdit[key] = !!(
              key.startsWith("id") ||
              key.startsWith("nu_") ||
              key.startsWith("sn_")
            )
              ? value * 1
              : value;
          } else if (key !== "id") {
            registerEdit[key] = null;
          }
        }
      }
    });
    // console.log('registros para enviar', register);
    return register;
  }
  guardar() {
    if (this.props.onSubmit) this.props.onSubmit(this.getRegistro());
  }
  render() {
    const primaryKey = this.props.catalogo
      ? this.props.catalogo.primaryKey
      : null;
    const registro = this.props.registro;
    if (!registro || !this.props.catalogo) return <div />;
    const styleScroll = this.props.isChildren
      ? { width: "100%", padding: 0, paddingTop: 10 }
      : {
          padding: 15,
          paddingTop: this.props.withOutHeader ? 10 : 60,
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          overflowY: "scroll",
          WebkitOverflowScrolling: "touch",
        };
    const title = primaryKey
      ? registro[primaryKey]
        ? `${this.props.catalogo.vc_name} (Editar)`
        : `${this.props.catalogo.vc_name} (Nuevo)`
      : registro.id
      ? `${this.props.catalogo.vc_name} (Editar)`
      : `${this.props.catalogo.vc_name} (Nuevo)`;
    return (
      <Paper
        style={{
          height: "100%",
          position: "relative",
          overflow: "hidden",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <div key="sets" style={styleScroll}>
          <FieldsetGroup fullWidth={window.isMobile || this.props.fullWidth}>
            {this.renderSets(registro)}
          </FieldsetGroup>
          {!this.props.isChildren && (
            <Box
              style={{
                display: "flex",
                width: "100%",
                height: 60,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography component="p">
                © Copyright {new Date().getFullYear()} - Thin-k Soluciones Médicas S.A. de C.V. -
                Todos los derechos reservados
              </Typography>
            </Box>
          )}
        </div>
        {this.props.isChildren || this.props.withOutHeader ? null : (
          <SearchBar
            title={title}
            onChange={this.handleChange}
            type="form"
            back={this.props.backButton ? this.props.backButton : null}
            save={
              // this.props.registro.id &&
              <CreateButton
                color="inherit"
                getRegistro={this.getRegistro.bind(this)}
                catalogo={this.props.catalogo}
                saveComplete={
                  this.props.backButton
                    ? this.props.backButton
                    : () => {
                        if (this.props.onSubmit)
                          this.props.onSubmit(this.getRegistro());

                        this.props.setState({
                          modulo,
                          state: {
                            opcion: "listadoRegistrosCatalogos",
                            registro: null,
                          },
                        });
                      }
                }
              />
            }
            guardar={this.guardar.bind(this)}
            qrReader={this.props.qrReader ? this.props.qrReader : null}
          />
        )}
      </Paper>
    );
  }
}
const getFields = (catalogo, catalogos, getFieldsInner) => {
  let libraries = [];
  if (!catalogo) return libraries;
  (catalogo.fieldsConfig?.sets || []).map((set, index) => {
    if (set.fields) {
      set.fields
        .filter((e) => e.relation)
        .map((field) => {
          libraries.push(field.relation.vc_nameReducer);
        });
    } else {
      const referCatalog = catalogos.find(
        (e) => e.vc_nameTable === set.catalog.vc_nameTable
      );
      if (referCatalog) {
        libraries.push(referCatalog.vc_nameReducer);
        libraries = libraries.concat(
          getFieldsInner(referCatalog, catalogos, getFields)
        );
      }
      if (set.catalog.relation.vc_nameReducer)
        libraries.push(set.catalog.relation.vc_nameReducer);
    }
  });
  return libraries;
};
const mapStateToProps = (state, props) => {
  const catalogos = state.Think.catalogos || [];
  let catalogo = null;
  let registro = {};
  switch (props.type) {
    case "form":
      catalogo = props.catalogo || {};
      registro = props.registro || {};
      break;
    default:
      catalogo = state.Think[modulo] ? state.Think[modulo].catalogo : {};
      registro = state.Think[modulo] ? state.Think[modulo].registro : {};
      break;
  }

  if (catalogo) {
    catalogo.registros = state.Think[catalogo.vc_nameReducer] || [];
  }
  let libraries = {};
  getFields(catalogo, catalogos, getFields).map((item) => {
    libraries[item] = state.Think[item] || [];
  });
  return {
    catalogo,
    registro,
    libraries,
    catalogos,
  };
};
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState,
};
export default connect(mapStateToProps, actions)(ThinkForm);

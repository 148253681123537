import http from '../http';


export default {
    edit(body){
        return new Promise((resolve, reject) => {
            const headers = { Authorization: window.amplify.store('token') };

            http.get(`/app/api/agendas/${body.id}/edit`, headers, (err, res) => {
                if(err) reject(res)
                else resolve(res)
            });    
        })
    },
    store(body){
        return new Promise((resolve, reject) => {
            const headers = { Authorization: window.amplify.store('token') };
            
            http.post(`/app/api/agendas`, headers, body, (err, res) => {
                if(err) reject(res)
                else resolve(res)
            }); 
        })
    }
};

import http from "../httpErp";

export default {
  //Proveedores
  general(path, method, body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    console.log(headers)
    switch (method.toLowerCase()) {
      case "get": {
        let qs = "";

        if (body) {
          qs = "?" + Object.keys(body) .reduce( (a, k) => [...a, `${k}=${encodeURIComponent(body[k])}`], [] ) .join("&");
        }

        http.get(`${path}${qs}`, headers, callback);
        break;
      }
      case "post": {
        http.post(path, headers, body, callback);
        break;
      }
      case "put": {
        http.put(`${path}/${body.id}`, headers, body, callback);
        break;
      }
      case "delete": {
        http.delete(`${path}/${body.id}`, headers, body, callback);
        break;
      }
      default: {
        http.delete(`${path}/${body.id}`, headers, body, callback);
        break;
      }
    }
  },
};

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Typography } from "@material-ui/core";
import useStyles from "./useStyles";

const Index = ({ columns, data, onSelect, valueKey, value, hideHeader }) => {
  const classes = useStyles();

  const handleSelect = (row, index) => {
    onSelect(row, index);
  };

  return (
    <table className={classes.table}>
      {!hideHeader && (
        <thead>
          <tr>
            {columns.map((c, i) => (
              <th key={i}>{c.label}</th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {data.map((row, ri) => (
          <tr
            className={classNames({
              selected: value && value === row[valueKey],
            })}
            key={ri}
            onClick={() => handleSelect(row, ri)}
          >
            {columns.map((column, ci) => {
              const { options = {} } = column;
              const {
                typographyProps = {},
                cellProps = {},
                cellRender,
              } = options;

              if (typeof cellRender === "function") {
                return (
                  <td key={ci} {...cellProps}>
                    {cellRender(row[column.name])}
                  </td>
                );
              }
              return (
                <td key={ci} {...cellProps}>
                  <Typography variant="subtitle2" {...typographyProps}>
                    {row[column.name]}
                  </Typography>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Index.defaultProps = {
  value: null,
  valueKey: "id",
  columns: [],
  data: [],
  onSelect: () => {},
  hideHeader: false,
};

Index.propTypes = {
  value: PropTypes.any,
  valueKey: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  onSelect: PropTypes.func,
  hideHeader: PropTypes.bool,
};

export default Index;

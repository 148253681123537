import React from "react";

import {
  Button,
  Input,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Box,
} from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";
import AttachMoney from "@material-ui/icons/AttachMoney";
import PrintTicket from "./PrintTicketCorte";
import * as firebase from "firebase/app";
import moment from "moment";

const REGEXP = /^[0-9\b]+$/;
const FLOATREG = new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$");
const DECIMALREG = /^\d{1,}(\.\d{0,4})?$/;

const methods = [
  { title: "Efectivo", id_method: "efectivo", total: 0 },
  { title: "Cheque", id_method: "cheque", total: 0 },
  { title: "Tarjeta", id_method: "tarjeta", total: 0 },
  { title: "Transferencia", id_method: "transferencia", total: 0 },
  { title: "Vales de Descuento", id_method: "vale", total: 0 },
];
export default (props) => {
  const { open, cashRegister, onClose, onAccept } = props;
  const db = firebase.database().ref();

  const ticketsRef = React.useRef(null);
  const [cut, setCut] = React.useState({});
  const [pays, setPays] = React.useState([]);
  const [cobros, setCobros] = React.useState(methods);
  const [montoInicial, setMontoInicial] = React.useState(0);
  const [monto, setMonto] = React.useState(0);
  const [saldo, setSaldo] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [suma, setSuma] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const { id_institucion } = window.amplify.store("institution") || {};
    const { cut: auxCut = {} } = cashRegister;

    setCut(auxCut);
    setLoading(true);

    const ref = db
      .child(`erp/orders/${id_institucion}`)
      .orderByChild("dt_created")
      .endAt(moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"));

    ref.on("value", (snapshot) => {
      const cuentas = snapshot.val() ? Object.values(snapshot.val()) : [];

      const auxPays = cuentas
        .reduce((arr, { pays = [] }) => [...arr, ...pays], [])
        .filter(
          (p) => p.id_cashRegister == cashRegister.id && p.id_cut == auxCut.id
        );

      const suma = auxPays.reduce((sum, v) => sum + v.nu_quantity, 0);
      const montoInicial = auxCut?.nu_initReceived || 0;

      setSuma(suma);
      setMontoInicial(montoInicial);
      setTotal(suma + montoInicial);

      setPays(auxPays);
      setMonto(suma);
      setSaldo(montoInicial);
      setCobros(
        methods.map((v) => ({
          ...v,
          total: auxPays
            .filter((p) => p.id_method == v.id_method)
            .reduce((sum, v) => sum + v.nu_quantity, 0),
        }))
      );

      setLoading(false);
    });

    return () => ref.off();
  }, []);

  const handleMonto = (e) => {
    let value = e.target.value;
    const match = value.match(DECIMALREG);
    if (value !== "" && !Number(value)) return;

    if (!!match && !!match[1] && match[1].length > 3)
      value = Number(value).toFixed(2);

    if (value > total) value = total.toFixed(2);

    setMonto(value);
    setSaldo((total - value).toFixed(2));
  };

  const handleSaldo = (e) => {
    let value = e.target.value;
    const match = value.match(DECIMALREG);

    if (value !== "" && !Number(value)) return;

    if (!!match && !!match[1] && match[1].length > 3)
      value = Number(value).toFixed(2);

    if (value > total) value = total.toFixed(2);

    setSaldo(value);
    setMonto((total - value).toFixed(2));
  };

  const accept = async () => {
    const ticket = ticketsRef.current;

    await ticket.print();
    await onAccept({ monto: Number(monto), saldo: Number(saldo) });
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"xs"}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Corte de caja: {cashRegister.vc_name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <GridUI container>
            <GridUI
              item
              xs="4"
              style={{ display: "flex", alignItems: "center" }}
            >
              Monto Inicial
            </GridUI>
            <GridUI item xs="8">
              <Input
                disableUnderline
                value={montoInicial}
                startAdornment={
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <AttachMoney />
                  </IconButton>
                }
                disabled
                inputProps={{
                  style: {
                    padding: 8,
                    width: "100%",
                    backgroundColor: "rgba(200,200,200,0.2)",
                    borderRadius: 5,
                    textAlign: "right",
                  },
                }}
                style={{ margin: 0, width: "100%", padding: 8 }}
              />
            </GridUI>
          </GridUI>
          {cobros.map((cobro, index) => (
            <GridUI container key={index}>
              <GridUI
                item
                xs="4"
                style={{ display: "flex", alignItems: "center" }}
              >
                {cobro.title}
              </GridUI>
              <GridUI item xs="8">
                <Input
                  disableUnderline
                  value={cobro.total.toFixed(2)}
                  startAdornment={
                    <IconButton
                      size="small"
                      style={{ marginLeft: 10, marginRight: 10 }}
                    >
                      <AttachMoney />
                    </IconButton>
                  }
                  disabled
                  inputProps={{
                    style: {
                      padding: 8,
                      width: "100%",
                      backgroundColor: "rgba(200,200,200,0.2)",
                      borderRadius: 5,
                      textAlign: "right",
                    },
                  }}
                  style={{ margin: 0, width: "100%", padding: 8 }}
                />
              </GridUI>
            </GridUI>
          ))}
          <GridUI container>
            <GridUI
              item
              xs="4"
              style={{ display: "flex", alignItems: "center" }}
            >
              Total
            </GridUI>
            <GridUI item xs="8">
              <Input
                disableUnderline
                value={suma.toFixed(2)}
                startAdornment={
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <AttachMoney />
                  </IconButton>
                }
                disabled
                inputProps={{
                  style: {
                    padding: 8,
                    width: "100%",
                    backgroundColor: "rgba(200,200,200,0.2)",
                    borderRadius: 5,
                    textAlign: "right",
                  },
                }}
                style={{ margin: 0, width: "100%", padding: 8 }}
              />
            </GridUI>
          </GridUI>
          <GridUI container>
            <GridUI
              item
              xs="4"
              style={{ display: "flex", alignItems: "center" }}
            >
              Monto a Retirar
            </GridUI>
            <GridUI item xs="8">
              <Input
                value={monto}
                onChange={handleMonto}
                autoFocus
                disableUnderline
                startAdornment={
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <AttachMoney />
                  </IconButton>
                }
                inputProps={{
                  style: {
                    padding: 8,
                    width: "100%",
                    backgroundColor: "rgba(200,200,200,0.2)",
                    borderRadius: 5,
                    textAlign: "right",
                  },
                }}
                style={{ margin: 0, width: "100%", padding: 8 }}
              />
            </GridUI>
          </GridUI>
          <GridUI container>
            <GridUI
              item
              xs="4"
              style={{ display: "flex", alignItems: "center" }}
            >
              Saldo de Caja
            </GridUI>
            <GridUI item xs="8">
              <Input
                value={saldo}
                onChange={handleSaldo}
                autoFocus
                disableUnderline
                startAdornment={
                  <IconButton
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <AttachMoney />
                  </IconButton>
                }
                inputProps={{
                  style: {
                    padding: 8,
                    width: "100%",
                    backgroundColor: "rgba(200,200,200,0.2)",
                    borderRadius: 5,
                    textAlign: "right",
                  },
                }}
                style={{ margin: 0, width: "100%", padding: 8 }}
              />
            </GridUI>
          </GridUI>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          style={{ alignSelf: "right" }}
        >
          Cancelar
        </Button>
        <PrintTicket
          print={{
            cashRegister,
            pays,
            monto,
            saldo,
          }}
          ref={ticketsRef}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={accept}
            style={{ alignSelf: "right" }}
            disabled={loading}
          >
            {loading && (
              <Box mr={1}>
                <CircularProgress size={20} color="white" />
              </Box>
            )}
            Corte
          </Button>
        </PrintTicket>
      </DialogActions>
    </Dialog>
  );
};

import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import _ from 'lodash';

// crear componente
class EmailInput extends Component {
    constructor(props) {
        super(props);

        let valid;
        if (props.defaultValue) {
            valid = true;
        } else {
            valid = !props.required;
        }

        this.state = {
            value: props.defaultValue ? props.defaultValue : '',
            valid,
            message: props.required ? `El campo ${props.label} es requerido` : '',
            dirty: false
        };
    }


    // -----------------------------
    // ------ life cycle events ----
    // -----------------------------
    componentDidMount() {
        this.setForm();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.dirty && nextProps.defaultValue) {
            this.setState({ value: nextProps.defaultValue, valid: true });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextState, this.state) || !_.isEqual(nextProps, this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.eq(prevState, this.state)) {
            this.setForm();
        }
    }


    // -----------------------
    // ------ user events ----
    // -----------------------
    onChange(e) {
        // actualizar valor
        this.setState({ value: e.target.value, dirty: true });

        // validar input
        if (this.props.required && !e.target.value) {
            this.setState({
                valid: false,
                message: `El campo ${this.props.label} es requerido`
            });
        } else {
            this.setState({ valid: true, message: '' });
        }
    }

    onBlur(e) {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // actualizar valor
        this.setState({ value: e.target.value, dirty: true });

        if (this.props.required && !e.target.value) {
            this.setState({
                valid: false,
                message: `El campo ${this.props.label} es requerido`
            });
        } else if (e.target.value && !regex.test(e.target.value)) {
            this.setState({
                valid: false,
                message: 'El correo electronico no tiene un formato correcto'
            });
		} else {
            this.setState({ valid: true, message: '' });
        }
    }

    setForm() {
        this.props.setFormData(this.props.name, this.state);
    }

    getValue() {
        return this.state.value;
    }

    dirtInput() {
        this.setState({ dirty: true });
    }

    resetInput() {
        let valid;
        if (this.props.defaultValue) {
            valid = true;
        } else {
            valid = !this.props.required;
        }

        this.setState({
            value: this.props.defaultValue ? this.props.defaultValue : '',
            valid,
            message: this.props.required ? `El campo ${this.props.label} es requerido` : '',
            dirty: false
        });
    }


    // --------------------------
    // ------ render methods ----
    // --------------------------
    render() {
        const invalidInput = this.state.dirty && !this.state.valid;
        let className = '';
        let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
        let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

        if (this.props.className) {
            className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
        } else {
            className = invalidInput ? 'invalid' : 'valid';
        }

        if (invalidInput) {
            finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
            finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
        }

        // render input
        if (this.props.inlineLabel) {
            return (
                <Form.Field 
                onClick={() => this.props.callbackSelectInput()}
                onBlur={this.onBlur.bind(this)}>
                    <Input
                        label={this.props.label}
                        value={this.state.value}
                        name={this.props.name}
                        className={className}
                        onChange={this.onChange.bind(this)}
                        disabled={this.props.readOnly}
                        placeholder={this.props.placeholder}
                        style={finalInputStyle}
                    />
                </Form.Field>
            );
        }

        return (
            <Form.Field 
            onClick={() => this.props.callbackSelectInput()}
            onBlur={this.onBlur.bind(this)}>
                <label style={finalLabelStyle}>
                    { this.props.label }
                </label>
                <input
                    value={this.state.value}
                    name={this.props.name}
                    className={className}
                    onChange={this.onChange.bind(this)}
                    disabled={this.props.readOnly}
                    placeholder={this.props.placeholder}
                    style={finalInputStyle}
                />
            </Form.Field>
        );
    }
}


// estilos
const styles = {
    label: {
        backgroundColor: 'transparent'

    },
    errorLabel: {
        color: '#9F3A38'
    },
    input: {
        backgroundColor: 'transparent'
    },
    errorInput: {
        background: 'rgba(224, 180, 180, 0.01)',
        border: '1px solid #9F3A38',
        color: '#9F3A38'
    }
};


// exportar componente
export default EmailInput;

import React, { Children } from "react";

import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  ListItem,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  }
}));

const Column = ({ cols = 12, children, center, right, header }) => (
  <Grid
    item
    xs={cols}
    style={{
      textAlign: center ? "center" : right ? "right" : "left",
      padding: header ? 8 : 0,
      paddingLeft: 16,
      paddingRight: 16
    }}
  >
    {children}
  </Grid>
);

const ItemProduct = ({ item, editable = false }) => {
  const classes = useStyles();

  let gridProps = {};

  if (!editable) {
    gridProps['style'] = {
      height: 40
    };
  }

  return (
    <React.Fragment>
      <Divider />
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="center"
        {...gridProps}
      >
        <Column cols={2} center>
          {!editable && item.cantidad}

          {editable && (
            <React.Fragment>
              <TextField
                fullWidth
                inputProps={{ min: 1, style: { textAlign: 'right' } }}
                value={item.cantidad}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="delete" className={classes.margin} size="small">
                        <ArrowDownwardIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton aria-label="delete" className={classes.margin} size="small">
                        <ArrowUpwardIcon fontSize="inherit" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </React.Fragment>
          )}
        </Column>
        <Column cols={4}>{item.nombre}</Column>
        <Column cols={3} center>{item.proveedor}</Column>
        <Column cols={1} center>{item.iva}</Column>
        <Column cols={1} center>{item.total}</Column>
        <Column cols={1} center>
          {editable && (
            <IconButton aria-label="delete" className={classes.margin}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          )}
        </Column>
      </Grid>
    </React.Fragment>
  );
};

const Index = ({ data, editable = true }) => {
  return (
    <Grid container direction="column"> 
      <Grid container spacing={0}>
        <Column cols={2} center header>
          Cantidad
        </Column>
        <Column cols={4} center header>
          Nombre
        </Column>
        <Column cols={3} center header>
          Proveedor
        </Column>
        <Column cols={1} center header>
          Iva
        </Column>
        <Column cols={1} center header>
          Importe
        </Column>
        <Column cols={1} center header />
      </Grid>

      {data.map((item, index) => <ItemProduct item={item} editable={editable} />)}
    </Grid>
  );
};

export default Index;

import notifications from "../../../../../utils/notifications";
import internalAPI from "../../../../../utils/internalAPI";
import moment from "moment";
import eventManager from "../../../../../utils/eventManager";
import { START_VIEW_TRANSITION } from "../../../../../constants";
import {
  getLastDetalle,
  getIndexLastDetalle,
} from "../../../../../utils/repository";
const guardarNota = (pacienteActual) => {
  try {
    let respuestas = [];
    const preguntas = [
      ...pacienteActual.preguntasTexto,
      ...pacienteActual.preguntasNumericas,
    ];
    const componentesRespuestas = [
      "vc_respuesta",
      "id_respuesta",
      "nu_respuesta",
      "dt_respuesta",
      "tm_respuesta",
      "cve_respuesta",
    ];
    console.log("testing   ------->");
    const {
      medicamentosPaciente = [],
      diagnosticosPaciente = [],
      procedimientosPaciente = [],
    } = pacienteActual;
    preguntas.map((item, index) => {
      if (
        item.respuesta != undefined ||
        (item.respuestasInternas || []).length
      ) {
        let respuestaValor = {
          id_pregunta: item.id,
        };
        switch (item.id_tipoPregunta) {
          case 1:
            respuestaValor.id_respuesta = item.respuesta.id_respuesta;
            break;
          case 2:
            respuestaValor.id_respuesta = item.respuesta.id_respuesta;
            break;
          case 3:
            respuestaValor.nu_respuesta = item.respuesta.nu_respuesta;
            break;
          case 4:
            if (item.respuestasInternas) {
              let vc_respuesta = "";
              item.respuestasInternas.map((input) => {
                vc_respuesta = vc_respuesta + input.vc_sentencia;
                if (input.id_pregunta) {
                  let respuestaInterna = {
                    ...input,
                    id_pregunta: input.id_pregunta,
                    dt_registro: moment().format("YYYY-MM-DD HH:mm:ss"),
                    id_institucion: window.amplify.store("institution")
                      .id_institucion,
                    id_dependencia: window.amplify.store("dependencia")
                      ? window.amplify.store("dependencia").id_dependencia
                      : 1,
                    id_usuario: window.amplify.store("user_id"),
                    id_paciente: pacienteActual.detalle.id,
                    id_atencion: pacienteActual.atencionActual
                      ? pacienteActual.atencionActual.id
                      : 1,
                  };
                  componentesRespuestas.map((component) => {
                    const componentRespuesta = input[component];
                    if (componentRespuesta) {
                      respuestaInterna[component] = componentRespuesta;
                    }
                  });
                  respuestas.push(respuestaInterna);
                }
              });
              respuestaValor.vc_respuesta = vc_respuesta;
            } else {
              respuestaValor.vc_respuesta = item.respuesta.vc_respuesta || "";
            }
            break;
          case 5:
            respuestaValor.dt_respuesta = item.respuesta.dt_respuesta;
            break;
          case 6:
            respuestaValor.tm_respuesta = item.respuesta.tm_respuesta;
            break;
        }

        if (respuestaValor != null) {
          respuestaValor.dt_registro = moment().format("YYYY-MM-DD HH:mm:ss");
          respuestaValor.id_institucion = window.amplify.store(
            "institution"
          ).id_institucion;
          respuestaValor.id_dependencia = window.amplify.store("dependencia")
            ? window.amplify.store("dependencia").id_dependencia
            : 1;
          respuestaValor.id_usuario = window.amplify.store("user_id");
          respuestaValor.id_paciente = pacienteActual.detalle.id;
          respuestaValor.id_atencion = pacienteActual.atencionActual
            ? pacienteActual.atencionActual.id
            : 1;
          respuestas.push(respuestaValor);
        }
      }
    });

    const diagnosticos = [];
    console.log("filter dx", diagnosticosPaciente);

    diagnosticosPaciente
      .filter((e, index) => getLastDetalle(e.detalles).id == null)
      .map((dx, index) => {
        const lastDetalle = getLastDetalle(dx.detalles);
        const dxEdit = {
          dt_inicio: moment(lastDetalle.dt_inicio).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          dt_registro: moment(lastDetalle.dt_registro).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          dt_vigencia: moment(lastDetalle.dt_vigencia).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          id_diagnostico: lastDetalle.id_diagnostico,
          id_estatusDiagnostico: lastDetalle.id_estatusDiagnostico,
          id_usuario: window.amplify.store("user_id"),
          id_paciente: pacienteActual.detalle.id,
          vc_observaciones: lastDetalle.vc_observaciones,
        };
        if (dx.id == null) {
          dxEdit.id_notaDiagnostico = null;
          dxEdit.id_notaOrigen = null;
          dxEdit.estatus = 2;
          dxEdit.id_conciliacion = 1;
          dxEdit.sn_principal = lastDetalle.sn_principal;
          dxEdit.sn_subsecuente = lastDetalle.sn_subsecuente;
          diagnosticos.push(dxEdit);
        } else if (
          lastDetalle.id == null &&
          new Date(lastDetalle.dt_vigencia) > new Date()
        ) {
          dxEdit.id_notaDiagnostico = dx.id;
          dxEdit.id_notaDiagnosticoDetalle = dx.id;
          dxEdit.id_notaOrigen = dx.id_nota;
          dxEdit.estatus = 1;
          dxEdit.id_conciliacion = 1;
          dxEdit.sn_principal = lastDetalle.sn_principal;
          dxEdit.sn_subsecuente = lastDetalle.sn_subsecuente;
          diagnosticos.push(dxEdit);
        } else if (
          lastDetalle.id == null &&
          new Date(lastDetalle.dt_vigencia) <= new Date()
        ) {
          dxEdit.id_notaDiagnostico = dx.id;
          dxEdit.id_notaOrigen = dx.id_nota;
          dxEdit.estatus = 3;
          dxEdit.id_conciliacion = 1;
          dxEdit.sn_principal = lastDetalle.sn_principal;
          dxEdit.sn_subsecuente = lastDetalle.sn_subsecuente;
          diagnosticos.push(dxEdit);
        }
      });

    const procedimientos = [];
    console.log("filter proc", procedimientosPaciente);
    procedimientosPaciente
      .filter((e, index) => e.detalles[e.detalles.length - 1].id == null)
      .map((procedimiento, index) => {
        const lastDetalle = getLastDetalle(procedimiento.detalles);
        const procedimientoEdit = {
          dt_inicio: moment(lastDetalle.dt_inicio).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          dt_convalecencia: moment(lastDetalle.dt_convalecencia).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          dt_registro: moment(lastDetalle.dt_registro).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          dt_vigencia: moment(lastDetalle.dt_vigencia).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          id_um_convalecencia: lastDetalle.id_um_convalecencia || null,
          nu_convalecencia: lastDetalle.nu_convalecencia || null,
          id_diagnostico: lastDetalle.id_diagnostico || null,
          id_procedimiento: lastDetalle.id_procedimiento,
          id_estatusProcedimiento: lastDetalle.id_estatusProcedimiento,
          id_usuario: window.amplify.store("user_id"),
          id_paciente: pacienteActual.detalle.id,
          vc_observaciones: lastDetalle.vc_observaciones,
        };
        if (procedimiento.id == null) {
          procedimientoEdit.id_notaProcedimiento = null;
          procedimientoEdit.id_notaOrigen = null;
          procedimientoEdit.estatus = 2;
          procedimientoEdit.id_conciliacion = 1;
          procedimientoEdit.sn_principal = lastDetalle.sn_principal;
          procedimientoEdit.sn_subsecuente = lastDetalle.sn_subsecuente;
          procedimientos.push(procedimientoEdit);
        } else if (
          lastDetalle.id == null &&
          new Date(lastDetalle.dt_vigencia) > new Date()
        ) {
          procedimientoEdit.id_notaProcedimiento = procedimiento.id;
          procedimientoEdit.id_notaOrigen = procedimiento.id_nota;
          procedimientoEdit.estatus = 1;
          procedimientoEdit.id_conciliacion = 1;
          procedimientoEdit.sn_principal = lastDetalle.sn_principal;
          procedimientoEdit.sn_subsecuente = lastDetalle.sn_subsecuente;
          procedimientos.push(procedimientoEdit);
        } else if (
          lastDetalle.id == null &&
          new Date(lastDetalle.dt_vigencia) <= new Date()
        ) {
          procedimientoEdit.id_notaProcedimiento = procedimiento.id;
          procedimientoEdit.id_notaOrigen = procedimiento.id_nota;
          procedimientoEdit.estatus = 3;
          procedimientoEdit.id_conciliacion = 1;
          procedimientoEdit.sn_principal = lastDetalle.sn_principal;
          procedimientoEdit.sn_subsecuente = lastDetalle.sn_subsecuente;
          procedimientos.push(procedimientoEdit);
        }
      });

    const now = moment();
    const medicamentos = medicamentosPaciente.map((medicamento) => {
      const detalles = medicamento.detalles.map((v) => ({
        ...v,
        dt_registro: moment(v.dt_registro).format("YYYY-MM-DD HH:mm:ss"),
        dt_inicio: moment(v.dt_inicio).format("YYYY-MM-DD HH:mm:ss"),
        dt_vigencia: moment(v.dt_vigencia).format("YYYY-MM-DD HH:mm:ss"),
      }));

      const lastIndex = getIndexLastDetalle(
        medicamento.detalles,
        "dt_vigencia"
      );

      const lastDetalle = medicamento.detalles[lastIndex];

      const medicamentoEdit = {
        dt_registro: moment(lastDetalle.dt_registro).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        id_medicamento: medicamento.id_medicamento,
        vc_medicamento: medicamento.vc_medicamento,
        id_um_dosis: lastDetalle.id_um_dosis,
        detalles,
      };

      const vigencia = moment(lastDetalle.dt_vigencia);
      if (!medicamento.id) {
        medicamentoEdit.estatus = 2;
      } else if (vigencia > now) {
        medicamentoEdit.id_tratamientoMedicamento = medicamento.id;
        medicamentoEdit.id_tratamientoOrigen = medicamento.id_tratamiento;
        medicamentoEdit.estatus = 1;
      } else if (vigencia <= now) {
        medicamentoEdit.id_tratamientoMedicamento = medicamento.id;
        medicamentoEdit.id_tratamientoOrigen = medicamento.id_tratamiento;
        medicamentoEdit.estatus = 3;
      }

      return medicamentoEdit;
    }) 
    // .filter(m => m.estatus !== 1);
    //  ============ VALIDAR ============

    const tratamiento = {
      //   ...pacienteActual.tratamientoActual,
      id_institucion: window.amplify.store("institution").id_institucion,
      id_dependencia: window.amplify.store("dependencia")
        ? window.amplify.store("dependencia").id
        : 1,
      id_area: window.amplify.store("area")
        ? window.amplify.store("area").id
        : 1,
      id_modulo: window.amplify.store("modulo")
        ? window.amplify.store("modulo").id
        : 1,
      id_usuario: window.amplify.store("user_id"),
      id_paciente: pacienteActual.detalle.id,
      id_atencion: pacienteActual.atencionActual
        ? pacienteActual.atencionActual.id
        : 1,
      medicamentos,
    };

    if (respuestas.length > 0 && diagnosticos.length > 0) {
      const nota = {
        id_institucion: window.amplify.store("institution").id_institucion,
        id_dependencia: window.amplify.store("dependencia")
          ? window.amplify.store("dependencia").id
          : 1,
        id_area: window.amplify.store("area")
          ? window.amplify.store("area").id
          : 1,
        id_modulo: window.amplify.store("modulo")
          ? window.amplify.store("modulo").id
          : 1,
        id_usuario: window.amplify.store("user_id"),
        id_paciente: pacienteActual.detalle.id,
        id_atencion: pacienteActual.atencionActual
          ? pacienteActual.atencionActual.id
          : 1,
        id_tipoNota: pacienteActual.atencionActual
          ? pacienteActual.atencionActual.id_tipoNota
          : 4,
        respuestas,
        diagnosticos,
        procedimientos,
      };

      if (tratamiento.medicamentos.length > 0) {
        nota.tratamiento = tratamiento;
      }

      if (
        pacienteActual.tratamientosNota &&
        pacienteActual.tratamientosNota.length
      ) {
        nota.tratamientosNota = pacienteActual.tratamientosNota;
      }

      if (
        pacienteActual.solicitudActual &&
        (pacienteActual.solicitudActual.id != null ||
          pacienteActual.solicitudActual.medicamentos ||
          pacienteActual.solicitudActual.vc_observaciones)
      ) {
        nota.solicitudes = pacienteActual.solicitudActual;
      }

      //   if (
      //     pacienteActual.tratamientoActual &&
      //     (pacienteActual.tratamientoActual.id != null ||
      //       pacienteActual.tratamientoActual.medicamentos ||
      //       pacienteActual.tratamientoActual.vc_observaciones)
      //   ) {
      //     nota.tratamiento = pacienteActual.tratamientoActual;
      //   }

      internalAPI.guardarNotas(nota, (err, response) => {
        if (err) {
          notifications.error(`Error: ${err.error}`);
        } else {
          window.history.back()
          // eventManager.emit(START_VIEW_TRANSITION, `#/admin/agendas`);
        }
      });
    } else {
      notifications.error(
        "debes agregar o editar al menos un diagnostico y algun campo de registro."
      );
    }
  } catch (error) {
    notifications.error("error" + error);
  }
};

export default guardarNota;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Loader, Dimmer, Button, Card, Image } from 'semantic-ui-react';
import { SidebarForm } from '../../../../components';
import { connect } from 'react-redux';
import notifications from '../../../../utils/notifications';
import internalAPI from '../../../../utils/internalAPI';
import { setHelperSidebarRender, resetHelperSidebarRender } from '../../actions';
import VincularUsuarios from './vincularUsuarios/index';

const { Row } = Grid;

const RENDER_HELPBAR_OPTIONS = {
  BUG: 'Buscar usuarios globales',
  MDUG: 'Mostrar detalle de busqueda global',
  MDU: 'Mostrar detalle del usuario',
  CUV: 'Captura de usuario a vincular',
};

class ListaUsuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userList: [],
      userListSearch: [],
      texto: '',
      loading: false,
      hoverIndex: null,
      indexSelected: null,
      selectedRow: null,
      loadingForm: false,
      optionsSearch: [],
      selectedSearch: null,
      renderOptionsHelperBar: RENDER_HELPBAR_OPTIONS.BUG,
      vincularUsuario: false,
    };
  }

  componentDidMount() {
    this.getUsuarios();
    this.updateHelperSidebar();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchText !== this.props.searchText) {
      this.buscarUsuario();
    }
  }

  componentWillUnmount() {
    this.props.resetHelperSidebarRender();
  }

  ////////////////////////////////////
  //////////// METHODS ///////////////
  ////////////////////////////////////
  onLinkUp() {
    if (!this.state.vincularUsuario) {
      this.setState({ vincularUsuario: true });
    } else {
      const body = this.VincularUsuario.getData();

      internalAPI.vincularUsuario(body, (error, response) => {
        this.setState({ loadingForm: false });

        if (error) {
          notifications.error(error.error);
        } else {
          notifications.success(response.message);

          this.setState({
            vincularUsuario: false,
            selectedSearch: null,
            optionsSearch: [],
            renderOptionsHelperBar: RENDER_HELPBAR_OPTIONS.BUG
          }, this.updateHelperSidebar());
          this.getUsuarios();
        }
      });

      this.setState({
        vincularUsuario: false,
        selectedSearch: null,
        optionsSearch: [],
        renderOptionsHelperBar: RENDER_HELPBAR_OPTIONS.BUG
      }, this.updateHelperSidebar());
    }
  }

  onCloseCardSearch() {
    this.setState({ selectedSearch: null, renderOptionsHelperBar: RENDER_HELPBAR_OPTIONS.BUG, vincularUsuario: false }, () => {
      this.SidebarForm.reset();
    });
  }

  getUsuarios() {
    this.setState({ loading: true });
    internalAPI.getUsuarios((error, response) => {
      this.setState({ loading: false });

      if (error) {
        notifications.error(error.error);
      } else {
        this.setState({ userList: response.usuarios });
      }
    });
  }

  getFields() {
    const options = this.state.optionsSearch.map((usuario) => ({
      label: `${usuario.vc_nombre} ${usuario.vc_apellidoPaterno} ${usuario.vc_apellidoMaterno}`,
      value: `${usuario.id}`,
    }));

    return [
      {
        name: 'texto',
        label: 'Busqueda de usuarios globales',
        placeholder: 'Ingresa',
        options,
        required: true,
        onChange: (value) => {
          if (value && value.length >= 3) {
            const body = {
              texto: value,
              id_institucion: window.amplify.store('institution').id_institucion,
            };

            this.setState({ loadingForm: true });
            internalAPI.searchUsuarios(body, (error, response) => {
              this.setState({ loadingForm: false });

              if (error) {
                notifications.error(error.error);
              } else {
                this.setState({ optionsSearch: response });
              }
            });
          } else {
            this.setState({ optionsSearch: [] });
          }
        }
      },
    ];
  }

  updateHelperSidebar() {
    this.props.setHelperSidebarRender(this.renderHelperSidebar.bind(this));
  }

  buscarUsuario() {
    let userListSearch = [];

    if (this.props.searchText) {
      userListSearch = this.state.userList.filter((usuario) => {
        const nombreCompletoEmail = `${usuario.detalle.vc_nombre} ${usuario.detalle.vc_apellidoPaterno} ${usuario.detalle.vc_apellidoMaterno} ${usuario.detalle.vc_email}`;
        const encontrado = nombreCompletoEmail.includes(new RegExp(this.props.searchText, 'i'));

        if (encontrado >= 0) {
          return true;
        }

        return false;
      });
    }

    this.setState({
      userListSearch
    });
  }

  /////////////////////////////////////
  ///////// RENDERS METHODS ///////////
  /////////////////////////////////////
  renderButtonLinkUp() {
    const foundIntitution = this.state.selectedSearch.instituciones.filter((institucion) => institucion.id === window.amplify.store('institution').id_institucion);

    if (!foundIntitution.length) {
      return (
        <Button onClick={this.onLinkUp.bind(this)} style={{ backgroundColor: 'white', width: 'calc(100% - 6px)', margin: 3, marginBottom: 0, boxShadow: '1px 1px 1px lightGray' }}>
          Vincular
        </Button>
      );
    }

    return null;
  }

  renderHelperSidebar() {
    const { renderOptionsHelperBar, selectedSearch, selectedRow } = this.state;
    let viewHelperBar = null;

    switch (renderOptionsHelperBar) {
      case RENDER_HELPBAR_OPTIONS.MDUG:
        viewHelperBar = (
          <Grid centered style={{ margin: 0, padding: 0, backgroundColor:window.colors.black }}>
            <div style={{ width: '100%', height: 74, color:window.colors.white, backgroundColor:window.colors.menu, border: 0, padding: 30, textAlign: 'center' }}>
              Usuario
            </div>

            <div style={{ height: window.screen.availHeight - 74, width: '100%', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', padding: 0, margin: 0 }}>
              <Grid centered style={{ width: '100%', padding: 3, paddingBottom: 0, margin: 0 }}>
                <Card centered style={{ width: '100%', padding: 0, margin: 0 }}>
                  <Image size='small' centered src={selectedSearch.picture || selectedSearch.id_sexo === 1 ? 'assets/images/hombre.png' : 'assets/images/mujer.png'} />
                  <Card.Content>
                    <Card.Header>
                      {selectedSearch.vc_nombre}
                    </Card.Header>
                    <Card.Header>
                      {selectedSearch.vc_apellidoPaterno}
                    </Card.Header>
                    <Card.Header>
                      {selectedSearch.vc_apellidoMaterno}
                    </Card.Header>
                    {/* <Card.Description>
                      Sexo: {selectedSearch.id_sexo === 1 ? 'Masculino' : 'Femenino'}
                    </Card.Description> */}
                    <Card.Description>
                      email: {selectedSearch.vc_email}
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid>
              <Row style={{ margin: 0, padding: 0, paddingBottom: 0 }}>
                { this.renderButtonLinkUp() }
                <Button onClick={this.onCloseCardSearch.bind(this)} style={{ backgroundColor: 'white', width: 'calc(100% - 6px)', margin: 3, marginBottom: 0, boxShadow: '1px 1px 1px lightGray' }}>Atras</Button>
              </Row>
              {
                /* window.amplify.store('roles').find(e => e.id_rol === 5) ?
                <Row style={{ margin: 0, padding: 0, paddingBottom: 0 }}>
                  <Button onClick={this.onLinkUp.bind(this)} style={{ backgroundColor: 'white', width: 'calc(100% - 6px)', margin: 3, marginBottom: 0, boxShadow: '1px 1px 1px lightGray' }}>Vincular</Button>
                  <Button onClick={() => this.setState({ estudiosSelectorModal: true })} style={{ backgroundColor: 'white', width: 'calc(100% - 6px)', margin: 3, marginBottom: 0, boxShadow: '1px 1px 1px lightGray' }}>Estudios</Button>
                </Row>
                : '' */
              }
              </div>
            </Grid>
        );
        break;
      case RENDER_HELPBAR_OPTIONS.MDU:
        viewHelperBar = (
          <Grid centered style={{ margin: 0, padding: 0, backgroundColor:window.colors.black }}>
            <div style={{ width: '100%', height: 74, color:window.colors.white, backgroundColor:window.colors.menu, border: 0, padding: 30, textAlign: 'center' }}>
              Usuario
            </div>

            <div style={{ height: window.screen.availHeight - 74, width: '100%', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', padding: 0, margin: 0 }}>
              <Grid centered style={{ width: '100%', padding: 3, paddingBottom: 0, margin: 0 }}>
                <Card centered style={{ width: '100%', padding: 0, margin: 0 }}>
                  <Image size='small' centered src={selectedRow.detalle.picture || selectedRow.detalle.id_sexo === 1 ? 'assets/images/hombre.png' : 'assets/images/mujer.png'} />
                  <Card.Content>
                    <Card.Header>
                      {selectedRow.detalle.vc_nombre}
                    </Card.Header>
                    <Card.Header>
                      {selectedRow.detalle.vc_apellidoPaterno}
                    </Card.Header>
                    <Card.Header>
                      {selectedRow.detalle.vc_apellidoMaterno}
                    </Card.Header>
                    <Card.Description>
                      Sexo: {selectedRow.detalle.id_sexo === 1 ? 'Masculino' : 'Femenino'}
                    </Card.Description>
                    <Card.Description>
                      email: {selectedRow.detalle.vc_email}
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid>
              {
                window.amplify.store('roles').find(e => e.id_rol === 5) ?
                <Row style={{ margin: 0, padding: 0, paddingBottom: 0 }}>
                  <Button onClick={this.onLinkUp.bind(this)} style={{ backgroundColor: 'white', width: 'calc(100% - 6px)', margin: 3, marginBottom: 0, boxShadow: '1px 1px 1px lightGray' }}>Vincular</Button>
                  <Button onClick={() => this.setState({ estudiosSelectorModal: true })} style={{ backgroundColor: 'white', width: 'calc(100% - 6px)', margin: 3, marginBottom: 0, boxShadow: '1px 1px 1px lightGray' }}>Estudios</Button>
                </Row>
                : ''
              }
              </div>
            </Grid>
        );
        break;
      default:
        break;
    }

    return viewHelperBar;
  }

  renderUsersList() {
    const { userList, userListSearch } = this.state;
    const list = this.props.searchText ? userListSearch : userList || [];

    return list.map((user, index) => {
      const { vc_nombre, vc_apellidoPaterno, vc_apellidoMaterno, vc_email, id_sexo } = user.detalle;
      const sexo = id_sexo === 1 ? 'Sexo: Masculino' : 'Sexo: Femenino';
      const opacity = this.state.hoverIndex === index ? 1 : 0.5;
      const radius = this.state.hoverIndex === index ? 10 : 5;
      const boxShadow = index === this.state.indexSelected ? `0px 0px ${radius}px rgba(0, 38, 153, 1)` : `1px 0px 10px rgba(0, 0,0, ${opacity})`;

      return (
        <Segment
          key={index}
          style={{ opacity: this.state.indexSelected === index ? 0.8 : 1, boxShadow, padding: 0, cursor: 'pointer' }}
          onClick={() => {
            if (this.state.indexSelected === index) {
              this.setState({
                selectedRow: null,
                indexSelected: null,
                renderOptionsHelperBar: RENDER_HELPBAR_OPTIONS.BUG
              });
            } else {
              this.setState({
                selectedRow: user,
                indexSelected: index,
                renderOptionsHelperBar: RENDER_HELPBAR_OPTIONS.MDU
              }, () => {
                this.updateHelperSidebar();
              });
            }
          }}
        >
          <Grid columns='equal'>
            <Grid.Column>
              <h5 style={{ width: '100%', color:window.colors.itemSelected, margin: 10, marginBottom: 3 }}>
                {`${vc_nombre} ${vc_apellidoPaterno} ${vc_apellidoMaterno}`}
              </h5>

              <p style={{ width: '100%', color: 'gray', margin: 10, marginTop: 3 }}>
                {`${sexo} email: ${vc_email}`}
              </p>
            </Grid.Column>

            <Grid.Column width={1}>
              <Button
                floated='right'
                icon='ellipsis vertical'
                style={{ height: '100%', backgroundColor: 'transparent' }}
              />
            </Grid.Column>
          </Grid>
        </Segment>
      );
    });
  }

  renderSiderForm() {
    if (this.state.renderOptionsHelperBar === RENDER_HELPBAR_OPTIONS.BUG) {
      return (
        <SidebarForm
          ref={(ref) => {
            if (ref && ref.getWrappedInstance) {
              this.SidebarForm = ref.getWrappedInstance();
            }
          }}
          hideFields
          loading={this.state.loadingForm}
          style={{ paddingRight: 10, paddingLeft: 10, marginTop: 20 }}
          fields={this.getFields()}
          onSubmit={(value) => {
            const selectedSearch = this.state.optionsSearch.filter((option) => option.id === parseInt(value.texto.value, 10))[0];
            if (selectedSearch) {
              this.setState({ selectedSearch, renderOptionsHelperBar: RENDER_HELPBAR_OPTIONS.MDUG }, () => { this.updateHelperSidebar(); });
            }
          }}
        />
      );
    }

    return null;
  }


  renderContent() {
    if (this.state.vincularUsuario) {
      return (
        <div style={styles.box}>
          <div style={{ marginTop: 30 }}>
            <VincularUsuarios
              ref={(ref) => {
                this.VincularUsuario = ref;
              }}
              selectedUser={this.state.selectedSearch}
              activaCapturaUsuario={() => {
                this.setState({ renderOptionsHelperBar: RENDER_HELPBAR_OPTIONS.MDUG }, () => this.updateHelperSidebar());
              }}
              onCancel={this.onCloseCardSearch.bind(this)}
            />
          </div>
        </div>
      );
    }

    return (
      <div style={styles.box}>
        <div style={{ marginTop: 30 }}>
          {this.renderUsersList()}
        </div>

        {this.renderSiderForm()}
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ width: '100%', height: '100%', padding: '2%', margin: 40 }}>
          <Segment style={{ height: 100 }}>
            <Dimmer active inverted style={{ height: 100 }}>
              <Loader content="Cargando usuarios" />
            </Dimmer>
          </Segment>
        </div>
      );
    }

    return (
      <div style={styles.container}>
        <div style={styles.subContainer}>
          { this.renderContent() }
        </div>
      </div>
    );
  }
}


const styles = {
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'hidden',
    padding: '2%',
    margin: 0,
  },
  subContainer: {
    width: '100%',
    height: '100%',
    margin: 0,
    background: 'transparent',
  },
  box: {
    width: '100%',
    height: 'calc(100% - 30px)',
    padding: 10,
    overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
    backgroundColor: 'transparent',
    textAlign: 'center',
    color: 'white',
  },
};


ListaUsuarios.propTypes = {
  setHelperSidebarRender: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  searchText: PropTypes.string,
};


export default connect(null, { setHelperSidebarRender, resetHelperSidebarRender })(ListaUsuarios);

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Typography } from "@material-ui/core";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { get } from "lodash";

const Index = ({ columns, data, onSelect, valueKey, value, hideHeader }) => {
  const handleSelect = (row, index) => {
    onSelect(row, index);
  };

  return (
    <TableContainer>
      <Table>
        {!hideHeader && (
          <TableHead>
            <TableRow>
              {columns.map((c, i) => {
                const { headerCellProps = {} } = c.options || {};
                return (
                  <TableCell component="th" key={i} {...headerCellProps}>
                    {c.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.map((row, ri) => (
            <TableRow
              className={classNames({
                selected: value && value === row[valueKey],
              })}
              key={ri}
              onClick={() => handleSelect(row, ri)}
            >
              {columns.map((column, ci) => {
                const { options = {} } = column;
                const {
                  typographyProps = {},
                  cellProps = {},
                  render,
                } = options;

                if (typeof render === "function") {
                  return (
                    <TableCell key={ci} {...cellProps}>
                      {render(get(row, column.name), row, ri)}
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={ci} {...cellProps}>
                    <Typography variant="subtitle2" {...typographyProps}>
                      {get(row, column.name)}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

Index.defaultProps = {
  value: null,
  valueKey: "id",
  columns: [],
  data: [],
  onSelect: () => {},
  hideHeader: false,
};

Index.propTypes = {
  value: PropTypes.any,
  valueKey: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  onSelect: PropTypes.func,
  hideHeader: PropTypes.bool,
};

export default Index;

import React from "react";

import { AppBar, Toolbar } from "@material-ui/core";

const Index = ({ children }) => {
  return (
    <AppBar position="static" style={{ height: 47 }}>
      <Toolbar style={{ minHeight: "100%" }}>{children}</Toolbar>
    </AppBar>
  );
};

export default Index;

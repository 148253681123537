const {
  REACT_APP_API_URL,
  REACT_APP_FB_API_KEY,
  REACT_APP_FB_AUTH_DOMAIN,
  REACT_APP_FB_DATABASE,
  REACT_APP_FB_SENDER_ID,
  REACT_APP_FB_STORAGE_BUCKET,
 } = process.env;

const stage = process.env.NODE_ENV || 'development';

const config = {
  stage,
  api: {
    url: `${REACT_APP_API_URL}/api/`,
    path: REACT_APP_API_URL,
  },
  apiKey: REACT_APP_FB_API_KEY,
  authDomain: REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: REACT_APP_FB_DATABASE,
  messagingSenderId: REACT_APP_FB_SENDER_ID,
  storageBucket: REACT_APP_FB_STORAGE_BUCKET,
};

export default config;

import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Modal,
  Typography,
  IconButton,
  Collapse,
} from "@material-ui/core";
import { CropFree } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";

import QrReader from "react-qr-reader";
import Input from "app/components/Form/Input";

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0,
  //maximumFractionDigits: 0,
});

const isValidHttpUrl = (string) => {
  if (!string || typeof string !== "string") return false;

  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return ["http:", "https:"].includes(url.protocol);
};

function PaypalPays(props) {
  const [open, setOpen] = useState(props.open);
  const [totalValue, setTotalValue] = useState("");
  const [qrReader, setQrReader] = React.useState(false);

  const [campaing, setCampaing] = useState("");
  const [orderDetail, setOrderDetail] = useState(null);

  const handleScan = (data) => {
    if (!isValidHttpUrl(data)) return;

    setQrReader(false);
    const params = data.split("?")[1];
    if (params) {
      const queryString = `?${params}`;
      const urlParams = new URLSearchParams(queryString);

      if (urlParams.has("campaing")) {
        setCampaing(urlParams.get("campaing"));
      }
    }
  };

  useEffect(() => {
    if (!props.open) {
      setOpen(false);
    } else {
    }
  }, [props.open]);

  useEffect(() => {
    if (orderDetail) {
      props.onCompleted && props.onCompleted(orderDetail, campaing);
    }
  }, [orderDetail, campaing]);

  useEffect(() => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AT5JdxDXd-bg1Ogq7j0m5z42c43dy8bvnPvPlhbaxN9GthTDUxydDQzKeCXooNsxAJjWc1NauwX2n__X&currency=MXN";
    script.async = true;
    script.onload = function () {
      window.paypal
        .Buttons({
          shippingPreference: "NO_SHIPPING",
          style: {
            shape: "rect",
            color: "white",
            layout: "vertical",
            label: "pay",
            shippingPreference: "NO_SHIPPING",
          },
          options: {
            // payerCountry:'MX'
          },
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  description: "Membresía Anual",
                  amount: {
                    currency_code: "MXN",
                    value: 6960,
                    // value: 0.01,
                    breakdown: {
                      shipping: null,
                      item_total: { currency_code: "MXN", value: 6000 },
                      tax_total: { currency_code: "MXN", value: 960 },
                      // item_total: { currency_code: "MXN", value: 0 },
                      // tax_total: { currency_code: "MXN", value: 0 },
                    },
                  },
                },
              ],
            });
          },

          onApprove: async function (data, actions) {
            const details = await actions.order.capture();

            setOrderDetail(details);
          },
          onError: function (err) {
            console.log(" -------------------->  ", err);
          },
          onClick: function () {
            setTotalValue(
              `pago total: ${formatter.format(500 * 12 * 1.16)} MXN`
            );
          },
        })
        .render("#smart-button-container");
    };

    script.onerror = function (e) {
      console.log("Paypal SDK could not be loaded.", e);
    };

    document.body.appendChild(script);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={props.onClose}
      onTransitionEnd={!open ? props.onClose : null}
      PaperProps={{
        style: {
          backgroundColor: "whitesmoke",
          color: "black",
          maxHeight: "90%",
        },
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <DialogTitle
          align="left"
          variant="h3"
          color="textPrimary"
          style={{
            textShadow: "0px 0px 1px gray",
            fontWeight: 700,
            position: "relative",
            color: "black",
          }}
        >
          {"Consultorio Pro"}
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            variant="h3"
            color="textPrimary"
            style={{
              textShadow: "0px 0px 1px gray",
              fontWeight: 700,
              position: "relative",
              color: "black",
            }}
          >
            {"$ 500"}
          </Typography>
          <DialogContentText key="0" align="center" color="inherit">
            {"+ IVA / mes"}
          </DialogContentText>
          <DialogContentText key="1" align="center" color="inherit">
            {"pago anual"}
          </DialogContentText>
          <DialogContentText
            key="4"
            align="right"
            color="inherit"
            style={{
              borderTop: totalValue ? "1px solid rgba(200,200,200,0.5)" : null,
              padding: 8,
            }}
          >
            {totalValue}
          </DialogContentText>

          <Input
            placeholder="Introducir cupon"
            value={campaing}
            onChange={setCampaing}
            style={{ color: "black" }}
            endAdornment={
              <IconButton onClick={() => setQrReader((prev) => !prev)}>
                <CropFree style={{ color: "black" }}/>
              </IconButton>
            }
          />
          <Collapse in={qrReader}>
            <Box position="relative">
              {qrReader && (
                <QrReader
                  showViewFinder={false}
                  delay={0}
                  onError={() => {}}
                  onScan={handleScan}
                  style={{
                    width: "100%",
                    margin: 0,
                    padding: 0,
                    borderRadius: 0,
                    overflow: "hidden",
                  }}
                />
              )}
              <Box position="absolute" p={2} top={0} width="100%" height="100%">
                <Box height="100%" border="2px solid rgba(20,20,20,0.2)" />
              </Box>
            </Box>
          </Collapse>
        </DialogContent>
        <div
          id="smart-button-container"
          style={{ minWidth: 400, padding: 30, maxHeight: 400 }}
        ></div>
      </Box>
    </Dialog>
  );
}
export default PaypalPays;

import catalogos from "./catalogos/catalogos.json";
import colores from "./catalogos/colores.json";
import dias from "./catalogos/dias.json";
import sets from "./catalogos/sets.json";
import paises from "./catalogos/paises.json";
import preguntas from "./catalogos/preguntas.json";
import preguntasVersiones from "./catalogos/preguntasVersiones.json";
import preguntasRespuestas from "./catalogos/preguntasRespuestas.json";
import respuestas from "./catalogos/respuestas.json";
import diagnosticos from "./catalogos/diagnosticos.json";
import procedimientos from "./catalogos/procedimientos.json";
import medicamentosDgis from "./catalogos/medicamentosDgis.json";
import medicamentosThink from "./catalogos/medicamentosThink.json";
import medicamentosSustanciasActivas from "./catalogos/medicamentosSustanciasActivas.json";
import unidadesMedida from "./catalogos/unidadesMedida.json";
import viasAdministracion from "./catalogos/viasAdministracion.json";
import vehiculos from "./catalogos/vehiculos.json";
import estudios from "./catalogos/estudios.json";
import gruposEstudios from "./catalogos/gruposEstudios.json";
import gruposEstudios_estudios from "./catalogos/gruposEstudios_estudios.json";
import paquetesEstudios from "./catalogos/paquetesEstudios.json";
import paquetesEstudios_estudios_grupos from "./catalogos/paquetesEstudios_estudios_grupos.json";
import niveles from "./catalogos/niveles.json";
import formacionAcademica from "./catalogos/formacionAcademica.json";
import roles from "./catalogos/roles.json";
import religiones from "./catalogos/religiones.json";
import entidades from "./catalogos/entidades.json";
import correos from "./catalogos/correos.json";
import estadosCiviles from "./catalogos/estadosCiviles.json";
import lenguasIndigenas from "./catalogos/lenguasIndigenas.json";
import nacionalidades from "./catalogos/nacionalidades.json";
import clues from "./catalogos/clues.json";
import tiposAreas from "./catalogos/tiposAreas.json";
import tiposOficios from "./catalogos/tiposOficios.json";
import preguntasEnarm from "./catalogos/preguntasEnarm.json";
import respuestasEnarm from "./catalogos/respuestasEnarm.json";
import casosClinicosEnarm from "./catalogos/casosClinicosEnarm.json";
import tiposTurnos from "./catalogos/tiposTurnos.json";
import municipios from "./catalogos/municipios.json";
import localidades from "./catalogos/localidades.json";
import tiposInstituciones from "./catalogos/tiposInstituciones.json";
import tiposPreguntas from "./catalogos/tiposPreguntas";
import tiposEstudios from "./catalogos/tiposEstudios";
import sexos from "./catalogos/sexos";
import tiposEventos from "./catalogos/tiposEventos";
import tiposPrioridad from "./catalogos/tiposPrioridad";
import regimenesFiscales from "./catalogos/regimenesFiscales.json";
import especialidades from "./catalogos/especialidades.json";
import nombres from "./catalogos/nombres.json";
import gpc from "./catalogos/gpc.json";
import apellidos from "./catalogos/apellidos.json";
import estatusCitas from "./catalogos/estatusCitas.json";

const INITIAL_STATE = {
  catalogos,
  colores,
  casosClinicosEnarm,
  preguntasEnarm,
  respuestasEnarm,
  dias,
  sets,
  paises,
  diagnosticos,
  procedimientos,
  medicamentosDgis,
  medicamentosThink,
  medicamentosSustanciasActivas,
  preguntas,
  preguntasVersiones,
  preguntasRespuestas,
  respuestas,
  unidadesMedida,
  viasAdministracion,
  vehiculos,
  estudios,
  gruposEstudios,
  gruposEstudios_estudios,
  paquetesEstudios,
  paquetesEstudios_estudios_grupos,
  niveles,
  formacionAcademica,
  roles,
  religiones,
  clues,
  tiposAreas,
  tiposTurnos,
  entidades,
  municipios,
  localidades,
  tiposInstituciones,
  tiposPreguntas,
  tiposEstudios,
  tiposEventos,
  tiposOficios,
  tiposPrioridad,
  sexos,
  regimenesFiscales,
  correos,
  estadosCiviles,
  lenguasIndigenas,
  nacionalidades,
  especialidades,
  nombres,
  gpc,
  apellidos,
  estatusCitas,
};

export default INITIAL_STATE;

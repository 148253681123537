import httpService from './httpService';
import customValidation from './customValidation';

const correctStatus = [200, 201, 202];

// ------- helper functions --------
const validHTTPStatus = (requestID) => {
    const httpData = window.amplify.store(requestID);
    window.amplify.store(requestID, null);
    const foundStatus = correctStatus.filter(status => status === httpData.status);

    if (foundStatus.length) {
        return true;
    }

    return false;
};

const httpHandler = (requestID, response, callback) => {
    if (validHTTPStatus(requestID) && customValidation(response)) {
        callback(null, response);
    } else {
        callback(response);
    }
};
// -------------------------------------

const http = {
    get(request, headers, callback, settings) {
        const requestID = Math.random().toString(36).slice(-5);

        httpService.get(request, requestID, headers, settings).then((response) => {
            httpHandler(requestID, response, callback);
        }).catch((err) => {
            console.log('Error in internalAPI: ', err);
            callback(err);
        });
    },
    post(request, headers, body, callback, settings) {
        const requestID = Math.random().toString(36).slice(-5);

        httpService.post(request, requestID, headers, body, settings).then((response) => {
            httpHandler(requestID, response, callback);
        }).catch((err) => {
            console.log('Error in internalAPI: ', err);
            callback(err);
        });
    },
    delete(request, headers, body, callback, settings) {
        const requestID = Math.random().toString(36).slice(-5);
        httpService.delete(request, requestID, headers, body, settings).then((response) => {
            httpHandler(requestID, response, callback);
        }).catch((err) => {
            console.log('Error in internalAPI: ', err);
            callback(err);
        });
    },
    put(request, headers, body, callback, settings) {
        const requestID = Math.random().toString(36).slice(-5);

        httpService.put(request, requestID, headers, body, settings).then((response) => {
            httpHandler(requestID, response, callback);
        }).catch((err) => {
            console.log('Error in internalAPI: ', err);
            callback(err);
        });
    }
};


const APIHelper = {
    get(request, headers, callback, settings) {
        http.get(request, headers, (err, response) => {
            if (err) {
                callback(err);
            } else {
                callback(null, response);
            }
        }, settings);
    },
    post(request, headers, body, callback, settings) {
        http.post(request, headers, body, (err, response) => {
            if (err) {
                callback(err);
            } else {
                callback(null, response);
            }
        }, settings);
    },
    delete(request, headers, body, callback, settings) {
        http.delete(request, headers, body, (err, response) => {
            if (err) {
                callback(err);
            } else {
                callback(null, response);
            }
        }, settings);
    },
    put(request, headers, body, callback, settings) {
        http.put(request, headers, body, (err, response) => {
            if (err) {
                callback(err);
            } else {
                callback(null, response);
            }
        }, settings);
    }
};

export default APIHelper;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Row(props) {
  const { index, style, row } = props;
  console.log(props);
  return (
    <ListItem key={index} button style={style} key={index}>
      <ListItemText primary={`${row.vc_nombre}`} />
    </ListItem>
  );
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

export default function VirtualizedList(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FixedSizeList height={400} width={360} itemSize={46} itemCount={10}>
        {
          props.data.map((row, index) => {
            console.log(row)
            return (
              <Row
              key={index}
              index={index}
              row={row}
              style={{}}
              />
            )
          })
        }
      </FixedSizeList>
    </div>
  );
}
import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import TimeLine from "./TimeLine";
import Days from "./Days";
import api from "./api";

import AddMeetingModal from "app/agenda/components/AddMeetingModal";
import Loading from "app/agenda/components/Loading";

const timeDiff = (t1, t2) =>
  moment(t1, "HH:mm:ss").diff(moment(t2, "HH:mm:ss"));

function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const getSpace = (array, position) => {
  if (!array.length) return [];
  if (!array[position + 1]) return [];

  const a = array[position];
  const b = array[position + 1];
  const am = moment(a.tm_fin, "HH:mm:ss");
  const bm = moment(b.tm_inicio, "HH:mm:ss");

  return [
    {
      tm_inicio: a.tm_fin,
      tm_fin: b.tm_inicio,
      nu_duracion: bm.diff(am, "minutes"),
    },
    ...getSpace(array, position + 1),
  ];
};

const Index = React.forwardRef(
  ({ title, style, agendaId, dates, omitStatus, ...props }, ref) => {
    const { agendas = [] } = window.amplify.store("institution") || {};
    const [data, setData] = React.useState({ horario: [], citas: [] });
    const [loading, setLoading] = React.useState([]);
    const [dias, setDias] = React.useState([]);
    const [horario, setHorario] = React.useState({
      from: "08:00:00",
      to: "14:00:00",
    });
    const [hoursDisabled, setHoursDisabled] = React.useState([]);

    const [addMeetingData, setAddMeetingData] = React.useState({});
    const [addMeetingModal, setAddMeetingModal] = React.useState(false);

    const prevAgendaID = usePrevious(agendaId);
    const prevDates = usePrevious(dates);

    const fetchData = async () => {
      setLoading(true);
      const res = await api.days(agendaId, dates);
      const citas = Object.values(res)
        .reduce((p, c) => [...p, ...c.citas], [])
        .filter((v) => !omitStatus.includes(v.id_estatusCita));

      setData({
        horario: Object.values(res).reduce((p, c) => [...p, ...c.horario], []),
        citas,
      });

      setLoading(false);
      const auxDias = dates
        .map((v) => moment(v, "YYYY-MM-DD"))
        .map((v) => ({
          id: v.day() + 1,
          vc_nombre: v.format("dddd"),
          dt_dia: v.format("YYYY-MM-DD"),
        }));

      setDias(auxDias);
    };

    React.useImperativeHandle(ref, () => ({
      refresh: () => fetchData(),
      addMeeting: (turno) => {
        setAddMeetingData({
          id_agenda: agendaId,
          ...turno,
        });
        setAddMeetingModal(true);
      },
    }));

    React.useEffect(() => {
      if (
        dates.length &&
        (prevAgendaID !== agendaId ||
          JSON.stringify(prevDates) !== JSON.stringify(dates))
      ) {
        fetchData();
      }
    }, [agendaId, dates]);

    React.useEffect(() => {
      const { tm_inicio = horario.from, tm_fin = horario.to } =
        data.horario[0] || {};
      // let from = tm_inicio;
      // let to = tm_fin;
      let from = "00:00";
      let to = "24:00";

      data.horario.forEach((v) => {
        if (timeDiff(v.tm_inicio, from) < 0) from = v.tm_inicio;
        if (timeDiff(v.tm_fin, to) > 0) to = v.tm_fin;
      });

      data.citas.forEach((v) => {
        if (timeDiff(v.tm_inicio, from) < 0) from = v.tm_inicio;
        if (timeDiff(v.tm_fin, to) > 0) to = v.tm_fin;
      });

      setHorario({ from, to });

      const disabled = dates.reduce((p, c) => {
        const aux = data.horario
          .filter((v) => moment(c, "YYYY-MM-DD").day() + 1 === v.nu_dia)
          .sort((a, b) =>
            moment(a.tm_inicio, "HH:mm:ss").diff(
              moment(b.tm_inicio, "HH:mm:ss")
            )
          );

        aux.unshift({ tm_inicio: from, tm_fin: from });
        aux.push({ tm_inicio: to, tm_fin: to });

        return {
          ...p,
          [c]: getSpace(aux, 0),
        };
      }, {});

      setHoursDisabled(disabled);
      console.log(disabled);
    }, [data]);

    return (
      <Box position="relative" style={{ flex: 1, ...style }}>
        <Loading show={loading} />
        <AddMeetingModal
          open={addMeetingModal}
          close={() => setAddMeetingModal(false)}
          accept={() => {
            setAddMeetingModal(false);
            fetchData();
          }}
          agendas={agendas}
          data={addMeetingData}
        />

        {title && (
          <Typography style={{ fontSize: 14, padding: 10 }} gutterBottom>
            {title}
          </Typography>
        )}
        <Box position="relative" p={"10px"}>
          <TimeLine
            {...props}
            from={horario.from}
            to={horario.to}
            dias={dias}
            hoursDisabled={hoursDisabled}
            add={(turno) => {
              setAddMeetingData({
                id_agenda: agendaId,
                ...turno,
              });
              setAddMeetingModal(true);
            }}
          />
          <Days
            {...props}
            // from="00:00"
            // to="24:00"
            from={horario.from}
            to={horario.to}
            events={data.citas}
            dias={dias}
          />
        </Box>
      </Box>
    );
  }
);

Index.defaultProps = {
  title: "",
  events: [],
  schedule: [],
  add: () => {},
  edit: () => {},
  dates: [],
  style: {},
  rowHeight: 20,
  rowTime: 60,
  hideTitleDay: false,
  omitStatus: [],
};

Index.propTypes = {
  agendaId: PropTypes.number,
  dates: PropTypes.array,
  title: PropTypes.string,
  style: PropTypes.object,
  schedule: PropTypes.array,
  from: PropTypes.string,
  to: PropTypes.string,
  omitStatus: PropTypes.array,
};

export default Index;

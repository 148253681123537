import React from "react";

export const useForm = (initialState) => {
  const [fields, setValues] = React.useState(initialState);

  return [
    fields,
    (key, value) => {
      let newValue = {};

      if (Array.isArray(key)) {
        newValue = key.reduce((pv, cv) => ({ ...pv, [cv.key]: cv.value }), {});
      } else if (key === Object(key)) {
        newValue = key;
      } else {
        newValue = { [key]: value };
      }

      // let nv = Array.isArray(key)
      //   ? key.reduce((pv, cv) => ({ ...pv, [cv.key]: cv.value }), {})
      //   : { [key]: value };

      setValues((prev) => ({ ...prev, ...newValue }));
    },
  ];
};

import React from 'react';
import { connect } from 'react-redux';
import { IconButton, Button, Box, Grid, MenuItem, DialogTitle, Divider } from '@material-ui/core';
import { setHelperSidebarRender, resetHelperSidebarRender, setDisplaySidebar } from '../../containers/Think/actions';
import QrReader from 'react-qr-reader';
import * as firebase from 'firebase/app';
import ConfirmAlert from '../ConfirmAlert';
import CircularProgress from '@material-ui/core/CircularProgress';
import internalApi from '../../utils/internalAPI';
import repository from '../../utils/repository';
import notifications from '../../utils/notifications';
class CreateButton extends React.Component {
  constructor(props) {
    super(props);
    this.app = firebase;
    this.db = this.app.database().ref();
    this.state = { delay: 'someValue' };
  }
  handleScan(data) {
    if (data) {
      const curpInfo = data.split('|');
      const fecha = curpInfo[6].split('/');
      const paciente = this.props.getRegistro ? this.props.getRegistro() : { detalle: {} };
      if (!paciente.detalle){
        paciente.detalle = {};
      }
      if (curpInfo[5] === 'HOMBRE' || curpInfo[5] === 'MUJER') {
        paciente.detalle.vc_curp = curpInfo[0];
        paciente.detalle.vc_apellidoPaterno = curpInfo[2];
        paciente.detalle.vc_apellidoMaterno = curpInfo[3];
        paciente.detalle.vc_nombre = curpInfo[4];
        paciente.detalle.id_sexo = curpInfo[5] === 'HOMBRE' ? 1 : 2;
        paciente.detalle.dt_nacimiento = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;
        paciente.detalle.id_entidad = parseInt(curpInfo[8], 10);

        notifications.success('Se registro CURP y los datos generales');

        this.props.setDisplaySidebar(false);
        this.props.resetHelperSidebarRender();
        this.props.setRegistro(paciente);

        document.getElementById('detalle.vc_nombre').setAttribute('value', 'hola')
      }
    }
  }

  handleError(err) {
    console.error(err);
    this.setState({ showReadQR: false });
  }

  renderHelpBar() {
    return (
      <Grid style={{ width: '100%', margin: 0, padding: 3, backgroundColor: 'transparent' }}>
        <QrReader
          delay={0}
          onError={this.handleError.bind(this)}
          onScan={this.handleScan.bind(this)}
          style={{ width: '100%', margin: 0, padding: 0, borderRadius: 4 }}
        />
        <Button
          style={{ width: '100%', marginTop: 5 }}
          variant='outlined' color='primary' onClick={() => {
            this.props.setDisplaySidebar(false);
            this.props.resetHelperSidebarRender();
          }}>
          Cancelar
            </Button>
      </Grid>
    )
  }
  saveFirebase() {
    let ref = this.db;
    const register = this.props.getRegistro();
    if (!register) return;
    this.props.catalogo.routes.create.vc_path.split('/').map((path) => {
      ref = ref.child(path);
    })

    const firebaseRegister = ref.push(register);
    ref.child(firebaseRegister.key).child('id').set(firebaseRegister.key, (result) => {
      if (this.props.saveComplete) this.props.saveComplete();
    });

  }
  replaceValuesURL(url, body){
    if (url.includes('${')){
      const firstParts = url.split('${');
      const initialRoute = firstParts[0] || '';
      const secondsParts = firstParts[1].split('}');
      const key = secondsParts[0] || '';
      let value = null;
      let prevBody = body;
      key.split('.').map((k) => {
        prevBody = prevBody[k];
      })
      value = prevBody;
      return `${initialRoute}${value || ''}${secondsParts[1] || ''}`
    }else{
      return url;
    }

  }
  save() {
    const body = this.props.getRegistro();
    console.log('create button', this.props.catalogo, body)
    const route =  body.id ? this.props.catalogo.routes.edit : this.props.catalogo.routes.create
    switch (route.tipoApi) {
      case 1: {
        break;
      }
      case 2: {

        if (!body) notifications.success('No se han cargado los registros');
        if (!body) return;
        const errorMessages = repository.validationBody(body, this.props.catalogo, this.props.catalogos);

        console.log('====ERRORES====', errorMessages)

        if (errorMessages.noNulls.length === 0 && errorMessages.noSuccess.length === 0) {
          const headers = {
            Authorization: window.amplify.store('token'),
            accept: 'application/json'
          };
          this.setState({loading: true})
          window.$.ajax({
            url: `${window.backEndURL}${body.id ? this.replaceValuesURL(this.props.catalogo.routes.edit.vc_url, body) : this.replaceValuesURL(this.props.catalogo.routes.create.vc_url, body)}`,
            data: body,
            headers,
            method: body.id ? this.props.catalogo.routes.edit.method : this.props.catalogo.routes.create.method
          }).then((data, status, xhr, error) => {
            if (data.message) {
              this.setState({ loading: false, successMessage: { title: '¡Listo!', message: data.message, onAccept: this.props.saveComplete || null } })
            } else if (data.error) {
              console.log('error ----->', data.error)
              this.setState({ loading: false, errorMessages: { title: '¡Ups!', message: data.error } })
            } else {
              this.setState({ loading: false, errorMessages: { title: '¡Listo!', message: '', onAccept: this.props.saveComplete || null } })
            }
          }).fail((data, status, xhr, error) => {
            if (data.responseJSON) {
              this.setState({ loading: false, errorMessages: { title: '¡Ups!', message: data.responseJSON.error || 'Verifica tu conexion a internet.' } })
            }else{
              this.setState({ loading: false, errorMessages: { title: '¡Ups!', message: 'Verifica tu conexion a internet.' } })
            }
          })
        } else {
          console.log('errormessage', errorMessages)
          this.setState({ loading: false, errorMessages})
        }
        break;
      }
      case 3: {
        this.saveFirebase();
        break;
      }
      case 4: {
        if (!body) notifications.success('No se han cargado los registros');
        if (!body) return;
        const errorMessages = repository.validationBody(body, this.props.catalogo, this.props.catalogos);

        console.log('====ERRORES====', errorMessages)
        console.log('DEBERIAENTRARAQUI')
        if (errorMessages.noNulls.length === 0 && errorMessages.noSuccess.length === 0) {
          const headers = {
            Authorization: window.amplify.store('token'),
            accept: 'application/json'
          };
          this.setState({loading: true})
          internalApi[body.id ? this.props.catalogo.routes.edit.repository : this.props.catalogo.routes.create.repository](body,(error, response) => {
            console.log(error, response)
            if (error) {
              console.log('error', error)
              this.setState({ loading: false, successMessage: { title: 'Ups!', message: error.texto || 'Verifica tu conexión a internet.' } })
            }else if (response.message) {
              this.setState({ loading: false, successMessage: { title: '¡Listo!', message: response.message, onAccept: this.props.saveComplete || null } })
            } else if (response.error) {
              this.setState({ loading: false, errorMessages: { title: '¡Ups!', message: response.error } })
            } else {
              this.setState({ loading: false, errorMessages: { title: 'Listo!', message: '', onAccept: this.props.saveComplete || null } })
            }
          })
        }else{
          this.setState({ loading: false, errorMessages})
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  render() {
    return (
      <Box>
        <Button
        color={this.props.color || 'primary'}
        disabled={this.props.disabled || false}
        variant={this.props.variant || 'text'}
        endIcon={ this.state.loading && <CircularProgress size={15} color='secondary' />}
        onClick={this.save.bind(this)}>
          Guardar
        </Button>
        {this.state.errorMessages && (
          <ConfirmAlert
            open
            title={this.state.errorMessages.title}
            message={
              <Box style={{ maxHeight: '90vh' }}>
                {
                  this.state.errorMessages.message || ''
                }
                {
                  this.state.errorMessages.noNulls && this.state.errorMessages.noNulls.length > 0 && <Box>
                    <Divider />
                    <MenuItem key={'vacios'}>
                      No deben estar vacios:
                  </MenuItem>
                    <Divider />
                    {this.state.errorMessages.noNulls.map((field, index) => {
                      return (
                        <MenuItem key={index}>
                          {field}
                        </MenuItem>
                      )
                    })}
                  </Box>
                }
                {
                  this.state.errorMessages.noSuccess && this.state.errorMessages.noSuccess.length > 0 &&
                  <Box>
                    <Divider />
                    <MenuItem key={'formatos'}>
                      Formato invalido:
                  </MenuItem>
                    <Divider />
                    {this.state.errorMessages.noSuccess.map((field, index) => {
                      return (
                        <MenuItem key={index}>
                          {field}
                        </MenuItem>
                      )
                    })}
                  </Box>
                }
              </Box>
            }

            onAccept={this.state.errorMessages.onAccept || (() => {
              this.setState({ errorMessages: null })
            })}
          />
        )}
        {this.state.successMessage && (
          <ConfirmAlert
            title={this.state.successMessage.title || ''}
            message={this.state.successMessage.message || ''}
            open
            onAccept={() => {
              this.setState({ errorMessages: null, successMessage: null }, this.state.successMessage.onAccept || null)
            }}
          />
        )}
      </Box>
    );
  }
}
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setDisplaySidebar
}
const mapStateToProps = (state) => {
  const {catalogos} = state.Think;
  return {catalogos};
};
export default connect(mapStateToProps, actions)(CreateButton);

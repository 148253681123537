import React from "react";
import { Card } from "semantic-ui-react";
import {
  AppBar,
  Paper,
  Toolbar,
  Typography,
  Divider,
  Button,
  Box,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  // console.log(theme);
  return {
    button: {
      marginBottom: theme.spacing(1),
    },
  };
});

const ButtonSidebar = ({ children, onClick, variant = "outlined" }) => {
  const classes = useStyles();
  return (
    <Button
      variant={variant}
      color="primary"
      fullWidth
      className={classes.button}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
export default (props) => {
  const { editar = () => {}, eliminar = () => {}, data = {} } = props;

  return (
    <Paper
      elevation={0}
      style={{ padding: 0, border: 0, borderRadius: 0, height: "100%" }}
    >
      <AppBar
        position="relative"
        style={{
          height: 95,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Typography style={{ textAlign: "center", width: "100%" }}>
            Producto
          </Typography>
        </Toolbar>
      </AppBar>
      <div
        style={{
          height: "calc(100% - 95px)",
          width: "100%",
          overflow: "hidden",
          WebkitOverflowScrolling: "touch",
          padding: 0,
          margin: 0,
        }}
      >
        <Box p={2} textAlign="center">
          <Card.Content>
            <Card.Header>
              <Typography
                style={{
                  margin: "5px 0px",
                  fontSize: "15px",
                  fontWeight: 500,
                  textTransform: "capitalize",
                }}
              >
                {data.nombre}
              </Typography>
            </Card.Header>
            <Card.Description>Tipo: {data.tipo}</Card.Description>
            <Card.Description>Area: {data.area}</Card.Description>
          </Card.Content>
        </Box>
        <Divider />
        <Box p={1}>
          <ButtonSidebar variant="contained" onClick={editar}>
            Editar
          </ButtonSidebar>
          <ButtonSidebar onClick={eliminar}>Eliminar</ButtonSidebar>
        </Box>
      </div>
    </Paper>
  );
};

import React from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";

const Table = (props) => {
  const { data, onClick, columns: cols, title, search } = props;
  const options = { filter: true, sort: true };

  const columns = cols.map((v) => ({ ...v, options }));
  
  const optionsTable = {
    elevation: 0,
    search: false,
    searchText: search,
    filter: false,
    fixedSelectColumn: false,
    print: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    onRowClick(rowData, { dataIndex }) {
      onClick(data[dataIndex], dataIndex);
    },
  };

  console.log(search, optionsTable);
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={optionsTable}
    />
  );
};

Table.defaultProps = {
  search: "",
  title: "",
  columns: [],
  data: [],
  onClick: () => {},
};

Table.propTypes = {
  search: PropTypes.string,
  title: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  onClick: PropTypes.func,
};

export default Table;

import React, { useCallback, useEffect, useState } from "react";
import AgregarCuentaDialog from "app/sales/components/Cuentas/AgregarCuentaDialog";

export default function useConfirmationDialog({ onAccept = () => {} }) {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);

  const Dialog = useCallback(
    () => (
      <AgregarCuentaDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onAccept={onAccept}
      />
    ),
    [isOpen]
  );

  return {
    AgregarCuentaDialog: Dialog,
    onOpen,
  };
}

import React from "react";
import _ from "lodash";
import {
  Box,
  Divider,
  Popover,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@material-ui/core";

import Input from "app/components/Form/Input";
import { ArrowDropDown, Search, Check } from "@material-ui/icons";

export default ({
  onChange = () => {},
  data = [],
  value = "",
  hideSearch = false,
  maxRows = 10,
  showAllRows = false,
  ...props
}) => {
  const ref = React.useRef();
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState(
    data.find((v) => v.value === value)
  );
  React.useEffect(() => {
    setRows(getRows(data));
  }, [data]);

  React.useEffect(() => {
    setSelected(data.find((v) => v.value === value));
  }, [value, data]);
  React.useEffect(() => {
    if (search) {
      const filtered = data.filter(({ text }) =>
        text.toLowerCase().includes(search.toLowerCase())
      );
      setRows(getRows(filtered));
    } else {
      setRows(getRows(data));
    }
  }, [search]);

  const getRows = (a) => [...a].splice(0, showAllRows ? a.length : maxRows);
  const select = (row) => {
    if (!selected || selected.value !== row.value) {
      onChange(row);
      setSelected(row);
    }

    setAnchorEl(null);
    setSearch("");
    setRows(getRows(data));
  };

  let labelModified = !props.required ? props.label : `${props.label} *`;
  return [
    <TextField
      variant="outlined"
      label={labelModified}
      InputProps={{
        readOnly: true,
        endAdornment: <ArrowDropDown />,
        placeholder: "Sin selección",
        onClick: (e) => {
          const target = e.target;
          if (target.tagName === "INPUT") {
            setAnchorEl(target);
            setSearch("");
          }
        },
      }}
      {...props}
      value={selected?.text || ""}
      ref={ref}
      style={{ marginRight: 10, marginTop: 9 }}
    />,
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      PaperProps={{
        style: {
          minWidth: ref?.current?.clientWidth || 200,
          width: "auto",
        },
      }}
    >
      <Box
        padding={0}
        style={{
          maxHeight: "300px",
          display: "flex",
          minWidth: "100%",
          flexDirection: "column",
        }}
      >
        {!hideSearch && [
          <Box padding={1} paddingBottom={0}>
            <Input
              inputProps={{}}
              autoFocus
              onClick={(e) => e.stopPropagation()}
              value={search}
              onChange={setSearch}
              startAdornment={<Search style={{ marginRight: 8 }} />}
              style={{ margin: 0 }}
              dense
              hasBackground={false}
            />
          </Box>,
          <Divider variant="fullWidth" />,
        ]}
        <Box
          padding={0}
          style={{
            flex: "1 1 auto",
            overflowY: "auto",
          }}
        >
          <ListItem button onClick={(_) => select({ value: null, label: "" })}>
            <ListItemText primary={<em>* Sin selección</em>} />
          </ListItem>
          {rows.map((row, index) => (
            <ListItem key={index} button onClick={(_) => select(row)}>
              <ListItemText primary={row.text} />
              {selected && selected.value === row.value && (
                <ListItemIcon style={{ justifyContent: "flex-end", width: 20 }}>
                  <Check color="primary" />
                </ListItemIcon>
              )}
            </ListItem>
          ))}
        </Box>
      </Box>
    </Popover>,
  ];
};

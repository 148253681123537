import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
// import { Popup, Input, Menu, Container } from 'semantic-ui-react';
import HelpBarThink from "../../../../material-ui/HelpBarThink";
import TratamientosItem from "./tratamientosItem";
import Timer from "@material-ui/icons/Timer";
import Today from "@material-ui/icons/Today";
import PlayListAdd from "@material-ui/icons/PlaylistAdd";
import {
  Box,
  ListItem,
  Toolbar,
  Typography,
  AppBar,
  List,
  Input,
  ListItemText,
  Paper,
  Button,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
  Popover,
  DialogActions,
} from "@material-ui/core";
import { getIndexLastDetalle } from "../../../../utils/repository";
import internalAPI from "../../../../utils/internalAPI";

import {
  StaticDatePicker,
  StaticTimePicker,
  LocalizationProvider,
} from "@material-ui/pickers";

import MomentAdapter from "@material-ui/pickers/adapter/moment";

const IconTimePicker = (props) => {
  const { value, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [date, setDate] = React.useState(value);

  const select = () => {
    onChange(date);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        size="small"
        style={{ marginLeft: 10, marginRight: 10 }}
        onClick={(e) => {
          setAnchorEl(e.target);
          setDate(value);
        }}
      >
        <Timer />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <LocalizationProvider dateAdapter={MomentAdapter}>
          <StaticTimePicker
            displayStaticWrapperAs="desktop"
            autoOk
            onAccept={() => { }}
            openTo="hours"
            value={date}
            onChange={setDate}
          />
        </LocalizationProvider>
        <DialogActions>
          <Button color="primary" onClick={() => setAnchorEl(null)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={select}>
            Aceptar
          </Button>
        </DialogActions>
      </Popover>
    </div>
  );
};

const IconDatePicker = (props) => {
  const { value, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [date, setDate] = React.useState(value);
  const select = (newDate) => {
    console.log('------------>', date)
    if (newDate) {
      onChange(moment(newDate));
      setAnchorEl(null);
    }else if (date){
      onChange(moment(date));
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        size="small"
        style={{ marginLeft: 10, marginRight: 10 }}
        onClick={(e) => {
          setAnchorEl(e.target);
        }}
      >
        <Today />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onInput={(e) => console.log('input')}
      >
        <LocalizationProvider dateAdapter={MomentAdapter}>
          <StaticDatePicker
            allowKeyboardControl
            displayStaticWrapperAs="desktop"
            autoOk
            openTo="date"
            views={["date", "month", "year"]}
            value={value}
            onChange={setDate}
            onYearChange={(newDate) => {
              setDate(newDate);
              select(newDate)
            }}
          />
        </LocalizationProvider>
        <DialogActions>
          <Button color="primary" onClick={() => setAnchorEl(null)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={select}>
            Aceptar
          </Button>
        </DialogActions>
      </Popover>
    </div>
  );
};

class MedicamentosHelpBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      indexHover: null,
      indexSelected: this.props.indexSelected,
      opcionPreSelected: null,
      isEdited: false,
      medicamentosPLM: [],
    };
  }
  render() {
    const { index, medicamento, opcionMedicamentos } = this.props;

    let optionStyle = {
      flexDirection: "column",
    };

    let labelDisplays = [
      { key: "vc_nombre", style: { width: "100%" } },
      { key: "vc_concentracion", style: { width: "100%" } },
      {
        key: "vc_farmaceutica",
        style: {
          color: window.colors.itemSelected,
          textAlign: "right",
          width: "100%",
          textTransform: "capitalize",
        },
      },
    ];
    return (
      <HelpBarThink
        header={this.titleHelpBar()}
        searchBar={!this.props.readOnly}
        options={this.opcionesArray()}
        optionsAction={this.optionsAction()}
        indexSeleccionado={this.state.opcionPreSelected}
        item={medicamento}
        // itemSkeleton={TratamientosItem}
        searchStartIcon={null}
        searchEndIcon={this.getSearchBarIcon()}
        onChangeValue={this.onChangeValue.bind(this)}
        searchText={this.state.searchText}
        scrollToTop={this.state.scrollToTop}
        menu={this.props.readOnly ? null : this.getMenu()}
        menuValue={this.props.readOnly ? null : this.getMenuValue()}
        labelDisplays={labelDisplays}
        optionStyle={optionStyle}
        tooltip={(item) => {
          return this.tooltipContent(item);
        }}
      />
    );
  }

  // ---------------------------------
  // ------ life cycle events --------
  // ---------------------------------

  componentDidMount() {
    document.onkeypress = this.modifyEnterKeyPressAsTab.bind(this);
    document.onkeydown = this.pressArrows.bind(this);
  }

  componentDidUpdate() {
    if (document.activeElement != window.$("#searchBar")) {
      //window.$('#searchBar').focus();
    }
  }

  componentWillReceiveProps() {
    this.setState({ searchText: "" });
  }

  // ------------------------
  // ------  methods --------
  // ------------------------
  getMedicamentosPLM() {
    if (this.state.searchText.length > 3) {
      const body = {
        searchText: this.state.searchText,
      };

      internalAPI.getMedicamentosPLM(body, (err, response) => {
        console.log("error", err, response);

        if (err) {
          this.setState({ medicamentosPLM: [] });
        } else {
          const result = [
            ...response
              .filter((e) => e.category === "Medicamentos")
              .map((medicamento, index) => {
                return {
                  id: 0,
                  vc_nombre: medicamento.label,
                };
              }),
          ];
          this.setState({ medicamentosPLM: result });
        }
      });
    }
  }

  getSearchBarIcon() {
    const { opcionMedicamentos } = this.props;
    console.log("opcionMedicamentos", opcionMedicamentos);
    switch (opcionMedicamentos) {
      case "agregar": {
        return (
          <PlayListAdd
            onClick={(e) => {
              e.stopPropagation();
              this.modifyEnterKeyPressAsTab({ ctrlKey: true, keyCode: 13 });
            }}
            style={{ marginRight: 5 }}
          />
        );
      }
      case "dosis":
        return (
          <IconTimePicker
            value={new Date()}
            onChange={(date) => {
              const { medicamento, indexDetalle } = this.addDetalleEdit(
                this.props.medicamento
              );

              console.log("indexDetalle", medicamento.detalles[indexDetalle]);

              medicamento.detalles[
                indexDetalle
              ].administraciones[0].tm_toma = date.format("hh:mm:ss");

              if (
                medicamento.detalles[indexDetalle].id_viaAdministracion != null
              ) {
                this.setState({ searchText: "", isEdited: false }, () =>
                  this.props.callbackMedicamento({
                    medicamento,
                    opcionesModuloNext: "nu_frecuencia",
                    indexDetalle,
                  })
                );
              } else {
                this.setState({ searchText: "", isEdited: false }, () =>
                  this.props.callbackMedicamento({
                    medicamento,
                    opcionesModuloNext: "via",
                    indexDetalle,
                  })
                );
              }
            }}
          />
        );
      case "um_inicio":
      case "nu_inicio":
        const { medicamento, indexDetalle } = this.addDetalleEdit(
          this.props.medicamento
        );
        return (
          <IconDatePicker
            value={new Date()}
            onChange={(date) => {
              console.log("cambiamos la fecha", date);
              console.log(medicamento, indexDetalle);

              medicamento.detalles[indexDetalle].dt_inicio = date.format(
                "YYYY-MM-DD"
              );
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackMedicamento({
                  medicamento,
                  opcionesModuloNext: "um_duracion",
                  umTiempo: null,
                })
              );
            }}
          />
          // <Today
          //     fontSize="small"
          //     onClick={(e) => {
          //         e.stopPropagation()
          //         this.setState({ searchText: '', isEdited: false }, () => this.props.callbackMedicamento({ medicamento, opcionesModuloNext: 'um_duracion', indexDetalle }));
          //     }}
          //     style={{ marginRight: 5 }}
          // />
        );
      case "nu_frecuencia":
        return (
          <Timer
            onClick={(e) => {
              e.stopPropagation();
              const { medicamento, indexDetalle } = this.addDetalleEdit(
                this.props.medicamento
              );
              this.setState({ searchText: "", isEdited: false }, () =>
                this.props.callbackMedicamento({
                  medicamento,
                  opcionesModuloNext: "um_frecuencia",
                  indexDetalle,
                })
              );
            }}
            style={{ marginRight: 5 }}
          />
        );
      case 'um_duracion':
      case "nu_duracion":
        return (
          <IconDatePicker
            value={new Date()}
            onChange={(date) => {
              const { medicamento, indexDetalle } = this.addDetalleEdit(
                this.props.medicamento
              );
              if (new Date(medicamento.detalles[indexDetalle].dt_inicio) < date) {
                medicamento.detalles[indexDetalle].dt_vigencia = date.format(
                  "YYYY-MM-DD"
                );
                this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                  this.props.callbackMedicamento({
                    medicamento,
                    opcionesModuloNext: "um_duracion",
                    umTiempo: null,
                  })
                );
              }
            }}
          />
        );
      default:
        return null;
    }
  }

  titleHelpBar() {
    const {
      medicamento,
      opcionMedicamentos,
      indexDetalleSeleccionado,
    } = this.props;
    let detalle = {};
    if (medicamento) {
      const indexDetalle =
        indexDetalleSeleccionado > -1
          ? indexDetalleSeleccionado
          : getIndexLastDetalle(medicamento.detalles, "dt_vigencia");
      detalle = medicamento ? medicamento.detalles[indexDetalle] : {};
    }

    let umInicio = null;
    let umStringInicio = null;

    let umString = "";
    if (this.props.umTiempo) {
      const um = this.props.unidadesMedida.filter(
        (e, index) => e.id == this.props.umTiempo
      )[0];
      umString =
        um.vc_nombre == "mes"
          ? `${um.vc_nombre}es`
          : `${um.vc_nombre}s`.toLowerCase();
    }

    // console.log('opcion title', opcionMedicamentos)
    switch (opcionMedicamentos) {
      case "historial":
        return "Historial";
      case "conciliar":
        return "Consciliar";
      case "dosis":
        const { vehiculos } = this.props;
        console.log("detalle -----", detalle);
        const vehiculo = vehiculos.find((v) => v.id == detalle.id_vehiculo);
        // console.log('vehiculo', vehiculo)
        const title = vehiculo
          ? `Especifique la dosis en ${vehiculo.vc_abreviaturaPlural}`
          : "Especifique la dosis";
        // const value = this.state.searchText || this.state.isEdited ? this.state.searchText : this.dosisDisplay(detalle.dosis)
        return title;
      case "vehiculo":
        return "Especifique el vehiculo:";
      case "via":
        return "Especifique la via de administración";
      case "um_frecuencia":
        return "Especifique la unidad de tiempo de la frecuencia";
      case "nu_frecuencia":
        umInicio = this.props.unidadesMedida.filter(
          (e, index) => e.id == detalle.id_um_frecuencia
        )[0];
        umStringInicio =
          umInicio.vc_nombre == "mes"
            ? `${umInicio.vc_nombre}es`
            : `${umInicio.vc_nombre}s`;
        return `Especifique la frecuencia en ${umStringInicio}`;

      case "um_inicio":
        return "El medicamento inició hace";
      case "nu_inicio":
        return `¿Hace cuantos ${umString} inició el medicamento?`;

      case "um_duracion":
        return "Especifique la unidad de tiempo de la duración:";
      case "nu_duracion":
        umInicio = this.props.unidadesMedida.filter(
          (e, index) => e.id == detalle.id_um_duracion
        )[0];
        umStringInicio =
          umInicio.vc_nombre == "mes"
            ? `${umInicio.vc_nombre}es`
            : `${umInicio.vc_nombre}s`;
        return `Especifique la duración en ${umStringInicio}`;
      case "um_intervalo":
        return "Especifique la unidad de tiempo de los intervalos:";
      case "nu_intervalo":
        umInicio = this.props.unidadesMedida.filter(
          (e, index) => e.id == detalle.id_um_intervalo
        )[0];
        umStringInicio =
          umInicio.vc_nombre == "mes"
            ? `${umInicio.vc_nombre}es`
            : `${umInicio.vc_nombre}s`;
        return `Especifique el intervalo de repeticion en ${umStringInicio}`;
      case "vc_observaciones":
        return "Especifique las observaciones:";
      default:
        return "Agregar";
    }
  }

  opcionesArray() {
    const {
      date,
      medicamento,
      viasAdministracion,
      medicamentosDgis,
      medicamentosThink,
      vehiculos,
      opcionMedicamentos,
    } = this.props;
    let opciones = [];
    let i = 0;

    // console.log('opcion opciones', opcionMedicamentos)
    switch (opcionMedicamentos) {
      case "agregar":
        return medicamentosDgis
          .filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()))
          .filter((e, index) => index < 30)
          .concat(this.state.medicamentosPLM);
      case "historial":
        return medicamento.detalles
          .map((v) => {
            return { vc_nombre: this.stringDescriptionMedicamento(v) };
          })
          .filter(
            (e) =>
              e.vc_nombre
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()) 
          );
      case "dosis":
        let lastDetalle = null;
        if (medicamento) {
          const indexDetalleSeleccionado =
            this.props.indexDetalleSeleccionado ||
            getIndexLastDetalle(medicamento.detalles);
          lastDetalle = medicamento
            ? medicamento.detalles[indexDetalleSeleccionado]
            : {};
        }
        const vehiculo = vehiculos.find((v) => v.id == lastDetalle.id_vehiculo);
        // console.log('vehiculo', vehiculo)
        opciones = [];
        if (vehiculo) {
          i = 0;
          for (i = 0; i < 200; i++) {
            const opcion = {
              vc_nombre: `${(i * vehiculo.nu_escala + 1).toFixed(
                vehiculo.nu_escala === 1 ? 0 : 2
              )}`,
              valor: i * vehiculo.nu_escala + 1,
              style:
                this.props.indexSeleccionado == i
                  ? styles.containerSelectedHelp
                  : styles.containerHelp,
            };
            opciones.push(opcion);
          }
        }
        return opciones.filter(
          (e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase()) 
        );
      case "vehiculo":
        return vehiculos
          .map((v, i) => {
            return {
              vc_nombre: v.vc_nombre,
              valor: v.id,
              style:
                this.props.indexSeleccionado == i
                  ? styles.containerSelectedHelp
                  : styles.containerHelp,
            };
          })
          .filter(
            (e) =>
              e.vc_nombre
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()) 
          );
      case "via":
        return viasAdministracion
          .map((v, i) => {
            return {
              vc_nombre: v.vc_nombre,
              valor: v.id,
              style:
                this.props.indexSeleccionado == i
                  ? styles.containerSelectedHelp
                  : styles.containerHelp,
            };
          })
          .filter(
            (e) =>
              e.vc_nombre
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()) 
          );
      case "um_frecuencia":
        return [
          {
            vc_nombre: "Minutos",
            valor: 1,
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Horas",
            valor: 2,
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Días",
            valor: 3,
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Semanas",
            valor: 4,
            style:
              this.props.indexSeleccionado == 3
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Meses",
            valor: 5,
            style:
              this.props.indexSeleccionado == 4
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Años",
            valor: 6,
            style:
              this.props.indexSeleccionado == 5
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Dosis unica",
            valor: 34,
            style:
              this.props.indexSeleccionado == 6
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
        ].filter(
          (e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase()) 
        );
      case "nu_frecuencia":
        opciones = [];
        i = 0;
        for (i = 0; i < 200; i++) {
          const opcion = {
            vc_nombre: `${i + 1}`,
            valor: i + 1,
            style:
              this.props.indexSeleccionado == i
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          };
          opciones.push(opcion);
        }
        return opciones.filter(
          (e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase()) 
        );
      case "um_inicio":
        return [
          {
            vc_nombre: "Minutos",
            valor: 1,
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Horas",
            valor: 2,
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Días",
            valor: 3,
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Semanas",
            valor: 4,
            style:
              this.props.indexSeleccionado == 3
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Meses",
            valor: 5,
            style:
              this.props.indexSeleccionado == 4
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Años",
            valor: 6,
            style:
              this.props.indexSeleccionado == 5
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Desde el nacimiento",
            valor: 34,
            style:
              this.props.indexSeleccionado == 6
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "nu_inicio":
        opciones = [];
        i = 0;
        for (i = 0; i < 200; i++) {
          const opcion = {
            vc_nombre: `${i + 1}`,
            valor: `${i + 1}`,
            style:
              this.props.indexSeleccionado == i
                ? styles.containerHelp
                : styles.containerHelp,
          };
          opciones.push(opcion);
        }
        return opciones
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "um_duracion":
        opciones = [
          {
            vc_nombre: "Minutos",
            valor: 1,
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Horas",
            valor: 2,
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Días",
            valor: 3,
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Semanas",
            valor: 4,
            style:
              this.props.indexSeleccionado == 3
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Meses",
            valor: 5,
            style:
              this.props.indexSeleccionado == 4
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Años",
            valor: 6,
            style:
              this.props.indexSeleccionado == 5
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Sin suspender",
            valor: 34,
            style:
              this.props.indexSeleccionado == 6
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
        ];
        return opciones.filter(
          (e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase()) 
        );
      case "nu_duracion":
        opciones = [];
        i = 0;
        for (i = 0; i < 200; i++) {
          const opcion = {
            vc_nombre: `${i + 1}`,
            valor: i + 1,
            style:
              this.props.indexSeleccionado == i
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          };
          opciones.push(opcion);
        }
        return opciones.filter(
          (e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase()) 
        );
      case "um_intervalo":
        return [
          {
            vc_nombre: "Minutos",
            valor: 1,
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Horas",
            valor: 2,
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Días",
            valor: 3,
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Semanas",
            valor: 4,
            style:
              this.props.indexSeleccionado == 3
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Meses",
            valor: 5,
            style:
              this.props.indexSeleccionado == 4
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Años",
            valor: 6,
            style:
              this.props.indexSeleccionado == 5
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
        ].filter(
          (e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase()) 
        );
      case "nu_intervalo":
        opciones = [];
        i = 0;
        for (i = 0; i < 200; i++) {
          const opcion = {
            vc_nombre: `${i + 1}`,
            valor: i + 1,
            style:
              this.props.indexSeleccionado == i
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          };
          opciones.push(opcion);
        }
        return opciones.filter(
          (e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase()) 
        );
      case "vc_observaciones":
        opciones = [
          {
            vc_nombre: "Solo en caso necesario.",
            valor: "Solo en caso necesario.",
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Antes de los alimentos.",
            valor: "Antes de los alimentos.",
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Con los alimentos",
            valor: "Con los alimentos",
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Después de los alimentos.",
            valor: "Después de los alimentos.",
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerSelectedHelp
                : styles.containerHelp,
          },
        ];
        return opciones.filter(
          (e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase()) 
        );
      default:
        return medicamentosDgis
          .filter(
            (e) =>
              e.vc_nombre
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()) 
          )
          .filter((e, index) => index < 30);
    }
  }

  optionsAction() {
    const {
      date,
      medicamento,
      viasAdministracion,
      medicamentosDgis,
      medicamentosThink,
      vehiculos,
      opcionMedicamentos,
    } = this.props;
    const lastDetalle = this.props.medicamento
      ? this.props.medicamento.detalles[
      getIndexLastDetalle(medicamento.detalles, "dt_vigencia")
      ]
      : null;
    let opciones = [];
    let i = 0;
    switch (opcionMedicamentos) {
      case "agregar":
        return this.addMedicamento.bind(this);
      case "dosis":
        return this.setDosis.bind(this);
      case "vehiculo":
        return this.setVehiculo.bind(this);
      case "via":
        return this.setVia.bind(this);
      case "um_frecuencia":
        return this.setUmFrecuencia.bind(this);
      case "nu_frecuencia":
        return this.setNuFrecuencia.bind(this);
      case "um_inicio":
        return this.setUmInicio.bind(this);
      case "nu_inicio":
        return this.setNuInicio.bind(this);
      case "um_duracion":
        return this.setUmDuracion.bind(this);
      case "nu_duracion":
        return this.setNuDuracion.bind(this);
      case "um_intervalo":
        return this.setUmIntervalo.bind(this);
      case "nu_intervalo":
        return this.setNuIntervalo.bind(this);
      case "vc_observaciones":
        return this.setObservaciones.bind(this);
      default:
        return () => { };
    }
  }

  getMenu() {
    const { medicamento, opcionMedicamentos } = this.props;
    let menu = [];

    switch (opcionMedicamentos) {
      case "agregar":
        break;
      // case "via":
      //   break;
      default:
        menu = [
          {
            value: "historial",
            vc_nombre: "Historial",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackMedicamento({
                  id_medicamento: null,
                  medicamento,
                  opcionesModuloNext: "historial",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "vehiculo",
            vc_nombre: "Vehiculo",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackMedicamento({
                  id_medicamento: null,
                  medicamento,
                  opcionesModuloNext: "vehiculo",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "dosis",
            vc_nombre: "Dosis",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackMedicamento({
                  id_medicamento: null,
                  medicamento,
                  opcionesModuloNext: "dosis",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "via",
            vc_nombre: "Vía",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackMedicamento({
                  id_medicamento: null,
                  medicamento,
                  opcionesModuloNext: "via",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "frecuencia",
            vc_nombre: "Frecuencia",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackMedicamento({
                  id_medicamento: null,
                  medicamento,
                  opcionesModuloNext: "um_frecuencia",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "inicio",
            vc_nombre: "Inicio",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackMedicamento({
                  medicamento,
                  opcionesModuloNext: "um_inicio",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "duracion",
            vc_nombre: "Duración",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackMedicamento({
                  id_medicamento: null,
                  medicamento,
                  opcionesModuloNext: "um_duracion",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "observaciones",
            vc_nombre: "Observaciones",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackMedicamento({
                  id_medicamento: null,
                  medicamento,
                  opcionesModuloNext: "vc_observaciones",
                  umTiempo: null,
                })
              );
            },
          },
        ];
        break;
    }

    return menu;
  }

  getMenuValue() {
    const { medicamento, opcionMedicamentos } = this.props;

    switch (opcionMedicamentos) {
      case "historial":
        return "historial";
      case "vehiculo":
        return "vehiculo";
      case "dosis":
        return "dosis";
      case "um_frecuencia":
        return "frecuencia";
      case "nu_frecuencia":
        return `frecuencia`;
      case "um_inicio":
        return "inicio";
      case "nu_inicio":
        return `inicio`;
      case "um_duracion":
        return "duracion";
      case "nu_duracion":
        return `duracion`;
      case "vc_observaciones":
        return "observaciones";
    }
  }

  tooltipContent(item) {
    const { medicamento, opcionMedicamentos, medicamentosDgis } = this.props;
    switch (opcionMedicamentos) {
      case "agregar":
        return (
          <Paper style={{ padding: "15px" }}>
            <h4>
              {
                medicamentosDgis.filter((r) => r.id === item.id)[0]
                  .vc_ng_general
              }
            </h4>

            <h5>Dosis recomendada:</h5>
            <Typography>
              {
                medicamentosDgis.filter((r) => r.id === item.id)[0]
                  .vc_dosis_diaria
              }
            </Typography>

            <h5>Indicación Principal:</h5>
            <Typography>
              {
                medicamentosDgis.filter((r) => r.id === item.id)[0]
                  .vc_principal_indicacion
              }
            </Typography>

            <h5>Contraindicaciones:</h5>
            <Typography>
              {
                medicamentosDgis.filter((r) => r.id === item.id)[0]
                  .vc_contraindicaciones
              }
            </Typography>
          </Paper>
        );
      default:
        return null;
    }
  }

  onChangeValue(event) {
    this.setState(
      { searchText: event.target.value, opcionPreSelected: null },
      this.getMedicamentosPLM
    );
  }

  pressArrows(e) {
    let opcionPreSelected = this.state.opcionPreSelected;
    if (e.keyCode == 38) {
      if (opcionPreSelected == null || opcionPreSelected == 0) {
        opcionPreSelected = 0;
      } else {
        opcionPreSelected = opcionPreSelected - 1;
      }
    } else if (e.keyCode == 40) {
      if (opcionPreSelected == null) {
        opcionPreSelected = 0;
      } else if (this.opcionesArray().length > opcionPreSelected + 1) {
        opcionPreSelected = opcionPreSelected + 1;
      }
    }
    this.setState({ opcionPreSelected, scrollToTop: e.keyCode == 38 });
  }

  modifyEnterKeyPressAsTab(e) {
    if (e && (e.keyCode === 10 || e.keyCode === 13)) {
      switch (this.props.opcionMedicamentos) {
        case "agregar":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.addMedicamento(item, null);
          } else if (
            (e.ctrlKey === true || window.isMobile) &&
            this.state.searchText !== ""
          ) {
            const medicamentoString = {
              vc_nombre: `${this.state.searchText}`,
              id: 0,
            };
            this.setState({ searchText: "", isEdited: false }, () =>
              this.props.callbackMedicamentoString({
                medicamentoString,
                opcionesModuloNext: "agregar",
              })
            );
          }
          break;
        case "dosis":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setDosis(item, null);
          } else if (e.ctrlKey === true && this.state.searchText !== "") {
            const { medicamento, indexDetalle } = this.addDetalleEdit(
              this.props.medicamento
            );
            const asyncTomas = this.state.searchText.split("-");
            const administraciones = [];
            asyncTomas.map((toma, index) => {
              const tomaDouble = toma * 1;
              if (tomaDouble) {
                administraciones.push({
                  nu_vehiculos: tomaDouble,
                  nu_dosis: null,
                  vc_dosis: null,
                  tm_toma: this.setInicioToma(asyncTomas, index),
                });
              } else {
                administraciones.push({
                  nu_vehiculos: null,
                  nu_dosis: null,
                  vc_dosis: toma,
                  tm_toma: this.setInicioToma(asyncTomas, index),
                });
              }
            });
            if (asyncTomas.length > 1) {
              medicamento.detalles[indexDetalle].id_um_frecuencia = 2;
              medicamento.detalles[indexDetalle].nu_frecuencia = parseInt(
                24 / asyncTomas.length
              );
            }
            medicamento.detalles[
              indexDetalle
            ].administraciones = administraciones;
            if (
              medicamento.detalles[indexDetalle].id_viaAdministracion != null
            ) {
              this.setState({ searchText: "", isEdited: false }, () =>
                this.props.callbackMedicamento({
                  medicamento,
                  opcionesModuloNext: "nu_frecuencia",
                  indexDetalle,
                })
              );
            } else {
              this.setState({ searchText: "", isEdited: false }, () =>
                this.props.callbackMedicamento({
                  medicamento,
                  opcionesModuloNext: "via",
                  indexDetalle,
                })
              );
            }
          } else if (this.state.searchText / 1 > 0) {
            const item = {
              vc_nombre: `${this.state.searchText / 1}`,
              valor: `${this.state.searchText / 1}`,
            };
            this.setDosis(item, null);
          }
          break;
        case "vehiculo":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setVehiculo(item, null);
          }
          break;
        case "via":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setVia(item, null);
          }
          break;
        case "um_frecuencia":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setUmFrecuencia(item, null);
          }
          break;
        case "nu_frecuencia":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setNuFrecuencia(item, null);
          } else if (this.state.searchText / 1 > 0) {
            const item = {
              vc_nombre: `${this.state.searchText / 1}`,
              valor: `${this.state.searchText / 1}`,
            };
            this.setNuFrecuencia(item, null);
          }
          break;
        case "um_duracion":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setUmDuracion(item, null);
          }
          break;
        case "nu_duracion":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setNuDuracion(item, null);
          } else if (this.state.searchText / 1 > 0) {
            const item = {
              vc_nombre: `${this.state.searchText / 1}`,
              valor: `${this.state.searchText / 1}`,
            };
            this.setNuDuracion(item, null);
          }
          break;
        case "um_intervalo":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setUmIntervalo(item, null);
          }
          break;
        case "nu_intervalo":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setNuIntervalo(item, null);
          } else if (this.state.searchText / 1 > 0) {
            const item = {
              vc_nombre: `${this.state.searchText / 1}`,
              valor: `${this.state.searchText / 1}`,
            };
            this.setNuIntervalo(item, null);
          }
          break;
        case "vc_observaciones":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setObservaciones(item, null);
          } else if (this.state.searchText != "") {
            const item = {
              vc_nombre: this.state.searchText,
              valor: this.state.searchText,
            };
            this.setObservaciones(item, null);
          } else {
            // const {medicamento, indexDetalle} = this.addDetalleEdit(this.props.medicamento);
            // medicamento.detalles[indexDetalle].vc_observaciones = null;
            // this.setState({ searchText: '', isEdited: false }, () => this.props.callbackMedicamento({ medicamento, opcionesModuloNext: 'siguienteMedicamento' }));
          }
          break;
      }
    }
  }

  // ---------------------------------------
  // ------ Descripcion Medicamento --------
  // ---------------------------------------

  dosisDisplay(dosis) {
    let vc_dosis = "";
    const dosisArr = dosis ? dosis : [];
    dosisArr.map((item, index) => {
      if (index > 0) {
        vc_dosis = vc_dosis + "-";
      }
      if (item.nu_vehiculos) {
        vc_dosis = `${vc_dosis}${item.nu_vehiculos}`;
      } else {
        vc_dosis = `${vc_dosis}${item.vc_dosis}`;
      }
    });
    return vc_dosis;
  }

  timeSince(refDate, date) {
    const seconds = Math.floor((refDate - date) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return `${interval} años`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return `${interval} meses`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return `${interval} días`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return `${interval} horas`;
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return `${interval} minutos`;
    }
    return "un instante";
  }

  statusDxToString(id_estatusDiagnostico) {
    switch (id_estatusDiagnostico) {
      case 1:
        return "presuntivo";
      case 2:
        return "definitivo ó confirmado";
      case 3:
        return "descartado";
      default:
        return "presuntivo";
    }
  }

  isDxActive(dt_vigencia) {
    if (new Date(dt_vigencia) < new Date()) {
      return false;
    }
    return true;
  }

  dosisString(detalle) {
    let dosisString = "";
    const administracionesArr = detalle.administraciones
      ? detalle.administraciones
      : [];
    administracionesArr.map((item, index) => {
      if (index > 0) {
        dosisString = dosisString + " - ";
      }
      const time = item.tm_toma;
      if (item.nu_vehiculos != null && detalle.id_vehiculo != null) {
        if (item.nu_vehiculos == 1) {
          dosisString =
            dosisString +
            `${item.nu_vehiculos}` +
            " " +
            `${this.props.vehiculos.filter(
              (r) => r.id === detalle.id_vehiculo
            )[0].vc_abreviaturaSingular
            } ${item.tm_toma
              ? moment(
                `${moment().format("YYYY-MM-DD")} ${item.tm_toma}`
              ).format("h:mm a")
              : ""
            }`;
        } else {
          dosisString =
            dosisString +
            `${item.nu_vehiculos}` +
            " " +
            `${this.props.vehiculos.filter(
              (r) => r.id === detalle.id_vehiculo
            )[0].vc_abreviaturaPlural
            } ${item.tm_toma
              ? moment(
                `${moment().format("YYYY-MM-DD")} ${item.tm_toma}`
              ).format("h:mm a")
              : ""
            }`;
        }
      } else if (item.vc_dosis != null) {
        dosisString = dosisString + `${item.vc_dosis}`;
      } else {
        dosisString = dosisString + "toma: no especificada";
      }
    });
    return dosisString == "" ? "Dosis:" : dosisString;
  }

  frecuenciastring(item) {
    if (item.nu_frecuencia != null && item.id_um_frecuencia != null) {
      if (item.nu_frecuencia == 1) {
        return (
          " cada " +
          `${item.nu_frecuencia}` +
          " " +
          `${this.props.unidadesMedida.filter(
            (r) => r.id === item.id_um_frecuencia
          )[0].vc_nombre
            }`.toLowerCase()
        );
      } else if (item.id_um_frecuencia == 5) {
        return (
          " cada " +
          `${item.nu_frecuencia}` +
          " " +
          `${this.props.unidadesMedida.filter(
            (r) => r.id === item.id_um_frecuencia
          )[0].vc_nombre
            }`.toLowerCase() +
          "es"
        );
      } else {
        return (
          " cada " +
          `${item.nu_frecuencia}` +
          " " +
          `${this.props.unidadesMedida.filter(
            (r) => r.id === item.id_um_frecuencia
          )[0].vc_nombre
            }`.toLowerCase() +
          "s"
        );
      }
    } else {
      return " frecuéncia: no especificada";
    }
  }

  viaAdministracionString(item) {
    if (item.id_viaAdministracion != null) {
      const viaAdministracion = this.props.viasAdministracion.filter(
        (r) => r.id === item.id_viaAdministracion
      )[0];
      if (viaAdministracion) {
        return " via " + `${viaAdministracion.vc_nombre.toLowerCase()}`;
      } else {
        return "via de administración no especificáda";
      }
    } else {
      return "via de administración no especificáda";
    }
  }

  duracionString(item) {
    if (item.nu_duracion != null && item.id_um_duracion != null) {
      if (item.nu_frecuencia == 1) {
        return (
          " durante " +
          `${item.nu_duracion}` +
          " " +
          `${this.props.unidadesMedida.filter(
            (r) => r.id === item.id_um_duracion
          )[0].vc_nombre
          }`
        );
      } else if (item.id_um_duracion == 5) {
        return (
          " durante " +
          `${item.nu_duracion}` +
          " " +
          `${this.props.unidadesMedida.filter(
            (r) => r.id === item.id_um_duracion
          )[0].vc_nombre
          }` +
          "es"
        );
      } else if (item.id_um_duracion == 34) {
        return " sin suspender.";
      } else {
        return (
          " durante " +
          `${item.nu_duracion}` +
          " " +
          `${this.props.unidadesMedida.filter(
            (r) => r.id === item.id_um_duracion
          )[0].vc_nombre
          }` +
          "s"
        );
      }
    } else {
      return " duracion: no especificada";
    }
  }

  stringDescriptionMedicamento(item) {
    if (this.isDxActive(item.dt_vigencia) && item.id != null) {
      return (
        `${this.dosisString(item)}` +
        `${this.viaAdministracionString(item)}` +
        `${this.frecuenciastring(item)}` +
        `${this.duracionString(item)}` +
        ", actualmente activo, el cual inició hace " +
        `${this.timeSince(new Date(), new Date(item.dt_inicio))}` +
        " y termina en " +
        `${this.timeSince(new Date(item.dt_vigencia), new Date())}.`
      );
    } else if (item.id == null) {
      return (
        `${this.dosisString(item)}` +
        `${this.viaAdministracionString(item)}` +
        `${this.frecuenciastring(item)}` +
        `${this.duracionString(item)}` +
        ", medicamento agregado recientemente, aún no ha sido registrado."
      );
    } else {
      return (
        `${this.dosisString(item)}` +
        `${this.viaAdministracionString(item)}` +
        `${this.frecuenciastring(item)}` +
        `${this.duracionString(item)}` +
        ", actualmente inactivo, el cual inició hace " +
        `${this.timeSince(new Date(), new Date(item.dt_inicio))}` +
        " y estuvo vigente durante " +
        `${this.timeSince(
          new Date(item.dt_vigencia),
          new Date(item.dt_inicio)
        )}.`
      );
    }
  }

  setInicioToma(asyncTomas, index) {
    if (asyncTomas.length > 1 && index > 0) {
      const hora = 6 + (24 / asyncTomas.length) * index;
      return `${hora < 10 ? `0${hora}` : `${hora}`}:00:00`;
    } else {
      return "06:00:00";
    }
  }

  // ----------------------------
  // ------ item methods --------
  // ----------------------------

  addDetalleEdit(medicamento) {
    const indexDetalleSeleccionado =
      this.props.indexDetalleSeleccionado > -1
        ? this.props.indexDetalleSeleccionado
        : getIndexLastDetalle(medicamento.detalles, "dt_vigencia");
    const medicamentoEdit = { ...medicamento };
    console.log(
      " medicamentoEdit.detalles[indexDetalleSeleccionado].id ",
      medicamentoEdit.detalles[indexDetalleSeleccionado].id
    );
    console.log(" indexDetalleSeleccionado incial ", indexDetalleSeleccionado);
    if (medicamentoEdit.detalles[indexDetalleSeleccionado].id != null) {
      const detalleAdd = {
        ...this.props.medicamento.detalles[indexDetalleSeleccionado],
        dt_registro: moment(
          moment(
            this.props.medicamento.detalles[indexDetalleSeleccionado]
              .dt_registro
          ) + 1
        ).format("YYYY-MM-DD HH:mm:ss"),
      };
      medicamentoEdit.detalles[
        indexDetalleSeleccionado
      ].dt_vigencia = moment().format("YYYY-MM-DD HH:mm:ss");
      medicamentoEdit.detalles.splice(
        indexDetalleSeleccionado + 1,
        0,
        detalleAdd
      );
      medicamentoEdit.detalles = medicamentoEdit.detalles.map((d, i) =>
        i > indexDetalleSeleccionado > -1
          ? { ...d, id: null, id_tratamientoActual: null }
          : d
      );
      console.log(
        " medicamentoEdit.detalles final ",
        medicamentoEdit.detalles.length
      );
      console.log(
        " indexDetalleSeleccionado final ",
        indexDetalleSeleccionado + 1
      );
      return {
        medicamento: medicamentoEdit,
        indexDetalle: indexDetalleSeleccionado + 1,
      };
    }
    console.log(" medicamentoEdit.detalles final ", medicamento.detalles);
    console.log(" indexDetalleSeleccionado final ", indexDetalleSeleccionado);

    return { medicamento, indexDetalle: indexDetalleSeleccionado };
  }

  addMedicamento(item, index) {
    this.setState({ searchText: "", isEdited: false }, () =>
      this.props.callbackMedicamento({
        id_medicamento: item.id,
        opcionesModuloNext: "agregar",
      })
    );
  }

  setDosis(item, index) {
    const { medicamento, indexDetalle } = this.addDetalleEdit(
      this.props.medicamento
    );
    const administraciones = [
      {
        nu_vehiculos: item.valor,
        nu_dosis: null,
        vc_dosis: null,
        tm_toma: "06:00:00",
      },
    ];

    medicamento.detalles[indexDetalle].administraciones = administraciones;
    if (medicamento.detalles[indexDetalle].id_viaAdministracion != null) {
      this.setState({ searchText: "", isEdited: false }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "nu_frecuencia",
          indexDetalle,
        })
      );
    } else {
      this.setState({ searchText: "", isEdited: false }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "via",
          indexDetalle,
        })
      );
    }
  }

  setVia(item, index) {
    const { medicamento, indexDetalle } = this.addDetalleEdit(
      this.props.medicamento
    );
    medicamento.detalles[indexDetalle].id_viaAdministracion = item.valor;
    if (
      medicamento.detalles[
        getIndexLastDetalle(medicamento.detalles, "dt_vigencia")
      ].id_um_frecuencia != null
    ) {
      this.setState({ searchText: "", isEdited: false }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "nu_frecuencia",
          indexDetalle,
        })
      );
    } else {
      this.setState({ searchText: "", isEdited: false }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "um_frecuencia",
          indexDetalle,
        })
      );
    }
  }

  setVehiculo(item, index) {
    const { medicamento, indexDetalle } = this.addDetalleEdit(
      this.props.medicamento
    );
    medicamento.detalles[indexDetalle].id_vehiculo = item.valor;
    this.setState({ searchText: "", isEdited: false }, () =>
      this.props.callbackMedicamento({
        medicamento,
        opcionesModuloNext: "dosis",
        indexDetalle,
      })
    );
  }

  setUmFrecuencia(item, index) {
    const { medicamento, indexDetalle } = this.addDetalleEdit(
      this.props.medicamento
    );
    medicamento.detalles[indexDetalle].id_um_frecuencia = item.valor;
    this.setState({ searchText: "", isEdited: false }, () =>
      this.props.callbackMedicamento({
        medicamento,
        opcionesModuloNext: "nu_frecuencia",
        indexDetalle,
      })
    );
  }

  setNuFrecuencia(item, index) {
    const { medicamento, indexDetalle } = this.addDetalleEdit(
      this.props.medicamento
    );
    medicamento.detalles[indexDetalle].nu_frecuencia = item.valor;
    if (
      medicamento.detalles[
        getIndexLastDetalle(medicamento.detalles, "dt_vigencia")
      ].id_um_duracion != null
    ) {
      this.setState({ searchText: "", isEdited: false }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "nu_duracion",
          indexDetalle,
        })
      );
    } else {
      this.setState({ searchText: "", isEdited: false }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "um_duracion",
          indexDetalle,
        })
      );
    }
  }

  setUmInicio(item) {
    const { medicamento, indexDetalle } = this.addDetalleEdit(
      this.props.medicamento
    );

    if (item.valor == 34 && this.props.pacienteActual) {
      medicamento.detalles[
        indexDetalle
      ].dt_inicio = this.props.pacienteActual.detalle.dt_nacimiento;
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "um_duracion",
          umTiempo: null,
        })
      );
    } else {
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "nu_inicio",
          umTiempo: item.valor,
        })
      );
    }
  }

  setNuInicio(item) {
    const { medicamento, indexDetalle } = this.addDetalleEdit(
      this.props.medicamento
    );

    const milisegundos =
      this.props.unidadesMedida.filter(
        (e, index) => e.id == this.props.umTiempo
      )[0].nu_ratio *
      item.valor *
      60000;
    const timeInterval = moment() - milisegundos;
    const fecha = new Date(timeInterval);
    const dt_inicio = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
    console.log(medicamento);
    medicamento.detalles[indexDetalle].dt_inicio = dt_inicio;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackMedicamento({
        medicamento,
        opcionesModuloNext: "um_duracion",
      })
    );
  }

  setUmDuracion(item, index) {
    if (item.valor == 34) {
      const { medicamento, indexDetalle } = this.addDetalleEdit(
        this.props.medicamento
      );
      medicamento.detalles[indexDetalle].nu_duracion = 1;
      medicamento.detalles[indexDetalle].id_um_duracion = item.valor;
      const milisegundos =
        this.props.unidadesMedida.filter((e, index) => e.id == 34)[0].nu_ratio *
        item.valor *
        60000;
      const dt_inicio = medicamento.dt_registro;
      const timeInterval = moment(dt_inicio) + milisegundos;
      const fecha = new Date(timeInterval);
      const dt_vigencia = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
      medicamento.detalles[indexDetalle].dt_vigencia = dt_vigencia;
      medicamento.detalles[indexDetalle].id_um_duracion = 34;
      medicamento.detalles[indexDetalle].nu_intervalo = null;
      medicamento.detalles[indexDetalle].id_um_intervalo = null;
      this.setState({ searchText: "", isEdited: false }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "vc_observaciones",
          indexDetalle,
        })
      );
    } else {
      const { medicamento, indexDetalle } = this.addDetalleEdit(
        this.props.medicamento
      );
      medicamento.detalles[indexDetalle].id_um_duracion = item.valor;
      this.setState({ searchText: "", isEdited: false }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "nu_duracion",
          indexDetalle,
        })
      );
    }
  }
  updateDuracion(detalle, dt_inicio) {
    const milisegundos =
      this.props.unidadesMedida.find(
        (e, index) => e.id == detalle.id_um_duracion
      ).nu_ratio *
      detalle.nu_duracion *
      60000;
    const timeInterval = moment(dt_inicio) + milisegundos;
    const fecha = new Date(timeInterval);
    const dt_vigencia = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
    detalle.dt_inicio = dt_inicio;
    detalle.dt_vigencia = dt_vigencia;
    return detalle;
  }
  setNuDuracion(item, index) {
    const { medicamento, indexDetalle } = this.addDetalleEdit(
      this.props.medicamento
    );
    medicamento.detalles[indexDetalle].nu_duracion = item.valor;
    const milisegundos =
      this.props.unidadesMedida.filter(
        (e, index) => e.id == medicamento.detalles[indexDetalle].id_um_duracion
      )[0].nu_ratio *
      item.valor *
      60000;
    const dt_inicio = medicamento.detalles[indexDetalle].dt_inicio;
    const timeInterval = moment(dt_inicio) + milisegundos;
    const fecha = new Date(timeInterval);
    const dt_vigencia = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
    medicamento.detalles[indexDetalle].dt_vigencia = dt_vigencia;
    medicamento.detalles = medicamento.detalles.map((d, i) =>
      i > indexDetalle && d.id === null
        ? this.updateDuracion(d, medicamento.detalles[i - 1].dt_vigencia)
        : d
    );
    this.setState({ searchText: "", isEdited: false }, () =>
      this.props.callbackMedicamento({
        medicamento,
        opcionesModuloNext: "vc_observaciones",
        indexDetalle,
      })
    );
  }

  setObservaciones(item, index) {
    const { medicamento, indexDetalle } = this.addDetalleEdit(
      this.props.medicamento
    );
    medicamento.detalles[indexDetalle].vc_observaciones = item.valor;
    this.setState({ searchText: "", isEdited: false }, () =>
      this.props.callbackMedicamento({
        medicamento,
        opcionesModuloNext: "siguienteMedicamento",
        indexDetalle,
      })
    );
  }

  setUmIntervalo(item, index) {
    if (item.valor == 34) {
      const { medicamento, indexDetalle } = this.addDetalleEdit(
        this.props.medicamento
      );
      medicamento.detalles[indexDetalle].id_um_intervalo = item.valor;
      this.setState({ searchText: "", isEdited: false }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "vc_observaciones",
          indexDetalle,
        })
      );
    } else {
      const { medicamento, indexDetalle } = this.addDetalleEdit(
        this.props.medicamento
      );
      medicamento.detalles[indexDetalle].id_um_intervalo = item.valor;
      this.setState({ searchText: "", isEdited: false }, () =>
        this.props.callbackMedicamento({
          medicamento,
          opcionesModuloNext: "nu_intervalo",
          indexDetalle,
        })
      );
    }
  }

  setNuIntervalo(item, index) {
    const { medicamento, indexDetalle } = this.addDetalleEdit(
      this.props.medicamento
    );
    medicamento.detalles[indexDetalle].nu_intervalo = item.valor;
    this.setState({ searchText: "", isEdited: false }, () =>
      this.props.callbackMedicamento({
        medicamento,
        opcionesModuloNext: "vc_observaciones",
        indexDetalle,
      })
    );
  }

  sendUnidadMedida(opcion) {
    this.props.callbackMedicamento({
      medicamento: this.props.medicamento,
      opcionesModuloNext: "vehiculo",
    });
  }

  //
  hoverIndex(index) {
    this.props.callbackHover({ indexHover: index });
  }

  searchBarEvent(event) {
    let opcionPreSelected = null;
    const primeraOpcion = this.opcionesArray().filter(
      (e) =>
        e.vc_nombre.toLowerCase().includes(event.target.value.toLowerCase()) !==
        -1
    )[0];
    if (primeraOpcion) {
      if (
        primeraOpcion.vc_nombre.toLowerCase() ==
        event.target.value.toLowerCase()
      ) {
        opcionPreSelected = 0;
      }
    } else {
      opcionPreSelected = null;
    }

    this.setState({
      searchText: event.target.value,
      isEdited: true,
      opcionPreSelected,
    });
  }
}

const styles = {
  container: {
    overflowX: "hidden",
    overflowY: "hidden",
    top: 0,
    bootom: 0,
    margin: 0,
    padding: 0,
  },
  containerSub: {
    overflowX: "hidden",
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    width: "100%",
    margin: 0,
    padding: 0,
    paddingLeft: 1,
    paddingRight: 1,
  },
  containerHelp: {
    margin: 0,
    marginBottom: 2,
    borderRadius: 3,
    overflowY: "hidden",
    padding: 5,
    minHeight: 40,
    color: "black",
    backgroundColor: window.colors.white,
  },
  containerSelectedHelp: {
    margin: 0,
    marginBottom: 2,
    borderRadius: 3,
    overflowY: "hidden",
    padding: 5,
    minHeight: 40,
    marginBottom: 2,
    color: "white",
    backgroundColor: window.colors.itemSelected,
  },
  descriptionHelp: {
    margin: 0,
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5,
    textAlign: "left",
    fontSize: 14,
    // minHeight: 40,
    color: "gray",
  },
  descriptionSelectedHelp: {
    margin: 0,
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5,
    textAlign: "left",
    fontSize: 14,
    // minHeight: 40,
    color: "white",
  },
  stickHelp: {
    margin: 0,
    marginLeft: "85%",
    marginBottom: 2,
    width: 30,
    overflowY: "hidden",
    paddingTop: 0,
    textAlign: "left",
    float: "left",
    fontSize: 12,
    color: window.colors.itemSelected,
  },
  subTitleHelp: {
    margin: 0,
    marginLeft: 10,
    marginRight: 10,
    width: "100%",
    overflowY: "scroll",
    paddingTop: 0,
    textAlign: "left",
    float: "left",
    fontSize: 12,
    color: "black",
  },
  subTitleRightHelp: {
    margin: 0,
    marginLeft: 10,
    marginRight: 10,
    width: "100%",
    paddingTop: 0,
    textAlign: "right",
    float: "right",
    fontSize: 12,
    color: window.colors.itemSelected,
  },
  titleHelp: {
    width: "100%",
    padding: 3,
    height: 37,
    color: "white",
  },
};

const mapStateToProps = (state) => {
  const {
    unidadesMedida,
    vehiculos,
    viasAdministracion,
    medicamentosDgis,
    medicamentosSustanciasActivas,
    medicamentosThink,
    pacienteActual,
  } = state.Think;
  return {
    medicamentosDgis,
    vehiculos,
    viasAdministracion,
    medicamentosSustanciasActivas,
    medicamentosThink,
    unidadesMedida,
    pacienteActual,
  };
};

export default connect(mapStateToProps)(MedicamentosHelpBar);

// return (
//     this.opcionesArray().map((item,index) =>
//         <Popup
//             key={index}
//             trigger={
//                 <div onClick={() =>  this.addMedicamento(item, index) } style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
//                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
//                         {item.vc_nombre}
//                     </div>
//                     <div style={styles.subTitleHelp}>

//                         {item.vc_concentracion}
//                     </div>
//                     <div style={styles.subTitleRightHelp}>
//                         {item.vc_farmaceutica}
//                     </div>
//                     <div style={styles.line}/>
//                 </div>
//             }
//             content={
//                 <div style={{width:'100%', padding: 5, textAlign: 'left'}}>
//                     <h4>{medicamentosDgis.filter(r => r.id === item.id)[0].vc_ng_general}</h4>
//                     <h5>Dosis recomendada:</h5>
//                     <div style={{width:'100%', padding: 0, textAlign: 'left', color: 'gray'}}>
//                         {medicamentosDgis.filter(r => r.id === item.id)[0].vc_dosis_diaria}
//                     </div>
//                     <h5>Indicación Principal:</h5>
//                     <div style={{width:'100%', padding: 0, textAlign: 'left', color: 'gray'}}>
//                         {medicamentosDgis.filter(r => r.id === item.id)[0].vc_principal_indicacion}
//                     </div>
//                     <h5>Contraindicaciones:</h5>
//                     <div style={{width:'100%', padding: 0, textAlign: 'left', color: 'gray'}}>
//                         {medicamentosDgis.filter(r => r.id === item.id)[0].vc_contraindicaciones}
//                     </div>
//                 </div>
//             }
//             hideOnScroll
//             position="left center"
//             on='hover'
//         />
//     )
// );

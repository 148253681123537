import React, { Component } from 'react';
import { Button, Header, Modal, Grid } from 'semantic-ui-react';
import { YellowButton } from './YellowButton';

const { Column } = Grid;


class ConfirmModal extends Component {
  render() {
    const { title, message, visible, onCancel, onAccept } = this.props;

    return (
      <Modal basic dimmer="blurring" open={visible} onClose={onCancel} size='small'>
        <Modal.Content>
          <Grid centered>
            <Column>
              <Header content={title} style={{ color: 'white' }} />
              <p>{ message }</p>

              <Button onClick={onCancel} style={{ borderRadius: '20px' }}>
                Cancelar
              </Button>

              <YellowButton label="Aceptar" onClick={onAccept} />
            </Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}


export { ConfirmModal };

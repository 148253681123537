import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Icon, TextArea, Input } from 'semantic-ui-react';
import TextField from '../../../../../components/TextField';
import ContentEditableDiv from '../../../../../components/ContentEditableDiv';


class PreguntasTexto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valor: '',
            indexSelected: null,
            respuestas: [],
            preguntaActual: null
        };
    }
    onChangeValue({state, inputs}) {
        let value = inputs.map((input, index) => {
            return input.sentence;
        })
        value = value || ''
        this.props.callbackPreg({ state, inputs, value: value, index: this.props.index });
    }
    timeSince(refDate, date) {

        var seconds = Math.floor((refDate - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
        return interval + " años";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
        return interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
        return interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
        return interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
        return interval + " minutos";
        }
        return "un instante";
    }
    statusDxToString(id_estatusDiagnostico){
        switch (id_estatusDiagnostico) {
            case 1:
            return "presuntivo";
            case 2:
            return "definitivo ó confirmado";
            case 3:
            return "descartado";
            default:
            return "presuntivo";
        }
    }
    
    isDxActive(dt_vigencia){
        if ( (new Date(dt_vigencia) < (new Date())) ) {
            return false
        }
        return true
    }
    stringSubsecuencia(lastDetalle) {
        if ( lastDetalle.sn_subsecuente == 1 ) {
            return 'subsecuente, con estatus ';
        }else{
            return 'de primera vez, con estatus ';
        }
    }
    stringDescriptionDx(lastDetalle) {
        if ( this.isDxActive(lastDetalle.dt_vigencia) ) {
            return `${this.stringSubsecuencia(lastDetalle)}` + `${this.statusDxToString(lastDetalle.id_estatusDiagnostico)}` + ', de ' + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))} de evolucion ` + `${lastDetalle.vc_observaciones != null ? `${lastDetalle.vc_observaciones}.` : '.'}`
        }else{
            return `${this.stringSubsecuencia(lastDetalle)}` + `${this.statusDxToString(lastDetalle.id_estatusDiagnostico)}` + ", el cual inició hace " + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` + ' con un periodo de convalecencia registrado de '+ `${this.timeSince(new Date(lastDetalle.dt_vigencia), new Date(lastDetalle.dt_inicio))}` + ', actualmente no se encuentra vigente ó la convalecencia ha terminado' + `${lastDetalle.vc_observaciones != null ? `${lastDetalle.vc_observaciones}.` : '.'}`
        }
    }
    antecedentesImportancia(item) {
        const { pacienteDiagnosticos, pacienteMedicamentos, diagnosticos } = this.props;

        let reporte = '';
        if ( pacienteDiagnosticos ) {
            const dxVigentes = pacienteDiagnosticos.filter(r => this.isDxActive(r.detalles[r.detalles.length - 1].dt_vigencia) && r.id != null);
            dxVigentes.map((dx,index) => {
                const lastDetalle = dx.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro}).reverse()[0];
                switch ( index ) {
                    case 0:
                    if ( dxVigentes.length > 1 ) {
                        reporte = reporte + `Paciente el cual padece ${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre}` + ` ${this.stringDescriptionDx(lastDetalle)}`
                    }else{
                        reporte = reporte + `Paciente el cual solo tiene registrado que padece ${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre}` + ` ${this.stringDescriptionDx(lastDetalle)}`
                    }
                    break;
                    case (dxVigentes.length - 1):
                        reporte = reporte + `y ${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre}` + ` ${this.stringDescriptionDx(lastDetalle)}`
                    break;
                    default:
                    reporte = reporte + `, ${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre}` + ` ${this.stringDescriptionDx(lastDetalle)}`
                    break;
                }
            });
        }
        if ( pacienteDiagnosticos ) {
            const dxVigentes = pacienteDiagnosticos.filter(r => this.isDxActive(r.detalles[r.detalles.length - 1].dt_vigencia) && r.id != null);
            dxVigentes.map((dx,index) => {
                const lastDetalle = dx.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro}).reverse()[0];
                switch ( index ) {
                    case 0:
                    if ( dxVigentes.length > 1 ) {
                        reporte = reporte + `Paciente el cual padece ${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre}` + ` ${this.stringDescriptionDx(lastDetalle)}`
                    }else{
                        reporte = reporte + `Paciente el cual solo tiene registrado que padece ${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre}` + ` ${this.stringDescriptionDx(lastDetalle)}`
                    }
                    break;
                    case (dxVigentes.length - 1):
                        reporte = reporte + `y ${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre}` + ` ${this.stringDescriptionDx(lastDetalle)}`
                    break;
                    default:
                    reporte = reporte + `, ${diagnosticos.filter(r => r.id == lastDetalle.id_diagnostico)[0].vc_nombre}` + ` ${this.stringDescriptionDx(lastDetalle)}`
                    break;
                }
            });
        }
    }
    respuestaValor(item) {

        switch ( item.id_tipoPregunta ) {
            case 1,2:
                if ( item.respuesta != undefined && item.respuesta != null) {
                    return `${item.versiones[0].respuestas.filter(r => r.id === item.respuesta.id_respuesta)[0].vc_respuesta}`;
                }
            case 3:
                if ( item.respuesta != undefined && item.respuesta != null) {
                    return `${item.respuesta.nu_respuesta}`;
                }
            case 4:
                if ( item.respuesta != undefined && item.respuesta != null) {
                    return `${item.respuesta.vc_respuesta}`;
                }else if ( item.id == 101 ) {
                    return this.antecedentesImportancia()
                }
            case 5:
                if ( item.respuesta != undefined && item.respuesta != null) {
                    return `${item.respuesta.dt_respuesta}`;
                }
            case 6:
                if ( item.respuesta != undefined && item.respuesta != null) {
                    return `${item.respuesta.tm_respuesta}`;
                }
            default:
                return null;
        }
        return null;
    }
    respuestaDisplay(respuesta) {
        const {preguntas} = this.props;

        const pregunta = preguntas.filter((item) => item.id == respuesta.id_pregunta)[0]
        switch (pregunta.id_tipoPregunta) {
            case 1:
            return preguntas.respuestas
        }
    }
    opcionesArray() {
        const { date , pacienteActual, respuestas, preguntasRespuestas } = this.props;
        const {preguntaActual} = this.state;
        let opciones = [];
        let i = 0;
        const preguntaVersion = this.preguntaVersion(pacienteActual.detalle);

        if (preguntaVersion != null) {

            const preguntasVersionRespuestas = preguntasRespuestas.filter((e, index) => e.id_preguntaVersion === preguntaVersion.id);

            const respuestasVersion = [];
            preguntasVersionRespuestas.map((item) => {
                const respuesta = respuestas.filter((e, index) => e.id === item.id_respuesta)[0];
                if (respuesta) {
                    respuestasVersion.push(respuesta);
                }
            });

            switch (preguntaActual.id_tipoPregunta) {
                case 1:
                return respuestasVersion.filter((e, index) => e.id_preguntaVersion === preguntaVersion.id).filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) );
                break;
                case 2:
                return respuestasVersion.filter((e, index) => e.id_preguntaVersion === preguntaVersion.id).filter(e => e.vc_respuesta.toLowerCase().includes(this.state.searchText.toLowerCase()) );
                break;
                case 3:
                return [];//this.opcionesNumericas(preguntaVersion);
                break;
                case 5:
                return [];//this.opcionesFechaHora();
                break;
                case 6:
                return [];//this.opcionesFechaHora();
                break;
                default:
                return []
                break;
            };
        } else {
            return []
        }
    }
    renderRespuestasPrevias(){
        this.state.respuestas.map((respuesta, index) => {
            return (
                <p>{this.respuestaDisplay(respuesta)}</p>
            )
        })
    }
    preguntaVersion(pacienteDetalle) {
        const { date, PreguntasNumericas, preguntaActual, respuestas } = this.props;
        const id_sexo = pacienteDetalle.id_sexo;
        const nu_edadMinutos = (new Date() - new Date(pacienteDetalle.dt_nacimiento)) / 60000;
        let version = null;
        preguntaActual.versiones.map((preguntaVersion, index) => {
            if (this.isValidAge(nu_edadMinutos,preguntaVersion) && this.isValidSex(id_sexo, preguntaVersion.id_sexo)) {
                version = preguntaVersion;
            }
        });
        return version;
    }
    render() {
        const shadow = this.state.indexSelected == index ? '1px 1px 10px rgba(0, 0,0, 0.7)' : '1px 1px 20px rgba(0, 0,0, 1)';
        const { item, index } = this.props;
        const respuesta = this.respuestaValor(item) != null ? this.respuestaValor(item) : '';
        const {preguntas} = this.props;
        const opcionesArray = this.state.preguntaActual ? this.opcionesArray() : [];

        return (
            <Container onMouseEnter={() => this.setState({ indexSelected: index })} onMouseLeave={() => this.setState({ indexSelected: null })}  style={{
                padding: 0,
                paddingTop: 0.5,
                backgroundColor:window.colors.itemSelected,
                aling: "center",
                marginBottom:10,
                borderRadius: 5,
                marginTop:0,
                width:"100%",
                borderBottom: 20,
                boxShadow: shadow
            }}>
                <div style={stylesPreguntasTexto.header}>{item.versiones[0].vc_pregunta}
                    <Icon fitted name='wizard' onClick={() => this.props.callbackWizard({ indexSeleccionado: index })} style={{float:'right', color:window.colors.itemSelected,marginRight:25}}/>
                </div>
                <div style={stylesPreguntasTexto.contentTextView}>
                    
                    {/* <TextArea
                        value={respuesta}
                        style={{minHeight: 100, maxWidth: '100%', minWidth: '100%', border: 0, padding: 15}}
                        onChange={this.onChangeValue.bind(this)}
                    >
                    // </TextArea> */}
                    <TextField
                    callbackPregunta={({state, inputs}) => {
                        // this.props.callbackPreg()
                        this.onChangeValue({state, inputs})
                    }}
                    pregunta={item}
                    inputs={item.respuestaStruct || []}
                    index={this.props.index}
                    />
                </div>
            </Container>
        );
    }
}


const stylesPreguntasTexto = {
    container:{
        padding: 0,
        paddingTop: 0.5,
        backgroundColor:window.colors.itemSelected,
        aling: "center",
        marginBottom:10,
        borderRadius: 5,
        marginTop:0,
        width:"100%",
        borderBottom: 20,
    },
    header:{
        width: "100%",
        marginTop:0.5,
        marginBottom:2,
        borderTopLeftRadius:5,
        borderTopRightRadius:5,
        lineHeight: "200%",
        height:30,
        backgroundColor: "rgba(50,50,50,1)",
        color:window.colors.white,
        textAlign: "center",
        paddingRight: 10
    },
    contentTextView:{
        margin:0,
        width:"100%",
        maxHeight:"50%",
        padding: 5,
        paddingBottom:0,
        borderRadius: 5,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        backgroundColor: "rgba(250,250,250,0.96)",
    },
    textView:{
        borderRadius:5,
        textWrapper: true,
        overflowY: "scroll",
        overflowX: "hidden",
        maxHeight:"50%",
        border: 0,
        minHeight: 200,
        padding: 10,
        width: "100%",
        maxWidth: "100%",
        minWidth: "100%",
        marginBottom:-3,
        backgroundColor: "rgba(250,250,250,0.0)",
        maxHeight: window.screen.height - 200,
        color:window.colors.textArea,
        fontSize: 17
    }
};


const mapStateToProps = (state) => {
  const { preguntas, pacienteActual, diagnosticos } = state.Think;
  return { preguntas, pacienteActual, diagnosticos };
};


export default connect(mapStateToProps)(PreguntasTexto);

import http from '../http';


export default {
    general(url, body, method, callback){
        return new Promise((resolve, reject) => {
            const headers = {};
            window.$.ajax({
                url,
                data: body,
                method
            }).then((data, status, xhr, error) => {
                callback(null,data)
            }).fail((data, status, xhr, error) => {
                callback(error, {})
            });
        })
    },
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
// Material UI
import { styled } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

const ProcedimientoItem = styled(ExpansionPanel)({
  marginBottom: '10px',
  '& .cie10': {
    marginRight: 10,
    // color:window.colors.red,
    width: '40px',
  },
  '& .MuiExpansionPanelSummary-root':{
    minHeight: '48px',
    height: '48px'
  },
  
  '& .MuiExpansionPanelDetails-root':{
    borderTop: '1px solid',
  },
  '& .icon': {
    marginLeft: 10,
    float: 'right',
    color: 'gray',
    
    '&.delete': {
        display: 'none',
        cursor: 'pointer',
        color :window.colors.red,
      },
  },

  '&:hover': {
    '& .icon': {
        display: 'none',
        '&.delete': {
            display: 'block',
        }
    },
  },
});

class ProcedimientosItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hoverIndex: null,
            hoverDelete: false,
            indexSeleccionado: null,
            respuestasProcedimiento: [],

            anchorEl: null,
            open: false,
        };
    }

    selectProcedimiento() {
        if (!this.state.hoverDelete) {
            if (this.props.index == this.props.indexSeleccionado && this.props.indexSeleccionado != null ) {
                this.props.callbackSelect({ opcionesModuloNext: 'agregar', sn_seleccionado: 0, indexSeleccionado: this.props.index});
            } else {
                this.props.callbackSelect({ opcionesModuloNext: 'clasificar', sn_seleccionado: 1 , indexSeleccionado: this.props.index});
            }
        }
    }


    // onChangeValue(event) {
    //     this.props.callbackPreg({ value: event.target.value, index: this.props.index });
    //     this.setState({ value: event.target.value });
    // }

    timeSince(refDate, date) {

        var seconds = Math.floor((refDate - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
        return interval + " años";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
        return interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
        return interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
        return interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
        return interval + " minutos";
        }
        return "un instante";
    }

    statusProcedimientoToString(id_estatusProcedimiento){
        switch (id_estatusProcedimiento) {
            case 1:
            return 'programado';
            case 2:
            return 'el cual se esta realizando actualmente';
            case 3:
            return 'realizado';
            case 4:
            return 'ha sido reprogramado';
            case 5:
            return 'se ha cancelado';
            default:
            return 'realizado';
        }
    }
    isDxActive(dt_vigencia){
        if ( (new Date(dt_vigencia) < (new Date())) ) {
            return false
        }
        return true
    }
    stringSubsecuencia(lastDetalle) {
        if ( lastDetalle.sn_subsecuente == 1 ) {
            return 'subsecuente, ';
        }else{
            return 'de primera vez, ';
        }
    }
    stringDescriptionDx(item) {
        const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro}).reverse()[0];
        if (this.isDxActive(lastDetalle.dt_vigencia)) {
            return "Procedimiento " + `${this.stringSubsecuencia(lastDetalle)}` + `${this.statusProcedimientoToString(lastDetalle.id_estatusProcedimiento)}` + ' actualmente en proceso, el cual inició hace ' + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` + `${lastDetalle.vc_observaciones != null ? `, ${lastDetalle.vc_observaciones}.` : '.'}`
        } else if (this.isDxActive(lastDetalle.dt_convalecencia) && !this.isDxActive(lastDetalle.dt_vigencia)) {
            return "Procedimiento " + `${this.stringSubsecuencia(lastDetalle)}` + `${this.statusProcedimientoToString(lastDetalle.id_estatusProcedimiento)}` + ', el cual se realizó hace ' + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` + ' con una duración registrada de '+ `${this.timeSince(new Date(lastDetalle.dt_vigencia), new Date(lastDetalle.dt_inicio))}` + `${lastDetalle.vc_observaciones != null ? `, ${lastDetalle.vc_observaciones}.` : '.'}` + ' actualmente en periodo de convalecencia o recuperación.';
        }else{
            return "Procedimiento " + `${this.stringSubsecuencia(lastDetalle)}` + `${this.statusProcedimientoToString(lastDetalle.id_estatusProcedimiento)}` + ", el cual se realizó hace " + `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` + ' con una duración registrada de '+ `${this.timeSince(new Date(lastDetalle.dt_vigencia), new Date(lastDetalle.dt_inicio))}` + ', actualmente la convalecencia y la recuperación deben haber terminado' + ` como observaciones de importancia ${lastDetalle.vc_observaciones != null ? `, ${lastDetalle.vc_observaciones}.` : ' sin observaciones de importancia.'}`
        }
    }
    colorDx(lastDetalle) {
        if ( this.isDxActive(lastDetalle.dt_vigencia) ) {
            if ( lastDetalle.id != null ) {
                return window.colors.green;
            }else{
                return window.colors.itemSelected;
            }
        }else{
            return window.colors.red;
        }
    }

    deleteItem(item) {
        if (item.id == null) {
            this.props.callbackDelete({ opcionesModuloNext: 'agregar', sn_seleccionado: 0, indexSeleccionado: null, item });
        } else {
            this.props.callbackDelete({ opcionesModuloNext: 'estatusFinal', sn_seleccionado: 0, indexSeleccionado: null, item });
        }
    }
    render() {
        const { procedimientos, item, index, indexSeleccionado, sn_printVersion } = this.props;
        const shadow = this.state.hoverIndex === index ? '1px 1px 20px rgba(0, 0,0, 1)' : '1px 0px 10px rgba(0, 0,0, 0.7)';

        const description = this.stringDescriptionDx(item);
        const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro}).reverse()[0];

        if( sn_printVersion ){
            return (
                <div key={index} style={stylesDx.containerPrint}>
                    <div style={{
                        width: "100%",
                        margin:0,
                        marginBottom: 1,
                        padding:0,
                    }}>
                        <div style={{...stylesDx.titlePrint, color:window.colors.textPrint}}>
                        {`${procedimientos.filter(r => r.id === lastDetalle.id_procedimiento)[0].vc_cveCIE9} - ${procedimientos.filter(r => r.id === lastDetalle.id_procedimiento)[0].vc_nombre.toLowerCase()}`}
                        </div>
                        <div readOnly value={description}
                            style={{
                                width:"100%",
                                float: "right",
                                fontSize: 14,
                                borderRadius: 0,
                                border: 0 ,
                                margin: 0,
                                marginTop: 1,
                                padding: 0,
                                textAlign: 'justify',
                                resize: 'none',
                                color:window.colors.textPrint
                            }}
                        >
                            {description}
                        </div>
                    </div>
                </div>
            );
        }
        
        let anchorEl; 

        let handleClick = (event) => {
            event.stopPropagation();
            anchorEl = event.currentTarget;
            const open = true;

            this.setState({anchorEl, open})
        }

        let handleClose = () => {
            anchorEl = null;
            const open = false;

            this.setState({anchorEl, open})
        }
        const color = this.colorDx(lastDetalle);

        return (
            <ProcedimientoItem
                expanded={true}
                onChange={(event, expanded)=>{
                    this.selectProcedimiento()
                }}
                style={{ width: index == indexSeleccionado ? 'calc(100% + 8px)' : '100%', borderRadius: 8, borderRight: index == indexSeleccionado ? `8px solid ${color}` : null }}
            >
                <ExpansionPanelSummary
                expandIcon={null}
                style={{ borderBottom: `1px solid ${color}` }}
                >
                    <Typography className="cie10" style={{color:color}}> {procedimientos.filter(r => r.id === lastDetalle.id_procedimiento)[0].vc_cveCIE9} </Typography>
                    <Typography>
                        {procedimientos.filter(r => r.id === lastDetalle.id_procedimiento)[0].vc_nombre}
                        <Icon name='delete' onClick={handleClick}/>

                        <Popover 
                            open={this.state.open}
                            anchorEl={this.state.anchorEl}
                            onClose={handleClose}
                            onClick={(e) => e.stopPropagation()}
                            anchorOrigin={{ vertical: 'center', horizontal: 'center', }}
                            transformOrigin={{ vertical: 'center', horizontal: 'center', }}
                        >
                            <div style={{padding: '20px', height:'100px', textAlign: 'center'}}>
                                <Typography>¿Seguro de querer eliminar el procedimiento?</Typography>
                                <Button size="small" color="primary" onClick={(e)=>{
                                    e.stopPropagation();
                                    this.deleteItem(item)
                                    handleClose()
                                }}>Aceptar</Button>
                                <Button size="small" onClick={handleClose}>Cancelar</Button>
                            </div>
                        </Popover>
                    </Typography>
                </ExpansionPanelSummary>
                
                <ExpansionPanelDetails style={{borderColor: this.colorDx(lastDetalle)}}>
                    <Typography>
                        {description}
                    </Typography>
                </ExpansionPanelDetails>
                {/* <Divider />
                <ExpansionPanelActions>
                    <Button size="small" color="primary">Historial</Button>
                </ExpansionPanelActions> */}
            </ProcedimientoItem>
        );

        // if (this.state.sn_selected) {
        //     return (
        //         <Container key={index} style={stylesDx.container}>
        //             <div style={stylesDx.subContainer}>
        //                 <div style={stylesDx.title}>
        //                     {index + 1}
        //                 </div>
        //                 <div style={stylesDx.title}>
        //                 {procedimientos.filter(r => r.id == item.detalles[item.detalles.length - 1])[0].vc_nombre}
        //                 </div>
        //                 <div style={stylesDx.line}/>
        //             </div>
        //         </Container>
        //     );
        // } else if (!sn_printVersion) {
        //     const description = this.stringDescriptionDx(item);
        //     const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro}).reverse()[0];
        //     return (
                // <Container key={index} style={stylesDx.container}>
                //     <div onClick={() => this.selectProcedimiento() } onMouseEnter={() => this.setState({ hoverIndex: index })} onMouseLeave={() => this.setState({ hoverIndex: null })} style={{
                //         backgroundColor: this.colorDx(lastDetalle),
                //         width: "100%",
                //         margin:0,
                //         marginBottom: 1,
                //         padding:0,
                //         minHeight: 90,
                //         borderRadius: 4,
                //         boxShadow: shadow,
                //         opacity: (this.props.indexSeleccionado != index && this.props.indexSeleccionado != null ? 1 : 0.9)
                //     }}>
                //         <div style={stylesDx.stick}>
                //             <div style={{textAlign: "center", verticalAlign: "middle",height: "100%", width: "100%"}}></div>
                //         </div>
                //         <div style={{
                //             color:this.colorDx(lastDetalle),
                //             width: "6%",
                //             textBorderLeft: 5,
                //             height: 30,
                //             borderTopLeftRadius: 4,
                //             float: "left",
                //             textAlign: "center",
                //             backgroundColor: "rgba(50,50,50,1)",
                //             margin: 0,
                //             marginTop: 1,
                //             lineHeight: 2
                //         }}>
                //             {procedimientos.filter(r => r.id === lastDetalle.id_procedimiento)[0].vc_cveCIE9}
                //         </div>
                //         <div style={stylesDx.title}>
                //             {procedimientos.filter(r => r.id === lastDetalle.id_procedimiento)[0].vc_nombre} 
                //             {this.state.hoverIndex === index ? <Icon name='delete' onClick={() => this.deleteItem(item) } onMouseEnter={() => this.setState({ hoverDelete: true })} onMouseLeave={() => this.setState({ hoverDelete: false })} style={{position:'absolute',marginLeft:8,float:'right', color:window.colors.red}}/> :''}
                //         </div>
                //         <div readOnly value={description}
                //             style={{
                //                 width:"99%",
                //                 float: "right",
                //                 fontSize: 12,
                //                 padding: 10,
                //                 borderRadius: 0,
                //                 border: 0 ,
                //                 margin: 0,
                //                 marginTop: 1,
                //                 borderBottomLeftRadius: 4,
                //                 height: 58,
                //                 maxHeight: 58,
                //                 maxWidth: '99%',
                //                 minWidth: '99%',
                //                 textAlign: 'justify',
                //                 resize: 'none',
                //                 color: 'gray',
                //                 backgroundColor: 'white',
                //             }}
                //         >
                //             {description}
                //         </div>
                //     </div>
                // </Container>
        //     );
        // }
        // const description = this.stringDescriptionDx(item);
        // const lastDetalle = item.detalles.sort(function(a, b){return a.dt_registro - b.dt_registro}).reverse()[0];
        
    }
}


const stylesDx = {
    container: {
        width: "100%",
        minHeight: 100,
        margin:0,
        padding:0,
    },
    containerPrint: {
        width: "100%",
        margin:0,
        padding:0,
    },
    subContainer: {
        width: "100%",
        margin:0,
        marginBottom: 5,
        padding:0,
        minHeight: 90,
        borderRadius: 4,
        backgroundColor:window.colors.itemSelected,
        boxShadow: '1px 0px 10px rgba(0, 0,0, 0.7)'
    },
    CIE10:{
        width: "6%",
        textBorderLeft: 5,
        height: 30,
        borderTopLeftRadius: 4,
        float: "left",
        textAlign: "center",
        backgroundColor: "rgba(50,50,50,1)",
        margin: 0,
        marginTop: 1,
        // color:window.colors.itemSelected,
        lineHeight: 2
    },
    title:{
        width: "93%",
        float: "right",
        marginTop: 1,
        marginRight: 0,
        height: 30,
        backgroundColor: "rgba(50,50,50,1)",
        color:window.colors.white,
        lineHeight: 2
    },
    titlePrint:{
        width: "100%",
        float: "right",
        marginTop: 1,
        marginRight: 0,
        lineHeight: 2,
        color:window.colors.itemSelected,
    },
    subTitle:{
        width: "99%",
        float: "right",
        backgroundColor: "rgba(250,250,250,0.96)",
        marginRight:0,
        marginTop: 1,
        marginBottom: 0,
        padding: 0,
        border:0,
        borderBottomLeftRadius: 4,
        color: "gray",
        textAlign:"justify",
        fontSize:15
    },
    textArea:{
        width:"99%",
        float: "right",
        height:"100%",
        color: "gray",
        fontSize: 12,
        padding: 10,
        borderRadius: 0,
        border: 0 ,
        margin: 0,
        marginTop: 1,
        borderBottomLeftRadius: 4,
        height: 58,
        maxHeight : 58,
        maxWidth : "99%",
        minWidth : "99%",
        textAlign:"justify",
        resize: "none"
    },
    stick:{
        width:"1%",
        height: "100%",
        minHeight: 80,
        height: "100%",
        float: "left",
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        margin: 0,
        border: 0 ,
        color:window.colors.white,
        verticalAlign: "middle",
        align: "center"
        // lineHeight: "500%"
    },
    line:{
        width:"100%",
        height: 1,
        float: "left",
        backgroundColor: "gray",
    }
};


const mapStateToProps = (state) => {
  const { procedimientos } = state.Think;
  return { procedimientos };
};


export default connect(mapStateToProps)(ProcedimientosItem);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
} from "app/containers/Think/actions";

import { Icon, Box } from "@material-ui/core";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import HotelIcon from "@material-ui/icons/Hotel";
import RepeatIcon from "@material-ui/icons/Repeat";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  timeline: {
    padding: 0,
    "& .MuiTimelineItem-root:before": {
      flex: 0,
      content: "",
      padding: 0,
    },
  },
  paper: {
    padding: "6px 16px",
    marginBottom: "25px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Index = (props) => {
  const { color = "grey", variant = "default", icon, children } = props;
  const classes = useStyles();

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color={color} variant={variant}>
          {icon && <Icon fontSize="small">{icon}</Icon>}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          {children}
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

Index.propTypes = {
  setHelperSidebarRender: PropTypes.func,
  setPacienteActual: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
  logOut: PropTypes.func,
};

const actions = {
  setHelperSidebarRender,
  setPacienteActual,
  setDisplaySidebar,
  logOut,
};

const mapStateToProps = (state) => {
  const {
    preguntas,
    medicamentosDgis,
    diagnosticos,
    pacienteActual,
  } = state.Think;

  return {
    preguntas,
    medicamentosDgis,
    diagnosticos,
    paciente: pacienteActual,
  };
};

export default connect(mapStateToProps, actions)(Index);

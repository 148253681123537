import http from '../http';

export default {
    getPaises(callback) {
        const headers = { Authorization: window.amplify.store('token') };
        http.get('/app/api/dependenciasApi/paises', headers, callback);
    },
    getEntidades(paisId, callback) {
        const headers = { Authorization: window.amplify.store('token') };
        http.get('/app/api/dependenciasApi/entidades', headers, callback);
    },
    getCiudades(estadoId, callback) {
        const headers = { Authorization: window.amplify.store('token') };
        http.get(`/app/api/dependenciasApi/entidades/${estadoId}`, headers, callback);
    },
    getTipoInstituciones(callback) {
    	const headers = { Authorization: window.amplify.store('token') };
    	http.get('/app/api/tipoInstituciones', headers, callback);
    },
};

import React from "react";
import _ from "lodash";
import {
  Box,
  Divider,
  Popover,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { Search, Check, ExpandMore } from "@material-ui/icons";

import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  selector: {
    color: theme.palette.primary.light,
    cursor: "pointer",
    display: "flex",
    "& > p": {
      marginRight: theme.spacing(1),
    },
  },
}));

function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default ({
  agregar = () => {},
  onChange = () => {},
  data = [],
  value = "",
  hideSearch = false,
  maxRows = 10,
  showAllRows = false,
  showNoSelect = false,
  fullWidthPopOver = true,

  noDataText = "Sin Registros",
  showNoDataText = false,
  multiple = false,
  renderInputValue,
  selectOnOne = false,
  ...props
}) => {
  const classes = useStyles();

  const ref = React.useRef();
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("Seleccionar cuenta");

  const prevValue = usePrevious(value);

  React.useEffect(() => {
    if (data && data.length) setRows(getRows(data));
    if (data && data.length === 1 && selectOnOne) {
      handleSelect(data[0]);
    }
  }, [data]);

  React.useEffect(() => {
    if (JSON.stringify(prevValue) !== JSON.stringify(value)) {
      if (multiple) setSelected(value);
      else setSelected([value]);
    }
  }, [value, data]);

  React.useEffect(() => {
    if (search) {
      const filtered = data.filter(({ text }) =>
        text.toLowerCase().includes(search.toLowerCase())
      );
      setRows(getRows(filtered));
    } else {
      setRows(getRows(data));
    }
  }, [search]);

  React.useEffect(() => {
    const dataSelected = data.filter((v) => selected.includes(v.value));
    const texts = dataSelected.map((v) => v.text);

    if (renderInputValue && dataSelected.length) {
      setInputValue(renderInputValue(dataSelected));
      return;
    }

    if (multiple) setInputValue(texts.join(", "));
    else if (dataSelected.length) setInputValue(texts[0]);
    else setInputValue("Seleccionar cuenta");
  }, [selected, data]);

  const getRows = (a) => (showAllRows ? a : [...a].splice(0, maxRows));

  const handleAgregar = () => {
    agregar();
    setAnchorEl(null);
  };

  const handleSelect = (row) => {
    const isSelected = selected.includes(row.value);
    let newSelected = [];

    if (multiple) {
      if (isSelected) newSelected = selected.filter((v) => v !== row.value);
      else newSelected = [...selected, row.value];
    } else {
      if (isSelected) newSelected = [];
      else newSelected = [row.value];
    }

    setSelected(newSelected);
    const dataSelected = data.filter((v) => newSelected.includes(v.value));

    if (multiple) {
      onChange(dataSelected);
    } else if (dataSelected.length) {
      onChange(dataSelected[0]);
    } else {
      onChange(row);
    }

    if (!multiple) {
      setAnchorEl(null);
      setSearch("");
    }
  };

  return [
    <Box
      className={classes.selector}
      onClick={(e) => {
        if (props.preventDefault) e.preventDefault();
        if (data.length === 1 && selectOnOne) return;
        setAnchorEl(e.target);
        setSearch("");
        setRows(getRows(data));
      }}
    >
      <Typography color="inherit">{inputValue}</Typography>
      <ExpandMore />
    </Box>,
    <Popover
      key="popover"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      PaperProps={{
        style: {
          minWidth: 200,
          width: fullWidthPopOver ? ref?.current?.clientWidth : undefined,
          // width: "auto",
        },
      }}
    >
      <Box
        padding={0}
        style={{
          maxHeight: "300px",
          display: "flex",
          minWidth: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          padding={0}
          style={{
            flex: "1 1 auto",
            overflowY: "auto",
          }}
        >
          <ListItem
            key="noSelect"
            button
            onClick={() => handleSelect({ value: null, text: "" })}
          >
            <ListItemText primary={<em>* Sin selección</em>} />
          </ListItem>

          {data.map((row, index) => (
            <ListItem key={index} button onClick={(_) => handleSelect(row)}>
              <ListItemText primary={row.text} />
              {selected.includes(row.value) && (
                <ListItemIcon style={{ justifyContent: "flex-end", width: 20 }}>
                  <Check color="primary" />
                </ListItemIcon>
              )}
            </ListItem>
          ))}

          <Button
            variant="text"
            color="primary"
            fullWidth
            onClick={handleAgregar}
          >
            Agregar cuenta
          </Button>
        </Box>
      </Box>
    </Popover>,
  ];
};

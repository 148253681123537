import React from "react";
import { connect } from "react-redux";

import { Box, Button } from "@material-ui/core";

import SubMenuModulos from "app/material-ui/SubMenuModulos";
import ConfirmAlert from "app/material-ui/ConfirmAlert";

import ModalForm from "app/sales/components/Productos/ModalForm";
import Sidebar from "app/sales/components/Productos/Sidebar";
import Table from "app/sales/components/Common/Table";

import { columns } from "app/sales/containers/Productos/fields";
import useCrearProducto from "app/sales/hooks/useCrearProducto";

import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "app/containers/Think/actions";
import useApi from "app/sales/hooks/useApi";

const Index = (props) => {
  const api = useApi("productos");

  const [data, setData] = React.useState([]);
  const [rowSelected, setRowSelected] = React.useState({
    index: -1,
    row: null,
  });

  const { ModalForm, onOpen } = useCrearProducto();

  const [modalConfirm, setModalConfirm] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    console.log(api);
    // api.listado().then((res) => {
    //   setData(res.items);
    // });
  }, []);

  React.useEffect(() => {
    if (rowSelected.row) {
      handleSelect(null, -1);
    }
  }, [data, searchText]);

  React.useEffect(() => {
    props.setHelperSidebarRender(() =>
      rowSelected.row ? (
        <Sidebar
          data={rowSelected.row}
          editar={() => onOpen(rowSelected.row)}
          eliminar={() => setModalConfirm(true)}
        />
      ) : null
    );
    props.setDisplaySidebar(Boolean(rowSelected.row));
  }, [rowSelected]);

  const handleSelect = (row, index) => {
    const newRowSelected = { index: -1, row: null };

    if (rowSelected.index !== index) {
      newRowSelected.index = index;
      newRowSelected.row = row;
    }

    setRowSelected(newRowSelected);
  };

  const eliminar = async () => {
    await api.eliminar(rowSelected.row.id);

    const { items } = await api.listado();
    setData(items);

    setModalConfirm(false);
    handleSelect(null, -1);
  };

  const guardar = async (data) => {
    if (rowSelected.row) await api.editar(rowSelected.row.id, data);
    else await api.guardar(data);

    const { items } = await api.listado();
    setData(items);

    setModalForm(false);
  };

  return (
    <Box>
      <SubMenuModulos
        title={
          <Button
            color="inherit"
            onClick={() => {
              handleSelect(null, -1);
              onOpen();
            }}
          >
            Agregar
          </Button>
        }
        type={"search"}
        searchText={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <Table
        title="Productos"
        search={searchText}
        columns={columns}
        data={data}
        onClick={handleSelect}
      />

      {/* {modalForm && (
        <ModalForm
          open
          close={() => setModalForm(false)}
          accept={guardar}
          data={rowSelected.row}
        />
      )} */}
      <ModalForm />

      {modalConfirm && (
        <ConfirmAlert
          title={`¿Desea eliminar el producto: ${rowSelected.row?.vc_descripcion}?`}
          onAccept={eliminar}
          onCancel={() => setModalConfirm(false)}
        />
      )}
    </Box>
  );
};

const actions = { setHelperSidebarRender, setDisplaySidebar };
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(Index);

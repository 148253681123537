import React from 'react';
import { Box } from '@material-ui/core';
import { position, offset } from 'caret-pos';

class TextFieldThink extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: 'someValue'
    };
  }
  
  render() {
//     const input = document.querySelector('.foo');
// const pos = position(input); // { left: 15, top: 30, height: 20, pos: 15 }
// const off = offset(input); 
    return (
      <Box contentEditable onKeyDown={(e) => {
        e.stopPropagation()
        e.preventDefault()
        console.log(e);
      }} style={{ width: '100%', height: '100%', padding: 16}}>

      </Box>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: 'otherValue'
    });
  }
}

export default TextFieldThink;

import { PACIENTE_ACTUAL } from '../actions/types';

export default (state, INITIAL_STATE, action) => {
    switch (action.type) {
        case PACIENTE_ACTUAL:
		return { ...state, pacienteActual: action.payload};
		default:
			return null;
	}
};

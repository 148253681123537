import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Grid, Menu, Dropdown, Input, Icon, Button } from 'semantic-ui-react';
import QrReader from 'react-qr-reader';
import { ProgressBar } from '../../../../components';
import notifications from '../../../../utils/notifications';
const colors = window.colors;
class UsuariosHelpBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            indexHover: null,
            indexSelected: this.props.indexSelected,
            opcionPreSelected: null,
            loading: false,
            showQR: false,
            delay: 300,
            usuario: { detalle: {} },
            opcionUsuario: 'vc_curp',
            steps: [
                {
                    id: 'vc_curp',
                    vc_nombre: 'CURP',
                    placeholder: 'Capture...',
                    siguiente: 'vc_apellidoPaterno',
                    anterior: null,
                    allowQR: true,
                    validacion: null,
                    vc_icon: 'qrcode',
                    sn_require: true
                },
                {
                    id: 'vc_apellidoPaterno',
                    vc_nombre: 'Apellido Paterno',
                    placeholder: 'Capture...',
                    siguiente: 'vc_apellidoMaterno',
                    anterior: 'vc_curp',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'user outline',
                    sn_require: true
                },
                {
                    id: 'vc_apellidoMaterno',
                    vc_nombre: 'Apellido Materno',
                    placeholder: 'Capture...',
                    siguiente: 'vc_nombre',
                    anterior: 'vc_apellidoPaterno',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'user outline',
                    sn_require: false
                },
                {
                    id: 'vc_nombre',
                    vc_nombre: 'Nombre(s)',
                    placeholder: 'Capture...',
                    siguiente: 'id_sexo',
                    anterior: 'vc_apellidoMaterno',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'user outline',
                    sn_require: true
                },
                {
                    id: 'id_sexo',
                    vc_nombre: 'Seleccione el sexo',
                    placeholder: 'Capture...',
                    siguiente: 'dt_nacimiento',
                    anterior: 'vc_nombre',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'male',
                    vc_icon2: 'female',
                    sn_require: true
                },
                {
                    id: 'dt_nacimiento',
                    vc_nombre: 'Día de la fecha de nacimiento',
                    placeholder: 'Capture...',
                    siguiente: 'mesNac',
                    anterior: 'id_sexo',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'calendar alternate outline',
                    sn_require: true
                },
                {
                    id: 'mesNac',
                    vc_nombre: 'Mes de la fecha de nacimiento',
                    placeholder: 'Capture...',
                    siguiente: 'anoNac',
                    anterior: 'dt_nacimiento',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'calendar alternate outline',
                    sn_require: true
                },
                {
                    id: 'anoNac',
                    vc_nombre: 'Año de la fecha de nacimiento',
                    placeholder: 'Capture...',
                    siguiente: 'id_entidad',
                    anterior: 'mesNac',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'calendar alternate outline',
                    sn_require: true
                },
                {
                    id: 'id_entidad',
                    vc_nombre: 'Entidad de nacimiento',
                    placeholder: 'Capture...',
                    siguiente: 'vc_email',
                    anterior: 'dt_nacimiento',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'point',
                    sn_require: true
                },
                {
                    id: 'vc_email',
                    vc_nombre: 'Correo electronico',
                    placeholder: 'Capture...',
                    siguiente: 'vc_telefono',
                    anterior: 'id_entidad',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'at',
                    sn_require: true
                },
                {
                    id: 'vc_telefono',
                    vc_nombre: 'Telefono Celular',
                    placeholder: 'Capture...',
                    siguiente: 'id_rol',
                    anterior: 'vc_email',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'phone',
                    sn_require: true
                },
                // {
                //     id: 'id_nacionalidad',
                //     vc_nombre: 'Nacionalidad',
                //     placeholder: 'Capture...',
                //     siguiente: 'id_formacion',
                //     anterior: 'vc_telefono',
                //     allowQR: false,
                //     validacion: null,
                //     sn_require: true
                // },
                // {
                //     id: 'id_formacion',
                //     vc_nombre: 'Formacion Academica',
                //     placeholder: 'Capture...',
                //     siguiente: 'id_especialidad',
                //     anterior: 'id_nacionalidad',
                //     allowQR: false,
                //     validacion: null,
                //     sn_require: true
                // },
                // {
                //     id: 'id_especialidad',
                //     vc_nombre: 'Rama médica ó paramédica',
                //     placeholder: 'Capture...',
                //     siguiente: 'vc_cedulaProfesional',
                //     anterior: 'id_formacion',
                //     allowQR: false,
                //     validacion: null,
                //     sn_require: false
                // },
                // {
                //     id: 'vc_cedulaProfesional',
                //     vc_nombre: 'Cedula Profesional',
                //     placeholder: 'Capture...',
                //     siguiente: 'vc_regSSA',
                //     anterior: 'id_formacion',
                //     allowQR: false,
                //     validacion: null,
                //     sn_require: false
                // },
                // {
                //     id: 'vc_regSSA',
                //     vc_nombre: 'Registro SSA para expedir recetas',
                //     placeholder: 'Capture...',
                //     siguiente: 'id_rol',
                //     anterior: 'vc_cedulaProfesional',
                //     allowQR: false,
                //     validacion: null,
                //     sn_require: false
                // },
                {
                    id: 'id_rol',
                    vc_nombre: 'Seleccione tu perfil',
                    placeholder: 'Capture...',
                    siguiente: 'vc_password',
                    anterior: 'vc_regSSA',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'drivers license',
                    sn_require: true
                },
                {
                    id: 'vc_password',
                    vc_nombre: 'Captura la contraseña',
                    placeholder: 'Capture...',
                    siguiente: 'vc_passwordConfirm',
                    anterior: 'id_rol',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'key',
                    sn_require: true
                },
                {
                    id: 'vc_passwordConfirm',
                    vc_nombre: 'Confirma tu contraseña',
                    placeholder: 'Capture...',
                    siguiente: null,
                    anterior: 'vc_password',
                    allowQR: false,
                    validacion: null,
                    vc_icon: 'key',
                    vc_icon2: 'key',
                    sn_require: true
                }
            ]
        };
        this.handleScan = this.handleScan.bind(this);
    }

    componentDidMount() {
        // document.onkeypress = this.modifyEnterKeyPressAsTab.bind(this);
        document.onkeydown = this.modifyEnterKeyPressAsTab.bind(this);
        // window.addEventListener('resize', this.updateDimensions.bind(this));
        window.$('#searchBar').focus();
    }

    componentDidUpdate() {
        window.$('#searchBar').focus();
    }

    componentWillUnmount() {
      document.onkeypress = null;
    //   window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    validarCurp(text) {
        const curp = text;//si obtienes la curp de otra forma.
        if (curp.match(/^([a-z]{4})([0-9]{6})([a-z]{6})([0-9]{2})$/i)) { //AAAA######AAAAAA##
            return true;
        }
        return false;
    }

    updateDimensions() {
		// this.forceUpdate();
    }

    modifyEnterKeyPressAsTab(e) {
        let opcionPreSelected = this.state.opcionPreSelected;
        if (window.event && window.event.keyCode == 13) {
            switch ( this.state.opcionUsuario) {
                case 'vc_curp':
                    if (this.state.searchText !== '') {
                        if (this.validarCurp(this.state.searchText) === false) {
                            notifications.info('Formato de CURP no valida.');
                        } else {
                            const item = {
                                vc_nombre: this.state.searchText,
                                valor: this.state.searchText
                            };
                            this.setCurp(item, null);
                        }
                    } else {
                        this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                    }
                break;
                case 'vc_apellidoPaterno':
                    if (this.state.opcionPreSelected != null) {
                        const item = this.opcionesArray()[this.state.opcionPreSelected];
                        this.setPaterno(item,null);
                    } else if (this.state.searchText !== '') {
                        const item = {
                            vc_nombre: this.state.searchText,
                            valor: this.state.searchText
                        };
                        this.setPaterno(item, null);
                    } else {
                        this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                    }
                break;
                case 'vc_apellidoMaterno':
                    if (this.state.opcionPreSelected != null) {
                        const item = this.opcionesArray()[this.state.opcionPreSelected];
                        this.setMaterno(item, null);
                    } else if (this.state.searchText !== '') {
                        const item = {
                            vc_nombre: this.state.searchText,
                            valor: this.state.searchText
                        };
                        this.setMaterno(item, null);
                    } else {
                        this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                    }
                break;
                case 'vc_nombre':
                    if (this.state.opcionPreSelected != null) {
                        const item = this.opcionesArray()[this.state.opcionPreSelected];
                        this.setNombre(item,null);
                    } else if (this.state.searchText != '') {
                        const item = {
                            vc_nombre: this.state.searchText,
                            valor: this.state.searchText
                        };
                        this.setNombre(item, null);
                    } else {
                        this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                    }
                break;
                case 'id_sexo':
                if (this.state.opcionPreSelected != null) {
                    const item = this.opcionesArray()[this.state.opcionPreSelected]
                    this.setSexo(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'dt_nacimiento':
                    if (this.state.opcionPreSelected != null) {
                        const item = this.opcionesArray()[this.state.opcionPreSelected]
                        this.setDiaNacimiento(item, null);
                    } else if (this.state.searchText != '' &&  (this.state.searchText * 1) >= 1 && (this.state.searchText * 1) <= 31 ) {
                        const item = {
                            vc_nombre: this.state.searchText,
                            valor: (this.state.searchText * 1) <= 9 ? `0${(this.state.searchText * 1)}` : `${(this.state.searchText * 1)}`
                        };
                        this.setDiaNacimiento(item, null);
                    } else {
                        this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                    }
                break;
                case 'mesNac':
                    if (this.state.opcionPreSelected != null) {
                        const item = this.opcionesArray()[this.state.opcionPreSelected]
                        this.setMesNacimiento(item, null);
                    } else if (this.state.searchText != '' &&  (this.state.searchText * 1) >= 1 && (this.state.searchText * 1) <= 12 ) {
                        const item = {
                            vc_nombre: this.state.searchText,
                            valor: (this.state.searchText * 1) <= 9 ? `0${(this.state.searchText * 1)}` : `${(this.state.searchText * 1)}`
                        };
                        this.setMesNacimiento(item, null);
                    } else {
                        this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                    }
                break;
                case 'anoNac':
                    if (this.state.opcionPreSelected != null) {
                        const item = this.opcionesArray()[this.state.opcionPreSelected]
                        this.setAnoNacimiento(item, null);
                    } else if (this.state.searchText != '' &&  (((this.state.searchText * 1) >= 1900 && (this.state.searchText * 1) <= (moment().format('YYYY') * 1) ) || (this.state.searchText * 1) <= 99 )) {
                        const item = {
                            vc_nombre: this.state.searchText,
                            valor: (this.state.searchText * 1) <= 99 ? `19${(this.state.searchText * 1)}` : `${(this.state.searchText * 1)}`
                        };
                        this.setAnoNacimiento(item, null);
                    } else {
                        this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                    }
                break;
                case 'id_entidad':
                if (this.state.opcionPreSelected != null) {
                    const item = this.opcionesArray()[this.state.opcionPreSelected]
                    this.setEntidad(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'vc_email':
                if (this.state.opcionPreSelected != null) {
                    const item = this.opcionesArray()[this.state.opcionPreSelected]
                    if (this.validEmail(item.valor)) {
                        this.setEmail(item, null);
                    }else{
                        notifications.info('E-Mail no es valido.')
                    }
                } else if (this.validEmail(this.state.searchText)) {
                    const item = {
                        vc_nombre: this.state.searchText,
                        valor: this.state.searchText
                    };
                    this.setEmail(item, null);
                } else {
                    notifications.info('E-Mail no es valido.')
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'vc_telefono':
                if (this.state.searchText.replace('(','').replace(')','').replace('-','') / 1 > 0  && this.state.searchText.replace('(','').replace(')','').replace('-','').length >= 10) {
                    const item = {
                        vc_nombre: `${this.state.searchText.replace('(','').replace(')','').replace('-','')}`,
                        valor: `${this.state.searchText.replace('(','').replace(')','').replace('-','')}`
                    };
                    this.setTelefono(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'vc_cedulaProfesional':
                if (this.state.searchText.length === 7 && this.state.searchText * 1 >= 1) {
                    const item = {
                        vc_nombre: `${this.state.searchText}`,
                        valor: `${this.state.searchText}`
                    };
                    this.setCedula(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'vc_regSSA':
                if (this.state.searchText !== '') {
                    const item = {
                        vc_nombre: `${this.state.searchText}`,
                        valor: `${this.state.searchText}`
                    };
                    this.setRegSSA(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'id_rol':
                if (this.state.opcionPreSelected != null) {
                    const item = this.opcionesArray()[this.state.opcionPreSelected]
                    this.setRol(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'vc_password':

                if (this.state.searchText.match(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/i)) {//AAAA######AAAAAA##
                    const usuario = this.state.usuario;
                    usuario.detalle.vc_password = this.state.searchText;
                    this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }else if ( this.state.searchText !== '' ){
                    notifications.info('la contraseña debe contener minimo 8 cracteres, al menos una letra y un numero.')
                }else{
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'vc_passwordConfirm':
                if (this.state.searchText.match(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/i) && this.state.usuario.detalle.vc_password === this.state.searchText) {//AAAA######AAAAAA##
                    const usuario = this.state.usuario;
                    usuario.detalle.vc_passwordConfirm = this.state.searchText;
                    this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }else if ( this.state.searchText !== ''){
                    notifications.info('la contraseña no coincide.')
                }else{
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'id_nacionalidad':
                if (this.state.opcionPreSelected != null) {
                    const item = this.opcionesArray()[this.state.opcionPreSelected]
                    this.setNacionalidad(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'id_especialidad':
                if (this.state.opcionPreSelected != null) {
                    const item = this.opcionesArray()[this.state.opcionPreSelected]
                    this.setEspecialidad(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'id_estadoCivil':
                if (this.state.opcionPreSelected != null) {
                    const item = this.opcionesArray()[this.state.opcionPreSelected]
                    this.setEstadoCivil(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'id_formacion':
                if (this.state.opcionPreSelected != null) {
                    const item = this.opcionesArray()[this.state.opcionPreSelected]
                    this.setFormacion(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'id_religion':
                if (this.state.opcionPreSelected != null) {
                    const item = this.opcionesArray()[this.state.opcionPreSelected]
                    this.setReligion(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
                case 'id_lenguaIndigena':
                if (this.state.opcionPreSelected != null) {
                    const item = this.opcionesArray()[this.state.opcionPreSelected]
                    this.setLenguaIndigena(item, null);
                } else {
                    this.setState({ searchText: '', opcionPreSelected: null, opcionUsuario: this.state.steps.filter((item) => item.id === this.state.opcionUsuario)[0].siguiente });
                }
                break;
            };
        } else if (e.keyCode === 38) {
            if (opcionPreSelected == null || opcionPreSelected === 0) {
                opcionPreSelected = null;
            } else {
                opcionPreSelected = (opcionPreSelected - 1);
            }
            this.setState({opcionPreSelected});
        } else if (e.keyCode === 40) {
            if (opcionPreSelected == null) {
                opcionPreSelected = 0;
            } else if (this.opcionesArray().length > (opcionPreSelected + 1)) {
                opcionPreSelected = (opcionPreSelected + 1);
            }
            this.setState({opcionPreSelected});
        }
    }

    handleScan(data) {
        console.log('CURP: -------->', data)
        if (data) {
            const curpInfo = data.split('|');
            const fecha = curpInfo[6].split('/');
            const usuario = this.state.usuario;

            if (curpInfo[5] === 'HOMBRE' || curpInfo[5] === 'MUJER') {
                usuario.detalle.vc_curp = curpInfo[0];
                usuario.detalle.vc_apellidoPaterno = curpInfo[2];
                usuario.detalle.vc_apellidoMaterno = curpInfo[3];
                usuario.detalle.vc_nombre = curpInfo[4];
                usuario.detalle.id_sexo = curpInfo[5] === 'HOMBRE' ? 1 : 2;
                usuario.detalle.dt_nacimiento = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;
                usuario.detalle.id_entidad = parseInt(curpInfo[8], 10);

                notifications.success('Se registro CURP');
                notifications.success('Se registro Nombre');
                notifications.success('Se registro Apellido Paterno');
                notifications.success('Se registro Apellido Materno');
                notifications.success('Se registro Fecha de Nacimiento');
                notifications.success('Se registro Entidad de Nacimiento');
                notifications.success('Se registro el Sexo');
                this.setState({
                    usuario,
                    showQR: false,
                    opcionUsuario: 'vc_email'
                });
            }else{
                notifications.info('El Codigo QR: no es un formato de CURP')
            }
        }
    }

      handleError(err) {
        console.error(err);
        this.setState({ showReadQR: false });
      }
    // descripcion usuario
    timeSince(refDate, date) {
        const seconds = Math.floor((refDate - date) / 1000);
        let interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
        return interval + " años";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
        return interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
        return interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
        return interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
        return interval + " minutos";
        }
        return "un instante";
    }
     //
    hoverIndex(index) {
        this.props.callbackHover({ indexHover: index });
    }
    searchBarEvent(event) {
        let opcionPreSelected = null;
        const primeraOpcion = this.opcionesArray().filter(e => e.vc_nombre.toLowerCase().includes(event.target.value.toLowerCase()) )[0];
        if (primeraOpcion) {
            if (primeraOpcion.vc_nombre.toLowerCase() === event.target.value.toLowerCase()) {
                opcionPreSelected = 0;
            }
        } else {
            opcionPreSelected = null;
        }
        const usuario = this.state.usuario;
        switch (this.state.opcionUsuario) {
            case 'vc_curp':
            usuario.detalle.vc_curp = null;
            this.setState({ searchText: event.target.value.toUpperCase(), usuario, opcionPreSelected });
            break;
            case 'vc_apellidoPaterno':
            usuario.detalle.vc_apellidoPaterno = event.target.value.toUpperCase();
            this.setState({ searchText: event.target.value.toUpperCase(), usuario, opcionPreSelected });
            break;
            case 'vc_apellidoMaterno':
            usuario.detalle.vc_apellidoMaterno = event.target.value.toUpperCase();
            this.setState({ searchText: event.target.value.toUpperCase(), usuario, opcionPreSelected });
            break;
            case 'vc_nombre':
            usuario.detalle.vc_nombre = event.target.value.toUpperCase();
            this.setState({ searchText: event.target.value.toUpperCase(), usuario, opcionPreSelected });
            break;
            case 'vc_email':
            usuario.detalle.vc_email = null;
            this.setState({ searchText: event.target.value, usuario, opcionPreSelected });
            break;
            case 'vc_telefono':
            usuario.detalle.vc_telefono = event.target.value.toUpperCase();
            this.setState({ searchText: event.target.value.toUpperCase(), usuario, opcionPreSelected });
            break;
            case 'dt_nacimiento':
            if ((event.target.value * 1) <= 31) {
                const diaNac = event.target.value * 1;
                this.setState({ searchText: event.target.value.toUpperCase(), diaNac, opcionPreSelected });
            } else if ( !(event.target.value * 1) ) {
                this.setState({ searchText: '', diaNac: null, opcionPreSelected });
            }
            break;
            case 'mesNac':
            if ((event.target.value * 1) <= 12) {
                const mesNac = event.target.value * 1;
                this.setState({ searchText: event.target.value.toUpperCase(), mesNac, opcionPreSelected });
            }
            break;
            case 'anoNac':
            if ((event.target.value * 1) <= (moment().format('YYYY') * 1)) {
                const anoNac = event.target.value * 1;
                this.setState({ searchText: event.target.value.toUpperCase(), anoNac, opcionPreSelected });
            }
            break;
            case 'vc_password':
            usuario.detalle.vc_password = null;
            usuario.detalle.vc_passwordConfirm = null;
            this.setState({ searchText: event.target.value, usuario, opcionPreSelected });
            break;
            case 'vc_passwordConfirm':
            usuario.detalle.vc_passwordConfirm = null;
            this.setState({ searchText: event.target.value, usuario, opcionPreSelected });
            break;
            default:
            this.setState({ searchText: event.target.value.toUpperCase(), usuario, opcionPreSelected });
            break;
        }
    }
    setCurp(item, index){
        const usuario = this.state.usuario;
        usuario.detalle.vc_curp = item.vc_nombre;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setPaterno(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.vc_apellidoPaterno = item.vc_nombre;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setMaterno(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.vc_apellidoMaterno = item.vc_nombre;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setNombre(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.vc_nombre = item.vc_nombre;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setSexo(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.id_sexo = item.valor;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setDiaNacimiento(item,index){
        const diaNac = item.valor;
        const usuario = this.state.usuario;
        this.setState({ searchText: '', diaNac, opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setMesNacimiento(item,index){
        const mesNac = item.valor;
        const usuario = this.state.usuario;
        this.setState({ searchText: '', mesNac, opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setAnoNacimiento(item,index){
        const anoNac = item.valor;
        const usuario = this.state.usuario;
        if (this.state.diaNac != null && this.state.mesNac != null) {
            usuario.detalle.dt_nacimiento = `${moment(`${anoNac}-${this.state.mesNac}-${this.state.diaNac} 00:00:00`).format('YYYY-MM-DD HH:mm:ss')}`;
            this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
        } else {
            this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: 'dt_nacimiento' });
        }
    }
    setEntidad(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.id_entidad = item.id;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    validEmail(e) {
        if (e.split('@').length < 2) return false;
        var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
        return String(e).search (filter) ;
    }
    setEmail(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.vc_email = item.valor;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setTelefono(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.vc_telefono = item.valor;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setCedula(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.vc_cedulaProfesional = item.valor;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setRegSSA(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.vc_regSSA = item.valor;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setRol(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.id_rol = item.valor;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setEspecialidad(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.id_especialidad = item.id;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setNacionalidad(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.id_nacionalidad = item.id;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setEstadoCivil(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.id_estadoCivil = item.id;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setFormacion(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.id_formacion = item.id;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setReligion(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.id_religion = item.id;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }
    setLenguaIndigena(item,index){
        const usuario = this.state.usuario;
        usuario.detalle.id_lenguaIndigena = item.id;
        this.setState({ searchText: '', opcionPreSelected: null, usuario, opcionUsuario: this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0].siguiente });
    }

    sendToValidate() {
        console.log(this.state.usuario.detalle)
		if (!this.success) {
            notifications.info('Faltan campos requeridos.');
		} else if (this.state.usuario.detalle.vc_email && this.state.usuario.detalle.vc_curp) {
            const body = this.state.usuario.detalle;
            delete body.vc_passwordConfirm;
            this.setState({ validating: true, finish: false });
			window.$.ajax({
				url: `${window.backEndURL}/api/usuariosRegistro`,
				data: body,
				method: 'POST'
			}).then((data, status, xhr, error) => {
				if (data.message) {
                    notifications.success(data.message);
                    this.setState({ validating: false, finish: true });
				}else if (data.error) {
                    notifications.info(data.error);
                    this.setState({ validating: false, finish: false });
                }else{
                    this.setState({ validating: false, finish: false });
                }
			}).fail((data, status, xhr, error) => {
                if (data.statusText) {
                    notifications.info(data.statusText,':verifica tu conexión a internet');
                }
				this.setState({ validating: false, finish: false });
			});
		} else {
			notifications.info('Faltan campos requeridos.');
        }
    }

    opcionesArray() {
        const { nombres, correos, apellidos, entidades, especialidades, formacionAcademica, nacionalidades } = this.props;
        let opciones = [];
        let i = 0;
        switch (this.state.opcionUsuario) {
            case "vc_apellidoPaterno":
            return apellidos.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) ).filter((e, index) => index < 60 );
            case "vc_apellidoMaterno":
            return apellidos.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) ).filter((e, index) => index < 60 );
            case "vc_nombre":
            return nombres.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) ).filter((e, index) => index < 60 );
            case "id_sexo":
            opciones = [
                {
                    vc_nombre: 'Masculino',
                    valor: 1,
                },{
                    vc_nombre: 'Femenino',
                    valor: 2,
                }
            ];
            return opciones.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case 'dt_nacimiento':
            opciones = [];
            i = 0;
            for (i = 0; i < 31; i++) {
                const pre = i < 9 ? '0' : '';
                const opcion = {
                    vc_nombre: `${pre}${i+1}`,
                    valor: `${pre}${i+1}`,
                }
                opciones.push(opcion);
            }
            return opciones.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case 'mesNac':
            opciones = [];
            i = 0;
            for (i = 0; i < 12; i++) {
                const nu = i < 9 ? `0${i+1}` : `${i+1}`;
                const meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octuble','Noviembre','Diciembre']
                const opcion = {
                    vc_nombre: `${nu} - ${meses[i]}`,
                    valor: `${nu}`,
                }
                if ((nu == '01') || (nu == '03') || (nu == '05') || (nu == '07') || (nu == '08') || (nu == '10') || (nu == '12') || (nu == '02' && this.state.diaNac <= 29) || (nu == '04' && this.state.diaNac <= 30) || (nu == '06' && this.state.diaNac <= 30) || (nu == '09' && this.state.diaNac <= 30) || (nu == '11' && this.state.diaNac <= 30)) {
                    opciones.push(opcion);
                }
            }
            return opciones.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case 'anoNac':
            opciones = [];
            i = 0;
            for (i = 0; i < 150; i++) {
                const año = `${moment().format('YYYY')}`;
                const nu = (año - i);
                const opcion = {
                    vc_nombre: `${nu}`,
                    valor: `${nu}`,
                }
                opciones.push(opcion);
            }
            return opciones.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );return opciones.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case "id_entidad":
            return entidades.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case "vc_email":
            opciones = [];
            i = 0;
            for (i = 0; i < correos.length; i++) {
                const opcion = {
                    vc_nombre: `${this.state.searchText.split('@')[0]}${correos[i].valor}`.toLowerCase(),
                    valor: `${this.state.searchText.split('@')[0]}${correos[i].valor}`.toLowerCase(),
                }
                opciones.push(opcion);
            }
            return opciones.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case "id_nacionalidad":
            return nacionalidades.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case "id_formacion":
            return formacionAcademica.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case "id_especialidad":
            return especialidades.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            case "vc_nombreInstitucionEducativa":
            return [];
            case "id_rol":
            opciones = [
                {
                    vc_nombre: 'Médico',
                    valor: 5,
                },{
                    vc_nombre: 'Odontologo(a)',
                    valor: 13,
                },{
                    vc_nombre: 'Nutriologo(a)',
                    valor: 14,
                },{
                    vc_nombre: 'Psicologo(a)',
                    valor: 15,
                },{
                    vc_nombre: 'Enfermero(a)',
                    valor: 9,
                },{
                    vc_nombre: 'Administrador(a)',
                    valor: 2,
                },{
                    vc_nombre: 'Asistente Médico',
                    valor: 12,
                }
            ];
            return opciones.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) );
            default:
            return [];
        };
    }

    renderOpcionesHelpBar() {
        const { date, usuario, opcionUsuario } = this.props;
        switch (this.state.opcionUsuario) {
            case "vc_curp":
            if (this.state.showQR === true) {
                return (
                    <Grid centered style={{ width: '100%', margin:0, padding:3, backgroundColor: 'transparent',boxShadow: '1px 1px 1px rgba(0, 0,0, 0.3)' }}>
                        <QrReader
                            resolution={1000}
                            delay={this.state.delay}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            style={{ width: window.screen.availWidth > window.screen.availHeight ? '40%' : '100%', margin:0, padding:0, borderRadius: 4 }}
                        />
                        <Grid centered style={{ width: '100%', margin:0, padding: 0, backgroundColor: 'transparent', bottom:0, position:'sticky' }} onClick={() =>  this.setState({ showQR: false })}>
                            <Button style={styles.botonCancelar}>
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                );
            }
            return (
                <div style={styles.botonScannear} onClick={() =>  this.setState({ showQR: true })}>
                    <Icon inverted size='large' name='qrcode'/>
                    Escanear
                </div>
            );

            break;
            case "vc_apellidoPaterno":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setPaterno(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "vc_apellidoMaterno":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setMaterno(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "vc_nombre":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setNombre(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "id_sexo":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setSexo(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "dt_nacimiento":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setDiaNacimiento(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        <div id={this.state.opcionPreSelected == index ? 'selectObject' : index } style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
                            {item.vc_nombre.toUpperCase()}
                        </div>
                    </Button>
                )
            );
            case "mesNac":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setMesNacimiento(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "anoNac":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setAnoNacimiento(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "id_entidad":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setEntidad(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "vc_email":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setEmail(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre}
                    </Button>
                )
            );
            case "id_nacionalidad":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setNacionalidad(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "id_estadoCivil":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setEstadoCivil(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "id_especialidad":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setEspecialidad(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "id_formacion":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setFormacion(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "id_rol":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setRol(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "id_religion":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setReligion(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            case "id_lenguaIndigena":
            return (
                this.opcionesArray().map((item,index) =>
                    <Button onClick={() => this.setLenguaIndigena(item, index) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
                        {item.vc_nombre.toUpperCase()}
                    </Button>
                )
            );
            default:
                return this.state.steps.map((step, indexStep) => {
                    const stepReg = this.state.usuario.detalle[step.id] ? this.userValuesDisplay(step,this.state.usuario.detalle[step.id]) : this.state.usuario.detalle.dt_nacimiento && (step.id === 'mesNac' || step.id === 'anoNac') ? this.userValuesDisplay(step,this.state.usuario.detalle.dt_nacimiento) : '---------------';
                    return (
                        <div
                        key={indexStep}
                        onClick={() => this.setState({
                            searchText: '',
                            opcionPreSelected: null,
                            opcionUsuario: step.id
                        })}
                        >
                            <div style={{ width: '100%', padding: 5}}>
                                <div style={{ width: '100%', float: 'left', marginLeft: 5, textAlign: 'left' }}>
                                    {step.vc_nombre}
                                    <div style={{ width: '50%', float: 'right', textAlign: 'left', color: this.colorStatus(step) }}>
                                        {stepReg}
                                        {this.iconDisplay(step)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
            break;
        };
    }
    userValuesDisplay(step, value) {
        switch (step.id) {
            case 'id_nacionalidad':
            return this.props.nacionalidades.filter((e) => e.id === value)[0].vc_nombre;
            case 'id_sexo':
            return value === 1 ? 'MASCULINO' :'FEMENINO';
            case 'dt_nacimiento':
            return moment(value).format('DD');
            case 'mesNac':
            return moment(value).format('MMMM').toUpperCase();
            case 'anoNac':
            return moment(value).format('YYYY');
            case 'id_entidad':
            return this.props.entidades.filter((e) => e.id === value)[0].vc_nombre;
            case 'id_especialidad':
            return this.props.especialidades.filter((e) => e.id === value)[0].vc_nombre;
            case 'id_formacion':
            return this.props.formacionAcademica.filter((e) => e.id === value)[0].vc_nombre;
            case 'id_rol':
            const rol = [
                {
                    vc_nombre: 'Médico'.toUpperCase(),
                    valor: 5,
                },{
                    vc_nombre: 'Odontologo(a)'.toUpperCase(),
                    valor: 13,
                },{
                    vc_nombre: 'Nutriologo(a)'.toUpperCase(),
                    valor: 14,
                },{
                    vc_nombre: 'Psicologo(a)'.toUpperCase(),
                    valor: 15,
                },{
                    vc_nombre: 'Enfermero(a)'.toUpperCase(),
                    valor: 9,
                },{
                    vc_nombre: 'Administrador(a)'.toUpperCase(),
                    valor: 2,
                },{
                    vc_nombre: 'Asistente Médico'.toUpperCase(),
                    valor: 12,
                }
            ].filter((e) => e.valor === value)[0];

            return  rol ? rol.vc_nombre : '' ;
            case 'vc_password':
                if (value) {
                    let ip = 0;
                    let pass = '';
                    for (ip = 0; ip < value.length; ip++) {
                        pass = `${pass}*`;
                    }
                    return pass;
                }
                return '-------------';
            case 'vc_passwordConfirm':
                if (value) {
                    let i = 0;
                    let confirm = '';
                    for (i = 0; i < value.length; i++) {
                        confirm = `${confirm}*`;
                    }
                    return confirm;
                }
                return '-------------';
            default:
            return value;
        }
    }
    onButtonClick() {

    }
    colorStatus(step) {
        if (step.sn_require === true) {
            if (this.state.usuario.detalle[step.id] !== null && this.state.usuario.detalle[step.id]) {
                return 'green';
            } else if ((step.id === 'mesNac' || step.id === 'anoNac') && (this.state.usuario.detalle.dt_nacimiento !== null && this.state.usuario.detalle.dt_nacimiento)) {
                return 'green';
            }
            return 'red';
        }
        if (this.state.usuario.detalle[step.id] !== null && this.state.usuario.detalle[step.id]) {
            return 'green';
        }
        return 'gray';
    }
    statusRequire(step) {
        if (step.sn_require === true) {
            if (this.state.usuario.detalle[step.id] !== null && this.state.usuario.detalle[step.id]) {
                return 'checkmark';
            } else if ((step.id === 'mesNac' || step.id === 'anoNac') && (this.state.usuario.detalle.dt_nacimiento !== null && this.state.usuario.detalle.dt_nacimiento)) {
                return 'checkmark';
            }
            return 'remove';
        }
        if (this.state.usuario.detalle[step.id] !== null && this.state.usuario.detalle[step.id]) {
            return 'checkmark';
        }
        return 'minus';
    }

    iconDisplay(step) {
        return (
            <Icon name={this.statusRequire(step)} style={{ float: 'right', color: this.colorStatus(step) }} />
        );
    }
    iconDisplayOptions() {
        switch(this.state.opcionUsuario) {
            case 'vc_curp':
                return 'qrcode';
            case 'vc_curp':
                return 'qrcode';
                
        }
    }
    renderMenuTitle() {
        let title = this.state.steps.filter((e) => e.id === this.state.opcionUsuario)[0];
        if (!title) {
            title = 'Estas a un paso de ser parte de la comunidad de Thin-k.'
        }
        return (
            <div style={{ width: '100%', maxHeight: '80%' }}>
                <Menu style={{ width: '100%', padding: 0, margin: 0, border: 0 }}>
                    <Dropdown item text={`${title.vc_nombre || 'Editar'}`}  style={{ fontSize: 15, margin: 0, width: '100%', color:colors.itemSelected }}>
                        <Dropdown.Menu size='small' style={{ position: 'absolute', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', width: '100%', height: (window.innerHeight * 0.45) ,backgroundColor: 'white', shadow: '0px, 0px, 0px transparent' }}>
                            <Dropdown.Item style={{ margin: 0, backgroundColor:window.colors.black }}>Datos de la Cuenta</Dropdown.Item>
                            {
                                this.state.steps.map((step, indexStep) => {
                                    const stepReg = this.state.usuario.detalle[step.id] ? this.userValuesDisplay(step,this.state.usuario.detalle[step.id]) : this.state.usuario.detalle.dt_nacimiento && (step.id === 'mesNac' || step.id === 'anoNac') ? this.userValuesDisplay(step,this.state.usuario.detalle.dt_nacimiento) : '---------------';
                                    return (
                                        <Dropdown.Item
                                        key={indexStep}
                                        onClick={() => this.setState({
                                            searchText: '',
                                            opcionPreSelected: null,
                                            opcionUsuario: step.id
                                        })}
                                        >
                                            {step.vc_nombre}
                                            {this.iconDisplay(step)}
                                            <div style={{ width: '50%', float: 'right', color: this.colorStatus(step) }}>
                                                {stepReg}
                                            </div>
                                        </Dropdown.Item>
                                    );
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu>
                <div style={styles.titleHelp}>
                    <Input
                    outline='none'
                    id='searchBar'
                    autoComplete="off"
                    type={ this.state.opcionUsuario === 'vc_password' || this.state.opcionUsuario === 'vc_passwordConfirm' ? 'password' : 'text' }
                    focus placeholder='Capture...'
                    icon={<Icon name={title.vc_icon} style={{color:window.colors.itemSelected}} onClick={() =>  this.setState({ showQR: true })}/>}
                    iconPosition='left'
                    value={ this.state.usuario.detalle[`${this.state.opcionUsuario}`] && !this.state.opcionUsuario.startsWith('id_') ? this.state.usuario.detalle[`${this.state.opcionUsuario}`] : this.state.searchText}
                    onChange={this.searchBarEvent.bind(this)}
                    style={{ margin: 0,width: '100%', height: 37, float: 'left', outline: 'none', borderRadius: 3, marginTop: 15, marginBottom: 15, outline:'none', border: 0 }}
                    />
                </div>
            </div>
        );
    }
    success() {
        let itemsRequired = this.state.steps.filter((item) => item.sn_require === true);
        let success = true;
        itemsRequired.map((item,index) => {
            if (this.state.usuario.detalle[item.id] === null || this.state.usuario.detalle[item.id] === undefined) {
                if (item.id === 'mesNac' || item.id === 'anoNac') {
                    if (!this.state.usuario.detalle.dt_nacimiento) {
                        success = false;
                    }
                } else {
                    success = false;
                }
            }
        });
        return success;
    }
    successRatio() {
        let itemsRequired = this.state.steps.filter((item) => item.sn_require === true);
        let completed = 0;
        itemsRequired.map((item,index) => {
            if (this.state.usuario.detalle[item.id] !== null && this.state.usuario.detalle[item.id] !== undefined) {
                completed = completed + 1;
            }else if (item.id === 'mesNac' || item.id === 'anoNac') {
                if (this.state.usuario.detalle.dt_nacimiento) {
                    completed = completed + 1;
                }
            }
        });
        const ratio = (completed / itemsRequired.length) * 100;
        return parseInt(ratio);
    }
    progressSuccess() {
        // if (!this.success()) {
        return (
            <div style={{height: 10, width: '100%', marginTop: 10 }}>
                <ProgressBar
                    value={30}
                    total={50}
                    rangeColor={colors.itemSelected}
                    backgroundColor={colors.black}
                    percent={this.successRatio()}
                />
            </div>
        );
    }
    render() {
        const { indexSeleccionado, index, opcionUsuario } = this.props;
        const shadow = this.state.indexSelected == index ? '1px 1px 20px rgba(0, 0,0, 1)' : '1px 0px 10px rgba(0, 0,0, 0.7)';
        let completed = 0;

        if (this.state.validating) {
             return (
                <div key={index} style={styles.container}>
                    <Icon name='remove' onClick={() => { this.props.callbackCancel() }} style={{position: 'sticky', float: 'right', marginTop: 5 , color: 'gray'}}/>
                    {/* <Grid
						centered
						style={{
							float: 'center',
							width: '100%',
							minWidth: 150,
							height: 150,
							margin: 0,
							padding: 0
						}}>
						<Grid centered style={styles.logo} />
					</Grid> */}
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', height: 50, textAlign: 'center', color: 'gray', backgroundColor: 'transparent', border: 0, marginTop: 10 }}>
                            ¡Enviando E-Mail de validación!
                        </div>
                    </div>
                </div>
            );
        }
        if (this.state.finish) {
            return (
               <Grid centered key={index} style={styles.container}>
                   <Icon name='remove' onClick={() => { this.props.callbackCancel() }} style={{position: 'sticky', float: 'right', marginTop: 5 , color: 'gray'}}/>
                   {/* <Grid
						centered
						style={{
							float: 'center',
							width: '100%',
							minWidth: 150,
							height: 150,
							margin: 0,
							padding: 0
						}}>
						<Grid centered style={styles.logo} />
					</Grid> */}
                    <div style={{ width: '100%' }}> 
                        <div style={{ width: '100%', height: 50, textAlign: 'center', color: 'gray', backgroundColor: 'transparent', border: 0, marginTop: 10 }}>
                            {`Para completar su registro verifique el correo enviado a ${this.state.usuario.detalle.vc_email}.`}
                        </div>
                    </div>
               </Grid>
           );
        }

        return (
            <div key={index} style={styles.container}>
                <Icon name='remove' onClick={() => { this.props.callbackCancel() } } style={{ position: 'absolute', top: '5px', right: '5px', color: '#fff', }} />
                {this.renderMenuTitle()}

                <div id='scrollHelpBar' style={{...styles.containerSub, padding:0, marginTop: 10}}>
                    {this.renderOpcionesHelpBar()}
                </div>
                {this.progressSuccess()}
                {
                    this.success() ?
                    <button onClick={() => {this.sendToValidate()}} style={{ width: '100%', height: 50, textAlign: 'center', color: 'gray', backgroundColor: 'transparent', border: 0, marginTop: 10 }}>
                        ¡Verifica tus datos y da click aqui!
                    </button>
                    :
                    <div style={{ width: '100%', height: 50, textAlign: 'center', color: 'gray', backgroundColor: 'transparent', border: 0, marginTop: 10 }}>
                        ¡Complete los campos requeridos!
                    </div>
                }
            </div>
        );
    }
}

const styles = {
	container: {
		overflowX: 'hidden',
		overflowY: 'hidden',
        borderRadius: 10,
        margin: 0,
        padding: '30px 20px',
        width: '450px',
        maxWidth: '450px',
        position: 'relative'
    },
    containerSub: {
		overflowX: 'hidden',
        overflowY: 'scroll', 
        WebkitOverflowScrolling: 'touch',
        width: '100%',
        backgroundColor:window.colors.white,
        margin: 0,
        borderRadius:3,
        padding: 3,
        maxHeight: '200px',
    },
    containerHelp: {
        margin:0,
        marginBottom:2,
        borderRadius:3,
        overflowY: "hidden",
        padding: 3,
        width: '100%',
        color: "gray",
        minHeight: 37,
        backgroundColor:window.colors.white,
        border:'solid 1px rgb(200,200,200)',
        boxShadow: '1px 1px 1px rgba(0, 0,0, 0.1)'
    },
    containerSelectedHelp: {
        margin:0,
        minHeight: 37,
        marginBottom:2,
        borderRadius:3,
        overflowY: "hidden",
        padding: 3,
        width: '100%',
        marginBottom: 2,
        color: "white",
        backgroundImage: `linear-gradient(${colors.itemSelectedLight},${colors.itemSelected})`,
    },
    descriptionHelp: {
        margin:0,
        marginTop:5,
        marginLeft:10,
        marginRight:10,
        textAlign: "left",
        fontSize: 14,
        color: "gray"
    },
    descriptionSelectedHelp: {
        margin:0,
        marginTop:5,
        marginLeft:10,
        marginRight:10,
        textAlign: "left",
        fontSize: 14,
        color: "white"
    },
    stickHelp: {
        margin:0,
        marginLeft:"85%",
        marginBottom: 2,
        width: 30,
        overflowY: "hidden",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color:window.colors.itemSelected
    },
    subTitleHelp: {
        margin:0,
        marginLeft:10,
        marginRight:10,
        width: "100%",
        overflowY: "scroll",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color: "black"
    },
    subTitleRightHelp: {
        margin:0,
        marginLeft:10,
        marginRight:10,
        width: "100%",
        paddingTop: 0,
        textAlign: "right",
        float: "right",
        fontSize: 12,
        color:window.colors.itemSelected
    },
	titleHelp:{
        width:"100%",
        margin:0,
        padding:0,
        height: 37,
        color: "gray",
    },
	button: {
		width: '60%',
		marginLeft: '20%',
		marginRight: '20%',
		marginTop: 5,
		marginBottom: 5,
		borderRadius: 5,
		backgroundColor:window.colors.itemSelected,
		color: 'white'
    },
    display: {
        width: '100%',
        height: '100%',
		marginTop: 5,
        marginBottom: 5,
        borderRadius: 10
    },
    stickCURP: {
		position: 'relative',
		height: '100%',
		width: '100%',
		backgroundImage: 'url(assets/images/CURP.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
	},
	logoSmall: {
		position: 'relative',
		marginTop: 25,
		height: 150,
		width: 150,
		backgroundImage: 'url(assets/images/iconLogin.png)',
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	},
    botonCancelar : {
        margin:0,
        marginBottom:2,
        borderRadius:3,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        overflowY: "hidden",
        padding: 10,
        width: window.screen.availWidth > window.screen.availHeight ? '40%' : '100%',
        minHeight: 40,
        textAlign: 'center',
        marginBottom: 2,
        color:window.colors.itemSelected,
    },
    botonScannear : {
        margin:0,
        marginBottom:2,
        borderRadius:3,
        overflowY: "hidden",
        padding: 10,
        width: '100%',
        minHeight: 40,
        textAlign: 'center',
        marginBottom: 2,
        color: "white",
        backgroundImage: `linear-gradient(${colors.itemSelectedLight},${colors.itemSelected})`,
        boxShadow: '1px 1px 1px rgba(0, 0,0, 0.3)'
    },
	logo: {
		float: 'center',
		width: 150,
		height: 150,
		backgroundImage: 'url(assets/images/iconThin-k.png)',
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	}
};


const mapStateToProps = (state) => {
    const { nombres, apellidos, entidades, correos, formacionAcademica, nacionalidades, religiones, lenguasIndigenas, estadosCiviles, especialidades } = state.Think;
    return { nombres, apellidos, entidades, correos, formacionAcademica, especialidades, nacionalidades, religiones, lenguasIndigenas, estadosCiviles };
};


export default connect(mapStateToProps)(UsuariosHelpBar);

import React from "react";
import PropTypes from "prop-types";
import { Input, makeStyles, TextField } from "@material-ui/core";
import { PhoneNumber, Number } from "./Formats";

const useStyles = (textAlign, dense, hasBackground, noMargin) =>
  makeStyles((theme) => ({
    input: {
      padding: dense ? theme.spacing(1) : theme.spacing(2, 1),
      width: "100%",
      borderRadius: 5,
      textAlign,
      ...(noMargin ? { margin: 0 } : {}),
    },
    containerInput: {
      padding: 0,
      width: "100%",
      backgroundColor: hasBackground ? "rgba(200,200,200,0.2)" : undefined,
      borderRadius: 5,
      textAlign,
      ...(noMargin ? { margin: 0 } : {}),
    },
    control: {
      ...(noMargin ? { margin: 0 } : {}),
    },
  }));

const inputComponent = ({ phoneNumber, number }) => {
  if (phoneNumber) return PhoneNumber;
  if (number) return Number;

  return undefined;
};

const Index = React.forwardRef(
  (
    {
      value,
      onChange,
      textAlign,
      getValue,

      dense,
      hasBackground,
      phoneNumber,
      number,
      noMargin,
      ...props
    },
    ref
  ) => {
    const classes = useStyles(textAlign, dense, hasBackground, noMargin)();

    const [inputComponentProps, setInputComponentProps] = React.useState({});

    React.useEffect(() => {
      if (phoneNumber) {
        setInputComponentProps({
          format: "(###) #-##-##-##",
        });
      } else if (number) {
        setInputComponentProps({
          prefix: "",
          thousandSeparator: true,
          decimalScale: 2,
        });
      } else {
        setInputComponentProps({});
      }
    }, [phoneNumber, number]);

    return (
      <TextField
        {...props}
        inputProps={{
          ...inputComponentProps,
          ...(props.inputProps || {}),
        }}
        InputProps={{
          ...(props.InputProps || {}),
          inputComponent: inputComponent({ phoneNumber, number }),
        }}
        value={value}
        onChange={(e) => {
          const response = getValue ? e.target.value : e;
          onChange(response);
        }}
        ref={ref}
      />
    );
  }
);

Index.defaultProps = {
  value: "",
  onChange: () => {},
  textAlign: "left",
  getValue: false,
  dense: false,
  hasBackground: true,
  phoneNumber: false,
  number: false,
  noMargin: false,
};

Index.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  textAlign: PropTypes.string,
  getValue: PropTypes.bool,
  inputProps: PropTypes.object,
  dense: PropTypes.bool,
  hasBackground: PropTypes.bool,
  phoneNumber: PropTypes.bool,
  number: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export default Index;

import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Container, Input, Menu } from "semantic-ui-react";
import HelpBarThink from "../../../../material-ui/HelpBarThink";
import {
  getLastDetalle,
  getIndexLastDetalle,
} from "../../../../utils/repository";

import {
  StaticDatePicker,
  StaticTimePicker,
  LocalizationProvider,
} from "@material-ui/pickers";

import { Button, IconButton, Popover, DialogActions } from "@material-ui/core";
import { Today } from "@material-ui/icons";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const IconDatePicker = (props) => {
  const { value, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [date, setDate] = React.useState(value);
  const select = (newDate) => {
    console.log("------------>", date);
    if (newDate) {
      onChange(moment(newDate));
      setAnchorEl(null);
    } else if (date) {
      onChange(moment(date));
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        size="small"
        style={{ marginLeft: 10, marginRight: 10 }}
        onClick={(e) => {
          setAnchorEl(e.target);
        }}
      >
        <Today />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onInput={(e) => console.log("input")}
      >
        <LocalizationProvider dateAdapter={MomentAdapter}>
          <StaticDatePicker
            allowKeyboardControl
            displayStaticWrapperAs="desktop"
            autoOk
            openTo="date"
            views={["date", "month", "year"]}
            value={value}
            onChange={setDate}
            disableFuture
            onYearChange={(newDate) => {
              setDate(newDate);
              select(newDate);
            }}
          />
        </LocalizationProvider>
        <DialogActions>
          <Button color="primary" onClick={() => setAnchorEl(null)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={select}>
            Aceptar
          </Button>
        </DialogActions>
      </Popover>
    </div>
  );
};

class DiagnosticosHelpBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      indexHover: null,
      indexSelected: this.props.indexSelected,
      opcionPreSelected: null,
      umTiempo: this.props.umTiempo,
      diagnostico: this.props.diagnostico,
    };
  }
  isDxActive(dt_vigencia) {
    if (new Date(dt_vigencia) < new Date()) {
      return false;
    }
    return true;
  }
  render() {
    const { index, opcionDiagnosticos, diagnostico } = this.props;
    const shadow =
      this.state.indexSelected == index
        ? "1px 1px 20px rgba(0, 0,0, 1)"
        : "1px 0px 10px rgba(0, 0,0, 0.7)";
    const results = this.opcionesArray();

    let optionStyle = {
      flexDirection: "column",
    };

    let labelDisplays = [
      { key: "vc_nombre", style: { width: "100%", textAlign: "left" } },
      {
        key: "vc_nombreDiagnostico",
        style: {
          color: window.colors.itemSelected,
          width: "100%",
          textAlign: "left",
        },
      },
      {
        key: "cve_cie10",
        style: {
          color: window.colors.itemSelected,
          textAlign: "right",
          width: "100%",
        },
      },
      {
        key: "vc_observaciones",
        style: { textAlign: "justify", width: "100%", padding: 8 },
      },
      {
        key: "dt_registro",
        style: {
          fontSize: "10",
          textAlign: "right",
          width: "100%",
          borderBottom:
            opcionDiagnosticos === "historial"
              ? "1px solid rgba(200,200,200,0.2)"
              : "",
        },
      },
    ];

    return (
      <HelpBarThink
        header={this.titleHelpBar()}
        searchBar={true}
        menu={this.getMenu()}
        menuValue={this.getMenuValue()}
        options={this.opcionesArray()}
        optionsAction={this.optionsAction()}
        indexSeleccionado={this.state.opcionPreSelected}
        item={diagnostico}
        // itemSkeleton={ProcedimientosItem}
        onChangeValue={this.onChangeValue.bind(this)}
        searchText={this.state.searchText}
        searchEndIcon={this.getSearchBarIcon()}
        labelDisplays={labelDisplays}
        optionStyle={optionStyle}
      />
    );
  }

  // ---------------------------------
  // ------ life cycle events --------
  // ---------------------------------

  componentDidMount() {
    document.onkeypress = this.modifyEnterKeyPressAsTab.bind(this);
    document.onkeydown = this.pressArrows.bind(this);
  }
  componentDidUpdate() {
    const element = document.getElementById("indexSelected");

    if (element) {
      element.scrollIntoView({ block: "end", behavior: "smooth" });
    }
    if (document.activeElement != window.$("#searchBar")) {
      //window.$('#searchBar').focus();
    }
  }

  componentWillReceiveProps() {
    this.setState({ searchText: "" });
  }

  // ------------------------
  // ------  methods --------
  // ------------------------
  // getSearchBarIcon() {
  //     const { opcionDiagnosticos, diagnostico, indexDetalle } = this.props;
  //     switch (opcionDiagnosticos) {
  //         case ('dt_inicio'): {
  //             return (
  //                 <Timer
  //                     onClick={(e) => {
  //                         e.stopPropagation()
  //                         this.setState({ searchText: '', isEdited: false }, () => this.props.callbackMedicamento({ medicamento, opcionesModuloNext: 'um_frecuencia', indexDetalle }));
  //                     }}
  //                     style={{ marginRight: 5 }}
  //                 />
  //             )
  //         }
  //         case ('dt_vigencia'): {
  //             return (
  //                 <Timer
  //                     onClick={(e) => {
  //                         e.stopPropagation()
  //                         this.setState({ searchText: '', isEdited: false }, () => this.props.callbackMedicamento({ medicamento, opcionesModuloNext: 'um_frecuencia', indexDetalle }));
  //                     }}
  //                     style={{ marginRight: 5 }}
  //                 />
  //             )
  //         }
  //         default: {
  //             return null;
  //         }
  //     }
  // }

  getSearchBarIcon() {
    const { opcionDiagnosticos } = this.props;
    switch (opcionDiagnosticos) {
      case "um_inicio":
      case "nu_inicio":
        const diagnostico = this.addDetalleEdit(this.props.diagnostico);
        const indexDetalle = getIndexLastDetalle(diagnostico.detalles);

        return (
          <IconDatePicker
            value={new Date()}
            onChange={(date) => {
              console.log("cambiamos la fecha", date);
              console.log(diagnostico, indexDetalle);

              diagnostico.detalles[indexDetalle].dt_inicio = date.format(
                "YYYY-MM-DD"
              );
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackDx({
                  diagnostico,
                  opcionesModuloNext: "um_duracion",
                  umTiempo: null,
                })
              );
            }}
          />
        );

      default:
        return null;
    }
  }

  titleHelpBar() {
    const { date, diagnosticos, opcionDiagnosticos } = this.props;
    let umString = "";
    if (this.props.umTiempo) {
      const um = this.props.unidadesMedida.filter(
        (e, index) => e.id == this.props.umTiempo
      )[0];
      umString =
        um.vc_nombre == "mes"
          ? `${um.vc_nombre}es`
          : `${um.vc_nombre}s`.toLowerCase();
    }

    switch (opcionDiagnosticos) {
      case "agregar":
        return "Agregar";
      case "historial":
        return "Historial";
      case "clasificar":
        return "Clasifique el diagnóstico CIE-10";
      case "principal":
        return "Es el diagnostigo principal";
      case "status":
        return "Especifique el estatus";
      case "subsecuencia":
        return "¿Es subsecuente?";
      case "um_inicio":
        return "El diagnostico inició hace";
      case "nu_inicio":
        return `¿Hace cuantos ${umString} inició el diagnostico?`;
      case "um_duracion":
        return "El diagnostico duró ó durará:";
      case "nu_duracion":
        return `¿aproximadamente cuantos ${umString} duró ó durará el diagnostico?`;
      case "vc_observaciones":
        return "Observaciones del diagnostico:";
    }
  }
  stringSubsecuencia(lastDetalle) {
    if (lastDetalle.sn_subsecuente == 1) {
      return "subsecuente, con estatus ";
    } else {
      return "de primera vez, con estatus ";
    }
  }
  timeSince(refDate, date) {
    var seconds = Math.floor((refDate - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " años";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " meses";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " días";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " horas";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutos";
    }
    return "un instante";
  }
  statusDxToString(id_estatusDiagnostico) {
    switch (id_estatusDiagnostico) {
      case 1:
        return "presuntivo";
      case 2:
        return "definitivo ó confirmado";
      case 3:
        return "descartado";
      default:
        return "presuntivo";
    }
  }

  stringDescriptionDx(lastDetalle) {
    if (this.isDxActive(lastDetalle.dt_vigencia)) {
      return (
        "Diagnostico " +
        `${this.stringSubsecuencia(lastDetalle)}` +
        `${this.statusDxToString(lastDetalle.id_estatusDiagnostico)}` +
        " actualmente vigente, el cual inició hace " +
        `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` +
        `${
          lastDetalle.vc_observaciones != null
            ? `${lastDetalle.vc_observaciones}.`
            : "."
        }`
      );
    } else {
      return (
        "Diagnostico " +
        `${this.stringSubsecuencia(lastDetalle)}` +
        `${this.statusDxToString(lastDetalle.id_estatusDiagnostico)}` +
        ", el cual inició hace " +
        `${this.timeSince(new Date(), new Date(lastDetalle.dt_inicio))}` +
        " con un periodo de convalecencia registrado de " +
        `${this.timeSince(
          new Date(lastDetalle.dt_vigencia),
          new Date(lastDetalle.dt_inicio)
        )}` +
        ", actualmente no se encuentra vigente ó la convalecencia ha terminado" +
        `${
          lastDetalle.vc_observaciones != null
            ? `, ${lastDetalle.vc_observaciones}.`
            : "."
        }`
      );
    }
  }
  opcionesArray() {
    let opciones = [];
    let i = 0;
    let lastDetalle = null;

    switch (this.props.opcionDiagnosticos) {
      case "agregar":
        return this.props.diagnosticos
          .filter((e) =>
            `${e.vc_nombre} ${e.cve_cie10}`
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 60);
      case "historial": {
        return this.props.diagnostico.detalles
          .filter((e) => e.id > 0)
          .map((v) => {
            return {
              vc_nombreDiagnostico:
                (
                  this.props.diagnosticos.find(
                    (e) => e.id === v.id_diagnostico
                  ) || {}
                ).vc_nombre || "",
              vc_observaciones: v.vc_observaciones || "No hay observaciones",
              dt_registro: moment(v.dt_registro).format(
                "dddd D MMMM YYYY - HH:mm"
              ),
            };
          })
          .filter((e) =>
            e.vc_nombreDiagnostico
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          );
      }
      case "conciliar":
        return this.props.diagnostico.detalles
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "clasificar":
        const dx = this.props.diagnosticos.filter(
          (e, index) => e.id == this.props.diagnostico.id_diagnostico
        )[0];
        return this.props.diagnosticos
          .filter((e, index) =>
            e.cve_cie10.startsWith(dx.cve_cie10.substring(0, 3))
          )
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "principal":
        lastDetalle = this.props.diagnostico
          ? this.props.diagnostico.detalles[
              this.props.diagnostico.detalles.length - 1
            ]
          : null;
        return [
          {
            vc_nombre: "Principal",
            valor: 1,
            style:
              lastDetalle.sn_principal == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Secundario",
            valor: 0,
            style:
              lastDetalle.sn_principal == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "status":
        lastDetalle = this.props.diagnostico
          ? this.props.diagnostico.detalles[
              this.props.diagnostico.detalles.length - 1
            ]
          : null;
        return [
          {
            vc_nombre: "Presuntivo",
            valor: 1,
            style:
              lastDetalle.id_estatusDiagnostico == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Definitivo",
            valor: 2,
            style:
              lastDetalle.id_estatusDiagnostico == 2
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Descartado",
            valor: 3,
            style:
              lastDetalle.id_estatusDiagnostico == 3
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "subsecuencia":
        lastDetalle = this.props.diagnostico
          ? this.props.diagnostico.detalles[
              this.props.diagnostico.detalles.length - 1
            ]
          : null;
        return [
          {
            vc_nombre: "Primera Vez",
            valor: 0,
            style:
              lastDetalle.sn_subsecuente == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Subsecuente",
            valor: 1,
            style:
              lastDetalle.sn_subsecuente == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "um_inicio":
        return [
          {
            vc_nombre: "Minutos",
            valor: 1,
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Horas",
            valor: 2,
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Días",
            valor: 3,
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Semanas",
            valor: 4,
            style:
              this.props.indexSeleccionado == 3
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Meses",
            valor: 5,
            style:
              this.props.indexSeleccionado == 4
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Años",
            valor: 6,
            style:
              this.props.indexSeleccionado == 5
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Desde el nacimiento",
            valor: 34,
            style:
              this.props.indexSeleccionado == 6
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "nu_inicio":
        opciones = [];
        i = 0;
        for (i = 0; i < 200; i++) {
          const opcion = {
            vc_nombre: `${i + 1}`,
            valor: `${i + 1}`,
            style:
              this.props.indexSeleccionado == i
                ? styles.containerHelp
                : styles.containerHelp,
          };
          opciones.push(opcion);
        }
        return opciones
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "um_duracion":
        return [
          {
            vc_nombre: "Minutos",
            valor: 1,
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Horas",
            valor: 2,
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Días",
            valor: 3,
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Semanas",
            valor: 4,
            style:
              this.props.indexSeleccionado == 3
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Meses",
            valor: 5,
            style:
              this.props.indexSeleccionado == 4
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Años",
            valor: 6,
            style:
              this.props.indexSeleccionado == 5
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "Indefinida",
            valor: 34,
            style:
              this.props.indexSeleccionado == 6
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "vc_observaciones":
        return [
          {
            vc_nombre: "Ninguna",
            valor: null,
            style:
              this.props.indexSeleccionado == 0
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "en protocolo de estudio",
            valor: ", actualmente en protocolo de estudio",
            style:
              this.props.indexSeleccionado == 1
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "controlado con medicamentos",
            valor: ", actualmente controlado con medicamentos",
            style:
              this.props.indexSeleccionado == 2
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "controlado sin medicamentos",
            valor: ", actualmente controlado sin medicamentos",
            style:
              this.props.indexSeleccionado == 3
                ? styles.containerHelp
                : styles.containerHelp,
          },
          {
            vc_nombre: "descontrolado con medicamentos",
            valor:
              ", actualente fuera de objetivos terapeuticos aun con tratamiento farmacologico",
            style:
              this.props.indexSeleccionado == 4
                ? styles.containerHelp
                : styles.containerHelp,
          },
        ]
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      case "nu_duracion":
        opciones = [];
        i = 0;
        for (i = 0; i < 200; i++) {
          const opcion = {
            vc_nombre: `${i + 1}`,
            valor: `${i + 1}`,
            style:
              this.props.indexSeleccionado == i
                ? styles.containerHelp
                : styles.containerHelp,
          };
          opciones.push(opcion);
        }
        return opciones
          .filter((e) =>
            e.vc_nombre
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          )
          .filter((e, index) => index < 30);
      default:
        return [];
    }
  }

  optionsAction() {
    switch (this.props.opcionDiagnosticos) {
      case "agregar":
        return this.addDiagnostico.bind(this);
      case "clasificar":
        return this.setDiagnostico.bind(this);
      case "principal":
        return this.setCausa.bind(this);
      case "status":
        return this.setStatus.bind(this);
      case "subsecuencia":
        return this.setSubsecuencia.bind(this);
      case "um_inicio":
        return this.setUmInicio.bind(this);
      case "nu_inicio":
        return this.setNuInicio.bind(this);
      case "um_duracion":
        return this.setUmDuracion.bind(this);
      case "nu_duracion":
        return this.setNuDuracion.bind(this);
      case "vc_observaciones":
        return this.setObservaciones.bind(this);
      default: {
        return () => {};
      }
    }
  }

  renderOptions() {
    const options = [
      { vc_nombre: "Historial", id: "historial" },
      { vc_nombre: "CIE-10", id: "clasificar" },
      { vc_nombre: "Inicio", id: "um_inicio" },
      { vc_nombre: "Vigencia", id: "um_duracion" },
      { vc_nombre: "Nota", id: "vc_observaciones" },
      { vc_nombre: "Estatus", id: "status" },
    ];
    return (
      <Menu
        inverted
        size="small"
        style={{ borderRadius: 0, margin: 0, width: "100%", height: 37 }}
      >
        {options.map((modulo, index) => {
          return (
            <Menu.Item
              key={modulo.id}
              name={modulo.vc_nombre}
              style={{ textAlign: "center" }}
              onClick={() => {
                this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                  this.props.callbackDx({
                    id_diagnostico: null,
                    diagnostico: this.props.diagnostico,
                    opcionesModuloNext: modulo.id,
                    umTiempo: null,
                  })
                );
              }}
            ></Menu.Item>
          );
        })}
      </Menu>
    );
  }

  getMenu() {
    const { diagnostico, opcionDiagnosticos } = this.props;
    let menu = [];

    switch (opcionDiagnosticos) {
      case "agregar":
        break;
      default:
        menu = [
          {
            value: "historial",
            vc_nombre: "Historial",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackDx({
                  diagnostico,
                  opcionesModuloNext: "historial",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "clasificar",
            vc_nombre: "CIE-10",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackDx({
                  diagnostico,
                  opcionesModuloNext: "clasificar",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "principal",
            vc_nombre: "Principal",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackDx({
                  diagnostico,
                  opcionesModuloNext: "principal",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "status",
            vc_nombre: "Estatus",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackDx({
                  diagnostico,
                  opcionesModuloNext: "status",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "subsecuencia",
            vc_nombre: "Subsecuente",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackDx({
                  diagnostico,
                  opcionesModuloNext: "subsecuencia",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "inicio",
            vc_nombre: "Inicio",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackDx({
                  diagnostico,
                  opcionesModuloNext: "um_inicio",
                  umTiempo: null,
                })
              );
            },
          },
          {
            value: "duracion",
            vc_nombre: "Vigencia",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackDx({
                  diagnostico,
                  opcionesModuloNext: "um_duracion",
                  umTiempo: null,
                })
              );
            },
          },

          {
            value: "observaciones",
            vc_nombre: "Observaciones",
            action: () => {
              this.setState({ opcionPreSelected: null, searchText: "" }, () =>
                this.props.callbackDx({
                  diagnostico,
                  opcionesModuloNext: "vc_observaciones",
                })
              );
            },
          },
        ];
        break;
    }

    return menu;
  }

  getMenuValue() {
    const { opcionDiagnosticos } = this.props;

    switch (opcionDiagnosticos) {
      case "historial":
        return "historial";
      case "clasificar":
        return "clasificar";
      case "principal":
        return "principal";
      case "status":
        return "status";
      case "subsecuencia":
        return "subsecuencia";
      case "um_inicio":
        return "inicio";
      case "nu_inicio":
        return `inicio`;
      case "um_duracion":
        return "duracion";
      case "nu_duracion":
        return `duracion`;
      case "vc_observaciones":
        return "observaciones";
    }
  }

  onChangeValue(event) {
    this.setState({ searchText: event.target.value, opcionPreSelected: null });
  }

  pressArrows(e) {
    let opcionPreSelected = this.state.opcionPreSelected;
    if (e.keyCode == 38) {
      if (opcionPreSelected == null || opcionPreSelected == 0) {
        opcionPreSelected = 0;
      } else {
        opcionPreSelected = opcionPreSelected - 1;
      }
      this.setState({ opcionPreSelected });
    } else if (e.keyCode == 40) {
      if (opcionPreSelected == null) {
        opcionPreSelected = 0;
      } else if (this.opcionesArray().length > opcionPreSelected + 1) {
        opcionPreSelected = opcionPreSelected + 1;
      }
      this.setState({ opcionPreSelected });
    }
  }

  modifyEnterKeyPressAsTab(e) {
    if (window.event && window.event.keyCode == 13) {
      switch (this.props.opcionDiagnosticos) {
        case "agregar":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.addDiagnostico(item);
          }
          break;
        case "clasificar":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setDiagnostico(item);
          }
          break;
        case "principal":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setCausa(item);
          }
          break;
        case "status":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setStatus(item);
          }
          break;
        case "subsecuencia":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setSubsecuencia(item);
          }
          break;
        case "um_inicio":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setUmInicio(item);
          }
          break;
        case "nu_inicio":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setNuInicio(item);
          }
          break;
        case "um_duracion":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setUmDuracion(item);
          }
          break;
        case "nu_duracion":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setNuDuracion(item);
          }
          break;
        case "vc_observaciones":
          if (this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.setObservaciones(item);
          } else if (this.state.searchText != "") {
            const item = {
              vc_nombre: this.state.searchText,
              valor: this.state.searchText,
              style:
                this.props.indexSeleccionado == 2
                  ? styles.containerHelp
                  : styles.containerHelp,
            };
            this.setObservaciones(item);
          }
          break;
      }
    }
  }

  statusDxToString(id_estatusDiagnostico) {
    switch (id_estatusDiagnostico) {
      case 1:
        return "presuntivo";
        break;
      case 2:
        return "definitivo ó confirmado";
        break;
      case 3:
        return "descartado";
        break;
      default:
        return "presuntivo";
        break;
    }
  }

  hoverIndex(index) {
    this.props.callbackHover({ indexHover: index });
  }

  searchBarEvent(event) {
    let opcionPreSelected = null;
    if (
      this.opcionesArray()
        .filter((e) =>
          e.vc_nombre.toLowerCase().includes(event.target.value.toLowerCase())
        )
        .filter((e, index) => index < 30).length > 0
    ) {
      opcionPreSelected = 0;
    } else {
      opcionPreSelected = null;
    }
    this.setState({ searchText: event.target.value, opcionPreSelected });
  }

  // ----------------------------
  // ------ item methods --------
  // ----------------------------

  addDiagnostico(item) {
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackDx({
        id_diagnostico: item.id,
        diagnostico: null,
        opcionesModuloNext: "agregar",
        umTiempo: null,
      })
    );
  }

  addDetalleEdit(dx) {
    const lastDetalle = { ...getLastDetalle(dx.detalles) };
    if (lastDetalle.id != null) {
      const detalleAdd = { ...lastDetalle };
      detalleAdd.id = null;
      detalleAdd.sn_subsecuente = 1;
      detalleAdd.sn_principal = 0;
      detalleAdd.id_notaActual = null;
      detalleAdd.vc_observaciones = null;
      detalleAdd.dt_registro = moment().format("YYYY-MM-DD HH:mm:ss");
      dx.detalles.push(detalleAdd);
      return dx;
    } else {
      return dx;
    }
  }

  setDiagnostico(item) {
    const dx = this.addDetalleEdit(this.props.diagnostico);
    const indexLastDetalle = getIndexLastDetalle(dx.detalles);
    dx.detalles[indexLastDetalle].id_diagnostico = item.id;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackDx({
        id_diagnostico: null,
        diagnostico: dx,
        opcionesModuloNext: "principal",
        umTiempo: null,
      })
    );
  }

  setCausa(item) {
    const dx = this.addDetalleEdit(this.props.diagnostico);
    const indexLastDetalle = getIndexLastDetalle(dx.detalles);
    dx.detalles[indexLastDetalle].sn_principal = item.valor;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackDx({
        id_diagnostico: null,
        diagnostico: dx,
        opcionesModuloNext: "status",
        umTiempo: null,
      })
    );
  }

  setStatus(item) {
    const dx = this.addDetalleEdit(this.props.diagnostico);
    const indexLastDetalle = getIndexLastDetalle(dx.detalles);
    dx.detalles[indexLastDetalle].id_estatusDiagnostico = item.valor;
    if (dx.id != null) {
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackDx({
          id_diagnostico: null,
          diagnostico: dx,
          opcionesModuloNext: "um_inicio",
          umTiempo: null,
        })
      );
    } else {
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackDx({
          id_diagnostico: null,
          diagnostico: dx,
          opcionesModuloNext: "subsecuencia",
          umTiempo: null,
        })
      );
    }
  }

  setSubsecuencia(item) {
    const dx = this.addDetalleEdit(this.props.diagnostico);
    const indexLastDetalle = getIndexLastDetalle(dx.detalles);
    dx.detalles[indexLastDetalle].sn_subsecuente = item.valor;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackDx({
        id_diagnostico: null,
        diagnostico: dx,
        opcionesModuloNext: "um_inicio",
        umTiempo: null,
      })
    );
  }

  setUmInicio(item) {
    if (item.valor == 34 && this.props.pacienteActual) {
      const dx = this.addDetalleEdit(this.props.diagnostico);
      const indexLastDetalle = getIndexLastDetalle(dx.detalles);
      dx.detalles[
        indexLastDetalle
      ].dt_inicio = this.props.pacienteActual.detalle.dt_nacimiento;
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackDx({
          id_diagnostico: null,
          diagnostico: dx,
          opcionesModuloNext: "um_duracion",
          umTiempo: null,
        })
      );
    } else {
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackDx({
          id_diagnostico: null,
          diagnostico: null,
          opcionesModuloNext: "nu_inicio",
          umTiempo: item.valor,
        })
      );
    }
  }

  setNuInicio(item) {
    const milisegundos =
      this.props.unidadesMedida.filter(
        (e, index) => e.id == this.props.umTiempo
      )[0].nu_ratio *
      item.valor *
      60000;
    const timeInterval = moment() - milisegundos;
    const fecha = new Date(timeInterval);
    const dt_inicio = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
    const dx = this.addDetalleEdit(this.props.diagnostico);
    const indexLastDetalle = getIndexLastDetalle(dx.detalles);
    dx.detalles[indexLastDetalle].dt_inicio = dt_inicio;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackDx({
        diagnostico: dx,
        opcionesModuloNext: "um_duracion",
      })
    );
  }

  setUmDuracion(item) {
    if (item.valor == 34) {
      const dx = this.addDetalleEdit(this.props.diagnostico);
      const indexLastDetalle = getIndexLastDetalle(dx.detalles);
      const dt_inicio = dx.detalles[indexLastDetalle].dt_inicio;
      const timeInterval = moment(dt_inicio) + 3153600000000;
      const fecha = new Date(timeInterval);
      const dt_vigencia = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
      dx.detalles[indexLastDetalle].dt_vigencia = dt_vigencia;
      dx.detalles[indexLastDetalle].id_um_duracion = 34;
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackDx({
          diagnostico: dx,
          opcionesModuloNext: "vc_observaciones",
        })
      );
    } else {
      this.setState({ opcionPreSelected: null, searchText: "" }, () =>
        this.props.callbackDx({
          umTiempo: item.valor,
          opcionesModuloNext: "nu_duracion",
        })
      );
    }
  }

  setNuDuracion(item) {
    const milisegundos =
      this.props.unidadesMedida.filter(
        (e, index) => e.id == this.props.umTiempo
      )[0].nu_ratio *
      item.valor *
      60000;
    const dx = this.addDetalleEdit(this.props.diagnostico);
    const indexLastDetalle = getIndexLastDetalle(dx.detalles);
    const dt_inicio = dx.detalles[indexLastDetalle].dt_inicio;
    const timeInterval = moment(dt_inicio) + milisegundos;
    const fecha = new Date(timeInterval);
    const dt_vigencia = `${moment(fecha).format("YYYY-MM-DD HH:mm:ss")}`;
    dx.detalles[indexLastDetalle].dt_vigencia = dt_vigencia;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackDx({
        diagnostico: dx,
        opcionesModuloNext: "vc_observaciones",
      })
    );
  }

  setObservaciones(item) {
    const dx = this.addDetalleEdit(this.props.diagnostico);
    const indexLastDetalle = getIndexLastDetalle(dx.detalles);
    dx.detalles[indexLastDetalle].vc_observaciones = item.valor;
    this.setState({ opcionPreSelected: null, searchText: "" }, () =>
      this.props.callbackDx({
        diagnostico: dx,
        opcionesModuloNext: "siguienteDx",
      })
    );
  }

  // renderTitleHelpBar() {
  //     const { date, diagnosticos, opcionDiagnosticos } = this.props;
  //     switch ( opcionDiagnosticos ) {
  //         case "agregar":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     Agregar
  //                 </button>
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                     placeholder='Buscar diagnosticos...'
  //                     onChange={this.searchBarEvent.bind(this)}
  //                     style={{ width: '100%', height: 31 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "clasificar":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     Clasifique el diagnóstico CIE-10
  //                 </button>
  //                 {this.renderOptions()}
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                         value={this.state.searchText}
  //                         focus placeholder='Buscar diagnosticos...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                         />
  //                 </div>

  //                 </div>
  //         );
  //         break;
  //         case "principal":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     Es el diagnostico principal
  //                 </button>
  //                 {this.renderOptions()}
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "status":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     Especifique el estatus
  //                 </button>
  //                 {this.renderOptions()}
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "subsecuencia":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     ¿Es subsecuente?
  //                 </button>
  //                 {this.renderOptions()}
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "um_inicio":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     El diagnostico inició hace
  //                 </button>
  //                 {this.renderOptions()}
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "nu_inicio":
  //         const umInicio = this.props.unidadesMedida.filter((e, index) => e.id == this.props.umTiempo)[0];
  //         const umStringInicio = umInicio.vc_nombre == 'mes' ? `${umInicio.vc_nombre}es` : `${umInicio.vc_nombre}s`;
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     ¿Hace cuantos {umStringInicio} inició el diagnostico?
  //                 </button>
  //                 {this.renderOptions()}
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "um_duracion":
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                 El diagnostico duró ó durará:
  //                 </button>
  //                 {this.renderOptions()}
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "nu_duracion":
  //         const um = this.props.unidadesMedida.filter((e, index) => e.id == this.props.umTiempo)[0];
  //         const umString = um.vc_nombre == 'mes' ? `${um.vc_nombre}es` : `${um.vc_nombre}s`;
  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                 ¿aproximadamente cuantos {umString.toLowerCase()} duró ó durará el diagnostico?
  //                 </button>
  //                 {this.renderOptions()}
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         focus placeholder='Seleccione...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //         case "vc_observaciones":

  //         return (
  //             <div style={{padding:0, border: 0}}>
  //                 <button style={{padding:0, border:0, height: 74, width: '100%',textAlign:'center',color: 'white' , backgroundColor:window.colors.menu}}>
  //                     Observaciones del diagnostico:

  //                 </button>
  //                 {this.renderOptions()}
  //                 <div style={styles.titleHelp}>
  //                     <Input
  //                     autoComplete='off'
  //                     id='searchBar'
  //                     value={this.state.searchText}
  //                         icon='search'
  //                         // iconPosition='right'
  //                         focus placeholder='Describa...'
  //                         onChange={this.searchBarEvent.bind(this)}
  //                         style={{ width: '100%', height: 30 }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //         break;
  //     };
  // }

  // renderOpcionesHelpBar() {
  //     const { date, diagnosticos, opcionDiagnosticos } = this.props;
  //     const lastDetalle = this.props.diagnostico ? this.props.diagnostico.detalles[this.props.diagnostico.detalles.length - 1]: null ;
  //     let opciones = [];
  //     let i = 0;

  //     switch ( opcionDiagnosticos ) {
  //         case "conciliar":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div key={index} style={styles.containerHelp}>
  //                     <div style={styles.descriptionHelp}>
  //                         {item.dt_registro}
  //                     </div>
  //                     <div style={styles.stickHelp}>
  //                         {/* {date(item.dt_registro, 'dddd D MMMM YYYY')} */}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "clasificar":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setDiagnostico(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                     <div style={styles.stickHelp}>
  //                         {item.cve_cie10}
  //                     </div>
  //                     <div style={styles.line}/>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "principal":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setCausa(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "status":
  //             return (
  //             this.opcionesArray().map((item,index) =>
  //                     <div onClick={() =>  this.setStatus(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                         <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                             {item.vc_nombre}
  //                         </div>
  //                     </div>
  //                 )
  //             );
  //             break;
  //         case "subsecuencia":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setSubsecuencia(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "um_inicio":
  //             return (
  //                 this.opcionesArray().map((item,index) =>
  //                     <div onClick={() =>  this.setUmInicio(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                         <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                             {item.vc_nombre}
  //                         </div>
  //                     </div>
  //                 )
  //             );
  //             break;
  //         case "nu_inicio":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setNuInicio(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "um_duracion":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setUmDuracion(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         case "nu_duracion":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setNuDuracion(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         case "vc_observaciones":
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div onClick={() =>  this.setObservaciones(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                 </div>
  //             )
  //         );
  //         break;
  //         default:
  //         return (
  //             this.opcionesArray().map((item,index) =>
  //                 <div id={this.state.opcionPreSelected == index ? 'indexSelected' : 'other'} onClick={() =>  this.addDiagnostico(item) } key={index} style={this.state.opcionPreSelected == index ? styles.containerSelectedHelp : styles.containerHelp }>
  //                     <div style={this.state.opcionPreSelected == index ? styles.descriptionSelectedHelp : styles.descriptionHelp }>
  //                         {item.vc_nombre}
  //                     </div>
  //                     <div style={styles.stickHelp}>
  //                         {item.cve_cie10}
  //                     </div>
  //                     <div style={styles.line}/>
  //                 </div>
  //             )
  //         );
  //         break;
  //     };
  // }
}

const styles = {
  container: {
    overflowX: "hidden",
    overflowY: "hidden",
    top: 0,
    bootom: 0,
    margin: 0,
    padding: 0,
  },
  containerSub: {
    overflowX: "hidden",
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    width: "100%",
    margin: 0,
    padding: 0,
    paddingLeft: 1,
    paddingRight: 1,
  },
  containerHelp: {
    margin: 0,
    marginBottom: 2,
    borderRadius: 3,
    overflowY: "hidden",
    padding: 10,
    minHeight: 40,
    color: "black",
    backgroundColor: window.colors.white,
  },
  containerSelectedHelp: {
    margin: 0,
    marginBottom: 2,
    borderRadius: 3,
    overflowY: "hidden",
    padding: 10,
    minHeight: 40,
    marginBottom: 2,
    color: "white",
    backgroundColor: window.colors.itemSelected,
  },
  descriptionHelp: {
    margin: 0,
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "left",
    fontSize: 14,
    minHeight: 40,
    color: "gray",
  },
  descriptionSelectedHelp: {
    margin: 0,
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
    textAlign: "left",
    fontSize: 14,
    minHeight: 40,
    color: "white",
  },
  stickHelp: {
    margin: 0,
    marginLeft: "85%",
    marginBottom: 2,
    width: 30,
    overflowY: "hidden",
    paddingTop: 0,
    textAlign: "left",
    float: "left",
    fontSize: 12,
    color: window.colors.itemSelected,
  },
  subTitleHelp: {
    margin: 0,
    marginLeft: 10,
    marginRight: 10,
    width: "100%",
    overflowY: "scroll",
    paddingTop: 0,
    textAlign: "left",
    float: "left",
    fontSize: 12,
    color: "black",
  },
  subTitleRightHelp: {
    margin: 0,
    marginLeft: 10,
    marginRight: 10,
    width: "100%",
    paddingTop: 0,
    textAlign: "right",
    float: "right",
    fontSize: 12,
    color: window.colors.itemSelected,
  },
  titleHelp: {
    width: "100%",
    padding: 3,
    height: 37,
    color: "white",
  },
};

const mapStateToProps = (state) => {
  const { diagnosticos, unidadesMedida, pacienteActual } = state.Think;
  return { diagnosticos, unidadesMedida, pacienteActual };
};

export default connect(mapStateToProps)(DiagnosticosHelpBar);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { styled, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState,
  setDisplaySidebar,
} from "app/containers/Think/actions";
import {
  Paper,
  Typography,
  Box,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import SubMenuModulos from "app/material-ui/SubMenuModulos";
import { Modal, Loader } from "semantic-ui-react";
import internalAPI from "app/utils/internalAPI";
import SideBar from "./SideBar";

const modulo = "settings";

const useStyles = makeStyles((theme) => {
  return {
    cita: {
      borderRadius: 5,
      padding: "12px",
      marginBottom: theme.spacing(1),
      boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
      border: "1px solid transparent",
      display: "flex",
      "&.selected": {
        borderColor: theme.palette.primary.main,
      },
      "&.notSelected": {
        opacity: 0.8,
      },
      "& h5": {
        color: window.colors.itemSelected,
        marginBottom: "0",
        textTransform: "capitalize",
      },
      "& > *": { flex: 1 },
    },
  };
});

const Index = ({
  changeState,
  especialidades,
  setHelperSidebarRender,
  setDisplaySidebar,
}) => {
  const [searchText, setSearchText] = React.useState("");
  const classes = useStyles();
  const { agendas = [] } = window.amplify.store("institution") || {};

  const data = agendas
    .map((v) => {
      const especialidad = especialidades.find(
        (e) => e.id == v.id_especialidad
      );
      return {
        ...v,
        vc_especialidad: especialidad ? especialidad.vc_alias : "",
      };
    })
    .filter((v) =>
      v.vc_nombre.toLowerCase().includes(searchText.toLowerCase())
    );
  const [selected, setSelected] = React.useState({
    index: -1,
    row: null,
  });

  const remove = () => {
    // internalAPI.desvincularUsuario(selected.row.id, (err, response) => {
    // this.getUsers();
    // this.props.setHelperSidebarRender(() => null);
    // this.props.setDisplaySidebar(false);
    // });
  };

  const handleSelect = (row, index) => {
    const newSelected = { index: -1, row: null };

    if (selected.index !== index) {
      newSelected.index = index;
      newSelected.row = row;
    }

    setSelected(newSelected);
  };

  React.useEffect(() => {
    if (selected.row) {
      setHelperSidebarRender(() => (
        <SideBar
          selectedRow={selected.row}
          edit={() => changeState({ opcion: "editar", data: selected.row })}
          remove={() => remove()}
        />
      ));
      setDisplaySidebar(true);
    } else setDisplaySidebar(false);
  }, [selected.row]);

  React.useEffect(() => {
    return () => {
      setHelperSidebarRender(() => null);
      setDisplaySidebar(false);
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" flexGrow={1}>
      <SubMenuModulos
        type="search"
        searchText={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        rightIcon={
          <IconButton
            onClick={() => {
              changeState({
                opcion: "agregar",
                data: { horarios: [{ detalle: [] }] },
              });
            }}
          >
            <PlaylistAdd />
          </IconButton>
        }
      />
      <Box
        display="flex"
        flexGrow={1}
        flexShrink={1}
        flexBasis="auto"
        height={"0px"}
        overflow="scroll"
        flexDirection="column"
        p={2}
      >
        {data.map((v, i) => {
          const classesNames = classNames(classes.cita, {
            selected: selected.row && selected.row.id === v.id,
            notSelected: selected.row && selected.row.id !== v.id,
          });

          return (
            <Paper
              key={i}
              className={classesNames}
              onClick={() => handleSelect(v, i)}
            >
              <Typography variant="subtitle1" component="h5">
                {v.vc_nombre}
              </Typography>
              <Typography variant="subtitle1">{v.vc_especialidad}</Typography>
            </Paper>
          );
        })}
      </Box>
    </Box>
  );
};

// class Listado extends React.Component {
//   constructor(props) {
//     super(props);

//     const { agendas = [] } = window.amplify.store("institution") || [];
//     let especialidad = null;
//     const data = agendas.map((v) => {
//       especialidad = props.especialidades.find(
//         (e) => e.id == v.id_especialidad
//       );
//       return {
//         ...v,
//         vc_especialidad: especialidad ? especialidad.vc_alias : "",
//       };
//     });

//     this.state = {
//       data,
//       searchText: " ",
//       selectedRow: null,
//       indexSelected: null,
//     };
//     this.selectedRow = this.selectedRow.bind(this);
//     this.edit = this.edit.bind(this);
//     this.remove = this.remove.bind(this);
//   }

//   componentWillUnmount() {
//     this.props.setHelperSidebarRender(() => null);
//     this.props.setDisplaySidebar(false);
//   }

//   edit(selectedRow) {
//     this.props.changeState({ opcion: "editar", data: selectedRow });
//   }

//   remove(selectedRow) {
//     internalAPI.desvincularUsuario(selectedRow.id, (err, response) => {
//       this.getUsers();
//       this.props.setHelperSidebarRender(() => null);
//       this.props.setDisplaySidebar(false);
//     });
//   }

//   selectedRow(selectedRow, indexSelected) {
//     if (indexSelected === this.state.indexSelected) {
//       this.setState({ selectedRow: null, indexSelected: null }, () => {
//         this.props.setHelperSidebarRender(() => null);

//         this.props.setDisplaySidebar(false);
//       });
//     } else {
//       this.setState({ selectedRow, indexSelected }, () => {
//         this.props.setHelperSidebarRender(() => (
//           <SideBar
//             selectedRow={selectedRow}
//             edit={() => this.edit(selectedRow)}
//             remove={() => this.remove(selectedRow)}
//           />
//         ));

//         this.props.setDisplaySidebar(true);
//       });
//     }
//   }

//   renderContent() {
//     return this.state.data.map((item, index) => {
//       return (
//         <Item
//           key={index}
//           className={this.state.indexSelected === index ? "selected" : ""}
//           onClick={() => this.selectedRow(item, index)}
//         >
//           <div className={this.props.classes.userData}>
//             <Typography variant="subtitle1">{item.vc_nombre}</Typography>
//           </div>
//         </Item>
//       );
//     });
//   }

//   render() {
//     return (
//       <Box display="flex" flexDirection="column" flexWrap="nowrap" flexGrow={1}>
//         <SubMenuModulos
//           type="search"
//           searchText={this.state.searchText}
//           // onChange={this.search.bind(this)}
//           rightIcon={
//             <IconButton
//               onClick={() => {
//                 this.props.changeState({
//                   opcion: "agregar",
//                   data: { horarios: [{ detalle: [] }] },
//                 });
//               }}
//             >
//               <PlaylistAdd />
//             </IconButton>
//           }
//         />
//         <Box
//           display="flex"
//           flexGrow={1}
//           flexShrink={1}
//           flexBasis="auto"
//           height={"0px"}
//           overflow="scroll"
//           flexDirection="column"
//           p={2}
//         >
//           {this.renderContent()}
//           {this.state.loading && (
//             <Modal
//               open
//               style={{
//                 boxShadow: "1px 0px 10px rgba(0, 0,0, 0)",
//                 backgroundColor: "rgba(0, 0,0, 0.9)",
//               }}
//             >
//               <Loader size="massive" active inverted>
//                 Cargando...
//               </Loader>
//             </Modal>
//           )}
//         </Box>
//       </Box>
//     );
//   }
// }

Index.propTypes = {
  viewTitle: PropTypes.string,
  setViewTitle: PropTypes.func,
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  resetHelperSidebarRender: PropTypes.func,
  setHelperSidebarRender: PropTypes.func,
  setState: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { especialidades } = state.Think;
  return {
    state: state.Think[modulo] || null,
    especialidades,
  };
};
const actions = {
  setHelperSidebarRender,
  resetHelperSidebarRender,
  setState,
  setDisplaySidebar,
};
export default connect(mapStateToProps, actions)(Index);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Container } from 'semantic-ui-react';
import Paper from '@material-ui/core/Paper';
 import AppBar from '@material-ui/core/AppBar';
 import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HelpBarThink from '../../../material-ui/HelpBarThink';



class GpcHelpBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            indexHover: null,
            indexSelected: this.props.indexSelected,
            opcionPreSelected: null,
        };
    }

    componentDidMount() {
        document.onkeypress = this.modifyEnterKeyPressAsTab.bind(this);
        document.onkeydown = this.pressArrows.bind(this);
    }
    componentWillUnmount(){
        document.onkeypress = null;
        document.onkeydown = null;
    }
    componentDidUpdate() {
        if (document.activeElement != window.$('#searchBar')) {
            window.$('#searchBar').focus();
        }
    }
    pressArrows(e){
        let opcionPreSelected = this.state.opcionPreSelected;
        if (e.keyCode == 38) {
            if ( opcionPreSelected == null || opcionPreSelected == 0 ) {
                opcionPreSelected = 0;
            } else {
                opcionPreSelected = (opcionPreSelected - 1);
            }
        } else if (e.keyCode == 40) {
            if (opcionPreSelected == null) {
                opcionPreSelected = 0;
            }else if ( this.opcionesArray().length > (opcionPreSelected + 1) ) {
                opcionPreSelected = (opcionPreSelected + 1);
            }
        }
        this.setState({opcionPreSelected});
    }
    modifyEnterKeyPressAsTab(e) {
        if (e && (e.keyCode === 10 || e.keyCode === 13) && this.state.opcionPreSelected != null) {
            const item = this.opcionesArray()[this.state.opcionPreSelected];
            this.selectGPC(item);
        }
    }

    //
    hoverIndex(index) {
        this.props.callbackHover({ indexHover: index });
    }
    onChangeValue(event) {
        this.setState({ searchText: event.target.value });
    }
    searchBarEvent(event) {
        let opcionPreSelected = null;
        const primeraOpcion = this.opcionesArray().filter(e => e.vc_nombre.toLowerCase().includes(event.target.value.toLowerCase()) )[0];
        if (primeraOpcion) {
            if (primeraOpcion.vc_nombre.toLowerCase() === event.target.value.toLowerCase()) {
                opcionPreSelected = 0;
            }
        } else {
            opcionPreSelected = null;
        }
        this.setState({ searchText: event.target.value, opcionPreSelected });
    }
    selectGPC(item) {
        this.setState({ searchText: '' }, () => this.props.callbackGPC(item));
    }
    renderTitleHelpBar() {


        // return (
        //   <Paper style={{ padding: 0, border: 0, borderRadius: 0, height: '100%' }}>
        //        <AppBar position='relative' style={{ height: 95, zIndex: 15 }}>
        //             <div style={{padding:0, border: 0}}>
        //        <Toolbar>
        //           <Typography variant="h6" style={{ textAlign: 'center', width: '100%', flexDirection: 'column', verticalAlign: 'center', justifyItems: 'center' }}>
        //             Selector de Guias de Práctica Clínica
        //           </Typography>
        //        </Toolbar>
        //         <div style={styles.titleHelp}>
        //             <Input
        //             autoComplete='off'
        //             id='searchBar'
        //             focus placeholder='Buscar GPC...'
        //             value={this.state.searchText}
        //             onChange={this.searchBarEvent.bind(this)}
        //             style={{ width: '100%', height: 31 }}
        //             />
        //         </div>
        //           </div>
        //         </AppBar>
        //         </Paper>
        //  );
    }

    opcionesArray() {
        const { gpc } = this.props;
        return gpc.filter(e => e.vc_nombre.toLowerCase().includes(this.state.searchText.toLowerCase()) ).filter((e, index) => index < 60);
    }

    renderOpcionesHelpBar() {
        const { gpc, idGuiaSelected } = this.props;
        return (
             this.opcionesArray().map((item, index) =>
                 <div onClick={() => this.selectGPC(item)} key={index} style={this.state.opcionPreSelected === index ? styles.containerSelectedHelp : styles.containerHelp}>
                     <div style={this.state.opcionPreSelected === index ? styles.descriptionSelectedHelp : styles.descriptionHelp}>
                         {item.vc_nombre}
                     </div>
                    <div style={styles.subTitleRightHelp}>
                       {item.cve_gpc}
                    </div>
                   <div style={styles.line} />
                </div>
            )
        );
    }

    render() {
        const { index } = this.props;
        const shadow = this.state.indexSelected === index ? '1px 1px 20px rgba(0, 0,0, 1)' : '1px 0px 10px rgba(0, 0,0, 0.7)';
        let labelDisplays = [
          {key: 'vc_nombre', style:{width:'100%'}},
          {key: 'cve_gpc', style: {color:window.colors.itemSelected, textAlign:'right', width:'100%' }}
      ]
      let optionStyle = {
        flexDirection: 'column',
    }
        return (
          <HelpBarThink
              header={'Guias de Practicas Clinicas'}
              searchBar={true}
              options={this.opcionesArray()}
              optionsAction={this.selectGPC.bind(this)}
              indexSeleccionado={this.state.opcionPreSelected}
              onChangeValue={this.onChangeValue.bind(this)}
              searchText={this.state.searchText}
              labelDisplays={labelDisplays}
              optionStyle={optionStyle}
          />
      )
        return (
            <Container key={index} style={styles.container}>
                {this.renderTitleHelpBar()}
                <div style={styles.containerSub}>
                    {this.renderOpcionesHelpBar()}
                </div>
            </Container>
        );
    }
}


const styles = {
	container: {
		overflowX: 'hidden',
		overflowY: 'hidden',
        width: '100%',
        height: '100%',
        margin: 0,
		padding: 0
    },
    containerSub: {
		overflowX: 'hidden',
		overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
        width: '100%',
        height: window.screen.availHeight - 111,
        margin: 0,
		padding: 0
    },
    containerHelp: {
        margin: 0,
        marginBottom: 2,
        borderRadius: 3,
        overflowY: "hidden",
        padding: 10,
        minHeight: 40,
        color: "black",
        backgroundColor:window.colors.white
    },
    containerSelectedHelp: {
        margin:0,
        marginBottom:2,
        borderRadius:3,
        overflowY: "hidden",
        padding: 10,
        minHeight: 40,
        marginBottom: 2,
        color: "white",
        backgroundColor:window.colors.itemSelected
    },
    descriptionHelp: {
        margin:0,
        marginTop:5,
        marginLeft:10,
        marginRight:10,
        textAlign: "left",
        fontSize: 14,
        minHeight: 40,
        color: "gray"
    },
    descriptionSelectedHelp: {
        margin:0,
        marginTop:5,
        marginLeft:10,
        marginRight:10,
        textAlign: "left",
        fontSize: 14,
        minHeight: 40,
        color: "white"
    },
    stickHelp: {
        margin:0,
        marginLeft:"85%",
        marginBottom: 2,
        width: 30,
        overflowY: "hidden",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color:window.colors.itemSelected
    },
    subTitleHelp: {
        margin:0,
        marginLeft:10,
        marginRight:10,
        width: "100%",
        overflowY: "scroll",
        paddingTop: 0,
        textAlign: "left",
        float: "left",
        fontSize: 12,
        color: "black"
    },
    subTitleRightHelp: {
        margin:0,
        marginLeft:10,
        marginRight:10,
        width: "100%",
        paddingTop: 0,
        textAlign: "right",
        float: "right",
        fontSize: 12,
        color:window.colors.itemSelected
    },
	titleHelp:{
        width:"100%",
        padding:3,
        height: 37,
        color: "white",
    }
};


const mapStateToProps = (state) => {
  const { gpc } = state.Think;
  return { gpc };
};


export default connect(mapStateToProps)(GpcHelpBar);

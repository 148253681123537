import React from "react";
import _ from "lodash";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  ListSubheader,
} from "@material-ui/core";

import Input from "app/components/Form/Input";
import Select from "app/components/Form/Select";
import Icon from "app/sales/components/Common/Icon";
import { useForm } from "app/sales/hooks";
import { Add, Delete } from "@material-ui/icons";
import useApi from "app/sales/hooks/useApi";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const fields = ["id_tipo", "vc_descripcion"];
const fieldsRequired = ["id_tipo", "vc_descripcion"];

export default (props) => {
  const { list } = useApi("productos/tipos-producto");
  const { open, close, accept, data } = props;

  const [tipos, setTipos] = React.useState([]);

  const [proveedores, setProveedores] = React.useState([
    { value: 1, text: "Proveedor 1" },
    { value: 2, text: "Proveedor 2" },
  ]);
  const [prodProveedores, setProdProveedores] = React.useState([]);

  const [form, setForm] = useForm({});
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    const formKeys = Object.keys(form);
    const keysExist = fieldsRequired.every((field) => formKeys.includes(field));
    const ok = formKeys.every(
      (field) =>
        keysExist && fieldsRequired.includes(field) && Boolean(form[field])
    );

    setDisabled(!ok);
  }, [form]);

  React.useEffect(() => {
    if (open)
      list().then((res) => {
        setTipos(
          res.items.map((tipo) => ({
            value: tipo.id,
            text: tipo.vc_descripcion,
          }))
        );
      });
  }, [open]);
  React.useEffect(() => {
    setForm(_.pick(data, fields));
  }, [data]);

  const agregarProveedor = () =>
    setProdProveedores((prev) => [...prev, { id: null }]);

  const eliminarProveedor = (index) => {
    const copyArr = [...prodProveedores];
    copyArr.splice(index, 1);

    setProdProveedores(copyArr);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Producto</DialogTitle>
      <DialogContent>
        <Grid container key="tipo">
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <Icon icon="FormatListNumbered" />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            <Select
              placeholder="Tipo*"
              data={tipos}
              value={form.id_tipo}
              onChange={(option) => setForm("id_tipo", option.value)}
            />
          </Grid>
        </Grid>
        <Grid container key="monto">
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <Icon icon="AttachMoney" />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            <Input
              placeholder="Monto*"
              number
              value={form.nu_monto}
              onChange={(value) => setForm("nu_monto", value)}
            />
          </Grid>
        </Grid>
        <Grid container key="descripcion">
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <Icon icon="ShortText" />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            <Input
              placeholder="Descripción*"
              value={form.vc_descripcion}
              onChange={(value) => setForm("vc_descripcion", value)}
            />
          </Grid>
        </Grid>

        <List
          subheader={
            <ListSubheader style={{ paddingLeft: 0 }}>
              <Box display="flex" justifyContent="space-between">
                Proveedores
                <IconButton onClick={() => agregarProveedor()} edge="end">
                  <Add />
                </IconButton>
              </Box>
            </ListSubheader>
          }
        >
          {prodProveedores.map((proveedor, index) => (
            <ListItem key={index}>
              <Box width="100%" pr={1}>
                <Select
                  noMargin
                  placeholder="Proveedor"
                  data={proveedores}
                  value={proveedor.id_proveedor}
                  onChange={(option) => {}}
                />
              </Box>
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => eliminarProveedor(index)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={close}
          style={{ alignSelf: "right" }}
        >
          Cancelar
        </Button>

        <Button
          onClick={() => {
            accept(form);
          }}
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          disabled={disabled}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";

import React from 'react';
import { teal } from "@material-ui/core/colors";
import moment from "moment";
const themeSelected = window.amplify.store('theme') || null;

var generateDayWiseTimeSeries = function (baseval, count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
        var x = baseval;
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

        series.push([x, y]);
        baseval += 86400000;
        i++;
    }
    return series;
}
var getRegisters = function (data) {
    let registers = [];
    data.forEach((register, index) => {
        if (index === 0) {
            registers.push({ x: (new Date(register.dt_registro)) - 6400000, y: 0 })
        }
        registers.push({ x: new Date(register.dt_registro), y: register.nu_respuesta })
    });
    return registers;
}
class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                theme: {
                    mode: 'light',
                    color: 'gray',
                    monochrome: {
                        enabled: true,
                        color: themeSelected ? themeSelected.primary['200'] : teal['200'],
                        shadeTo: 'light',
                        shadeIntensity: 0.65
                    },
                },
                chart: {
                    stacked: true,
                    events: {
                        selection: function (chart, e) {
                            console.log(new Date(e.xaxis.min))
                        },
                        click: function(event, chartContext, config) {
                            console.log(chartContext)
                        }
                    },
                },
                colors: [themeSelected ? themeSelected.primary['200'] : teal['200'], '#00E396', '#CED4DC'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 1

                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 0.6,
                        opacityTo: 0.8,
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                xaxis: {
                    categories: this.getCategories()
                },
                grid: {
                    show: true,
                    borderColor: 'rgba(200,200,200,0.2)',
                    strokeDashArray: 0,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false,
                            color: 'red'
                        }
                    },   
                    yaxis: {
                        lines: {
                            show: true,
                            color: 'red'                       
                        }
                    },  
                    row: {
                        colors: undefined,
                        opacity: 0.5
                    },  
                    column: {
                        colors: undefined,
                        opacity: 0.5
                    },  
                    padding: {
                        top: 0,
                        right: 15,
                        bottom: 0,
                        left: 3
                    },  
                },
                markers: {
                    size: 2,
                    colors: 'gray',
                    strokeColors: 'gray',
                    strokeWidth: 1,
                    strokeOpacity: 0.9,
                    fillOpacity: 1,
                    discrete: [],
                    shape: "circle",
                    radius: 2,
                    offsetX: 0,
                    offsetY: 0,
                    onClick: undefined,
                    onDblClick: undefined,
                    hover: {
                      size: undefined,
                      sizeOffset: 3
                    }
                },
                dataLabels: {
                    enabled: true
                },
                chart: {
                    
                    // animations: {
                    //     enabled: true,
                    //     easing: 'easeinout',
                    //     speed: 300,
                    //     animateGradually: {
                    //         enabled: true,
                    //         delay: 150
                    //     },
                    //     dynamicAnimation: {
                    //         enabled: true,
                    //         speed: 350
                    //     }
                    // }
                },
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 3,
                    opacity: 0.5
                  }
            },
            series: this.getSeries(),
        }
    }
    getOptions(){
        const categories = this.getCategories()
        const colors = [themeSelected ? themeSelected.primary['200'] : teal['200'], '#00E396', '#CED4DC'];
        console.log('colors.', colors)
        return {
            theme: {
                mode: 'light',
                color: 'red'
            },
            chart: {
                height: 350,
                type: 'area',
                padding: 16
              },
            colors,
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1

            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.6,
                    opacityTo: 0.8,
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            },
            xaxis: {
                ...categories,
                tickAmount: 6,
                formatter: function(val, timestamp) {
                    console.log("What is val?");
                    console.log(val);
                    console.log("What is timestamp");
                    console.log(timestamp);
                    // return moment(timestamp).format("MM/dd/yyyy");
                    return moment(timestamp).format("MM/DD/YYYY");
                    // return val;
                    // return "blah";
                }
            },
            yaxis:{
            },
            grid: {
                show: true,
                borderColor: 'rgba(200,200,200,0.2)',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: false,
                        color: 'red'
                    }
                },   
                yaxis: {
                    lines: {
                        show: true,
                        color: 'red'                       
                    }
                },  
                row: {
                    colors: undefined,
                    opacity: 0.5
                },  
                column: {
                    colors: undefined,
                    opacity: 0.5
                },  
                padding: {
                    top: 0,
                    right: 15,
                    bottom: 0,
                    left: 3
                },  
            },
            markers: {
                size: 2,
                colors: 'gray',
                strokeColors: 'gray',
                strokeWidth: 1,
                strokeOpacity: 0.9,
                fillOpacity: 1,
                discrete: [],
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                hover: {
                  size: undefined,
                  sizeOffset: 3
                }
            },
            dataLabels: {
                enabled: true
            },
            chart: {
                
                // animations: {
                //     enabled: true,
                //     easing: 'easeinout',
                //     speed: 300,
                //     animateGradually: {
                //         enabled: true,
                //         delay: 150
                //     },
                //     dynamicAnimation: {
                //         enabled: true,
                //         speed: 350
                //     }
                // }
            },
            dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5
              }
        }
    }
    getCategories(){
        if (this.props.type === 'datetime' || !this.props.type) return {
            type: 'datetime'
        };

        const categories = (this.props.data || []).map((item, index) => {
            console.log('categories', item)
            return item.vc_nombre
        })
        return categories;
    }
    getSeries(){
        console.log(this.props.data)
        if (this.props.type === 'datetime') return [{data: this.props.data || []}];
        const series = Object.keys(((this.props.data || [])[0] || {}).series || {})
        const seriesEdit = [];
        series.map((serie, index) => {
            const data = []
            this.props.data.map((item, index) => {
                const value = ((item.series || {})[serie] || 0)
                data.push(value);
            })
            seriesEdit.push({
                name:serie,
                data,
            })
        })
        console.log('serie------', seriesEdit)
        return seriesEdit;
    }
    render() {
        return (
            <div id="chart"  style={{ backgroundColor: this.props.backgroundColor || 'white', borderRadius: 10, overflowX: 'scroll', padding: 10, width: '100%'}}>
                <ReactApexChart options={this.getOptions()} series={this.getSeries()} type="area" height={this.props.height || 300} />
            </div>
        );
    }
}

export default (Index);


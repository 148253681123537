import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as firebase from "firebase/app";

import { login, setLibs, updateCatalogs } from "app/containers/Think/actions";
import ConfirmAlert from "app/material-ui/ConfirmAlert";

import { Box, Grid, Input, Button, CircularProgress } from "@material-ui/core";
import useStyles from "./useStyles";
import api from "./api";

import RecuperarContraseña from "app/login/components/RecuperarContraseña";
import RegistroUsario from "app/login/components/RegistroUsuario";

const Index = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState({ vc_email: "", vc_password: "" });
  const handleForm = (k, v) => setForm((prev) => ({ ...prev, [k]: v }));

  const [modalRecuperar, setModalRecuperar] = React.useState({
    open: false,
    data: null,
  });
  const [modalRegistroUsario, setModalRegistroUsario] = React.useState(false);
  const [modalNotificacion, setModalNotificacion] = React.useState({
    open: false,
    title: "",
    message: "",
  });

  const openModal = (modal, open = true) => {
    switch (modal) {
      case "notificacion":
        setModalNotificacion((prev) => ({ ...prev, open }));
        break;
      case "recuperar":
        setModalRecuperar((prev) => ({ ...prev, open }));
        break;
      case "registro":
        setModalRegistroUsario(open);
        break;
    }
  };

  React.useEffect(() => {
    const libs = require("app/libs/index");
    props.setLibs(libs.default);

    const params = window.location.hash.split("?")[1];
    if (params) {
      const queryString = `?${params}`;
      const urlParams = new URLSearchParams(queryString);

      if (urlParams.has("email") && urlParams.has("token")) {
        const email = urlParams.get("email");
        const token = urlParams.get("token");
        setModalRecuperar({ open: true, data: { email, token } });
      }
    }
  }, []);

  const recuperar = (email) => {
    setLoading(true);
    api
      .recuperar(email)
      .then(({ message }) => {
        if (message) {
          setModalNotificacion({
            open: true,
            title: "",
            message,
          });
        }
        openModal("recuperar", false);
      })
      .catch((message) => {
        setModalNotificacion({
          open: true,
          title: "¡Ups!",
          message,
        });
      })
      .finally(() => setLoading(false));
  };

  const restablecer = (body) => {
    setLoading(true);
    api
      .restablecer(body)
      .then(({ message }) => {
        if (message) {
          setModalNotificacion({
            open: true,
            title: "",
            message,
          });
        }
        setModalRecuperar({ open: false, data: null });
        // openModal("recuperar", false);
      })
      .catch((message) => {
        setModalNotificacion({
          open: true,
          title: "¡Ups!",
          message,
        });
      })
      .finally(() => setLoading(false));
  };

  const login = () => {
    setLoading(true);
    api
      .login(form)
      .then((user) => {
        loginFirebase(form);
        props.login(user);
      })
      .catch((message) => {
        setModalNotificacion({
          open: true,
          title: "¡Ups!",
          message,
        });
      })
      .finally(() => setLoading(false));
  };

  const loginFirebase = (user) => {
    const auth = firebase.auth();
    auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(function () {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return auth
          .signInWithEmailAndPassword(user.vc_email, user.vc_password)
          .catch((error) => {
            if (error) {
              auth.createUserWithEmailAndPassword(
                user.vc_email,
                user.vc_password
              );
            }
          });
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  };

  return (
    <Box height="100%" className={classes.login}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={12} md={6}>
          <Box className="logo-container">
            <Box className="logo" />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {modalNotificacion.open && (
            <ConfirmAlert
              title={modalNotificacion.title}
              message={modalNotificacion.message}
              onAccept={() => openModal("notificacion", false)}
            />
          )}

          <RecuperarContraseña
            key={"recovery"}
            open={modalRecuperar.open}
            data={modalRecuperar.data}
            close={() => openModal("recuperar", false)}
            accept={(data) => {
              if (modalRecuperar.data) restablecer(data);
              else recuperar(data);
            }}
          />

          <RegistroUsario
            open={modalRegistroUsario}
            close={() => openModal("registro", false)}
          />

          <Box className="form-container">
            <Box className="form">
              <Input
                label="E-mail"
                type={"email"}
                disableUnderline
                fullWidth
                placeholder={"E-mail"}
                value={form.vc_email}
                onChange={(e) => handleForm("vc_email", e.target.value)}
                // errormessage={!valEmail ? "E-mail no valido" : ""}
              />
              <Input
                label="Password"
                type={"password"}
                disableUnderline
                fullWidth
                placeholder={"Password"}
                value={form.vc_password}
                onChange={(e) => handleForm("vc_password", e.target.value)}
                // errormessage={!valEmail ? "E-mail no valido" : ""}
              />

              <Button
                endIcon={
                  loading ? (
                    <CircularProgress
                      size={20}
                      color={"inherit"}
                      style={{
                        float: "right",
                        position: "absolute",
                        right: 8,
                        top: 10,
                      }}
                    />
                  ) : null
                }
                fullWidth
                variant="outlined"
                onClick={login}
              >
                Iniciar Sesion
              </Button>
              <Box
                display="flex"
                justifyContent="space-between"
                className="extra"
              >
                <Button size="small" onClick={() => openModal("recuperar")}>
                  Recuperar Cuenta
                </Button>
                <Button size="small" onClick={() => openModal("registro")}>
                  Registrarse
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Index.propTypes = {
  login: PropTypes.func,
  setLibs: PropTypes.func,
  updateCatalogs: PropTypes.func,
};

const actions = {
  login,
  setLibs,
  updateCatalogs,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(Index);

import React from "react";

import {
  Box,
  IconButton,
  Divider,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";

import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  }
}));

const Column = ({ cols = 12, children, center, right, header }) => (
  <Grid
    item
    xs={cols}
    style={{
      textAlign: center ? "center" : right ? "right" : "left",
      padding: header ? 8 : 0,
      paddingLeft: 16,
      paddingRight: 16
    }}
  >
    {children}
  </Grid>
);

const Payment = ({ item }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Divider />
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="center"
        style={{ height: 40 }}
      >
        <Column cols={5} center>{item.nombre}</Column>
        <Column cols={2}>{item.fecha}</Column>
        <Column cols={2} center>{item.estatus}</Column>
        <Column cols={2} center>{item.total}</Column>
        <Column cols={1} center>
          {item.estatus === 'Pagada' && (
            <IconButton aria-label="delete" className={classes.margin}>
              <CancelIcon fontSize="inherit" />
            </IconButton>
          )}
        </Column>
      </Grid>
    </React.Fragment>
  );
};

const Index = ({ data }) => {
  return (
    <Grid container direction="column"> 
      <Grid container spacing={0}>
        <Column cols={5} center header>
          Forma de pago
        </Column>
        <Column cols={2} center header>
          Fecha
        </Column>
        <Column cols={2} center header>
          Estatus
        </Column>
        <Column cols={2} center header>
          Importe
        </Column>
        <Column cols={1} />
      </Grid>

      {data.map((item, index) => <Payment item={item} />)}
    </Grid>
  );
};

export default Index;

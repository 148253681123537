import React from "react";
import { Box, CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Index = ({ onChange, fetch, ...props }) => {
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const fetchData = async () => {
    setData([]);
    if (!inputValue || inputValue.length < 3) return;

    try {
      const res = await fetch(inputValue);
      setLoading(false);
      setData(res);
    } catch (error) {
      setLoading(false);
      setData([]);
    }
  };

  React.useEffect(() => {
    setLoading(!!inputValue);
    const timeout = setTimeout(async () => {
      await fetchData();
    }, 300);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  React.useEffect(() => {
    onChange(value);
  }, [value]);

  React.useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  return [
    <Autocomplete
      {...props}
      filterOptions={(x) => x}
      options={inputValue ? data : []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      openOnFocus={false}
      freeSolo
      value={value}
      autoHighlight
      getOptionLabel={(option) => {
        return typeof option === "string"
          ? option
          : `${option.vc_nombre} ${option.vc_apellidoPaterno} ${option.vc_apellidoMaterno}`;
      }}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") return;
        if (newValue && "loading" in newValue) return;

        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      loading={loading}
      style={{ margin: 0 }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          size="small"
          {...props}
          {...params}
          InputProps={{
            inputRef: params.InputProps.ref,
            ...params.InputProps,
            endAdornment: (
              <Box>
                {loading && !value && (
                  <CircularProgress size={20} color="inherit" />
                )}
                {!loading && params.InputProps.endAdornment}
              </Box>
            ),
          }}
        />
      )}
      renderOption={(data, option) => {
        return (
          <Box>
            {data.vc_nombre} {data.vc_apellidoPaterno} {data.vc_apellidoMaterno}
          </Box>
        );
      }}
    />,
  ];
};

Index.defaultProps = {
  onClick() {},
  onChange() {},
};

export default Index;

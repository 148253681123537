import moment from "moment";

export const getAge = (date) => {
  if (!date) return "No especificado";

  const a = moment();
  const b = moment(date, "YYYY-MM-DD");
  console.log(a.diff(b))
  const age = moment.duration(a.diff(b));
  console.log(age)
  const years = age.years();
  const months = age.months();
  const days = age.days();

  if(years < 1 && !months) return `${days} días`
  if(years < 1 && !days) return `${months} meses`
  if(years < 1 && days) return `${months}m ${days}d`
  if( years <= 5 && !months) return `${years} años`
  if( years <= 5 && months) return `${years}a ${months}m`

  return `${years} años`
};

const displaySummaryPatient = (pacienteActual, catalogos) => {
  const getDiff = (dt_inicio, dt_fin) => {
    const diff = moment(dt_fin).diff(moment(dt_inicio), "M");
    switch (true) {
      case diff < 1: {
        return "menos de un mes";
      }
      case diff >= 1 && diff < 12: {
        return `${diff.toFixed(0)} meses`;
      }
      case diff >= 12 && diff < 24: {
        return `un año y ${(diff - 12).toFixed(0)} meses`;
      }
      default: {
        return `${(diff / 12).toFixed(0)} años`;
      }
    }
  };
  const pd = pacienteActual.detalle;

  const descripcionPaciente = `Paciente ${
    pd.id_sexo === 1 ? "masculino" : "femenino"
  } de ${moment().diff(
    moment(pd.dt_nacimiento, "YYYY-MM-DD"),
    "years"
  )} de edad`;
  let diagnosticosPaciente = "";
  pacienteActual.diagnosticosPaciente.map((dx, index) => {
    const lastDetalle = getLastDetalle(dx.detalles, "dt_vigencia");
    const isVigente = isDetalleActive(lastDetalle);
    if (isVigente) {
      const dxCIE =
        catalogos.diagnosticos.find(
          (e) => e.id === lastDetalle.id_diagnostico
        ) || null;
      diagnosticosPaciente = `${
        diagnosticosPaciente === ""
          ? ", actualmente refiere padecer "
          : diagnosticosPaciente
      } ${dxCIE.vc_nombre} desde hace ${getDiff(
        lastDetalle.dt_inicio,
        moment().format("YYYY-MM-DD HH:mm:ss")
      )}, `;
    }
  });
  let medicamentosPaciente = "";
  pacienteActual.medicamentosPaciente.map((med, index) => {
    const lastDetalle = getLastDetalle(med.detalles, "dt_vigencia");
    const isVigente = isDetalleActive(lastDetalle);
    if (isVigente) {
      const medicamento =
        catalogos.medicamentosDgis.find((e) => e.id === med.id_medicamento) ||
        med.vc_medicamento ||
        null;
      medicamentosPaciente = `${
        medicamentosPaciente === ""
          ? ", actualmente en tratamiento con "
          : medicamentosPaciente
      } ${medicamento.vc_nombre} desde hace ${getDiff(
        lastDetalle.dt_inicio,
        moment().format("YYYY-MM-DD HH:mm:ss")
      )}, `;
    }
  });
  return descripcionPaciente + diagnosticosPaciente + medicamentosPaciente;
};
const validationBodyInnerValues = (body, catalogo, catalogos) => {
  let noNulls = [];
  let noSuccess = [];
  catalogo.fieldsConfig.sets.forEach((set, index) => {
    (set.fields || []).forEach((field, indexField) => {
      if (
        !field.nullify &&
        (body[field.key] === null ||
          body[field.key] === undefined ||
          body[field.key] === "" ||
          (typeof body[field.key] !== "string" && isNaN(body[field.key])))
      ) {
        noNulls.push(field.vc_name);
      }
      if (field.regExp) {
        const regExp = new RegExp(field.regExp);
        const val = regExp.test(body[field.key]);
        if (
          !val &&
          body[field.key] !== null &&
          body[field.key] !== undefined &&
          body[field.key] !== ""
        ) {
          noSuccess.push(field.vc_name);
        }
      }
      if (field.key.split(".").pop().startsWith("dt_")) {
        const timeValue = moment(body[field.key], "DD-MM-YYYY");
        if (
          !timeValue.isValid() &&
          body[field.key] !== null &&
          body[field.key] !== undefined &&
          body[field.key] !== ""
        ) {
          noSuccess.push(field.vc_name);
        }
      }
    });
    if (set.id_typeSet === 2 && set.catalog) {
      const innerCatalog = catalogos.find(
        (e) => e.vc_nameTable === set.catalog.vc_nameTable
      );
      (body[set.catalog.vc_nameObject] || []).map((innerBody, index) => {
        const resultInner = validationBodyInnerValues(
          innerBody,
          innerCatalog,
          catalogos
        );
        noNulls = [...noNulls, ...resultInner.noNulls];
        noSuccess = [...noSuccess, ...resultInner.noSuccess];
      });
    }
  });
  return { noNulls, noSuccess, title: "¡Revisa los siguientes campos!" };
};
const validationBody = (body, catalogo, catalogos) => {
  let noNulls = [];
  let noSuccess = [];
  catalogo.fieldsConfig.sets.forEach((set, index) => {
    (set.fields || []).forEach((field, indexField) => {
      if (
        !field.nullify &&
        (body[field.key] === null ||
          body[field.key] === undefined ||
          body[field.key] === "" ||
          (typeof body[field.key] !== "string" && isNaN(body[field.key])))
      ) {
        noNulls.push(field.vc_name);
      }
      if (field.regExp) {
        const regExp = new RegExp(field.regExp);
        const val = regExp.test(body[field.key]);

        if (
          !val &&
          body[field.key] !== null &&
          body[field.key] !== undefined &&
          body[field.key] !== ""
        ) {
          noSuccess.push(field.vc_name);
        }
      }
      if (field.key.split(".").pop().startsWith("dt_")) {
        const timeValue = moment(body[field.key], "DD-MM-YYYY");
        if (
          !timeValue.isValid() &&
          body[field.key] !== null &&
          body[field.key] !== undefined &&
          body[field.key] !== ""
        ) {
          noSuccess.push(field.vc_name);
        }
      }
    });
    if (set.id_typeSet === 2 && set.catalog) {
      const innerCatalog = catalogos.find(
        (e) => e.vc_nameTable === set.catalog.vc_nameTable
      );
      (body[set.catalog.vc_nameObject] || []).map((innerBody, index) => {
        const resultInner = validationBodyInnerValues(
          innerBody,
          innerCatalog,
          catalogos
        );
        noNulls = [...noNulls, ...resultInner.noNulls];
        noSuccess = [...noSuccess, ...resultInner.noSuccess];
      });
    } else if (set.id_typeSet === 3 && set.catalog) {
      const innerCatalog = catalogos.find(
        (e) => e.vc_nameTable === set.catalog.vc_nameTable
      );
      const resultInner = validationBodyInnerValues(
        body[set.catalog.vc_nameObject] || {},
        innerCatalog,
        catalogos
      );
      noNulls = [...noNulls, ...resultInner.noNulls];
      noSuccess = [...noSuccess, ...resultInner.noSuccess];
    }
  });
  return { noNulls, noSuccess, title: "¡Revisa los siguientes campos!" };
};

export default {
  validateOptionsRol(action) {
    const rolesIds = (window.amplify.store("roles") || []).map((rol, index) => {
      return rol.id_rol;
    });
    switch (action) {
      case "consultar": {
        return (
          rolesIds.includes(5) ||
          rolesIds.includes(6) ||
          rolesIds.includes(7) ||
          rolesIds.includes(8)
        );
      }
      case "editar cita": {
        return (
          rolesIds.includes(5) ||
          rolesIds.includes(6) ||
          rolesIds.includes(7) ||
          rolesIds.includes(8) ||
          rolesIds.includes(12)
        );
      }
      case "formularios": {
        return (
          rolesIds.includes(5) ||
          rolesIds.includes(6) ||
          rolesIds.includes(7) ||
          rolesIds.includes(8) ||
          rolesIds.includes(12)
        );
      }
      case "cancelar cita": {
        return true;
      }
      case "covid19": {
        return true;
      }
      default: {
        return false;
      }
    }
  },
  getCalendarLayout(agendaDia) {
    const adjust = (daySystem) => {
      switch (daySystem) {
        case 6: {
          return 0;
        }
        default: {
          return daySystem + 1;
        }
      }
    };
    const nextDays = (daysOfMonthPrev, daysInMonth) => {
      const daysOver = (daysOfMonthPrev + daysInMonth) % 7;
      if (daysOver !== 0) {
        return 7 - daysOver;
      }
      return 0;
    };

    const weekDayInit = moment(
      `${moment(agendaDia).format("YYYY-MM-")}01`
    ).weekday();
    const daysInMonth = moment(agendaDia).daysInMonth();
    const daysOfMonthPrev = adjust(weekDayInit);
    const nextMonthDays = nextDays(daysOfMonthPrev, daysInMonth);
    const totalDaysToRender = daysOfMonthPrev + daysInMonth + nextMonthDays;
    const daysOfCalendar = [];
    const getDate = (index) => {
      const date = moment(`${moment(agendaDia).format("YYYY-MM-")}01`);
      switch (true) {
        case index < daysOfMonthPrev: {
          const restDays = index - daysOfMonthPrev;
          date.add(restDays, "days");
          return date.format("YYYY-MM-DD");
        }
        default: {
          const restDays = index - daysOfMonthPrev;
          date.add(restDays, "days");
          return date.format("YYYY-MM-DD");
        }
      }
    };
    for (let i = 0; i < totalDaysToRender; i++) {
      const dt_inicio = getDate(i);
      daysOfCalendar.push({
        dt_inicio: getDate(i),
        sn_hoy: dt_inicio === moment(agendaDia).format("YYYY-MM-DD") ? 1 : 0,
        sn_mesActual:
          i >= daysOfMonthPrev && i < daysOfMonthPrev + daysInMonth ? 1 : 0,
        nu_citas: 0,
        nu_disponibles: 0,
      });
    }
    return daysOfCalendar;
  },
  validationBodyInnerValues,
  validationBody,
  displaySummaryPatient,
};

export const getLastDetalle = (detalles, type) => {
  if (!type) {
    return [
      ...[...detalles].sort(function (a, b) {
        return moment(a.dt_registro) - moment(b.dt_registro);
      }),
    ].pop();
  } else {
    return [
      ...[...detalles].sort(function (a, b) {
        return moment(a[type]) - moment(b[type]);
      }),
    ].pop();
  }
};
export const getIndexLastDetalle = (detalles, type) => {
  let indexLastdetalle = 0;
  detalles.map((item, index) => {
    if (!type) {
      if (
        moment(detalles[indexLastdetalle].dt_registro) <
        moment(item.dt_registro)
      ) {
        indexLastdetalle = index;
      }
    } else {
      if (moment(detalles[indexLastdetalle][type]) < moment(item[type])) {
        indexLastdetalle = index;
      }
    }
  });
  return indexLastdetalle;
};

export const isDetalleActive = (lastDetalle) => {
  if (!lastDetalle) return false;
  // console.log('lastDetalle', lastDetalle)
  if (moment(lastDetalle.dt_vigencia) < moment()) {
    return false;
  }
  return true;
};

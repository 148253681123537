import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  List,
  Typography,
  makeStyles,
  Fade,
} from "@material-ui/core";

import Input from "app/components/Form/Input";

const useStyles = makeStyles((theme) => ({
  title: { paddingBottom: theme.spacing(1) },
  content: {
    minWidth: "400px",
    "& > .MuiDialogContentText-root": {
      marginBottom: theme.spacing(0),
    },
    "& > .descripcion": {
      marginBottom: theme.spacing(2),
    },
    "& > .MuiInput-root": {
      marginBottom: theme.spacing(2),
    },
    "& > .MuiInput-root:last-child": {
      marginBottom: theme.spacing(0),
    },
  },
}));
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const Index = ({ open, close, accept, data, ...props }) => {
  const classes = useStyles();
  const [disabled, setDisabled] = React.useState(false);
  const [content, setContent] = React.useState(null);
  const [descripcion, setDescripcion] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");

  React.useEffect(() => {
    setEmail("");
    setPassword("");
    setPasswordConfirm("");
  }, [open]);

  React.useEffect(() => {
    if (!data) {
      setDisabled(!email);
      setDescripcion(
        "Introduce la dirección de correo electrónico asociados con su cuenta Thin-k, y le enviaremos un correo electrónico con un enlace para restablecer su contraseña."
      );
      setContent([
        <DialogContentText key={'mail'}>E-mail</DialogContentText>,
        <Input
          key={'mail Input'}
          dense
          value={email}
          autoFocus
          onChange={(value) => setEmail(value)}
        />,
      ]);
    } else {
      setDisabled(
        !password ||
        !passwordConfirm ||
        password.length < 6 ||
        password !== passwordConfirm
      );

      let error = "";
      if (password && passwordConfirm && password.length < 6)
        error = "La contraseña debe contener al menos 6 caracteres";
      if (
        password &&
        passwordConfirm &&
        password.length > 5 &&
        password !== passwordConfirm
      )
        error = "Las contraseñas deben coincidir";

      setDescripcion(
        `Introduce la nueva contraseña de su cuenta Thin-k ${data?.email || ""}`
      );
      setContent([
        <DialogContentText key={'Contraseña'}>Nueva Contraseña</DialogContentText>,
        <Input
        key={'password'}
          dense
          value={password}
          type="password"
          autoFocus
          onChange={(value) => setPassword(value)}
        />,
        <DialogContentText key={'Confirmar Contraseña'}>Confirmar Contraseña</DialogContentText>,
        <Input
          key={'passwordConfirm'}
          dense
          value={passwordConfirm}
          type="password"
          onChange={(value) => setPasswordConfirm(value)}
        />,
        <Fade in={error}>
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        </Fade>,
      ]);
    }
  }, [data, email, password, passwordConfirm]);

  const handleAccept = () => {
    if (data) {
      accept({
        vc_email: data.email,
        vc_password: password,
        vc_token: data.token,
      });
    } else accept(email);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={close}
      keepMounted
      {...props}
    >
      <DialogTitle className={classes.title}>Recuperar Contraseña</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText className="descripcion">
          {descripcion}
        </DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disabled}
          onClick={handleAccept}
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Index.defaultProps = {
  open: false,
  data: null,
  close: () => { },
  accept: () => { },
};

Index.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  close: PropTypes.func,
  accept: PropTypes.func,
};

export default Index;

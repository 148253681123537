import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  outlinedInput: {
    padding: '8px 14px'
  },
  inputLabel: {
    transform: 'translate(14px, 10px) scale(1)'
  }
}));
export default props => {

  const textTransform = (textTransform, text) => {
    switch (textTransform) {
        case 'uppercase': {
            return text.toUpperCase()
        }
        case 'capitalize': {
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        }
        default: {
            return text;
        }
    }
}
  const classes = useStyles();
  const { fullWidth, label, meta = {}, validation = () => {}, ...moreInputProps } = props;

  const inputProps = Object.assign({
    autoComplete: 'off',
    autoCorrect: 'off',
    spellCheck: 'off',
    className: classes.outlinedInput,
    style: {textTransform: props.texttransform || null}
  }, moreInputProps, { label: moreInputProps.required ? `${label} *` : label });

  const InputProps = { inputProps };
  const InputLabelProps = { className: classes.inputLabel };
  const [value, setValue] = React.useState('');
  const [prevValueProps, setPrevValueProps] = React.useState(null);
  const testError = validation(value);
  const error = validation && value.length > 0 ? !testError ? false : true  : false;
  
  let errorValidate = error === true ? testError || 'Formato invalido' : props.errormessage;
  useEffect(() => {
    if (props.value != value.value && props.value != prevValueProps) {
        if(props.value !== null){
          setPrevValueProps(props.value)
          setValue(props.value)
        }
    }
}, [props])
const onChangeCapture = (e) => {
  if (!props.maxLength ){
    setValue(props.texttransform ? textTransform(props.texttransform, e.target.value) : e.target.value)
  }else if (props.maxLength >= e.target.value.length) {
    console.log('maxlength', props.maxLength)
    setValue(props.texttransform ? textTransform(props.texttransform, e.target.value) : e.target.value)
  }
  props.onChange && props.onChange(e.target.value)
}
let val = true;
let regExp = props.regExp;
if (regExp){
  val = new RegExp(regExp).test(value);
  console.log(val)
}
  return (
    <TextField
      variant={props.variant || 'outlined'}
      margin="normal"
      autoComplete='off'
      autoCorrect='off'
      onChangeCapture={onChangeCapture}
      value={value}
      id={moreInputProps.fieldid}
      label={props.required ? `${props.label}*` : props.label}
      InputLabelProps={{...InputLabelProps}}
      fullWidth={ window.isMobile  ? true : fullWidth }
      autoComplete="off"
      helperText={!val ? props.errormessage || '*Formato invalido' : ''}
      disabled={props.hidden}
      error={false}
      style={{marginRight: 10, marginTop: 9}}
      type={props.type || 'text'}
      multiline
      InputProps={{endAdornment: props.endAdornment || null}}
    />
  );
};
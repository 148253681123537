import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import _ from 'lodash';

// crear componente
class TextInput extends Component {
    constructor(props) {
        super(props);
        let valid;
        let placeholder = props.placeholder;

        if (props.defaultValue) {
            valid = true;
            placeholder = props.defaultValue.name;
        } else {
            valid = !props.required;
        }

        this.state = {
            value: props.defaultValue ? props.defaultValue.value : '',
            valid,
            message: props.required ? `El campo ${props.label} es requerido` : '',
            dirty: false,
            placeholder
        };
    }


    // -----------------------------
    // ------ life cycle events ----
    // -----------------------------
    componentDidMount() {
        this.setForm();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.dirty && nextProps.defaultValue) {
            this.setState({ value: nextProps.defaultValue.value, valid: true });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextState, this.state) || !_.isEqual(nextProps, this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.eq(prevState, this.state)) {
            this.setForm();
        }
    }

    // -----------------------
    // ------ user events ----
    // -----------------------
    onChange(e) {
        this.setState({ dirty: true });
        
        if (e.target.files.length) {
            const currentFile = e.target.files[0];
            const name = currentFile.name;
            let format = currentFile.name.split('.');
            format = format[format.length - 1];
            const size = currentFile.size / 1024;

            if (this.props.maxSize && size > this.props.maxSize){
                 this.setState({
                    valid: false,
                    value: '',
                    message: `El archivo debe ser menor o igual a ${this.props.maxSize}kb`,
                    placeholder: 'Tamaño de archivo excedido'
                });
            } else if (this.props.extention && this.props.extention !== format) {
                 this.setState({
                    valid: false,
                    value: '',
                    message: `El campo ${this.props.label} no tiene un formato valido`,
                    placeholder: `La extensión ${format} no esta permitida`
                });
            } else {
                this.getBase64(currentFile, base64 => {
                    this.setState({ valid: true, value: base64, message: '', placeholder: name });
                });
            }
        }        
    }

    getBase64(currentFile, callback) {
        const reader = new FileReader(); 
        let base64;

        // asignar callback
        reader.onloadend = () => {
            base64 = reader.result;

            if (this.props.fullBase64 === false) {
                base64 = reader.result.split(',')[1];  
                callback(base64);
            } else {
                callback(base64);
            }
        };

        reader.readAsDataURL(currentFile); 
    }    
    
    setForm() {
        this.props.setFormData(this.props.name, this.state);
    }

    getValue() {
        return this.state.value;
    }
   
    dirtInput() {
        this.setState({ dirty: true });
    }

    resetInput() {
        let valid;
        let placeholder = this.props.placeholder;

        if (this.props.defaultValue) {
            valid = true;
            placeholder = this.props.defaultValue.name;
        } else {
            valid = !this.props.required;
        }

        this.state = {
            value: this.props.defaultValue ? this.props.defaultValue.value : '',
            valid,
            message: this.props.required ? `El campo ${this.props.label} es requerido` : '',
            dirty: false,
            placeholder
        };
    }


    // --------------------------
    // ------ render methods ----
    // --------------------------
    render() {
        const invalidInput = this.state.dirty && !this.state.valid;
        let className = '';
        let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
        let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

        if (this.props.className) {
            className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
        } else {
            className = invalidInput ? 'invalid' : 'valid';
        }

        if (invalidInput) {
            finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
            finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
        }

        return (
            <Form.Field>
                <label style={finalLabelStyle}>
                    { this.props.label }
                </label>

                <label style={{ ...styles.labelcontent, ...finalInputStyle }}>   
                    <label style={styles.labelFile}><span>{this.state.placeholder}</span></label>   
                    <Input
                        type='file'
                        name={this.props.name}
                        className={className}
                        onChange={this.onChange.bind(this)}
                        disabled={this.props.readOnly}
                        style={styles.inputfile}
                        onClick={() => this.props.callbackSelectInput()}
                    />
                    <div style={{ ...styles.buttom, ...this.props.buttonStyle }} > 
                        {this.props.buttonLabel}
                    </div>
                </label>
            </Form.Field>
        );
    }
}


// estilos
const styles = {
    errorLabel: {
        color: '#9F3A38'
    },
    input: {

    },
    errorInput: {
        background: 'rgba(224, 180, 180, 0.48)',
        border: '1px solid #9F3A38',
        color: '#9F3A38'
    },
    inputfile: { 
        width: '0.1px', 
        height: '0.1px', 
        opacity: 0, 
        overflow: 'hidden', 
        position: 'absolute', 
        zIndex: -1       
    },
    buttom: { 
        height: '40px', 
        background: '#E0E1E2',
        color: 'rgba(0,0,0,.6)',
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px'
    },
    labelFile: {
        flex: 1,  
        color: 'rgba(191,191,191,.87)',
        marginRight: '5px',
        paddingLeft: '15px',
        fontWeight: 500,    
        fontSize: '15px',
        display: 'flex',
        alignItems: 'center'
    },
     labelcontent: {
        display: 'flex',
        width: '100%',
        marginRight: '20px',        
        border: '1px solid #D8D8D8',
        marginBottom: 0
    },

};

// exportar componente
export default TextInput;

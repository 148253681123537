import React from "react";
import { connect, useSelector } from "react-redux";
import { Box, Button, Typography } from "@material-ui/core";

import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "app/containers/Think/actions";

import Header from "app/sales/components/Common/Header";
import Table from "app/sales/components/Caja/Table";
import Formulario from "app/sales/components/Caja/Formulario";
import Sidebar from "app/sales/components/Caja/Sidebar";

import useAbrirCaja from "app/sales/hooks/useAbrirCaja";
import CerrarCajaDialog from "app/sales/components/Cuentas/CerrarCajaDialog";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const Index = (props) => {
  const { AbrirCajaDialog, onOpen, selected } = useAbrirCaja();

  const state = useSelector((state) => state.Sales);
  const { productos } = state.venta;

  const [cerrarCuentaOpen, setCerrarCuentaOpen] = React.useState(false);

  // Sidebar
  React.useEffect(() => {
    props.setHelperSidebarRender(() => null);
    props.setDisplaySidebar(false);

    return () => {
      props.setHelperSidebarRender(() => null);
      props.setDisplaySidebar(false);
    };
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header>
        {selected || (
          <Button color="inherit" onClick={() => onOpen()}>
            Seleccionar caja
          </Button>
        )}
        {selected && (
          <Button color="inherit" onClick={() => setCerrarCuentaOpen(true)}>
            Corte de caja
          </Button>
        )}
      </Header>

      <Box flex="1" display="flex">
        <Box flex="1 1 auto">
          <Box p={2}>
            <Formulario />
          </Box>
          <Table my={2} data={productos} />
        </Box>

        <Sidebar />
      </Box>

      <AbrirCajaDialog />
      <CerrarCajaDialog
        open={cerrarCuentaOpen}
        onClose={() => setCerrarCuentaOpen(false)}
      />
    </Box>
  );
};

const actions = { setHelperSidebarRender, setDisplaySidebar };
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(Index);

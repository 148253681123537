import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { progressPercentage } from 'style-value-types';
import posed from 'react-pose';

const PoseDiv = posed.div({
  open: {
    height: 200
  },
  close: {
    height: 60
  }
});

const ExpansionPanel = withStyles({
  root: {
    padding: 0,
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    overflow: 'hidden'
  },
  content: {
    '&$expanded': {
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

export default function PreguntasItem(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = () => {
    if (!expanded) setExpanded(true);
  };

  return (
    <PoseDiv pose={props.open && window.isMobile ? 'open' : 'close'} style={{ overflow: 'hidden' }}>
      {props.item}
      {
        props.open && window.isMobile ?
          <div style={{ width: '100%', height: 150 }}>
            {props.chart}
          </div> :
          null
      }
    </PoseDiv>
  );
}




import React from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import { setLoggedIn, setCatalogo } from "app/sales/store/actions";

import notifications from "../../utils/notifications";

import Cuentas from "./Cuentas";
import Caja from "./Caja";
import Cajas from "./Cajas";
import Productos from "./Productos";
import Proveedores from "./Proveedores";
import Clientes from "./Clientes";
import Cuts from "../components/Cuts";

import useApi from "app/sales/hooks/useApi";
import useLogin from "app/sales/hooks/useLogin";

const menu = [
  {
    id: "cuentas",
    vc_nombre: "Cuentas",
    enable: true,
    opciones: [
      {
        id: "accountsDay",
        vc_nombre: "Cuentas del Dia",
        acciones: [
          {
            id: "agregar",
            vc_nombre: "Agregar Cuenta",
            icon: null,
          },
          {
            id: "corte",
            vc_nombre: "Corte de Caja",
            icon: null,
          },
        ],
      },
      {
        id: "accountsOpen",
        vc_nombre: "Cuentas Abiertas",
        acciones: [],
      },
      {
        id: "accountsHistory",
        vc_nombre: "Historial",
        acciones: [],
      },
    ],
  },
  {
    id: "caja",
    vc_nombre: "Caja",
    enable: true,
    opciones: [
      {
        id: "accountsDay",
        vc_nombre: "Cuentas del Dia",
        acciones: [
          {
            id: "agregar",
            vc_nombre: "Agregar Cuenta",
            icon: null,
          },
          {
            id: "corte",
            vc_nombre: "Corte de Caja",
            icon: null,
          },
        ],
      },
      {
        id: "accountsOpen",
        vc_nombre: "Cuentas Abiertas",
        acciones: [],
      },
      {
        id: "accountsHistory",
        vc_nombre: "Historial",
        acciones: [],
      },
    ],
  },
  {
    id: "cuts",
    vc_nombre: "Cortes",
    enable: true,
  },
  {
    id: "productos",
    vc_nombre: "Productos",
    enable: true,
  },
  // {
  //   id: "cajas",
  //   vc_nombre: "Cajas",
  //   enable: true,
  // },
  // {
  //   id: "proveedores",
  //   vc_nombre: "Proveedores",
  //   enable: true,
  // },
  // {
  //   id: "clientes",
  //   vc_nombre: "Clientes",
  //   enable: true,
  // },
];

const getContent = (moduloActual) => {
  switch (moduloActual) {
    case "cuentas":
      return <Cuentas />;
    case "caja":
      return <Caja />;
    case "cuts":
      return <Cuts />;
    case "productos":
      return <Productos />;
    case "cajas":
      return <Cajas />;
    case "proveedores":
      return <Proveedores />;
    case "clientes":
      return <Clientes />;
    default:
      return <Box />;
  }
};

const Index = (props) => {
  const { list } = useApi("ventas/formas-pago");
  const dispatch = useDispatch();
  const [content, setContent] = React.useState(null);
  const [moduloActual, setModuloActual] = React.useState("cuentas");
  const isLogged = window.amplify.store("token") != null;

  const { LoginDialog, isLoggedIn } = useLogin();

  React.useEffect(() => {
    const options = menu.map((option) => ({
      ...option,
      disabled: false,
      onClick: () => {
        if (isLogged) {
          props.setViewTitle(option.vc_nombre);
          setModuloActual(option.id);
        } else {
          notifications.info(
            "¡Registrate con un plan para acceder a todas las herramientas!"
          );
        }
      },
    }));

    props.setSubMenuOptions(options);
    props.setActiveView("cuentas");

    list()
      .then(({ items }) => dispatch(setCatalogo("formasPago", items)))
      .catch(() => dispatch(setCatalogo("formasPago", [])));
  }, []);

  React.useEffect(() => {
    setContent(React.cloneElement(getContent(moduloActual), {}));
  }, [moduloActual]);

  return (
    <Box height="100%" bgcolor="rgba(200, 200, 200, 0.2)">
      {isLoggedIn && content}
      <LoginDialog />
    </Box>
  );
};

const mapStateToProps = (state) => ({ state: state.Sales });
const actions = { setLoggedIn, setCatalogo };

export default connect(mapStateToProps, actions)(Index);

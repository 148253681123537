import http from "../http";

export default {
  getDiaryByDay(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.get(
      `/app/api/agenda/${body.id_agenda}/dia?dt_fecha=${body.dt_fecha}`,
      headers,
      callback
    );
  },
  getDiaryByDays(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.post(
      `/app/api/agenda/${body.id_agenda}/dias`,
      headers,
      { fechas: body.fechas },
      callback
    );
  },
  getDiaryByMonth(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.get(
      `/app/api/agenda/${body.id_agenda}/mes?dt_fecha=${body.dt_fecha}`,
      headers,
      callback
    );
  },
  getUpcomings(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.get(
      `/app/api/agenda/${body.id_agenda}/proximas?dt_fecha=${body.dt_fecha}&nu_proximas=${body.nu_proximas}`,
      headers,
      callback
    );
  },
  getAvailable({ id_agenda, ...body }, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.post(
      `/app/api/agenda/${id_agenda}/disponibles`,
      headers,
      body,
      callback
    );
  },
  saveMeeting(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.post("/app/api/citas", headers, body, callback);
  },
  cancelMeeting(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.put(`/app/api/citas/${body.id}/estatus`, headers, body, callback);
  },
  editMeeting(body, callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.put(`/app/api/citas/${body.id}`, headers, body, callback);
  },
};

import React from "react";
import { connect } from "react-redux";

import { Box, Button } from "@material-ui/core";

import SubMenuModulos from "app/material-ui/SubMenuModulos";
import ConfirmAlert from "app/material-ui/ConfirmAlert";

import ModalForm from "app/sales/components/Proveedores/ModalForm";
import Sidebar from "app/sales/components/Proveedores/Sidebar";
import Table from "app/sales/components/Common/Table";
import { columns } from "app/sales/containers/Proveedores/fields";

import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "app/containers/Think/actions";

import useApi from "app/sales/hooks/useApi";

const Index = (props) => {
  const api = useApi('proveedores')
  const [data, setData] = React.useState([]);
  const [rowSelected, setRowSelected] = React.useState({
    index: -1,
    row: null,
  });
  const [modalForm, setModalForm] = React.useState(false);
  const [modalConfirm, setModalConfirm] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    props.setDisplaySidebar(false);
    props.setHelperSidebarRender(null);

    api.listado(20, 1).then((res) => {
      setData(res.items);
    });
  }, []);

  React.useEffect(() => {
    if (rowSelected.row) {
      handleSelect(null, -1);
    }
  }, [data, searchText]);

  React.useEffect(() => {
    props.setHelperSidebarRender(() =>
      rowSelected.row ? (
        <Sidebar
          data={rowSelected.row}
          editar={() => setModalForm(true)}
          eliminar={() => setModalConfirm(true)}
        />
      ) : null
    );
    props.setDisplaySidebar(Boolean(rowSelected.row));
  }, [rowSelected]);

  const handleSelect = (row, index) => {
    const newRowSelected = { index: -1, row: null };

    if (rowSelected.index !== index) {
      newRowSelected.index = index;
      newRowSelected.row = row;
    }

    setRowSelected(newRowSelected);
  };

  const eliminar = async () => {
    const { row } = rowSelected;

    await api.eliminar(row.id);
    const { items } = await api.listado();

    setData(items);

    setModalConfirm(false);
    handleSelect(null, -1);
  };

  const guardar = async (data) => {
    await api.guardar(data);
    const { items } = await api.listado();

    setData(items);
    setModalForm(false);
  };

  return (
    <Box>
      <SubMenuModulos
        title={
          <Button
            color="inherit"
            onClick={() => {
              handleSelect(null, -1);
              setModalForm(true);
            }}
          >
            Agregar
          </Button>
        }
        type={"search"}
        searchText={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <Box>
        <Table
          title="Proveedores"
          search={searchText}
          columns={columns}
          data={data}
          onClick={handleSelect}
        />
      </Box>

      {modalForm && (
        <ModalForm
          open
          close={() => setModalForm(false)}
          accept={guardar}
          data={rowSelected.row}
        />
      )}

      {modalConfirm && (
        <ConfirmAlert
          title={`¿Desea eliminar el proveedor: ${rowSelected.row?.vcNombre}?`}
          onAccept={eliminar}
          onCancel={() => setModalConfirm(false)}
        />
      )}
    </Box>
  );
};

const actions = { setHelperSidebarRender, setDisplaySidebar };
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(Index);

import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import _ from "lodash";
import Qrcode from "qrcode.react";

const round = (num) => {
  const indexOf = `${num}`.indexOf(".");
  if (indexOf > 0) return Number(`${num}`.slice(0, indexOf + 3));
  return num;
};

export default React.forwardRef((props, ref) => {
  const { trigger, print = {} } = props;
  const { account = {}, pay } = print;
  const cliente = `${account.client.vc_nombre} ${account.client.vc_apellidoPaterno} ${account.client.vc_apellidoMaterno}`;

  const {
    institucion = window.amplify.store("institution") || {},
    details = [],
    pays = [],
    folio = "",
  } = pay;
  const abonado = pays.reduce((sum, v) => sum + v.nu_quantity, 0);
  const el = document.createElement("div");
  el.setAttribute("id", "print");
  const formatter = (v) =>
    "$ " + (v * 1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

  const onPrintClose = (callback) => () => {
    window.onafterprint = () => null;
    el.remove();
    callback();
  };

  const fecha = pay.dt_register
    ? moment(pay.dt_register).format("LL")
    : moment().format("LL");

  React.useImperativeHandle(ref, () => ({
    print() {
      return new Promise((resolve, reject) => {
        document.body.insertAdjacentElement("afterbegin", el);
        window.onafterprint = onPrintClose(resolve);
        window.print();
      });
    },
  }));
  const printComponent = (
    <div
      style={{
        width: "100%",
        maxWidth: "350px",
        color: "black",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: ".75rem",
      }}
    >
      <style>
        {`
            #print{
                display: none;
                z-index: 999999;
                position: relative;
                background-color: white;
                
            }
            @media print {
                body > *:not(#print) {
                    display: none;
                }
                #print {
                    display: block;
                }
            }
        `}
      </style>
      <img
        style={{ maxWidth: "200px", marginBottom: "30px" }}
        src={institucion.vc_urlLogo}
      />
      <h5
        style={{ margin: 0, textTransform: "uppercase", textAlign: "center" }}
      >
        {institucion.vc_razonSocial}
      </h5>
      <h5
        style={{ margin: 0, textTransform: "uppercase", textAlign: "center" }}
      >
        calle {institucion.vc_calle} Col. {institucion.vc_colonia} #
        {institucion.vc_codigoPostal}
      </h5>
      <p style={{ margin: "10px 0" }}>{fecha}</p>
      <p style={{ margin: "0" }}>Cliente: {cliente}</p>
      <p style={{ margin: "0" }}>Cajero: {pay.vc_nameUser}</p>
      {/* <p style={{ margin: "0 0 10px" }}>Medico: {cliente}</p> */}

      {folio && (
        <h5
          style={{
            margin: "10px 0",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          Folio {folio}
        </h5>
      )}

      <table style={{ margin: "10px 0" }}>
        <thead>
          <tr>
            <th style={{ textAlign: "left", padding: "0 3px" }}>Prod.</th>
            <th style={{ textAlign: "left", padding: "0 3px" }}>P. Unit.</th>
            <th style={{ textAlign: "left", padding: "0 3px" }}>Desc.</th>
            <th style={{ textAlign: "left", padding: "0 3px" }}>Total</th>
          </tr>
        </thead>
        <tbody>
          {details.map((v, i) => {
            const nu_unitValue = Number(
              `${v.nu_unitValue}`.split(",").join("")
            );
            let subtotal = v.nu_quantity * nu_unitValue;
            const descuento = (subtotal * (v.nu_discount || 0)) / 100;

            subtotal -= descuento;

            const iva = round(subtotal * (v.nu_tax > -1 ? v.nu_tax : 0.16));
            let total = subtotal + iva;

            return (
              <tr key={i}>
                <td>
                  {v.nu_quantity} - {v.text}.
                </td>
                <td style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                  {formatter(`${v.nu_unitValue}`.split(",").join(""))}
                </td>
                <td style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                  {v.nu_discount ? `${v.nu_discount}%` : ""}
                </td>
                <td style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                  {formatter(total)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table style={{ alignSelf: "flex-end", marginBottom: 20 }}>
        <tbody>
          <tr>
            <th style={{ widht: "200px", textAlign: "right" }}>Sub-Total</th>
            <td style={{ widht: "200px", textAlign: "right" }}>
              {formatter(pay.subTotal)}
            </td>
          </tr>
          <tr>
            <th style={{ widht: "200px", textAlign: "right" }}>IVA</th>
            <td style={{ widht: "200px", textAlign: "right" }}>
              {formatter(pay.iva)}
            </td>
          </tr>
          <tr>
            <th style={{ widht: "200px", textAlign: "right" }}>Total</th>
            <td style={{ widht: "200px", textAlign: "right" }}>
              {formatter(pay.total)}
            </td>
          </tr>
          <tr>
            <th style={{ widht: "200px", textAlign: "right" }}>Abonado</th>
            <td style={{ widht: "200px", textAlign: "right" }}>
              {formatter(abonado)}
            </td>
          </tr>
          <tr>
            <th style={{ widht: "200px", textAlign: "right" }}>
              Total a Pagar
            </th>
            <td style={{ widht: "200px", textAlign: "right" }}>
              {formatter(pay.total - abonado)}
            </td>
          </tr>
          <tr>
            <th style={{ widht: "200px", textAlign: "right" }}>Recibí</th>
            <td style={{ widht: "200px", textAlign: "right" }}>
              {formatter(pay.nu_received)}
            </td>
          </tr>
          <tr>
            <th style={{ widht: "200px", textAlign: "right" }}>Cambio</th>
            <td style={{ widht: "200px", textAlign: "right" }}>
              {formatter(pay.nu_change)}
            </td>
          </tr>
        </tbody>
      </table>

      <p style={{ margin: "0 0 10px" }}>
        Forma de Pago: {pay.id_method}
        {pay.id_method === "tarjeta" && ` de ${pay.id_cardType}`}
      </p>
      <Qrcode value="efrain" size={100} />
    </div>
  );

  return (
    <div>
      {props.children}
      {ReactDOM.createPortal(printComponent, el)}
    </div>
  );
});

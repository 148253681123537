import React from 'react';
import internalApi from '../../../../../utils/internalAPI';
import Charts from '../../../../../material-ui/Charts';
import { Paper, Typography, Box } from '@material-ui/core';
class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            data: []
        };
    }

    goToData() {
        const headers = {
            Authorization: window.amplify.store('token'),
            accept: 'application/json'
        };
        this.setState({ loading: true })
        const url = 'https://services1.arcgis.com/0MSEUqKaxRlEPj5g/arcgis/rest/services/ncov_cases/FeatureServer/2/query?f=json&where=Confirmed%20%3E%200&returnGeometry=false&spatialRel=esriSpatialRelIntersects&outFields=*&orderByFields=Confirmed%20desc&resultOffset=0&resultRecordCount=100&cacheHint=true'
        const request = internalApi.general(url, null, 'GET', (error, response) => {
            console.log(JSON.parse(response))
            if (error) {
                console.log('error', error)
                this.setState({ loading: false }, () => this.getCategories(null));
            } else if (response.message) {
                this.setState({ loading: false }, () => this.getCategories(JSON.parse(response)));
            } else if (response.error) {
                this.setState({ loading: false }, () => this.getCategories(null));
            } else {
                this.setState({ loading: false }, () => this.getCategories(JSON.parse(response)));
            }
        })
        console.log('request', request);
    }
    getCategories(data) {
        if (data) {
            const entries = data.features || {};
            const countrys = [];
            entries.map((entry, index) => {
                countrys.push({
                    vc_nombre: entry.attributes.Country_Region || 'N/A',
                    series: {
                        'Casos Confirmados': entry.attributes.Confirmed * 1 || 0,
                        'Casos Recuperados': entry.attributes.Recovered * 1 || 0,
                        'Muertes Reportadas': entry.attributes.Deaths * 1 || 0
                    }
                })

            })
            console.log('conuntrys', countrys)
            this.setState({ data: countrys });
        } else {
            this.setState({ data: [] });
        }
    }
    render() {
        return (
            <Box p={2}>
                <Typography style={{padding: 16}}>
                    Graficas de Evolución Mundial del Covid-19
                </Typography>
                <Paper>
                <Charts
                    colors={['rgba(0,200,200,1)', 'rgba(255,0,0,1)']}
                    data={this.state.data}
                />
            </Paper>
            </Box>
        );
    }

    componentDidMount() {
        console.log('renderizo')
        this.goToData()
    }
}

export default Index;

import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import {
  Box,
  Button,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  List,
  Grid,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import { Close, PersonOutline, AttachMoney } from "@material-ui/icons";
import useApi from "app/sales/hooks/useApi";
import InputSearchPatient from "app/components/Form/InputSearchPatient";
import ItemPatient from "app/components/Form/ItemPatient";
import Input from "app/components/Form/Input";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = (props) => {
  const { open = false, onClose = () => {}, onAccept = () => {} } = props;
  const [openAlert, setOpenAlert] = React.useState(false);
  const [paciente, setPaciente] = React.useState(null);

  React.useEffect(() => {
    if (open) setPaciente(null);
  }, [open]);

  const apiCliente = useApi("clientes");
  const apiCuentas = useApi("session/cuentas");

  const abrirCuenta = async () => {
    const cliente = await apiCliente.post({
      uuid_empresa: props.uuidEmpresa,
      vc_nombre: paciente.vc_nombre,
      vc_apellidoPaterno: paciente.vc_apellidoPaterno,
      vc_apellidoMaterno: paciente.vc_apellidoMaterno,
      id_sexo: paciente.id_sexo,
      vc_curp: paciente.vc_curp,
      dt_nacimiento: paciente.dt_nacimiento,
      vc_domicilio: paciente.vc_domicilio,
      ref_idPacienteGlobal: paciente.id_pacienteGlobal
    });

    const cuenta = await apiCuentas.post({
      uuid_cliente: cliente.uuid
    });

    onAccept(cuenta);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Agregar Cuenta</DialogTitle>
      <DialogContent style={{ maxWidth: "100%" }}>
        <Grid container>
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <DialogContentText>
              <PersonOutline />
            </DialogContentText>
          </Grid>
          <Grid item xs={11}>
            {!paciente && (
              <Box py={1}>
                <InputSearchPatient
                  onClick={setPaciente}
                  autoFocus
                  addPatient
                />
              </Box>
            )}
            {paciente && (
              <List dense>
                <ItemPatient
                  data={paciente}
                  dense
                  disableGutters
                  onDelete={() => setPaciente(null)}
                />
              </List>
            )}
          </Grid>
        </Grid>

        <Collapse in={openAlert}>
          <Alert
            variant="filled"
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            El cliente seleccionado tienen cuenta abierta!
          </Alert>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          style={{ alignSelf: "right" }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!paciente}
          onClick={() => abrirCuenta()}
          style={{ alignSelf: "right" }}
        >
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { catalogos } = state.Think;

  return {
    catalogo: catalogos.find((e) => e.vc_nameTable === "ins_pacientesDetalle"),
    uuidEmpresa: state.Sales.uuidEmpresa
  };
};
export default connect(mapStateToProps, [])(Modal);

import React from "react";
import Route from "react-router/es/Route";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Container from "../containers";

const skeletonApp = (Component) => {
  return (props) => (
    <Box
      m={0}
      height="100%"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      <Component {...props} />
    </Box>
  );
};

export default (() => {
  return <Route path="erp" component={skeletonApp(Container)} />;
})();

import React from "react";
import _ from "lodash";
import moment from "moment";
import { Box, Input, IconButton, Divider, ListItem } from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";
import Search from "@material-ui/icons/Search";

import PrintTicket from "../PrintTicketAbono";

export default (props) => {
  const { account = {}, data = [], onClick = () => {} } = props;
  const ticketsRef = React.useRef([]);

  const [search, setSearch] = React.useState("");
  const filtered = search
    ? data.filter((v) =>
        `${v.id_method}${v.vc_nameUser}${v.nu_quantity}${moment(
          v.dt_register
        ).format("LL")}`
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    : data;

  return (
    <Box>
      <Input
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        disableUnderline
        startAdornment={
          <IconButton size="small" style={{ marginLeft: 10, marginRight: 10 }}>
            <Search />
          </IconButton>
        }
        inputProps={{
          style: {
            padding: 8,
            width: "100%",
            backgroundColor: "rgba(200,200,200,0.2)",
            borderRadius: 5,
          },
        }}
        style={{ margin: 0, width: "100%", padding: 8 }}
      />
      <Divider />
      <GridUI container>
        <GridUI
          item
          xs={1}
          style={{ padding: 8, paddingTop: 10, paddingBottom: 8, fontSize: 12 }}
        >
          Metodo
        </GridUI>
        <GridUI
          item
          xs={2}
          style={{ padding: 8, paddingTop: 10, paddingBottom: 8, fontSize: 12 }}
        >
          Abono
        </GridUI>
        <GridUI
          item
          xs={4}
          style={{ padding: 8, paddingTop: 10, paddingBottom: 8, fontSize: 12 }}
        >
          Cajero
        </GridUI>
        <GridUI
          item
          xs={2}
          style={{ padding: 8, paddingTop: 10, paddingBottom: 8, fontSize: 12 }}
        >
          Fecha
        </GridUI>
        <GridUI
          item
          xs={2}
          style={{ padding: 8, paddingTop: 10, paddingBottom: 8, fontSize: 12 }}
        >
          Referencia
        </GridUI>
      </GridUI>
      <Divider />
      <Box
        style={{
          maxHeight: 200,
          paddingTop: 5,
          paddingBottom: 5,
          overflowY: "scroll",
          WebkitOverflowScrolling: "touch",
        }}
      >
        <GridUI>
          {filtered.map((pay, index) => {
            return (
              <PrintTicket
                key={index}
                print={{
                  account,
                  pay,
                }}
                ref={(el) => (ticketsRef.current[index] = el)}
              >
                <ListItem
                  key={index}
                  button
                  onClick={async () => {
                    const ticket = ticketsRef.current[index];

                    await ticket.print();
                    onClick();
                  }}
                >
                  <GridUI container spacing={2}>
                    <GridUI
                      item
                      xs={1}
                      style={{
                        padding: 8,
                        paddingTop: 10,
                        paddingBottom: 8,
                        fontSize: 12,
                        textAlign: "right",
                      }}
                    >
                      {pay.id_method || "N/A"}
                    </GridUI>
                    <GridUI
                      item
                      xs={2}
                      style={{
                        padding: 8,
                        paddingTop: 10,
                        paddingBottom: 8,
                        fontSize: 12,
                        textAlign: "right",
                      }}
                    >
                      {"$ "}
                      {pay.nu_quantity ? pay.nu_quantity.toFixed(2) : "N/A"}
                    </GridUI>
                    <GridUI
                      item
                      xs={4}
                      style={{
                        padding: 8,
                        paddingTop: 10,
                        paddingBottom: 8,
                        fontSize: 12,
                        textAlign: "left",
                      }}
                    >
                      {pay.vc_nameUser}
                    </GridUI>
                    <GridUI
                      item
                      xs={3}
                      style={{
                        padding: 8,
                        paddingTop: 10,
                        paddingBottom: 8,
                        fontSize: 12,
                        textAlign: "left",
                      }}
                    >
                      {moment(pay.dt_register).format("LL")}
                    </GridUI>
                    <GridUI
                      item
                      xs={2}
                      style={{
                        padding: 8,
                        paddingTop: 10,
                        paddingBottom: 8,
                        fontSize: 12,
                        textAlign: "right",
                      }}
                    >
                      {pay.vc_reference || "-"}
                    </GridUI>
                  </GridUI>
                </ListItem>
              </PrintTicket>
            );
          })}
        </GridUI>
      </Box>
    </Box>
  );
};

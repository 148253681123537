export const fields = [
  "vc_rfc",
  "vc_nombre",
  "vc_razonSocial",
  "vc_domicilioFiscal",
  "nu_codigoPostal",
  "ref_id_institucion",
  'vc_email',
  'vc_telefono',
];

export const fieldsRequired = [
  "vc_rfc",
  "vc_nombre",
  "vc_razonSocial",
  "vc_domicilioFiscal",
];

export const fieldsForm = [
  {
    icon: "ShortText",
    name: "vc_nombre",
    props: { placeholder: "Nombre*" },
  },
  {
    icon: "Subject",
    name: "vc_razonSocial",
    props: { placeholder: "Razón Social*" },
  },
  {
    icon: "PlaceOutlined",
    name: "vc_domicilioFiscal",
    required: false,
    props: { placeholder: "Domicilio Fiscal" },
  },
  {
    icon: "PinDropOutlined",
    name: "nu_codigoPostal",
    required: false,
    props: {
      placeholder: "Codigo Postal",
      number: true,
      inputProps: { decimalScale: 0, thousandSeparator: false, maxLength: 5 },
    },
  },
  {
    icon: "MailOutline",
    name: "vc_email",
    required: true,
    props: { placeholder: "Correo Electronico" },
  },
  {
    icon: "ContactPhoneOutlined",
    name: "vc_telefono",
    required: true,

    props: { placeholder: "Teléfono", phoneNumber: true },
  },
];

export const columns = [
  { name: "vc_nombre", label: "Nombre" },
  { name: "vc_razonSocial", label: "Razón Social" },
  { name: "vc_domicilioFiscal", label: "Domicilio Fiscal" },
  { name: "nu_codigoPostal", label: "Código Postal" },
];

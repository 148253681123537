/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { menuPacientes as menu } from "app/containers/Think/listados";

import Listado from "./Listado";
import Registro from "./Registro";
import { Box } from "@material-ui/core";

import { Modal, Loader } from "semantic-ui-react";

const index = (props) => {
  const [moduloActual, setModuloActual] = useState("listado");
  const [content, setContent] = useState(null);
  const [paciente, setPaciente] = React.useState(null);
  const [loading, setLoading] = React.useState(null);

  const modulo = menu.find((v) => v.id === moduloActual);
  const { opciones = [] } = modulo;

  const editar = (paciente) => {
    setPaciente(paciente);
    changeModulo("registrar");
  };

  React.useEffect(() => {
    const options = menu
      .map((option) => ({
        ...option,
        onClick: (m) => {
          setPaciente(null);
          setModuloActual(m.id);
        },
      }))
      .filter((v) => v.id !== "callcenter");

    props.setSubMenuOptions(options);
    props.setActiveView("listado");
  }, []);

  const changeModulo = (modulo) => {
    setModuloActual(modulo);
    props.setActiveView(modulo);
  };

  React.useEffect(() => {
    switch (moduloActual) {
      case "listado":
        setContent(
          <Listado
            modulo={modulo}
            opciones={opciones}
            editar={editar}
            setLoading={setLoading}
            addPatient={editar}
          />
        );
        break;
      case "registrar":
        setContent(
          <Registro
            data={paciente}
            opciones={opciones}
            changeModulo={() => changeModulo("listado")}
            setLoading={setLoading}
          />
        );
        break;
      default:
        setContent(<Box />);
        break;
    }
  }, [moduloActual]);

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      {content}
      <Modal
        open={!!loading}
        style={{
          boxShadow: "1px 0px 10px rgba(0, 0,0, 0)",
          backgroundColor: "rgba(0, 0,0, 0.9)",
        }}
      >
        <Loader size="massive" active>
          {loading}
        </Loader>
      </Modal>
    </Box>
  );
};

index.propTypes = {
  setActiveView: PropTypes.func,
  setSubMenuOptions: PropTypes.func,
  navigate: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const actions = {};

export default connect(mapStateToProps, actions)(index);

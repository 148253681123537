import {  UPDATE_CATALOGS, SET_ITEM_EDIT, SET_LIBS } from './types';

export const updateCatalogs = (response) => ({
    type: UPDATE_CATALOGS,
    payload: response
});

export const setItemEdit = (payload) => ({
    type: SET_ITEM_EDIT,
    payload
});

export const setLibs = (payload) => ({
    type: SET_LIBS,
    payload
});

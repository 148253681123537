import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  Divider,
  MenuItem,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProductSelect from "./ProductSelect";
import ThinkForm from "../../../material-ui/ThinkForm";
import PaymentMethod from "../../../material-ui/PaymentMethod";
import CustomizedSteppers from "./CustomizedSteppers";
import ConfirmAlert from "../../../material-ui/ConfirmAlert";
import FinishPay from "./FinishPay";
import repository from "../../../utils/repository";
import internalAPI from "../../../utils/internalAPI";
import { round } from "lodash";
import { login } from "../../../containers/Think/actions";
import { database, auth } from "firebase";
import PaypalPays from "app/components/PaypalPays/test";
import Loading from "app/agenda/components/Loading";
import { Modal } from "semantic-ui-react";
class ModalPaymentWizard extends React.Component {
  constructor() {
    super();
    this.auth = auth();
    this.db = database().ref();
    this.state = {
      step: { id: 1, vc_nombre: "institutionConfig" },
      paymentMethod: null,
      packageSelected: null,
      productsSelected: [],
      steps: [
        { id: 1, vc_nombre: "institutionConfig", vc_descripcion: "Configura" },
        {
          id: 2,
          vc_nombre: "productSelect",
          vc_descripcion: "Paquete inicial",
        },
        { id: 3, vc_nombre: "paymentMethod", vc_descripcion: "Metodo de pago" },
        { id: 4, vc_nombre: "finish", vc_descripcion: "Finalizar" },
      ],
      payments: null,
      ...this.chargeRegister(),
    };
  }
  componentDidMount() {
    // this.auth.onAuthStateChanged(() => {
    //     const user = this.auth.currentUser;
    //     console.log('user', this.auth, user);
    //     if (user && !user.isAnonymous) {
    //         this.db.child('payments').child('user').child(user.uid).orderByChild('active').equalTo(1).off();
    //         this.db.child('payments').child('user').child(user.uid).orderByChild('active').equalTo(1).on('value', (snapshot) => {
    //             const val = Object.values(snapshot.val() || {});
    //             console.log('valoes firebase', snapshot.val());
    //             if (val) {
    //                 this.setState({ payments: val })
    //             }
    //         })
    //     }
    // })
  }
  getNameUser() {
    const user =
      window.amplify.store("user") && window.amplify.store("user").usuario;
    return `${user.vc_nombre}${
      user.vc_apellidoPaterno ? ` ${user.vc_apellidoPaterno}` : ""
    }${user.vc_apellidoMaterno ? ` ${user.vc_apellidoMaterno}` : ""}`;
  }

  componentDidUpdate() {
    console.log("se renderizo");
    const vc_rfc = document.getElementById("vc_rfc");
    if (vc_rfc && !this.state.readyTovalidateRFC) {
      console.log("se cargo el observer en el rfc");
      this.setState({ readyTovalidateRFC: true });
      vc_rfc.addEventListener("keyup", (e) => {
        console.log("se escribio el rfc", e);
        if ((e.target.value || "").length >= 12)
          internalAPI.apiInstitucionExist(e.target.value, (err, response) => {
            if (err) {
              console.log("error", err);
            } else if (response && response.data) {
              const errorMessages = {
                title: "¡Ups!",
                message: response.message || "No se puede usar ese RFC.",
              };
              this.setState({ errorMessages, ins_exist: true }, () => {});
            }
          });
      });
    }
  }
  renderContent() {
    switch (this.state.step.vc_nombre) {
      case "institutionConfig": {
        const institucion = this.state.institucion || {};
        const catalogo = this.props.catalogo;
        return (
          <Box
            style={{
              width: "100%",
              height: "calc(100% - 50px)",
              borderRadius: 10,
              padding: 8,
              backgroundColor: "rgba(200,200,200,0.2)",
              overflowY: "scroll",
              WebkitOverflowScrolling: "touch",
            }}
          >
            {catalogo && (
              <ThinkForm
                type="form"
                withOutHeader
                setFunctions={(functions) => {
                  this.setState({ functions });
                  console.log("se carga functions");
                }}
                tags={[]}
                registro={institucion || {}}
                catalogo={catalogo}
              />
            )}
          </Box>
        );
      }
      case "productSelect": {
        return (
          <Box
            style={{
              width: "100%",
              height: "calc(100% - 50px)",
              borderRadius: 10,
              padding: 8,
              backgroundColor: "rgba(200,200,200,0.2)",
              overflowY: "scroll",
              WebkitOverflowScrolling: "touch",
            }}
          >
            <ProductSelect
              packageSelected={this.state.packageSelected || null}
              callbackSelect={(packageSelected) => {
                this.setState(
                  {
                    packageSelected,
                    step: this.state.steps.find((e) => e.id === 3),
                  },
                  () => {
                    this.pausedRegister({
                      institucion: this.state.institucion,
                      paymentMethod: null,
                      packageSelected: this.state.packageSelected,
                    });
                  }
                );
              }}
              callbackLoading={this.setLoading.bind(this)}
            />
          </Box>
        );
      }
      case "paymentMethod": {
        return (
          <Box
            style={{
              display: "flex",
              width: "100%",
              height: "calc(100% - 50px)",
              borderRadius: 10,
              padding: 8,
              backgroundColor: "rgba(200,200,200,0.2)",
              alignItems: "center",
              justifyContent: "center",
              overflowY: "scroll",
              WebkitOverflowScrolling: "touch",
            }}
          >
            <PaymentMethod
              // total={300}
              type="naked"
              onSubmit={({ id, deviceDataId, card }) => {
                const paymentMethod = {
                  tarjetaToken: id,
                  nombre: card.holder_name,
                  ultimosDigitos: card.card_number.slice(12, 16),
                  marca: card.brand,
                  dispositivoId: deviceDataId,
                };
                console.log("se cargo el token");
                this.setState(
                  {
                    paymentMethod,
                    step: this.state.steps.find((e) => e.id === 4),
                  },
                  () => {
                    console.log("se guardo el token");
                    this.pausedRegister({
                      institucion: this.state.institucion,
                      paymentMethod: null,
                      packageSelected: this.state.packageSelected,
                    });
                  }
                );
              }}
              callbackLoading={this.setLoading.bind(this)}
              name={this.getNameUser()}
            />
          </Box>
        );
      }
      case "finish": {
        return (
          <Box
            style={{
              width: "100%",
              height: "calc(100% - 50px)",
              borderRadius: 10,
              padding: 8,
              backgroundColor: "rgba(200,200,200,0.2)",
              alignItems: "center",
              justifyContent: "center",
              overflowY: "scroll",
              WebkitOverflowScrolling: "touch",
            }}
          >
            <FinishPay
              institucion={this.state.institucion}
              packageSelected={this.state.packageSelected}
              paymentMethod={this.state.paymentMethod}
              type="naked"
            />
          </Box>
        );
      }
    }
  }
  saveInstitution() {
    const institucion = this.state.institucion;
    this.setState({ loading: true });
    console.log("save institucion", institucion);
    internalAPI.editarCrearInstitucion(institucion, (error, response) => {
      if (error) {
        const successMessage = {
          title: "¡Institución no ha sido creada!",
          message: error.error || "Verifica tu conexión a internet",
          onAccept: null,
        };
        this.setState({ successMessage, loading: false });
      } else if (response.institucion) {
        const user = (window.amplify.store("user") || {}).usuario || {} || {};
        if (
          user.vc_email === "demo@thin-k.mx" ||
          user.vc_email === "sedf_83@hotmail.com"
        )
          this.setState({
            institucion: response.institucion,
            loading: false,
            step: this.state.steps.find((e) => e.id === this.state.step.id + 1),
          });
      }
    });
  }
  saveClient() {
    const {
      packageSelected,
      productsSelected = [],
      paymentMethod,
      institucion,
    } = this.state;
    console.log("save institution order", packageSelected);
    if (!institucion.id) return;
    let productos = [
      {
        paqueteProductoId: packageSelected.id,
        productoId: packageSelected.id,
        descripcion: packageSelected.nombre,
        precio: packageSelected.precio,
        cantidad: 1,
        subtotal: packageSelected.precio * 1,
        total: packageSelected.precio * 1.16,
        descuento: 0,
      },
    ];

    const totalProductos = productos
      .map((producto) => producto.total)
      .reduce((lastValue, currentValue) => lastValue + currentValue);

    const descuentoGlobal = Number.parseFloat(
      (1 - packageSelected.precio / totalProductos).toFixed(6)
    );

    if (descuentoGlobal < 1) {
      productos = productos.map((producto) => {
        const subtotal = round(
          producto.subtotal - producto.subtotal * descuentoGlobal,
          2
        );
        const total = round(producto.total - producto.total * descuentoGlobal);
        return Object.assign({}, producto, {
          descuento: descuentoGlobal,
          subtotal,
          total,
        });
      });
    }

    const totales = productos
      .map((producto) => {
        const { subtotal, total } = producto;

        return { subtotal, total };
      })
      .reduce((lastValue, currentValue) => {
        return {
          subtotal: lastValue.subtotal + currentValue.subtotal,
          total: lastValue.total + currentValue.total,
        };
      });

    const formaPago = { estatus: true, ...paymentMethod };

    const venta = {
      subtotal: totales.subtotal,
      total: totales.total,
      productos,
    };
    this.setState({ loading: true });

    internalAPI.clientesStartup(
      {
        id_institucion: institucion.id,
        cliente: {
          institucionId: institucion.id,
          nombre: institucion.vc_razonSocial,
          razonSocial: institucion.vc_razonSocial,
          rfc: institucion.vc_rfc,
          email: institucion.vc_email,
          numeroTelefono: institucion.vc_telefono,
          requiereCuenta: true,
        },
        formasPago: [formaPago],
        venta,
      },
      (err, institucion) => {
        let successMessage = null;
        if (err) {
          successMessage = {
            title: "¡Su cargo no fue procesado!",
            message: err.texto || "Intente de nuevo",
            onAccept: null,
          };
        } else {
          successMessage = {
            title: "¡Institución activada correctamente!",
            message:
              "Bienvenido a la plataforma de salud mas avanzada de México",
            onAccept: () => {
              const result = {
                ...(window.amplify.store("user") || {}),
                token: window.amplify.store("token") || null,
                institution: institucion,
                roles: institucion.roles || [],
              };
              this.props.login(result);
            },
          };
        }
        this.setState({ successMessage, loading: false });
      }
    );
  }
  validateSiguiente() {
    switch (this.state.step.id) {
      case 1: {
        return true;
      }
      case 3: {
        return this.state.step.id === 3 && !this.state.paymentMethod;
      }
      default: {
        return true;
      }
    }
  }
  pausedRegister({ institucion, paymentMethod, packageSelected }) {
    if (window.amplify.store("user")) {
      const user = (window.amplify.store("user") || {}).usuario || {};
      const registro = window.amplify.store(`${user.vc_email}-registro`) || {};
      window.amplify.store(`${user.vc_email}-registro`, {
        ...registro,
        ...(institucion ? { institucion } : {}),
        ...(paymentMethod ? { paymentMethod } : {}),
        ...(packageSelected ? { packageSelected } : {}),
      });
    } else {
      console.log("no hay usuario");
    }
  }
  getPayData() {
    if (this.state.payments && this.state.payments[0]) {
      const user = this.state.payments[0].purchase_units[0].shipping;
    }
  }
  chargeRegister() {
    if (window.amplify.store("user")) {
      const user = (window.amplify.store("user") || {}).usuario || {};
      const registro = window.amplify.store(`${user.vc_email}-registro`) || {};
      return registro;
      // this.setState({ ...(institucion ? { institucion } : {}), ...(paymentMethod ? { paymentMethod } : {}), ...(packageSelected ? { packageSelected } : {}) });
    }
    return {};
  }
  componentWillUnmount() {
    if (
      this.state.functions &&
      this.state.functions.getRegistro &&
      this.state.step.id === 1
    ) {
      this.pausedRegister({
        institucion: this.state.functions.getRegistro(),
        paymentMethod: null,
        packageSelected: this.state.packageSelected,
      });
    } else {
      this.pausedRegister({
        institucion: this.state.institucion,
        paymentMethod: null,
        packageSelected: this.state.packageSelected,
      });
    }
  }
  setLoading(loading, state) {
    this.setState({ loading, ...state });
  }
  actionsClick(step) {
    console.log("step", step);

    switch (step.id) {
      case 1: {
        if (this.state.functions && this.state.functions.getRegistro) {
          const body = this.state.functions.getRegistro();
          const errorMessages = repository.validationBody(
            body,
            this.props.catalogo,
            this.props.catalogos
          );

          //   if (
          //     errorMessages.noNulls.length === 0 &&
          //     errorMessages.noSuccess.length === 0
          //   ) {
          //     this.setState({ institucion: body }, () => {
          //       this.saveInstitution();
          //     });
          //   } else {
          //     this.setState({ errorMessages });
          //   }

          this.setState({
            step: this.state.steps.find((e) => e.id === this.state.step.id + 1),
          });
        }
        break;
      }
      case 4: {
        this.saveClient();
      }
      default: {
        this.setState({
          step: this.state.steps.find((e) => e.id === this.state.step.id + 1),
        });
      }
    }
  }
  render() {
    const paddingButtons = window.isMobile ? 20 : 30;


    const usuario = (window.amplify.store("user") || {}).usuario || {};

    console.log(usuario)
    return (
      <Box style={{ width: "100%", height: "100%" }}>
        <CustomizedSteppers step={this.state.step} steps={this.state.steps} />
        <Box
          style={{
            width: "100%",
            alignContent: "space-between",
            height: "calc(100% - 135px)",
            padding: 20,
            paddingTop: 0,
          }}
        >
          {this.renderContent()}
        </Box>
        <Button
          onClick={() => {
            if (this.state.step.id > 1) {
              this.setState({
                step: this.state.steps.find(
                  (e) => e.id === this.state.step.id - 1
                ),
              });
            } else {
              this.props.navigate("#/admin");
            }
          }}
          color="primary"
          variant="outlined"
          style={{
            position: "absolute",
            bottom: paddingButtons,
            left: paddingButtons,
          }}
        >
          {this.state.step === "institutionConfig" ? "Salir" : "Atrás"}
        </Button>
        <Button
          onClick={() => this.actionsClick(this.state.step)}
          endIcon={
            this.state.loading && (
              <CircularProgress size={15} color="secondary" />
            )
          }
          color="primary"
          disabled={!this.validateSiguiente()}
          variant="contained"
          style={{
            position: "absolute",
            bottom: paddingButtons,
            right: paddingButtons,
          }}
        >
          {this.state.step.vc_nombre === "finish"
            ? "Confirmar Pago"
            : "Siguiente"}
        </Button>
        {this.state.errorMessages && (
          <ConfirmAlert
            title={this.state.errorMessages.title}
            message={
              <Box style={{ maxHeight: "90vh" }}>
                {this.state.errorMessages.message || ""}
                {this.state.errorMessages.noNulls &&
                  this.state.errorMessages.noNulls.length > 0 && (
                    <Box>
                      <Divider />
                      <MenuItem key={"vacios"}>
                        {"No deben estar vacios:"}
                      </MenuItem>
                      <Divider />
                      {this.state.errorMessages.noNulls.map((field, index) => {
                        return <MenuItem key={index}>{field}</MenuItem>;
                      })}
                    </Box>
                  )}
                {this.state.errorMessages.noSuccess &&
                  this.state.errorMessages.noSuccess.length > 0 && (
                    <Box>
                      <Divider />
                      <MenuItem key={"formatos"}>
                        {"Formato invalido:"}
                      </MenuItem>
                      <Divider />
                      {this.state.errorMessages.noSuccess.map(
                        (field, index) => {
                          return <MenuItem key={index}>{field}</MenuItem>;
                        }
                      )}
                    </Box>
                  )}
              </Box>
            }
            onAccept={
              this.state.errorMessages.onAccept ||
              (() => {
                this.setState({ errorMessages: null });
              })
            }
          />
        )}
        {this.state.successMessage && (
          <ConfirmAlert
            title={this.state.successMessage.title || ""}
            message={this.state.successMessage.message || ""}
            onAccept={() => {
              this.setState(
                { errorMessages: null, successMessage: null },
                this.state.successMessage.onAccept || null
              );
            }}
          />
        )}
        {this.state.payments && this.state.payments.length === 0 && usuario.vc_email !== "admin@thin-k.mx" && (
          <PaypalPays
            onCompleted={(pay) => {
              this.db
                .child("payments")
                .child("user")
                .child(this.auth.currentUser.uid)
                .push({
                  uid: this.auth.currentUser.uid,
                  ...pay,
                });
              this.db
                .child("payments")
                .child("root")
                .child(this.auth.currentUser.uid)
                .push({
                  active: 0,
                  uid: this.auth.currentUser.uid,
                  ...pay,
                });
            }}
            onClose={() => this.props.navigate("#/admin/inicio")}
            open={this.state.payments && this.state.payments.length === 0}
          />
        )}

        {/* <Modal open={!this.state.payments}>
                    <Loading text={'Buscando compras anteriores'} cancelButton={
                        <Button fullWidth variant='outlined' onClick={() => this.props.navigate('#/admin/inicio')} style={{ marginTop: 16 }} >
                            {'cancelar'}
                        </Button>
                    } />
                </Modal> */}
      </Box>
    );
  }
}
const actions = {
  login,
};
const mapStateToProps = (state) => {
  const { catalogos } = state.Think;
  return {
    catalogo: catalogos.find((e) => e.vc_nameTable === "ins_instituciones"),
    catalogos,
  };
};
export default connect(mapStateToProps, actions)(ModalPaymentWizard);

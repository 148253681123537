import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import internalAPI from "app/utils/internalAPI";
import api from "app/agenda/api";
import {
  CalendarTodayOutlined,
  ContactsOutlined,
  PersonOutline,
  ScheduleOutlined,
  SubjectOutlined,
  TodayOutlined,
  ListAlt,
  LocationOn,
} from "@material-ui/icons";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
  List,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import GridUI from "@material-ui/core/Grid";

import LocationSearch from "app/components/Form/LocationSearch";
import Input from "app/components/Form/Input";
import InputSearchPatient from "app/components/Form/InputSearchPatient";
import InputSearchUser from "app/components/Form/InputSearchUser";
import ItemPatient from "app/components/Form/ItemPatient";
import Select from "app/components/Form/Select";
import ButtonDatePicker from "app/components/Form/ButtonDatePicker";
import moment from "moment";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const getHorarioCorrido = (dt_inicio) => {
  const hora = moment(dt_inicio, "HH:mm:ss").subtract(5, "minutes");
  const aux = Array(5)
    .fill(0)
    .map(() => {
      const newHora = hora.add(5, "minutes");
      return {
        value: newHora.format("HH:mm:ss"),
        text: newHora.format("hh:mm a"),
      };
    });

  return aux;
};

const Index = (props) => {
  const [date, setDate] = React.useState(null);

  const { open, close, accept, agendas, data, procedimientos } = props;
  const [paciente, setPaciente] = React.useState(null);
  const [medico, setMedico] = React.useState(null);
  const [agenda, setAgenda] = React.useState(null);
  const [form, setForm] = React.useState({
    vc_observaciones: "",
    nu_duracion: 15,
  });
  const [disabled, setDisabled] = React.useState(false);
  const [error, setError] = React.useState("");
  const [horas, setHoras] = React.useState([]);

  const cat_agendas = agendas.map((v) => ({ value: v.id, text: v.vc_nombre }));
  const cat_procedimientos = procedimientos.map(
    ({ id, vc_cveCIE9, vc_nombre }) => ({
      value: id,
      text: `${vc_cveCIE9} ${vc_nombre}`,
      vc_cveCIE9,
      vc_nombre,
    })
  );
  const duraciones = [
    // { text: "5", value: 5 },
    // { text: "10", value: 10 },
    { text: "15 min.", value: 15 },
    { text: "20 min.", value: 20 },
    { text: "25 min.", value: 25 },
    { text: "30 min.", value: 30 },
    { text: "45 min.", value: 45 },
    { text: "60 min.", value: 60 },
    { text: "90 min.", value: 90 },
    { text: "2 hrs.", value: 120 },
    { text: "2 hrs. y media", value: 150 },
    { text: "3 hrs.", value: 180 },
    { text: "3 hrs. y media", value: 210 },
    { text: "4 hrs", value: 240 },
  ];

  const handleInputChange = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleAgendaChange = ({ value }) => {
    handleInputChange("id_agenda", value);
    const found = agendas.find((v) => v.id === value);
    setAgenda(found);

    if (found) {
      const duracion = duraciones.find((v) => v.value === found.nu_duracion);
      handleInputChange("nu_duracion", duracion ? duracion.value : null);
    }
  };

  // data change
  React.useEffect(() => {
    if (Object.keys(data).length) {
      setPaciente(null);
      setMedico(null);
      setError("");

      const agenda = agendas.find((v) => v.id === data.id_agenda);
      setAgenda(agenda);
      const duracion = duraciones.find((v) => v.value === agenda.nu_duracion);

      let tm_inicio = data.inicio;

      if (data.disabled) {
        const aux = getHorarioCorrido(data.inicio);
        const inicio = aux.find((v) => v.value === form.tm_inicio);

        setHoras(aux);
        tm_inicio = inicio ? inicio.value : aux.length ? aux[0].value : null;
      }

      setForm((prev) => ({
        ...prev,

        id_place: null,
        vc_direccion: null,
        json_addressComponents: null,

        id_agenda: data.id_agenda,
        dt_cita: data.dt_dia,
        tm_inicio,
        vc_observaciones: "",
        sn_guardarFueraHorario: false,
        procedimientos: [],
        nu_duracion: duracion ? duracion.value : null,
      }));

      setDate(moment(data.dt_dia, "YYYY-MM-DD"));
    }
  }, [data]);

  // Disabled
  React.useEffect(() => {
    setError("");
    setDisabled(
      !form.id_agenda ||
        !paciente ||
        !form.nu_duracion ||
        (data.disabled && !form.sn_guardarFueraHorario)
    );
  }, [form, paciente]);

  // Disponibles
  React.useEffect(() => {
    if (!open) return undefined;

    const { id_agenda, dt_cita: dt_fecha, nu_duracion } = form;

    if (id_agenda && dt_fecha && nu_duracion && !data.disabled) {
      (() => {
        api
          .available({
            id_agenda,
            dt_fecha,
            tm_inicio: data.inicio,
            nu_duracion,
            nu_disponibles: 5,
          })
          .then((res) => {
            const aux = res.map((v) => {
              const hora = moment(v.tm_inicio, "HH:mm:ss");
              return {
                value: hora.format("HH:mm:ss"),
                text: hora.format("hh:mm a"),
              };
            });

            const inicio = aux.find((v) => v.value === data.inicio);

            handleInputChange(
              "tm_inicio",
              inicio ? inicio.value : aux.length ? aux[0].value : null
            );
            setHoras(aux);
          })
          .catch(() => {
            const aux = getHorarioCorrido(data.inicio);
            const inicio = aux.find((v) => v.value === data.incio);

            handleInputChange(
              "tm_inicio",
              inicio ? inicio.value : aux.length ? aux[0].value : null
            );
            setHoras(aux);
          });
      })();
    }
  }, [form.id_agenda, form.dt_cita, form.nu_duracion, open]);

  const guardar = () => {
    const tm_fin = moment(form.tm_inicio, "HH:mm:ss")
      .add(form.nu_duracion, "minutes")
      .format("HH:mm:ss");

    const json_address = {};

    if (form.id_place) {
      json_address.id_place = form.id_place;
      json_address.address = form.vc_direccion;
      json_address.components = form.json_addressComponents;
    }

    const params = {
      id_institucion: agenda.id_institucion,
      id_dependencia: agenda.id_dependencia,
      id_area: agenda.id_area,
      id_agenda: agenda.id,
      id_usuario: window.amplify.store("user_id"),
      id_paciente: paciente.id,
      dt_cita: form.dt_cita,
      tm_inicio: form.tm_inicio,
      tm_fin,
      vc_observaciones: form.vc_observaciones,
      procedimientos: form.procedimientos || [],
      id_medico: medico?.id || null,
      json_address,
    };

    if (data.disabled)
      params.sn_guardarFueraHorario = form.sn_guardarFueraHorario;

    internalAPI.saveMeeting(params, (err, res) => {
      if (err) {
        setError(err.error);
      } else {
        accept();
      }
    });
  };

  return (
    open && (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={close}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ style: { margin: 15, overflowX: "hidden" } }}
      >
        <DialogTitle>Agregar Cita</DialogTitle>
        <Divider variant="middle" />
        <DialogContent style={{ maxWidth: "100%", paddingTop: 20 }}>
          <GridUI key={"agenda"} container>
            <GridUI
              item
              xs={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              <DialogContentText>
                <TodayOutlined />
              </DialogContentText>
            </GridUI>
            <GridUI item xs={11}>
              <Select
                data={cat_agendas}
                key={"agendas"}
                value={form.id_agenda}
                onClick={handleAgendaChange}
              />
            </GridUI>
          </GridUI>
          <GridUI key={"paciente"} container>
            <GridUI
              item
              xs={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              <DialogContentText>
                <PersonOutline />
              </DialogContentText>
            </GridUI>
            <GridUI item xs={11}>
              {!paciente && (
                <InputSearchPatient onClick={setPaciente} autoFocus />
              )}
              {paciente && (
                <List dense>
                  <ItemPatient
                    data={paciente}
                    dense
                    disableGutters
                    onDelete={() => setPaciente(null)}
                  />
                </List>
              )}
            </GridUI>
          </GridUI>

          <GridUI key={"fecha"} container>
            <GridUI
              item
              xs={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              <DialogContentText>
                <ScheduleOutlined />
              </DialogContentText>
            </GridUI>
            <GridUI item xs={11}>
              <Box display="flex" alignItems="center" key="boton-datepicker">
                <ButtonDatePicker
                  value={date}
                  onChange={(v) => {
                    setDate(v);
                    handleInputChange("dt_cita", v.format("YYYY-MM-DD"));
                  }}
                  closeOnChange
                  DatePickerProps={{
                    disablePast: true,
                  }}
                  style={{
                    flex: "1 1 250px",
                    marginRight: "8px",
                  }}
                />
                <Box mr={1} key="select-inicio">
                  <Select
                    data={horas}
                    key={"tm_inicio"}
                    value={form.tm_inicio}
                    hideSearch
                    onClick={(v) => handleInputChange("tm_inicio", v.value)}
                    style={{
                      root: {
                        marginRight: 8,
                        marginTop: 0,
                        width: "auto",
                        flex: 1,
                      },
                    }}
                    showNoDataText
                    noDataText="Sin turnos disponibles"
                    placeholder="Turno"
                  />
                </Box>
                <Box key="select-duracion">
                  <Select
                    data={duraciones}
                    key={"duracion"}
                    value={form.nu_duracion}
                    onClick={(v) => handleInputChange("nu_duracion", v.value)}
                    hideSearch
                    showAllRows
                    style={{ root: { width: "auto", flex: 1 } }}
                  />
                </Box>
              </Box>
            </GridUI>
          </GridUI>

          {data.disabled && (
            <GridUI key={"fuerahorario"} container>
              <GridUI
                item
                xs={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <DialogContentText>
                  <ScheduleOutlined />
                </DialogContentText>
              </GridUI>
              <GridUI item xs={11}>
                <Box pb={1}>
                  <FormControlLabel
                    label="Guardar fuera de horario"
                    control={
                      <Checkbox
                        checked={form.sn_guardarFueraHorario}
                        onChange={(e) =>
                          handleInputChange(
                            "sn_guardarFueraHorario",
                            e.target.checked
                          )
                        }
                        color="primary"
                      />
                    }
                  />
                </Box>
              </GridUI>
            </GridUI>
          )}

          <GridUI container key="procedimientos">
            <GridUI
              item
              xs={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              <DialogContentText>
                <ListAlt />
              </DialogContentText>
            </GridUI>
            <GridUI item xs={11}>
              <Select
                placeholder="Procedimientos"
                multiple
                renderInputValue={(selected) =>
                  selected.map((v) => v.vc_cveCIE9).join(", ")
                }
                data={cat_procedimientos}
                maxRows={30}
                value={form.procedimientos}
                onClick={(v) =>
                  handleInputChange(
                    "procedimientos",
                    v.map((p) => p.value)
                  )
                }
              />
            </GridUI>
          </GridUI>

          <GridUI key={"medico"} container>
            <GridUI
              item
              xs={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              <DialogContentText>
                <PersonOutline />
              </DialogContentText>
            </GridUI>
            <GridUI item xs={11}>
              {!medico && (
                <InputSearchUser
                  onClick={setMedico}
                  placeholder="Buscar medico"
                />
              )}
              {medico && (
                <List dense>
                  <ItemPatient
                    data={medico}
                    dense
                    disableGutters
                    onDelete={() => setMedico(null)}
                  />
                </List>
              )}
            </GridUI>
          </GridUI>

          <GridUI key="ubicacion" container>
            <GridUI
              item
              xs={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              <DialogContentText>
                <LocationOn />
              </DialogContentText>
            </GridUI>
            <GridUI item xs={11}>
              <LocationSearch
                placeholder="Buscar Localización *"
                value={form.vc_direccion}
                onChangeInput={(value) => {
                  if (value !== form.vc_direccion)
                    handleInputChange("id_place", null);
                }}
                onChange={(value) => {
                  handleInputChange(
                    "json_addressComponents",
                    value.address_components
                  );
                  handleInputChange("vc_direccion", value.formatted_address);
                  handleInputChange("id_place", value.place_id);
                }}
              />
            </GridUI>
          </GridUI>

          <GridUI key={"motivo"} container>
            <GridUI
              item
              xs={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              <DialogContentText>
                <SubjectOutlined />
              </DialogContentText>
            </GridUI>
            <GridUI item xs={11}>
              <Input
                multiline
                placeholder="Describa el motivo"
                rows={3}
                rowsMax={3}
                value={form.vc_observaciones}
                onChange={(value) =>
                  handleInputChange("vc_observaciones", value)
                }
              />
            </GridUI>
          </GridUI>

          {error && (
            <Typography key="error" variant="body2" color="error">
              - {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={close}
            style={{ alignSelf: "right" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={guardar}
            variant="contained"
            color="primary"
            style={{ marginLeft: 8 }}
            disabled={disabled}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

Index.defaultProps = {
  open: false,
  agendas: [],
  agendaId: 0,
  data: {},
  close: () => {},
  accept: () => {},

  procedimientos: [],
};

Index.propTypes = {
  open: PropTypes.bool,
  agendas: PropTypes.array,
  agendaId: PropTypes.number,
  data: PropTypes.object,
  close: PropTypes.func,
  accept: PropTypes.func,

  procedimientos: PropTypes.array,
};

const mapStateToProps = ({ Think: { procedimientos } }) => ({ procedimientos });

export default connect(mapStateToProps, [])(Index);

import React from 'react';
import { Loader } from 'semantic-ui-react';
import { Typography } from '@material-ui/core';

class LoaderThink extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: 'someValue'
    };
  }

  render() {
    return (
      <div style={{
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyItems: 'center',
        // backgroundImage: 'url(https://futurelatam.inese.es/wp-content/uploads/2020/03/videoconsulta.jpg)',
        backgroundSize: 'cover',
      }}>
        <Loader active size='large'>
          <Typography>
            {'Configurando seguridad...'}
          </Typography>
        </Loader>

      </div>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: 'otherValue'
    });
  }
}

export default LoaderThink;

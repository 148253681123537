import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Icon, Input, Button } from 'semantic-ui-react';
import { START_VIEW_TRANSITION } from '../../../../constants';
import notifications from '../../../../utils/notifications';
import eventManager from '../../../../utils/eventManager';
import PrincipalHeaderItem from '../notas/principalHeaderItem';
import { logOut, setHelperSidebarRender, resetHelperSidebarRender } from '../../actions';
import { menuInicio, opcionesMenu } from '../../listados';
import GpcHelpBar from '../gpcHelpBar';


class UsersView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			moduloActual: 'configuracion',
			opcionActualModulo: 'instituciones',
			title: '¡Configuración!',
		};
	}
	componentDidMount() {
		this.updateHelperSidebar();
	}
	componentWillUnmount() {
		this.props.resetHelperSidebarRender();
	}
	navigate(url) {
		if ( url == null ) {
			logOut()
		}else{
			eventManager.emit(START_VIEW_TRANSITION, `#/admin/${url}`);
		}
	}
	updateHelperSidebar() {
		this.props.setHelperSidebarRender(this.renderHelperSidebar.bind(this));
	}

	onChangeModule(modulo, opcion, subOpcionModuloActual) {
		if (this.validateLogin()) {
			this.setState({
				moduloActual: modulo.id,
				opcionActualModulo: opcion.id,
				title: modulo.vc_nombre
			}, () => {
				this.updateHelperSidebar();
			});
		} else {
			notifications.success('¡Registrate para acceder a todas las herramientas!');
		}
	}

	// validateIntitution() {
	// 	if (window.amplify.store('rol').id === 2) {

	// 	}else{

	// 	}
	// }

	renderHelperSidebar() {
		switch (this.state.moduloActual) {
			case 'gpc':
			return (
				<div style={{ padding: 0, border: 0, backgroundColor:window.colors.black }}>
					<GpcHelpBar
					callbackGPC={(gpcSelected) => {
						this.setState({ gpcSelected }, () => {
							this.updateHelperSidebar();
						});
					}}
					/>
				</div>
			);
			default: break;
		}
		return (
			<div style={{ padding: 0, border: 0, backgroundColor:window.colors.black }}>
				<button
				style={{
					padding: 0,
					border: 0,
					width: '100%',
					height: 74,
					textAlign: 'center',
					color: 'white',
					backgroundColor:window.colors.menu
					}}
				>Barra de Ayuda</button>
				<div
					style={{
						width: '100%',
						padding: 3,
						height: 74,
						color: 'white',
					}}>
					<Input
						id='searchBar'
						value={this.state.searchText}
						focus placeholder={`Especifique...`}
						style={{ width: '100%', height: 31 }}
					/>
				</div>
				<div style={{ height: window.screen.availHeight - 150, color:window.colors.white, textAlign: 'center', padding: 10 }}>
					Hola esta es una barra interactiva de ayuda:
				</div>
			</div>
		);
	}
	render() {
		const moduloActual = menuInicio.filter((e, index) => e.id == this.state.moduloActual)[0];
		const opcionActual = moduloActual.opciones.filter((e, index) => e.id == this.state.opcionActualModulo)[0] ? moduloActual.opciones.filter((e, index) => e.id == this.state.opcionActualModulo)[0] : [];
		const acciones = opcionActual.acciones ? opcionActual.acciones : [];
		return (
			<div style={stylesInit.container}>
				<div style={stylesInit.layerContainer}>
				<PrincipalHeaderItem
				    title='Usuarios'
				/>
				<Menu inverted size='small' style={{borderRadius: 0, margin: 0,boxShadow: '1px 0px 10px rgba(0, 0,0, 0.7)'}}>
					<Dropdown simple key='menu' size='medium' icon='sidebar' style={{fontSize: 13,height:'100%', padding:8, color: window.colors.white}}>
						<Dropdown.Menu style={{backgroundColor: 'rgb(230,230,230)'}}>
							{
								opcionesMenu.map((opcion,index) => {
									return (
										<Dropdown.Item key={index} onClick={() =>  this.navigate(opcion.url) }><Icon name={opcion.icon}/>{opcion.id !== 'logout' ? opcion.vc_nombre : window.amplify.store('token') !== null ? 'Cerrar Sesión' : 'Iniciar Sesión'}</Dropdown.Item>
									)
								})
							}
						</Dropdown.Menu>
					</Dropdown>
            	</Menu>
				<div style={{textAlign: 'right',borderRadius: 0, padding:0, height: 35, margin: 0,boxShadow: '1px 0px 10px rgba(0, 0,0, 0.7)',backgroundColor: 'rgb(240,240,240)'}}>
					<Dropdown simple key={moduloActual.id} text={`${moduloActual.vc_nombre} > ${opcionActual.vc_nombre}`} style={{fontSize: 13,height:'100%', padding:8}}>
						<Dropdown.Menu style={{width:'100%',backgroundColor: 'rgb(230,230,230)'}}>
							{
								moduloActual.opciones.map((opcion,index) => {
									return (
										<Dropdown.Item key={opcion.id} onClick={() =>  this.onChangeModule(moduloActual,opcion,'agregar') }>{opcion.vc_nombre}</Dropdown.Item>
									)
								})
							}
						</Dropdown.Menu>
					</Dropdown>
						<div style={{height:35,float: 'left',textAlign: 'center',borderRadius: 0, padding:3, margin: 0}}>
							{
								acciones.map((accion,index) => {
									return (
										<Button key={accion.id} size='mini' basic  onClick={() =>  this.clickAccion(accion) }>{accion.vc_nombre}</Button>
									)
								})
							}
						</div>
				</div>
				<div style={{width:'100%', height: 1000}}></div>
				</div>
			</div>
		);
	}
}


const stylesInit = {
	layerContainer: {
		overflow: 'hidden',
		height: '100%',
		float: 'center',
		width: '100%',		// backgroundImage: 'url('assets/images/iconThink.png')',
		backgroundSize: 'cover',
	},
	container: {
		height: '100%',
		float: 'center',
		width: '100%',
		overflow: 'hidden',
	}
};


export default connect(null, { setHelperSidebarRender, resetHelperSidebarRender })(UsersView);

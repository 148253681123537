import { SET_INSTITUCION } from '../actions/types';


export default (state, INITIAL_STATE, action) => {
    switch (action.type) {
      case SET_INSTITUCION:
			return { ...state, institucion: action.payload };
		default:
			return null;
	}
};

import React from 'react';
import { connect } from 'react-redux';
import { Paper, Typography } from '@material-ui/core';
import moment from 'moment'
import { teal } from '@material-ui/core/colors';

const themeSelected = window.amplify.store('theme') || null;

// const rowHeight = 20;
// let rowTime = 60;
// const ratio = rowHeight / rowTime

const TimeSpace = props => {
    const { dias, add, time, rowHeight } = props
    const label = moment(time, 'HH:mm:ss').format('HH:mm')

    return (
        <div style={{ height: rowHeight, display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
            <span style={{ transform: 'translateY(-50%)', position: 'absolute', left: 0, top: 0 }}>{label}</span>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: rowHeight,
                    marginLeft: '50px',
                    borderBottom: '1px solid rgba(200, 200, 200, 0.1)'
                }}
            >
                {dias.map((dia, index) => <div
                    key={index}
                    style={{ flex: 1, zIndex: 15 }}
                    onClick={() => {
                        add({
                            nu_dia: dia.id,
                            tm_inicio: time,
                            tm_fin: moment(time, 'HH:mm:ss').add(1, 'hour').format('HH:mm:ss')
                        })
                    }}
                />)}
            </div>
        </div>
    )
}

const Timeline = props => {
    const { from, to, rowHeight, rowTime, titleDay } = props
    const tm_from = moment(from, 'HH:mm:ss'),
        tm_to = moment(to, 'HH:mm:ss'),
        diff = tm_to.diff(tm_from, 'minutes'),
        date = tm_from.clone().subtract(rowTime, 'minutes')

    const lapsos = diff / rowTime

    return (
        <div style={{ width: '100%', height: '100%', paddingTop: titleDay ? rowHeight : 0 }}>

            {Array(lapsos).fill(0).map((v, i) => {
                const dateRow = date.add(rowTime, 'minutes')
                const format = dateRow.format('HH:mm:ss')
                return <TimeSpace key={`hora-${i}`} {...props} time={format} />
            })}
        </div>
    )
}

const Event = props => {
    const {data, from, to, rowHeight, rowTime, onClick } = props
    const {tm_inicio:inicio, tm_fin:fin,} = data

    const tm_inicio = moment(inicio, 'HH:mm:ss'),
        tm_fin = moment(fin, 'HH:mm:ss'),
        tm_from = moment(from, 'HH:mm:ss')

    const minFrom = (tm_from.hours() * 60) + tm_from.minutes()

    const minInicio = ((tm_inicio.hours() * 60) + tm_inicio.minutes()) - minFrom
    const minFin = (tm_fin.hours() * 60) + tm_fin.minutes()

    const ratio = rowHeight / rowTime
    const top = minInicio * ratio
    const height = (minFin - minInicio - minFrom) * ratio

    // const style = { top, height, backgroundColor: themeSelected.primary[500], position: 'absolute', width: 'calc(100% - 10px)', padding: '5px 10px', zIndex: 20, }
    const style = { top, height, position: 'absolute', width: 'calc(100% - 10px)', zIndex: 20, }
    
    if( props.event ) return props.event({data, style})
    return (
        <Paper
            style={{...style, padding: '5px 10px', backgroundColor: themeSelected ? themeSelected.primary[500] : teal[500]}}
            onClick={onClick}
        >
            <em>{tm_inicio.format('HH:mm')} - {tm_fin.format('HH:mm')}</em>
        </Paper>
    )
}

const Day = props => {
    const { dia, detalles, edit, events, titleDay, ...restProps } = props
    const { rowHeight } = restProps

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {
                titleDay ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: rowHeight, border: '1px solid rgba(200, 200, 200, 0.1)' }}>
                        <span>{dia.vc_nombre}</span>
                    </div> :

                    <div style={{ height: 1, border: '1px solid rgba(200, 200, 200, 0.1)' }}/>
            }


            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '0px 5px', position: 'relative' }}>
                {detalles.map((d, i) => <Event
                    key={i}
                    data={d}
                    {...restProps}
                    onClick={() => edit(d, events.indexOf(d))}
                />)}
            </div>
        </div>
    )
}

const Events = ({ dias, events, ...props }) => {
    return (
        <div
            style={{
                width: 'calc(100% - 60px)', height: '100%',
                marginLeft: '60px',
                display: 'flex',
                flexDirection: 'row',
                position: "absolute", top: 0, left: 0,
                padding: '10px 10px 10px 0px',
            }}
        >
            {dias.map((dia, i) => {
                let detalles = events.filter(e => e.nu_dia === dia.id) || []
                return (
                    <Day key={i} dia={dia} detalles={detalles} events={events} {...props} />
                )
            })}
        </div>
    )
}

export default props => {
    const { title, from = '00:00:00', to = '24:00:00', events = [], add, edit, dias = [], style = {}, rowHeight = 20, rowTime = 60, titleDay = true, event } = props
    const newProps = { from, to, events, add, edit, dias, rowHeight, rowTime, titleDay, event }
    const styleSchedule = {
        flex: 1,
        ...style
    }

    return (
        <Paper draggable style={styleSchedule}>
            {title && <Typography style={{ fontSize: 14, padding: 10 }} gutterBottom>
                {title}
            </Typography>}
            <div style={{ padding: 10, width: '100%', position: "relative", }}>
                <Timeline {...newProps} />
                <Events {...newProps} />
            </div>
        </Paper>
    )
}
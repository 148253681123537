import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, List, Menu, Dropdown, Card, Image, ButtonGroup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import internalAPI from '../../../../utils/internalAPI';
import formatter from '../../../../utils/formatter';
import notifications from '../../../../utils/notifications';
import { opcionesMenu } from '../../listados';
import { setHelperSidebarRender, resetHelperSidebarRender, selectMeeting, setPacienteActual, logOut, setDisplaySidebar } from '../../actions';
import AddMeetingModal from './AddMeetingModal';
import PatientConsultModal from './PatientConsultModal';
import { Box, IconButton, Typography, Paper, Button, Divider, AppBar, Toolbar, MenuItem, Badge, Breadcrumbs } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import SubMenuModulos from '../../../../material-ui/SubMenuModulos';
import { menuAgendas } from '../../listados';
import Configuracion from './Configuracion'
import AgendaDia from './AgendaDia'
import ConfirmAlert from '../../../../material-ui/ConfirmAlert'
import repository from '../../../../utils/repository';
import ButtonMenu from './Components/ButtonMenu'
import UploadPicture from '../../../../components/UploadPicture';
BigCalendar.momentLocalizer(moment);

const colors = window.colors;
const { Row, Column } = Grid;
const { Item, Icon, Content } = List;
const daySize = (( window.sidebarWidth - 4) / 7);

const CancelarCita = ({ open, cita, onAccept, onCancel }) => {
   return (open &&
      <ConfirmAlert
         title={`¿Desea cancelar la cita del paciente: ${cita.vc_nombre} ${cita.vc_apellidoPaterno} ${cita.vc_apellidoMaterno}?`}
         onAccept={onAccept}
         onCancel={onCancel}
      />)
}

class DiaryView extends Component {
   constructor(props) {
      super(props);

      this.state = {
         moduloActual: 'agendas',
         agendaActual: this.getAgendaActual(),
         especialidad: {},
         dayCalendar: moment().format('YYYY-MM-DD'),
         dayAgenda: moment().format('YYYY-MM-DD'),
         turns: [],
         hoverTurn: null,
         daysOfCalendar: repository.getCalendarLayout(moment().format('YYYY-MM-DD')) || [],
         meetingsByMonth: [],
         availables: [],
         visibleAddMeeting: false,
         slotInfo: null,
         meetings: [],
         horary: null,
         view: 'day',
         meetingSelected: null,
         patientConsult: false,
         menuAgendas,

         confirm: false,
      };
   }

   getAgendaActual() {
      if (this.state && this.state.agendaActual) {
         return this.state.agendaActual;
      }
      if (window.amplify.store('institution') && window.amplify.store('institution').agendas.length > 0) {
         return window.amplify.store('institution').agendas[0];
      } else {
         return null;
      }
   }
   render() {
      const { min, max } = this.calculateMinMax();
      const fechaAgenda = this.state.dayAgenda != null ? moment(this.state.dayAgenda).format('dddd D MMMM YYYY') : moment(new Date()).format('dddd D MMMM YYYY')

      return (
         <div style={{ ...styles.container, backgroundColor: 'rgba(200,200,200,0.1)' }}>
            {this.renderHeaderMenu()}
            {this.renderContent()}
         </div>
      );
   }

   renderContent() {
      const { moduloActual, confirm, meetingSelected: cita } = this.state
      switch (moduloActual) {
         case 'agendas':
            return (
               <Grid style={{ ...styles.principal }}>
                  <Row>
                     <Column>
                        {this.renderAgendaDia()}
                     </Column>
                  </Row>

                  <CancelarCita cita={cita} open={confirm}
                     onAccept={() => {
                        this.cancelMeeting(cita)
                           .then(() => {
                              this.setState({ confirm: false })
                           })
                     }}
                     onCancel={(e) => {
                        e.stopPropagation();
                        this.setState({ confirm: false })
                     }}
                  />

                  {this.renderAddMeetingModal()}
                  {/* {this.renderPatientConsultModal()} */}
               </Grid>
            )
         case 'configuracion':
            return <Configuracion />
      }
   }

   selectAgenda(agenda) {
      this.setState({ agendaActual: agenda }, () => {
         this.getDiaryByDay(this.state.dayAgenda);
         this.getDiaryByMonth(this.state.dayCalendar);
      })
   }
   rightComponent() {
      const { especialidades = [] } = this.props
      const { agendas = [] } = window.amplify.store('institution')

      const especialidadesProps = {
         options: especialidades.filter(e => agendas.find(a => a.id_especialidad == e.id)).map(v => ({
            ...v,
            value: v.id,
            vc_nombre: v.vc_alias,
            action: () => {
               this.setState({ especialidad: v })
            }
         })),
         display: 'Especialidad',
         value: this.state.especialidad ? this.state.especialidad.id : null
      }

      let auxAgendas = agendas.map(v => ({ ...v, action: () => this.selectAgenda(v) }))
      if (this.state.especialidad) {
         auxAgendas = auxAgendas.filter(v => v.id_especialidad === this.state.especialidad.id)

         if (this.state.agendaActual && this.state.especialidad.id && this.state.agendaActual.id_especialidad != this.state.especialidad.id)
            this.selectAgenda(auxAgendas[0])
      }

      const agendasProps = {
         options: auxAgendas,
         display: this.state.agendaActual != null ? this.state.agendaActual.vc_nombre : 'Sin Agendas Disponibles',
         value: this.state.agendaActual ? this.state.agendaActual.id : null
      }


      return (
         <Breadcrumbs separator="›" >
            <Typography color="inherit">Agendas</Typography>
            <Typography color="inherit">
               <ButtonMenu {...especialidadesProps} />
            </Typography>
            <Typography color="inherit">
               <ButtonMenu {...agendasProps} />
            </Typography>
         </Breadcrumbs>
      )
   }

   renderHeaderMenu() {
      const moduloActual = this.state.menuAgendas.find(v => v.id === this.state.moduloActual)
      let acciones = []
      let opciones = []
      let opcionesDisplay = []

      if ('opciones' in moduloActual) {
         const opcionActual = moduloActual.opciones[0];
         acciones = opcionActual ? opcionActual.acciones : [];
         acciones.forEach((accion) => {
            accion.action = () => {
               this.clickAccion(accion);
            }
         })
      }
      switch (this.state.moduloActual) {
         case 'agendas':
            // opciones = window.amplify.store('institution').agendas.map((opcion) => {
            //    return {
            //       ...opcion,
            //       action: () => {
            //          this.setState({ agendaActual: opcion }, () => { this.getDiaryByDay(this.state.dayAgenda); this.getDiaryByMonth(this.state.dayCalendar); })
            //       }
            //    }
            // })

            // const agenda = this.state.agendaActual != null ? this.state.agendaActual.vc_nombre : 'Sin Agendas Disponibles';
            // opcionesDisplay = ['Agenda', agenda]
            return (
               <SubMenuModulos
                  title={moment(this.state.dayAgenda || '').format('dddd D MMMM YYYY')}
                  actions={acciones}
                  // options={opciones}
                  // optionDisplay={opcionesDisplay}
                  rightComponent={this.rightComponent()}
               />
            )
      }
   }

   renderCalendar() {
      const { date } = formatter;
      const w = window.innerWidth;
      const alto = ((w * 0.2)) / 7
      const line = w * 0.002;
      const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      const anoActual = moment(this.state.dayCalendar).format('YYYY') * 1;
      const anos = [(anoActual - 2), (anoActual - 1), (anoActual), (anoActual + 1), (anoActual + 2)];
      const nu_lines = this.state.daysOfCalendar.length / 7;

      return (
         <Box>
            <Menu style={{ backgroundColor: 'transparent', margin: 0 }}>
               <Dropdown item loading={this.state.loadingMonthCalendar == true ? true : false} text={this.state.loadingMonthCalendar == true ? '' : `${date(this.state.dayAgenda, 'MMMM')}`} style={{ textTransform: 'capitalize', maxWidth: '40%', width: "40%", height: 40, textAlign: "center", lineHeight: 3, float: "right", color: 'gray' }}>
                  <Dropdown.Menu style={{ width: "100%", overflowY: "scroll", maxHeight: 400, color: teal }}>
                     {
                        meses.map((item, index) => {
                           return (
                              <Dropdown.Item key={index} onClick={() => { this.changeMonthCalendar(index) }} >{item}</Dropdown.Item>
                           )
                        })
                     }
                  </Dropdown.Menu>
               </Dropdown>
               <Menu.Item size='large' onClick={() => { this.selectDayOfCalendar({ dt_inicio: moment().format('YYYY-MM-DD') }) }} style={{ fontSize: 13, padding: 10, maxWidth: '20%', height: 40, width: '20%', color: 'gray' }}>Hoy</Menu.Item>
               <Dropdown item loading={this.state.loadingYearCalendar == true ? true : false} text={this.state.loadingYearCalendar == true ? '' : `${date(this.state.dayAgenda, 'YYYY')}`} style={{ width: "40%", maxWidth: '40%', height: 40, textAlign: "center", lineHeight: 3, float: "right", color: 'gray' }}>
                  <Dropdown.Menu style={{ width: "100%", overflowY: "scroll", maxHeight: 400 }}>
                     {
                        anos.map((item, index) => {
                           return (
                              <Dropdown.Item key={index} onClick={() => { this.changeYearCalendar(item) }} >{item}</Dropdown.Item>
                           )
                        })
                     }
                  </Dropdown.Menu>
               </Dropdown>
            </Menu>
            <Divider key={'divider1'} />
            <ButtonGroup key="headerCalendar" style={{ width: window.sidebarWidth }}>
               <Button key="1" style={{ minWidth: daySize, minHeight: daySize, border: 0, borderRadius: 0 }}>D</Button>
               <Button key="2" style={{ minWidth: daySize, minHeight: daySize, border: 0, borderRadius: 0 }}>L</Button>
               <Button key="3" style={{ minWidth: daySize, minHeight: daySize, border: 0, borderRadius: 0 }}>M</Button>
               <Button key="4" style={{ minWidth: daySize, minHeight: daySize, border: 0, borderRadius: 0 }}>M</Button>
               <Button key="5" style={{ minWidth: daySize, minHeight: daySize, border: 0, borderRadius: 0 }}>J</Button>
               <Button key="6" style={{ minWidth: daySize, minHeight: daySize, border: 0, borderRadius: 0 }}>V</Button>
               <Button key="7" style={{ minWidth: daySize, minHeight: daySize, border: 0, borderRadius: 0 }}>S</Button>
            </ButtonGroup>
            <Divider key={'divider2'} />
            <Paper key={'calendar'} style={{ padding: 2, width: window.sidebarWidth }}>
               {
                  this.state.daysOfCalendar.map((day, index) => {
                     const isSelected = (day.dt_inicio === this.state.dayAgenda);
                     return (
                        <IconButton
                           key={index}
                           onClick={() => { this.selectDayOfCalendar(day) }}
                           style={{
                              minHeight: daySize,
                              minWidth: daySize,
                              padding: 0,
                              backgroundColor: isSelected ? this.colorCitas(day.nu_disponibles) : 'transparent',
                              borderRadius: '50%',
                              opacity: day.sn_mesActual == 1 ? 1 : 0.4,
                           }}
                        >
                           {day.nu_citas > 0 &&
                              <Badge badgeContent={day.nu_citas} variant='dot' style={{ left: '50%', top: '25%', position: 'absolute' }} color={'primary'}> </Badge>
                           }
                           <Typography style={{
                              border: '1px solid transparent',
                              // borderColor: isSelected ? this.colorCitas(day.nu_disponibles) : 'transparent',
                              borderBottomColor: this.colorCitas(day.nu_disponibles),
                           }}>
                              {moment(day.dt_inicio).format('D')}
                           </Typography>
                        </IconButton>
                     )
                  })
               }
            </Paper>
            {/* <Grid centered key="calendar" style={{borderRadius: 5,margin:0,padding: 2,height: (alto * nu_lines) + 10,width:"100%"}}>
          {
            this.state.daysOfCalendar.map((item,index) => {

              if ( item.dt_inicio == this.state.dayAgenda ) {
                return (
                  <div key={index} style={{marginLeft: 0,marginTop:0,paddingLeft:0,paddingRight:0,paddingBottom:0,paddingTop:0,height: alto,width: 'calc(100% / 7)',color:"white"}}>
                      <Button style={{border:0,textAling: "center",verticalAlign: "center",backgroundColor:this.colorCitas(item.nu_disponibles), backgroundImage: `linear-gradient(${this.colorCitasGradient(item.nu_disponibles)})`,margin: '2.5%',padding:0,width:"95%",height:"95%",color:"white"}}>{date(item.dt_inicio, 'D')}</Button>
                  </div>
                )
              }else{
                return (
                  <div key={index} onClick={() => { this.selectDayOfCalendar(item) }} style={{border:0, opacity: item.sn_mesActual == 1 ? 1 : 0.4, margin:0,padding:0, height: alto, width: 'calc(100% / 7)',color:"white", border: 0}}>
                      <Button style={{border:0, borderBottom: `2px solid ${this.colorCitas(item.nu_disponibles)}`,textAling: "center",verticalAlign: "center",backgroundColor: 'transparent',margin: '2.5%',padding:0,width:"95%",height:"95%",color:"black"}}>{date(item.dt_inicio, 'D')}</Button>
                  </div>
                )}
            })
          }
        </Grid> */}
         </Box>
      );
   }

   // ---------------------------------
   // ------ life cycle events --------
   // ---------------------------------
   componentDidMount() {
      console.log('aqui entro')
      this.updateHelperSidebar();
      this.getDiaryByDay();
      this.getDiaryByMonth();
      this.props.setActiveView('agendas');
      this.props.setDisplaySidebar(true);
      this.setMenuOptions();
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.institucion !== nextProps.institucion && nextProps.institucion) {
         const agendaActual = nextProps.institucion.agendas[0] || null;
         this.setState({ agendaActual });
      }
   }
   componentWillUnmount() {
      this.props.resetHelperSidebarRender();
      this.props.setMenuVisibility(true);
   }

   navigate(url) {
      if (url === null) {
         logOut();
      } else {
         this.props.navigate(`#/admin/${url}`);
      }
   }

   // ------------------------
   // ------  methods --------
   // ------------------------

   setMenuOptions() {
      const options = this.state.menuAgendas.map((option) => ({
         ...option,
         onClick: this.onChangeModule.bind(this),
      }));

      this.props.setSubMenuOptions(options);
   }

   onChangeModule(modulo) {
      this.setState({ moduloActual: modulo.id }, () => {
         this.updateHelperSidebar()
      });
   }

   onMeetRegisterArrival() {
      notifications.success(`Se registro la llegada de: ${this.state.meetingSelected.vc_nombre}`);
   }

   onMeetSelected(item) {
      if (this.state.meetingSelected != null) {
         if (this.state.meetingSelected.id == item.id) {
            this.setState({ meetingSelected: null }, () => {
               this.updateHelperSidebar();
            });
         } else {
            this.setState({ meetingSelected: item }, () => {
               this.updateHelperSidebar();
               this.props.setDisplaySidebar(true);
            });
         }
      } else {
         this.setState({ meetingSelected: item }, () => {
            this.updateHelperSidebar();
            this.props.setDisplaySidebar(true);
         });
      }
   }

   onMeetEdit() {
      notifications.success(`Se editará la cita de: ${this.state.meetingSelected.vc_nombre}`);
   }

   getExpediente() {
      if (this.state.meetingSelected) {
         const id_paciente = this.state.meetingSelected.id_paciente
         internalAPI.getExpediente({
            id_paciente: id_paciente,
            id_rol: 5,
            sn_pacienteRespuestas: true,
            sn_diagnosticos: true,
            sn_procedimientos: true,
            sn_medicamentos: true,
            sn_respuestas: true,
            sn_solicitudes: true,
            sn_signosAlarma: true,
            sn_atenciones: true,
            sn_notas: true,
            id_tipoNota: 4
         }, (err, response) => {
            if (err) {
               console.log('Error: ', err);
               if (err.texto) {
                  notifications.error(err.texto);
                  logOut();
               }
            } else {

               const preguntasNumericas = [];
               const preguntasTexto = [];
               response.data.preguntas.map((item, index) => {
                  if (item.id_tipoPregunta == 4 && (item.id_sexo == 3 || response.data.paciente.id_sexo == item.id_sexo)) {
                     preguntasTexto.push(item)
                  } else if ((item.id_sexo == 3 || response.data.paciente.id_sexo == item.id_sexo)) {
                     preguntasNumericas.push(item)
                  }
               });
               const paciente = {
                  detalle: response.data.paciente,
                  citaActual: this.state.meetingSelected ? this.state.meetingSelected : null,
                  medicamentosPaciente: response.data.medicamentos,
                  diagnosticosPaciente: response.data.diagnosticos,
                  solicitudes: response.data.solicitudes,
                  signosAlarma: response.data.signosAlarma,
                  pacienteRespuestas: response.data.pacientesRespuestas,
                  atenciones: response.data.atenciones,
                  notas: response.data.notas,
                  preguntasNumericas: preguntasNumericas,
                  preguntasTexto: preguntasTexto
               };
               this.props.setPacienteActual(paciente);
               this.props.navigate(`#/admin/notas`);
            }
         });
      }
   }

   confirmCancel() {
      this.setState({ confirm: true })
   }

   cancelMeeting(cita) {
      cita.id_estatusCita = 8;

      // return new Promise((resolve, reject) => {
      //    internalAPI.cancelMeeting(cita, (error, response) => {
      //       if (error) {
      //          this.setState({ error: error.error });
      //          reject()
      //       } else {
      //          this.getDiaryByDay(cita.dt_cita);
      //          resolve()
      //       }
      //    });
      // })

   }

   consultarFuncion() {
      this.getExpediente();
   }

   onMeetCancel() {
      if (this.state.meetingSelected) {
         const meet = this.state.meetingSelected;
         meet.id_estatusCita = 8;

         //  internalAPI.cancelMeeting(meet, (error, response) => {
         //     console.log(response);
         //     if (error) {
         //        console.log(error);
         //        this.setState({ error: error.error });
         //     } else {
         //        notifications.success(`Se canceló la cita del siguiente paciente: ${this.state.meetingSelected.vc_nombre}`);
         //        this.getDiaryByDay(meet.dt_cita);
         //     }
         //  });
      }
   }

   isZero(value) {
      const zero = (value + 1) < 10 ? '0' : '';
      return `${zero}${value + 1}`;
   }

   getDiaryByDay(dayAgenda) {
      let fecha = dayAgenda != undefined ? moment(dayAgenda).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      if (!this.state.agendaActual) {
         const institucion = this.props.institucion;
         if (institucion) {
            if (institucion.agendas.length > 0) {
               this.setState({ agendaActual: institucion.agendas[0] }, () => {
                  this.getDiaryByDay(dayAgenda);
               })
            }
         }
         return;
      };

      internalAPI.getDiaryByDay({ dt_fecha: fecha, id_agenda: this.state.agendaActual.id }, (err, response) => {
         if (err) {
            console.log('Error: ', err);
            this.setState({ loadingYearCalendar: false, loadingMonthCalendar: false, dayAgenda: fecha }, () => {
               this.updateHelperSidebar();
            });
         } else {
            const meetings = response.citas.map((item) => {
               const buildDateStart = moment(`${item.dt_cita} ${item.tm_inicio}`);
               const buildDateEnd = moment(`${item.dt_cita} ${item.tm_fin}`);

               return {
                  title: `${item.vc_nombre} ${item.vc_apellidoPaterno} ${item.vc_apellidoMaterno}`,
                  start: new Date(buildDateStart),
                  end: new Date(buildDateEnd),
                  tm_inicio: item.tm_inicio,
                  ...item
               };
            });
            const availables = response.proximas.map((item) => {
               const buildDateStart = moment(`${item.tm_inicio}`);
               const buildDateEnd = moment(`${item.tm_fin}`);

               return {
                  start: new Date(buildDateStart),
                  end: new Date(buildDateEnd),
                  tm_inicio: item.tm_inicio,
                  tm_fin: item.tm_fin,
                  ...item
               };
            });
            const disponibles = response.disponibles.map((item) => {
               const buildDateStart = moment(`${item.tm_inicio}`);
               const buildDateEnd = moment(`${item.tm_fin}`);

               return {
                  start: new Date(buildDateStart),
                  end: new Date(buildDateEnd),
                  tm_inicio: item.tm_inicio,
                  tm_fin: item.tm_fin,
                  ...item
               };
            });

            const horary = response.horario[0];
            const mixArr = meetings.concat(disponibles).sort(function (a, b) { return a.start - b.start });
            const turns = mixArr;

            this.setState({ visibleAddMeeting: false, meetings, horary, availables, turns, dayAgenda: fecha, loadingYearCalendar: false, loadingMonthCalendar: false, meetingSelected: null }, () => {
               this.updateHelperSidebar();
            });
         }
      });
   }

   getDiaryByMonth(fechaMes) {
      let fecha = fechaMes != undefined ? moment(fechaMes).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      if (!this.state.agendaActual) {
         const institucion = this.props.institucion;
         if (institucion) {
            if (institucion.agendas.length > 0) {
               this.setState({ agendaActual: institucion.agendas[0] }, () => {
                  this.getDiaryByMonth(fechaMes);
               })
            }
         }
         return;
      }
      this.setState({ daysOfCalendar: repository.getCalendarLayout(fecha), dayAgenda: fecha }, () => {
         this.updateHelperSidebar();
      });
      internalAPI.getDiaryByMonth({ dt_fecha: fecha, id_agenda: this.state.agendaActual.id }, (err, response) => {
         if (err) {
            console.log('Error: ', err);
            this.setState({ loadingYearCalendar: false, loadingMonthCalendar: false }, () => {
               this.updateHelperSidebar();
            });
         } else {
            const daysOfCalendar = response.map((j) => {
               return {
                  dt_inicio: j.dt_inicio,
                  sn_hoy: j.sn_hoy,
                  sn_mesActual: j.sn_mesActual,
                  nu_citas: j.nu_citas,
                  nu_disponibles: j.nu_disponibles
               };
            });
            this.setState({ daysOfCalendar, dayCalendar: fecha, loadingYearCalendar: false, loadingMonthCalendar: false }, () => {
               this.updateHelperSidebar();
            });
         }
      });
   }

   selectDayOfCalendar(item) {
      const { date } = formatter;
      if (`${date(item.dt_inicio, 'YYYY-MM')}` != `${date(this.state.dayAgenda, 'YYYY-MM')}`) {
         this.getDiaryByMonth(item.dt_inicio);
      }
      this.getDiaryByDay(item.dt_inicio);
   }

   getSlot(slotInfo) {

      let proximas = [];

      (this.state.turns || []).concat((this.state.availables || [])).map((turno, index) => {
         console.log('turno', turno)
         if (!proximas.find(e => e.tm_inicio === turno.tm_inicio)) {
            proximas.push(turno);
         }
      });
      slotInfo.proximas = proximas;

      this.setState({ visibleAddMeeting: true, slotInfo });
   }

   getView(view) {
      this.setState({ view });
   }

   getEvents() {
      let events;
      switch (this.state.view) {
         case 'month':
            events = this.state.meetingsByMonth;
            break;

         default:
            events = this.state.meetings;
            break;
      }

      return events;
   }

   updateHelperSidebar() {
      this.props.setHelperSidebarRender(this.renderHelperSidebar.bind(this));
   }

   addMeeting(response) {
      notifications.success(response.message);
      this.setState({ visibleAddMeeting: false }, () => {
         this.getDiaryByDay(response.dt_cita);
      })
   }

   eventStyleGetter(event, start, end, isSelected) {
      let backgroundColor;
      let style;
      const horasDisponibles = parseInt(event.title);

      if (horasDisponibles > 1) {
         backgroundColor = '#61EA68';
      } else if (horasDisponibles > 0) {
         backgroundColor = '#FIBBED';
      } else {
         backgroundColor = '#F50707';
      }

      if (this.state.view === 'month') {
         style = {
            backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: window.colors.black,
            border: '0px',
            display: 'block'
         };
      }

      return {
         style
      };
   }

   calculateMinMax() {
      const now = new Date();
      let min = new Date();
      let max = new Date();
      if (this.state.horary) {
         const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
         const stringDate = `${now.getFullYear()}-${now.getMonth() + 1}-${day}T`;
         min = new Date(`${stringDate}${this.state.horary.tm_inicio}-07:00`);
         max = new Date(`${stringDate}${this.state.horary.tm_fin}-07:00`);
      }

      return { min, max };
   }

   selectEvent(item) {
      // console.log(item.tm_inicio);
      this.setState({ meetingSelected: item }, () => { this.updateHelperSidebar(); });
   }

   patientConsult() {
      notifications.success(`Se consultará el siguiente paciente:  ${this.state.meetingSelected.vc_nombre}`);
   }

   changeMonthCalendar(index) {
      const date = moment(this.state.dayCalendar);
      const month = index < 9 ? `0${index + 1}` : `${index + 1}`;
      if (date.format('MM') != month) {
         this.setState({ loadingMonthCalendar: true }, () => { this.updateHelperSidebar() });
         const dateString = `${date.format('YYYY')}-${month}-01`
         this.getDiaryByMonth(dateString);
         this.getDiaryByDay(dateString);
      }
   }

   changeYearCalendar(item) {
      const date = moment(this.state.dayCalendar);
      if (date.format('YYYY') != item) {
         this.setState({ loadingYearCalendar: true }, () => { this.updateHelperSidebar() });
         const dateString = `${item}-${date.format('MM')}-01`
         this.getDiaryByMonth(dateString);
         this.getDiaryByDay(dateString);
      }
   }

   // ------------------------------
   // ------ render methods --------
   // ------------------------------


   colorCitas(nu_disponibles) {
      if (nu_disponibles >= 3) {
         return window.colors.green
      } else if (nu_disponibles >= 1) {
         return window.colors.yellow
      } else {
         return window.colors.red
      }
   }
   colorCitasGradient(nu_disponibles) {
      if (nu_disponibles >= 3) {
         return `${colors.greenLight}, ${colors.green}`
      } else if (nu_disponibles >= 1) {
         return `${colors.yellowLight}, ${colors.yellow}`
      } else {
         return `${colors.redLight}, ${colors.red}`
      }
   }
   renderHoursAvailables() {
      const { date } = formatter;
      const w = window.innerWidth;
      const alto = ((w * (w > 800 ? 0.2 : 1)) - 11) / 7;
      const line = alto * 0.08;
      const fSize = alto * 0.3;

      return this.state.availables.map((hourAvailable, index) => (
         <div key={index}>
            <Button fullWidth key={index} onClick={() => { this.getSlot(hourAvailable); }} style={{ marginTop: 3 }}>
               {date(hourAvailable.tm_inicio, 'HH:mm')} - {date(hourAvailable.tm_inicio, 'dddd D MMMM YYYY')}
            </Button>
            <Divider />
         </div>

      ));
   }

   getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
         age--;
      }
      return age;
   }

   renderAgendaDia() {
      return <AgendaDia
         turns={this.state.turns}
         onMeetSelected={this.onMeetSelected.bind(this)}
         getSlot={this.getSlot.bind(this)}
         updateHelperSidebar={this.updateHelperSidebar.bind(this)}
         meetingSelected={this.state.meetingSelected}
      />
   }
   editSelectRow(dataEdit) {
      console.log(' entrolllllll a guardar imagen', dataEdit);
      if (this.state.meetingSelected) {
         let meetingSelected = null;
         const meetings = this.state.meetings.map((item, index) => {
            if (item.id_paciente === this.state.meetingSelected.id_paciente) {
               meetingSelected = {
                  ...item,
                  ...dataEdit
               }
               return { ...meetingSelected };
            } else {
               return item;
            }
         })
         this.setState({
            meetings,
            meetingSelected
         }, () => { this.updateHelperSidebar(); });
      }
   }

   renderHelperSidebar() {
      const w = window.innerWidth;
      const alto = ((w * 0.2)) / 7;
      const heightCalendar = this.state.daysOfCalendar.length > 35 ? alto * 6 : alto * 6;

      if (this.state.meetingSelected) {
         const { dt_nacimiento, vc_telefono, id_sexo, vc_nombre, vc_apellidoPaterno, vc_apellidoMaterno, vc_email, vc_curp, vc_observaciones } = this.state.meetingSelected
         const nombre = `${vc_nombre} ${vc_apellidoPaterno} ${vc_apellidoMaterno}`
         return (
            <Paper style={{ padding: 0, border: 0, borderRadius: 0, height: '100vh', overflow: 'hidden' }}>
               <div style={{ height: '100%', width: '100%', overflowX: 'hidden', overflowY: 'scroll', WebkitOverflowScrolling: 'touch', padding: 0, margin: 0, paddingTop: 84 }}>
                  <Grid centered style={{ width: '100%', padding: 4, paddingBottom: 0, margin: 0 }}>
                     <Paper style={{ width: '100%', padding: 0, margin: 0, paddingTop: 15 }}>
                        <Box style={{ position: 'relative', padding: 30 }}>
                           <Image style={{ borderRadius: 5, overflow: 'hidden', marginBottom: 10, height: '15vw', width: '15vw' }} size='small' centered src={this.state.meetingSelected.vc_url || this.state.meetingSelected.id_sexo === 1 ? 'assets/images/hombre.png' : 'assets/images/mujer.png'} />
                           <div style={{ position: 'absolute', right: 'calc(5vw - 20px)', top: 10, }}>
                              <UploadPicture id_paciente={this.state.meetingSelected.id_paciente} editPatient={this.editSelectRow.bind(this)} />
                           </div>
                        </Box>
                        <Card.Content style={{ marginBottom: 10 }}>
                           <Card.Header style={{ fontSize: '16px' }}> {nombre} </Card.Header>
                           <Card.Description> Sexo: {id_sexo === 1 ? 'Masculino' : 'Femenino'} </Card.Description>
                           <Card.Description> Edad: {this.getAge(dt_nacimiento)} </Card.Description>
                           {vc_telefono && <Card.Description> Teléfono: {vc_telefono} </Card.Description>}
                           {vc_email && <Card.Description> email: {vc_email} </Card.Description>}
                           {vc_curp && <Card.Description> CURP: {vc_curp} </Card.Description>}
                        </Card.Content>

                        <div style={{ textAlign: 'justify', padding: 15 }}>
                           {vc_observaciones}
                        </div>

                     </Paper>
                  </Grid>
                  <Row style={{ flexDirection: 'column' }}>
                     {
                        repository.validateOptionsRol('consultar') &&
                        <Button key={'consultar'} variant="outlined" color='primary' onClick={this.consultarFuncion.bind(this)} style={{ width: "calc(100% - 6px)", margin: 3, marginBottom: 0 }}>Consultar</Button>
                     }
                     {
                        repository.validateOptionsRol('formularios') &&
                        <Button key={'formularios'} variant="outlined" color='primary' disabled style={{ width: "calc(100% - 6px)", margin: 3, marginBottom: 0 }}>Formularios</Button>
                     }
                     {
                        repository.validateOptionsRol('cancelar cita') &&
                        <Button key={'cancelar cita'} variant="outlined" color='primary' onClick={() => { this.confirmCancel() }} style={{ width: "calc(100% - 6px)", margin: 3, marginBottom: 0 }}>Cancelar Cita</Button>
                     }

                  </Row>
               </div>
               <AppBar position='absolute' style={{ height: 95, zIndex: 0 }}>
                  <Toolbar color='primary' key={`header-${1}`} style={{ height: 95 }}>
                     <Typography style={{ textAlign: 'center', width: '100%', flexDirection: 'column', verticalAlign: 'center', justifyItems: 'center' }}>
                        Paciente
              </Typography>
                  </Toolbar>
               </AppBar>
            </Paper>
         );
      }
      return (
         <Box style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
            <AppBar position={'static'} color='primary' key={`header`} style={{ height: 95, }}>
               <Toolbar color='primary' key={`header-${1}`} style={{ height: 95 }}>
                  <Typography style={{ textAlign: 'center', width: '100%', flexDirection: 'column', verticalAlign: 'center', justifyItems: 'center' }}>
                     Agendas
                </Typography>
               </Toolbar>
            </AppBar>

            <Grid centered style={{ margin: 0, padding: 0, width: '100%' }}>
               {this.renderCalendar()}
            </Grid>

            <Box style={{ padding: 0, margin: 0 }}>
               <AppBar position={'static'} color='primary' key={`header`} style={{ height: 40, }}>
                  <Toolbar color='primary' key={`header`} style={{ height: 40, minHeight: 40 }}>
                     <Typography style={{ textAlign: 'center', width: '100%', flexDirection: 'column', verticalAlign: 'center', justifyItems: 'center' }}>
                        Turnos disponibles
                </Typography>
                  </Toolbar>
               </AppBar>

               <div style={{ overflowY: 'scroll', WebkitOverflowScrolling: 'touch', height: alto - (heightCalendar + 240), padding: 3, paddingTop: 0 }}>
                  {this.renderHoursAvailables()}
               </div>
            </Box>
         </Box>
      );
   }

   changeDayCalendar(date) {

   }

   renderAddMeetingModal() {
      if (this.state.visibleAddMeeting) {
         return (<AddMeetingModal
            visible={this.state.visibleAddMeeting}
            close={() => {
               this.setState({ visibleAddMeeting: false }, () => {
                  this.updateHelperSidebar()
               });
            }}
            agendaActual={this.state.agendaActual}
            addMeeting={this.addMeeting.bind(this)}
            setInfo={this.state.slotInfo}
            horary={this.calculateMinMax()}
            events={this.state.meetings}
         />);
      }
   }

   renderPatientConsultModal() {
      if (this.state.patientConsult) {
         return (
            <PatientConsultModal
               visible={this.state.patientConsult}
               close={() => { this.setState({ patientConsult: false }); }}
               consult={this.patientConsult.bind(this)}
            />
         );
      }
   }

   renderAgendasOpciones() {
      return (
         <Dropdown.Menu>
            {
               window.amplify.store('institution').agendas.map((item, index) => {
                  return (
                     <Dropdown.Item key={index} onClick={() => { this.setState({ agendaActual: item }, () => { this.getDiaryByDay(this.state.dayAgenda); this.getDiaryByMonth(this.state.dayCalendar); }) }} >{item.vc_nombre}</Dropdown.Item>
                  )
               })
            }
         </Dropdown.Menu>
      );
   }



   clickAccion(accion) {
      switch (this.state.moduloActual) {
         case 'configuracion':
            switch (accion.id) {
               case 'guardar':
                  this.setState({ editPatient: this.state.functions.getRegistro() }, () => {
                     console.log('hola entre')
                     this.onSave(false);
                     this.updateHelperSidebar();
                  })
                  break;
            }
            break;
      }
   }
}

const styles = {
   container: {
      overflowX: 'hidden',
      overflowY: 'hidden',
      height: '100vh',
      width: '100%',
      padding: 0,
      margin: 0
   },
   principal: {
      overflowY: 'scroll', WebkitOverflowScrolling: 'touch',
      overflowX: 'hidden',
      padding: 0,
      paddingBottom: 0,
      margin: 0,
      width: "100%",
   },
   rightHeader: {
      color: 'white',
      background: 'transparent',
      textAlign: "center",
      margin: 0,
   },
   item: {
      padding: 0,
      paddingBottom: 3,
      margin: 0,
      width: "100%"
   }
}


DiaryView.propTypes = {
   setHelperSidebarRender: PropTypes.func,
   resetHelperSidebarRender: PropTypes.func,
   selectMeeting: PropTypes.func,
   setPacienteActual: PropTypes.func,
   setActiveView: PropTypes.func,
   setViewTitle: PropTypes.func,
   setMenuVisibility: PropTypes.func,
   navigate: PropTypes.func,
   setDisplaySidebar: PropTypes.func
};


const actions = {
   setHelperSidebarRender,
   resetHelperSidebarRender,
   selectMeeting,
   setPacienteActual,
   setDisplaySidebar
};

const mapStateToProps = (state) => {
   const { especialidades } = state.Think;

   return {
      especialidades,
   };
};

export default connect(mapStateToProps, actions)(DiaryView);

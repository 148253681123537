import http from "../http";

export default {
  login(body, callback) {
    http.post("/app/login", {}, body, callback);
  },
  loginUser(callback) {
    const headers = { Authorization: window.amplify.store("token") };
    http.get(`/app/api/login/user`, headers, callback);
  },
};

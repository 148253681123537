import React from "react";
import { useDispatch, useSelector } from "react-redux";

import makeStyles from "@material-ui/core/styles/makeStyles";

import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import { Box, List } from "@material-ui/core";

import InputCliente from "app/sales/components/Common/InputCliente";
import SelectCuenta from "app/sales/components/Caja/SelectCuentas";

//Hooks
import useApi from "app/sales/hooks/useApi";
import useSelect from "app/sales/hooks/form/useSelect";

import { setCliente, setCuenta } from "app/sales/store/actions";

const useStyles = makeStyles((theme) => ({
  root: { minHeight: "140px" },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
  },
  primary: {
    // color: "white",
    margin: 0,
    fontSize: "1.12rem",
    letterSpacing: "0.0075em",
  },
}));

const Index = () => {
  const { custom } = useApi("clientes");

  const classes = useStyles();
  const dispatch = useDispatch();
  const { cliente, cuenta } = useSelector((state) => state.Sales.venta);

  // const [cliente, setCliente] = React.useState(state.cliente || null);
  const [buscando, setBuscando] = React.useState(false);

  const { options, setData } = useSelect({
    keyvalue: "uuid",
    keytext: "vc_paciente",
  });

  const handleCliente = async (value) => {
    // setCliente(value);
    setBuscando(false);
    // setCuenta(null);

    dispatch(setCuenta(null));
    dispatch(setCliente(value));

    try {
      //Obtener cuentas
      const res = await custom(`${value.uuid}/cuentas`);
      setData(res);
    } catch {
      setData([]);
    }
  };

  const handleCuenta = (value) => {
    dispatch(setCuenta(value));
  };

  const handleEliminar = () => {
    dispatch(setCliente(null));
  };

  const general = !Boolean(cliente);
  let nombre = "Cliente general";
  if (!general)
    nombre = `${cliente.vc_nombre} ${cliente.vc_apellidoPaterno} ${cliente.vc_apellidoMaterno}`;

  return (
    <List className={classes.root}>
      <ListSubheader>Datos de cliente</ListSubheader>
      {buscando && (
        <ListItem>
          <ListItemText
            primary={
              <InputCliente
                autoFocus
                variant="filled"
                onChange={handleCliente}
              />
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" size="small">
              <ClearIcon onClick={() => setBuscando(false)} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
      {buscando || (
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar} size>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography className={classes.primary}>{nombre}</Typography>
            }
            secondary={
              general || (
                <SelectCuenta
                  value={cuenta}
                  data={options}
                  // selectOnOne
                  onChange={(o) => handleCuenta(o.value)}
                />
              )
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" size="small">
              {general && <SearchIcon onClick={() => setBuscando(true)} />}
              {general || <DeleteIcon onClick={handleEliminar} />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </List>
  );
};

export default Index;

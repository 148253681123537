import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setHelperSidebarRender,
  setDisplaySidebar,
} from "app/containers/Think/actions";

import { Box } from "@material-ui/core";
import Sidebar from "./sidebar";

import Schedule from "app/agenda/components/Schedule";

const Index = React.forwardRef(({ dias, agendas, ...props }, ref) => {
  const [selected, setSelected] = React.useState(null);
  const agendasRef = React.useRef({});

  React.useImperativeHandle(ref, () => ({
    addMeeting: ({ id_agenda, ...turno }) =>
      agendasRef.current[id_agenda].addMeeting(turno),
  }));

  React.useEffect(() => {
    if (selected)
      props.setHelperSidebarRender(() => (
        <Sidebar
          selected={selected}
          onActionCallback={() => {
            const agendaId = selected.id_agenda;
            agendasRef.current[agendaId].refresh();
            setSelected(null);
          }}
        />
      ));
    else props.showCalendar();
  }, [selected]);

  const handleSelect = (data) => {
    if (!selected) return setSelected(data);

    if (data.id === selected.id) setSelected(null);
    else setSelected(data);
  };

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" flexGrow={1}>
      <Box
        display="flex"
        flexGrow={1}
        flexShrink={1}
        flexBasis="auto"
        py={2}
        height={"0px"}
        overflow="scroll"
        bgcolor="rgba(200, 200, 200, 0.2)"
      >
        {agendas.map((v, i) => (
          <Schedule
            key={i}
            agendaId={v.id}
            dates={dias}
            selected={selected}
            edit={handleSelect}
            rowTime={60}
            rowHeight={200}
            hideTitleDay={dias.length === 1}
            omitStatus={[6, 7]}
            ref={(el) => (agendasRef.current[v.id] = el)}
          />
        ))}
      </Box>
    </Box>
  );
});

Index.defaultProps = {
  dias: [],
  agendas: [],
  showCalendar: () => {},
};

Index.propTypes = {
  dias: PropTypes.array,
  agendas: PropTypes.array,
  showCalendar: PropTypes.func,

  setHelperSidebarRender: PropTypes.func,
  setDisplaySidebar: PropTypes.func,
};

const actions = {
  setHelperSidebarRender,
  setDisplaySidebar,
};

const mapStateToProps = (state) => {
  const { especialidades } = state.Think;

  return {
    especialidades,
  };
};

export default connect(mapStateToProps, actions, null, { forwardRef: true })(
  Index
);

import http from "../http";

export default {
  getAtenciones(body) {
    return new Promise((resolve, reject) => {
      const headers = { Authorization: window.amplify.store("token") };
      http.post("/app/api/hospitalizacion", headers, body, (err, res) => {
        if (err) reject(err);
        else resolve(res);
      });
    });
  },
  getAtencion(body) {
    return new Promise((resolve, reject) => {
      const headers = { Authorization: window.amplify.store("token") };
      http.post(`/app/api/hospitalizacion/atencion`, headers, body, (err, res) => {
        if (err) reject(err);
        else resolve(res);
      });
    });
  },
};

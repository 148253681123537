import React from "react";
import PropTypes from "prop-types";
import { Image, Card } from "semantic-ui-react";
import { Avatar, Box } from "@material-ui/core";
import UploadPicture from "app/components/UploadPicture";
import useMeasure from "react-use-measure";

import { getAge } from "app/utils/repository";

const sexos = ["No Especificado", "Masculino", "Femenino", "Indistinto"];

const Index = ({ paciente, onChange }) => {
  const [ref, { height, width }] = useMeasure();
  const [avatar, setAvatar] = React.useState("assets/images/hombre.png");
  const {
    dt_nacimiento,
    vc_telefono,
    id_sexo,
    vc_nombre,
    vc_apellidoPaterno,
    vc_apellidoMaterno,
    vc_email,
    vc_curp,
    vc_observaciones,
    vc_url,
  } = paciente;

  const nombre = `${vc_nombre} ${vc_apellidoPaterno} ${vc_apellidoMaterno}`;

  React.useEffect(() => {
    const { id_sexo, vc_url } = paciente;
    let aux = "assets/images/hombre.png";
    if (id_sexo === 2) aux = "assets/images/mujer.png";
    if (vc_url) aux = vc_url;

    setAvatar(aux);
  }, [paciente]);

  return (
    <Box ref={ref}>
      <Box p={2} position="relative">
        <Avatar
          style={{
            overflow: "hidden",
            height: width * 0.5,
            width: width * 0.5,
            marginLeft: (width * 0.25) - 16,
          }}
          size="small"
          centered
          src={avatar}
        />
        <div
          style={{
            position: "absolute",
            right: "calc(5vw - 20px)",
            top: 10,
          }}
        >
          <UploadPicture
            id_paciente={paciente.id_paciente || paciente.id}
            editPatient={({ vc_url }) => {
              setAvatar(vc_url);
              console.log(vc_url, onChange);
              // onChange(vc_url);
            }}
          />
        </div>
      </Box>
      <Card.Content style={{ marginBottom: 10, textAlign: "center" }}>
        <Card.Header style={{ fontSize: "16px" }}> {nombre} </Card.Header>
        <Card.Description> Sexo: {sexos[id_sexo || 0]}</Card.Description>
        <Card.Description> Edad: {getAge(dt_nacimiento)} </Card.Description>
        {vc_telefono && (
          <Card.Description> Teléfono: {vc_telefono} </Card.Description>
        )}
        {vc_email && <Card.Description> email: {vc_email} </Card.Description>}
        {vc_curp && <Card.Description> CURP: {vc_curp} </Card.Description>}
      </Card.Content>

      <div style={{ textAlign: "justify", padding: 15 }}>
        {vc_observaciones}
      </div>
    </Box>
  );
};

Index.defaultProps = {
  paciente: {},
  onChange: () => {},
};

Index.propTypes = {
  paciente: PropTypes.object,
  onChange: PropTypes.func,
};
export default Index;

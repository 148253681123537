import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import QRCode from 'qrcode.react';
import TratamientosItem from './tratamientosItem';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { teal } from '@material-ui/core/colors';
import moment from 'moment';
import HeaderPrint from 'app/material-ui/Print/HeaderPrint';
let theme = createMuiTheme({
    palette: {
        primary: teal,
        secondary: {
            main: '#fafafa'
        },
        type: 'light'
    }
});

class RecetasPrint extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }
    renderMedicamentos() {
        // console.log('render medicamentos')
        return this.props.medicamentos.map((item, index) =>
            <TratamientosItem
                item={item}
                sn_printVersion={true}
                index={index}
                key={index}
                reload={(e => {
                    this.forceUpdate();
                })}
            />
        );
    }
    cedulaTitulo(rol) {
        if (rol) {
            const especialidad = this.props.especialidades.find((e) => e.id === rol.id_especialidad);
            return {
                especialidad: especialidad ? especialidad.vc_alias : '',
                vc_cedulaProfesional: rol.vc_cedulaProfesional || '',
                vc_registroSSA: rol.vc_registroSSA || ''
            };
        }
        return {
            especialidad: '',
            vc_cedulaProfesional: '',
            vc_registroSSA: ''
        }

    }
    renderHeader() {
        let paciente = 'no especificado, la nota no podrá ser guardada.';
        const pacienteObj = this.props.pacienteActual || {};
        if (pacienteObj.detalle) {
            if (pacienteObj.detalle.vc_apellidoMaterno !== undefined) {
                paciente = `${pacienteObj.detalle.vc_nombre} ${pacienteObj.detalle.vc_apellidoPaterno} ${pacienteObj.detalle.vc_apellidoMaterno}`;
            } else {
                paciente = `${pacienteObj.detalle.vc_nombre} ${pacienteObj.detalle.vc_apellidoPaterno}`;
            }
        }
        const usuario = window.amplify.store('user').usuario;
        const institucion = window.amplify.store('institution');
        const nombreUsuario = `${usuario.vc_nombre} ${usuario.vc_apellidoPaterno} ${usuario.vc_apellidoMaterno}`;

        const nombreInstitucion = `${institucion.vc_nombre}`;
        const prefijo = usuario.id_sexo === 1 ? 'Dr. ' : 'Dra.';
        const rol = window.amplify.store('rol') || window.amplify.store('roles').find(e => e.id_especialidad);
        const cedulaTitulo = this.cedulaTitulo(rol);
        const especialidad = cedulaTitulo.especialidad;
        const logoIzquierdo = rol && rol.urlLogoIzquierdo ? rol.logoIzquierdo : 'url(assets/images/iconThin-k.png)'
        return (
            <div style={{ width: '100%', margin: 0, padding: 0 }}>
                <div style={{ width: '100%', height: 100 }}>
                <div style={{
                        float: 'left',
                        width: 100,
                        height: 100,
                        margin: 0,
                    }}>
                        <div style={{
                            float: 'left',
                            width: 90,
                            height: 90,
                            borderRadius: 5,
                            margin: 0,
                            padding: 0,
                            backgroundImage: logoIzquierdo,
                            backgroundPosition: 'center',
                            backgroundSize: '90px 90px',
                            backgroundRepeat: 'no-repeat',
                        }} />
                    </div>
                    <div style={{ float: 'left', width: 'calc(100% - 200px)', height: 100 }}>
                        <div style={{ textAlign: 'center', fontSize: 20, width: '100%', marginBottom: 8, height: 20 }}>
                            {nombreInstitucion}
                        </div>
                        {
                            rol && rol.vc_institucionAval ?
                                <div style={{ textAlign: 'center', fontSize: 18, width: '100%', marginBottom: 8, height: 20 }}>
                                    {rol.vc_institucionAval}
                                </div>
                                :
                                <div />
                        }

                        <div style={{ float: 'center', textAlign: 'center', fontSize: 18, width: '100%', marginBottom: 0, height: 20 }}>
                            {especialidad.vc_nombre}
                        </div>
                    </div>
                    <div style={{
                        float: 'left',
                        width: 100,
                        height: 100,
                        margin: 0,
                    }}>
                        <QRCode
                            fgColor='black'
                            value='https://www.thin-k.mx'
                            size={90}
                            style={{
                                float: 'right',
                                width: 90,
                                height: 90,
                                border: '1px solid gray',
                                margin: 0,
                                borderRadius: 5,
                                padding: 3,
                                overflow: 'hidden'
                            }}
                        />
                    </div>
                </div>
                <div style={{ textAlign: 'center', fontSize: 15, width: '100%', padding: 5, borderRadius: 5, border: '1px solid gray', height: 30, margin: 0 }}>Paciente</div>
                <div style={{ padding: 8, marginBottom: 5, width: '100%', height: 30 }}>
                    <div style={{ float: 'left', textAlign: 'left', margin: 0, fontSize: 14, width: '70%', height: 30, textTransform: 'capitalize', color:window.colors.itemSelected }}>
                        Nombre: {paciente.toLowerCase()}
                    </div>
                    <div key={'fechanac'} style={{ float: 'right', textAlign: 'right', margin: 0, fontSize: 14, width: '30%', height: 30, color:window.colors.itemSelected }}>
                        Fecha: {moment().format('DD-MM-YYYY')}
                    </div>
                </div>
                    
                <div style={{ textAlign: 'center', fontSize: 15, width: '100%', padding: 5, borderRadius: 5, border: `1px solid gray`, height: 30 }}>Medicamentos</div>
            </div>
        )
    }
    renderFooter() {
        const rol = window.amplify.store('rol') || window.amplify.store('roles').filter(e => e.id_especialidad && e.vc_cedulaProfesional)[0];
        const cedulaTitulo = this.cedulaTitulo(rol);
        const vc_direccionReceta = rol ? rol.vc_direccionReceta || '' : ''
        return (
            <Grid centered style={{ width: '100%', margin: 0 }}>
                <div style={{ float: 'center', textAlign: 'center', width: '35%', margin: 0, padding: 0, color: 'black' }}>
                    <div style={{ float: 'center', textAlign: 'center', width: '100%', height: 1, margin: 0, backgroundColor: 'black' }}></div>
                    <div style={{ float: 'center', textAlign: 'center', width: '100%', fontSize: 13, height: 20, margin: 0, marginTop: 5 }}>{cedulaTitulo.especialidad}</div>
                    <div style={{ float: 'center', textAlign: 'center', width: '100%', fontSize: 13, height: 20, margin: 0 }}>Ced. Prof.: {cedulaTitulo.vc_cedulaProfesional} - Reg. SSA: {cedulaTitulo.vc_registroSSA}</div>
                </div>
                <div style={{ float: 'center', textAlign: 'center', width: '100%', fontSize: 13, height: 20, margin: 0, marginTop: 10 }}>{vc_direccionReceta}</div>
            </Grid>
        )
    }
    renderContent() {
        return (
            <div>
                {this.renderMedicamentos()}
                {
                    this.props.vc_observaciones ?
                        <div style={{ width: '100%', margin: 0, padding: 0 }}>
                            <div style={{ textAlign: 'center', fontSize: 15, width: '100%', padding: 5, borderRadius: 5, border: `1px solid gray`, height: 30 }}>Indicaciones</div>
                            <div style={{ width: '100%', marginTop: 20, fontSize: 18, color: 'gray', whiteSpace:'pre-wrap' }}>
                                {this.props.vc_observaciones} {console.log(this.props.vc_observaciones)}
                            </div>
                        </div>
                        :
                        <div />
                }
            </div>
        )
    }
    render() {
        return (
            <ThemeProvider theme={theme}>
                <div style={{ width: '100%', margin: 0, color: 'black' }}>
                    <table style={{ width: '100%', margin: 0 }}>
                        <thead style={{ width: '100%', margin: 0 }}>
                            <tr style={{ width: '100%', margin: 0 }}>
                                <td style={{ width: '100%', margin: 0 }}>
                                    {/* {this.renderHeader()} */}
                                    <HeaderPrint
                                    module='Medicamentos'
                                    />
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{ width: '100%', margin: 0 }}>
                            <tr style={{ width: '100%', margin: 0 }}>
                                <td style={{ width: '100%', margin: 0 }}>
                                    <div style={{ width: '100%', margin: 0 }}>{this.renderContent()}</div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td >
                                    <div style={{ opacity: 0, bottom: 10 }}>
                                        {this.renderFooter()}
                                    </div>
                                    <div style={{ width: '100%', margin: 0, position: 'fixed', bottom: 10, breakAfter: 'page', breakBefore: 'page' }}>
                                        {this.renderFooter()}
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </ThemeProvider>
        )
    }
}


const stylesReceta = {
    container: {
        padding: 2,
        float: 'left'

    },
    layerBackgroundTx: {
        float: 'center',
        position: 'absolute',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        opacity: 0.05,
        backgroundImage: 'url(assets/images/iconThin-k.png)',
        backgroundPosition: 'center',
        backgroundSize: '700px 700px',
        backgroundRepeat: 'no-repeat'

    },
    title: {
        backgroundColor: 'white',
        color:window.colors.black,
        padding: 5,
        borderRadius: 3,
    }
};


const mapStateToProps = (state) => {
    const { diagnosticos, especialidades, pacienteActual } = state.Think;
    return { diagnosticos, pacienteActual, especialidades };
};


export default connect(mapStateToProps)(RecetasPrint);

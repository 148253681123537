import http from '../http';

export default {
    updateCatalogs(body, callback) {
        const headers = { Authorization: window.amplify.store('token') };
        http.post('/app/api/actualizacion/catalogos', headers, body, callback);
    },
    updatePreguntas(body, callback) {
        const headers = { Authorization: window.amplify.store('token') };
        http.get(`/app/api/preguntas?dt_actualizacion=${body.dt_actualizacion}`, headers, callback);
    }
};

import * as types from "./types";

export const setLoggedIn = (payload) => ({
  type: types.SET_LOGGEDIN,
  payload,
});

export const setCliente = (payload) => ({
  type: types.SET_CLIENTE,
  payload,
});

export const setCuenta = (payload) => ({
  type: types.SET_CUENTA,
  payload,
});

export const setEmpresa = (payload) => ({
  type: types.SET_EMPRESA,
  payload,
});

export const setCaja = (payload) => ({
  type: types.SET_CAJA,
  payload,
});

export const addProducto = (payload) => ({
  type: types.ADD_PRODUCTO,
  payload,
});

export const deleteProducto = (payload) => ({
  type: types.DELETE_PRODUCTO,
  payload,
});

export const editProducto = (payload) => ({
  type: types.EDIT_PRODUCTO,
  payload,
});

export const setCatalogo = (catalogo, data) => ({
  type: types.SET_CATALOGO,
  payload: { catalogo, data },
});

export const resetVenta = () => ({
  type: types.RESET_VENTA,
});
